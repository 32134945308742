import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, Grid, Box } from '@mui/material';

enum TabsList {
    Invoices = 'invoices',
    Claims = 'claims',
}

type NavigationPanelProps = {
    children: React.ReactNode;
    isClaimTab?: boolean;
};

const NavigationPanel: React.FC<NavigationPanelProps> = props => {
    const { children, isClaimTab } = props;
    const history = useNavigate();
    const [currentTab, setCurrentTab] = useState<TabsList>(
        isClaimTab ? TabsList.Claims : TabsList.Invoices,
    );
    const handleChange = useCallback(
        (newTab: TabsList) => {
            if (currentTab !== newTab) {
                setCurrentTab(newTab);
                history(`/doula/portal/${newTab}`);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentTab],
    );
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={currentTab} onChange={(_, newValue) => handleChange(newValue)}>
                        <Tab value={TabsList.Invoices} label="Invoices" />
                        <Tab value={TabsList.Claims} label="Claims" />
                    </Tabs>
                </Box>
            </Grid>
            <Grid item xs={12} container>
                {children}
            </Grid>
        </Grid>
    );
};

export default NavigationPanel;
