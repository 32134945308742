import React, { useContext, useMemo, useState } from 'react';

type DashboardContextValue = {
    orgId: string;
    setOrgId: (orgId: string) => void;
};

const DashboardContext = React.createContext<DashboardContextValue>({
    orgId: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setOrgId: () => {},
});

export const useDashboard = () => useContext(DashboardContext);

export const DashboardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [orgId, setOrgId] = useState<string>('');

    const dashboardContextValue = useMemo(
        () => ({
            orgId,
            setOrgId,
        }),
        [orgId],
    );

    return (
        <DashboardContext.Provider value={dashboardContextValue}>
            {children}
        </DashboardContext.Provider>
    );
};
