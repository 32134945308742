import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { Delete } from '@mui/icons-material';
import React from 'react';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    CompareType,
    isGroup,
    operator,
    Operators,
    Rule,
    SYMPTOMS,
} from '../ConfigExpression/ConfigExpression';
import AddExpressionSelect from './AddExpressionSelect';

const deriveName = (rule: Rule) => {
    const type = () => {
        if (rule.compareType === CompareType.ConfigToValue) return 'App Config';
        if (rule.compareType === CompareType.SymptomToValue) return ' Symptom';
        if (
            rule.compareType === CompareType.TrackerToConfig ||
            rule.compareType === CompareType.TrackerToProfile ||
            rule.compareType === CompareType.TrackerToValue
        )
            return 'Tracker value';
        return 'User Profile';
    };
    let value: string | undefined = 'Unknown';
    let displayName: string | undefined = rule.name;
    let displayRuleString = '';

    if (
        rule.compareType === CompareType.ConfigToValue ||
        rule.compareType === CompareType.ProfileToValue ||
        rule.compareType === CompareType.SumOfValues ||
        rule.compareType === CompareType.DaysBeforeProfileToValue ||
        rule.compareType === CompareType.DaysAfterProfileToValue
    ) {
        if (rule.textValue) value = `'${rule.textValue}'`;
        else if (rule.choiceName) value = `choice ${rule.choiceName}`;
        else if (rule.num && !Number.isNaN(rule.num)) value = `'${rule.num.toString()}'`;
        else if (rule.bool !== undefined) value = rule.bool ? 'True' : 'False';
    } else if (
        rule.compareType === CompareType.ProfileToConfig ||
        rule.compareType === CompareType.DaysAfterProfileToConfig ||
        rule.compareType === CompareType.DaysBeforeProfileToConfig
    ) {
        value = `App Config ${rule.appConfigName}`;
        displayName = rule.userProfileName;
    } else if (
        rule.compareType === CompareType.ProfileToProfile ||
        rule.compareType === CompareType.DaysBeforeProfileToProfile ||
        rule.compareType === CompareType.DaysAfterProfileToProfile
    ) {
        value = `User Profile ${rule.userProfileNameTo}`;
        displayName = rule.userProfileName;
    }

    if (
        rule.compareType === CompareType.DaysBeforeProfileToValue ||
        rule.compareType === CompareType.DaysBeforeProfileToConfig ||
        rule.compareType === CompareType.DaysBeforeProfileToProfile
    ) {
        displayRuleString = ' Days Before';
    }

    if (
        rule.compareType === CompareType.DaysAfterProfileToValue ||
        rule.compareType === CompareType.DaysAfterProfileToConfig ||
        rule.compareType === CompareType.DaysAfterProfileToProfile
    ) {
        displayRuleString = ' Days After';
    }

    if (
        rule.compareType === CompareType.TrackerToConfig ||
        rule.compareType === CompareType.TrackerToProfile ||
        rule.compareType === CompareType.TrackerToValue
    ) {
        displayName = rule.measurementType;
    }

    if (rule.compareType === CompareType.SymptomToValue) {
        displayName = `${rule.symptom && SYMPTOMS.get(rule.symptom)}`;
    }

    if (rule.compareType === CompareType.TrackerToValue) {
        if (rule.num && !Number.isNaN(rule.num)) {
            value = `'${rule.num.toString()}'`;
        } else {
            value = '';
        }
    }

    if (rule.compareType === CompareType.TrackerToConfig) {
        value = `App Config ${rule.appConfigName}`;
    }

    if (rule.compareType === CompareType.TrackerToProfile) {
        value = `User Profile ${rule.userProfileNameTo}`;
    }

    if (rule.compareType === CompareType.SymptomToValue) {
        value = `Symptom ${rule.symptom && SYMPTOMS.get(rule.symptom)}`;
    }

    switch (rule.op) {
        case operator.eq:
            return `Rule:${displayRuleString} ${type()} ${displayName} is equal to ${value}.`;
        case operator.gt:
            return `Rule:${displayRuleString} ${type()} ${displayName} is greater than ${value}.`;
        case operator.gte:
            return `Rule:${displayRuleString} ${type()} ${displayName} is greater than or equal to ${value}.`;
        case operator.lt:
            return `Rule:${displayRuleString} ${type()} ${displayName} is less than ${value}.`;
        case operator.lte:
            return `Rule:${displayRuleString} ${type()} ${displayName} is less than or equal to ${value}.`;
        case operator.missing:
            return `Rule:${type()} ${displayName} does not have a value.`;
        default:
            break;
    }
    return 'Unknown operator';
};

const DisplayRule: React.FC<{
    rule: Rule | undefined;
    addHandler: (op: operator, id: number | undefined) => void;
    deleteHandler: (id: number) => void;
    editHandler: (id: number) => void;
}> = props => {
    const { rule, addHandler, deleteHandler, editHandler } = props;
    if (!rule)
        return (
            <div>
                <AddExpressionSelect addHandler={addHandler} ruleId={undefined} />
            </div>
        );
    if (isGroup(rule.op))
        return (
            <OutlinedSection title={Operators.find(o => o.value === rule.op)?.title || 'Unknown'}>
                {rule.subrules &&
                    rule.subrules.map(sr => (
                        <DisplayRule
                            rule={sr}
                            key={sr.id}
                            addHandler={addHandler}
                            deleteHandler={deleteHandler}
                            editHandler={editHandler}
                        />
                    ))}
                {rule.op === operator.not && !rule.subrules && (
                    <div>
                        <AddExpressionSelect addHandler={addHandler} ruleId={rule.id} />
                    </div>
                )}
                {rule.op !== operator.not && (
                    <div>
                        <AddExpressionSelect addHandler={addHandler} ruleId={rule.id} />
                    </div>
                )}
                <div style={{ textAlign: 'right', width: '100%' }}>
                    <Button onClick={() => deleteHandler(rule.id)}>
                        <Delete /> Delete {rule.name}
                    </Button>
                </div>
            </OutlinedSection>
        );

    return (
        <div>
            <Button onClick={() => editHandler(rule.id)} style={{ minWidth: '10px', padding: '0' }}>
                <FontAwesomeIcon icon={faPenToSquare} />
            </Button>
            <Button
                onClick={() => deleteHandler(rule.id)}
                style={{ minWidth: '10px', padding: '0' }}
            >
                <Delete />
            </Button>
            {deriveName(rule)}
        </div>
    );
};

export default DisplayRule;
