import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, IconButton, Tooltip, Typography } from '@mui/material';
import { Add, FileCopy, InfoOutlined } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    AdvocateTaskTemplate,
    AdvocateTaskTemplatesForAdvocateTaskTemplatesPageDocument,
    AlertSeverity,
    useAdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery,
    useAdvocateTaskTemplateWhereUsedLazyQuery,
    useDeleteAdvocateTaskTemplateMutation,
} from '~/schemaTypes';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import { CloneAdvocateTaskTemplateModal } from '../components';
import { useStyles } from './styles';
import { AdvocateTaskTemplatesResults } from './types';

const AdvocateTaskTemplates: React.FC = () => {
    const history = useNavigate();
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [showUsageModal, setShowUsageModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState<AdvocateTaskTemplate>();
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [isCloneDialogOpen, setCloneDialogOpen] = useState<boolean>(false);
    const [selectedAdvocateTaskTemplateId, setSelectedAdvocateTaskTemplateId] = useState<
        string | null
    >(null);

    const { data: advocateTaskTemplates, loading } =
        useAdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery();
    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        useAdvocateTaskTemplateWhereUsedLazyQuery();
    const [deleteTemplate, { loading: deleteTemplateLoading }] =
        useDeleteAdvocateTaskTemplateMutation({
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: AdvocateTaskTemplatesForAdvocateTaskTemplatesPageDocument,
                    variables: {},
                },
            ],
        });
    const handleDelete = () => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${selectedItem?.label}'?`,
            callback: () => {
                deleteTemplate({ variables: { id: selectedItem?.id } });
                setShowUsageModal(false);
            },
        });
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleShowUsage = (content: AdvocateTaskTemplate) => {
        whereUsed({
            variables: {
                id: content?.id,
            },
        });
        setShowUsageModal(true);
    };
    return (
        <>
            <div className={classes.root} ref={tableRef}>
                <MaterialTable<AdvocateTaskTemplatesResults>
                    title="Advocate Task Templates"
                    icons={tableIcons}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    isLoading={loading || whereUsedLoading || deleteTemplateLoading}
                    data={_.cloneDeep(advocateTaskTemplates?.advocateTaskTemplatesV2.results) ?? []}
                    actions={[
                        {
                            onClick: () => history('/app-config/advocate-task-templates/new'),
                            hidden: !pagePermissions?.AdvocateTaskTemplates.Edit,
                            icon: () => <Add />,
                            tooltip: 'Add Advocate Task Template',
                            isFreeAction: true,
                        },
                        {
                            onClick: (_, advocateTaskTemplate) =>
                                !Array.isArray(advocateTaskTemplate) &&
                                history(
                                    `/app-config/advocate-task-templates/${advocateTaskTemplate.id}`,
                                ),
                            hidden: !pagePermissions?.AdvocateTaskTemplates.Edit,
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit Advocate Task Template',
                        },
                        {
                            onClick: (_, advocateTaskTemplate) => {
                                if (!Array.isArray(advocateTaskTemplate)) {
                                    const { id } = advocateTaskTemplate;
                                    setSelectedAdvocateTaskTemplateId(id);
                                    setCloneDialogOpen(true);
                                }
                            },
                            hidden: !pagePermissions?.AdvocateTaskTemplates.Edit,
                            icon: () => <FileCopy />,
                            tooltip: 'Clone Advocate Task Template',
                        },
                        {
                            onClick: (_e, item) => {
                                setSelectedItem(item as AdvocateTaskTemplate);
                                handleShowUsage(item as AdvocateTaskTemplate);
                            },
                            icon: () => <FontAwesomeIcon icon={faEye} />,
                            tooltip: 'View References',
                        },
                    ]}
                    columns={[
                        {
                            width: '10%',
                            title: 'Id',
                            field: 'id',
                            align: 'center',
                            sorting: false,
                            render: ({ id }) => (
                                <Tooltip title={id}>
                                    <IconButton
                                        onClick={() => {
                                            copy(`${id}`);
                                            TriggerGlobalAlert({
                                                message: 'Id copied to clipboard',
                                                severity: AlertSeverity.Success,
                                            });
                                        }}
                                        size="large"
                                    >
                                        <InfoOutlined />
                                    </IconButton>
                                </Tooltip>
                            ),
                        },
                        {
                            title: 'Name',
                            field: 'label',
                            defaultSort: 'asc',
                            render: ({ label }) => (
                                <Typography data-test={label}>{label}</Typography>
                            ),
                        },
                    ]}
                    options={{ pageSize: rowsPerPage }}
                    localization={{ header: { actions: '' } }}
                />
            </div>
            {selectedAdvocateTaskTemplateId && (
                <CloneAdvocateTaskTemplateModal
                    isOpen={isCloneDialogOpen}
                    advocateTaskTemplateId={selectedAdvocateTaskTemplateId}
                    onClose={() => setCloneDialogOpen(false)}
                />
            )}
            <Dialog scroll="paper" open={showUsageModal}>
                <WhereUsedModal
                    onClose={() => setShowUsageModal(false)}
                    onDelete={handleDelete}
                    title={`Usage for ${selectedItem?.label}`}
                    canDelete={pagePermissions?.AdvocateTaskTemplates.Delete || false}
                    usageData={whereUsedData?.advocateTaskTemplateWhereUsed?.dependencies || []}
                    permissions={pagePermissions}
                />
            </Dialog>
        </>
    );
};

export default AdvocateTaskTemplates;
