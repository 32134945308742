import { Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ChevronRight, ExpandMore, Search } from '@mui/icons-material';
import React, { useMemo } from 'react';
import { TreeItem, SimpleTreeView } from '@mui/x-tree-view';
import { OrgTreeNode } from '../../../../hooks/useOrgTree';
import { OrgState } from '../Organizations';

/* eslint-disable react/jsx-props-no-spreading */

type OrganizationTreeProps = {
    orgTree: OrgTreeNode;
    defaultExpanded: string[];
    defaultSelected: string;
    orgState: OrgState;
    onClickNode: (nodeId: OrgTreeNode['org']['id']) => () => void;
    onFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const useStyles = makeStyles()(() => ({
    root: {
        height: '100%',
        padding: 10,
    },
}));

const renderOrganization = (
    { org: { id, brandingName }, orgChildren }: OrgTreeNode,
    onClickNode: OrganizationTreeProps['onClickNode'],
) => (
    <TreeItem
        itemId={id}
        key={id}
        label={brandingName}
        onClick={onClickNode(id)}
        // onSelect={onClickNode(id)}
        style={{ border: '1px solid #ccc' }}
    >
        {orgChildren.length ? orgChildren.map(org => renderOrganization(org, onClickNode)) : null}
    </TreeItem>
);

const OrganizationSidebar: React.FC<OrganizationTreeProps> = ({
    orgTree,
    defaultExpanded,
    defaultSelected,
    orgState,
    onClickNode,
    onFilterChange,
}) => {
    const { classes } = useStyles();

    const expandedItems = useMemo(
        () =>
            Object.entries(orgState).reduce((result: string[], [key, value]) => {
                if (value) {
                    result.push(key);
                }

                return result;
            }, []),
        [orgState],
    );

    const RenderedItems = useMemo(
        () => renderOrganization(orgTree, onClickNode),
        [orgTree, onClickNode],
    );

    return (
        <Paper className={classes.root} elevation={0}>
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <TextField
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        onChange={onFilterChange}
                        autoFocus
                    />
                </Grid>
                <Grid item>
                    <SimpleTreeView
                        multiSelect
                        slots={{
                            collapseIcon: ExpandMore,
                            expandIcon: ChevronRight,
                        }}
                        defaultExpandedItems={defaultExpanded}
                        defaultSelectedItems={[defaultSelected]}
                        expandedItems={expandedItems}
                        selectedItems={expandedItems}
                    >
                        {RenderedItems}
                    </SimpleTreeView>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default OrganizationSidebar;
