import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    resetPadding: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    filterButtonWrapper: {
        marginTop: 10,
    },
    emptyState: {
        fontWeight: 'lighter',
    },
    imageSelect: {
        marginBottom: 10,
    },
    imageTextField: {
        padding: 0,
    },
    imageCard: {
        padding: 0,
        marginBottom: 0,
    },
    imageSelectWrapper: {
        margin: 5,
        width: 'auto',
    },
    error: {
        color: '#e74c3c',
    },
});
