import React, { useState, useEffect, useCallback } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField, Grid } from '@mui/material';
import { DoulaClaim } from './types';

const TimeRange = () => {
    const [durationStr, setDurationStr] = useState('');
    const {
        control,
        formState: { errors },
        watch,
        setValue,
        register,
    } = useFormContext<DoulaClaim>();
    const startTime = watch('startTime');
    const endTime = watch('endTime');
    const setDuration = useCallback((durationMinutes: number) => {
        const hours = Math.floor(durationMinutes / 60);
        const minutes = durationMinutes % 60;
        const durationString = `${hours}h ${minutes}m`;
        setDurationStr(durationMinutes > 0 ? durationString : '');
        setValue('duration', durationMinutes > 0 ? durationMinutes : 0, { shouldValidate: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (startTime && endTime) {
            const start = new Date(`1970-01-01T${startTime}:00`);
            const end = new Date(`1970-01-01T${endTime}:00`);
            const durationMs = end.getTime() - start.getTime();
            const durationMinutes = Math.floor(durationMs / 1000 / 60);
            setDuration(durationMinutes);
        } else {
            setDuration(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTime, endTime]);
    return (
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <Controller
                    render={({ field: { value, onChange } }) => (
                        <TextField
                            label="Start Time"
                            type="time"
                            value={value}
                            onChange={evt => onChange(evt.target.value)}
                            InputLabelProps={{ shrink: true }}
                            error={!!errors.startTime}
                            helperText={errors.startTime?.message}
                        />
                    )}
                    name="startTime"
                    control={control}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    render={({ field: { value, onChange } }) => (
                        <TextField
                            label="End Time"
                            type="time"
                            value={value}
                            onChange={evt => onChange(evt.target.value)}
                            InputLabelProps={{ shrink: true }}
                            error={!!errors.endTime}
                            helperText={errors.endTime?.message}
                        />
                    )}
                    name="endTime"
                    control={control}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    {...register('duration')}
                    label="Visit Duration"
                    disabled
                    value={durationStr}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.duration}
                    helperText={errors.duration?.message}
                />
            </Grid>
        </Grid>
    );
};

export default TimeRange;
