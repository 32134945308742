import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, Checkbox, FormControlLabel, Typography, FormHelperText } from '@mui/material';
import CoverageAreas, {
    CoverageArea,
} from '~/views/AffiliateCare/components/CoverageAreas/CoverageAreas';

type PatientsHomeTypeProps = {
    deleteConfirm?: boolean;
    showHelperText?: boolean;
};

const PatientsHomeType: React.FC<PatientsHomeTypeProps> = props => {
    const { deleteConfirm = false, showHelperText = true } = props;
    const {
        control,
        formState: { errors },
        watch,
        setValue,
    } = useFormContext();
    const coverageAreas = watch('coverageAreas');
    const isPatientsHomeType = watch('isPatientsHomeType');
    const setData = useCallback((newAres: CoverageArea[]) => {
        setValue('coverageAreas', newAres, { shouldValidate: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <Controller
                    name="isPatientsHomeType"
                    control={control}
                    render={({ field: { value } }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value}
                                    onChange={e => {
                                        setData([]);
                                        setValue('isPatientsHomeType', e.target.checked, {
                                            shouldValidate: true,
                                            shouldDirty: !e.target.checked,
                                        });
                                    }}
                                />
                            }
                            label="At-Home"
                            labelPlacement="end"
                        />
                    )}
                />
            </Grid>
            {isPatientsHomeType && (
                <Grid item xs={12} rowSpacing={3} container padding="0 20px">
                    {showHelperText && (
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                Please add a coverage area where you or other lactation consultants
                                are willing to travel for home visits with patients.
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12} container>
                        <Grid item xs={12}>
                            {!!errors.coverageAreas && (
                                <FormHelperText error>
                                    {errors.coverageAreas.message as string}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <CoverageAreas
                                data={coverageAreas}
                                setData={setData}
                                deleteConfirm={deleteConfirm}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default PatientsHomeType;
