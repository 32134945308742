import { Button, TextField } from '@mui/material';
import { Save } from '@mui/icons-material';
import React, { useState } from 'react';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { CloneTemplateModalParams } from './MessageTemplates';
import { useStyles } from './styles';

const CloneMessageTemplateModal: React.FC<CloneTemplateModalParams> = params => {
    const { existingTemplateId: id, submitHandler } = params;
    const [name, setName] = useState('');
    const { classes } = useStyles();
    const onSubmit = () => {
        if (name.length > 0) submitHandler(id, name);
    };

    return (
        <div className={classes.dialog}>
            <OutlinedSection title="New Name">
                <TextField
                    variant="outlined"
                    type="text"
                    fullWidth
                    margin="dense"
                    onChange={e => setName(e.target.value)}
                />
            </OutlinedSection>
            <div className={classes.saveButtons}>
                <Button
                    aria-label="clone"
                    type="submit"
                    startIcon={<Save />}
                    color="secondary"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save Clone
                </Button>
            </div>
        </div>
    );
};

export default CloneMessageTemplateModal;
