export enum ProfileScreenEnum {
    PROFILE_SCREENS_CONTAINER = 'profile-screens-container',
    NAV_PROFILE_SCREENS = 'profile-screens',
    LABEL = 'profile-screens-label',
    HEALTH_INFO_LABEL = 'profile-screens-health-info-label',
    HEALTH_INFO_ICON = 'profile-screens-health-info-icon',
    HEALTH_INFO_INSTRUCTIONS = 'profile-screens-health-info-instructions',
    ADD_SURVEY_GROUP_BUTTON = 'profile-screens-add-survey-group-button',
    SURVEY_GROUP_LABEL = 'profile-screens-survey-group-label',
    ADD_SURVEY_TO_LIST_BUTTON = 'add-survey-to-list-button',
    ADDING_NEW_SURVEY_MODAL_ADD_SURVEY_TO_LIST_BUTTON = 'adding-new-survey-modal-add-survey-to-list-button',
    SURVEY_GROUP_ADD_SURVEY_TO_LIST_SURVEY = 'profile-screens-survey-group-add-survey-to-list-survey',
    SURVEY_GROUP_ADD_SURVEY_TO_LIST_TAGS = 'profile-screens-survey-group-add-survey-to-list-tags',
    SURVEY_GROUP_TAGS = 'profile-screens-survey-group-tags',
    ADD_SHORTCUT_BUTTON = 'profile-screens-add-shortcut-button',
    SHORTCUT_LABEL = 'profile-screens-shortcut-label',
    SHORTCUT_SURVEY = 'profile-screens-shortcut-survey',
    SHORTCUT_ICON = 'profile-screens-shortcut-icon',
    SHORTCUT_INSTRUCTIONS = 'profile-screens-shortcut-instructions',
    SAVE_AND_CLOSE = 'save-and-close-button',
    SHORTCUT_TAGS = 'profile-screens-shortcut-tags',
}

export const ProfileScreenSelectors = {
    NAV_PROFILE_SCREENS: `a[data-test="${ProfileScreenEnum.NAV_PROFILE_SCREENS}"]`,
    ADD_PROFILE_SCREEN_BUTTON: `div[data-test="${ProfileScreenEnum.PROFILE_SCREENS_CONTAINER}"] button[title="Add Profile Screen"]`,
    LABEL: `div[data-test="${ProfileScreenEnum.LABEL}"] input`,
    HEALTH_INFO_LABEL: `div[data-test="${ProfileScreenEnum.HEALTH_INFO_LABEL}"] input`,
    HEALTH_INFO_ICON_DROPDOWN: `div[data-test="${ProfileScreenEnum.HEALTH_INFO_ICON}"] div`,
    HEALTH_INFO_ICON_DROPDOWN_HEALTH_SUMMARY: `li[data-test="healthSummary"]`,
    SHORTCUT_ICON_DROPDOWN: `div[data-test="${ProfileScreenEnum.SHORTCUT_ICON}"] div`,
    SHORTCUT_ICON_DROPDOWN_FLOWER: `li[data-test="flower"]`,
    HEALTH_INFO_INSTRUCTIONS: `div[data-test="${ProfileScreenEnum.HEALTH_INFO_INSTRUCTIONS}"] textarea`,
    SHORTCUT_INSTRUCTIONS: `div[data-test="${ProfileScreenEnum.SHORTCUT_INSTRUCTIONS}"] textarea`,
    ADD_SURVEY_GROUP_BUTTON: `button[data-test="${ProfileScreenEnum.ADD_SURVEY_GROUP_BUTTON}"]`,
    ADD_SHORTCUT_BUTTON: `button[data-test="${ProfileScreenEnum.ADD_SHORTCUT_BUTTON}"]`,
    SAVE_AND_CLOSE_BUTTON: `button[data-test="${ProfileScreenEnum.SAVE_AND_CLOSE}"]`,
    SURVEY_GROUP_LABEL: `div[data-test="${ProfileScreenEnum.SURVEY_GROUP_LABEL}"] input`,
    SHORTCUT_LABEL: `div[data-test="${ProfileScreenEnum.SHORTCUT_LABEL}"] input`,
    ADD_SURVEY_TO_LIST_BUTTON: `button[data-test="${ProfileScreenEnum.ADD_SURVEY_TO_LIST_BUTTON}"]`,
    ADDING_NEW_SURVEY_MODAL_ADD_SURVEY_TO_LIST_BUTTON: `button[data-test="${ProfileScreenEnum.ADDING_NEW_SURVEY_MODAL_ADD_SURVEY_TO_LIST_BUTTON}"]`,
    SURVEY_GROUP_ADD_SURVEY_TO_LIST_SURVEY: `div[data-test="${ProfileScreenEnum.SURVEY_GROUP_ADD_SURVEY_TO_LIST_SURVEY}"] div`,
    SURVEY_DROPDOWN_FIRST_ITEM: 'li[data-test]:first-child',
    SHORTCUT_SURVEY: `div[data-test="${ProfileScreenEnum.SHORTCUT_SURVEY}"] div`,
    SURVEY_GROUP_ADD_SURVEY_TO_LIST_TAGS: `div[data-test="${ProfileScreenEnum.SURVEY_GROUP_ADD_SURVEY_TO_LIST_TAGS}"] input`,
    SURVEY_GROUP_ADD_SURVEY_TO_LIST_TAGS_OPTIONS: 'ul[id="new-survey-tags-popup"]',
    SURVEY_GROUP_TAGS: `div[data-test="${ProfileScreenEnum.SURVEY_GROUP_TAGS}"] input`,
    SURVEY_GROUP_TAGS_OPTIONS: 'ul[id="tags-0-popup"]',
    SHORTCUT_TAGS: `div[data-test="${ProfileScreenEnum.SHORTCUT_TAGS}"] input`,
    SHORTCUT_TAGS_OPTIONS: 'ul[id="tags-1-popup"]',
};
