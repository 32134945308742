import { AppointmentMethod } from '~/schemaTypes';

export type VirtualCarePatientInfoInput = {
    firstName: string;
    lastName: string;
    birthDate: Date | string;
    email: string;
    phoneNumber: string;
    sex: string;
    insuranceId: string;
    insuranceGroup: string;
    patientRelationshipToInsured: string;
    insuredFirstName: string;
    insuredLastName: string;
    subscriberSex: string;
    subscriberBirthDate: Date | string;
};

export type VirtualCareSurveyInput = VirtualCarePatientInfoInput & {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    insuredAddressLine1: string;
    insuredAddressLine2: string;
    insuredCity: string;
    insuredState: string;
    insuredZipCode: string;
    insuredAddressSameAsPatient: boolean;
    appointmentMethod?: AppointmentMethod;
    babyMultiples?: boolean;
    babyArrived?: boolean;
    babyFirstName?: string;
    babyLastName?: string;
    babyBirthDate?: Date | string;
    babySex?: string;
    babyDueDate?: Date | string;
    breastPump?: boolean;
    shouldSendBreastPumpEmail?: boolean;
};

export const RelationshipToInsuredEnum = {
    SELF: 'self',
    SPOUSE: 'spouse',
    CHILD: 'child',
    OTHER: 'other',
} as const;

export const EMPTY_GENDER_VALUE = { id: '', label: '' };
export const inputGenders = [
    EMPTY_GENDER_VALUE,
    { id: 'female', label: 'Female' },
    { id: 'male', label: 'Male' },
    { id: 'non-binary', label: 'Non-Binary' },
    { id: 'other', label: 'Other' },
];

export const inputBabyGenders = [
    EMPTY_GENDER_VALUE,
    { id: 'male', label: 'Male' },
    { id: 'female', label: 'Female' },
    { id: 'dontKnow', label: "Don't know yet" },
];

export type StepHandlerOptions = {
    atHomeOptionEnabled?: boolean | null;
};

export enum Steps {
    PATIENT_INFO = 1,
    PATIENT_INFO_CONTACT = 2,
    APPOINTMENT_METHOD = 3,
    CONFIRMATION = 4,
    PACIFY_CONFIRMATION = 5,
    AFFILIATE_THANK = 6,
    SUCCESS_SCREEN = 7,
}

export const validStepsForView = new Map<Steps, Steps[]>([
    [Steps.PATIENT_INFO, []],
    [Steps.PATIENT_INFO_CONTACT, [Steps.PATIENT_INFO]],
    [Steps.APPOINTMENT_METHOD, [Steps.PATIENT_INFO, Steps.PATIENT_INFO_CONTACT]],
    [Steps.CONFIRMATION, [Steps.PATIENT_INFO, Steps.PATIENT_INFO_CONTACT]],
    [Steps.PACIFY_CONFIRMATION, [Steps.PATIENT_INFO, Steps.PATIENT_INFO_CONTACT]],
    [Steps.AFFILIATE_THANK, [Steps.PATIENT_INFO, Steps.PATIENT_INFO_CONTACT]],
    [Steps.SUCCESS_SCREEN, [Steps.PATIENT_INFO, Steps.PATIENT_INFO_CONTACT]],
]);
