import { FetchAdvocateTaskForAdvocateTaskPageQuery } from '~/schemaTypes';
import { Watcher } from '../Watchers/types';

type IWatcher = NonNullable<
    FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']
>['watchers'][0];

type ToWatchersForm = (watchers: IWatcher[]) => Watcher[];

const toWatchersForm: ToWatchersForm = watchers =>
    watchers.map(({ id, name }) => ({
        id,
        name,
    }));

export default toWatchersForm;
