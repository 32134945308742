import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    Card,
    CardHeader,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { Add, ArrowBack, Delete, Save } from '@mui/icons-material';
import ObjectId from 'bson-objectid';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { useUserPermissions } from '~/hooks';
import {
    Language,
    ReadingLevel,
    TokenInput,
    TokenListDocument,
    TokenListQuery,
    useCreateTokenMutation,
    usePreFetchAppsQuery,
    useTokenByIdLazyQuery,
    useTokenListQuery,
    useUpdateTokenMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { useStyles } from '../styles';
import ReplacementModal from './ReplacementModal';

export interface Replacement {
    appId?: string | null;
    language?: Language | null;
    readingLevel?: ReadingLevel | null;
    replacement: string;
}
interface FormInput {
    name: string;
    description: string;
}

const formValuesFromData = (instance: TokenInput): FormInput => {
    return {
        name: instance.name,
        description: instance.description ?? '',
    };
};

const formValuesDefault = (): FormInput => {
    return {
        name: '',
        description: '',
    };
};
export const TokenEditor: React.FC = () => {
    const history = useNavigate();
    const { classes } = useStyles();
    const { id: tokenId } = useParams<{ id: string }>();
    const isEditMode = tokenId !== 'new';
    const { pagePermissions } = useUserPermissions();
    const canEdit = pagePermissions?.Tokens.Edit || false;
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedReplacement, setSelectedReplacement] = useState<Replacement | null>(null);
    const [replacements, setReplacements] = useState<Replacement[]>([]);
    let title = isEditMode ? 'Edit Token' : 'Create Token';
    if (!canEdit) title = 'Token Details';
    const [isModified, setIsModified] = useState(false);
    const [noReplacements, setNoReplacements] = useState(false);
    const [close, setClose] = useState(false);

    const { data: tokenList, loading: listLoading } = useTokenListQuery();

    const {
        register,
        reset,
        handleSubmit,
        setError,

        formState: { errors },
    } = useForm<FormInput>({
        defaultValues: formValuesDefault(),
    });
    const [fetchTokenById, { data: currentToken, loading: tokenLoading }] = useTokenByIdLazyQuery();

    const { data: apps, loading: appsLoading } = usePreFetchAppsQuery();

    const [updateToken, { loading: updateTokenLoading }] = useUpdateTokenMutation({
        onCompleted: () => {
            if (close) history(`/app-config/tokens`);
        },
        update: (cache, response) => {
            const updatedToken = response.data?.updateToken?.resourceUpdated;
            if (response.data?.updateToken?.success && updatedToken) {
                const currentTokens = cache.readQuery<TokenListQuery>({
                    query: TokenListDocument,
                });
                if (currentTokens?.tokens) {
                    cache.writeQuery<TokenListQuery>({
                        query: TokenListDocument,
                        data: {
                            tokens: [
                                ...currentTokens.tokens.map(t => {
                                    if (t.id === updatedToken.id) {
                                        return updatedToken;
                                    }
                                    return t;
                                }),
                            ],
                        },
                    });
                }
            }
        },
    });

    const [createToken, { loading: createTokenLoading }] = useCreateTokenMutation({
        onCompleted: data => {
            if (close) history(`/app-config/tokens`);
            else {
                if (data.createToken?.resourceCreated)
                    formValuesFromData(data.createToken.resourceCreated);
                history(`/app-config/tokens/${data.createToken?.resourceCreated?.id}`);
            }
        },
        update: (cache, response) => {
            const newToken = response.data?.createToken?.resourceCreated;
            if (response.data?.createToken?.success && newToken) {
                const currentTokens = cache.readQuery<TokenListQuery>({
                    query: TokenListDocument,
                });
                if (currentTokens?.tokens) {
                    cache.writeQuery<TokenListQuery>({
                        query: TokenListDocument,
                        data: {
                            tokens: [...currentTokens.tokens, newToken],
                        },
                    });
                }
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: TokenListDocument,
            },
        ],
    });

    useEffect(() => {
        if (isEditMode) {
            fetchTokenById({ variables: { input: { id: tokenId } } });
            if (currentToken && currentToken.token) {
                setReplacements(currentToken.token.replacements);
                reset(formValuesFromData(currentToken.token));
            }
        } else {
            setReplacements([]);
            reset(formValuesDefault());
        }
    }, [currentToken, fetchTokenById, reset, isEditMode, tokenId]);

    const onSubmit = (values: any) => {
        setNoReplacements(false);
        if (!isEditMode) {
            if (values.name === undefined || values.name === '') {
                setError('name', { message: 'Name is required' });
                return;
            }
            if (
                tokenList?.tokens.find(t => t.name.toLowerCase() === values.name.toLowerCase()) !==
                undefined
            ) {
                setError('name', { message: 'Name is already used, please change.' });
                return;
            }
        }
        if (replacements.length === 0) {
            setNoReplacements(true);
            return;
        }
        if (isEditMode && tokenId) {
            updateToken({
                variables: {
                    updateTokenInput: {
                        id: tokenId,
                        data: { ...values, name: currentToken?.token?.name, replacements },
                    },
                },
            });
        } else {
            createToken({
                variables: {
                    input: { ...values, id: new ObjectId(), replacements },
                },
            });
        }
    };
    const onNavigateAway = () => {
        if (isModified)
            TriggerGlobalConfirm({
                message: `You have unsaved changes. Are you sure you want to return to the Token List?`,
                callback: () => {
                    history(`/app-config/tokens/`);
                },
            });
        else history(`/app-config/tokens/`);
    };
    const onEdit = () => {
        setIsModified(true);
    };
    const openDialog = (r: Replacement | null) => {
        setSelectedReplacement(r);
        setModalOpen(true);
    };
    const handleClose = () => {
        setSelectedReplacement(null);
        setModalOpen(false);
    };
    const handleModalSave = (r: Replacement) => {
        const newRepSet = replacements.filter(
            o =>
                !(
                    o.appId === r.appId &&
                    o.language === r.language &&
                    o.readingLevel === r.readingLevel
                ),
        );
        newRepSet.push(r);
        setReplacements(newRepSet);
        setModalOpen(false);
        setIsModified(true);
        setNoReplacements(false);
    };
    const deleteReplacement = (r: Replacement) => {
        TriggerGlobalConfirm({
            message: `Are you sure you want remove this replacement?`,
            callback: () => {
                setReplacements(
                    replacements.filter(
                        o =>
                            o.appId !== r.appId &&
                            o.language !== r.language &&
                            o.readingLevel !== r.readingLevel,
                    ),
                );
            },
        });
    };
    if (tokenLoading || createTokenLoading || updateTokenLoading || listLoading || appsLoading)
        return <Loading />;
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                    Back to Token List
                </Button>
            </Grid>
            <Grid item xs={12}>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        <CardHeader title={title} />
                        <OutlinedSection title="Token Name *">
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                onChange={onEdit}
                                disabled={!canEdit || isEditMode}
                            />
                        </OutlinedSection>
                        <OutlinedSection title="Description">
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                {...register('description')}
                                onChange={onEdit}
                                multiline
                                minRows={4}
                            />
                        </OutlinedSection>
                        <OutlinedSection title="Replacements">
                            <Button onClick={() => openDialog(null)} title="Add Replacement">
                                <Add /> New Replacement
                            </Button>
                            {replacements && (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell>App</TableCell>
                                            <TableCell>Language</TableCell>
                                            <TableCell>Reading Level</TableCell>
                                            <TableCell>Replacement Text</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {replacements.map(r => (
                                            <TableRow>
                                                <TableCell>
                                                    <Button
                                                        title="Edit Replacement"
                                                        onClick={() => {
                                                            openDialog(r);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faPenToSquare} />
                                                    </Button>
                                                    <Button onClick={() => deleteReplacement(r)}>
                                                        <Delete />
                                                    </Button>
                                                </TableCell>
                                                <TableCell>
                                                    {apps?.applicationsV2.results.find(
                                                        a => a.id === r.appId,
                                                    )?.appBundleId ?? 'All Apps'}
                                                </TableCell>
                                                <TableCell>
                                                    {r.language ?? 'Any Language'}
                                                </TableCell>
                                                <TableCell>
                                                    {r.readingLevel ?? 'Any Reading level'}
                                                </TableCell>
                                                <TableCell>{r.replacement}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </OutlinedSection>
                        {noReplacements && (
                            <div style={{ color: 'red', fontWeight: 'bold' }}>
                                At least one Replacement is required.
                            </div>
                        )}
                    </Card>
                    {canEdit && (
                        <div style={{ width: '100%', textAlign: 'right' }}>
                            <Button
                                type="submit"
                                startIcon={<Save />}
                                color="secondary"
                                variant="contained"
                                onClick={() => handleSubmit(onSubmit)}
                            >
                                Save
                            </Button>
                            <Button
                                type="submit"
                                startIcon={<Save />}
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                    setClose(true);
                                    handleSubmit(onSubmit);
                                }}
                            >
                                Save &amp; Close
                            </Button>
                        </div>
                    )}
                </form>
            </Grid>
            {modalOpen && (
                <ReplacementModal
                    replacementList={replacements}
                    replacement={selectedReplacement}
                    closeHandler={handleClose}
                    submitHandler={handleModalSave}
                    apps={apps?.applicationsV2.results ?? []}
                />
            )}
        </Grid>
    );
};
