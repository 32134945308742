import React, { useCallback, useMemo, useState } from 'react';
import { Button, Grid, Typography, Dialog } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import MaterialTable from '@material-table/core';
import { TriggerGlobalConfirm } from '~/state';
import { OfficeAddress } from './types';
import AddOfficeAddress from './components/AddOfficeAddress';

type OfficeAddressesProps = {
    data: OfficeAddress[];
    setData: (offices: OfficeAddress[]) => void;
    deleteConfirm?: boolean;
};

type TableData = {
    index: number;
} & OfficeAddress;

const OfficeAddresses: React.FC<OfficeAddressesProps> = props => {
    const { data, setData, deleteConfirm = false } = props;
    const [showAddForm, setShowAddForm] = useState(false);
    const tableData: TableData[] = useMemo(
        () =>
            data.map((row, index) => ({
                ...row,
                index,
            })),
        [data],
    );
    const deleteHandler = useCallback(
        (deleteIndex: number) => {
            const newData = data.filter((row, index) => index !== deleteIndex);
            if (deleteConfirm) {
                TriggerGlobalConfirm({
                    open: true,
                    message: 'Are sure you want to delete?',
                    callback: () => setData(newData),
                    description: '',
                });
            } else {
                setData(newData);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, deleteConfirm],
    );
    const addNewHandler = useCallback(
        (newOffice: OfficeAddress) => {
            const newData = [...data, newOffice];
            setData(newData);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data],
    );
    return (
        <>
            <Grid container item xs={12} rowSpacing={2}>
                <Grid item xs={12} container alignItems="center" columnSpacing={2}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => setShowAddForm(true)}
                            disabled={showAddForm}
                        >
                            Add Office Address
                        </Button>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">
                            Office Addresses {!!data.length && `(${data.length})`}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        columns={[
                            {
                                title: 'Name',
                                field: 'name',
                                render: ({ name }) => <Typography>{name}</Typography>,
                                sorting: false,
                            },
                            {
                                title: 'Address',
                                field: 'address',
                                render: ({ address }) => <Typography>{address}</Typography>,
                                sorting: false,
                            },
                        ]}
                        data={tableData}
                        options={{
                            showTitle: false,
                            paging: false,
                            search: false,
                            toolbar: false,
                            actionsColumnIndex: -1,
                        }}
                        actions={[
                            {
                                icon: () => <DeleteOutline />,
                                tooltip: 'Delete',
                                onClick: (_, rowData: TableData) => deleteHandler(rowData.index),
                                isFreeAction: false,
                            },
                        ]}
                        localization={{ header: { actions: '' } }}
                    />
                </Grid>
            </Grid>
            <Dialog open={showAddForm} scroll="paper">
                <AddOfficeAddress
                    close={() => setShowAddForm(false)}
                    addNewOffice={addNewHandler}
                />
            </Dialog>
        </>
    );
};

export default OfficeAddresses;

export type { OfficeAddress };
