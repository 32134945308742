import * as Yup from 'yup';

export const formSchema = Yup.object().shape({
    firstName: Yup.string().when(['lastName, patientId'], ([lastName, patientId], schema) => {
        if (lastName === '' && patientId === '') {
            return schema.required();
        }
        return schema;
    }),
    lastName: Yup.string().when(['firstName, patientId'], ([firstName, patientId], schema) => {
        if (firstName === '' && patientId === '') {
            return schema.required();
        }
        return schema;
    }),
    patientId: Yup.string().when(['firstName', 'lastName'], ([firstName, lastName], schema) => {
        if (firstName === '' && lastName === '') {
            return schema.required('One of these 3 fields must be filled in.');
        }
        return schema;
    }),
});
