import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    BioScreensForProfileScreensPageDocument,
    BioScreensForProfileScreensPageQuery,
    FetchBioScreenForBioScreenBuilderQuery,
    useCloneBioScreenForBioScreenCloneModalMutation,
} from '~/schemaTypes';

type BioScreen = NonNullable<BioScreensForProfileScreensPageQuery['bioScreensV2']>['results'][0];
type Label = NonNullable<FetchBioScreenForBioScreenBuilderQuery['bioScreen']>['label'];

interface BioScreenCloneInput {
    bioScreenId: string;
    label: Omit<Label, '__typename'>;
}

const BIO_SCREEN_CLONE_MODAL_SCHEMA = Yup.object().shape({
    label: Yup.object().shape({
        en: Yup.string().required('You need to enter an English name'),
        es: Yup.string(),
    }),
});

type BioScreenCloneModalProps = {
    bioScreens: BioScreen[];
    bioScreenId: string;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

const BioScreenCloneModal: React.FC<BioScreenCloneModalProps> = ({
    bioScreenId,
    bioScreens,
    setOpen,
}) => {
    const history = useNavigate();
    const {
        handleSubmit,
        register,
        setError,

        formState: { errors },
    } = useForm<BioScreenCloneInput>({
        resolver: yupResolver(BIO_SCREEN_CLONE_MODAL_SCHEMA as any),
    });

    const [cloneBioScreen, { loading: cloneBioScreenLoading }] =
        useCloneBioScreenForBioScreenCloneModalMutation({
            onCompleted: () => {
                history('/app-config/bio-screens/');
            },
            update: (cache, response) => {
                const clonedBioScreen = response.data?.cloneBioScreen?.bioScreen;
                if (response.data?.cloneBioScreen?.success && clonedBioScreen) {
                    const currentBioScreens = cache.readQuery<BioScreensForProfileScreensPageQuery>(
                        {
                            query: BioScreensForProfileScreensPageDocument,
                        },
                    );
                    if (currentBioScreens?.bioScreensV2) {
                        cache.writeQuery<BioScreensForProfileScreensPageQuery>({
                            query: BioScreensForProfileScreensPageDocument,
                            data: {
                                bioScreensV2: {
                                    __typename: currentBioScreens.bioScreensV2.__typename,
                                    results: [
                                        ...currentBioScreens.bioScreensV2.results,
                                        clonedBioScreen,
                                    ],
                                },
                            },
                        });
                    }
                    setOpen(false);
                }
            },
        });

    const handleCancel = () => setOpen(false);
    const onSubmit = ({ label }: BioScreenCloneInput) => {
        const screen = bioScreens.find(s => s.label.en === label.en);
        if (!screen)
            cloneBioScreen({
                variables: {
                    input: {
                        id: bioScreenId,
                        label,
                    },
                },
            });
        else {
            setError('label.en', { message: 'Name already used, please change.' });
        }
    };

    return (
        <div>
            <DialogTitleWithClose onClose={handleCancel} id="form-dialog-title">
                Clone Profile Screen from {bioScreens.find(s => s.id === bioScreenId)?.label.en}
            </DialogTitleWithClose>
            <DialogContent className="formCont">
                {cloneBioScreenLoading ? (
                    <Loading height={140} />
                ) : (
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <OutlinedSection title="Name">
                            <TextField
                                variant="outlined"
                                label="English *"
                                fullWidth
                                margin="dense"
                                {...register('label.en')}
                                error={Boolean(errors.label?.en)}
                                helperText={errors.label?.en?.message}
                            />
                            <TextField
                                variant="outlined"
                                label="Spanish"
                                fullWidth
                                margin="dense"
                                {...register('label.es')}
                            />
                        </OutlinedSection>
                    </form>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit(onSubmit)()}
                >
                    Clone
                </Button>
            </DialogActions>
        </div>
    );
};

export default BioScreenCloneModal;
