import { FetchAdvocateTaskForAdvocateTaskPageQuery } from '~/schemaTypes';
import { actionTypeLabelById } from './index';

type ActionType = NonNullable<
    FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']
>['actionTypes'][0];

type PatientAction = NonNullable<
    FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']
>['patientActions'][0];

type PatientActionModalType = { typeId: string; description?: string; completedAt: string };

const renderComma = (changedActionType: string, changedDescription: string) =>
    `${changedActionType}${
        changedActionType && changedDescription ? ', ' : ''
    }${changedDescription}`;

const getPatientActionDiff = (
    newPatientAction: PatientActionModalType,
    prevPatientAction: PatientAction,
    patientActionTypes: ActionType[],
): string => {
    let changedActionType = '';
    let changedDescription = '';

    if (newPatientAction.typeId !== prevPatientAction.typeId) {
        changedActionType = `changed Action Type from '${actionTypeLabelById(
            prevPatientAction.typeId ?? '',
            patientActionTypes,
        )}' to '${actionTypeLabelById(newPatientAction.typeId, patientActionTypes)}'`;
    }

    if (newPatientAction.description !== prevPatientAction.description) {
        changedDescription = `changed Description from '${prevPatientAction.description}' to '${newPatientAction.description}'`;
    }

    return renderComma(changedActionType, changedDescription);
};

export default getPatientActionDiff;
