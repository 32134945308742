type AffiliateForText = {
    supportPhone?: string | null;
    contactName?: string | null;
    externalName?: string | null;
    supportEmail?: string | null;
    routingURL?: string | null;
    website?: string | null;
} | null;

const EMPTY_VALUE = (name: string, html = true) =>
    `${html ? '<b><i>' : ''}INVALID ${name}. ${name} is required field${html ? '</i></b>' : ''}`;

export const eligibilitySuccessText = (affiliate?: AffiliateForText) => {
    return `To schedule your consult, please contact ${
        affiliate?.contactName ? affiliate.contactName : EMPTY_VALUE('Contact Name', false)
    } of ${affiliate?.externalName ? affiliate.externalName : EMPTY_VALUE('External Name', false)}
${affiliate?.supportPhone ? `\nCall/Text: ${affiliate.supportPhone}` : ''}
${affiliate?.supportEmail ? `Email: ${affiliate.supportEmail}` : ''}
${affiliate?.website ? `Website: ${affiliate.website}` : ''}
 `;
};

export const zipCodeSuccessText = (affiliate?: AffiliateForText) => {
    return `<p>It's a match! You've been paired with ${
        affiliate?.externalName ? affiliate.externalName : EMPTY_VALUE('External Name')
    }.</p>
<br>
<p>To schedule your consult:</p>   
${affiliate?.supportPhone ? `<p>Call/Text: ${affiliate.supportPhone}</p>` : ''}
<p>Email: ${affiliate?.supportEmail ? affiliate.supportEmail : EMPTY_VALUE('Contact Email')}</p>
${affiliate?.website ? `<p>Website: ${affiliate.website}</p>` : ''}
 `;
};

export const zipCodeWithUrlSuccessText = (affiliate?: AffiliateForText) => {
    return `<p>It's a match! You've been paired with ${
        affiliate?.externalName ? affiliate.externalName : EMPTY_VALUE('External Name')
    }.</p>
<br>
<p>To schedule your consult, please click ${
        affiliate?.routingURL
            ? `<a href="${affiliate?.routingURL}">here</a>`
            : `${EMPTY_VALUE('Routing Url')}`
    }
 to schedule. </p>
 <br>
<p>For any additional questions:</p>    
${affiliate?.supportPhone ? `<p>Call/Text: ${affiliate.supportPhone}</p>` : ''}
<p>Email: ${affiliate?.supportEmail ? affiliate.supportEmail : EMPTY_VALUE('Contact Email')}</p>
${affiliate?.website ? `<p>Website: ${affiliate.website}</p>` : ''}
 `;
};
