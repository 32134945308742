import {
    Autocomplete,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { AdvocateTaskTypeStatus } from '~/schemaTypes';

interface AssignToFormInput {
    status: AdvocateTaskTypeStatus;
}

type ProviderModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    reassignHandler: (status: AdvocateTaskTypeStatus) => void;
    isLoading: boolean;
};

const AdvocateTasksStatusModal: React.FC<ProviderModalProps> = ({
    setOpen,
    reassignHandler,
    isLoading,
}) => {
    const { control, handleSubmit } = useForm<AssignToFormInput>({});
    const [status, setStatus] = useState<AdvocateTaskTypeStatus>(AdvocateTaskTypeStatus.Open);

    const onSubmit = ({ status }: AssignToFormInput) => {
        reassignHandler(status);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const statusValues = Object.values(AdvocateTaskTypeStatus).map(i => ({ id: i, name: i }));

    return (
        <>
            <DialogTitleWithClose onClose={handleCancel} id="Update Status">
                Update Status
            </DialogTitleWithClose>
            <DialogContent dividers>
                {isLoading ? (
                    <Grid container justifyContent="center">
                        <CircularProgress />
                    </Grid>
                ) : (
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Controller
                                name="status"
                                defaultValue={AdvocateTaskTypeStatus.Open}
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        sx={{ width: '100%' }}
                                        value={
                                            statusValues.find(i => i?.id === status) ||
                                            statusValues[0]
                                        }
                                        onChange={(_, value) => {
                                            setStatus(value?.id || AdvocateTaskTypeStatus.Open);
                                            field.onChange(value?.id);
                                        }}
                                        options={statusValues}
                                        getOptionLabel={option =>
                                            statusValues.find(i => i?.id === option?.id)?.name ?? ''
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option?.id === value?.id
                                        }
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Assigned to"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                    </form>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary" variant="contained">
                    Cancel
                </Button>
                <Button
                    onClick={() => handleSubmit(onSubmit)()}
                    variant="contained"
                    color="secondary"
                    disabled={isLoading}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
};

export default AdvocateTasksStatusModal;
