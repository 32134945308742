import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Checkbox,
    Chip,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { CheckBox, DeleteForever, Save } from '@mui/icons-material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    AlertSeverity,
    PatientTodosItemsDocument,
    PatientTodosItemsQuery,
    PatientTodosItemsQueryVariables,
    PatientTodoTaskQueryQuery,
    useDeleteTodoHaCreatedMutation,
    usePatientTodoTaskQueryQuery,
    useUpdateTodoCpUpcomingMutation,
} from '~/schemaTypes';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import { FIELD_NAMES, HA_CREATED, PRIORITIES, REPEAT_TYPE, TODO_TYPE } from '../enums';
import { intervalFromEffectiveDate, mapFormInputToQueryInput } from '../helpers';
import { INotifyIntervalUnit, IToDoEditFormInput } from '../interfaces';
import { useDefStyles } from '../styles';
import { RouteParams } from '../types';
import { isEditFormFieldDisabled, isInputHidden } from './enums';
import { determineTodoType } from './helpers';
import useStyles from './styles';
import { UPDATE_TODO_FORM_INPUT } from './validators';

type PatientTodoTask = NonNullable<PatientTodoTaskQueryQuery['patientProxiedTodoTask']>;

interface PatientTodoEditModalProps {
    todoId: string;
    closeHandler: () => void;
}

export const PatientTodoEditModal: React.FC<PatientTodoEditModalProps> = ({
    todoId,
    closeHandler,
}) => {
    const { classes } = useStyles();
    const { classes: defClasses } = useDefStyles();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [tags, setTags] = useState<string[]>([]);

    const handleTagsChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;
        const parsed = typeof value === 'string' ? value.split(',') : value;
        setTags(parsed);
    };
    const [EditableData, setEditableData] = useState<PatientTodoTask | null>(null);
    // determine type of modal window: (CP - CarePlan, HA - Health Plan)
    const [TodoType, setTodoType] = useState<TODO_TYPE>(TODO_TYPE.CPCreatedCompleted);
    const [isRecurring, setIsRecurring] = useState<boolean>(false);

    const [notifyInterval, setNotifyInterval] = useState<INotifyIntervalUnit>({
        days: 0,
        months: 0,
        years: 0,
    });
    const {
        register,
        handleSubmit,
        control,

        formState: { errors },
    } = useForm<IToDoEditFormInput>({
        resolver: yupResolver(UPDATE_TODO_FORM_INPUT as any),
    });

    const resetTodoType = (todoTask: PatientTodoTask): void => {
        const todoType = determineTodoType(todoTask);
        return setTodoType(todoType);
    };
    const { id: patientId } = useParams<RouteParams>();

    const [updateCPTodoUpcoming] = useUpdateTodoCpUpcomingMutation({
        onCompleted: async data => {
            const {
                updateProxiedTodoTask: { resourceCreated },
            } = data;

            if (resourceCreated === null || EditableData === null) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'TODO update went wrong',
                });
                return;
            }

            const todoTask = { ...EditableData, ...resourceCreated };
            resetTodoType(todoTask);
            setEditableData(todoTask);
        },
        onError: error => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `${error}`,
            });
        },
    });

    const { loading: todoLoading } = usePatientTodoTaskQueryQuery({
        variables: {
            patientTodoTaskId: todoId,
        },
        fetchPolicy: 'network-only',
        onCompleted: async data => {
            const { patientProxiedTodoTask } = data;
            if (patientProxiedTodoTask) {
                setEditableData(patientProxiedTodoTask);
                resetTodoType(patientProxiedTodoTask);

                const { dueDate, effectiveDate } = patientProxiedTodoTask;

                if (effectiveDate !== null && dueDate !== null) {
                    const notifyIntervalUnit = intervalFromEffectiveDate(
                        new Date(dueDate ?? ''),
                        new Date(effectiveDate ?? ''),
                    );
                    setNotifyInterval(notifyIntervalUnit);
                }
                const { repeatType } = patientProxiedTodoTask;
                if (repeatType !== REPEAT_TYPE.ONE_TIME && repeatType !== null) {
                    setIsRecurring(true);
                }
            }
        },
    });

    const getToDoStatusString = () => {
        const base = 'Edit TODO';
        const isCompleted =
            TodoType === TODO_TYPE.CPCreatedCompleted || TodoType === TODO_TYPE.HACreatedCompleted;
        const status = isCompleted ? 'Completed' : 'Upcoming';
        const isHACreated = EditableData?.createdBy && EditableData.createdBy === HA_CREATED;
        const createdBY = isHACreated ? 'HA Created' : 'Care Plan';
        const recurring = isRecurring ? 'recurring' : 'non-recurring';
        const final = isHACreated ? recurring : '';

        return `${base} (${status} - ${createdBY} ${final})`;
    };
    const onSubmit = async (data: IToDoEditFormInput) => {
        if (TodoType === TODO_TYPE.CPCreatedUpcoming || TodoType === TODO_TYPE.HACreatedUpcoming) {
            const defaultsOverrides = {
                ...data,
                [FIELD_NAMES.priority]: EditableData?.priority ?? data[FIELD_NAMES.priority],
                [FIELD_NAMES.isEssential]:
                    EditableData?.isEssential ?? data[FIELD_NAMES.isEssential],
                [FIELD_NAMES.dueDate]: data[FIELD_NAMES.dueDate] ?? EditableData?.dueDate,
            };
            const queryInput = mapFormInputToQueryInput(defaultsOverrides, todoId);
            await updateCPTodoUpcoming({
                variables: {
                    input: {
                        ...queryInput,
                    },
                },
            });
        }
        closeHandler();
    };

    const markAsCompleteAction = async (): Promise<void> => {
        if (EditableData === null) return;
        const isComplete = true;
        if (TodoType === TODO_TYPE.CPCreatedUpcoming || TodoType === TODO_TYPE.HACreatedUpcoming) {
            await updateCPTodoUpcoming({
                variables: {
                    input: {
                        id: todoId,
                        isComplete,
                    },
                },
            });
        }
    };

    const [deleteTodoHACreated] = useDeleteTodoHaCreatedMutation({
        onCompleted: async data => {
            const { deleteProxiedTodoTask } = data;
            if (
                deleteProxiedTodoTask === null ||
                EditableData === null ||
                !deleteProxiedTodoTask?.success
            ) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'TODO deletion went wrong',
                });
            }
        },
        onError: error => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `${error}`,
            });
        },
        update: (cache, response) => {
            const { data } = response;
            if (data === null || data === undefined) {
                return;
            }
            const { deleteProxiedTodoTask } = data;
            if (deleteProxiedTodoTask !== null && patientId !== undefined) {
                const currentTodoTasks = cache.readQuery<
                    PatientTodosItemsQuery,
                    PatientTodosItemsQueryVariables
                >({
                    query: PatientTodosItemsDocument,
                    variables: {
                        patientId,
                    },
                });
                if (currentTodoTasks !== null) {
                    const filteredTodos = currentTodoTasks.patientProxiedTodosItems.filter(todo => {
                        return todo.id !== todoId;
                    });
                    cache.writeQuery<PatientTodosItemsQuery, PatientTodosItemsQueryVariables>({
                        query: PatientTodosItemsDocument,
                        variables: {
                            patientId,
                        },
                        data: {
                            patientProxiedTodosItems: [...filteredTodos],
                        },
                    });
                    if (currentTodoTasks !== null) {
                        const filteredTodos = currentTodoTasks.patientProxiedTodosItems.filter(
                            todo => {
                                return todo.id !== todoId;
                            },
                        );
                        cache.writeQuery<PatientTodosItemsQuery, PatientTodosItemsQueryVariables>({
                            query: PatientTodosItemsDocument,
                            variables: {
                                patientId,
                            },
                            data: {
                                patientProxiedTodosItems: [...filteredTodos],
                            },
                        });
                    }
                }
            }
        },
    });

    const _deleteTodoCallback = async () => {
        return deleteTodoHACreated({
            variables: {
                input: {
                    id: todoId,
                },
            },
        });
    };

    const deleteCurrentTodoAction = async () => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete this Todo?`,
            callback: async () => {
                await _deleteTodoCallback();
                closeHandler();
            },
        });
    };

    const deleteRecurringTodoAction = async () => {
        TriggerGlobalConfirm({
            message: 'Are you sure you want to delete this Todo?',
            description: 'This will delete this Todo and all future recurring todos.',
            callback: async () => {
                await _deleteTodoCallback();
                closeHandler();
            },
        });
    };
    // todo: could be refactored when genered types would be more clear.
    const parseTagValues = (values: (string | null)[] | null | undefined): string[] => {
        const defs: string[] = [];
        if (values && values.length > 0) {
            for (const val of values) {
                if (val !== null) {
                    defs.push(val);
                }
            }
        }
        return defs;
    };

    if (todoLoading) return <Loading />;
    return (
        <form className={defClasses.root} onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>{getToDoStatusString()}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <OutlinedSection title="Title">
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                {...register('title.en')}
                                placeholder="English"
                                type="text"
                                margin="dense"
                                value={EditableData?.title.en}
                                className={defClasses.inputField}
                                fullWidth
                                disabled={isEditFormFieldDisabled[TodoType][FIELD_NAMES.title]}
                                error={!!errors[FIELD_NAMES.title]}
                                onChange={e => {
                                    if (EditableData && EditableData.title) {
                                        const editableCopy = {
                                            ...EditableData,
                                            title: {
                                                ...EditableData.title,
                                                en: e.target.value,
                                            },
                                        };
                                        setEditableData(editableCopy);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                {...register('title.es')}
                                placeholder="Spanish"
                                type="text"
                                margin="dense"
                                value={EditableData?.title.es}
                                className={defClasses.inputField}
                                fullWidth
                                disabled={isEditFormFieldDisabled[TodoType][FIELD_NAMES.title]}
                                error={!!errors[FIELD_NAMES.title]}
                                onChange={e => {
                                    if (EditableData && EditableData.title) {
                                        const editableCopy = {
                                            ...EditableData,
                                            title: {
                                                ...EditableData.title,
                                                es: e.target.value,
                                            },
                                        };
                                        setEditableData(editableCopy);
                                    }
                                }}
                            />
                        </Grid>
                    </OutlinedSection>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Description"
                            fullWidth
                            multiline
                            rows={5}
                            margin="dense"
                            value={EditableData?.description?.en}
                            className={defClasses.inputField}
                            disabled={isEditFormFieldDisabled[TodoType][FIELD_NAMES.description]}
                            {...register(FIELD_NAMES.description)}
                            error={!!errors[FIELD_NAMES.description]}
                            onChange={e => {
                                if (EditableData && EditableData.description) {
                                    const editableCopy = {
                                        ...EditableData,
                                        description: {
                                            ...EditableData.description,
                                            en: e.target.value,
                                        },
                                    };
                                    setEditableData(editableCopy);
                                }
                            }}
                        />
                    </Grid>
                    {!isInputHidden[TodoType][FIELD_NAMES.careplanLabel] && (
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="careplan-label">Careplan</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="careplan-label"
                                    value={EditableData?.carePlan?.label?.en ?? ''}
                                    disabled={
                                        isEditFormFieldDisabled[TodoType][FIELD_NAMES.careplanLabel]
                                    }
                                    {...register(FIELD_NAMES.careplanLabel)}
                                    error={!!errors[FIELD_NAMES.careplanLabel]}
                                >
                                    <MenuItem value={EditableData?.carePlan?.label?.en ?? ''}>
                                        {EditableData?.carePlan?.label?.en}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {!isInputHidden[TodoType][FIELD_NAMES.careplanGroupLabel] && (
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="category-label">Category</InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="category-label"
                                    value={EditableData?.carePlan?.group?.label?.en ?? ''}
                                    disabled={
                                        isEditFormFieldDisabled[TodoType][
                                            FIELD_NAMES.careplanGroupLabel
                                        ]
                                    }
                                    {...register(FIELD_NAMES.careplanGroupLabel)}
                                    error={!!errors[FIELD_NAMES.careplanGroupLabel]}
                                >
                                    <MenuItem
                                        value={EditableData?.carePlan?.group?.label?.en ?? ''}
                                    >
                                        {EditableData?.carePlan?.group?.label?.en}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
                <OutlinedSection title="Timing and order">
                    {!isInputHidden[TodoType][FIELD_NAMES.repeatType] && (
                        <Grid container>
                            <Grid item xs={12}>
                                <Controller
                                    name={FIELD_NAMES.repeatType}
                                    control={control}
                                    render={({ field: { onChange } }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isRecurring}
                                                    disabled={
                                                        isEditFormFieldDisabled[TodoType][
                                                            FIELD_NAMES.repeatType
                                                        ]
                                                    }
                                                    color="primary"
                                                    inputRef={register(FIELD_NAMES.repeatType).ref}
                                                    onChange={e => onChange(e.target.checked)}
                                                />
                                            }
                                            label="Recurring Todo"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid container alignItems="center">
                        <Grid item xs={2}>
                            <InputLabel>Date to complete</InputLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Start Date (UTC)"
                                    margin="dense"
                                    format="MM/DD/YYYY"
                                    InputLabelProps={{ shrink: true }}
                                    className={defClasses.inputField}
                                    value={new Date(EditableData?.dueDate ?? '')}
                                    defaultValue={new Date(EditableData?.dueDate ?? '')}
                                    {...register(FIELD_NAMES.dueDate)}
                                    onChange={(e: Date) => {
                                        if (EditableData === null) return;
                                        setEditableData({
                                            ...EditableData,
                                            dueDate: e ? e.toISOString() : EditableData.dueDate,
                                        });
                                    }}
                                    disabled={
                                        isEditFormFieldDisabled[TodoType][FIELD_NAMES.dueDate]
                                    }
                                    error={!!errors[FIELD_NAMES.dueDate]}
                                    helperText={errors[FIELD_NAMES.dueDate]?.message}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid item xs={1}>
                            <InputLabel color="primary">Show</InputLabel>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                type="number"
                                label="Days *"
                                value={notifyInterval.days}
                                defaultValue={notifyInterval.days}
                                className={defClasses.inputField}
                                {...register(FIELD_NAMES.effectiveDateDaysInterval)}
                                onChange={e => {
                                    setNotifyInterval({
                                        days: parseInt(e.target.value, 10),
                                        months: notifyInterval.months,
                                        years: notifyInterval.years,
                                    });
                                }}
                                disabled={
                                    isEditFormFieldDisabled[TodoType][
                                        FIELD_NAMES.effectiveDateDaysInterval
                                    ]
                                }
                                error={!!errors[FIELD_NAMES.effectiveDateDaysInterval]}
                                InputProps={{
                                    inputProps: { min: 0, step: 1 },
                                }}
                                margin="dense"
                                style={{
                                    maxWidth: '12em',
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                type="number"
                                label="Months *"
                                value={notifyInterval.months}
                                defaultValue={notifyInterval.months}
                                className={defClasses.inputField}
                                {...register(FIELD_NAMES.effectiveDateMonthsInterval)}
                                onChange={e => {
                                    setNotifyInterval({
                                        days: notifyInterval.days,
                                        months: parseInt(e.target.value, 10),
                                        years: notifyInterval.years,
                                    });
                                }}
                                disabled={
                                    isEditFormFieldDisabled[TodoType][
                                        FIELD_NAMES.effectiveDateMonthsInterval
                                    ]
                                }
                                error={!!errors[FIELD_NAMES.effectiveDateMonthsInterval]}
                                InputProps={{
                                    inputProps: { min: 0, step: 1 },
                                }}
                                margin="dense"
                                style={{
                                    maxWidth: '12em',
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                type="number"
                                label="Years *"
                                value={notifyInterval.years}
                                defaultValue={notifyInterval.years}
                                className={defClasses.inputField}
                                {...register(FIELD_NAMES.effectiveDateYearsInterval)}
                                onChange={e => {
                                    setNotifyInterval({
                                        days: notifyInterval.days,
                                        months: notifyInterval.months,
                                        years: parseInt(e.target.value, 10),
                                    });
                                }}
                                disabled={
                                    isEditFormFieldDisabled[TodoType][
                                        FIELD_NAMES.effectiveDateYearsInterval
                                    ]
                                }
                                error={!!errors[FIELD_NAMES.effectiveDateYearsInterval]}
                                InputProps={{
                                    inputProps: { min: 0, step: 1 },
                                }}
                                margin="dense"
                                style={{
                                    maxWidth: '12em',
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel>Before Date To Complete</InputLabel>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="priorities-label">Priority</InputLabel>
                                <Controller
                                    name={FIELD_NAMES.priority}
                                    control={control}
                                    defaultValue={PRIORITIES[4]}
                                    render={({ field: { onChange, ref, value } }) => (
                                        <Select
                                            className={defClasses.inputField}
                                            variant="outlined"
                                            labelId="priorities-label"
                                            value={EditableData?.priority ?? value}
                                            fullWidth
                                            disabled={
                                                isEditFormFieldDisabled[TodoType][
                                                    FIELD_NAMES.priority
                                                ]
                                            }
                                            inputRef={ref}
                                            onChange={e => {
                                                if (EditableData === null) return;
                                                const priority = e.target.value;
                                                if (typeof priority !== 'number') return;
                                                setEditableData({
                                                    ...EditableData,
                                                    priority,
                                                });
                                                onChange(e);
                                            }}
                                        >
                                            {Object.values(PRIORITIES).map(i => (
                                                <MenuItem key={i} value={i}>
                                                    {i}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name={FIELD_NAMES.isEssential}
                                control={control}
                                defaultValue={false}
                                render={({ field: { onChange, ref } }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Boolean(EditableData?.isEssential)}
                                                color="primary"
                                                disabled={
                                                    isEditFormFieldDisabled[TodoType][
                                                        FIELD_NAMES.isEssential
                                                    ]
                                                }
                                                inputRef={ref}
                                                onChange={e => {
                                                    if (EditableData === null) return;
                                                    setEditableData({
                                                        ...EditableData,
                                                        isEssential: Boolean(e.target.checked),
                                                    });
                                                    onChange(e.target.checked);
                                                }}
                                            />
                                        }
                                        label="Essential"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </OutlinedSection>
                {!isInputHidden[TodoType][FIELD_NAMES.careplanTemplateTags] && (
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" className={classes.selectTagsField}>
                                <InputLabel id="tags-label">Tags</InputLabel>
                                <Select
                                    multiple
                                    variant="outlined"
                                    labelId="tags-label"
                                    id="tags-multiselect"
                                    {...register(FIELD_NAMES.careplanTemplateTags)}
                                    value={parseTagValues(EditableData?.carePlan?.template?.tags)}
                                    onChange={handleTagsChange}
                                    input={<Input id="select-multiple-chip" />}
                                    renderValue={selected => (
                                        <div className={classes.chips}>
                                            {selected.map(value => (
                                                <Chip
                                                    key={value}
                                                    label={value}
                                                    className={classes.chip}
                                                />
                                            ))}
                                        </div>
                                    )}
                                    disabled={
                                        isEditFormFieldDisabled[TodoType][
                                            FIELD_NAMES.careplanTemplateTags
                                        ]
                                    }
                                    error={!!errors[FIELD_NAMES.priority]}
                                >
                                    {EditableData?.carePlan &&
                                        EditableData?.carePlan?.template &&
                                        EditableData?.carePlan?.template?.tags &&
                                        EditableData?.carePlan?.template?.tags.map(
                                            tag =>
                                                tag && (
                                                    <MenuItem key={tag} value={tag}>
                                                        {tag}
                                                    </MenuItem>
                                                ),
                                        )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>

            <DialogActions
                style={{
                    justifyContent: 'flex-start',
                }}
            >
                {!isInputHidden[TodoType][FIELD_NAMES.cancelModal] && (
                    <Button onClick={closeHandler} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                )}
                {!isInputHidden[TodoType][FIELD_NAMES.submitTodo] && (
                    <Button
                        name={FIELD_NAMES.submitTodo}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        startIcon={<Save />}
                    >
                        Save
                    </Button>
                )}
                {!isInputHidden[TodoType][FIELD_NAMES.closeModal] && (
                    <Button onClick={closeHandler} color="secondary" variant="outlined">
                        Close
                    </Button>
                )}
                {!isInputHidden[TodoType][FIELD_NAMES.deleteTodoRecurring] && isRecurring && (
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<DeleteForever />}
                        onClick={() => {
                            deleteRecurringTodoAction();
                        }}
                        style={{
                            marginLeft: 'auto',
                            marginRight: '1em',
                        }}
                        name={FIELD_NAMES.deleteTodoRecurring}
                    >
                        Delete Recurring
                    </Button>
                )}
                {!isInputHidden[TodoType][FIELD_NAMES.deleteTodo] && !isRecurring && (
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<DeleteForever />}
                        style={{
                            marginLeft: 'auto',
                            marginRight: '1em',
                        }}
                        onClick={() => deleteCurrentTodoAction()}
                        name={FIELD_NAMES.deleteTodo}
                    >
                        Delete
                    </Button>
                )}
                {!isInputHidden[TodoType][FIELD_NAMES.markAsComplete] && (
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        startIcon={<CheckBox />}
                        style={{
                            marginLeft: 'auto',
                        }}
                        name={FIELD_NAMES.markAsComplete}
                        onClick={() => {
                            TriggerGlobalConfirm({
                                message: 'Are you sure you want to complete this Todo?',
                                callback: async () => {
                                    markAsCompleteAction();
                                },
                            });
                        }}
                    >
                        Mark as complete
                    </Button>
                )}
            </DialogActions>
        </form>
    );
};
