import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '@mui/material';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    CareTeamMemberType,
    FetchCareTeamMemberTypesForConfigPageDocument,
    useCareTeamMemberTypeWhereUsedLazyQuery,
    useDeleteCareTeamMemberTypeMutation,
    useFetchCareTeamMemberTypesForConfigPageQuery,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { CareTeamMemberTypeModal } from './CareTeamMemberTypeModal/CareTeamMemberTypeModal';
import { useStyles } from './styles';

const CareTeamMemberTypes: React.FC = () => {
    const { classes } = useStyles();
    const { data, loading } = useFetchCareTeamMemberTypesForConfigPageQuery({});
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [showUsageModal, setShowUsageModal] = useState(false);
    const [selectedCareTeamMemberType, setSelectedCareTeamMemberType] = useState<
        CareTeamMemberType | undefined
    >();
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        useCareTeamMemberTypeWhereUsedLazyQuery();
    const [deleteItem, { loading: deleteItemLoading }] = useDeleteCareTeamMemberTypeMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: FetchCareTeamMemberTypesForConfigPageDocument,
                variables: {},
            },
        ],
    });
    const handleDelete = () => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${selectedCareTeamMemberType?.name}'?`,
            callback: () => {
                deleteItem({ variables: { id: selectedCareTeamMemberType?.id } });
                setShowUsageModal(false);
            },
        });
    };
    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleOpenEdit = (item: CareTeamMemberType | undefined) => {
        setSelectedCareTeamMemberType(item);
        setModalOpen(true);
    };
    const handleShowUsage = (item: CareTeamMemberType) => {
        whereUsed({
            variables: {
                id: item?.id,
            },
        });
        setSelectedCareTeamMemberType(item);
        setShowUsageModal(true);
    };

    return (
        <>
            <div className={classes.root} ref={tableRef}>
                <MaterialTable<CareTeamMemberType>
                    title="Care Team Member Types"
                    icons={tableIcons}
                    isLoading={loading || whereUsedLoading || deleteItemLoading}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    data={_.cloneDeep(data?.careTeamMemberTypesV2.results) ?? []}
                    actions={[
                        {
                            onClick: () => handleOpenEdit(undefined),
                            icon: () => <Add />,
                            tooltip: 'Add Care Team Member Type',
                            isFreeAction: true,
                            hidden: !pagePermissions?.CareTeamMemberTypes.Edit,
                        },
                        {
                            onClick: (_, item) => {
                                const isSingleItemSelected = !Array.isArray(item);
                                if (isSingleItemSelected) {
                                    handleOpenEdit(item);
                                }
                            },
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit Article Category',
                            hidden: !pagePermissions?.ArticleCategory.Edit,
                        },
                        {
                            onClick: (_e, item) => {
                                handleShowUsage(item as CareTeamMemberType);
                            },
                            icon: () => <FontAwesomeIcon icon={faEye} />,
                            tooltip: 'View References',
                        },
                    ]}
                    columns={[
                        {
                            title: 'Name',
                            field: 'name',
                        },
                        {
                            title: 'Profile Definition',
                            field: 'profileDef.name',
                        },
                        {
                            title: 'is Job',
                            field: 'isJob',
                            render: rowData => (rowData.isJob ? 'Yes' : 'No'),
                        },
                    ]}
                    options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100] }}
                    localization={{ header: { actions: '' } }}
                />
            </div>
            <CareTeamMemberTypeModal
                isOpen={isModalOpen}
                item={selectedCareTeamMemberType || null}
                onClose={() => {
                    setModalOpen(false);
                    setSelectedCareTeamMemberType(undefined);
                }}
            />
            <Dialog scroll="paper" open={showUsageModal}>
                <WhereUsedModal
                    onClose={() => setShowUsageModal(false)}
                    onDelete={handleDelete}
                    title={`Usage for ${selectedCareTeamMemberType?.name}`}
                    canDelete={pagePermissions?.CareTeamMemberTypes.Delete || false}
                    usageData={whereUsedData?.careTeamMemberTypeWhereUsed?.dependencies || []}
                    permissions={pagePermissions}
                />
            </Dialog>
        </>
    );
};

export default CareTeamMemberTypes;
