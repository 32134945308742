export enum WhatsNewTemplateEnum {
    NAV_WHATS_NEW_TEMPLATES = 'whats-new-templates',
    CONTAINER = 'whats-new-templates-container',
    TYPE_DROPDOWN = 'whats-new-template-type-dropdown',
    LABEL = 'whats-new-template-label',
    HEADER_DISPLAY_ICON_DROPDOWN = 'header-display-icon-dropdown',
    HEADER_DISPLAY_LABEL = 'header-display-label',
    CONTENT_DISPLAY_TITLE = 'content-display-title',
    CONTENT_DISPLAY_LABEL = 'content-display-label',
    CONTENT_DISPLAY_DESCRIPTION = 'content-display-description',
    ADD_ANOTHER_PARAGRAPH_BUTTON = 'add-another-paragraph-button',
    OTHER_PRIORITY_DROPDOWN = 'other-priority-dropdown',
    OTHER_HIGHLIGHTED_CHECKBOX = 'highlighted-checkbox',
    OTHER_CLOSEABLE_CHECKBOX = 'closeable-checkbox',
    OTHER_WHEN_TYPE_DROPDOWN = 'other-when-type-dropdown',
    SAVE_AND_CLOSE = 'save-and-close-button',
}

export const WhatsNewTemplateSelectors = {
    NAV_WHATS_NEW_TEMPLATES: `a[data-test="${WhatsNewTemplateEnum.NAV_WHATS_NEW_TEMPLATES}"]`,
    ADD_TEMPLATE_BUTTON: `div[data-test="${WhatsNewTemplateEnum.CONTAINER}"] button[title="Add Template"]`,
    TYPE_DROPDOWN: `div[data-test="${WhatsNewTemplateEnum.TYPE_DROPDOWN}"] div[role="button"]`,
    TYPE_DROPDOWN_ALERT_OPTION: `li[data-test="alert"]`,
    LABEL_INPUT: `div[data-test="${WhatsNewTemplateEnum.LABEL}"] input`,
    HEADER_DISPLAY_ICON_DROPDOWN: `div[data-test="${WhatsNewTemplateEnum.HEADER_DISPLAY_ICON_DROPDOWN}"] div[role="button"]`,
    HEADER_DISPLAY_ICON_DROPDOWN_RESOURCE_OPTION: `li[data-test="resource"]`,
    HEADER_DISPLAY_LABEL: `div[data-test="${WhatsNewTemplateEnum.HEADER_DISPLAY_LABEL}"] input`,
    CONTENT_DISPLAY_TITLE: `div[data-test="${WhatsNewTemplateEnum.CONTENT_DISPLAY_TITLE}"] input`,
    CONTENT_DISPLAY_LABEL: `div[data-test="${WhatsNewTemplateEnum.CONTENT_DISPLAY_LABEL}"] input`,
    CONTENT_DISPLAY_DESCRIPTION_1: `div[data-test="${WhatsNewTemplateEnum.CONTENT_DISPLAY_DESCRIPTION}-0"] textarea`,
    CONTENT_DISPLAY_DESCRIPTION_2: `div[data-test="${WhatsNewTemplateEnum.CONTENT_DISPLAY_DESCRIPTION}-1"] textarea`,
    ADD_ANOTHER_PARAGRAPH_BUTTON: `button[data-test="${WhatsNewTemplateEnum.ADD_ANOTHER_PARAGRAPH_BUTTON}"]`,
    COLOR_PICKER_BACKGROUND: 'input[id="color-color-picker"]',
    COLOR_PICKER_BACKGROUND_VALUE: 'div[data-test="Background Color"] input',
    COLOR_PICKER_TEXT: 'input[id="textColor-color-picker"]',
    COLOR_PICKER_TEXT_VALUE: 'div[data-test="Text Color"] input',
    OTHER_PRIORITY_DROPDOWN: `div[data-test="${WhatsNewTemplateEnum.OTHER_PRIORITY_DROPDOWN}"] div[role="button"]`,
    OTHER_PRIORITY_DROPDOWN_5_OPTION: `li[data-test="5"]`,
    OTHER_HIGHLIGHTED_CHECKBOX: `span[data-test="${WhatsNewTemplateEnum.OTHER_HIGHLIGHTED_CHECKBOX}"] input`,
    OTHER_CLOSEABLE_CHECKBOX: `span[data-test="${WhatsNewTemplateEnum.OTHER_CLOSEABLE_CHECKBOX}"] input`,
    OTHER_WHEN_TYPE_DROPDOWN: `div[data-test="${WhatsNewTemplateEnum.OTHER_WHEN_TYPE_DROPDOWN}"] div[role="button"]`,
    OTHER_WHEN_TYPE_DROPDOWN_TIMELINE_OPTION: `li[data-test="timeline"]`,
    SAVE_AND_CLOSE_BUTTON: `button[data-test="${WhatsNewTemplateEnum.SAVE_AND_CLOSE}"]`,
    SEARCH_INPUT: `div[data-test="${WhatsNewTemplateEnum.CONTAINER}"] input[placeholder="Search"]`,
    DELETE_TEMPLATE_BUTTON: `div[data-test="${WhatsNewTemplateEnum.CONTAINER}"] button[title="Delete Template"]`,
};
