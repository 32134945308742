import { Card, Fade, Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import React, { useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { displayDateWithAbbrTimeZone, isToday } from '~/helpers/dateHelpers';
import {
    FetchChatRoomMessagesForMessageCenterQuery,
    useFetchStaffByIdForStaffModalLazyQuery,
} from '~/schemaTypes';
import { TriggerGlobalImagePreview } from '~/state';
import parse from 'html-react-parser';

type ChatRoom = NonNullable<FetchChatRoomMessagesForMessageCenterQuery['chatRoom']>;
type ChatMessageType = NonNullable<ChatRoom['messages'][0]>;

const useStyles = makeStyles<{
    isOwner: boolean;
    isPatient: boolean;
    messageReadByPatient: boolean;
}>()((theme, { isOwner, isPatient, messageReadByPatient }) => ({
    root: {
        width: 750,
        marginBottom: 30,
        zIndex: 5,
        position: 'relative',
        alignSelf: isOwner ? 'flex-end' : 'flex-start',
        '& .card': {
            padding: 8,
            position: 'relative',
            border: isPatient ? '2px solid green' : '',
            // eslint-disable-next-line no-nested-ternary
            background: isOwner ? theme.colors.Primary(100) : isPatient ? '#8FBC8F' : 'lightgray',
            marginBottom: 0,
            overflow: 'visible',
            '& .messageTimestamp': {
                position: 'absolute',
                fontSize: '.8em',
                right: '5px',
                bottom: '5px',
            },
            '& .messageReadReceipt': {
                color: !messageReadByPatient ? 'orange' : 'black',
                fontWeight: !messageReadByPatient ? 'bold' : 'normal',
                position: 'absolute',
                bottom: -25,
                right: 0,
            },
        },
    },
    carouselItem: {
        position: 'relative',
        '& .overlayDiv': {
            position: 'absolute',
            top: 0,
            // height: '100%',
            width: '100%',
            display: 'none',
        },
        '&:hover': {
            '& .overlayDiv': {
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                opacity: 0.8,
                '& .hoverText': {
                    display: 'none',
                    color: '#fff',
                    opacity: 1,
                },
                '&:hover': {
                    boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.2)',
                    '& .hoverText': {
                        display: 'inherit',
                    },
                },
            },
        },
    },
    carouselIcons: {
        background: '#fff',
        boxShadow: theme.shadows[1],
        transition: 'color .2s',
        '&:hover': {
            opacity: 1,
            background: '#fff',
            color: theme.colors.Primary(),
        },
    },
}));

type ChatMessageProps = {
    chatRoom: ChatRoom | null;
    chatMessage: ChatMessageType;
    currentUserId?: string;
};

const ChatMessage: React.FC<ChatMessageProps> = ({ chatRoom, chatMessage, currentUserId }) => {
    const [getStaff, { data: staffData }] = useFetchStaffByIdForStaffModalLazyQuery({
        fetchPolicy: 'network-only',
    });
    const messageReadByPatient = useMemo(
        () => !!(chatRoom?.patient.id && chatMessage.readByPatient),
        [chatRoom?.patient.id, chatMessage.readByPatient],
    );

    const isOwner = currentUserId === chatMessage.senderId;
    const isPatient = chatMessage.sentByPatient || false;
    const { classes } = useStyles({
        isOwner,
        isPatient,
        messageReadByPatient,
    });
    const { senderId } = chatMessage;

    const sender = useMemo(() => {
        if (isPatient) {
            return chatRoom?.patient.fullName;
        }
        getStaff({
            variables: {
                input: {
                    id: senderId,
                },
            },
        });
        if (staffData) {
            return staffData.user?.name;
        }
        return 'Unknown';
    }, [chatRoom?.patient.fullName, getStaff, isPatient, senderId, staffData]);

    return (
        <Fade timeout={500} in>
            <Grid
                container
                spacing={1}
                alignItems="flex-end"
                wrap="nowrap"
                direction={isOwner ? 'row-reverse' : 'row'}
                className={classes.root}
                data-message-id={chatMessage.id}
            >
                <Grid item xs={10}>
                    <Card className="card">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Carousel
                                    showThumbs={false}
                                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                        hasPrev && (
                                            <IconButton
                                                className={classes.carouselIcons}
                                                onClick={onClickHandler}
                                                title={label}
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: 2,
                                                    top: 'calc(50% - 15px)',
                                                    width: 30,
                                                    height: 30,
                                                    cursor: 'pointer',
                                                    left: 15,
                                                }}
                                                size="large"
                                            >
                                                <ChevronLeftOutlined />
                                            </IconButton>
                                        )
                                    }
                                    renderArrowNext={(onClickHandler, hasNext, label) =>
                                        hasNext && (
                                            <IconButton
                                                className={classes.carouselIcons}
                                                onClick={onClickHandler}
                                                title={label}
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: 2,
                                                    top: 'calc(50% - 15px)',
                                                    width: 30,
                                                    height: 30,
                                                    cursor: 'pointer',
                                                    right: 15,
                                                }}
                                                size="large"
                                            >
                                                <ChevronRightOutlined />
                                            </IconButton>
                                        )
                                    }
                                >
                                    {chatMessage.attachments?.map(uuid => (
                                        <div className={classes.carouselItem} key={uuid}>
                                            {/* eslint-disable-next-line jsx-a11y/alt-text */}
                                            <img
                                                src={
                                                    uuid?.startsWith('http')
                                                        ? uuid
                                                        : `https://ucarecdn.com/${uuid}/`
                                                }
                                            />
                                            <Card
                                                onClick={() =>
                                                    TriggerGlobalImagePreview({
                                                        imageSrc: uuid?.startsWith('http')
                                                            ? uuid
                                                            : `https://ucarecdn.com/${uuid}/`,
                                                    })
                                                }
                                                className="overlayDiv"
                                            >
                                                <Typography variant="h6" className="hoverText">
                                                    Click to view
                                                </Typography>
                                            </Card>
                                        </div>
                                    ))}
                                </Carousel>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" paragraph style={{ maxWidth: '100%' }}>
                                    {chatMessage.text && parse(chatMessage.text)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="messageTimestamp" variant="subtitle1">
                                    {displayDateWithAbbrTimeZone({
                                        isoDateStr: chatMessage.createdAt,
                                        format: `${
                                            isToday(new Date(chatMessage.createdAt))
                                                ? 'h:mm a'
                                                : 'MMM DD, h:mm a'
                                        }`,
                                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    })}
                                </Typography>
                            </Grid>
                        </Grid>
                        {!chatMessage.sentByPatient && (
                            <Typography className="messageReadReceipt">
                                {messageReadByPatient ? 'Read' : 'Unread'}
                            </Typography>
                        )}
                    </Card>
                    <div style={{ fontStyle: 'italic', fontSize: '.8em' }}>Sent by {sender}</div>
                </Grid>
            </Grid>
        </Fade>
    );
};

export default ChatMessage;
