export const ROOT_ORG_ID = '000000000000000000000000';
export const DORSATA_ORG_ID = '000000000000000000000001';
export const CIGNA_LACTATION_ORG_ID = '6446f71a7dfb8695804af26c';
export const BCNCNC_ORG_ID = '64d419b4dfb569b69bb068d4';

export const ROLE_USER_ID = '000000000000000000000002';
export const ROLE_WILDFLOWER_USER_ID = '000000000000000000000003';
export const ROLE_ADMIN_ID = '000000000000000000000004';
export const ROLE_WILDFLOWER_ADMIN_ID = '000000000000000000000005';
export const ROLE_SYS_ADMIN_ID = '000000000000000000000006';

export const BASE_MODEL_NAME = 'baseModel';

export const METADATA_KEY = '';

export const TEST_PATIENT_ID = '000000000000000000000007';
export const TEST_SECOND_PATIENT_ID = '000000000000000000000008';

export const POSTPARTUM_SURVEY_ID = '000000000000000000000009';
export const TEST_SURVEY_ID = '000000000000000000000019';

export const PAGESIZE = 25;

export const TOKENDELIMITER = '@@';

export const CLOUDFRONT_IMAGE_SOURCE = 'http://d3ek8h09snyh5p.cloudfront.net/originals/';

export const ASSIGNED_HEALTH_ADVOCATE_PROFILE_DEFINITION_ID = '637295daf056222916440a9f';
export const PHONE_NUMBER_PROFILE_DEFINITION_ID = '612d4cec592f296ee0c44d6a';
export const ZIP_CODE_PROFILE_DEFINITION_ID = '612d4cec592f29250dc44d69';
export const ADI = '66e87a5be983ed192c32676e';
export const IS_VBC_TAG = '63370fdacdc99cc5dedf55cb';
export const AHS_INTAKE_SURVEYS = '63cec72d26e919ca27cd6a3a';
export const CARE_TEAM_EMPLOYER = '63a3778c616d791264c8c1df';
export const CARE_TEAM_PRACTICE = '63a238a9cfd11ba4d06fad87';
export const CARE_TEAM_PAYER = '63a605a2b1fa8665455e8ab6';
export const CARE_TEAM_PROVIDER = '63ae2952ffe53b5f5a53d938';
export const IS_VBC_ELIGIBLE = '633caabc9ce15c4a2ebf7ba2';
export const IS_USER_PAYER_ELIGIBILE = '63a605cdf49dab80fc83a3c2';
export const IS_HA_INTERACTED_DEF_ID = '6419005899ea611c4ac80eab';
export const HA_INTERACTED_TYPE_DEF_ID = '641900a2115c1ff6cbf6dcd3';
export const HA_INTERACTED_DATE_DEF_ID = '6425da44bd1d10d89090ceb0';
export const HTC_INVOICE_DATE_DEF_ID = '640a5ea09843dfc027b6baaa';

export const HEALTH_ADVOCATE_CARE_TEAM_MEMBER_TYPE_ID = '666757d763090369ea9a754f';
export const VC_PARENT_ORG_ID = '64ed20b3c869a4f592dc357f';
export const CIGNA_DOULA_ORG_ID = '6732383ac929c5ecf5df6f67';
