import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { ContentType, ExpressionUseType, SurveyDefTutorialContentInput } from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import ConfigExpressionPicker from '~/views/ConfigDashboard/ConfigExpressions/components/ConfigExpressionPicker';
import NonImageContentPicker from '../../../../components/NonImageContentPicker/NonImageContentPicker';

interface PieceTutorialContentInputParms extends SurveyDefTutorialContentInput {
    divisionId: string;
    sectionId: string;
    pieceId?: string;
    expressionList?: (string | null)[] | null | undefined;
    video: string;
    submitHandler: (data: any) => void;
    deleteHandler: (divId: string, secId: string, pieceId: string) => void;
    closeHandler: () => void;
}

const PieceTutorialContentModal: React.FC<PieceTutorialContentInputParms> = props => {
    const { handleSubmit } = useForm<SurveyDefTutorialContentInput>({});
    const {
        divisionId,
        sectionId,
        pieceId,
        video,
        expressionList,
        submitHandler,
        closeHandler,
        deleteHandler,
    } = props;
    const [selectedExpressions, setSelectedExpressions] = useState<(string | null)[]>(
        expressionList ?? [],
    );
    const isEditMode = Boolean(video);
    const [selectedVideoId, setSelectedVideoId] = useState(video || '');
    const [selectedVideoName, setSelectedVideoName] = useState('');
    const [videoRequired, setVideoRequired] = useState<boolean>(false);

    const onSubmit = (nameVariables: any) => {
        if (!selectedVideoId) {
            setVideoRequired(true);
            return;
        }

        const data = {
            ...nameVariables,
            divisionId,
            sectionId,
            pieceId,
            selectedExpressions,
            videoId: selectedVideoId,
            videoName: selectedVideoName,
        };
        submitHandler(data);
    };
    const onDelete = () => {
        TriggerGlobalConfirm({
            message: 'Do you really want to delete this Tutorial Content?',
            callback: () => {
                deleteHandler(divisionId, sectionId, pieceId ?? '');
            },
        });
    };
    const expressionAdded = (id: string) => {
        setSelectedExpressions(selectedExpressions?.concat(id));
    };
    const expressionDropped = (id: string) => {
        setSelectedExpressions(selectedExpressions?.filter(exp => exp !== id));
    };
    return (
        <Dialog open>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogTitleWithClose onClose={closeHandler} id="modalTitle">
                    {isEditMode ? 'Edit' : 'Add'} Content
                </DialogTitleWithClose>
                <DialogContent>
                    <NonImageContentPicker
                        setSelectedContentId={setSelectedVideoId}
                        setSelectedContentName={setSelectedVideoName}
                        selectedContentId={selectedVideoId}
                        required={videoRequired}
                        allowedTypes={[ContentType.Video]}
                        compact
                    />
                    <ConfigExpressionPicker
                        inputList={selectedExpressions}
                        itemAddedHandler={expressionAdded}
                        itemDroppedHandler={expressionDropped}
                        useType={ExpressionUseType.Surveys}
                        canChange
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeHandler} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button type="submit" color="secondary" variant="contained">
                        {pieceId ? 'Update ' : 'Add '}
                        Tutorial Content
                    </Button>{' '}
                    {pieceId && (
                        <Button color="primary" variant="contained" onClick={onDelete}>
                            Remove Tutorial Content
                        </Button>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
};

export {};
export default PieceTutorialContentModal;
