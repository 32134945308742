import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormHelperText,
    MenuItem,
    TextField,
} from '@mui/material';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import {
    ExpressionUseType,
    OrderByDirectionEnum,
    SurveyDefQuestionDisplayType,
    useCompoundQuestionsV2ForSurveyQuery,
} from '~/schemaTypes';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import ConfigExpressionPicker from '~/views/ConfigDashboard/ConfigExpressions/components/ConfigExpressionPicker';
import { TriggerGlobalConfirm } from '~/state';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import Loading from '~/components/Loading/Loading';
import { useStyles } from './styles';
import { PIECE_QUESTION_VALIDATION } from './yupSchema';
import {
    CompoundQuestion,
    SurveyCompoundQuestionFormInput,
    PieceCompoundQuestionModalProps,
} from './types';

const labelsMap: { [key in SurveyDefQuestionDisplayType]?: string } = {
    [SurveyDefQuestionDisplayType.RadioButtons]: 'Radio Buttons',
    [SurveyDefQuestionDisplayType.DropDown]: 'Dropdown',
    [SurveyDefQuestionDisplayType.ButtonGroup]: 'Button Group',
    [SurveyDefQuestionDisplayType.CheckBoxes]: 'Checkboxes',
    [SurveyDefQuestionDisplayType.DropDownMultipleSelect]: 'Dropdown with Multiple Select',
};

const getDisplayTypeByCompoundQuestionId = (
    compoundQuestionId: string,
    compoundQuestions: CompoundQuestion[],
): SurveyDefQuestionDisplayType[] => {
    const { profileValueType, questionProfileDefs } =
        compoundQuestions.find(({ id }) => id === compoundQuestionId) ?? {};

    if (!profileValueType || !questionProfileDefs) {
        return [];
    }

    return [
        SurveyDefQuestionDisplayType.RadioButtons,
        SurveyDefQuestionDisplayType.DropDown,
        SurveyDefQuestionDisplayType.ButtonGroup,
        SurveyDefQuestionDisplayType.CheckBoxes,
        SurveyDefQuestionDisplayType.DropDownMultipleSelect,
    ].sort();
};

const getDefaultValues = (
    compoundQuestion: PieceCompoundQuestionModalProps['compoundQuestion'],
    expressions: PieceCompoundQuestionModalProps['expressions'],
) => ({
    label: {
        en: compoundQuestion?.label?.en ?? '',
        es: compoundQuestion?.label?.es ?? '',
    },
    answerRequired: compoundQuestion?.answerRequired ?? false,
    hidePreviousAnswer: compoundQuestion?.hidePreviousAnswer ?? false,
    compoundQuestionId: compoundQuestion?.compoundQuestionId,
    displayType: compoundQuestion?.displayType,
    expressions: expressions ?? [],
});

const PieceCompoundQuestionModal = ({
    submitHandler,
    deleteHandler,
    closeHandler,
    compoundQuestion,
    expressions,
}: PieceCompoundQuestionModalProps) => {
    const { classes } = useStyles();

    const defaultValues = getDefaultValues(compoundQuestion, expressions);

    const {
        register,
        getValues,
        setValue,
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<SurveyCompoundQuestionFormInput>({
        // @ts-expect-error RHF V7 limitation #7895 will be fixed in V8 of react-hook-form
        resolver: yupResolver(PIECE_QUESTION_VALIDATION as any),
        defaultValues,
    });

    const { data, loading: loadingCompoundQuestions } = useCompoundQuestionsV2ForSurveyQuery({
        variables: {
            input: {
                orderBy: { field: 'name', order: OrderByDirectionEnum.Asc },
            },
        },
    });

    const compoundQuestions = data?.compoundQuestionsV2.results;

    const compoundQuestionId = watch('compoundQuestionId');

    const displayTypes = useMemo(
        () => getDisplayTypeByCompoundQuestionId(compoundQuestionId, compoundQuestions ?? []),
        [compoundQuestionId, compoundQuestions],
    );

    return (
        <Dialog open>
            <form noValidate onSubmit={handleSubmit(submitHandler)}>
                <DialogTitleWithClose id="modalTitle" onClose={closeHandler}>
                    {compoundQuestion ? 'Edit' : 'Add'} Compound Question
                </DialogTitleWithClose>
                <DialogContent dividers>
                    {loadingCompoundQuestions ? (
                        <Loading />
                    ) : (
                        <>
                            <ReactHookFormSelect
                                control={control}
                                defaultValue=""
                                name="compoundQuestionId"
                                variant="outlined"
                                label="Compound question *"
                                fullWidth
                                margin="dense"
                                error={!!errors.compoundQuestionId}
                                onChangeHandler={id => {
                                    const compoundQuestion = compoundQuestions?.find(
                                        i => i.id === id,
                                    );
                                    if (compoundQuestion) {
                                        setValue('label.en', compoundQuestion.label.en);
                                        setValue('label.es', compoundQuestion.label.es);
                                    }
                                    setValue('displayType', '' as SurveyDefQuestionDisplayType);
                                }}
                                loading={loadingCompoundQuestions}
                            >
                                {(compoundQuestions ?? []).map(({ id, label: { en } }) => (
                                    <MenuItem key={id} value={id}>
                                        {en}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                            {errors?.compoundQuestionId && (
                                <FormHelperText error className={classes.helperMessage}>
                                    {errors.compoundQuestionId?.message?.toString()}
                                </FormHelperText>
                            )}
                            <OutlinedSection title="Label">
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="English *"
                                    fullWidth
                                    margin="dense"
                                    {...register('label.en')}
                                    error={!!errors?.label?.en}
                                    helperText={errors.label?.en?.message}
                                    disabled={!compoundQuestionId}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Spanish"
                                    fullWidth
                                    margin="dense"
                                    {...register('label.es')}
                                    disabled={!compoundQuestionId}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </OutlinedSection>
                            <ReactHookFormSelect
                                control={control}
                                defaultValue=""
                                name="displayType"
                                variant="outlined"
                                label="Display as *"
                                fullWidth
                                margin="dense"
                                error={!!errors.displayType}
                                disabled={!compoundQuestionId}
                            >
                                {displayTypes.map(displayType => (
                                    <MenuItem key={displayType} value={displayType}>
                                        {labelsMap[displayType]}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                            {errors?.displayType && (
                                <FormHelperText error className={classes.helperMessage}>
                                    {errors.displayType?.message}
                                </FormHelperText>
                            )}
                            <Controller
                                name="answerRequired"
                                control={control}
                                defaultValue={defaultValues.answerRequired}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        label="Answer required?"
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                id="answerRequired"
                                                checked={value === true}
                                                onChange={onChange}
                                            />
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="hidePreviousAnswer"
                                control={control}
                                defaultValue={defaultValues.hidePreviousAnswer}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        label="Hide previous answer?"
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                id="hidePreviousAnswer"
                                                checked={value === true}
                                                onChange={onChange}
                                            />
                                        }
                                    />
                                )}
                            />

                            <ConfigExpressionPicker
                                inputList={getValues('expressions')}
                                itemAddedHandler={(id: string) =>
                                    setValue('expressions', getValues('expressions').concat(id))
                                }
                                itemDroppedHandler={(id: string) =>
                                    setValue(
                                        'expressions',
                                        getValues('expressions').filter(item => item !== id),
                                    )
                                }
                                useType={ExpressionUseType.Surveys}
                                canChange
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeHandler} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button type="submit" color="secondary" variant="contained">
                        {compoundQuestion ? 'Update ' : 'Add '}
                    </Button>{' '}
                    {compoundQuestion && (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                TriggerGlobalConfirm({
                                    message: 'Do you really want to delete this Compound Question?',
                                    callback: deleteHandler,
                                });
                            }}
                        >
                            Remove
                        </Button>
                    )}{' '}
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default PieceCompoundQuestionModal;
