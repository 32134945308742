export enum TimelineEnum {
    NAV_TIMELINES = 'timelines',
    CONTAINER = 'timelines-container',
    LABEL = 'timeline-label',
    DESCRIPTION = 'timeline-description',
    TYPE_DROPDOWN = 'timeline-type-dropdown',
    DATE = 'timeline-date',
    SAVE_AND_CLOSE = 'save-and-close-button',
}

export const TimelineSelectors = {
    NAV_TIMELINES: `a[data-test="${TimelineEnum.NAV_TIMELINES}"]`,
    ADD_TIMELINE_BUTTON: `div[data-test="${TimelineEnum.CONTAINER}"] button[title="Add Timeline"]`,
    LABEL: `div[data-test="${TimelineEnum.LABEL}"] input`,
    DESCRIPTION: `div[data-test="${TimelineEnum.DESCRIPTION}"] textarea`,
    TYPE_DROPDOWN: `div[data-test="${TimelineEnum.TYPE_DROPDOWN}"] div`,
    TYPE_DROPDOWN_FIXED_OPTION: `li[data-test="fixed"]`,
    DATE: `div[data-test="${TimelineEnum.DATE}"] input`,
    SAVE_AND_CLOSE_BUTTON: `button[data-test="${TimelineEnum.SAVE_AND_CLOSE}"]`,
    DELETE_TIMELINE_BUTTON: `div[data-test="${TimelineEnum.CONTAINER}"] button[title="Delete Timeline"]`,
    SEARCH_INPUT: `div[data-test="${TimelineEnum.CONTAINER}"] input[placeholder="Search"]`,
};
