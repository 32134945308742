import { FetchAdvocateTaskForAdvocateTaskPageQuery } from '~/schemaTypes';

type ActionType = NonNullable<
    FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']
>['actionTypes'][0];

const actionTypeLabelById = (actionTypeId: string, actionTypes: ActionType[]): string => {
    const [actionType] = actionTypes.filter(actionType => actionType.id === actionTypeId);

    return actionType?.label;
};

export default actionTypeLabelById;
