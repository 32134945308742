import { Dialog, DialogActions, DialogContent, DialogTitle, Skeleton } from '@mui/material';
import React from 'react';
import Loading from '../Loading/Loading';

type DialogLoaderProps = {
    height?: number | string;
    fullWidth?: boolean;
    subtitle?: string;
};

const DialogLoader: React.FC<DialogLoaderProps> = ({ height, fullWidth = true, subtitle }) => {
    return (
        <Dialog open fullWidth={fullWidth}>
            <DialogTitle>
                <Skeleton variant="text" width={150} />
            </DialogTitle>
            <DialogContent dividers>
                <Loading height={height} subtitle={subtitle} />
            </DialogContent>
            <DialogActions>
                <Skeleton variant="rectangular" width={100} height={40} />
                <Skeleton variant="rectangular" width={100} height={40} />
            </DialogActions>
        </Dialog>
    );
};

export default DialogLoader;
