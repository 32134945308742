import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardHeader, Grid, MenuItem, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowBack, Save } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import { useUserPermissions } from '~/hooks';
import {
    ArticleGroupInput,
    ArticleGroupListDocument,
    ArticleGroupListQuery,
    ArticleGroupUpdateInput,
    useArticleGroupListQuery,
    useCreateArticleGroupMutation,
    useFetchArticleGroupLazyQuery,
    usePreFetchAppsQuery,
    useUpdateArticleGroupMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { ClientSelectionType, ClientSelectionTypes } from '~/enums/enums';
import _ from 'lodash';

const useStyles = makeStyles()(theme => ({
    root: {},
    fab: {
        position: 'absolute',
        top: theme.spacing(12),
        right: theme.spacing(4),
    },
}));
const toClientSelectionType = ({
    excludeApps,
    includeApps,
}: ArticleGroupInput): ClientSelectionType => {
    if (includeApps && includeApps.length > 0) return ClientSelectionType.IncludeCertainApps;
    if (excludeApps && excludeApps.length > 0) return ClientSelectionType.ExcludeCertainApps;
    return ClientSelectionType.IncludeAllApps;
};
interface ArticleGroupFormInput {
    name: string;
    clientSelectionType: ClientSelectionType;
    includeApps?: string[];
    excludeApps?: string[];
}
const formValuesFromData = (instance: ArticleGroupInput): ArticleGroupFormInput => {
    return {
        name: instance.name,
        clientSelectionType: toClientSelectionType(instance),
        includeApps: instance.includeApps ?? [],
        excludeApps: instance.excludeApps ?? [],
    };
};

const formValuesDefault = (): ArticleGroupFormInput => {
    return {
        name: '',
        clientSelectionType: ClientSelectionType.IncludeAllApps,
        includeApps: [],
        excludeApps: [],
    };
};

const ArticleGroupValidation = Yup.object().shape({
    name: Yup.string().required('Required'),
    clientSelectionType: Yup.mixed().oneOf(ClientSelectionTypes).required(),
    includeApps: Yup.array()
        .of(Yup.string())
        .when('clientSelectionType', ([clientSelectionType], schema) => {
            return clientSelectionType === ClientSelectionType.IncludeCertainApps
                ? schema.min(1, 'Must select at least one app to include').required()
                : schema;
        }),
    excludeApps: Yup.array()
        .of(Yup.string())
        .when('clientSelectionType', ([clientSelectionType], schema) => {
            return clientSelectionType === ClientSelectionType.ExcludeCertainApps
                ? schema.min(1, 'Must select at least one app to exclude').required()
                : schema;
        }),
});
const toIncludeOrExclude = ({
    clientSelectionType,
    includeApps,
    excludeApps,
}: ArticleGroupFormInput) => {
    switch (clientSelectionType) {
        case ClientSelectionType.IncludeCertainApps:
            return {
                includeApps: includeApps ?? [],
                excludeApps: [],
            };
        case ClientSelectionType.ExcludeCertainApps:
            return {
                includeApps: [],
                excludeApps: excludeApps ?? [],
            };
        case ClientSelectionType.IncludeAllApps:
        default:
            return {
                includeApps: [],
                excludeApps: [],
            };
    }
};
export const formValuesToCreate = (form: ArticleGroupFormInput): ArticleGroupInput => {
    const { name } = form;

    return {
        name,
        ...toIncludeOrExclude(form),
    };
};

export const formValuesToUpdate = (form: ArticleGroupFormInput): ArticleGroupUpdateInput => {
    const { clientSelectionType, includeApps, excludeApps, ...rest } = form;

    return {
        ...rest,
        ...toIncludeOrExclude(form),
    };
};
const ArticleGroupEditor: React.FC = () => {
    const history = useNavigate();
    const { classes } = useStyles();
    const { id: urlId } = useParams<{ id: string }>();
    const [isModified, setIsModified] = useState(false);
    const [close, setClose] = useState(false);
    const { pagePermissions } = useUserPermissions();
    const canEdit = pagePermissions?.ArticleGroup.Edit || false;
    const isEditMode = urlId !== 'new';
    const [groupId] = useState(isEditMode && urlId ? urlId : null);
    let title = isEditMode ? 'Edit Article Group' : 'Create Article Group';
    if (!canEdit) title = 'Article Group Details';
    const { data: preFetchData, loading: preFetchLoading } = usePreFetchAppsQuery();

    const [fetchArticleGroupById, { data: articleGroupData, loading: articleGroupLoading }] =
        useFetchArticleGroupLazyQuery();

    const { data: articleGroupList, loading: articleGroupListLoading } = useArticleGroupListQuery({
        variables: { articleGroupListInput: {} },
    });

    const [updateArticleGroup, { loading: updateArticleGroupLoading }] =
        useUpdateArticleGroupMutation({
            onCompleted: () => {
                if (close) history(`/app-config/articlegroups`);
            },
            update: (cache, response) => {
                const updatedGroup = response.data?.updateArticleGroup?.resourceUpdated;
                if (response.data?.updateArticleGroup?.success && updatedGroup) {
                    const currentGroups = cache.readQuery<ArticleGroupListQuery>({
                        query: ArticleGroupListDocument,
                    });
                    if (currentGroups?.articleGroups) {
                        cache.writeQuery<ArticleGroupListQuery>({
                            query: ArticleGroupListDocument,
                            data: {
                                articleGroups: [
                                    ...currentGroups.articleGroups.map(g => {
                                        if (g.id === updatedGroup.id) {
                                            return updatedGroup;
                                        }
                                        return g;
                                    }),
                                ],
                            },
                        });
                    }
                }
            },
        });

    const [createArticleGroup, { loading: createArticleGroupLoading }] =
        useCreateArticleGroupMutation({
            onCompleted: ({ createArticleGroup }) => {
                if (close) {
                    history(`/app-config/articlegroups`);
                } else {
                    history(`/app-config/articlegroups/${createArticleGroup?.resourceCreated?.id}`);
                }
            },
            update: (cache, response) => {
                const newGroup = response.data?.createArticleGroup?.resourceCreated;
                if (response.data?.createArticleGroup?.success && newGroup) {
                    const currentGroups = cache.readQuery<ArticleGroupListQuery>({
                        query: ArticleGroupListDocument,
                        variables: { articleGroupListInput: {} },
                    });
                    if (currentGroups?.articleGroups) {
                        cache.writeQuery<ArticleGroupListQuery>({
                            query: ArticleGroupListDocument,
                            variables: { articleGroupListInput: {} },
                            data: {
                                articleGroups: [...currentGroups.articleGroups, newGroup],
                            },
                        });
                    }
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: ArticleGroupListDocument,
                },
            ],
        });

    const {
        control,
        register,
        reset,
        handleSubmit,
        watch,
        setError,

        formState: { errors },
    } = useForm<ArticleGroupFormInput>({
        resolver: yupResolver(ArticleGroupValidation as any),
        defaultValues: formValuesDefault(),
    });

    useEffect(() => {
        if (isEditMode) {
            fetchArticleGroupById({ variables: { articleGroupInput: { id: groupId } } });
            if (articleGroupData && articleGroupData.articleGroup) {
                reset(formValuesFromData(articleGroupData.articleGroup));
            }
        }
    }, [fetchArticleGroupById, isEditMode, groupId, articleGroupData, reset]);

    const clientSelectionType = watch('clientSelectionType');

    const onSubmit = (values: any) => {
        if (
            articleGroupList &&
            ((isEditMode &&
                articleGroupList.articleGroups.filter(
                    g => g.id !== groupId && g.name.toLowerCase() === values.name.toLowerCase(),
                ).length > 0) ||
                (!isEditMode &&
                    articleGroupList.articleGroups.filter(
                        g => g.name.toLowerCase() === values.name.toLowerCase(),
                    ).length > 0))
        ) {
            setError('name', { message: 'Article Group Name is already used, please change.' });
            return;
        }
        if (isEditMode && groupId) {
            updateArticleGroup({
                variables: {
                    updateArticleGroupInput: {
                        id: groupId,
                        data: formValuesToUpdate(values),
                    },
                },
            });
        } else {
            createArticleGroup({
                variables: {
                    createArticleGroupInput: formValuesToCreate(values),
                },
            });
        }
        setIsModified(false);
    };
    const onNavigateAway = () => {
        if (isModified)
            TriggerGlobalConfirm({
                message: `You have unsaved changes. Are you sure you want to return to the Article Group List?`,
                callback: () => {
                    history(`/app-config/articlegroups/`);
                },
            });
        else history(`/app-config/articlegroups/`);
    };
    const onEdit = () => {
        setIsModified(true);
    };
    if (
        articleGroupLoading ||
        createArticleGroupLoading ||
        updateArticleGroupLoading ||
        preFetchLoading ||
        !preFetchData ||
        articleGroupListLoading
    )
        return <Loading />;
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                    Back to Article Group List
                </Button>
            </Grid>
            <Grid item xs={12}>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        <CardHeader title={title} />
                        <OutlinedSection title="Name *">
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                onChange={onEdit}
                                disabled={!canEdit}
                            />
                        </OutlinedSection>
                        <OutlinedSection title="Apps">
                            <ReactHookFormSelect
                                control={control}
                                name="clientSelectionType"
                                variant="outlined"
                                defaultValue=""
                                label="Selection type *"
                                fullWidth
                                margin="dense"
                                disabled={!canEdit}
                            >
                                {ClientSelectionTypes.map(m => (
                                    <MenuItem key={m} value={m}>
                                        {m}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                            {(clientSelectionType as unknown) ===
                                ClientSelectionType.IncludeCertainApps && (
                                <AutocompleteWithRecordOptions
                                    options={
                                        _.sortBy(
                                            preFetchData?.applicationsV2.results,
                                            'appBundleId',
                                        ) ?? []
                                    }
                                    valueKey="appBundleId"
                                    labelKey="appBundleId"
                                    control={control}
                                    name="includeApps"
                                    label="Include apps *"
                                    placeholder="Select apps to include..."
                                    required
                                    disabled={!canEdit}
                                    error={Boolean(errors.includeApps)}
                                    helperText={
                                        (errors.includeApps as FieldError | undefined)?.message
                                    }
                                />
                            )}
                            {(clientSelectionType as unknown) ===
                                ClientSelectionType.ExcludeCertainApps && (
                                <AutocompleteWithRecordOptions
                                    options={
                                        _.sortBy(
                                            preFetchData?.applicationsV2.results,
                                            'appBundleId',
                                        ) ?? []
                                    }
                                    valueKey="appBundleId"
                                    labelKey="appBundleId"
                                    control={control}
                                    name="excludeApps"
                                    label="Exclude apps *"
                                    placeholder="Select apps to exclude..."
                                    required
                                    disabled={!canEdit}
                                    error={Boolean(errors.excludeApps)}
                                    helperText={
                                        (errors.excludeApps as FieldError | undefined)?.message
                                    }
                                />
                            )}
                        </OutlinedSection>
                        {canEdit && (
                            <div style={{ width: '100%', textAlign: 'right' }}>
                                <Button
                                    type="submit"
                                    startIcon={<Save />}
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => handleSubmit(onSubmit)}
                                >
                                    Save
                                </Button>
                                <Button
                                    type="submit"
                                    startIcon={<Save />}
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        setClose(true);
                                        handleSubmit(onSubmit);
                                    }}
                                >
                                    Save &amp; Close
                                </Button>
                            </div>
                        )}
                    </Card>
                </form>
            </Grid>
        </Grid>
    );
};

export default ArticleGroupEditor;
