import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    logo: {
        width: '250px',
    },
    container: {
        padding: '50px',
        textAlign: 'center',
    },
    headingTitle: {
        padding: '15px 0',
    },
    uploadForm: {
        paddingTop: '15px',
        textAlign: 'center',
    },
    affiliateBox: {
        border: '1px solid grey',
    },
    inputFile: {
        width: 0,
        height: 0,
    },
    dialogContent: {
        minHeight: '300px',
        maxHeight: '300px',
        overflowY: 'scroll',
        padding: '20px 0',
        border: '1px solid grey',
    },
    errorList: {
        paddingBottom: '15px',
    },
    dialogButtons: {
        justifyContent: 'center',
    },
    contactBlock: {
        paddingTop: '20px',
    },
}));
