import ObjectId from 'bson-objectid';
import {
    AdvocateTaskTemplateFormInput,
    AdvocateTaskTemplatesResults,
    CheckList,
    FormCheckList,
} from '../types';

const toFormChecklist = (checklist: CheckList[]): FormCheckList[] => {
    return checklist.map(checklistItem => ({
        id: new ObjectId(checklistItem.id),
        label: checklistItem.label ?? '',
        description: checklistItem.description ?? '',
        completedAt: checklistItem.completedAt
            ? new Date(checklistItem.completedAt).toDateString()
            : null,
        completedBy: checklistItem?.completedBy
            ? new ObjectId(checklistItem.completedBy).toHexString()
            : null,
        actionTypeIds: checklistItem?.actionTypeIds,
    }));
};

export const toFormValuesFromAdvocateTaskTemplates = (
    advocateTaskTemplate: AdvocateTaskTemplatesResults,
): AdvocateTaskTemplateFormInput => {
    return {
        label: advocateTaskTemplate?.label ?? '',
        description: advocateTaskTemplate?.description ?? '',
        priority: advocateTaskTemplate.priority ?? null,
        checklist: advocateTaskTemplate.checklist
            ? toFormChecklist(advocateTaskTemplate.checklist)
            : [],
        typeId: advocateTaskTemplate?.typeId
            ? new ObjectId(advocateTaskTemplate?.typeId).toHexString()
            : new ObjectId().toHexString(),
        whenType: advocateTaskTemplate?.whenType ?? '',
        whenTimeline: advocateTaskTemplate.whenTimeline
            ? {
                  timelineId: advocateTaskTemplate?.whenTimeline?.timelineId ?? '',
                  offsetDays: advocateTaskTemplate.whenTimeline?.offsetDays ?? 0,
                  offsetMonths: advocateTaskTemplate.whenTimeline?.offsetMonths ?? 0,
                  offsetYears: advocateTaskTemplate.whenTimeline?.offsetYears ?? 0,
                  durationMinutes: advocateTaskTemplate.whenTimeline?.durationMinutes ?? 0,
                  tags: advocateTaskTemplate.whenTimeline?.tags ?? [],
              }
            : null,
    };
};
