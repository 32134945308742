import { format } from 'date-fns-tz';
import { ClaimSubmittedVisitType } from '~/schemaTypes';
import { Claim } from './types';

const generateGeneralPayload = (claim: Claim) => {
    return {
        firstName: claim.firstName,
        lastName: claim.lastName,
        dateOfService: format(claim.dateOfService, 'MM/dd/yyyy'),
        birthDate: claim.birthDate,
        patientId: claim.patientId,
        serviceType: claim.serviceType as ClaimSubmittedVisitType,
    };
};

export const getAffiliateClaimsPayload = (claims: Claim[]) => {
    return claims.map(claim => ({
        ...generateGeneralPayload(claim),
    }));
};

export const getDoulaClaimsPayload = (claims: Claim[]) => {
    return claims.map(claim => ({
        ...generateGeneralPayload(claim),
        ...(claim.duration && { duration: claim.duration }),
        ...(claim.startTime && { startTime: claim.startTime }),
        ...(claim.endTime && { endTime: claim.endTime }),
        ...(claim.consultType && { consult_type: claim.consultType }),
        ...(claim.birthType && { birthType: claim.birthType }),
        ...(claim.visitReason && { visitReason: claim.visitReason }),
        ...(claim.admissionFrom && {
            admissionFrom: format(claim.admissionFrom, 'MM/dd/yyyy'),
        }),
        ...(claim.admissionTo && {
            admissionTo: format(claim.admissionTo, 'MM/dd/yyyy'),
        }),
    }));
};
