import React, { PropsWithChildren } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { IVCAffiliatesFormValues } from '~/views/ConfigDashboard/VCAffiliates/interfaces/IVCAffiliatesFormValues';
import { yupResolver } from '@hookform/resolvers/yup';
import { VALIDATION_SCHEMA } from '~/views/ConfigDashboard/VCAffiliates/components/yupSchema';
import { Checkbox, FormControlLabel, Grid, TextField, FormGroup } from '@mui/material';
import { useVCAffiliateFormContext } from '~/views/ConfigDashboard/VCAffiliates/components/VCAffiliateFormContextProvider';
import {
    CreateVirtualCareAffiliatesInput,
    VirtualCareAffiliatesUpdateInput,
    OnSuccessfulZipCodeMatch,
    OnSuccessfulEligibility,
} from '~/schemaTypes';
import { NotificationsForm } from '~/views/ConfigDashboard/VCAffiliates/components/NotificationsForm';
import { mapFromFormToInput } from '../mappers';
import BillCom from './BillCom';
import LactationConsultants from './LactationConsultants';
import W9Section from './W9';
import ServiceTypesCoverages from './ServiceTypesCoverages';
import RoutingCustomization from './RoutingCustomization';

interface IVCAffiliateForm {
    values?: IVCAffiliatesFormValues;
    isEdit?: boolean;
    handleFormSubmit: (
        data: VirtualCareAffiliatesUpdateInput | CreateVirtualCareAffiliatesInput,
    ) => void;
}
export const VCAffiliateForm: React.FC<PropsWithChildren<IVCAffiliateForm>> = props => {
    const { values, isEdit, handleFormSubmit, children } = props;

    const methods = useForm<IVCAffiliatesFormValues>({
        resolver: yupResolver(VALIDATION_SCHEMA(Boolean(isEdit))),
        values,
        defaultValues: {
            internalName: '',
            externalName: '',
            contactName: '',
            contactEmail: '',
            contactPhoneNumber: '',
            onSuccessfulEligibility: OnSuccessfulEligibility.RouteToLanding,
            routingURL: '',
            phoneNumberForScheduling: '',
            emailAddressForScheduling: '',
            customSuccessText: '',
            notificationEmail: '',
            notifyCompanyWhenPatientPassesEligibility: true,
            onSuccessfulZipCodeMatch: OnSuccessfulZipCodeMatch.ToSuccessScreen,
            BillDotComNameOnCheck: '',
            BillDotComAddress1: '',
            BillDotComAddress2: '',
            BillDotComAddressCity: '',
            BillDotComAddressState: '',
            BillDotComAddressZip: '',
            BillDotComVendorId: '',
            website: '',
            inviteAffiliate: true,
            lactationConsultants: [],
            officeAddresses: [],
            coverageAreas: [],
            isInOfficeType: false,
            isVirtualType: false,
            isPatientsHomeType: false,
            supportPhone: '',
            supportEmail: '',
        },
    });
    const { isValid } = useVCAffiliateFormContext();
    const { handleSubmit, control } = methods;

    const onSubmit: SubmitHandler<IVCAffiliatesFormValues> = data => {
        if (isValid) {
            handleFormSubmit(mapFromFormToInput(data));
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container direction="column">
                    <Grid item>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                control={control}
                                name="internalName"
                                render={({ field, formState: { errors } }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        autoFocus={!isEdit}
                                        label="Internal Name"
                                        error={!!errors.internalName}
                                        helperText={
                                            errors.internalName && 'Internal Name is required'
                                        }
                                    />
                                )}
                                rules={{ required: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                control={control}
                                name="externalName"
                                render={({ field, formState: { errors } }) => (
                                    <TextField
                                        fullWidth
                                        {...field}
                                        label="External Name"
                                        error={!!errors.externalName}
                                        helperText={
                                            errors.externalName && 'External Name is required'
                                        }
                                    />
                                )}
                                rules={{ required: true }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Controller
                                control={control}
                                name="contactName"
                                render={({ field, formState: { errors } }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label="Contact Name"
                                        error={!!errors.contactName}
                                        helperText={
                                            errors.contactName && 'Contact Name is required'
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                control={control}
                                name="contactEmail"
                                render={({ field, formState: { errors } }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label="Contact Email"
                                        error={!!errors.contactEmail}
                                        helperText={
                                            errors.contactEmail &&
                                            'Please, enter valid contact email'
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                control={control}
                                name="contactPhoneNumber"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label="Contact Phone Number (optional)"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                control={control}
                                name="website"
                                render={({ field }) => (
                                    <TextField {...field} fullWidth label="Website (optional)" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                control={control}
                                name="supportEmail"
                                render={({ field, formState: { errors } }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label="Support Email"
                                        error={!!errors.supportEmail}
                                        helperText={
                                            errors.supportEmail && 'Support email is required'
                                        }
                                    />
                                )}
                                rules={{ required: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                control={control}
                                name="supportPhone"
                                render={({ field, formState: { errors } }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label="Support Phone"
                                        error={!!errors.supportPhone}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <NotificationsForm />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <BillCom
                                isEdit={Boolean(isEdit)}
                                vendorId={values?.BillDotComVendorId}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <W9Section />
                        </Grid>
                        <Grid item xs={12} mt={3}>
                            <LactationConsultants />
                        </Grid>
                        <Grid item xs={12} mt={3}>
                            <ServiceTypesCoverages />
                        </Grid>
                        <Grid item xs={12} mt={3}>
                            <RoutingCustomization />
                        </Grid>
                        {!isEdit && (
                            <Grid item xs={12} sm={6}>
                                <FormGroup style={{ paddingLeft: 12 }}>
                                    <Controller
                                        control={control}
                                        name="inviteAffiliate"
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox {...field} checked={field.value} />
                                                }
                                                label="Invite Affiliate To the AffiliateCare Portal"
                                            />
                                        )}
                                    />
                                </FormGroup>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            {children}
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};
