import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import React, { useEffect, useState } from 'react';
import { useVCAffiliateFormContext } from '~/views/ConfigDashboard/VCAffiliates/components/VCAffiliateFormContextProvider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    notificationsGroup: {
        paddingLeft: '10px',
    },
}));

export const NotificationsForm = () => {
    const { classes } = useStyles();
    const { setIsValid } = useVCAffiliateFormContext();
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const notificationEmailWatch = useWatch({
        name: 'notificationEmail',
        control,
        exact: true,
    });
    const notifyCompanyWhenPatientPassesEligibilityWatch = useWatch({
        name: 'notifyCompanyWhenPatientPassesEligibility',
        control,
        exact: true,
    });

    const [notificationEmailError, setNotificationEmailError] = useState(false);

    useEffect(() => {
        if (notifyCompanyWhenPatientPassesEligibilityWatch) {
            setIsValid(!!notificationEmailWatch);
            setNotificationEmailError(!notificationEmailWatch);
        } else {
            setIsValid(true);
            setNotificationEmailError(false);
        }
    }, [setIsValid, notificationEmailWatch, notifyCompanyWhenPatientPassesEligibilityWatch]);

    return (
        <OutlinedSection title="Notifications">
            <Controller
                control={control}
                name="notificationEmail"
                render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        label="Notification Emails (comma separated)"
                        error={!!errors.notificationEmail || notificationEmailError}
                        helperText={
                            (!!errors.notificationEmail || notificationEmailError) &&
                            'Please, enter notification emails'
                        }
                    />
                )}
            />
            <FormGroup className={classes.notificationsGroup}>
                <Controller
                    control={control}
                    name="notifyCompanyWhenPatientPassesEligibility"
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} checked={field.value} />}
                            label="Notify company when patient passes eligibility"
                        />
                    )}
                />
            </FormGroup>
        </OutlinedSection>
    );
};
