import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dialog, Grid, Typography } from '@mui/material';
import MaterialTable from '@material-table/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
    AlertSeverity,
    OrderByDirectionEnum,
    useDeleteVirtualCareEligibilityWhitelistMutation,
    useVirtualCareEligibilityWhitelistsV2LazyQuery,
    VirtualCareEligibilityWhitelist as VirtualCareEligibilityWhitelistType,
    VirtualCareEligibilityWhitelistsV2Document,
    VirtualCareEligibilityWhitelistsV2Query,
} from '~/schemaTypes';
import { TriggerGlobalAlert, TriggerGlobalConfirm, SuppressNextGlobalAlert } from '~/state';
import { useUserPermissions } from '~/hooks';
import { VCEligibilityEditorModal } from './VCEligibilityEditorModal';

const GET_VC_ELIGIBILITY_WHITELIST_PAYLOAD = {
    input: {
        orderBy: {
            field: 'updatedAt',
            order: OrderByDirectionEnum.Desc,
        },
    },
};

export const VCEligibilityWhitelistList = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const { pagePermissions } = useUserPermissions();
    const [editEntityId, setEditEntityId] = useState<string>('');
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [getAllQuery, { data: tableData, loading: fetchLoading }] =
        useVirtualCareEligibilityWhitelistsV2LazyQuery({
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
        });
    useEffect(() => {
        getAllQuery({
            variables: GET_VC_ELIGIBILITY_WHITELIST_PAYLOAD,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [deleteEntity, { loading: deleteLoading }] =
        useDeleteVirtualCareEligibilityWhitelistMutation({
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: VirtualCareEligibilityWhitelistsV2Document,
                    variables: GET_VC_ELIGIBILITY_WHITELIST_PAYLOAD,
                },
            ],
            onCompleted: data => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: `${data.deleteVirtualCareEligibilityWhitelist?.id} Virtual Care Eligibility Whitelist entry was deleted`,
                });
            },
        });
    const editEntityCallback = useCallback(
        (saved: boolean) => {
            setEditEntityId('');
            if (saved) {
                getAllQuery({ variables: GET_VC_ELIGIBILITY_WHITELIST_PAYLOAD });
                if (tableRef.current) {
                    tableRef.current.scrollIntoView();
                }
            }
            setShowEditModal(false);
        },
        [getAllQuery],
    );
    const addNewItem = useCallback(() => {
        setShowEditModal(true);
    }, []);
    const editItem = useCallback((_: any, { id }: VirtualCareEligibilityWhitelistType) => {
        setEditEntityId(id);
        setShowEditModal(true);
    }, []);
    const deleteItem = useCallback(
        (_: any, { id, lastName, firstName }: VirtualCareEligibilityWhitelistType) => {
            TriggerGlobalConfirm({
                message: `Do you want to delete ${lastName} ${firstName} from whitelist`,
                callback: () => {
                    SuppressNextGlobalAlert(true);
                    deleteEntity({
                        variables: {
                            input: {
                                id,
                            },
                        },
                    });
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    return (
        <div style={{ marginRight: '1rem' }} ref={tableRef}>
            <Grid>
                <MaterialTable<
                    VirtualCareEligibilityWhitelistsV2Query['virtualCareEligibilityWhitelistsV2']['results'][0]
                >
                    title="Virtual Care Eligibility Whitelist"
                    actions={[
                        {
                            icon: () => <FontAwesomeIcon icon={faPlus} />,
                            isFreeAction: true,
                            onClick: addNewItem,
                            tooltip: 'Create New Entry',
                            hidden: !pagePermissions?.VirtualCareEligibilityWhitelist.Edit,
                        },
                        {
                            onClick: editItem,
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit',
                            hidden: !pagePermissions?.VirtualCareEligibilityWhitelist.Edit,
                        },
                        {
                            onClick: deleteItem,
                            icon: () => <FontAwesomeIcon icon={faTrash} />,
                            tooltip: 'Delete',
                            hidden: !pagePermissions?.VirtualCareEligibilityWhitelist.Delete,
                        },
                    ]}
                    columns={[
                        {
                            title: 'First Name',
                            field: 'firstName',
                            render: ({ firstName }) => (
                                <Typography data-test={firstName}>{firstName}</Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Last Name',
                            field: 'lastName',
                            render: ({ lastName }) => (
                                <Typography data-test={lastName}>{lastName}</Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Member Id',
                            field: 'memberId',
                            render: ({ memberId }) => (
                                <Typography data-test={memberId}>{memberId}</Typography>
                            ),
                            sorting: false,
                        },
                    ]}
                    data={
                        tableData?.virtualCareEligibilityWhitelistsV2.results.map((o: any) => ({
                            ...o,
                        })) || []
                    }
                    options={{
                        search: false,
                        paging: false,
                    }}
                    localization={{ header: { actions: '' } }}
                    isLoading={fetchLoading || deleteLoading}
                />
            </Grid>
            {showEditModal && (
                <Dialog open={showEditModal} scroll="paper">
                    <VCEligibilityEditorModal id={editEntityId} onComplete={editEntityCallback} />
                </Dialog>
            )}
        </div>
    );
};
