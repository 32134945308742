import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import useStyles from '../styles';

type AffiliateThankProps = {
    backHandler: () => void;
    patientId: string;
};

const AffiliateThank: React.FC<AffiliateThankProps> = props => {
    const { backHandler, patientId } = props;
    const { classes } = useStyles();
    return (
        <Grid container flexDirection="column">
            <Grid item>
                <Typography className={classes.greenBox}>
                    Patient is Eligible. Confirmation Id: {patientId}
                </Typography>
            </Grid>
            <Grid item>
                <Typography className={classes.thankContainer}>
                    Thank you for the submission.{' '}
                    <Button onClick={backHandler}>Submit Another</Button>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default AffiliateThank;
