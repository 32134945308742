import React from 'react';
import { format } from 'date-fns-tz';
import { Typography, Grid } from '@mui/material';
import MaterialTable from '@material-table/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ServiceType from '~/views/AffiliateCare/components/ServiceType/ServiceType';
import { Claim } from './types';

type ClaimsToSubmitProps = {
    claims: Claim[];
    handleDelete: (id: number) => void;
};

const ClaimsToSubmit: React.FC<ClaimsToSubmitProps> = props => {
    const { claims, handleDelete } = props;
    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <Typography>Visits To Submit ({claims.length})</Typography>
            </Grid>
            <Grid item xs={12}>
                <MaterialTable
                    columns={[
                        {
                            title: 'Name',
                            render: ({ firstName, lastName }) => (
                                <Typography>
                                    {firstName} {lastName}
                                </Typography>
                            ),
                            field: 'name',
                        },
                        {
                            title: 'Date Of Service',
                            render: ({ dateOfService }) => (
                                <Typography>{format(dateOfService, 'MM/dd/yyyy')}</Typography>
                            ),
                            field: 'dateOfService',
                        },
                        {
                            title: 'Service Type',
                            render: ({ serviceType }) => <ServiceType type={serviceType} />,
                            field: 'serviceType',
                        },
                    ]}
                    options={{
                        sorting: false,
                        search: false,
                        paging: false,
                        showTitle: false,
                        showEmptyDataSourceMessage: false,
                        toolbar: false,
                        actionsColumnIndex: -1,
                    }}
                    data={claims}
                    actions={[
                        {
                            onClick: (_, { id }: any) => handleDelete(id),
                            icon: () => <FontAwesomeIcon icon={faTrash} />,
                            tooltip: 'Delete Visit',
                        },
                    ]}
                    localization={{
                        header: {
                            actions: '',
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default ClaimsToSubmit;
