import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useEligibilityMatchStatsQuery } from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import Header from './components/Header';

const EligibilityMatch: React.FC = () => {
    const { data, loading } = useEligibilityMatchStatsQuery({
        fetchPolicy: 'network-only',
    });
    if (loading) {
        return <Loading />;
    }
    return (
        <Grid container rowSpacing={4}>
            <Header />
            <Grid container item>
                <Grid item xs={12}>
                    <Link to="/app-config/eligibility-match/queue">
                        Match Queue ({data?.eligibilityMatchStats?.inQueue})
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/app-config/eligibility-match/matches">
                        Matches ({data?.eligibilityMatchStats?.matches})
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/app-config/eligibility-match/nomatches">
                        No Matches ({data?.eligibilityMatchStats?.noMatches})
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Link to="/app-config/eligibility-match/accessocde">Eligibility Data</Link>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EligibilityMatch;
