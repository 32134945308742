import { toBase64 } from '~/helpers/base64Helper';
import {
    AdvocateTaskHistoryItemInput,
    FetchAdvocateTaskForAdvocateTaskPageQuery,
} from '~/schemaTypes';

type History = NonNullable<FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']>['history'][0];

type ToAdvocateTaskHistoryInput = (history: History[]) => AdvocateTaskHistoryItemInput[];

const toAdvocateTaskHistoryInput: ToAdvocateTaskHistoryInput = history =>
    history.map(({ id, whoId, what, when }) => ({
        id,
        whoId,
        what,
        when,
    }));

export const toAdvocateTaskHistoryInputEncoded: ToAdvocateTaskHistoryInput = history =>
    history.map(({ id, whoId, what, when }) => ({
        id,
        whoId,
        what: toBase64(what) || '',
        when,
    }));

export default toAdvocateTaskHistoryInput;
