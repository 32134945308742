import { makeStyles } from 'tss-react/mui';

export default makeStyles()(theme => ({
    root: {
        padding: theme.spacing(2),
    },
    actions: {
        marginTop: theme.spacing(2),
    },
}));
