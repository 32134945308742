import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    root: {},
    titleDivider: {
        marginBottom: '20px',
    },
    link: {
        fontSize: '.8rem',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        textDecoration: 'underline',
        display: 'inline',
        margin: 0,
        padding: 0,
        color: 'blue',
        '&:hover': {
            cursor: 'pointer',
            filter: 'brightness(50%)',
        },
    },
    addButtonWrapper: {
        marginLeft: 5,
    },
    addButton: {
        color: 'rgba(0, 0, 0, 0.87)',
    },
    patientAction: {
        marginLeft: 10,
        marginBottom: 10,
    },
    patientActionType: {
        fontWeight: 'bolder',
    },
    date: {
        fontWeight: 'lighter',
    },
    edit: {
        marginLeft: 10,
    },
    summary: {
        fontWeight: 'bold',
    },
});
