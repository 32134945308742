import { Button, Card, CardHeader, Grid, TextField } from '@mui/material';
import { ArrowBack, Save } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { useUserPermissions } from '~/hooks';
import {
    MessageTemplateInput,
    MessageTemplatesListDocument,
    MessageTemplatesListQuery,
    useCreateMessageTemplateMutation,
    useTemplateByIdLazyQuery,
    useUpdateMessageTemplateMutation,
    MessageTemplatesListQueryVariables,
    LocalizedStringLoose,
    MessageSubstitutionItem,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { toBase64 } from '~/helpers/base64Helper';
import { PAGESIZE } from '~/constants';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStyles } from './styles';
import RichTextEditor, {
    RichTextEditorCommands,
} from '../../../components/RichTextEditor/RichTextEditor';

interface FormInput {
    name: string;
    emailTitle: LocalizedStringLoose;
    emailBody: LocalizedStringLoose;
    textBody: LocalizedStringLoose;
}

const formValuesDefault = (): FormInput => {
    return {
        name: '',
        emailTitle: { en: '', es: '' },
        emailBody: { en: '', es: '' },
        textBody: { en: '', es: '' },
    };
};

const MESSAGE_TEMPLATE_VALIDATION_SCHEMA: Yup.ObjectSchema<FormInput> = Yup.object({
    name: Yup.string()
        .required('Name is required')
        .notOneOf([''], 'Name cannot be empty')
        .matches(/^(?!\s*$).+$/, 'Name cannot consist only of spaces'),
    emailTitle: Yup.object().shape({
        en: Yup.string(),
    }),
    emailBody: Yup.object().shape({
        en: Yup.string(),
    }),
    textBody: Yup.object().shape({
        en: Yup.string(),
    }),
});

export const MessageTemplateEditor: React.FC = () => {
    const history = useNavigate();
    const { classes } = useStyles();
    const { id: emailTemplateId } = useParams<{ id: string }>();
    let isEditMode = emailTemplateId !== 'new';
    const { pagePermissions } = useUserPermissions();
    let title = isEditMode ? 'Edit Message Template' : 'Create Message Template';

    const canEdit = !!pagePermissions?.EmailTemplates.Edit;
    if (!canEdit) title = 'Message Template Details';
    const [isModified, setIsModified] = useState<boolean>(false);
    const [close, setClose] = useState<boolean>(false);
    const [emailBodyEn, setEmailBodyEn] = useState<string>();
    const [emailBodyEs, setEmailBodyEs] = useState<string>();
    const [textBodyEn, setTextBodyEn] = useState<string>();
    const [textBodyEs, setTextBodyEs] = useState<string>();
    const [emailEditorCommandsEn, setEmailEditorCommandsEn] =
        useState<RichTextEditorCommands | null>(null);
    const [emailEditorCommandsEs, setEmailEditorCommandsEs] =
        useState<RichTextEditorCommands | null>(null);
    const [textEditorCommandsEn, setTextEditorCommandsEn] = useState<RichTextEditorCommands | null>(
        null,
    );
    const [textEditorCommandsEs, setTextEditorCommandsEs] = useState<RichTextEditorCommands | null>(
        null,
    );
    const [substitutions, setSubstitutions] = useState<MessageSubstitutionItem[]>([]);

    const templatesListQueryVariables: MessageTemplatesListQueryVariables = {
        input: {
            pagination: {
                skip: 0,
                limit: PAGESIZE,
            },
            filter: null,
        },
    };

    const formValuesFromData = (instance: MessageTemplateInput): FormInput => {
        return {
            name: instance.name,
            emailTitle: { en: instance.emailTitle?.en, es: instance.emailTitle?.es },
            emailBody: { en: instance.emailBody?.en, es: instance.emailBody?.es },
            textBody: { en: instance.textBody?.en, es: instance.textBody?.es },
        };
    };

    const {
        register,
        reset,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<FormInput>({
        defaultValues: formValuesDefault(),
        resolver: yupResolver<FormInput>(MESSAGE_TEMPLATE_VALIDATION_SCHEMA),
    });
    const [fetchTemplateById, { data: currentTemplate, loading: templateLoading }] =
        useTemplateByIdLazyQuery({
            onCompleted: data => {
                if (data.messageTemplate) {
                    reset(formValuesFromData(data.messageTemplate));
                    setEmailBodyEn(data.messageTemplate.emailBody?.en || '');
                    setEmailBodyEs(data.messageTemplate.emailBody?.es || '');
                    emailEditorCommandsEn?.commands.setContent(
                        data.messageTemplate.emailBody?.en || '',
                    );
                    emailEditorCommandsEs?.commands.setContent(
                        data.messageTemplate.emailBody?.es || '',
                    );
                    if (emailEditorCommandsEn?.commands.setSubstitutions) {
                        emailEditorCommandsEn?.commands.setSubstitutions(
                            data.messageTemplate.substitutions || [],
                        );
                    }
                    if (emailEditorCommandsEs?.commands.setSubstitutions) {
                        emailEditorCommandsEs?.commands.setSubstitutions(
                            data.messageTemplate.substitutions || [],
                        );
                    }
                    setSubstitutions(data.messageTemplate.substitutions || []);
                    setTextBodyEn(data.messageTemplate.textBody?.en || '');
                    setTextBodyEs(data.messageTemplate.textBody?.es || '');
                    textEditorCommandsEn?.commands.setContent(
                        data.messageTemplate.textBody?.en || '',
                    );
                    textEditorCommandsEs?.commands.setContent(
                        data.messageTemplate.textBody?.es || '',
                    );
                    if (textEditorCommandsEn?.commands.setSubstitutions) {
                        textEditorCommandsEn?.commands.setSubstitutions(
                            data.messageTemplate.substitutions || [],
                        );
                    }
                    if (textEditorCommandsEs?.commands.setSubstitutions) {
                        textEditorCommandsEs?.commands.setSubstitutions(
                            data.messageTemplate.substitutions || [],
                        );
                    }
                }
            },
        });

    const [updateTemplate, { loading: updateTemplateLoading }] = useUpdateMessageTemplateMutation({
        onCompleted: () => {
            setIsModified(false);
            if (close) history(`/app-config/message-templates`);
        },
        update: (cache, response) => {
            const updatedTemplate = response.data?.updateMessageTemplate?.resourceUpdated;
            if (response.data?.updateMessageTemplate?.success && updatedTemplate) {
                const currentTemplates = cache.readQuery<MessageTemplatesListQuery>({
                    query: MessageTemplatesListDocument,
                    variables: templatesListQueryVariables,
                });
                if (currentTemplates?.messageTemplatesV2.results) {
                    cache.writeQuery<MessageTemplatesListQuery>({
                        query: MessageTemplatesListDocument,
                        data: {
                            messageTemplatesV2: {
                                ...currentTemplates.messageTemplatesV2,
                                results: [
                                    ...currentTemplates.messageTemplatesV2.results.map(t => {
                                        if (t.id === updatedTemplate.id) {
                                            return updatedTemplate;
                                        }
                                        return t;
                                    }),
                                ],
                            },
                        },
                    });
                }
            }
        },
    });

    const [createTemplate, { loading: createTemplateLoading }] = useCreateMessageTemplateMutation({
        onCompleted: data => {
            isEditMode = true;
            setIsModified(false);
            if (close) {
                history(`/app-config/message-templates`);
            } else {
                history(
                    `/app-config/message-templates/${data.createMessageTemplate?.resourceCreated?.id}`,
                );
            }
        },
        update: (cache, response) => {
            const newTemplate = response.data?.createMessageTemplate?.resourceCreated;
            if (response.data?.createMessageTemplate?.success && newTemplate) {
                const currentTemplates = cache.readQuery<MessageTemplatesListQuery>({
                    query: MessageTemplatesListDocument,
                    variables: templatesListQueryVariables,
                });
                if (currentTemplates?.messageTemplatesV2.results) {
                    cache.writeQuery<MessageTemplatesListQuery>({
                        query: MessageTemplatesListDocument,
                        data: {
                            messageTemplatesV2: {
                                ...currentTemplates.messageTemplatesV2,
                                results: [
                                    ...currentTemplates.messageTemplatesV2.results,
                                    newTemplate,
                                ],
                            },
                        },
                    });
                }
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: MessageTemplatesListDocument,
                variables: templatesListQueryVariables,
            },
        ],
    });

    useEffect(() => {
        if (isEditMode) {
            fetchTemplateById({ variables: { input: { id: emailTemplateId } } });
        } else {
            reset(formValuesDefault());
            emailEditorCommandsEn?.commands.clearContent();
            emailEditorCommandsEs?.commands.clearContent();
            textEditorCommandsEn?.commands.clearContent();
            textEditorCommandsEs?.commands.clearContent();
        }
    }, [
        currentTemplate,
        fetchTemplateById,
        reset,
        emailEditorCommandsEn?.commands,
        emailEditorCommandsEs?.commands,
        textEditorCommandsEn?.commands,
        textEditorCommandsEs?.commands,
        isEditMode,
        emailTemplateId,
    ]);

    const onSubmit = (values: any) => {
        const emailBodyUpdate = {
            en: toBase64(emailBodyEn || ''),
            es: toBase64(emailBodyEs || ''),
        };
        const textBodyUpdate = {
            en: toBase64(textBodyEn || ''),
            es: toBase64(textBodyEs || ''),
        };
        const filteredSubstitutions = substitutions.map(({ __typename, ...rest }) => rest);
        if (isEditMode && emailTemplateId) {
            updateTemplate({
                variables: {
                    input: {
                        id: emailTemplateId,
                        data: {
                            ...values,
                            emailBody: emailBodyUpdate,
                            textBody: textBodyUpdate,
                            substitutions: filteredSubstitutions,
                        },
                    },
                },
            });
        } else {
            createTemplate({
                variables: {
                    input: {
                        ...values,
                        emailBody: emailBodyUpdate,
                        textBody: textBodyUpdate,
                        substitutions: filteredSubstitutions,
                    },
                },
            });
        }
    };
    const onNavigateAway = () => {
        if (isModified)
            TriggerGlobalConfirm({
                message: `You have unsaved changes. Are you sure you want to return to the Message Templates List?`,
                callback: () => {
                    history(`/app-config/message-templates/`);
                },
            });
        else history(`/app-config/message-templates/`);
    };
    const onEdit = () => {
        setIsModified(true);
    };

    if (templateLoading || createTemplateLoading || updateTemplateLoading) return <Loading />;
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                    Back to Message Templates List
                </Button>
            </Grid>
            <Grid item xs={12}>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        <CardHeader title={title} />
                        <OutlinedSection title="Name *">
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                onChange={onEdit}
                                disabled={!canEdit}
                            />
                        </OutlinedSection>
                        <OutlinedSection title="Email">
                            <OutlinedSection title="English">
                                <OutlinedSection title="Email Subject">
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        fullWidth
                                        margin="dense"
                                        {...register('emailTitle.en')}
                                        onChange={onEdit}
                                        error={!!errors.emailTitle?.en}
                                        helperText={errors.emailTitle?.en?.message}
                                    />
                                </OutlinedSection>
                                <OutlinedSection
                                    title="Email Body"
                                    className={
                                        errors.emailBody?.en
                                            ? classes.emailBodyErrorContainer
                                            : undefined
                                    }
                                >
                                    <Controller
                                        control={control}
                                        name="emailBody.en"
                                        defaultValue=""
                                        render={({ field: { onChange } }) => (
                                            <>
                                                <RichTextEditor
                                                    onInit={setEmailEditorCommandsEn}
                                                    onChange={(value, subs) => {
                                                        onChange(value);
                                                        setEmailBodyEn(value);
                                                        if (subs) {
                                                            setSubstitutions(subs);
                                                        }
                                                    }}
                                                    initialValue={emailBodyEn}
                                                    useSubstitutionTokens
                                                />
                                                {!!errors.emailBody?.en && (
                                                    <div className={classes.emailBodyErrorText}>
                                                        {errors.emailBody?.en?.message}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    />
                                </OutlinedSection>
                            </OutlinedSection>
                            <OutlinedSection title="Spanish">
                                <OutlinedSection title="Email Subject">
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        fullWidth
                                        margin="dense"
                                        {...register('emailTitle.es')}
                                        onChange={onEdit}
                                    />
                                </OutlinedSection>
                                <OutlinedSection title="Email Body">
                                    <RichTextEditor
                                        onInit={setEmailEditorCommandsEs}
                                        onChange={(value, subs) => {
                                            setEmailBodyEs(value);
                                            if (subs) {
                                                setSubstitutions(subs);
                                            }
                                        }}
                                        initialValue={emailBodyEs}
                                        useSubstitutionTokens
                                    />
                                </OutlinedSection>
                            </OutlinedSection>
                        </OutlinedSection>
                        <OutlinedSection title="Text (SMS)">
                            <OutlinedSection title="English">
                                <OutlinedSection
                                    title="Text Body"
                                    className={
                                        errors.textBody?.en
                                            ? classes.emailBodyErrorContainer
                                            : undefined
                                    }
                                >
                                    <Controller
                                        control={control}
                                        name="textBody.en"
                                        render={({ field: { onChange } }) => (
                                            <>
                                                <RichTextEditor
                                                    onInit={setTextEditorCommandsEn}
                                                    onChange={(value, subs) => {
                                                        onChange(value);
                                                        setTextBodyEn(value);
                                                        if (subs) {
                                                            setSubstitutions(subs);
                                                        }
                                                    }}
                                                    initialValue={textBodyEn}
                                                    useSubstitutionTokens
                                                    allowImages={false}
                                                />
                                                {!!errors.textBody?.en && (
                                                    <div className={classes.emailBodyErrorText}>
                                                        {errors.textBody?.en?.message}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    />
                                </OutlinedSection>
                            </OutlinedSection>
                            <OutlinedSection title="Spanish">
                                <OutlinedSection
                                    title="Text Body"
                                    className={
                                        errors.textBody?.es
                                            ? classes.emailBodyErrorContainer
                                            : undefined
                                    }
                                >
                                    <Controller
                                        control={control}
                                        name="textBody.es"
                                        render={({ field: { onChange } }) => (
                                            <>
                                                {' '}
                                                <RichTextEditor
                                                    onInit={setTextEditorCommandsEs}
                                                    onChange={(value, subs) => {
                                                        onChange(value);
                                                        setTextBodyEs(value);
                                                        if (subs) {
                                                            setSubstitutions(subs);
                                                        }
                                                    }}
                                                    initialValue={textBodyEs}
                                                    useSubstitutionTokens
                                                    allowImages={false}
                                                />
                                            </>
                                        )}
                                    />
                                </OutlinedSection>
                            </OutlinedSection>
                        </OutlinedSection>
                    </Card>
                    {canEdit && (
                        <div className={classes.saveButtons}>
                            <Button
                                type="submit"
                                startIcon={<Save />}
                                color="secondary"
                                variant="contained"
                                onClick={() => handleSubmit(onSubmit)}
                            >
                                Save
                            </Button>
                            <Button
                                type="submit"
                                startIcon={<Save />}
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                    setClose(true);
                                    handleSubmit(onSubmit);
                                }}
                            >
                                Save &amp; Close
                            </Button>
                        </div>
                    )}
                </form>
            </Grid>
        </Grid>
    );
};
