import React from 'react';
import {
    FormHelperText,
    FormLabel,
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import useStyles from './styles';

type Button<T> = {
    value: T;
    label: string;
};

interface ToggleButtonProps<T> {
    label: string;
    onChange: (event: React.MouseEvent<HTMLElement>, value: T) => void;
    value?: T;
    buttons: Button<T>[];
    error?: string;
}

type ToggleButtonSwitchI<T = any> = React.FC<ToggleButtonProps<T>>;

const ToggleButtonSwitch: ToggleButtonSwitchI = props => {
    const { classes } = useStyles();
    const { label, onChange, value, buttons, error } = props;
    return (
        <Grid container direction="column" paddingX={2} className={classes.toggle} item xs={12}>
            <FormLabel>
                <Typography variant="body1" color="black">
                    {label}
                </Typography>
            </FormLabel>
            <Grid container item xs={12}>
                <ToggleButtonGroup
                    exclusive
                    onChange={onChange}
                    value={value}
                    color="primary"
                    fullWidth
                >
                    {buttons.map(buttonProps => (
                        <ToggleButton value={buttonProps.value} key={buttonProps.value}>
                            {buttonProps.label}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
                <FormHelperText error={!!error}>{error}</FormHelperText>
            </Grid>
        </Grid>
    );
};

export default ToggleButtonSwitch;
