import React, { useMemo } from 'react';
import { Button, Grid, Link, Typography } from '@mui/material';
import MaterialTable from '@material-table/core';
import { DeleteOutline } from '@mui/icons-material';
import { FormInput } from '~/views/AffiliateCare/components/ConsultantForm/ConsultantForm';

type LactationConsultantsProps = {
    addNewHandler: () => void;
    consultants: FormInput[];
    editHandler: (index: number) => void;
    deleteHandler: (index: number) => void;
};

type TableData = {
    rowIndex: number;
} & FormInput;

const LactationConsultants: React.FC<LactationConsultantsProps> = props => {
    const { addNewHandler, consultants, editHandler, deleteHandler } = props;
    const dataTable: TableData[] = useMemo(() => {
        return consultants.map((c, index) => ({ ...c, rowIndex: index }));
    }, [consultants]);
    return (
        <Grid xs={12} item container rowSpacing={2}>
            <Grid item xs={12} container columnSpacing={2} alignItems="center">
                <Grid item>
                    <Button onClick={addNewHandler} variant="contained">
                        Add Lactation Consultant
                    </Button>
                </Grid>
                {!!consultants.length && (
                    <Grid item>
                        <Typography variant="body1">
                            Lactation Consultants ({consultants.length})
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12}>
                <MaterialTable<TableData>
                    columns={[
                        {
                            title: 'Name',
                            render: ({ name, rowIndex }) => (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <Link
                                    component="button"
                                    underline="always"
                                    onClick={() => editHandler(rowIndex)}
                                >
                                    {name}
                                </Link>
                            ),
                            sorting: false,
                        },
                    ]}
                    data={dataTable}
                    options={{
                        showTitle: false,
                        paging: false,
                        search: false,
                        toolbar: false,
                        actionsColumnIndex: -1,
                    }}
                    actions={[
                        {
                            icon: () => <DeleteOutline />,
                            tooltip: 'Delete',
                            onClick: (_, rowData: TableData) => deleteHandler(rowData.rowIndex),
                            isFreeAction: false,
                        },
                    ]}
                    localization={{ header: { actions: '' } }}
                />
            </Grid>
        </Grid>
    );
};

export default LactationConsultants;
