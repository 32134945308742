import ObjectId from 'bson-objectid';
import { ChecklistItemForm, ChecklistItemInput } from '../types';

export const toSubmitInput = (
    formData: ChecklistItemForm,
    actionTypeIds: string[],
): ChecklistItemInput => {
    const { id, name, description, completedAt, completedBy, endSlaTimerOnComplete } = formData;

    return {
        id: id ?? new ObjectId().toHexString(),
        label: name,
        description: description ?? null,
        actionTypeIds,
        completedAt: completedAt ?? null,
        completedBy: completedBy ?? null,
        endSlaTimerOnComplete: endSlaTimerOnComplete ?? null,
    };
};
