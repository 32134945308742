import { Button, TextField } from '@mui/material';
import { Save } from '@mui/icons-material';
import React, { useState } from 'react';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { CloneArticleModalParms } from '../Articles';

const CloneArticleModal: React.FC<CloneArticleModalParms> = parms => {
    const { existingArticleId: id, submitHandler } = parms;
    const [name, setName] = useState('');
    const onSubmit = () => {
        if (name.length > 0) submitHandler(id, name);
    };

    return (
        <>
            <OutlinedSection title="New Name">
                <TextField
                    variant="outlined"
                    type="text"
                    fullWidth
                    margin="dense"
                    onChange={e => setName(e.target.value)}
                />
            </OutlinedSection>
            <div style={{ width: '100%', textAlign: 'right' }}>
                <Button
                    aria-label="clone"
                    type="submit"
                    startIcon={<Save />}
                    color="secondary"
                    variant="contained"
                    onClick={onSubmit}
                >
                    Save Clone
                </Button>
            </div>
        </>
    );
};

export default CloneArticleModal;
