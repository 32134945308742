import { OrderByDirectionEnum } from '~/schemaTypes';

// some fields are calcualted from Profile Variables
// this enum connects Patient List query field name and Profile Value Definition id together
export enum PatientPVRelatedFields {
    riskScore = '64c741ac4478b8661e3e5f93',
}

export enum PatientSortingFields {
    firstName = 'firstName',
    lastName = 'lastName',
    riskScore = 'riskScore',
    createdAt = 'createdAt',
    birthDate = 'birthDate',
    onboardingCompleteDate = 'onboardingCompleteDate',
    isPregnant = 'isPregnant',
    dueDate = 'dueDate',
    deliveryDate = 'deliveryDate',
    assignedHealthAdvocate = 'assignedHealthAdvocate',
}

export interface IPatientOrderChange {
    field: PatientSortingFields | PatientPVRelatedFields;
    direction: OrderByDirectionEnum;
    fieldIsProfileDefId?: boolean;
    isInit: boolean;
}

export enum GridColumns {
    invitationCode = 'invitationCode',
    externalId = 'externalId',
    firstName = 'firstName',
    lastName = 'lastName',
    riskScore = 'riskScore',
    createdAt = 'createdAt',
    assignedHealthAdvocate = 'assignedHealthAdvocate',
    openAdvocateTaskCount = 'openAdvocateTaskCount',
    openCriticalAdvocateTaskCount = 'openCriticalAdvocateTaskCount',
    appRegistrationCompleted = 'appRegistrationCompleted',
    patientCallStatus = 'patientCallStatus',
    birthDate = 'birthDate',
    organization = 'organization',
    clinic = 'clinic',
    onboardingCompleteDate = 'onboardingCompleteDate',
    isPregnant = 'isPregnant',
    dueDate = 'dueDate',
    deliveryDate = 'deliveryDate',
    email = 'email',
    phoneNumber = 'phoneNumber',
    id = 'id',
    coverageExpiration = 'coverageExpiration',
    affiliates = 'affiliates',
    language = 'language',
}

export const GENERAL_COLUMNS = [
    GridColumns.invitationCode,
    GridColumns.externalId,
    GridColumns.firstName,
    GridColumns.lastName,
    GridColumns.riskScore,
    GridColumns.createdAt,
    GridColumns.assignedHealthAdvocate,
    GridColumns.openAdvocateTaskCount,
    GridColumns.openCriticalAdvocateTaskCount,
    GridColumns.appRegistrationCompleted,
    GridColumns.patientCallStatus,
    GridColumns.birthDate,
    GridColumns.organization,
    GridColumns.clinic,
    GridColumns.onboardingCompleteDate,
    GridColumns.isPregnant,
    GridColumns.dueDate,
    GridColumns.deliveryDate,
    GridColumns.email,
    GridColumns.phoneNumber,
    GridColumns.language,
    GridColumns.id,
];

export const DOULA_COLUMNS = [
    GridColumns.firstName,
    GridColumns.lastName,
    GridColumns.createdAt,
    GridColumns.birthDate,
    GridColumns.email,
    GridColumns.coverageExpiration,
];

export const VC_COLUMNS = [
    GridColumns.firstName,
    GridColumns.lastName,
    GridColumns.createdAt,
    GridColumns.birthDate,
    GridColumns.email,
    GridColumns.coverageExpiration,
    GridColumns.affiliates,
];
