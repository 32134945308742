import { FetchAdvocateTaskForAdvocateTaskPageQuery } from '~/schemaTypes';

type Type = NonNullable<FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']>['types'][0];
type UserToAssign = NonNullable<
    FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']
>['usersToAssign'][0];

export const Priorities = Array.from({ length: 5 }, (_, i) => i + 1);

export const typeLabelById = (id: string, types: Type[]): string | undefined =>
    types.find(type => type.id === id)?.label;

export const userNameById = (
    id: string | null,
    usersToAssign: UserToAssign[],
): string | undefined => usersToAssign.find(user => user.id === id)?.name;
