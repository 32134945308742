import React from 'react';
import { Button, Grid } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export const BackToVCAffiliatesList = () => {
    const navigate = useNavigate();
    return (
        <Grid item xs={12}>
            <Button
                onClick={() => {
                    navigate('/app-config/vcaffiliates');
                }}
                startIcon={<ArrowBack />}
            >
                Back to VC Affiliates
            </Button>
        </Grid>
    );
};
