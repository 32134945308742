import { useReactiveVar } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AccessToken } from '~/state';
import { tss } from 'tss-react/mui';

const useStyles = tss.create({
    nobr: {
        whiteSpace: 'nowrap',
    },
});

export const SessionCountdown: React.FC = () => {
    const { classes } = useStyles();
    const [[timeRemaining, minutesRemaining], setTimeRemaining] = useState(['...', -1]);
    const authExpiration = useReactiveVar(AccessToken)?.payload?.exp;
    useEffect(() => {
        const updateTimeRemaining = () => {
            if (authExpiration == null) {
                setTimeRemaining(['...', -1]);
                return;
            }
            const expirationMinutes = Math.floor((authExpiration * 1000 - Date.now()) / 60_000);
            if (expirationMinutes > 60) {
                setTimeRemaining([`over an hour`, expirationMinutes]);
            } else if (expirationMinutes > 0) {
                setTimeRemaining([`${expirationMinutes} minutes`, expirationMinutes]);
            } else {
                setTimeRemaining(['Expired!', expirationMinutes]);
            }
        };
        const interval = setInterval(() => {
            updateTimeRemaining();
        }, 60_000);
        updateTimeRemaining();
        return () => clearInterval(interval);
    }, [authExpiration]);
    return (
        <Grid item>
            {authExpiration != null && (
                <p title={`${minutesRemaining.toString()} minutes`}>
                    <span className={classes.nobr}>Session expires in:</span>{' '}
                    <b className={classes.nobr}>{timeRemaining}</b>
                </p>
            )}
        </Grid>
    );
};
