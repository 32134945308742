export enum AppointmentEnum {
    SIDE_NAV_APPOINTMENTS = 'appointments',
    APPOINTMENTS_CONTAINER = 'appointments-container',
    APPOINTMENT_MODAL = 'appointment-modal',
    PATIENT_INPUT = 'appointment-patient-input',
    DURATION_INPUT = 'appointment-duration-input',
    EXTERNAL_ID_INPUT = 'appointment-external-id-input',
    STAFF_INPUT = 'appointment-staff-input',
    LOCATION_INPUT = 'appointment-location-input',
    DESCRIPTION_INPUT = 'appointment-description-input',
    VIRTUAL_VISIT_INPUT = 'appointment-virtual-visit-input',
    SUBMIT_APPOINTMENT = 'submit-appointment-button',
}

export const AppointmentSelectors = {
    SIDE_NAV_APPOINTMENTS: `a[data-test="${AppointmentEnum.SIDE_NAV_APPOINTMENTS}"]`,
    ADD_NEW_APPOINTMENT_BUTTON: `div[data-test="${AppointmentEnum.APPOINTMENTS_CONTAINER}"] button[title="Add New Appointment"]`,
    SUBMIT_APPOINTMENT: `button[data-test="${AppointmentEnum.SUBMIT_APPOINTMENT}"]`,
    EDIT_APPOINTMENT_BUTTON: `div[data-test="${AppointmentEnum.APPOINTMENTS_CONTAINER}"] button[title="Edit"]`,
    APPOINTMENT_MODAL: `div[data-test="${AppointmentEnum.APPOINTMENT_MODAL}"] div[role="dialog"]`,
    APPOINTMENT_MODAL_TITLE: `div[data-test="${AppointmentEnum.APPOINTMENT_MODAL}"] div[id="form-dialog-title"] h2`,
    PATIENT_INPUT: `div[data-test="${AppointmentEnum.PATIENT_INPUT}"] input`,
    DURATION_INPUT: `div[data-test="${AppointmentEnum.DURATION_INPUT}"] input`,
    EXTERNAL_ID_INPUT: `div[data-test="${AppointmentEnum.EXTERNAL_ID_INPUT}"] input`,
    LOCATION_INPUT: `div[data-test="${AppointmentEnum.LOCATION_INPUT}"] input`,
    DESCRIPTION_INPUT: `div[data-test="${AppointmentEnum.DESCRIPTION_INPUT}"] input`,
    SEARCH_INPUT: `div[data-test="${AppointmentEnum.APPOINTMENTS_CONTAINER}"] input[placeholder="Search"]`,
};
