import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    root: {
        position: 'relative',
        height: '100%',
        '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
            opacity: '0',
        },
    },
    titleDivider: {
        marginBottom: '20px',
    },
    simpleContentImageCard: {
        padding: '0 10px',
    },
});
