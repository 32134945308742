/* eslint-disable react/jsx-props-no-spreading */
import { Card, Checkbox, Dialog, Grid, Tab, Tabs, Typography } from '@mui/material';
import { format } from 'date-fns';
import MaterialTable from '@material-table/core';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { useUserPermissions } from '~/hooks';
import {
    useFetchPatientAppUsersForArticleFeedQuery,
    useGetPatientHighlightsLazyQuery,
} from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import tableIcons from '~/helpers/tableIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import PatientHighlightsModal from './PatientHighlightsModal/PatientHighlightsModal';

const PatientHighlights: React.FC = () => {
    // const { pagePermissions } = useUserPermissions();
    const { id } = useParams<{ id: string }>();
    const [appBundleId, setAppBundleId] = useState<string>();
    const [itemToEdit, setItemToEdit] = useState<{
        id: string;
        priority: number;
        closed: boolean;
    }>();
    const [showCurrent, setShowCurrent] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const { data: patientData, loading: patientDataLoading } =
        useFetchPatientAppUsersForArticleFeedQuery({ variables: { input: { id } } });

    useEffect(() => {
        if (patientData?.patient?.appBundleId) {
            setAppBundleId(patientData.patient.appBundleId);
        }
    }, [patientData]);

    const [getHighlights, { loading: isLoading, data }] = useGetPatientHighlightsLazyQuery({
        variables: { patientId: id, appBundleId },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (appBundleId && appBundleId.length > 0) getHighlights();
    }, [appBundleId, getHighlights]);

    const handleTabChange = () => {
        setShowCurrent(!showCurrent);
    };
    const handleEditItem = (row: any) => {
        setItemToEdit({
            id: row.id,
            closed: row.closed,
            priority: row.priority,
        });
        setShowModal(true);
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    if (!appBundleId || appBundleId.length === 0 || isLoading || patientDataLoading)
        return <Loading />;
    return (
        <Grid container direction="column" spacing={2} wrap="wrap">
            <Grid item style={{ flexGrow: 1 }}>
                <Card style={{ padding: 15, marginBottom: 0 }}>
                    <Tabs
                        aria-label="basic tabs example"
                        value={showCurrent ? 'current' : 'upcoming'}
                        onChange={handleTabChange}
                    >
                        <Tab
                            label={`Today (${data?.patientHighlights?.todayitemcount})`}
                            value="current"
                        />
                        <Tab
                            label={`Future (${data?.patientHighlights?.futureitemcount})`}
                            value="upcoming"
                        />
                    </Tabs>
                </Card>
            </Grid>
            <Grid item style={{ flexGrow: 1 }} ref={tableRef}>
                <MaterialTable
                    title="Patient Highlights"
                    actions={[
                        {
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit',
                            onClick: (_event, row) => {
                                handleEditItem(row);
                            },
                            // hidden: !pagePermissions?.PatientHighlights.Edit,
                            hidden: true,
                        },
                    ]}
                    icons={tableIcons}
                    columns={[
                        {
                            title: 'Highlight Name',
                            field: 'highlightName',
                        },
                        { title: 'Type', field: 'type' },
                        {
                            title: 'Title',
                            render: ({ title }) => (
                                <Typography dangerouslySetInnerHTML={{ __html: title }} />
                            ),
                            customSort: (a, b) => {
                                const current = `${a.title}${a.header}${a.body}`.toLowerCase();
                                const next = `${b.title}${b.header}${b.body}`.toLowerCase();

                                if (current < next) {
                                    return -1;
                                }
                                if (current > next) {
                                    return 1;
                                }
                                return 0;
                            },
                        },
                        { title: 'Priority', field: 'priority' },
                        {
                            title: 'Show From',
                            render: ({ availableFrom }) =>
                                format(new Date(availableFrom), 'MM/dd/yyyy'),
                            customSort: (a, b) => {
                                const current = new Date(a.availableFrom);
                                const next = new Date(b.availableFrom);

                                if (current < next) {
                                    return 1;
                                }
                                if (current > next) {
                                    return -1;
                                }
                                return 0;
                            },
                        },
                        {
                            title: 'Show To',
                            render: ({ availableUntil }) =>
                                format(new Date(availableUntil), 'MM/dd/yyyy'),
                            customSort: (a, b) => {
                                const current = new Date(a.availableUntil);
                                const next = new Date(b.availableUntil);
                                if (current < next) {
                                    return 1;
                                }
                                if (current > next) {
                                    return -1;
                                }
                                return 0;
                            },
                        },
                        {
                            title: 'Closed',
                            field: 'closed',
                            render: ({ closed }) => <Checkbox disabled checked={closed} />,
                        },
                    ]}
                    data={
                        showCurrent
                            ? data?.patientHighlights?.todayitems || []
                            : data?.patientHighlights?.futureitems || []
                    }
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    options={{
                        pageSize: rowsPerPage,
                        pageSizeOptions: [25, 50, 100],
                    }}
                />
                {itemToEdit && (
                    <Dialog scroll="paper" open={showModal} onClose={() => setShowModal(false)}>
                        <DialogTitleWithClose onClose={() => setShowModal(false)} id="editTitle">
                            Edit Patient Highlight
                        </DialogTitleWithClose>

                        <PatientHighlightsModal
                            closeHandler={() => setShowModal(false)}
                            id={itemToEdit.id}
                            priority={itemToEdit.priority}
                            closed={itemToEdit.closed}
                        />
                    </Dialog>
                )}
            </Grid>
        </Grid>
    );
};

export default PatientHighlights;
