import { Dialog, Tab, Tabs } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PatientTodosItemsQuery, usePatientTodosItemsQuery } from '~/schemaTypes';

import { CompletedTodosTable } from './components/CompletedTodosTable';
import { TabPanel } from './components/TabPanel';
import { UpcomingTodosTable } from './components/UpcomingTodosTable';
import { PatientTodoCreateModal } from './PatientTodoCreateModal/PatientTodoCreateModal';
import { PatientTodoEditModal } from './PatientTodoEditModal/PatientTodoEditModal';
import { RouteParams } from './types';

type PatientTodosItem = NonNullable<PatientTodosItemsQuery['patientProxiedTodosItems'][0]>;

const useStyles = makeStyles()(_theme => ({
    root: {
        position: 'relative',
        height: '100%',
    },
}));

enum TABS {
    upcoming = 'Upcoming',
    completed = 'Completed',
}

const PatientTodos: React.FC = () => {
    const { id } = useParams<RouteParams>();
    const { classes } = useStyles();
    const [tableDataUpcoming, setTableDataUpcoming] = useState<PatientTodosItem[]>([]);
    const [tableDataCompleted, setTableDataCompleted] = useState<PatientTodosItem[]>([]);
    const [activeTab, setActiveTab] = useState<TABS>(TABS.upcoming);
    const [todoIdToEdit, setTodoIdToEdit] = useState<string | null>(null);
    const [isCreateMode, setIsCreateMode] = useState<boolean>(false);

    const sortTodos = (data: PatientTodosItemsQuery) => {
        setTableDataUpcoming(
            _(data?.patientProxiedTodosItems)
                .filter(todo => todo.isComplete === false)
                .sortBy(todo => todo.priority)
                .map<PatientTodosItem>((todo, index) => ({
                    ...todo,
                    index,
                }))
                .value() || [],
        );
        setTableDataCompleted(
            _(data?.patientProxiedTodosItems)
                .filter(todo => todo.isComplete === true)
                .sortBy(todo => todo.priority)
                .map<PatientTodosItem>((todo, index) => ({
                    ...todo,
                    index,
                }))
                .value() || [],
        );
    };
    const {
        loading: todosLoading,
        data: allTodos,
        refetch: refetchAllTodos,
    } = usePatientTodosItemsQuery({
        variables: {
            patientId: id ?? '',
        },
        onCompleted: data => {
            sortTodos(data);
        },
    });

    const handleTabChange = (event: unknown, value: TABS) => {
        setActiveTab(value);
    };
    const editDialogCloseHandler = useCallback(async () => {
        setTodoIdToEdit(null);
        setIsCreateMode(false);
        if (allTodos === undefined) return;
        const { data: todos } = await refetchAllTodos({
            patientId: id,
        });
        sortTodos(todos);
    }, [allTodos, id, refetchAllTodos]);

    return (
        <div className={classes.root}>
            <Tabs
                aria-label="Upcoming & Pending todos tabs."
                value={activeTab}
                onChange={handleTabChange}
            >
                <Tab label={TABS.upcoming} value={TABS.upcoming} />
                <Tab label={TABS.completed} value={TABS.completed} />
            </Tabs>
            <TabPanel value={activeTab} index={TABS.upcoming}>
                <UpcomingTodosTable
                    data={tableDataUpcoming}
                    isLoading={todosLoading}
                    onEdit={id => setTodoIdToEdit(id)}
                    onCreate={() => setIsCreateMode(true)}
                />
            </TabPanel>
            <TabPanel value={activeTab} index={TABS.completed}>
                <CompletedTodosTable
                    data={tableDataCompleted}
                    isLoading={todosLoading}
                    onEdit={id => setTodoIdToEdit(id)}
                />
            </TabPanel>
            {todoIdToEdit && (
                <Dialog
                    scroll="paper"
                    open={Boolean(todoIdToEdit)}
                    onClose={editDialogCloseHandler}
                    fullWidth
                    maxWidth="md"
                >
                    <PatientTodoEditModal
                        todoId={todoIdToEdit}
                        closeHandler={editDialogCloseHandler}
                    />
                </Dialog>
            )}
            {isCreateMode && (
                <Dialog
                    scroll="paper"
                    open={isCreateMode}
                    onClose={editDialogCloseHandler}
                    fullWidth
                    maxWidth="md"
                >
                    <PatientTodoCreateModal closeHandler={editDialogCloseHandler} />
                </Dialog>
            )}
        </div>
    );
};

export default PatientTodos;
