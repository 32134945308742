import { OrderByDirectionEnum } from '~/schemaTypes';

export enum CallListsSortingFields {
    name = 'name',
    userName = 'user.name',
    tagName = 'tags.name',
    createdAt = 'createdAt',
}

export interface ICallListsOrderChange {
    field: CallListsSortingFields;
    direction: OrderByDirectionEnum;
    isInit: boolean;
}
