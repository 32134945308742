import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, Grid, Link, Typography } from '@mui/material';
import { Chat, Phone, Save } from '@mui/icons-material';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { displayBirthday } from '~/helpers';
import { FetchAdvocateTaskForAdvocateTaskPageQuery } from '~/schemaTypes';
import RichTextEditor from '~/components/RichTextEditor/RichTextEditor';
import parse from 'html-react-parser';
import { useStyles } from './styles';
import { DESCRIPTION_SCHEMA } from './yupSchema';

type Patient = NonNullable<
    NonNullable<FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']>['patient']
>;

type DescriptionInput = {
    description: string;
};

interface TaskInfoProps {
    patient: Patient;
    description: string;
    onDescriptionUpdated: (description: string) => void;
}

const TaskInfo: React.FC<TaskInfoProps> = ({ patient, description, onDescriptionUpdated }) => {
    const { classes } = useStyles();
    const history = useNavigate();
    const [isEditDescription, setIsEditDescription] = useState<boolean>(false);
    const { reset, handleSubmit, setValue } = useForm<DescriptionInput>({
        resolver: yupResolver(DESCRIPTION_SCHEMA as any),
    });

    const onSubmit: SubmitHandler<DescriptionInput> = ({ description }) => {
        onDescriptionUpdated(description);
        setIsEditDescription(false);
    };

    const handleCancel = () => {
        setIsEditDescription(false);
        reset();
    };

    const handleOpenChatRoom = () => {
        history(`/portal/patients/${patient.id}/chat`);
    };

    return (
        <div>
            <Grid item xs={12} className={classes.patientSection}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                    Patient
                </Typography>
                <Grid container item alignItems="flex-start" spacing={1}>
                    <Grid item>
                        <Link
                            to={`/portal/patients/${patient.id}`}
                            component={RouterLink}
                            className={classes.fullName}
                            underline="hover"
                        >
                            <Typography>{patient.fullName}</Typography>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Chat
                            className={classes.chat}
                            fontSize="small"
                            onClick={handleOpenChatRoom}
                        />
                    </Grid>
                    <Grid item>
                        <Phone fontSize="small" />
                    </Grid>
                    <Grid item>
                        <Typography>{patient.phoneNumber}</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        DOB:{' '}
                        {patient?.birthDate &&
                            displayBirthday({
                                isoDateStr: patient.birthDate,
                            })}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        App Name: {patient.practice.brandingName}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                    Description
                </Typography>
                {isEditDescription ? (
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <RichTextEditor
                            onChange={value => setValue('description', value)}
                            placeholder="Description *"
                            initialValue={description}
                            label="Description *"
                        />
                        <DialogActions>
                            <Button
                                color="secondary"
                                variant="outlined"
                                size="small"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                startIcon={<Save />}
                                type="submit"
                                color="secondary"
                                variant="contained"
                                size="small"
                                onClick={handleSubmit(onSubmit)}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                ) : (
                    <Grid container item alignItems="center" wrap="nowrap">
                        <Grid item>
                            <Typography variant="body2">
                                <span>{parse(description)}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default TaskInfo;
