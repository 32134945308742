import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, Checkbox, FormControlLabel, Typography, FormHelperText } from '@mui/material';
import OfficeAddresses, {
    OfficeAddress,
} from '~/views/AffiliateCare/components/OfficeAddresses/OfficeAddresses';

type InOfficeTypeProps = {
    deleteConfirm?: boolean;
    showHelperText?: boolean;
};

const InOfficeType: React.FC<InOfficeTypeProps> = props => {
    const { deleteConfirm = false, showHelperText = true } = props;
    const {
        control,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();
    const offices = watch('officeAddresses');
    const isInOfficeType = watch('isInOfficeType');
    const setData = useCallback((newOffices: OfficeAddress[]) => {
        setValue('officeAddresses', newOffices, { shouldValidate: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <Controller
                    name="isInOfficeType"
                    control={control}
                    render={({ field: { value } }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value}
                                    onChange={e => {
                                        setData([]);
                                        setValue('isInOfficeType', e.target.checked, {
                                            shouldValidate: true,
                                            shouldDirty: !e.target.checked,
                                        });
                                    }}
                                />
                            }
                            label="In-Office"
                            labelPlacement="end"
                        />
                    )}
                />
            </Grid>
            {isInOfficeType && (
                <Grid item xs={12} rowSpacing={3} container padding="0 20px">
                    {showHelperText && (
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                What is your office address? (if multiple, please provide)
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12} container>
                        <Grid item xs={12}>
                            {!!errors.officeAddresses && (
                                <FormHelperText error>
                                    {errors.officeAddresses.message as string}
                                </FormHelperText>
                            )}
                        </Grid>
                        <OfficeAddresses
                            data={offices}
                            setData={setData}
                            deleteConfirm={deleteConfirm}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default InOfficeType;
