import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ObjectID from 'bson-objectid';
import React, { useState } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { excludeContentTypes } from '~/components/NonImageContentPicker/helpers/excludeContentTypes';
import NonImageContentPicker from '~/components/NonImageContentPicker/NonImageContentPicker';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { addSpacesBetweenCapitalsHelper } from '~/helpers/addSpaceBetweenCapitalLetters';
import { ContentType, CtaType } from '~/schemaTypes';
import { ListItem } from '~/views/ConfigDashboard/Articles/Editors/ArticleEditor';
import { CtaModalParms } from '../Editors/VariantEditor';

const useStyles = makeStyles()(() => ({
    paper: { minWidth: 800 },
}));

const CTAModal: React.FC<CtaModalParms> = ({ closeHandler, submitHandler, tags, ctaData }) => {
    const { classes } = useStyles();
    const [selectedType, setSelectedType] = useState(
        ctaData ? CtaType[ctaData?.cta.type] : CtaType.OpenInternalLink,
    );
    const [simpleContentId, setSimpleContentId] = useState<string | null>(
        ctaData ? ctaData?.cta.simpleContentId : null,
    );
    const [parm1en, setParm1en] = useState(ctaData ? ctaData?.cta.parameter1?.en : '');
    const [parm2en, setParm2en] = useState(ctaData ? ctaData?.cta.parameter2?.en : '');
    const [parm1es, setParm1es] = useState(ctaData ? ctaData?.cta.parameter1?.es : '');
    const [parm2es, setParm2es] = useState(ctaData ? ctaData?.cta.parameter2?.es : '');
    const [buttonTextEn, setButtonTextEn] = useState(ctaData ? ctaData?.cta.buttonText?.en : '');
    const [buttonTextEs, setButtonTextEs] = useState(ctaData ? ctaData?.cta.buttonText?.es : '');
    const ctaList = [
        CtaType.OpenInternalLink,
        CtaType.OpenExternalLink,
        CtaType.AddToQList,
        CtaType.PopUpMessage,
        CtaType.TapToCall,
        CtaType.TapToCallPopUp,
    ];
    const [tagList, setTagList] = useState<ListItem[]>(ctaData?.tagList || []);
    const [contentRequired, setContentRequired] = useState<boolean>(false);
    const [selectedContentName, setSelectedContentName] = useState<string>('');
    const [mustHaveContent, setMustHaveContent] = useState(
        ctaData
            ? CtaType[ctaData?.cta.type] === CtaType.OpenExternalLink ||
                  CtaType[ctaData?.cta.type] === CtaType.OpenInternalLink ||
                  CtaType[ctaData?.cta.type] === CtaType.TapToCall ||
                  CtaType[ctaData?.cta.type] === CtaType.TapToCallPopUp
            : true,
    );
    const [buttonTextRequired, setButtonTextRequired] = useState(false);
    const onSubmit = () => {
        setContentRequired(false);
        setButtonTextRequired(false);
        if (
            !simpleContentId &&
            (selectedType === CtaType.OpenExternalLink ||
                selectedType === CtaType.OpenInternalLink ||
                selectedType === CtaType.TapToCall ||
                selectedType === CtaType.TapToCallPopUp)
        ) {
            setContentRequired(true);
            return;
        }
        if (!mustHaveContent && buttonTextEn?.length === 0) {
            setButtonTextRequired(true);
            return;
        }
        if (ctaData) {
            submitHandler(
                true,
                {
                    id: ctaData.cta.id,
                    type: selectedType,
                    tagList: tagList.map(tag => tag.id),
                    simpleContentId:
                        selectedType === CtaType.OpenExternalLink ||
                        selectedType === CtaType.OpenInternalLink ||
                        selectedType === CtaType.TapToCall ||
                        selectedType === CtaType.TapToCallPopUp
                            ? simpleContentId
                            : null,
                    parameter1: { en: parm1en, es: parm1es },
                    parameter2: { en: parm2en, es: parm2es },
                    buttonText: { en: buttonTextEn, es: buttonTextEs },
                    defaultButtonText: buttonTextEn?.length === 0,
                },
                tagList,
                selectedContentName,
            );
            return;
        }

        submitHandler(
            false,
            {
                id: new ObjectID().toHexString(),
                type: selectedType,
                tagList: tagList.map(tag => tag.id),
                simpleContentId:
                    selectedType === CtaType.OpenExternalLink ||
                    selectedType === CtaType.OpenInternalLink ||
                    selectedType === CtaType.TapToCall ||
                    selectedType === CtaType.TapToCallPopUp
                        ? simpleContentId
                        : null,
                parameter1: { en: parm1en, es: parm1es },
                parameter2: { en: parm2en, es: parm2es },
                buttonText: { en: buttonTextEn, es: buttonTextEs },
                defaultButtonText: buttonTextEn?.length === 0,
            },
            tagList,
            selectedContentName,
        );
    };
    const selectedTypeChangeHandler = (selectedType: CtaType) => {
        setSelectedType(selectedType);
        setButtonTextRequired(false);
        setMustHaveContent(
            selectedType === CtaType.OpenExternalLink ||
                selectedType === CtaType.OpenInternalLink ||
                selectedType === CtaType.TapToCall ||
                selectedType === CtaType.TapToCallPopUp,
        );
    };
    return (
        <Dialog open classes={{ paper: classes.paper }}>
            <DialogTitleWithClose id="modalTitle" onClose={closeHandler}>
                {ctaData ? 'Edit' : 'Add'} Call To Action
            </DialogTitleWithClose>
            <DialogContent>
                <OutlinedSection title="Call To Action Type">
                    <Autocomplete
                        size="small"
                        value={selectedType}
                        onChange={(_, val) => {
                            selectedTypeChangeHandler(val ?? CtaType.OpenInternalLink);
                            setParm1en('');
                            setParm1es('');
                            setParm2en('');
                            setParm2es('');
                            setSimpleContentId(null);
                        }}
                        getOptionLabel={type => {
                            return addSpacesBetweenCapitalsHelper(CtaType[type]);
                        }}
                        options={ctaList}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        renderInput={params => <TextField variant="outlined" {...params} />}
                    />
                </OutlinedSection>
                <OutlinedSection
                    title={
                        mustHaveContent ? 'Button Text (leave blank for default)' : 'Button Text'
                    }
                >
                    <TextField
                        variant="outlined"
                        type="text"
                        label="English"
                        margin="dense"
                        defaultValue={buttonTextEn}
                        onChange={e => setButtonTextEn(e.target.value)}
                        fullWidth
                        required={!mustHaveContent}
                    />
                    {buttonTextRequired && (
                        <div style={{ color: 'red', fontWeight: 'bold' }}>Button Text required</div>
                    )}
                    <TextField
                        variant="outlined"
                        type="text"
                        label="Spanish"
                        margin="dense"
                        defaultValue={buttonTextEs}
                        onChange={e => setButtonTextEs(e.target.value)}
                        fullWidth
                    />
                </OutlinedSection>
                {selectedType === CtaType.OpenExternalLink && (
                    <NonImageContentPicker
                        selectedContentId={simpleContentId}
                        setSelectedContentId={setSimpleContentId}
                        required={contentRequired}
                        setRequired={setContentRequired}
                        allowedTypes={[ContentType.ExternalLink]}
                        setSelectedContentName={setSelectedContentName}
                    />
                )}
                {selectedType === CtaType.OpenInternalLink && (
                    <NonImageContentPicker
                        selectedContentId={simpleContentId}
                        setSelectedContentId={setSimpleContentId}
                        required={contentRequired}
                        setRequired={setContentRequired}
                        allowedTypes={
                            excludeContentTypes([
                                ContentType.ExternalLink,
                                ContentType.Image,
                                ContentType.PhoneNumber,
                            ]) as ContentType[]
                        }
                        setSelectedContentName={setSelectedContentName}
                    />
                )}
                {(selectedType === CtaType.TapToCall ||
                    selectedType === CtaType.TapToCallPopUp) && (
                    <NonImageContentPicker
                        selectedContentId={simpleContentId}
                        setSelectedContentId={setSimpleContentId}
                        required={contentRequired}
                        setRequired={setContentRequired}
                        allowedTypes={[ContentType.PhoneNumber]}
                        setSelectedContentName={setSelectedContentName}
                    />
                )}
                {selectedType === CtaType.AddToQList && (
                    <OutlinedSection title="QList Text">
                        <TextField
                            variant="outlined"
                            type="text"
                            label="English *"
                            margin="dense"
                            defaultValue={parm1en}
                            onChange={e => setParm1en(e.target.value)}
                            fullWidth
                            required
                        />
                        <TextField
                            variant="outlined"
                            type="text"
                            label="Spanish"
                            margin="dense"
                            defaultValue={parm1es}
                            onChange={e => setParm1es(e.target.value)}
                            fullWidth
                        />
                    </OutlinedSection>
                )}
                {selectedType === CtaType.PopUpMessage && (
                    <>
                        <OutlinedSection title="Popup Title">
                            <TextField
                                variant="outlined"
                                type="text"
                                label="English *"
                                margin="dense"
                                defaultValue={parm1en}
                                onChange={e => setParm1en(e.target.value)}
                                fullWidth
                                required
                            />
                            <TextField
                                variant="outlined"
                                type="text"
                                label="Spanish"
                                margin="dense"
                                defaultValue={parm1es}
                                onChange={e => setParm1es(e.target.value)}
                                fullWidth
                            />
                        </OutlinedSection>
                        <OutlinedSection title="Popup Body">
                            <TextField
                                variant="outlined"
                                type="text"
                                label="English *"
                                margin="dense"
                                defaultValue={parm2en}
                                onChange={e => setParm2en(e.target.value)}
                                fullWidth
                                required
                            />
                            <TextField
                                variant="outlined"
                                type="text"
                                label="Spanish"
                                margin="dense"
                                defaultValue={parm2es}
                                onChange={e => setParm2es(e.target.value)}
                                fullWidth
                            />
                        </OutlinedSection>
                    </>
                )}
                <OutlinedSection title="Select Tags">
                    <Autocomplete
                        multiple
                        value={tagList}
                        options={
                            tags &&
                            tags
                                .slice()
                                .sort((a, b) => {
                                    if (a.name < b.name) return -1;
                                    if (a.name === b.name) return 0;
                                    return 1;
                                })
                                .map(tag => ({ id: tag.id, name: tag.name }))
                        }
                        onChange={(_, val) => {
                            setTagList(val);
                        }}
                        getOptionLabel={tag => tag.name}
                        renderInput={params => (
                            <TextField
                                variant="outlined"
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                label="Tag Filter"
                                placeholder="Select Tags to filter this Call To Action"
                            />
                        )}
                    />
                </OutlinedSection>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={closeHandler} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={onSubmit} color="secondary" variant="contained">
                    {ctaData ? 'Save' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CTAModal;
