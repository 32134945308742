import * as Yup from 'yup';
import { ResponseCalculationEnum, SlaTimerTypeEnum } from '~/schemaTypes';

export const CHAT_SLA_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    timerType: Yup.mixed<SlaTimerTypeEnum>()
        .oneOf(Object.values(SlaTimerTypeEnum))
        .required('SLA timer type is required.'),
    responseCalculation: Yup.mixed<ResponseCalculationEnum>()
        .oneOf(Object.values(ResponseCalculationEnum))
        .required('SLA response calculation type is required.'),
    timeToComplete: Yup.number().min(0).required('time to complete is required'),
    calendarId: Yup.string().required('calendar is required'),
    appBundleId: Yup.string().required('App bundle id is required'),
    careTeamMemberTypeId: Yup.string().required('Care team member type id is required'),
    assignedToId: Yup.string().nullable(),
    // notifyBefore: Yup.array()
    //     .of(
    //         Yup.object().shape({
    //             whenToNotify: Yup.number().min(0),
    //         }),
    //     )
    //     .notRequired(),
});

export type ChatSlaFormInput = {
    name: string;
    timerType: SlaTimerTypeEnum;
    responseCalculation: ResponseCalculationEnum;
    timeToComplete: number;
    calendarId: string;
    appBundleId: string;
    careTeamMemberTypeId: string;
    assignedToId?: string | null;
    // notifyBefore?: {
    //     whenToNotify: number;
    // }[];
};
