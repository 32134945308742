import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material';
import ObjectId from 'bson-objectid';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Loading from '~/components/Loading/Loading';
import toContentPickerForm from '~/components/NonImageContentPicker/helpers/toFormValues';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    ContentType,
    useContentByCriteriaLazyQuery,
    useContentByIdForContentPickerLazyQuery,
} from '~/schemaTypes';
import { CarePlanEnum } from '~/selectors';
import { defaultFormValues } from './helpers/defaultFormValues';
import { useStyles } from './styles';
import {
    NonImageContentFormInput,
    NonImageContentItem,
    NonImageContentPickerParams,
} from './types';

const NonImageContentPicker: React.FC<NonImageContentPickerParams> = ({
    allowedTypes,
    selectedContentId,
    setSelectedContentId,
    setSelectedContentName,
    onEdit,
    setRequired,
    required = false,
    disabled = false,
    compact = false,
    titleText = 'Select Simple Content',
}) => {
    const { classes } = useStyles();
    const isEditMode = Boolean(selectedContentId);
    const [filterDisabled, setFilterDisabled] = useState<boolean>(true);
    const [contentNotFound, setContentNotFound] = useState<boolean>(false);
    const [isFilterInUse, setIsFilterInUse] = useState<boolean>(false);
    const [isContentSelectedByUser, setIsContentSelectedByUser] = useState<boolean>(false);
    const [content, setContent] = useState<NonImageContentItem[]>([]);
    const [defaultContent, setDefaultContent] = useState<NonImageContentItem | null>(null);
    const [types] = useState<ContentType[]>(allowedTypes);
    const [textFilter, setTextFilter] = useState<string>('');
    const [contentId, setContentId] = useState<string>('');
    const [contentType, setContentType] = useState<ContentType | null>(null);

    const isFilterById = Boolean(contentId);
    const isFilterByCriteria = Boolean(textFilter || contentType);
    const isFilterEnabled = isFilterById || isFilterByCriteria;

    const showInfoMessage = useMemo(() => {
        return (!isEditMode && !isFilterInUse) || !defaultContent?.id;
    }, [isEditMode, isFilterInUse, defaultContent?.id]);
    const {
        control,
        register,
        setError,
        clearErrors,
        handleSubmit,

        formState: { errors },
    } = useForm({
        defaultValues: defaultFormValues,
    });

    const setDefaultContentWrapper = useCallback(
        (contentItem: NonImageContentItem | null) => {
            setDefaultContent(null);
            setDefaultContent(state => {
                const isContentItemModified =
                    (onEdit && isFilterInUse && state?.id !== contentItem?.id) ||
                    (onEdit && !contentItem);
                if (isContentItemModified) {
                    onEdit();
                }
                return contentItem;
            });
        },
        [isFilterInUse, setDefaultContent, onEdit],
    );

    const [contentById, { loading: contentByIdLoading }] = useContentByIdForContentPickerLazyQuery({
        onCompleted: ({ simpleContent }) => {
            if (simpleContent && allowedTypes.includes(simpleContent.contentType)) {
                const [content] = toContentPickerForm([simpleContent]);
                setContent(() => [content]);
                setDefaultContentWrapper(content);
            } else {
                setError('contentId', {
                    type: 'custom',
                    message: 'Found content is of the wrong type...',
                });
            }
        },
        onError: () => {
            setContentNotFound(true);
        },
    });
    const [contentByCriteria, { loading: contentByCriteriaLoading }] =
        useContentByCriteriaLazyQuery({
            onCompleted: ({ contentPickerFetch: { data, total } }) => {
                if (data) {
                    const sortedContent = _.sortBy(toContentPickerForm(data), 'name');

                    setContentNotFound(!total);
                    setContent(() => sortedContent);
                    setDefaultContentWrapper(sortedContent[0]);
                }
            },
            fetchPolicy: 'network-only',
        });

    useEffect(() => {
        if (isFilterEnabled) {
            setFilterDisabled(false);
        } else {
            setFilterDisabled(true);
        }
    }, [isFilterEnabled]);

    useEffect(() => {
        if (content.length) {
            setSelectedContentId(content[0]?.id);
        }
    }, [content, setSelectedContentId]);

    useEffect(() => {
        if (content.length && setSelectedContentName) {
            setSelectedContentName(content[0]?.name || '');
        }
    }, [content, setSelectedContentName]);

    useEffect(() => {
        if (isEditMode && !isContentSelectedByUser) {
            contentById({ variables: { input: { id: selectedContentId } } });
        }
    }, [isEditMode, contentById, selectedContentId, isContentSelectedByUser]);

    const handleFilter: SubmitHandler<NonImageContentFormInput> = ({
        contentId,
        contentType,
        textFilter,
    }) => {
        setContentNotFound(false);
        setIsContentSelectedByUser(true);
        setIsFilterInUse(true);
        if (setRequired) setRequired(false);

        if (contentId && !ObjectId.isValid(contentId)) {
            setError('contentId', {
                type: 'custom',
                message: 'Provided id is not a valid ObjectId',
            });
            return;
        }

        if (!contentId) {
            contentByCriteria({
                variables: {
                    input: {
                        ...(textFilter && { searchTerm: textFilter }),
                        ...(contentType ? { types: [contentType] } : { types: allowedTypes }),
                    },
                },
            });
            return;
        }

        contentById({ variables: { input: { id: contentId } } });
    };

    const getContentTypeKeyByValue = (value: string | null) => {
        if (!value) {
            return null;
        }
        const entries = Object.entries(ContentType);
        const [[key]] = entries.filter(([_, val]) => val === value);

        return ContentType[key as keyof typeof ContentType];
    };

    const handleContentChange = (val: { name: string; id: string } | null) => {
        const contentItem = content.find(item => item?.id === val?.id);

        setIsContentSelectedByUser(true);
        setDefaultContentWrapper(contentItem || null);
        setSelectedContentId(val?.id ?? '');
        if (setRequired) setRequired(false);
        if (setSelectedContentName) {
            setSelectedContentName(val?.name ?? '');
        }
    };

    if (contentByIdLoading) {
        return <Loading height={250} subtitle="Fetching Content by id..." />;
    }
    if (contentByCriteriaLoading) {
        return <Loading height={250} subtitle="Fetching Content by criteria..." />;
    }

    return (
        <OutlinedSection title={titleText}>
            <OutlinedSection title="Filter">
                <form onSubmit={handleSubmit(handleFilter)}>
                    <Grid container>
                        <Grid item container direction="column" xs={12} md={compact ? 12 : 10}>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    label="Content Id"
                                    fullWidth
                                    margin="dense"
                                    onFocus={() => clearErrors('contentId')}
                                    className={classes.resetPadding}
                                    {...register('contentId')}
                                    disabled={disabled || isFilterByCriteria}
                                    error={Boolean(errors.contentId)}
                                    helperText={errors.contentId?.message}
                                    value={contentId}
                                    onChange={({ target: { value } }) => setContentId(value)}
                                />
                            </Grid>
                            <Grid item>
                                <Controller
                                    control={control}
                                    name="contentType"
                                    render={({ field: { onChange } }) => (
                                        <Autocomplete
                                            size="small"
                                            disabled={disabled || isFilterById}
                                            value={contentType}
                                            onChange={(_, val) => {
                                                onChange(val);
                                                setContentType(getContentTypeKeyByValue(val));
                                            }}
                                            getOptionLabel={selected => selected}
                                            options={_.sortBy(types)}
                                            isOptionEqualToValue={(option, val) => option === val}
                                            renderInput={params => (
                                                <TextField
                                                    variant="outlined"
                                                    margin="dense"
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    {...params}
                                                    placeholder="Select Type"
                                                    label="Type"
                                                    className={classes.resetPadding}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    label="Text Filter"
                                    fullWidth
                                    margin="dense"
                                    className={classes.resetPadding}
                                    disabled={disabled || isFilterById}
                                    {...register('textFilter')}
                                    value={textFilter}
                                    onChange={({ target: { value } }) => setTextFilter(value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            md={compact ? 12 : 2}
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <Grid item className={classes.filterButtonWrapper}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    disabled={disabled || filterDisabled}
                                    data-test={CarePlanEnum.FILTER}
                                    onClick={handleSubmit(handleFilter)}
                                >
                                    Filter
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </OutlinedSection>
            {content.length > 0 && (
                <Controller
                    control={control}
                    name="content"
                    render={({ field: { onChange } }) => (
                        <Autocomplete
                            disabled={disabled}
                            className={classes.contentSelect}
                            size="small"
                            value={defaultContent}
                            onChange={(_, val) => {
                                onChange(val?.id || null);
                                handleContentChange(val);
                            }}
                            getOptionLabel={selected => selected.name ?? ''}
                            options={content.map(o => ({
                                name: o?.name,
                                id: o?.id,
                            }))}
                            isOptionEqualToValue={(option, val) => option?.id === val?.id}
                            renderInput={params => (
                                <TextField
                                    variant="outlined"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...params}
                                    className={classes.contentTextField}
                                    placeholder="Select Content"
                                    label="Content"
                                    InputLabelProps={{ shrink: true }}
                                    error={required}
                                />
                            )}
                        />
                    )}
                />
            )}
            {contentNotFound && (
                <Grid container justifyContent="center" alignItems="center">
                    <Typography className={classes.emptyState}>
                        Content not found. Please refine your search criteria...
                    </Typography>
                </Grid>
            )}
            {showInfoMessage && (
                <Grid container justifyContent="center" alignItems="center">
                    <Typography className={classes.emptyState}>
                        Use filter to search Content...
                    </Typography>
                </Grid>
            )}
            {required && (
                <Grid container justifyContent="center" alignItems="center">
                    <Typography className={classes.error}>
                        Please search and select a Content
                    </Typography>
                </Grid>
            )}
        </OutlinedSection>
    );
};
export default NonImageContentPicker;
