import * as Yup from 'yup';

export const PIECE_QUESTION_VALIDATION = Yup.object().shape({
    label: Yup.object().shape({
        en: Yup.string().required('Label (en) is required'),
        es: Yup.string(),
    }),
    compoundQuestionId: Yup.string().required('Compound question is required'),
    displayType: Yup.string().required('"Display as" is required'),
});
