import { makeStyles } from 'tss-react/mui';
import { FileInfo, Widget, WidgetAPI } from '@uploadcare/react-widget';
import React, { useEffect, useRef, useState } from 'react';
import { useFetchUploadCareSettingsQuery } from '~/schemaTypes';
import { GlobalImageUpload } from '../../state';

const useStyles = makeStyles()({
    root: {
        [`
            & .uploadcare--widget__button,
            & .uploadcare--widget__dragndrop-area,
            & .uploadcare--widget__text
        `]: {
            display: 'none',
        },
        position: 'fixed',
        zIndex: 100000,
    },
});

type UploadWidgetProps = {
    open: boolean;
    publicKey?: string | null;
    multiple?: boolean;
    isImage: boolean;
};

const UploadWidget: React.FC<UploadWidgetProps> = ({
    publicKey,
    multiple = false,
    isImage,
    open,
}) => {
    const widgetApi = useRef<WidgetAPI>(null);
    const [value, setValue] = useState<string>('');
    const [fileInfo, setFileInfo] = useState<FileInfo | null>(null);
    const { classes } = useStyles();

    const { data: uploadCareSettings } = useFetchUploadCareSettingsQuery();

    publicKey = publicKey || uploadCareSettings?.settings?.uploadCarePublicKey || null;

    useEffect(() => {
        if (widgetApi.current && open) {
            widgetApi.current.openDialog('file');
        }
        if (widgetApi.current && !open) {
            setValue('');
        }
    }, [open]);

    useEffect(() => {
        if (fileInfo) {
            GlobalImageUpload().callback(fileInfo);
            setFileInfo(null);
            setValue(fileInfo.uuid ?? '');
        } else {
            GlobalImageUpload({
                ...GlobalImageUpload(),
                open: false,
                isImage: false,
            });
            setValue('');
        }
    }, [fileInfo]);

    return (
        <div className={classes.root}>
            {publicKey && (
                <Widget
                    ref={widgetApi}
                    publicKey={publicKey}
                    value={value}
                    onChange={(fileInfo: FileInfo) => {
                        setFileInfo(fileInfo);
                    }}
                    onDialogClose={() => {
                        GlobalImageUpload({
                            ...GlobalImageUpload(),
                            open: false,
                            isImage: false,
                        });
                        setValue('');
                    }}
                    multiple={multiple}
                    tabs="file"
                    clearable
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...(isImage && {
                        localeTranslations: {
                            dialog: {
                                tabs: {
                                    file: {
                                        drag: 'drag & drop <br>.png or .jpg files',
                                    },
                                },
                            },
                        },
                    })}
                />
            )}
        </div>
    );
};

export default UploadWidget;
