import React from 'react';
import { Link, Grid, Typography } from '@mui/material';
import useStyles from '../styles';

type AgreementProps = {
    isMobileView: boolean;
};

const AGREEMENT_LINK = 'https://www.wildflowerhealth.com/privacy-policy/wfthpp';

const Agreement: React.FC<AgreementProps> = props => {
    const { isMobileView } = props;
    const { classes } = useStyles();
    return (
        <Grid container className={classes.agreementContainer} justifyContent="center">
            <Grid item md={12} xs={10}>
                <Typography
                    variant="body2"
                    textAlign={isMobileView ? 'center' : 'right'}
                    fontWeight="bold"
                >
                    By clicking &apos;Continue to Schedule Appointment&apos;, you agree to the
                    following:
                    <br />
                    <Link underline="always" href={AGREEMENT_LINK} target="_blank" rel="noreferrer">
                        End User License Agreement and Privacy Policy
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default React.memo(Agreement);
