import React, { useCallback, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Typography, Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOnboarding } from '~/views/AffiliateCare/Onboarding/useOboarding';
import { useUpdateStAndLocationsForAffiliateMutation, AlertSeverity } from '~/schemaTypes';
import { SuppressNextGlobalAlert, TriggerGlobalAlert } from '~/state';
import { CONTACT_INFO_FORM_SCHEMA } from './yupSchema';
import { FormInput } from './types';
import VirtualType from './components/VirtualType/VirtualType';
import InOfficeType from './components/InOfficeType/InOfficeType';
import PatientsHomeType from './components/PatientsHomeType/PatientsHomeType';

const ServiceTypesAndLocations: React.FC = () => {
    const { setIsValidStep, affiliate, setStepCb, setLoading } = useOnboarding();
    const isValidUpdate = useRef(true);
    const formData = useForm<FormInput>({
        resolver: yupResolver(CONTACT_INFO_FORM_SCHEMA as any),
        defaultValues: {
            isVirtualType: affiliate?.isVirtualType || false,
            isInOfficeType: affiliate?.isInOfficeType || false,
            isPatientsHomeType: affiliate?.isPatientsHomeType || false,
            officeAddresses: affiliate?.officeAddresses || [],
            coverageAreas: affiliate?.coverageAreas || [],
        },
    });
    const {
        formState: { isValid, isDirty },
        handleSubmit,
    } = formData;
    const [updateData] = useUpdateStAndLocationsForAffiliateMutation();
    useEffect(() => {
        setIsValidStep(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onSubmit = useCallback(
        async (data: FormInput) => {
            if (affiliate) {
                try {
                    SuppressNextGlobalAlert(true);
                    const response = await updateData({
                        variables: {
                            input: {
                                onboarding: true,
                                id: affiliate?.id,
                                data: {
                                    ...data,
                                },
                            },
                        },
                    });
                    isValidUpdate.current =
                        !!response.data?.updateSTAndLocationsForAffiliate?.success;
                    if (!response.data?.updateSTAndLocationsForAffiliate?.success) {
                        SuppressNextGlobalAlert(false);
                        throw new Error(response.data?.updateSTAndLocationsForAffiliate?.message);
                    }
                } catch (err) {
                    isValidUpdate.current = false;
                    if (err instanceof Error) {
                        TriggerGlobalAlert({
                            message: err.message,
                            severity: AlertSeverity.Error,
                        });
                    }
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [affiliate],
    );
    const stepCb = useCallback(async () => {
        setLoading(true);
        await handleSubmit(onSubmit)();
        setLoading(false);
        return isValid && isValidUpdate.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSubmit, isValid, isDirty]);
    useEffect(() => {
        setStepCb(() => async () => stepCb());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepCb]);
    return (
        <FormProvider {...formData}>
            <Grid container item xs={12} rowSpacing={5}>
                <Grid item xs={12} container rowSpacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            What types of Lactation Services will you provide? (Select All That
                            Apply)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} container>
                        <VirtualType />
                    </Grid>
                    <Grid item xs={12} container>
                        <InOfficeType />
                    </Grid>
                    <Grid item xs={12} container>
                        <PatientsHomeType deleteConfirm />
                    </Grid>
                </Grid>
            </Grid>
        </FormProvider>
    );
};

export default ServiceTypesAndLocations;
