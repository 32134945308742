import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { OrderByDirectionEnum, useFetchPatientsForAdvocateTaskPageLazyQuery } from '~/schemaTypes';
import { Patient } from './PatientPicker.types';

const rowsPerPage = 50;
const OrderByField = 'firstName';
export default (query: string, pageNumber: number) => {
    const [patients, setPatients] = useState<Patient[]>([]);
    const [getPatients, { loading, data }] = useFetchPatientsForAdvocateTaskPageLazyQuery();

    useEffect(() => setPatients([]), [query]);

    const fetchPatients = useCallback(
        async (_query: string, _pageNumber: number) => {
            const [firstName, lastName] = _query.split(' ');

            const isFullName = Boolean(
                firstName && firstName && firstName !== '' && firstName !== '',
            );

            return getPatients({
                variables: {
                    input: {
                        pagination: {
                            skip: _pageNumber * rowsPerPage,
                            limit: rowsPerPage,
                        },
                        orderBy: {
                            field: OrderByField,
                            order: OrderByDirectionEnum.Asc,
                        },
                        ...(isFullName
                            ? {
                                  filter: {
                                      fields: { firstName, lastName },
                                  },
                              }
                            : {
                                  filterOr: {
                                      firstName: _query,
                                      lastName: _query,
                                  },
                              }),
                    },
                },
            });
        },
        [getPatients],
    );
    const loadPatients = useCallback(
        async (_query: string, _pageNumber: number) => {
            const { data: patientsData } = await fetchPatients(_query, _pageNumber);

            setPatients(prevPatients =>
                _.uniqBy(
                    [...prevPatients, ...(patientsData?.currentUser?.patientsV2.results ?? [])],
                    'id',
                ),
            );
        },
        [fetchPatients],
    );

    useEffect(() => {
        loadPatients(query, pageNumber);
    }, [query, loadPatients, pageNumber]);

    const reset = useCallback(async () => {
        setPatients([]);
        const emptyQuery = '';
        const emptyPageNumber = 0;
        await loadPatients(emptyQuery, emptyPageNumber);
    }, [loadPatients]);

    return {
        patients,
        loading,
        hasMore: data?.currentUser?.patientsV2.hasMore,
        fetchPatients,
        reset,
    };
};
