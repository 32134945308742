import {
    Avatar,
    Autocomplete,
    Badge,
    Button,
    CardActions,
    CardContent,
    CardHeader,
    Fade,
    Grid,
    IconButton,
    Tooltip,
    TextField,
    Link,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AddPhotoAlternateOutlined, Close, Launch } from '@mui/icons-material';
import { FileInfo } from '@uploadcare/react-widget';
import _ from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import useUserPermissions from '~/hooks/useUserPermissions';
import {
    AlertSeverity,
    GetChatRoomInput,
    FetchChatRoomMessagesForMessageCenterDocument,
    FetchChatRoomMessagesForMessageCenterQuery,
    FetchCurrentUserForUseUserHookDocument,
    useCreateChatMessageForMessageCenterMutation,
    useFetchCurrentUserForChatMessagesQuery,
    useUpdateReadMessagesForChatRoomMutation,
    ChatConversationsV2ForMessageCenterDocument,
    OrderByDirectionEnum,
    ChatConversationsV2ForMessageCenterQuery,
    useMessageCenterTemplatesQuery,
    ChatConversationsV2ForMessageCenterQueryVariables,
    useSubscribeToNewChatMessagesForChatRoomSubscription,
    NewMessageInput,
    FetchChatRoomMessagesForMessageCenterQueryVariables,
    Language,
    useSetChatTypingForChatMessagesMutation,
} from '~/schemaTypes';
import { SuppressNextGlobalAlert, TriggerGlobalAlert } from '~/state';
import { toBase64 } from '~/helpers/base64Helper';
import RichTextEditor, { RichTextEditorCommands } from '~/components/RichTextEditor/RichTextEditor';
import { useAtom } from 'jotai';
import Loading from '~/components/Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk, faWrench } from '@fortawesome/free-solid-svg-icons';
import { cognitoRefreshTokens } from '~/state/auth/cognito.svc';
import ChatConversations from './ChatConversations/ChatConversations';
import { conversationsLoadedAtom } from '../MessageCenter.context';
import { messageCenterTokensResolver } from './functions/messageCenterTokensResolver';
import { ChatConversation } from '../MessageCenter.types';
import TypingMessages from './TypingMessages/TypingMessages';

const useStyles = makeStyles()(theme => ({
    root: {
        display: 'grid',
        maxHeight: '75vh',
        gridTemplateRows: '65px 1fr auto',
        gridTemplateColumns: 'auto',
        gridTemplateAreas: `
            "title"
            "messages"
            "actions"
        `,
        '& .title': {
            borderBottom: '1px solid lightgray',
        },
        '& .messages': {
            overflowY: 'auto',
        },
        '& .actions': {
            borderTop: '1px solid lightgray',
        },
    },
    imagePreviews: {
        border: '2px solid lightgray',
        padding: 3,
        borderRadius: 15,
        height: 75,
        width: 75,
    },
    deletePhotoIcon: {
        background: '#fff',
        boxShadow: theme.shadows[1],
        transition: 'color .2s',
        '&:hover': {
            opacity: 1,
            background: '#fff',
            color: theme.colors.ErrorRed,
        },
    },
    actionWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    patientLink: {
        display: 'inline-flex',
        padding: '10px',
    },
}));

type ChatMessagesProps = {
    chatRoomData: FetchChatRoomMessagesForMessageCenterQuery['chatRoom'];
    getChatRoomInput?: GetChatRoomInput;
    setRefreshTicks?: (ticks: number) => void;
};

let timeoutId: number | undefined;

const debounce = (callback: () => void, delay: number) => {
    return () => {
        clearTimeout(timeoutId);
        timeoutId = window.setTimeout(callback, delay);
    };
};

const ChatMessages = ({ chatRoomData, getChatRoomInput, setRefreshTicks }: ChatMessagesProps) => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const [messageText, setMessageText] = useState<string>('');
    const [attachments, setAttachments] = useState<Array<{ uuid: string; label: string }>>([]);
    const [clearEditor, setClearEditor] = useState<boolean>(false);
    const [textEnEditorCommands, setTextEnEditorCommands] = useState<RichTextEditorCommands | null>(
        null,
    );
    const [messageTemplateId, setMessageTemplateId] = useState<string>('');
    const [messageTemplateName, setMessageTemplateName] = useState<string>('');
    const messagesCont = useRef<HTMLDivElement>(null);
    const chatRoomId = useMemo(() => chatRoomData?.id, [chatRoomData?.id]);
    const [chatConversationsLoaded] = useAtom(conversationsLoadedAtom);

    const { data: currentUserData } = useFetchCurrentUserForChatMessagesQuery();

    const { data: messageCenterTemplateData, loading: messageCenterTemplateDataLoading } =
        useMessageCenterTemplatesQuery();

    const [setChatTyping] = useSetChatTypingForChatMessagesMutation();

    const appBundleId = chatRoomData?.patient?.appBundleId ?? '';
    const patientFullName = chatRoomData?.patient?.fullName ?? '';
    const orgName = chatRoomData?.patient?.practice?.name ?? '';
    const haName = chatRoomData?.patient?.practice?.healthAdvocateTitle ?? '';
    const language = chatRoomData?.patient?.language ?? Language.En;

    // filtering message templates by include/exclude apps
    const messageTemplates = messageCenterTemplateData?.messageCenterTemplates?.filter(template => {
        const excludeApps = template.excludeApps ?? [];
        const includeApps = template.includeApps ?? [];

        if (excludeApps.length > 0 && excludeApps.includes(appBundleId)) {
            return false;
        }
        if (includeApps.length > 0 && !includeApps.includes(appBundleId)) {
            return false;
        }
        return true;
    });

    const refreshHandler = () => {
        cognitoRefreshTokens();
    };

    const subscribeToNewMessagesInput: NewMessageInput = useMemo(() => {
        return {
            chatRoomId,
            ...(chatRoomId === '' && { chatRoomId: chatRoomData?.id }),
        };
    }, [chatRoomId, chatRoomData?.id]);

    useSubscribeToNewChatMessagesForChatRoomSubscription({
        variables: {
            input: subscribeToNewMessagesInput,
        },
        shouldResubscribe: true,
        onData: async ({ client, data }) => {
            const { cache } = client;
            const newOrUpdatedMessage = data.data?.newMessage;
            const chatRoomQuery = cache.readQuery<
                FetchChatRoomMessagesForMessageCenterQuery,
                FetchChatRoomMessagesForMessageCenterQueryVariables
            >({
                query: FetchChatRoomMessagesForMessageCenterDocument,
                variables: {
                    input: {
                        id: chatRoomId,
                    },
                },
            });
            if (chatRoomQuery?.chatRoom && newOrUpdatedMessage) {
                cache.writeQuery<FetchChatRoomMessagesForMessageCenterQuery>({
                    query: FetchChatRoomMessagesForMessageCenterDocument,
                    data: {
                        ...chatRoomQuery,
                        chatRoom: {
                            ...chatRoomQuery.chatRoom,
                            messages: [
                                ...chatRoomQuery.chatRoom.messages.map(currentMessage => {
                                    if (currentMessage.id === newOrUpdatedMessage.id) {
                                        return newOrUpdatedMessage;
                                    }
                                    return currentMessage;
                                }),
                                ...(!_.map(chatRoomQuery.chatRoom.messages, 'id').includes(
                                    newOrUpdatedMessage.id,
                                )
                                    ? [newOrUpdatedMessage]
                                    : []),
                            ],
                        },
                    },
                });
            }
            if (newOrUpdatedMessage?.senderId !== currentUserData?.currentUser?.id) {
                // check if the user sent the message
                const chatConversationsQuery = cache.readQuery<
                    ChatConversationsV2ForMessageCenterQuery,
                    ChatConversationsV2ForMessageCenterQueryVariables
                >({
                    query: ChatConversationsV2ForMessageCenterDocument,
                    variables: {
                        input: {
                            filter: {
                                fields: {
                                    chatRoomId,
                                },
                            },
                            orderBy: {
                                field: 'createdAt',
                                order: OrderByDirectionEnum.Asc,
                            },
                        },
                    },
                });
                if (chatConversationsQuery?.chatConversationsV2.results && newOrUpdatedMessage) {
                    const chatConversation =
                        chatConversationsQuery.chatConversationsV2.results.find(
                            conversation =>
                                conversation.id === newOrUpdatedMessage.chatConversationId,
                        );
                    const updatedConversation = {
                        ...chatConversation,
                        ...(chatConversation?.startedAt == null
                            ? { startedAt: newOrUpdatedMessage.createdAt }
                            : {}),
                    } as ChatConversation;
                    const messageExists = updatedConversation?.messages?.find(
                        message => message.id === newOrUpdatedMessage.id,
                    );
                    let updatedMessages;
                    if (messageExists != null) {
                        updatedMessages = updatedConversation?.messages;
                    } else {
                        updatedMessages = updatedConversation?.messages
                            ? [...updatedConversation.messages, newOrUpdatedMessage]
                            : [newOrUpdatedMessage];
                    }
                    const updatedConversations = [
                        ...chatConversationsQuery.chatConversationsV2.results.filter(
                            res => res.id !== updatedConversation?.id,
                        ),
                        ...(updatedConversation != null
                            ? [
                                  {
                                      ...updatedConversation,
                                      messages: updatedMessages,
                                  },
                              ]
                            : []),
                    ].sort(
                        (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
                    );
                    cache.writeQuery<
                        ChatConversationsV2ForMessageCenterQuery,
                        ChatConversationsV2ForMessageCenterQueryVariables
                    >({
                        query: ChatConversationsV2ForMessageCenterDocument,
                        variables: {
                            input: {
                                filter: {
                                    fields: {
                                        chatRoomId,
                                    },
                                },
                                orderBy: {
                                    field: 'createdAt',
                                    order: OrderByDirectionEnum.Asc,
                                },
                            },
                        },
                        data: {
                            ...chatConversationsQuery,
                            chatConversationsV2: {
                                ...chatConversationsQuery.chatConversationsV2,
                                results: updatedConversations,
                            },
                        },
                    });
                }
            }
        },
    });

    const setMessageTextHandler = async (value: string) => {
        setMessageText(value);

        const setTyping = async () => {
            try {
                if (value !== '') {
                    await setChatTyping({
                        variables: {
                            input: {
                                chatRoomId,
                                userId: currentUserData?.currentUser?.id,
                            },
                        },
                    });
                }
            } catch (err) {
                if (err instanceof Error) {
                    TriggerGlobalAlert({
                        message: err.message,
                        severity: AlertSeverity.Error,
                    });
                }
            }
        };

        debounce(setTyping, 2000)();
    };

    const [sendMessage, { loading: sendMessageLoading }] =
        useCreateChatMessageForMessageCenterMutation({
            onCompleted: data => {
                if (data.createChatMessageEncoded?.success) {
                    setMessageTextHandler('');
                    textEnEditorCommands?.commands.clearContent();
                    setAttachments([]);
                }
            },
            update: (cache, response) => {
                const newMessage = response.data?.createChatMessageEncoded?.chatMessage;
                if (newMessage) {
                    if (getChatRoomInput) {
                        const messageCenterQuery =
                            cache.readQuery<FetchChatRoomMessagesForMessageCenterQuery>({
                                query: FetchChatRoomMessagesForMessageCenterDocument,
                                variables: {
                                    input: getChatRoomInput,
                                },
                            });
                        if (messageCenterQuery?.chatRoom?.id && newMessage) {
                            const alreadyInCache = messageCenterQuery.chatRoom?.messages.find(
                                msg => msg.id === newMessage.id,
                            );
                            cache.writeQuery<FetchChatRoomMessagesForMessageCenterQuery>({
                                query: FetchChatRoomMessagesForMessageCenterDocument,
                                variables: { input: getChatRoomInput },
                                data: {
                                    ...messageCenterQuery,
                                    chatRoom: {
                                        ...messageCenterQuery.chatRoom,
                                        messages: alreadyInCache
                                            ? messageCenterQuery.chatRoom.messages
                                            : [...messageCenterQuery.chatRoom.messages, newMessage],
                                    },
                                },
                            });
                        }
                    }
                    const chatConversationsQuery =
                        cache.readQuery<ChatConversationsV2ForMessageCenterQuery>({
                            query: ChatConversationsV2ForMessageCenterDocument,
                            variables: {
                                input: {
                                    filter: {
                                        fields: {
                                            chatRoomId,
                                        },
                                    },
                                    orderBy: {
                                        field: 'createdAt',
                                        order: OrderByDirectionEnum.Asc,
                                    },
                                },
                            },
                        });
                    if (chatConversationsQuery?.chatConversationsV2.results && newMessage) {
                        let chatConversation =
                            chatConversationsQuery.chatConversationsV2.results.find(
                                conversation => conversation.id === newMessage.chatConversationId,
                            );
                        if (chatConversation == null) {
                            chatConversation = {
                                __typename: 'ChatConversation',
                                id: newMessage.chatConversationId,
                                chatRoomId: newMessage.chatRoomId,
                                createdAt: new Date(),
                                startedAt: new Date(),
                                resolvedAt: null,
                                resolutionTime: null,
                                resolvedById: null,
                                resolvedByName: null,
                            };
                        }
                        const updatedMessages = chatConversation?.messages
                            ? [...chatConversation.messages, newMessage]
                            : [newMessage];
                        if (chatConversation) {
                            cache.writeQuery<ChatConversationsV2ForMessageCenterQuery>({
                                query: ChatConversationsV2ForMessageCenterDocument,
                                variables: {
                                    input: {
                                        filter: {
                                            fields: {
                                                chatRoomId,
                                            },
                                        },
                                        orderBy: {
                                            field: 'createdAt',
                                            order: OrderByDirectionEnum.Asc,
                                        },
                                    },
                                },
                                data: {
                                    ...chatConversationsQuery,
                                    chatConversationsV2: {
                                        ...chatConversationsQuery.chatConversationsV2,
                                        results: [
                                            ...chatConversationsQuery.chatConversationsV2.results.filter(
                                                res => res.id !== chatConversation?.id,
                                            ),
                                            {
                                                ...chatConversation,
                                                messages: updatedMessages,
                                            },
                                        ],
                                    },
                                },
                            });
                        }
                    }
                }
            },
        });

    const [updateReadMessages] = useUpdateReadMessagesForChatRoomMutation({
        refetchQueries: [{ query: FetchCurrentUserForUseUserHookDocument }],
        onCompleted: () => {
            SuppressNextGlobalAlert(true);
        },
    });

    useEffect(() => {
        SuppressNextGlobalAlert(true);
        updateReadMessages({
            variables: {
                input: {
                    chatRoomId,
                    unreadMessages: false,
                },
            },
        });
    }, [chatRoomId, updateReadMessages]);

    const handleSendMessage = (e: any) => {
        e.preventDefault();
        if (!messageText) {
            return;
        }
        const resolvedText = messageCenterTokensResolver(
            messageText,
            patientFullName,
            orgName,
            haName,
        );
        const encodedMessage = toBase64(resolvedText);

        sendMessage({
            variables: {
                input: {
                    text: encodedMessage,
                    chatRoomId,
                    attachments: _.map(attachments, 'uuid'),
                    messageTemplateId: messageTemplateId.length > 0 ? messageTemplateId : undefined,
                    messageTemplateName,
                },
            },
        });
        setMessageTemplateId('');
        setMessageTemplateName('');
        setClearEditor(true);
    };

    const setMessageTemplate = (val: string) => {
        const template = messageTemplates?.find(item => item.id === val);

        if (template) {
            const messageString = `${messageText}\n\n${template.content[language]}`;

            setMessageText(messageString);
            setMessageTemplateName(template.title || '');

            textEnEditorCommands?.commands.setContent(messageString);
        }
    };

    const scrollToLastMessage = useCallback(
        (val?: 'smooth') => {
            if (messagesCont?.current) {
                messagesCont.current.scrollTo({
                    top: messagesCont.current.scrollHeight,
                    behavior: val,
                });
            }
        },
        [messagesCont],
    );

    useLayoutEffect(() => {
        scrollToLastMessage();
    }, [chatConversationsLoaded, chatRoomId, scrollToLastMessage]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onFileUpload = (file: FileInfo) => {
        const newAttachment = file.uuid
            ? {
                  uuid: file.uuid,
                  label: file.name || 'new-file',
              }
            : null;
        if (newAttachment) {
            setAttachments(prevAttachments => [...prevAttachments, newAttachment]);
        }
    };

    const handleDeleteAttachment = (id: string) => {
        if (id) {
            const filteredAttachments = attachments.filter(item => item.uuid !== id);
            setAttachments(filteredAttachments);
        }
    };
    const handleMarkUnread = () => {
        updateReadMessages({
            variables: {
                input: {
                    chatRoomId,
                    unreadMessages: true,
                },
            },
        });
        if (setRefreshTicks) {
            setRefreshTicks(new Date().getTime());
        }
    };

    if (chatRoomId == null || messageCenterTemplateDataLoading) {
        return <Loading subtitle="Fetching chatRoomId" />;
    }

    return (
        <div className={`${classes.root} main`}>
            <CardHeader
                className="title"
                title={`${chatRoomData?.patient.firstName} ${chatRoomData?.patient.lastName} (${chatRoomData?.patient?.practice?.name} - ${chatRoomData?.careTeamMemberType.name})`}
                action={
                    <div className={classes.actionWrapper}>
                        {pagePermissions?.PatientDetails.Read && (
                            <Tooltip title="View Patient">
                                <Link
                                    target="_blank"
                                    variant="inherit"
                                    color="inherit"
                                    aria-label="view patient"
                                    href={`/portal/patients/${chatRoomData?.patient.id}/patient-details`}
                                    rel="noopener noreferrer"
                                    className={classes.patientLink}
                                >
                                    <Launch />
                                </Link>
                            </Tooltip>
                        )}
                        <Tooltip title="Mark Unread">
                            <Button
                                startIcon={<FontAwesomeIcon icon={faWrench} />}
                                color="secondary"
                                variant="contained"
                                onClick={() => handleMarkUnread()}
                            >
                                Mark Unread
                            </Button>
                        </Tooltip>
                    </div>
                }
            />
            <CardContent className="messages" ref={messagesCont}>
                <ChatConversations
                    chatRoom={chatRoomData ?? null}
                    currentUserId={currentUserData?.currentUser?.id}
                    chatRoomId={chatRoomId}
                    scrollToLastMessage={scrollToLastMessage}
                />
                <TypingMessages
                    chatRoomId={chatRoomId}
                    currentUserId={currentUserData?.currentUser?.id}
                    scrollToLastMessage={scrollToLastMessage}
                />
            </CardContent>
            <CardActions className="actions">
                <form style={{ width: '100%' }} onSubmit={handleSendMessage}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item xs={8} sm={9} lg={10}>
                            <div style={{ fontWeight: 'bold' }}>
                                Language Preference is{' '}
                                {language === Language.En ? 'English' : 'Spanish'}
                            </div>
                            <RichTextEditor
                                onInit={setTextEnEditorCommands}
                                initialValue={messageText}
                                onChange={(value: string) => {
                                    debounce(refreshHandler, 10000)();
                                    setMessageTextHandler(value);
                                }}
                                disabled={
                                    !pagePermissions?.MessageCenter.Edit || sendMessageLoading
                                }
                                clear={clearEditor}
                                setClear={setClearEditor}
                                allowImages={false}
                            />
                            <Grid item xs={8} sm={9} lg={10} style={{ marginTop: 20 }}>
                                <Autocomplete
                                    size="small"
                                    onChange={(_, val) => {
                                        setMessageTemplate(val);
                                        setMessageTemplateId(val);
                                    }}
                                    options={
                                        messageTemplates
                                            ?.sort((a, b) => {
                                                const titleA = a.title || '';
                                                const titleB = b.title || '';
                                                return titleA.localeCompare(titleB);
                                            })
                                            ?.map((item: any) => item.id) ?? []
                                    }
                                    getOptionLabel={id =>
                                        messageTemplates?.find(item => item.id === id)?.title ?? ''
                                    }
                                    isOptionEqualToValue={(option, val) => option === val}
                                    value={messageTemplateId}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                            placeholder="Select Message template"
                                            label="Message Template"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid item style={{ display: 'none' }}>
                            <IconButton
                                size="medium"
                                color="primary"
                                disabled={
                                    !pagePermissions?.MessageCenter.Edit || sendMessageLoading
                                }
                                onClick={() =>
                                    TriggerGlobalAlert({
                                        message: 'Not yet implemented',
                                        severity: AlertSeverity.Warning,
                                    })
                                }
                            >
                                <AddPhotoAlternateOutlined />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <AsyncActionButton loading={sendMessageLoading}>
                                <Button
                                    disabled={
                                        !pagePermissions?.MessageCenter.Edit || sendMessageLoading
                                    }
                                    type="submit"
                                    size="medium"
                                    startIcon={<FontAwesomeIcon icon={faMailBulk} />}
                                    color="secondary"
                                    variant="contained"
                                >
                                    Send Message
                                </Button>
                            </AsyncActionButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4} style={{ marginLeft: 5 }}>
                                {attachments.map(({ uuid, label }) => (
                                    <Fade key={uuid} in timeout={500}>
                                        <Grid item>
                                            <Badge
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                badgeContent={
                                                    <IconButton
                                                        className={classes.deletePhotoIcon}
                                                        size="small"
                                                        edge="end"
                                                        onClick={() => handleDeleteAttachment(uuid)}
                                                    >
                                                        <Close />
                                                    </IconButton>
                                                }
                                            >
                                                <Avatar
                                                    className={classes.imagePreviews}
                                                    variant="square"
                                                    src={`https://ucarecdn.com/${uuid}/`}
                                                    alt={label}
                                                />
                                            </Badge>
                                        </Grid>
                                    </Fade>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </CardActions>
        </div>
    );
};

export default ChatMessages;
