import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import history from 'history/browser';

const useQueryParams = (): [
    URLSearchParams,
    React.Dispatch<React.SetStateAction<Record<string, any>>>,
] => {
    const location = useLocation();
    const [query, setQuery] = useState<Record<string, any>>(
        Object.fromEntries(new URLSearchParams(location.search)),
    );

    useEffect(() => {
        const newParamString = new URLSearchParams(query);
        history.replace({
            pathname: location.pathname,
            search: newParamString.toString(),
        });
    }, [location.pathname, query]);

    return [new URLSearchParams(location.search), setQuery];
};

export default useQueryParams;
