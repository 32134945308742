import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardHeader, Grid, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowBack } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { useUserPermissions } from '~/hooks';
import { TriggerGlobalConfirm } from '~/state';
import {
    GetMassUpdatePatientListsDocument,
    useCreateMassUpdatePatientListMutation,
    useGetMassUpdatePatientListLazyQuery,
    useUpdateMassUpdatePatientListMutation,
} from '~/schemaTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import ObjectId from 'bson-objectid';
import PatientsInList from './PatientInList';

const useStyles = makeStyles()(theme => ({
    root: {},
    fab: {
        position: 'absolute',
        top: theme.spacing(12),
        right: theme.spacing(4),
    },
}));

const Validation = Yup.object().shape({
    name: Yup.string().required('Required'),
});

const MassUpdatePatientListEditor: React.FC = () => {
    const history = useNavigate();
    const { classes } = useStyles();
    const { id: urlId } = useParams<{ id: string }>();
    const [isModified, setIsModified] = useState(false);
    const [close, setClose] = useState(false);
    const [patientIds, setPatientIds] = useState<string[]>([]);
    const { pagePermissions } = useUserPermissions();
    const canEdit = pagePermissions?.MassUpdate.Edit || false;
    const isEditMode = urlId !== 'new';
    const [listId, setListId] = useState(isEditMode && urlId ? urlId : null);
    let title = isEditMode ? 'Edit Patient List' : 'Create Patient List';
    if (!canEdit) title = 'Patient List Details';

    const [fetchList, { data: listData, loading: listDataLoading }] =
        useGetMassUpdatePatientListLazyQuery();

    const [updateList, { loading: updateListLoading }] = useUpdateMassUpdatePatientListMutation({
        onCompleted: () => {
            if (close) history(`/system-admin/massupdate`);
        },
        refetchQueries: [
            {
                query: GetMassUpdatePatientListsDocument,
                variables: { input: {} },
            },
        ],
    });

    const [createList, { loading: createListLoading }] = useCreateMassUpdatePatientListMutation({
        onCompleted: ({ createMassUpdatePatient }) => {
            if (close) {
                history(`/system-admin/massupdate`);
            } else {
                setListId(createMassUpdatePatient?.resourceCreated?.id);
                history(`/system-admin/massupdate/${createMassUpdatePatient?.resourceCreated?.id}`);
            }
        },
        refetchQueries: [
            {
                query: GetMassUpdatePatientListsDocument,
                variables: { input: {} },
            },
        ],
    });

    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(Validation as any),
    });

    useEffect(() => {
        if (isEditMode) {
            fetchList({ variables: { input: { id: listId } } });
            if (listData && listData.massUpdatePatient) {
                setValue('name', listData.massUpdatePatient.name);
                setPatientIds(listData.massUpdatePatient.patients || []);
            }
        }
    }, [fetchList, isEditMode, listId, listData, setValue]);

    const onSubmit = (values: any) => {
        if (isEditMode && listId) {
            updateList({
                variables: {
                    input: {
                        id: listId,
                        data: {
                            name: values.name,
                            patients: patientIds.map(p => ObjectId.createFromHexString(p)),
                        },
                    },
                },
            });
        } else {
            createList({
                variables: {
                    input: {
                        name: values.name,
                        patients: patientIds.map(p => ObjectId.createFromHexString(p)),
                    },
                },
            });
        }
        setIsModified(false);
    };
    const onNavigateAway = () => {
        if (isModified)
            TriggerGlobalConfirm({
                message: `You have unsaved changes. Are you sure you want to return to Patient Lists?`,
                callback: () => {
                    history(`/system-admin/massupdate/`);
                },
            });
        else history(`/system-admin/massupdate/`);
    };
    const onEdit = () => {
        setIsModified(true);
    };
    if (listDataLoading || updateListLoading || createListLoading) return <Loading />;
    return (
        <div style={{ marginLeft: '15px' }}>
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12}>
                    <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                        Back to Patient Lists
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Card>
                            <CardHeader title={title} />
                            <OutlinedSection title="Name *">
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    {...register('name')}
                                    error={!!errors.name}
                                    onChange={onEdit}
                                    disabled={!canEdit}
                                />
                            </OutlinedSection>
                            {isEditMode && listId && (
                                <OutlinedSection title="Patients">
                                    <PatientsInList
                                        patientIds={patientIds}
                                        setPatientIds={setPatientIds}
                                        listId={listId}
                                    />
                                </OutlinedSection>
                            )}
                            {canEdit && (
                                <div style={{ width: '100%', textAlign: 'right' }}>
                                    <Button
                                        type="submit"
                                        startIcon={<FontAwesomeIcon icon={faSave} />}
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => handleSubmit(onSubmit)}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        type="submit"
                                        startIcon={<FontAwesomeIcon icon={faSave} />}
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => {
                                            setClose(true);
                                            handleSubmit(onSubmit);
                                        }}
                                    >
                                        Save &amp; Close
                                    </Button>
                                </div>
                            )}
                        </Card>
                    </form>
                </Grid>
            </Grid>
        </div>
    );
};

export default MassUpdatePatientListEditor;
