import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Button, DialogContent, InputLabel } from '@mui/material';
import { AlertSeverity, useCreateClaimsViaFileMutation } from '~/schemaTypes';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import { TriggerGlobalAlert } from '~/state';
import useStyles from './styles/submitClaimsFiles';

export interface IClaimsFileFormInput {
    fileName: string;
    isSubmitted: boolean;
    claimsFile: FileList | null;
}

type SubmitClaimsModalProps = {
    onComplete: () => void;
    orgId: string;
};

const SubmitClaimsFile: React.FC<SubmitClaimsModalProps> = props => {
    const { classes } = useStyles();
    const { register, handleSubmit, reset } = useForm<IClaimsFileFormInput>();
    const [claimsFileFormData, setClaimsFileFormData] = useState<IClaimsFileFormInput>({
        fileName: '',
        isSubmitted: false,
        claimsFile: null,
    });
    const { onComplete, orgId } = props;
    const [submitClaimsViaFile, { loading: createLoading }] = useCreateClaimsViaFileMutation({
        onCompleted: () => {
            onComplete();
        },
    });
    const submitFile = useCallback(
        (data: IClaimsFileFormInput) => {
            if (data.claimsFile) {
                const reader = new FileReader();
                const file = data.claimsFile[0];
                reader.onload = async () => {
                    if (reader.result) {
                        const buffer = reader.result as unknown as string[];
                        try {
                            await submitClaimsViaFile({
                                variables: {
                                    input: {
                                        file: buffer,
                                        organizationId: orgId,
                                    },
                                },
                            });
                        } catch (err: any) {
                            TriggerGlobalAlert({
                                severity: AlertSeverity.Error,
                                message: JSON.stringify(err?.message),
                            });
                        }
                    }
                };
                reader.readAsText(file, 'UTF-8');
            }
        },
        [orgId, submitClaimsViaFile],
    );
    const handleChange = useCallback(
        async ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
            if (files && files.length) {
                const file = files[0];
                setClaimsFileFormData(state => ({
                    ...state,
                    fileName: file.name,
                }));
            }
        },
        [],
    );

    const handleReset = useCallback(() => {
        setClaimsFileFormData(state => ({
            ...state,
            fileName: '',
            claimsFile: null,
            isSubmitted: false,
        }));
        onComplete();
        reset({}, { keepErrors: true });
    }, [onComplete, reset]);

    return (
        <div>
            <DialogContent>
                <form
                    id="form"
                    onSubmit={handleSubmit(submitFile)}
                    onReset={handleReset}
                    className={classes.container}
                >
                    <Grid container className={classes.containerItem}>
                        <Grid item xs={6}>
                            <Button color="secondary" variant="outlined" component="label">
                                Select Invoice
                                <input
                                    {...register('claimsFile')}
                                    className={classes.inputFile}
                                    type="file"
                                    accept=".csv"
                                    onChange={handleChange}
                                />
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel>{claimsFileFormData.fileName}</InputLabel>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item>
                            <Button variant="outlined" color="secondary" onClick={handleReset}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <AsyncActionButton loading={createLoading}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit(submitFile)}
                                    disabled={!claimsFileFormData.fileName || createLoading}
                                >
                                    Submit Invoice to Waystar
                                </Button>
                            </AsyncActionButton>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </div>
    );
};

export default SubmitClaimsFile;
