import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    PatientNoteTagListDocument,
    useCreatePatientNoteTagMutation,
    useUpdatePatientNoteTagMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { PatientNoteTag } from '../PatientNoteTags';

export interface PatientNoteTagInput {
    name: string;
}

const ValidationScheme = Yup.object().shape({
    name: Yup.string().required('Required'),
});

export const toFormValuesFromItem = (item: PatientNoteTag): PatientNoteTagInput => ({
    name: item ? item.name : '',
});

export const EditPatientNoteTagModal: React.FC<{
    isOpen: boolean;
    item?: PatientNoteTag;
    onSave: () => void;
    onCancel: () => void;
}> = props => {
    const { isOpen, item, onSave, onCancel } = props;
    const handleClose = () => {
        onCancel();
    };
    const {
        control,
        handleSubmit: handleFormSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ValidationScheme as any),
    });

    useEffect(() => {
        if (item) reset(toFormValuesFromItem(item));
    }, [reset, item]);

    const [createNoteTag] = useCreatePatientNoteTagMutation({
        onError: error => {
            TriggerGlobalConfirm({
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                callback: () => {},
                message: `There was a problem saving the Note Tag: ${error.message}`,
            });
        },
        onCompleted: () => {
            onSave();
        },
        refetchQueries: [{ query: PatientNoteTagListDocument }],
    });

    const [updateNoteTag] = useUpdatePatientNoteTagMutation({
        onError: error => {
            TriggerGlobalConfirm({
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                callback: () => {},
                message: `There was a problem saving the Note Tag: ${error.message}`,
            });
        },
        onCompleted: () => {
            onSave();
        },
        refetchQueries: [{ query: PatientNoteTagListDocument }],
    });

    const onSubmit: SubmitHandler<PatientNoteTagInput> = form => {
        if (item) {
            updateNoteTag({
                variables: {
                    updatePatientNoteTagInput: {
                        id: item.id,
                        data: {
                            name: form.name,
                        },
                    },
                },
            });
        } else {
            createNoteTag({
                variables: {
                    createPatientNoteTagInput: {
                        name: form.name,
                    },
                },
            });
        }
    };
    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose onClose={handleClose} id="modalTitle">
                <div>{item ? 'Edit' : 'Add'} Note Tag Item</div>
            </DialogTitleWithClose>
            <DialogContent>
                <OutlinedSection title="Note tag" outlineColor="secondary">
                    <Controller
                        control={control}
                        name="name"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                variant="outlined"
                                margin="dense"
                                label="Enter Name"
                                required
                                error={Boolean(errors.name)}
                                helperText={errors.name?.message?.toString()}
                            />
                        )}
                    />
                </OutlinedSection>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={handleFormSubmit(onSubmit)}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
