import { makeStyles } from 'tss-react/mui';

export default makeStyles()({
    toggle: {
        paddingBottom: 10,
        '.MuiToggleButtonGroup-root': {
            paddingTop: 5,
        },
        '.MuiFormHelperText-root': {
            marginLeft: 14,
            marginRight: 14,
        },
    },
});
