import { Typography } from '@mui/material';
import React from 'react';
import { GetTriggerInterventionSetProfileValueForPatientTranscriptQuery } from '~/schemaTypes';

type TriggerInterventionSetProfileValue = NonNullable<
    GetTriggerInterventionSetProfileValueForPatientTranscriptQuery['getTriggerInterventionSetProfileValueForPatientTranscript']
>[0];

export const TriggerInterventionSetProfileValueCard = ({
    intervention,
}: {
    intervention: TriggerInterventionSetProfileValue;
}) => {
    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <Typography variant="h6">Trigger Intervention Set Profile Value</Typography>
            <p className="m-0">{`Profile: ${intervention?.profileDef?.name || 'Unknown'}`}</p>
            <p className="m-0">{`Value: ${
                intervention?.event?.data?.profileValue?.str ||
                intervention?.event?.data?.profileValue?.num ||
                intervention?.event?.data?.profileValue?.bool ||
                intervention?.event?.data?.profileValue?.date ||
                intervention?.event?.data?.profileValue?.choiceId ||
                'Unknown'
            }`}</p>
        </div>
    );
};
