/* eslint-disable camelcase */
import { IconButton, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { InfoOutlined } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import tableIcons from '~/helpers/tableIcons';
import {
    AlertSeverity,
    FetchPatientTagsForPatientTagsQuery,
    useFetchPatientTagsForPatientTagsQuery,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';

type Tags = NonNullable<
    NonNullable<NonNullable<FetchPatientTagsForPatientTagsQuery['patient']>['tags']>[0]
>;

const useStyles = makeStyles()({
    root: {},
});

type RouteParams = {
    id: string;
};

const PatientNotes: React.FC = () => {
    const { id } = useParams<RouteParams>();
    const { classes } = useStyles();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);

    const { data: patientData, loading: patientDataLoading } =
        useFetchPatientTagsForPatientTagsQuery({
            variables: { input: { id } },
        });

    if (patientDataLoading) {
        return <Loading />;
    }

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable<Tags>
                title="Tags"
                icons={tableIcons}
                columns={[
                    {
                        title: 'Id',
                        field: 'id',
                        align: 'center',
                        sorting: false,
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Tag Id Copied to Clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    { title: 'Name', field: 'name' },
                ]}
                data={_.cloneDeep(patientData?.patient?.tags?.filter(Boolean) ?? []) as Tags[]}
                localization={{ header: { actions: '' } }}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{ pageSize: rowsPerPage, grouping: true, pageSizeOptions: [25, 50, 100] }}
            />
        </div>
    );
};

export default PatientNotes;
