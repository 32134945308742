import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    root: {},
    card: {
        padding: 15,
        marginBottom: 0,
    },
    viewIcon: {
        width: '1.4em',
        height: '1.4em',
    },
}));

export default useStyles;
