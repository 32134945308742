import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ObjectID from 'bson-objectid';
import React, { useEffect, useState } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { DirectoryItemType } from '~/schemaTypes';
import { ListItem } from '~/views/ConfigDashboard/Articles/Editors/ArticleEditor';
import { ClientSelectionType } from '~/enums/enums';
import { toClientSelectionType } from '../ContentDirectoryEditor';
import { SurveyModalParms } from '../types';

const useStyles = makeStyles()(() => ({
    paper: { minWidth: '800px' },
}));

const SurveyModal: React.FC<{
    parms: SurveyModalParms;
}> = props => {
    const { parms } = props;
    const { closeHandler, submitHandler, surveys, tags, apps, selectedItem } = parms;
    const { classes } = useStyles();

    const [selectedSurveyId, setSelectedSurveyId] = useState<string | null | undefined>(
        selectedItem ? selectedItem.surveyId : null,
    );

    const [tagList, setTagList] = useState<ListItem[]>(selectedItem?.tagList || []);
    const [appSelection, setAppSelection] = useState<ClientSelectionType>(
        toClientSelectionType(selectedItem?.includeApps, selectedItem?.excludeApps),
    );
    const [appRequired, setAppRequired] = useState<boolean>(false);
    const [includeApps, setIncludeApps] = useState<ListItem[]>(
        selectedItem?.includeApps?.map(a => ({ id: a, name: a })) || [],
    );
    const [excludeApps, setExcludeApps] = useState<ListItem[]>(
        selectedItem?.excludeApps?.map(a => ({ id: a, name: a })) || [],
    );

    useEffect(() => {
        if (surveys && !selectedItem) setSelectedSurveyId(surveys[0].id);
    }, [surveys, selectedItem]);

    const onSubmit = () => {
        setAppRequired(false);
        if (appSelection === ClientSelectionType.ExcludeCertainApps && excludeApps.length === 0) {
            setAppRequired(true);
            return;
        }
        if (appSelection === ClientSelectionType.IncludeCertainApps && includeApps.length === 0) {
            setAppRequired(true);
            return;
        }
        if (selectedSurveyId)
            submitHandler({
                id: selectedItem ? selectedItem.id : new ObjectID().toHexString(),
                type: DirectoryItemType.Survey,
                tagList,
                surveyId: selectedSurveyId,
                surveyName: surveys?.find(a => a.id === selectedSurveyId)?.name,
                includeApps: includeApps.map(a => a.id),
                excludeApps: excludeApps.map(a => a.id),
            });
    };

    return (
        <Dialog open classes={{ paper: classes.paper }}>
            <DialogTitleWithClose id="modalTitle" onClose={closeHandler}>
                {selectedItem ? 'Edit Survey' : 'Add Survey'}
            </DialogTitleWithClose>
            <DialogContent>
                <OutlinedSection title="Select Survey">
                    {surveys && (
                        <div>
                            <select
                                value={selectedSurveyId || ''}
                                onChange={e => setSelectedSurveyId(e.target.value)}
                            >
                                {surveys.map(a => (
                                    <option key={a.id} value={a.id}>{`${a.name}`}</option>
                                ))}
                            </select>
                        </div>
                    )}
                </OutlinedSection>
                <OutlinedSection title="Select Tags">
                    <Autocomplete
                        multiple
                        value={tagList}
                        options={
                            tags &&
                            tags
                                .slice()
                                .sort((a, b) => {
                                    if (a.name < b.name) return -1;
                                    if (a.name === b.name) return 0;
                                    return 1;
                                })
                                .map(tag => ({ id: tag.id, name: tag.name }))
                        }
                        onChange={(_, val) => {
                            setTagList(val);
                        }}
                        getOptionLabel={tag => tag.name}
                        renderInput={params => (
                            <TextField
                                variant="outlined"
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                label="Tag Filter"
                                placeholder="Select a Tag to filter this Item"
                            />
                        )}
                    />
                </OutlinedSection>
                <OutlinedSection title="Select Apps">
                    <select
                        key="appSelectionType"
                        value={appSelection}
                        onChange={e => {
                            setAppSelection(e.target.value as ClientSelectionType);
                            setIncludeApps([]);
                            setExcludeApps([]);
                        }}
                    >
                        <option value={ClientSelectionType.IncludeAllApps}>All Apps</option>
                        <option value={ClientSelectionType.IncludeCertainApps}>
                            Include Selected
                        </option>
                        <option value={ClientSelectionType.ExcludeCertainApps}>
                            Exclude Selected
                        </option>
                    </select>
                    {appSelection !== ClientSelectionType.IncludeAllApps && (
                        <Autocomplete
                            multiple
                            value={
                                appSelection === ClientSelectionType.IncludeCertainApps
                                    ? includeApps
                                    : excludeApps
                            }
                            options={apps && apps.slice().map(app => ({ id: app, name: app }))}
                            onChange={(_, val) => {
                                if (appSelection === ClientSelectionType.IncludeCertainApps)
                                    setIncludeApps(val);
                                else setExcludeApps(val);
                            }}
                            getOptionLabel={app => app.name}
                            renderInput={params => (
                                <TextField
                                    variant="outlined"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...params}
                                    label="App Selection"
                                    placeholder="Select an App"
                                />
                            )}
                        />
                    )}
                    {appRequired && (
                        <div style={{ color: 'red' }}>At least one app must be selected</div>
                    )}
                </OutlinedSection>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={closeHandler} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={onSubmit} color="secondary" variant="contained">
                    {selectedItem ? 'Update' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export {};
export default SurveyModal;
