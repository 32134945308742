import { Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { AuthUsername, TriggerGlobalAlert } from '~/state';
import { AlertSeverity } from '~/schemaTypes';
import {
    cognitoCurrentUser,
    cognitoDisableMfa,
    cognitoSetupMfa,
    cognitoVerifyMfaSetup,
} from '~/state/auth/cognito.svc';

export const MyAccount: React.FC = () => {
    const [filtersCleared, setFiltersCleared] = useState<boolean>(false);
    const [totpSecretCode, setTotpSecretCode] = useState<string>('');
    const [qrCode, setQrCode] = useState<string>('');
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [totpEnabled, setTotpEnabled] = useState<boolean>(false);

    useEffect(() => {
        const fetchMFA = async () => {
            const user = await cognitoCurrentUser();
            if (
                user.UserMFASettingList?.includes('SOFTWARE_TOKEN_MFA') &&
                user.PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA'
            ) {
                setTotpEnabled(true);
            } else {
                setTotpEnabled(false);
            }
        };
        if (!isFetching) {
            fetchMFA();
        }
    }, [isFetching]);

    const handleEnableTOTP = async () => {
        const setup = await cognitoSetupMfa();
        const setupUri = `otpauth://totp/Wildflower%20Health%20Portal:${AuthUsername()}?secret=${
            setup.SecretCode
        }`;
        const newQrCode = await QRCode.toDataURL(setupUri);
        setQrCode(newQrCode);
    };

    const handleClearAllFilters = () => {
        localStorage.clear();
        setFiltersCleared(true);
    };

    const handleVerifyTOTP = async () => {
        if (!totpSecretCode) {
            return;
        }
        setIsFetching(true);
        try {
            await cognitoVerifyMfaSetup(totpSecretCode);
            setIsFetching(false);
            setQrCode('');
            TriggerGlobalAlert({
                severity: AlertSeverity.Success,
                message: 'TOTP successfully enabled',
            });
        } catch (e) {
            setIsFetching(false);
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'Error verifying TOTP, please try again.',
            });
        }
    };

    const handleRemoveTotp = async () => {
        setIsFetching(true);
        try {
            await cognitoDisableMfa();
            setIsFetching(false);
            TriggerGlobalAlert({
                severity: AlertSeverity.Success,
                message: 'TOTP successfully disabled',
            });
        } catch (e) {
            setIsFetching(false);
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'Error disabling TOTP',
            });
        }
    };

    const handleCloseDialog = () => {
        setQrCode('');
    };

    return (
        <div>
            <Typography style={{ marginBottom: '10px' }} variant="h4">
                My Account
            </Typography>
            <Button variant="contained" color="primary" onClick={handleClearAllFilters}>
                Clear All Filters
            </Button>
            {filtersCleared && (
                <div>
                    <Typography variant="body1">Filters Cleared</Typography>
                </div>
            )}
            <Typography style={{ marginTop: '20px' }} variant="h4">
                TOTP Settings
            </Typography>
            {totpEnabled ? (
                <div>
                    <Typography variant="body1">TOTP currently enabled</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleRemoveTotp}
                        disabled={!totpEnabled || isFetching}
                    >
                        Remove TOTP
                    </Button>
                </div>
            ) : (
                <div>
                    <Typography variant="body1">TOTP currently disabled</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleEnableTOTP}
                        disabled={totpEnabled || isFetching}
                    >
                        Enable TOTP
                    </Button>
                </div>
            )}
            <Dialog open={qrCode !== ''} onClose={handleCloseDialog}>
                <DialogTitleWithClose id="enableTOTP" onClose={handleCloseDialog}>
                    Enable TOTP
                </DialogTitleWithClose>
                <DialogContent
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: '16px',
                    }}
                >
                    <Typography variant="h6">Scan QR Code</Typography>
                    <img data-amplify-qrcode src={qrCode} alt="qr code" width="228" height="228" />
                    <Typography variant="h6">Enter in code from Authenticator App</Typography>
                    <TextField
                        value={totpSecretCode}
                        onChange={e => setTotpSecretCode(e.target.value)}
                        placeholder="Enter Code"
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                    <Button
                        onClick={handleVerifyTOTP}
                        disabled={isFetching}
                        variant="contained"
                        color="primary"
                    >
                        Verify Code
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
