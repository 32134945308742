import { FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';

interface PatientSelectFilterProps {
    name: string;
    value: string;
    onChangeHandler: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
    children: React.ReactNode;
}

const PatientSelectFilter: React.FC<PatientSelectFilterProps> = ({
    name,
    value,
    onChangeHandler,
    children,
}) => (
    <FormControl variant="outlined" fullWidth>
        <InputLabel id={`${name.toLowerCase()}-label`}>{name}</InputLabel>
        <Select
            variant="outlined"
            labelId={`${name.toLowerCase()}-label`}
            id={`${name.toLowerCase()}-label`}
            value={value}
            onChange={onChangeHandler}
            label={name}
        >
            {children}
        </Select>
    </FormControl>
);

export default PatientSelectFilter;
