import { Grid, TextField, Typography } from '@mui/material';
import ObjectId from 'bson-objectid';
import React, { useState } from 'react';
import { AdvocateTaskCommentInput, FetchCurrentUserForAdvocateTaskPageQuery } from '~/schemaTypes';
import { Comment } from './Comment';
import { useStyles } from './styles';
import { CommentAuthor } from './types';

type CurrentsUser = NonNullable<FetchCurrentUserForAdvocateTaskPageQuery['currentUser']>;

interface CommentsProps {
    comments: AdvocateTaskCommentInput[];
    commentAuthors: CommentAuthor[];
    currentUser: CurrentsUser;
    onCommentsUpdated: (
        comments: AdvocateTaskCommentInput[],
        message?: string,
        index?: number,
    ) => void;
}

const Comments: React.FC<CommentsProps> = ({
    comments,
    commentAuthors,
    onCommentsUpdated,
    currentUser,
}) => {
    const { classes } = useStyles();
    const [isNewComment, setIsNewComment] = useState<boolean>(false);

    return (
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <Typography className={classes.commentsSummary} variant="subtitle1">
                    Comments ({comments?.length})
                </Typography>
            </Grid>
            {isNewComment ? (
                <Grid item xs={12}>
                    <Comment
                        onCommentsUpdated={onCommentsUpdated}
                        comments={comments}
                        commentAuthors={commentAuthors}
                        setIsNewComment={setIsNewComment}
                        currentUser={currentUser}
                    />
                </Grid>
            ) : (
                <TextField
                    id="comments"
                    label="Add a comment..."
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={classes.commentsLabel}
                    onClick={() => {
                        setIsNewComment(true);
                    }}
                />
            )}
            <Grid item xs={12}>
                {comments.length > 0 &&
                    comments.map(({ message, when, authorId }, index) => (
                        <Comment
                            key={new ObjectId().toHexString()}
                            onCommentsUpdated={onCommentsUpdated}
                            comments={comments}
                            message={message}
                            when={when}
                            authorId={authorId}
                            index={index}
                            setIsNewComment={setIsNewComment}
                            commentAuthors={commentAuthors}
                            currentUser={currentUser}
                        />
                    ))}
            </Grid>
        </Grid>
    );
};

export default Comments;
