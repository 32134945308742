import {
    PatientInsurancePlan,
    ClaimSubmittedConsultType,
    ClaimSubmittedVisitType,
    ClaimSubmittedBirthType,
    ClaimSubmittedVisitReason,
} from '~/schemaTypes';

export type SelectedPatient = {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    birthDate: string;
    email?: string | null;
    multiples?: boolean | null;
    insurancePlans: (PatientInsurancePlan | null)[];
    insuranceId: string;
};

export type AffiliateClaim = {
    firstName: string;
    lastName: string;
    birthDate: string;
    dateOfService: Date;
    serviceType: ClaimSubmittedVisitType | '';
    id: number;
    patientId: string;
};

export enum VisitType {
    Consult = 'Consult',
    Birth = 'Birth',
}

export enum LocationType {
    Virtual = 'Virtual',
    InPerson = 'InPerson',
}

export enum InPersonLocationType {
    Home = 'Home',
    Office = 'Office',
}

export type ConsultType = ClaimSubmittedConsultType;

export type DoulaClaim = {
    firstName: string;
    lastName: string;
    birthDate: string;
    dateOfService: Date;
    id: number;
    patientId: string;
    userId: string;
    visitType: VisitType;
    consultType: ConsultType;
    location: LocationType;
    inPersonLocation: InPersonLocationType;
    startTime: string;
    endTime: string;
    duration: number;
    serviceType: ClaimSubmittedVisitType | '';
    birthType: ClaimSubmittedBirthType | '';
    visitReason: ClaimSubmittedVisitReason | '';
    admissionFrom: Date;
    admissionTo: Date;
};

export type Claim = DoulaClaim & AffiliateClaim;

export enum FormType {
    Affiliate = 'Affiliate',
    Doula = 'Doula',
}
