import React, { useRef } from 'react';
import MaterialTable, { OrderByCollection } from '@material-table/core';
import { TablePagination, TableContainer, Paper } from '@mui/material';
import tableIcons from '../../../helpers/tableIcons';
import {
    AdvocateTasksForAdvocateTaskListPageQuery,
    OrderByDirectionEnum,
} from '../../../schemaTypes';

interface TableColumn<T> {
    title: string | React.ReactNode;
    field?: keyof T | string;
    render?: (rowData: T) => React.ReactNode;
    sorting?: boolean;
    hidden?: boolean;
}

type AdvocateTaskTableColumns = TableColumn<AdvocateTask>[];

type AdvocateTask = NonNullable<
    AdvocateTasksForAdvocateTaskListPageQuery['customFilteredAdvocateTasks']
>['results'][0];

interface AdvocateTaskTableProps {
    data: AdvocateTask[];
    totalCount: number;
    page: number;
    pageSize: number;
    onPageChange: (page: number, pageSize: number) => void;
    isLoading: boolean;
    actions: {
        icon: () => React.ReactElement;
        tooltip: string;
        onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
        isFreeAction?: boolean;
        hidden?: boolean;
    }[];
    columns: AdvocateTaskTableColumns;
    onOrderChange?: (orderField: keyof AdvocateTask, orderDirection: OrderByDirectionEnum) => void;
}

export const AdvocateTaskTable: React.FC<AdvocateTaskTableProps> = ({
    data,
    columns,
    totalCount,
    page,
    pageSize,
    onPageChange,
    onOrderChange,
    isLoading,
    actions,
}) => {
    const tableContainerRef = useRef<HTMLDivElement | null>(null);

    const handlePageChange = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        event?.preventDefault();
        onPageChange(newPage, pageSize);

        if (tableContainerRef.current) {
            tableContainerRef.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPageSize = parseInt(event.target.value, 10);
        const newPage = Math.floor((page * pageSize) / newPageSize);

        onPageChange(newPage, newPageSize);
    };

    const handleOrderCollectionChange = (orderByCollection: OrderByCollection[]) => {
        if (orderByCollection.length > 0 && onOrderChange) {
            const { orderBy, orderDirection } = orderByCollection[0];
            const column = columns[orderBy];

            if (column.field) {
                onOrderChange(
                    column.field as keyof AdvocateTask,
                    orderDirection === 'asc' ? OrderByDirectionEnum.Asc : OrderByDirectionEnum.Desc,
                );
            }
        }
    };

    return (
        <MaterialTable<AdvocateTask>
            title="Advocate Task list"
            columns={columns}
            data={isLoading ? [] : data}
            icons={tableIcons}
            options={{
                pageSize,
                pageSizeOptions: [25, 50, 100],
                search: false,
                paginationPosition: 'both',
                thirdSortClick: false,
                draggable: false,
            }}
            isLoading={isLoading}
            actions={actions}
            onOrderCollectionChange={handleOrderCollectionChange}
            components={{
                Pagination: props => (
                    <TablePagination
                        {...props}
                        count={totalCount}
                        page={page}
                        rowsPerPage={pageSize}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        id="advocate-task-table-pagination"
                    />
                ),
                Container: (props: any) => (
                    <TableContainer
                        id="advocate-task-table-container"
                        component={Paper}
                        ref={tableContainerRef}
                        {...props}
                    />
                ),
            }}
        />
    );
};
