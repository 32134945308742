import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
    root: {
        marginTop: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > .titleCont': {
            textAlign: 'center',
            maxWidth: 800,
            marginBottom: 40,
        },
        '& > .searchCont': {
            textAlign: 'center',
            width: '100%',
            maxWidth: 800,
            marginBottom: 40,
        },
        '& > .footerImgCont': {
            width: '100%',
            display: 'flex',
            '& > img': {
                height: '20%',
                width: '20%',
            },
        },
    },
    card: {
        width: '100%',
        maxWidth: 800,
        marginBottom: 50,
        padding: 60,
        backgroundColor: '#eceff1',
        boxShadow: '0px 17px 37px -32px grey',
        '& > p': {
            marginBottom: 0,
            fontSize: 16,
        },
    },
    search: {
        '& .MuiInputBase-root': {
            backgroundColor: '#eceff1',
            borderRadius: 30,
        },
        '& .MuiInput-input': {
            padding: '18.5px 14px',
        },
    },
});

export default useStyles;
