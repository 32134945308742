import { client } from '~/ApolloClientWrapper';
import {
    GenerateReportByReportTypeDocument,
    GenerateReportByReportTypeQuery,
    ReportingJobReportNameType,
} from '~/schemaTypes';

export const CHOICE_HA_PHONE_INTERACTION = '64190084539bfb319e8798b7';
export const CHOICE_HA_INBOUND_MESSAGE = '6419008f539bfb319e8798b9';
export const CHOICE_HA_OUTBOUND_MESSAGE = '6419008b539bfb319e8798b8';

const generateReportQuery = async (
    reportName: string,
    organizationId: string,
    startReportDate?: Date,
    finishReportDate?: Date,
    autoSendToClient?: boolean,
): Promise<GenerateReportByReportTypeQuery['generateReportByReportType']> => {
    const res = await client
        .query({
            query: GenerateReportByReportTypeDocument,
            variables: {
                input: {
                    reportName,
                    startReportDate,
                    finishReportDate,
                    organizationId,
                    autoSendToClient,
                },
            },
            fetchPolicy: 'no-cache',
        })
        .then(d => d.data.generateReportByReportType);
    return res;
};

const createOrganizationReportingForAxia = async (
    organizationId: string,
    from?: string,
    to?: string,
) => {
    const res = await generateReportQuery(
        ReportingJobReportNameType.PatientListExportForAxia,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
    );
    return res;
};

const createOrganizationReportingForAhsPatientList = async (
    organizationId: string,
    from?: string,
    to?: string,
    autoSendToClient?: boolean,
) => {
    const res = await generateReportQuery(
        ReportingJobReportNameType.PatientListExport,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
        autoSendToClient,
    );
    return res;
};

const createOrganizationReportingForAhsIntakeForm = async (
    organizationId: string,
    from?: string,
    to?: string,
) => {
    const res = await generateReportQuery(
        ReportingJobReportNameType.IntakeSurveyResults,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
    );
    return res;
};

const createOrganizationReportingForLifeline = async (
    organizationId: string,
    from?: string,
    to?: string,
) => {
    const res = await generateReportQuery(
        ReportingJobReportNameType.LifelineReport,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
    );
    return res;
};

const createOrganizationReportingForAllPatientList = async (
    organizationId: string,
    from?: string,
    to?: string,
) => {
    const res = await generateReportQuery(
        ReportingJobReportNameType.AllPatientList,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
    );
    return res;
};

const createOrganizationReportingForHtcEmployeeList = async (
    organizationId: string,
    from?: string,
    to?: string,
) => {
    const res = await generateReportQuery(
        ReportingJobReportNameType.HtcBillingDataReport,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
    );
    return res;
};

const createOrganizationReportingForHtcGenericList = async (
    organizationId: string,
    from?: string,
    to?: string,
) => {
    const res = await generateReportQuery(
        ReportingJobReportNameType.HtcGenericDataReport,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
    );
    return res;
};

const createOrganizationReportingBhfList = async (
    organizationId: string,
    from?: string,
    to?: string,
    autoSendToClient?: boolean,
) => {
    const res = await generateReportQuery(
        ReportingJobReportNameType.BhfMonthlyInvoicing,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
        autoSendToClient,
    );
    return res;
};

const createOrganizationReportingPregnancyList = async (
    organizationId: string,
    from?: string,
    to?: string,
) => {
    const res = await generateReportQuery(
        ReportingJobReportNameType.PregnancyPatientList,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
    );
    return res;
};

const createHighRiskEPDSExport = async (organizationId: string, from?: string, to?: string) => {
    const res = await generateReportQuery(
        ReportingJobReportNameType.HighRiskEpdsReport,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
    );
    return res;
};

const createProgenyBillingExport = async (organizationId: string, from?: string, to?: string) => {
    const res = await generateReportQuery(
        ReportingJobReportNameType.ProgenyBillingReport,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
    );
    return res;
};

const createSSHPCaseMgmtExport = async (
    organizationId: string,
    from?: string,
    to?: string,
    autoSendToClient?: boolean,
) => {
    const res = await generateReportQuery(
        ReportingJobReportNameType.SshpCaseReport,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
        autoSendToClient,
    );
    return res;
};

const createCignaChatDataExport = async (organizationId: string, from?: string, to?: string) => {
    const res = await generateReportQuery(
        ReportingJobReportNameType.CignaChatDataReport,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
    );
    return res;
};

const createStPetersBillingReport = async (organizationId: string, from?: string, to?: string) => {
    const res = await generateReportQuery(
        ReportingJobReportNameType.StPetersBillingReport,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
    );
    return res;
};

const createStandardizedReport = async (organizationId: string, from?: string, to?: string) => {
    const response = await generateReportQuery(
        ReportingJobReportNameType.StandardizedHmReport,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
    );
    return response;
};

const createHpnBillingReport = async (organizationId: string, from?: string, to?: string) => {
    const response = await generateReportQuery(
        ReportingJobReportNameType.HpnBillingReport,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
    );
    return response;
};

const createHpnSupplementalDataFeedReportForPregnantPatients = async (
    organizationId: string,
    from?: string,
    to?: string,
    autoSendToClient?: boolean,
) => {
    const response = await generateReportQuery(
        ReportingJobReportNameType.HpnSupplementalDataFeedPregnant,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
        autoSendToClient ?? false,
    );
    return response;
};
const createHpnSupplementalDataFeedReportForPostpartumPatients = async (
    organizationId: string,
    from?: string,
    to?: string,
    autoSendToClient?: boolean,
) => {
    const response = await generateReportQuery(
        ReportingJobReportNameType.HpnSupplementalDataFeedPostpartum,
        organizationId,
        from ? new Date(from) : undefined,
        to ? new Date(to) : undefined,
        autoSendToClient ?? false,
    );
    return response;
};

export const generateOrganizationReport = async (
    organizationId: string,
    reportName?: ReportingJobReportNameType,
    from?: string,
    to?: string,
    autoSendToClient?: boolean,
) => {
    switch (reportName) {
        case ReportingJobReportNameType.PatientListExportForAxia:
            return createOrganizationReportingForAxia(organizationId, from, to);

        case ReportingJobReportNameType.PatientListExport:
            return createOrganizationReportingForAhsPatientList(organizationId, from, to);

        case ReportingJobReportNameType.IntakeSurveyResults:
            return createOrganizationReportingForAhsIntakeForm(organizationId, from, to);

        case ReportingJobReportNameType.LifelineReport:
            return createOrganizationReportingForLifeline(organizationId, from, to);

        case ReportingJobReportNameType.AllPatientList:
            return createOrganizationReportingForAllPatientList(organizationId, from, to);

        case ReportingJobReportNameType.HtcBillingDataReport:
            return createOrganizationReportingForHtcEmployeeList(organizationId, from, to);

        case ReportingJobReportNameType.HtcGenericDataReport:
            return createOrganizationReportingForHtcGenericList(organizationId, from, to);
        case ReportingJobReportNameType.BhfMonthlyInvoicing:
            return createOrganizationReportingBhfList(organizationId, from, to, autoSendToClient);
        case ReportingJobReportNameType.PregnancyPatientList:
            return createOrganizationReportingPregnancyList(organizationId, from, to);
        case ReportingJobReportNameType.HighRiskEpdsReport:
            return createHighRiskEPDSExport(organizationId, from, to);
        case ReportingJobReportNameType.ProgenyBillingReport:
            return createProgenyBillingExport(organizationId, from, to);
        case ReportingJobReportNameType.SshpCaseReport:
            return createSSHPCaseMgmtExport(organizationId, from, to, autoSendToClient);
        case ReportingJobReportNameType.CignaChatDataReport:
            return createCignaChatDataExport(organizationId, from, to);
        case ReportingJobReportNameType.StPetersBillingReport:
            return createStPetersBillingReport(organizationId, from, to);
        case ReportingJobReportNameType.StandardizedHmReport: {
            return createStandardizedReport(organizationId, from, to);
        }
        case ReportingJobReportNameType.HpnBillingReport: {
            return createHpnBillingReport(organizationId, from, to);
        }
        case ReportingJobReportNameType.HpnSupplementalDataFeedPregnant: {
            return createHpnSupplementalDataFeedReportForPregnantPatients(
                organizationId,
                from,
                to,
                autoSendToClient,
            );
        }
        case ReportingJobReportNameType.HpnSupplementalDataFeedPostpartum: {
            return createHpnSupplementalDataFeedReportForPostpartumPatients(
                organizationId,
                from,
                to,
                autoSendToClient,
            );
        }
        default:
            return null;
    }
};
