import React from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { ZipCodesType } from './constants';

const ZIP_CODES_BY_CITY_DOMAIN = 'https://www.unitedstateszipcodes.org/';
const ZIP_CODES_BY_ADDRESS_DOMAIN = 'https://www.unitedstateszipcodes.org/zip-code-radius-map.php';
const ZIP_CODES_MIX_DOMAIN = 'https://www.unitedstateszipcodes.org/';

type HelperTextProps = {
    type: ZipCodesType | null;
};

const HelperText: React.FC<HelperTextProps> = props => {
    const { type } = props;
    let url;
    switch (type) {
        case ZipCodesType.Address:
            url = ZIP_CODES_BY_ADDRESS_DOMAIN;
            break;
        case ZipCodesType.CityAndState:
            url = ZIP_CODES_BY_CITY_DOMAIN;
            break;
        default:
            url = ZIP_CODES_MIX_DOMAIN;
    }
    return (
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <Typography variant="subtitle1">
                    Please use{' '}
                    <Link
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="primary"
                        underline="always"
                    >
                        US Zip Codes Tool
                    </Link>{' '}
                    to see these zip codes on a map and verify that you are willing to travel to all
                    these locations
                </Typography>
            </Grid>
        </Grid>
    );
};

export default HelperText;
