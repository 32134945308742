import React, { useState, useEffect, useMemo } from 'react';
import { format, toDate } from 'date-fns-tz';
import { useNavigate } from 'react-router-dom';
import {
    DialogContent,
    Grid,
    MenuItem,
    Button,
    DialogActions,
    TextField,
    Autocomplete,
    CircularProgress,
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import {
    Patient,
    useCurrentAffiliateByAffiliateIdQuery,
    useFetchAffiliatesForAutocompleteLazyQuery,
    OrderByDirectionEnum,
    useAffiliatesForZipCodeFlowQuery,
    useReassignPatientToAffiliateMutation,
} from '~/schemaTypes';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';

enum ReassignOptions {
    reassign,
    remove,
    removeAndReassign,
}

enum ChooseAffiliateOptions {
    choose,
    region,
}

type AffiliateOption = {
    title: string;
    value: string;
};

type ReassignDialogProps = {
    currentAffiliateId: string;
    patient: Partial<Patient>;
    onClose: () => void;
};

const ReassignDialog: React.FC<ReassignDialogProps> = props => {
    const { currentAffiliateId, patient, onClose } = props;
    const history = useNavigate();
    const [reassignActionValue, setReassignActionValue] = useState(ReassignOptions.reassign);
    const [chooseValue, setChooseValue] = useState(ChooseAffiliateOptions.choose);
    const [selectedAffiliateId, setSelectedAffiliate] = useState<null | string>(null);
    const [autocompleteSearch, setAutocompleteSearch] = useState('');
    const [autocompleteOptions, setAutocompleteOptions] = useState<AffiliateOption[]>([]);
    const showChooseOptions = [
        ReassignOptions.reassign,
        ReassignOptions.removeAndReassign,
    ].includes(reassignActionValue);
    const showRegionOption = [ReassignOptions.reassign, ReassignOptions.removeAndReassign].includes(
        reassignActionValue,
    );
    const { data: currentAffiliate } = useCurrentAffiliateByAffiliateIdQuery({
        variables: {
            input: {
                affiliateId: currentAffiliateId,
            },
        },
    });
    const [fetchAffiliates, { data: affiliates, loading: affiliatesLoading }] =
        useFetchAffiliatesForAutocompleteLazyQuery();
    const { data: affiliatesForZipCodeFlow } = useAffiliatesForZipCodeFlowQuery({
        variables: {
            input: {
                patientId: patient.id,
            },
        },
        fetchPolicy: 'no-cache',
    });
    const [reassignPatient, { loading: reassignPatientLoading }] =
        useReassignPatientToAffiliateMutation({
            onCompleted: response => {
                if (response.reassignPatientToAffiliate?.newAffiliateId) {
                    history(
                        `/app-config/vcaffiliates/patients/${response.reassignPatientToAffiliate.newAffiliateId}/${patient.id}`,
                    );
                } else if (response.reassignPatientToAffiliate?.removedFromCurrent) {
                    history(`/app-config/vcaffiliates/patients/${currentAffiliateId}`);
                }
                onClose();
            },
        });
    useEffect(() => {
        setChooseValue(ChooseAffiliateOptions.choose);
    }, [reassignActionValue]);
    useEffect(() => {
        setSelectedAffiliate(null);
        setAutocompleteSearch('');
        setAutocompleteOptions([]);
    }, [chooseValue, reassignActionValue]);
    const internalName =
        currentAffiliate?.virtualCareAffiliateByAffiliateId?.data?.internalName || '';
    useEffect(() => {
        setAutocompleteOptions(
            (affiliates?.virtualCareAffiliates?.results || []).map(a => ({
                title: `${a.internalName} (${a.affiliateId})`,
                value: a.affiliateId as string,
            })),
        );
    }, [affiliates?.virtualCareAffiliates?.results]);
    const regionOptionsEnabled = !!affiliatesForZipCodeFlow?.affiliatesForZipCodeFlow?.possible;
    const disableAutocomplete =
        !regionOptionsEnabled && chooseValue === ChooseAffiliateOptions.region;
    const fetchAffiliatesDebounced = useMemo(
        () => {
            return debounce(
                (search: string) =>
                    fetchAffiliates({
                        variables: {
                            input: {
                                search,
                                excludedAffiliateIds: patient.affiliateIds,
                                ...(chooseValue === ChooseAffiliateOptions.region && {
                                    patientZipCode: patient.mailingAddress?.code,
                                }),
                                pagination: {
                                    limit: 10,
                                    skip: 0,
                                },
                                orderBy: {
                                    field: 'internalName',
                                    order: OrderByDirectionEnum.Asc,
                                },
                            },
                        },
                    }),
                500,
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [patient.affiliateIds, chooseValue, patient.mailingAddress?.code],
    );
    useEffect(() => {
        fetchAffiliatesDebounced(autocompleteSearch);
    }, [autocompleteSearch, fetchAffiliatesDebounced]);
    const showAffiliateAutocomplete =
        [ChooseAffiliateOptions.region, ChooseAffiliateOptions.choose].includes(chooseValue) &&
        showChooseOptions;
    const isValidForm = useMemo(
        () =>
            reassignActionValue === ReassignOptions.remove ||
            (showChooseOptions && !!selectedAffiliateId),
        [reassignActionValue, selectedAffiliateId, showChooseOptions],
    );
    const onSubmit = () => {
        const payload = {
            currentAffiliateId,
            patientId: patient.id,
            ...([ReassignOptions.remove, ReassignOptions.removeAndReassign].includes(
                reassignActionValue,
            ) && { removeFromCurrent: true }),
            ...(selectedAffiliateId && { newAffiliateId: selectedAffiliateId }),
        };
        reassignPatient({
            variables: payload,
        });
    };
    return (
        <>
            <DialogTitleWithClose id="add-patient-to-calims" onClose={onClose}>
                Reassign Patient
            </DialogTitleWithClose>
            <DialogContent dividers>
                <Grid container>
                    <Grid item xs={12} container>
                        <Grid item xs={4}>
                            <TextField
                                disabled
                                value={`${patient.firstName} ${patient.lastName}`}
                                fullWidth
                                label="Patient Name"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                disabled
                                value={format(
                                    toDate(patient.birthDate.split('T')[0]),
                                    'MM/dd/yyyy',
                                )}
                                fullWidth
                                label="DOB"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            value={reassignActionValue}
                            onChange={e =>
                                setReassignActionValue(e.target.value as unknown as ReassignOptions)
                            }
                        >
                            <MenuItem
                                value={ReassignOptions.reassign}
                                key={ReassignOptions.reassign}
                            >
                                Assign to another Affiliate
                            </MenuItem>
                            <MenuItem value={ReassignOptions.remove} key={ReassignOptions.remove}>
                                Remove from {internalName}
                            </MenuItem>
                            <MenuItem
                                value={ReassignOptions.removeAndReassign}
                                key={ReassignOptions.removeAndReassign}
                            >
                                Remove from {internalName} and assign to another affiliate
                            </MenuItem>
                        </TextField>
                    </Grid>
                    {showChooseOptions && (
                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                value={chooseValue}
                                onChange={e =>
                                    setChooseValue(
                                        e.target.value as unknown as ChooseAffiliateOptions,
                                    )
                                }
                            >
                                <MenuItem
                                    key={ChooseAffiliateOptions.choose}
                                    value={ChooseAffiliateOptions.choose}
                                >
                                    Choose Affiliate
                                </MenuItem>
                                {showRegionOption && (
                                    <MenuItem
                                        key={ChooseAffiliateOptions.region}
                                        value={ChooseAffiliateOptions.region}
                                    >
                                        Reassign to affiliate in the region
                                    </MenuItem>
                                )}
                            </TextField>
                        </Grid>
                    )}
                    {showAffiliateAutocomplete && (
                        <Grid item xs={12}>
                            <Autocomplete
                                fullWidth
                                noOptionsText="Not match"
                                disabled={disableAutocomplete}
                                getOptionLabel={option => option.title}
                                options={autocompleteOptions}
                                loading={affiliatesLoading}
                                getOptionKey={option => option.value}
                                inputValue={autocompleteSearch}
                                onInputChange={(event, inputValue) =>
                                    event && setAutocompleteSearch(inputValue)
                                }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={
                                            disableAutocomplete
                                                ? 'No Affiliate Found In Region'
                                                : 'Type To Complete Affiliate Name'
                                        }
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {affiliatesLoading ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                onChange={(_, value) => setSelectedAffiliate(value?.value || null)}
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={onSubmit}
                    disabled={!isValidForm || reassignPatientLoading}
                >
                    Save
                </Button>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
};

export default ReassignDialog;
