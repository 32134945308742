import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import PatientHeaderComponent from '~/components/PatientHeader/PatientHeaderComponent';
import {
    AdvocateTaskChecklistItemInput as Step,
    AdvocateTaskCommentInput,
    AdvocateTasksForAdvocateTaskListPageDocument,
    FetchAdvocateTaskForAdvocateTaskPageDocument,
    ReferencePages,
    useFetchAdvocateTaskForAdvocateTaskPageQuery,
    useFetchCurrentUserForAdvocateTaskPageQuery,
    useUpdateAdvocateTaskForAdvocateTaskPageMutation,
    useFetchReferenceLinksForAdvocateTaskPageQuery,
    AdvocateTaskTypeStatus,
} from '~/schemaTypes';
import { toBase64 } from '~/helpers/base64Helper';
import ObjectID from 'bson-objectid';
import _ from 'lodash';
import { typeLabelById, userNameById } from '../helpers';
import { Comments } from './Comments';
import { History } from './History';
import { createHistoryItem } from './History/helpers';
import {
    toAdvocateTaskCommentsInput,
    toAdvocateTaskHistoryInput,
    toAdvocateTaskInput,
    toCommentAuthorsForm,
    toHistoryFiguresForm,
    toWatchersForm,
} from './mappers';
import { PatientActions } from './PatientActions';
import { Sidebar } from './Sidebar';
import { OnSidebarUpdated } from './Sidebar/types';
import { Steps } from './Steps';
import { useStyles } from './styles';
import { TaskInfo } from './TaskInfo';
import { TaskLabel } from './TaskLabel';
import { Watchers } from './Watchers';
import { toAdvocateTaskChecklistInputForSteps } from './mappers/toAdvocateTaskChecklistInput';
import { createHistoryItemEncoded } from './History/helpers/createHistoryItem';
import { toAdvocateTaskHistoryInputEncoded } from './mappers/toAdvocateTaskHistoryInput';

type AdvocateTaskProps = {
    setLinks?: any;
};

const AdvocateTask: React.FC<AdvocateTaskProps> = ({ setLinks }) => {
    const { classes } = useStyles();
    const { id: advocateTaskId } = useParams<{ id: string }>();

    const { data: currentUserData, loading: currentUserLoading } =
        useFetchCurrentUserForAdvocateTaskPageQuery();

    const { data: advocateTask, loading: advocateTaskLoading } =
        useFetchAdvocateTaskForAdvocateTaskPageQuery({
            variables: {
                input: {
                    id: advocateTaskId,
                },
            },
        });

    const { data: refereneLinksData, loading: refereneLinksLoading } =
        useFetchReferenceLinksForAdvocateTaskPageQuery({
            variables: {
                input: {
                    page: ReferencePages.HaTask,
                },
            },
        });

    useEffect(() => {
        if (refereneLinksData && setLinks) {
            setLinks(refereneLinksData.getReferenceLinksForPage);
        }
    }, [refereneLinksData, setLinks]);

    const advocateTaskData = advocateTask?.advocateTask;
    const advocateTaskPatient = advocateTaskData?.patient;

    const [updateTask] = useUpdateAdvocateTaskForAdvocateTaskPageMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: FetchAdvocateTaskForAdvocateTaskPageDocument,
                variables: { input: { id: advocateTaskId } },
            },
            {
                query: AdvocateTasksForAdvocateTaskListPageDocument,
                variables: { input: { customFilter: undefined } },
            },
        ],
    });

    const onTaskLabelUpdated = (label: string) => {
        if (advocateTaskData && currentUserData?.currentUser && advocateTaskId) {
            updateTask({
                variables: {
                    input: {
                        id: advocateTaskId,
                        data: {
                            ...toAdvocateTaskInput(advocateTaskData),
                            label,
                            history: [
                                createHistoryItemEncoded(
                                    currentUserData.currentUser.id,
                                    `updated Task Label from '${advocateTaskData.label}' to '${label}'`,
                                ),
                                ...toAdvocateTaskHistoryInputEncoded(advocateTaskData.history),
                            ],
                        },
                    },
                },
                optimisticResponse: {
                    updateAdvocateTaskEncoded: {
                        __typename: 'UpdateAdvocateTaskEncodedResponse',
                        success: true,
                        message: 'Task updated',
                        resourceUpdated: {
                            ...advocateTaskData,
                            label,
                            history: [
                                createHistoryItem(
                                    currentUserData.currentUser.id,
                                    `updated Task Label from '${advocateTaskData.label}' to '${label}'`,
                                ),
                                ...toAdvocateTaskHistoryInput(advocateTaskData.history),
                            ],
                            __typename: 'AdvocateTask',
                        },
                    },
                },
            });
        }
    };

    const onDescriptionUpdated = (description: string) => {
        if (advocateTaskData && currentUserData?.currentUser && advocateTaskId) {
            updateTask({
                variables: {
                    input: {
                        id: advocateTaskId,
                        data: {
                            ...toAdvocateTaskInput(advocateTaskData),
                            description,
                            history: [
                                createHistoryItemEncoded(
                                    currentUserData.currentUser.id,
                                    `updated Task Description from '${advocateTaskData.description}' to '${description}'`,
                                ),
                                ...toAdvocateTaskHistoryInputEncoded(advocateTaskData.history),
                            ],
                        },
                    },
                },
                optimisticResponse: {
                    updateAdvocateTaskEncoded: {
                        __typename: 'UpdateAdvocateTaskEncodedResponse',
                        success: true,
                        message: 'Task updated',
                        resourceUpdated: {
                            ...advocateTaskData,
                            description,
                            history: [
                                createHistoryItem(
                                    currentUserData.currentUser.id,
                                    `updated Task Description from '${advocateTaskData.description}' to '${description}'`,
                                ),
                                ...toAdvocateTaskHistoryInput(advocateTaskData.history),
                            ],
                            __typename: 'AdvocateTask',
                        },
                    },
                },
            });
        }
    };

    const onStepsUpdated = (steps: Step[], historyStepAction: string[]) => {
        if (advocateTaskData && currentUserData?.currentUser && advocateTaskId) {
            const currentUserId = currentUserData.currentUser.id;

            const encodedChecklist = steps.map(step => ({
                ...step,
                label: toBase64(step.label || '') || '',
                description: toBase64(step.description || '') || '',
            }));

            updateTask({
                variables: {
                    input: {
                        id: advocateTaskId,
                        data: {
                            ...toAdvocateTaskInput(advocateTaskData),
                            checklist: encodedChecklist,
                            history: [
                                ...historyStepAction.map(historyStepActionItem =>
                                    createHistoryItemEncoded(currentUserId, historyStepActionItem),
                                ),
                                ...toAdvocateTaskHistoryInputEncoded(advocateTaskData.history),
                            ],
                        },
                    },
                },
                optimisticResponse: {
                    updateAdvocateTaskEncoded: {
                        __typename: 'UpdateAdvocateTaskEncodedResponse',
                        success: true,
                        message: 'Task updated',
                        resourceUpdated: {
                            ...advocateTaskData,
                            checklist: steps,
                            history: [
                                ...historyStepAction.map(historyStepActionItem =>
                                    createHistoryItem(currentUserId, historyStepActionItem),
                                ),
                                ...toAdvocateTaskHistoryInput(advocateTaskData.history),
                            ],
                            __typename: 'AdvocateTask',
                        },
                    },
                },
            });
        }
    };

    const onPatientActionsUpdated = (change: string) => {
        if (advocateTaskData && currentUserData?.currentUser && advocateTaskId) {
            updateTask({
                variables: {
                    input: {
                        id: advocateTaskId,
                        data: {
                            ...toAdvocateTaskInput(advocateTaskData),
                            history: [
                                createHistoryItemEncoded(currentUserData.currentUser.id, change),
                                ...toAdvocateTaskHistoryInputEncoded(advocateTaskData.history),
                            ],
                        },
                    },
                },
                optimisticResponse: {
                    updateAdvocateTaskEncoded: {
                        __typename: 'UpdateAdvocateTaskEncodedResponse',
                        success: true,
                        message: 'Task updated',
                        resourceUpdated: {
                            ...advocateTaskData,
                            history: [
                                createHistoryItem(currentUserData.currentUser.id, change),
                                ...toAdvocateTaskHistoryInput(advocateTaskData.history),
                            ],
                            __typename: 'AdvocateTask',
                        },
                    },
                },
            });
        }
    };

    const onPatientActionCreatedAutomatically = (steps: Step[], whatItems: string[], who = '') => {
        if (advocateTaskData && currentUserData?.currentUser && advocateTaskId) {
            updateTask({
                variables: {
                    input: {
                        id: advocateTaskId,
                        data: {
                            ...toAdvocateTaskInput(advocateTaskData),
                            checklist: steps.map(step => ({
                                ...step,
                                label: toBase64(step.label || '') || '',
                                description: toBase64(step.description || '') || '',
                            })),
                            history: [
                                ...whatItems.map(what => createHistoryItemEncoded(who, what)),
                                ...toAdvocateTaskHistoryInputEncoded(advocateTaskData.history),
                            ],
                        },
                    },
                },
                optimisticResponse: {
                    updateAdvocateTaskEncoded: {
                        __typename: 'UpdateAdvocateTaskEncodedResponse',
                        success: true,
                        message: 'Task updated',
                        resourceUpdated: {
                            ...advocateTaskData,
                            checklist: steps,
                            history: [
                                ...whatItems.map(what => createHistoryItem(who, what)),
                                ...toAdvocateTaskHistoryInput(advocateTaskData.history),
                            ],
                            __typename: 'AdvocateTask',
                        },
                    },
                },
            });
        }
    };

    const onWatchersUpdated = (watchedBy: string[]) => {
        if (advocateTaskData && currentUserData?.currentUser && advocateTaskId) {
            updateTask({
                variables: {
                    input: {
                        id: advocateTaskId,
                        data: {
                            ...toAdvocateTaskInput(advocateTaskData),
                            watchedBy,
                            history: [
                                createHistoryItemEncoded(
                                    currentUserData.currentUser.id,
                                    `${
                                        watchedBy.includes(currentUserData.currentUser.id)
                                            ? 'started'
                                            : 'stopped'
                                    } watching the Task`,
                                ),
                                ...toAdvocateTaskHistoryInputEncoded(advocateTaskData.history),
                            ],
                        },
                    },
                },
                optimisticResponse: {
                    updateAdvocateTaskEncoded: {
                        __typename: 'UpdateAdvocateTaskEncodedResponse',
                        success: true,
                        message: 'Task updated',
                        resourceUpdated: {
                            ...advocateTaskData,
                            watchedBy,
                            history: [
                                createHistoryItem(
                                    currentUserData.currentUser.id,
                                    `${
                                        watchedBy.includes(currentUserData.currentUser.id)
                                            ? 'started'
                                            : 'stopped'
                                    } watching the Task`,
                                ),
                                ...toAdvocateTaskHistoryInput(advocateTaskData.history),
                            ],
                            __typename: 'AdvocateTask',
                        },
                    },
                },
            });
        }
    };

    const onSidebarUpdated = ({
        status: newStatus,
        dueDate: newDueDate,
        typeId: newTypeId,
        priority: newPriority,
        assignedTo: newAssignedTo,
    }: OnSidebarUpdated) => {
        if (advocateTaskData && currentUserData?.currentUser && advocateTaskId) {
            const { history, status, dueDate, typeId, priority, assignedTo, completedAt } =
                advocateTaskData;
            let newCompletedAt = completedAt;
            const newHistory = history.slice().map(h => ({
                ..._.omit(h, '__typename'),
                what: toBase64(h.what) || '',
            }));
            if (newStatus && newStatus !== status) {
                newHistory.unshift(
                    createHistoryItemEncoded(
                        currentUserData.currentUser.id,
                        `changed Status from '${status}' to '${newStatus}'`,
                    ),
                );
                if (newStatus === AdvocateTaskTypeStatus.Closed) {
                    newCompletedAt = new Date();
                } else {
                    newCompletedAt = null;
                }
            }
            if (newDueDate && newDueDate !== dueDate) {
                newHistory.unshift(
                    createHistoryItemEncoded(
                        currentUserData.currentUser.id,
                        `changed Date To Complete from '${new Date(
                            dueDate,
                        ).toLocaleDateString()}' to '${new Date(newDueDate).toLocaleDateString()}'`,
                    ),
                );
            }
            if (newTypeId && newTypeId !== typeId) {
                newHistory.unshift(
                    createHistoryItemEncoded(
                        currentUserData.currentUser.id,
                        `changed Type from '${typeLabelById(
                            typeId,
                            advocateTaskData.types,
                        )}' to '${typeLabelById(newTypeId, advocateTaskData.types)}'`,
                    ),
                );
            }
            if (newPriority && newPriority !== priority) {
                newHistory.unshift(
                    createHistoryItemEncoded(
                        currentUserData.currentUser.id,
                        `changed Priority from '${priority}' to '${newPriority}'`,
                    ),
                );
            }
            if (newAssignedTo && newAssignedTo !== assignedTo) {
                newHistory.unshift(
                    createHistoryItemEncoded(
                        currentUserData.currentUser.id,
                        `changed the Assignee from '${userNameById(
                            assignedTo,
                            advocateTaskData.usersToAssign,
                        )}' to '${userNameById(newAssignedTo, advocateTaskData.usersToAssign)}'`,
                    ),
                );
            }
            updateTask({
                variables: {
                    input: {
                        id: advocateTaskId,
                        data: {
                            ...toAdvocateTaskInput(advocateTaskData),
                            status: newStatus,
                            dueDate: newDueDate,
                            typeId: newTypeId,
                            priority: newPriority,
                            assignedTo: newAssignedTo ? new ObjectID(newAssignedTo) : assignedTo,
                            completedAt: newCompletedAt,
                            history: newHistory,
                        },
                    },
                },
            });
        }
    };

    const onCommentsUpdated = (
        comments: AdvocateTaskCommentInput[],
        message?: string,
        index?: number,
    ) => {
        if (advocateTaskData && currentUserData?.currentUser && advocateTaskId) {
            updateTask({
                variables: {
                    input: {
                        id: advocateTaskId,
                        data: {
                            ...toAdvocateTaskInput(advocateTaskData),
                            comments,
                            history: [
                                createHistoryItemEncoded(
                                    currentUserData.currentUser.id,
                                    message && index !== undefined
                                        ? `edited comment from '${advocateTaskData.comments[index].message}' to '${message}'`
                                        : 'left a comment',
                                ),
                                ...toAdvocateTaskHistoryInputEncoded(advocateTaskData.history),
                            ],
                        },
                    },
                },
                optimisticResponse: {
                    updateAdvocateTaskEncoded: {
                        __typename: 'UpdateAdvocateTaskEncodedResponse',
                        success: true,
                        message: 'Task updated',
                        resourceUpdated: {
                            ...advocateTaskData,
                            comments,
                            history: [
                                createHistoryItem(
                                    currentUserData.currentUser.id,
                                    message && index !== undefined
                                        ? `edited comment from '${advocateTaskData.comments[index].message}' to '${message}'`
                                        : 'left a comment',
                                ),
                                ...toAdvocateTaskHistoryInput(advocateTaskData.history),
                            ],
                        },
                    },
                },
            });
        }
    };

    if (advocateTaskLoading || currentUserLoading || refereneLinksLoading) {
        return <Loading height={500} />;
    }

    return (
        <Grid container>
            {advocateTaskPatient?.patientHeaderData &&
                advocateTaskPatient?.patientHeaderData.length > 0 && (
                    <PatientHeaderComponent
                        patientHeaderItems={advocateTaskPatient?.patientHeaderData ?? []}
                    />
                )}
            <Grid item container classes={{ root: classes.pageTitleWrap }} xs={11}>
                {!advocateTaskLoading && advocateTaskData && (
                    <TaskLabel
                        label={advocateTaskData.label}
                        onTaskLabelUpdated={onTaskLabelUpdated}
                    />
                )}
            </Grid>
            <Grid item classes={{ root: classes.pageTitleWrap }} xs={1}>
                {currentUserData?.currentUser && advocateTaskData?.watchedBy && (
                    <Watchers
                        currentUser={currentUserData.currentUser}
                        watchedBy={advocateTaskData.watchedBy}
                        watchers={toWatchersForm(advocateTaskData.watchers)}
                        onWatchersUpdated={onWatchersUpdated}
                    />
                )}
            </Grid>
            <Grid item container xs={8} md={9} lg={10}>
                <Grid item container xs={12} className={classes.infoSection}>
                    {advocateTaskData &&
                        advocateTaskPatient &&
                        currentUserData &&
                        currentUserData.currentUser && (
                            <TaskInfo
                                patient={advocateTaskPatient}
                                description={advocateTaskData.description}
                                onDescriptionUpdated={onDescriptionUpdated}
                            />
                        )}
                </Grid>
                <Grid item xs={12} className={classes.infoSection}>
                    {advocateTaskData?.patientId && currentUserData?.currentUser?.id && (
                        <Steps
                            advocateTask={advocateTaskData}
                            actorId={currentUserData?.currentUser.id}
                            onStepsUpdated={onStepsUpdated}
                            onPatientActionCreatedAutomatically={
                                onPatientActionCreatedAutomatically
                            }
                            steps={toAdvocateTaskChecklistInputForSteps(advocateTaskData.checklist)}
                            patientActionTypes={advocateTaskData.actionTypes}
                            patientActions={advocateTaskData.patientActions}
                        />
                    )}
                </Grid>
                <Grid item xs={12} className={classes.infoSection}>
                    {advocateTaskId &&
                        advocateTask?.advocateTask?.patientId &&
                        currentUserData?.currentUser?.id && (
                            <PatientActions
                                actorId={currentUserData.currentUser.id}
                                patientId={advocateTask.advocateTask.patientId}
                                advocateTaskId={advocateTaskId}
                                patientActionTypes={advocateTask.advocateTask.actionTypes}
                                patientActions={advocateTask.advocateTask.patientActions}
                                onPatientActionsUpdated={onPatientActionsUpdated}
                            />
                        )}
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    justifyContent="center"
                    className={classes.infoSection}
                >
                    {currentUserData?.currentUser && advocateTaskData?.comments && (
                        <Comments
                            comments={toAdvocateTaskCommentsInput(advocateTaskData.comments)}
                            commentAuthors={toCommentAuthorsForm(advocateTaskData.commentAuthors)}
                            onCommentsUpdated={onCommentsUpdated}
                            currentUser={currentUserData.currentUser}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    justifyContent="center"
                    className={classes.infoSection}
                >
                    {advocateTaskData?.history && (
                        <History
                            history={toAdvocateTaskHistoryInput(advocateTaskData.history)}
                            historyFigures={toHistoryFiguresForm(advocateTaskData.historyFigures)}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid item className={classes.sidebarWrapper} xs={4} md={3} lg={2}>
                {currentUserData?.currentUser && advocateTaskData && (
                    <Sidebar
                        onSidebarUpdated={onSidebarUpdated}
                        status={advocateTaskData.status}
                        dueDate={advocateTaskData.dueDate}
                        typeId={advocateTaskData.typeId}
                        types={advocateTaskData.types}
                        priority={advocateTaskData.priority}
                        assignedTo={advocateTaskData.assignedTo}
                        usersToAssign={advocateTaskData.usersToAssign}
                        taskCreatorName={advocateTaskData.taskCreator.name}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default AdvocateTask;
