import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { PAGESIZE } from '~/constants';
import { HighlightsForListPageDocument, useSaveHighlightMutation } from '~/schemaTypes';
import { toBase64 } from '~/helpers/base64Helper';

export const CloneHighlightModal: React.FC<{
    isOpen: boolean;
    selectedHighlight: any;
    onClose: () => void;
}> = props => {
    const { isOpen, selectedHighlight, onClose } = props;
    const [newName, setNewName] = useState<string>('');
    const history = useNavigate();

    const handleClose = () => {
        onClose();
    };
    const [saveHighlight, { loading: saveHighlightLoading }] = useSaveHighlightMutation({
        onCompleted: data => {
            onClose();
            history(`/app-config/highlights/${data.saveHighlight?.resource?.id ?? 'new'}`);
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: HighlightsForListPageDocument,
                variables: {
                    input: {
                        pagination: {
                            skip: 0,
                            limit: PAGESIZE,
                        },
                        filter: null,
                    },
                },
            },
        ],
    });

    const onSubmit = () => {
        if (newName.length > 0 && selectedHighlight && selectedHighlight.name !== newName) {
            saveHighlight({
                variables: {
                    input: {
                        id: undefined,
                        data: {
                            name: newName,
                            type: selectedHighlight.type,
                            clockType: selectedHighlight.clockType,
                            startDate: selectedHighlight.startDate,
                            endDate: selectedHighlight.endDate,
                            timelineId: selectedHighlight.timelineId,
                            offsetDays: selectedHighlight.offsetDays,
                            offsetMonths: selectedHighlight.offsetMonths,
                            offsetYears: selectedHighlight.offsetYears,
                            duration: selectedHighlight.duration,
                            durationTimeUnit: selectedHighlight.durationTimeUnit,
                            priority: selectedHighlight.priority,
                            includeApps: selectedHighlight.includeApps,
                            excludeApps: selectedHighlight.excludeApps,
                            titleMarkup: selectedHighlight.titleMarkup && {
                                en: toBase64(selectedHighlight.titleMarkup.en),
                                es: toBase64(selectedHighlight.titleMarkup.es),
                            },
                            tags: selectedHighlight.tags,
                            headerMarkup: selectedHighlight.headerMarkup && {
                                en: toBase64(selectedHighlight.headerMarkup.en),
                                es: toBase64(selectedHighlight.headerMarkup.es),
                            },
                            bodyMarkup: selectedHighlight.bodyMarkup && {
                                en: toBase64(selectedHighlight.bodyMarkup.en),
                                es: toBase64(selectedHighlight.bodyMarkup.es),
                            },
                            icon: selectedHighlight.icon,
                            imageContentId: selectedHighlight.imageContentId,
                            surveyId: selectedHighlight.surveyId,
                            simpleContentId: selectedHighlight.simpleContentId,
                            closeable: selectedHighlight.closeable,
                            highlighted: selectedHighlight.highlighted,
                            shouldSendNotification: selectedHighlight.shouldSendNotification,
                            notificationText: selectedHighlight.notificationText && {
                                en: selectedHighlight.notificationText.en,
                                es: selectedHighlight.notificationText.es,
                            },
                        },
                    },
                },
            });
        }
    };
    if (selectedHighlight)
        return (
            <Dialog open={isOpen}>
                <DialogTitleWithClose onClose={handleClose} id="modalTitle">
                    Clone {selectedHighlight.name}
                </DialogTitleWithClose>
                <DialogContent>
                    {saveHighlightLoading ? (
                        <Loading height={140} />
                    ) : (
                        <OutlinedSection title="New Highlight Name *">
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                onChange={e => setNewName(e.target.value)}
                            />
                        </OutlinedSection>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button type="submit" color="secondary" variant="contained" onClick={onSubmit}>
                        Clone
                    </Button>
                </DialogActions>
            </Dialog>
        );
    return null;
};
