import { useMemo } from 'react';
import { OUMA_ORG_ID, SIMPLIFED_ORG_ID } from './constants';

type SubmittedClaimsProps = {
    orgId: string;
};
const useSubmittedClaims = (props: SubmittedClaimsProps): { hasClaimsFeature: boolean } => {
    const { orgId } = props;
    const hasClaimsFeature = useMemo<boolean>(
        () => [OUMA_ORG_ID, SIMPLIFED_ORG_ID].includes(orgId),
        [orgId],
    );
    return { hasClaimsFeature };
};

export default useSubmittedClaims;
