import React, { useRef, useState, Ref, useMemo, useEffect } from 'react';
import { PAGESIZE } from '~/constants';
import {
    OrderByDirectionEnum,
    useEligibilityMatchsV2LazyQuery,
    EligibilityMatchsV2Query,
    EligibilityMatchUpdateInput,
    useAccessCodesLazyQuery,
    AccessCodesQuery,
} from '~/schemaTypes';

type TableProps<T> = {
    rowsPerPage: number;
    page: number;
    tableRef: Ref<HTMLDivElement>;
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
    handleChangeRowsPerPage: (pageSize: number) => void;
    data: {
        loading: boolean;
        data?: T;
    };
    fetchQuery: () => void;
};

export enum matchesType {
    noMatches,
    Matches,
}

type ConfigType = {
    [key in matchesType]: {
        orderBy: string;
        filter: Partial<EligibilityMatchUpdateInput>;
    };
};

const fetchQueryConfigs: ConfigType = {
    [matchesType.Matches]: {
        orderBy: 'matchDateTime',
        filter: { hasMatch: true, toBeReviewed: false },
    },
    [matchesType.noMatches]: {
        orderBy: 'updatedAt',
        filter: { hasMatch: false, toBeReviewed: false },
    },
};

const useTableProps = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [page, setPage] = useState<number>(0);
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    return {
        tableRef,
        handleChangePage,
        handleChangeRowsPerPage,
        rowsPerPage,
        page,
    };
};

const useTable = ({ type }: { type: matchesType }): TableProps<EligibilityMatchsV2Query> => {
    const [getEligibilityMatches, { data, loading }] = useEligibilityMatchsV2LazyQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    });
    const config = useMemo(() => fetchQueryConfigs[type], [type]);
    const { tableRef, handleChangePage, handleChangeRowsPerPage, rowsPerPage, page } =
        useTableProps();
    const fetchPayload = useMemo(
        () => ({
            variables: {
                input: {
                    filter: {
                        fields: config.filter,
                    },
                    orderBy: {
                        field: config.orderBy,
                        order: OrderByDirectionEnum.Desc,
                    },
                    pagination: {
                        skip: page * rowsPerPage,
                        limit: rowsPerPage,
                    },
                },
            },
        }),
        [page, rowsPerPage, config],
    );
    useEffect(() => {
        getEligibilityMatches(fetchPayload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchPayload]);
    return {
        tableRef,
        handleChangePage,
        handleChangeRowsPerPage,
        rowsPerPage,
        page,
        data: { loading, data },
        fetchQuery: () => getEligibilityMatches(fetchPayload),
    };
};

export const useAccessCodeTable = (unitName: string): TableProps<AccessCodesQuery> => {
    const [getAccessCodes, { data, loading }] = useAccessCodesLazyQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    });

    const { tableRef, handleChangePage, handleChangeRowsPerPage, rowsPerPage, page } =
        useTableProps();
    const fetchPayload = useMemo(
        () => ({
            variables: {
                input: {
                    pagination: {
                        skip: page * rowsPerPage,
                        limit: rowsPerPage,
                    },
                    unitName,
                },
            },
        }),
        [page, rowsPerPage, unitName],
    );
    useEffect(() => {
        getAccessCodes(fetchPayload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchPayload]);
    return {
        tableRef,
        handleChangePage,
        handleChangeRowsPerPage,
        rowsPerPage,
        page,
        data: { loading, data },
        fetchQuery: () => getAccessCodes(fetchPayload),
    };
};

export default useTable;
