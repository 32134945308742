import { Box, Button, Card, Grid, Typography, List, ListItem } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { GetReferenceLinksForPageResponse, LinkDataType } from '~/schemaTypes';

type ReferenceLinksProps = {
    input: any;
};

const useStyles = makeStyles()({
    button: {
        top: '29px',
        left: '-90px',
        position: 'absolute',
        borderRadius: '10px',
        transform: 'rotate(270deg) translateY(35px)',
        backgroundColor: 'WhiteSmoke',
        '&:hover': {
            backgroundColor: 'WhiteSmoke',
        },
    },
    sidebar: {
        position: 'absolute',
        top: '250px',
        width: '450px',
        transition: 'right 0.3s ease-in-out',
        right: '-450px',
    },
    sidebarOpen: {
        position: 'absolute',
        top: '250px',
        width: '450px',
        transition: 'right 0.3s ease-in-out',
        right: 0,
    },
    card: {
        marginBottom: 0,
        padding: 0,
        minHeight: '100px',
        maxHeight: '70vh',
        border: '1px solid black',
        backgroundColor: 'WhiteSmoke',
        overflow: 'auto',
    },
});

const ReferenceLinksComponent: React.FC<ReferenceLinksProps> = ({ input }) => {
    const { classes } = useStyles();
    const [state, setState] = useState(false);
    let newTab: Window | null;

    const handleOpenSidebar = () => setState(!state);

    const handleLinkClick = (url: string) => {
        if (newTab) {
            if (newTab.closed) {
                newTab = window.open(url);
            } else {
                newTab.location.replace(url);
            }
        } else {
            newTab = window.open(url);
        }
    };

    return (
        <Box
            sx={{ width: 250, zIndex: 10 }}
            className={`${state ? classes.sidebarOpen : classes.sidebar}`}
        >
            <Card className={classes.card}>
                <Grid container>
                    {(!input || input.length < 1) && (
                        <Typography
                            color="primary"
                            gutterBottom
                            variant="body1"
                            style={{ padding: '20px' }}
                        >
                            Nothing to show
                        </Typography>
                    )}
                    {input && input.length > 0 && (
                        <List>
                            {input.map((item: GetReferenceLinksForPageResponse) => (
                                <ListItem key={item.category}>
                                    <Grid container direction="column">
                                        <Typography color="primary" gutterBottom variant="body1">
                                            <b>{item.category}</b>:
                                        </Typography>
                                        {item.data &&
                                            item.data.length > 0 &&
                                            item.data.map((link: LinkDataType) => (
                                                <div key={link.label}>
                                                    <Button
                                                        onClick={() =>
                                                            handleLinkClick(link.url || '')
                                                        }
                                                        variant="text"
                                                    >
                                                        <Typography>{link.label}</Typography>
                                                    </Button>
                                                </div>
                                            ))}
                                    </Grid>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Grid>
            </Card>
            <Button
                className={classes.button}
                variant="outlined"
                onClick={() => handleOpenSidebar()}
            >
                More Info
            </Button>
        </Box>
    );
};

export default ReferenceLinksComponent;
