import { Alert, Button, Fade, Snackbar } from '@mui/material';
import React from 'react';
import { AlertSeverity, useFetchBannerStateQuery } from '~/schemaTypes';
import { CommonEnum } from '~/selectors';
import { GlobalBanner, TriggerGlobalBanner } from '~/state';

export const GlobalBannerComponent: React.FC = () => {
    const { data: globalBannerData, loading: globalBannerDataLoading } = useFetchBannerStateQuery();

    const handleBannerExecute = () => {
        const { callback } = GlobalBanner();
        callback();
        TriggerGlobalBanner({
            open: false,
            message: '',
            actionText: '',
            severity: AlertSeverity.Info,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            callback: () => {},
        });
    };

    if (globalBannerDataLoading) {
        return null;
    }

    return (
        <Fade in>
            <div>
                <Snackbar
                    open={globalBannerData?.GlobalBanner.open}
                    onClose={() =>
                        GlobalBanner({
                            open: false,
                            message: '',
                            actionText: '',
                            severity: AlertSeverity.Info,
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            callback: () => {},
                        })
                    }
                    ClickAwayListenerProps={{ mouseEvent: false }}
                    data-test={CommonEnum.GLOBAL_BANNER_CONTAINER}
                >
                    <Alert
                        onClose={() =>
                            GlobalBanner({
                                open: false,
                                message: '',
                                actionText: '',
                                severity: AlertSeverity.Info,
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                callback: () => {},
                            })
                        }
                        severity={globalBannerData?.GlobalBanner.severity}
                    >
                        {globalBannerData?.GlobalBanner.message}
                        {globalBannerData?.GlobalBanner.actionText && (
                            <Button
                                color="primary"
                                variant="outlined"
                                style={{ marginRight: 10, marginLeft: 10 }}
                                onClick={handleBannerExecute}
                            >
                                {globalBannerData?.GlobalBanner.actionText}
                            </Button>
                        )}
                    </Alert>
                </Snackbar>
            </div>
        </Fade>
    );
};
