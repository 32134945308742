import * as Yup from 'yup';
import { yupLocalizedString } from '~/helpers/yupLocalizedString';
import { ClientSelectionType } from '~/enums/enums';
import { CarePlanType } from './types';

export const CARE_PLAN_SCHEMA = Yup.object().shape({
    portalLabel: Yup.string().required('Label is a required field'),
    timelineId: Yup.string().required('Timeline is a required field'),
    clientSelectionType: Yup.mixed()
        .oneOf(Object.values(ClientSelectionType))
        .when('$type', ([type], schema) => {
            return type === CarePlanType.Base
                ? schema.oneOf(Object.values(ClientSelectionType)).required()
                : schema;
        }),
    includeApps: Yup.array()
        .of(Yup.string())
        .when('clientSelectionType', ([clientSelectionType], schema) => {
            return clientSelectionType === ClientSelectionType.IncludeCertainApps
                ? schema.min(1, 'Must select at least one app to include').required()
                : schema;
        }),
    excludeApps: Yup.array()
        .of(Yup.string())
        .when('clientSelectionType', ([clientSelectionType], schema) => {
            return clientSelectionType === ClientSelectionType.ExcludeCertainApps
                ? schema.min(1, 'Must select at least one app to exclude').required()
                : schema;
        }),
    todoGroups: Yup.array().of(
        Yup.object().shape({
            label: Yup.object().shape({
                en: Yup.string().required('Label (en) is required'),
                es: Yup.string(),
            }),
        }),
    ),
    appLabel: yupLocalizedString().when(
        ['$type', '$overrideState'],
        ([type, overrideState], schema) => {
            return type === CarePlanType.Base || overrideState.appLabel
                ? yupLocalizedString({ required: true })
                : schema;
        },
    ),
    tags: Yup.array().of(Yup.string()),
    viewLimitValue: Yup.number().required(),
    viewLimitUnit: Yup.string().required(),
});
