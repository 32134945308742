export enum AppDefaultEnum {
    NAV_APP_DEFAULTS = 'app-defaults',
    CONTAINER = 'app-defaults-container',
    MODAL = 'app-default-modal',
    MODAL_NAME = 'app-default-modal-name',
    MODAL_CATEGORY = 'app-default-modal-category',
    MODAL_DESCRIPTION = 'app-default-modal-description',
    MODAL_VALUE_TYPE = 'app-default-modal-value-type',
    MODAL_VALIDATION_PATTERN = 'app-default-modal-validation-pattern',
    MODAL_SAVE_BUTTON = 'app-default-modal-save',
    DEFAULT_BOOLEAN_VALUE = 'default-boolean-value',
}

export const AppDefaultSelectors = {
    NAV_APP_DEFAULTS: `a[data-test="${AppDefaultEnum.NAV_APP_DEFAULTS}"]`,
    ADD_APP_DEFAULT_BUTTON: `div[data-test="${AppDefaultEnum.CONTAINER}"] button[title="Add App Default"]`,
    EDIT_APP_DEFAULT_BUTTON: `div[data-test="${AppDefaultEnum.CONTAINER}"] button[title="Edit App Default"]`,
    MODAL: `div[data-test="${AppDefaultEnum.MODAL}"] div[aria-labelledby="form-dialog-title"]`,
    MODAL_NAME: `div[data-test="${AppDefaultEnum.MODAL_NAME}"] input[name="name"]`,
    MODAL_CATEGORY: `div[data-test="${AppDefaultEnum.MODAL_CATEGORY}"] input[name="category"]`,
    MODAL_DESCRIPTION: `div[data-test="${AppDefaultEnum.MODAL_DESCRIPTION}"] input[name="description"]`,
    MODAL_VALIDATION_PATTERN: `div[data-test="${AppDefaultEnum.MODAL_VALIDATION_PATTERN}"] input[name="validationPattern"]`,
    MODAL_VALUE_TYPE_DROPDOWN: `div[data-test="${AppDefaultEnum.MODAL_VALUE_TYPE}"] div[role="button"]`,
    VALUE_TYPE_DROPDOWN_BOOL_OPTION: 'ul[aria-labelledby="valueType-label"] li[data-test="Bool"]',
    DEFAULT_BOOLEAN_VALUE_CHECKBOX: `span[data-test="${AppDefaultEnum.DEFAULT_BOOLEAN_VALUE}"] input[type="checkbox"]`,
    MODAL_SAVE_BUTTON: `button[data-test="${AppDefaultEnum.MODAL_SAVE_BUTTON}"]`,
    SEARCH_INPUT: `div[data-test="${AppDefaultEnum.CONTAINER}"] input[placeholder="Search"]`,
};
