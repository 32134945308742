import React from 'react';
import { Chip, Paper } from '@mui/material';

type ZipCode = {
    value: string;
};

type ZipCodesViewerProps = {
    zipCodes: ZipCode[];
    handleDeleteZipCode: (zipCode: string) => void;
};

const ZipCodesViewer: React.FC<ZipCodesViewerProps> = props => {
    const { zipCodes, handleDeleteZipCode } = props;
    return (
        <Paper
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                alignContent: 'flex-start',
                listStyle: 'none',
                p: 0.5,
                m: 0,
                height: 200,
                overflowY: 'scroll',
                overflowX: 'hidden',
            }}
            component="ul"
        >
            {zipCodes.map(({ value }) => (
                <li key={value} style={{ padding: 5 }}>
                    <Chip label={value} onDelete={() => handleDeleteZipCode(value)} />
                </li>
            ))}
        </Paper>
    );
};

export default ZipCodesViewer;
