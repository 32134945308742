import { useVirtualCarePatientsAffiliateByAffiliateIdQuery } from '~/schemaTypes';

export const useAffiliate = (affiliateId: string) => {
    const { data, loading } = useVirtualCarePatientsAffiliateByAffiliateIdQuery({
        variables: {
            input: {
                affiliateId,
            },
        },
    });
    return { affiliate: data?.virtualCareAffiliateByAffiliateId?.data, loading };
};
