import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    root: {
        position: 'relative',
        '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
            opacity: '0',
        },
    },
    paper: { minWidth: '400px' },
    titleDivider: {
        marginBottom: '20px',
    },
    saveButtons: {
        width: '100%',
        textAlign: 'right',
    },
    dialog: {
        padding: '10px',
    },
    emailBodyErrorContainer: {
        borderColor: '#e74c3c',
    },
    emailBodyErrorText: {
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: 1.66,
        letterSpacing: '0.03333em',
        textAlign: 'left',
        marginTop: '3px',
        marginRight: '14px',
        marginBottom: 0,
        marginLeft: '14px',
        color: '#e74c3c',
    },
});
