import { Autocomplete, Button, Card, Dialog, Grid, TextField, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { definition as faPenToSquare } from '@fortawesome/free-solid-svg-icons/faPenToSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import { ASSIGNED_HEALTH_ADVOCATE_PROFILE_DEFINITION_ID, PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUser, useUserPermissions } from '~/hooks';
import {
    CareTeamMember,
    FetchPatientProfileVariablesDocument,
    FetchProfileValueHistoriesByProfileKeysForPatientDocument,
    Permission,
    ProfileVariableForPatientCareTeamDocument,
    Target,
    useCareTeamMembersV2ForPatientCareTeamLazyQuery,
    useCareTeamMemberTypesV2ForPatientCareTeamQuery,
    usePatientProfileVariablesForPatientCareTeamPageLazyQuery,
    useProfileVariableForPatientCareTeamQuery,
    UsersV2ForPatientCareTeamQuery,
    useUpdatePatientProfileVariableForCareTeamMutation,
    useUsersV2ForPatientCareTeamQuery,
    useUsStateListQuery,
} from '~/schemaTypes';
import { WEIGHT_UNIT_DEFAULT } from '~/components/WeightValue/WeightValue';
import PatientCareTeamMemberModal from './PatientCareTeamMembersModal/PatientCareTeamMembersModal';
import useStyles from './styles';
import PatientProfileVariableModal from '../PatientProfileVariables/PatientProfileVariableModal/PatientProfileVariableModal';
import { UpdatedProfileVariable } from '../../PatientProfileVariableLink/types';
import { getPVToEditCOnvertedWeight } from '../PatientProfileVariables/PatientProfileVariables';
import { client } from '../../../../../ApolloClientWrapper';

type User = NonNullable<UsersV2ForPatientCareTeamQuery['usersV2']['results'][0]>;

export const PatientCareTeam: React.FC = () => {
    const { classes } = useStyles();
    const { id: patientId } = useParams<{ id: string }>();
    const { pagePermissions } = useUserPermissions();
    const [assignableUsers, setAssignableUsers] = useState<User[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [careTeamMembers, setCareTeamMembers] = useState<CareTeamMember[]>([]);
    const [showCareTeamMemberModal, setShowCareTeamMemberModal] = useState(false);
    const [showCareTeamMemberEditModal, setShowCareTeamMemberEditModal] = useState(false);
    const [careTeamMember, setCareTeamMember] = useState<CareTeamMember | null>(null);
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [supportedUnitName, setSupportedUnitName] = useState<string>(WEIGHT_UNIT_DEFAULT);
    const { data: userData } = useUser();

    const { data: usStateListData, loading: usStateListLoading } = useUsStateListQuery();

    const { loading: assignedCareTeamLoading } = useProfileVariableForPatientCareTeamQuery({
        variables: {
            patientId,
            profileVariableDefId: ASSIGNED_HEALTH_ADVOCATE_PROFILE_DEFINITION_ID,
        },
        onCompleted: ({ profileVariable }) => {
            setSelectedUserId(profileVariable?.value?.str || null);
        },
    });

    const { loading: usersToAssignLoading } = useUsersV2ForPatientCareTeamQuery({
        onCompleted: ({ usersV2 }) => {
            if (usersV2) {
                const assignableUsersData = usersV2.results.filter(user => {
                    const hasPermission = user.role?.permissions?.filter(
                        permission =>
                            permission?.target === Target.IsHealthAdvocate &&
                            permission?.permission !== Permission.None,
                    );
                    if (hasPermission?.length && hasPermission?.length > 0) {
                        return user;
                    }
                    return null;
                });
                setAssignableUsers(assignableUsersData || []);
            }
        },
    });

    const [fetchCareTeamMembers, { loading: careTeamMembersLoading }] =
        useCareTeamMembersV2ForPatientCareTeamLazyQuery({
            onCompleted: ({ careTeamMembersV2 }) => {
                if (careTeamMembersV2) {
                    setCareTeamMembers(state => [...state, ...careTeamMembersV2.results]);
                }
            },
        });

    const [
        fetchPatientsPV,
        {
            data: patientProfileVariablesForPatientCareTeam,
            loading: patientProfileVariablesLoading,
            refetch: refetchPatientsPV,
        },
    ] = usePatientProfileVariablesForPatientCareTeamPageLazyQuery({
        onCompleted: ({ patientProfileVariables }) => {
            if (patientProfileVariables) {
                const patientProfileVariablesWithValueSet = patientProfileVariables.reduce(
                    (acc: string[], curr) => {
                        if (curr.stringifiedValue && curr.stringifiedValue !== 'None') {
                            acc.push(curr.stringifiedValue);
                            return acc;
                        }
                        return acc;
                    },
                    [],
                );
                if (patientProfileVariablesWithValueSet.length) {
                    fetchCareTeamMembers({
                        variables: {
                            input: {
                                filter: {
                                    fieldsInList: {
                                        _id: patientProfileVariablesWithValueSet,
                                    },
                                },
                            },
                        },
                    });
                }
            }
        },
    });

    const { loading: careTeamMemberTypesLoading } = useCareTeamMemberTypesV2ForPatientCareTeamQuery(
        {
            onCompleted: ({ careTeamMemberTypesV2 }) => {
                if (careTeamMemberTypesV2 && patientId) {
                    const profileVariableDefIds = careTeamMemberTypesV2.results.map(
                        ({ profileDefId }) => profileDefId,
                    );

                    fetchPatientsPV({
                        variables: {
                            patientId,
                            profileVariableDefIds,
                        },
                    });
                }
            },
            fetchPolicy: 'network-only',
        },
    );

    const [updateAssignedHAProfileVariable, { loading: updateAssignedHAProfileVariableLoading }] =
        useUpdatePatientProfileVariableForCareTeamMutation({
            optimisticResponse: {
                updatePatientProfileVariable: {
                    __typename: 'PatientProfileVariable',
                    profileVariableDefId: ASSIGNED_HEALTH_ADVOCATE_PROFILE_DEFINITION_ID,
                    value: {
                        __typename: 'UserProfileValueSchema',
                        str: selectedUserId,
                    },
                },
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: ProfileVariableForPatientCareTeamDocument,
                    variables: {
                        patientId,
                        profileVariableDefId: ASSIGNED_HEALTH_ADVOCATE_PROFILE_DEFINITION_ID,
                    },
                },
            ],
        });

    const handleAssignHealthAdvocate = (user: User | null) => {
        if (patientId) {
            updateAssignedHAProfileVariable({
                variables: {
                    input: {
                        portalPatientId: patientId,
                        profileVariableDefId: ASSIGNED_HEALTH_ADVOCATE_PROFILE_DEFINITION_ID,
                        value: {
                            str: user?.id || '',
                        },
                    },
                },
            });
        }
    };

    const handleViewCareTeamMember = (event: any, element: any) => {
        setCareTeamMember(
            careTeamMembers.find(careTeamMember => careTeamMember.id === element.id) ?? null,
        );
        setShowCareTeamMemberModal(true);
    };

    const handleEditCareTeamMember = (event: any, element: any) => {
        setCareTeamMember(
            careTeamMembers.find(careTeamMember => careTeamMember.id === element.id) ?? null,
        );
        setShowCareTeamMemberEditModal(true);
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    const handleEditModalClose = (data: UpdatedProfileVariable) => {
        if (data) {
            const inputData =
                data.updatePatientProfileVariableMutation?.updatePatientProfileVariable;
            const profileDefId = inputData?.profileVariableDefId;
            const careTeamMemberId = inputData?.value.str;
            if (!profileDefId || !careTeamMemberId) {
                return;
            }

            const unchangedCareTeamMembers = careTeamMembers.filter(
                careTeamMember => careTeamMember.careTeamMemberType?.profileDefId !== profileDefId,
            );

            setCareTeamMembers(unchangedCareTeamMembers);
            fetchCareTeamMembers({
                variables: {
                    input: {
                        filter: {
                            fieldsInList: {
                                _id: [careTeamMemberId],
                            },
                        },
                    },
                },
            });

            refetchPatientsPV();

            // Invalidate profile variables and histories cache, so that updates will display at Patient Profile Variables
            client.refetchQueries({
                include: [
                    FetchPatientProfileVariablesDocument,
                    FetchProfileValueHistoriesByProfileKeysForPatientDocument,
                ],
                updateCache(cache) {
                    cache.evict({ fieldName: 'getProfileValueHistoriesByProfileKeys' });
                    cache.evict({ fieldName: 'patientProfileVariables' });
                },
            });
        }

        setShowCareTeamMemberEditModal(false);
    };

    useEffect(() => {
        if (userData?.currentUser) {
            const currentOrgWeightUnit =
                userData?.currentUser?.currentOrg.organizationMeasurementConfig?.weight?.units;
            const rootOrgWeightUnit =
                userData?.currentUser?.rootOrg.organizationMeasurementConfig?.weight?.units;
            const weightUnitFromOrg = currentOrgWeightUnit ?? rootOrgWeightUnit;
            if (weightUnitFromOrg) {
                const weightUnit = weightUnitFromOrg ?? WEIGHT_UNIT_DEFAULT;
                setSupportedUnitName(weightUnit);
            }
        }
    }, [userData]);

    if (assignedCareTeamLoading || usersToAssignLoading || updateAssignedHAProfileVariableLoading) {
        return <Loading />;
    }

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Card className={classes.card}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={assignableUsers}
                                getOptionLabel={option => option?.name}
                                value={
                                    assignableUsers.find(user => user.id === selectedUserId) || null
                                }
                                autoComplete
                                onChange={(event, value) => {
                                    event.preventDefault();
                                    setSelectedUserId(value?.id);
                                    handleAssignHealthAdvocate(value);
                                }}
                                disabled={!pagePermissions?.PatientCareTeam.Edit}
                                renderInput={params => (
                                    <TextField
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label="Assign Health Advocate"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        {pagePermissions?.PatientCareTeam.Edit && selectedUserId != null && (
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setSelectedUserId(null);
                                        handleAssignHealthAdvocate(null);
                                    }}
                                >
                                    Unassign Health Advocate
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Card>
            </Grid>
            <Grid item ref={tableRef}>
                <MaterialTable
                    title="Care Team Members"
                    isLoading={
                        careTeamMembersLoading ||
                        patientProfileVariablesLoading ||
                        careTeamMemberTypesLoading ||
                        usStateListLoading
                    }
                    icons={tableIcons}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    data={_.cloneDeep(careTeamMembers)}
                    actions={[
                        {
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit Care Team Member',
                            onClick: (event, element) => handleEditCareTeamMember(event, element),
                            hidden: !pagePermissions?.PatientProfileVariables.Edit,
                        },
                        {
                            icon: () => <VisibilityOutlinedIcon className={classes.viewIcon} />,
                            onClick: (event, element) => handleViewCareTeamMember(event, element),
                            tooltip: 'View Care Team Member',
                            hidden: !pagePermissions?.CareTeamMembers.Edit,
                        },
                    ]}
                    columns={[
                        {
                            title: 'Profile Name',
                            defaultSort: 'asc',
                            field: 'label.en',
                        },
                        {
                            title: 'Care Team Member Type',
                            defaultSort: 'asc',
                            field: 'careTeamMemberType.name',
                        },
                        {
                            title: 'App Id',
                            field: 'appBundleId',
                        },
                        {
                            title: 'US State',
                            field: 'usState',
                            sorting: true,
                            render: ({ usState }) => {
                                return (
                                    <Typography>
                                        {
                                            usStateListData?.usStateList.find(
                                                i => i.abbreviation === usState,
                                            )?.name
                                        }
                                    </Typography>
                                );
                            },
                        },
                        {
                            title: 'Zip Code',
                            field: 'zipCode',
                            sorting: true,
                            render: ({ zipCode }) => <Typography>{zipCode}</Typography>,
                        },
                    ]}
                    localization={{ header: { actions: '' } }}
                    options={{
                        pageSize: rowsPerPage,
                        search: true,
                    }}
                />
                {careTeamMember && usStateListData && (
                    <Dialog
                        scroll="body"
                        open={Boolean(careTeamMember) && showCareTeamMemberModal}
                        fullWidth
                        maxWidth="lg"
                        aria-labelledby="form-dialog-title"
                    >
                        <PatientCareTeamMemberModal
                            setOpen={setShowCareTeamMemberModal}
                            careTeamMember={careTeamMember}
                            usStateList={usStateListData?.usStateList}
                        />
                    </Dialog>
                )}
                {careTeamMember &&
                    patientId &&
                    patientProfileVariablesForPatientCareTeam?.patientProfileVariables && (
                        <Dialog
                            scroll="body"
                            open={Boolean(careTeamMember) && showCareTeamMemberEditModal}
                            fullWidth
                            maxWidth="lg"
                            aria-labelledby="form-dialog-title"
                        >
                            <PatientProfileVariableModal
                                appBundleId={careTeamMember.appBundleId}
                                patientId={patientId}
                                patientProfileVariable={getPVToEditCOnvertedWeight(
                                    patientProfileVariablesForPatientCareTeam.patientProfileVariables,
                                    careTeamMember?.careTeamMemberType?.profileDefId,
                                    supportedUnitName,
                                )}
                                closeHandler={handleEditModalClose}
                            />
                        </Dialog>
                    )}
            </Grid>
        </Grid>
    );
};
