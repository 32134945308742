import { Button, FormHelperText, Grid, MenuItem, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import React from 'react';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import { BioScreenShortcutIcon, BioScreenShortcutType } from '~/schemaTypes';
import { ProfileScreenEnum } from '~/selectors';
import { ShortcutProps } from './types/ShortcutProps';

const Shortcut: React.FC<ShortcutProps> = ({
    index,
    classes,
    useFormMethods,
    onDelete,
    portalTags,
    shortcut,
    surveyGroups,
    surveyDefs,
}) => {
    const {
        control,
        watch,
        register,
        formState: { errors },
    } = useFormMethods;

    const { type, icon, label, surveyGroupId, instructions, surveyId } = shortcut;

    const watchedType = watch(`shortcuts.${index}.type`);

    function handleDeleteShortcutClick(index: number) {
        return () => onDelete(index);
    }

    const { ref: refInstructionsEn, ...refInstructionsEnRest } = register(
        `shortcuts.${index}.instructions.en`,
    );
    const { ref: refInstructionsEs, ...refInstructionsEsRest } = register(
        `shortcuts.${index}.instructions.es`,
    );

    return (
        <OutlinedSection title="Shortcut">
            <Grid container item xs={12}>
                <Grid container item xs={12}>
                    <OutlinedSection title="Label">
                        <TextField
                            variant="outlined"
                            label="English *"
                            defaultValue={label?.en ?? ''}
                            error={Boolean(errors.shortcuts?.[index]?.label?.en)}
                            helperText={errors.shortcuts?.[index]?.label?.en?.message}
                            {...register(`shortcuts.${index}.label.en`)}
                            fullWidth
                            margin="dense"
                            data-test={ProfileScreenEnum.SHORTCUT_LABEL}
                        />
                        <TextField
                            variant="outlined"
                            label="Spanish"
                            defaultValue={label?.es ?? ''}
                            error={Boolean(errors.shortcuts?.[index]?.label?.es)}
                            helperText={errors.shortcuts?.[index]?.label?.es?.message}
                            {...register(`shortcuts.${index}.label.es`)}
                            fullWidth
                            margin="dense"
                        />
                    </OutlinedSection>
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormSelect
                        control={control}
                        name={`shortcuts.${index}.type`}
                        variant="outlined"
                        defaultValue={type || BioScreenShortcutType.Survey}
                        label="Type *"
                        fullWidth
                        margin="dense"
                        error={
                            errors.shortcuts &&
                            errors.shortcuts[index] &&
                            !!errors.shortcuts[index]?.message
                        }
                    >
                        {Object.values(BioScreenShortcutType)
                            .filter(value => value !== BioScreenShortcutType.YourHealthInfo)
                            .map(value => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                    </ReactHookFormSelect>
                    {errors.shortcuts &&
                        errors.shortcuts[index] &&
                        errors.shortcuts[index]?.message && (
                            <FormHelperText error className={classes.helperMessage}>
                                {errors.shortcuts[index]?.message}
                            </FormHelperText>
                        )}
                    {watchedType === BioScreenShortcutType.SurveyGroup && (
                        <ReactHookFormSelect
                            control={control}
                            name={`shortcuts.${index}.surveyGroupId`}
                            variant="outlined"
                            defaultValue={surveyGroupId || ''}
                            label="Survey Group *"
                            fullWidth
                            margin="dense"
                        >
                            {surveyGroups.map(({ id, label }) => (
                                <MenuItem key={id} value={id || ''}>
                                    {label?.en}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                    )}
                    {watchedType === BioScreenShortcutType.Survey && (
                        <ReactHookFormSelect
                            control={control}
                            name={`shortcuts.${index}.surveyId`}
                            variant="outlined"
                            defaultValue={surveyId || ''}
                            label="Survey *"
                            fullWidth
                            margin="dense"
                            data-test={ProfileScreenEnum.SHORTCUT_SURVEY}
                        >
                            {Object.values(surveyDefs).map(({ id, name }) => (
                                <MenuItem key={id} value={id} data-test={name}>
                                    {name}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                    )}
                    {errors.shortcuts &&
                        errors.shortcuts[index] &&
                        (errors.shortcuts[index]?.surveyGroupId?.message ||
                            errors.shortcuts[index]?.surveyId?.message) && (
                            <FormHelperText error className={classes.helperMessage}>
                                {`${errors.shortcuts[index]?.surveyGroupId?.message}`}
                                {`${errors.shortcuts[index]?.surveyId?.message}`}
                            </FormHelperText>
                        )}
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormSelect
                        control={control}
                        name={`shortcuts.${index}.icon`}
                        variant="outlined"
                        defaultValue={icon || ''}
                        label="Icon *"
                        fullWidth
                        margin="dense"
                        error={
                            errors.shortcuts &&
                            errors.shortcuts[index] &&
                            !!errors.shortcuts[index]?.icon?.message
                        }
                        data-test={ProfileScreenEnum.SHORTCUT_ICON}
                    >
                        {Object.values(BioScreenShortcutIcon).map(value => (
                            <MenuItem key={value} value={value} data-test={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                    {errors.shortcuts &&
                        errors.shortcuts[index] &&
                        errors.shortcuts[index]?.icon?.message && (
                            <FormHelperText error className={classes.helperMessage}>
                                {errors.shortcuts[index]?.icon?.message}
                            </FormHelperText>
                        )}
                </Grid>
                <Grid container item xs={12}>
                    <OutlinedSection title="Instructions">
                        <TextField
                            inputRef={refInstructionsEn}
                            {...refInstructionsEnRest}
                            variant="outlined"
                            label="English *"
                            fullWidth
                            defaultValue={instructions?.en ?? ''}
                            multiline
                            rows={5}
                            margin="dense"
                            error={
                                errors.shortcuts &&
                                errors.shortcuts[index] &&
                                !!errors.shortcuts[index]?.instructions?.en?.message
                            }
                            helperText={
                                errors.shortcuts &&
                                errors.shortcuts[index] &&
                                errors.shortcuts[index]?.instructions?.en?.message
                            }
                            data-test={ProfileScreenEnum.SHORTCUT_INSTRUCTIONS}
                        />
                        <TextField
                            inputRef={refInstructionsEs}
                            {...refInstructionsEsRest}
                            variant="outlined"
                            label="Spanish"
                            defaultValue={instructions?.es ?? ''}
                            fullWidth
                            multiline
                            rows={5}
                            margin="dense"
                            error={
                                errors.shortcuts &&
                                errors.shortcuts[index] &&
                                !!errors.shortcuts[index]?.instructions?.es?.message
                            }
                            helperText={
                                errors.shortcuts &&
                                errors.shortcuts[index] &&
                                errors.shortcuts[index]?.instructions?.es?.message
                            }
                        />
                    </OutlinedSection>
                </Grid>
                <Grid item xs={12}>
                    <AutocompleteWithRecordOptions
                        options={portalTags ?? []}
                        name={`shortcuts.${index}.tags`}
                        control={control}
                        valueKey="id"
                        labelKey="name"
                        label="Tags"
                        placeholder="Select tags..."
                        dataTest={ProfileScreenEnum.SHORTCUT_TAGS}
                    />
                </Grid>
                <Grid className={classes.surveyGroupActionBtns} item xs={12}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleDeleteShortcutClick(index)}
                        startIcon={<DeleteIcon />}
                    >
                        Remove Shortcut
                    </Button>
                </Grid>
            </Grid>
        </OutlinedSection>
    );
};

export default Shortcut;
