/* eslint-disable react/jsx-props-no-spreading */
import { TextField } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';

type LocalzedStringTextFieldsProps = {
    register: UseFormRegister<any>;
    name: string;
    label: string;
    required?: {
        en?: boolean;
        es?: boolean;
    };
    multiline?: boolean;
    rhfErrors?: FieldErrors;
    defaultValues?: {
        en?: string;
        es?: string | null;
    };
    disabled?: boolean;
    dataTest?: string;
};

export const LocalizedStringTextFields = ({
    register,
    name,
    label,
    required = { en: false, es: false },
    multiline,
    rhfErrors,
    defaultValues = {},
    disabled = false,
    dataTest,
}: LocalzedStringTextFieldsProps): JSX.Element => {
    const names = {
        en: `${name}.en`,
        es: `${name}.es`,
    };
    const errors = {
        en: _.get(rhfErrors, names.en),
        es: _.get(rhfErrors, names.es),
    };
    return (
        <OutlinedSection title={label}>
            <TextField
                {...register(names.en, { required: required.en, disabled })}
                variant="outlined"
                label={`English ${required.en ? ' *' : ''}`}
                fullWidth
                margin="dense"
                multiline={multiline}
                rows={3}
                error={Boolean(errors?.en)}
                helperText={errors?.en?.message as string}
                defaultValue={defaultValues.en}
                {...(dataTest && { 'data-test': `${dataTest}-en` })}
            />
            <TextField
                {...register(names.es, { required: required.es, disabled })}
                variant="outlined"
                label={`Spanish ${required.es ? ' *' : ''}`}
                fullWidth
                margin="dense"
                multiline={multiline}
                rows={3}
                error={Boolean(errors?.es)}
                helperText={errors?.es?.message as string}
                defaultValue={defaultValues.es}
                {...(dataTest && { 'data-test': `${dataTest}-es` })}
            />
        </OutlinedSection>
    );
};
