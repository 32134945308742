// This component has a UI Test
import { faEye, faPenToSquare, faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, IconButton, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add, InfoOutlined } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    AlertSeverity,
    useFetchProfileDefsForProfileDefsPageQeryQuery,
    useProfileDefWhereUsedLazyQuery,
    UserProfileDef,
    UserProfileValueType,
} from '~/schemaTypes';
import { ProfileDefEnum } from '~/selectors';
import { TriggerGlobalAlert } from '~/state';

import { useParams } from 'react-router-dom';
import ProfileDefsModal from './ProfileDefsModal/ProfileDefsModal';
import ProfileDefsHistoryModal from './ProfileDefHistoryModal/ProfileDefHistoryModal';

export const USER_PROFILE_DEF_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    label: Yup.object().shape({
        en: Yup.string().required('English Label is required'),
        es: Yup.string().nullable(),
    }),
    questionLabel: Yup.object().shape({
        en: Yup.string().required('English Question Label is required'),
        es: Yup.string().nullable(),
    }),
    category: Yup.string().required('Category is required'),
    valueType: Yup.mixed()
        .oneOf(Object.values(UserProfileValueType))
        .required('Value Type is required'),
    choiceValueType: Yup.mixed().when('valueType', ([valueTypeValue], schema) => {
        if (
            valueTypeValue === UserProfileValueType.Choice ||
            valueTypeValue === UserProfileValueType.Choices
        ) {
            return schema
                .oneOf([
                    UserProfileValueType.Str,
                    UserProfileValueType.Num,
                    UserProfileValueType.Bool,
                    UserProfileValueType.Date,
                ])
                .required();
        }
        return schema.notRequired().test({
            name: "Choice Value Type should be empty, because Value Type isn't 'choice' or 'choices'.",
            message:
                "Choice Value Type should be empty, because Value Type isn't 'choice' or 'choices'.",
            test: value => !value,
        });
    }),
    choices: Yup.mixed().when('valueType', ([valueTypeValue], schema) => {
        if (
            valueTypeValue === UserProfileValueType.Choice ||
            valueTypeValue === UserProfileValueType.Choices
        ) {
            return Yup.array().of(
                Yup.object().shape({
                    id: Yup.string(),
                    label: Yup.object()
                        .nullable()
                        .shape({
                            en: Yup.string().required('English is required'),
                            es: Yup.string().nullable(),
                        }),
                    answerLabel: Yup.object()
                        .nullable()
                        .shape({
                            en: Yup.string().required('English is required'),
                            es: Yup.string().nullable(),
                        }),
                    specifyValue: Yup.bool().required('Specify Value is required'),
                    value: Yup.object().shape({
                        str: Yup.string().nullable(),
                        num: Yup.number().nullable(),
                        bool: Yup.bool().nullable(),
                        date: Yup.date().nullable(),
                    }),
                }),
            );
        }
        return schema.notRequired().test({
            name: "Choices should be empty, because Value Type isn't 'choice' or 'choices'.",
            message: "Choices should be empty, because Value Type isn't 'choice' or 'choices'.",
            test: value => !value,
        });
    }),
});

const useStyles = makeStyles()(theme => ({
    root: {
        textOverflow: 'ellipsis',
        ...theme.containerStyles(theme),
        marginTop: 50,
    },
    detailPanel: {
        padding: '10px 40px',
    },
}));

const ProfileDefs: React.FC = () => {
    const { classes } = useStyles();
    const { id } = useParams();
    const [showProfileDefinitionModal, setShowProfileDefinitionModal] = useState(!!id);
    const [showProfileDefHistoryModal, setShowProfileDefHistoryModal] = useState(false);
    const [editProfileDefinitionId, setEditProfileDefinitionId] = useState(id ?? '');
    const [historyProfileDefinitionId, setHistoryProfileDefinitionId] = useState('');
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<UserProfileDef>();
    const [showUsageModal, setShowUsageModal] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        useProfileDefWhereUsedLazyQuery();

    const { data: queryData, loading: queryLoading } =
        useFetchProfileDefsForProfileDefsPageQeryQuery();

    const handleAddProfileDefinition = () => {
        setShowProfileDefinitionModal(true);
    };

    const handleEditProfileDefinition = (event: any, element: any) => {
        setShowProfileDefinitionModal(true);
        setEditProfileDefinitionId(element.id);
    };

    const handleShowProfileDefHistory = (event: any, element: any) => {
        setShowProfileDefHistoryModal(true);
        setHistoryProfileDefinitionId(element.id);
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleShowUsage = (item: UserProfileDef) => {
        whereUsed({
            variables: {
                id: item?.id,
            },
        });
        setShowUsageModal(true);
    };
    const handleDelete = () => {
        // does nothing - profile defs can't be deleted
    };

    return (
        <div className={classes.root} data-test={ProfileDefEnum.CONTAINER} ref={tableRef}>
            <MaterialTable
                title="Profile Definitions"
                isLoading={queryLoading}
                icons={tableIcons}
                data={[...(queryData?.userProfileDefs ?? []).map(o => ({ ...o }))]}
                actions={[
                    {
                        onClick: () => handleAddProfileDefinition(),
                        hidden: !pagePermissions?.ProfileDefs.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Profile Definition',
                        isFreeAction: true,
                    },
                    {
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        hidden: !pagePermissions?.ProfileDefs.Edit,
                        onClick: (event, element) => handleEditProfileDefinition(event, element),
                        tooltip: 'Edit Profile Definition',
                    },
                    {
                        icon: () => <FontAwesomeIcon icon={faHistory} />,
                        onClick: (event, element) => handleShowProfileDefHistory(event, element),
                        tooltip: 'History',
                    },
                    {
                        onClick: (_e, item) => {
                            setSelectedItem(item as UserProfileDef);
                            handleShowUsage(item as UserProfileDef);
                        },
                        icon: () => <FontAwesomeIcon icon={faEye} />,
                        tooltip: 'View References',
                    },
                ]}
                columns={[
                    {
                        title: 'Id',
                        field: 'id',
                        align: 'center',
                        sorting: false,
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Profile Definition Id copied to clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'Profile Name',
                        defaultSort: 'asc',
                        field: 'name',
                        render: ({ name }) => <Typography data-test={name}>{name}</Typography>,
                    },
                    { title: 'Description', field: 'description' },
                    { title: 'Category', field: 'category' },
                    { title: 'Label', field: 'label.en' },
                    { title: 'Question Label', field: 'questionLabel.en' },
                    { title: 'Value Type', field: 'valueType' },
                ]}
                localization={{ header: { actions: '' } }}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    exportAllData: pagePermissions?.ProfileDefs.Export,
                    pageSizeOptions: [25, 50, 100],
                }}
            />
            <Dialog scroll="paper" open={!whereUsedLoading && showUsageModal}>
                <WhereUsedModal
                    onClose={() => setShowUsageModal(false)}
                    onDelete={handleDelete}
                    title={`Usage for ${selectedItem?.name}`}
                    canDelete={false} // Cannot delete profile defs
                    usageData={whereUsedData?.profileDefWhereUsed?.dependencies || []}
                    permissions={pagePermissions}
                />
            </Dialog>
            <Dialog
                scroll="body"
                open={showProfileDefinitionModal}
                fullWidth
                maxWidth="lg"
                aria-labelledby="form-dialog-title"
                data-test={ProfileDefEnum.MODAL_CREATE_PROFILE_DEF_FORM}
            >
                <ProfileDefsModal
                    setOpen={setShowProfileDefinitionModal}
                    setEditProfileDefId={setEditProfileDefinitionId}
                    id={editProfileDefinitionId}
                />
            </Dialog>
            <Dialog
                scroll="body"
                open={showProfileDefHistoryModal}
                fullWidth
                maxWidth="lg"
                aria-labelledby="form-dialog-title"
            >
                <ProfileDefsHistoryModal
                    setOpen={setShowProfileDefHistoryModal}
                    id={historyProfileDefinitionId}
                />
            </Dialog>
        </div>
    );
};

export default ProfileDefs;
