import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField,
} from '@mui/material';
import React, { useState } from 'react';
import {
    useFetchTagsForFilterModalPageQuery,
    useGetUsersByPermissionForFilterModalPageQuery,
} from '~/schemaTypes';

type FilterModalProps = {
    suffix: string;
    open: boolean;
    onClose: () => void;
};

export const FilterModal: React.FC<FilterModalProps> = ({ suffix, open, onClose }) => {
    const [filteredTags, setFilteredTags] = useState<string[]>(
        localStorage
            .getItem(`filteredTags${suffix}`)
            ?.split(',')
            .filter(str => str.length > 1) || [],
    );
    const [filteredAdvocate, setFilteredAdvocate] = useState<string>(
        localStorage.getItem(`filteredAdvocate${suffix}`) || '',
    );
    const [filterTestData, setFilterTestData] = useState<boolean>(
        localStorage.getItem(`filterTestData${suffix}`) === 'true' ?? false,
    );

    const { data: tagData, loading: tagLoading } = useFetchTagsForFilterModalPageQuery({
        variables: {
            input: {
                filter: {
                    fields: {
                        isPatientFilter: true,
                    },
                },
            },
        },
    });

    const { data: advocates, loading: advocatesLoading } =
        useGetUsersByPermissionForFilterModalPageQuery({
            variables: {
                permission: 'isHealthAdvocate',
            },
        });

    const handleSave = () => {
        localStorage.setItem(`filteredTags${suffix}`, filteredTags.join(','));
        localStorage.setItem(`filteredAdvocate${suffix}`, filteredAdvocate);
        localStorage.setItem(`filterTestData${suffix}`, `${filterTestData}`);

        const filtersAppliedCount =
            (filteredAdvocate ? 1 : 0) + (filterTestData ? 1 : 0) + filteredTags.length;

        localStorage.setItem(`filtersAppliedCount${suffix}`, String(filtersAppliedCount));
        onClose();
    };

    const handleClearFilters = () => {
        setFilteredTags([]);
        setFilteredAdvocate('');
        setFilterTestData(false);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Filter {suffix}</DialogTitle>
            <DialogContent>
                <Autocomplete
                    multiple
                    options={tagData?.tagsV2?.results.map(tag => tag.id) || []}
                    getOptionLabel={tag =>
                        tagData?.tagsV2.results.find(t => t.id === tag)?.name ?? ''
                    }
                    filterSelectedOptions
                    value={filteredTags}
                    onChange={(_, value) => setFilteredTags(value)}
                    disabled={tagLoading}
                    renderInput={params => (
                        <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            variant="outlined"
                            label="Tags"
                            placeholder="Tags"
                        />
                    )}
                />
                <Autocomplete
                    options={
                        advocates?.getUsersByPermission
                            ?.map(user => user?.id)
                            .concat(['unassigned']) ?? []
                    }
                    getOptionLabel={user =>
                        advocates?.getUsersByPermission?.find(u => u?.id === user)?.name ?? user
                    }
                    filterSelectedOptions
                    value={filteredAdvocate}
                    onChange={(_, value) => setFilteredAdvocate(value ?? '')}
                    disabled={advocatesLoading}
                    renderInput={params => (
                        <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="Filter Assigned Health Advocates"
                            variant="outlined"
                        />
                    )}
                />
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterTestData}
                                onChange={() => {
                                    setFilterTestData(!filterTestData);
                                    localStorage.setItem(
                                        `filterTestData${suffix}`,
                                        `${!filterTestData}`,
                                    );
                                }}
                            />
                        }
                        label="Enable Test Data To Be Displayed"
                    />
                </div>
                <Button onClick={handleClearFilters}>Clear Filters</Button>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setFilteredTags(
                            localStorage
                                .getItem(`filteredTags${suffix}`)
                                ?.split(',')
                                .filter(str => str.length > 1) ?? [],
                        );
                        setFilteredAdvocate(
                            localStorage.getItem(`filteredAdvocate${suffix}`) ?? '',
                        );
                        onClose();
                    }}
                    color="primary"
                >
                    Close
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
