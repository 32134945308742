import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from 'tss-react/mui';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGESIZE } from '~/constants';
import { displayDate } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    TagListDocument,
    TagListQuery,
    TagUsageType,
    useDeleteTagMutation,
    useTagListQuery,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';

const useStyles = makeStyles()({
    root: {},
});
const TagTriggers: React.FC = () => {
    const { classes } = useStyles();

    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);

    const { data: triggerData, loading: triggersLoading } = useTagListQuery({
        variables: { tagListInput: [TagUsageType.Trigger] },
    });

    const [deleteTag, { loading: deleteTagLoading }] = useDeleteTagMutation({
        update: (cache, res) => {
            const triggersQuery = cache.readQuery<TagListQuery>({
                query: TagListDocument,
            });
            const filteredTriggers =
                triggersQuery?.getTagListByUsageTypes.filter(
                    item => item?.id !== res.data?.deleteTag?.id,
                ) ?? [];
            if (triggersQuery?.getTagListByUsageTypes) {
                cache.writeQuery<TagListQuery>({
                    query: TagListDocument,
                    data: {
                        ...triggersQuery,
                        getTagListByUsageTypes: filteredTriggers,
                    },
                });
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: TagListDocument,
                variables: { tagListInput: [TagUsageType.Trigger] },
            },
        ],
    });

    const handleDeleteTrigger = (id: string) => {
        TriggerGlobalConfirm({
            message: 'Do you really want to delete this Tag Trigger?',
            callback: () => {
                deleteTag({ variables: { input: { id, isTrigger: true } } });
            },
        });
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Triggers"
                icons={tableIcons}
                isLoading={triggersLoading || deleteTagLoading}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                data={_.cloneDeep(triggerData?.getTagListByUsageTypes) ?? []}
                actions={[
                    {
                        onClick: () => history('/app-config/triggers/new'),
                        hidden: !pagePermissions?.Triggers.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Tag Trigger',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => history(`/app-config/triggers/${id}`),
                        hidden: !pagePermissions?.Triggers.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Tag Trigger',
                    },
                    {
                        icon: () => <FontAwesomeIcon icon={faTrash} />,
                        onClick: (_, { id }: any) => handleDeleteTrigger(id),
                        tooltip: 'Delete Tag Trigger',
                        hidden: !pagePermissions?.Triggers.Delete,
                    },
                ]}
                columns={[
                    { title: 'Name', field: 'name', defaultSort: 'asc' },
                    { title: 'Expression', field: 'expression.name' },
                    { title: 'Apps', field: 'appInfo' },
                    {
                        title: 'Last Updated',
                        field: 'updatedAt',
                        render: ({ updatedAt }) => (
                            <>
                                {displayDate({
                                    isoDateStr: updatedAt,
                                })}
                            </>
                        ),
                    },
                ]}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100] }}
                localization={{ header: { actions: '' } }}
            />
        </div>
    );
};

export default TagTriggers;
