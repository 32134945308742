/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { Save } from '@mui/icons-material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import {
    AlertSeverity,
    CreateTestAppUserForSystemAdminMutationMutation,
    CreateTestAppUserInput,
    useCreateTestAppUserForSystemAdminMutationMutation,
    usePreFetchAppsQuery,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import _ from 'lodash';
import DateEditor from '~/components/DateEditor/DateEditor';
import moment from 'moment';
import useStyles from './styles';
import validationSchema from './validationSchema';

type CreateTestAppUserMutation = NonNullable<
    CreateTestAppUserForSystemAdminMutationMutation['createTestAppUserMutation']
>;

const CreateTestAppUser: React.FC = () => {
    const { classes } = useStyles();
    const history = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [appUserData, setAppUserData] = useState<CreateTestAppUserMutation | null>(null);
    const [dob, setDob] = useState(new Date().toISOString());
    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<CreateTestAppUserInput>({
        resolver: yupResolver(validationSchema as any),
    });

    const { data: preFetchData, loading: preFetchLoading } = usePreFetchAppsQuery();

    const [createTestAppUser, { loading: createTestAppUserLoading }] =
        useCreateTestAppUserForSystemAdminMutationMutation({
            onCompleted: data => {
                setAppUserData(data.createTestAppUserMutation ?? null);
                setIsModalOpen(true);
            },
            onError: error => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: `${error}`,
                });
            },
        });

    const onSubmit = (data: CreateTestAppUserInput) => {
        createTestAppUser({
            variables: {
                createTestAppUserInput: {
                    ...data,
                    birthDate: dob,
                    isTestData: true,
                    isCreatedByPortal: true,
                },
            },
        });
    };

    const handleReturnToCreation = () => {
        setIsModalOpen(false);
        reset({
            appBundleId: undefined,
            email: undefined,
            firstName: undefined,
            lastName: undefined,
            birthDate: moment().add(-13, 'years').toISOString(),
        });
    };

    const handleGoToPatient = () => {
        history(`/portal/patients/${appUserData?.patientId}`);
    };
    return (
        <Grid container>
            <Grid xs={12} lg={4}>
                <Card className={classes.formWrapper}>
                    {(!preFetchLoading && !createTestAppUserLoading) || (
                        <div className={classes.loaderWrapper}>
                            <Loading />
                        </div>
                    )}
                    <Typography variant="h4">Create new App User</Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid>
                            <ReactHookFormSelect
                                control={control}
                                {...register('appBundleId', { required: true })}
                                variant="outlined"
                                defaultValue=""
                                label="Client"
                                fullWidth
                                margin="dense"
                                error={!!errors.appBundleId}
                            >
                                {_.sortBy(preFetchData?.applicationsV2.results, 'appBundleId').map(
                                    ({ id, appBundleId }) => (
                                        <MenuItem key={id} value={appBundleId}>
                                            {appBundleId}
                                        </MenuItem>
                                    ),
                                )}
                            </ReactHookFormSelect>
                            <TextField
                                variant="outlined"
                                label="First Name"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('firstName', { required: true })}
                                error={!!errors.firstName}
                                helperText={errors.firstName?.message}
                            />
                            <TextField
                                variant="outlined"
                                label="Last Name"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('lastName', { required: true })}
                                error={!!errors.lastName}
                                helperText={errors.lastName?.message}
                            />
                            <TextField
                                variant="outlined"
                                label="Email"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('email', { required: true })}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                            />
                            <DateEditor
                                title="Date of Birth"
                                setSelectedDate={d => setDob(d)}
                                initialDate={dob}
                            />
                        </Grid>
                        <Grid item style={{ textAlign: 'right' }}>
                            <Button
                                startIcon={<Save />}
                                type="submit"
                                color="secondary"
                                variant="contained"
                            >
                                Create App User
                            </Button>
                        </Grid>
                    </form>
                    {appUserData && (
                        <Dialog scroll="paper" open={isModalOpen} fullWidth>
                            <DialogTitle>App User created successfully</DialogTitle>
                            <DialogContent>
                                Password for created App User:
                                <Typography variant="h5"> {appUserData.password}</Typography>
                                <br />
                                Copy it now, or it will be lost!
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleReturnToCreation}
                                    color="secondary"
                                    variant="outlined"
                                >
                                    Create new Test App User
                                </Button>
                                <Button
                                    onClick={handleGoToPatient}
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                >
                                    Proceed to Patient
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </Card>
            </Grid>
        </Grid>
    );
};

export default CreateTestAppUser;
