import { makeStyles } from 'tss-react/mui';

export default makeStyles()({
    formWrapper: {
        position: 'relative',
    },
    loaderWrapper: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 2,
        top: 0,
        left: 0,
        backgroundColor: '#fff',
    },
});
