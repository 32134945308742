import * as Yup from 'yup';
import { ClientSelectionType } from '~/enums/enums';
import { ArticlePromotionType } from '~/schemaTypes';

export const ARTICLE_PROMOTION_SCHEMA = Yup.object().shape({
    priority: Yup.number().required('Priority field is required'),
    tags: Yup.array().of(Yup.string()),
    clientSelectionType: Yup.mixed().oneOf(Object.values(ClientSelectionType)).required(),
    includeApps: Yup.array()
        .of(Yup.string())
        .when('clientSelectionType', ([clientSelectionType], schema) => {
            return clientSelectionType === ClientSelectionType.IncludeCertainApps
                ? schema.min(1, 'Must select at least one app to include').required()
                : schema;
        }),
    excludeApps: Yup.array()
        .of(Yup.string())
        .when('clientSelectionType', ([clientSelectionType], schema) => {
            return clientSelectionType === ClientSelectionType.ExcludeCertainApps
                ? schema.min(1, 'Must select at least one app to exclude').required()
                : schema;
        }),
    type: Yup.mixed().oneOf(Object.values(ArticlePromotionType)).required(),
    timelineId: Yup.string().when('type', ([type], schema) => {
        return type === ArticlePromotionType.Timeline
            ? schema.required('Timeline is required')
            : schema;
    }),
});
