import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    DialogActions,
    DialogContent,
    FormHelperText,
    Grid,
    MenuItem,
    TextField,
    Tooltip,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import { ActionSource, PatientActionInput } from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { useUserPermissions } from '~/hooks';
import { useStyles } from './styles';
import { PatientActionModalPropsType } from './types';
import { PATIENT_ACTION_TYPES_SCHEMA } from './yupSchema';

export const PatientActionModal: React.FC<PatientActionModalPropsType> = ({
    closeModal,
    data,
    onSubmit,
    isEditMode,
    onDelete,
    patientActionTypes,
    actionSource,
}) => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();

    const {
        control,
        handleSubmit: handleFormSubmit,
        register,
        formState: { errors },
    } = useForm<Pick<PatientActionInput, 'typeId' | 'description' | 'completedAt'>>({
        resolver: yupResolver(PATIENT_ACTION_TYPES_SCHEMA),
        defaultValues: data ?? {},
    });

    const handleClose = () => {
        closeModal();
    };

    const onFormSubmit = (values: PatientActionInput) => {
        onSubmit(
            {
                typeId: values.typeId,
                description: values.description ?? '',
                completedAt: values.completedAt ?? new Date().toLocaleString(),
                source: actionSource,
            },
            data?.id,
        );
    };

    const handlePatientActionDelete = () => {
        TriggerGlobalConfirm({
            message: 'Do you really want to delete this action?',
            callback: () => {
                if (data) {
                    onDelete(data);
                }
            },
        });
    };

    return (
        <>
            <DialogTitleWithClose id="dialogTitle" onClose={handleClose}>
                {isEditMode ? 'Edit Action' : 'Add Action'}
            </DialogTitleWithClose>
            <form noValidate onSubmit={handleFormSubmit(onFormSubmit)}>
                <DialogContent>
                    <div>
                        <ReactHookFormSelect
                            control={control}
                            defaultValue=""
                            name="typeId"
                            variant="outlined"
                            label="Action Type"
                            fullWidth
                            margin="dense"
                            disabled={data?.source === ActionSource.Automation}
                            error={!!errors.typeId}
                        >
                            {patientActionTypes?.map(({ id, label }) => (
                                <MenuItem key={id} value={id}>
                                    {label}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                        {errors.typeId && errors.typeId?.message && (
                            <FormHelperText error className={classes.helperMessage}>
                                {String(errors.typeId?.message)}
                            </FormHelperText>
                        )}
                        <Grid item xs={12}>
                            <OutlinedSection title="Note Text (optional)">
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Description"
                                    fullWidth
                                    margin="dense"
                                    {...register('description')}
                                    error={!!errors.description}
                                    helperText={errors.description?.message}
                                    disabled={data?.source === ActionSource.Automation}
                                />
                            </OutlinedSection>
                        </Grid>
                        <Grid>
                            <Controller
                                control={control}
                                name="completedAt"
                                defaultValue={
                                    data?.completedAt ? new Date(data?.completedAt) : new Date()
                                }
                                render={({ field: { onChange, value } }) => (
                                    <Tooltip
                                        title={`Time is in ${moment()
                                            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                                            .zoneAbbr()} time zone`}
                                    >
                                        <DatePicker
                                            fullWidth
                                            variant="inline"
                                            margin="dense"
                                            label="Completed Date/Time"
                                            onChange={onChange}
                                            openTo="date"
                                            value={value}
                                            ampm={false}
                                            minutesStep={5}
                                            format="MM/dd/yyyy hh:mm"
                                        />
                                    </Tooltip>
                                )}
                            />
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions className={classes.actionButtons}>
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        {data?.source !== ActionSource.Automation && (
                            <Button
                                startIcon={<SaveIcon />}
                                type="submit"
                                color="secondary"
                                variant="contained"
                            >
                                {isEditMode ? 'Save' : 'Create'}
                            </Button>
                        )}
                        <Button onClick={handleClose} color="secondary" variant="outlined">
                            Cancel
                        </Button>

                        {isEditMode &&
                            pagePermissions?.PatientActions.Delete &&
                            actionSource !== ActionSource.Automation && (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handlePatientActionDelete}
                                >
                                    Delete
                                </Button>
                            )}
                    </div>
                </DialogActions>
            </form>
        </>
    );
};
