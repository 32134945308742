import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import Header from './Header';

export enum TabsConfig {
    config,
    patients,
    claims,
    invoices,
    builder,
}

const samePageLinkNavigation = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (
        event.defaultPrevented ||
        event.button !== 0 ||
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
};

type NavigationProps = {
    currentTab: TabsConfig;
    affiliateId?: string | null;
    id?: string | null;
    name: string;
};

const Navigation: React.FC<NavigationProps> = props => {
    const { currentTab, id, affiliateId, name = '' } = props;
    const disabled = !id || !affiliateId;
    const history = useNavigate();
    const onChange = useCallback(
        (event: React.SyntheticEvent, newValue: TabsConfig) => {
            if (
                event.type !== 'click' ||
                (event.type === 'click' &&
                    samePageLinkNavigation(
                        event as React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                    ))
            ) {
                switch (newValue) {
                    case TabsConfig.config:
                        history(`/app-config/vcaffiliates/${id}`);
                        break;
                    case TabsConfig.patients:
                        history(`/app-config/vcaffiliates/patients/${affiliateId}`);
                        break;
                    case TabsConfig.invoices:
                        history(`/app-config/vcaffiliates/invoices/${affiliateId}`);
                        break;
                    case TabsConfig.claims:
                        history(`/app-config/vcaffiliates/claims/${affiliateId}`);
                        break;
                    case TabsConfig.builder:
                        history(`/app-config/vcaffiliates/build-claims/${affiliateId}`);
                        break;
                    default:
                        history(`/app-config/vcaffiliates/patients/${affiliateId}`);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [affiliateId, id],
    );
    return (
        <Grid container item xs={12}>
            <Header name={name} />
            <Grid item xs={12} paddingBottom={3}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={currentTab} onChange={onChange}>
                        <Tab label="Config" value={TabsConfig.config} disabled={disabled} />
                        <Tab label="Patients" value={TabsConfig.patients} disabled={disabled} />
                        <Tab label="Claim History" value={TabsConfig.claims} disabled={disabled} />
                        <Tab label="Invoices" value={TabsConfig.invoices} disabled={disabled} />
                        <Tab label="Claim Builder" value={TabsConfig.builder} disabled={disabled} />
                    </Tabs>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Navigation;
