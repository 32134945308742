import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Divider, Slide, TextField, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import { useQueryParams } from '~/hooks';
import {
    AlertSeverity,
    useLoginUserForAcceptInvitePageMutation,
    useFetchCurrentUserAcceptInviteLoginLazyQuery,
} from '~/schemaTypes';
import { SuppressNextGlobalAlert, TriggerGlobalAlert, PersistAuthTokens } from '~/state';
import { logout } from '~/state/auth/auth.svc';
import { PASSWORD_REGEX } from '../../helpers/regularExpressions';
import PasswordInput from '../../components/PasswordInput/PasswordInput';

const ACCEPT_INVITATION_FORM_VALIDATION_SCHEMA: Yup.ObjectSchema<AcceptInviteFormInput> =
    Yup.object({
        email: Yup.string().email('Invalid Email').required('Email is Required'),
        newPassword: Yup.string()
            .required('Password is required')
            .matches(
                PASSWORD_REGEX,
                'Password must contain at least 8 characters, one uppercase, one number and one special case character',
            ),
        confirmNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), undefined], `Passwords don't match`)
            .required('Password confirm is required'),
    }).defined();

type AcceptInviteFormInput = {
    email: string;
    newPassword: string;
    confirmNewPassword: string;
};

const useStyles = makeStyles()(theme => ({
    root: {
        ...theme.wildflowerBackgroundContainer,
    },
    errorMessage: {
        color: theme.colors.ErrorRed,
        marginBottom: '1.5em',
    },
    loginCard: {
        padding: 0,
        width: 400,
        '& .title': {
            padding: 20,
            borderBottom: '1px solid lightgray',
        },
        '& .inputCont': {
            padding: 20,
            minHeight: 200,
        },
        '& .bottomControl': {
            borderTop: '1px solid lightgray',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: 20,
            '& > button': {
                '&:not(:last-child)': {
                    marginRight: 10,
                },
            },
        },
        ' & .credit': {
            position: 'absolute',
            bottom: 5,
            left: 5,
            fontSize: '75%',
            fontWeight: 'bold',
            color: 'lightGrey',
        },
    },
}));

const AcceptInvite: React.FC = () => {
    const { classes } = useStyles();

    const [query] = useQueryParams();
    const passwordToken = query.get('token');
    const prefilledEmail = query.get('email') ?? '';
    const history = useNavigate();
    const [fetchUser, { loading: currentUserLading, data: currentUser }] =
        useFetchCurrentUserAcceptInviteLoginLazyQuery({
            onCompleted: data => {
                if (data.currentUser) {
                    window.location.reload();
                }
            },
        });

    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        watch,
        register,
        handleSubmit,
        formState: { errors: acceptInviteFormErrors },
    } = useForm<AcceptInviteFormInput>({
        resolver: yupResolver(ACCEPT_INVITATION_FORM_VALIDATION_SCHEMA),
        defaultValues: {
            email: prefilledEmail,
        },
    });

    const [errorMessage, setErrorMessage] = React.useState<string>();
    const [loginUser, { loading: acceptInviteLoading }] = useLoginUserForAcceptInvitePageMutation({
        onCompleted: async data => {
            if (data.loginUser?.success && data.loginUser?.tokens) {
                PersistAuthTokens(data.loginUser.tokens);
                fetchUser();
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'Successfully created account.',
                });
            } else if (data.loginUser?.success === false) {
                setErrorMessage(data.loginUser?.message ?? undefined);
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: data.loginUser?.message ?? 'An error occurred.',
                });
            }
        },
        onError: error => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: error.message,
            });
        },
    });

    const handleAcceptInviteFormSubmit = ({
        email,
        newPassword,
        confirmNewPassword,
    }: AcceptInviteFormInput) => {
        setErrorMessage(undefined);
        if (newPassword !== confirmNewPassword) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `Passwords don't match.`,
            });
            return;
        }
        loginUser({
            variables: {
                input: {
                    email,
                    newPassword,
                    passwordToken,
                },
            },
        });
        SuppressNextGlobalAlert(true);
    };

    useEffect(() => {
        if (currentUser?.currentUser?.id) {
            history('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    useEffect(() => {
        logout(false);
    }, []);

    return (
        <div className={classes.root}>
            <Card className={classes.loginCard}>
                <Typography variant="h6" className="title">
                    Create an Account
                </Typography>
                <Divider />
                <Slide in direction="right">
                    <form onSubmit={handleSubmit(handleAcceptInviteFormSubmit)} autoComplete="off">
                        <div className="inputCont">
                            {errorMessage && (
                                <Typography
                                    variant="body2"
                                    color="error"
                                    className={classes.errorMessage}
                                >
                                    {errorMessage}
                                </Typography>
                            )}
                            <TextField
                                variant="outlined"
                                {...register('email')}
                                label="Email"
                                type="email"
                                fullWidth
                                error={!!acceptInviteFormErrors.email}
                                helperText={acceptInviteFormErrors.email?.message}
                                disabled={!!prefilledEmail}
                            />
                            <PasswordInput
                                name="newPassword"
                                register={register}
                                label="New Password"
                                error={!!acceptInviteFormErrors.newPassword || !!errorMessage}
                                helperText={
                                    acceptInviteFormErrors.newPassword?.message ??
                                    'Passwords must be a minimum of 8 characters, and must contain at least one uppercase character, one lowercase character, one symbol, and one number.'
                                }
                            />
                            <PasswordInput
                                name="confirmNewPassword"
                                register={register}
                                label="Confirm Password"
                                error={
                                    !!acceptInviteFormErrors.confirmNewPassword || !!errorMessage
                                }
                                helperText={acceptInviteFormErrors.confirmNewPassword?.message}
                                inputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        <div className="bottomControl">
                            <AsyncActionButton loading={acceptInviteLoading || currentUserLading}>
                                <Button
                                    disabled={acceptInviteLoading || currentUserLading}
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                >
                                    Create Account
                                </Button>
                            </AsyncActionButton>
                        </div>
                        <div className="bottomControl">
                            Have an Account?{' '}
                            <span style={{ marginLeft: '10px' }}>
                                <a href="\" title="Sign In">
                                    Sign in
                                </a>
                            </span>
                        </div>
                    </form>
                </Slide>
            </Card>
        </div>
    );
};

export default AcceptInvite;
