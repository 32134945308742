import { useState } from 'react';
import { PAGESIZE } from '~/constants';
import { IFilterChange } from '~/components/CustomFilter/CustomFilter';
import {
    CompoundQuestionCustomFiltersInput,
    CompoundQuestionFilterInput,
    CompoundQuestionsV2QueryVariables,
    OrderByDirectionEnum,
    useCompoundQuestionsV2Query,
} from '~/schemaTypes';
import { COMPOUND_QUESTION_KEY } from './const';
import {
    CompoundQuestion,
    UseCompoundQuestionsSearch,
    UseCompoundQuestionsSearchProps,
} from './types';

const getCustomFilter = (
    filter: IFilterChange<CompoundQuestion> | null,
): CompoundQuestionCustomFiltersInput | null => {
    if (filter?.fields) {
        const entities = Object.entries(filter.fields).filter(
            ([key]) => key === COMPOUND_QUESTION_KEY.PROFILE_DEF_KEY,
        );
        if (entities.length > 0) {
            return Object.fromEntries(entities);
        }
    }
    return null;
};

const getFilter = (
    filter: IFilterChange<CompoundQuestion> | null,
): CompoundQuestionFilterInput | null => {
    if (filter?.fields) {
        const entities = Object.entries(filter.fields).filter(
            ([key]) => key !== COMPOUND_QUESTION_KEY.PROFILE_DEF_KEY,
        );
        if (entities.length > 0) {
            return {
                fields: Object.fromEntries(
                    entities.map(([key, value]) => {
                        if (key === COMPOUND_QUESTION_KEY.LABEL) {
                            return [key, { en: value }];
                        }
                        return [key, value];
                    }),
                ),
            };
        }
    }
    return null;
};

const useCompoundQuestionsSearch = ({
    onCompletedDataLoad,
}: UseCompoundQuestionsSearchProps): UseCompoundQuestionsSearch => {
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [filter, setFilter] = useState<IFilterChange<CompoundQuestion> | null>(null);

    const getCompoundQuestionQueryVariables: CompoundQuestionsV2QueryVariables = {
        input: {
            pagination: {
                skip: page * rowsPerPage,
                limit: rowsPerPage,
            },
            filter: getFilter(filter),
            customFilters: getCustomFilter(filter),
            orderBy: { field: 'label.en', order: OrderByDirectionEnum.Asc },
        },
    };

    const { data, loading } = useCompoundQuestionsV2Query({
        variables: getCompoundQuestionQueryVariables,
        onCompleted: () => {
            onCompletedDataLoad();
        },
    });

    return {
        data: data?.compoundQuestionsV2.results ?? [],
        total: data?.compoundQuestionsV2.total ?? 0,
        loading,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        setFilter,
    };
};

export default useCompoundQuestionsSearch;
