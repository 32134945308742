import { FetchSurveyResponsesForPatientSurveyPageQuery } from '~/schemaTypes';

type SurveyResponse = NonNullable<
    NonNullable<FetchSurveyResponsesForPatientSurveyPageQuery['patient']>['surveyResponses'][0]
>;

const FLAGGED_SURVEY_TYPES = new Set([
    'Postpartum',
    'EPDS Survey',
    'Cigna_EPDS Postpartum Survey',
    'Axia_EPDS Survey',
    'BCNC_EPDS Survey',
    'IEHP_EPDS Postpartum Survey',
    // all the above types are deprecated and are here for backawrd compatibility
    'EPDS',
]);

// somehow some ids are just plain texts may be potential problem
const EPDS_SELF_HARM_QUESTION_IDS = new Set(['Self-Harm', 'Harming yourself', 'Thoughts']);

const EPDS_SELF_HARM_RIGHT_ANSWERS = new Set(['Never', 'Not at all']);

function removeQuotes(input: string): string {
    return input.replace(/^["']|["']$/g, '');
}

const epdsScoreThreshold = 10;
enum ClientSurveyIds {
    SWC_NEW_PATIENT_INTAKE = 'SWC_NEW_PATIENT_INTAKE',
}

export const shouldFlagSurvey = (survey: SurveyResponse): boolean => {
    const { surveyScore, surveyType, answers } = survey;
    if (answers && surveyType) {
        const isFlaggedSurvey = FLAGGED_SURVEY_TYPES.has(surveyType);

        if (!isFlaggedSurvey) {
            return false;
        }

        const selfHarmAnswerRaw = answers.find(({ questionId }) =>
            questionId ? EPDS_SELF_HARM_QUESTION_IDS.has(questionId) : false,
        );
        const selfHarmAnswer = removeQuotes(selfHarmAnswerRaw?.answerText ?? '');
        const isSelfHarmAnswer = selfHarmAnswerRaw
            ? !EPDS_SELF_HARM_RIGHT_ANSWERS.has(selfHarmAnswer)
            : false;
        const isAboveThreshold = surveyScore ? surveyScore >= epdsScoreThreshold : false;

        if (isSelfHarmAnswer || isAboveThreshold) {
            return true;
        }

        return false;
    }
    return false;
};

export const combineSurveyResponses = (surveyResponses: SurveyResponse[]): SurveyResponse[] =>
    Object.values(
        surveyResponses.reduce(
            (
                responses: { [key: string]: SurveyResponse },
                currentResponse: SurveyResponse,
                index: number,
            ) => {
                const id = currentResponse?.clientSurveyId;

                if (!id) {
                    return responses;
                }

                if (id !== ClientSurveyIds.SWC_NEW_PATIENT_INTAKE) {
                    responses[index] = currentResponse;

                    return responses;
                }

                if (!responses[id]) {
                    responses[id] = currentResponse;
                    return responses;
                }

                responses[id] = {
                    ...responses[id],
                    answers: [...responses[id].answers, ...currentResponse.answers],
                };

                return responses;
            },
            {},
        ),
    );
