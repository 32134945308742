import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import ObjectID from 'bson-objectid';
import omitDeep from 'omit-deep-lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    AlertSeverity,
    FetchSurveyDefForSurveyBuilderQuery,
    SurveyDefInput,
    SurveyDefListDocument,
    SurveyDefListQuery,
    SurveyDefPieceType,
    useCreateSurveyDefForSurveyBuilderMutation,
    useFetchSurveyDefForSurveyBuilderLazyQuery,
    useSurveyDefListQuery,
} from '~/schemaTypes';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import { errorRed } from '~/theme/WildTheme';

type SurveyDef = NonNullable<FetchSurveyDefForSurveyBuilderQuery['surveyDef']>;

const CloneSurveyInputValidation = Yup.object().shape({
    name: Yup.string().required('Required'),
});

const SurveyValidation = Yup.object().shape({
    name: Yup.string().required(),
    organizationId: Yup.string().required(),
    label: Yup.object().required().shape({
        en: Yup.string().required(),
        es: Yup.string(),
    }),
    surveyType: Yup.string().required(),
    summaryText: Yup.object()
        .nullable()
        .shape({
            title: Yup.object().shape({
                en: Yup.string().typeError('type error').strict(true),
                es: Yup.string(),
            }),
            body: Yup.object().shape({
                en: Yup.string().typeError('type error').strict(true),
                es: Yup.string(),
            }),
        }),
    buttons: Yup.object()
        .required()
        .shape({
            saveSurvey: Yup.object()
                .required()
                .shape({
                    label: Yup.object().shape({
                        en: Yup.string().typeError('type error').strict(true),
                    }),
                }),
            end: Yup.object()
                .required()
                .shape({
                    label: Yup.object().shape({
                        en: Yup.string().typeError('type error').strict(true),
                    }),
                }),
            next: Yup.object()
                .required()
                .shape({
                    label: Yup.object().shape({
                        en: Yup.string().typeError('type error').strict(true),
                    }),
                }),
            previous: Yup.object()
                .required()
                .shape({
                    label: Yup.object().shape({
                        en: Yup.string().typeError('type error').strict(true),
                    }),
                }),
        }),
    divisions: Yup.array()
        .required()
        .of(
            Yup.object().shape({
                id: Yup.string().required(),
                label: Yup.object()
                    .shape({
                        en: Yup.string().typeError('type error').strict(true),
                    })
                    .required(),
                sections: Yup.array()
                    .required()
                    .of(
                        Yup.object().shape({
                            id: Yup.string().required(),
                            label: Yup.object()
                                .shape({
                                    en: Yup.string().typeError('type error').strict(true),
                                })
                                .required(),
                            pieces: Yup.array().of(
                                Yup.object().shape({
                                    id: Yup.string().required(),
                                    type: Yup.mixed().oneOf(Object.values(SurveyDefPieceType)),
                                }),
                            ),
                        }),
                    ),
            }),
        ),
});

const isSurveyDefInput = (input: any): boolean => {
    let valid = false;
    try {
        SurveyValidation.validateSync(input);
        valid = true;
    } catch (error: any) {
        TriggerGlobalAlert({
            severity: AlertSeverity.Error,
            message: JSON.stringify(error?.errors),
        });
    }
    return valid;
};

export const CloneSurveyModal: React.FC<{
    isOpen: boolean;
    surveyId: string;
    onClose: () => void;
}> = props => {
    const { isOpen, surveyId, onClose } = props;
    const [selectedSurveyDef, setSelectedSurveyDef] = useState<SurveyDef | null>(null);
    const [nameUsed, setNameUsed] = useState(false);
    // list of surveys to comapare with name field of cloned one
    const { data: surveyList, loading: surveyListLoading } = useSurveyDefListQuery();

    // input whole survey object
    const [fetchSurveyById, { data: surveyData, loading: surveyLoading }] =
        useFetchSurveyDefForSurveyBuilderLazyQuery();

    useEffect(() => {
        fetchSurveyById({ variables: { surveyDefInput: { id: surveyId } } });
    }, [fetchSurveyById, surveyId]);

    useEffect(() => {
        if (surveyData && surveyData.surveyDef) {
            setSelectedSurveyDef({ ...surveyData.surveyDef });
        }
    }, [surveyData]);

    const {
        register,
        handleSubmit,

        formState: { errors },
    } = useForm<SurveyDefInput>({
        // @ts-expect-error RHF V7 limitation #7895 will be fixed in V8 of react-hook-form
        resolver: yupResolver(CloneSurveyInputValidation as any),
    });
    //  execute graphql mutation the same as it on creation survey screan

    const handleClose = () => {
        onClose();
    };
    const [createSurveyDef, { loading: createSurveyDefLoading }] =
        useCreateSurveyDefForSurveyBuilderMutation({
            onCompleted: () => {
                handleClose();
            },
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        handleClose();
                    },
                    message: `There was a problem saving the survey: ${error.message}`,
                });
            },
            update: (cache, response) => {
                const newSurveyDef = response.data?.createSurveyDef?.resourceCreated;
                if (response.data?.createSurveyDef?.success && newSurveyDef) {
                    const currentSurveys = cache.readQuery<SurveyDefListQuery>({
                        query: SurveyDefListDocument,
                    });
                    if (currentSurveys?.surveyDefs) {
                        cache.writeQuery<SurveyDefListQuery>({
                            query: SurveyDefListDocument,
                            data: {
                                surveyDefs: [...currentSurveys.surveyDefs, newSurveyDef],
                            },
                        });
                    }
                }
            },
        });

    const onSubmit = (values: SurveyDefInput) => {
        setNameUsed(false);
        if (
            surveyList &&
            surveyList.surveyDefs.filter(t => {
                const name = values.name ?? '';
                return t.name?.toLowerCase() === name.toLowerCase();
            }).length > 0
        ) {
            setNameUsed(true);
        } else {
            const cloneSurvey = { ...selectedSurveyDef };
            const resultingSurvey = {
                ...values,
                organizationId: cloneSurvey?.organizationId,
                label: cloneSurvey?.label,
                surveyType: cloneSurvey?.surveyType,
                requiresAdvocateReview: cloneSurvey?.requiresAdvocateReview,
                disableRestart: cloneSurvey?.disableRestart,
                restartFromBeginning: cloneSurvey?.restartFromBeginning,
                shouldScore: cloneSurvey?.shouldScore,
                summary: cloneSurvey?.summary,
                summaryText: cloneSurvey?.summaryText,
                buttons: cloneSurvey?.buttons,
                divisions: cloneSurvey?.divisions?.map(i => {
                    const div = { ...i };
                    delete div.subSurveyDef;
                    return div;
                }),
            };
            const cleared = omitDeep(
                resultingSurvey,
                '__typename',
                'articleName',
            ) as SurveyDefInput;
            if (isSurveyDefInput(resultingSurvey)) {
                // workaround for some old survey data since we are not doing data versioning yet
                if (cleared.buttons?.saveSurvey?.label?.en === '') {
                    cleared.buttons.saveSurvey.label = null;
                }
                if (cleared.buttons?.end?.label?.en === '') {
                    cleared.buttons.end.label = null;
                }
                if (cleared.divisions) {
                    for (const div of cleared.divisions) {
                        div.id = new ObjectID().toHexString();
                        for (const section of div.sections) {
                            section.id = new ObjectID().toHexString();
                            for (const piece of section.pieces) {
                                piece.id = new ObjectID().toHexString();
                                if (piece.question?.labelOverride?.en === '') {
                                    piece.question.labelOverride = null;
                                }
                            }
                        }
                    }
                }
                createSurveyDef({
                    variables: {
                        createSurveyDefInput: cleared,
                    },
                });
            }
        }
    };
    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose onClose={handleClose} id="modalTitle">
                Clone {surveyData?.surveyDef?.name}
            </DialogTitleWithClose>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    {surveyLoading || createSurveyDefLoading || surveyListLoading ? (
                        <Loading height={140} />
                    ) : (
                        <OutlinedSection title="Survey Name *">
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                defaultValue={surveyData?.surveyDef?.name}
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                            />
                            {nameUsed && (
                                <div style={{ color: errorRed }}>
                                    Name already used. Please change.
                                </div>
                            )}
                        </OutlinedSection>
                    )}
                    {/* show input?select for name */}
                    {/* input should be unique */}
                </DialogContent>
                <DialogActions>
                    {/* show buttons cancel and clone */}
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={() => handleSubmit(onSubmit)}
                    >
                        Clone
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
