import { yupResolver } from '@hookform/resolvers/yup';
import {
    Autocomplete,
    Avatar,
    Button,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from 'tss-react/mui';
import { FileInfo } from '@uploadcare/react-widget';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DialogLoader from '~/components/DialogLoader/DialogLoader';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import { ROOT_ORG_ID } from '~/constants';
import {
    AddressInput,
    AppPlatform,
    EligibilityType,
    FetchOrgDetailsDocument,
    FetchOrgDetailsForModalQuery,
    FetchOrgDetailsQuery,
    FetchOrgDetailsQueryVariables,
    useCreateOrgForModalMutation,
    useFetchOrgDetailsForModalLazyQuery,
    usePreFetchAppsQuery,
    useUpdateOrgForModalMutation,
} from '~/schemaTypes';
import { TriggerGlobalImageUploader } from '~/state';
import _ from 'lodash';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { WEIGHT_UNIT_DEFAULT } from '~/components/WeightValue/WeightValue';
import { generateFormSchema, OrganizationDetailsSections } from './validation';

const SUPPORTED_UNIT_NAMES = [WEIGHT_UNIT_DEFAULT, 'kg'];

const EligibilityTypes = [
    {
        name: 'Wildflower Invitation Code',
        value: EligibilityType.WfhInvitationCode,
    },
    { name: 'Dorsata', value: EligibilityType.Dorsata },
];

const TimezoneOptions = [
    {
        name: 'Pacific Daylight Time (Los Angeles)',
        value: 'America/Los_Angeles',
    },
    {
        name: 'Alaska Daylight Time (Anchorage)',
        value: 'America/Anchorage',
    },
    {
        name: 'Mountain Standard Time (Phoenix)',
        value: 'America/Phoenix',
    },
    {
        name: 'Mountain Daylight Time (Denver)',
        value: 'America/Denver',
    },
    {
        name: 'Central Daylight Time (Chicago)',
        value: 'America/Chicago',
    },
    {
        name: 'Eastern Daylight Time (New York)',
        value: 'America/New_York',
    },
];

export interface OrganizationFormInput {
    externalId?: string;
    name: string;
    allowPatientCreation?: boolean;
    address?: AddressInput;
    appPlatform?: AppPlatform | null;
    appBundleId?: string;
    email?: string;
    conferencingAppName?: string;
    eligibilityType?: EligibilityType;
    timezone?: string;
    parentId?: string;
    brandingName?: string;
    healthAdvocateTitle?: string;
    organizationMeasurementConfig?: {
        weight?: {
            units: string;
        };
    };
}

const useStyles = makeStyles()({
    root: {},
    stickyHeader: {
        paddingTop: '10px !important',
        paddingBottom: '10px !important',
        marginBottom: 5,
        position: 'sticky',
        top: '-20px',
        zIndex: 1000,
        background: 'white',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        '& > .MuiTypography-root': {
            height: 48,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    helperMessage: {
        margin: '-10px 0 0 24px',
    },
});

type OrganizationModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setEditOrgId: Dispatch<SetStateAction<string>>;
    id?: string;
    parentId: string;
    parentAppBundleId?: string | null;
    orgDetailsSection?: string;
};

const OrganizationModal: React.FC<OrganizationModalProps> = ({
    setOpen,
    setEditOrgId,
    id,
    parentId,
    parentAppBundleId = null,
    orgDetailsSection,
}) => {
    const { classes } = useStyles();
    const bottomDiv = useRef<HTMLDivElement>(null);
    const [logoUrl, setLogoUrl] = useState<string>('');
    const [appBundleId, setAppBundleId] = useState<string>('');
    const [appPlatform, setAppPlatform] = useState<string>(AppPlatform.Vbc);
    const [weightUnit, setWeightUnit] = useState<string>(WEIGHT_UNIT_DEFAULT);
    const methods = useForm<OrganizationFormInput>({
        resolver: yupResolver(generateFormSchema(orgDetailsSection) as any),
    });
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = methods;
    const { data: appList, loading: appListLoading } = usePreFetchAppsQuery();

    const [updateOrg, { loading: updateOrgLoading }] = useUpdateOrgForModalMutation({
        onCompleted: data => {
            if (data.updateOrganization?.success) {
                setOpen(false);
                setEditOrgId('');
            }
        },
        update: (cache, data) => {
            if (data.data?.updateOrganization?.resourceUpdated) {
                cache.writeQuery<FetchOrgDetailsQuery, FetchOrgDetailsQueryVariables>({
                    query: FetchOrgDetailsDocument,
                    variables: {
                        input: { id: data.data.updateOrganization.resourceUpdated.id },
                    },
                    data: {
                        organization: data.data.updateOrganization.resourceUpdated,
                    },
                });
            }
        },
    });

    const [createOrg, { loading: createOrgLoading }] = useCreateOrgForModalMutation({
        onCompleted: data => {
            if (data.createOrganization?.success) {
                setOpen(false);
                setEditOrgId('');
            }
        },
        update: (cache, data) => {
            if (data.data?.createOrganization?.resourceCreated) {
                cache.writeQuery<FetchOrgDetailsQuery, FetchOrgDetailsQueryVariables>({
                    query: FetchOrgDetailsDocument,
                    variables: {
                        input: { id: data.data.createOrganization.resourceCreated.id },
                    },
                    data: {
                        organization: data.data.createOrganization.resourceCreated,
                    },
                });
            }
        },
    });

    const [getOrg, { data: orgData, loading: orgDataLoading }] =
        useFetchOrgDetailsForModalLazyQuery({
            onCompleted: ({ organization }: FetchOrgDetailsForModalQuery) => {
                if (organization) {
                    setLogoUrl(organization.logo ?? '');
                    setAppBundleId(organization?.appBundleId ?? '');
                    setAppPlatform(organization.appPlatform as AppPlatform);
                    const weightUnitName =
                        organization.organizationMeasurementConfig?.weight?.units ||
                        WEIGHT_UNIT_DEFAULT;
                    setWeightUnit(weightUnitName);
                    reset({
                        ...(organization.externalId && {
                            externalId: organization.externalId,
                        }),
                        name: organization.name,
                        ...(organization.allowPatientCreation && {
                            allowPatientCreation: organization.allowPatientCreation,
                        }),
                        ...(organization.email && { email: organization.email }),
                        ...(organization.conferencingAppName && {
                            conferencingAppName: organization.conferencingAppName,
                        }),
                        ...(organization.timezone && { timezone: organization.timezone }),
                        brandingName: organization.brandingName,
                        healthAdvocateTitle: organization.healthAdvocateTitle || 'Care Coordinator',
                        ...(organization.organizationMeasurementConfig?.weight?.units && {
                            organizationMeasurementConfig: {
                                weight: {
                                    units: weightUnitName,
                                },
                            },
                        }),
                    });
                }
            },
            fetchPolicy: 'network-only',
        });

    const onSubmit = (form: OrganizationFormInput) => {
        if (id) {
            updateOrg({
                variables: {
                    input: {
                        id,
                        data: {
                            ...form,
                            appBundleId,
                            logo: logoUrl,
                            services: [],
                        },
                    },
                },
            });
        } else {
            createOrg({
                variables: {
                    input: {
                        ...form,
                        parentId,
                        appBundleId,
                        logo: logoUrl,
                        services: [],
                    },
                },
            });
        }
    };

    const onImageUpload = (file: FileInfo) => {
        const newLogo = file.cdnUrl;
        if (newLogo) {
            setLogoUrl(newLogo);
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setEditOrgId('');
    };

    useEffect(() => {
        if (id) {
            getOrg({
                variables: {
                    input: {
                        id,
                    },
                },
            });
        }
    }, [id, getOrg]);

    if (orgDataLoading || appListLoading) {
        return <DialogLoader subtitle="Loading org details..." />;
    }

    if (createOrgLoading) {
        return <DialogLoader subtitle="Creating org..." />;
    }

    if (updateOrgLoading) {
        return <DialogLoader subtitle="Updating org..." />;
    }

    return (
        <>
            <DialogTitleWithClose id="Edit Organization Modal" onClose={handleCancel}>
                {!id ? 'New Org' : `Edit ${orgData?.organization?.brandingName}`}
            </DialogTitleWithClose>
            <DialogContent className="formCont" dividers>
                <form className={classes.root} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        {(!orgDetailsSection ||
                            orgDetailsSection === OrganizationDetailsSections.GeneralInfo) && (
                            <>
                                <Grid className={classes.stickyHeader} item xs={12}>
                                    <Typography variant="h6">Logo:</Typography>
                                </Grid>
                                <Grid
                                    container
                                    spacing={3}
                                    alignItems="center"
                                    justifyContent="space-around"
                                    style={{ marginTop: 10 }}
                                >
                                    <Grid item xs={1}>
                                        <Avatar
                                            style={{ height: 75, width: 75, textAlign: 'center' }}
                                            src={logoUrl ?? orgData?.organization?.logo ?? ''}
                                        >
                                            No Logo
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                TriggerGlobalImageUploader({
                                                    callback: onImageUpload,
                                                    isImage: true,
                                                })
                                            }
                                        >
                                            Change Logo
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid className={classes.stickyHeader} item xs={12}>
                                    <Typography variant="h6">General Info:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        id="externalId"
                                        label="External ID"
                                        type="text"
                                        margin="dense"
                                        fullWidth
                                        error={!!errors.externalId}
                                        helperText={errors.externalId?.message}
                                        {...register('externalId')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        id="name"
                                        label="Name"
                                        type="text"
                                        margin="dense"
                                        fullWidth
                                        error={!!errors.name}
                                        helperText={errors.name?.message}
                                        {...register('name')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        id="brandingName"
                                        label="Branding Name"
                                        type="text"
                                        margin="dense"
                                        fullWidth
                                        error={!!errors.brandingName}
                                        helperText={
                                            errors.brandingName?.message ??
                                            'This is the name that will appear in the app'
                                        }
                                        {...register('brandingName')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        id="healthAdvocateTitle"
                                        label="Health Advocate Title"
                                        type="text"
                                        margin="dense"
                                        fullWidth
                                        error={!!errors.healthAdvocateTitle}
                                        helperText={errors.healthAdvocateTitle?.message}
                                        {...register('healthAdvocateTitle')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ReactHookFormSelect
                                        control={control}
                                        defaultValue=""
                                        name="timezone"
                                        variant="outlined"
                                        label="Timezone *"
                                        fullWidth
                                        margin="dense"
                                        error={!!errors.timezone}
                                    >
                                        {TimezoneOptions.map(({ value, name }) => (
                                            <MenuItem key={value} value={value}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </ReactHookFormSelect>
                                    {errors?.timezone && (
                                        <FormHelperText error className={classes.helperMessage}>
                                            {errors?.timezone?.message}
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <ReactHookFormSelect
                                        control={control}
                                        defaultValue={EligibilityTypes[0].value}
                                        name="eligibilityType"
                                        variant="outlined"
                                        label="Eligibility Type"
                                        fullWidth
                                        margin="dense"
                                    >
                                        {EligibilityTypes.map(({ value, name }) => (
                                            <MenuItem key={value} value={value}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </ReactHookFormSelect>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        id="email"
                                        label="Email"
                                        type="text"
                                        margin="dense"
                                        fullWidth
                                        error={!!errors.email}
                                        helperText={errors.email?.message}
                                        {...register('email')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="allowPatientCreation"
                                        defaultValue={false}
                                        render={({ field: { onChange } }) => (
                                            <Autocomplete
                                                size="small"
                                                onChange={(_, org) => onChange(org?.value)}
                                                getOptionLabel={selected => selected.name ?? ''}
                                                fullWidth
                                                options={[
                                                    { name: 'Yes', value: true },
                                                    { name: 'No', value: false },
                                                ]}
                                                defaultValue={
                                                    id
                                                        ? {
                                                              name: orgData?.organization
                                                                  ?.allowPatientCreation
                                                                  ? 'Yes'
                                                                  : 'No',
                                                              value:
                                                                  orgData?.organization
                                                                      ?.allowPatientCreation ??
                                                                  true,
                                                          }
                                                        : { name: 'No', value: false }
                                                }
                                                isOptionEqualToValue={(option, val) => {
                                                    return option.value === val.value;
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                                        {...params}
                                                        error={!!errors.allowPatientCreation}
                                                        helperText={
                                                            errors.allowPatientCreation?.message
                                                        }
                                                        label="Allow Patient Creation"
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        error={!!errors.conferencingAppName}
                                        label="Conferencing App Name"
                                        id="conferencingAppName"
                                        type="text"
                                        margin="dense"
                                        fullWidth
                                        {...register('conferencingAppName')}
                                        helperText={errors.conferencingAppName?.message}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ReactHookFormSelect
                                        control={control}
                                        defaultValue={appPlatform || AppPlatform.Vbc}
                                        name="appPlatform"
                                        variant="outlined"
                                        label="App Platform"
                                        fullWidth
                                        margin="dense"
                                        disabled={id !== ''}
                                    >
                                        <MenuItem
                                            key={AppPlatform.Swc}
                                            value={AppPlatform.Swc}
                                            onClick={() => {
                                                setAppPlatform(AppPlatform.Swc);
                                            }}
                                        >
                                            {AppPlatform.Swc}
                                        </MenuItem>
                                        <MenuItem
                                            key={AppPlatform.Vbc}
                                            value={AppPlatform.Vbc}
                                            onClick={() => {
                                                setAppPlatform(AppPlatform.Vbc);
                                            }}
                                        >
                                            {AppPlatform.Vbc}
                                        </MenuItem>
                                    </ReactHookFormSelect>
                                </Grid>
                                <Grid item xs={6}>
                                    <ReactHookFormSelect
                                        control={control}
                                        defaultValue={weightUnit}
                                        name="organizationMeasurementConfig.weight.units"
                                        variant="outlined"
                                        label="Supported Weight Units"
                                        fullWidth
                                        margin="dense"
                                    >
                                        {SUPPORTED_UNIT_NAMES.map(item => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </ReactHookFormSelect>
                                </Grid>
                                {id !== ROOT_ORG_ID && (
                                    <Grid item xs={12}>
                                        <OutlinedSection title="Select App">
                                            {parentAppBundleId && (
                                                <div>Parent App: {parentAppBundleId}</div>
                                            )}
                                            <select
                                                value={appBundleId || ''}
                                                onChange={event =>
                                                    setAppBundleId(event.target.value)
                                                }
                                            >
                                                <option value="">None</option>
                                                {_.sortBy(
                                                    appList?.applicationsV2.results,
                                                    'appBundleId',
                                                ).map(a => (
                                                    <option
                                                        key={a.appBundleId}
                                                        value={a.appBundleId}
                                                    >
                                                        {a.appBundleId}
                                                    </option>
                                                ))}
                                            </select>
                                        </OutlinedSection>
                                    </Grid>
                                )}
                            </>
                        )}
                        <div ref={bottomDiv} />
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    onClick={() => handleSubmit(onSubmit)()}
                    color="primary"
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
};

export default OrganizationModal;
