import React, { FC, useState } from 'react';
import { Card, CardContent, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';

interface ITimePeriodFilter {
    onSelectPeriod: (period: Date | null) => void;
}

enum Periods {
    week,
    week2,
    day30,
    month3,
    month6,
    year1,
    alltime,
}

const periodsLabels = [
    [Periods.week, 'Last Week'],
    [Periods.week2, 'Last 2 Weeks'],
    [Periods.day30, 'Last 30 Days'],
    [Periods.month3, 'Last 3 Months'],
    [Periods.month6, 'Last 6 Months'],
    [Periods.year1, 'Last Year'],
    [Periods.alltime, 'All Time'],
];
const calculateCreatedAfter = (period: Periods): Date | null => {
    let defaultDate: Date | null = new Date();
    switch (period) {
        case Periods.week:
            defaultDate.setDate(defaultDate.getDate() - 7);
            break;
        case Periods.week2:
            defaultDate.setDate(defaultDate.getDate() - 14);
            break;
        case Periods.day30:
            defaultDate.setDate(defaultDate.getDate() - 30);
            break;
        case Periods.month3:
            defaultDate.setMonth(defaultDate.getMonth() - 3);
            break;
        case Periods.month6:
            defaultDate.setMonth(defaultDate.getMonth() - 6);
            break;
        case Periods.year1:
            defaultDate.setMonth(defaultDate.getMonth() - 12);
            break;
        default:
            defaultDate = null;
            break;
    }
    return defaultDate;
};

export const TimePeriodFilter: FC<ITimePeriodFilter> = props => {
    const { onSelectPeriod } = props;
    const [selectedPeriod, setSelectedPeriod] = useState<Periods>(Periods.alltime);
    const changePeriod = (period: Periods) => {
        if (period !== selectedPeriod) {
            setSelectedPeriod(period);
            onSelectPeriod(calculateCreatedAfter(period));
        }
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="body2">
                            <b>Show:</b>&nbsp;
                        </Typography>
                        <FormControl variant="outlined">
                            <Select
                                variant="outlined"
                                labelId="period"
                                onChange={event => {
                                    const {
                                        target: { value },
                                    } = event;
                                    changePeriod(value as Periods);
                                }}
                                defaultValue={selectedPeriod}
                            >
                                {periodsLabels.map(period => (
                                    <MenuItem key={period[0]} value={period[0]}>
                                        {period[1]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};
