import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import { useTokenListQuery } from '~/schemaTypes';
import { useStyles } from './styles';

const ArticleCategories: React.FC = () => {
    const { classes } = useStyles();
    const { data, loading } = useTokenListQuery();
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Tokens"
                icons={tableIcons}
                isLoading={loading}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                data={_.cloneDeep(data?.tokens) ?? []}
                actions={[
                    {
                        onClick: () => history('/app-config/tokens/new'),
                        icon: () => <Add />,
                        tooltip: 'Add Token',
                        isFreeAction: true,
                        hidden: !pagePermissions?.Tokens.Edit,
                    },
                    {
                        onClick: (_, item) => {
                            const isSingleItemSelected = !Array.isArray(item);
                            if (isSingleItemSelected) {
                                history(`/app-config/tokens/${item.id}`);
                            }
                        },
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Token',
                        hidden: !pagePermissions?.Tokens.Edit,
                    },
                ]}
                columns={[
                    {
                        title: 'Name',
                        field: 'name',
                        defaultSort: 'asc',
                    },
                    { title: 'Description', field: 'description' },
                ]}
                options={{ pageSize: rowsPerPage }}
                localization={{ header: { actions: '' } }}
            />
        </div>
    );
};

export default ArticleCategories;
