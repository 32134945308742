import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import React, { useState } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import { SurveyDef, useSurveyDefsWithoutSubSurveysQuery } from '~/schemaTypes';

const SubSurveyModal: React.FC<{
    divisionId: string;
    title: string;
    editMode: boolean;
    surveyDefId: string;
    closeHandler: () => void;
    submitHandler: (subSurvey: Partial<SurveyDef>, divId: string) => void;
    deleteHandler: (subSurveyDefId: string, divId: string) => void;
}> = props => {
    const { divisionId, title, closeHandler, submitHandler, deleteHandler, editMode, surveyDefId } =
        props;
    const [subSurveyDefId, setSubSurveyDefId] = useState(surveyDefId ?? '');
    const { data: surveyList, loading: surveyListLoading } = useSurveyDefsWithoutSubSurveysQuery();
    const onSave = () => {
        if (surveyList && surveyList.surveyDefsWithoutSubSurveys) {
            const survey = surveyList.surveyDefsWithoutSubSurveys.find(
                item => item?.id === subSurveyDefId,
            );
            submitHandler({ id: survey?.id, name: survey?.name }, divisionId);
        }
    };
    const onDelete = () => {
        deleteHandler(subSurveyDefId, divisionId);
    };
    return (
        <Dialog open>
            <DialogTitleWithClose id="modalTitle" onClose={closeHandler}>
                {title}
            </DialogTitleWithClose>
            <DialogContent>
                {surveyListLoading ? (
                    <Loading />
                ) : (
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="sub-survey-select-label">Select Sub Survey item</InputLabel>
                        {editMode ? (
                            <Select
                                variant="outlined"
                                labelId="sub-survey-select-label"
                                id="sub-survey-select"
                                defaultValue={surveyDefId}
                                onChange={event => {
                                    const {
                                        target: { value },
                                    } = event;
                                    setSubSurveyDefId(value as string);
                                }}
                            >
                                {surveyList &&
                                    surveyList.surveyDefsWithoutSubSurveys &&
                                    surveyList.surveyDefsWithoutSubSurveys.map(
                                        item =>
                                            item && (
                                                <MenuItem value={item.id}>{item?.name}</MenuItem>
                                            ),
                                    )}
                            </Select>
                        ) : (
                            <Select
                                variant="outlined"
                                labelId="sub-survey-select-label"
                                id="sub-survey-select"
                                onChange={event => {
                                    const {
                                        target: { value },
                                    } = event;
                                    setSubSurveyDefId(value as string);
                                }}
                            >
                                {surveyList &&
                                    surveyList.surveyDefsWithoutSubSurveys &&
                                    surveyList.surveyDefsWithoutSubSurveys.map(
                                        item =>
                                            item && (
                                                <MenuItem value={item.id}>{item?.name}</MenuItem>
                                            ),
                                    )}
                            </Select>
                        )}
                    </FormControl>
                )}
            </DialogContent>
            <DialogActions>
                {editMode && (
                    <Button onClick={onDelete} color="primary" variant="outlined">
                        Delete
                    </Button>
                )}
                <Button onClick={closeHandler} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button type="submit" color="secondary" variant="contained" onClick={onSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default SubSurveyModal;
