import * as Yup from 'yup';

const CARE_TEAM_TYPE_SCHEMA = Yup.object({
    id: Yup.string().required('Care Team Member Type is required'),
    label: Yup.object({
        en: Yup.string().required('English Label is required'),
        es: Yup.string(),
    }),
    hiddenToPhone: Yup.boolean().default(false).required(),
    readOnly: Yup.boolean().default(false).required(),
    isRequired: Yup.boolean().default(false).required(),
});

export default CARE_TEAM_TYPE_SCHEMA;
