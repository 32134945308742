import React, { useEffect, useCallback, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Typography, Grid, TextField } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOnboarding } from '~/views/AffiliateCare/Onboarding/useOboarding';
import StateAutocomplete from '~/components/StateAutocomplete/StateAutocomplete';
import { useUpdatePaymentInfoForAffiliateMutation, AlertSeverity } from '~/schemaTypes';
import { SuppressNextGlobalAlert, TriggerGlobalAlert } from '~/state';
import { PAYMENT_FORM_SCHEMA } from './yupSchema';
import { FormInput } from './types';
import { useStyles } from './styles';

const Payment: React.FC = () => {
    const { setIsValidStep, affiliate, setStepCb, setLoading } = useOnboarding();
    const { classes } = useStyles();
    const isValidUpdate = useRef(true);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid: isValidForm, isDirty },
        control,
        watch,
    } = useForm<FormInput>({
        resolver: yupResolver(PAYMENT_FORM_SCHEMA as any),
        defaultValues: {
            BillDotComAddress1: affiliate?.billDotComInitData.BillDotComAddress1,
            BillDotComAddress2: affiliate?.billDotComInitData.BillDotComAddress2,
            BillDotComNameOnCheck: affiliate?.billDotComInitData.BillDotComNameOnCheck,
            BillDotComAddressCity: affiliate?.billDotComInitData.BillDotComAddressCity,
            BillDotComAddressState: affiliate?.billDotComInitData.BillDotComAddressState,
            BillDotComAddressZip: affiliate?.billDotComInitData.BillDotComAddressZip,
        },
    });
    const isValid = !isDirty || (isValidForm && isDirty);
    useEffect(() => {
        setIsValidStep(isValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);
    const state = watch('BillDotComAddressState');
    const [updatePayment] = useUpdatePaymentInfoForAffiliateMutation();
    const onSubmit = useCallback(
        async (data: FormInput) => {
            if (affiliate) {
                setLoading(true);
                try {
                    SuppressNextGlobalAlert(true);
                    const response = await updatePayment({
                        variables: {
                            input: {
                                onboarding: true,
                                data,
                                id: affiliate?.id,
                            },
                        },
                    });
                    isValidUpdate.current = !!response.data?.updatePaymentInfoForAffiliate?.success;
                    if (!response.data?.updatePaymentInfoForAffiliate?.success) {
                        SuppressNextGlobalAlert(false);
                        throw new Error(response.data?.updatePaymentInfoForAffiliate?.message);
                    }
                } catch (err) {
                    isValidUpdate.current = false;
                    if (err instanceof Error) {
                        TriggerGlobalAlert({
                            message: err.message,
                            severity: AlertSeverity.Error,
                        });
                    }
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [affiliate],
    );
    const updateBillDotComInfoHandler = useCallback(async () => {
        if (!affiliate) {
            return false;
        }
        setLoading(true);
        await handleSubmit(onSubmit, () => setIsValidStep(false))();
        setLoading(false);
        return isValidForm && isValidUpdate.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [affiliate, isValidForm]);
    useEffect(() => {
        setStepCb(() => async () => updateBillDotComInfoHandler());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateBillDotComInfoHandler]);
    return (
        <Grid container item xs={12} rowSpacing={5}>
            <Grid item container xs={12} rowSpacing={3}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        We partner with BILL to provide payment. You&apos;ll receive an invitation
                        to join BILL once you complete onboarding. You will be asked to upload your
                        W9 form and set up automatic payments. It&apos;s essential to upload your W9
                        in order to receive payment from Wildflower. By default, BILL will send your
                        payment checks to the address you enter below. If you want to change your
                        method of payment to direct deposit, you can do so in your BILL account.
                    </Typography>
                </Grid>
                <Grid item xs={12} container>
                    <form
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                        className={classes.formContainer}
                    >
                        <Grid item md={8} xs={12}>
                            <Controller
                                control={control}
                                name="BillDotComNameOnCheck"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        error={!!errors.BillDotComNameOnCheck}
                                        helperText={errors.BillDotComNameOnCheck?.message}
                                        label="Name On Check"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Controller
                                control={control}
                                name="BillDotComAddress1"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        error={!!errors.BillDotComAddress1}
                                        helperText={errors.BillDotComAddress1?.message}
                                        label="Address 1"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Controller
                                control={control}
                                name="BillDotComAddress2"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        error={!!errors.BillDotComAddress2}
                                        label="Address 2"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Controller
                                control={control}
                                name="BillDotComAddressCity"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        error={!!errors.BillDotComAddressCity}
                                        helperText={errors.BillDotComAddressCity?.message}
                                        label="City"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <StateAutocomplete
                                isMobileView={false}
                                name="BillDotComAddressState"
                                label="State"
                                register={register('BillDotComAddressState')}
                                onChange={value =>
                                    setValue('BillDotComAddressState', value, {
                                        shouldValidate: true,
                                    })
                                }
                                errorMessage={errors.BillDotComAddressState?.message}
                                value={state}
                            />
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Controller
                                control={control}
                                name="BillDotComAddressZip"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        error={!!errors.BillDotComAddressZip}
                                        helperText={errors.BillDotComAddressZip?.message}
                                        label="Zip"
                                    />
                                )}
                            />
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Payment;
