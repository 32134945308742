import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, IconButton, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { AlertSeverity, TimelineViewerEntryType } from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import { selectedItem } from '../TimelineViewer';

export const ViewTimelineModal: React.FC<{
    isOpen: boolean;
    item: selectedItem;
    canEdit: boolean;
    onClose: () => void;
}> = props => {
    const { isOpen, item, onClose, canEdit } = props;
    const history = useNavigate();
    const handleClose = () => {
        onClose();
    };
    const openEditor = () => {
        switch (item.type) {
            case TimelineViewerEntryType.Promotion:
                history(`/app-config/articlepromotions/${item.itemId}/`);
                break;
            case TimelineViewerEntryType.Hatask:
                history(`/portal/advocate-tasks/${item.itemId}/`);
                break;
            case TimelineViewerEntryType.Highlight:
                history(`/app-config/highlights/${item.itemId}/`);
                break;
            case TimelineViewerEntryType.Todo:
                history(`/app-config/care-plans/${item.itemId}/`);
                break;
            default:
                break;
        }
    };
    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose onClose={handleClose} id="modalTitle">
                <div>View Timeline Item</div>
            </DialogTitleWithClose>
            <DialogContent>
                <div>
                    {item.type}: {item.description}
                    <div>
                        Id
                        <Tooltip title={item.itemId}>
                            <IconButton
                                onClick={() => {
                                    copy(`${item.itemId}`);
                                    TriggerGlobalAlert({
                                        message: 'Item Id copied to clipboard',
                                        severity: AlertSeverity.Success,
                                    });
                                }}
                                size="large"
                            >
                                <InfoOutlined />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                {canEdit && (
                    <div>
                        <Button onClick={openEditor} title="Open Editor">
                            <FontAwesomeIcon icon={faPenToSquare} />
                            <span style={{ marginLeft: '10px' }}>Open Editor</span>
                        </Button>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="outlined">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
