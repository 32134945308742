import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    container: {
        padding: '10px 20px 20px',
        marginTop: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    containerItem: {
        paddingBottom: '20px',
    },
    inputFile: {
        height: 0,
        width: 0,
    },
}));

export default useStyles;
