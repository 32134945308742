import _ from 'lodash';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import tableIcons from '~/helpers/tableIcons';
import {
    useFetchPatientAppUsersForArticleFeedQuery,
    useFetchPatientArticleFeedLazyQuery,
} from '~/schemaTypes';
import { useStyles } from './styles';

const PatientArticleFeed: React.FC = () => {
    const { classes } = useStyles();
    const { id: patientId } = useParams<{ id: string }>();
    const [selectedAppUser, setSelectedAppUser] = useState('');
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);

    const { data: patientData, loading: patientDataLoading } =
        useFetchPatientAppUsersForArticleFeedQuery({ variables: { input: { id: patientId } } });

    const [getArticleFeed, { data: articleFeed, loading: articleFeedLoading }] =
        useFetchPatientArticleFeedLazyQuery();

    useEffect(() => {
        if (patientData && patientData?.patient && patientData?.patient.appUserId) {
            setSelectedAppUser(patientData.patient.appUserId);
        }
    }, [patientData]);

    useEffect(() => {
        if (selectedAppUser) {
            getArticleFeed({
                variables: { appUserId: selectedAppUser },
            });
        }
    }, [getArticleFeed, selectedAppUser]);

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Article Feed"
                isLoading={articleFeedLoading || patientDataLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                data={_.cloneDeep(articleFeed?.AppUserArticleFeed?.items) ?? []}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                }}
                columns={[
                    {
                        title: 'Title',
                        field: 'title',
                        align: 'center',
                    },
                    {
                        title: 'Summary',
                        field: 'summary',
                        align: 'center',
                    },
                    {
                        title: 'Available Until',
                        field: 'availableUntil',
                        align: 'center',
                        defaultSort: 'desc',
                        render: ({ availableUntil }) => {
                            return (
                                <div>
                                    {availableUntil &&
                                        new Date(availableUntil).toLocaleDateString()}
                                </div>
                            );
                        },
                    },
                ]}
            />
        </div>
    );
};

export default PatientArticleFeed;
