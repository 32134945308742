import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useUser } from '~/hooks';
import { PortalVcUserType } from '~/schemaTypes';
import useUserPermissions from '~/hooks/useUserPermissions';
import history from 'history/browser';
import Loading from '../Loading/Loading';
import Login from '../Login/Login';
import { NavLinks } from '../PortalNav/PortalNav';
import InactiveLogin from '../Login/InactiveLogin';

interface AuthWrapperProps {
    children: React.ReactElement<any, any> | null;
}

interface VCViewProps {
    children: React.ReactElement<any, any> | null;
}

export const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
    const { pagePermissions, loading: userPermissionsLoading } = useUserPermissions();
    const { data: userData } = useUser();
    const location = useLocation();
    const navRoute = NavLinks.find(({ path }) => path === location.pathname);

    if (!userData?.currentUser && !pagePermissions && !userPermissionsLoading) {
        return <Login />;
    }

    if (userPermissionsLoading) {
        return <Loading height={600} />;
    }

    if (userData?.currentUser && pagePermissions && navRoute) {
        if (navRoute.hide(pagePermissions)) {
            history.replace('/');
        }
    }

    return <InactiveLogin>{children}</InactiveLogin>;
};

export const VCViewWrapper: React.FC<VCViewProps> = ({ children }) => {
    const { data: userData } = useUser();
    const location = useLocation();
    const isParent = (parentPaths: string[]) => parentPaths.includes(location.pathname);
    if (userData?.currentUser?.vcType) {
        switch (userData.currentUser.vcType) {
            case PortalVcUserType.Doula:
                if (isParent(['/doula', '/doula/portal'])) {
                    return <Navigate to="/doula/portal/welcome" />;
                }
                if (!location.pathname.includes('doula')) {
                    return <Navigate to={`/doula${location.pathname}`} />;
                }
                break;
            default:
                return children;
        }
    }
    return children;
};
