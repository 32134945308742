import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    helperMessage: {
        margin: '-10px 0 0 24px',
    },
    profileDefLabel: {
        paddingLeft: '10px',
    },
    surveys: {
        marginTop: '20px',
    },
    surveyItem: {
        paddingLeft: '20px',
        paddingBottom: '3px',
    },
    content: {
        maxHeight: '75vh',
    },
});
