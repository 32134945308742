/* eslint-disable no-nested-ternary */
import { faListCheck, faRectangleList, faRectangleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Badge,
    Button,
    Checkbox,
    FormControlLabel,
    Typography,
    TablePagination,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import MaterialTable from '@material-table/core';
import React, { useMemo, useRef, useState } from 'react';
import Loading from '~/components/Loading/Loading';
import { PAGESIZE } from '~/constants';
import { displayDate } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import {
    ApprovalStatus,
    ArticleApprovalCustomFilters,
    ArticleApprovalListFilterInput,
    ArticleApprovalListV2Query,
    Language,
    ReadingLevel,
    useArticleApprovalListV2Query,
    useUpdateArticleApprovalMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';

import { useUserPermissions } from '~/hooks';
import ApproveModal from './components/ApproveModal';
import { ArticleApprovalFilter } from './components/ArticleApprovalsFilter';
import ArticleReview from './components/Review';

type Approval = NonNullable<
    ArticleApprovalListV2Query['customFilteredArticleApprovalsV2']
>['results'][0];

export interface ApprovalParms {
    count?: number;
    approve: boolean;
    closeHandler: () => void;
    submitHandler: (approvedBy: string, approve: boolean) => void;
}

const useStyles = makeStyles()({
    root: {},
});

const ArticleApprovals: React.FC = () => {
    const { classes } = useStyles();
    const tableRef = useRef<HTMLDivElement>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [openFilterArticleApprovalsModel, setOpenFilterArticleApprovalsModel] =
        useState<boolean>(false);
    const [reviewOpen, setReviewOpen] = useState<boolean>(false);
    const { pagePermissions } = useUserPermissions();
    const [selectedApproval, setSelectedApproval] = useState<Approval>();
    const [approve, setApprove] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [selectedList, setSelectedList] = useState<
        { articleId: string; l: Language; rl: ReadingLevel; appBundleId: string }[]
    >([]);

    const filteredArticle = localStorage.getItem('filteredArticleId');
    const filteredLanguage = localStorage.getItem('filteredLanguage');
    const filteredReadingLevel = localStorage.getItem('filteredReadingLevel');
    const filteredVersion = Number(localStorage.getItem('filteredVersion'));
    const filteredStatus = localStorage.getItem('filteredStatus');
    const filteredApp = localStorage.getItem('filteredApp');
    const filteredGroups = localStorage.getItem('filteredGroups');
    const filteredOperator = localStorage.getItem('filteredOperator') || 'OR';

    const articleApprovalFilter: ArticleApprovalListFilterInput = useMemo(() => {
        const filter: ArticleApprovalListFilterInput = {
            fields: {
                ...(filteredArticle && { articleId: filteredArticle }),
                ...(filteredLanguage && { language: filteredLanguage as Language }),
                ...(filteredReadingLevel && { readingLevel: filteredReadingLevel as ReadingLevel }),
                ...(filteredVersion && { versionNumber: filteredVersion }),
            },
        };
        return filter;
    }, [filteredArticle, filteredLanguage, filteredReadingLevel, filteredVersion]);

    const articleApprovalCustomFilter: ArticleApprovalCustomFilters = useMemo(() => {
        const filter: ArticleApprovalCustomFilters = {
            ...(filteredApp && { appBundleId: filteredApp }),
            ...(filteredStatus && { status: filteredStatus as ApprovalStatus }),
            ...(filteredGroups && { articleGroupIds: filteredGroups.split(',') }),
            ...(filteredOperator && filteredGroups && { operator: filteredOperator }),
        };
        return filter;
    }, [filteredApp, filteredStatus, filteredGroups, filteredOperator]);

    const { data: filteredApprovals, loading: loadingList } = useArticleApprovalListV2Query({
        fetchPolicy: 'network-only',
        variables: {
            input: {
                ...(Object.values(articleApprovalFilter.fields || {}).length > 0 && {
                    filter: articleApprovalFilter,
                }),
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                customFilters: {
                    ...articleApprovalCustomFilter,
                },
            },
        },
    });

    const [updateArticleApproval, { loading: updateApprovalLoading }] =
        useUpdateArticleApprovalMutation({
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        setModalOpen(false);
                    },
                    message: `There was a problem saving the article approval: ${error.message}`,
                });
            },
        });

    const selectAll = () => {
        if (filteredApprovals?.customFilteredArticleApprovalsV2)
            setSelectedList(
                filteredApprovals.customFilteredArticleApprovalsV2.results.map(a => {
                    return {
                        articleId: a.articleId,
                        l: a.language,
                        rl: a.readingLevel,
                        appBundleId: a.appBundleId,
                    };
                }),
            );
    };

    const handleSelectedChange = (
        articleId: string,
        l: Language,
        rl: ReadingLevel,
        appBundleId: string,
        checked: boolean,
    ) => {
        const items = selectedList.slice();
        if (checked) {
            items.push({ articleId, l, rl, appBundleId });
            setSelectedList(items);
        } else
            setSelectedList(
                items.filter(
                    i =>
                        !(
                            i.articleId === articleId &&
                            i.l === l &&
                            i.rl === rl &&
                            i.appBundleId === appBundleId
                        ),
                ),
            );
    };

    const updateUnapprovals = () => {
        if (filteredApprovals?.customFilteredArticleApprovalsV2) {
            for (const item of selectedList) {
                const approval = filteredApprovals.customFilteredArticleApprovalsV2.results.find(
                    a =>
                        a.articleId === item.articleId &&
                        a.appBundleId === item.appBundleId &&
                        a.language === item.l &&
                        a.readingLevel === item.rl,
                );
                if (approval) {
                    updateArticleApproval({
                        variables: {
                            updateArticleApprovalInput: {
                                id: approval.id,
                                data: {
                                    appBundleId: approval.appBundleId,
                                    articleId: approval.articleId,
                                    language: approval.language,
                                    readingLevel: approval.readingLevel,
                                    approvedBy: null,
                                    dateApproved: null,
                                    status: ApprovalStatus.Pending,
                                    articleName: approval.articleName,
                                },
                            },
                        },
                    });
                }
            }
        }
        setSelectedList([]);
    };
    const handleUnapprove = () => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to revert these items (${selectedList.length}) to pending?`,
            callback: () => {
                updateUnapprovals();
            },
        });
    };
    const handleSubmit = (approvedBy: string, approve: boolean) => {
        if (filteredApprovals?.customFilteredArticleApprovalsV2) {
            for (const item of selectedList) {
                const approval = filteredApprovals.customFilteredArticleApprovalsV2.results.find(
                    a =>
                        a.articleId === item.articleId &&
                        a.appBundleId === item.appBundleId &&
                        a.language === item.l &&
                        a.readingLevel === item.rl,
                );
                if (approval) {
                    updateArticleApproval({
                        variables: {
                            updateArticleApprovalInput: {
                                id: approval.id,
                                data: {
                                    appBundleId: approval.appBundleId,
                                    articleId: approval.articleId,
                                    language: approval.language,
                                    readingLevel: approval.readingLevel,
                                    articleName: approval.articleName,
                                    approvedBy,
                                    dateApproved: new Date().toUTCString(),
                                    status: approve
                                        ? ApprovalStatus.Approved
                                        : ApprovalStatus.Denied,
                                },
                            },
                        },
                    });
                }
            }
        }
        setSelectedList([]);
        setModalOpen(false);
    };
    const handlePreviewClick = (item: any) => {
        setSelectedApproval(
            filteredApprovals?.customFilteredArticleApprovalsV2.results.find(a => a.id === item.id),
        );
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    if (loadingList || updateApprovalLoading) return <Loading />;

    return (
        <>
            <div className={classes.root}>
                {modalOpen && (
                    <ApproveModal
                        parms={{
                            count: selectedList.length,
                            approve,
                            submitHandler: handleSubmit,
                            closeHandler: () => setModalOpen(false),
                        }}
                    />
                )}
                {reviewOpen && (
                    <ArticleReview
                        approval={selectedApproval}
                        closeReview={() => setReviewOpen(false)}
                    />
                )}
                {!reviewOpen && (
                    <div>
                        <div ref={tableRef}>
                            {selectedList.length > 0 &&
                                pagePermissions?.ArticleApproval.Edit &&
                                (filteredStatus as ApprovalStatus) === ApprovalStatus.Pending && (
                                    <div>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => {
                                                setApprove(true);
                                                setModalOpen(true);
                                            }}
                                        >
                                            Approve Selected
                                        </Button>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => {
                                                setApprove(false);
                                                setModalOpen(true);
                                            }}
                                        >
                                            Deny Selected
                                        </Button>
                                    </div>
                                )}
                            {selectedList.length > 0 &&
                                pagePermissions?.ArticleApproval.Edit &&
                                ((filteredStatus as ApprovalStatus) === ApprovalStatus.Denied ||
                                    (filteredStatus as ApprovalStatus) ===
                                        ApprovalStatus.Approved) && (
                                    <div>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => {
                                                handleUnapprove();
                                            }}
                                        >
                                            Revert Selected
                                        </Button>
                                    </div>
                                )}
                            <MaterialTable
                                title="Article Approvals"
                                icons={tableIcons}
                                isLoading={loadingList}
                                data={
                                    filteredApprovals?.customFilteredArticleApprovalsV2.results ??
                                    []
                                }
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            count={
                                                filteredApprovals?.customFilteredArticleApprovalsV2
                                                    .total ?? 0
                                            }
                                            page={page}
                                            onPageChange={handleChangePage}
                                        />
                                    ),
                                }}
                                actions={[
                                    {
                                        tooltip: 'Filter Article Approvals',
                                        onClick: event => {
                                            event.preventDefault();
                                        },
                                        icon: () => (
                                            <Badge
                                                color="error"
                                                invisible={
                                                    !(
                                                        Object.values(
                                                            articleApprovalFilter?.fields ?? {},
                                                        ).length +
                                                            Object.values(
                                                                articleApprovalCustomFilter ?? {},
                                                            ).length >
                                                        0
                                                    )
                                                }
                                                badgeContent={localStorage.getItem(
                                                    'filtersAppliedCount',
                                                )}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() =>
                                                        setOpenFilterArticleApprovalsModel(true)
                                                    }
                                                >
                                                    Filter Article Approvals
                                                </Button>
                                            </Badge>
                                        ),
                                        isFreeAction: true,
                                    },
                                    {
                                        onClick: () => {
                                            selectAll();
                                        },
                                        icon: () => <FontAwesomeIcon icon={faListCheck} />,
                                        tooltip: 'Select All',
                                        isFreeAction: true,
                                        hidden: !pagePermissions?.ArticleApproval.Edit,
                                        disabled: filteredStatus === '',
                                    },
                                    {
                                        onClick: () => {
                                            setSelectedList([]);
                                        },
                                        icon: () => <FontAwesomeIcon icon={faRectangleXmark} />,
                                        tooltip: 'Unselect All',
                                        isFreeAction: true,
                                        hidden: !pagePermissions?.ArticleApproval.Edit,
                                        disabled: filteredStatus === '',
                                    },
                                    {
                                        onClick: (_, item) => {
                                            handlePreviewClick(item);
                                            setReviewOpen(true);
                                        },
                                        icon: () => <FontAwesomeIcon icon={faRectangleList} />,
                                        tooltip: 'Preview',
                                    },
                                ]}
                                columns={[
                                    {
                                        title: 'Article',
                                        field: 'articleName',
                                    },
                                    {
                                        title: 'Language',
                                        field: 'language',
                                        render: ({ language }) => {
                                            return (
                                                <Typography>
                                                    {language === Language.En
                                                        ? 'English'
                                                        : 'Spanish'}
                                                </Typography>
                                            );
                                        },
                                    },
                                    {
                                        title: 'Reading Level',
                                        field: 'readingLevel',
                                        render: ({ readingLevel }) => {
                                            return (
                                                <Typography>
                                                    {readingLevel === ReadingLevel.Eighth
                                                        ? 'Eighth'
                                                        : 'Fifth'}
                                                </Typography>
                                            );
                                        },
                                    },
                                    {
                                        title: 'App',
                                        field: 'appBundleId',
                                        render: ({ appBundleId }) => {
                                            return (
                                                <Typography variant="body1">
                                                    {appBundleId ?? 'All Apps'}
                                                </Typography>
                                            );
                                        },
                                    },
                                    {
                                        title: 'Status',
                                        field: 'status',
                                        render: rowData => {
                                            return (
                                                <Typography>
                                                    {rowData.status === ApprovalStatus.Approved &&
                                                        `Approved by
                                                     ${rowData.approvedBy} on ${displayDate({
                                                            isoDateStr: rowData.dateApproved,
                                                        })}`}
                                                    {rowData.status === ApprovalStatus.Denied &&
                                                        `Denied by
                                                     ${rowData.approvedBy} on ${displayDate({
                                                            isoDateStr: rowData.dateApproved,
                                                        })}`}
                                                    {rowData.status === ApprovalStatus.Pending &&
                                                        'pending'}
                                                </Typography>
                                            );
                                        },
                                    },
                                    {
                                        title: 'Action',
                                        hidden:
                                            !pagePermissions?.ArticleApproval.Edit ||
                                            filteredStatus === '',
                                        render: rowData => {
                                            return (
                                                <Typography>
                                                    <FormControlLabel
                                                        label="Select"
                                                        labelPlacement="start"
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    selectedList.find(
                                                                        x =>
                                                                            x.articleId ===
                                                                                rowData.articleId &&
                                                                            x.l ===
                                                                                rowData.language &&
                                                                            x.rl ===
                                                                                rowData.readingLevel &&
                                                                            x.appBundleId ===
                                                                                rowData.appBundleId,
                                                                    ) !== undefined
                                                                }
                                                                onChange={e =>
                                                                    handleSelectedChange(
                                                                        rowData.articleId,
                                                                        rowData.language,
                                                                        rowData.readingLevel,
                                                                        rowData.appBundleId,
                                                                        e.target.checked,
                                                                    )
                                                                }
                                                            />
                                                        }
                                                    />
                                                </Typography>
                                            );
                                        },
                                    },
                                ]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                options={{
                                    pageSize: rowsPerPage,
                                    pageSizeOptions: [25, 50, 100],
                                    search: false,
                                    exportAllData: pagePermissions?.ArticleApproval.Export,
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            <ArticleApprovalFilter
                open={openFilterArticleApprovalsModel}
                onClose={() => setOpenFilterArticleApprovalsModel(false)}
            />
        </>
    );
};

export default ArticleApprovals;
