import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, IconButton, Link, Tooltip } from '@mui/material';
import { Add, InfoOutlined } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { ReactElement, useRef, useState } from 'react';
import tableIcons from '~/helpers/tableIcons';
import useUserPermissions from '~/hooks/useUserPermissions';
import { AlertSeverity, useFetchDevicesForDevicesPageQueryQuery } from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import DevicesModal from './DevicesModal/DevicesModal';

export default function Devices(): ReactElement {
    const { data, loading: devicesLoading } = useFetchDevicesForDevicesPageQueryQuery();

    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [showDeviceModal, setShowDeviceModal] = useState(false);
    const [editDeviceId, setEditDeviceId] = useState<string>('');
    const { pagePermissions } = useUserPermissions();

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <>
            <MaterialTable
                icons={tableIcons as MaterialTableProps<any>['icons']}
                actions={[
                    {
                        onClick: (_, device: any) => {
                            setEditDeviceId(device.id);
                            setShowDeviceModal(true);
                        },
                        disabled: !pagePermissions?.Devices.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit',
                    },
                    {
                        onClick: () => setShowDeviceModal(true),
                        disabled: !pagePermissions?.Devices.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add New Device',
                        isFreeAction: true,
                    },
                ]}
                columns={[
                    {
                        title: 'Id',
                        field: 'id',
                        align: 'center',
                        sorting: false,
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Device Order Id Copied to Clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'External Id',
                        field: 'externalId',
                        align: 'center',
                        sorting: false,
                        render: ({ externalId }) => {
                            if (externalId) {
                                return (
                                    <Tooltip title={externalId ?? ''}>
                                        <IconButton
                                            onClick={() => {
                                                copy(`${externalId}`);
                                                TriggerGlobalAlert({
                                                    message: 'External Id Copied to Clipboard',
                                                    severity: AlertSeverity.Success,
                                                });
                                            }}
                                            size="large"
                                        >
                                            <InfoOutlined />
                                        </IconButton>
                                    </Tooltip>
                                );
                            }
                            return '';
                        },
                    },
                    { title: 'Name', field: 'localizedName' },
                    {
                        title: 'Photo',
                        render: ({ photo }) => (
                            <div>
                                {photo && (
                                    <Link
                                        href={photo}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        underline="hover"
                                    >
                                        Photo Link
                                    </Link>
                                )}
                            </div>
                        ),
                    },
                    { title: 'Documentation Link', field: 'localizedDocLink' },
                ]}
                isLoading={devicesLoading}
                data={_.cloneDeep(data?.devices ?? [])}
                title="Devices"
                localization={{ header: { actions: '' } }}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100] }}
            />
            <Dialog
                scroll="paper"
                open={showDeviceModal}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <DevicesModal
                    setOpen={setShowDeviceModal}
                    setEditDeviceId={setEditDeviceId}
                    id={editDeviceId}
                />
            </Dialog>
        </>
    );
}
