import {
    Autocomplete,
    Button,
    FormControl,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
    AdvocateTaskTypeStatus,
    FetchAdvocateTaskForAdvocateTaskPageQuery,
    Target,
} from '~/schemaTypes';
import DateEditor from '~/components/DateEditor/DateEditor';
import { Priorities } from '../../helpers';
import { useStyles } from './styles';
import { OnSidebarUpdated } from './types';

type Type = NonNullable<FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']>['types'][0];

type UserToAssign = NonNullable<
    FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']
>['usersToAssign'][0];

interface SidebarProps {
    onSidebarUpdated: (sidebarData: OnSidebarUpdated) => void;
    status: AdvocateTaskTypeStatus;
    dueDate: string;
    typeId: string;
    types: Type[];
    priority: number;
    assignedTo?: string | null;
    usersToAssign: UserToAssign[];
    taskCreatorName: string;
}

const Sidebar: React.FC<SidebarProps> = ({
    onSidebarUpdated,
    status,
    dueDate,
    typeId,
    types,
    priority,
    assignedTo,
    usersToAssign,
    taskCreatorName,
}) => {
    const { classes } = useStyles();
    const { control: dateControl } = useForm();
    const [newStatus, setNewStatus] = useState(status);
    const [newDate, setNewDate] = useState(dueDate);
    const [newTypeId, setNewTypeId] = useState(typeId);
    const [newPriority, setNewPriority] = useState(priority);
    const [newAssignedTo, setNewAssignedTo] = useState(assignedTo);
    const handleSave = () => {
        onSidebarUpdated({
            assignedTo: newAssignedTo || '',
            status: newStatus,
            dueDate: newDate,
            typeId: newTypeId,
            priority: newPriority,
        });
    };
    return (
        <Grid className={classes.sideBarContainer}>
            <Grid className={classes.sideBarItem}>
                <FormControl variant="outlined" fullWidth>
                    <TextField
                        variant="outlined"
                        label="Status"
                        select
                        defaultValue={status}
                        onChange={({ target }) => {
                            setNewStatus(
                                target.value as
                                    | AdvocateTaskTypeStatus.Open
                                    | AdvocateTaskTypeStatus.Closed,
                            );
                        }}
                    >
                        <MenuItem
                            className={classes.selectMenuItem}
                            value={AdvocateTaskTypeStatus.Open}
                        >
                            Open
                        </MenuItem>
                        <MenuItem
                            className={classes.selectMenuItem}
                            value={AdvocateTaskTypeStatus.Closed}
                        >
                            Closed
                        </MenuItem>
                    </TextField>
                </FormControl>
            </Grid>
            <Grid className={classes.sideBarItem}>
                <Controller
                    control={dateControl}
                    name="dueDate"
                    defaultValue={dueDate}
                    render={({ field: { value } }) => {
                        return (
                            <DateEditor
                                title="Due Date"
                                setSelectedDate={d => setNewDate(d)}
                                initialDate={value || new Date().toISOString()}
                                separateLines
                            />
                        );
                    }}
                />
            </Grid>
            <Grid className={classes.sideBarItem}>
                <FormControl variant="outlined" fullWidth>
                    <TextField
                        label="Type"
                        select
                        defaultValue={typeId}
                        onChange={({ target }) => {
                            setNewTypeId(target.value);
                        }}
                    >
                        {types.map(({ id, label }) => (
                            <MenuItem key={id} value={id} className={classes.selectMenuItem}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Grid>
            <Grid className={classes.sideBarItem}>
                <FormControl variant="outlined" fullWidth>
                    <TextField
                        label="Priority"
                        select
                        defaultValue={priority}
                        onChange={({ target }) => {
                            setNewPriority(Number(target.value));
                        }}
                    >
                        {Priorities.map(i => (
                            <MenuItem key={i} value={i} className={classes.selectMenuItem}>
                                {i}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Grid>
            <Grid className={classes.sideBarItem}>
                <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                        options={
                            usersToAssign.filter(user =>
                                user.role?.permissions?.find(
                                    i => i?.target === Target.IsHealthAdvocate,
                                ),
                            ) ?? []
                        }
                        getOptionLabel={option =>
                            usersToAssign.find(i => i.id === option.id)?.name ?? ''
                        }
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(_, value) => {
                            setNewAssignedTo(value?.id ?? '');
                        }}
                        disableClearable
                        value={usersToAssign.find(i => i.id === assignedTo) ?? undefined}
                        renderInput={params => (
                            <TextField
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                style={{ padding: 0 }}
                                label="Assigned to"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                </FormControl>
                <Button color="secondary" variant="contained" fullWidth onClick={handleSave}>
                    Save
                </Button>
            </Grid>
            <Grid item container alignItems="center">
                <Grid item>
                    <Typography className={classes.createdBy} variant="subtitle2">
                        Created by:
                    </Typography>
                    <Typography className={classes.taskCreator} variant="subtitle2">
                        {taskCreatorName}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Sidebar;
