export const HA_CREATED = 'advocate';
export const MILISECONDS_IN_MINUTE = 1000 * 60;

export enum TODO_TYPE {
    CPCreatedUpcoming,
    CPCreatedCompleted,
    HACreatedUpcoming,
    HACreatedCompleted,
}

export enum FIELD_NAMES {
    title = 'title',
    description = 'description',
    careplanLabel = 'careplan-label',
    careplanGroupLabel = 'careplan-group-label',
    repeatType = 'repeat-type',
    dueDate = 'due-date',
    effectiveDateDaysInterval = 'effective-date-days-interval',
    effectiveDateMonthsInterval = 'effective-date-months-interval',
    effectiveDateYearsInterval = 'effective-date-years-interval',
    priority = 'priority',
    isEssential = 'is-essential',
    careplanTemplateTags = 'careplan-template-tags',
    deleteTodo = 'delete-todo',
    deleteTodoRecurring = 'delete-todo-recurring',
    markAsComplete = 'mark-as-complete',
    submitTodo = 'submit-todo-button',
    closeModal = 'close-modal-button',
    cancelModal = 'cancel-modal-button',
}

export enum REPEAT_TYPE {
    ONE_TIME = 'ONE_TIME',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
}

export const PRIORITIES = Array.from({ length: 10 }, (_, i) => i + 1);
