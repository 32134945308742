import * as Yup from 'yup';

export const yupLocalizedString = ({ required = false } = {}): Yup.Schema<
    any,
    Record<string, string>
> =>
    Yup.object().shape({
        en: required
            ? Yup.string().required('Label (en) is a required field')
            : Yup.string().when('es', ([es], schema) => {
                  if (typeof es === 'string' && es.length > 0) {
                      return schema.required('English is required when Spanish is filled');
                  }
                  return schema;
              }),
        es: Yup.string(),
    });
