import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import RoutingForm from '~/views/AffiliateCare/Onboarding/steps/Routing/components/RoutingForm';
import { IVCAffiliatesFormValues } from '../interfaces/IVCAffiliatesFormValues';

const RoutingCustomization = () => {
    const { watch } = useFormContext<IVCAffiliatesFormValues>();
    const externalName = watch('externalName');
    const supportPhone = watch('supportPhone');
    const contactName = watch('contactName');
    const supportEmail = watch('supportEmail');
    const website = watch('website');
    const affiliateDataForText = useMemo(() => {
        return {
            externalName,
            supportPhone,
            contactName,
            supportEmail,
            website,
        };
    }, [externalName, supportPhone, contactName, supportEmail, website]);
    return (
        <OutlinedSection title="Routing Customization">
            <RoutingForm
                affiliate={affiliateDataForText}
                disabled={false}
                showPreview={false}
                showZipCode
            />
        </OutlinedSection>
    );
};

export default RoutingCustomization;
