import React, {
    useState,
    useCallback,
    useRef,
    useMemo,
    useEffect,
    Dispatch,
    SetStateAction,
} from 'react';
import { Button, Grid, TablePagination, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
    GetPatientsForMassUpdateQuery,
    OrderByDirectionEnum,
    useGetPatientsForMassUpdateLazyQuery,
} from '~/schemaTypes';
import MaterialTable, { MaterialTableProps, MTableToolbar } from '@material-table/core';
import { PAGESIZE } from '~/constants';
import { TriggerGlobalConfirm } from '~/state';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import _ from 'lodash';
import useStyles from '../Tasks/CreateTask/styles';
import { AddPatientModal } from './AddPatientModal';

type PatientsInListProps = {
    setPatientIds: Dispatch<SetStateAction<string[]>>;
    patientIds: string[];
    listId: string;
};

const PatientsInList: React.FC<PatientsInListProps> = props => {
    const { patientIds, setPatientIds, listId } = props;
    const { classes } = useStyles();
    const patientTableRef = useRef<HTMLDivElement>(null);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [page, setPage] = useState<number>(0);
    const [showAddModal, setShowAddModal] = useState(false);
    const { pagePermissions } = useUserPermissions();

    const fetchPatientsPayload = useMemo(
        () => ({
            variables: {
                input: {
                    filter: {
                        fieldsInList: {
                            _id: patientIds,
                        },
                    },
                    orderBy: {
                        field: 'createdAt',
                        order: OrderByDirectionEnum.Desc,
                    },
                    pagination: {
                        skip: page * rowsPerPage,
                        limit: rowsPerPage,
                    },
                },
            },
        }),
        [page, rowsPerPage, patientIds],
    );
    const [fetchPatients, { data: patients, loading: patientsLoading }] =
        useGetPatientsForMassUpdateLazyQuery({
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
        });
    const uploadPatientsHandler = useCallback(
        async ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
            if (files && files.length) {
                const file = files[0];
                const reader = new FileReader();
                reader.onload = async () => {
                    const buffer = reader.result as unknown as string;
                    if (buffer) {
                        const ids = buffer
                            .split('\n')
                            .map(id => id?.trim())
                            .filter(id => id && /^[0-9a-fA-F]{24}$/.test(id));
                        if (ids.length) {
                            const newPatientsId = ids.filter(id => !patientIds.includes(id));
                            const response = await fetchPatients({
                                variables: {
                                    input: {
                                        filter: {
                                            fieldsInList: {
                                                _id: newPatientsId,
                                            },
                                        },
                                    },
                                },
                            });
                            const existingPatients =
                                response.data?.patientsV2.results?.map(patient => patient.id) || [];
                            setPatientIds([...patientIds, ...existingPatients]);
                        }
                    }
                };
                reader.readAsText(file);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [patientIds],
    );
    const handleChangeRowsPerPage = useCallback((pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (patientTableRef.current) {
                patientTableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    }, []);
    const handleChangePage = useCallback(
        (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
            event?.preventDefault();
            setPage(page);
            if (patientTableRef.current) {
                patientTableRef.current.scrollIntoView();
            }
        },
        [],
    );
    useEffect(() => {
        fetchPatients(fetchPatientsPayload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchPatientsPayload]);
    const patientDeleteHandler = useCallback(
        (removeId: string) => {
            TriggerGlobalConfirm({
                message: 'Are you sure you want to remove patient?',
                callback: () => {
                    const filteredPatients = patientIds.filter(patientId => patientId !== removeId);
                    setPatientIds(filteredPatients);
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [patientIds],
    );
    const handleAddPatient = (id: string) => {
        const newPatients = _.clone(patientIds);
        if (!newPatients.find(p => p === id)) {
            newPatients.push(id);
            setPatientIds(newPatients);
        }
    };

    const removeAllPatientsHandler = useCallback(() => {
        TriggerGlobalConfirm({
            message: 'Are you sure you want to remove all patients?',
            callback: () => {
                setPatientIds([]);
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <MaterialTable<GetPatientsForMassUpdateQuery['patientsV2']['results'][0]>
                icons={tableIcons as MaterialTableProps<any>['icons']}
                columns={[
                    {
                        title: 'Patient ID',
                        field: 'id',
                        render: ({ id }) => <Typography data-test={id}>{id}</Typography>,
                        sorting: false,
                    },
                    {
                        title: 'First Name',
                        field: 'firstName',
                        render: ({ firstName }) => (
                            <Typography data-test={firstName}>{firstName}</Typography>
                        ),
                        sorting: false,
                        hidden: !pagePermissions?.PatientDetails.Read,
                    },
                    {
                        title: 'Last Name',
                        field: 'lastName',
                        render: ({ lastName }) => (
                            <Typography data-test={lastName}>{lastName}</Typography>
                        ),
                        sorting: false,
                        hidden: !pagePermissions?.PatientDetails.Read,
                    },
                    {
                        title: 'Organization Name',
                        field: 'practice.name',
                        render: ({ practice }) => (
                            <Typography data-test={practice?.name}>{practice?.name}</Typography>
                        ),
                        sorting: false,
                    },
                ]}
                title="Patients"
                data={
                    patients?.patientsV2.results.map((o: any) => ({
                        ...o,
                    })) || []
                }
                options={{
                    search: false,
                    paginationPosition: 'bottom',
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    emptyRowsWhenPaging: false,
                }}
                isLoading={patientsLoading}
                localization={{ header: { actions: '' } }}
                actions={[
                    {
                        onClick: (_, { id }: any) => patientDeleteHandler(id),
                        icon: () => <FontAwesomeIcon icon={faTrash} />,
                        tooltip: 'Remove Patient',
                        hidden: !pagePermissions?.MassUpdate.Edit,
                    },
                ]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                components={{
                    Pagination: props => (
                        <>
                            <td className={classes.pagination}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    onClick={removeAllPatientsHandler}
                                    disabled={!patientIds.length}
                                >
                                    Remove All Patients
                                </Button>
                            </td>
                            <TablePagination
                                {...props}
                                count={patients?.patientsV2?.total || 0}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </>
                    ),
                    Toolbar: props => (
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            paddingRight={2}
                        >
                            <Grid item xs={5}>
                                <MTableToolbar {...props} />
                            </Grid>
                            <Grid item>
                                <Grid container columnSpacing={1}>
                                    <Grid item>
                                        <Button variant="contained" component="label">
                                            Upload Patient IDs
                                            <input
                                                type="file"
                                                accept=".csv"
                                                onChange={uploadPatientsHandler}
                                                className={classes.inputFile}
                                            />
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            component="label"
                                            onClick={() => setShowAddModal(true)}
                                        >
                                            Add Patient
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ),
                }}
            />
            <AddPatientModal
                isOpen={showAddModal}
                onClose={() => {
                    setShowAddModal(false);
                }}
                addPatient={handleAddPatient}
                listId={listId}
            />
        </>
    );
};

export default PatientsInList;
