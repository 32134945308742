import * as Yup from 'yup';
import { OnSuccessfulEligibility } from '~/schemaTypes';

export const ROUTING_FORM_SCHEMA = Yup.object().shape({
    onSuccessfulEligibility: Yup.string().required(),
    routingURL: Yup.string().when(
        ['onSuccessfulEligibility'],
        ([onSuccessfulEligibility], schema) => {
            if (onSuccessfulEligibility === OnSuccessfulEligibility.RouteDirectly) {
                return schema.required();
            }
            return schema.nullable();
        },
    ),
    customSuccessText: Yup.string().when(
        ['onSuccessfulEligibility'],
        ([onSuccessfulEligibility], schema) => {
            if (onSuccessfulEligibility === OnSuccessfulEligibility.RouteToLanding) {
                return schema.required();
            }
            return schema.nullable();
        },
    ),
    supportEmail: Yup.string().required('Support Email Address is a required field'),
    website: Yup.string().optional(),
    supportPhone: Yup.string().optional(),
});
