import { List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import React, { ReactElement, useCallback } from 'react';
import Draggable from '~/components/Draggable/Draggable';
import Droppable from '~/components/Droppable/Droppable';

export type ListItemType = {
    name: string;
    id: number;
};

export type SortableListOnChangeProps = {
    startIndex: number;
    endIndex: number;
};

type SortableListProps<T extends ListItemType> = {
    list: T[];
    onChange: (props: SortableListOnChangeProps) => void;
    droppableId: string;
};

const SortableList: React.FC<SortableListProps<ListItemType>> = ({
    list,
    onChange,
    droppableId,
}): ReactElement | null => {
    const onDragEnd = useCallback(
        (result: { source?: any; destination?: any }) => {
            const { source, destination } = result;
            const hasSource = source && source.index != null;
            const hasDestination = destination && destination.index != null;
            const hasData = hasSource && hasDestination;
            const shouldMoveItem = hasData && source.index !== destination.index;
            if (!shouldMoveItem) {
                return;
            }

            if (onChange) {
                onChange({ startIndex: result.source.index, endIndex: result.destination.index });
            }
        },
        [onChange],
    );

    if (list.length < 1) return null;
    return (
        <List>
            <Droppable onDragEnd={onDragEnd} droppableId={droppableId}>
                {list?.map((item, index) => {
                    if (!item?.id) return null;
                    return (
                        <Draggable id={item.id.toString()} index={index} key={item.id}>
                            <ListItem>
                                <ListItemIcon
                                    style={{
                                        minWidth: 'auto',
                                        cursor: 'pointer',
                                        marginRight: '20px',
                                    }}
                                >
                                    <RemoveCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary={item.name} />
                                <ListItemSecondaryAction>
                                    <ListItemIcon className="drag-handle">
                                        <DragHandleIcon />
                                    </ListItemIcon>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Draggable>
                    );
                })}
            </Droppable>
        </List>
    );
};

export default SortableList;
