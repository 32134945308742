import { Typography } from '@mui/material';
import React from 'react';
import { GetResolvedTagsForPatientTranscriptQuery } from '~/schemaTypes';

type ResolvedTag = NonNullable<
    GetResolvedTagsForPatientTranscriptQuery['getResolvedTagsForPatientTranscript']
>[0];

export const ResolvedTagEventCard = ({ resolvedTagEvent }: { resolvedTagEvent: ResolvedTag }) => {
    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <Typography variant="h6">Resolved Tag Event</Typography>
            <p className="m-0">{`${resolvedTagEvent?.tag?.name} resolved to ${resolvedTagEvent?.event?.data?.state}`}</p>
        </div>
    );
};
