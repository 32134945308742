import React, { useMemo, useState, useCallback } from 'react';
import { Grid, Dialog } from '@mui/material';
import {
    useConsultantsSettingsFetchAffiliateQuery,
    ConsultantsSettingsFetchAffiliateDocument,
    useConsultantsSettingsUpdateLactationConsultantsForAffiliateMutation,
} from '~/schemaTypes';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';
import LactationConsultantsList from '~/views/AffiliateCare/components/LactationConsultants/LactationConsultants';
import ConsultantForm, {
    FormMode,
    FormInput as LactationConsultantFormInput,
} from '~/views/AffiliateCare/components/ConsultantForm/ConsultantForm';
import { TriggerGlobalConfirm } from '~/state';
import Loading from '~/components/Loading/Loading';
import Layout from '../components/Layout';

const LactationConsultants = () => {
    const { userAffiliateId, id } = useAffiliateCareView();
    const [showConsultantForm, setShowConsultantForm] = useState(false);
    const [editableConsultant, setEditableConsultant] =
        useState<null | LactationConsultantFormInput>(null);
    const [editableConsultantIndex, setEditableConsultantIndex] = useState<null | number>(null);
    const { data, loading: fetchLoading } = useConsultantsSettingsFetchAffiliateQuery({
        variables: {
            input: {
                affiliateId: userAffiliateId,
            },
        },
    });

    const [updateConsultantsMutation, { loading: updateLoading }] =
        useConsultantsSettingsUpdateLactationConsultantsForAffiliateMutation({
            refetchQueries: [
                {
                    query: ConsultantsSettingsFetchAffiliateDocument,
                    variables: { input: { affiliateId: userAffiliateId } },
                },
            ],
            awaitRefetchQueries: true,
        });
    const consultants = useMemo(() => {
        if (data?.virtualCareAffiliateByAffiliateId?.data?.lactationConsultants) {
            return data.virtualCareAffiliateByAffiliateId.data.lactationConsultants.map(c => ({
                name: c.name || '',
                IBCLCNumber: c.IBCLCNumber || '',
                IBCLCExpiration: c.IBCLCExpiration || '',
                IBCLCCertification: c.IBCLCCertification || null,
                CAQHNumber: c.CAQHNumber || null,
                NPINumber: c.NPINumber || '',
                fileName: c.fileName || '',
                s3Link: c.s3Link || null,
                liabilityInsuranceDate: c.liabilityInsuranceDate || '',
            }));
        }
        return [];
    }, [data?.virtualCareAffiliateByAffiliateId?.data?.lactationConsultants]);
    const editHandler = useCallback(
        (index: number) => {
            const selectedConsultant = consultants[index];
            if (selectedConsultant) {
                setEditableConsultant(selectedConsultant);
                setEditableConsultantIndex(index);
                setShowConsultantForm(true);
            }
        },
        [consultants],
    );
    const updateConsultants = useCallback(
        (data: LactationConsultantFormInput[]) => {
            updateConsultantsMutation({
                variables: {
                    input: {
                        id,
                        data,
                    },
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id],
    );
    const deleteHandler = useCallback(
        (removeIndex: number) => {
            TriggerGlobalConfirm({
                open: true,
                message:
                    'Are you sure you want to delete? If you re-add the Lactation Consultant later, they will have to reviewed again.',
                callback: () => {
                    updateConsultants(consultants.filter((c, index) => index !== removeIndex));
                },
            });
        },
        [updateConsultants, consultants],
    );
    const addNewHandler = useCallback(() => {
        setEditableConsultant(null);
        setEditableConsultantIndex(null);
        setShowConsultantForm(true);
    }, []);
    const formCompleteCb = useCallback(
        (close: boolean, consultant?: LactationConsultantFormInput) => {
            if (consultant) {
                if (editableConsultantIndex !== null) {
                    updateConsultants(
                        consultants.reduce((acc, item, index) => {
                            if (index === editableConsultantIndex) {
                                acc.push(consultant);
                            } else {
                                acc.push(item);
                            }
                            return acc;
                        }, [] as LactationConsultantFormInput[]),
                    );
                } else {
                    updateConsultants([...consultants, consultant]);
                }
            }
            setShowConsultantForm(!close);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [editableConsultantIndex, consultants, updateConsultants],
    );
    const loading = updateLoading || fetchLoading;
    return (
        <>
            <Layout title="Lactation Consultants">
                {loading ? (
                    <Loading />
                ) : (
                    <Grid item xs={12}>
                        <LactationConsultantsList
                            addNewHandler={addNewHandler}
                            consultants={consultants}
                            editHandler={editHandler}
                            deleteHandler={deleteHandler}
                        />
                    </Grid>
                )}
            </Layout>
            <Dialog open={showConsultantForm} scroll="paper" fullWidth maxWidth="lg">
                <ConsultantForm
                    isEdit={!!editableConsultant}
                    onComplete={formCompleteCb}
                    data={editableConsultant}
                    mode={FormMode.Onboarding}
                />
            </Dialog>
        </>
    );
};

export default LactationConsultants;
