import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import ArticlePicker from '~/components/ArticlePicker/ArticlePicker';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { LocalizedStringTextFields } from '~/components/LocalizedStringTextFields/LocalizedStringTextFields';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import { CarePlanEnum } from '~/selectors';
import { OffsetType } from '~/enums/enums';
import { TimeUnit } from '~/schemaTypes';
import { FormTodoTemplate, RepeatIntervalUnit } from '../../CarePlan/types';
import { defaultFormTodo } from './helpers';
import { useStyles } from './styles';
import { CarePlanTodoModalPropsType } from './types';
import { CARE_PLAN_TODO_SCHEMA } from './yupSchema';

const Priorities = Array.from({ length: 10 }, (_, i) => i + 1);

const CarePlanTodoModal: React.FC<CarePlanTodoModalPropsType> = ({
    data,
    closeModal,
    onSubmit,
    timeline,
    prefetchData,
    isEditMode,
}) => {
    const { classes } = useStyles();
    const handleCancel = () => closeModal();
    const {
        control,
        register,
        watch,
        handleSubmit: handleFormSubmit,

        formState: { errors },
    } = useForm({
        resolver: yupResolver(CARE_PLAN_TODO_SCHEMA as any),
        defaultValues: data ?? defaultFormTodo(),
    });

    const watchedIsRecurring = watch('isRecurring');
    const [selectedArticleId, setSelectedArticleId] = useState(data?.articleId ?? '');
    const [articleRequired, setArticleRequired] = useState<boolean>(false);

    useEffect(() => {
        if (watchedIsRecurring) {
            control.unregister('durationValue');
        } else {
            control.unregister('repeatCount');
        }
    }, [control, watchedIsRecurring]);

    const handleSubmit: SubmitHandler<FormTodoTemplate> = form => {
        if (!selectedArticleId) {
            setArticleRequired(true);
            return;
        }

        onSubmit({ ...data, ...form, articleId: selectedArticleId });
        closeModal();
    };

    return (
        <form
            noValidate
            className={classes.root}
            onSubmit={handleFormSubmit(handleSubmit)}
            data-test={CarePlanEnum.CREATE_TODO_MODAL}
        >
            <DialogTitleWithClose id="form-dialog-title" onClose={handleCancel}>
                {isEditMode ? 'Edit' : 'Create'} Todo
            </DialogTitleWithClose>
            <DialogContent className="formCont">
                <Grid item xs={12}>
                    <ArticlePicker
                        selectedArticleId={selectedArticleId}
                        setSelectedArticleId={setSelectedArticleId}
                        required={articleRequired}
                        setRequired={setArticleRequired}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedSection title="Title">
                        <TextField
                            variant="outlined"
                            label="English *"
                            fullWidth
                            margin="dense"
                            {...register('title.en')}
                            error={Boolean(errors.title?.en)}
                            helperText={errors.title?.en?.message}
                            data-test={CarePlanEnum.PORTAL_LABEL}
                        />
                        <TextField
                            variant="outlined"
                            label="Spanish"
                            fullWidth
                            margin="dense"
                            {...register('title.es')}
                            error={Boolean(errors.title?.es)}
                            helperText={errors.title?.es?.message}
                            data-test={CarePlanEnum.PORTAL_LABEL}
                        />
                    </OutlinedSection>
                </Grid>
                <OutlinedSection className={classes.outlinedSection} title="Timing and Order">
                    <Grid item xs={12}>
                        <Controller
                            name="isRecurring"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <FormControlLabel
                                    label="Recurring Todo"
                                    control={
                                        <Checkbox
                                            checked={value}
                                            disabled
                                            onChange={e => onChange(e.target.checked)}
                                        />
                                    }
                                />
                            )}
                        />
                    </Grid>
                    {watchedIsRecurring ? (
                        <Grid item xs={12}>
                            <div className={classes.offsetWrap}>
                                <span className={classes.offsetLabel}>Show</span>
                                <ReactHookFormSelect
                                    style={{ width: '100px', marginRight: '10px' }}
                                    control={control}
                                    name="repeatIntervalUnit"
                                    variant="outlined"
                                    label="Time Unit"
                                    margin="dense"
                                    defaultValue={RepeatIntervalUnit.Daily}
                                >
                                    {Object.values(RepeatIntervalUnit).map(u => (
                                        <MenuItem key={u} value={u}>
                                            {u}
                                        </MenuItem>
                                    ))}
                                </ReactHookFormSelect>
                                <span className={classes.offsetLabel}>Starting</span>
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    label="Days *"
                                    margin="dense"
                                    {...register('offsetDays')}
                                    style={{ marginRight: '10px' }}
                                    defaultValue={0}
                                />
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    label="Months *"
                                    margin="dense"
                                    {...register('offsetMonths')}
                                    style={{ marginRight: '10px' }}
                                    defaultValue={0}
                                />
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    label="Years *"
                                    margin="dense"
                                    {...register('offsetYears')}
                                    style={{ marginRight: '10px' }}
                                    defaultValue={0}
                                />
                                <span className={classes.offsetLabel}>Days</span>
                                <ReactHookFormSelect
                                    style={{ width: '100px', marginRight: '10px' }}
                                    control={control}
                                    name="offsetType"
                                    variant="outlined"
                                    label="Offset Type"
                                    margin="dense"
                                    defaultValue={OffsetType.After}
                                >
                                    {Object.values(OffsetType).map(u => (
                                        <MenuItem key={u} value={u}>
                                            {u}
                                        </MenuItem>
                                    ))}
                                </ReactHookFormSelect>
                                <span className={classes.offsetLabel}>{timeline}</span>
                            </div>
                            <div className={classes.offsetWrap}>
                                {/*
                                 *  The below component is being used as a dummy field to ingest
                                 *  the value assignment for durationValue. Without this, the
                                 *  repeatCount field seems to be assigned to durationValue on
                                 *  submission instead. This behavior does not happen if the
                                 *  containing div above is removed, but the styling will not be correct.
                                 */}
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    {...register('durationValue')}
                                    style={{ display: 'none' }}
                                />
                                <span className={classes.offsetLabel}>
                                    Number Of Times To Be Shown
                                </span>
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    margin="dense"
                                    {...register('repeatCount')}
                                    defaultValue={0}
                                />
                            </div>
                            <ReactHookFormSelect
                                control={control}
                                name="priority"
                                variant="outlined"
                                label="Priority"
                                margin="dense"
                                fullWidth
                                defaultValue="5"
                            >
                                {Priorities.map(i => (
                                    <MenuItem key={i} value={i}>
                                        {i}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                            <Controller
                                name="isEssential"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <FormControlLabel
                                        label="Essential"
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={e => onChange(e.target.checked)}
                                            />
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <div className={classes.offsetWrap}>
                                <span className={classes.offsetLabel}>Complete</span>
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    label="Days *"
                                    margin="dense"
                                    {...register('offsetDays')}
                                    style={{ marginRight: '10px' }}
                                    defaultValue={0}
                                />
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    label="Months *"
                                    margin="dense"
                                    {...register('offsetMonths')}
                                    style={{ marginRight: '10px' }}
                                    defaultValue={0}
                                />
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    label="Years *"
                                    margin="dense"
                                    {...register('offsetYears')}
                                    style={{ marginRight: '10px' }}
                                    defaultValue={0}
                                />
                                <ReactHookFormSelect
                                    style={{ marginRight: '10px' }}
                                    control={control}
                                    name="offsetType"
                                    variant="outlined"
                                    label="Offset Type"
                                    margin="dense"
                                    size="small"
                                    defaultValue={OffsetType.After}
                                >
                                    {Object.values(OffsetType).map(u => (
                                        <MenuItem key={u} value={u}>
                                            {u}
                                        </MenuItem>
                                    ))}
                                </ReactHookFormSelect>
                                <span className={classes.offsetLabel}>{timeline}</span>
                            </div>
                            <div className={classes.offsetWrap}>
                                <span className={classes.offsetLabel}>Show</span>
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    margin="dense"
                                    {...register('durationValue')}
                                    style={{ marginRight: '10px' }}
                                    defaultValue={3}
                                />
                                <ReactHookFormSelect
                                    style={{ marginRight: '10px' }}
                                    control={control}
                                    name="durationUnit"
                                    variant="outlined"
                                    size="medium"
                                    label="Time Unit"
                                    margin="dense"
                                    defaultValue={TimeUnit.Days}
                                >
                                    {Object.values(TimeUnit).map(u => (
                                        <MenuItem key={u} value={u}>
                                            {u}
                                        </MenuItem>
                                    ))}
                                </ReactHookFormSelect>
                                <span className={classes.offsetLabel}>Before complete</span>
                            </div>
                            <Grid container item xs={12}>
                                <ReactHookFormSelect
                                    control={control}
                                    name="priority"
                                    variant="outlined"
                                    label="Priority"
                                    margin="dense"
                                    fullWidth
                                    defaultValue="5"
                                >
                                    {Priorities.map(i => (
                                        <MenuItem key={i} value={i}>
                                            {i}
                                        </MenuItem>
                                    ))}
                                </ReactHookFormSelect>
                            </Grid>
                            <Controller
                                name="isEssential"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <FormControlLabel
                                        label="Essential"
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={e => onChange(e.target.checked)}
                                            />
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    {prefetchData?.tags && (
                        <Grid style={{ marginTop: '10px' }} item xs={12}>
                            <AutocompleteWithRecordOptions
                                options={prefetchData?.tags ?? []}
                                valueKey="id"
                                labelKey="name"
                                control={control}
                                name="tags"
                                label="Tags"
                                placeholder="Select tags..."
                            />
                        </Grid>
                    )}
                </OutlinedSection>
                <OutlinedSection className={classes.outlinedSection} title="Notification">
                    <Grid item xs={12}>
                        <Controller
                            name="notificationData.shouldSendNotification"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <FormControlLabel
                                    label="Send Notification For Todo when Todo appears on list"
                                    control={
                                        <Checkbox
                                            checked={value}
                                            onChange={e => onChange(e.target.checked)}
                                        />
                                    }
                                />
                            )}
                        />
                        <LocalizedStringTextFields
                            name="notificationData"
                            label="Text"
                            register={register}
                        />
                    </Grid>
                </OutlinedSection>
            </DialogContent>
            <DialogActions>
                <div>
                    <Button onClick={handleCancel} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        data-test={CarePlanEnum.MODAL_SAVE}
                    >
                        Save
                    </Button>
                </div>
            </DialogActions>
        </form>
    );
};

export default CarePlanTodoModal;
