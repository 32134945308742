import { Typography } from '@mui/material';
import React from 'react';
import { GetExpressionEvaluatedForPatientTranscriptQuery } from '~/schemaTypes';

type ExpressionEvaluated = NonNullable<
    GetExpressionEvaluatedForPatientTranscriptQuery['getExpressionEvaluatedForPatientTranscript']
>[0];

export const ExpressionEvaluatedCard = ({ event }: { event: ExpressionEvaluated }) => {
    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <Typography variant="h6">Expression Evaluated</Typography>
            <p className="m-0">{`Expression: ${event?.expression?.name || 'Unknown'}`}</p>
            <p className="m-0">{`Description: ${event?.expression?.description || 'Unknown'}`}</p>
            <p className="m-0">{`State: ${event?.event?.data?.state || 'Unknown'}`}</p>
        </div>
    );
};
