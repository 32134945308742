import moment from 'moment';
import * as Yup from 'yup';
import { DATE_FORMAT } from './helpers';
import { RelationshipToInsuredEnum } from './types';

declare module 'yup' {
    interface DateSchema {
        dateFormatVCF(): this;
    }
    interface ValidateOptions {
        originalValue?: string;
    }
}

// eslint-disable-next-line func-names
Yup.addMethod<Yup.DateSchema>(Yup.date, 'dateFormatVCF', function (this: Yup.DateSchema) {
    return this.test(
        'date validation',
        'Invalid date',
        // eslint-disable-next-line func-names
        function (this, val) {
            if (!val) {
                return true;
            }
            const originalValue = this.options.originalValue || this.originalValue;
            return moment(originalValue, DATE_FORMAT, true).isValid();
        },
    );
});

const Schema = (isAffiliateView: boolean, selfCheck: boolean) => [
    Yup.object().shape({}),
    Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        birthDate: Yup.date()
            .typeError('Birth date is required')
            .dateFormatVCF()
            .required('Birth date is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        sex: Yup.string().required('Sex is required'),
        phoneNumber: Yup.string().when([], (_, schema) => {
            if (!selfCheck) {
                return schema.required('Phone number is required');
            }
            return schema.notRequired();
        }),
        insuranceId: Yup.string().required('Insurance ID is required'),
        insuranceGroup: Yup.string().required('Insurance group is required'),
        patientRelationshipToInsured: Yup.string().required(
            'Patient relationship to insured is required',
        ),
        insuredFirstName: Yup.string().when(
            'patientRelationshipToInsured',
            ([patientRelationshipToInsure], schema) => {
                if (patientRelationshipToInsure !== RelationshipToInsuredEnum.SELF) {
                    return schema.required('Insured first name is required');
                }
                return schema.notRequired();
            },
        ),
        insuredLastName: Yup.string().when(
            'patientRelationshipToInsured',
            ([patientRelationshipToInsure], schema) => {
                if (patientRelationshipToInsure !== RelationshipToInsuredEnum.SELF) {
                    return schema.required('Insured last name is required');
                }
                return schema.notRequired();
            },
        ),
        subscriberSex: Yup.string().when(
            'patientRelationshipToInsured',
            ([patientRelationshipToInsure], schema) => {
                if (patientRelationshipToInsure !== RelationshipToInsuredEnum.SELF) {
                    return schema.required('Subscriber Sex is required');
                }
                return schema.notRequired();
            },
        ),
        subscriberBirthDate: Yup.date()
            .typeError('Subscriber birth date is required')
            .when('patientRelationshipToInsured', ([patientRelationshipToInsure], schema) => {
                if (patientRelationshipToInsure !== RelationshipToInsuredEnum.SELF) {
                    return schema.dateFormatVCF().required('Subscriber birth date is required');
                }
                return schema.notRequired();
            }),
    }),
    Yup.object().shape({
        addressLine1: Yup.string().required('Address is required'),
        addressLine2: Yup.string(),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        zipCode: Yup.string()
            .required('Zip code is required')
            .matches(/^\d{5}(-\d{4})?$/, 'Please enter a valid zip code.'),
        insuredAddressSameAsPatient: Yup.boolean(),
        insuredAddressLine1: Yup.string().when(
            'insuredAddressSameAsPatient',
            ([insuredAddressSameAsPatient], schema) => {
                if (!insuredAddressSameAsPatient) {
                    return schema.required('Insured address is required');
                }
                return schema.notRequired();
            },
        ),
        insuredAddressLine2: Yup.string(),
        insuredCity: Yup.string().when(
            'insuredAddressSameAsPatient',
            ([insuredAddressSameAsPatient], schema) => {
                if (!insuredAddressSameAsPatient) {
                    return schema.required('Insured city is required');
                }
                return schema.notRequired();
            },
        ),
        insuredState: Yup.string().when(
            'insuredAddressSameAsPatient',
            ([insuredAddressSameAsPatient], schema) => {
                if (!insuredAddressSameAsPatient) {
                    return schema.required('Insured state is required');
                }
                return schema.notRequired();
            },
        ),
        insuredZipCode: Yup.string().when(
            'insuredAddressSameAsPatient',
            ([insuredAddressSameAsPatient], schema) => {
                if (!insuredAddressSameAsPatient) {
                    return schema
                        .required('Insured zip code is required')
                        .matches(/^\d{5}(-\d{4})?$/, 'Please enter a valid zip code.');
                }
                return schema.notRequired();
            },
        ),
        babyMultiples: Yup.boolean().required('Please select any option'),
        babyArrived: Yup.boolean().when([], (_, schema) => {
            if (!isAffiliateView && !selfCheck) {
                return schema.required('Please select any option');
            }
            return schema.notRequired();
        }),
        babyFirstName: Yup.string().when('babyArrived', ([babyArrived], schema) => {
            if (babyArrived && !isAffiliateView && !selfCheck) {
                return schema.required("Baby's First name is required");
            }
            return schema.notRequired();
        }),
        babyLastName: Yup.string().when('babyArrived', ([babyArrived], schema) => {
            if (babyArrived && !isAffiliateView && !selfCheck) {
                return schema.required("Baby's Last name is required");
            }
            return schema.notRequired();
        }),
        babyBirthDate: Yup.date().when('babyArrived', ([babyArrived], schema) => {
            if (babyArrived && !isAffiliateView && !selfCheck) {
                return schema.dateFormatVCF().required("Baby's Birth date is required");
            }
            return schema.notRequired();
        }),
        babySex: Yup.string().when('babyArrived', ([babyArrived], schema) => {
            if (babyArrived && !isAffiliateView && !selfCheck) {
                return schema.required('Assigned Sex At Birth is required');
            }
            return schema.notRequired();
        }),
        babyDueDate: Yup.date().when('babyArrived', ([babyArrived], schema) => {
            if (babyArrived === false && !isAffiliateView && !selfCheck) {
                return schema.dateFormatVCF().required('Expected Due Date is required');
            }
            return schema.notRequired();
        }),
        breastPump: Yup.boolean().when([], (_, schema) => {
            if (!selfCheck) {
                return schema.required('Please select any option');
            }
            return schema.notRequired();
        }),
    }),
    Yup.object().shape({
        appointmentMethod: Yup.string().required('Appointment method is required'),
    }),
    Yup.object().shape({}),
    Yup.object().shape({}),
];

export default Schema;
