import ObjectId from 'bson-objectid';
import { OffsetType } from '~/enums/enums';
import { TimeUnit } from '~/schemaTypes';
import { FormTodoTemplate, RepeatIntervalUnit } from '../../../CarePlan/types';
import { CarePlanTodoModalFormInput } from '../types';

export const defaultFormValues = (): Partial<CarePlanTodoModalFormInput> => ({});
export const defaultFormTodo = (): FormTodoTemplate => ({
    id: new ObjectId(),
    title: { en: '', es: '' },
    articleId: '',
    offsetDays: 0,
    offsetMonths: 0,
    offsetYears: 0,
    offsetType: OffsetType.After,
    durationValue: 3,
    durationUnit: TimeUnit.Days,
    isRecurring: false,
    repeatIntervalUnit: RepeatIntervalUnit.Daily,
    repeatCount: 0,
    priority: 5,
    isEssential: false,
    tags: [],
    notificationData: {
        shouldSendNotification: false,
        en: '',
        es: '',
    },
});
