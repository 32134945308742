import { Dialog, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { displayDateWithAbbrTimeZone } from '~/helpers/dateHelpers';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    ActionSource,
    FetchActionsForPatientActionsPageQueryDocument,
    FetchActionsForPatientActionsPageQueryQuery,
    FetchActionsForPatientActionsPageQueryQueryVariables,
    FetchAdvocateTaskForAdvocateTaskPageQuery,
    useCreatePatientActionForPatientActionPageMutation,
    useDeletePatientActionForPatientActionPageMutation,
    useFetchActionsForPatientActionsPageQueryQuery,
    useFetchPatientActionTypesForPatientActionsPageQuery,
    useUpdatePatientActionForPatientActionPageMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { PatientActionModal } from '~/views/ConfigDashboard/AdvocateTasks/components';
import { PatientActionModalSubmit } from '~/views/ConfigDashboard/AdvocateTasks/components/PatientActionModal/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useStyles } from './styles';

type IPatientAction = NonNullable<
    FetchActionsForPatientActionsPageQueryQuery['patientActionsV2']
>['results'][0];

type PatientAction = NonNullable<
    FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']
>['patientActions'][0];

const PatientActions: React.FC = () => {
    const { id: patientId } = useParams<{ id: string }>();
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPatientAction, setSelectedPatientAction] = useState<PatientAction | null>(null);

    const actionListQueryVariables: FetchActionsForPatientActionsPageQueryQueryVariables = {
        actionInput: {
            filter: {
                fields: {
                    patientId,
                },
            },
        },
    };

    const { data: actionsQueryData, loading: actionsLoading } =
        useFetchActionsForPatientActionsPageQueryQuery({
            variables: actionListQueryVariables,
        });

    const { data: patientActionTypesData, loading: actionTypesLoading } =
        useFetchPatientActionTypesForPatientActionsPageQuery();

    const onCloseModal = () => {
        setIsModalOpen(false);
        setSelectedPatientAction(null);
    };

    const [createPatientAction] = useCreatePatientActionForPatientActionPageMutation({
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    onCloseModal();
                },
                message: `There was a problem saving the patient action: ${error.message}`,
            });
        },
        onCompleted: onCloseModal,
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: FetchActionsForPatientActionsPageQueryDocument,
                variables: actionListQueryVariables,
            },
        ],
    });

    const [updatePatientAction] = useUpdatePatientActionForPatientActionPageMutation({
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    onCloseModal();
                },
                message: `There was a problem saving the patient action: ${error.message}`,
            });
        },
        onCompleted: onCloseModal,
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: FetchActionsForPatientActionsPageQueryDocument,
                variables: actionListQueryVariables,
            },
        ],
    });

    const [deletePatientAction] = useDeletePatientActionForPatientActionPageMutation({
        onCompleted: () => {
            onCloseModal();
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: FetchActionsForPatientActionsPageQueryDocument,
                variables: actionListQueryVariables,
            },
        ],
    });

    const handlePatientActionDelete = ({ id }: PatientAction) => {
        if (id) {
            deletePatientAction({ variables: { input: { id } } });
        }
    };

    const onModalSubmit: PatientActionModalSubmit = (patientAction, id) => {
        if (id) {
            updatePatientAction({
                variables: {
                    input: {
                        id,
                        data: {
                            ...patientAction,
                            patientId,
                            source: selectedPatientAction?.source,
                        },
                    },
                },
            });
        } else {
            createPatientAction({
                variables: {
                    input: { ...patientAction, patientId },
                },
            });
        }
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Actions"
                icons={tableIcons as MaterialTableProps<any>['icons']}
                isLoading={actionsLoading || actionTypesLoading}
                data={
                    _.cloneDeep(
                        _.sortBy(actionsQueryData?.patientActionsV2.results, 'updatedAt').reverse(),
                    ) ?? []
                }
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                }}
                localization={{ header: { actions: '' } }}
                actions={[
                    {
                        onClick: () => setIsModalOpen(true),
                        hidden: !pagePermissions?.PatientActions.Edit,
                        icon: () => <Add />,
                        tooltip: 'Create Action',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, patientAction: IPatientAction) => {
                            setSelectedPatientAction(patientAction);
                            setIsModalOpen(true);
                        },
                        hidden: !pagePermissions?.PatientActions.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Action',
                    },
                ]}
                columns={[
                    {
                        title: 'Action Type',
                        field: 'type.label',
                        align: 'center',
                    },
                    {
                        title: 'Note',
                        field: 'description',
                        align: 'center',
                    },
                    {
                        title: 'Completed Date/Time',
                        customSort: (a, b) =>
                            (new Date(a.completedAt ?? '')?.getTime() ?? 0) -
                            (new Date(b.completedAt ?? '')?.getTime() ?? 0),
                        render: ({ completedAt }) => (
                            <Typography>
                                {completedAt &&
                                    displayDateWithAbbrTimeZone({
                                        isoDateStr: completedAt,
                                        format: 'MMMM DD YYYY • hh:mm A',
                                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    })}
                            </Typography>
                        ),
                    },
                    {
                        title: 'Last Update',
                        defaultSort: 'desc',
                        customSort: (a, b) =>
                            (new Date(a.updatedAt ?? '')?.getTime() ?? 0) -
                            (new Date(b.updatedAt ?? '')?.getTime() ?? 0),
                        render: ({ updatedAt }) => (
                            <Typography>
                                {updatedAt &&
                                    displayDateWithAbbrTimeZone({
                                        isoDateStr: updatedAt,
                                        format: 'MMMM DD YYYY • hh:mm A',
                                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    })}
                            </Typography>
                        ),
                    },
                    {
                        title: 'Action Source',
                        field: 'source',
                        align: 'center',
                    },
                ]}
            />
            <Dialog
                scroll="paper"
                open={isModalOpen}
                fullWidth
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
            >
                <PatientActionModal
                    data={selectedPatientAction}
                    patientActionTypes={patientActionTypesData?.patientActionTypesV2.results ?? []}
                    closeModal={() => {
                        setIsModalOpen(false);
                        setSelectedPatientAction(null);
                    }}
                    onSubmit={onModalSubmit}
                    onDelete={handlePatientActionDelete}
                    isEditMode={Boolean(selectedPatientAction)}
                    actionSource={ActionSource.Manually}
                />
            </Dialog>
        </div>
    );
};

export default PatientActions;
