import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Card, Fade, Grid, Hidden, IconButton, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { InfoOutlined } from '@mui/icons-material';
import React, { useMemo } from 'react';
import Masonry from 'react-masonry-css';
import Loading from '~/components/Loading/Loading';
import { useFetchOrgDetailsQuery } from '~/schemaTypes';
import { useUserPermissions } from '../../../../../hooks';
import { OrganizationDetailsSections } from '../../OrganizationModal/validation';
import OrgInviteCodes from './OrgInviteCodes/OrgInviteCodes';
import OrgConfigOptions from './OrgConfigOptions/OrgConfigOptions';
import useVCEligibility from '../OrganizationVCF/useVCEligibility';
import { OrgTreeNode } from '../../../../../hooks/useOrgTree';
import OrgWaystarEligibilityConfigOptions from './OrgWaystarEligibilityConfigOptions/OrgWaystarEligibilityConfigOptions';

const useStyles = makeStyles()({
    root: {
        padding: 30,
    },
    masonryGrid: {
        display: 'flex',
        marginLeft: '-30px',
        width: 'auto',
    },
    masonryColumn: {
        paddingLeft: 30,
        backgroundClip: 'padding-box',
    },
    masonryCard: {
        width: '100%',
    },
});

type OrganizationDetailsProps = {
    orgNode: OrgTreeNode;
    level: number;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setEditOrgId: React.Dispatch<React.SetStateAction<string>>;
    setOrgDetailsSection: React.Dispatch<React.SetStateAction<string>>;
};

const OrganizationDetails: React.FC<OrganizationDetailsProps> = ({
    orgNode: {
        org: { id: orgId, ancestors: orgAncestors },
    },
    level,
    setEditOrgId,
    setModalOpen,
    setOrgDetailsSection,
}) => {
    const { classes } = useStyles();

    const { pagePermissions } = useUserPermissions();

    const { isVCOrg } = useVCEligibility({ ancestors: orgAncestors });

    const { data: orgData, loading: organizationDataLoading } = useFetchOrgDetailsQuery({
        variables: { input: { id: orgId } },
    });

    const breakpointCols = useMemo(
        () => ({
            default: 3,
            [1100 - level * 50]: 2,
            [800 - level * 50]: 1,
        }),
        [level],
    );

    if (organizationDataLoading) {
        return <Loading height={400} subtitle="Loading org details..." />;
    }

    return (
        <div className={classes.root}>
            <Masonry
                breakpointCols={breakpointCols}
                className={classes.masonryGrid}
                columnClassName={classes.masonryColumn}
            >
                <Fade in timeout={500}>
                    <Grid container>
                        <Typography paragraph variant="h6">
                            General Info:
                            <Tooltip title="Edit General Info">
                                <IconButton
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        setModalOpen(true);
                                        setEditOrgId(orgId);
                                        setOrgDetailsSection(
                                            OrganizationDetailsSections.GeneralInfo,
                                        );
                                    }}
                                    size="large"
                                >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                        <Card className={classes.masonryCard} elevation={0}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={7}>
                                    <Typography color="primary" gutterBottom variant="h6">
                                        Name:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {orgData?.organization?.name}
                                    </Typography>
                                </Grid>
                                <Hidden mdDown>
                                    <Grid item>
                                        <Avatar
                                            style={{ height: 75, width: 75 }}
                                            src={orgData?.organization?.logo ?? ''}
                                        />
                                    </Grid>
                                </Hidden>
                                <Grid item xs={12}>
                                    <Typography color="primary" gutterBottom variant="h6">
                                        Internal Id:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {orgData?.organization?.id}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="primary" gutterBottom variant="h6">
                                        External Id:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {orgData?.organization?.externalId}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="primary" gutterBottom variant="h6">
                                        Branding Name:{' '}
                                        <Tooltip title="This is the name that will appear in the app">
                                            <InfoOutlined color="secondary" />
                                        </Tooltip>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {orgData?.organization?.brandingName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="primary" gutterBottom variant="h6">
                                        Health Advocate Title:{' '}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {orgData?.organization?.healthAdvocateTitle}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="primary" gutterBottom variant="h6">
                                        Patient Creation:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {orgData?.organization?.allowPatientCreation
                                            ? 'Enabled'
                                            : 'Disabled'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="primary" gutterBottom variant="h6">
                                        Timezone:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {orgData?.organization?.timezone}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="primary" gutterBottom variant="h6">
                                        Email:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {orgData?.organization?.email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="primary" gutterBottom variant="h6">
                                        Conferencing App Name:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {orgData?.organization?.conferencingAppName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="primary" gutterBottom variant="h6">
                                        App Bundle ID:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {orgData?.organization?.appBundleId}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="primary" gutterBottom variant="h6">
                                        App Platform:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {orgData?.organization?.appPlatform}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Fade>
                <Grid container>
                    <Grid item xs={12}>
                        <OrgInviteCodes
                            orgId={orgData?.organization?.id ?? ''}
                            inviteCodes={orgData?.organization?.inviteCodes ?? []}
                        />
                    </Grid>
                    {pagePermissions?.OrgConfig.Edit && (
                        <Grid item xs={12}>
                            <OrgConfigOptions
                                orgId={orgData?.organization?.id ?? ''}
                                configOptions={orgData?.organization?.configOptions ?? {}}
                            />
                        </Grid>
                    )}
                    {isVCOrg && (
                        <Grid item xs={12}>
                            <OrgWaystarEligibilityConfigOptions
                                orgId={orgId}
                                configOptions={
                                    orgData?.organization?.waystarEligibilityConfig ?? null
                                }
                            />
                        </Grid>
                    )}
                </Grid>
            </Masonry>
        </div>
    );
};

export default OrganizationDetails;
