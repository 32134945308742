import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import {
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { VC_PARENT_ORG_ID } from '~/constants';
import {
    OrderByDirectionEnum,
    PortalVcUserType,
    useCreateAffiliateUserInAffiliateUsersModalMutation,
    useCreateDoulaUserInAffiliateUsersModalMutation,
    useFetchStaffByIdForStaffModalLazyQuery,
    useGetVendorByIdInAffiliateUsersModalLazyQuery,
    User,
    useStaffVirtualCareAffiliatessV2Query,
    useUpdateStaffInStaffModalMutation,
} from '~/schemaTypes';
import useUpdateCache from '../useUpdateCache';
import BillComSection from './BillCom';
import { AffiliateUserFormInput, VCUserType } from './types';
import { STAFF_VALIDATION_SCHEMA } from './yupSchema';

const useStyles = makeStyles()({
    root: {},
});

type AffiliateUserModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setEditStaffId: Dispatch<SetStateAction<string>>;
    id?: string;
    VCTypeFilter?: PortalVcUserType | '';
};

const VCAffiliateUserModal: React.FC<AffiliateUserModalProps> = ({
    setOpen,
    setEditStaffId,
    id,
    VCTypeFilter,
}) => {
    const { classes } = useStyles();
    const formMethods = useForm<AffiliateUserFormInput>({
        resolver: yupResolver(STAFF_VALIDATION_SCHEMA as any),
        defaultValues: {
            userType: VCUserType.Doula,
            createVendor: true,
            state: '',
        },
    });

    const {
        watch,
        reset,
        control,
        formState: { errors },
        handleSubmit,
        register,
        resetField,
    } = formMethods;

    const userType = watch('userType');

    const { data: affiliates } = useStaffVirtualCareAffiliatessV2Query({
        variables: {
            input: {
                orderBy: {
                    field: 'internalName',
                    order: OrderByDirectionEnum.Asc,
                },
            },
        },
    });

    const [getVendorInfo, { data: vendorData, loading: vendorDataLoading }] =
        useGetVendorByIdInAffiliateUsersModalLazyQuery({
            fetchPolicy: 'network-only',
        });

    useEffect(() => {
        if (vendorData?.getVendorById) {
            resetField('vendorId', { defaultValue: vendorData.getVendorById?.vendorId });
            resetField('nameOnCheck', {
                defaultValue: vendorData.getVendorById?.nameOnCheck || '',
            });
            resetField('address2', { defaultValue: vendorData.getVendorById?.address2 || '' });
            resetField('address1', { defaultValue: vendorData.getVendorById?.address1 || '' });
            resetField('city', { defaultValue: vendorData.getVendorById?.addressCity || '' });
            resetField('state', { defaultValue: vendorData.getVendorById?.addressState || '' });
            resetField('zip', { defaultValue: vendorData.getVendorById?.addressZip || '' });
            resetField('vendorName', { defaultValue: vendorData.getVendorById?.name || '' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendorData]);

    const [getStaff, { data: staffQuery, loading }] = useFetchStaffByIdForStaffModalLazyQuery({
        onCompleted: data => {
            if (data.user) {
                reset({
                    name: data.user.name,
                    email: data.user.email,
                    affiliateId: data.user.affiliateId,
                    userType: data.user.affiliateId ? VCUserType.Affiliate : VCUserType.Doula,
                    vendorId: data.user.billComVendorId,
                    state: '',
                });
                if (data.user.billComVendorId) {
                    getVendorInfo({
                        variables: {
                            input: {
                                id: data.user.billComVendorId,
                            },
                        },
                    });
                }
            }
        },
        fetchPolicy: 'network-only',
    });

    const affiliateOptions = useMemo(() => {
        const initialOption = {
            title: 'Select Affiliate',
            value: '',
        };
        if (affiliates?.virtualCareAffiliatessV2.results) {
            return [
                initialOption,
                ...affiliates.virtualCareAffiliatessV2.results.map(affiliate => ({
                    title: affiliate.internalName,
                    value: affiliate.id,
                })),
            ];
        }
        return [initialOption];
    }, [affiliates]);

    const [updateUser, { loading: updateUserLoading }] = useUpdateStaffInStaffModalMutation();
    const { updateAfterCreate } = useUpdateCache(VC_PARENT_ORG_ID, VCTypeFilter, true);
    const [createAffiliateUser, { loading: createAffiliateUserLoading }] =
        useCreateAffiliateUserInAffiliateUsersModalMutation({
            update: (cache, response) => {
                updateAfterCreate(
                    cache,
                    !!response.data?.createAffiliateUser?.success,
                    response.data?.createAffiliateUser?.user as User,
                );
            },
        });

    const [createDoulaUser, { loading: createDoulaUserLoading }] =
        useCreateDoulaUserInAffiliateUsersModalMutation({
            update: (cache, response) => {
                updateAfterCreate(
                    cache,
                    !!response.data?.createDoulaUser?.success,
                    response.data?.createDoulaUser?.user as User,
                );
            },
        });

    const onSubmit = ({
        email,
        name,
        affiliateId,
        userType,
        createVendor,
        vendorId,
        ...billComData
    }: AffiliateUserFormInput) => {
        if (id) {
            updateUser({
                variables: {
                    input: {
                        id,
                        data: {
                            name,
                            email,
                            ...(affiliateId && { affiliateId }),
                        },
                    },
                },
            });
        } else if (userType === VCUserType.Affiliate && affiliateId) {
            createAffiliateUser({
                variables: {
                    input: {
                        name,
                        email,
                        affiliateId,
                    },
                },
            });
        } else if (userType === VCUserType.Doula) {
            if (createVendor) {
                createDoulaUser({
                    variables: {
                        input: {
                            name,
                            email,
                            billComData: {
                                BillDotComNameOnCheck: billComData.nameOnCheck,
                                BillDotComAddress1: billComData.address1,
                                BillDotComAddress2: billComData.address2,
                                BillDotComAddressCity: billComData.city,
                                BillDotComAddressState: billComData.state,
                                BillDotComAddressZip: billComData.zip,
                                BillDotComName: billComData.vendorName,
                            },
                        },
                    },
                });
            } else {
                createDoulaUser({
                    variables: {
                        input: {
                            name,
                            email,
                            billComVendorId: vendorId,
                        },
                    },
                });
            }
        }
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
        setEditStaffId('');
    };
    useEffect(() => {
        if (id) {
            getStaff({
                variables: {
                    input: {
                        id,
                    },
                },
            });
        }
    }, [id, getStaff]);

    if (loading || updateUserLoading || createAffiliateUserLoading || createDoulaUserLoading) {
        return <Loading />;
    }

    return (
        <form className={classes.root} noValidate onSubmit={handleSubmit(onSubmit)}>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setOpen(false)}>
                {staffQuery?.user?.id === undefined ? 'New VC User' : 'Edit VC User'}
            </DialogTitleWithClose>
            <DialogContent>
                <FormProvider {...formMethods}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                error={!!errors.name}
                                {...register('name')}
                                label="Name"
                                id="name"
                                type="text"
                                margin="dense"
                                fullWidth
                                helperText={errors.name?.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Email"
                                id="email"
                                {...register('email')}
                                type="text"
                                margin="dense"
                                fullWidth
                                error={!!errors.email}
                                helperText={errors.email?.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="userType" shrink>
                                    Select Type of User
                                </InputLabel>
                                <Controller
                                    name="userType"
                                    control={control}
                                    render={({ field: { onChange, value, ...props } }) => (
                                        <Select
                                            labelId="userType"
                                            onChange={e => onChange(e)}
                                            error={!!errors.userType}
                                            value={value ?? ''}
                                            fullWidth
                                            disabled={!!id}
                                            {...props}
                                        >
                                            <MenuItem
                                                key={VCUserType.Doula}
                                                value={VCUserType.Doula}
                                            >
                                                Doula
                                            </MenuItem>
                                            <MenuItem
                                                key={VCUserType.Affiliate}
                                                value={VCUserType.Affiliate}
                                            >
                                                Affiliate
                                            </MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        {userType === VCUserType.Affiliate && (
                            <Grid item xs={12}>
                                <OutlinedSection title="Affiliate">
                                    <Controller
                                        name="affiliateId"
                                        control={control}
                                        render={({ field: { onChange, value, ...props } }) => (
                                            <Select
                                                id="affiliateId"
                                                onChange={e => onChange(e)}
                                                error={!!errors.affiliateId}
                                                value={value ?? ''}
                                                fullWidth
                                                displayEmpty
                                                {...props}
                                            >
                                                {affiliateOptions.map(affiliate => (
                                                    <MenuItem
                                                        key={affiliate.value}
                                                        value={affiliate.value}
                                                    >
                                                        {affiliate.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </OutlinedSection>
                            </Grid>
                        )}
                        {userType === VCUserType.Doula && (
                            <BillComSection isEdit={!!id} loading={vendorDataLoading} />
                        )}
                    </Grid>
                </FormProvider>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    startIcon={<SaveIcon />}
                    type="submit"
                    color="secondary"
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </form>
    );
};

export default VCAffiliateUserModal;
