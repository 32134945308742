import React, { useCallback, useEffect } from 'react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Paragraph from '@tiptap/extension-paragraph';
import { EditorContent, useEditor } from '@tiptap/react';
import { IconButton, Tooltip, FormControl, FormHelperText, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from 'tss-react/mui';
import { secondaryColor, primaryColor } from '~/theme/WildTheme';

export const useStyles = makeStyles()({
    editor: {
        '.tiptap': {
            height: 160,
            border: '1px solid black',
            borderRadius: 5,
            padding: 5,
            overflowY: 'auto',
            ':focus': {
                borderColor: secondaryColor,
                outlineColor: secondaryColor,
            },
            p: {
                margin: 0,
                'br:not(.ProseMirror-trailingBreak)': {
                    display: 'none',
                },
            },
        },
    },
    menu: {
        '.is-active': {
            borderColor: primaryColor,
            color: primaryColor,
        },
    },
});

type EditorProps = {
    content?: string;
    disabled?: boolean;
    setFormValue: (value: string) => void;
    error?: string;
};

const Editor: React.FC<EditorProps> = props => {
    const { content = '', disabled = false, setFormValue, error } = props;
    const { classes } = useStyles();
    const editor = useEditor({
        extensions: [
            Paragraph,
            StarterKit,
            Link.configure({
                openOnClick: true,
                autolink: true,
            }).extend({ inclusive: false }),
        ],
        content,
        editable: !disabled,
        onUpdate: ({ editor }) => {
            if (editor.isEmpty) {
                setFormValue('');
            } else {
                setFormValue(editor.getHTML());
            }
        },
    });
    const setLink = useCallback(() => {
        if (editor) {
            const previousUrl = editor.getAttributes('link').href;
            const url = (window as any).prompt('URL', previousUrl);

            // cancelled
            if (url === null) {
                return;
            }

            // empty
            if (url === '') {
                editor.chain().focus().extendMarkRange('link').unsetLink().run();

                return;
            }

            // update link
            editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
        }
    }, [editor]);
    useEffect(() => {
        if (editor) {
            setFormValue(content);
            editor.commands.setContent(content);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content, editor]);
    if (!editor) {
        return null;
    }
    return (
        <FormControl fullWidth>
            <Grid container className={classes.menu}>
                <Tooltip title="Add link">
                    <IconButton
                        type="button"
                        size="small"
                        onClick={setLink}
                        className={editor.isActive('link') ? 'is-active' : ''}
                    >
                        <FontAwesomeIcon icon={faLink} />
                    </IconButton>
                </Tooltip>
            </Grid>
            <EditorContent
                editor={editor}
                className={classes.editor}
                onClick={() => editor.chain().focus().run()}
            />
            {!!error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    );
};

export default Editor;
