import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { MassUpdateError } from '~/schemaTypes';

export const TaskErrorsModal: React.FC<{
    isOpen: boolean;
    errors: MassUpdateError[];
    onClose: () => void;
}> = props => {
    const { isOpen, errors, onClose } = props;

    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose id="dialogTitle" onClose={onClose}>
                Task Error List
            </DialogTitleWithClose>
            <DialogContent>
                <table>
                    <tbody>
                        <th>Patient Id</th>
                        <th>Error</th>
                        {errors.map(e => (
                            <tr>
                                <td>{e.patientId}</td>
                                <td>{e.errorText}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
