/* eslint-disable camelcase */
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DeleteOutline, InfoOutlined } from '@mui/icons-material';
import ObjectId from 'bson-objectid';
import { format } from 'date-fns';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import Draggable from '~/components/Draggable/Draggable';
import Droppable from '~/components/Droppable/Droppable';
import { FetchUserProfileDefinitionByIdQuery, UserProfileValueType } from '~/schemaTypes';
import { DropResult } from 'react-beautiful-dnd';
import setValueForKeyPath from '~/utils/setValueForKeyPath';
import { ProfileDefinitionFormInput } from '../ProfileDefsModal';

type Choices = NonNullable<
    NonNullable<NonNullable<FetchUserProfileDefinitionByIdQuery['userProfileDef']>['choices']>[0]
>;
type ChoiceLabel = NonNullable<Choices['label']>;
type ChoiceValue = NonNullable<Choices['value']>;
type ChoiceAnswerLabel = NonNullable<Choices['answerLabel']>;

export type ChoiceItemValueInputType = Omit<ChoiceValue, '__typename'>;

export type ChoiceItemLabelInputType = Omit<ChoiceLabel, '__typename'>;

export type ChoiceItemAnswerLabelInputType = Omit<ChoiceAnswerLabel, '__typename'>;

export type ChoiceItemInputType = Omit<
    Choices,
    '__typename' | 'value' | 'label' | 'answerLabel'
> & {
    value: ChoiceItemValueInputType;
    label: ChoiceItemLabelInputType;
    answerLabel: ChoiceItemAnswerLabelInputType;
};

export type CurrentChoiceValueType =
    | UserProfileValueType.Str
    | UserProfileValueType.Num
    | UserProfileValueType.Bool
    | UserProfileValueType.Date;

type ChoicesFormProps = {
    currentChoiceValueType: CurrentChoiceValueType;
    choices: ChoiceItemInputType[];
    formMethods: {
        register: UseFormRegister<ProfileDefinitionFormInput>;
        setValue: UseFormSetValue<ProfileDefinitionFormInput>;
    };
    name: keyof ProfileDefinitionFormInput;
};

type ChoiceItemProps = {
    tooltipTitle: string;
    appendDivider: boolean;
    items?: Array<{
        leftItem: ReactElement | null;
        rightItem: ReactElement | null;
    }>;
    onDeleteHandler: () => void;
    children?: React.ReactNode;
};

enum InputTypeEnum {
    number = 'number',
    text = 'text',
    date = 'datetime-local',
}

const ChoiceValueTypeToInputTypeMapper: Record<
    Exclude<CurrentChoiceValueType, UserProfileValueType.Bool>,
    InputTypeEnum
> = {
    [UserProfileValueType.Str]: InputTypeEnum.text,
    [UserProfileValueType.Num]: InputTypeEnum.number,
    [UserProfileValueType.Date]: InputTypeEnum.date,
};

const useStyles = makeStyles()(theme => ({
    slideIcon: {
        marginRight: theme.spacing(2),
        paddingLeft: theme.spacing(1),
    },
    leftSpacing: {
        paddingLeft: theme.spacing(1),
    },
}));

const reorder = (list: ChoiceItemInputType[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getChoiceItemLayout = (items: ChoiceItemProps['items']) =>
    items?.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid container key={index} item xs={12} alignItems="center">
            {item.leftItem && (
                <Grid item xs={index === items?.length - 1 ? 2 : 6}>
                    {item.leftItem}
                </Grid>
            )}

            {item.rightItem && (
                <Grid item xs>
                    {item.rightItem}
                </Grid>
            )}
        </Grid>
    ));

const ChoiceItem: React.FC<ChoiceItemProps> = ({
    children,
    tooltipTitle,
    appendDivider,
    items,
    onDeleteHandler,
}) => {
    const { classes } = useStyles();

    return (
        <>
            <Grid item container alignItems="center">
                <Grid item className={classes.slideIcon}>
                    <Tooltip title={tooltipTitle}>
                        <InfoOutlined />
                    </Tooltip>
                </Grid>
                <Grid item style={{ flex: 1 }}>
                    {items && <Grid container>{getChoiceItemLayout(items)}</Grid>}
                    {children}
                </Grid>
                <Grid item>
                    <IconButton onClick={onDeleteHandler} size="large">
                        <DeleteOutline />
                    </IconButton>
                </Grid>
            </Grid>
            {appendDivider && <Divider />}
        </>
    );
};

const ProfileDefsChoicesForm: React.FC<ChoicesFormProps> = ({
    currentChoiceValueType,
    choices,
    formMethods,
    name,
}) => {
    const { classes } = useStyles();

    const [choiceItems, setChoiceItems] = useState<ChoiceItemInputType[]>(choices || []);
    const [choiceValueType, setChoiceValueType] =
        useState<CurrentChoiceValueType>(currentChoiceValueType);

    useEffect(() => {
        formMethods.setValue(name, choiceItems);
    }, [choiceItems, name, formMethods]);
    formMethods.register(name);

    const handleChoiceItemsReset = useCallback(
        (choiceValueType: string | null) => {
            const updatedChoiceItems = choiceItems?.map(item => {
                return {
                    ...item,
                    value: {
                        bool:
                            !item.specifyValue && choiceValueType === UserProfileValueType.Bool
                                ? false
                                : null,
                        date: null,
                        num: null,
                        str: null,
                    },
                };
            });
            return setChoiceItems(updatedChoiceItems);
        },
        [choiceItems],
    );

    useEffect(() => {
        if (choiceValueType !== currentChoiceValueType) {
            setChoiceValueType(currentChoiceValueType);
            handleChoiceItemsReset(choiceValueType);
        }
    }, [choiceValueType, currentChoiceValueType, handleChoiceItemsReset]);

    const handleChoiceItemChange = useCallback(
        (choiceItemIndex: number) => (key: string, value: any) => {
            const updatedChoiceItems = choiceItems?.map((item, index: number) => {
                if (choiceItemIndex === index) {
                    if (key === 'specifyValue') {
                        return {
                            ...item,
                            ...setValueForKeyPath(key, value, item ?? {}),
                            value: {
                                bool:
                                    choiceValueType === UserProfileValueType.Bool && value === false
                                        ? false
                                        : null,
                                date: null,
                                num: null,
                                str: null,
                            },
                        };
                    }
                    return {
                        ...item,
                        ...setValueForKeyPath(key, value, item ?? {}),
                    };
                }
                return item;
            });
            setChoiceItems(updatedChoiceItems);
        },
        [choiceItems, choiceValueType],
    );

    const onDeleteHandler = useCallback(
        (index: number) => () => {
            const newChoiceItems = Array.from(choiceItems);
            newChoiceItems.splice(index, 1);

            setChoiceItems(newChoiceItems);
        },
        [choiceItems],
    );

    const handleNewChoice = useCallback(() => {
        const newChoice: ChoiceItemInputType = {
            id: new ObjectId().toHexString(),
            value: {
                bool: choiceValueType === UserProfileValueType.Bool ? false : null,
                date: null,
                num: null,
                str: null,
            },
            label: { en: '', es: null },
            specifyValue: false,
            answerLabel: { en: '', es: null },
            name: null,
        };
        setChoiceItems([newChoice, ...choiceItems]);
    }, [choiceItems, choiceValueType]);

    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) {
                return;
            }

            const reorderedChoiceItems = reorder(
                choiceItems,
                result.source.index,
                result.destination.index,
            );

            setChoiceItems(reorderedChoiceItems);
        },
        [choiceItems],
    );

    return (
        <>
            <Button onClick={handleNewChoice} color="primary" variant="contained">
                Add Choice
            </Button>

            <Droppable
                onDragEnd={onDragEnd}
                styles={{ maxHeight: '45vh', minHeight: '10rem' }}
                droppableId="choices"
            >
                {choiceItems?.map((item, index: number) => {
                    if (item.value) {
                        const appendDivider = index !== choiceItems.length - 1;

                        const choiceValueControllerElement =
                            UserProfileValueType.Bool === choiceValueType ? (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={item.value[choiceValueType] || false}
                                            color="primary"
                                            onChange={e =>
                                                handleChoiceItemChange(index)(
                                                    `value.${choiceValueType}`,
                                                    e.target.checked,
                                                )
                                            }
                                        />
                                    }
                                    label={item?.label?.en}
                                />
                            ) : (
                                <TextField
                                    variant="outlined"
                                    label="Value"
                                    value={
                                        // eslint-disable-next-line no-nested-ternary
                                        choiceValueType === UserProfileValueType.Date &&
                                        item.value[choiceValueType]
                                            ? format(
                                                  // eslint-disable-next-line
                                                  new Date(item.value[choiceValueType]!),
                                                  "yyyy-MM-dd'T'HH:mm",
                                              )
                                            : choiceValueType === UserProfileValueType.Num
                                            ? item.value.num
                                            : item.value.str ?? '' // for clearing input there's needed '' or undefined
                                    }
                                    type={ChoiceValueTypeToInputTypeMapper[choiceValueType]}
                                    margin="dense"
                                    fullWidth
                                    onChange={e => {
                                        handleChoiceItemChange(index)(
                                            `value.${choiceValueType}`,
                                            choiceValueType === UserProfileValueType.Num
                                                ? Number(e.target.value)
                                                : e.target.value,
                                        );
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            );

                        return (
                            <Draggable id={item.id} index={index} key={item.id}>
                                <ChoiceItem
                                    tooltipTitle={item.id}
                                    appendDivider={appendDivider}
                                    items={[
                                        {
                                            leftItem: (
                                                <TextField
                                                    variant="outlined"
                                                    label="Reporting Name"
                                                    defaultValue={item?.name ?? ''}
                                                    type={InputTypeEnum.text}
                                                    fullWidth
                                                    margin="dense"
                                                    onChange={e =>
                                                        handleChoiceItemChange(index)(
                                                            'name',
                                                            e.currentTarget.value,
                                                        )
                                                    }
                                                />
                                            ),
                                            rightItem: null,
                                        },
                                        {
                                            leftItem: (
                                                <TextField
                                                    variant="outlined"
                                                    label="Label En"
                                                    defaultValue={item?.label?.en}
                                                    type={InputTypeEnum.text}
                                                    fullWidth
                                                    margin="dense"
                                                    onChange={e =>
                                                        handleChoiceItemChange(index)(
                                                            'label.en',
                                                            e.currentTarget.value,
                                                        )
                                                    }
                                                />
                                            ),
                                            rightItem: (
                                                <TextField
                                                    variant="outlined"
                                                    label="Label Es"
                                                    defaultValue={item?.label?.es}
                                                    type={InputTypeEnum.text}
                                                    fullWidth
                                                    margin="dense"
                                                    onChange={e =>
                                                        handleChoiceItemChange(index)(
                                                            'label.es',
                                                            e.currentTarget.value,
                                                        )
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            leftItem: (
                                                <TextField
                                                    variant="outlined"
                                                    label="Answer Label En"
                                                    defaultValue={item?.answerLabel?.en}
                                                    type={InputTypeEnum.text}
                                                    fullWidth
                                                    margin="dense"
                                                    onChange={e =>
                                                        handleChoiceItemChange(index)(
                                                            'answerLabel.en',
                                                            e.currentTarget.value,
                                                        )
                                                    }
                                                />
                                            ),
                                            rightItem: (
                                                <TextField
                                                    variant="outlined"
                                                    label="Answer Label Es"
                                                    defaultValue={item?.answerLabel?.es}
                                                    type={InputTypeEnum.text}
                                                    fullWidth
                                                    margin="dense"
                                                    onChange={e =>
                                                        handleChoiceItemChange(index)(
                                                            'answerLabel.es',
                                                            e.currentTarget.value,
                                                        )
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            leftItem: !item.specifyValue
                                                ? choiceValueControllerElement
                                                : null,
                                            rightItem: (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={item.specifyValue}
                                                            onChange={() =>
                                                                handleChoiceItemChange(index)(
                                                                    'specifyValue',
                                                                    !item.specifyValue,
                                                                )
                                                            }
                                                            name="specifyValue"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="User Should Specify Value (Other)"
                                                    className={classes.leftSpacing}
                                                />
                                            ),
                                        },
                                    ]}
                                    onDeleteHandler={onDeleteHandler(index)}
                                />
                            </Draggable>
                        );
                    }
                    return null;
                })}
            </Droppable>
        </>
    );
};

export default ProfileDefsChoicesForm;
