import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import FileUpload, {
    generateFileFromString,
} from '~/views/AffiliateCare/components/FileUpload/FileUpload';
import { useGetAffiliateW9ByKeyLazyQuery, useUploadAffiliateW9Mutation } from '~/schemaTypes';

const W9 = () => {
    const { control, setValue } = useFormContext();
    const w9FileName = useWatch({
        name: 'w9FileName',
        control,
        exact: true,
    });
    const w9FS3Link = useWatch({
        name: 'w9FS3Link',
        control,
        exact: true,
    });
    const [showFileUploader, setShowFileUploader] = useState(!w9FS3Link);
    const [downloadFile, { data: s3File, loading: fileDownloadLoading }] =
        useGetAffiliateW9ByKeyLazyQuery({
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
        });
    useEffect(() => {
        if (w9FS3Link) {
            downloadFile({
                variables: {
                    input: {
                        key: w9FS3Link,
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [w9FS3Link]);
    const downloadedFile = useMemo(
        () =>
            generateFileFromString(
                s3File?.getAffiliateW9ByKey?.data as unknown as Buffer,
                w9FileName,
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [s3File?.getAffiliateW9ByKey?.filename, w9FileName],
    );
    useEffect(() => {
        if (downloadedFile) {
            setShowFileUploader(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downloadedFile]);
    const [fileUpload, { loading: fileUploadLoading }] = useUploadAffiliateW9Mutation({
        onCompleted: async data => {
            if (data.uploadAffiliateW9?.s3Link) {
                setValue('w9FS3Link', data.uploadAffiliateW9.s3Link);
            }
        },
    });
    const setFileName = useCallback((name: string, shouldValidate: boolean) => {
        setValue('w9FileName', name, {
            shouldValidate,
        });
        if (!name) {
            setValue('w9FS3Link', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onComplete = useCallback((data: string, name: string) => {
        if (data && name) {
            fileUpload({
                variables: {
                    input: {
                        file: data,
                        fileName: name,
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const showUploadedFile = !fileDownloadLoading && !fileUploadLoading && showFileUploader;
    return (
        <OutlinedSection title="W9">
            <FileUpload
                setValue={setFileName}
                fileData={downloadedFile}
                onComplete={onComplete}
                showPreview
                label="Upload W9"
                showFileUploader={showUploadedFile}
            />
        </OutlinedSection>
    );
};

export default W9;
