export enum CommonEnum {
    GLOBAL_ALERT_CONTAINER = 'global-alert-container',
    GLOBAL_DIALOG_CONTAINER = 'global-dialog-container',
    SIDEBAR_TRIGGER = 'sidebar-trigger',
    CONFIG_APPS_CONTAINER = 'config-apps-container',
    NAV_APPS = 'apps',
    GLOBAL_BANNER_CONTAINER = 'global-banner-container',
}

export const CommonSelectors = {
    NAV_APPS: `a[data-test="${CommonEnum.NAV_APPS}"]`,
    SIDEBAR_TRIGGER: `button[data-test="${CommonEnum.SIDEBAR_TRIGGER}"]`,
    SIDEBAR_PORTAL: 'a[data-test="Portal"]',
    SIDEBAR_CONFIG: 'a[data-test="Config"]',
    SUCCESS_MESSAGE: `div[data-test="${CommonEnum.GLOBAL_ALERT_CONTAINER}"] div[class="MuiAlert-message"]`,
    GLOBAL_ERROR: `div[data-test="${CommonEnum.GLOBAL_ALERT_CONTAINER}"] div.MuiAlert-standardError div[class="MuiAlert-message"]`,
    GLOBAL_SUCCESS: `div[data-test="${CommonEnum.GLOBAL_ALERT_CONTAINER}"] div.MuiAlert-standardSuccess div[class="MuiAlert-message"]`,
    DELETION_MODAL: `div[data-test="${CommonEnum.GLOBAL_DIALOG_CONTAINER}"] div[role="dialog"]`,
    CONFIRM_DELETION_BUTTON: `div[data-test="${CommonEnum.GLOBAL_DIALOG_CONTAINER}"] div[role="dialog"] button.MuiButton-containedPrimary`,
    GLOBAL_ALERT_CLOSE_BUTTON: `div[data-test="${CommonEnum.GLOBAL_ALERT_CONTAINER}"]  button[title="Close"]`,
    GLOBAL_ALERT: `div[data-test="${CommonEnum.GLOBAL_ALERT_CONTAINER}"]`,
    VIEW_APP_DETAILS_BUTTON: `div[data-test="${CommonEnum.CONFIG_APPS_CONTAINER}"] button[title="View App Details"]`,
    GLOBAL_BANNER_CLOSE_BUTTON: `div[data-test="${CommonEnum.GLOBAL_BANNER_CONTAINER}"]  button[title="Close"]`,
};
