import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Typography, Grid, Dialog } from '@mui/material';
import { useUpdateLactationConsultantsForAffiliateMutation, AlertSeverity } from '~/schemaTypes';
import ConsultantForm, {
    FormMode,
    FormInput as LactationConsultantFormInput,
} from '~/views/AffiliateCare/components/ConsultantForm/ConsultantForm';
import LactationConsultants from '~/views/AffiliateCare/components/LactationConsultants/LactationConsultants';
import { TriggerGlobalConfirm, SuppressNextGlobalAlert, TriggerGlobalAlert } from '~/state';
import { useOnboarding } from '../../useOboarding';

const Consultants: React.FC = () => {
    const { setIsValidStep, affiliate, setAffiliateData, setLoading, setStepCb } = useOnboarding();
    const [showConsultantForm, setShowConsultantForm] = useState(false);
    const [editableConsultant, setEditableConsultant] =
        useState<null | LactationConsultantFormInput>(null);
    const [editableConsultantIndex, setEditableConsultantIndex] = useState<null | number>(null);
    const consultants = useMemo(() => {
        if (affiliate?.lactationConsultants) {
            return affiliate.lactationConsultants;
        }
        return [];
    }, [affiliate]);
    React.useEffect(() => {
        setIsValidStep(!!consultants.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consultants]);
    const formCompleteCb = useCallback(
        (close: boolean, consultant?: LactationConsultantFormInput) => {
            if (consultant) {
                if (editableConsultantIndex !== null) {
                    setAffiliateData(prev => {
                        const newConsultants = prev.lactationConsultants.reduce(
                            (acc, item, index) => {
                                if (index === editableConsultantIndex) {
                                    acc.push(consultant);
                                } else {
                                    acc.push(item);
                                }
                                return acc;
                            },
                            [] as LactationConsultantFormInput[],
                        );
                        return {
                            ...prev,
                            lactationConsultants: [...newConsultants],
                        };
                    });
                } else {
                    setAffiliateData(prev => ({
                        ...prev,
                        lactationConsultants: [...prev.lactationConsultants, consultant],
                    }));
                }
            }
            setShowConsultantForm(!close);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [editableConsultantIndex],
    );
    const deleteHandler = useCallback((removeIndex: number) => {
        TriggerGlobalConfirm({
            open: true,
            callback: () => {
                setAffiliateData(prev => ({
                    ...prev,
                    lactationConsultants: prev?.lactationConsultants.filter(
                        (c, index) => index !== removeIndex,
                    ),
                }));
            },
            message: 'Are you sure you want to delete this Lactation Consultant?',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const editHandler = useCallback(
        (index: number) => {
            const selectedConsultant = affiliate?.lactationConsultants[index];
            if (selectedConsultant) {
                setEditableConsultant(selectedConsultant);
                setEditableConsultantIndex(index);
                setShowConsultantForm(true);
            }
        },
        [affiliate],
    );
    const addNewHandler = useCallback(() => {
        setEditableConsultant(null);
        setEditableConsultantIndex(null);
        setShowConsultantForm(true);
    }, []);
    const [updateConsultants] = useUpdateLactationConsultantsForAffiliateMutation();
    const updateConsultantsHandler = useCallback(async () => {
        if (!affiliate) {
            return false;
        }
        try {
            setLoading(true);
            SuppressNextGlobalAlert(true);
            const response = await updateConsultants({
                variables: {
                    input: {
                        id: affiliate.id,
                        data: affiliate.lactationConsultants,
                        onboarding: true,
                    },
                },
            });
            if (!response.data?.updateLactationConsultantsForAffiliate?.success) {
                SuppressNextGlobalAlert(false);
                throw new Error(response.data?.updateLactationConsultantsForAffiliate?.message);
            }
            return true;
        } catch (err) {
            if (err instanceof Error) {
                TriggerGlobalAlert({
                    message: err.message,
                    severity: AlertSeverity.Error,
                });
            }
            return false;
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [affiliate]);
    useEffect(
        () => {
            setStepCb(() => async () => updateConsultantsHandler());
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [updateConsultantsHandler],
    );
    return (
        <>
            <Grid container item xs={12} rowSpacing={5}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        Add information and documentation for all the Lactation Consultants (IBCLC)
                        that work for you
                    </Typography>
                </Grid>
                <LactationConsultants
                    addNewHandler={addNewHandler}
                    consultants={consultants}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                />
            </Grid>
            <Dialog open={showConsultantForm} scroll="paper" fullWidth maxWidth="lg">
                <ConsultantForm
                    isEdit={!!editableConsultant}
                    onComplete={formCompleteCb}
                    data={editableConsultant}
                    mode={FormMode.Onboarding}
                />
            </Dialog>
        </>
    );
};

export default Consultants;
