import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Grid, Divider, Dialog, Button, Link } from '@mui/material';
import { SuppressNextGlobalAlert, TriggerGlobalConfirm } from '~/state';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';
import useUser from '~/hooks/useUser';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import {
    useClaimsBuilderCreateAffiliateClaimMutation,
    useClaimsBuilderVcfSettingsQuery,
    useVirtualCareEligibilityWhitelistsV2LazyQuery,
    useCreateDoulaClaimFromBuilderMutation,
} from '~/schemaTypes';
import ErrorDialog from '~/views/AffiliateClaimSubmission/components/ErrorDialog';
import { ProcessingError } from '~/views/AffiliateClaimSubmission/types';
import Loading from '~/components/Loading/Loading';
import { TabsId } from '../Claims/Claims';
import PatientsSearch from './PatientsSearch';
import { SelectedPatient, Claim } from './types';
import AddPatientToClaimModal from './AddPatientToClaimModal';
import ClaimsToSubmit from './ClaimsToSubmit';
import { getDoulaClaimsPayload, getAffiliateClaimsPayload } from './helpers';

type ClaimsBuilderProps = {
    affiliateId?: string;
    portalView?: boolean;
    VCView?: boolean;
};

const ClaimsBuilder: React.FC<ClaimsBuilderProps> = props => {
    const { affiliateId = '', portalView = false, VCView = false } = props;
    const history = useNavigate();
    const { userAffiliateId } = useAffiliateCareView();
    const { data: userData } = useUser();
    const currentAffiliateId = portalView ? affiliateId : userAffiliateId;
    const [selectedPatient, setSelectedPatient] = useState<SelectedPatient | null>(null);
    const [showPatientModal, setShowPatientModal] = useState<boolean>(false);
    const [isSelectedPatientWhitelisted, setIsSelectedPatientWhitelisted] =
        useState<boolean>(false);
    const [claims, setClaims] = useState<Claim[]>([]);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
    const [processingError, setProcessingError] = useState<ProcessingError>();
    const selectPatientHandler = useCallback((patient: SelectedPatient) => {
        setSelectedPatient(patient);
        setShowPatientModal(true);
    }, []);
    const closePatientModalHandler = useCallback(() => {
        setShowPatientModal(false);
        setSelectedPatient(null);
    }, []);
    const addClaimHandler = useCallback((claim: Claim) => {
        setClaims(prev => [...prev, claim]);
        setShowPatientModal(false);
    }, []);
    const deleteClaimHandler = useCallback((id: number) => {
        setClaims(prev => prev.filter(c => c.id !== id));
    }, []);
    const [createClaims, { loading: generalClaimLoading }] =
        useClaimsBuilderCreateAffiliateClaimMutation({
            onCompleted: response => {
                if (response.createAffiliateClaimFromBuilder?.success) {
                    if (portalView) {
                        history(`/app-config/vcaffiliates/claims/${currentAffiliateId}`);
                    } else {
                        history('/affiliate-care-portal/claims', { state: TabsId.Patients });
                    }
                } else if (response.createAffiliateClaimFromBuilder?.error) {
                    setProcessingError(response.createAffiliateClaimFromBuilder.error);
                    setShowErrorDialog(true);
                }
            },
        });

    const [fetchWhitelist, { loading: whitelistLoading }] =
        useVirtualCareEligibilityWhitelistsV2LazyQuery({
            onCompleted: data => {
                const whitelists = data.virtualCareEligibilityWhitelistsV2.results;
                if (!whitelists || !whitelists.length) {
                    return;
                }
                const { firstName, lastName, memberId } = whitelists[0];
                if (firstName && lastName && memberId) {
                    setIsSelectedPatientWhitelisted(true);
                }
                SuppressNextGlobalAlert(true);
            },
        });

    const { data: vcfSettings, loading: vcfSettingsLoading } = useClaimsBuilderVcfSettingsQuery();

    useEffect(() => {
        if (selectedPatient) {
            setIsSelectedPatientWhitelisted(false);
            fetchWhitelist({
                variables: {
                    input: {
                        filter: {
                            fields: {
                                firstName: selectedPatient.firstName,
                                lastName: selectedPatient.lastName,
                                memberId: selectedPatient.insuranceId,
                            },
                        },
                        pagination: {
                            skip: 0,
                            limit: 1,
                        },
                    },
                },
            });
        }
    }, [fetchWhitelist, selectedPatient]);

    const [createDoulaClaims, { loading: createDoulaLoading }] =
        useCreateDoulaClaimFromBuilderMutation({
            onCompleted: response => {
                if (response.createDoulaClaimFromBuilder?.success) {
                    history(`/doula/portal/claims/${currentAffiliateId}`);
                } else if (response.createDoulaClaimFromBuilder?.error) {
                    setProcessingError(response.createDoulaClaimFromBuilder.error);
                    setShowErrorDialog(true);
                }
            },
        });
    const createClaimsHandler = () => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to submit these (${claims.length}) Claims for Reimbursement?`,
            callback: () => {
                if (VCView) {
                    const VCPayload = {
                        userId: userData?.currentUser?.id,
                        claims: getDoulaClaimsPayload(claims),
                    };
                    createDoulaClaims({
                        variables: {
                            input: VCPayload,
                        },
                    });
                } else {
                    const affiliatePayload = {
                        affiliateId: currentAffiliateId,
                        claims: getAffiliateClaimsPayload(claims),
                    };
                    createClaims({
                        variables: {
                            input: affiliatePayload,
                        },
                    });
                }
            },
        });
    };
    const loading = createDoulaLoading || generalClaimLoading;
    const showUploadBatchClaims = !(VCView || portalView);
    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12} container justifyContent="space-between">
                <Grid item>
                    <Typography variant="h6">Claim Builder Assistant</Typography>
                </Grid>
                {showUploadBatchClaims && (
                    <Grid item>
                        <Link variant="button" href="/affiliate-care-portal/claims/submit">
                            <Button variant="contained" color="primary">
                                Upload Batch Claims (Advanced)
                            </Button>
                        </Link>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} paddingBottom={3}>
                <Typography variant="body1">
                    Welcome to the Claim Builder Assistant! Use the search bar below to find your
                    patients by name and add the visit details to complete your claim. You can enter
                    multiple visits before submitting your batch of claims.
                </Typography>
            </Grid>
            <Grid item xs={8} minHeight={150}>
                <PatientsSearch
                    setSelectedPatient={selectPatientHandler}
                    affiliateId={currentAffiliateId}
                    portalView={portalView}
                    VCView={VCView}
                />
            </Grid>
            <Grid item xs={12} paddingBottom={4}>
                <ClaimsToSubmit claims={claims} handleDelete={deleteClaimHandler} />
            </Grid>
            <Grid item xs={12}>
                <AsyncActionButton loading={loading}>
                    <Button
                        onClick={createClaimsHandler}
                        variant="outlined"
                        disabled={!claims.length}
                    >
                        Submit Claims for Reimbursement
                    </Button>
                </AsyncActionButton>
            </Grid>
            {selectedPatient && (
                <Dialog open={showPatientModal}>
                    {whitelistLoading || vcfSettingsLoading ? (
                        <Loading />
                    ) : (
                        <AddPatientToClaimModal
                            onClose={closePatientModalHandler}
                            patient={selectedPatient}
                            addClaimHandler={addClaimHandler}
                            portalView={portalView}
                            currentAffiliateId={currentAffiliateId}
                            isPatientWhitelisted={isSelectedPatientWhitelisted}
                            allowNoInsuranceClaims={
                                vcfSettings?.VCFSettings?.waystarEligibilityConfig
                                    ?.allowNoInsuranceClaims
                            }
                            VCView={VCView}
                        />
                    )}
                </Dialog>
            )}
            <ErrorDialog
                open={showErrorDialog}
                onClose={() => setShowErrorDialog(false)}
                processingError={processingError}
            />
        </Grid>
    );
};

export default ClaimsBuilder;
