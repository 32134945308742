import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    root: {},
    overrideContactButton: {
        marginLeft: 10,
        marginBottom: 4,
    },
    overriddenContactWrapper: {
        marginLeft: 10,
        marginBottom: 14,
    },
    indent: {
        marginLeft: 10,
    },
    eligibilityRecheckDaysWidth: {
        width: 80,
    },
}));

export default useStyles;
