/* eslint-disable camelcase */
import { Button, Grid } from '@mui/material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { PAGESIZE } from '~/constants';
import { useEpisodesByConditionIdQuery } from '~/schemaTypes';
import { EpisodesHistoryModalDataType } from '../shared/types';
import useStyles from './styles';

interface PatientProfileVariableEpisodesModalProps {
    episodesHistoryData: EpisodesHistoryModalDataType;
    closeHandler: () => void;
}

// type EpisodesHistoryTableData = {
//     index: number;
//     beginDate: string | null;
//     endDate: string | null;
// };

const PatientProfileVariableEpisodesModal: React.FC<PatientProfileVariableEpisodesModalProps> = ({
    episodesHistoryData,
    closeHandler,
}) => {
    const { conditionId } = episodesHistoryData;
    const { classes } = useStyles();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    // const [tableData, setTableData] = useState<EpisodesHistoryTableData[]>([]);

    const { loading: isEpisodesLoading, data: episodes } = useEpisodesByConditionIdQuery({
        variables: { conditionId },
    });

    // useEffect(() => {
    //     setTableData(
    //         episodes?.proxiedEpisodesByConditionId?.map((ep, index) => ({ ...ep, index })) || [],
    //     );
    // }, [setTableData, episodes]);

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <Grid className={classes.root} ref={tableRef}>
            <MaterialTable
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                data={_.cloneDeep(
                    episodes?.proxiedEpisodesByConditionId?.map((ep, index) => ({
                        ...ep,
                        index,
                    })) || [],
                )}
                options={{
                    search: false,
                    paging: false,
                    pageSize: rowsPerPage,
                    toolbar: false,
                }}
                columns={[
                    { title: '#', render: rowData => rowData.index + 1 },
                    {
                        title: 'Start date',
                        field: 'beginDate',
                        searchable: false,
                        render: ({ beginDate }) => {
                            return beginDate ? new Date(beginDate).toLocaleString() : beginDate;
                        },
                    },
                    {
                        title: 'End Date',
                        field: 'endDate',
                        searchable: false,
                        render: ({ endDate }) => {
                            return endDate ? new Date(endDate).toLocaleString() : endDate;
                        },
                    },
                ]}
                isLoading={isEpisodesLoading}
            />

            <Grid className={classes.actions} item container justifyContent="flex-end">
                <Button color="primary" variant="contained" onClick={closeHandler}>
                    Close
                </Button>
            </Grid>
        </Grid>
    );
};

export default PatientProfileVariableEpisodesModal;
