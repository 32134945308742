import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
    root: {},
    fab: {
        position: 'absolute',
        top: theme.spacing(12),
        right: theme.spacing(4),
    },
    displayAs: {
        '& > span': {
            marginRight: 10,
        },
    },
    editButton: {
        marginLeft: 5,
    },
}));
