import { OrderByDirectionEnum } from '~/schemaTypes';

export enum OrdersSortingFields {
    procedure = 'procedure',
    patientFullName = 'patientFullName',
    transactionDateTime = 'transactionDateTime',
    isDeviceOrder = 'isDeviceOrder',
    createdAt = 'createdAt',
}

export interface IOrdersOrderChange {
    field: OrdersSortingFields;
    direction: OrderByDirectionEnum;
    isInit: boolean;
}
