import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ArticlePicker from '~/components/ArticlePicker/ArticlePicker';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { ExpressionUseType, SurveyDefContentInput } from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import ConfigExpressionPicker from '~/views/ConfigDashboard/ConfigExpressions/components/ConfigExpressionPicker';

interface PieceContentInputParms extends SurveyDefContentInput {
    divisionId: string;
    sectionId: string;
    pieceId?: string;
    expressionList?: (string | null)[] | null | undefined;
    articleId: string;
    submitHandler: (data: any) => void;
    deleteHandler: (divId: string, secId: string, pieceId: string) => void;
    closeHandler: () => void;
}

const PieceContentModal: React.FC<PieceContentInputParms> = props => {
    const { handleSubmit } = useForm<SurveyDefContentInput>({});
    const {
        divisionId,
        sectionId,
        pieceId,
        articleId,
        expressionList,
        submitHandler,
        closeHandler,
        deleteHandler,
    } = props;
    const [selectedExpressions, setSelectedExpressions] = useState<(string | null)[]>(
        expressionList ?? [],
    );
    const isEditMode = Boolean(articleId);
    const [selectedArticleId, setSelectedArticleId] = useState(articleId || '');
    const [selectedArticleName, setSelectedArticleName] = useState('');
    const [articleRequired, setArticleRequired] = useState<boolean>(false);

    const onSubmit = (nameVariables: any) => {
        if (!selectedArticleId) {
            setArticleRequired(true);
            return;
        }

        const data = {
            ...nameVariables,
            divisionId,
            sectionId,
            pieceId,
            selectedExpressions,
            articleId: selectedArticleId,
            articleName: selectedArticleName,
        };
        submitHandler(data);
    };
    const onDelete = () => {
        TriggerGlobalConfirm({
            message: 'Do you really want to delete this Content?',
            callback: () => {
                deleteHandler(divisionId, sectionId, pieceId ?? '');
            },
        });
    };
    const expressionAdded = (id: string) => {
        setSelectedExpressions(selectedExpressions?.concat(id));
    };
    const expressionDropped = (id: string) => {
        setSelectedExpressions(selectedExpressions?.filter(exp => exp !== id));
    };
    return (
        <Dialog open>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogTitleWithClose onClose={closeHandler} id="modalTitle">
                    {isEditMode ? 'Edit' : 'Add'} Content
                </DialogTitleWithClose>
                <DialogContent>
                    <ArticlePicker
                        selectedArticleId={selectedArticleId}
                        setSelectedArticleId={setSelectedArticleId}
                        setSelectedArticleName={setSelectedArticleName}
                        required={articleRequired}
                        setRequired={setArticleRequired}
                        compact
                    />
                    <ConfigExpressionPicker
                        inputList={selectedExpressions}
                        itemAddedHandler={expressionAdded}
                        itemDroppedHandler={expressionDropped}
                        useType={ExpressionUseType.Surveys}
                        canChange
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeHandler} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button type="submit" color="secondary" variant="contained">
                        {pieceId ? 'Update ' : 'Add '}
                        Content
                    </Button>{' '}
                    {pieceId && (
                        <Button color="primary" variant="contained" onClick={onDelete}>
                            Remove Content
                        </Button>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
};

export {};
export default PieceContentModal;
