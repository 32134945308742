/**
 *
 * @param pathToNeededProp - path to the needed object prop from the currentPropPath perspective - a string template like one we using for moving between files in console but with '&.' instead of '../'
 * @param currentPropPath - path to prop 'we are in' from object zero nesting level perspective
 * @returns path to the needed object prop from object zero nesting level perspective
 */
export const deriveNestedPropertyPath = (
    pathToNeededProp: string,
    currentPropPath: string,
): string => {
    const path: [number, string] = pathToNeededProp.split('.').reduce(
        (acc, cur) => {
            return cur === '&' ? [++acc[0], acc[1]] : [acc[0], `${acc[1]}.${cur}`];
        },
        [0, ''],
    );
    return `${currentPropPath.split('.').slice(0, -path[0]).join('.')}${path[1]}`;
};

/**
 * Replacing html tags in the text
 * @param stringText - text with html tags
 * @returns text without html tags
 */
export const replaceHTMLTags = (stringText: string) =>
    stringText.replace(/<[^>]+>/g, ' ').replace(/\s+/g, ' ');
