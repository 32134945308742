import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import moment from 'moment/moment';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography, Button, TablePagination } from '@mui/material';
import MaterialTable, { MaterialTableProps, MTableAction } from '@material-table/core';
import {
    GetMassUpdateTasksDocument,
    GetMassUpdateTasksQuery,
    MassUpdateError,
    MassUpdateTask,
    MassUpdateTaskProcess,
    MassUpdateTaskStatus,
    OrderByDirectionEnum,
    useDeleteMassUpdateTaskMutation,
    useGetMassUpdatePatientListQuery,
    useGetMassUpdateTasksLazyQuery,
    useSubmitMassUpdateTaskMutation,
} from '~/schemaTypes';
import tableIcons from '~/helpers/tableIcons';
import { PAGESIZE } from '~/constants';
import { useUserPermissions } from '~/hooks';
import { ArrowBack } from '@mui/icons-material';
import Loading from '~/components/Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faExclamationCircle,
    faEye,
    faPenToSquare,
    faRunning,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { TriggerGlobalConfirm } from '~/state';
import { TaskErrorsModal } from './components/TaskErrorsModal';

const typeToName = (type: MassUpdateTaskProcess): string => {
    switch (type) {
        case MassUpdateTaskProcess.DeletePatient:
            return 'Delete Patient';
        case MassUpdateTaskProcess.EndEpisode:
            return 'End Episode';
        case MassUpdateTaskProcess.ProfileValue:
            return 'Set Profile Value(s)';
        case MassUpdateTaskProcess.RebuildCache:
            return 'Rebuid Cache';
        case MassUpdateTaskProcess.RecalcTags:
            return 'Recalculate Tags';
        default:
            return 'Unknown';
    }
};

const MassUpdateTasks: React.FC = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const { id: massUpdatePatientsId } = useParams<{ id: string }>();
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [selectedErrors, setSelectedErrors] = useState<MassUpdateError[]>([]);
    const [page, setPage] = useState<number>(0);
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const { data, loading } = useGetMassUpdatePatientListQuery({
        variables: { input: { id: massUpdatePatientsId } },
    });
    const [getTasks, { data: tasks, loading: loadingTasks }] = useGetMassUpdateTasksLazyQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    });
    const fetchPVJobsPayload = useMemo(
        () => ({
            variables: {
                massUpdatePatientsId: massUpdatePatientsId || '',
                input: {
                    orderBy: {
                        field: 'createdAt',
                        order: OrderByDirectionEnum.Desc,
                    },
                    pagination: {
                        skip: page * rowsPerPage,
                        limit: rowsPerPage,
                    },
                },
            },
        }),
        [massUpdatePatientsId, page, rowsPerPage],
    );
    useEffect(() => {
        getTasks(fetchPVJobsPayload);
    }, [fetchPVJobsPayload, getTasks]);
    const handleChangeRowsPerPage = useCallback((pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChangePage = useCallback(
        (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
            event?.preventDefault();
            setPage(page);
            if (tableRef.current) {
                tableRef.current.scrollIntoView();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    const [submitTask] = useSubmitMassUpdateTaskMutation({
        refetchQueries: [
            {
                query: GetMassUpdateTasksDocument,
                variables: {
                    massUpdatePatientsId: massUpdatePatientsId || '',
                    input: {
                        orderBy: {
                            field: 'createdAt',
                            order: OrderByDirectionEnum.Desc,
                        },
                        pagination: {
                            skip: page * rowsPerPage,
                            limit: rowsPerPage,
                        },
                    },
                },
            },
        ],
    });

    const addNewItem = useCallback(() => {
        history(`/system-admin/massupdate/${massUpdatePatientsId}/tasks/new`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onNavigateAway = () => {
        history('/system-admin/massupdate/');
    };
    const handleSubmit = (id: string, status: MassUpdateTaskStatus) => {
        if (status === MassUpdateTaskStatus.InSetup) {
            TriggerGlobalConfirm({
                message: `Do you really want to submit this task'?`,
                callback: () => {
                    submitTask({
                        variables: {
                            taskId: id,
                        },
                    });
                },
            });
        }
    };
    const [deleteTask, { loading: deleteTaskLoading }] = useDeleteMassUpdateTaskMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: GetMassUpdateTasksDocument,
                variables: {
                    massUpdatePatientsId: massUpdatePatientsId || '',
                    input: {
                        orderBy: {
                            field: 'createdAt',
                            order: OrderByDirectionEnum.Desc,
                        },
                        pagination: {
                            skip: page * rowsPerPage,
                            limit: rowsPerPage,
                        },
                    },
                },
            },
        ],
    });

    const handleDelete = (id: string) => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: `Do you really want to delete this task'?`,
            callback: () => {
                deleteTask({ variables: { input: { id } } });
            },
        });
    };
    const handleErrorDialog = (errors: MassUpdateError[]) => {
        setSelectedErrors(errors);
        setShowErrorDialog(true);
    };
    if (loading || loadingTasks || deleteTaskLoading) {
        return <Loading />;
    }
    return (
        <div style={{ marginLeft: '15px' }} ref={tableRef}>
            <Grid item xs={12}>
                <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                    Back to {data?.massUpdatePatient?.name}
                </Button>
            </Grid>

            <Grid>
                <MaterialTable<GetMassUpdateTasksQuery['massUpdateTasksV2']['results'][0]>
                    icons={tableIcons as MaterialTableProps<any>['icons']}
                    columns={[
                        {
                            title: 'Type',
                            field: 'processType',
                            render: ({ processType }) => typeToName(processType),
                        },
                        {
                            title: 'Created At',
                            field: 'createdAt',
                            render: ({ createdAt }) => (
                                <Typography data-test={createdAt}>
                                    {moment(createdAt).format('MM-DD-YYYY')}
                                </Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            sorting: false,
                        },
                        {
                            title: 'Submitted By',
                            field: 'submittedByName',
                            render: ({ status, submittedByName }) => {
                                if (status !== MassUpdateTaskStatus.InSetup) {
                                    return <Typography>{submittedByName}</Typography>;
                                }
                                return <div />;
                            },
                            sorting: false,
                        },
                    ]}
                    title={`Mass Update Tasks for ${data?.massUpdatePatient?.name}`}
                    data={
                        tasks?.massUpdateTasksV2.results.map((o: any) => ({
                            ...o,
                        })) || []
                    }
                    options={{
                        search: false,
                        paginationPosition: 'bottom',
                        pageSize: rowsPerPage,
                        pageSizeOptions: [25, 50, 100],
                    }}
                    isLoading={loading}
                    actions={[
                        {
                            icon: 'Create Task',
                            isFreeAction: true,
                            onClick: addNewItem,
                            hidden: !pagePermissions?.MassUpdate.Edit,
                        },
                        (rowData: MassUpdateTask) => ({
                            onClick: (_, { id }: any) =>
                                history(
                                    `/system-admin/massupdate/${massUpdatePatientsId}/tasks/${id}`,
                                ),
                            hidden:
                                !pagePermissions?.MassUpdate.Edit ||
                                rowData.status !== MassUpdateTaskStatus.InSetup,
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit Task',
                        }),
                        (rowData: MassUpdateTask) => ({
                            onClick: (_, { id }: any) =>
                                history(
                                    `/system-admin/massupdate/${massUpdatePatientsId}/tasks/${id}`,
                                ),
                            hidden: rowData.status === MassUpdateTaskStatus.InSetup,
                            icon: () => <FontAwesomeIcon icon={faEye} />,
                            tooltip: 'View Task',
                        }),
                        (rowData: MassUpdateTask) => ({
                            onClick: (_, { id }: any) => handleDelete(id),
                            hidden:
                                !pagePermissions?.MassUpdate.Delete ||
                                rowData.status === MassUpdateTaskStatus.Running ||
                                rowData.status === MassUpdateTaskStatus.Submitted,
                            icon: () => <FontAwesomeIcon icon={faTrash} />,
                            tooltip: 'Delete Task',
                        }),
                        (rowData: MassUpdateTask) => ({
                            onClick: (_, { id, status }: any) => handleSubmit(id, status),
                            hidden:
                                !pagePermissions?.MassUpdate.Edit ||
                                rowData.status !== MassUpdateTaskStatus.InSetup,
                            icon: () => <FontAwesomeIcon icon={faRunning} />,
                            tooltip: 'Run Task',
                        }),
                        (rowData: MassUpdateTask) => ({
                            onClick: (_, { errors }: any) => handleErrorDialog(errors),
                            hidden:
                                !pagePermissions?.MassUpdate.Edit ||
                                rowData.status !== MassUpdateTaskStatus.Error,
                            icon: () => (
                                <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    style={{ color: 'red' }}
                                />
                            ),
                            tooltip: 'Show Errors',
                        }),
                    ]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    components={{
                        Action: props => {
                            const {
                                action: { isFreeAction, icon, onClick },
                            } = props;
                            if (isFreeAction) {
                                return (
                                    <Button variant="contained" onClick={onClick}>
                                        {icon}
                                    </Button>
                                );
                            }
                            return <MTableAction {...props} />;
                        },
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                count={tasks?.massUpdateTasksV2.total ?? 0}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        ),
                    }}
                />
            </Grid>
            <TaskErrorsModal
                isOpen={showErrorDialog}
                errors={selectedErrors}
                onClose={() => {
                    setShowErrorDialog(false);
                }}
            />
        </div>
    );
};

export default MassUpdateTasks;
