import React, { memo } from 'react';
import { Typography } from '@mui/material';
import { ClaimSubmittedVisitType } from '~/schemaTypes';

type ServiceTypeProps = {
    type?: string | null;
};

const getServiceName = (service: ClaimSubmittedVisitType | string | null) => {
    switch (service) {
        case ClaimSubmittedVisitType.Virtual:
        case ClaimSubmittedVisitType.Home:
        case ClaimSubmittedVisitType.Office:
        case ClaimSubmittedVisitType.Hospital:
            return service.charAt(0).toUpperCase() + service.slice(1);
        case ClaimSubmittedVisitType.Center:
            return 'Birthing Center';
        default:
            return service ? service.charAt(0).toUpperCase() + service.slice(1) : '';
    }
};

const ServiceType: React.FC<ServiceTypeProps> = memo(props => {
    const { type = '' } = props;
    return <Typography>{getServiceName(type)}</Typography>;
});

export default ServiceType;
