export enum ConditionTypeEnum {
    NAV_CONDITION_TYPES = 'condition-types',
    CONTAINER = 'condition-types-container',
    MODAL = 'condition-types-modal',
    NAME_EN = 'modal-name-en',
    NAME_ES = 'modal-name-es',
    SAVE = 'modal-save',
}

export const ConditionTypeSelectors = {
    NAV_CONDITION_TYPES: `a[data-test="${ConditionTypeEnum.NAV_CONDITION_TYPES}"]`,
    ADD_CONDITION_TYPE_BUTTON: `div[data-test="${ConditionTypeEnum.CONTAINER}"] button[title="Add Condition Type"]`,
    EDIT_CONDITION_TYPE_BUTTON: `div[data-test="${ConditionTypeEnum.CONTAINER}"] button[title="Edit Condition Type"]`,
    MODAL: `div[data-test="${ConditionTypeEnum.MODAL}"] div[role="dialog"]`,
    NAME_EN_INPUT: `div[data-test="${ConditionTypeEnum.NAME_EN}"] input`,
    NAME_ES_INPUT: `div[data-test="${ConditionTypeEnum.NAME_ES}"] input`,
    SAVE_BUTTON: `button[data-test="${ConditionTypeEnum.SAVE}"]`,
    SEARCH_INPUT: `div[data-test="${ConditionTypeEnum.CONTAINER}"] input[placeholder="Search"]`,
};
