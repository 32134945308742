import {
    Button,
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Search } from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
    AlertSeverity,
    CreateNewOrderMutation,
    Diagnoses,
    FetchPatientForCreateNewOrderQuery,
    OrderInput,
    OrderTypes,
    Patient,
    useCreateNewOrderMutation,
    useFetchPatientForCreateNewOrderLazyQuery,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import PatientSearchModal from '~/components/PatientsSearchModal';

import Loading from '~/components/Loading/Loading';
import DiagnosesModal from './DiagnosesModal';
import { NOTES } from './enums/NOTES';
import { STATUS } from './enums/STATUS';
import OrderModal from '../OrderModal/OrderModal';
import { RouteParams } from '../../Patients/Patient/PatientTodos/types';

export interface INewDiagnose {
    id: string;
    code: string;
    codeSet: string;
    type: string;
    name: string;
    dateTime: string;
}

interface INewOrderProps {
    patientId?: string | null;
}

// more of it can be added
const BGDiagnoses: Diagnoses[] = [
    {
        code: 'O24.419',
        codeset: 'ICD-10',
        name: 'Gestational Diabetes Mellitus',
    },
];

// more of it can be added
const BGCarePlanOptions: string[] = ['Check Sugars 4x/Day - Fasting and 2 Hours After Meals'];

const BPDiagnoses: Diagnoses[] = [
    {
        code: 'O10.9',
        codeset: 'ICD-10',
        name: 'Chronic Hypertension',
    },
    {
        code: 'O13.9',
        codeset: 'ICD-10',
        name: 'Gestational Hypertension',
    },
    {
        code: 'O14.9',
        codeset: 'ICD-10',
        name: 'Preeclampsia',
    },
    {
        code: 'O16.9',
        codeset: 'ICD-10',
        name: 'Unspecified Maternal Hypertension',
    },
    {
        code: 'O12.1',
        codeset: 'ICD-10',
        name: 'Proteinuria',
    },
    {
        code: 'R03.0',
        codeset: 'ICD-10',
        name: 'Elevated BP reading, without diagnosis of hypertension',
    },
    {
        code: 'O09.9',
        codeset: 'ICD-10',
        name: 'Supervision of High Risk Pregnancy ',
    },
];

const BPCarePlanOptions: string[] = [
    'Take BP Daily',
    'Take BP 2x / Day',
    'Take BP 3x / Day',
    'Take BP Weekly',
    'Take BP 2x / Week',
    'Take BP 3x / Week',
];
const SDohDiagnoses: Diagnoses[] = [
    {
        name: 'Food Insecurity',
        codeset: 'ICD-10-CM',
        code: 'Z59.4',
    },
    {
        name: 'Housing Insecurity/Instability',
        codeset: 'ICD-10-CM',
        code: 'Z59.0',
    },
    {
        name: 'Transportation Challenges',
        codeset: 'ICD-10-CM',
        code: 'Z75.3',
    },
    {
        name: 'Utility Needs',
        codeset: 'ICD-10-CM',
        code: 'Z59.1',
    },
    {
        name: 'Interpersonal Violence',
        codeset: 'ICD-10-CM',
        code: 'Z63.0',
    },
    {
        name: 'Childcare Needs',
        codeset: 'ICD-10-CM',
        code: 'Z62.29',
    },
    {
        name: 'Financial Insecurity',
        codeset: 'ICD-10-CM',
        code: 'Z59.7',
    },
    {
        name: 'Employment Issues',
        codeset: 'ICD-10-CM',
        code: 'Z56.9',
    },
];

const AdvocacyReferralOptions = [
    'SDoH Need',
    'Healthcare/Health Plan Navigation',
    'Maternity Leave/Return to Work Prep',
    'Mental Health Care Coordination',
] as const;

const CoachingReferralOptions = [
    'Condition Coaching for High Risk Diagnoses',
    'Sleep Hygiene',
    'Nutrition Support',
    'Lifestyle or Behavior Modification Support',
] as const;

const useStyles = () =>
    makeStyles()(theme => ({
        nestedItem: {
            paddingLeft: theme.spacing(2),
        },
        titleWrapper: {
            fontSize: '20px',
            marginTop: '1em',
            marginBottom: '1.5em',
        },
        radio: {
            flexBasis: '50',
        },
        searchPatient: {
            marginRight: theme.spacing(1),
            paddingLeft: theme.spacing(4),
            marginBottom: theme.spacing(2),
        },
        selectedPatient: {
            color: 'gray',
            border: '1px solid gray',
            borderRadius: '3px',
            padding: '5px',
            boxSizing: 'border-box',
            fontWeight: 600,
            marginBottom: '1.5em',
        },
    }));

const BP_CUF_DESCRIPTION = 'Blood Pressure Cuff';
const GLUCOMETER_DESCRIPTION = 'Glucometer';

const CreateNewOrder: React.FC<INewOrderProps> = () => {
    const history = useNavigate();

    const [showPatientSearchModal, setShowPatientSearchModal] = useState<boolean>(false);
    const [showDiagnosesModal, setShowDiagnosesModal] = useState<boolean>(false);

    const [patientsList, setPatientsList] = useState<Patient[]>([]);
    const [totalPatientsCount, setTotalPatientsCount] = useState<number>(0);
    const [patientsNotFound, setPatientsNotFound] = useState<boolean>(false);
    const [createdOrderData, setCreatedOrderData] = useState<CreateNewOrderMutation | null>(null);

    const [selectedPatient, setSelectedPatient] = useState<
        FetchPatientForCreateNewOrderQuery['patient'] | null
    >(null);
    const [isBpCuffDeviceChecked, setIsBpCuffDeviceChecked] = useState<boolean>(false);
    const [isGlucometerDeviceChecked, setIsGlucometerDeviceChecked] = useState<boolean>(false);
    const [isAdvocacyReferralChecked, setIsAdvocacyReferralChecked] = useState<boolean>(false);
    const [isCoachingReferralChecked, setIsCoachingReferralChecked] = useState<boolean>(false);
    const [BPCarePlan, setBPCarePlan] = useState<string>();
    const [BGCarePlan, setBGCarePlan] = useState<string>();
    const [diagnoses, setDiagnoses] = useState<Diagnoses[]>([]);
    const [orderType, setOrderType] = useState<OrderTypes>(OrderTypes.Device);
    const [SDoHNeed, setSDoHNeed] = useState<boolean>(false);
    const [otherSDoHValue, setOtherSDoHValue] = useState<string>('');
    const [otherCoachingValue, setOtherCoachingValue] = useState<string>('');
    const [advocacyReferalSelected, setAdvocacyReferalSelected] = useState<
        (typeof AdvocacyReferralOptions)[number][]
    >([]);
    const [coachingReferalSelected, setCoachingReferalSelected] = useState<string[]>([]);

    const [fetchPatientData, { loading: patientDataLoading }] =
        useFetchPatientForCreateNewOrderLazyQuery({
            onCompleted: data => {
                if (data.patient) {
                    setSelectedPatient(data.patient);
                }
            },
        });
    const { id: patientId } = useParams<RouteParams>();

    useEffect(() => {
        if (patientId) {
            fetchPatientData({
                variables: {
                    input: {
                        id: patientId,
                    },
                },
            });
        }
    }, [fetchPatientData, patientId]);

    const { classes } = useStyles()();

    const orderForm = useForm<OrderInput>({});
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = orderForm;

    const resetFullState = () => {
        setPatientsList([]);
        setTotalPatientsCount(0);
        setPatientsNotFound(false);
        setSelectedPatient(null);
        setIsBpCuffDeviceChecked(false);
        setIsGlucometerDeviceChecked(false);
        setDiagnoses([]);
        setBPCarePlan(undefined);
        setBGCarePlan(undefined);
        setSDoHNeed(false);
        setOtherSDoHValue('');
        setOtherCoachingValue('');
        setAdvocacyReferalSelected([]);
        setCoachingReferalSelected([]);
    };

    const resetOrderState = () => {
        setIsBpCuffDeviceChecked(false);
        setIsGlucometerDeviceChecked(false);
        setDiagnoses([]);
        setBPCarePlan(undefined);
        setBGCarePlan(undefined);
        setSDoHNeed(false);
        setOtherSDoHValue('');
        setOtherCoachingValue('');
        setAdvocacyReferalSelected([]);
        setCoachingReferalSelected([]);
    };

    const [createNewOrder, { loading: createNewOrderLoading }] = useCreateNewOrderMutation({
        onCompleted: data => {
            setCreatedOrderData(data);
            resetFullState();
            reset();
            TriggerGlobalAlert({
                severity: AlertSeverity.Success,
                message: 'Order has been created',
            });
        },
        onError: error => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: error.message,
            });
        },
    });

    useEffect(() => {
        if (!showPatientSearchModal) {
            setPatientsList([]);
        }
    }, [showPatientSearchModal]);

    const addNewDiagnose = (diagnose: Diagnoses) => {
        setDiagnoses(prev => [...prev, diagnose]);
    };

    const deleteDiagnose = (diagnoseCode: string) => {
        setDiagnoses(diagnoses => diagnoses.filter(diagnose => diagnose.code !== diagnoseCode));
    };

    const onSubmit = ({
        providerNPI,
        providerFirstName,
        providerLastName,
        transactionDateTime,
    }: OrderInput) => {
        if (!selectedPatient) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'Please select a patient',
            });
            return;
        }
        const notes = [
            isBpCuffDeviceChecked ? NOTES.BP_CUFF : null,
            isGlucometerDeviceChecked ? NOTES.GLUCOMETER : null,
            ...advocacyReferalSelected,
            ...coachingReferalSelected,
            BPCarePlan ?? null,
            BGCarePlan ?? null,
        ].filter(note => note !== null);

        const mappedDiagnoses = diagnoses.length
            ? diagnoses.map(diagnose => ({
                  ...diagnose,
                  documentedDateTime: new Date().toISOString(),
              }))
            : [];
        let procedureDescription = '';
        switch (orderType) {
            case OrderTypes.Referral:
                procedureDescription = isCoachingReferralChecked ? 'Coaching' : 'Advocacy referral';
                break;
            case OrderTypes.Device:
                if (isBpCuffDeviceChecked && isGlucometerDeviceChecked) {
                    procedureDescription = `${BP_CUF_DESCRIPTION}, ${GLUCOMETER_DESCRIPTION}`;
                } else {
                    procedureDescription = isBpCuffDeviceChecked
                        ? BP_CUF_DESCRIPTION
                        : GLUCOMETER_DESCRIPTION;
                }
                break;
            default:
                break;
        }
        const input = {
            type: orderType,
            patientId: selectedPatient?.id,
            status: STATUS.NEW,
            isDeviceOrder: orderType === OrderTypes.Device,
            providerNPI,
            providerFirstName,
            providerLastName,
            procedure: '',
            procedureDescription,
            notes,
            diagnoses: mappedDiagnoses,
            transactionDateTime,
        };
        createNewOrder({
            variables: {
                input,
            },
        });
    };

    const checkCoachingRiskSelected = (
        option: (typeof CoachingReferralOptions)[number],
    ): boolean => {
        if (coachingReferalSelected.includes(option)) {
            return true;
        }
        const withCustomText = coachingReferalSelected.filter(value => value.includes(option));
        if (withCustomText.length > 0) {
            return true;
        }

        return false;
    };

    const handleShowPatientSearchModal = () => {
        setShowPatientSearchModal(true);
    };
    const closeCreatedOrderModal = () => {
        setCreatedOrderData(null);
        history(patientId ? `/portal/patients/${patientId}/orders` : '/portal/orders');
    };
    return (
        <>
            <Grid container>
                <Typography className={classes.titleWrapper} paragraph variant="h2">
                    Add New Order
                </Typography>
                <Grid container>
                    {!patientId && (
                        <Grid item md={2} xs={6}>
                            <Grid container alignItems="center">
                                <Grid item className={classes.searchPatient}>
                                    <Typography>Patient Name</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={handleShowPatientSearchModal} size="large">
                                        <Search />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        {selectedPatient && (
                            <Typography className={classes.selectedPatient}>
                                {selectedPatient.firstName} {selectedPatient.lastName}{' '}
                                {moment(selectedPatient.birthDate).format('(MM-DD-YYYY)')}
                            </Typography>
                        )}
                        {patientDataLoading && <Loading subtitle="Loading patient data..." />}
                    </Grid>
                    <Grid item md={4} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    {/* todo: default to current date time */}
                    <Controller
                        control={control}
                        name="transactionDateTime"
                        defaultValue={new Date() as any}
                        render={({ field }) => (
                            <DateTimePicker
                                {...field}
                                label="Prescribe Date and Time"
                                ampm={false}
                                format="MM/dd/yyyy hh:mm"
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={6} xs={12}>
                    <TextField
                        variant="outlined"
                        id="ProviderFirstName"
                        label="Provider First Name"
                        type="text"
                        margin="dense"
                        fullWidth
                        error={!!errors.providerFirstName}
                        helperText={errors.providerFirstName?.message}
                        {...register('providerFirstName')}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        variant="outlined"
                        id="ProviderLastName"
                        label="Provider Last Name"
                        type="text"
                        margin="dense"
                        fullWidth
                        error={!!errors.providerLastName}
                        helperText={errors.providerLastName?.message}
                        {...register('providerLastName')}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={classes.titleWrapper} paragraph variant="h3">
                        Order Type
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <Controller
                            control={control}
                            name="type"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    variant="outlined"
                                    defaultValue={OrderTypes.Device}
                                    onChange={({ target }) => {
                                        resetOrderState();
                                        setOrderType(target.value as OrderTypes);
                                    }}
                                >
                                    {Object.values(OrderTypes).map(type => (
                                        <MenuItem key={type} value={type}>
                                            {type}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            {/* device type section */}
            {orderType === OrderTypes.Device && (
                <>
                    <Grid container>
                        <Typography className={classes.titleWrapper} paragraph variant="h2">
                            Device Type
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                label="Glucometer Order"
                                labelPlacement="end"
                                control={
                                    <Checkbox
                                        id="isGlucometerOrderChecked"
                                        name="isGlucometerOrderChecked"
                                        checked={isGlucometerDeviceChecked}
                                        onChange={e =>
                                            setIsGlucometerDeviceChecked(e.target.checked)
                                        }
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                label="BP Cuff Order"
                                labelPlacement="end"
                                control={
                                    <Checkbox
                                        id="isBpCuffOrder"
                                        name="isBpCuffOrder"
                                        checked={isBpCuffDeviceChecked}
                                        onChange={e => setIsBpCuffDeviceChecked(e.target.checked)}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    {isGlucometerDeviceChecked && (
                        <>
                            <Grid container>
                                <Typography className={classes.titleWrapper} paragraph variant="h4">
                                    Glucometer Diagnoses Options
                                </Typography>
                            </Grid>
                            <Grid container>
                                {BGDiagnoses.map(diagnose => (
                                    <Grid item xs={6} md={3} key={diagnose.code}>
                                        <FormControlLabel
                                            label={diagnose.name}
                                            labelPlacement="end"
                                            control={
                                                <Checkbox
                                                    id={diagnose.code ?? ''}
                                                    checked={diagnoses.some(
                                                        d => d.code === diagnose.code,
                                                    )}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            addNewDiagnose(diagnose);
                                                        } else {
                                                            deleteDiagnose(diagnose.code ?? '');
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid container>
                                <Typography className={classes.titleWrapper} paragraph variant="h4">
                                    Glucose Care Plan Options
                                </Typography>
                            </Grid>
                            <Grid container>
                                <RadioGroup row>
                                    {BGCarePlanOptions.map(option => (
                                        <Grid item xs={6} md={4} key={option}>
                                            <FormControlLabel
                                                label={option}
                                                labelPlacement="end"
                                                className={classes.radio}
                                                checked={BGCarePlan === option}
                                                control={
                                                    <Radio
                                                        onChange={() => {
                                                            setBGCarePlan(option);
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    ))}
                                </RadioGroup>
                            </Grid>
                        </>
                    )}
                    {isBpCuffDeviceChecked && (
                        <>
                            <Grid container>
                                <Typography className={classes.titleWrapper} paragraph variant="h4">
                                    BP Diagnoses Options
                                </Typography>
                            </Grid>
                            <Grid container>
                                {BPDiagnoses.map(diagnose => (
                                    <Grid item xs={6} md={3} key={diagnose.code}>
                                        <FormControlLabel
                                            label={diagnose.name}
                                            labelPlacement="end"
                                            control={
                                                <Checkbox
                                                    id={diagnose.code ?? ''}
                                                    checked={diagnoses.some(
                                                        d => d.code === diagnose.code,
                                                    )}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            addNewDiagnose(diagnose);
                                                        } else {
                                                            deleteDiagnose(diagnose.code ?? '');
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid container>
                                <Typography className={classes.titleWrapper} paragraph variant="h3">
                                    BP Care Plan Options (select only one)
                                </Typography>
                            </Grid>
                            <Grid container>
                                <RadioGroup row>
                                    {BPCarePlanOptions.map(option => (
                                        <Grid item xs={6} md={4} key={option}>
                                            <FormControlLabel
                                                label={option}
                                                labelPlacement="end"
                                                checked={BPCarePlan === option}
                                                control={
                                                    <Radio
                                                        onChange={() => {
                                                            setBPCarePlan(option);
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    ))}
                                </RadioGroup>
                            </Grid>
                        </>
                    )}
                </>
            )}
            {/* end of device type section */}
            {/* Advocacy Referral section */}
            {orderType === OrderTypes.Referral && (
                <>
                    <Grid container>
                        <Typography className={classes.titleWrapper} paragraph variant="h2">
                            Referral Type
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                label="Advocacy Referral"
                                labelPlacement="end"
                                control={
                                    <Checkbox
                                        id="isAdvocacyReferralChecked"
                                        name="isAdvocacyReferralChecked"
                                        checked={isAdvocacyReferralChecked}
                                        onChange={e =>
                                            setIsAdvocacyReferralChecked(e.target.checked)
                                        }
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                label="Coaching Referral"
                                labelPlacement="end"
                                control={
                                    <Checkbox
                                        id="isBpCuffOrder"
                                        name="isBpCuffOrder"
                                        checked={isCoachingReferralChecked}
                                        onChange={e =>
                                            setIsCoachingReferralChecked(e.target.checked)
                                        }
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </>
            )}
            {orderType === OrderTypes.Referral && isAdvocacyReferralChecked && (
                <>
                    <Grid container>
                        <Typography className={classes.titleWrapper} paragraph variant="h2">
                            Advocacy Referral
                        </Typography>
                        <Typography className={classes.titleWrapper} paragraph variant="h3">
                            Advocacy Referral Options
                        </Typography>
                    </Grid>
                    <Grid container>
                        {AdvocacyReferralOptions.map(option => (
                            <Grid item xs={12} key={option}>
                                <FormControlLabel
                                    label={option}
                                    labelPlacement="end"
                                    control={
                                        <Checkbox
                                            id={option}
                                            checked={advocacyReferalSelected.includes(option)}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    // 'SDoH Need' is a special case
                                                    if (option === AdvocacyReferralOptions[0]) {
                                                        setSDoHNeed(true);
                                                    }
                                                    setAdvocacyReferalSelected(old => [
                                                        ...old,
                                                        option,
                                                    ]);
                                                } else {
                                                    if (option === AdvocacyReferralOptions[0]) {
                                                        setSDoHNeed(false);
                                                    }
                                                    setAdvocacyReferalSelected(old =>
                                                        old.filter(item => item !== option),
                                                    );
                                                }
                                            }}
                                        />
                                    }
                                />
                                {option === AdvocacyReferralOptions[0] && SDoHNeed && (
                                    <Grid className={classes.nestedItem} container>
                                        {SDohDiagnoses.map(diagnose => (
                                            <Grid item xs={6} key={diagnose.name}>
                                                <FormControlLabel
                                                    label={diagnose.name}
                                                    labelPlacement="end"
                                                    control={
                                                        <Checkbox
                                                            id={diagnose.code ?? ''}
                                                            checked={diagnoses.some(
                                                                d => d.code === diagnose.code,
                                                            )}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    addNewDiagnose(diagnose);
                                                                } else {
                                                                    deleteDiagnose(
                                                                        diagnose.code ?? '',
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        ))}
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                label="Other"
                                                labelPlacement="end"
                                                control={
                                                    <Checkbox
                                                        id="other"
                                                        checked={diagnoses.some(
                                                            d => d.code === 'Z60.9',
                                                        )}
                                                        onChange={e => {
                                                            if (e.target.checked) {
                                                                addNewDiagnose({
                                                                    name: `Other: ${otherSDoHValue}`,
                                                                    codeset: 'ICD-10-CM',
                                                                    code: 'Z60.9',
                                                                });
                                                            } else {
                                                                deleteDiagnose('Z60.9');
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                            <TextField
                                                id="outlined-multiline-static"
                                                label=""
                                                multiline
                                                variant="outlined"
                                                onChange={e => {
                                                    setOtherSDoHValue(e.target.value);
                                                    deleteDiagnose('Z60.9');
                                                    addNewDiagnose({
                                                        name: `Other: ${e.target.value}`,
                                                        codeset: 'ICD-10-CM',
                                                        code: 'Z60.9',
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
            {/* end of Advocacy Referral section */}
            {orderType === OrderTypes.Referral && isCoachingReferralChecked && (
                <>
                    <Grid container>
                        <Typography className={classes.titleWrapper} paragraph variant="h2">
                            Coaching Referral
                        </Typography>
                        <Typography className={classes.titleWrapper} paragraph variant="h3">
                            Coaching Referral Options
                        </Typography>
                    </Grid>
                    <Grid container>
                        {CoachingReferralOptions.map(option => (
                            <Grid item xs={12} key={option}>
                                <FormControlLabel
                                    label={option}
                                    labelPlacement="start"
                                    control={
                                        <Checkbox
                                            id={option}
                                            checked={checkCoachingRiskSelected(option)}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    let newValue: string = option;
                                                    if (option === CoachingReferralOptions[0]) {
                                                        newValue = `${option}: ${otherCoachingValue}`;
                                                    }
                                                    setCoachingReferalSelected(old => [
                                                        ...old,
                                                        newValue,
                                                    ]);
                                                } else {
                                                    setCoachingReferalSelected(old =>
                                                        old.filter(item => !item.includes(option)),
                                                    );
                                                }
                                            }}
                                        />
                                    }
                                />
                                {option === CoachingReferralOptions[0] && (
                                    <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        variant="outlined"
                                        value={otherCoachingValue}
                                        onChange={e => {
                                            const newValue = `${option}: ${e.target.value}`;
                                            setCoachingReferalSelected(old =>
                                                old.filter(
                                                    item =>
                                                        !item.includes(option) && item !== newValue,
                                                ),
                                            );
                                            setCoachingReferalSelected(old => [...old, newValue]);
                                            setOtherCoachingValue(e.target.value);
                                        }}
                                    />
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
            {/* end of Coaching Referral section */}
            <Grid container>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <FormControl variant="outlined">
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={createNewOrderLoading}
                        >
                            Create
                        </Button>
                    </FormControl>
                </form>
            </Grid>
            <Dialog scroll="paper" open={showPatientSearchModal} fullWidth>
                <PatientSearchModal
                    patientsList={patientsList}
                    totalPatientsCount={totalPatientsCount}
                    selectedPatient={(selectedPatient as Patient) ?? null}
                    setPatientsList={setPatientsList}
                    setShowPatientSearchModal={setShowPatientSearchModal}
                    setTotalPatientsCount={setTotalPatientsCount}
                    setSelectedPatient={setSelectedPatient}
                    patientsNotFound={patientsNotFound}
                    setPatientsNotFound={setPatientsNotFound}
                />
            </Dialog>
            <Dialog scroll="paper" open={showDiagnosesModal} fullWidth>
                <DiagnosesModal
                    setShowDiagnosesModal={setShowDiagnosesModal}
                    addNewDiagnose={addNewDiagnose}
                />
            </Dialog>
            {createdOrderData && (
                <Dialog scroll="paper" open={Boolean(createdOrderData)} fullWidth maxWidth="lg">
                    <OrderModal
                        setOpen={closeCreatedOrderModal}
                        orderId={createdOrderData?.createOrder?.resourceCreated?.id}
                    />
                </Dialog>
            )}
        </>
    );
};

export default CreateNewOrder;
