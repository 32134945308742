import ObjectId from 'bson-objectid';
import {
    AdvocateTaskChecklistItemInput,
    AdvocateTaskInput,
    AdvocateTaskTypeStatus,
} from '~/schemaTypes';
import { toBase64 } from '~/helpers/base64Helper';
import { createHistoryItem } from '../../AdvocateTask/History/helpers';
import { AdvocateTaskChecklistItem, AdvocateTaskFormInput } from '../types';

const toChecklistInput = (
    checklist: AdvocateTaskChecklistItem[],
): AdvocateTaskChecklistItemInput[] =>
    checklist.map(checklistItem => ({
        id: checklistItem.id || new ObjectId().toHexString(),
        label: toBase64(checklistItem.label.en) ?? '',
        description: toBase64(checklistItem.description ?? ''),
        actionTypeIds: checklistItem.actionTypeIds,
    }));

export const toRequestInput = (
    form: AdvocateTaskFormInput,
    checklist: AdvocateTaskChecklistItem[],
    currentUserId: string,
    isTestData: boolean,
): AdvocateTaskInput => {
    const { label, description, priority, typeId, dueDate, displayDate, patientId, assignedTo } =
        form;

    return {
        label,
        description: toBase64(description) ?? '',
        priority,
        checklist: toChecklistInput(checklist),
        typeId,
        status: AdvocateTaskTypeStatus.Open,
        comments: [],
        history: [createHistoryItem(currentUserId, 'created the Task')],
        dueDate,
        displayDate,
        patientId,
        assignedTo,
        createdBy: currentUserId,
        watchedBy: [],
        isTestData,
    };
};
