import { FormControl, FormControlProps, InputLabel, Select } from '@mui/material';
import React from 'react';
import { Control, Controller, ControllerProps } from 'react-hook-form';

type ReactHookFormSelectProps = {
    name: string;
    label: string;
    control: Control<any>;
    defaultValue?: string;
    rules?: ControllerProps<any>['rules'];
    onChangeHandler?: (value: unknown) => void;
    disabled?: boolean;
    loading?: boolean;
    loadingText?: string;
};

const ReactHookFormSelect: React.FC<ReactHookFormSelectProps & FormControlProps> = ({
    name,
    label,
    control,
    defaultValue,
    children,
    rules = {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChangeHandler = () => {},
    loading = false,
    loadingText = 'Loading...',
    disabled = false,
    ...props
}) => {
    const labelId = `${name}-label`;
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <FormControl variant="outlined" {...props}>
            <InputLabel id={labelId} htmlFor={name}>
                {label}
            </InputLabel>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                render={({ field: { onChange, value, ref, name: fName } }) => (
                    <Select
                        variant="outlined"
                        labelId={labelId}
                        name={fName}
                        label={label}
                        inputProps={{ id: name }}
                        onChange={event => {
                            onChange(event);
                            onChangeHandler(event.target.value);
                        }}
                        ref={ref}
                        value={value}
                        disabled={disabled}
                    >
                        {loading && (
                            <div style={{ padding: '10px', opacity: 0.8, fontSize: '16px' }}>
                                {loadingText}
                            </div>
                        )}
                        {!loading && children}
                    </Select>
                )}
            />
        </FormControl>
    );
};
export default ReactHookFormSelect;
