import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    helperMessage: {
        margin: '-10px 0 0 24px',
    },
    actionBtns: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '20px 10px 0 0',
        '& > *': {
            marginLeft: '10px',
        },
    },
}));

export default useStyles;
