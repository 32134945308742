import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import { PAGESIZE } from '~/constants';
import { displayDate } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    Tag,
    TagListDocument,
    TagUsageType,
    useDeleteTagMutation,
    useTagListQuery,
    useTagWhereUsedLazyQuery,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';

const useStyles = makeStyles()({
    root: {},
});
const Tags: React.FC = () => {
    const { classes } = useStyles();

    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [selectedTag, setSelectedTag] = useState<Tag>();
    const [showUsageModal, setShowUsageModal] = useState(false);
    const { data: tagData, loading: tagsLoading } = useTagListQuery({
        variables: { tagListInput: [TagUsageType.Filtering, TagUsageType.PatientRiskScore] },
    });
    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        useTagWhereUsedLazyQuery();
    const [deleteTag, { loading: deleteTagLoading }] = useDeleteTagMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: TagListDocument,
                variables: {
                    tagListInput: [TagUsageType.Filtering, TagUsageType.PatientRiskScore],
                },
            },
        ],
    });
    const handleDelete = () => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${selectedTag?.name}'?`,
            callback: () => {
                deleteTag({ variables: { input: { id: selectedTag?.id, isTrigger: false } } });
                setShowUsageModal(false);
            },
        });
    };
    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleShowUsage = (item: Tag) => {
        whereUsed({
            variables: {
                input: {
                    id: item?.id,
                    isTrigger: false,
                },
            },
        });
        setShowUsageModal(true);
    };

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Tags"
                icons={tableIcons}
                isLoading={tagsLoading || deleteTagLoading || whereUsedLoading}
                data={_.cloneDeep(tagData?.getTagListByUsageTypes) ?? []}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                actions={[
                    {
                        onClick: () => history('/app-config/tags/new'),
                        hidden: !pagePermissions?.Tags.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Tag',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => history(`/app-config/tags/${id}`),
                        hidden: !pagePermissions?.Tags.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Tag',
                    },
                    {
                        onClick: (_e, item) => {
                            setSelectedTag(item as Tag);
                            handleShowUsage(item as Tag);
                        },
                        icon: () => <FontAwesomeIcon icon={faEye} />,
                        tooltip: 'View References',
                    },
                ]}
                columns={[
                    { title: 'Name', field: 'name', defaultSort: 'asc' },
                    { title: 'Expression', field: 'expression.name' },
                    { title: 'Apps', field: 'appInfo' },
                    {
                        title: 'Last Updated',
                        field: 'updatedAt',
                        render: ({ updatedAt }) => (
                            <>
                                {displayDate({
                                    isoDateStr: updatedAt,
                                })}
                            </>
                        ),
                    },
                    {
                        title: 'is Patient Filter',
                        field: 'isPatientFilter',
                        render: ({ isPatientFilter }) => (
                            <Typography>{`${isPatientFilter}`}</Typography>
                        ),
                    },
                    {
                        title: 'Usage',
                        field: 'usageType',
                    },
                    {
                        title: 'Risk Points',
                        field: 'patientRiskScorePoints',
                    },
                ]}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100] }}
                localization={{ header: { actions: '' } }}
            />
            <Dialog scroll="paper" open={!whereUsedLoading && showUsageModal}>
                <WhereUsedModal
                    onClose={() => setShowUsageModal(false)}
                    onDelete={handleDelete}
                    title={`Usage for ${selectedTag?.name}`}
                    canDelete={pagePermissions?.Tags.Delete || false}
                    usageData={whereUsedData?.tagWhereUsed?.dependencies || []}
                    permissions={pagePermissions}
                />
            </Dialog>
        </div>
    );
};

export default Tags;
