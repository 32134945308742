import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import Loading from '~/components/Loading/Loading';
import { OnboardingStatus, useFetchAffiliateLazyQuery } from '~/schemaTypes';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';
import { SuppressNextGlobalAlert } from '~/state';
import Header from './components/Header';
import RoutingStep from './steps/Routing/Routing';
import PaymentStep from './steps/Payment/Payment';
import ConsultantsStep from './steps/Consultants/Consultants';
import ServiceTypesAndLocationsStep from './steps/ServiceTypesAndLocations/ServiceTypesAndLocations';
import Progress from './components/Progress';
import Footer from './components/Footer';
import { Steps, FormInput } from './types';
import { useStyles } from './styles';
import { OnboardingContext, DEFAULT_STEP_CB } from './useOboarding';
import { mapDataToFormInput } from './mappers';

const onboardingStatusToStep = (status?: OnboardingStatus): Steps => {
    if (status === OnboardingStatus.Started) {
        return Steps.Consultants;
    }
    if (status === OnboardingStatus.FirstStepCompleted) {
        return Steps.Payment;
    }
    if (status === OnboardingStatus.SecondStepCompleted) {
        return Steps.ServiceAndLocations;
    }
    if (status === OnboardingStatus.ThirdStepCompleted) {
        return Steps.Routing;
    }
    if (status === OnboardingStatus.FourthStepCompleted) {
        return Steps.Routing;
    }
    return Steps.Consultants;
};

const Onboarding = () => {
    const { step: stepFromUrl } = useParams();
    const history = useNavigate();
    const [onboardingStep, setOnboardingStep] = useState<OnboardingStatus>();
    const [currentStep, setStep] = useState<Steps>(Steps.Consultants);
    const [isValidStep, setIsValidStep] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [affiliate, setAffiliateData] = useState<FormInput | null>(null);
    const [stepCb, setStepCb] = useState(DEFAULT_STEP_CB);
    const isLastStep = currentStep === Steps.Routing;
    const isFirstStep = currentStep === Steps.Consultants;
    const { classes } = useStyles();
    const containerRef = useRef<HTMLDivElement>(null);
    const { userAffiliateId } = useAffiliateCareView();
    const [fetchAffiliate, { data: affiliateData, loading: fetchAffiliateLoading }] =
        useFetchAffiliateLazyQuery({
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
        });
    useEffect(() => {
        if (affiliateData?.virtualCareAffiliateByAffiliateId?.data?.onboardingStatus) {
            setOnboardingStep(
                affiliateData.virtualCareAffiliateByAffiliateId.data.onboardingStatus,
            );
        }
    }, [affiliateData?.virtualCareAffiliateByAffiliateId?.data?.onboardingStatus]);
    useEffect(() => {
        fetchAffiliate({
            variables: {
                input: {
                    affiliateId: userAffiliateId,
                },
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, userAffiliateId, stepFromUrl]);
    useEffect(() => {
        setLoading(fetchAffiliateLoading);
    }, [fetchAffiliateLoading]);
    const setStepHandler = useCallback(
        async (newStep: Steps, backStep?: boolean) => {
            let result;
            SuppressNextGlobalAlert(false);
            if (backStep) {
                result = true;
            } else {
                result = await stepCb();
            }
            if (result && Steps[newStep] !== undefined) {
                setIsValidStep(false);
                setStepCb(DEFAULT_STEP_CB);
                history(`/affiliate-care-portal/affiliate-onboarding/${newStep + 1}`);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentStep, stepCb],
    );
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [currentStep]);
    useEffect(() => {
        if (affiliateData?.virtualCareAffiliateByAffiliateId?.data) {
            setAffiliateData(
                mapDataToFormInput(affiliateData.virtualCareAffiliateByAffiliateId.data),
            );
        }
    }, [affiliateData?.virtualCareAffiliateByAffiliateId?.data]);
    const contextValues = useMemo(
        () => ({
            currentStep,
            setIsValidStep,
            setLoading,
            affiliate,
            setAffiliateData,
            setStepCb,
        }),
        [currentStep, affiliate],
    );
    useEffect(() => {
        if (!stepFromUrl) {
            const stepId = onboardingStatusToStep(onboardingStep);
            setStep(stepId);
        }
    }, [onboardingStep, stepFromUrl]);
    useEffect(() => {
        if (stepFromUrl) {
            const stepNum = parseInt(stepFromUrl, 10);
            const stepId = onboardingStatusToStep(onboardingStep);
            if (!Number.isNaN(stepNum) && stepNum > 0 && stepNum <= stepId + 1) {
                setStep(stepNum - 1);
            }
        }
    }, [stepFromUrl, onboardingStep]);
    if (!affiliate) {
        return <Loading />;
    }
    return (
        <OnboardingContext.Provider value={contextValues}>
            <Grid container rowSpacing={5} ref={containerRef}>
                <Grid xs={12} item container justifyContent="center">
                    <Header onboarding />
                </Grid>
                <Grid xs={12} item className={classes.progressContainer}>
                    <Progress />
                </Grid>
                <Grid xs={12} item className={classes.stepContainer}>
                    {currentStep === Steps.Payment && <PaymentStep />}
                    {currentStep === Steps.Routing && <RoutingStep />}
                    {currentStep === Steps.ServiceAndLocations && <ServiceTypesAndLocationsStep />}
                    {currentStep === Steps.Consultants && <ConsultantsStep />}
                </Grid>
                <Grid
                    xs={12}
                    item
                    container
                    justifyContent="space-between"
                    className={classes.navigationContainer}
                >
                    <Grid item>
                        {!isFirstStep && (
                            <Button
                                onClick={() => setStepHandler(currentStep - 1, true)}
                                variant="outlined"
                                size="large"
                            >
                                Back
                            </Button>
                        )}
                    </Grid>
                    <Grid item>
                        <AsyncActionButton loading={loading}>
                            <Button
                                onClick={() => setStepHandler(currentStep + 1)}
                                variant="contained"
                                size="large"
                                disabled={!isValidStep || loading}
                            >
                                {isLastStep ? 'Finish' : 'Continue'}
                            </Button>
                        </AsyncActionButton>
                    </Grid>
                </Grid>
                <Grid xs={12} item container justifyContent="center" className={classes.footer}>
                    <Footer enableLogout />
                </Grid>
            </Grid>
        </OnboardingContext.Provider>
    );
};

export default Onboarding;
