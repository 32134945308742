import React from 'react';
import { FileUploadOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { ClaimSubmitted, useForceClaimUploadMutation } from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';

type UploadClaimProps = {
    claim: Pick<ClaimSubmitted, 'ClaimRef'>;
    callback: () => void;
};

const UploadClaim: React.FC<UploadClaimProps> = props => {
    const { claim, callback } = props;
    const [sendClaimToUpload] = useForceClaimUploadMutation({
        onCompleted: callback,
    });
    const onClickHandler = React.useCallback(() => {
        TriggerGlobalConfirm({
            message: 'Are you sure you want to upload the claim?',
            callback: () => {
                sendClaimToUpload({
                    variables: {
                        input: {
                            id: claim.ClaimRef,
                        },
                    },
                });
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claim]);
    return (
        <IconButton aria-label="upload claim" onClick={onClickHandler}>
            <FileUploadOutlined />
        </IconButton>
    );
};

export default UploadClaim;
