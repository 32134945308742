import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from 'tss-react/mui';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGESIZE } from '~/constants';
import { displayDate } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    DateTriggerListDocument,
    DateTriggerListQuery,
    useDateTriggerListQuery,
    useDeleteDateTriggerMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';

const useStyles = makeStyles()({
    root: {},
});
const DateTriggers: React.FC = () => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const { data: triggerData, loading: triggersLoading } = useDateTriggerListQuery({
        variables: { input: {} },
    });
    const [deleteDateTrigger, { loading: deleteTagLoading }] = useDeleteDateTriggerMutation({
        update: (cache, res) => {
            const triggersQuery = cache.readQuery<DateTriggerListQuery>({
                query: DateTriggerListDocument,
            });
            const filteredTriggers =
                triggersQuery?.dateTriggersV2.results.filter(
                    item => item?.id !== res.data?.deleteDateTrigger?.id,
                ) ?? [];
            if (triggersQuery?.dateTriggersV2.results) {
                cache.writeQuery<DateTriggerListQuery>({
                    query: DateTriggerListDocument,
                    data: {
                        ...triggersQuery,
                        dateTriggersV2: { results: filteredTriggers },
                    },
                });
            }
        },
        awaitRefetchQueries: true,
        notifyOnNetworkStatusChange: true,
        refetchQueries: [
            {
                query: DateTriggerListDocument,
                variables: { input: {} },
            },
        ],
    });

    const handleDeleteTrigger = (id: string) => {
        TriggerGlobalConfirm({
            message: 'Do you really want to delete this Date Trigger?',
            callback: () => {
                deleteDateTrigger({ variables: { id } });
            },
        });
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Date Triggers"
                icons={tableIcons}
                isLoading={triggersLoading || deleteTagLoading}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                data={_.cloneDeep(triggerData?.dateTriggersV2.results) ?? []}
                actions={[
                    {
                        onClick: () => history('/app-config/datetriggers/new'),
                        hidden: !pagePermissions?.DateTriggers.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Date Trigger',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => history(`/app-config/datetriggers/${id}`),
                        hidden: !pagePermissions?.DateTriggers.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Date Trigger',
                    },
                    {
                        icon: () => <FontAwesomeIcon icon={faTrash} />,
                        onClick: (_, { id }: any) => handleDeleteTrigger(id),
                        tooltip: 'Delete Date Trigger',
                        hidden: !pagePermissions?.DateTriggers.Delete,
                    },
                ]}
                columns={[
                    { title: 'Name', field: 'name' },
                    {
                        title: 'Last Updated',
                        field: 'updatedAt',
                        render: ({ updatedAt }) => (
                            <>
                                {displayDate({
                                    isoDateStr: updatedAt,
                                })}
                            </>
                        ),
                    },
                ]}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100] }}
                localization={{ header: { actions: '' } }}
            />
        </div>
    );
};

export default DateTriggers;
