import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Launch } from '@mui/icons-material';
import { DialogContent, Grid, TextField, Tooltip } from '@mui/material';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { DestinationNames, useOrganizationVirtualCareFormQuery } from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import { AppointmentMethodViewMap } from './constants';

type ViewSelectedResultProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    VCFId?: string;
};

const ViewVCF: React.FC<ViewSelectedResultProps> = props => {
    const { VCFId, setOpen } = props;
    const { data, loading } = useOrganizationVirtualCareFormQuery({
        variables: {
            input: {
                id: VCFId,
            },
        },
    });
    const formData = data?.virtualCareForm;
    const showContent = !loading && formData;
    return (
        <div>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setOpen(false)}>
                Virtual Care Form
            </DialogTitleWithClose>
            <DialogContent dividers>
                {!showContent && <Loading />}
                {showContent && (
                    <Grid container direction="column" justifyContent="flex-start">
                        <Grid container minWidth={600}>
                            <Grid item width={400}>
                                <TextField
                                    variant="outlined"
                                    label="Patient ID"
                                    fullWidth
                                    type="text"
                                    margin="dense"
                                    value={formData?.patientId}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item alignSelf="center">
                                <Tooltip title="Open Patient Page in new tab">
                                    <Link
                                        target="_blank"
                                        to={`/portal/patients/${formData?.patientId}`}
                                        rel="noopener noreferrer"
                                    >
                                        <Launch />
                                    </Link>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item width={400}>
                            <TextField
                                variant="outlined"
                                label="First Name"
                                fullWidth
                                type="text"
                                margin="dense"
                                value={formData?.firstName}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item width={400}>
                            <TextField
                                variant="outlined"
                                label="Last Name"
                                fullWidth
                                type="text"
                                margin="dense"
                                value={formData?.lastName}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <Grid item width={400}>
                                <TextField
                                    variant="outlined"
                                    label="Email"
                                    fullWidth
                                    type="text"
                                    margin="dense"
                                    value={formData?.email}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item width={400}>
                                <TextField
                                    variant="outlined"
                                    label="Birth Date"
                                    fullWidth
                                    type="text"
                                    margin="dense"
                                    value={moment(formData?.birthDate).format('MM-DD-YYYY')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item width={400}>
                                <TextField
                                    variant="outlined"
                                    label="Phone Number"
                                    fullWidth
                                    type="text"
                                    margin="dense"
                                    value={formData?.phoneNumber}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item width={400}>
                                <TextField
                                    variant="outlined"
                                    label="Appointment Method"
                                    fullWidth
                                    type="text"
                                    margin="dense"
                                    value={
                                        formData?.appointmentMethod &&
                                        AppointmentMethodViewMap[formData.appointmentMethod]
                                    }
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item width={400}>
                                <TextField
                                    variant="outlined"
                                    label="Destination"
                                    fullWidth
                                    type="text"
                                    margin="dense"
                                    value={
                                        formData?.Destination === DestinationNames.Pacify
                                            ? 'Wildflower'
                                            : formData?.Destination
                                    }
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item width={400}>
                                <TextField
                                    variant="outlined"
                                    label="CreatedAt"
                                    fullWidth
                                    type="text"
                                    margin="dense"
                                    value={moment(formData?.createdAt)
                                        .local()
                                        .format('MM-DD-YYYY hh:mm A')}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </div>
    );
};

export default ViewVCF;
