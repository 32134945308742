import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    root: {
        '& .MuiFormControl-root': {
            padding: 0,
        },
    },
    labelWrapper: {
        margin: '10px 0',
        width: 'auto',
    },
    helperMessage: {
        marginLeft: 15,
    },
});
