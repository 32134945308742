import { Typography } from '@mui/material';
import React from 'react';
import { GetSentMessageByPatientIdForPatientTranscriptQuery } from '~/schemaTypes';

type SentMessage = NonNullable<
    GetSentMessageByPatientIdForPatientTranscriptQuery['getSentMessageByPatientId']
>[0];

export const SentMessageCard = ({ sentMessage }: { sentMessage: SentMessage }) => {
    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <Typography variant="h6">Sent Comms</Typography>
            <p className="m-0">Email Title: {sentMessage?.emailTitle}</p>
            <p className="m-0">Email Body:</p>
            <p
                className="m-0"
                style={{ display: 'inline-block' }}
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{ __html: sentMessage?.emailBody || '' }}
            />
            <p className="m-0">Send Status: {sentMessage?.sendStatus[0].status}</p>
        </div>
    );
};
