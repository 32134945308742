/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    ExpressionUseType,
    LocalizedStringInput,
    PreFetchOrgsProfileDefsForSurveyBuilderQuery,
    SurveyDefQuestionInput,
    UserProfileValueType,
} from '~/schemaTypes';
import { SurveyEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';
import ConfigExpressionPicker from '~/views/ConfigDashboard/ConfigExpressions/components/ConfigExpressionPicker';
import PieceQuestionSelect from './components/PieceQuestionSelect';
import { useStyles } from './styles';

type UserProfileDefs = NonNullable<
    PreFetchOrgsProfileDefsForSurveyBuilderQuery['userProfileDefs'][0]
>;

interface PieceQuestionInputParms extends SurveyDefQuestionInput {
    divisionId: string;
    sectionId: string;
    pieceId?: string;
    expressionList?: (string | null)[] | null | undefined;
    profileDefList: UserProfileDefs[];

    submitHandler: (data: any) => void;
    deleteHandler: (divId: string, secId: string, pieceId: string) => void;
    closeHandler: () => void;
}
const PieceQuestionValidation = Yup.object().shape({
    // displayType: Yup.string().required('Required'),
});

const PieceQuestionModal: React.FC<PieceQuestionInputParms> = props => {
    const { classes } = useStyles();
    const { control, register, handleSubmit } = useForm<SurveyDefQuestionInput>({
        // @ts-expect-error RHF V7 limitation #7895 will be fixed in V8 of react-hook-form
        resolver: yupResolver(PieceQuestionValidation as any),
    });
    const {
        divisionId,
        sectionId,
        pieceId,
        expressionList,
        profileDefList,
        displayType,
        questionProfileDefId,
        choiceOverrides,
        answerRequired,
        hidePreviousAnswer,
        labelOverride,
        submitHandler,
        deleteHandler,
        closeHandler,
    } = props;
    const isEditMode = Boolean(questionProfileDefId);
    const [selectedExpressions, setSelectedExpressions] = useState<(string | null)[]>(
        expressionList ?? [],
    );
    const [selectedDisplayType, setSelectedDisplayType] = useState(displayType);
    const [selectedProfileId, setSelectedProfileId] = useState(questionProfileDefId);
    const [choiceLabelOverrides, setChoiceLabelOverrides] = useState(choiceOverrides);
    const onSubmit = (nameVariables: any) => {
        const data = {
            ...nameVariables,
            divisionId,
            sectionId,
            pieceId,
            selectedDisplayType,
            selectedProfileId,
            choiceLabelOverrides,
            selectedExpressions,
        };
        submitHandler(data);
    };
    const onDelete = () => {
        TriggerGlobalConfirm({
            message: 'Do you really want to delete this Question?',
            callback: () => {
                deleteHandler(divisionId, sectionId, pieceId ?? '');
            },
        });
    };

    const selectedProfile = useMemo(() => {
        return profileDefList.filter(profile => profile.id === selectedProfileId)[0];
    }, [profileDefList, selectedProfileId]);

    useEffect(() => {
        if (!selectedProfileId) {
            setSelectedProfileId(profileDefList[0].id);
        }
    }, [profileDefList, selectedProfileId]);

    const handleTypeChange = (value: any) => {
        setSelectedDisplayType(value);
    };
    const handleProfileChange = (event: any) => {
        setSelectedProfileId(event.target.value);
    };
    const handleLabelChange = (lang: string, value: string, choiceId?: string) => {
        let label: LocalizedStringInput = { en: '', es: '' };
        if (lang === 'en') {
            label.en = value;
        } else {
            label = { en: value, es: value };
        }
        if (choiceId) {
            if (choiceLabelOverrides) {
                const clo = choiceLabelOverrides.find(choice => choice?.choiceId === choiceId);
                if (!clo) {
                    setChoiceLabelOverrides(
                        choiceLabelOverrides.concat({ choiceId, labelOverride: label }),
                    );
                } else if (clo) {
                    if (lang === 'en') clo.labelOverride = { ...clo.labelOverride, en: value };
                    else clo.labelOverride = { en: clo.labelOverride?.en || value, es: value };
                    setChoiceLabelOverrides(
                        choiceLabelOverrides
                            .filter(choice => choice?.choiceId !== choiceId)
                            .concat(clo),
                    );
                }
            } else setChoiceLabelOverrides([{ choiceId, labelOverride: label }]);
        }
    };
    const expressionAdded = (id: string) => {
        setSelectedExpressions(selectedExpressions?.concat(id));
    };
    const expressionDropped = (id: string) => {
        setSelectedExpressions(selectedExpressions?.filter(exp => exp !== id));
    };

    return (
        <Dialog open>
            <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                data-test={SurveyEnum.QUESTION_MODAL}
            >
                <DialogTitleWithClose id="modalTitle" onClose={closeHandler}>
                    {isEditMode ? 'Edit' : 'Add'} Question
                </DialogTitleWithClose>
                <DialogContent>
                    {pieceId === '' && (
                        <div>
                            Profile:{' '}
                            <Select
                                variant="outlined"
                                label="profileLabel"
                                autoWidth
                                value={selectedProfile?.id}
                                onChange={handleProfileChange}
                            >
                                {profileDefList
                                    .slice()
                                    .sort((a, b) => {
                                        const x = a.name.toLowerCase();
                                        const y = b.name.toLowerCase();
                                        if (x < y) return -1;
                                        if (x > y) return 1;
                                        return 0;
                                    })
                                    .map(profile => {
                                        return (
                                            <MenuItem key={profile.id} value={profile.id}>
                                                {profile.name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </div>
                    )}

                    {pieceId !== '' && selectedProfile && (
                        <div>
                            Profile: <strong>{selectedProfile.name}</strong>
                        </div>
                    )}
                    <div>
                        Label:{' '}
                        <strong>
                            {selectedProfile?.questionLabel?.en || selectedProfile?.label?.en}
                        </strong>
                        <OutlinedSection title="Label Override">
                            <TextField
                                variant="outlined"
                                type="text"
                                label="English"
                                fullWidth
                                margin="dense"
                                defaultValue={labelOverride?.en}
                                {...register('labelOverride.en')}
                                data-test={SurveyEnum.EDIT_QUESTION_MODAL_LABEL_OVERRIDE}
                            />
                            <TextField
                                variant="outlined"
                                type="text"
                                label="Spanish"
                                fullWidth
                                margin="dense"
                                defaultValue={labelOverride?.es}
                                {...register('labelOverride.es')}
                            />
                        </OutlinedSection>
                    </div>

                    <div>
                        <FormControlLabel
                            labelPlacement="start"
                            label="Display as:"
                            className={classes.displayAs}
                            control={
                                <PieceQuestionSelect
                                    valueType={
                                        selectedProfile
                                            ? selectedProfile.valueType
                                            : UserProfileValueType.Str
                                    }
                                    selectedType={selectedDisplayType}
                                    displayTypeChanged={handleTypeChange}
                                    registerField={register('displayType').ref}
                                />
                            }
                        />
                    </div>
                    <div>
                        <Controller
                            name="answerRequired"
                            control={control}
                            defaultValue={answerRequired}
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    label="Answer required?"
                                    labelPlacement="start"
                                    control={
                                        <Checkbox
                                            id="answerRequired"
                                            checked={value === true}
                                            onChange={onChange}
                                        />
                                    }
                                />
                            )}
                        />
                        <Controller
                            name="hidePreviousAnswer"
                            control={control}
                            defaultValue={hidePreviousAnswer}
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    label="Hide previous answer?"
                                    labelPlacement="start"
                                    control={
                                        <Checkbox
                                            id="hidePreviousAnswer"
                                            checked={value === true}
                                            onChange={onChange}
                                        />
                                    }
                                />
                            )}
                        />
                    </div>
                    {selectedProfile &&
                        selectedProfile.choices &&
                        selectedProfile.choices.length > 0 && (
                            <table>
                                <tbody>
                                    <tr key="a">
                                        <th>Choice - English</th>
                                        <th>Override - English</th>
                                        <th>Spanish</th>
                                    </tr>
                                    {selectedProfile.choices.map(choice => {
                                        return (
                                            <tr key={choice?.id}>
                                                <td>{choice?.label?.en}</td>
                                                <td>
                                                    <TextField
                                                        variant="outlined"
                                                        {...register(
                                                            `${choice?.id}-label.en` as any,
                                                        )}
                                                        type="text"
                                                        fullWidth
                                                        margin="dense"
                                                        value={
                                                            choiceLabelOverrides?.find(
                                                                co => co?.choiceId === choice?.id,
                                                            )?.labelOverride?.en
                                                        }
                                                        onChange={e => {
                                                            handleLabelChange(
                                                                'en',
                                                                e.target.value,
                                                                choice?.id,
                                                            );
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextField
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        margin="dense"
                                                        {...register(
                                                            `${choice?.id}-label.es` as any,
                                                        )}
                                                        value={
                                                            choiceLabelOverrides?.find(
                                                                co => co?.choiceId === choice?.id,
                                                            )?.labelOverride?.es
                                                        }
                                                        onChange={e => {
                                                            handleLabelChange(
                                                                'es',
                                                                e.target.value,
                                                                choice?.id,
                                                            );
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        )}
                    <ConfigExpressionPicker
                        inputList={selectedExpressions}
                        itemAddedHandler={expressionAdded}
                        itemDroppedHandler={expressionDropped}
                        useType={ExpressionUseType.Surveys}
                        canChange
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeHandler} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        data-test={SurveyEnum.EDIT_QUESTION_MODAL_SUBMIT}
                    >
                        {pieceId ? 'Update ' : 'Add '}
                        Question
                    </Button>{' '}
                    {pieceId && (
                        <Button color="primary" variant="contained" onClick={onDelete}>
                            Remove Question
                        </Button>
                    )}{' '}
                </DialogActions>
            </form>
        </Dialog>
    );
};
export {};
export default PieceQuestionModal;
