import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    comment: {
        margin: '0 0 10px 10px',
    },
    author: {
        fontWeight: 'bolder',
    },
    date: {
        fontWeight: 'lighter',
    },
    edit: {
        marginLeft: 10,
    },
});
