import { MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Loading from '~/components/Loading/Loading';
import { ExpressionUseType, useFetchConfigExpressionListQuery } from '~/schemaTypes';

const ConfigExpressionSelect: React.FC<{
    inputId: string | null;
    itemSelected: (id: string, name: string) => void;
    useType: ExpressionUseType;
    canChange: boolean;
}> = props => {
    const { inputId, itemSelected, useType, canChange } = props;
    const [selectedItem, setSelectedItem] = useState<{ id: string; name: string }>({
        id: '0',
        name: '',
    });
    const { data: expressionData, loading: expressionsLoading } =
        useFetchConfigExpressionListQuery();

    useEffect(() => {
        const item = expressionData?.configExpressions.find(exp => exp.id === inputId);
        if (item) setSelectedItem({ id: item.id, name: item.name });
    }, [inputId, expressionData?.configExpressions]);

    const selectChanged = (event: any) => {
        if (event.target.value === '0') setSelectedItem({ id: '0', name: '' });
        else {
            const item = expressionData?.configExpressions.find(
                exp => exp.id === event.target.value,
            );
            if (item) {
                setSelectedItem({ id: item.id, name: item.name });
                itemSelected(event.target.value, item.name);
            }
        }
    };
    if (expressionsLoading) return <Loading />;
    return (
        <Select
            variant="outlined"
            style={{ margin: '5px' }}
            autoWidth
            value={selectedItem?.id}
            disabled={!canChange}
            onChange={selectChanged}
        >
            <MenuItem key="0" value="0">
                Select Expression
            </MenuItem>
            {expressionData?.configExpressions
                .filter(c => c.useType === useType)
                .sort((a, b) => {
                    const x = a.name.toLowerCase();
                    const y = b.name.toLowerCase();
                    if (x < y) return -1;
                    if (x > y) return 1;
                    return 0;
                })
                .map(exp => {
                    return (
                        <MenuItem key={exp.id} value={exp.id}>
                            {exp.name}
                        </MenuItem>
                    );
                })}
        </Select>
    );
};

export default ConfigExpressionSelect;
