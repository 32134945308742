import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogContent, FormControl, Grid, TextField } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuidV4 } from 'uuid';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { INewDiagnose } from '..';
import { generateFormSchema, NewOrderSections } from '../validations';

type INewDiagnoseFormInput = Omit<INewDiagnose, 'id'>;

interface IDiagnosesModalProps {
    setShowDiagnosesModal: (isOpen: boolean) => void;
    addNewDiagnose: (diagnose: INewDiagnose) => void;
}

const DiagnosesModal: React.FC<IDiagnosesModalProps> = ({
    setShowDiagnosesModal,
    addNewDiagnose,
}) => {
    const diagnosesMethods = useForm<INewDiagnoseFormInput>({
        resolver: yupResolver(generateFormSchema(NewOrderSections.NewDiagnose) as any),
    });
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = diagnosesMethods;

    const addDiagnosis = (diagnose: INewDiagnoseFormInput) => {
        addNewDiagnose({
            ...diagnose,
            id: uuidV4(),
        });
        setShowDiagnosesModal(false);
    };

    return (
        <>
            <DialogTitleWithClose id="Add Diagnosis" onClose={() => setShowDiagnosesModal(false)}>
                Add
            </DialogTitleWithClose>
            <DialogContent dividers>
                <form noValidate onSubmit={handleSubmit(addDiagnosis)}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                id="code"
                                label="Code"
                                type="text"
                                margin="dense"
                                fullWidth
                                error={!!errors.code}
                                helperText={errors.code?.message}
                                {...register('code')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                id="codeSet"
                                label="Code Set"
                                type="text"
                                margin="dense"
                                fullWidth
                                disabled
                                {...register('codeSet')}
                                value="ICD-10"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                id="type"
                                label="Type"
                                type="text"
                                margin="dense"
                                fullWidth
                                error={!!errors.type}
                                helperText={errors.type?.message}
                                {...register('type')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                id="name"
                                label="Name"
                                type="text"
                                margin="dense"
                                fullWidth
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                {...register('name')}
                            />
                        </Grid>
                        <Grid>
                            <Controller
                                control={control}
                                name="dateTime"
                                render={({ field }) => (
                                    <DateTimePicker
                                        {...field}
                                        label="Target Date"
                                        ampm={false}
                                        minutesStep={5}
                                        format="MM/dd/yyyy hh:mm"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid>
                        <FormControl variant="outlined">
                            <Button color="primary" variant="contained" type="submit">
                                Add Diagnosis
                            </Button>
                        </FormControl>
                    </Grid>
                </form>
            </DialogContent>
        </>
    );
};

export default DiagnosesModal;
