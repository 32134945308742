import React from 'react';
import { Card, Fade, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import {
    FetchOrgDetailsQuery,
    useUpdatePracticeConfigOptionsForPracticeMutation,
} from '~/schemaTypes';

type Organization = NonNullable<FetchOrgDetailsQuery['organization']>;

type OrgConfigOptionsProps = {
    orgId: string;
    configOptions: Partial<Organization['configOptions']>;
};

type OrgConfigOptionsData = {
    riskScoreEnabled: boolean;
};

const OrgConfigOptions: React.FC<OrgConfigOptionsProps> = ({ orgId, configOptions }) => {
    const { control, handleSubmit } = useForm();
    const [updateConfigOptions] = useUpdatePracticeConfigOptionsForPracticeMutation();

    const onSubmit = (configOptions: OrgConfigOptionsData) => {
        updateConfigOptions({
            variables: {
                input: {
                    id: orgId,
                    data: {
                        configOptions,
                    },
                },
            },
        });
    };

    return (
        <Fade in timeout={500}>
            <Card elevation={0}>
                <Typography variant="h5" paragraph>
                    Configuration Options
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            name="riskScoreEnabled"
                            control={control}
                            render={({ field: { onChange } }) => (
                                <FormControlLabel
                                    label="Risk Score"
                                    control={
                                        <Switch
                                            checked={configOptions?.riskScoreEnabled ?? false}
                                            onChange={(event, val) => {
                                                onChange(val);
                                                handleSubmit(onSubmit)();
                                            }}
                                        />
                                    }
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Card>
        </Fade>
    );
};

export default OrgConfigOptions;
