import React from 'react';
import { Button, Grid } from '@mui/material';
import { FormMode, ZipCodesType } from './constants';
import AddZipCode from './AddZipCode';

type ActionButtonsProps = {
    isEdit: boolean;
    setMode: (mode: FormMode) => void;
    mode: FormMode;
    addHandler: (zipCodes: string[], type: ZipCodesType) => void;
};

const ActionButtons: React.FC<ActionButtonsProps> = props => {
    const { isEdit, setMode, mode, addHandler } = props;
    const addByCityAndStateLabel = isEdit
        ? 'Populate Zip Codes by City, State'
        : 'Add Zip Codes By City and State';
    const addByAddressLabel = isEdit ? 'Populate Zip Codes by Address' : 'Add Zip Codes By Address';
    const showButtons = [FormMode.View, FormMode.AddManually].includes(mode);
    if (!showButtons) {
        return null;
    }
    return (
        <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            minHeight={isEdit ? 30 : 60}
            rowSpacing={2}
        >
            <Grid
                sm="auto"
                xs={12}
                item
                container
                justifyContent="flex-start"
                columnSpacing={2}
                alignItems="center"
                rowSpacing={2}
            >
                <Grid item>
                    <Button
                        variant={isEdit ? 'text' : 'contained'}
                        size="small"
                        onClick={() => setMode(FormMode.AddByCityState)}
                    >
                        {addByCityAndStateLabel}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant={isEdit ? 'text' : 'contained'}
                        size="small"
                        onClick={() => setMode(FormMode.AddByAddress)}
                    >
                        {addByAddressLabel}
                    </Button>
                </Grid>
            </Grid>
            {!isEdit && (
                <Grid
                    item
                    sm="auto"
                    xs={12}
                    container
                    justifyContent="flex-end"
                    columnSpacing={2}
                    alignItems="center"
                >
                    <AddZipCode
                        mode={mode}
                        isEdit={isEdit}
                        setMode={setMode}
                        addHandler={addHandler}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default ActionButtons;
