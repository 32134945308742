export enum FilterFields {
    filteredTags = 'filteredTags',
    filteredAdvocate = 'filteredAdvocate',
    filteredFirstName = 'filteredFirstName',
    filteredLastName = 'filteredLastName',
    filteredExternalId = 'filteredExternalId',
    filteredBirthDate = 'filteredBirthDate',
    filteredEmail = 'filteredEmail',
    filteredPhoneNumber = 'filteredPhoneNumber',
    filteredMemberId = 'filteredMemberId',
    filterTestData = 'filterTestData',
    filteredClinics = 'filteredClinics',
    enableAdvocateFilter = 'enableAdvocateFilter',
    filteredRiskScore = 'filteredRiskScore',
    filteredAffiliateId = 'filteredAffiliateId',
    filteredLanguage = 'filteredLanguage',
}
