/**
 * gql queries returns you objects with __typename, it is sometimes redundant
 * @param obj
 * @returns copy of obj without __typename property (deep)
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const excludeTypeName = (obj: any): any => {
    if (obj === null) return obj;
    if (Array.isArray(obj)) return obj.map(el => excludeTypeName(el));
    if (typeof obj === 'object' && !(obj instanceof Date)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { __typename, ...rest } = obj;
        return Object.entries(rest).reduce((acc, [key, value]) => {
            return {
                ...acc,
                [key]: excludeTypeName(value),
            };
        }, {});
    }
    return obj;
};

export default {
    excludeTypeName,
};
