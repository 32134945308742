import * as Yup from 'yup';

export const CONSULTANT_FORM_SCHEMA = () =>
    Yup.object().shape({
        name: Yup.string().required('Full Name is a required field'),
        IBCLCNumber: Yup.string().required('IBCLC Number is a required field'),
        IBCLCExpiration: Yup.date()
            .typeError('Invalid date')
            .min(new Date(), 'Please enter future date')
            .dateFormatVCF()
            .required('IBCLC Expiration is a required field'),
        IBCLCCertification: Yup.lazy(value => {
            if (!value) {
                return Yup.string().optional();
            }
            return Yup.date().typeError('Invalid date').dateFormatVCF().required();
        }),
        CAQHNumber: Yup.string().optional(),
        NPINumber: Yup.string().required('NPI Number is a required field'),
        fileName: Yup.string().required('Liability Insurance is a required field'),
        liabilityInsuranceDate: Yup.date()
            .typeError('Invalid date')
            .min(new Date(), 'Please enter future date')
            .dateFormatVCF()
            .required('Liability Insurance Date of Expiration is a required field'),
    });
