export enum ProfileValueOriginSelectorEnum {
    NAV_PROFILE_VALUE_ORIGIN = 'profile-value-origin',
    CONTAINER = 'profile-value-origin-container',
    MODAL = 'profile-value-origin-modal',
    LABEL_EN = 'modal-label-en',
    LABEL_ES = 'modal-label-es',
    SAVE = 'modal-save',
}

export const ProfileValueOriginSelectors = {
    NAV_CONDITION_TYPES: `a[data-test="${ProfileValueOriginSelectorEnum.NAV_PROFILE_VALUE_ORIGIN}"]`,
    ADD_CONDITION_TYPE_BUTTON: `div[data-test="${ProfileValueOriginSelectorEnum.CONTAINER}"] button[title="Add Condition Type"]`,
    EDIT_CONDITION_TYPE_BUTTON: `div[data-test="${ProfileValueOriginSelectorEnum.CONTAINER}"] button[title="Edit Condition Type"]`,
    MODAL: `div[data-test="${ProfileValueOriginSelectorEnum.MODAL}"] div[role="dialog"]`,
    NAME_EN_INPUT: `div[data-test="${ProfileValueOriginSelectorEnum.LABEL_EN}"] input`,
    NAME_ES_INPUT: `div[data-test="${ProfileValueOriginSelectorEnum.LABEL_ES}"] input`,
    SAVE_BUTTON: `button[data-test="${ProfileValueOriginSelectorEnum.SAVE}"]`,
    SEARCH_INPUT: `div[data-test="${ProfileValueOriginSelectorEnum.CONTAINER}"] input[placeholder="Search"]`,
};
