import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    inputFile: {
        height: 0,
        width: 0,
    },
    pagination: {
        paddingTop: 10,
        paddingLeft: 10,
    },
}));

export default useStyles;
