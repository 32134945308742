import { Button, Card, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUnsubscribePatientEmailMutation } from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import { SuppressNextGlobalAlert } from '~/state';

SuppressNextGlobalAlert(true);
const useStyles = makeStyles()(theme => ({
    root: {
        padding: 20,
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 600,
        '& .content': {
            padding: 10,
            [theme.breakpoints.up('md')]: {
                padding: 40,
            },
        },
    },
}));

const PatientUnsubscribeEmail: React.FC = () => {
    const { classes } = useStyles();
    const { patientId } = useParams<{
        patientId: string;
    }>();
    const [unsubscribe, { loading }] = useUnsubscribePatientEmailMutation();
    const [showResub, setShowResub] = useState(true);
    const firstTimeRef = useRef<any>();
    firstTimeRef.current = true;
    useEffect(() => {
        if (patientId && firstTimeRef.current) {
            unsubscribe({ variables: { id: patientId, setting: true } });
            firstTimeRef.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [msg, setMsg] = useState(
        'You have been successfully unsubscribed from email communications. If you would like to re-subscribe, tap the button below.',
    );
    const [header, setHeader] = useState('Unsubscribe Successful');
    const handleResubscribe = () => {
        if (patientId) {
            unsubscribe({ variables: { id: patientId, setting: false } });
        }
        setMsg('You have been successfully re-subscribed to email communications');
        setHeader('Re-subscribe Successful');
        setShowResub(false);
    };
    if (loading) return <Loading />;
    return (
        <div className={classes.root}>
            <Card>
                <h2>{header}</h2>
                <Grid item xs={12}>
                    <div>{msg}</div>
                </Grid>
                {showResub && (
                    <Grid item xs={12}>
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                            <Button onClick={handleResubscribe} variant="contained">
                                Re-subscribe
                            </Button>
                        </div>
                    </Grid>
                )}
            </Card>
        </div>
    );
};

export default PatientUnsubscribeEmail;
