import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
    FetchPatientProfileVariablesQuery,
    FetchProfileDefsForVbcPatientModalQuery,
    useFetchPatientProfileVariablesLazyQuery,
    useFetchProfileDefsForVbcPatientModalQuery,
} from '~/schemaTypes';
import { ProfileVariableInputsProps } from '~/views/Dashboard/Patients/VBCPatientModal/ProfileVariableInputs';
import { VBCInviteInputsProps } from '~/views/PatientInvite/VBCInviteInputs';

const CREATE_PATIENT_FIELDS = 'createPatientFields';
const EDIT_PATIENT_FIELDS = 'editPatientFields';

interface UseProfileVariableInputsArgs {
    VBCPatientModalInputs?: React.FC<ProfileVariableInputsProps>;
    VBCInviteStepsInputs?: React.FC<VBCInviteInputsProps>;
    bundleId: string | null;
    useFormObject?: UseFormReturn;
    patientId?: string;
    profileVariableData?: { [key: string]: any };
    onChangeHandler?: (arg: { [key: string]: any }) => void;
}
type AdditionalPatientFields =
    FetchProfileDefsForVbcPatientModalQuery['additionalPatientFieldsProfileDefs'];

export const useProfileVariableInputs = ({
    bundleId,
    patientId,
    profileVariableData,
    onChangeHandler,
    VBCPatientModalInputs,
    VBCInviteStepsInputs,
    useFormObject,
}: UseProfileVariableInputsArgs) => {
    const [additionalPatientFields, setAdditionalPatientFields] = useState<AdditionalPatientFields>(
        { profileDefs: [], careTeamMembers: [] },
    );
    const [getPatientProfileVariables, { loading: profileVariablesLoading }] =
        useFetchPatientProfileVariablesLazyQuery({
            onCompleted: data => {
                const patientProfileDefIds: string[] =
                    additionalPatientFields?.profileDefs?.map(item => item?.id) ?? [];
                const profileVariables: FetchPatientProfileVariablesQuery['patientProfileVariables'] =
                    data.patientProfileVariables.filter(item =>
                        patientProfileDefIds.includes(item.profileVariableDefId),
                    );
                if (useFormObject) {
                    const { setValue } = useFormObject;
                    profileVariables.forEach((item: any) => {
                        setValue(item.name, item.value[item.valueType]);
                    });
                }
            },
        });

    useFetchProfileDefsForVbcPatientModalQuery({
        variables: {
            input: {
                bundleId: bundleId as string,
                configName: patientId ? EDIT_PATIENT_FIELDS : CREATE_PATIENT_FIELDS,
            },
        },
        onCompleted: data => {
            setAdditionalPatientFields(data?.additionalPatientFieldsProfileDefs);
            if (patientId) {
                getPatientProfileVariables({
                    variables: {
                        patientId,
                    },
                });
            }
        },
    });

    if (VBCPatientModalInputs && useFormObject) {
        return {
            profileVariableInputs: (
                <VBCPatientModalInputs
                    additionalPatientFields={additionalPatientFields}
                    profileVariablesLoading={profileVariablesLoading}
                    useForm={useFormObject}
                    bundleId={bundleId}
                />
            ),
            profileDefinitionsData: additionalPatientFields?.profileDefs,
        };
    }

    return {
        profileVariableInputs:
            VBCInviteStepsInputs && profileVariableData && onChangeHandler ? (
                <VBCInviteStepsInputs
                    additionalPatientFields={additionalPatientFields}
                    profileVariablesLoading={profileVariablesLoading}
                    profileVariableData={profileVariableData}
                    onChangeHandler={onChangeHandler}
                    bundleId={bundleId}
                />
            ) : null,
        profileDefinitionsData: additionalPatientFields?.profileDefs,
    };
};
