import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    root: {},
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(4),
    },
    helperMessage: {
        margin: '-10px 0 0 24px',
    },
    actionBtns: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '20px 10px 0 0',
        '& > *': {
            marginLeft: '10px',
        },
    },
    surveyGroup: {
        width: '100%',
    },
    surveyGroupActionBtns: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '10px',
    },
    addSurveyGroupBtn: {
        margin: '10px',
    },
    sortableList: {
        marginTop: '14px',
    },
    hiddenInput: {
        display: 'none',
    },
    modalBtns: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px',
        '& > *': {
            marginLeft: '10px',
        },
    },
    addSurveyBtn: {
        margin: '0 10px 10px',
    },
}));

export default useStyles;
