import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, IconButton, Tooltip, Typography } from '@mui/material';
import { Add, InfoOutlined } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { ReactElement, useRef, useState } from 'react';
import tableIcons from '~/helpers/tableIcons';
import useUserPermissions from '~/hooks/useUserPermissions';
import { AlertSeverity, useFetchHealthPlansForHealthPlansPageQueryQuery } from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import HealthPlanModal from './HealthPlanModal/HealthPlanModal';

export default function HealthPlans(): ReactElement {
    const { data, loading: healthPlansLoading } = useFetchHealthPlansForHealthPlansPageQueryQuery();

    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [showHealthPlanModal, setShowHealthPlanModal] = useState(false);
    const [editHealthPlanId, setEditHealthPlanId] = useState<string>('');
    const { pagePermissions } = useUserPermissions();

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <>
            <MaterialTable
                icons={tableIcons as MaterialTableProps<any>['icons']}
                actions={[
                    {
                        onClick: () => setShowHealthPlanModal(true),
                        hidden: !pagePermissions?.HealthPlans.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add New Health Plan',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, plan: any) => {
                            setEditHealthPlanId(plan.id);
                            setShowHealthPlanModal(true);
                        },
                        disabled: !pagePermissions?.HealthPlans.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit',
                    },
                ]}
                columns={[
                    {
                        title: 'Id',
                        field: 'id',
                        align: 'center',
                        sorting: false,
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Health Plan Id Copied to Clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'External Id',
                        field: 'externalId',
                        align: 'center',
                        sorting: false,
                        render: ({ externalId }) => {
                            if (externalId) {
                                return (
                                    <Tooltip title={externalId ?? ''}>
                                        <IconButton
                                            onClick={() => {
                                                copy(`${externalId}`);
                                                TriggerGlobalAlert({
                                                    message: 'External Id Copied to Clipboard',
                                                    severity: AlertSeverity.Success,
                                                });
                                            }}
                                            size="large"
                                        >
                                            <InfoOutlined />
                                        </IconButton>
                                    </Tooltip>
                                );
                            }
                            return '';
                        },
                    },
                    { title: 'Name', field: 'name', defaultSort: 'asc' },
                    { title: 'Branding Name', field: 'brandingName' },
                    { title: 'Health Plan Code', field: 'healthPlanCode' },
                    { title: 'Eligibility Type', field: 'eligibilityType' },
                    { title: 'State', field: 'state' },
                    {
                        title: 'Surveys',
                        render: ({ surveys }) => (
                            <>
                                {surveys.map((name: string) => (
                                    <Typography key={name}>{name}</Typography>
                                ))}
                            </>
                        ),
                    },
                ]}
                isLoading={healthPlansLoading}
                data={_.cloneDeep(data?.healthPlans ?? [])}
                title="Health Plans"
                localization={{ header: { actions: '' } }}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                }}
            />
            <Dialog scroll="paper" open={showHealthPlanModal} fullWidth>
                <HealthPlanModal
                    setOpen={setShowHealthPlanModal}
                    setEditHealthPlanId={setEditHealthPlanId}
                    id={editHealthPlanId}
                />
            </Dialog>
        </>
    );
}
