import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Breadcrumbs as MaterialBreadcrumbs } from '@mui/material';
import { useStyles } from '../styles';

type BreadcrumbsType = {
    lastTitle?: string;
    lastPath?: string;
};

const Breadcrumbs: React.FC<BreadcrumbsType> = props => {
    const { lastTitle, lastPath } = props;
    const { classes } = useStyles();
    const breadcrumbs = [
        <Link key="1" to="/app-config/eligibility-match" className={classes.breadcrumbs}>
            <Typography variant="h6">Eligibility Match</Typography>
        </Link>,
    ];
    if (lastTitle && lastPath) {
        breadcrumbs.push(
            <Link
                key="2"
                to={`/app-config/eligibility-match/${lastPath}`}
                className={classes.breadcrumbs}
            >
                <Typography variant="h6">{lastTitle}</Typography>
            </Link>,
        );
    }
    return (
        <MaterialBreadcrumbs aria-label="breadcrumb" separator="-">
            {breadcrumbs}
        </MaterialBreadcrumbs>
    );
};

export default memo(Breadcrumbs);
