import { BioScreenShortcutType } from '~/schemaTypes';
import { BioScreenBuilderFormInput } from '../types/BioScreenBuilderFormInput';
import { BioScreen } from '../types/Schema';

export function toBioScreenFormInput(bioScreen: BioScreen): BioScreenBuilderFormInput {
    const { id, label, yourHealthInfo, surveyGroups, shortcuts } = bioScreen;
    return {
        id,
        label: { en: label.en ?? '', es: label.es ?? '' },
        yourHealthInfo: {
            label: { en: yourHealthInfo?.label?.en ?? '', es: yourHealthInfo?.label?.es ?? '' },
            icon: yourHealthInfo?.icon ?? '',
            instructions: {
                en: yourHealthInfo?.instructions?.en ?? '',
                es: yourHealthInfo?.instructions?.es ?? '',
            },
            type: BioScreenShortcutType.YourHealthInfo,
        },
        shortcuts: shortcuts.map(
            ({ icon, label, tags, instructions, surveyGroupId, surveyId, type }) => ({
                icon,
                type,
                surveyGroupId: surveyGroupId || '',
                surveyId: surveyId || '',
                label: { en: label?.en ?? '', es: label?.es ?? '' },
                tags: tags?.map(tag => tag || '') || [],
                instructions: {
                    en: instructions?.en ?? '',
                    es: instructions?.es ?? '',
                },
            }),
        ),
        surveyGroups: surveyGroups.map(({ id, label, surveys, tags }) => ({
            id: id ?? null,
            label: { en: label.en ?? '', es: label.es ?? '' },
            surveys: surveys.map(({ label, surveyId, tags }) => ({
                label,
                surveyId,
                tags,
            })),
            tags: tags?.map(tag => tag || '') || [],
        })),
    };
}
