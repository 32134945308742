import { FIELD_NAMES, TODO_TYPE } from '../enums';

export const isInputHidden = {
    [TODO_TYPE.CPCreatedCompleted]: {
        [FIELD_NAMES.repeatType]: true,
        [FIELD_NAMES.deleteTodo]: true,
        [FIELD_NAMES.deleteTodoRecurring]: true,
        [FIELD_NAMES.careplanGroupLabel]: false,
        [FIELD_NAMES.careplanLabel]: false,
        [FIELD_NAMES.markAsComplete]: true,
        [FIELD_NAMES.submitTodo]: true,
        [FIELD_NAMES.closeModal]: false,
        [FIELD_NAMES.cancelModal]: true,
        [FIELD_NAMES.careplanTemplateTags]: false,
    },
    [TODO_TYPE.CPCreatedUpcoming]: {
        [FIELD_NAMES.repeatType]: true,
        [FIELD_NAMES.deleteTodo]: true,
        [FIELD_NAMES.deleteTodoRecurring]: true,
        [FIELD_NAMES.careplanGroupLabel]: false,
        [FIELD_NAMES.careplanLabel]: false,
        [FIELD_NAMES.markAsComplete]: false,
        [FIELD_NAMES.submitTodo]: false,
        [FIELD_NAMES.closeModal]: true,
        [FIELD_NAMES.cancelModal]: false,
        [FIELD_NAMES.careplanTemplateTags]: false,
    },
    [TODO_TYPE.HACreatedCompleted]: {
        [FIELD_NAMES.repeatType]: false,
        [FIELD_NAMES.deleteTodo]: false,
        [FIELD_NAMES.deleteTodoRecurring]: false,
        [FIELD_NAMES.careplanGroupLabel]: true,
        [FIELD_NAMES.careplanLabel]: true,
        [FIELD_NAMES.markAsComplete]: true,
        [FIELD_NAMES.submitTodo]: true,
        [FIELD_NAMES.closeModal]: false,
        [FIELD_NAMES.cancelModal]: true,
        [FIELD_NAMES.careplanTemplateTags]: true,
    },
    [TODO_TYPE.HACreatedUpcoming]: {
        [FIELD_NAMES.repeatType]: false,
        [FIELD_NAMES.deleteTodo]: false,
        [FIELD_NAMES.deleteTodoRecurring]: false,
        [FIELD_NAMES.careplanGroupLabel]: true,
        [FIELD_NAMES.careplanLabel]: true,
        [FIELD_NAMES.markAsComplete]: false,
        [FIELD_NAMES.submitTodo]: false,
        [FIELD_NAMES.closeModal]: true,
        [FIELD_NAMES.cancelModal]: false,
        [FIELD_NAMES.careplanTemplateTags]: true,
    },
};

export const isEditFormFieldDisabled = {
    [TODO_TYPE.CPCreatedCompleted]: {
        [FIELD_NAMES.title]: true,
        [FIELD_NAMES.description]: true,
        [FIELD_NAMES.careplanLabel]: true,
        [FIELD_NAMES.careplanGroupLabel]: true,
        [FIELD_NAMES.repeatType]: true,
        [FIELD_NAMES.dueDate]: true,
        [FIELD_NAMES.effectiveDateDaysInterval]: true,
        [FIELD_NAMES.effectiveDateMonthsInterval]: true,
        [FIELD_NAMES.effectiveDateYearsInterval]: true,
        [FIELD_NAMES.priority]: true,
        [FIELD_NAMES.isEssential]: true,
        [FIELD_NAMES.careplanTemplateTags]: true,
    },
    [TODO_TYPE.CPCreatedUpcoming]: {
        [FIELD_NAMES.title]: true,
        [FIELD_NAMES.description]: true,
        [FIELD_NAMES.careplanLabel]: true,
        [FIELD_NAMES.careplanGroupLabel]: true,
        [FIELD_NAMES.repeatType]: true,
        [FIELD_NAMES.dueDate]: false,
        [FIELD_NAMES.effectiveDateDaysInterval]: false,
        [FIELD_NAMES.effectiveDateMonthsInterval]: false,
        [FIELD_NAMES.effectiveDateYearsInterval]: false,
        [FIELD_NAMES.priority]: false,
        [FIELD_NAMES.isEssential]: false,
        [FIELD_NAMES.careplanTemplateTags]: true,
    },
    [TODO_TYPE.HACreatedCompleted]: {
        [FIELD_NAMES.title]: true,
        [FIELD_NAMES.description]: true,
        [FIELD_NAMES.careplanLabel]: true,
        [FIELD_NAMES.careplanGroupLabel]: true,
        [FIELD_NAMES.repeatType]: true,
        [FIELD_NAMES.dueDate]: true,
        [FIELD_NAMES.effectiveDateDaysInterval]: true,
        [FIELD_NAMES.effectiveDateMonthsInterval]: true,
        [FIELD_NAMES.effectiveDateYearsInterval]: true,
        [FIELD_NAMES.priority]: true,
        [FIELD_NAMES.isEssential]: true,
        [FIELD_NAMES.careplanTemplateTags]: true,
    },
    [TODO_TYPE.HACreatedUpcoming]: {
        [FIELD_NAMES.title]: false,
        [FIELD_NAMES.description]: false,
        [FIELD_NAMES.careplanLabel]: false,
        [FIELD_NAMES.careplanGroupLabel]: false,
        [FIELD_NAMES.repeatType]: true,
        [FIELD_NAMES.dueDate]: false,
        [FIELD_NAMES.effectiveDateDaysInterval]: false,
        [FIELD_NAMES.effectiveDateMonthsInterval]: false,
        [FIELD_NAMES.effectiveDateYearsInterval]: false,
        [FIELD_NAMES.priority]: false,
        [FIELD_NAMES.isEssential]: false,
        [FIELD_NAMES.careplanTemplateTags]: true,
    },
};
