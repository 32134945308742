import { QueryHookOptions, QueryResult } from '@apollo/client';
import {
    Exact,
    FetchCurrentUserForUseUserHookQuery,
    FetchCurrentUserForUseUserHookQueryVariables,
    useFetchCurrentUserForUseUserHookQuery,
} from '../schemaTypes';

const useUser = (
    queryOptions?: QueryHookOptions<
        FetchCurrentUserForUseUserHookQuery,
        FetchCurrentUserForUseUserHookQueryVariables
    >,
    onCompleted?: (data: FetchCurrentUserForUseUserHookQuery) => any,
): QueryResult<FetchCurrentUserForUseUserHookQuery, Exact<{ [key: string]: never }>> => {
    const res = useFetchCurrentUserForUseUserHookQuery({
        ...queryOptions,
        onCompleted: data => {
            if (onCompleted) {
                onCompleted(data);
            }
        },
    });
    return res;
};

export default useUser;
