import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    AlertSeverity,
    ProfileValueOriginInput,
    ProfileValueOriginListDocument,
    ProfileValueOriginListQuery,
    ProfileValueOriginUpdateInput,
    useCreateProfileValueOriginMutation,
    useProfileValueOriginByIdLazyQuery,
    useProfileValueOriginListQuery,
    useUpdateProfileValueOriginMutation,
} from '~/schemaTypes';
import { ProfileValueOriginSelectorEnum } from '~/selectors/profileValueOrigin.selector';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import { ProfileValueOrigin } from '../types';

const ProfileValueOriginUpdateInputValidation = Yup.object().shape({
    label: Yup.object().shape({
        en: Yup.string().required('You need to enter a label'),
        es: Yup.string(),
    }),
});

const ProfileValueOriginInputValidation = Yup.object().shape({
    label: Yup.object()
        .required()
        .shape({
            en: Yup.string().required('You need to enter a label'),
            es: Yup.string(),
        }),
});

const isProfileValueOriginInput = (input: unknown): input is ProfileValueOriginInput => {
    let valid = false;
    try {
        ProfileValueOriginInputValidation.validateSync(input);
        valid = true;
    } catch (error: any) {
        TriggerGlobalAlert({
            severity: AlertSeverity.Error,
            message: JSON.stringify(error?.errors),
        });
    }
    return valid;
};

interface ProfileValueOriginFormInput {
    label: {
        en: string;
        es: string;
    };
}

export const ProfileValueOriginModal: React.FC<{
    isOpen: boolean;
    profileValueOrigin: ProfileValueOrigin | null;
    onClose: () => void;
}> = props => {
    const { isOpen, profileValueOrigin, onClose } = props;
    const [selectedProfileValueOrigin, setSelectedProfileValueOrigin] =
        React.useState<ProfileValueOrigin | null>(null);
    const [labelUsed, setLabelUsed] = useState<boolean>(false);

    const { data: profileValueOriginList, loading: profileValueOriginListLoading } =
        useProfileValueOriginListQuery();

    const [
        fetchProfileValueOriginById,
        { data: profileValueOriginData, loading: profileValueOriginLoading },
    ] = useProfileValueOriginByIdLazyQuery();

    const {
        register,
        handleSubmit,

        formState: { errors },
    } = useForm<ProfileValueOriginFormInput>({
        resolver: yupResolver(ProfileValueOriginUpdateInputValidation as any),
    });

    useEffect(() => {
        if (profileValueOrigin != null) {
            const { id: profileValueOriginId } = profileValueOrigin;
            fetchProfileValueOriginById({ variables: { input: { id: profileValueOriginId } } });
        }
    }, [fetchProfileValueOriginById, profileValueOrigin]);

    useEffect(() => {
        if (profileValueOriginData && profileValueOriginData.profileValueOrigin) {
            setSelectedProfileValueOrigin({ ...profileValueOriginData.profileValueOrigin });
        }
    }, [profileValueOriginData]);

    const handleClose = () => {
        onClose();
        setSelectedProfileValueOrigin(null);
        setLabelUsed(false);
    };

    const [createProfileValueOrigin, { loading: createProfileValueOriginLoading }] =
        useCreateProfileValueOriginMutation({
            onCompleted: () => {
                handleClose();
            },
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        handleClose();
                    },
                    message: `There was a problem saving the profile value origin: ${error.message}`,
                });
            },
            update: (cache, response) => {
                const newProfileValueOrigin =
                    response.data?.createProfileValueOrigin?.resourceCreated;
                if (response.data?.createProfileValueOrigin?.success && newProfileValueOrigin) {
                    const currentProfileValueOrigins = cache.readQuery<ProfileValueOriginListQuery>(
                        {
                            query: ProfileValueOriginListDocument,
                        },
                    );
                    if (currentProfileValueOrigins?.profileValueOrigins) {
                        cache.writeQuery<ProfileValueOriginListQuery>({
                            query: ProfileValueOriginListDocument,
                            data: {
                                profileValueOrigins: [
                                    ...currentProfileValueOrigins.profileValueOrigins,
                                    newProfileValueOrigin,
                                ],
                            },
                        });
                    }
                }
            },
        });

    const [updateProfileValueOrigin, { loading: updateProfileValueOriginLoading }] =
        useUpdateProfileValueOriginMutation({
            onCompleted: () => {
                handleClose();
            },
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        handleClose();
                    },
                    message: `There was a problem updating the profile value origin: ${error.message}`,
                });
            },
            update: (cache, response) => {
                const newProfileValueOrigin =
                    response.data?.updateProfileValueOrigin?.resourceUpdated;
                if (response.data?.updateProfileValueOrigin?.success && newProfileValueOrigin) {
                    const currentProfileValueOrigins = cache.readQuery<ProfileValueOriginListQuery>(
                        {
                            query: ProfileValueOriginListDocument,
                        },
                    );
                    if (currentProfileValueOrigins?.profileValueOrigins) {
                        const updatedProfileValueOrigins =
                            currentProfileValueOrigins.profileValueOrigins.map(
                                profileValueOrigin => {
                                    if (profileValueOrigin.id === newProfileValueOrigin.id) {
                                        return newProfileValueOrigin;
                                    }
                                    return profileValueOrigin;
                                },
                            );
                        cache.writeQuery<ProfileValueOriginListQuery>({
                            query: ProfileValueOriginListDocument,
                            data: {
                                profileValueOrigins: [...updatedProfileValueOrigins],
                            },
                        });
                    }
                }
            },
        });

    const isInEditMode = profileValueOrigin !== null;
    const onSubmit = (values: ProfileValueOriginUpdateInput) => {
        if (!isProfileValueOriginInput(values)) {
            return;
        }
        if (isInEditMode && selectedProfileValueOrigin !== null) {
            updateProfileValueOrigin({
                variables: {
                    updateProfileValueOriginInput: {
                        id: profileValueOrigin?.id,
                        data: values,
                    },
                },
            });
            return;
        }
        setLabelUsed(false);
        if (
            profileValueOriginList &&
            profileValueOriginList.profileValueOrigins.filter(
                t => t.label.en?.toLowerCase() === values?.label?.en.toLowerCase(),
            ).length > 0
        ) {
            setLabelUsed(true);
            return;
        }
        createProfileValueOrigin({
            variables: {
                input: values,
            },
        });
    };

    return (
        <Dialog open={isOpen} data-test={ProfileValueOriginSelectorEnum.MODAL}>
            <DialogTitleWithClose id="dialogTitle" onClose={handleClose}>
                {isInEditMode ? 'Edit Profile Value Origin' : 'Add Profile Value Origin'}
            </DialogTitleWithClose>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    {profileValueOriginLoading ||
                    profileValueOriginListLoading ||
                    createProfileValueOriginLoading ||
                    updateProfileValueOriginLoading ? (
                        <Loading height={140} />
                    ) : (
                        <Grid container>
                            <Grid item xs={12}>
                                <OutlinedSection title="Label">
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        label="English"
                                        fullWidth
                                        margin="dense"
                                        defaultValue={profileValueOrigin?.label?.en}
                                        {...register('label.en')}
                                        error={!!errors.label?.en}
                                        helperText={errors.label?.en?.message}
                                        data-test={ProfileValueOriginSelectorEnum.LABEL_EN}
                                    />
                                    {labelUsed && (
                                        <Typography variant="caption" color="error">
                                            Label already used. Please change.
                                        </Typography>
                                    )}
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        label="Spanish"
                                        fullWidth
                                        margin="dense"
                                        defaultValue={profileValueOrigin?.label?.es}
                                        {...register('label.es')}
                                        error={!!errors.label?.es}
                                        helperText={errors.label?.es?.message}
                                        data-test={ProfileValueOriginSelectorEnum.LABEL_ES}
                                    />
                                </OutlinedSection>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                        data-test={ProfileValueOriginSelectorEnum.SAVE}
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
