/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-bitwise */
// taken from: https://github.com/aws-samples/amazon-cognito-passwordless-auth
// and modified to fit the needs of this project under this license:
/**
 * Copyright Amazon.com, Inc. and its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You
 * may not use this file except in compliance with the License. A copy of
 * the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is
 * distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF
 * ANY KIND, either express or implied. See the License for the specific
 * language governing permissions and limitations under the License.
 */

/**
 * Base64 implementations below as atob and btoa don't work with unicode
 * and aren't available in all JS environments to begin with, e.g. React Native
 */
const _bufferFromBase64 = (characters: string, padChar = '') => {
    const map = characters
        .split('')
        .reduce(
            (acc, char, index) => Object.assign(acc, { [char.charCodeAt(0)]: index }),
            {} as { [key: number]: number },
        );
    return (base64: string) => {
        const paddingLength = padChar
            ? base64.match(new RegExp(`^.+?(${padChar}?${padChar}?)$`))![1].length
            : 0;
        let first: number;
        let second: number;
        let third: number;
        let fourth: number;
        return base64.match(/.{1,4}/g)!.reduce((acc, chunk, index) => {
            first = map[chunk.charCodeAt(0)];
            second = map[chunk.charCodeAt(1)];
            third = map[chunk.charCodeAt(2)];
            fourth = map[chunk.charCodeAt(3)];
            acc[3 * index] = (first << 2) | (second >> 4);
            acc[3 * index + 1] = ((second & 0b1111) << 4) | (third >> 2);
            acc[3 * index + 2] = ((third & 0b11) << 6) | fourth;
            return acc;
        }, new Uint8Array((base64.length * 3) / 4 - paddingLength));
    };
};

export const bufferFromBase64 = _bufferFromBase64(
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/',
    '=',
);
export const bufferFromBase64Url = _bufferFromBase64(
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_',
);

function* chunks(arr: Uint8Array, n: number): Generator<Uint8Array, void> {
    for (let i = 0; i < arr.length; i += n) {
        yield arr.subarray(i, i + n);
    }
}

const _bufferToBase64 = (characters: string, padChar = '') => {
    const map = characters
        .split('')
        .reduce(
            (acc, char, index) => Object.assign(acc, { [index]: char }),
            {} as { [key: number]: string },
        );
    return (base64: ArrayBuffer) => {
        const result = [] as string[];
        for (const chunk of chunks(new Uint8Array(base64), 3)) {
            result.push(map[chunk[0] >> 2]);
            result.push(map[((chunk[0] & 0b11) << 4) | (chunk[1] >> 4)]);
            result.push(
                chunk[1] !== undefined
                    ? map[((chunk[1] & 0b1111) << 2) | (chunk[2] >> 6)]
                    : padChar,
            );
            result.push(chunk[2] !== undefined ? map[chunk[2] & 0b111111] : padChar);
        }
        return result.join('');
    };
};

export const bufferToBase64 = _bufferToBase64(
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/',
    '=',
);
export const bufferToBase64Url = _bufferToBase64(
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_',
);
