import { displayDate } from '~/helpers/dateHelpers';

export const getDateFormatted = (date: string | undefined | Date) => {
    if (!date) {
        return date;
    }
    if (date instanceof Date) {
        return date;
    }
    return displayDate({ isoDateStr: date });
};
