import { CompoundQuestionsV2Query, FetchProfileDefsForCompoundQuestionsQuery } from '~/schemaTypes';
import { IFilterChange } from '~/components/CustomFilter/CustomFilter';

export type CompoundQuestion = NonNullable<
    CompoundQuestionsV2Query['compoundQuestionsV2']
>['results'][0];

export type CompoundQuestionProfileDef = CompoundQuestion['questionProfileDefs'][0];

export type ProfileDef = NonNullable<
    FetchProfileDefsForCompoundQuestionsQuery['userProfileDefs']
>[0];

export interface CompoundQuestionFormInput {
    label: {
        en: string;
        es?: string | undefined | null;
    };
    profileValueType: string;
    questionProfileDefs: {
        questionProfileDefId: string;
        labelOverride?:
            | {
                  en?: string | undefined | null;
                  es?: string | undefined | null;
              }
            | null
            | undefined;
        value?:
            | {
                  bool?: boolean | null | undefined;
              }
            | undefined
            | null;
    }[];
}

export type UseCompoundQuestionsSearch = {
    data: CompoundQuestion[];
    total: number;
    loading: boolean;
    page: number;
    setPage: (value: ((prevState: number) => number) | number) => void;
    rowsPerPage: number;
    setRowsPerPage: (value: ((prevState: number) => number) | number) => void;
    setFilter: (
        value:
            | ((
                  prevState: IFilterChange<CompoundQuestion> | null,
              ) => IFilterChange<CompoundQuestion> | null)
            | IFilterChange<CompoundQuestion>
            | null,
    ) => void;
};

export type UseCompoundQuestionsSearchProps = {
    onCompletedDataLoad: () => void;
};

export enum CompoundQuestionModalCloseType {
    DELETED,
}

export type CompoundQuestionModalProps = {
    id: string | null;
    compoundQuestion: CompoundQuestion | null;
    onClose: (type?: CompoundQuestionModalCloseType) => void;
};

export enum ErrorType {
    EXTRACT,
    CREATE,
    UPDATE,
    DELETE,
    EXTRACT_SURVEYS,
}
