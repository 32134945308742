import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Grid, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    input: {
        '.MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
            maxWidth: 'calc(100% - 35px)',
        },
    },
}));

const ContactInformation: React.FC = () => {
    const {
        formState: { errors },
        control,
    } = useFormContext();
    const { classes } = useStyles();
    return (
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <Controller
                    control={control}
                    name="supportEmail"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={!!errors.supportEmail}
                            helperText={errors.supportEmail?.message as string}
                            label="Support Email Address"
                            className={classes.input}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    control={control}
                    name="supportPhone"
                    render={({ field: { onChange, ...restProps } }) => (
                        <InputMask
                            {...restProps}
                            mask="999-999-9999"
                            onChange={onChange}
                            maskPlaceholder={null}
                        >
                            <TextField
                                {...restProps}
                                fullWidth
                                error={!!errors.supportPhone}
                                helperText={errors.supportPhone?.message as string}
                                label="Support Phone Number (optional)"
                                className={classes.input}
                            />
                        </InputMask>
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    control={control}
                    name="website"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={!!errors.website}
                            helperText={errors.website?.message as string}
                            label="Website (Optional)"
                            className={classes.input}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default ContactInformation;
