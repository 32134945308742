/* eslint-disable no-empty-function */
/* eslint-disable no-useless-constructor */
/* eslint-disable max-classes-per-file */
import { bufferFromBase64Url } from './base64';

export type Json = null | string | number | boolean | Json[] | JsonObject;

/** JSON Object type */
export type JsonObject = { [name: string]: Json };

export type BaseJwtPayload = {
    /** (issuer): Issuer of the JWT */
    iss?: string;

    /** (subject): Subject of the JWT (the user) */
    sub?: string;

    /** (audience): Recipient for which the JWT is intended */
    aud?: string;

    /** (expiration time): Time after which the JWT expires */
    exp?: number;

    /** (not before time): Time before which the JWT must not be accepted for processing */
    nbf?: number;

    /** (issued at time): Time at which the JWT was issued; can be used to determine age of the JWT */
    iat?: number;

    /** (JWT ID): Unique identifier; can be used to prevent the JWT from being replayed (allows a token to be used only once) */
    jti?: string;
};

export const parseJwtPayload = <T extends JsonObject = JsonObject>(jwt: string) => {
    const parts = jwt.split('.');
    const payload = parts[1];
    if (!payload) {
        throw new Error('Invalid JWT');
    }
    return JSON.parse(new TextDecoder().decode(bufferFromBase64Url(payload))) as T;
};

export class OpaqueWebToken {
    constructor(public readonly token: string) {}
}

export class JsonWebToken<T extends JsonObject = BaseJwtPayload> extends OpaqueWebToken {
    constructor(token: string) {
        super(token);
        this.payload = parseJwtPayload<T>(token);
    }

    payload: T;

    initializationTime() {
        return typeof this.payload?.iat === 'number' ? this.payload.iat * 1000 : undefined;
    }

    initializationDate() {
        const iat = this.initializationTime();
        return iat != null ? new Date(iat) : undefined;
    }

    ageMinutes() {
        const iat = this.initializationTime();
        return iat != null ? (Date.now() - iat) / 60_000 : undefined;
    }

    expirationTime() {
        return typeof this.payload?.exp === 'number' ? this.payload.exp * 1000 : undefined;
    }

    expirationDate() {
        const exp = this.expirationTime();
        return exp != null ? new Date(exp) : undefined;
    }

    isExpired() {
        const exp = this.expirationTime();
        return exp != null ? Date.now() >= exp : undefined;
    }
}
