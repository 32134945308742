import * as Yup from 'yup';

export const PAYMENT_FORM_SCHEMA = Yup.object().shape({
    BillDotComNameOnCheck: Yup.string().required('Name is a required field'),
    BillDotComAddress1: Yup.string().required('Address 1 is a required field'),
    BillDotComAddress2: Yup.string().optional(),
    BillDotComAddressCity: Yup.string().required('City is a required field'),
    BillDotComAddressState: Yup.string().required('State is a required field'),
    BillDotComAddressZip: Yup.string().required('Zip is a required field'),
});
