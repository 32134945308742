import React from 'react';
import { Grid, Typography } from '@mui/material';
import useStyles from '../styles';

type SuccessScreenProps = {
    customSuccessText?: string | null;
    patientId: string;
};

const SuccessScreen: React.FC<SuccessScreenProps> = props => {
    const { customSuccessText, patientId } = props;
    const { classes } = useStyles();

    return (
        <Grid container flexDirection="column" spacing={2}>
            <Grid item>
                <Typography className={classes.thankContainer}>
                    Confirmation Id: {patientId}
                </Typography>
            </Grid>
            <Grid item>
                <Typography
                    className={classes.thankContainer}
                    dangerouslySetInnerHTML={{
                        __html: customSuccessText ?? '',
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default SuccessScreen;
