import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    Paper,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { Dispatch, SetStateAction } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { displayDate } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import { Indication, Medication } from '~/schemaTypes';

import { tableHeaderStyle } from '../PatientClinicalSummary';

const useStyles = makeStyles()(theme => ({
    value: {
        padding: theme.spacing(1),
        border: '1px solid lightgray',
    },
    valueLabel: {
        fontWeight: 600,
    },
    route: {
        border: '1px solid lightgray',
        margin: `20px 0`,
        position: 'relative',
        padding: '10px 5px',
    },
    routeLabel: {
        position: 'absolute',
        top: 0,
        left: '10px',
        padding: '0 5px',
        transform: 'translate(0, -50%)',
        backgroundColor: theme.palette.background.paper,
        fontWeight: 700,
    },
    textLabel: {
        marginBottom: '5px',
        fontSize: '16px',
        fontWeight: 600,
    },
    text: {
        padding: '15px 10px',
    },
    margin: {
        marginBottom: '20px',
    },
}));

type MedicationModalProps = {
    setShow: Dispatch<SetStateAction<boolean>>;
    medication: Medication;
};

const MedicationModal: React.FC<MedicationModalProps> = ({ setShow, medication }) => {
    const { classes } = useStyles();

    const stringOrDefaultValue = (str: string | null | undefined): string => str || '-';
    const formatDate = ({
        date,
        format = 'MM/DD/YYYY',
    }: {
        date: Date;
        format?: string;
    }): string => {
        if (!date) {
            return '-';
        }
        return displayDate({
            isoDateStr: medication.startDate,
            format,
        });
    };

    return (
        <>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setShow(false)}>
                <Grid container alignItems="center" justifyContent="space-around" spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Typography className={classes.valueLabel}>Status</Typography>
                        <Typography className={classes.value}>{medication.status}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography className={classes.valueLabel}>Start Date</Typography>
                        <Typography className={classes.value}>
                            {formatDate({ date: medication.startDate })}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography className={classes.valueLabel}>End Date</Typography>
                        <Typography className={classes.value}>
                            {formatDate({ date: medication.endDate })}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitleWithClose>

            <DialogContent>
                <>
                    <Grid container alignItems="center" spacing={3}>
                        <Grid item>
                            <Typography className={classes.valueLabel}>Medication</Typography>
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                label="Perscription"
                                labelPlacement="end"
                                control={
                                    <Checkbox
                                        id="perscription"
                                        name="perscription"
                                        checked={Boolean(medication.prescription)}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.margin}>
                        <Grid item xs={12}>
                            <Paper variant="outlined">
                                <Typography className={classes.text}>
                                    {medication.product?.name}
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" className={classes.margin} spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Typography className={classes.valueLabel}>Code</Typography>
                            <Typography className={classes.value}>
                                {medication.product?.code}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography className={classes.valueLabel}>Code system Name</Typography>
                            <Typography className={classes.value}>
                                {medication.product?.codeSystemName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.margin}>
                        <Grid item xs={12} className={classes.textLabel}>
                            Free Text Sig
                        </Grid>

                        <Grid item xs={12}>
                            <Paper variant="outlined">
                                <Typography className={classes.text}>
                                    {medication.freeTextSig}
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" className={classes.margin} spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Typography className={classes.valueLabel}>Dose quantity</Typography>
                            <Typography className={classes.value}>
                                {medication.dose?.quantity}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography className={classes.valueLabel}>Units:</Typography>
                            <Typography className={classes.value}>
                                {medication.dose?.units}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid className={classes.route} container alignItems="center" spacing={2}>
                        <Typography className={classes.routeLabel}>Route</Typography>
                        <Grid item xs={12}>
                            <Typography className={classes.valueLabel}>Name</Typography>
                            <Typography className={classes.value}>
                                {medication.route?.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography className={classes.valueLabel}>Code</Typography>
                            <Typography className={classes.value}>
                                {medication.route?.code}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography className={classes.valueLabel}>Code System Name</Typography>
                            <Typography className={classes.value}>
                                {medication.route?.codeSystemName}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid className={classes.route} container alignItems="center" spacing={2}>
                        <Typography className={classes.routeLabel}>Frequency</Typography>
                        <Grid item xs={12}>
                            <Typography className={classes.valueLabel}>Period</Typography>
                            <Typography className={classes.value}>
                                {stringOrDefaultValue(medication.frequency?.period)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography className={classes.valueLabel}>Period Max</Typography>
                            <Typography className={classes.value}>
                                {stringOrDefaultValue(medication.frequency?.periodMax)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography className={classes.valueLabel}>Unit</Typography>
                            <Typography className={classes.value}>
                                {stringOrDefaultValue(medication.frequency?.unit)}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid>
                        {Array.isArray(medication.indications) && (
                            <MaterialTable
                                icons={tableIcons as MaterialTableProps<any>['icons']}
                                columns={[
                                    {
                                        title: 'Name',
                                        field: 'name',
                                        align: 'center',
                                        sorting: false,
                                        render: ({ name }) => stringOrDefaultValue(name),
                                    },
                                    {
                                        title: 'Code',
                                        field: 'code',
                                        align: 'center',
                                        sorting: false,
                                        render: ({ code }) => stringOrDefaultValue(code),
                                    },
                                    {
                                        title: 'Code System Name',
                                        field: 'codeSystemName',
                                        align: 'center',
                                        sorting: false,
                                        render: ({ codeSystemName }) =>
                                            stringOrDefaultValue(codeSystemName),
                                    },
                                ]}
                                data={(medication.indications as Indication[]) ?? []}
                                options={{
                                    thirdSortClick: false,
                                    search: false,
                                    paging: false,
                                    showTitle: false,
                                    toolbar: false,
                                    headerStyle: tableHeaderStyle,
                                }}
                            />
                        )}
                    </Grid>
                </>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShow(false)} color="secondary" variant="outlined">
                    Close
                </Button>
            </DialogActions>
        </>
    );
};

export default MedicationModal;
