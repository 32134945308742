export enum MessageCenterTemplatesEnum {
    NAV_MESSAGE_CENTER_TEMPLATES = 'message-center-templates',
    CONTAINER = 'message-center-templates-container',
    ADD_MESSAGE_CENTER_TEMPLATE = 'add-message-center-template', //
    SAVE_AND_CLOSE = 'save-and-close-button', //
}

export const MessageCenterTemplatesSelectors = {
    NAV_MESSAGE_CENTER_TEMPLATES: `a[data-test="${MessageCenterTemplatesEnum.NAV_MESSAGE_CENTER_TEMPLATES}"]`,
    ADD_NEW_INSTANCE_BUTTON: `div[data-test="${MessageCenterTemplatesEnum.CONTAINER}"] button[title="Add New Message Center Template"]`,
    ADD_MESSAGE_CENTER_TEMPLATE: `p[data-test="${MessageCenterTemplatesEnum.ADD_MESSAGE_CENTER_TEMPLATE}"]`,
    SAVE_AND_CLOSE_BUTTON: `button[data-test="${MessageCenterTemplatesEnum.SAVE_AND_CLOSE}"]`,
    SEARCH_INPUT: `div[data-test="${MessageCenterTemplatesEnum.CONTAINER}"] input[placeholder="Search"]`,
    DELETE_INSTANCE_BUTTON: `div[data-test="${MessageCenterTemplatesEnum.CONTAINER}"] button[title="Delete Message Center Template"]`,
};
