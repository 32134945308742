import * as yup from 'yup';
import { IAccessCodeFormValue } from './types';

export const VALIDATION_SCHEMA: yup.ObjectSchema<IAccessCodeFormValue> = yup
    .object({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        birthDate: yup.string().required(),
        unitName: yup.string().required(),
    })
    .required();
