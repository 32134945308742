import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useStyles } from '~/views/AffiliateClaimSubmission/styles';
import { Link, Typography, Grid } from '@mui/material';
import FileUpload from '~/views/AffiliateClaimSubmission/components/FileUpload';

type AffiliateCareViewProps = {
    handleClearForm: () => void;
    isProcessing: boolean;
    register: UseFormRegisterReturn;
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const AffiliateCareView: React.FC<AffiliateCareViewProps> = props => {
    const { handleClearForm, isProcessing, register, handleFileChange } = props;
    const { classes } = useStyles();
    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={12} container justifyContent="center">
                <Grid item>
                    <FileUpload
                        handleClearForm={handleClearForm}
                        isProcessing={isProcessing}
                        register={register}
                        handleFileChange={handleFileChange}
                        title="Upload Batches of Claims using CSV Template"
                    />
                    <Typography className={classes.headingTitle} variant="body2" align="center">
                        CSV Template Found{' '}
                        <Link
                            href="https://www.wildflowerhealth.com/cms/resources/admin/affiliate-claim-submission-template.csv"
                            variant="body2"
                        >
                            here
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AffiliateCareView;
