import React from 'react';
import { Link, Grid, Typography } from '@mui/material';
import useStyles from '../styles';
import { useVCFContext } from '../hooks/useVCF';

type SupportBlockProps = {
    isMobileView: boolean;
    align?: 'center' | 'right' | 'left';
};

const SupportBlock: React.FC<SupportBlockProps> = props => {
    const { isMobileView, align } = props;
    const { classes } = useStyles();
    const { email } = useVCFContext();
    return (
        <Grid container justifyContent="center">
            <Grid item xs={10} md={12} className={classes.supportBlock}>
                <Typography
                    variant="body2"
                    style={{ textAlign: align ?? (isMobileView ? 'center' : 'right') }}
                    fontWeight="bold"
                >
                    Questions? Send us an email at{' '}
                    <Link underline="always" href={`mailto:${email}`}>
                        {email}
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default SupportBlock;
