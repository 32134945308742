import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    titleDivider: {
        marginBottom: '20px',
    },
    buttonWrap: {
        paddingLeft: '10px',
        paddingBottom: '10px',
    },
    actionButtons: {
        marginTop: '5px',
        marginLeft: '5px',
        marginRight: '5px',
    },
    addTodoGroupBtn: {
        margin: '10px',
    },
    removeTodoGroupBtnWrap: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '10px',
    },
    todoTable: {
        margin: '10px',
    },
    addTodoBtnWrap: {
        marginLeft: '10px',
    },
    tableLabel: {
        color: 'blue',
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
            filter: 'brightness(50%)',
        },
    },
    hiddenInput: {
        display: 'none',
    },
    tableTag: {
        margin: '0 10px 10px 0',
    },
    sortableList: {
        marginTop: '20px',
    },
});
