import * as Yup from 'yup';
import { ClientSelectionType } from '~/enums/enums';
import { AdvocateTaskTemplateWhenType } from '~/schemaTypes';

export const CREATE_NEW_TYPE = 'Create New Task Type';
export const ADVOCATE_TASK_TEMPLATE_SCHEMA = Yup.object().shape({
    label: Yup.string().required('Label is required'),
    description: Yup.string().required('Label is required'),
    newType: Yup.string().when('typeId', ([typeId], schema) => {
        if (typeId === CREATE_NEW_TYPE) {
            return schema.required('Type is required');
        }
        return schema;
    }),
    typeId: Yup.string().required('Type is required'),
    whenType: Yup.string().required('Type is required'),
    durationValue: Yup.number().when('whenType', ([whenType], schema) => {
        if (whenType === AdvocateTaskTemplateWhenType.Intervention) {
            return schema.required('Duration is required');
        }
        return schema;
    }),
    shouldNotify: Yup.boolean().default(false).required('Should Notify is required'),
    whenTimeline: Yup.object().when('whenType', ([whenType], schema) => {
        if (whenType === AdvocateTaskTemplateWhenType.Timeline) {
            return schema.shape({
                timelineId: Yup.string().required('Timeline is required'),
                clientSelectionType: Yup.mixed()
                    .oneOf(Object.values(ClientSelectionType))
                    .required(),
                includeApps: Yup.array()
                    .of(Yup.string())
                    .when('clientSelectionType', ([clientSelectionType], schema) => {
                        if (clientSelectionType === ClientSelectionType.IncludeCertainApps) {
                            return schema
                                .min(1, 'Must select at least one app to include')
                                .required();
                        }
                        return schema;
                    }),
                excludeApps: Yup.array()
                    .of(Yup.string())
                    .when('clientSelectionType', ([clientSelectionType], schema) => {
                        if (clientSelectionType === ClientSelectionType.ExcludeCertainApps) {
                            return schema
                                .min(1, 'Must select at least one app to exclude')
                                .required();
                        }
                        return schema;
                    }),
            });
        }
        return schema;
    }),
});
