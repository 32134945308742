import React from 'react';
import { Grid } from '@mui/material';
import Header from '~/views/VirtualCareSurvey/components/Header';
import useStyles from '~/views/VirtualCareSurvey/styles';
import { useVCFContext } from '../hooks/useVCF';

type VirtualCareSurveyWrapperProps = {
    children: React.ReactNode;
    showFormDescription?: boolean;
    header?: React.ReactNode;
    formDescriptionText?: string;
};

const VirtualCareSurveyWrapper: React.FC<VirtualCareSurveyWrapperProps> = props => {
    const { formDescriptionText, header, children, showFormDescription = false } = props;
    const { isMobileView } = useVCFContext();
    const { classes } = useStyles();
    const formContainer = React.useRef<HTMLDivElement>(null);
    return (
        <div className={classes.container} ref={formContainer}>
            <Grid
                className={classes.form}
                container
                justifyContent={isMobileView ? 'flex-start' : 'center'}
            >
                {header ?? (
                    <Header
                        showFormDescription={showFormDescription}
                        formDescriptionText={formDescriptionText}
                    />
                )}
                <Grid container className={classes.formContainer}>
                    {children}
                </Grid>
            </Grid>
        </div>
    );
};

export default VirtualCareSurveyWrapper;
