import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { EligibilityRecheckModal } from '../../../VirtualCareSurvey/EligibilityRecheckModal';

type RecheckEligibilityButtonProps = {
    patientId: string;
};

const RecheckEligibilityButton: React.FC<RecheckEligibilityButtonProps> = ({ patientId }) => {
    const [modalOpen, setModalOpen] = useState(false);
    return (
        <>
            <IconButton
                onClick={() => {
                    setModalOpen(true);
                }}
            >
                <Refresh />
            </IconButton>
            <EligibilityRecheckModal
                patientId={patientId}
                isOpen={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            />
        </>
    );
};

export default RecheckEligibilityButton;
