/* eslint-disable @typescript-eslint/no-use-before-define */

import {
    Alert,
    Button,
    Card,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    Snackbar,
    Typography,
} from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment-timezone';
import React, { FunctionComponent, Suspense, useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Layout from '~/components/Layout/Layout';
import Loading from '~/components/Loading/Loading';
import { AuthWrapper, VCViewWrapper } from '~/components/PrivateRoute/PrivateRoute';
import UploadWidget from '~/components/UploadWidget/UploadWidget';
import ReferenceLinksComponent from '~/components/ReferenceLinksComponent/ReferenceLinksComponent';
import ErrorHandler from '~/Error';
import { AlertSeverity, useFetchAlertStateQuery } from '~/schemaTypes';
import { CommonEnum } from '~/selectors';
import {
    AccessToken,
    AuthTokens,
    BraveNotificationSent,
    GlobalAlertOpen,
    GlobalConfirm,
    GlobalImagePreview,
    IdToken,
    RefreshToken,
    TriggerGlobalAlert,
    TriggerGlobalConfirm,
} from '~/state';
import { GlobalBannerComponent } from '~/state/GlobalBannerComponent';
import PatientInvite from '~/views/PatientInvite/PatientInvite';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CompoundQuestions from '~/views/ConfigDashboard/CompoundQuestions/CompoundQuestions';
import {
    VCAffiliates,
    VCAffiliatesEdit,
    VCAffiliatesAddNew,
} from '~/views/ConfigDashboard/VCAffiliates';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';
import { AffiliateClaimSubmission } from 'src/views/AffiliateClaimSubmission';
import WildTheme from './theme/WildTheme';
import AcceptInvite from './views/AcceptInvite/AcceptInvite';
import ResetPassword from './views/ResetPassword/ResetPassword';
import AddNote from './views/AddNote/AddNote';
import ConfigDashboardTable from './views/ConfigDashboard/ConfigDashboardTable';
import { useUserPermissions } from './hooks';
import ConfigAppSummary from './views/ConfigDashboard/ConfigApps/ConfigAppSummary/ConfigAppSummary';
import ConfigValueDefs from './views/ConfigDashboard/ConfigValueDefs/ConfigValueDefs';
import Expressions from './views/ConfigDashboard/ConfigExpressions/ConfigExpressions';
import Expression from './views/ConfigDashboard/ConfigExpressions/ConfigExpression/ConfigExpression';
import ProfileDefs from './views/ConfigDashboard/ProfileDefs/ProfileDefs';
import CareTeamContacts from './views/ConfigDashboard/CareTeamContacts/CareTeamContacts';
import SurveyTaker from './views/SurveyTaker/SurveyTaker';
import SurveyDefs from './views/ConfigDashboard/SurveyDefs/SurveyDefs';
import SurveyEditor from './views/ConfigDashboard/SurveyDefs/SurveyEditorV2/SurveyEditor';
import Staff from './views/ConfigDashboard/Staff/Staff';
import Roles from './views/ConfigDashboard/Roles/Roles';
import Timelines from './views/ConfigDashboard/Timelines/Timelines';
import TimelineBuilder from './views/ConfigDashboard/Timelines/TimelineBuilder/TimelineBuilder';
import BioScreen from './views/ConfigDashboard/BioScreen/BioScreen';
import BioScreenBuilder from './views/ConfigDashboard/BioScreen/BioScreenBuilder/BioScreenBuilder';
import Tags from './views/ConfigDashboard/Tags/Tags';
import TagEditor from './views/ConfigDashboard/Tags/TagEditor/TagEditor';
import TagTriggers from './views/ConfigDashboard/Triggers/TagTriggers/TagTriggers';
import TagTriggerEditor from './views/ConfigDashboard/Triggers/TagTriggers/TagTriggerEditor';
import CareTeamMemberTypes from './views/ConfigDashboard/CareTeamMemberTypes/CareTeamMemberTypes';
import CareTeams from './views/ConfigDashboard/CareTeams/CareTeams';
import CareTeamBuilder from './views/ConfigDashboard/CareTeams/CareTeamBuilder/CareTeamBuilder';
import CareTeamMembers from './views/ConfigDashboard/CareTeamMembers/CareTeamMembers';
import { CarePlan, CarePlanList } from './views/ConfigDashboard/CarePlans';
import ConditionTypes from './views/ConfigDashboard/ConditionTypes/ConditionTypes';
import ArticleCategories from './views/ConfigDashboard/ArticleCategory/ArticleCategories';
import ReportingCategories from './views/ConfigDashboard/ReportingCategory/ReportingCategories';
import Tokens from './views/ConfigDashboard/Tokens/Tokens';
import { TokenEditor } from './views/ConfigDashboard/Tokens/components/TokenEditor';
import SimpleContents from './views/ConfigDashboard/SimpleContent/SimpleContent';
import Articles from './views/ConfigDashboard/Articles/Articles';
import ArticleApprovals from './views/ConfigDashboard/ArticleApproval/ArticleApprovals';
import { ArticlePromotion, ArticlePromotionList } from './views/ConfigDashboard/ArticlePromotions';
import VariantEditor from './views/ConfigDashboard/Articles/Editors/VariantEditor';
import ArticleEditor from './views/ConfigDashboard/Articles/Editors/ArticleEditor';
import ArticleGroups from './views/ConfigDashboard/ArticleGroups/ArticleGroups';
import ArticleGroupEditor from './views/ConfigDashboard/ArticleGroups/ArticleGroupEditor';
import MessageCenterTemplates from './views/ConfigDashboard/MessageCenterTemplates/MessageCenterTemplates';
import CreateMessageCenterTemplate from './views/ConfigDashboard/MessageCenterTemplates/CreateMessageCenterTemplate';
import ContentDirectorys from './views/ConfigDashboard/ContentDirectory/ContentDirectorys';
import ContentDirectoryEditor from './views/ConfigDashboard/ContentDirectory/ContentDirectoryEditor';
import TimelineViewer from './views/ConfigDashboard/TimelineViewer/TimelineViewer';
import CreateNewOrder from './views/Dashboard/Orders/CreateNewOrder';
import NoteTags from './views/ConfigDashboard/PatientNoteTags/PatientNoteTags';
import { PatientActionTypes } from './views/ConfigDashboard/PatientActionTypes/PatientActionTypes';
import ProfileValueOrigins from './views/ConfigDashboard/ProfileValueOrigin/ProfileValueOrigins';
import ReferenceLinks from './views/ConfigDashboard/ReferenceLinks/ReferenceLinks';
import ReferenceLinkEditor from './views/ConfigDashboard/ReferenceLinks/ReferenceLinkEditor/ReferenceLinkEditor';
import ReferenceLinkCategories from './views/ConfigDashboard/ReferenceLinkCategories/ReferenceLinkCategories';
import Patients from './views/Dashboard/Patients/Patients';
import Patient from './views/Dashboard/Patients/Patient/Patient';
import AdvocateTaskList from './views/Dashboard/AdvocateTaskList/AdvocateTaskList';
import Measurements from './views/Dashboard/Measurements/Measurements';
import Surveys from './views/Dashboard/Survey/Surveys';
import Orders from './views/Dashboard/Orders/Orders';
import Appointments from './views/Dashboard/Appointments/Appointments';
import Organizations from './views/ConfigDashboard/Organizations/Organizations';
import PortalSettings from './views/Dashboard/PortalSettings/PortalSettings';
import MessageCenter from './views/Dashboard/MessageCenter/MessageCenter';
import Welcome from './views/Dashboard/Welcome/Welcome';
import PatientOverview from './views/Dashboard/Patients/Patient/PatientOverview/PatientOverview';
import PatientDetails from './views/Dashboard/Patients/Patient/PatientDetails/PatientDetails';
import PatientAppointments from './views/Dashboard/Patients/Patient/PatientAppointments/PatientAppointments';
import PatientMeasurements from './views/Dashboard/Patients/Patient/PatientMeasurements/PatientMeasurements';
import PatientSurveyResponses from './views/Dashboard/Patients/Patient/PatientSurveyResponses/PatientSurveyResponses';
import PatientOrders from './views/Dashboard/Patients/Patient/PatientOrders/PatientOrders';
import PatientNotes from './views/Dashboard/Patients/Patient/PatientNotes/PatientNotes';
import PatientProfileVariables from './views/Dashboard/Patients/Patient/PatientProfileVariables/PatientProfileVariables';
import PatientTags from './views/Dashboard/Patients/Patient/PatientTags/PatientTags';
import PatientTodos from './views/Dashboard/Patients/Patient/PatientTodos/PatientTodos';
import PatientArticleFeed from './views/Dashboard/Patients/Patient/PatientArticleFeed/PatientArticleFeed';
import PatientActions from './views/Dashboard/Patients/Patient/PatientActions/PatientActions';
import { PatientCareTeam } from './views/Dashboard/Patients/Patient/PatientCareTeam/PatientCareTeam';
import PatientAppEvents from './views/Dashboard/Patients/Patient/PatientAppEvents/PatientAppEvents';
import { PatientHATasks } from './views/Dashboard/Patients/Patient/PatientHATasks/PatientHATasks';
import PatientClinicalSummaries from './views/Dashboard/Patients/Patient/PatientClinicalSummaries/PatientClinicalSummaries';
import PatientClinicalSummary from './views/Dashboard/Patients/Patient/PatientClinicalSummary/PatientClinicalSummary';
import HealthPlans from './views/Dashboard/PortalSettings/HealthPlans/Plans';
import Providers from './views/Dashboard/PortalSettings/Providers/Providers';
import Devices from './views/Dashboard/PortalSettings/Devices/Devices';
import { MyAccount } from './views/Dashboard/PortalSettings/MyAccount/MyAccount';
import { CreateTestAppUser } from './views/SystemAdmin/CreateTestAppUser';
import BatchTasks from './views/SystemAdmin/BatchTasks/BatchTasks';
import { EnvLabel } from './components/EnvLabel/EnvLabel';
import Highlights from './views/ConfigDashboard/Highlights/Highlights';
import { HighlightEditor } from './views/ConfigDashboard/Highlights/HighlightEditor';
import PatientHighlights from './views/Dashboard/Patients/Patient/PatientHighlights/PatientHighlights';
import AppointmentTemplateEditor from './views/ConfigDashboard/Highlights/HighlightEditor/AppointmentTemplateEditor';
import DateTriggers from './views/ConfigDashboard/Triggers/DateTriggers/DateTriggers';
import DateTriggerEditor from './views/ConfigDashboard/Triggers/DateTriggers/DateTriggerEditor';
import { CreateAdvocateTask } from './views/ConfigDashboard/AdvocateTasks/CreateAdvocateTask';
import { AdvocateTask } from './views/ConfigDashboard/AdvocateTasks/AdvocateTask';
import AdvocateTaskTemplate from './views/ConfigDashboard/AdvocateTasks/AdvocateTaskTemplate/AdvocateTaskTemplate';
import { AdvocateTaskTemplates } from './views/ConfigDashboard/AdvocateTasks/AdvocateTaskTemplates';
import { VCEligibilityWhitelistList } from './views/ConfigDashboard/VCEligibilityWhitelist/VCEligibilityWhitelistList';
import VCZipCodeToLocationList from './views/ConfigDashboard/VCZipCodeToLocation/VCZIPCodeToLocationList';
import MessageTemplates from './views/ConfigDashboard/MessageTemplates/MessageTemplates';
import { MessageTemplateEditor } from './views/ConfigDashboard/MessageTemplates/MessageEditor';
import EligibilityMatch from './views/ConfigDashboard/EligibilityMatch/EligibilityMatch';
import EligibilityMatches from './views/ConfigDashboard/EligibilityMatch/Matches/Matches';
import EligibilityMatchQueue from './views/ConfigDashboard/EligibilityMatch/MatchQueue/MatchQueue';
import EligibilityNoMatches from './views/ConfigDashboard/EligibilityMatch/NoMatches/NoMatches';
import EligibilityMatchManage from './views/ConfigDashboard/EligibilityMatch/MatchQueue/MatchManage';
import MatchComplete from './views/ConfigDashboard/EligibilityMatch/MatchQueue/MatchComplete';
import AccessCode from './views/ConfigDashboard/EligibilityMatch/AccessCode/AccessCode';
import { VCAffiliateClaimSubmissions } from './views/ConfigDashboard/VCAffiliateClaimSubmissions';
import MassUpdatePatientLists from './views/SystemAdmin/MassUpdate/Patients/PatientLists';
import MassUpdatePatientListEditor from './views/SystemAdmin/MassUpdate/Patients/PatientListEditor';
import MassUpdateTasks from './views/SystemAdmin/MassUpdate/Tasks/Tasks';
import MassUpdateTaskEditor from './views/SystemAdmin/MassUpdate/Tasks/CreateTask/MassUpdateTaskEditor';
import EventTriggers from './views/ConfigDashboard/Triggers/EventTriggers/EventTriggers';
import EventTriggerEditor from './views/ConfigDashboard/Triggers/EventTriggers/EventTriggerEditor';
import TrendTriggers from './views/ConfigDashboard/Triggers/TrendTriggers/TrendTriggers';
import TrendTriggerEditor from './views/ConfigDashboard/Triggers/TrendTriggers/TrendTriggerEditor';
import { Calendars } from './views/ConfigDashboard/Calendars/Calendars';
import AffiliateWelcome from './views/Dashboard/Welcome/AffiliateWelcome';
import AffiliateCarePatients from './views/AffiliateCare/Patients/Patients';
import { SLAs } from './views/ConfigDashboard/SLAs/SLAs';
import SLATimers from './views/Dashboard/SLATimers/SLATimers';
import PatientUnsubscribeEmail from './views/Unsubscribe/PatientUnsubscribe';
import PatientCallListGrid from './views/Dashboard/PatientCallList/PatientCallListGrid';
import PatientCallListPreview from './views/Dashboard/PatientCallList/PatientCallListPreview/PatientCallListPreview';
import AffiliateCarePatient from './views/AffiliateCare/Patients/Patient/Patient';
import AffiliateCareInvoices from './views/AffiliateCare/Invoices/Invoices';
import AffiliateCareInvoice from './views/AffiliateCare/Invoices/Invoice/Invoice';
import AffiliateCareClaims from './views/AffiliateCare/Claims/Claims';
import AffiliateCareClaimsBuilder from './views/AffiliateCare/ClaimsBuilder/ClaimsBuilder';
import AffiliateOnboarding from './views/AffiliateCare/Onboarding/Onboarding';
import { OnboardingRoute } from './views/AffiliateCare/components/OnbordingRoute/OnboardingRoute';
import './global.css';
import { PatientChat } from './views/Dashboard/Patients/Patient/PatientChat/PatientChat';
import { useCheckIsLargeOrgSelected } from './hooks/useCheckIsLargeOrgSelected';
import { ChatSlas } from './views/ConfigDashboard/ChatSlas/ChatSlas';
import ArticleExportList from './views/ConfigDashboard/ArticleExport/ArticleExportList';
import { PatientTranscript } from './views/Dashboard/Patients/Patient/PatientTranscript/PatientTranscript';
import AffiliateCreation from './views/AffiliateCare/Onboarding/AffiliateCreation/AffiliateCreation';
import LactationConsultantsSettings from './views/AffiliateCare/Settings/LactationConsultants/LactationConsultants';
import AffiliateRoutingSettings from './views/AffiliateCare/Settings/Routing/Routing';
import ServiceTypesAndLocationsSettings from './views/AffiliateCare/Settings/ServiceTypesAndLocations/ServiceTypesAndLocations';
import AffiliateSupportSettings from './views/AffiliateCare/Settings/Support/Support';
import AffiliateNotificationsSettings from './views/AffiliateCare/Settings/Notifications/Notification';
import VCPatients from './views/Dashboard/VCPatients/VCPatients';
import VCPatient from './views/Dashboard/VCPatients/VCPatient';
import VCInvoices from './views/Dashboard/VCPatients/Invoices';
import VCInvoice from './views/Dashboard/VCPatients/Invoice';
import VCClaims from './views/Dashboard/VCPatients/Claims';
import Login from './components/Login/Login';
import VCClaimBuilder from './views/Dashboard/VCPatients/ClaimBuilder';
import AffiliateVCF from './views/VirtualCareSurvey/views/AffiliateVCF';
import GeneralVCF from './views/VirtualCareSurvey/views/GeneralVCF';
import DoulaVCF from './views/VirtualCareSurvey/views/DoulaVCF';
import VCFEligibility from './views/ConfigDashboard/VCFEligibility/VCFEligibility';
import Applications from './views/ConfigDashboard/Applications/Applications';
import ApplicationEditor from './views/ConfigDashboard/Applications/ApplicationEditor';
import DoulaWelcome from './views/Doula/Welcome/Welcome';
import DoulaInvoices from './views/Doula/Invoices/Invoices';
import DoulaNavigationPanel from './views/Doula/components/NavigationPanel';
import DoulaClaims from './views/Doula/Claims/Claims';
import { CognitoAccessToken, CognitoIdToken } from './state/auth/cognito.model';
import { OpaqueWebToken } from './utils/jwt.model';
import { log } from './utils/log.svc';

interface BraveNavigator extends Navigator {
    brave?: {
        isBrave: () => boolean;
    };
}

const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));
const ConfigDashboard = React.lazy(() => import('./views/ConfigDashboard/ConfigDashboard'));
const FrontDesk = React.lazy(() => import('./views/FrontDesk/ConfigFrontDesk'));
const SystemAdmin = React.lazy(() => import('./views/SystemAdmin/ConfigSystemAdmin'));

moment.tz.setDefault('UTC');
moment.relativeTimeThreshold('m', 59);
moment.relativeTimeThreshold('h', 23);

const App: React.FC = () => {
    const { pagePermissions, groupPermissions } = useUserPermissions();
    const [links, setLinks] = useState([]);
    const { allowSettings: showAffiliateSettings, isDenied: deniedClaimsUploading } =
        useAffiliateCareView();

    useEffect(() => {
        const checkBrowser = async () => {
            const braveNavigator: BraveNavigator = navigator;
            const isBrave = braveNavigator?.brave && (await braveNavigator.brave.isBrave());
            if (isBrave) {
                TriggerGlobalAlert({
                    message:
                        'Brave Browser Detected. Please disable Brave Browser Shield for the Wildflower Health Portal',
                    severity: AlertSeverity.Error,
                });
            }
            BraveNotificationSent(true);
            localStorage.setItem('BraveNotificationSent', 'true');
        };
        if (!BraveNotificationSent()) {
            checkBrowser();
        }
    }, []);

    // Temporary fix to avoid crashing (see SYS-12982)
    const isLargeOrgSelected = useCheckIsLargeOrgSelected();

    return (
        <Router>
            <AppConfig>
                <Suspense fallback={<Loading />}>
                    <Routes>
                        <Route path="/login" element={<Login clearAuthTokens />} />
                        <Route
                            path="/portal"
                            element={
                                <AuthWrapper>
                                    <VCViewWrapper>
                                        <Dashboard />
                                    </VCViewWrapper>
                                </AuthWrapper>
                            }
                        >
                            <Route
                                path="patients"
                                element={
                                    pagePermissions?.PatientList?.Read ? (
                                        <Patients />
                                    ) : (
                                        <Navigate to="/portal" />
                                    )
                                }
                            />
                            <Route
                                path="call-list-dashboard/:id?"
                                element={
                                    pagePermissions?.PatientCallLists?.Read ? (
                                        <PatientCallListGrid />
                                    ) : (
                                        <Navigate to="/portal" />
                                    )
                                }
                            />
                            <Route
                                path="call-list-preview/:callListId"
                                element={
                                    pagePermissions?.PatientCallLists?.Read ? (
                                        <PatientCallListPreview />
                                    ) : (
                                        <Navigate to="/portal" />
                                    )
                                }
                            />
                            <Route
                                path="patients/:id"
                                element={
                                    pagePermissions?.PatientList?.Read ? (
                                        <Patient />
                                    ) : (
                                        <Navigate to="/portal" />
                                    )
                                }
                            >
                                <Route
                                    path="overview"
                                    element={
                                        pagePermissions?.PatientDetails?.Read ? (
                                            <PatientOverview />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="patient-details"
                                    element={
                                        pagePermissions?.PatientDetails?.Read ? (
                                            <PatientDetails />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="appointments"
                                    element={
                                        pagePermissions?.Appointments?.Read ? (
                                            <PatientAppointments />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="measurements"
                                    element={
                                        pagePermissions?.Measurements?.Read ? (
                                            <PatientMeasurements setLinks={setLinks} />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="survey-responses"
                                    element={
                                        pagePermissions?.SurveyResponses?.Read ? (
                                            <PatientSurveyResponses setLinks={setLinks} />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="orders"
                                    element={
                                        pagePermissions?.Orders?.Read ? (
                                            <PatientOrders setLinks={setLinks} />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="orders/new"
                                    element={
                                        pagePermissions?.Orders?.Edit ? (
                                            <CreateNewOrder />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="notes"
                                    element={
                                        pagePermissions?.PatientNotes?.Read ? (
                                            <PatientNotes setLinks={setLinks} />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="chat"
                                    element={
                                        pagePermissions?.MessageCenter?.Read ? (
                                            <Card
                                                style={{
                                                    padding: 0,
                                                    height: '85vh',
                                                    overflow: 'auto',
                                                    position: 'relative',
                                                }}
                                            >
                                                <PatientChat setLinks={setLinks} />
                                            </Card>
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="highlights"
                                    element={
                                        pagePermissions?.PatientHighlights?.Read ? (
                                            <PatientHighlights />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="profile-variables"
                                    element={
                                        pagePermissions?.PatientProfileVariables?.Read ? (
                                            <PatientProfileVariables />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="tags"
                                    element={
                                        pagePermissions?.PatientTags?.Read ? (
                                            <PatientTags />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="todos"
                                    element={
                                        pagePermissions?.PatientTodos?.Read ? (
                                            <PatientTodos />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="actions"
                                    element={
                                        pagePermissions?.PatientActions?.Read ? (
                                            <PatientActions />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="article-feed"
                                    element={
                                        pagePermissions?.PatientArticleFeed?.Read ? (
                                            <PatientArticleFeed />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="care-team"
                                    element={
                                        pagePermissions?.PatientCareTeam?.Read ? (
                                            <PatientCareTeam />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="app-events"
                                    element={
                                        pagePermissions?.PatientAppEvents?.Read ? (
                                            <PatientAppEvents />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="ha-tasks"
                                    element={
                                        pagePermissions?.HaTasks?.Read ? (
                                            <PatientHATasks setLinks={setLinks} />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="patient-transcript"
                                    element={
                                        pagePermissions?.HaTasks?.Read ? (
                                            <PatientTranscript />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="clinical-summaries"
                                    element={
                                        pagePermissions?.PatientClinicalSummaries?.Read ? (
                                            <PatientClinicalSummaries setLinks={setLinks} />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="clinical-summaries/:summaryId"
                                    element={
                                        pagePermissions?.PatientClinicalSummaries?.Read ? (
                                            <PatientClinicalSummary />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                            </Route>
                            <Route
                                path="advocate-task-list"
                                element={
                                    !isLargeOrgSelected &&
                                    pagePermissions?.AdvocateTaskList?.Read ? (
                                        <AdvocateTaskList setLinks={setLinks} />
                                    ) : (
                                        <Navigate to="/portal" />
                                    )
                                }
                            />
                            <Route
                                path="advocate-tasks/new"
                                element={
                                    pagePermissions?.AdvocateTaskList?.Edit ? (
                                        <CreateAdvocateTask />
                                    ) : (
                                        <Navigate to="/portal" />
                                    )
                                }
                            />
                            <Route
                                path="advocate-tasks/:id"
                                element={
                                    pagePermissions?.AdvocateTaskList?.Edit ? (
                                        <AdvocateTask setLinks={setLinks} />
                                    ) : (
                                        <Navigate to="/portal" />
                                    )
                                }
                            />
                            <Route
                                path="measurements"
                                element={
                                    !isLargeOrgSelected && pagePermissions?.Measurements?.Edit ? (
                                        <Measurements setLinks={setLinks} />
                                    ) : (
                                        <Navigate to="/portal" />
                                    )
                                }
                            />
                            <Route
                                path="surveys"
                                element={
                                    !isLargeOrgSelected &&
                                    pagePermissions?.SurveyResponses?.Read ? (
                                        <Surveys setLinks={setLinks} />
                                    ) : (
                                        <Navigate to="/portal" />
                                    )
                                }
                            />
                            <Route
                                path="orders"
                                element={
                                    pagePermissions?.Orders?.Read ? (
                                        <Orders setLinks={setLinks} />
                                    ) : (
                                        <Navigate to="/portal" />
                                    )
                                }
                            />
                            <Route
                                path="appointments"
                                element={
                                    pagePermissions?.Appointments?.Read ? (
                                        <Appointments />
                                    ) : (
                                        <Navigate to="/portal" />
                                    )
                                }
                            />
                            <Route
                                path="sla-timers"
                                element={
                                    pagePermissions?.SlaTimer?.Read ? (
                                        <SLATimers />
                                    ) : (
                                        <Navigate to="/portal" />
                                    )
                                }
                            />
                            <Route path="portal-settings" element={<PortalSettings />}>
                                <Route
                                    path="plans"
                                    element={
                                        pagePermissions?.HealthPlans?.Read ? (
                                            <HealthPlans />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="providers"
                                    element={
                                        pagePermissions?.Providers?.Read ? (
                                            <Providers />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route
                                    path="devices"
                                    element={
                                        pagePermissions?.Devices?.Read ? (
                                            <Devices />
                                        ) : (
                                            <Navigate to="/portal" />
                                        )
                                    }
                                />
                                <Route path="my-account" element={<MyAccount />} />
                            </Route>
                            <Route
                                path="message-center/:id"
                                element={
                                    pagePermissions?.MessageCenter.Read ? (
                                        <MessageCenter setLinks={setLinks} />
                                    ) : (
                                        <Navigate to="/portal" />
                                    )
                                }
                            />
                            <Route
                                path="message-center"
                                element={
                                    pagePermissions?.MessageCenter.Read ? (
                                        <MessageCenter setLinks={setLinks} />
                                    ) : (
                                        <Navigate to="/portal" />
                                    )
                                }
                            />
                            <Route path="welcome" element={<Welcome />} />
                        </Route>
                        <Route path="/accept-invite" element={<AcceptInvite />} />
                        <Route path="/patient-invite" element={<PatientInvite />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route
                            path="/unsubscribeemail/:patientId"
                            element={<PatientUnsubscribeEmail />}
                        />
                        <Route
                            path="/affiliate-care/:affiliateId/:step?"
                            element={<AffiliateVCF />}
                        />
                        <Route path="/virtual-care/:step" element={<GeneralVCF />} />
                        <Route path="/virtual-care" element={<GeneralVCF />} />
                        <Route path="care-connect/doula/:step?" element={<DoulaVCF />} />
                        <Route path="/add-note" element={<AddNote />} />
                        <Route
                            path="/add-patient-note-for-lactation-services"
                            element={<AddNote />}
                        />
                        {/* Public Routes must be above this route as it uses '/' */}
                        <Route
                            path="/app-config/*"
                            element={
                                <AuthWrapper>
                                    <ConfigDashboard />
                                </AuthWrapper>
                            }
                        >
                            <Route
                                path="orgs"
                                element={
                                    pagePermissions?.OrgConfig?.Read ? (
                                        <Organizations />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="summary/:id"
                                element={
                                    pagePermissions?.Apps?.Read ? (
                                        <ConfigAppSummary />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="value-defaults"
                                element={
                                    pagePermissions?.AppDefaults?.Read ? (
                                        <ConfigValueDefs />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="expressions"
                                element={
                                    pagePermissions?.Expressions?.Read ? (
                                        <Expressions />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="expressions/:id"
                                element={
                                    pagePermissions?.Expressions?.Edit ? (
                                        <Expression />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="profile-defs/:id"
                                element={
                                    pagePermissions?.ProfileDefs?.Read ? (
                                        <ProfileDefs />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="profile-defs"
                                element={
                                    pagePermissions?.ProfileDefs?.Read ? (
                                        <ProfileDefs />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="profile-value-origins"
                                element={
                                    pagePermissions?.ProfileValueOrigin?.Read ? (
                                        <ProfileValueOrigins />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="care-team-contacts"
                                element={
                                    pagePermissions?.CareTeamContacts?.Read ? (
                                        <CareTeamContacts />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="surveys/surveytaker/:id"
                                element={
                                    pagePermissions?.SurveyDefs?.Read ? (
                                        <SurveyTaker />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="surveys"
                                element={
                                    pagePermissions?.SurveyDefs?.Read ? (
                                        <SurveyDefs />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="surveys/:id"
                                element={
                                    pagePermissions?.SurveyDefs?.Edit ? (
                                        <SurveyEditor />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="staff"
                                element={
                                    pagePermissions?.Staff?.Read ? (
                                        <Staff />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="roles"
                                element={
                                    pagePermissions?.Roles?.Read ? (
                                        <Roles />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="timelines"
                                element={
                                    pagePermissions?.WhatsNewTimelines?.Read ? (
                                        <Timelines />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="timelines/:id"
                                element={
                                    pagePermissions?.WhatsNewTimelines?.Edit ? (
                                        <TimelineBuilder />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="bio-screens"
                                element={
                                    pagePermissions?.ProfileScreens?.Read ? (
                                        <BioScreen />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="bio-screens/:id"
                                element={
                                    pagePermissions?.ProfileScreens?.Edit ? (
                                        <BioScreenBuilder />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="tags"
                                element={
                                    pagePermissions?.Tags?.Read ? (
                                        <Tags />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="tags/:id"
                                element={
                                    pagePermissions?.Tags?.Edit ? (
                                        <TagEditor />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="triggers"
                                element={
                                    pagePermissions?.Triggers?.Read ? (
                                        <TagTriggers />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="triggers/:id"
                                element={
                                    pagePermissions?.Triggers?.Edit ? (
                                        <TagTriggerEditor />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="datetriggers"
                                element={
                                    pagePermissions?.DateTriggers?.Read ? (
                                        <DateTriggers />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="datetriggers/:id"
                                element={
                                    pagePermissions?.DateTriggers?.Edit ? (
                                        <DateTriggerEditor />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="eventtriggers"
                                element={
                                    pagePermissions?.EventTriggers?.Read ? (
                                        <EventTriggers />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="eventtriggers/:id"
                                element={
                                    pagePermissions?.EventTriggers?.Edit ? (
                                        <EventTriggerEditor />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="trendtriggers"
                                element={
                                    pagePermissions?.TrendTriggers?.Read ? (
                                        <TrendTriggers />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="trendtriggers/:id"
                                element={
                                    pagePermissions?.TrendTriggers?.Edit ? (
                                        <TrendTriggerEditor />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="apps"
                                element={
                                    pagePermissions?.Apps?.Read ? (
                                        <Applications />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="apps/:id"
                                element={
                                    pagePermissions?.Apps?.Edit ? (
                                        <ApplicationEditor />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="care-team-member-types"
                                element={
                                    pagePermissions?.CareTeamMemberTypes?.Read ? (
                                        <CareTeamMemberTypes />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="care-teams"
                                element={
                                    pagePermissions?.CareTeams?.Read ? (
                                        <CareTeams setLinks={setLinks} />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="care-teams/:id"
                                element={
                                    pagePermissions?.CareTeams?.Edit ? (
                                        <CareTeamBuilder />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="care-team-members"
                                element={
                                    pagePermissions?.CareTeamMembers?.Read ? (
                                        <CareTeamMembers />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="care-plans"
                                element={
                                    pagePermissions?.CarePlans?.Read ? (
                                        <CarePlanList />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="care-plans/:id"
                                element={
                                    pagePermissions?.CarePlans?.Edit ? (
                                        <CarePlan />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="calendars"
                                element={
                                    pagePermissions?.Calendars.Read ? (
                                        <Calendars />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="slas"
                                element={
                                    pagePermissions?.Sla.Read ? (
                                        <SLAs />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="chat-slas"
                                element={
                                    pagePermissions?.Sla.Read ? (
                                        <ChatSlas />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="condition-types"
                                element={
                                    pagePermissions?.ConditionTypes?.Read ? (
                                        <ConditionTypes />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="articlecategories"
                                element={
                                    pagePermissions?.ArticleCategory?.Read ? (
                                        <ArticleCategories />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="articleexports"
                                element={
                                    pagePermissions?.ArticleExport?.Read ? (
                                        <ArticleExportList />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="reportingcategories"
                                element={
                                    pagePermissions?.ReportingCategory?.Read ? (
                                        <ReportingCategories />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="tokens"
                                element={
                                    pagePermissions?.Tokens?.Read ? (
                                        <Tokens />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="tokens/:id"
                                element={
                                    pagePermissions?.Tokens?.Edit ? (
                                        <TokenEditor />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="simplecontent/:id"
                                element={
                                    pagePermissions?.SimpleContent?.Edit ? (
                                        <SimpleContents />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="simplecontent"
                                element={
                                    pagePermissions?.SimpleContent?.Read ? (
                                        <SimpleContents />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="articles"
                                element={
                                    pagePermissions?.Articles?.Read ? (
                                        <Articles />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="articleapproval"
                                element={
                                    pagePermissions?.ArticleApproval?.Read ? (
                                        <ArticleApprovals />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="articlepromotions"
                                element={
                                    pagePermissions?.ArticlePromotions?.Read ? (
                                        <ArticlePromotionList />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="articlepromotions/:id"
                                element={
                                    pagePermissions?.ArticlePromotions?.Edit ? (
                                        <ArticlePromotion />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="variants/:articleId/:lang/:rl/:app"
                                element={
                                    pagePermissions?.Articles?.Edit ? (
                                        <VariantEditor />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="articles/:id"
                                element={
                                    pagePermissions?.Articles?.Edit ? (
                                        <ArticleEditor />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="articlegroups"
                                element={
                                    pagePermissions?.ArticleGroup?.Read ? (
                                        <ArticleGroups />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="articlegroups/:id"
                                element={
                                    pagePermissions?.ArticleGroup?.Edit ? (
                                        <ArticleGroupEditor />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="advocate-task-templates/:id"
                                element={
                                    pagePermissions?.AdvocateTaskTemplates?.Edit ? (
                                        <AdvocateTaskTemplate />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="advocate-task-templates"
                                element={
                                    pagePermissions?.AdvocateTaskTemplates?.Read ? (
                                        <AdvocateTaskTemplates />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="message-center-templates"
                                element={
                                    pagePermissions?.MessageCenterTemplates?.Read ? (
                                        <MessageCenterTemplates />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="message-center-templates/new"
                                element={
                                    pagePermissions?.MessageCenterTemplates?.Edit ? (
                                        <CreateMessageCenterTemplate />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="message-center-templates/edit/:id"
                                element={
                                    pagePermissions?.MessageCenterTemplates?.Edit ? (
                                        <CreateMessageCenterTemplate />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="contentdirectory"
                                element={
                                    pagePermissions?.ContentDirectory?.Read ? (
                                        <ContentDirectorys />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="contentdirectory/:id"
                                element={
                                    pagePermissions?.ContentDirectory?.Edit ? (
                                        <ContentDirectoryEditor />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="timelineviewer"
                                element={
                                    pagePermissions?.AdminTimelineViewer?.Read ? (
                                        <TimelineViewer />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="orders/new"
                                element={
                                    pagePermissions?.Orders?.Edit ? (
                                        <CreateNewOrder />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="notetags"
                                element={
                                    pagePermissions?.NoteTags?.Read ? (
                                        <NoteTags />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="referencelinks"
                                element={
                                    pagePermissions?.ReferenceLinks?.Read ? (
                                        <ReferenceLinks />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="referencelinks/:id"
                                element={
                                    pagePermissions?.ReferenceLinks?.Read ? (
                                        <ReferenceLinkEditor />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="referencelinkCategories"
                                element={
                                    pagePermissions?.ReferenceLinkCategories?.Read ? (
                                        <ReferenceLinkCategories />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="patient-action-types"
                                element={
                                    pagePermissions?.PatientActionTypes?.Read ? (
                                        <PatientActionTypes />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="highlights"
                                element={
                                    pagePermissions?.Highlights?.Read ? (
                                        <Highlights />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="highlights/:id"
                                element={
                                    pagePermissions?.Highlights?.Edit ? (
                                        <HighlightEditor />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="highlighttemplate"
                                element={
                                    pagePermissions?.Highlights?.Edit ? (
                                        <AppointmentTemplateEditor />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route path="table" element={<ConfigDashboardTable />} />
                            <Route
                                path="compound-questions/:id"
                                element={
                                    pagePermissions?.CompoundQuestions?.Read ? (
                                        <CompoundQuestions />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="compound-questions"
                                element={
                                    pagePermissions?.CompoundQuestions?.Read ? (
                                        <CompoundQuestions />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="vc-zip-code-to-location"
                                element={
                                    pagePermissions?.VirtualCareZipCodeMap?.Read ? (
                                        <VCZipCodeToLocationList />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="vc-eligibility-whitelist"
                                element={
                                    pagePermissions?.VirtualCareEligibilityWhitelist?.Read ? (
                                        <VCEligibilityWhitelistList />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="message-templates"
                                element={
                                    pagePermissions?.EmailTemplates?.Read ? (
                                        <MessageTemplates />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route
                                path="message-templates/:id"
                                element={
                                    pagePermissions?.EmailTemplates?.Edit ? (
                                        <MessageTemplateEditor />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                            <Route path="vcaffiliates">
                                <Route index element={<VCAffiliates />} />
                                <Route path="new" element={<VCAffiliatesAddNew />} />
                                <Route
                                    path="patients/:affiliateId/:patientId?"
                                    element={<VCPatients />}
                                />
                                <Route
                                    path="patients/:affiliateId/patient/:id"
                                    element={<VCPatient />}
                                />
                                <Route path="invoices/:affiliateId" element={<VCInvoices />} />
                                <Route path="invoices/:affiliateId/:id" element={<VCInvoice />} />
                                <Route path="claims/:affiliateId" element={<VCClaims />} />
                                <Route
                                    path="build-claims/:affiliateId"
                                    element={<VCClaimBuilder />}
                                />
                                <Route path=":id" element={<VCAffiliatesEdit />} />
                            </Route>
                            <Route
                                path="affiliate-claim-submissions"
                                element={<VCAffiliateClaimSubmissions />}
                            />
                            <Route path="eligibility-match">
                                <Route
                                    index
                                    element={
                                        pagePermissions?.EligibilityMatch?.Read ? (
                                            <EligibilityMatch />
                                        ) : (
                                            <Navigate to="/app-config" />
                                        )
                                    }
                                />
                                <Route
                                    path="queue"
                                    element={
                                        pagePermissions?.EligibilityMatch?.Edit ? (
                                            <EligibilityMatchQueue />
                                        ) : (
                                            <Navigate to="/app-config/eligibility-match" />
                                        )
                                    }
                                >
                                    <Route
                                        path=":id"
                                        element={
                                            pagePermissions?.EligibilityMatch?.Edit ? (
                                                <EligibilityMatchManage />
                                            ) : (
                                                <Navigate to="/app-config/eligibility-match/queue" />
                                            )
                                        }
                                    />
                                </Route>
                                <Route
                                    path="matches"
                                    element={
                                        pagePermissions?.EligibilityMatch?.Edit ? (
                                            <EligibilityMatches />
                                        ) : (
                                            <Navigate to="/app-config/eligibility-match" />
                                        )
                                    }
                                />
                                <Route
                                    path="nomorematches"
                                    element={
                                        pagePermissions?.EligibilityMatch?.Read ? (
                                            <MatchComplete />
                                        ) : (
                                            <Navigate to="/app-config/eligibility-match" />
                                        )
                                    }
                                />
                                <Route
                                    path="nomatches"
                                    element={
                                        pagePermissions?.EligibilityMatch?.Edit ? (
                                            <EligibilityNoMatches />
                                        ) : (
                                            <Navigate to="/app-config/eligibility-match" />
                                        )
                                    }
                                />
                                <Route
                                    path="accessocde"
                                    element={
                                        pagePermissions?.EligibilityMatch?.Read ? (
                                            <AccessCode />
                                        ) : (
                                            <Navigate to="/app-config/eligibility-match" />
                                        )
                                    }
                                />
                            </Route>
                            <Route path="affiliate-invoices">
                                <Route
                                    index
                                    element={
                                        pagePermissions?.AffiliateInvoices.Read ? (
                                            <AffiliateCareInvoices />
                                        ) : (
                                            <Navigate to="/app-config" />
                                        )
                                    }
                                />
                                <Route
                                    path=":id"
                                    element={
                                        pagePermissions?.AffiliateInvoices.Read ? (
                                            <AffiliateCareInvoice />
                                        ) : (
                                            <Navigate to="/app-config" />
                                        )
                                    }
                                />
                            </Route>
                            <Route
                                path="vcf-eligibility-results"
                                element={
                                    pagePermissions?.VirtualCareEligibilityResults.Read ? (
                                        <VCFEligibility />
                                    ) : (
                                        <Navigate to="/app-config" />
                                    )
                                }
                            />
                        </Route>
                        <Route
                            path="/frontdesk"
                            element={
                                <AuthWrapper>
                                    <FrontDesk />
                                </AuthWrapper>
                            }
                        />
                        <Route
                            path="/system-admin"
                            element={
                                <AuthWrapper>
                                    <SystemAdmin />
                                </AuthWrapper>
                            }
                        >
                            <Route path="create-test-user" element={<CreateTestAppUser />} />
                            <Route path="batchTasks" element={<BatchTasks />} />
                            <Route
                                path="massupdate"
                                element={
                                    pagePermissions?.MassUpdate?.Read ? (
                                        <MassUpdatePatientLists />
                                    ) : (
                                        <Navigate to="/" />
                                    )
                                }
                            />
                            <Route
                                path="massupdate/:id"
                                element={
                                    pagePermissions?.MassUpdate?.Edit ? (
                                        <MassUpdatePatientListEditor />
                                    ) : (
                                        <Navigate to="/" />
                                    )
                                }
                            />
                            <Route
                                path="massupdate/:id/tasks"
                                element={
                                    pagePermissions?.MassUpdate?.Edit ? (
                                        <MassUpdateTasks />
                                    ) : (
                                        <Navigate to="/" />
                                    )
                                }
                            />
                            <Route
                                path="massupdate/:id/tasks/:taskId"
                                element={
                                    pagePermissions?.MassUpdate?.Edit ? (
                                        <MassUpdateTaskEditor />
                                    ) : (
                                        <Navigate to="/" />
                                    )
                                }
                            />
                        </Route>
                        <Route
                            path="/"
                            element={
                                groupPermissions?.VirtualCare ? (
                                    <AuthWrapper>
                                        <Navigate to="/affiliate-care-portal/landing" />
                                    </AuthWrapper>
                                ) : (
                                    <AuthWrapper>
                                        <Navigate to="/portal/welcome" />
                                    </AuthWrapper>
                                )
                            }
                        />
                        <Route
                            path="/affiliate-claim-submission/:aid?"
                            element={
                                <AuthWrapper>
                                    <AffiliateClaimSubmission />
                                </AuthWrapper>
                            }
                        />
                        <Route
                            path="/affiliate-care-portal/affiliate-onboarding/:step?"
                            element={
                                <AuthWrapper>
                                    <OnboardingRoute>
                                        <AffiliateOnboarding />
                                    </OnboardingRoute>
                                </AuthWrapper>
                            }
                        />

                        <Route
                            path="/affiliate-care-portal/affiliate-creation"
                            element={<AffiliateCreation />}
                        />
                        <Route
                            path="/affiliate-care-portal"
                            element={
                                <AuthWrapper>
                                    <OnboardingRoute>
                                        <Dashboard />
                                    </OnboardingRoute>
                                </AuthWrapper>
                            }
                        >
                            <Route path="landing" element={<AffiliateWelcome />} />
                            <Route path="patients">
                                <Route
                                    index
                                    element={
                                        pagePermissions?.VirtualCarePatients.Read ? (
                                            <AffiliateCarePatients />
                                        ) : (
                                            <Navigate to="/" />
                                        )
                                    }
                                />
                                <Route
                                    path=":id"
                                    element={
                                        pagePermissions?.VirtualCarePatients.Read ? (
                                            <AffiliateCarePatient />
                                        ) : (
                                            <Navigate to="/" />
                                        )
                                    }
                                />
                                <Route
                                    path=":affiliateId/:step"
                                    element={
                                        pagePermissions?.VirtualCarePatients.Edit ? (
                                            <AffiliateVCF baseUrl="/affiliate-care-portal/patients" />
                                        ) : (
                                            <Navigate to="/" />
                                        )
                                    }
                                />
                            </Route>
                            <Route path="claims">
                                <Route
                                    index
                                    element={
                                        pagePermissions?.VirtualCareClaims.Read ? (
                                            <AffiliateCareClaims />
                                        ) : (
                                            <Navigate to="/" />
                                        )
                                    }
                                />
                                <Route
                                    path="submit"
                                    element={
                                        !deniedClaimsUploading &&
                                        pagePermissions?.AffiliateCareClaimsSubmission.Edit ? (
                                            <AffiliateClaimSubmission />
                                        ) : (
                                            <Navigate to="/" />
                                        )
                                    }
                                />
                                <Route
                                    path="build-claims"
                                    element={
                                        !deniedClaimsUploading &&
                                        pagePermissions?.AffiliateCareClaimsSubmission.Edit ? (
                                            <AffiliateCareClaimsBuilder />
                                        ) : (
                                            <Navigate to="/" />
                                        )
                                    }
                                />
                            </Route>
                            <Route path="invoices">
                                <Route
                                    index
                                    element={
                                        pagePermissions?.AffiliateCareInvoices.Read ? (
                                            <AffiliateCareInvoices />
                                        ) : (
                                            <Navigate to="/" />
                                        )
                                    }
                                />
                                <Route
                                    path=":id"
                                    element={
                                        pagePermissions?.AffiliateCareInvoices.Read ? (
                                            <AffiliateCareInvoice />
                                        ) : (
                                            <Navigate to="/" />
                                        )
                                    }
                                />
                            </Route>
                            <Route path="affiliate-creation" element={<AffiliateCreation />} />
                            <Route path="settings">
                                <Route
                                    path="lactation-consultants"
                                    element={
                                        !showAffiliateSettings ? (
                                            <Navigate to="/" />
                                        ) : (
                                            <LactationConsultantsSettings />
                                        )
                                    }
                                />
                                <Route
                                    path="locations"
                                    element={
                                        !showAffiliateSettings ? (
                                            <Navigate to="/" />
                                        ) : (
                                            <ServiceTypesAndLocationsSettings />
                                        )
                                    }
                                />
                                <Route
                                    path="support"
                                    element={
                                        !showAffiliateSettings ? (
                                            <Navigate to="/" />
                                        ) : (
                                            <AffiliateSupportSettings />
                                        )
                                    }
                                />
                                <Route
                                    path="routing"
                                    element={
                                        !showAffiliateSettings ? (
                                            <Navigate to="/" />
                                        ) : (
                                            <AffiliateRoutingSettings />
                                        )
                                    }
                                />
                                <Route
                                    path="notifications"
                                    element={
                                        !showAffiliateSettings ? (
                                            <Navigate to="/" />
                                        ) : (
                                            <AffiliateNotificationsSettings />
                                        )
                                    }
                                />
                            </Route>
                        </Route>
                        <Route
                            path="doula"
                            element={
                                <AuthWrapper>
                                    <VCViewWrapper>
                                        <Dashboard />
                                    </VCViewWrapper>
                                </AuthWrapper>
                            }
                        >
                            <Route path="portal">
                                <Route path="patients">
                                    <Route
                                        index
                                        element={
                                            pagePermissions?.PatientList.Read ? (
                                                <Patients />
                                            ) : (
                                                <Navigate to="/" />
                                            )
                                        }
                                    />
                                    <Route
                                        path=":id"
                                        element={
                                            pagePermissions?.PatientList?.Read ? (
                                                <Patient />
                                            ) : (
                                                <Navigate to="/portal/patients" />
                                            )
                                        }
                                    >
                                        <Route
                                            index
                                            element={
                                                pagePermissions?.PatientDetails?.Read ? (
                                                    <AffiliateCarePatient VCView />
                                                ) : (
                                                    <Navigate to="/portal/patients" />
                                                )
                                            }
                                        />
                                        <Route
                                            path="overview"
                                            element={
                                                pagePermissions?.PatientDetails?.Read ? (
                                                    <AffiliateCarePatient VCView />
                                                ) : (
                                                    <Navigate to="/portal" />
                                                )
                                            }
                                        />
                                        <Route
                                            path="patient-details"
                                            element={
                                                pagePermissions?.PatientDetails?.Read ? (
                                                    <PatientDetails />
                                                ) : (
                                                    <Navigate to="/portal" />
                                                )
                                            }
                                        />
                                        <Route
                                            path="notes"
                                            element={
                                                pagePermissions?.PatientNotes?.Read ? (
                                                    <PatientNotes setLinks={setLinks} />
                                                ) : (
                                                    <Navigate to="/portal/patients" />
                                                )
                                            }
                                        />
                                    </Route>
                                </Route>
                                <Route
                                    path="build-claims"
                                    element={
                                        pagePermissions?.DoulaClaimsSubmission.Edit ? (
                                            <AffiliateCareClaimsBuilder VCView />
                                        ) : (
                                            <Navigate to="/" />
                                        )
                                    }
                                />
                                <Route path="claims">
                                    <Route
                                        index
                                        element={
                                            pagePermissions?.DoulaInvoices.Read ? (
                                                <DoulaNavigationPanel isClaimTab>
                                                    <DoulaClaims />
                                                </DoulaNavigationPanel>
                                            ) : (
                                                <Navigate to="/" />
                                            )
                                        }
                                    />
                                </Route>
                                <Route path="invoices">
                                    <Route
                                        index
                                        element={
                                            pagePermissions?.DoulaInvoices.Read ? (
                                                <DoulaNavigationPanel>
                                                    <DoulaInvoices />
                                                </DoulaNavigationPanel>
                                            ) : (
                                                <Navigate to="/" />
                                            )
                                        }
                                    />
                                    <Route
                                        path=":id"
                                        element={
                                            pagePermissions?.DoulaInvoices.Read ? (
                                                <AffiliateCareInvoice
                                                    patientRedirectUrl="/doula/portal/patients"
                                                    invoicesRedirectUrl="/doula/portal/invoices"
                                                />
                                            ) : (
                                                <Navigate to="/" />
                                            )
                                        }
                                    />
                                </Route>
                                <Route path="welcome" element={<DoulaWelcome />} />
                            </Route>
                        </Route>
                    </Routes>
                </Suspense>
                {links && links.length > 0 && <ReferenceLinksComponent input={links ?? []} />}
            </AppConfig>
        </Router>
    );
};

type AppConfigProps = {
    children: React.ReactNode;
};
// This comp has apollo available to it
const AppConfig: FunctionComponent<AppConfigProps> = ({ children }) => {
    const { data: globalStateData } = useFetchAlertStateQuery();

    // support for SSO login via WfhAuthToken param
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const wfhAuthToken = queryParams.get(AuthTokens.WfhAuthToken);
        if (wfhAuthToken) {
            localStorage.setItem(AuthTokens.WfhAuthToken, wfhAuthToken);
            queryParams.delete(AuthTokens.WfhAuthToken);
            navigate({
                search: queryParams.toString(),
            });
        }
    }, [location, navigate]);

    // load auth token when saved in other page
    useEffect(() => {
        const handleStorageEvent = (event: StorageEvent) => {
            log.info({ event }, 'storage event');
            if (event.newValue === event.oldValue) return;
            switch (event.key) {
                case AuthTokens.AccessToken:
                    AccessToken(
                        event.newValue ? new CognitoAccessToken(event.newValue) : undefined,
                    );
                    break;
                case AuthTokens.IdToken:
                    IdToken(event.newValue ? new CognitoIdToken(event.newValue) : undefined);
                    break;
                case AuthTokens.RefreshToken:
                    RefreshToken(event.newValue ? new OpaqueWebToken(event.newValue) : undefined);
                    break;
                default:
                    // do nothing
                    break;
            }
        };
        window.addEventListener('storage', handleStorageEvent);
        return () => window.removeEventListener('storage', handleStorageEvent);
    }, []);

    const handleAlertClose = () => {
        GlobalAlertOpen(false);
    };

    const handleConfirmClose = () => {
        TriggerGlobalConfirm({
            open: false,
            message: '',
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            callback: () => {},
        });
    };

    const handleConfirmExecute = () => {
        const { callback } = GlobalConfirm();
        callback();
        TriggerGlobalConfirm({
            open: false,
            message: '',
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            callback: () => {},
        });
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={WildTheme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ErrorHandler>
                        <CssBaseline />
                        <Fade in>
                            <div>
                                <Snackbar
                                    open={globalStateData?.GlobalAlert?.open}
                                    autoHideDuration={6000}
                                    onClose={handleAlertClose}
                                    ClickAwayListenerProps={{ mouseEvent: false }}
                                    data-test={CommonEnum.GLOBAL_ALERT_CONTAINER}
                                >
                                    <Alert
                                        onClose={handleAlertClose}
                                        severity={globalStateData?.GlobalAlert.severity}
                                    >
                                        {globalStateData?.GlobalAlert.message}
                                    </Alert>
                                </Snackbar>
                            </div>
                        </Fade>
                        <UploadWidget
                            open={!!globalStateData?.GlobalImageUpload.open}
                            isImage={!!globalStateData?.GlobalImageUpload.isImage}
                        />
                        <Dialog
                            open={!!globalStateData?.GlobalConfirm.open}
                            onClose={handleConfirmClose}
                            data-test={CommonEnum.GLOBAL_DIALOG_CONTAINER}
                        >
                            <DialogTitle>{globalStateData?.GlobalConfirm.message}</DialogTitle>
                            {globalStateData?.GlobalConfirm.description && (
                                <DialogContent>
                                    <Typography>
                                        {globalStateData?.GlobalConfirm.description}
                                    </Typography>
                                </DialogContent>
                            )}
                            <DialogActions>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    onClick={handleConfirmClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{ marginRight: 10 }}
                                    onClick={handleConfirmExecute}
                                >
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            scroll="paper"
                            maxWidth="lg"
                            open={!!globalStateData?.GlobalImagePreview.open}
                            onClose={() => GlobalImagePreview({ open: false, imageSrc: '' })}
                        >
                            <DialogTitleWithClose
                                id="FullScreenPreview"
                                onClose={() => GlobalImagePreview({ open: false, imageSrc: '' })}
                            >
                                File Preview
                            </DialogTitleWithClose>
                            <DialogContent
                                dividers
                                style={{
                                    minHeight: 400,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img
                                    src={globalStateData?.GlobalImagePreview.imageSrc}
                                    alt="Full screen preview"
                                />
                            </DialogContent>
                        </Dialog>
                        <GlobalBannerComponent />
                        <Layout>{children}</Layout>
                    </ErrorHandler>
                    <EnvLabel />
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
