import { Typography } from '@mui/material';
import React from 'react';
import { GetTriggerInterventionClearProfileValueForPatientTranscriptQuery } from '~/schemaTypes';

type TriggerInterventionClearProfileValue = NonNullable<
    GetTriggerInterventionClearProfileValueForPatientTranscriptQuery['getTriggerInterventionClearProfileValueForPatientTranscript']
>[0];

export const TriggerInterventionClearProfileValueCard = ({
    intervention,
}: {
    intervention: TriggerInterventionClearProfileValue;
}) => {
    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <Typography variant="h6">Trigger Intervention Clear Profile Value</Typography>
            <p className="m-0">{`Profile: ${intervention?.profileDef?.name || 'Unknown'}`}</p>
            <p className="m-0">{`Value: ${
                intervention?.event?.data?.updatedProfileValue?.str ||
                intervention?.event?.data?.updatedProfileValue?.num ||
                intervention?.event?.data?.updatedProfileValue?.bool ||
                intervention?.event?.data?.updatedProfileValue?.date ||
                intervention?.event?.data?.updatedProfileValue?.choiceId ||
                'Unknown'
            }`}</p>
        </div>
    );
};
