import * as Yup from 'yup';
import moment from 'moment';
import { PatientInsurancePlan, ClaimSubmittedBirthType } from '~/schemaTypes';
import { wasEligibleForLactationServicesAtDate } from '~/utils/lactationServicesEligibility';
import { VisitType, FormType, LocationType } from './types';

declare module 'yup' {
    interface DateSchema {
        eligibleForLactationServicesAtDate(
            patientInsurancePlans: (PatientInsurancePlan | null)[],
            isWhitelisted: boolean,
            allowNoInsuranceClaims?: boolean,
        ): this;
    }
    interface ValidateOptions {
        originalValue?: string;
    }
}

Yup.addMethod<Yup.DateSchema>(
    Yup.date,
    'eligibleForLactationServicesAtDate',
    // eslint-disable-next-line func-names
    function (
        this: Yup.DateSchema,
        patientInsurancePlans: (PatientInsurancePlan | null)[],
        isWhitelisted: boolean,
        allowNoInsuranceClaims: boolean,
    ) {
        return this.test(
            'is-eligible',
            'Patient Was Not Eligible for Services on date specified',
            // eslint-disable-next-line func-names
            function (this, val) {
                if (isWhitelisted) {
                    return true;
                }
                if (!val) {
                    return allowNoInsuranceClaims;
                }
                const originalValue = this.options.originalValue || this.originalValue;
                return wasEligibleForLactationServicesAtDate(
                    patientInsurancePlans,
                    originalValue,
                    allowNoInsuranceClaims,
                );
            },
        );
    },
);

export const ADD_PATIENT_TO_CLAIM_SCHEMA = (
    patientInsurancePlans: (PatientInsurancePlan | null)[],
    isPatientWhitelisted: boolean,
    allowNoInsuranceClaims?: boolean,
) =>
    Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        birthDate: Yup.string(),
        dateOfService: Yup.date()
            .typeError('Invalid date')
            .dateFormatVCF()
            .min(moment().subtract(90, 'days').toDate(), 'DOS Greater than 90 days')
            .required('DOS is required')
            .eligibleForLactationServicesAtDate(
                patientInsurancePlans,
                isPatientWhitelisted,
                allowNoInsuranceClaims,
            ),
        serviceType: Yup.string().when(['$type'], ([$type], schema) => {
            if ($type === FormType.Affiliate) {
                return schema.required('Service Type is required');
            }
            return schema.nullable();
        }),
        visitType: Yup.string().when(['$type'], ([$type], schema) => {
            if ($type === FormType.Doula) {
                return schema.required('Visit Type is required');
            }
            return schema.nullable();
        }),
        consultType: Yup.string().when(['$type', 'visitType'], ([$type, visitType], schema) => {
            if ($type === FormType.Doula && visitType === VisitType.Consult) {
                return schema.required('Consult Type is required');
            }
            return schema.nullable();
        }),
        location: Yup.string().when(['$type'], ([$type], schema) => {
            if ($type === FormType.Doula) {
                return schema.required('Location is required');
            }
            return schema.nullable();
        }),
        inPersonLocation: Yup.string().when(
            ['$type', 'location', 'visitType'],
            ([$type, location, visitType], schema) => {
                if (
                    $type === FormType.Doula &&
                    visitType === VisitType.Consult &&
                    location === LocationType.InPerson
                ) {
                    return schema.required('In-Person Location is required');
                }
                return schema.nullable();
            },
        ),
        startTime: Yup.string().when(['$type', 'visitType'], ([$type, visitType], schema) => {
            if ($type === FormType.Doula && visitType === VisitType.Consult) {
                return schema.required('Start Time is required');
            }
            return schema.nullable();
        }),
        endTime: Yup.string().when(['$type', 'visitType'], ([$type, visitType], schema) => {
            if ($type === FormType.Doula && visitType === VisitType.Consult) {
                return schema.required('End Time is required');
            }
            return schema.nullable();
        }),
        duration: Yup.number().when(
            ['$type', 'startTime', 'endTime', 'visitType'],
            ([$type, startTime, endTime, visitType], schema) => {
                if (
                    $type === FormType.Doula &&
                    startTime &&
                    endTime &&
                    visitType === VisitType.Consult
                ) {
                    if (endTime <= startTime) {
                        return schema.moreThan(0, 'Invalid time range').required();
                    }
                    return schema
                        .min(8, 'Duration of visit must be at least 8 minutes to submit visit')
                        .required();
                }
                return schema.nullable();
            },
        ),
        birthType: Yup.string().when(
            ['$type', 'location', 'visitType'],
            ([$type, location, visitType], schema) => {
                if (
                    $type === FormType.Doula &&
                    visitType === VisitType.Birth &&
                    location === LocationType.InPerson
                ) {
                    return schema.required('In-Person Location is required');
                }
                return schema.nullable();
            },
        ),
        admissionFrom: Yup.date().when(
            ['$type', 'birthType', 'visitType'],
            ([$type, birthType, visitType], schema) => {
                if (
                    $type === FormType.Doula &&
                    visitType === VisitType.Birth &&
                    birthType === ClaimSubmittedBirthType.Hospital
                ) {
                    return schema
                        .typeError('Invalid date')
                        .dateFormatVCF()
                        .required('Admission Date is required');
                }
                return schema.nullable();
            },
        ),
        admissionTo: Yup.date().when(
            ['$type', 'birthType', 'visitType'],
            ([$type, birthType, visitType], schema) => {
                if (
                    $type === FormType.Doula &&
                    visitType === VisitType.Birth &&
                    birthType === ClaimSubmittedBirthType.Hospital
                ) {
                    return schema
                        .typeError('Invalid date')
                        .dateFormatVCF()
                        .required('Discharge Date is required');
                }
                return schema.nullable();
            },
        ),
    });
