import {
    AdvocateTaskChecklistItemInput as Step,
    FetchAdvocateTaskForAdvocateTaskPageQuery,
} from '~/schemaTypes';
import { renderActionTypeLabels } from './index';

type ActionType = NonNullable<
    FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']
>['actionTypes'][0];

const renderComma = (
    changedName: string,
    changedDescription: string,
    changedCreateActionCheckbox: string,
    changedActionTypes: string,
) =>
    `${changedName}${
        changedName && (changedDescription || changedCreateActionCheckbox || changedActionTypes)
            ? ', '
            : ''
    }${changedDescription}${
        changedDescription && (changedCreateActionCheckbox || changedActionTypes) ? ', ' : ''
    }${changedCreateActionCheckbox}${
        changedCreateActionCheckbox && changedActionTypes ? ', ' : ''
    }${changedActionTypes}`;

const getStepDiff = (
    newStep: Step,
    steps: Step[],
    indexToUpdate: number,
    actionTypesData: ActionType[],
): string => {
    let changedName = '';
    let changedDescription = '';
    let changedCreateActionCheckbox = '';
    let changedActionTypes = '';
    let removedIdLabels = '';
    let addedIdLabels = '';
    const newActionTypeIdsLength = newStep.actionTypeIds.length;
    const prevActionTypeIdsLength = steps[indexToUpdate].actionTypeIds.length;
    const isCreateActionOnCompletionChecked =
        newActionTypeIdsLength > prevActionTypeIdsLength && !prevActionTypeIdsLength;
    const isCreateActionOnCompletionUnchecked =
        newActionTypeIdsLength < prevActionTypeIdsLength && !newActionTypeIdsLength;
    const removedIds = steps[indexToUpdate].actionTypeIds.filter(
        id => !newStep.actionTypeIds.includes(id),
    );
    const addedIds = newStep.actionTypeIds.filter(
        id => !steps[indexToUpdate].actionTypeIds.includes(id),
    );

    if (newStep.label !== steps[indexToUpdate].label) {
        changedName = `changed Name from '${steps[indexToUpdate].label}' to '${newStep.label}'`;
    }

    if (newStep.description !== steps[indexToUpdate].description) {
        changedDescription = `changed Description from '${steps[indexToUpdate].description}' to '${newStep.description}'`;
    }

    if (isCreateActionOnCompletionChecked) {
        changedCreateActionCheckbox = `checked 'Create Action on Completion' checkbox`;
    }

    if (isCreateActionOnCompletionUnchecked) {
        changedCreateActionCheckbox = `unchecked 'Create Action on Completion' checkbox`;
    }

    if (removedIds.length) {
        removedIdLabels = renderActionTypeLabels([], removedIds, actionTypesData);
    }

    if (addedIds.length) {
        addedIdLabels = renderActionTypeLabels([], addedIds, actionTypesData);
    }

    if (removedIds.length || addedIds.length) {
        changedActionTypes = `${
            removedIds.length ? `removed ${removedIdLabels}${addedIds.length ? ', ' : ' '}` : ''
        }${addedIds.length ? `added ${addedIdLabels} ` : ''}${
            removedIds.length || addedIds.length ? 'Action Type(s)' : ''
        }`;
    }

    if (
        !changedName &&
        !changedDescription &&
        !changedCreateActionCheckbox &&
        !changedActionTypes
    ) {
        return 'saved step without any changes';
    }

    return renderComma(
        changedName,
        changedDescription,
        changedCreateActionCheckbox,
        changedActionTypes,
    );
};

export default getStepDiff;
