/* eslint-disable camelcase */
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    Select,
    Switch,
    TextField,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import DatePicker from '@mui/lab/DatePicker';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    ArticleListQueryVariables,
    FetchProfileDefsForExpressionPageQuery,
    SimpleContentListQueryVariables,
    SurveyDefPieceType,
    SurveyDefQuestionDisplayType,
    useArticleListQuery,
    useFetchProfileDefsForExpressionPageQuery,
    useFetchSurveyDefForSurveyBuilderLazyQuery,
    UserProfileValueType,
    useSimpleContentListQuery,
    ContentType as SimpleContentContentType,
} from '~/schemaTypes';
import { ContentType, Response, SurveyContent } from './types/typeDefs';

type UserProfileDef = NonNullable<FetchProfileDefsForExpressionPageQuery['userProfileDefs'][0]>;

const getLabel = (
    lang: string,
    str: { en?: string | null; es?: string | null } | undefined | null,
): string => {
    if (str) {
        if (lang === 'es' && str.es) return str.es;
        if (str.en) return str.en;
    }
    return 'Unknown';
};

const isDateType = (type: SurveyDefQuestionDisplayType): boolean => {
    return (
        type === SurveyDefQuestionDisplayType.Date13YearsInThePast ||
        type === SurveyDefQuestionDisplayType.Date18YearsInThePast ||
        type === SurveyDefQuestionDisplayType.DateInTheFuture ||
        type === SurveyDefQuestionDisplayType.DateInThePast ||
        type === SurveyDefQuestionDisplayType.DueDate ||
        type === SurveyDefQuestionDisplayType.Date
    );
};

const SurveyTaker: React.FC = () => {
    const history = useNavigate();
    const { id: surveyDefId } = useParams<{ id: string }>();
    const [language, setLanguage] = useState('en');
    const [selectedChoices, setSelectedChoices] = useState<Set<string>>();
    const [poundsValue, setPoundsValue] = useState<number>(0);
    const [ouncesValue, setOuncesValue] = useState<number>(0);
    const [feetValue, setFeetValue] = useState<number>(0);
    const [inchValue, setInchValue] = useState<number>(0);
    const [displayType, setDisplayType] = useState<SurveyDefQuestionDisplayType>();
    const [dateValue, setDateValue] = useState<Date | null>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [contentList, setContentList] = useState<SurveyContent[]>([]);
    const [responseList, setResponseList] = useState<Response[]>([]);
    const [profile, setProfile] = useState<UserProfileDef>();
    const [prevValue, setPrevValue] = useState<string>();
    const [questionLabel, setQuestionLabel] = useState<string>();
    const [required, setRequired] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [valueSet, setValueSet] = useState<boolean>(false);
    const [showResults, setShowResuts] = useState<boolean>(false);
    const { data: profileData, loading: profileDefsLoading } =
        useFetchProfileDefsForExpressionPageQuery();

    const [fetchSurveyById, { data: surveyData, loading: surveyLoading }] =
        useFetchSurveyDefForSurveyBuilderLazyQuery();

    const articlesListQueryVariables: ArticleListQueryVariables = {
        input: {},
    };

    const { data: articleList, loading: articlesLoading } = useArticleListQuery({
        variables: articlesListQueryVariables,
    });

    const simpleContentListQueryVariables: SimpleContentListQueryVariables = {
        input: { filter: { fields: { contentType: SimpleContentContentType.Video } } },
    };

    const { data: videoSimpleContentList, loading: videoSimpleContentLoading } =
        useSimpleContentListQuery({
            variables: simpleContentListQueryVariables,
        });

    const singleValueUpdated = (value: string) => {
        setValueSet(value !== undefined && value !== '');
        if (profile) {
            const currentResponse = responseList.find(r => r.index === currentIndex);
            if (currentResponse) {
                currentResponse.value = value;
            } else {
                responseList.push({
                    index: currentIndex,
                    profileDefId: profile.id,
                    profileName: profile.name,
                    questionText: questionLabel || 'Unknown',
                    value,
                });
                setResponseList(responseList);
            }
        }
    };
    const multiValueUpdated = (value: string, added: boolean) => {
        const choices = selectedChoices || new Set<string>();
        if (added) choices.add(value);
        else choices.delete(value);
        setSelectedChoices(choices);
        if (choices.size > 0) singleValueUpdated(Array.from(choices).join(','));
        else {
            singleValueUpdated('');
        }
    };
    const handlePoundsChange = (value: number) => {
        setPoundsValue(value);
        singleValueUpdated(`${value} lbs,${ouncesValue} ozs`);
    };
    const handleOuncesChange = (value: number) => {
        setOuncesValue(value);
        singleValueUpdated(`${poundsValue} lbs,${value} ozs`);
    };
    const handleFeetChange = (value: number) => {
        setFeetValue(value);
        singleValueUpdated(`${value} ft,${inchValue} ins`);
    };
    const handleInchChange = (value: number) => {
        setInchValue(value);
        singleValueUpdated(`${feetValue} ft,${inchValue} ins`);
    };
    useEffect(() => {
        fetchSurveyById({ variables: { surveyDefInput: { id: surveyDefId } } });
    }, [surveyDefId, fetchSurveyById]);
    useEffect(() => {
        if (surveyData && surveyData.surveyDef) {
            const survey = surveyData.surveyDef;
            const data: SurveyContent[] = [];
            if (survey.summary && survey.summaryText) {
                data.push({
                    type: ContentType.Summary,
                    summary: {
                        title: {
                            en: survey.summaryText.title?.en || '',
                            es: survey.summaryText.title?.es || '',
                        },
                        body: {
                            en: survey.summaryText.body?.en || '',
                            es: survey.summaryText.body?.es || '',
                        },
                    },
                });
            }
            for (const div of survey.divisions) {
                for (const sec of div.sections) {
                    for (const item of sec.pieces) {
                        switch (item.type) {
                            case SurveyDefPieceType.Content:
                                data.push({
                                    type: ContentType.Content,
                                    divName: div.label,
                                    secName: sec.label,
                                    content: { articleId: item.content?.articleId || '' },
                                });
                                break;
                            case SurveyDefPieceType.Text:
                                data.push({
                                    type: ContentType.Text,
                                    divName: div.label,
                                    secName: sec.label,
                                    text: {
                                        title: {
                                            en: item.text?.title?.en || '',
                                            es: item.text?.title?.es || '',
                                        },
                                        body: {
                                            en: item.text?.body?.en || '',
                                            es: item.text?.body?.es || '',
                                        },
                                    },
                                });
                                break;
                            case SurveyDefPieceType.TutorialContent:
                                data.push({
                                    type: ContentType.TutorialContent,
                                    divName: div.label,
                                    secName: sec.label,
                                    tutorialContent: {
                                        videoId: item.tutorialContent?.videoId || '',
                                    },
                                });
                                break;
                            default:
                                if (item.question)
                                    data.push({
                                        type: ContentType.Question,
                                        divName: div.label,
                                        secName: sec.label,
                                        question: {
                                            label: {
                                                en: item.question.labelOverride?.en || '',
                                                es: item.question.labelOverride?.es || '',
                                            },
                                            profileDefId: item.question.questionProfileDefId,
                                            displayType: item.question.displayType,
                                            valueType: UserProfileValueType.Bool, // TODO Get real type from profile
                                            required: item.question.answerRequired || false,
                                            hidePrevious: item.question.hidePreviousAnswer || false,
                                        },
                                    });
                        }
                    }
                }
            }
            setContentList(data);
        }
    }, [surveyData, language]);

    const handleLanguageChange = (lang: string) => {
        setLanguage(lang);
    };
    const setQuestionData = (index: number) => {
        setSelectedChoices(new Set<string>());
        const content = contentList[index];
        setRequired(false);
        setValueSet(false);
        if (content.type === ContentType.Question && content.question !== undefined) {
            setRequired(content.question.required);
            const newProfile = profileData?.userProfileDefs.find(
                p => p.id === content.question?.profileDefId,
            );
            setProfile(newProfile);
            const newDisplayType =
                content.question?.displayType || SurveyDefQuestionDisplayType.LineInput;
            setDisplayType(newDisplayType);
            const newPrev = responseList.find(r => r.index === index)?.value;
            setPrevValue(newPrev);
            setDateValue(null);
            if (newPrev !== undefined) {
                setValueSet(true);
                setSelectedChoices(new Set<string>(newPrev.split(',')));
                if (newDisplayType === SurveyDefQuestionDisplayType.BabyWeight) {
                    try {
                        setPoundsValue(parseInt(newPrev.split(',')[0].replace(' lbs', ''), 10));
                        setOuncesValue(parseInt(newPrev.split(',')[1].replace(' ozs', ''), 10));
                    } catch {
                        //
                    }
                }
                if (newDisplayType === SurveyDefQuestionDisplayType.Height) {
                    try {
                        setFeetValue(parseInt(newPrev.split(',')[0].replace(' ft', ''), 10));
                        setOuncesValue(parseInt(newPrev.split(',')[1].replace(' ins', ''), 10));
                    } catch {
                        //
                    }
                }
                if (isDateType(newDisplayType)) setDateValue(new Date(newPrev));
            }
            let questionLabel = 'Unknown';
            if (content.question?.label)
                questionLabel = getLabel(language, content.question?.label);

            if (questionLabel === 'Unknown')
                questionLabel = getLabel(language, newProfile?.questionLabel);
            setQuestionLabel(questionLabel);
        }
    };
    const handlePrevious = () => {
        setHasError(false);
        const newIndex = currentIndex - 1;
        setQuestionData(newIndex);
        setCurrentIndex(newIndex);
    };
    const handleNext = () => {
        if (!valueSet && required) {
            setHasError(true);
            return;
        }
        setHasError(false);
        const newIndex = currentIndex + 1;
        setQuestionData(newIndex);
        setCurrentIndex(newIndex);
    };
    const handleSave = () => {
        if (!valueSet && required) {
            setHasError(true);
            return;
        }
        setShowResuts(true);
    };
    if (
        surveyLoading ||
        profileDefsLoading ||
        contentList.length === 0 ||
        articlesLoading ||
        videoSimpleContentLoading
    )
        return <Loading />;
    if (
        contentList[currentIndex].type === ContentType.Question &&
        currentIndex === 0 &&
        questionLabel == null
    ) {
        setQuestionData(0);
    }
    return (
        <>
            <Button onClick={() => history(`/app-config/surveys/`)} startIcon={<ArrowBack />}>
                Back to Survey List
            </Button>
            {showResults && (
                <div>
                    <table>
                        <tr>
                            <th>Profile Value</th>
                            <th>Survey Response</th>
                        </tr>
                        {_.sortBy(responseList, 'index').map(r => (
                            <tr>
                                <td>{r.profileName}</td>
                                <td>{r.value}</td>
                            </tr>
                        ))}
                    </table>{' '}
                    <Button onClick={() => setShowResuts(false)}>Resume Survey</Button>
                </div>
            )}
            {!showResults && (
                <div>
                    <h1>{`Taking ${surveyData?.surveyDef?.name}`}</h1>
                    <OutlinedSection title="Select Language">
                        English:{' '}
                        <Radio
                            value="en"
                            radioGroup="language"
                            checked={language === 'en'}
                            onChange={() => handleLanguageChange('en')}
                        />{' '}
                        Spanish:{' '}
                        <Radio
                            value="es"
                            radioGroup="language"
                            checked={language === 'es'}
                            onChange={() => handleLanguageChange('es')}
                        />
                    </OutlinedSection>
                    {contentList[currentIndex].type === ContentType.Summary && (
                        <OutlinedSection title="Summary">
                            <h2>{getLabel(language, contentList[currentIndex].summary?.title)}</h2>
                            <div>{getLabel(language, contentList[currentIndex].summary?.body)}</div>
                        </OutlinedSection>
                    )}
                    {contentList[currentIndex].type !== ContentType.Summary && (
                        <OutlinedSection
                            title={`Section: ${getLabel(
                                language,
                                contentList[currentIndex].divName,
                            )}, Screen: ${getLabel(language, contentList[currentIndex].secName)}`}
                        >
                            {contentList[currentIndex].type === ContentType.Text && (
                                <OutlinedSection title="Text">
                                    <h2>
                                        {getLabel(language, contentList[currentIndex].text?.title)}
                                    </h2>
                                    <div>
                                        {getLabel(language, contentList[currentIndex].text?.body)}
                                    </div>
                                </OutlinedSection>
                            )}
                            {contentList[currentIndex].type === ContentType.Content && (
                                <OutlinedSection title="Content">
                                    <div>{`Article: ${
                                        articleList?.articlesV2.results.find(
                                            a =>
                                                a.id ===
                                                contentList[currentIndex].content?.articleId,
                                        )?.name
                                    }`}</div>
                                </OutlinedSection>
                            )}
                            {contentList[currentIndex].type === ContentType.TutorialContent && (
                                <OutlinedSection title="Tutorial Content">
                                    <div>{`Video: ${
                                        videoSimpleContentList?.simpleContentsV2.results.find(
                                            a =>
                                                a.id ===
                                                contentList[currentIndex].tutorialContent?.videoId,
                                        )?.name
                                    }`}</div>
                                </OutlinedSection>
                            )}
                            {contentList[currentIndex].type === ContentType.Question && (
                                <OutlinedSection title="Question">
                                    <div>
                                        <div>{`${questionLabel} ${required ? '*' : ''}`}</div>
                                        {contentList[currentIndex].expressionName !== undefined && (
                                            <div
                                                style={{ color: 'red', fontWeight: 'bold' }}
                                            >{`Uses expression: ${contentList[currentIndex].expressionName}`}</div>
                                        )}
                                        <div>
                                            {displayType ===
                                                SurveyDefQuestionDisplayType.CheckBox && (
                                                <FormControl variant="outlined">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={e =>
                                                                    singleValueUpdated(
                                                                        e.target.checked
                                                                            ? 'true'
                                                                            : 'false',
                                                                    )
                                                                }
                                                                defaultChecked={
                                                                    prevValue === 'true'
                                                                }
                                                            />
                                                        }
                                                        label="True/False"
                                                        style={{ flexBasis: '90%' }}
                                                    />
                                                </FormControl>
                                            )}
                                            {displayType ===
                                                SurveyDefQuestionDisplayType.TextInput && (
                                                <textarea
                                                    value={prevValue}
                                                    defaultValue=""
                                                    onChange={e =>
                                                        singleValueUpdated(e.target.value)
                                                    }
                                                />
                                            )}
                                            {(displayType === SurveyDefQuestionDisplayType.Avatar ||
                                                displayType ===
                                                    SurveyDefQuestionDisplayType.LineInput ||
                                                displayType ===
                                                    SurveyDefQuestionDisplayType.PhoneNumber ||
                                                displayType ===
                                                    SurveyDefQuestionDisplayType.DateTime) && (
                                                <TextField
                                                    name="textField"
                                                    key={currentIndex}
                                                    fullWidth
                                                    label="Value"
                                                    variant="outlined"
                                                    size="small"
                                                    type="text"
                                                    defaultValue=""
                                                    value={prevValue}
                                                    onChange={e =>
                                                        singleValueUpdated(e.target.value)
                                                    }
                                                />
                                            )}
                                            {(displayType ===
                                                SurveyDefQuestionDisplayType.Numeric ||
                                                displayType === SurveyDefQuestionDisplayType.Year ||
                                                displayType ===
                                                    SurveyDefQuestionDisplayType.Weight) && (
                                                <TextField
                                                    fullWidth
                                                    label="Value"
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    defaultValue=""
                                                    value={prevValue}
                                                    onChange={e =>
                                                        singleValueUpdated(e.target.value)
                                                    }
                                                />
                                            )}
                                            {displayType === SurveyDefQuestionDisplayType.Email && (
                                                <TextField
                                                    fullWidth
                                                    label="Value"
                                                    variant="outlined"
                                                    size="small"
                                                    type="email"
                                                    value={prevValue}
                                                    defaultValue=""
                                                    onChange={e =>
                                                        singleValueUpdated(e.target.value)
                                                    }
                                                />
                                            )}
                                            {(displayType ===
                                                SurveyDefQuestionDisplayType.Date13YearsInThePast ||
                                                displayType ===
                                                    SurveyDefQuestionDisplayType.Date18YearsInThePast ||
                                                displayType ===
                                                    SurveyDefQuestionDisplayType.DateInTheFuture ||
                                                displayType ===
                                                    SurveyDefQuestionDisplayType.DateInThePast ||
                                                displayType ===
                                                    SurveyDefQuestionDisplayType.DueDate ||
                                                displayType ===
                                                    SurveyDefQuestionDisplayType.Date) && (
                                                <DatePicker
                                                    fullWidth
                                                    label="Date"
                                                    onChange={(e: Date) => {
                                                        if (e) {
                                                            setDateValue(e);
                                                            singleValueUpdated(e.toDateString());
                                                        }
                                                    }}
                                                    openTo="date"
                                                    format="MM/dd/yyyy"
                                                    size="small"
                                                    value={dateValue}
                                                />
                                            )}
                                            {(displayType ===
                                                SurveyDefQuestionDisplayType.RadioButtons ||
                                                displayType ===
                                                    SurveyDefQuestionDisplayType.ButtonGroup) &&
                                                profile?.choices?.map(c => {
                                                    return (
                                                        <div key={c?.id}>
                                                            <input
                                                                type="radio"
                                                                name="radioGroup"
                                                                radioGroup="radioGroup"
                                                                defaultChecked={
                                                                    prevValue !== undefined &&
                                                                    prevValue ===
                                                                        getLabel(
                                                                            language,
                                                                            c?.answerLabel,
                                                                        )
                                                                }
                                                                value={getLabel(
                                                                    language,
                                                                    c?.answerLabel,
                                                                )}
                                                                onChange={({
                                                                    target: { checked, value },
                                                                }) => {
                                                                    singleValueUpdated(
                                                                        checked ? value : '',
                                                                    );
                                                                }}
                                                            />
                                                            {getLabel(language, c?.answerLabel)}
                                                        </div>
                                                    );
                                                })}
                                            {displayType ===
                                                SurveyDefQuestionDisplayType.CheckBoxes &&
                                                profile?.choices?.map(c => {
                                                    return (
                                                        <div key={c?.id}>
                                                            <FormControlLabel
                                                                key={c?.id}
                                                                control={
                                                                    <Checkbox
                                                                        key={c?.id}
                                                                        defaultChecked={
                                                                            prevValue !==
                                                                                undefined &&
                                                                            prevValue.includes(
                                                                                getLabel(
                                                                                    language,
                                                                                    c?.answerLabel,
                                                                                ),
                                                                            )
                                                                        }
                                                                        value={getLabel(
                                                                            language,
                                                                            c?.answerLabel,
                                                                        )}
                                                                        onChange={({
                                                                            target: {
                                                                                checked,
                                                                                value,
                                                                            },
                                                                        }) => {
                                                                            multiValueUpdated(
                                                                                value,
                                                                                checked,
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label={getLabel(
                                                                    language,
                                                                    c?.answerLabel,
                                                                )}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            {(displayType ===
                                                SurveyDefQuestionDisplayType.DropDown ||
                                                displayType ===
                                                    SurveyDefQuestionDisplayType.DropDownMultipleSelect) && (
                                                <Select
                                                    variant="outlined"
                                                    multiple={
                                                        displayType ===
                                                        SurveyDefQuestionDisplayType.DropDownMultipleSelect
                                                    }
                                                    onChange={e =>
                                                        singleValueUpdated(e.target.value as string)
                                                    }
                                                >
                                                    {profile?.choices?.map(c => {
                                                        return (
                                                            <MenuItem
                                                                selected={
                                                                    prevValue !== undefined &&
                                                                    prevValue.includes(
                                                                        getLabel(
                                                                            language,
                                                                            c?.answerLabel,
                                                                        ),
                                                                    )
                                                                }
                                                                key={c?.id}
                                                                value={getLabel(
                                                                    language,
                                                                    c?.answerLabel,
                                                                )}
                                                            >
                                                                {getLabel(language, c?.answerLabel)}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            )}
                                            {displayType ===
                                                SurveyDefQuestionDisplayType.BabyWeight && (
                                                <div>
                                                    Pounds:{' '}
                                                    <TextField
                                                        fullWidth
                                                        label="Pounds"
                                                        variant="outlined"
                                                        size="small"
                                                        type="number"
                                                        value={poundsValue}
                                                        defaultValue=""
                                                        onChange={e =>
                                                            handlePoundsChange(
                                                                parseInt(e.target.value, 10),
                                                            )
                                                        }
                                                    />{' '}
                                                    Ounces:{' '}
                                                    <TextField
                                                        fullWidth
                                                        label="Ounces"
                                                        variant="outlined"
                                                        size="small"
                                                        type="number"
                                                        value={ouncesValue}
                                                        defaultValue=""
                                                        onChange={e =>
                                                            handleOuncesChange(
                                                                parseInt(e.target.value, 10),
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {displayType ===
                                                SurveyDefQuestionDisplayType.Toggle && (
                                                <Switch
                                                    defaultChecked={prevValue === 'true'}
                                                    onChange={(e: any) =>
                                                        singleValueUpdated(
                                                            e.target.checked ? 'true' : 'false',
                                                        )
                                                    }
                                                />
                                            )}
                                            {displayType ===
                                                SurveyDefQuestionDisplayType.Height && (
                                                <div>
                                                    Feet:{' '}
                                                    <TextField
                                                        fullWidth
                                                        label="Feet"
                                                        variant="outlined"
                                                        size="small"
                                                        type="number"
                                                        value={feetValue}
                                                        defaultValue=""
                                                        onChange={e =>
                                                            handleFeetChange(
                                                                parseInt(e.target.value, 10),
                                                            )
                                                        }
                                                    />{' '}
                                                    Inches:{' '}
                                                    <TextField
                                                        fullWidth
                                                        label="Inches"
                                                        variant="outlined"
                                                        size="small"
                                                        type="number"
                                                        defaultValue=""
                                                        value={inchValue}
                                                        onChange={e =>
                                                            handleInchChange(
                                                                parseInt(e.target.value, 10),
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {hasError && (
                                            <div style={{ fontWeight: 'bold', color: 'red' }}>
                                                This question requires an answer
                                            </div>
                                        )}
                                    </div>
                                </OutlinedSection>
                            )}
                        </OutlinedSection>
                    )}
                    <div style={{ width: '100%' }}>
                        <Button disabled={currentIndex === 0} onClick={handlePrevious}>
                            {getLabel(language, surveyData?.surveyDef?.buttons?.previous?.label)}
                        </Button>
                        {currentIndex < contentList.length - 1 && (
                            <>
                                <Button onClick={handleNext}>
                                    {getLabel(
                                        language,
                                        surveyData?.surveyDef?.buttons?.next?.label,
                                    )}
                                </Button>
                                <Button onClick={() => setShowResuts(true)}>
                                    {getLabel(language, surveyData?.surveyDef?.buttons?.end?.label)}
                                </Button>
                            </>
                        )}
                        {currentIndex === contentList.length - 1 && (
                            <Button onClick={handleSave}>
                                {getLabel(
                                    language,
                                    surveyData?.surveyDef?.buttons?.saveSurvey?.label,
                                )}
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default SurveyTaker;
