import React from 'react';
import { Draggable as DNDDraggable } from 'react-beautiful-dnd';

type DraggableProps = {
    id: string;
    index: number;
    children: React.ReactNode;
};

const Draggable: React.FC<DraggableProps> = ({ id, index, children }) => {
    return (
        <DNDDraggable draggableId={id} index={index}>
            {provided => (
                <div
                    ref={provided.innerRef}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...provided.dragHandleProps}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...provided.draggableProps}
                >
                    {children}
                </div>
            )}
        </DNDDraggable>
    );
};

export default Draggable;
