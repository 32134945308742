import { faRectangleList, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, DialogActions, DialogContent, Link } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { PagePermissions } from '~/hooks/useUserPermissions';
import { DependentEntity, WhereUsedEntity } from '~/schemaTypes';

type WhereUsedModalProps = {
    title: string;
    canDelete: boolean;
    onDelete: () => void;
    onClose: () => void;
    usageData: DependentEntity[];
    permissions: PagePermissions | undefined;
};

const entityName = (type: WhereUsedEntity) => {
    switch (type) {
        case WhereUsedEntity.Article:
            return 'Article';
        case WhereUsedEntity.Articlepromotion:
            return 'Article Promotion';
        case WhereUsedEntity.Careplan:
            return 'Care Plan';
        case WhereUsedEntity.Configvalue:
            return 'App Config Value';
        case WhereUsedEntity.Configvaluedef:
            return 'App Config Default';
        case WhereUsedEntity.Contentdirectory:
            return 'Content Directory';
        case WhereUsedEntity.Simplecontent:
            return 'Simple Content';
        case WhereUsedEntity.Survey:
            return 'Survey';
        case WhereUsedEntity.User:
            return 'Admin User';
        case WhereUsedEntity.Advocatetasktemplate:
            return 'Advocate Task Template';
        case WhereUsedEntity.Intervention:
            return 'Intervention';
        case WhereUsedEntity.Tag:
            return 'Tag';
        case WhereUsedEntity.Careteamcontact:
            return 'Care Team Contact';
        case WhereUsedEntity.Careteammember:
            return 'Care Team Member';
        case WhereUsedEntity.Bioscreen:
            return 'Bioscreen';
        case WhereUsedEntity.Profiledef:
            return 'Profile Definition';
        case WhereUsedEntity.Advocatetaskchecklist:
            return 'Advocate Task Checklist';
        case WhereUsedEntity.Patientaction:
            return 'Patient Action';
        case WhereUsedEntity.Highlight:
            return 'Highlight';
        case WhereUsedEntity.Datetrigger:
            return 'Date Trigger';
        case WhereUsedEntity.Eventtrigger:
            return 'Event Trigger';
        default:
            return 'Unknown';
    }
};
const itemLink = (type: WhereUsedEntity, permissions: PagePermissions | undefined, id: string) => {
    let href = '';
    switch (type) {
        case WhereUsedEntity.Article:
            if (permissions && permissions.Articles.Read) href = `/app-config/articles/${id}`;
            break;
        case WhereUsedEntity.Articlepromotion:
            if (permissions && permissions.ArticlePromotions.Read)
                href = `/app-config/articlepromotions/${id}`;
            break;
        case WhereUsedEntity.Careplan:
            if (permissions && permissions.CarePlans.Read) href = `/app-config/care-plans/${id}`;
            break;
        case WhereUsedEntity.Configvaluedef:
            if (permissions && permissions.AppDefaults.Read) href = '/app-config/value-defaults';
            break;
        case WhereUsedEntity.Contentdirectory:
            if (permissions && permissions.ContentDirectory.Read)
                href = `/app-config/contentdirectory/${id}`;
            break;
        case WhereUsedEntity.Simplecontent:
            if (permissions && permissions.SimpleContent.Read)
                href = `/app-config/simplecontent/${id}`;
            break;
        case WhereUsedEntity.Survey: {
            if (permissions && permissions.SurveyDefs.Read) href = `/app-config/surveys/${id}`;
            break;
        }
        case WhereUsedEntity.User: {
            if (permissions && permissions.Staff.Read) href = `/app-config/staff/${id}`;
            break;
        }
        case WhereUsedEntity.Advocatetasktemplate:
            if (permissions && permissions.AdvocateTaskTemplates.Read)
                href = `/app-config/advocate-task-templates/${id}`;
            break;
        case WhereUsedEntity.Intervention:
            if (permissions && permissions.Triggers.Read) href = `/app-config/triggers/${id}`;
            break;
        case WhereUsedEntity.Tag:
            if (permissions && permissions.Tags.Read) href = `/app-config/tags/${id}`;
            break;
        case WhereUsedEntity.Careteamcontact:
            if (permissions && permissions.CareTeamContacts.Read)
                href = `/app-config/care-team-contacts/${id}`;
            break;
        case WhereUsedEntity.Careteammember:
            if (permissions && permissions.CareTeamMembers.Read)
                href = `/app-config/care-team-members/${id}`;
            break;
        default:
            break;
    }
    if (href !== '')
        return (
            <Link href={href} underline="hover" title={`View ${entityName(type)}`}>
                <FontAwesomeIcon icon={faRectangleList} />
            </Link>
        );
    return '';
};

const WhereUsedModal: React.FC<WhereUsedModalProps> = ({
    canDelete,
    onDelete,
    onClose,
    usageData,
    title,
    permissions,
}) => {
    const blocksDeleteList = _.sortBy(
        usageData.filter(u => u.blocksDelete),
        'entityType',
    );
    const nonBlockingList = _.sortBy(
        usageData.filter(u => !u.blocksDelete),
        'entityType',
    );
    const deleteAllowed = canDelete && blocksDeleteList.length === 0;
    return (
        <>
            <DialogTitleWithClose id="edit-role-modal" onClose={() => onClose()}>
                {title}
            </DialogTitleWithClose>
            <DialogContent>
                {blocksDeleteList.length > 0 && (
                    <OutlinedSection title="Blocking References">
                        {blocksDeleteList &&
                            blocksDeleteList.map(u => (
                                <OutlinedSection
                                    title={entityName(u.entityType)}
                                    key={u.entityType}
                                >
                                    {(u.total ?? 0) > 50 && (
                                        <div
                                            style={{ color: 'red', fontWeight: 'bold' }}
                                        >{`(too many entries ${u.total} to display)`}</div>
                                    )}
                                    {u.entries?.map(e => (
                                        <div key={e?.id}>
                                            {itemLink(u.entityType, permissions, e?.id || '')}{' '}
                                            {e?.name}
                                        </div>
                                    ))}
                                </OutlinedSection>
                            ))}
                    </OutlinedSection>
                )}
                {nonBlockingList.length > 0 && (
                    <OutlinedSection
                        title={`Non-Blocking References${
                            deleteAllowed ? ' (will be deleted)' : ''
                        }`}
                    >
                        {nonBlockingList &&
                            nonBlockingList.map(u => (
                                <OutlinedSection title={entityName(u.entityType)}>
                                    {(u.total ?? 0) > 50 && (
                                        <div
                                            style={{ color: 'red', fontWeight: 'bold' }}
                                        >{`(too many entries ${u.total} to display)`}</div>
                                    )}
                                    {u.entries?.map(e => (
                                        <div key={e?.id}>
                                            {itemLink(u.entityType, permissions, e?.id || '')}{' '}
                                            {e?.name}
                                        </div>
                                    ))}
                                </OutlinedSection>
                            ))}
                    </OutlinedSection>
                )}
                {usageData.length === 0 && <div>Not referenced</div>}
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={() => onClose()} color="secondary" variant="outlined">
                    Close
                </Button>
                {deleteAllowed && (
                    <Button
                        onClick={() => onDelete()}
                        startIcon={<FontAwesomeIcon icon={faTrash} />}
                        color="secondary"
                        variant="contained"
                    >
                        Delete
                    </Button>
                )}
            </DialogActions>
        </>
    );
};

export default WhereUsedModal;
