import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Grid, TextField, DialogContent, Button, DialogActions } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useForm } from 'react-hook-form';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { TriggerGlobalAlert, SuppressNextGlobalAlert } from '~/state';
import Loading from '~/components/Loading/Loading';
import {
    useCreateVirtualCareEligibilityWhitelistMutation,
    useUpdateVirtualCareEligibilityWhitelistMutation,
    useVirtualCareEligibilityWhitelistLazyQuery,
    AlertSeverity,
} from '~/schemaTypes';
import { VC_ELIGIBILITY_WHITELIST_SCHEMA } from './yupSchema';

type VCEligibilityWhitelistModalProps = {
    onComplete: Dispatch<SetStateAction<boolean>>;
    id?: string;
};

type FormInput = {
    firstName: string;
    lastName: string;
    memberId: string;
};

export const VCEligibilityEditorModal: React.FC<VCEligibilityWhitelistModalProps> = props => {
    const { id, onComplete } = props;
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormInput>({
        resolver: yupResolver(VC_ELIGIBILITY_WHITELIST_SCHEMA as any),
    });
    const [getEntityQuery, { loading: getEntityLoading }] =
        useVirtualCareEligibilityWhitelistLazyQuery({
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
            onCompleted: data => {
                if (data?.virtualCareEligibilityWhitelist) {
                    setValue('firstName', data.virtualCareEligibilityWhitelist.firstName ?? '');
                    setValue('lastName', data.virtualCareEligibilityWhitelist.lastName ?? '');
                    setValue('memberId', data.virtualCareEligibilityWhitelist.memberId ?? '');
                }
            },
        });
    const [createEntity, { loading: createLoading }] =
        useCreateVirtualCareEligibilityWhitelistMutation({
            onCompleted: () => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'Entry created successfully',
                });
                onComplete(true);
            },
            onError: error => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: error.message,
                });
            },
        });
    const [updateEntity, { loading: updateLoading }] =
        useUpdateVirtualCareEligibilityWhitelistMutation({
            onCompleted: () => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'Entry updated successfully',
                });
                onComplete(true);
            },
            onError: error => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: error.message,
                });
            },
        });
    useEffect(() => {
        if (id) {
            getEntityQuery({
                variables: {
                    input: {
                        id,
                    },
                },
            });
        }
    }, [id, getEntityQuery]);
    const onSubmit = (data: FormInput) => {
        SuppressNextGlobalAlert(true);
        if (id) {
            updateEntity({
                variables: {
                    input: {
                        id,
                        data,
                    },
                },
            });
        } else {
            createEntity({
                variables: {
                    input: {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        memberId: data.memberId,
                    },
                },
            });
        }
    };
    return (
        <div>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => onComplete(false)}>
                {id
                    ? 'Edit Virtual Care Eligibility Whitelist'
                    : 'Create Virtual Care Eligibility Whitelist'}
            </DialogTitleWithClose>
            <DialogContent dividers>
                {getEntityLoading && <Loading />}
                {!getEntityLoading && (
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="First Name"
                                    fullWidth
                                    margin="dense"
                                    {...register('firstName')}
                                    error={!!errors.firstName}
                                    helperText={errors.firstName?.message}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Last Name"
                                    fullWidth
                                    margin="dense"
                                    {...register('lastName')}
                                    error={!!errors.lastName}
                                    helperText={errors.lastName?.message}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Member Id"
                                    fullWidth
                                    margin="dense"
                                    {...register('memberId')}
                                    error={!!errors.memberId}
                                    helperText={errors.memberId?.message}
                                />
                            </Grid>
                        </Grid>
                    </form>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onComplete(false)} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <LoadingButton
                    startIcon={<SaveIcon />}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={createLoading || updateLoading}
                    onClick={() => handleSubmit(onSubmit)()}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </div>
    );
};
