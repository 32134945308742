import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    root: {
        position: 'relative',
        height: '100%',
    },
    appSelector: {
        margin: '10px 0',
        width: '100%',
    },
});
