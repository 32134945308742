import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Button, Chip, Grid, FormHelperText } from '@mui/material';
import { FileUploadOutlined } from '@mui/icons-material';
import { TriggerGlobalAlert, SuppressNextGlobalAlert } from '~/state';
import { AlertSeverity } from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import { FILE_ACCEPT, FILE_TYPE_TO_NAME_MAP, MAX_FILE_SIZE } from './constants';
import { useStyles } from './styles';
import { generateFileFromString } from './helpers';

type FileUploadProps = {
    setValue: (name: string, shouldValidate: boolean) => void;
    fileData: File | null;
    errorMessage?: string;
    onComplete: (data: string | null, fileName: string) => void;
    showPreview: boolean;
    fileAccept?: string;
    maxSize?: number;
    fileTypeToNameMap?: Map<string, string>;
    label: string;
    showFileUploader?: boolean;
};

SuppressNextGlobalAlert(false);

const FileUpload: React.FC<FileUploadProps> = props => {
    const {
        setValue,
        fileData,
        errorMessage,
        onComplete,
        showPreview,
        fileAccept = FILE_ACCEPT,
        maxSize = MAX_FILE_SIZE,
        fileTypeToNameMap = FILE_TYPE_TO_NAME_MAP,
        label,
        showFileUploader = true,
    } = props;
    const inputFileRef = useRef<HTMLInputElement>(null);
    const { classes } = useStyles();
    const [fileName, setFileName] = useState<string>(fileData?.name || '');
    const [currentFile, setCurrentFile] = useState<File | null>(fileData);
    // const downloadAttach = useCallback(() => {
    //     if (currentFile) {
    //         const link = document.createElement('a');
    //         link.setAttribute('href', window.URL.createObjectURL(currentFile));
    //         link.setAttribute('download', fileName || '');
    //         document.body.appendChild(link);
    //         link.click();
    //         link.remove();
    //     }
    // }, [currentFile, fileName]);
    useEffect(() => {
        if (fileData) {
            setCurrentFile(fileData);
            setFileName(fileData.name);
        }
    }, [fileData]);
    const fileViewHandler = useCallback(() => {
        if (currentFile && showPreview) {
            // downloadAttach();
            window.open(URL.createObjectURL(currentFile), '_blank');
        }
    }, [currentFile, showPreview]);
    const fileDeleteHandler = useCallback(() => {
        if (inputFileRef.current) {
            inputFileRef.current.value = '';
            setFileName('');
            setValue('', false);
            setCurrentFile(null);
            onComplete(null, '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleUploadedFile = useCallback((file: File) => {
        setFileName(file.name);
        setCurrentFile(file);
        setValue(file.name, true);
        const reader = new FileReader();
        reader.onload = async () => {
            if (!reader.result) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: `Invalid file format`,
                });
                fileDeleteHandler();
                return;
            }
            const buffer = reader.result as unknown as string;
            onComplete(buffer, file.name);
        };
        reader.readAsBinaryString(file);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const fileUploadHandler = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
        if (target.files && target.files.length) {
            const acceptedFormatsString = Array.from(fileTypeToNameMap.values());
            const acceptedFormatsTypes = Array.from(fileTypeToNameMap.keys());
            const file = target.files[0];
            const correctFormat = acceptedFormatsTypes.some(type => {
                const pattern = new RegExp(type);
                return pattern.test(file.type);
            });
            const fileSize = file.size;
            if (!correctFormat) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: `Invalid file format. Only ${acceptedFormatsString.join(
                        ', ',
                    )} are supported.`,
                });
            } else if (fileSize > maxSize) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: `File size more then ${maxSize / 1000000} MB`,
                });
            } else {
                handleUploadedFile(file);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!showFileUploader) {
        return <Loading height={80} />;
    }
    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <Button
                    fullWidth
                    variant="outlined"
                    color={errorMessage ? 'error' : 'primary'}
                    component="label"
                >
                    <FileUploadOutlined /> {label}
                    <input
                        type="file"
                        style={{
                            clip: 'rect(0 0 0 0)',
                            clipPath: 'inset(50%)',
                            height: 1,
                            overflow: 'hidden',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            whiteSpace: 'nowrap',
                            width: 1,
                        }}
                        accept={fileAccept}
                        ref={inputFileRef}
                        onChange={fileUploadHandler}
                    />
                </Button>
            </Grid>
            {errorMessage && (
                <FormHelperText error className={classes.fileError}>
                    {errorMessage}
                </FormHelperText>
            )}
            {fileName && (
                <Grid item xs={12}>
                    <Chip
                        label={fileName}
                        variant="outlined"
                        onClick={fileViewHandler}
                        onDelete={fileDeleteHandler}
                        className={classes.fileInfo}
                        clickable={showPreview}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default FileUpload;
export { generateFileFromString };
