import React, { useCallback } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import InputMask, { Selection, BeforeMaskedStateChangeStates } from 'react-input-mask';

import { TextField, TextFieldProps } from '@mui/material';
import { ControllerRenderProps, FieldError, FieldValues, Path } from 'react-hook-form';

const DATE_FORMAT = 'MM/DD/YYYY';

type DateInputProps<TFieldValues extends FieldValues, TName extends Path<TFieldValues>> = {
    label: string;
    error?: FieldError;
    field: ControllerRenderProps<TFieldValues, TName>;
    disabled?: boolean;
    inputProps?: TextFieldProps;
};

type InputMaskedHandlerProps = {
    nextState: {
        value: string;
        selection: Selection | null;
        enteredString?: string;
    };
};

const currentYear = new Date().getFullYear() % 100;
const SKIP_AUTOCOMPLETE_YEAR_VALUES = [19, 20];

const DateInput = <TFieldValues extends FieldValues, TName extends Path<TFieldValues>>(
    props: DateInputProps<TFieldValues, TName>,
) => {
    const { label, error, field, disabled, inputProps = {} } = props;
    const { value = '', onChange, ref, ...otherProps } = field;
    const beforeMaskedStateChange = useCallback((props: BeforeMaskedStateChangeStates) => {
        const {
            nextState: { value, selection, enteredString },
        } = props as InputMaskedHandlerProps;
        const end = selection?.end;
        const start = selection?.start;
        // if user entered / in date or month postion add 0 before digit
        if (enteredString === '/' && end === start && (end === 1 || end === 4)) {
            const newValue = `${value.slice(0, end - 1)}0${value.slice(end - 1)}/`;
            return {
                selection: {
                    end: end + 2,
                    start: end + 2,
                },
                value: newValue,
                enteredString,
            };
        }
        // if user entered 2 digits in year (7-8 positions)
        if (end === start && end === 8 && enteredString && /[0-9]/.test(enteredString)) {
            const lastTwoDigit = value.split('/').pop();
            if (lastTwoDigit) {
                const centuryYearDelimiter = currentYear + 1;
                const valueAsNumber = parseInt(lastTwoDigit, 10);
                if (SKIP_AUTOCOMPLETE_YEAR_VALUES.includes(valueAsNumber)) {
                    return {
                        value,
                        selection,
                    };
                }
                // complete to 21st century
                if (valueAsNumber >= 0 && valueAsNumber <= centuryYearDelimiter) {
                    const newValue = `${value.slice(0, end - 2)}20${value.slice(end - 2)}`;
                    return {
                        value: newValue,
                        selection: {
                            end: end + 2,
                            start: end + 2,
                        },
                    };
                }
                // complete to 20th century
                if (valueAsNumber > centuryYearDelimiter && valueAsNumber <= 99) {
                    const newValue = `${value.slice(0, end - 2)}19${value.slice(end - 2)}`;
                    return {
                        value: newValue,
                        selection: {
                            end: end + 2,
                            start: end + 2,
                        },
                    };
                }
            }
        }

        return {
            value,
            selection,
        };
    }, []);
    return (
        <InputMask
            {...otherProps}
            mask="99/99/9999"
            onChange={onChange}
            value={value as string}
            maskPlaceholder={null}
            beforeMaskedStateChange={beforeMaskedStateChange}
            disabled={disabled}
        >
            <TextField
                {...inputProps}
                label={label}
                ref={ref}
                error={!!error}
                helperText={error?.message}
                placeholder={DATE_FORMAT}
                inputProps={{
                    size: 10,
                    maxLength: 10,
                }}
            />
        </InputMask>
    );
};

export default DateInput;
