import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import {
    useFetchChatRoomForPortalUserForPatientChatPageQuery,
    FetchChatRoomForPortalUserInput,
} from '~/schemaTypes';
import ChatMessages from '~/views/Dashboard/MessageCenter/ChatMessages/ChatMessages';
import { RouteParams } from '../../PatientTodos/types';

type PatientChatRoomProps = {
    careTeamMemberTypeId: string;
};

const PatientChatRoom = ({ careTeamMemberTypeId }: PatientChatRoomProps) => {
    const { id } = useParams<RouteParams>();

    const fetchChatRoomInput: FetchChatRoomForPortalUserInput = useMemo(() => {
        return {
            patientId: id,
            careTeamMemberTypeId,
        };
    }, [careTeamMemberTypeId, id]);

    const { data: chatRoom, loading: chatRoomLoading } =
        useFetchChatRoomForPortalUserForPatientChatPageQuery({
            variables: {
                input: fetchChatRoomInput,
            },
        });

    if (chatRoomLoading) {
        return <Loading />;
    }

    return <ChatMessages chatRoomData={chatRoom?.fetchChatRoomForPortalUser} />;
};

export default PatientChatRoom;
