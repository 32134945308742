import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    GetMassUpdatePatientListsDocument,
    useAddToPatientListMutation,
    useCreateMassUpdatePatientListMutation,
    useGetMassUpdatePatientListsQuery,
} from '~/schemaTypes';
import ObjectId from 'bson-objectid';

export const AddToMassUpdateModal: React.FC<{
    isOpen: boolean;
    patientId: string;
    onClose: () => void;
}> = props => {
    const { isOpen, onClose, patientId } = props;
    const [newListName, setNewListName] = useState('');
    const [newListError, setNewListError] = useState(false);
    const [selectedListId, setSelectedListId] = useState('');
    const { data: listData, loading: listsLoading } = useGetMassUpdatePatientListsQuery({
        variables: { input: {} },
    });
    useEffect(() => {
        const lists = _.sortBy(listData?.massUpdatePatientsV2.results, 'name');
        if (lists.length !== 0) setSelectedListId(lists[0].id);
    }, [listData]);
    const [updateList, { loading: updateListLoading }] = useAddToPatientListMutation({
        onCompleted: () => {
            setNewListError(false);
            onClose();
        },
    });
    const [createList, { loading: createListLoading }] = useCreateMassUpdatePatientListMutation({
        onCompleted: () => {
            setNewListError(false);
            onClose();
        },
        refetchQueries: [
            {
                query: GetMassUpdatePatientListsDocument,
                variables: { input: {} },
            },
        ],
    });

    const handleAddToNewList = () => {
        if (
            listData?.massUpdatePatientsV2.results.find(
                l => l.name.toLowerCase() === newListName.toLowerCase(),
            ) !== undefined
        ) {
            setNewListError(true);
            return;
        }
        createList({
            variables: {
                input: { name: newListName, patients: [ObjectId.createFromHexString(patientId)] },
            },
        });
    };
    const handleAddToExistingList = () => {
        if (selectedListId) {
            updateList({ variables: { input: { id: selectedListId, patientIds: [patientId] } } });
        }
    };

    if (updateListLoading || listsLoading || createListLoading) return <Loading />;
    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose id="dialogTitle" onClose={onClose}>
                Add Patient to Mass Update List
            </DialogTitleWithClose>
            <DialogContent>
                <OutlinedSection title="Add To New List">
                    <TextField
                        variant="outlined"
                        type="text"
                        fullWidth
                        value={newListName}
                        margin="dense"
                        onChange={event => {
                            setNewListName(event.target.value);
                            setNewListError(false);
                        }}
                    />
                    {newListError && (
                        <div style={{ color: 'red', fontWeight: 'bold' }}>
                            Mass Update List Name already used
                        </div>
                    )}
                    <div>
                        <Button onClick={handleAddToNewList} color="secondary" variant="contained">
                            Add To New List
                        </Button>
                    </div>
                </OutlinedSection>
                <OutlinedSection title="Add To Selected List">
                    <select
                        value={selectedListId}
                        onChange={event => setSelectedListId(event.target.value)}
                    >
                        {_.sortBy(listData?.massUpdatePatientsV2.results, 'name').map(l => (
                            <option value={l.id} key={l.id}>
                                {`${l.name} (${l.patients?.length})`}
                            </option>
                        ))}
                    </select>
                    <div>
                        <Button
                            onClick={handleAddToExistingList}
                            color="secondary"
                            variant="contained"
                        >
                            Add To Selected List
                        </Button>
                    </div>
                </OutlinedSection>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant="contained">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
