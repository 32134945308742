import { UserProfileValueType } from '~/schemaTypes';
import { UpdatedProfileVariable } from '~/views/Dashboard/Patients/PatientProfileVariableLink/types';

export const getUpdatedProfileVariableValue = (
    updatedProfileVariable: UpdatedProfileVariable,
): string | boolean | null => {
    const { value, valueType } =
        updatedProfileVariable?.updatePatientProfileVariableMutation
            ?.updatePatientProfileVariable ?? {};

    if (value === undefined || valueType === undefined) {
        return null;
    }

    const updatedProfileVariableValue = value[valueType as UserProfileValueType] ?? null;

    if (updatedProfileVariableValue === null) {
        return '';
    }

    if (valueType === UserProfileValueType.Choice) {
        return (
            updatedProfileVariable?.profileDefChoices?.userProfileDef?.choices?.find(
                item => item?.id === updatedProfileVariableValue,
            )?.label?.en ?? null
        );
    }

    return updatedProfileVariableValue;
};
