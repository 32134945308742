import React, { useCallback, useState } from 'react';
import copy from 'copy-to-clipboard';
import { Link } from 'react-router-dom';
import { Dialog, Grid, IconButton, TablePagination, Tooltip, Typography } from '@mui/material';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import { InfoOutlined } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { AlertSeverity, EligibilityMatchsV2Query } from '~/schemaTypes';
import tableIcons from '~/helpers/tableIcons';
import { TriggerGlobalAlert } from '~/state';
import Header from '../components/Header';
import useTable, { matchesType } from '../hooks/useTable';
import ActionDialog from './ActionDialog';

const Matches: React.FC = () => {
    const [showActionDialog, setShowActionDialog] = useState<boolean>(false);
    const [selectedMatch, setSelectedMatch] = useState<string>('');
    const {
        page,
        rowsPerPage,
        handleChangeRowsPerPage,
        handleChangePage,
        tableRef,
        data: { loading, data },
        fetchQuery,
    } = useTable({ type: matchesType.Matches });
    const actionDialogCallback = useCallback(() => {
        fetchQuery();
        setShowActionDialog(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchQuery]);
    const removeMatchHandler = useCallback((id: string) => {
        setSelectedMatch(id);
        setShowActionDialog(true);
    }, []);
    return (
        <Grid container rowSpacing={4}>
            <Header lastTitle="Matches" lastPath="matches" />
            <Grid item xs={12} marginRight="1rem" ref={tableRef}>
                <MaterialTable<EligibilityMatchsV2Query['eligibilityMatchsV2']['results'][0]>
                    icons={tableIcons as MaterialTableProps<any>['icons']}
                    columns={[
                        {
                            title: '_id',
                            field: 'id',
                            render: ({ id }) => (
                                <Tooltip title={id}>
                                    <IconButton
                                        onClick={() => {
                                            copy(`${id}`);
                                            TriggerGlobalAlert({
                                                message: 'Id copied to clipboard',
                                                severity: AlertSeverity.Success,
                                            });
                                        }}
                                        size="large"
                                    >
                                        <InfoOutlined />
                                    </IconButton>
                                </Tooltip>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Patient FN',
                            field: 'patientFirstName',
                            render: ({ patientFirstName, patientId }) => (
                                <Link
                                    to={`/portal/patients/${patientId}/overview`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {patientFirstName}
                                </Link>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Patient LN',
                            field: 'patientLastName',
                            render: ({ patientLastName }) => (
                                <Typography data-test={patientLastName}>
                                    {patientLastName}
                                </Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Eligibility FN',
                            field: 'firstNameMatch',
                            render: ({ firstNameMatch }) => (
                                <Typography data-test={firstNameMatch}>{firstNameMatch}</Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Eligibility LN',
                            field: 'lastNameMatch',
                            render: ({ lastNameMatch }) => (
                                <Typography data-test={lastNameMatch}>{lastNameMatch}</Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'App',
                            field: 'appId',
                            render: ({ appId }) => (
                                <Typography data-test={appId}>{appId}</Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Date Of Match',
                            field: 'matchDateTime',
                            render: ({ matchDateTime }) => (
                                <Typography data-test={matchDateTime}>{matchDateTime}</Typography>
                            ),
                            sorting: false,
                        },
                    ]}
                    data={
                        data?.eligibilityMatchsV2.results.map((o: any) => ({
                            ...o,
                        })) || []
                    }
                    options={{
                        search: false,
                        paginationPosition: 'both',
                        pageSize: rowsPerPage,
                        pageSizeOptions: [25, 50, 100],
                        toolbar: false,
                    }}
                    isLoading={loading}
                    localization={{ header: { actions: '' } }}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                count={data?.eligibilityMatchsV2.total ?? 0}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        ),
                    }}
                    actions={[
                        {
                            onClick: (_, { id }: any) => removeMatchHandler(id),
                            icon: () => <FontAwesomeIcon icon={faTrash} />,
                            tooltip: 'Remove from matches',
                        },
                    ]}
                />
            </Grid>
            {showActionDialog && (
                <Dialog open={showActionDialog} scroll="paper">
                    <ActionDialog id={selectedMatch} callback={actionDialogCallback} />
                </Dialog>
            )}
        </Grid>
    );
};

export default Matches;
