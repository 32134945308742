export enum WhatsNewInstanceEnum {
    NAV_WHATS_NEW_INSTANCES = 'whats-new-instances',
    CONTAINER = 'whats-new-instances-container',
    TYPE_DROPDOWN = 'whats-new-instance-type-dropdown',
    TEMPLATE_DROPDOWN = 'whats-new-instance-template-dropdown',
    MODAL_CREATE_BUTTON = 'add-whats-new-instance-button',
    ADD_WHATS_NEW_INSTANCE_SUMMARY = 'add-whats-new-instance-summary',
    HEADER_DISPLAY_ICON_DROPDOWN = 'header-display-icon-dropdown',
    HEADER_DISPLAY_LABELS = 'header-display-labels',
    CONTENT_DISPLAY_TITLES = 'content-display-titles',
    CONTENT_DISPLAY_LABELS = 'content-display-labels',
    CONTENT_DISPLAY_DESCRIPTIONS = 'content-display-descriptions',
    OTHER_PRIORITY_DROPDOWN = 'other-priority-dropdown',
    OTHER_HIGHLIGHTED_CHECKBOX = 'highlighted-checkbox',
    OTHER_CLOSEABLE_CHECKBOX = 'closeable-checkbox',
    OTHER_WHEN_TYPE_DROPDOWN = 'other-when-type-dropdown',
    WHEN_TIMELINE_DROPDOWN = 'when-timeline-dropdown',
    SAVE_AND_CLOSE = 'save-and-close-button',
}

export const WhatsNewInstanceSelectors = {
    NAV_WHATS_NEW_INSTANCES: `a[data-test="${WhatsNewInstanceEnum.NAV_WHATS_NEW_INSTANCES}"]`,
    ADD_NEW_INSTANCE_BUTTON: `div[data-test="${WhatsNewInstanceEnum.CONTAINER}"] button[title="Add New Instance"]`,
    TYPE_DROPDOWN: `div[data-test="${WhatsNewInstanceEnum.TYPE_DROPDOWN}"] div[role="button"]`,
    TYPE_DROPDOWN_ALERT_OPTION: 'li[data-test="alert"]',
    TEMPLATE_DROPDOWN: `div[data-test="${WhatsNewInstanceEnum.TEMPLATE_DROPDOWN}"] div[role="button"]`,
    MODAL_CREATE_BUTTON: `button[data-test="${WhatsNewInstanceEnum.MODAL_CREATE_BUTTON}"]`,
    ADD_WHATS_NEW_INSTANCE_SUMMARY: `p[data-test="${WhatsNewInstanceEnum.ADD_WHATS_NEW_INSTANCE_SUMMARY}"]`,
    HEADER_DISPLAY_ICON_DROPDOWN: `div[data-test="${WhatsNewInstanceEnum.HEADER_DISPLAY_ICON_DROPDOWN}"] div[role="button"]`,
    HEADER_DISPLAY_LABEL_EN: `div[data-test="${WhatsNewInstanceEnum.HEADER_DISPLAY_LABELS}"] input[name="header.label.en"]`,
    CONTENT_DISPLAY_TITLE_EN: `div[data-test="${WhatsNewInstanceEnum.CONTENT_DISPLAY_TITLES}"] input[name="content.title.en"]`,
    CONTENT_DISPLAY_LABEL_EN: `div[data-test="${WhatsNewInstanceEnum.CONTENT_DISPLAY_LABELS}"] input[name="content.label.en"]`,
    CONTENT_DISPLAY_DESCRIPTION_ONE: `div[data-test="${WhatsNewInstanceEnum.CONTENT_DISPLAY_DESCRIPTIONS}"] textarea[name="content.description.0.en"]`,
    COLOR_PICKER_BACKGROUND_VALUE: 'div[data-test="Background Color"] input',
    COLOR_PICKER_TEXT_VALUE: 'div[data-test="Text Color"] input',
    OTHER_PRIORITY_DROPDOWN: `div[data-test="${WhatsNewInstanceEnum.OTHER_PRIORITY_DROPDOWN}"] div[role="button"]`,
    OTHER_HIGHLIGHTED_CHECKBOX: `span[data-test="${WhatsNewInstanceEnum.OTHER_HIGHLIGHTED_CHECKBOX}"] input`,
    OTHER_CLOSEABLE_CHECKBOX: `span[data-test="${WhatsNewInstanceEnum.OTHER_CLOSEABLE_CHECKBOX}"] input`,
    OTHER_WHEN_TYPE_DROPDOWN: `div[data-test="${WhatsNewInstanceEnum.OTHER_WHEN_TYPE_DROPDOWN}"] div[role="button"]`,
    WHEN_TIMELINE_DROPDOWN: `div[data-test="${WhatsNewInstanceEnum.WHEN_TIMELINE_DROPDOWN}"] div[role="button"]`,
    SAVE_AND_CLOSE_BUTTON: `button[data-test="${WhatsNewInstanceEnum.SAVE_AND_CLOSE}"]`,
    SEARCH_INPUT: `div[data-test="${WhatsNewInstanceEnum.CONTAINER}"] input[placeholder="Search"]`,
    DELETE_INSTANCE_BUTTON: `div[data-test="${WhatsNewInstanceEnum.CONTAINER}"] button[title="Delete Instance"]`,
};
