import React from 'react';
import { Typography, Grid, Button } from '@mui/material';

type ZipCodesLabelProps = {
    count: number;
    children?: React.ReactNode;
    clearHandler: () => void;
};

const ZipCodesLabel: React.FC<ZipCodesLabelProps> = props => {
    const { count, children, clearHandler } = props;
    return (
        <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
            minHeight={60}
        >
            <Grid item xs={6} container columnSpacing={2} alignItems="center">
                <Grid item>
                    <Typography variant="h6">{count} Zip Codes</Typography>
                </Grid>
                <Grid item>
                    <Button variant="text" onClick={clearHandler}>
                        Clear All
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={6} justifyContent="flex-end" container>
                {children}
            </Grid>
        </Grid>
    );
};

export default ZipCodesLabel;
