import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    formContainer: {
        width: '100%',
    },
    footer: {
        paddingTop: '80px !important',
    },
}));
