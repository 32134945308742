import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
    Add,
    ArrowDownward,
    ArrowUpward,
    Delete,
    ExpandMore,
    InfoOutlined,
} from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { AlertSeverity } from '~/schemaTypes';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import { deriveNestedPropertyPath } from '../../../helpers/stringHelpers';
import FormBuilder, { FormBuilderFormField } from '../FormBuilder';
import FormInput from '../FormInput/FormInput';

const useStyles = makeStyles()(theme => ({
    root: {},
    array_input_error: {
        borderBottom: `1px solid ${theme.colors.ErrorRed}`,
        color: theme.colors.ErrorRed,
    },
}));

type ArrayInputProps = {
    field: FormBuilderFormField;
};

const ArrayInput: React.FC<ArrayInputProps> = ({ field }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { classes } = useStyles();

    const [hidden, setHidden] = useState<boolean>(false);

    const {
        control,
        watch,
        unregister,
        getValues,
        formState: { errors },
    } = useFormContext();

    const { name, label, fields, options, watch: watchField } = field;
    const {
        fields: arrayFields,
        append,
        remove,
        move,
    } = useFieldArray({
        control,
        name,
        keyName: 'arrayInputId',
    });

    const handleAddItem = () => {
        if (fields == null) {
            append({ value: '' });
        } else {
            const newField: Record<string, unknown> = {};
            append(newField);
        }
    };

    let watchResult: any;
    if (watchField?.field) {
        watchResult = watch(deriveNestedPropertyPath(watchField?.field, name));
    }

    const watchArrayInputLabel = (index: number) => {
        return watch(`${name}[${index}].label.en`);
    };
    const makeDefaultTitleForArrayInput = () => {
        return name
            .split('.')
            .map(field => field[0].toUpperCase() + field.slice(1))
            .map(field =>
                field.includes('[')
                    ? `${field.split('[')[0]} ${
                          parseInt(field.replace(']', '').split('[')[1], 10) + 1
                      }`
                    : field,
            )
            .join(' - ');
    };

    useEffect(() => {
        const hideInput = () => {
            setHidden(true);
            unregister(name);
        };
        const showInput = () => {
            setHidden(false);
        };
        if (watchField?.handler) {
            watchField?.handler({
                value: watchResult,
                hide: hideInput,
                show: showInput,
            });
        }
    }, [name, unregister, watchField, watchResult]);

    if (hidden) {
        return null;
    }

    const error = _.get(errors, name);

    return (
        <Grid item xs={12} container spacing={1}>
            <Grid
                item
                xs={12}
                container
                justifyContent="space-between"
                className={error && error.message ? classes.array_input_error : ''}
                alignItems="center"
            >
                <Typography variant="h6">{`${label}${options?.required ? '*' : ''} `}:</Typography>
                <Tooltip title={`Add ${label}`}>
                    <IconButton aria-label="Add Item" onClick={handleAddItem} size="large">
                        <Add />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                {arrayFields.map((arrayField, index) => {
                    return (
                        <Accordion TransitionProps={{ unmountOnExit: true }}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Grid item xs={12} container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Tooltip title="Move Up">
                                            <IconButton
                                                aria-label="Move Up"
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    move(index, index - 1);
                                                }}
                                                onFocus={event => event.stopPropagation()}
                                                size="large"
                                            >
                                                <ArrowUpward />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="Move Down">
                                            <IconButton
                                                aria-label="Move Down"
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    move(index, index + 1);
                                                }}
                                                onFocus={event => event.stopPropagation()}
                                                size="large"
                                            >
                                                <ArrowDownward />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h6">
                                            {`${index + 1}. `}
                                            {watchArrayInputLabel(index) ||
                                                makeDefaultTitleForArrayInput()}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1">
                                            <Tooltip title={arrayField.arrayInputId || true}>
                                                <IconButton
                                                    onClick={() => {
                                                        copy(`${arrayField.arrayInputId}`);
                                                        TriggerGlobalAlert({
                                                            message:
                                                                'Profile Definition Id Copied to Clipboard',
                                                            severity: AlertSeverity.Success,
                                                        });
                                                    }}
                                                    size="large"
                                                >
                                                    <InfoOutlined />
                                                </IconButton>
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip
                                            title={`Delete ${
                                                name[0].toUpperCase() + name.slice(1)
                                            } ${index + 1}`}
                                        >
                                            <IconButton
                                                aria-label={`Delete ${
                                                    name[0].toUpperCase() + name.slice(1)
                                                } ${index + 1}`}
                                                onFocus={event => event.stopPropagation()}
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    TriggerGlobalConfirm({
                                                        message: `Are you sure you want to delete this item?`,
                                                        callback: () => {
                                                            remove(index);
                                                        },
                                                    });
                                                }}
                                                size="large"
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid
                                    key={arrayField.arrayInputId}
                                    item
                                    xs={12}
                                    container
                                    spacing={1}
                                    alignItems="center"
                                >
                                    {fields ? (
                                        <Grid item xs={12}>
                                            <FormBuilder
                                                nested={{
                                                    fieldProps: { ...field, watch: undefined },
                                                }}
                                                fields={fields.map(field => {
                                                    return {
                                                        ...field,
                                                        name: `${name}[${index}].${field.name}`,
                                                        ...('defaultValue' in field
                                                            ? {
                                                                  defaultValue:
                                                                      typeof field.defaultValue ===
                                                                      'function'
                                                                          ? field.defaultValue()
                                                                          : field.defaultValue,
                                                              }
                                                            : {
                                                                  defaultValue: getValues(
                                                                      `${name}[${index}].${field.name}`,
                                                                  ),
                                                              }),
                                                    };
                                                })}
                                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                                handleSubmit={() => {}}
                                            />
                                        </Grid>
                                    ) : (
                                        <>
                                            <Grid item xs={11}>
                                                <FormInput
                                                    field={{
                                                        ...field,
                                                        name: `${name}[${index}].value`,
                                                        defaultValue: field.defaultValue
                                                            ? field.defaultValue[index]
                                                            : null,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Tooltip title="Delete Item">
                                                    <IconButton
                                                        aria-label="Delete Item"
                                                        onClick={() => remove(index)}
                                                        size="large"
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default ArrayInput;
