import React, { createContext, useContext, useMemo, useState } from 'react';

interface InactiveLoginWrapperProps {
    children: React.ReactElement<any, any> | null;
}

type InactiveLoginContextValue = {
    isTokenExpired: boolean;
    setIsTokenExpired: (value: boolean) => void;
};

const InactiveLoginContext = createContext<InactiveLoginContextValue>({
    isTokenExpired: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setIsTokenExpired: () => {},
});

export const useInactiveLoginCthx = () => useContext(InactiveLoginContext);

const InactiveLoginWrapper: React.FC<InactiveLoginWrapperProps> = ({ children }) => {
    const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);
    const inactiveLoginContextValue = useMemo(
        () => ({
            isTokenExpired,
            setIsTokenExpired,
        }),
        [isTokenExpired],
    );
    return (
        <InactiveLoginContext.Provider value={inactiveLoginContextValue}>
            {children}
        </InactiveLoginContext.Provider>
    );
};

export default InactiveLoginWrapper;
