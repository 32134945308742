export const getMimeType = (fileName: string) => {
    const fileExtension = fileName.split('.').pop();
    switch (fileExtension) {
        case 'pdf':
            return 'application/pdf';
        default:
            return `image/${fileExtension}`;
    }
};

export const generateFileFromString = (data?: Buffer, name?: string) => {
    if (data && name) {
        const blob = new Blob([new Uint8Array(data as unknown as Buffer).buffer]);
        return new File([blob], name, {
            type: getMimeType(name),
        });
    }
    return null;
};
