import * as Yup from 'yup';

export const COMPOUND_QUESTION_SCHEMA = Yup.object().shape({
    label: Yup.object().shape({
        en: Yup.string().required('Label (en) is required'),
        es: Yup.string().nullable(),
    }),
    profileValueType: Yup.string().required('Profile Value Type is required'),
    questionProfileDefs: Yup.array()
        .required('Profile Definitions is required')
        .of(
            Yup.object().shape({
                questionProfileDefId: Yup.string().required('Profile Definition is required'),
                labelOverride: Yup.object()
                    .shape({
                        en: Yup.string(),
                        es: Yup.string(),
                    })
                    .nullable(),
                value: Yup.object().shape({
                    bool: Yup.boolean().required('Profile Value is required'),
                }),
            }),
        ),
});
