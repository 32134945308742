import * as Yup from 'yup';
import { ClientSelectionType } from '~/enums/enums';
import { ClockType, HighlightType } from '~/schemaTypes';

export const HIGHLIGHT_PROMOTION_SCHEMA = Yup.object().shape({
    priority: Yup.number().required('Priority field is required'),
    tags: Yup.array().of(Yup.string()),
    clientSelectionType: Yup.mixed().oneOf(Object.values(ClientSelectionType)).required(),
    includeApps: Yup.array()
        .of(Yup.string())
        .when('clientSelectionType', ([clientSelectionType], schema) => {
            if (clientSelectionType === ClientSelectionType.IncludeCertainApps) {
                return schema.min(1, 'Must select at least one app to include').required();
            }
            return schema;
        }),
    excludeApps: Yup.array()
        .of(Yup.string())
        .when('clientSelectionType', ([clientSelectionType], schema) => {
            if (clientSelectionType === ClientSelectionType.ExcludeCertainApps) {
                return schema.min(1, 'Must select at least one app to exclude').required();
            }
            return schema;
        }),
    type: Yup.mixed().oneOf(Object.values(HighlightType)).required(),
    timelineId: Yup.string()
        .nullable()
        .when('clockType', ([clockType], schema) => {
            if (clockType === ClockType.Timeline) {
                return schema.required('Timeline is required');
            }
            return schema;
        }),
    displayAsModal: Yup.boolean(),
});
