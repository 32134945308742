export enum ExpressionEnum {
    NAV_EXPRESSIONS = 'expressions',
    CONTAINER = 'expression-container',
    NAME_INPUT = 'expression-name-input',
    DESCRIPTION_TEXTAREA = 'expression-description-textarea',
    RULES_ADD_ITEM_BUTTON = 'expression-rules-add-item-button',
    RULE_MODAL = 'expression-rule-modal',
    DATASET_DROPDOWN = 'expression-dataset-dropdown',
    OPERATORS_A_DROPDOWN = 'expression-operators-a-dropdown',
    OPERATORS_U_DROPDOWN = 'expression-operators-u-dropdown',
    OPERATORS_U_CHOICES_DROPDOWN = 'expression-operators-u-choices-dropdown',
    RULE_MODAL_SUBMIT_BUTTON = 'expression-rule-modal-submit-button',
    SAVE_AND_CLOSE = 'save-and-close',
}

export const ExpressionSelectors = {
    NAV_EXPRESSIONS: `a[data-test="${ExpressionEnum.NAV_EXPRESSIONS}"]`,
    ADD_EXPRESSION_BUTTON: `div[data-test="${ExpressionEnum.CONTAINER}"] button[title="Add Expression"]`,
    NAME_INPUT: `div[data-test="${ExpressionEnum.NAME_INPUT}"] input`,
    DESCRIPTION_TEXTAREA: `div[data-test="${ExpressionEnum.DESCRIPTION_TEXTAREA}"] textarea[id="description"]`,
    RULES_ADD_ITEM_BUTTON: `button[data-test="${ExpressionEnum.RULES_ADD_ITEM_BUTTON}"]`,
    RULES_AND_GROUP_OPTION: 'li[data-test="And group"]',
    RULES_OR_GROUP_OPTION: 'li[data-test="Or group"]',
    RULES_EQUAL_RULE_OPTION: 'li[data-test="Equal rule"]',
    RULE_MODAL: `div[data-test="${ExpressionEnum.RULE_MODAL}"]`,
    DATASET_DROPDOWN: `select[data-test="${ExpressionEnum.DATASET_DROPDOWN}"]`,
    OPERATORS_A_DROPDOWN: `select[data-test="${ExpressionEnum.OPERATORS_A_DROPDOWN}"]`,
    OPERATORS_U_DROPDOWN: `div[data-test="${ExpressionEnum.OPERATORS_U_DROPDOWN}"] input`,
    OPERATORS_U_CHOICES_DROPDOWN: `select[data-test="${ExpressionEnum.OPERATORS_U_CHOICES_DROPDOWN}"]`,
    RULE_MODAL_SUBMIT_BUTTON: `button[data-test="${ExpressionEnum.RULE_MODAL_SUBMIT_BUTTON}"]`,
    SAVE_AND_CLOSE_BUTTON: `button[data-test="${ExpressionEnum.SAVE_AND_CLOSE}"]`,
};
