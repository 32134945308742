import {
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    Fade,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AddCircleOutline, Delete, Link as LinkIcon } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import React, { useState } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import {
    AlertSeverity,
    FetchOrgDetailsQuery,
    useAddInviteCodeOnOrgPageMutation,
    useDeleteInviteCodeOnOrgPageMutation,
} from '~/schemaTypes';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';

type Organization = NonNullable<FetchOrgDetailsQuery['organization']>;

const useStyles = makeStyles()({
    root: {},
});

type OrgInviteCodesProps = {
    orgId: string;
    inviteCodes: Organization['inviteCodes'];
};

const OrgInviteCodes: React.FC<OrgInviteCodesProps> = ({ orgId, inviteCodes }) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [inviteCode, setInviteCode] = useState<string>('');

    const [addCode] = useAddInviteCodeOnOrgPageMutation({
        onCompleted: data => {
            if (data.addOrganizationInviteCode?.success) {
                setOpen(false);
                setInviteCode('');
            }
        },
    });

    const [deleteCode] = useDeleteInviteCodeOnOrgPageMutation();

    const handleDeleteCode = (inviteCode: string) => {
        TriggerGlobalConfirm({
            callback: () => {
                deleteCode({
                    variables: {
                        input: {
                            orgId,
                            inviteCode,
                        },
                    },
                });
            },
            message: `Are you sure you wish to delete the invite code: ${inviteCode}?`,
        });
    };

    const handleAddCode = () => {
        if (inviteCode.includes(' ')) {
            TriggerGlobalAlert({
                message: 'Codes cannot contain spaces',
                severity: AlertSeverity.Error,
            });
        } else {
            addCode({
                variables: {
                    input: {
                        orgId,
                        inviteCode,
                    },
                },
            });
        }
    };

    const handleCancelAddCode = () => {
        setInviteCode('');
        setOpen(false);
    };

    return (
        <>
            <Fade in timeout={500}>
                <Grid container className={classes.root} spacing={1}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Typography variant="h6">Patient Invite Codes:</Typography>
                            <IconButton onClick={() => setOpen(true)} size="large">
                                <AddCircleOutline />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Card elevation={0}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {_.map(inviteCodes, code => (
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="space-between"
                                            wrap="nowrap"
                                        >
                                            <Typography variant="subtitle1">{code}</Typography>
                                            <Grid item>
                                                <Grid
                                                    container
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    wrap="nowrap"
                                                >
                                                    <Tooltip title="Copy Invite URL">
                                                        <IconButton
                                                            onClick={() => {
                                                                const url = window.location.host;
                                                                copy(
                                                                    `${url}/patient-invite?practiceCode=${code}`,
                                                                );
                                                                TriggerGlobalAlert({
                                                                    message:
                                                                        'Invitation Link Copied to Clipboard',
                                                                    severity: AlertSeverity.Success,
                                                                });
                                                            }}
                                                            size="large"
                                                        >
                                                            <LinkIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete Invite Code">
                                                        <IconButton
                                                            onClick={() =>
                                                                handleDeleteCode(code ?? '')
                                                            }
                                                            size="large"
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Fade>
            <Dialog open={open}>
                <DialogTitleWithClose id="Add Patient Invite Code" onClose={handleCancelAddCode}>
                    Add Patient Invite Code
                </DialogTitleWithClose>
                <DialogContent dividers>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="New Invite Code"
                        value={inviteCode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setInviteCode(e.target.value)
                        }
                        placeholder="PRACTICE2021"
                        helperText="Invite codes cannot contain spaces."
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelAddCode} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={handleAddCode} color="primary" variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default OrgInviteCodes;
