export enum SurveyEnum {
    NAV_SURVEYS = 'surveys',
    CONTAINER = 'surveys-container',
    SURVEY_NAME = 'survey-name-input',
    LABEL = 'survey-label-input',
    SURVEY_TYPE = 'survey-type-input',
    SUMMARY_TITLE = 'survey-summary-title-input',
    SUMMARY_BODY = 'survey-summary-body-input',
    ADD_SECTION_BUTTON = 'add-section-button',
    ADD_MODAL = 'add-modal',
    ADD_MODAL_LABEL = 'add-modal-label',
    ADD_MODAL_SUBMIT = 'add-modal-submit',
    ADD_SCREEN = 'add-screen',
    ADD_QUESTION = 'add-question',
    ADD_COMPOUND_QUESTION = 'add-compound-question',
    ADD_TUTORIAL_CONTENT = 'add-tutorial-content',
    QUESTION_MODAL = 'question-modal',
    EDIT_QUESTION_MODAL_LABEL_OVERRIDE = 'edit-question-modal-label-override',
    EDIT_QUESTION_MODAL_SUBMIT = 'edit-question-modal-submit',
    SAVE_AND_CLOSE = 'save-and-close-button',
}

export const SurveySelectors = {
    NAV_SURVEYS: `a[data-test="${SurveyEnum.NAV_SURVEYS}"]`,
    ADD_SURVEY_BUTTON: `div[data-test="${SurveyEnum.CONTAINER}"] button[title="Add Survey"]`,
    SURVEY_NAME: `div[data-test="${SurveyEnum.SURVEY_NAME}"] input`,
    LABEL: `div[data-test="${SurveyEnum.LABEL}"] input`,
    SURVEY_TYPE: `div[data-test="${SurveyEnum.SURVEY_TYPE}"] input`,
    SUMMARY_TITLE: `div[data-test="${SurveyEnum.SUMMARY_TITLE}"] input`,
    SUMMARY_BODY: `div[data-test="${SurveyEnum.SUMMARY_BODY}"] textarea`,
    ADD_SECTION_BUTTON: `button[data-test="${SurveyEnum.ADD_SECTION_BUTTON}"]`,
    ADD_MODAL: `form[data-test="${SurveyEnum.ADD_MODAL}"]`,
    ADD_MODAL_LABEL: `div[data-test="${SurveyEnum.ADD_MODAL_LABEL}"] input`,
    ADD_MODAL_SUBMIT: `button[data-test="${SurveyEnum.ADD_MODAL_SUBMIT}"]`,
    ACTION_MENU: 'div[role="presentation"] ul[role="menu"] ul[tabindex="0"]',
    SECTION_ACTIONS_BUTTON: 'button[data-test="section-label"]',
    SCREEN_ACTIONS_BUTTON: 'button[data-test="screen-label"]',
    ADD_SCREEN_ITEM: `li[data-test="${SurveyEnum.ADD_SCREEN}"]`,
    ADD_QUESTION_ITEM: `li[data-test="${SurveyEnum.ADD_QUESTION}"]`,
    QUESTION_MODAL: `form[data-test="${SurveyEnum.QUESTION_MODAL}"]`,
    QUESTION_MODAL_LABEL_OVERRIDE: `div[data-test="${SurveyEnum.EDIT_QUESTION_MODAL_LABEL_OVERRIDE}"] input`,
    QUESTION_MODAL_SUBMIT: `button[data-test="${SurveyEnum.EDIT_QUESTION_MODAL_SUBMIT}"]`,
    SAVE_AND_CLOSE_BUTTON: `button[data-test="${SurveyEnum.SAVE_AND_CLOSE}"]`,
    SEARCH_INPUT: `div[data-test="${SurveyEnum.CONTAINER}"] input[placeholder="Search"]`,
};
