import { makeStyles } from 'tss-react/mui';
import { errorRed } from '~/theme/WildTheme';

export const useStyles = makeStyles()({
    root: {
        '& .MuiFormControl-root': {
            padding: 0,
        },
    },
    dialogActions: {
        textAlign: 'right',
        margin: '0 16px',
        width: '100%',
        '& button:not(:last-child)': {
            marginRight: 10,
        },
    },
    actionTypeBtn: {
        color: '#8dc42e',
        '&:hover': {
            cursor: 'pointer',
            filter: 'brightness(70%)',
        },
    },
    helperMessage: {
        marginLeft: 15,
    },
    actionType: {
        fontWeight: 'bold',
    },
    validationError: {
        color: errorRed,
        marginBottom: 10,
    },
    actionTypesOutlinedSection: {
        marginLeft: 0,
        marginRight: 0,
        width: '100%',
    },
});
