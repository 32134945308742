import {
    VirtualCareAffiliatesEntity,
    OnSuccessfulEligibility,
    AffiliateCoverageArea,
    AffiliateOfficeAddress,
    LactationConsultant,
} from '~/schemaTypes';
import { FormInput } from './types';

export const coverageAreasMapper = (areas?: AffiliateCoverageArea[]) => {
    return (areas || []).map(area => ({
        name: area?.name || '',
        zipCodes: area?.zipCodes || [],
        VCZipCodeToLocationIds: area?.VCZipCodeToLocationIds || [],
        zipCodeSource: area.zipCodeSource || null,
    }));
};

export const officeAddressesMapper = (offices?: AffiliateOfficeAddress[]) => {
    return (offices || []).map(office => ({
        name: office?.name || '',
        address: office?.address || '',
        zipCodes: office?.zipCodes || [],
        VCZipCodeToLocationIds: office?.VCZipCodeToLocationIds || [],
        addressZipCode: office?.addressZipCode || '',
    }));
};

export const lactationConsultantsMapper = (consultants?: LactationConsultant[]) => {
    return (consultants || []).map(consultant => ({
        name: consultant.name || '',
        IBCLCNumber: consultant.IBCLCNumber || '',
        IBCLCExpiration: consultant.IBCLCExpiration || '',
        IBCLCCertification: consultant.IBCLCCertification || null,
        CAQHNumber: consultant.CAQHNumber || null,
        NPINumber: consultant.NPINumber || '',
        fileName: consultant.fileName || '',
        s3Link: consultant.s3Link || '',
        liabilityInsuranceDate: consultant.liabilityInsuranceDate || '',
    }));
};

export const mapDataToFormInput = (data: VirtualCareAffiliatesEntity): FormInput => {
    return {
        internalName: data.internalName,
        externalName: data.externalName,
        contactName: data.contactName,
        contactEmail: data.contactEmail,
        contactPhoneNumber: data.contactPhoneNumber,
        onSuccessfulEligibility: data.onSuccessfulEligibility as OnSuccessfulEligibility,
        routingURL: data.routingURL,
        phoneNumberForScheduling: data.phoneNumberForScheduling,
        emailAddressForScheduling: data.emailAddressForScheduling,
        customSuccessText: data.customSuccessText,
        notifyCompanyWhenPatientPassesEligibility: data.notifyCompanyWhenPatientPassesEligibility,
        BillDotComVendorId: data.BillDotComVendorId,
        website: data.website,
        inviteAffiliate: data.inviteAffiliate,
        id: data.id,
        affiliateId: data.affiliateId,
        onboardingStatus: data.onboardingStatus,
        lactationConsultants: lactationConsultantsMapper(data.lactationConsultants),
        billDotComInitData: {
            BillDotComNameOnCheck: data.billDotComInitData?.BillDotComNameOnCheck || '',
            BillDotComAddress1: data.billDotComInitData?.BillDotComAddress1 || '',
            BillDotComAddress2: data.billDotComInitData?.BillDotComAddress2 || '',
            BillDotComAddressCity: data.billDotComInitData?.BillDotComAddressCity || '',
            BillDotComAddressState: data.billDotComInitData?.BillDotComAddressState || '',
            BillDotComAddressZip: data.billDotComInitData?.BillDotComAddressZip || '',
        },
        supportPhone: data.supportPhone || '',
        isVirtualType: data.isVirtualType || false,
        isInOfficeType: data.isInOfficeType || false,
        isPatientsHomeType: data.isPatientsHomeType || false,
        officeAddresses: officeAddressesMapper(data.officeAddresses),
        coverageAreas: coverageAreasMapper(data.coverageAreas),
        supportEmail: data.supportEmail || '',
    };
};
