import { Card, Divider, Grid, List, ListItemButton, ListItemText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useMemo } from 'react';
import { NavLink, NavLinkProps, Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUserPermissions } from '~/hooks';

const useStyles = makeStyles()(theme => ({
    root: {},
    sidebar: {},
    navLink: {
        transition: 'color .2s ease-in-out',
        color: '#000',
        '&:hover': {
            color: theme.colors.Primary(),
        },
        '&.active': {
            color: theme.colors.Primary(),
        },
    },
}));

const PortalSettings: React.FC = () => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const location = useLocation();
    const { pathname } = location;
    // todo: there could be better way to handle default route for parent component
    const isParent = pathname.split('/').length === 3;
    const PatientPages = useMemo(
        () => [
            {
                to: '/portal/portal-settings/plans',
                label: 'Health Plans',
                hide: !pagePermissions?.HealthPlans.Read,
            },
            {
                to: '/portal/portal-settings/providers',
                label: 'Providers',
                hide: !pagePermissions?.Providers.Read,
            },
            {
                to: '/portal/portal-settings/devices',
                label: 'Devices',
                hide: !pagePermissions?.Devices.Read,
            },
            {
                to: '/portal/portal-settings/my-account',
                label: 'My Account',
            },
        ],
        [pagePermissions],
    );
    const CustomLink = React.forwardRef(
        (props: React.PropsWithoutRef<NavLinkProps>, ref: React.Ref<HTMLAnchorElement>) => (
            <NavLink
                ref={ref}
                {...props}
                className={({ isActive }) => `${classes.navLink} ${isActive ? 'active' : ''}`}
            />
        ),
    );
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item className={classes.sidebar} xs={12} md={2}>
                    <Card style={{ padding: 5, position: 'sticky', top: 0 }}>
                        <List>
                            {PatientPages.map(({ to, label }, i) => (
                                <div key={to}>
                                    <ListItemButton component={CustomLink} to={to}>
                                        <ListItemText primary={label} />
                                    </ListItemButton>
                                    {i < PatientPages.length - 1 && <Divider />}
                                </div>
                            ))}
                        </List>
                    </Card>
                </Grid>
                <Grid item xs={12} md={10}>
                    {isParent ? <Navigate to="/portal/portal-settings/plans" /> : <Outlet />}
                </Grid>
            </Grid>
        </div>
    );
};

export default PortalSettings;
