import { makeStyles } from 'tss-react/mui';
import { keyframes } from 'tss-react';

const useStyles = makeStyles()(_theme => ({
    root: {
        position: 'relative',
        height: '100%',
        '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
            opacity: '0',
        },
    },
    searchField: {
        position: 'absolute',
        right: 0,
        top: 0,
        padding: 0,
    },
    questionModal: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        '& > .headerRow': {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 20,
        },
        '& > .noQuestions': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 200,
        },
    },
    deletePatientTitle: {
        color: _theme.palette.warning.dark,
    },
    tasksButton: {
        '&:hover': { backgroundColor: 'transparent' },
        '&.Mui-disabled': { color: '#116b8e' },
    },
    rotating: {
        animation: `${keyframes`
        from {
            transform: rotate(360deg);
        }
        to {
            transform: rotate(0deg);
        }
        `} 1s linear infinite`,
    },
}));

export default useStyles;
