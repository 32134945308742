// This component has a UI Test
import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    ConditionTypeListDocument,
    useConditionTypeListQuery,
    useConditionTypeWhereUsedLazyQuery,
    useDeleteConditionTypeMutation,
} from '~/schemaTypes';
import { ConditionTypeEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';
import { ConditionTypeModal } from './components/ConditionTypeModal';
import { useStyles } from './styles';
import { ConditionType } from './types';

const ConditionTypes: React.FC = () => {
    const { classes } = useStyles();
    const { data, loading } = useConditionTypeListQuery();
    const [isConditionTypeModalOpen, setConditionTypeModalOpen] = useState<boolean>(false);
    const [showUsageModal, setShowUsageModal] = useState(false);
    const [selectedConditionType, setSelectedConditionType] = useState<ConditionType | null>(null);
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        useConditionTypeWhereUsedLazyQuery();
    const [deleteItem, { loading: deleteItemLoading }] = useDeleteConditionTypeMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: ConditionTypeListDocument,
                variables: {},
            },
        ],
    });
    const handleDelete = () => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${selectedConditionType?.name.en}'?`,
            callback: () => {
                deleteItem({ variables: { id: selectedConditionType?.id } });
                setShowUsageModal(false);
            },
        });
    };
    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleShowUsage = (item: ConditionType) => {
        whereUsed({
            variables: {
                id: item?.id,
            },
        });
        setShowUsageModal(true);
    };

    return (
        <>
            <div className={classes.root} data-test={ConditionTypeEnum.CONTAINER} ref={tableRef}>
                <MaterialTable<ConditionType>
                    title="Condition Types"
                    icons={tableIcons}
                    isLoading={loading || whereUsedLoading || deleteItemLoading}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    data={_.cloneDeep(data?.conditionTypes) ?? []}
                    actions={[
                        {
                            onClick: () => setConditionTypeModalOpen(true),
                            icon: () => <Add />,
                            tooltip: 'Add Condition Type',
                            isFreeAction: true,
                            hidden: !pagePermissions?.ConditionTypes.Edit,
                        },
                        {
                            onClick: (_, conditionType) => {
                                const isSingleItemSelected = !Array.isArray(conditionType);
                                if (isSingleItemSelected) {
                                    setConditionTypeModalOpen(true);
                                    setSelectedConditionType(conditionType);
                                }
                            },
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit Condition Type',
                            hidden: !pagePermissions?.ConditionTypes.Edit,
                        },
                        {
                            onClick: (_e, item) => {
                                setSelectedConditionType(item as ConditionType);
                                handleShowUsage(item as ConditionType);
                            },
                            icon: () => <FontAwesomeIcon icon={faEye} />,
                            tooltip: 'View References',
                        },
                    ]}
                    columns={[
                        {
                            title: 'Name',
                            field: 'name.en',
                            defaultSort: 'asc',
                            render: ({ name }) => (
                                <Typography data-test={name.en}>{name.en}</Typography>
                            ),
                        },
                    ]}
                    options={{ pageSize: rowsPerPage }}
                    localization={{ header: { actions: '' } }}
                />
            </div>
            <ConditionTypeModal
                isOpen={isConditionTypeModalOpen}
                conditionType={selectedConditionType || null}
                onClose={() => {
                    setConditionTypeModalOpen(false);
                    setSelectedConditionType(null);
                }}
            />
            <Dialog scroll="paper" open={!whereUsedLoading && showUsageModal}>
                <WhereUsedModal
                    onClose={() => setShowUsageModal(false)}
                    onDelete={handleDelete}
                    title={`Usage for ${selectedConditionType?.name.en}`}
                    canDelete={pagePermissions?.ConditionTypes.Delete || false}
                    usageData={whereUsedData?.conditionTypeWhereUsed?.dependencies || []}
                    permissions={pagePermissions}
                />
            </Dialog>
        </>
    );
};

export default ConditionTypes;
