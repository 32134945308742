import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';

type LayoutProps = {
    title: string;
    children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = props => {
    const { title, children } = props;
    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            {children}
        </Grid>
    );
};

export default Layout;
