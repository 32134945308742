import { Menu, MenuItem, MenuList } from '@mui/material';
import React from 'react';
import { SurveyDefSectionInput } from '~/schemaTypes';
import { SurveyEnum } from '~/selectors';
import { SECTION_ACTIONS_ENUM } from '../enums/sectionActionsEnum';

export const SectionActionDropdown: React.FC<{
    sec: SurveyDefSectionInput | undefined;
    anchor: HTMLElement | null;
    onUserSelectAction: (action: SECTION_ACTIONS_ENUM) => void;
    onUserCloseDropdown: () => void;
}> = props => {
    const { sec, anchor, onUserSelectAction, onUserCloseDropdown } = props;
    return (
        <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={onUserCloseDropdown} elevation={1}>
            <MenuList dense>
                <MenuItem key="S1" onClick={() => onUserSelectAction(SECTION_ACTIONS_ENUM.EDIT)}>
                    Edit
                </MenuItem>
                <MenuItem
                    key="S2"
                    onClick={() => onUserSelectAction(SECTION_ACTIONS_ENUM.ADD_CONTENT)}
                >
                    Add Content
                </MenuItem>
                <MenuItem
                    key="S3"
                    onClick={() => onUserSelectAction(SECTION_ACTIONS_ENUM.ADD_TEXT)}
                >
                    Add Text
                </MenuItem>
                <MenuItem
                    key="S4"
                    onClick={() => onUserSelectAction(SECTION_ACTIONS_ENUM.ADD_QUESTION)}
                    data-test={SurveyEnum.ADD_QUESTION}
                >
                    Add Question
                </MenuItem>
                <MenuItem
                    key="S7"
                    onClick={() => onUserSelectAction(SECTION_ACTIONS_ENUM.ADD_COMPOUND_QUESTION)}
                    data-test={SurveyEnum.ADD_COMPOUND_QUESTION}
                >
                    Add Compound Question
                </MenuItem>
                <MenuItem
                    key="S5"
                    onClick={() => onUserSelectAction(SECTION_ACTIONS_ENUM.ADD_TUTORIAL_CONTENT)}
                    data-test={SurveyEnum.ADD_TUTORIAL_CONTENT}
                >
                    Add Tutorial Content
                </MenuItem>
                {sec && sec.pieces?.length > 1 && (
                    <MenuItem
                        key="S6"
                        onClick={() => onUserSelectAction(SECTION_ACTIONS_ENUM.REORDER)}
                    >
                        Reorder Items
                    </MenuItem>
                )}
            </MenuList>
        </Menu>
    );
};
