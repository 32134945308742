import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Dialog, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import React, { useState } from 'react';
import {
    ActionSource,
    AdvocateTaskChecklistItemInput as Step,
    FetchAdvocateTaskForAdvocateTaskPageQuery,
    FetchPatientActionTypesForChecklistModalPageQuery,
    useCreatePatientActionsForAdvocateTaskPageMutation,
    useDeletePatientActionByStepForAdvocateTaskMutation,
} from '~/schemaTypes';
import parse from 'html-react-parser';
import { ChecklistItemModal } from '../../components';
import { getStepDiff, renderActionTypeLabels } from '../History/helpers';
import { useStyles } from './styles';
import { ActionType } from '../../components/ChecklistItemModal/types';

type IAdvocateTask = NonNullable<FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']>;

type PatientActionType = NonNullable<IAdvocateTask['actionTypes']>[0];

type PatientAction = NonNullable<IAdvocateTask['patientActions']>[0];

type UpdatedPatientActionType = NonNullable<
    FetchPatientActionTypesForChecklistModalPageQuery['patientActionTypesV2']
>['results'][0];

interface StepsProps {
    advocateTask: IAdvocateTask;
    steps: Step[];
    actorId?: string;
    patientActionTypes: PatientActionType[];
    patientActions: PatientAction[];
    onStepsUpdated: (steps: Step[], historyStepAction: string[]) => void;
    onPatientActionCreatedAutomatically: (steps: Step[], what: string[], who?: string) => void;
}

const Steps: React.FC<StepsProps> = ({
    steps,
    advocateTask,
    actorId,
    patientActionTypes,
    patientActions,
    onStepsUpdated,
    onPatientActionCreatedAutomatically,
}) => {
    const checked: boolean[] = steps.map(step => Boolean(step.completedBy));
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isCompleteMode, setIsCompleteMode] = useState<boolean>(false);
    const [selectedStep, setSelectedStep] = useState<Step | null>(null);
    const [stepLabel, setStepLabel] = useState<string | null>(null);
    const [updatedSteps, setUpdatedSteps] = useState<Step[]>(steps);

    const { classes } = useStyles();

    const handleAddStepClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseChecklistModal = () => {
        setSelectedStep(null);
        setIsModalOpen(false);
        setIsCompleteMode(false);
    };

    const [createPatientActions] = useCreatePatientActionsForAdvocateTaskPageMutation({
        onCompleted: ({ createPatientActions }) => {
            const historyItemDescriptions = [];

            if (createPatientActions && stepLabel) {
                const resourcesCreated = createPatientActions?.resourcesCreated ?? [];

                for (const resourceCreated of resourcesCreated) {
                    const actionType = patientActionTypes.find(
                        actionType => actionType.id === resourceCreated?.typeId,
                    );

                    const historyItemDescription = `completed '${stepLabel}' Step and '${actionType?.label}' Action was created automatically`;
                    historyItemDescriptions.push(historyItemDescription);
                }

                onPatientActionCreatedAutomatically(updatedSteps, historyItemDescriptions, actorId);
            }
        },
    });

    const [deletePatientActionByStep] = useDeletePatientActionByStepForAdvocateTaskMutation({
        onCompleted: ({ deletePatientActionByStep }) => {
            if (deletePatientActionByStep && stepLabel) {
                const actionType = patientActionTypes.find(
                    actionType =>
                        actionType.id === deletePatientActionByStep.resourceDeleted?.typeId,
                );

                const historyItemDescription = `uncompleted '${stepLabel}' Step and '${actionType?.label}' Action was removed automatically`;
                onPatientActionCreatedAutomatically(
                    updatedSteps,
                    [historyItemDescription],
                    actorId,
                );
            }
        },
    });

    const handleStepDelete = (step: Step) => {
        const filteredSteps = steps.filter(item => item.id !== step.id);
        onStepsUpdated(filteredSteps, [`removed '${step.label}' Step`]);
        handleCloseChecklistModal();
    };

    const handleStepModalSubmit = async (
        newStep: Step,
        newActionTypes: UpdatedPatientActionType[],
        checkedActionTypes?: ActionType[],
    ) => {
        if (!newActionTypes) {
            newActionTypes = [];
        }
        const indexToUpdate = steps.findIndex(item => item.id === newStep.id);
        if (isCompleteMode) {
            const updatedSteps = [...steps];
            const isChecked = checked[steps.findIndex(item => item.id === selectedStep?.id)];
            updatedSteps[indexToUpdate] = {
                ...newStep,
                // actionTypeIds: updatedSteps[indexToUpdate].actionTypeIds,
                completedAt: isChecked ? null : newStep.completedAt,
                completedBy: isChecked ? null : actorId,
            };

            if (advocateTask.patientId && checkedActionTypes && checkedActionTypes.length > 0) {
                setStepLabel(updatedSteps[indexToUpdate].label);
                setUpdatedSteps(updatedSteps);
                if (isChecked) {
                    deletePatientActionByStep({
                        variables: {
                            input: {
                                stepId: newStep.id,
                            },
                        },
                    });
                } else {
                    const stepHistoryItems = checkedActionTypes.map(actionType => {
                        const actionTypeData = patientActionTypes.find(
                            actionTypeItem => actionTypeItem.id === actionType.typeId,
                        );

                        return `${!isChecked ? 'completed' : 'uncompleted'} '${
                            actionTypeData?.label
                        }'`;
                    });

                    onStepsUpdated(updatedSteps, stepHistoryItems);

                    createPatientActions({
                        variables: {
                            input: {
                                patientId: advocateTask.patientId,
                                patientActions: checkedActionTypes.map(checkedActionType => ({
                                    patientId: advocateTask.patientId,
                                    typeId: checkedActionType.typeId,
                                    taskId: advocateTask.id,
                                    actorId,
                                    stepId: newStep.id,
                                    description: updatedSteps[indexToUpdate].description,
                                    completedAt: newStep.completedAt,
                                    source: ActionSource.HaTask,
                                })),
                            },
                        },
                    });
                }
            } else {
                onStepsUpdated(updatedSteps, [
                    `${!isChecked ? 'completed' : 'uncompleted'} '${newStep.label}' Step`,
                ]);
            }
            handleCloseChecklistModal();
            return;
        }
        if (indexToUpdate === -1) {
            const actionTypeHistoryMessage = newStep.actionTypeIds.length
                ? ` with ${renderActionTypeLabels(
                      newStep.actionTypeIds,
                      [],
                      [...patientActionTypes, ...newActionTypes],
                  )} Action Type(s)`
                : '';
            onStepsUpdated(
                [...steps, newStep],
                [`created '${newStep.label}' Step${actionTypeHistoryMessage}`],
            );
        } else {
            const updatedSteps = [...steps];
            updatedSteps[indexToUpdate] = newStep;

            const stepDiff = getStepDiff(
                newStep,
                steps,
                indexToUpdate,
                [...patientActionTypes, ...newActionTypes] || [],
            );
            onStepsUpdated(updatedSteps, [`edited '${newStep.label}' Step: ${stepDiff}`]);
        }
        handleCloseChecklistModal();
    };

    const handleEditStepClick = (step: Step) => {
        setSelectedStep(step);
        setIsModalOpen(true);
    };

    const handleStepChecked = (step: Step) => {
        setSelectedStep(step);
        setIsCompleteMode(true);
        setIsModalOpen(true);
    };

    return (
        <>
            <Grid container item xs={12}>
                <Grid item container alignItems="center" xs={12}>
                    <Grid item>
                        <Typography className={classes.stepsInfo} variant="subtitle1">
                            Steps {checked.filter(Boolean).length}/{checked.length} Complete
                        </Typography>
                    </Grid>
                    <Grid item className={classes.addButtonWrapper}>
                        <Tooltip title="Add Step">
                            <IconButton
                                className={classes.addButton}
                                onClick={handleAddStepClick}
                                size="large"
                            >
                                <AddOutlinedIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {steps.map((step, index) => (
                        <Grid
                            key={step.id}
                            container
                            alignItems="baseline"
                            style={{ display: 'flex', flexWrap: 'nowrap' }}
                        >
                            <Grid item>
                                <Checkbox
                                    color="primary"
                                    value={step.id}
                                    checked={checked[index]}
                                    onChange={() => handleStepChecked(step)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <span className={classes.stepLabel}>{parse(step.label)}</span>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Edit">
                                    <IconButton
                                        onClick={() => handleEditStepClick(step)}
                                        size="large"
                                    >
                                        <FontAwesomeIcon icon={faPenToSquare} size="xs" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Dialog
                scroll="paper"
                open={isModalOpen}
                fullWidth
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
            >
                <ChecklistItemModal
                    onSubmit={handleStepModalSubmit}
                    data={selectedStep}
                    onDelete={handleStepDelete}
                    checked={checked[steps.findIndex(item => item.id === selectedStep?.id)]}
                    closeModal={handleCloseChecklistModal}
                    isEditMode={Boolean(selectedStep)}
                    isCompleteMode={isCompleteMode}
                    patientActions={patientActions}
                    completedAt={selectedStep?.completedAt}
                    completedBy={selectedStep?.completedBy}
                />
            </Dialog>
        </>
    );
};

export default Steps;
