import React, { useCallback, useState, useEffect } from 'react';
import { Button, Grid, Typography, MenuItem, FormControl, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import AddressAutocomplete, {
    MetaData,
} from '~/views/AffiliateCare/components/AddressAutocomplete/AddressAutocomplete';
import { useGetZipCodesByFilterLazyQuery } from '~/schemaTypes';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import { ZipCodesType } from './constants';
import CloseButton from './CloseButton';

const MILES_OPTIONS = ['5', '10', '15', '25', '50', '75'];

type AddZipCodesByAddressProps = {
    onComplete: (zipCodes: string[], type?: ZipCodesType) => void;
};

const useStyles = makeStyles()(() => ({
    container: {
        minHeight: 120,
        flexWrap: 'nowrap',
        '@media (max-width: 1250px)': {
            flexWrap: 'wrap',
        },
    },
}));

const AddZipCodesByAddress: React.FC<AddZipCodesByAddressProps> = props => {
    const { onComplete } = props;
    const { classes } = useStyles();
    const [zipCodes, setZipCodes] = useState<string[]>([]);
    const [radius, setRadius] = useState(MILES_OPTIONS[0]);
    const [addressZipCode, setAddressZipCode] = useState('');
    const [autocompleteError, setAutocompleteError] = useState('');
    const [getZipCodesByRadius, { loading, data: zipCodesByRadius }] =
        useGetZipCodesByFilterLazyQuery();
    const addressAutocompleteHandler = useCallback((address: string, meta: MetaData) => {
        setAddressZipCode(meta.zipCode ?? '');
        setAutocompleteError(meta.message ?? '');
    }, []);
    useEffect(() => {
        if (addressZipCode && radius) {
            getZipCodesByRadius({
                variables: {
                    input: {
                        zipCode: addressZipCode,
                        radius,
                    },
                },
            });
        } else {
            setZipCodes([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressZipCode, radius]);
    useEffect(() => {
        setZipCodes(zipCodesByRadius?.getZipCodesByFilter?.zipCodes || []);
    }, [zipCodesByRadius]);
    const handleClick = useCallback(() => {
        onComplete(zipCodes, ZipCodesType.Address);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipCodes]);
    const handleClose = useCallback(() => {
        onComplete([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <OutlinedSection title="Add Zip Codes By Address">
            <CloseButton handleClose={handleClose} />
            <Grid
                item
                container
                xs={12}
                alignItems="center"
                columnSpacing={2}
                className={classes.container}
                rowSpacing={2}
            >
                <Grid item sm="auto" xs={12}>
                    <Typography variant="subtitle1">See Zip Codes within</Typography>
                </Grid>
                <Grid item sm="auto" xs={12}>
                    <FormControl>
                        <TextField
                            value={radius}
                            onChange={e => setRadius(e.target.value)}
                            disabled={loading}
                            select
                        >
                            {MILES_OPTIONS.map(r => (
                                <MenuItem key={r} value={r}>
                                    {r} miles
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid item sm="auto" xs={12}>
                    <Typography variant="subtitle1">Of</Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <FormControl fullWidth>
                        <AddressAutocomplete
                            setValue={addressAutocompleteHandler}
                            disabled={loading}
                            errorMessage={autocompleteError}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm="auto" xs={12}>
                    <AsyncActionButton loading={loading}>
                        <Button
                            disabled={loading || !zipCodes.length}
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                            size="large"
                        >
                            Add {zipCodes.length} Zip Codes
                        </Button>
                    </AsyncActionButton>
                </Grid>
            </Grid>
        </OutlinedSection>
    );
};

export default AddZipCodesByAddress;
