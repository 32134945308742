import React from 'react';
import { Grid, DialogContent, TextField, MenuItem } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import {
    useEligibilityCreateAccessCodeMutation,
    useEligibilityUpdateAccessCodeMutation,
} from '~/schemaTypes';
import { useStyles } from '../styles';
import { VALIDATION_SCHEMA } from './yupSchema';
import { IAccessCodeFormValue } from './types';

type ActionDialogProps = {
    id?: string;
    values?: IAccessCodeFormValue;
    callback: () => void;
    unitNames: string[];
};

const ActionDialog: React.FC<ActionDialogProps> = props => {
    const { callback, values, unitNames, id } = props;
    const { classes } = useStyles();
    const [createAccessCodeMutation, { loading: createLoading }] =
        useEligibilityCreateAccessCodeMutation({
            onCompleted: () => callback(),
        });
    const [updateAccessCodeMutation, { loading: updateLoading }] =
        useEligibilityUpdateAccessCodeMutation({
            onCompleted: () => callback(),
        });
    const { handleSubmit, control } = useForm<IAccessCodeFormValue>({
        resolver: yupResolver(VALIDATION_SCHEMA),
        values,
    });
    const loading = createLoading || updateLoading;
    const isEdit = !!id;
    const onSubmit: SubmitHandler<IAccessCodeFormValue> = data => {
        const inputData = {
            ...data,
            firstName: data.firstName.toUpperCase(),
            lastName: data.lastName.toUpperCase(),
        };
        if (isEdit) {
            updateAccessCodeMutation({
                variables: {
                    input: {
                        data: inputData,
                        id,
                    },
                },
            });
        } else {
            createAccessCodeMutation({
                variables: {
                    input: inputData,
                },
            });
        }
    };
    const title = id ? 'Edit Eligibility Data' : 'New Eligibility Data';
    return (
        <div>
            <DialogTitleWithClose id="form-dialog-title" onClose={callback}>
                {title}
            </DialogTitleWithClose>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction="column" rowSpacing={1} marginTop={3}>
                        <Grid item container justifyContent="flex-start">
                            <Grid item xs={8}>
                                <Controller
                                    control={control}
                                    name="firstName"
                                    render={({ field, formState: { errors } }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            autoFocus={!isEdit}
                                            label="Patient FN"
                                            error={!!errors.firstName}
                                            helperText={
                                                errors.firstName && 'First Name is required'
                                            }
                                        />
                                    )}
                                    rules={{ required: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="flex-start">
                            <Grid item xs={8}>
                                <Controller
                                    control={control}
                                    name="lastName"
                                    render={({ field, formState: { errors } }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            autoFocus={!isEdit}
                                            label="Patient LN"
                                            error={!!errors.lastName}
                                            helperText={errors.lastName && 'Last Name is required'}
                                        />
                                    )}
                                    rules={{ required: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="flex-start">
                            <Grid item xs={8}>
                                <Controller
                                    control={control}
                                    name="birthDate"
                                    render={({ field, formState: { errors } }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            autoFocus={!isEdit}
                                            label="DOB"
                                            error={!!errors.birthDate}
                                            helperText={errors.birthDate && 'DOB is required'}
                                        />
                                    )}
                                    rules={{ required: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="flex-start">
                            <Grid item xs={8}>
                                <Controller
                                    control={control}
                                    name="unitName"
                                    render={({ field, formState: { errors } }) => (
                                        <TextField
                                            {...field}
                                            select
                                            fullWidth
                                            error={!!errors.unitName}
                                            helperText={
                                                errors.unitName && 'Please select an option'
                                            }
                                        >
                                            {unitNames.map(value => (
                                                <MenuItem key={value} value={value}>
                                                    {value}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                    rules={{ required: true }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                <Grid container justifyContent="flex-start" columnSpacing={2}>
                    <Grid item>
                        <LoadingButton
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                            className={classes.actionButton}
                            loading={loading}
                        >
                            Save
                        </LoadingButton>
                    </Grid>
                    <Grid item>
                        <LoadingButton
                            onClick={callback}
                            variant="outlined"
                            className={classes.actionButton}
                            loading={loading}
                        >
                            Cancel
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </div>
    );
};

export default ActionDialog;
