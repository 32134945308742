import { AdvocateTaskCommentInput, FetchAdvocateTaskForAdvocateTaskPageQuery } from '~/schemaTypes';

type AdvocateTaskComment = NonNullable<
    FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']
>['comments'][0];

type ToAdvocateTaskCommentsInput = (comments: AdvocateTaskComment[]) => AdvocateTaskCommentInput[];

const toAdvocateTaskCommentsInput: ToAdvocateTaskCommentsInput = comments =>
    comments.map(({ authorId, message, when }) => ({
        authorId,
        message,
        when,
    }));

export default toAdvocateTaskCommentsInput;
