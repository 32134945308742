import { ArticlePromotionInput } from '~/schemaTypes';
import { ClientSelectionType, OffsetType } from '~/enums/enums';
import { ArticlePromotionFormInput } from '../types/types';

const toIncludeOrExclude = (articlePromotion: Partial<ArticlePromotionFormInput>) => {
    const { clientSelectionType, includeApps, excludeApps } = articlePromotion ?? {};
    switch (clientSelectionType) {
        case ClientSelectionType.IncludeCertainApps:
            return {
                includeApps: includeApps ?? [],
                excludeApps: [],
            };
        case ClientSelectionType.ExcludeCertainApps:
            return {
                includeApps: [],
                excludeApps: excludeApps ?? [],
            };
        case ClientSelectionType.IncludeAllApps:
        default:
            return {
                includeApps: [],
                excludeApps: [],
            };
    }
};

export const toRequestInput = (
    form: Partial<ArticlePromotionFormInput>,
    selectedArticle: string,
): ArticlePromotionInput => {
    const {
        tagIds,
        type,
        startDate,
        endDate,
        priority,
        timelineId,
        duration,
        durationTimeUnit,
        offsetDays: offsetDaysInput,
        offsetMonths: offsetMonthsInput,
        offsetYears: offsetYearsInput,
        offsetType,
        title,
        titleSp,
    } = form;
    const offsetDays =
        offsetType === OffsetType.After ? Number(offsetDaysInput) : -(offsetDaysInput ?? 0);
    const offsetMonths =
        offsetType === OffsetType.After ? Number(offsetMonthsInput) : -(offsetMonthsInput ?? 0);
    const offsetYears =
        offsetType === OffsetType.After ? Number(offsetYearsInput) : -(offsetYearsInput ?? 0);
    return {
        ...toIncludeOrExclude(form),
        articleId: selectedArticle,
        title,
        titleSp,
        priority: priority != null ? priority : 5,
        tagIds,
        type,
        startDate,
        endDate,
        timelineId: timelineId || null,
        offsetDays,
        offsetMonths,
        offsetYears,
        duration: typeof duration === 'string' ? Number(duration) : duration,
        durationTimeUnit,
    };
};
