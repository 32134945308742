import { AdvocateTaskTemplateForAdvocateTaskTemplatePageQuery } from '~/schemaTypes';
import { ClientSelectionType } from '~/enums/enums';
import { getOffsets, toTimeUnit } from '~/helpers/minuteConverters';
import { AdvocateTaskTemplateFormInput } from '../types/AdvocateTaskTemplateFormInput';

type WhenTimeline = NonNullable<
    NonNullable<
        AdvocateTaskTemplateForAdvocateTaskTemplatePageQuery['advocateTaskTemplate']
    >['whenTimeline']
>;

const toClientSelectionType = ({ excludeApps, includeApps }: WhenTimeline): ClientSelectionType => {
    if (includeApps && includeApps.length > 0) return ClientSelectionType.IncludeCertainApps;
    if (excludeApps && excludeApps.length > 0) return ClientSelectionType.ExcludeCertainApps;
    return ClientSelectionType.IncludeAllApps;
};

export const toFormValuesFromAdvocateTaskTemplate = (
    advocateTaskTemplate?: AdvocateTaskTemplateForAdvocateTaskTemplatePageQuery,
): AdvocateTaskTemplateFormInput => {
    const taskTemplate = advocateTaskTemplate?.advocateTaskTemplate;
    const timeline = taskTemplate?.whenTimeline;
    const offset = getOffsets(
        timeline?.offsetDays ?? 0,
        timeline?.offsetMonths ?? 0,
        timeline?.offsetYears ?? 0,
    );
    const duration = toTimeUnit(timeline?.durationMinutes ?? 0);
    const interventionDuration = toTimeUnit(taskTemplate?.interventionDurationMinutes ?? 0);

    return {
        label: taskTemplate?.label ?? '',
        description: taskTemplate?.description ?? '',
        checklist:
            taskTemplate?.checklist.map(checklistItem => ({
                ...checklistItem,
                label: { en: checklistItem.label },
            })) ?? [],
        priority: taskTemplate?.priority ?? 5,
        whenType: taskTemplate?.whenType,
        typeId: taskTemplate?.typeId ?? '',
        newType: '',
        whenTimeline: {
            timelineId: timeline?.timelineId ?? '',
            offsetDays: offset.days,
            offsetMonths: offset.months,
            offsetYears: offset.years,
            offsetType: offset.type,
            durationUnit: duration.unit,
            durationValue: duration.value,
            includeApps: timeline?.includeApps ?? [],
            excludeApps: timeline?.excludeApps ?? [],
            tags: timeline?.tags ?? [],
            clientSelectionType: timeline
                ? toClientSelectionType(timeline)
                : ClientSelectionType.IncludeAllApps,
        },
        durationValue: interventionDuration.value,
        durationUnit: interventionDuration.unit,
        shouldNotify: taskTemplate?.shouldNotify ?? false,
        slaId: taskTemplate?.slaId,
    };
};
