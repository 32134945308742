import * as Yup from 'yup';
import moment from 'moment/moment';

const DATE_FORMAT = 'MM/DD/YYYY';

declare module 'yup' {
    interface DateSchema {
        dateFormatDateInput(): this;
    }
    interface ValidateOptions {
        originalValue?: string;
    }
}

// eslint-disable-next-line func-names
Yup.addMethod<Yup.DateSchema>(Yup.date, 'dateFormatDateInput', function (this: Yup.DateSchema) {
    return this.test(
        'date validation',
        `Invalid date or format. The string should be a valid ${DATE_FORMAT} format.`,
        // eslint-disable-next-line func-names
        function (this, val) {
            if (!val) {
                return true;
            }
            const originalValue = this.options.originalValue || this.originalValue;
            return moment(originalValue, DATE_FORMAT, true).isValid();
        },
    );
});

export default Yup.object().shape({
    firstName: Yup.string().required('Patient First name is required'),
    lastName: Yup.string().required('Patient Last name is required'),
    birthDate: Yup.date()
        // eslint-disable-next-line no-template-curly-in-string
        .typeError('Expected a value of type ${type} but got: ${value}')
        .dateFormatDateInput()
        .required('Patient Birth date is required'),
    sessionDate: Yup.date()
        // eslint-disable-next-line no-template-curly-in-string
        .typeError('Expected a value of type ${type} but got: ${value}')
        .dateFormatDateInput()
        .required('Date of Session is required'),
    insuranceId: Yup.string().required('Insurance ID is required'),
    note: Yup.string().required('Note is required'),
    providerName: Yup.string().required('Provider Name is required'),
    providerNumber: Yup.string().required('Provider Number (NPI) is required'),
});
