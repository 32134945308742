import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import StateAutocomplete from '~/components/StateAutocomplete/StateAutocomplete';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import Loading from '~/components/Loading/Loading';
import { AffiliateUserFormInput } from './types';

type BillComProps = {
    isEdit?: boolean;
    loading: boolean;
};

const BillCom: React.FC<BillComProps> = props => {
    const { isEdit = false, loading } = props;
    const {
        setValue,
        resetField,
        watch,
        register,
        formState: { errors },
    } = useFormContext<AffiliateUserFormInput>();
    const createVendor = watch('createVendor');
    const state = watch('state');
    useEffect(() => {
        if (!isEdit) {
            resetField('vendorId');
            resetField('nameOnCheck');
            resetField('address2');
            resetField('address1');
            resetField('city');
            resetField('state');
            resetField('zip');
            resetField('vendorName');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createVendor]);

    return (
        <OutlinedSection title="Bill.com Information">
            {loading && <Loading />}
            {!loading && (
                <Grid container spacing={1}>
                    {!isEdit && (
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Create Vendor in Bill.com"
                                labelPlacement="end"
                                control={
                                    <Checkbox
                                        id="createVendor"
                                        name="createVendor"
                                        checked={createVendor}
                                        onChange={e => setValue('createVendor', e.target.checked)}
                                    />
                                }
                            />
                        </Grid>
                    )}
                    {(!createVendor || isEdit) && (
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Vendor Id"
                                {...register('vendorId')}
                                type="text"
                                margin="dense"
                                fullWidth
                                error={!!errors.vendorId}
                                helperText={errors.vendorId?.message}
                                InputLabelProps={{ shrink: true }}
                                disabled={isEdit}
                            />
                        </Grid>
                    )}
                    {(createVendor || isEdit) && (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="Vendor Name"
                                    {...register('vendorName')}
                                    type="text"
                                    margin="dense"
                                    fullWidth
                                    error={!!errors.vendorName}
                                    helperText={errors.vendorName?.message}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={isEdit}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="Name On Check"
                                    {...register('nameOnCheck')}
                                    type="text"
                                    margin="dense"
                                    fullWidth
                                    error={!!errors.nameOnCheck}
                                    helperText={errors.nameOnCheck?.message}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={isEdit}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="Address 1"
                                    {...register('address1')}
                                    type="text"
                                    margin="dense"
                                    fullWidth
                                    error={!!errors.address1}
                                    helperText={errors.address1?.message}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={isEdit}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="Address 2"
                                    {...register('address2')}
                                    type="text"
                                    margin="dense"
                                    fullWidth
                                    error={!!errors.address2}
                                    helperText={errors.address2?.message}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={isEdit}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="City"
                                    {...register('city')}
                                    type="text"
                                    margin="dense"
                                    fullWidth
                                    error={!!errors.city}
                                    helperText={errors.city?.message}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={isEdit}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StateAutocomplete
                                    isMobileView={false}
                                    name="state"
                                    label="State"
                                    register={register('state')}
                                    onChange={value =>
                                        setValue('state', value, { shouldValidate: true })
                                    }
                                    errorMessage={errors.state?.message}
                                    value={state}
                                    disabled={isEdit}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="Zip"
                                    {...register('zip')}
                                    type="text"
                                    margin="dense"
                                    fullWidth
                                    error={!!errors.zip}
                                    helperText={errors.zip?.message}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={isEdit}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            )}
        </OutlinedSection>
    );
};

export default BillCom;
