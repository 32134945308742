export enum ProfileDefEnum {
    NAV_PROFILE_DEFINITIONS = 'profile-definitions',
    CONTAINER = 'profile-def-container',
    MODAL = 'profile-def-modal',
    MODAL_NAME_INPUT = 'modal-name-input',
    MODAL_DESCRIPTION_TEXTAREA = 'modal-description-textarea',
    MODAL_CATEGORY_INPUT = 'modal-category-input',
    MODAL_VALUE_TYPE_DROPDOWN = 'modal-value-type-dropdown',
    MODAL_LABEL_EN_INPUT = 'modal-label-en-input',
    MODAL_QUESTION_LABEL_EN_INPUT = 'modal-question-label-en-input',
    MODAL_CREATE_PROFILE_DEF_FORM = 'modal-create-profile-def-form',
}

export const ProfileDefSelectors = {
    NAV_PROFILE_DEFINITIONS: `a[data-test="${ProfileDefEnum.NAV_PROFILE_DEFINITIONS}"]`,
    ADD_PROFILE_DEF_BUTTON: `div[data-test="${ProfileDefEnum.CONTAINER}"] button[title="Add Profile Definition"]`,
    MODAL_NAME_INPUT: `div[data-test="${ProfileDefEnum.MODAL_NAME_INPUT}"] input`,
    MODAL_DESCRIPTION_TEXTAREA: `div[data-test="${ProfileDefEnum.MODAL_DESCRIPTION_TEXTAREA}"] textarea:first-child`,
    MODAL_CATEGORY_INPUT: `div[data-test="${ProfileDefEnum.MODAL_CATEGORY_INPUT}"] input`,
    MODAL_VALUE_TYPE_DROPDOWN: `div[data-test="${ProfileDefEnum.MODAL_VALUE_TYPE_DROPDOWN}"] input`,
    MODAL_VALUE_TYPE_DROPDOWN_STR_OPTION: `ul[role='listbox'] li[data-option-index="9"]`,
    MODAL_LABEL_EN_INPUT: `div[data-test="${ProfileDefEnum.MODAL_LABEL_EN_INPUT}"] input`,
    MODAL_QUESTION_LABEL_EN_INPUT: `div[data-test="${ProfileDefEnum.MODAL_QUESTION_LABEL_EN_INPUT}"] input`,
    MODAL_SAVE_BUTTON: `form[data-test="${ProfileDefEnum.MODAL}"] button[type="submit"]`,
    SEARCH_INPUT: `div[data-test="${ProfileDefEnum.CONTAINER}"] input[placeholder="Search"]`,
    DELETE_BUTTON: `div[data-test="${ProfileDefEnum.CONTAINER}"] button[title="Delete Profile Definition"]`,
    MODAL_CREATE_PROFILE_DEF_FORM: `div[data-test="${ProfileDefEnum.MODAL_CREATE_PROFILE_DEF_FORM}"] form`,
};
