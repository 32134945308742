/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from 'copy-to-clipboard';
import { TriggerGlobalAlert } from '~/state';
import { AlertSeverity } from '~/schemaTypes';
import { useStyles } from './styles';

export const EmailHelpLink: React.FC = () => {
    const { classes } = useStyles();

    return (
        <div className={classes.helpLink}>
            <Link variant="body2">
                Need help?
                <br /> Email us at&nbsp;
            </Link>
            <Link
                href="mailto:support@wildflowerhealth.com?subject=Wildflower Health Portal - Login Help needed"
                target="_blank"
                rel="noopener noreferrer"
                variant="body2"
            >
                <span className={classes.underlined}>support@wildflowerhealth.com</span>
            </Link>
            <IconButton
                aria-label="copy email"
                onClick={() => {
                    copy('support@wildflowerhealth.com');
                    TriggerGlobalAlert({
                        message: 'Copied!',
                        severity: AlertSeverity.Success,
                    });
                }}
                size="small"
            >
                <ContentCopyIcon />
            </IconButton>
        </div>
    );
};
