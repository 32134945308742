import * as Yup from 'yup';
import { RoutingMethod } from '~/schemaTypes';

export const VC_ZIP_CODE_TO_LOCATION_SCHEMA = Yup.object().shape({
    locationName: Yup.string().required('Location Name is required'),
    zipCode: Yup.string().required('Zip Code is a required field'),
    locationId: Yup.string().when(['routingMethod'], ([routingMethod], schema) => {
        if (routingMethod === RoutingMethod.TimeTap) {
            return schema.required();
        }
        return schema.nullable();
    }),
    affiliateId: Yup.string().when(['routingMethod'], ([routingMethod], schema) => {
        if (routingMethod === RoutingMethod.Affiliate) {
            return schema.required('Please select an option');
        }
        return schema.nullable();
    }),
    routingMethod: Yup.string().required(),
});
