import { OrderByDirectionEnum } from '~/schemaTypes';

export enum OrderByFields {
    name = 'internalName',
}
export const OrderDirectionMap: Record<string, OrderByDirectionEnum> = {
    desc: OrderByDirectionEnum.Desc,
    asc: OrderByDirectionEnum.Asc,
};
export const ROWS_PER_PAGE = 10;
export const DEFAULT_ORDER_DIRECTION = OrderByDirectionEnum.Asc;
