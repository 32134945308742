import React, { useEffect, useState, useMemo } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { debounce } from '@mui/material/utils';
import {
    OrderByDirectionEnum,
    useFetchAffiliatesForFilterPatientsModalLazyQuery,
} from '~/schemaTypes';

type AffiliateOption = {
    title: string;
    value: string;
};

type AffiliateFilterInputProps = {
    onChange: (affiliateId: string) => void;
    affiliateId: string;
    affiliateName: string;
};

const AffiliateFilterInput: React.FC<AffiliateFilterInputProps> = props => {
    const { onChange, affiliateId, affiliateName } = props;
    const [autocompleteSearch, setAutocompleteSearch] = useState('');
    const [autocompleteOptions, setAutocompleteOptions] = useState<AffiliateOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<AffiliateOption | null>(null);
    const [fetchAffiliates, { data: affiliates, loading: affiliatesLoading }] =
        useFetchAffiliatesForFilterPatientsModalLazyQuery();
    useEffect(() => {
        setAutocompleteOptions(
            (affiliates?.virtualCareAffiliates?.results || []).map(a => ({
                title: a.internalName,
                value: a.affiliateId as string,
            })),
        );
    }, [affiliates?.virtualCareAffiliates?.results]);
    useEffect(() => {
        if (affiliateId && affiliateName) {
            setAutocompleteSearch(affiliateName);
            setSelectedOption({
                title: affiliateName,
                value: affiliateId,
            });
        }
    }, [affiliateId, affiliateName]);
    useEffect(() => {
        onChange(selectedOption?.value || '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption]);
    const fetchAffiliatesDebounced = useMemo(
        () =>
            debounce((search: string) => {
                fetchAffiliates({
                    variables: {
                        input: {
                            search,
                            pagination: {
                                limit: 10,
                                skip: 0,
                            },
                            orderBy: {
                                field: 'internalName',
                                order: OrderByDirectionEnum.Asc,
                            },
                        },
                    },
                });
            }, 500),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    useEffect(() => {
        fetchAffiliatesDebounced(autocompleteSearch);
    }, [autocompleteSearch, fetchAffiliatesDebounced]);
    return (
        <Autocomplete
            fullWidth
            blurOnSelect
            getOptionLabel={option => option.title}
            options={autocompleteOptions}
            loading={affiliatesLoading}
            getOptionKey={option => option.value}
            value={selectedOption}
            onInputChange={(_, inputValue) => setAutocompleteSearch(inputValue)}
            inputValue={autocompleteSearch}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Filter Affiliates"
                    placeholder="Type To Complete"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {affiliatesLoading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            onChange={(_, value) => setSelectedOption(value)}
        />
    );
};

export default AffiliateFilterInput;
