import { Autocomplete, Button, Dialog, Grid, MenuItem, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import React, { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import SortableList, {
    ListItemType,
    SortableListOnChangeProps,
} from '~/components/SortableList/SortableList';
import { ProfileScreenEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';
import { SurveyDef } from './types/Schema';
import { Survey } from './types/Survey';
import { SurveyGroupProps } from './types/SurveyGroupProps';

const DEFAULT_NEW_SURVEY = {
    label: {
        en: '',
        es: '',
    },
    surveyId: '',
    tags: [],
};

const SurveyGroup: React.FC<SurveyGroupProps> = ({
    id,
    index,
    label,
    classes,
    useFormMethods,
    onDelete,
    portalTags,
    surveyDefs,
}) => {
    const [isAddingNewSurvey, setIsAddingNewSurvey] = useState(false);
    const [survey, setSurvey] = useState<Survey>(DEFAULT_NEW_SURVEY);
    const [editedSurveyIndex, setEditedSurveyIndex] = useState<number | null>(null);
    const {
        control,
        register,
        formState: { errors },
    } = useFormMethods;

    const { fields, append, move, remove, insert } = useFieldArray({
        control,
        name: `surveyGroups.${index}.surveys`,
    });

    const handleDeleteSurveyGroupClick = (index: number) => {
        return () => onDelete(index);
    };

    const handleDeleteSurveyClick = (index: number) => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete this item?`,
            callback: () => {
                remove(index);
            },
        });
    };

    const handleSurveyGroupChange = ({ startIndex, endIndex }: SortableListOnChangeProps) => {
        move(startIndex, endIndex);
    };

    const handleAddSurveyClick = () => {
        setIsAddingNewSurvey(true);
    };

    const handleAddSurveyConfirmClick = () => {
        if (!survey.surveyId) return;
        const newSurvey = {
            surveyId: survey.surveyId,
            label: survey.label,
            tags: survey.tags,
        };
        if (editedSurveyIndex === null) {
            append(newSurvey);
        } else {
            remove(editedSurveyIndex);
            insert(editedSurveyIndex, newSurvey);
        }
        setIsAddingNewSurvey(false);
        setSurvey(DEFAULT_NEW_SURVEY);
    };

    const handleCloseAddingNewSurvey = () => {
        setSurvey(DEFAULT_NEW_SURVEY);
        setEditedSurveyIndex(null);
        setIsAddingNewSurvey(false);
    };

    const handleSurveyClick = (survey: ListItemType, index: number) => {
        const selectedSurvey = survey as Survey;
        setSurvey({
            label: {
                en: selectedSurvey?.label.en,
                es: selectedSurvey?.label.es,
            },
            surveyId: selectedSurvey?.surveyId,
            tags: selectedSurvey.tags,
        });
        setEditedSurveyIndex(index);
        setIsAddingNewSurvey(true);
    };

    const handleChangeOrgSurvey = (newSurvey: SurveyDef) => {
        return () =>
            setSurvey(survey => ({
                ...survey,
                label: newSurvey.label,
                surveyId: newSurvey.id,
            }));
    };

    return (
        <>
            {isAddingNewSurvey && (
                <Dialog
                    scroll="paper"
                    open={isAddingNewSurvey}
                    onClose={handleCloseAddingNewSurvey}
                    aria-labelledby="form-dialog-title"
                >
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                label="Survey"
                                select
                                value={survey.surveyId}
                                data-test={ProfileScreenEnum.SURVEY_GROUP_ADD_SURVEY_TO_LIST_SURVEY}
                            >
                                {surveyDefs.map(item => (
                                    <MenuItem
                                        onClick={handleChangeOrgSurvey(item)}
                                        key={item?.id}
                                        value={item?.id}
                                        data-test={item.name}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                id="surveyId"
                                disabled
                                fullWidth
                                margin="dense"
                                label="Id"
                                value={survey.surveyId}
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <OutlinedSection title="Label">
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    label="English"
                                    value={survey.label.en}
                                    onChange={e =>
                                        setSurvey(survey => ({
                                            ...survey,
                                            label: {
                                                ...survey.label,
                                                en: e.target.value,
                                            },
                                        }))
                                    }
                                />
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    label="Spanish"
                                    value={survey.label.es}
                                    onChange={e =>
                                        setSurvey(survey => ({
                                            ...survey,
                                            label: {
                                                ...survey.label,
                                                es: e.target.value,
                                            },
                                        }))
                                    }
                                />
                            </OutlinedSection>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                limitTags={5}
                                onChange={(_, tags) =>
                                    setSurvey(survey => ({
                                        ...survey,
                                        tags,
                                    }))
                                }
                                id="new-survey-tags"
                                value={survey.tags || []}
                                defaultValue={survey.tags || []}
                                options={portalTags.map(item => item.id)}
                                getOptionLabel={id =>
                                    portalTags.find(tag => tag.id === id)?.name ?? ''
                                }
                                renderInput={params => (
                                    <TextField
                                        variant="outlined"
                                        label="Tags"
                                        placeholder="Select tags..."
                                        data-test={
                                            ProfileScreenEnum.SURVEY_GROUP_ADD_SURVEY_TO_LIST_TAGS
                                        }
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid className={classes.modalBtns} item xs={12}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleCloseAddingNewSurvey}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={handleAddSurveyConfirmClick}
                                startIcon={<AddIcon />}
                                data-test={
                                    editedSurveyIndex !== null
                                        ? 'Save' // TODO: case for data-attribute for Save button
                                        : ProfileScreenEnum.ADDING_NEW_SURVEY_MODAL_ADD_SURVEY_TO_LIST_BUTTON
                                }
                            >
                                {editedSurveyIndex !== null ? 'Save' : 'Add Survey To List'}
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>
            )}
            <OutlinedSection title="Survey Group">
                <Grid container item xs={12}>
                    {id && (
                        <TextField
                            variant="outlined"
                            className={classes.hiddenInput}
                            label="ID"
                            defaultValue={id}
                            {...register(`surveyGroups.${index}.id`)}
                            fullWidth
                            margin="dense"
                        />
                    )}
                    <Grid container item xs={12}>
                        <OutlinedSection title="Label">
                            <TextField
                                variant="outlined"
                                label="English *"
                                defaultValue={label?.en ?? ''}
                                fullWidth
                                margin="dense"
                                {...register(`surveyGroups.${index}.label.en`)}
                                error={Boolean(errors.surveyGroups?.[index]?.label?.en)}
                                helperText={errors.surveyGroups?.[index]?.label?.en?.message}
                                data-test={ProfileScreenEnum.SURVEY_GROUP_LABEL}
                            />
                            <TextField
                                variant="outlined"
                                label="Spanish"
                                defaultValue={label?.es ?? ''}
                                fullWidth
                                margin="dense"
                                {...register(`surveyGroups.${index}.label.es`)}
                                error={Boolean(errors.surveyGroups?.[index]?.label?.es)}
                                helperText={errors.surveyGroups?.[index]?.label?.es?.message}
                            />
                        </OutlinedSection>
                    </Grid>
                    <Grid container item xs={12}>
                        {fields && fields.length > 0 && (
                            <OutlinedSection className={classes.sortableList} title="Survey Order">
                                <Grid item xs={12}>
                                    <SortableList
                                        list={fields}
                                        droppableId="surveyOrder"
                                        onClick={handleSurveyClick}
                                        onChange={handleSurveyGroupChange}
                                        onDelete={handleDeleteSurveyClick}
                                    >
                                        {(surveyIndex: number, survey: Survey & { id: string }) => (
                                            <div key={survey.id}>
                                                <TextField
                                                    variant="outlined"
                                                    name={`surveyGroups.${index}.surveys.${surveyIndex}.surveyId`}
                                                    className={classes.hiddenInput}
                                                    defaultValue={survey.surveyId || survey.id}
                                                    inputRef={
                                                        register(
                                                            `surveyGroups.${index}.surveys.${surveyIndex}.surveyId`,
                                                        ).ref
                                                    }
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    name={`surveyGroups.${index}.surveys.${surveyIndex}.label.en`}
                                                    className={classes.hiddenInput}
                                                    defaultValue={survey.label.en}
                                                    inputRef={
                                                        register(
                                                            `surveyGroups.${index}.surveys.${surveyIndex}.label.en`,
                                                        ).ref
                                                    }
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    name={`surveyGroups.${index}.surveys.${surveyIndex}.label.es`}
                                                    className={classes.hiddenInput}
                                                    defaultValue={survey.label.es}
                                                    inputRef={
                                                        register(
                                                            `surveyGroups.${index}.surveys.${surveyIndex}.label.es`,
                                                        ).ref
                                                    }
                                                />

                                                {survey?.tags?.map((item, tagIndx) => (
                                                    <TextField
                                                        variant="outlined"
                                                        key={item || tagIndx}
                                                        name={`surveyGroups.${index}.surveys.${surveyIndex}.tags.${tagIndx}`}
                                                        className={classes.hiddenInput}
                                                        defaultValue={item}
                                                        inputRef={
                                                            register(
                                                                `surveyGroups.${index}.surveys.${surveyIndex}.tags.${tagIndx}`,
                                                            ).ref
                                                        }
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </SortableList>
                                </Grid>
                            </OutlinedSection>
                        )}
                        <Grid item xs={12}>
                            <Button
                                className={classes.addSurveyBtn}
                                color="secondary"
                                variant="contained"
                                onClick={handleAddSurveyClick}
                                startIcon={<AddIcon />}
                                data-test={ProfileScreenEnum.ADD_SURVEY_TO_LIST_BUTTON}
                            >
                                Add Survey To List
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <AutocompleteWithRecordOptions
                            options={portalTags ?? []}
                            name={`surveyGroups[${index}].tags`}
                            control={control}
                            valueKey="id"
                            labelKey="name"
                            label="Tags"
                            placeholder="Select tags..."
                            dataTest={ProfileScreenEnum.SURVEY_GROUP_TAGS}
                        />
                    </Grid>
                    <Grid className={classes.surveyGroupActionBtns} item xs={12}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleDeleteSurveyGroupClick(index)}
                            startIcon={<DeleteIcon />}
                        >
                            Remove Survey Group
                        </Button>
                    </Grid>
                </Grid>
            </OutlinedSection>
        </>
    );
};

export default SurveyGroup;
