import * as Yup from 'yup';

export const ST_SETTINGS_FORM_SCHEMA = Yup.object().shape({
    isInOfficeType: Yup.boolean().required(),
    isVirtualType: Yup.boolean().required(),
    isPatientsHomeType: Yup.boolean().required(),
    officeAddresses: Yup.array().when('isInOfficeType', ([isInOfficeType], schema) => {
        return isInOfficeType
            ? schema.min(1, 'Needs to create at least one Office Address').required()
            : schema;
    }),
    coverageAreas: Yup.array().when('isPatientsHomeType', ([isPatientsHomeType], schema) => {
        return isPatientsHomeType
            ? schema.min(1, 'Needs to create at least one Coverage Area').required()
            : schema;
    }),
});
