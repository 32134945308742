import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import { Typography, Grid, Radio } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Loading from '~/components/Loading/Loading';
import { useEligibilityMatchQuery, useUpdateEligibilityMatchMutation } from '~/schemaTypes';
import { displayBirthday } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import Header from '../components/Header';
import { useContext } from './useContext';

const CURRENT_PATIENT_ID = 'currentPatient';
const EMPTY_ROW = 'emtyRow';

type TableData = {
    id: string | number;
    firstName?: string | null;
    lastName?: string | null;
};

const MatchManage: React.FC = () => {
    const { id } = useParams();
    const history = useNavigate();
    const { prevMatchId, leftToMatch, nextMatchId } = useContext();
    const { data, loading } = useEligibilityMatchQuery({
        fetchPolicy: 'network-only',
        variables: {
            input: {
                id,
            },
        },
    });
    const [selectedItem, setSelectedItem] = useState<number>();
    useEffect(() => {
        if (data?.eligibilityMatch) {
            const { firstNameMatch, lastNameMatch } = data.eligibilityMatch;
            if (firstNameMatch && lastNameMatch) {
                const selectedIndex = data.eligibilityMatch.potentialMatches?.findIndex(
                    pm => pm?.firstName === firstNameMatch && pm?.lastName === lastNameMatch,
                );
                if (selectedIndex !== -1) {
                    setSelectedItem(selectedIndex);
                }
            } else {
                setSelectedItem(undefined);
            }
        }
    }, [data]);
    const matchData = data?.eligibilityMatch;
    const moveToNext = useCallback(() => {
        if (nextMatchId) {
            history(`/app-config/eligibility-match/queue/${nextMatchId}?prevMatchId=${id}`);
        } else {
            history('/app-config/eligibility-match/nomorematches');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextMatchId, id]);
    const [updateMatchMutation, { loading: updateMatchMutationLoading }] =
        useUpdateEligibilityMatchMutation({
            onCompleted: () => {
                moveToNext();
            },
        });

    const skipCurrentHandler = useCallback(() => {
        moveToNext();
    }, [moveToNext]);
    const noMatchHandler = useCallback(() => {
        if (id) {
            updateMatchMutation({
                variables: {
                    input: {
                        data: {
                            hasMatch: false,
                            toBeReviewed: false,
                        },
                        id,
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    const matchHandler = useCallback(() => {
        if (id && selectedItem !== undefined) {
            const match = data?.eligibilityMatch?.potentialMatches?.find(
                (_, index) => index === selectedItem,
            );
            const firstNameMatch = match?.firstName;
            const lastNameMatch = match?.lastName;
            updateMatchMutation({
                variables: {
                    input: {
                        data: {
                            hasMatch: true,
                            toBeReviewed: false,
                            firstNameMatch,
                            lastNameMatch,
                            matchDateTime: moment().utc(true),
                        },
                        id,
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem, id]);
    const handleKeyPress = (e: KeyboardEvent) => {
        if (updateMatchMutationLoading) {
            return;
        }
        switch (e.key) {
            case 'N':
                noMatchHandler();
                break;
            case 'S':
                skipCurrentHandler();
                break;
            default:
        }
    };
    useEffect(() => {
        document.addEventListener('keypress', handleKeyPress);
        return () => document.removeEventListener('keypress', handleKeyPress);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextMatchId, prevMatchId, updateMatchMutationLoading]);
    const breadcrumbsTitle = useMemo(() => {
        if (Number.isInteger(leftToMatch)) {
            return `Match Queue (${leftToMatch} left to match)`;
        }
        return 'Match Queue';
    }, [leftToMatch]);
    const tableData: TableData[] = useMemo(() => {
        const initialData = [
            {
                firstName: data?.eligibilityMatch?.patientFirstName,
                lastName: data?.eligibilityMatch?.patientLastName,
                id: CURRENT_PATIENT_ID,
            },
            { id: EMPTY_ROW, firstName: '', lastName: '' },
        ];
        if (data) {
            return [
                ...initialData,
                ...(data.eligibilityMatch?.potentialMatches?.map((pm, index) => ({
                    ...pm,
                    id: index,
                })) || []),
            ];
        }
        return initialData;
    }, [data]);

    if (loading) {
        return <Loading />;
    }
    if (!matchData) {
        history(-1);
        return null;
    }
    return (
        <Grid container rowSpacing={4}>
            <Header lastTitle={breadcrumbsTitle} lastPath="queue" />
            <Grid item xs={12}>
                <Typography>
                    <Link
                        to={`/portal/patients/${matchData.patientId}/overview`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {matchData.patientFirstName} {matchData.patientLastName}
                    </Link>
                    {': '}
                    {matchData.patientDOB && displayBirthday({ isoDateStr: matchData.patientDOB })}
                </Typography>
            </Grid>
            <Grid item container xs={12} columnSpacing={2}>
                <Grid item>
                    <LoadingButton
                        variant="outlined"
                        size="small"
                        loading={updateMatchMutationLoading}
                        onClick={noMatchHandler}
                    >
                        (N)o Match
                    </LoadingButton>
                </Grid>
                <Grid item>
                    <LoadingButton
                        variant="outlined"
                        size="small"
                        onClick={skipCurrentHandler}
                        loading={updateMatchMutationLoading}
                    >
                        (S)kip - Keep in Queue
                    </LoadingButton>
                </Grid>
            </Grid>
            <Grid item xs={12} marginRight="1rem">
                <MaterialTable<TableData>
                    icons={tableIcons as MaterialTableProps<any>['icons']}
                    columns={[
                        {
                            title: 'First Name',
                            field: 'firstName',
                            render: ({ firstName }) => (
                                <Typography data-test={firstName}>{firstName}</Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Last Name',
                            field: 'lastName',
                            render: ({ lastName }) => (
                                <Typography data-test={lastName}>{lastName}</Typography>
                            ),
                            sorting: false,
                        },
                    ]}
                    data={tableData}
                    isLoading={loading}
                    options={{
                        search: false,
                        toolbar: false,
                        paging: false,
                    }}
                    localization={{ header: { actions: '' } }}
                    actions={[
                        {
                            icon: () => null,
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onClick: () => {},
                        },
                    ]}
                    components={{
                        Action: props => {
                            const {
                                data: { id },
                            } = props;
                            if (typeof id !== 'number') {
                                return null;
                            }
                            return (
                                <Radio
                                    checked={selectedItem === id}
                                    onClick={() => setSelectedItem(id)}
                                />
                            );
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <LoadingButton
                    onClick={matchHandler}
                    variant="outlined"
                    size="large"
                    loading={updateMatchMutationLoading}
                >
                    Match
                </LoadingButton>
            </Grid>
            {prevMatchId && (
                <Grid item xs={12}>
                    <Link to={`/app-config/eligibility-match/queue/${prevMatchId}`}>
                        {'<<<Previous'}
                    </Link>
                </Grid>
            )}
        </Grid>
    );
};

export default MatchManage;
