import MaterialTable from '@material-table/core';
import { Add } from '@mui/icons-material';
import { Dialog, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import { SlasV2ForSlAsPageQuery, useSlasV2ForSlAsPageQuery } from '~/schemaTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import SLAModal from './SLAModal/SLAModal';
import { SlaFormInput } from './SLAModal/SLAModal.formValidation';

type SLA = NonNullable<SlasV2ForSlAsPageQuery['slasV2']>['results'][0];

export const SLAs = () => {
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [selectedId, setSelectedId] = React.useState<string>('');
    const [selectedSla, setSelectedSla] = React.useState<SlaFormInput | null>(null);
    const [showSlaModal, setShowSlaModal] = useState<boolean>(false);

    const { data: slasData, loading } = useSlasV2ForSlAsPageQuery();

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div ref={tableRef}>
            <MaterialTable<SLA>
                title="SLAs"
                icons={tableIcons}
                isLoading={loading}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                data={_.cloneDeep(slasData?.slasV2.results) ?? []}
                actions={[
                    {
                        onClick: () => {
                            setSelectedId('');
                            setSelectedSla(null);
                            setShowSlaModal(true);
                        },
                        hidden: !pagePermissions?.Sla.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add SLA',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, sla: SLA) => {
                            const {
                                name,
                                type,
                                timerType,
                                responseCalculation,
                                timeToComplete,
                                calendarId,
                            } = sla;
                            setSelectedId(sla.id);
                            setSelectedSla({
                                name,
                                type,
                                timerType,
                                responseCalculation,
                                timeToComplete,
                                calendarId,
                                // notifyBefore: sla.notifyBefore?.map(time => {
                                //     return {
                                //         whenToNotify: time,
                                //     };
                                // }),
                            });
                            setShowSlaModal(true);
                        },
                        hidden: !pagePermissions?.Sla.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit SLA',
                    },
                ]}
                columns={[
                    {
                        title: 'Name',
                        field: 'name',
                        defaultSort: 'asc',
                        render: ({ name }) => <Typography>{name}</Typography>,
                    },
                    {
                        title: 'Time to complete',
                        field: 'timeToComplete',
                        render: ({ timeToComplete, timerType }) => (
                            <Typography>
                                {timeToComplete} {timerType}
                            </Typography>
                        ),
                    },
                    {
                        title: 'Timer Type',
                        field: 'timerType',
                    },
                    {
                        title: 'Type',
                        field: 'type',
                    },
                    {
                        title: 'Response Calculation',
                        field: 'responseCalculation',
                    },
                ]}
                options={{ pageSize: rowsPerPage }}
                localization={{ header: { actions: '' } }}
            />
            <Dialog scroll="body" open={showSlaModal} fullWidth maxWidth="lg">
                <SLAModal
                    setOpen={setShowSlaModal}
                    id={selectedId}
                    defaultSLAValues={selectedSla}
                />
            </Dialog>
        </div>
    );
};
