import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import { PAGESIZE } from '~/constants';
import { displayDate } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    ArticleGroup,
    ArticleGroupListDocument,
    useArticleGroupListQuery,
    useArticleGroupWhereUsedLazyQuery,
    useDeleteArticleGroupMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';

const useStyles = makeStyles()({
    root: {},
});
const ArticleGroups: React.FC = () => {
    const { classes } = useStyles();

    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [showUsageModal, setShowUsageModal] = useState(false);
    const [selectedItem, setItem] = useState<ArticleGroup | null>(null);

    const { data: groupData, loading: groupsLoading } = useArticleGroupListQuery({
        variables: { articleGroupListInput: {} },
    });
    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        useArticleGroupWhereUsedLazyQuery();
    const [deleteArticleGroup, { loading: deleteArticleGroupLoading }] =
        useDeleteArticleGroupMutation({
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: ArticleGroupListDocument,
                    variables: { articleGroupListInput: {} },
                },
            ],
        });

    const handleDelete = () => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${selectedItem?.name}'?`,
            callback: () => {
                deleteArticleGroup({ variables: { input: { id: selectedItem?.id } } });
                setShowUsageModal(false);
            },
        });
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleShowUsage = (content: ArticleGroup) => {
        whereUsed({
            variables: {
                id: content?.id,
            },
        });
        setShowUsageModal(true);
    };

    return (
        <div className={classes.root}>
            <MaterialTable
                title="Article Groups"
                icons={tableIcons}
                isLoading={groupsLoading || deleteArticleGroupLoading || whereUsedLoading}
                data={_.cloneDeep(_.sortBy(groupData?.articleGroups, 'name')) ?? []}
                actions={[
                    {
                        onClick: () => history('/app-config/articlegroups/new'),
                        hidden: !pagePermissions?.ArticleGroup.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Article group',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => history(`/app-config/articlegroups/${id}`),
                        hidden: !pagePermissions?.ArticleGroup.Read,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Article Group',
                    },
                    {
                        onClick: (_e, item) => {
                            setItem(item as ArticleGroup);
                            handleShowUsage(item as ArticleGroup);
                        },
                        icon: () => <FontAwesomeIcon icon={faEye} />,
                        tooltip: 'View References',
                    },
                ]}
                columns={[
                    { title: 'Name', field: 'name', defaultSort: 'asc' },
                    {
                        title: 'Last Updated',
                        field: 'updatedAt',
                        render: ({ updatedAt }) => (
                            <>
                                {displayDate({
                                    isoDateStr: updatedAt,
                                })}
                            </>
                        ),
                    },
                ]}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{ pageSize: rowsPerPage }}
                localization={{ header: { actions: '' } }}
            />
            <Dialog scroll="paper" open={!whereUsedLoading && showUsageModal}>
                <WhereUsedModal
                    onClose={() => setShowUsageModal(false)}
                    onDelete={handleDelete}
                    title={`Usage for ${selectedItem?.name}`}
                    canDelete={pagePermissions?.ArticleGroup.Delete || false}
                    usageData={whereUsedData?.articleGroupWhereUsed?.dependencies || []}
                    permissions={pagePermissions}
                />
            </Dialog>
        </div>
    );
};

export default ArticleGroups;
