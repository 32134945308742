import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    DialogActions,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { Save } from '@mui/icons-material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { displayDateWithAbbrTimeZone } from '~/helpers';
import { AdvocateTaskCommentInput, FetchCurrentUserForAdvocateTaskPageQuery } from '~/schemaTypes';
import { CommentAuthor } from '../types';
import { useStyles } from './styles';
import { ADVOCATE_TASK_COMMENTS_SCHEMA } from './yupSchema';

type CurrentsUser = NonNullable<FetchCurrentUserForAdvocateTaskPageQuery['currentUser']>;

type CommentInput = {
    message: string;
};

interface CommentProps {
    onCommentsUpdated: (
        comments: AdvocateTaskCommentInput[],
        message?: string,
        index?: number,
    ) => void;
    comments: AdvocateTaskCommentInput[];
    message?: string;
    when?: string;
    authorId?: string;
    setIsNewComment: Dispatch<SetStateAction<boolean>>;
    index?: number;
    commentAuthors: CommentAuthor[];
    currentUser: CurrentsUser;
}

const Comment: React.FC<CommentProps> = ({
    onCommentsUpdated,
    comments,
    message,
    when,
    authorId,
    setIsNewComment,
    index,
    commentAuthors,
    currentUser,
}) => {
    const { classes } = useStyles();
    const isNewComment = index === undefined;
    const [isEditMode, setIsEditMode] = useState<boolean>(isNewComment);
    const {
        register,
        handleSubmit,
        reset,

        formState: { errors },
    } = useForm<CommentInput>({
        resolver: yupResolver(ADVOCATE_TASK_COMMENTS_SCHEMA as any),
    });

    const renderCommentAuthor = () => {
        const [commentAuthor] = commentAuthors.filter(author => author.id === authorId);

        return commentAuthor?.name ?? '';
    };

    const onSubmit: SubmitHandler<CommentInput> = ({ message }) => {
        if (!isNewComment) {
            const nextStateComments = [...comments];

            nextStateComments[index].message = message;
            nextStateComments[index].when = new Date().toISOString();
            onCommentsUpdated(nextStateComments, message, index);
            setIsEditMode(false);

            return;
        }

        if (currentUser) {
            onCommentsUpdated([
                {
                    authorId: currentUser?.id,
                    message,
                    when: new Date().toISOString(),
                },
                ...comments,
            ]);
            setIsNewComment(false);
            setIsEditMode(false);
            reset();
        }
    };

    const handleCancel = () => {
        if (isNewComment) {
            setIsNewComment(false);
            return;
        }
        setIsEditMode(false);
        reset();
    };

    return isEditMode ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <TextField
                id="comments"
                label={`${isNewComment ? 'Add' : 'Edit'} a comment...`}
                variant="outlined"
                size="small"
                {...register('message')}
                autoFocus
                fullWidth
                multiline
                minRows={4}
                defaultValue={isNewComment ? '' : message}
                error={!!errors.message}
                helperText={errors.message?.message}
                InputLabelProps={{
                    shrink: isEditMode,
                }}
            />
            <DialogActions>
                <Button onClick={handleCancel} color="secondary" variant="outlined" size="small">
                    Cancel
                </Button>
                <Button
                    startIcon={<Save />}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={handleSubmit(onSubmit)}
                >
                    Save
                </Button>
            </DialogActions>
        </form>
    ) : (
        <Grid item container className={classes.comment} xs={11}>
            <Grid item container alignItems="center">
                <Grid item>
                    <Typography variant="subtitle2">
                        <span className={classes.author}>{renderCommentAuthor()}</span> -{' '}
                        <span className={classes.date}>
                            {displayDateWithAbbrTimeZone({
                                isoDateStr: when || new Date().toISOString(),
                                format: 'MM/DD/yyyy, hh:mm:ss A',
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                            })}
                        </span>
                    </Typography>
                </Grid>
                <Grid item className={classes.edit}>
                    {currentUser?.id === authorId && (
                        <Tooltip title="Edit">
                            <IconButton onClick={() => setIsEditMode(true)} size="large">
                                <FontAwesomeIcon icon={faPenToSquare} size="xs" />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant="body2">{message}</Typography>
            </Grid>
        </Grid>
    );
};

export default Comment;
