import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs, Tab, Grid, Box } from '@mui/material';
import useUserPermissions from '~/hooks/useUserPermissions';
import { VCAffiliateClaimSubmissions } from '~/views/ConfigDashboard/VCAffiliateClaimSubmissions';
import PatientClaims from './PatientClaims/PatientClaims';

export enum TabsId {
    Batch,
    Patients,
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: TabsId;
    value: TabsId;
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props;

    return (
        <Grid role="tabpanel" hidden={value !== index} item xs={12}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </Grid>
    );
};

type StateLocationProps = {
    initTab?: TabsId;
};

const Claims = () => {
    const { state }: { state: StateLocationProps } = useLocation();
    const { pagePermissions } = useUserPermissions();
    const initialTab = state?.initTab ?? TabsId.Patients;
    const [currentTab, setCurrentTab] = useState(initialTab);
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={currentTab}
                        onChange={(event, newValue) => setCurrentTab(newValue)}
                    >
                        {pagePermissions?.VirtualCarePatients.Read && (
                            <Tab label="Patient Claims" value={TabsId.Patients} />
                        )}
                        <Tab label="Batch Claims" value={TabsId.Batch} />
                    </Tabs>
                </Box>
            </Grid>
            <CustomTabPanel value={currentTab} index={TabsId.Batch}>
                <VCAffiliateClaimSubmissions />
            </CustomTabPanel>
            <CustomTabPanel value={currentTab} index={TabsId.Patients}>
                <PatientClaims />
            </CustomTabPanel>
        </Grid>
    );
};

export default Claims;
