import React, { useEffect, useMemo, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import { debounce } from '@mui/material/utils';
import { useGoogleApiKeySettingsQuery } from '~/schemaTypes';

export type MetaData = {
    zipCode?: string;
    message?: string;
    inputAddress: string;
};

const GOOGLE_MAPS_ID = 'googleMaps';
const MORE_DETAILED_ADDRESS_ERROR = 'Please enter a more detailed address';

function loadScript(src: string, position: HTMLElement | null) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', GOOGLE_MAPS_ID);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };
const placeService = { current: null };

const useStyles = makeStyles()(() => ({
    input: {
        '.MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
            maxWidth: 'calc(100% - 35px)',
        },
    },
}));

interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}
interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
    description: string;
    structured_formatting: StructuredFormatting;
    place_id: string;
    types: string[];
}

interface AddressComponents {
    long_name: string;
    types: string[];
}

interface PlaceResult {
    adr_address: string;
    address_components: AddressComponents[];
    formatted_address: string;
}

type AddressAutocompleteProps = {
    setValue: (value: string, meta: MetaData) => void;
    errorMessage?: string;
    disabled?: boolean;
};

const COUNTRY_RESTRICTION = 'us';
const LANGUAGE = 'en';
const SUPPORTED_ADDRESS_TYPES = ['premise', 'street_address'];

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = props => {
    const { setValue: setValueCb, errorMessage, disabled } = props;
    const { data: googleApiKey } = useGoogleApiKeySettingsQuery();
    const [value, setValue] = React.useState<PlaceType | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
    const loaded = useRef(false);
    const { classes } = useStyles();

    useEffect(() => {
        if (
            typeof window !== 'undefined' &&
            !loaded.current &&
            googleApiKey?.settings.googleApiKey
        ) {
            if (!document.querySelector(`#${GOOGLE_MAPS_ID}`)) {
                loadScript(
                    `https://maps.googleapis.com/maps/api/js?key=${googleApiKey.settings.googleApiKey}&libraries=places&language=${LANGUAGE}&region=${COUNTRY_RESTRICTION}`,
                    document.querySelector('head'),
                );
            }
            loaded.current = true;
        }
    }, [googleApiKey?.settings.googleApiKey]);

    const fetchAutocomplete = useMemo(
        () =>
            debounce(
                (
                    request: { input: string },
                    callback: (results?: readonly PlaceType[]) => void,
                ) => {
                    (autocompleteService.current as any).getPlacePredictions(
                        {
                            ...request,
                            types: ['address'],
                            componentRestrictions: { country: [COUNTRY_RESTRICTION] },
                        },
                        callback,
                    );
                },
                400,
            ),
        [],
    );

    const fetchPlaceById = useMemo(
        () => (request: { placeId: string }, callback: (result?: PlaceResult) => void) => {
            const payload = {
                ...request,
                language: LANGUAGE,
                region: COUNTRY_RESTRICTION,
                fields: ['formatted_address', 'address_components'],
            };
            (placeService.current as any).getDetails(payload, callback);
        },
        [],
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (
                window as any
            ).google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetchAutocomplete({ input: inputValue }, (results?: readonly PlaceType[]) => {
            if (active) {
                let newOptions: readonly PlaceType[] = [];
                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [
                        ...newOptions,
                        ...results.filter(res =>
                            res.types.some(t => SUPPORTED_ADDRESS_TYPES.includes(t)),
                        ),
                    ];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetchAutocomplete]);
    useEffect(() => {
        if (!value?.place_id) {
            return undefined;
        }
        let active = true;

        if (!placeService.current && (window as any).google) {
            placeService.current = new (window as any).google.maps.places.PlacesService(
                document.querySelector(`#${GOOGLE_MAPS_ID}`),
            );
        }
        if (!placeService.current) {
            return undefined;
        }
        fetchPlaceById({ placeId: value.place_id }, (result: PlaceResult) => {
            if (active) {
                const zipCodeComponent = result.address_components.find(comp =>
                    comp.types.includes('postal_code'),
                );
                if (zipCodeComponent) {
                    setValueCb(value.description, {
                        inputAddress: value.description,
                        zipCode: zipCodeComponent.long_name,
                        message: '',
                    });
                } else {
                    setValueCb(value.description, {
                        inputAddress: value.description,
                        message: MORE_DETAILED_ADDRESS_ERROR,
                    });
                }
            }
        });
        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <Autocomplete
            getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
            filterOptions={x => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            freeSolo
            disabled={disabled}
            onChange={(event: any, newValue: PlaceType | null) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                if (!newInputValue) {
                    setValueCb('', { message: '', inputAddress: '' });
                }
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Address Auto-complete with Google"
                    fullWidth
                    error={!!errorMessage}
                    helperText={errorMessage}
                    className={classes.input}
                />
            )}
            renderOption={(props, option) => {
                const { ...optionProps } = props;
                return (
                    <li {...optionProps}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                {option.description}
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
};

export default AddressAutocomplete;
