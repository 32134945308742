import {
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import { displayBirthday, isToday } from '~/helpers/dateHelpers';
import {
    AlertSeverity,
    ChatRoomsToInclude,
    FetchChatRoomsForMessageCenterQueryVariables,
    ReferencePages,
    useCareTeamMemberTypesV2ForPatientChatQuery,
    useFetchChatRoomsForMessageCenterLazyQuery,
    useFetchReferenceLinksForMessageCenterPageQuery,
    useFetchUsersForStaffPageQueryQuery,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import history from 'history/browser';
import { getTextFromHTML } from '~/helpers/getTextFromHTML';
import { HEALTH_ADVOCATE_CARE_TEAM_MEMBER_TYPE_ID, ROOT_ORG_ID } from '~/constants';
import { useDashboard } from '../DashboardProvider';
import ChatRoom from './ChatRoom/ChatRoom';
import { getMessageCenterWorkbook } from './MessageCenterExcelWorkbook';

type MessageCenterProps = {
    setLinks?: any;
};

const useStyles = makeStyles()({
    absoluteBar: {
        width: '100%',
        marginLeft: '-5px',
        height: 20,
        top: '-20px',
        position: 'sticky',
        background: '#fff',
        zIndex: 500,
        marginTop: '-20px',
    },
    root: {
        marginBottom: 20,
        paddingTop: 0,
        position: 'relative',
        display: 'grid',
        minHeight: 'calc(100vh-80px)',
        gridTemplateColumns: '420px auto',
        gridTemplateRows: 'auto calc(100vh - 20px - 70px - 40px - 20px) auto',
        gridTemplateAreas: `
            "header header"
            "sidebar main"
            "sidebar none"
        `,
        '& > .header': {
            gridArea: 'header',
            background: '#fff',
            top: 0,
            zIndex: 500,
            position: 'sticky',
            padding: '0px 10px',
            margin: 0,
            width: '100%',
            borderBottom: '1px lightgray solid',
            '& .searchField': {
                margin: 0,
                padding: 0,
            },
        },
        '& > .sidebar': {
            gridArea: 'sidebar',
            borderRight: '1px solid lightgray',
            overflow: 'auto',
        },
        '& > .main': {
            top: 58,
            position: 'sticky',
            gridArea: 'main',
            padding: 0,
        },
    },
});

const MessageCenter: React.FC<MessageCenterProps> = ({ setLinks }) => {
    const { classes } = useStyles();
    const { id } = useParams<{
        id: string;
    }>();
    const [selectedChatRoomId, setSelectedChatRoomId] = useState<string>(
        id !== 'true' && id !== 'false' ? id || '' : '',
    );
    const [chatRoomsToInclude, setChatRoomsToInclude] = useState<ChatRoomsToInclude>(
        ChatRoomsToInclude.UnreadMessages,
    );
    const [careTeamMemberTypeId, setCareTeamMemberTypeId] = useState(
        HEALTH_ADVOCATE_CARE_TEAM_MEMBER_TYPE_ID,
    );
    const limit = 25;
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState(-1);
    const [refreshTicks, setRefreshTicks] = useState(0);
    const dashboard = useDashboard();

    const fetchChatRoomVariables: FetchChatRoomsForMessageCenterQueryVariables = useMemo(
        () => ({
            input: {
                careTeamMemberTypeId: careTeamMemberTypeId !== 'all' ? careTeamMemberTypeId : '',
                chatRoomsToInclude,
                limit,
                skip: limit * page,
                refreshTicks,
                sort,
            },
        }),
        [careTeamMemberTypeId, chatRoomsToInclude, limit, page, refreshTicks, sort],
    );

    const [fetchChatRoomData, { data: chatRoomData, loading: chatRoomDataLoading }] =
        useFetchChatRoomsForMessageCenterLazyQuery({
            variables: fetchChatRoomVariables,
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
            onCompleted: data => {
                setTotal(data?.currentUser?.chatRooms.total || 0);
            },
        });

    const { data: careTeamMemberTypes, loading: jobsLoading } =
        useCareTeamMemberTypesV2ForPatientChatQuery({
            variables: {
                input: {
                    filter: {
                        fields: {
                            isJob: true,
                        },
                    },
                },
            },
        });

    useEffect(() => {
        if (id === 'true') {
            setChatRoomsToInclude(ChatRoomsToInclude.OpenConversations);
        }
        if (id === 'false') {
            setChatRoomsToInclude(ChatRoomsToInclude.UnreadMessages);
        }
        fetchChatRoomData();
    }, [dashboard.orgId, fetchChatRoomData, id]);

    const { data: staffData, loading: staffLoading } = useFetchUsersForStaffPageQueryQuery({
        variables: {
            input: {
                orgId: ROOT_ORG_ID,
            },
        },
    });

    const { data: referenceLinksData, loading: referenceLinksLoading } =
        useFetchReferenceLinksForMessageCenterPageQuery({
            variables: {
                input: {
                    page: ReferencePages.MessageCenter,
                },
            },
        });

    useEffect(() => {
        if (referenceLinksData && setLinks) {
            setLinks(referenceLinksData.getReferenceLinksForPage);
        }
    }, [referenceLinksData, setLinks]);

    // I think this was here for when switching orgs in message center it would not switch
    // chatrooms so a patient in the other orgs message center would appear -Mason
    useEffect(() => {
        if (!chatRoomData?.currentUser?.currentOrg) {
            setSelectedChatRoomId('');
        }
    }, [chatRoomData?.currentUser?.currentOrg]);

    const handleDownloadMessageCenterMessages = () => {
        const downloadErrorMessage = 'Unable to download Message Center spreadsheet.';
        if (chatRoomData && !chatRoomDataLoading && staffData) {
            getMessageCenterWorkbook(chatRoomData, staffData);
        } else if (!chatRoomData) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `${downloadErrorMessage} Chatroom data is not defined.`,
            });
        } else {
            TriggerGlobalAlert({ severity: AlertSeverity.Error, message: downloadErrorMessage });
        }
    };

    const handleChangeChatRoom = (event: SelectChangeEvent) => {
        setCareTeamMemberTypeId(event.target.value);
        setPage(0);
    };

    const handleChangeFilter = (event: SelectChangeEvent) => {
        setChatRoomsToInclude(event.target.value as ChatRoomsToInclude);
        setPage(0);
    };

    const handleChangeSort = (event: SelectChangeEvent) => {
        setSort(Number(event.target.value));
        setPage(0);
    };

    if (chatRoomDataLoading || referenceLinksLoading || jobsLoading || staffLoading) {
        return <Loading />;
    }

    return (
        <>
            <div className={classes.absoluteBar} />
            <Paper className={classes.root} elevation={2}>
                <div className="flex flex-row justify-between items-center w-full header">
                    <div className="flex flex-col gap-2 mb-4">
                        <Typography variant="h5">Message Center</Typography>
                        <div>
                            <Select
                                value={careTeamMemberTypeId}
                                label="Job"
                                variant="outlined"
                                onChange={handleChangeChatRoom}
                                className="min-w-52"
                            >
                                <MenuItem value={HEALTH_ADVOCATE_CARE_TEAM_MEMBER_TYPE_ID}>
                                    Health Advocate
                                </MenuItem>
                                {careTeamMemberTypes?.careTeamMemberTypesV2.results
                                    .filter(
                                        job => job.id !== HEALTH_ADVOCATE_CARE_TEAM_MEMBER_TYPE_ID,
                                    )
                                    .map(job => (
                                        <MenuItem key={job.id} value={job.id}>
                                            {job.name}
                                        </MenuItem>
                                    ))}
                                <MenuItem value="all">All</MenuItem>
                            </Select>
                            <Select
                                value={chatRoomsToInclude}
                                label="roomFilter"
                                variant="outlined"
                                onChange={handleChangeFilter}
                                className="min-w-52"
                                style={{ marginLeft: '10px' }}
                            >
                                <MenuItem value={ChatRoomsToInclude.UnreadMessages}>
                                    Unread Message
                                </MenuItem>
                                <MenuItem value={ChatRoomsToInclude.OpenConversations}>
                                    Unresolved
                                </MenuItem>
                                <MenuItem value={ChatRoomsToInclude.All}>All</MenuItem>
                            </Select>
                            <Select
                                value={sort.toString()}
                                label="roomSort"
                                variant="outlined"
                                onChange={handleChangeSort}
                                className="min-w-52"
                                style={{ marginLeft: '10px' }}
                            >
                                <MenuItem value="-1">Most Recent First</MenuItem>
                                <MenuItem value="1">Oldest First</MenuItem>
                            </Select>
                            <div>
                                <Button
                                    onClick={() => setPage(page - 1)}
                                    variant="outlined"
                                    color="primary"
                                    disabled={page === 0}
                                >
                                    Previous
                                </Button>
                                <span style={{ marginLeft: '20px' }}>
                                    {`${page * limit + 1} to ${Math.min(
                                        (page + 1) * limit,
                                        total,
                                    )} of ${total}`}
                                </span>
                                <span style={{ marginLeft: '20px' }}>
                                    <Button
                                        onClick={() => setPage(page + 1)}
                                        variant="outlined"
                                        color="primary"
                                        disabled={total < (page + 1) * limit}
                                    >
                                        Next
                                    </Button>
                                </span>
                                <span style={{ marginLeft: '20px' }}>
                                    <Button
                                        onClick={() => {
                                            setPage(0);
                                            setRefreshTicks(new Date().getTime());
                                        }}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        Refresh
                                    </Button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 items-end">
                        <Button
                            onClick={handleDownloadMessageCenterMessages}
                            variant="contained"
                            color="secondary"
                        >
                            Export Messages
                        </Button>
                    </div>
                </div>

                <div className="sidebar" style={{ overflow: 'auto' }}>
                    <Grid container direction="column" alignItems="stretch">
                        {(chatRoomData?.currentUser?.chatRooms?.results.length ?? 0) === 0 && (
                            <Grid
                                container
                                spacing={1}
                                style={{ marginTop: 150, padding: 40, textAlign: 'center' }}
                            >
                                <Grid item>
                                    <Typography variant="h6">
                                        No messages yet for this selection
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        <List disablePadding className="list">
                            {(chatRoomData?.currentUser?.chatRooms.results ?? []).map(
                                ({
                                    id,
                                    patient,
                                    careTeamMemberType,
                                    latestMessage,
                                    unreadMessages,
                                    openConversationId,
                                }) => {
                                    const { createdAt, text, attachments } = latestMessage ?? {};
                                    return (
                                        <React.Fragment key={id}>
                                            <ListItem
                                                className="listItem"
                                                style={{
                                                    maxWidth: '100%',
                                                    cursor: 'pointer',
                                                    border:
                                                        selectedChatRoomId === id
                                                            ? '3px solid black'
                                                            : '',
                                                    // eslint-disable-next-line no-nested-ternary
                                                    backgroundColor: unreadMessages
                                                        ? 'lightyellow'
                                                        : openConversationId !== null
                                                        ? 'lightcyan'
                                                        : '',
                                                }}
                                                onClick={() => {
                                                    history.replace(`/portal/message-center/${id}`);
                                                    setSelectedChatRoomId(id);
                                                }}
                                            >
                                                <ListItemText
                                                    primaryTypographyProps={{
                                                        noWrap: true,
                                                        color: 'primary',
                                                    }}
                                                    primary={
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                flexWrap: 'nowrap',
                                                            }}
                                                        >
                                                            <Grid
                                                                item
                                                                container
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                }}
                                                            >
                                                                <Typography variant="subtitle1">
                                                                    <strong>
                                                                        {`${patient.firstName} ${patient.lastName}`
                                                                            .length > 40
                                                                            ? `${`${patient.firstName} ${patient.lastName}`.slice(
                                                                                  0,
                                                                                  39,
                                                                              )}...`
                                                                            : `${patient.firstName} ${patient.lastName}`}
                                                                    </strong>
                                                                </Typography>
                                                                <Typography variant="subtitle1">
                                                                    {`${patient?.practice?.name} - ${careTeamMemberType.name}`
                                                                        ?.length > 40
                                                                        ? `${`${patient?.practice?.name} - ${careTeamMemberType.name}`
                                                                              .slice(0, 39)
                                                                              .trim()}...`
                                                                        : `${patient?.practice?.name} - ${careTeamMemberType.name}`}
                                                                </Typography>
                                                                <div
                                                                    style={{
                                                                        color: 'gray',
                                                                        fontSize: '.8em',
                                                                    }}
                                                                >
                                                                    DOB:{' '}
                                                                    {displayBirthday({
                                                                        isoDateStr:
                                                                            patient.birthDate,
                                                                    })}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    secondary={
                                                        <Grid
                                                            container
                                                            justifyContent="space-between"
                                                        >
                                                            <Typography
                                                                variant="subtitle1"
                                                                style={{ color: 'black' }}
                                                            >
                                                                {createdAt && (
                                                                    <Typography variant="subtitle1">
                                                                        <div>
                                                                            {isToday(
                                                                                new Date(
                                                                                    createdAt ?? '',
                                                                                ),
                                                                            )
                                                                                ? format(
                                                                                      new Date(
                                                                                          createdAt ??
                                                                                              '',
                                                                                      ),
                                                                                      'h:mm aaa',
                                                                                  )
                                                                                : format(
                                                                                      new Date(
                                                                                          createdAt ??
                                                                                              '',
                                                                                      ),
                                                                                      'MMM d',
                                                                                  )}
                                                                            :
                                                                            {text &&
                                                                                (getTextFromHTML(
                                                                                    text,
                                                                                ).length > 40
                                                                                    ? `${getTextFromHTML(
                                                                                          text,
                                                                                      )
                                                                                          .slice(
                                                                                              0,
                                                                                              40,
                                                                                          )
                                                                                          .trim()}...`
                                                                                    : getTextFromHTML(
                                                                                          text,
                                                                                      ))}
                                                                            {attachments &&
                                                                                attachments.length >
                                                                                    0 &&
                                                                                `${attachments?.length} Attachments Sent`}
                                                                        </div>
                                                                    </Typography>
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider className="divider" />
                                        </React.Fragment>
                                    );
                                },
                            )}
                        </List>
                    </Grid>
                </div>
                {selectedChatRoomId && (
                    <ChatRoom chatRoomId={selectedChatRoomId} setRefreshTicks={setRefreshTicks} />
                )}
            </Paper>
        </>
    );
};

export default MessageCenter;
