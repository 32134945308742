import {
    Button,
    Fade,
    Grid,
    Link,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { sub } from 'date-fns';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import {
    AlertSeverity,
    FetchOrgByInviteCodeForPatientInvitePageQuery,
    useCreateAppUserForPatientMutation,
    useResendPatientInviteIfExistsForSelfSignUpPageMutation,
    useUpdatePatientProfileVariableMutation,
} from '~/schemaTypes';
import { SuppressNextGlobalAlert, TriggerGlobalAlert } from '~/state';

import { useProfileVariableInputs } from '~/views/Dashboard/Patients/VBCPatientModal/useProfileVariableInputs';
import { VBCInviteInputs } from '~/views/PatientInvite/VBCInviteInputs';

const Steps = {
    practiceCode: 0,
    patientEmail: 1,
    patientInfo: 2,
    confirm: 3,
};

const useStyles = makeStyles()(theme => ({
    root: {
        padding: 20,
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 600,
        '& .content': {
            padding: 10,
            [theme.breakpoints.up('md')]: {
                padding: 40,
            },
        },
    },
    orgLogo: {
        width: 250,
    },
}));

type InviteStepsProps = {
    orgData: FetchOrgByInviteCodeForPatientInvitePageQuery['orgByInviteCode'];
};

const VBCInviteSteps: React.FC<InviteStepsProps> = ({ orgData }) => {
    const { classes } = useStyles();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [practiceCode, setPracticeCode] = useState<string>(
        searchParams.get('practiceCode') ?? '',
    );
    const [activeStep, setActiveStep] = useState<number>(
        practiceCode ? Steps.patientEmail : Steps.practiceCode,
    );
    const [patientEmail, setPatientEmail] = useState<string>('');
    const [patientFirstName, setPatientFirstName] = useState<string>('');
    const [patientLastName, setPatientLastName] = useState<string>('');
    const [patientDOB, setPatientDOB] = useState<Date | null>(sub(new Date(), { years: 26 }));

    const [profileVariableData, setProfileVariableData] = useState<{ [key: string]: any }>({});

    const onChangeHandler = (value: { [key: string]: any }) => {
        setProfileVariableData({
            ...profileVariableData,
            ...value,
        });
    };

    const { profileDefinitionsData, profileVariableInputs } = useProfileVariableInputs({
        bundleId: orgData?.appBundleId || '',
        VBCInviteStepsInputs: VBCInviteInputs,
        profileVariableData,
        onChangeHandler,
    });

    const [resendPatientInviteIfExists, { loading: resendInviteLoading }] =
        useResendPatientInviteIfExistsForSelfSignUpPageMutation({
            onCompleted: data => {
                if (data.resendPatientInvitationByEmailIfExists?.success) {
                    setActiveStep(3);
                } else {
                    setActiveStep(2);
                }
            },
        });

    const [updatePatientProfileVariable] = useUpdatePatientProfileVariableMutation();
    const getCreateOrUpdateProfileVariablesInput = (
        patientId: string,
        profileVariableData: { [key: string]: any },
    ) => {
        return profileDefinitionsData.map(item => ({
            patientId,
            profileVariableDefId: item.id,
            value: {
                [item.valueType]: profileVariableData[item.name],
            },
        }));
    };

    const [createPatient, { loading: createPatientLoading }] = useCreateAppUserForPatientMutation({
        onCompleted: data => {
            if (
                data.createAppUserForPatient?.appUserId &&
                data.createAppUserForPatient?.patientId
            ) {
                setActiveStep(3);
                const profileVariables = getCreateOrUpdateProfileVariablesInput(
                    data.createAppUserForPatient?.patientId,
                    profileVariableData,
                );
                for (const pv of profileVariables) {
                    updatePatientProfileVariable({
                        variables: {
                            input: {
                                portalPatientId: pv.patientId,
                                profileVariableDefId: pv.profileVariableDefId,
                                value: pv.value,
                            },
                        },
                    });
                }
            } else {
                setActiveStep(2);
            }
        },
        onError: error => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `${error}`,
            });
        },
    });

    const handleEmailCheck = () => {
        SuppressNextGlobalAlert(true);
        resendPatientInviteIfExists({
            variables: {
                input: {
                    email: patientEmail,
                    appBundleId: orgData?.appBundleId || '',
                },
            },
        });
    };

    const handleCreatePatient = () => {
        if (orgData?.appBundleId && patientDOB) {
            createPatient({
                variables: {
                    input: {
                        email: patientEmail,
                        firstName: patientFirstName,
                        lastName: patientLastName,
                        birthDate: patientDOB.toISOString(),
                        appBundleId: orgData.appBundleId,
                    },
                },
            });
        }
    };

    return (
        <Grid container spacing={2} direction="column" alignItems="center">
            {practiceCode && activeStep > 0 ? (
                <>
                    <Grid item xs={12}>
                        <Fade in timeout={300}>
                            <img
                                className={classes.orgLogo}
                                src={orgData?.logo ?? ''}
                                alt={`${orgData?.brandingName} Logo`}
                            />
                        </Fade>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">{orgData?.brandingName}</Typography>
                    </Grid>
                </>
            ) : (
                <Grid item xs={12}>
                    <Typography variant="h5">Welcome to Patient Registration</Typography>
                </Grid>
            )}
            <Grid item xs={12} style={{ width: '100%' }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    <Step completed={activeStep > 0}>
                        <StepLabel>Enter Code</StepLabel>
                    </Step>
                    <Step completed={activeStep > 1}>
                        <StepLabel>Verify Email</StepLabel>
                    </Step>
                    <Step completed={activeStep > 2}>
                        <StepLabel>Enter Info</StepLabel>
                    </Step>
                </Stepper>
            </Grid>
            <Grid className="content" item xs={12}>
                {activeStep === 0 && (
                    <Fade in timeout={500}>
                        <Grid container spacing={3} direction="column" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="body1" style={{ textAlign: 'center' }}>
                                    Your practice has invited you to set up an account. Please enter
                                    your code below to get started.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} style={{ width: '100%' }}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Practice Code"
                                    value={practiceCode}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setPracticeCode(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setActiveStep(1)}
                                    disabled={!orgData?.id}
                                >
                                    Continue
                                </Button>
                            </Grid>
                        </Grid>
                    </Fade>
                )}
                {activeStep === 1 && (
                    <Fade in timeout={500}>
                        <Grid container spacing={3} direction="column" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="body1" style={{ textAlign: 'center' }}>
                                    Please enter your email address below to set up your app
                                    account. This app is provided to you by {orgData?.brandingName}
                                    and is full of health tips and articles, trackers and more!
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} style={{ width: '100%' }}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Email"
                                    value={patientEmail}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setPatientEmail(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AsyncActionButton loading={resendInviteLoading}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleEmailCheck}
                                        disabled={!patientEmail}
                                    >
                                        Continue
                                    </Button>
                                </AsyncActionButton>
                            </Grid>
                        </Grid>
                    </Fade>
                )}
                {activeStep === 2 && (
                    <Fade in timeout={500}>
                        <Grid container spacing={3} direction="column" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="body1" style={{ textAlign: 'center' }}>
                                    Please enter some additional information below to continue
                                    registering for the app.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" style={{ textAlign: 'center' }}>
                                    We will use this information to set up your account.
                                </Typography>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} style={{ width: '100%' }}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Email"
                                        value={patientEmail}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setPatientEmail(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ width: '100%' }}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="First Name"
                                        value={patientFirstName}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setPatientFirstName(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ width: '100%' }}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Last Name"
                                        value={patientLastName}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setPatientLastName(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ width: '100%' }}>
                                    <DesktopDatePicker
                                        label="Date of Birth"
                                        value={patientDOB}
                                        onChange={(date: Date) => {
                                            if (date) {
                                                setPatientDOB(date);
                                            }
                                        }}
                                        maxDate={new Date()}
                                        disableFuture
                                        openTo="year"
                                        format="MM/dd/yyyy"
                                    />
                                </Grid>
                            </Grid>
                            {profileVariableInputs}
                            <Grid item xs={12}>
                                <AsyncActionButton loading={createPatientLoading}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleCreatePatient}
                                        disabled={
                                            !patientDOB || !patientFirstName || !patientLastName
                                        }
                                    >
                                        Submit
                                    </Button>
                                </AsyncActionButton>
                            </Grid>
                        </Grid>
                    </Fade>
                )}
                {activeStep === 3 && (
                    <Fade in timeout={500}>
                        <Grid container spacing={3} direction="column" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ textAlign: 'center' }}>
                                    Thank you for confirming your information!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" style={{ textAlign: 'center' }}>
                                    We&apos;ve just sent you an invite with further instructions on
                                    how to download your app from {orgData?.brandingName} and
                                    complete registration.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" style={{ textAlign: 'center' }}>
                                    The invite will contain a unique code that will help you
                                    register for the app. If you have any questions, please reach
                                    out to{' '}
                                    <Link
                                        rel="noopener noreferrer"
                                        href="mailto:support@wildflowerhealth.com"
                                        underline="hover"
                                    >
                                        support@wildflowerhealth.com
                                    </Link>
                                    .
                                </Typography>
                            </Grid>
                        </Grid>
                    </Fade>
                )}
            </Grid>
        </Grid>
    );
};

export default VBCInviteSteps;
