import { Button, Card, Divider, Grid, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import {
    BioScreensForProfileScreensPageDocument,
    BioScreensForProfileScreensPageQuery,
    BioScreenShortcutIcon,
    BioScreenShortcutType,
    FetchBioScreenForBioScreenBuilderDocument,
    useCreateBioScreenForBioScreenBuilderMutation,
    useFetchBioScreenForBioScreenBuilderLazyQuery,
    useFetchTagsForBioScreensBuilderQuery,
    useUpdateBioScreenForBioScreenBuilderMutation,
} from '~/schemaTypes';
import { toUpdateBioScreen } from '~/views/ConfigDashboard/BioScreen/BioScreenBuilder/mappers/toUpdateBioScreen';
import BioScreenBuilderForm from './BioScreenBuilderForm';
import { toBioScreenFormInput } from './mappers/toBioScreenFormInput';
import useStyles from './styles';
import { BioScreenBuilderFormInput } from './types/BioScreenBuilderFormInput';

const DEFAULT_LOCALIZED_STRING = {
    en: '',
    es: '',
};

export const DEFAULT_FORM_VALUES = {
    label: DEFAULT_LOCALIZED_STRING,
    yourHealthInfo: {
        label: DEFAULT_LOCALIZED_STRING,
        icon: BioScreenShortcutIcon.Flower,
        instructions: DEFAULT_LOCALIZED_STRING,
        type: BioScreenShortcutType.YourHealthInfo,
    },
    surveyGroups: [],
    shortcuts: [],
};

const BioScreenBuilder: React.FC = () => {
    const { classes } = useStyles();
    const { id: bioScreenId } = useParams<{ id: string }>();
    const isEditMode = bioScreenId !== 'new';
    const history = useNavigate();
    const [close, setClose] = useState(false);

    const { data: portalTagsQueryData, loading: portalTagsLoading } =
        useFetchTagsForBioScreensBuilderQuery();

    const portalTags = portalTagsQueryData?.tagsV2.results;

    const [fetchBioScreenById, { data: bioScreenData, loading: bioScreenLoading }] =
        useFetchBioScreenForBioScreenBuilderLazyQuery();

    const [createBioScreen, { loading: createBioScreenLoading }] =
        useCreateBioScreenForBioScreenBuilderMutation({
            onCompleted: data => {
                if (close) history('/app-config/bio-screens/');
                else
                    history(`/app-config/bio-screens/${data.createBioScreen?.resourceCreated?.id}`);
            },
            update: (cache, response) => {
                const createdBioScreen = response.data?.createBioScreen?.resourceCreated;
                if (response.data?.createBioScreen?.success && createdBioScreen) {
                    const currentBioScreens = cache.readQuery<BioScreensForProfileScreensPageQuery>(
                        {
                            query: BioScreensForProfileScreensPageDocument,
                        },
                    );
                    if (currentBioScreens?.bioScreensV2) {
                        cache.writeQuery<BioScreensForProfileScreensPageQuery>({
                            query: BioScreensForProfileScreensPageDocument,
                            data: {
                                bioScreensV2: {
                                    __typename: currentBioScreens.bioScreensV2.__typename,
                                    results: [
                                        ...currentBioScreens.bioScreensV2.results,
                                        createdBioScreen,
                                    ],
                                },
                            },
                        });
                    }
                }
            },
        });

    const [updateBioScreen, { loading: updateBioScreenLoading }] =
        useUpdateBioScreenForBioScreenBuilderMutation({
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: FetchBioScreenForBioScreenBuilderDocument,
                    variables: { bioScreenInput: { id: isEditMode ? bioScreenId : '' } },
                },
            ],
            onCompleted: () => {
                if (close) history('/app-config/bio-screens/');
            },
            update: (cache, response) => {
                const updatedBioScreen = response.data?.updateBioScreen?.resourceUpdated;
                if (response.data?.updateBioScreen?.success && updatedBioScreen) {
                    const currentBioScreens = cache.readQuery<BioScreensForProfileScreensPageQuery>(
                        {
                            query: BioScreensForProfileScreensPageDocument,
                        },
                    );
                    if (currentBioScreens?.bioScreensV2) {
                        cache.writeQuery<BioScreensForProfileScreensPageQuery>({
                            query: BioScreensForProfileScreensPageDocument,
                            data: {
                                bioScreensV2: {
                                    __typename: currentBioScreens.bioScreensV2.__typename,
                                    results: [
                                        ...currentBioScreens.bioScreensV2.results.map(bioScreen => {
                                            if (bioScreen.id === updatedBioScreen.id) {
                                                return updatedBioScreen;
                                            }
                                            return bioScreen;
                                        }),
                                    ],
                                },
                            },
                        });
                    }
                }
            },
        });

    useEffect(() => {
        if (isEditMode) {
            fetchBioScreenById({ variables: { bioScreenInput: { id: bioScreenId } } });
        }
    }, [fetchBioScreenById, bioScreenId, isEditMode]);

    const onSubmit = (formData: BioScreenBuilderFormInput) => {
        const updatedBioScreen = toUpdateBioScreen(formData);
        if (isEditMode && bioScreenId) {
            updateBioScreen({
                variables: {
                    bioScreenUpdateInput: {
                        id: bioScreenId,
                        data: updatedBioScreen,
                    },
                },
            });
        } else {
            createBioScreen({
                variables: {
                    createBioScreenInput: {
                        ...updatedBioScreen,
                    },
                },
            });
        }
    };
    const renderForm = () => {
        const bioScreen =
            bioScreenData?.bioScreen && toBioScreenFormInput(bioScreenData?.bioScreen);
        const formInitValues = isEditMode ? bioScreen : DEFAULT_FORM_VALUES;

        if (!portalTags || !formInitValues) {
            return null;
        }

        return (
            <BioScreenBuilderForm
                isEditMode={isEditMode}
                defaultValues={formInitValues}
                classes={classes}
                portalTags={portalTags}
                onSubmit={onSubmit}
                handleClose={setClose}
            />
        );
    };

    if (portalTagsLoading || bioScreenLoading || updateBioScreenLoading || createBioScreenLoading) {
        return <Loading />;
    }

    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Button component={Link} to="/app-config/bio-screens" startIcon={<ArrowBack />}>
                    Back to Profile Screens
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <Typography variant="h6" paragraph>
                        {isEditMode ? 'Edit' : 'Add'} Profile Screen
                    </Typography>
                    <Divider style={{ marginBottom: 20 }} />
                    {portalTags && renderForm()}
                </Card>
            </Grid>
        </Grid>
    );
};

export default BioScreenBuilder;
