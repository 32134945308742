import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import moment from 'moment';
import {
    Autocomplete,
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import {
    Maybe,
    useProfileDefsListQuery,
    UserProfileDefChoice,
    UserProfileValueType,
} from '~/schemaTypes';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Loading from '~/components/Loading/Loading';
import FormBuilder, {
    FormBuilderFormField,
    FormBuilderRef,
} from '~/components/FormBuilder/FormBuilder';
import _ from 'lodash';
import * as Yup from 'yup';
import { SelectedPV } from './types';

const PV_VALUE_SCHEMA = Yup.object().shape({
    name: Yup.string().required('PV name is required'),
    valueType: Yup.string().required('PV type is required'),
    value: Yup.mixed(),
    setClearValue: Yup.boolean(),
});

export const SetDisplayName = (
    valueType: UserProfileValueType,
    rawValue: any,
): { value: any; displayValue: string } => {
    let value;
    let displayValue;
    if (valueType === UserProfileValueType.Date) {
        displayValue = moment(rawValue).startOf('day').toISOString();
        value = moment(rawValue).startOf('day').toISOString();
    } else if (valueType === UserProfileValueType.Bool) {
        displayValue = rawValue ? 'True' : 'False';
        value = !!rawValue;
    } else {
        displayValue = Array.isArray(rawValue) ? rawValue.join(', ') : rawValue;
        value = rawValue;
    }
    return { value, displayValue };
};

type AddPvModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    addPVHandler: (PV: SelectedPV) => void;
};

type PVDefForSelect = {
    name: string;
    valueType: UserProfileValueType;
    choices: Maybe<UserProfileDefChoice>[];
    id: string;
};

type FormInput = {
    name: string;
    value: any;
    valueType: string;
    setClearValue: boolean;
};

const AddPVModal: React.FC<AddPvModalProps> = props => {
    const { setOpen, addPVHandler } = props;
    const { data: PVDefs, loading: PVDefsLoading } = useProfileDefsListQuery();
    const [selectedPv, setSelectedPv] = useState<PVDefForSelect | null>(null);
    const [shouldSetClearValue, setShouldSetClearValue] = useState(false);
    const PVDefsForSelect: PVDefForSelect[] = useMemo(
        () =>
            _.sortBy(PVDefs?.userProfileDefs, 'name').map(PVDef => ({
                name: PVDef.name,
                valueType: PVDef.valueType,
                choices: PVDef.choices || [],
                id: PVDef.id,
            })) || [],
        [PVDefs],
    );
    const formRef = useRef<FormBuilderRef>(null);
    const {
        control,
        handleSubmit: handleFormSubmit,
        setValue,
        formState: { errors },
        reset,
        resetField,
        watch,
    } = useForm<FormInput>({
        // @ts-expect-error RHF V7 limitation #7895 will be fixed in V8 of react-hook-form
        resolver: yupResolver(PV_VALUE_SCHEMA as any),
        defaultValues: { value: '' },
    });
    useEffect(() => {
        if (selectedPv) {
            setValue('valueType', selectedPv.valueType);
            resetField('value', { keepDirty: true });
            if (selectedPv.valueType === UserProfileValueType.Date) {
                setValue('value', new Date());
            }
        } else {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPv]);
    const currentValue = watch('value');
    const handleCancel = useCallback(() => {
        setOpen(false);
        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onSubmit = useCallback(
        (data: FormInput) => {
            if (selectedPv) {
                const { value: rawValue, setClearValue } = data;
                const vals = SetDisplayName(selectedPv?.valueType, rawValue);
                addPVHandler({
                    id: selectedPv.id,
                    name: selectedPv.name,
                    type: selectedPv.valueType,
                    displayValue: setClearValue ? '' : vals.displayValue,
                    value: setClearValue ? undefined : vals.value,
                });
            }
            setOpen(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedPv],
    );
    const valField: FormBuilderFormField = useMemo(() => {
        const defaultData = {
            label: 'Value',
            name: 'value',
        };
        switch (selectedPv?.valueType) {
            case UserProfileValueType.Num:
                return { ...defaultData, type: 'number' };
            case UserProfileValueType.Str:
                return { ...defaultData, type: 'string' };
            case UserProfileValueType.Date:
                return {
                    ...defaultData,
                    name: 'dateValue',
                    type: 'date',
                    date: currentValue,
                    onChangeHandler: value => {
                        setValue('value', value);
                    },
                };
            case UserProfileValueType.Bool:
                return { ...defaultData, type: 'boolean', label: 'True' };
            case UserProfileValueType.Choice:
            case UserProfileValueType.Choices:
                return {
                    ...defaultData,
                    type: 'selector',
                    values: selectedPv.choices.map(choice => ({
                        label: `${choice?.label?.en} (${choice?.id})` || '',
                        value: choice?.id,
                    })),
                    array: UserProfileValueType.Choices === selectedPv.valueType,
                };
            default:
                return { ...defaultData, type: 'none' };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPv, currentValue]);
    if (PVDefsLoading) {
        return <Loading />;
    }
    return (
        <>
            <DialogContent>
                <form noValidate onSubmit={e => e.preventDefault()}>
                    <Grid container flexDirection="column">
                        <Grid item xs={8}>
                            <Controller
                                control={control}
                                name="name"
                                render={({ field: { onChange } }) => (
                                    <Autocomplete
                                        renderInput={params => (
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...params}
                                                placeholder="Search for Pv"
                                                error={!!errors.name}
                                                label="Search for Pv"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                        fullWidth
                                        options={PVDefsForSelect}
                                        isOptionEqualToValue={(option, val) =>
                                            option?.id === val.id
                                        }
                                        getOptionLabel={selected => selected.name}
                                        onChange={(_, option) => {
                                            onChange(option?.name);
                                            setSelectedPv(option);
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth error={!!errors.valueType}>
                                <InputLabel size="small" id="ValueType">
                                    Value Type
                                </InputLabel>
                                <Select
                                    value={selectedPv?.valueType || ''}
                                    fullWidth
                                    error={!!errors.valueType}
                                    label="Value Type"
                                    labelId="ValueType"
                                    disabled
                                >
                                    <MenuItem key="Str" value={UserProfileValueType.Str}>
                                        Str
                                    </MenuItem>
                                    <MenuItem key="Strs" value={UserProfileValueType.Strs}>
                                        Strs
                                    </MenuItem>
                                    <MenuItem key="Bool" value={UserProfileValueType.Bool}>
                                        Bool
                                    </MenuItem>
                                    <MenuItem key="Bools" value={UserProfileValueType.Bools}>
                                        Bools
                                    </MenuItem>
                                    <MenuItem key="Date" value={UserProfileValueType.Date}>
                                        Date
                                    </MenuItem>
                                    <MenuItem key="Dates" value={UserProfileValueType.Dates}>
                                        Dates
                                    </MenuItem>
                                    <MenuItem key="Num" value={UserProfileValueType.Num}>
                                        Num
                                    </MenuItem>
                                    <MenuItem key="Nums" value={UserProfileValueType.Nums}>
                                        Nums
                                    </MenuItem>
                                    <MenuItem key="Choice" value={UserProfileValueType.Choice}>
                                        Choice
                                    </MenuItem>
                                    <MenuItem key="Choices" value={UserProfileValueType.Choices}>
                                        Choices
                                    </MenuItem>
                                </Select>
                                <FormHelperText>{errors.valueType?.message}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container flexDirection="row" xs={12}>
                            {!shouldSetClearValue && (
                                <FormBuilder
                                    key={selectedPv?.id}
                                    ref={formRef}
                                    fields={[valField]}
                                    handleSubmit={() => false}
                                />
                            )}
                            {selectedPv && (
                                <Controller
                                    name="setClearValue"
                                    control={control}
                                    render={({ field: { onChange } }) => (
                                        <FormControlLabel
                                            label="Set Clear Value"
                                            control={
                                                <Checkbox
                                                    id="setClearValue"
                                                    checked={shouldSetClearValue}
                                                    onChange={e => {
                                                        onChange(e.target.checked);
                                                        setShouldSetClearValue(e.target.checked);
                                                    }}
                                                />
                                            }
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    startIcon={<SaveIcon />}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                        const PVValue = formRef.current?.getValues();
                        if (Array.isArray(PVValue?.value)) {
                            setValue(
                                'value',
                                PVValue.value.map((v: any) => v.value),
                            );
                        } else if (selectedPv?.valueType !== UserProfileValueType.Date) {
                            setValue('value', PVValue?.value);
                        }
                        handleFormSubmit(onSubmit)();
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
};

export default AddPVModal;
