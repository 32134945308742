import { Share, Close } from '@mui/icons-material';
import { Popover, Typography, IconButton, Grid } from '@mui/material';
import React from 'react';
import { displayDateWithLocalTimeZoneAbbr } from '~/helpers';
import { GetSurveyResponsesForPatientTranscriptQuery } from '~/schemaTypes';

type SurveyResponse = NonNullable<
    GetSurveyResponsesForPatientTranscriptQuery['getSurveyResponsesForPatientTranscript']
>[0];

export const SurveyResponseCard = ({ surveyResponse }: { surveyResponse: SurveyResponse }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <div className="flex flex-row justify-between">
                <Typography variant="h6">Survey</Typography>
                <IconButton
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onClick={handlePopoverOpen}
                >
                    <Share />
                </IconButton>
                <Popover
                    id="mouse-over-popover"
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Grid container direction="row" justifyContent="space-between">
                        <Typography className="p-2" variant="h6">
                            Survey Responses
                        </Typography>
                        <IconButton onClick={handlePopoverClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                    <table className="border-collapse m-2">
                        <tr className="">
                            <th className="border-solid border-slate-500 border-[1px] p-2">
                                Title
                            </th>
                            <th className="border-solid border-slate-500 border-[1px] p-2">
                                Question
                            </th>
                            <th className="border-solid border-slate-500 border-[1px] p-2">
                                Response
                            </th>
                        </tr>
                        {surveyResponse?.answers.map(answer => (
                            <tr key={answer.answerId}>
                                <td className="border-solid border-slate-500 border-[1px] p-2">
                                    {answer.subsectionName}
                                </td>
                                <td className="border-solid border-slate-500 border-[1px] p-2">
                                    {answer.questionText}
                                </td>
                                <td className="border-solid border-slate-500 border-[1px] p-2">
                                    {answer.answerText || JSON.stringify(answer.answerTexts)}
                                </td>
                            </tr>
                        ))}
                    </table>
                </Popover>
            </div>
            <p className="m-0">Name: {surveyResponse?.displayName}</p>
            <p className="m-0">
                Response Date:{' '}
                {displayDateWithLocalTimeZoneAbbr({
                    isoDateStr: new Date(surveyResponse?.responseDate).toISOString(),
                })}
            </p>
            <p className="m-0">Type: {surveyResponse?.surveyType}</p>
            <p className="m-0">Score: {surveyResponse?.surveyScore}</p>
        </div>
    );
};
