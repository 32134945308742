import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    commentsSummary: {
        fontWeight: 'bold',
    },
    commentsLabel: {
        '& label': {
            fontSize: 15,
        },
    },
});
