import { Grid, Typography } from '@mui/material';
import React from 'react';
import { displayDate, displayDateWithAbbrTimeZone } from '~/helpers';
import { AdvocateTaskHistoryItemInput as HistoryItem } from '~/schemaTypes';
import { getTextFromHTML } from '~/helpers/getTextFromHTML';
import { useStyles } from './styles';
import { HistoryFigure } from './types';

interface HistoryProps {
    history: HistoryItem[];
    historyFigures: HistoryFigure[];
}

const History: React.FC<HistoryProps> = ({ history, historyFigures }) => {
    const { classes } = useStyles();

    const renderWho = (whoId: string) => {
        const who = historyFigures.find(historyFigure => historyFigure.id === whoId);

        return who?.name;
    };
    const renderWhen = (when: string) =>
        `${displayDateWithAbbrTimeZone({
            isoDateStr: when,
            format: 'MM/DD/yyyy, hh:mm:ss A',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })}`;
    const renderWhenDate = (when: string) =>
        `${displayDate({
            isoDateStr: when,
            format: 'MM/DD/yyyy',
        })}`;

    return (
        <Grid item container>
            <Grid item className={classes.historyWrapper} xs={12}>
                <Typography className={classes.historySummary} variant="subtitle1">
                    History
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {history.map(({ whoId, what, when, id }) => (
                    <Grid item container className={classes.historyItem} xs={11} key={id}>
                        <Grid item container alignItems="center">
                            <Grid item>
                                <Typography variant="body2">
                                    <span>{renderWho(whoId)} </span>
                                    <span className={classes.whatAndWhen}>
                                        {getTextFromHTML(what)}{' '}
                                    </span>
                                    {/* This is to display the task display date on the proper day which is stored in the db 
                                    midnight of the day it should be displayed but in UTC timezone so when being displayed
                                    in PST it appears the day before */}
                                    {what === 'Task Displayed' ? (
                                        <span className={classes.whatAndWhen}>
                                            on {renderWhenDate(when)}
                                        </span>
                                    ) : (
                                        <span className={classes.whatAndWhen}>
                                            on {renderWhen(when)}
                                        </span>
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export default History;
