import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    dialogContent: {
        minHeight: '300px',
        maxHeight: '300px',
        overflowY: 'scroll',
        padding: '20px 0',
        border: '1px solid grey',
    },
    statusTextColor: {
        '&.error': {
            color: 'red',
        },
        '&.submitted': {
            color: 'green',
        },
    },
}));
