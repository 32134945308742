import { atomWithStorage } from 'jotai/utils';
import { GridColumns } from './types';

export const PatientsHiddenColumnsAtom = atomWithStorage('patientHiddenColumns', {
    [GridColumns.invitationCode]: false,
    [GridColumns.externalId]: false,
    [GridColumns.firstName]: false,
    [GridColumns.lastName]: false,
    [GridColumns.riskScore]: false,
    [GridColumns.createdAt]: false,
    [GridColumns.assignedHealthAdvocate]: false,
    [GridColumns.openAdvocateTaskCount]: false,
    [GridColumns.openCriticalAdvocateTaskCount]: false,
    [GridColumns.appRegistrationCompleted]: false,
    [GridColumns.patientCallStatus]: false,
    [GridColumns.birthDate]: false,
    [GridColumns.organization]: false,
    [GridColumns.clinic]: false,
    [GridColumns.onboardingCompleteDate]: false,
    [GridColumns.isPregnant]: false,
    [GridColumns.dueDate]: false,
    [GridColumns.deliveryDate]: false,
    [GridColumns.email]: false,
    [GridColumns.phoneNumber]: false,
    [GridColumns.id]: false,
    [GridColumns.affiliates]: false,
    [GridColumns.coverageExpiration]: false,
    [GridColumns.language]: false,
});
