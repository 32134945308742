import { Avatar, Button, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { ChevronLeft, GridOn, People, RoomService, Settings } from '@mui/icons-material';
import React, { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useUserPermissions } from '~/hooks';
import { CommonEnum } from '~/selectors';

type LayoutStyleProps = {
    isOpen: boolean;
};

const useStyles = makeStyles<LayoutStyleProps>()((theme, { isOpen }) => ({
    '@global': {
        '@keyframes rotateIconClose': {
            from: { transform: 'rotate(0deg)' },
            to: { transform: 'rotate(180deg)' },
        },
        '@keyframes rotateIconOpen': {
            from: { transform: 'rotate(180deg)' },
            to: { transform: 'rotate(0deg)' },
        },
    },
    root: {
        display: 'grid',
        position: 'relative',
        gridTemplateRows: '100vh',
        gridTemplateColumns: `75px calc(100vw - 10px)`,
        gridTemplateAreas: `
            "sidebar main"
        `,
        overflow: 'hidden',
        transition: 'margin-left .3s ease-in-out .3s',
        marginLeft: isOpen ? '0px' : '-65px',
    },
    sidebar: {
        gridArea: 'sidebar',
        background: theme.colors.Primary(1000),
        position: 'relative',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 15,
    },
    sidebarTrigger: {
        position: 'absolute',
        transition: 'margin-left .3s ease-in-out .3s',
        right: -12,
        top: 20,
        height: 30,
        width: 20,
        borderRadius: 0,
        padding: 0,
        zIndex: 1500,
        background: theme.colors.Primary(900),
        color: 'white',
        '&:hover': {
            background: theme.colors.Primary(1000),
        },
        '& svg, span': {
            width: 20,
        },
        '& svg': {
            ...(isOpen && { animation: '.3s ease-in-out rotateIn' }),
            ...(!isOpen && { animation: '.3s ease-in-out rotateOut' }),
            '@keyframes rotateIn': {
                from: {
                    rotate: '0deg',
                },
                to: {
                    rotate: '-180deg',
                },
            },
            '@keyframes rotateOut': {
                from: {
                    rotate: '-180deg',
                },
                to: {
                    rotate: '0deg',
                },
            },
            animationFillMode: 'forwards',
        },
    },
    linkButton: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& > .button-label': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
            '& > *:not(:last-child)': {
                paddingBottom: '10px,',
            },
        },
    },
    main: {
        gridArea: 'main',
        transition: 'padding-right .3s ease-in-out .3s',
        paddingRight: isOpen ? '65px' : '0px',
        overflowY: 'auto',
    },
}));

type LatoutProps = {
    children: React.ReactNode;
};
const Layout: React.FC<LatoutProps> = ({ children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const theme = useTheme();
    const { classes } = useStyles({ isOpen });
    const { groupPermissions } = useUserPermissions();

    const links = useMemo(
        () =>
            [
                {
                    icon: <People />,
                    title: 'Portal',
                    link: '/',
                    color: theme.colors.Secondary(),
                    hide: !groupPermissions?.Patients,
                },
                {
                    icon: <Settings />,
                    title: 'Config',
                    link: '/app-config',
                    color: theme.colors.Primary(300),
                    hide: !groupPermissions?.Config,
                },
                {
                    icon: <RoomService />,
                    title: 'Front Desk',
                    link: '/frontdesk',
                    color: theme.colors.Primary(300),
                    hide: !groupPermissions?.Support,
                },
                {
                    icon: <GridOn />,
                    title: 'System Admin',
                    link: '/system-admin',
                    color: theme.colors.Primary(300),
                    hide: !groupPermissions?.Support,
                },
            ].filter(({ hide }) => !hide),
        [theme.colors, groupPermissions],
    );
    if (links.length <= 0) {
        return <div>{children}</div>;
    }

    return (
        <div className={classes.root}>
            <div className={classes.sidebar}>
                <IconButton
                    className={classes.sidebarTrigger}
                    disableRipple
                    onClick={() => setIsOpen(c => !c)}
                    data-test={CommonEnum.SIDEBAR_TRIGGER}
                    size="large"
                >
                    <ChevronLeft />
                </IconButton>
                {links.map(({ icon, title, link, color }) => (
                    <Button
                        key={link}
                        className={classes.linkButton}
                        component={NavLink}
                        to={link}
                        data-test={title}
                    >
                        <Avatar variant="square" style={{ background: color, borderRadius: 10 }}>
                            {icon}
                        </Avatar>
                        <Typography variant="body1" className="button-label">
                            {title}
                        </Typography>
                    </Button>
                ))}
            </div>
            <div className={classes.main}>{children}</div>
        </div>
    );
};

export default Layout;
