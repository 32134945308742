import React from 'react';
import { VirtualCareSurvey } from '../VirtualCareSurvey';
import { useInitVCF, VCFContext } from '../hooks/useVCF';

const GeneralVCF = () => {
    const contextProps = useInitVCF();
    return (
        <VCFContext.Provider value={contextProps}>
            <VirtualCareSurvey pathname="/virtual-care" />
        </VCFContext.Provider>
    );
};

export default GeneralVCF;
