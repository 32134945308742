import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from '@mui/material';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import tableIcons from '~/helpers/tableIcons';
import useUserPermissions from '~/hooks/useUserPermissions';
import { HA_CREATED, REPEAT_TYPE } from '../enums';
import { TodosTablePropsInterface } from './interfaces';
import { displayDate } from '../../../../../../helpers';

const ToDoTaskRepeatTypeArray = [
    [REPEAT_TYPE.ONE_TIME, 'One time'],
    [REPEAT_TYPE.DAILY, 'Recurring Daily'],
    [REPEAT_TYPE.WEEKLY, 'Recurring Weekly'],
    [REPEAT_TYPE.MONTHLY, 'Recurring Monthly'],
];

export const UpcomingTodosTable: React.FC<TodosTablePropsInterface> = ({
    data,
    onCreate,
    onEdit,
    isLoading,
}) => {
    const { pagePermissions } = useUserPermissions();

    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);

    const sortedData = _.sortBy(data, 'dueDate');

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div ref={tableRef}>
            <MaterialTable
                title="To Do's"
                icons={tableIcons as MaterialTableProps<any>['icons']}
                actions={[
                    {
                        onClick: () => onCreate(),
                        hidden: !pagePermissions?.PatientTodos.Edit,
                        icon: () => <Add />,
                        tooltip: 'Create To Do',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, todoItem) => {
                            if (!Array.isArray(todoItem)) {
                                onEdit(todoItem.id);
                            }
                        },
                        disabled: !pagePermissions?.PatientTodos.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit',
                    },
                ]}
                columns={[
                    {
                        title: 'To Do',
                        field: 'title.en',
                        align: 'center',
                    },
                    {
                        title: 'Careplan',
                        field: 'carePlan.label.en',
                        align: 'center',
                    },
                    {
                        title: 'Category',
                        field: 'carePlan.group.label.en',
                        align: 'center',
                    },
                    {
                        title: 'Date to Compelete By',
                        field: 'dueDate',
                        align: 'center',
                        type: 'date',
                        render: ({ dueDate }) => {
                            return (
                                <>
                                    {dueDate && displayDate({ isoDateStr: dueDate })}
                                    {moment(new Date()).isAfter(moment(dueDate)) && (
                                        <span style={{ color: 'red', fontWeight: 'bold' }}>
                                            {' !'}
                                        </span>
                                    )}
                                </>
                            );
                        },
                    },
                    {
                        title: 'Recurring',
                        field: 'repeatType',
                        align: 'center',
                        render: ({ repeatType }) => {
                            if (repeatType === null) return '';
                            const match = ToDoTaskRepeatTypeArray.findIndex(
                                item => item[0] === repeatType,
                            );
                            return match ? ToDoTaskRepeatTypeArray[match][1] : '';
                        },
                        customSort: (a, b) => {
                            if (a.repeatType === null && b.repeatType !== null) return -1;
                            if (a.repeatType !== null && b.repeatType === null) return 1;
                            if (a.repeatType === null && b.repeatType === null) return 0;
                            const currentMatch = ToDoTaskRepeatTypeArray.findIndex(
                                item => item[0] === a.repeatType,
                            );
                            const current = ToDoTaskRepeatTypeArray[currentMatch][1] ?? '';

                            const nextMatch = ToDoTaskRepeatTypeArray.findIndex(
                                item => item[0] === b.repeatType,
                            );
                            const next = ToDoTaskRepeatTypeArray[nextMatch][1] ?? '';

                            if (current < next) {
                                return -1;
                            }
                            if (current > next) {
                                return 1;
                            }
                            return 0;
                        },
                    },
                    {
                        title: 'HA Created',
                        field: 'createdBy',
                        align: 'center',
                        render: ({ createdBy }) => {
                            const defaultCheckbox = <Checkbox disabled />;
                            if (createdBy === null) return defaultCheckbox;
                            return createdBy === HA_CREATED ? (
                                <Checkbox checked disabled />
                            ) : (
                                defaultCheckbox
                            );
                        },
                        customSort: (a, b) => {
                            if (a.createdBy === null || b.createdBy === null) return 0;
                            const current = a.createdBy === HA_CREATED ? 1 : 0;
                            const next = b.createdBy === HA_CREATED ? 1 : 0;

                            if (current < next) {
                                return -1;
                            }
                            if (current > next) {
                                return 1;
                            }
                            return 0;
                        },
                    },
                ]}
                isLoading={isLoading}
                data={sortedData}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [30, 50, 100],
                    exportAllData: pagePermissions?.PatientTodos.Export,
                }}
            />
        </div>
    );
};
