export enum OnboardingScreenEnum {
    APP_ONBOARDING_SCREENS = 'app-onboarding-screens',
    ONBOARDING_SCREENS = 'onboarding-screens',
    ADD_ONBOARDING_SCREEN = 'add-onboarding-screen',
    SURVEY_SCREENS = 'survey-screens',
    ADD_SURVEY_SCREEN = 'add-survey-screen',
    SCREEN_ORDER_DELETE = 'screen-order-delete',
    SCREEN_ORDER_TEXT = 'screen-order-text',
    SAVE = 'onboarding-screens-save',
}

export const OnboardingScreenSelectors = {
    APP_ONBOARDING_SCREENS_BUTTON: `button[data-test="${OnboardingScreenEnum.APP_ONBOARDING_SCREENS}"]`,
    ONBOARDING_SCREENS_DROPDOWN: `div[data-test="${OnboardingScreenEnum.ONBOARDING_SCREENS}"]`,
    DROPDOWN_WELCOME_OPTION: 'li[data-test="Welcome"]',
    ADD_ONBOARDING_SCREEN_BUTTON: `button[data-test="${OnboardingScreenEnum.ADD_ONBOARDING_SCREEN}"]`,
    SCREEN_ORDER_LIST_WELCOME_TEXT: `div[data-test="Welcome (Onboarding screens)"] div[data-test="${OnboardingScreenEnum.SCREEN_ORDER_TEXT}"]`,
    SCREEN_ORDER_WELCOME_DELETE: `div[data-test="Welcome (Onboarding screens)"] div[data-test="${OnboardingScreenEnum.SCREEN_ORDER_DELETE}"]`,
    SURVEY_SCREENS_DROPDOWN: `div[data-test="${OnboardingScreenEnum.SURVEY_SCREENS}"]`,
    SURVEY_SCREENS_DROPDOWN_OPTIONS:
        'div[role="presentation"] ul[aria-labelledby="selectSurveyScreenValue-label"]',
    ADD_SURVEY_SCREEN_BUTTON: `button[data-test="${OnboardingScreenEnum.ADD_SURVEY_SCREEN}"]`,
    SAVE_BUTTON: `button[data-test="${OnboardingScreenEnum.SAVE}"]`,
};
