import * as Yup from 'yup';

const schema = Yup.object().shape({
    label: Yup.object().shape({
        en: Yup.string().required('Label (en) is required'),
        es: Yup.string(),
    }),
    yourHealthInfo: Yup.object().shape({
        label: Yup.object().shape({
            en: Yup.string().required('Label (en) is required'),
            es: Yup.string(),
        }),
        instructions: Yup.object().shape({
            en: Yup.string().required('Instructions (en) are required'),
            es: Yup.string(),
        }),
        icon: Yup.string(),
    }),
    surveyGroups: Yup.array()
        .of(
            Yup.object().shape({
                label: Yup.object().shape({
                    en: Yup.string().required('Label (en) is required'),
                    es: Yup.string(),
                }),
                surveys: Yup.array()
                    .of(
                        Yup.object().shape({
                            label: Yup.object().shape({
                                en: Yup.string().required('Label (en) is required'),
                                es: Yup.string(),
                            }),
                            surveyId: Yup.string().required(),
                        }),
                    )
                    .required('You have to add at least one survey'),
            }),
        )
        .required('You have to add at least one survey group'),
    shortcuts: Yup.array()
        .of(
            Yup.object().shape({
                label: Yup.object().shape({
                    en: Yup.string().required('Label (en) is required'),
                    es: Yup.string(),
                }),
                surveyGroupId: Yup.string().when('type', ([type], schema) => {
                    return type === 'surveyGroup'
                        ? schema.required('Survey group is required')
                        : schema;
                }),
                surveyId: Yup.string().when('type', ([type], schema) => {
                    return type === 'survey' ? schema.required('Survey is required') : schema;
                }),
                type: Yup.string().required('Type is required'),
                icon: Yup.string().required('Icon is required'),
                instructions: Yup.object().shape({
                    en: Yup.string().required('Label (en) is required'),
                    es: Yup.string(),
                }),
            }),
        )
        .required('You have to add at least one shortcut'),
});

export default schema;
