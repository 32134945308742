import { Dialog, Drawer, Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Search } from '@mui/icons-material';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import Loading from '~/components/Loading/Loading';
import { OrgTreeNode } from '~/hooks/useOrgTree';
import { useOrgListQuery } from '~/schemaTypes';
import { useNavigate } from 'react-router-dom';
import useOrganizations from './hooks/useOrganizations';
import { FlattedOrgNode, OrgId } from './hooks/useOrganizations.types';

import OrganizationCard from './OrganizationCard/OrganizationCard';
import OrganizationModal from './OrganizationModal/OrganizationModal';
import OrganizationSidebar from './OrganizationSidebar/OrganizationSidebar';

export type OrgState = {
    [key: string]: boolean;
};

const isOrgEqualToValue = (value: string, { org: { id, brandingName } }: OrgTreeNode): boolean =>
    id.toLowerCase().includes(value) || brandingName.toLowerCase().includes(value);

const findOrgNode = (value: string, orgTree: OrgTreeNode): OrgTreeNode | null => {
    let resultedOrgTree: OrgTreeNode | null = null;

    if (isOrgEqualToValue(value, orgTree)) {
        resultedOrgTree = JSON.parse(JSON.stringify(orgTree));
        /* eslint-disable  @typescript-eslint/no-non-null-assertion */
        resultedOrgTree!.orgChildren = [];
    }

    if (orgTree.orgChildren.length) {
        for (const org of orgTree.orgChildren) {
            const foundOrgNode = findOrgNode(value, org);

            if (foundOrgNode) {
                resultedOrgTree = {
                    ...(resultedOrgTree || orgTree),
                    orgChildren: resultedOrgTree
                        ? [...resultedOrgTree.orgChildren, foundOrgNode]
                        : [foundOrgNode],
                };
            }
        }
    }

    return resultedOrgTree;
};

const filterOrgTree = (
    filterValue: string,
    flattedOrgTreeInfo: FlattedOrgNode[],
    orgTree: OrgTreeNode | null,
) => {
    const isOrgExist = flattedOrgTreeInfo.filter(
        ({ id, name }) => id.includes(filterValue) || name.includes(filterValue),
    );

    return isOrgExist.length && orgTree ? findOrgNode(filterValue, orgTree) : null;
};

const useStyles = makeStyles()(theme => ({
    orgContainer: {
        marginLeft: '-25%',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    shiftedOrgContainer: {
        marginLeft: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    searchContainer: {
        position: 'static',
        border: 0,
        borderRight: 0,
    },
    drawer: {
        height: '100%',
        borderRight: 0,
    },
    titleWrapper: {
        marginRight: theme.spacing(3),
    },
}));
const Organizations: React.FC = () => {
    const { classes } = useStyles();
    const [editOrgId, setEditOrgId] = useState<string>('');
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [parentId, setParentId] = useState<string>('');
    const [parentAppBundleId, setParentAppBundleId] = useState<string | null>('');
    const [orgDetailsSection, setOrgDetailsSection] = useState<string>('');
    const [isSearchOpen, setSearchOpen] = useState<boolean>(true);
    const [filterValue, setFilterValue] = useState<string>('');
    const history = useNavigate();
    const [
        {
            orgTree,
            orgState,
            selectedOrgId,
            calculated: { flattedOrgTreeInfo, flattedOrgTreeInfoMap },
        },
        { loadOrgs, selectOrgId },
    ] = useOrganizations();

    const { loading } = useOrgListQuery({ onCompleted: loadOrgs });

    const onFilterChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => setFilterValue(event.target.value.toLowerCase()),
        [],
    );
    const onClickNode = useCallback(
        (id: OrgId) => () => {
            selectOrgId(id);
            history(`?id=${id}`);
        },
        [selectOrgId, history],
    );
    const toggleSearch = useCallback(() => setSearchOpen(value => !value), []);

    const defaultExpandedMemoizedValue = useMemo(() => [orgTree?.org?.id], [orgTree]);

    const filteredOrgTree = useMemo(
        () => filterOrgTree(filterValue, flattedOrgTreeInfo, orgTree),
        [filterValue, flattedOrgTreeInfo, orgTree],
    );

    if (loading) return <Loading />;
    return (
        <div>
            <Grid container alignItems="center">
                <Grid item className={classes.titleWrapper}>
                    <Typography paragraph variant="h3">
                        Organizations
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={toggleSearch} size="large">
                        <Search />
                    </IconButton>
                </Grid>
            </Grid>
            {orgTree ? (
                <Grid container spacing={3} wrap="nowrap">
                    <Grid item>
                        <Drawer
                            open
                            variant="persistent"
                            className={classes.drawer}
                            classes={{
                                paper: classes.searchContainer,
                            }}
                        >
                            <OrganizationSidebar
                                orgTree={filteredOrgTree || orgTree}
                                orgState={orgState}
                                onClickNode={onClickNode}
                                defaultExpanded={defaultExpandedMemoizedValue}
                                defaultSelected={orgTree?.org?.id}
                                onFilterChange={onFilterChange}
                            />
                        </Drawer>
                    </Grid>
                    <Grid
                        item
                        xs
                        className={
                            isSearchOpen ? classes.shiftedOrgContainer : classes.orgContainer
                        }
                    >
                        <OrganizationCard
                            orgNode={
                                flattedOrgTreeInfoMap[selectedOrgId]?.node ||
                                filteredOrgTree ||
                                orgTree
                            }
                            orgState={orgState}
                            onOrgStateUpdate={onClickNode}
                            setEditOrgId={setEditOrgId}
                            setModalOpen={setModalOpen}
                            setParentId={setParentId}
                            setParentAppBundleId={setParentAppBundleId}
                            setOrgDetailsSection={setOrgDetailsSection}
                        />
                    </Grid>
                </Grid>
            ) : (
                <Loading height={400} />
            )}
            <Dialog scroll="paper" open={modalOpen} fullWidth>
                <OrganizationModal
                    setOpen={setModalOpen}
                    setEditOrgId={setEditOrgId}
                    id={editOrgId}
                    parentId={parentId}
                    parentAppBundleId={parentAppBundleId}
                    orgDetailsSection={orgDetailsSection}
                />
            </Dialog>
        </div>
    );
};

export default Organizations;
