import React from 'react';
import {
    AlertSeverity,
    useDeleteVirtualCareAffiliateMutation,
    useFetchVirtualCareAffiliateByIdQuery,
    useUpdateVirtualCareAffiliatesMutation,
    VirtualCareAffiliatesUpdateInput,
} from '~/schemaTypes';
import { VCAffiliateForm } from '~/views/ConfigDashboard/VCAffiliates/components/VCAffiliateForm';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Divider, FormControl, Grid, Typography } from '@mui/material';
import { VCAffiliateFormContextProvider } from '~/views/ConfigDashboard/VCAffiliates/components/VCAffiliateFormContextProvider';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import { mapToFormValues } from '~/views/ConfigDashboard/VCAffiliates/mappers';
import { VCAffiliatesURLs } from '~/views/ConfigDashboard/VCAffiliates/components/VCAffiliatesURLs';
import { BackToVCAffiliatesList } from '~/views/ConfigDashboard/VCAffiliates/components/BackToVCAffiliatesList';
import Loading from '~/components/Loading/Loading';
import Navigation, { TabsConfig } from '~/views/Dashboard/VCPatients/Navigation';
import { VCAffiliatesHistory } from './VCAffiliatesHistory/VCAffiliatesHistory';
import StatusesMenu from './components/StatusesMenu';

export const VCAffiliatesEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const handleCancel = () => {
        navigate('/app-config/vcaffiliates');
    };
    const { data } = useFetchVirtualCareAffiliateByIdQuery({
        variables: {
            input: {
                id: id ?? '',
            },
        },
        onError: () => {
            TriggerGlobalAlert({ severity: AlertSeverity.Error, message: 'Fetching failed' });
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const [updateVirtualCareAffiliate] = useUpdateVirtualCareAffiliatesMutation({
        onCompleted: data => {
            if (data.updateVirtualCareAffiliate?.success) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'Affiliate updated',
                });
                navigate('/app-config/vcaffiliates');
            } else {
                TriggerGlobalAlert({ severity: AlertSeverity.Error, message: 'Update failed' });
            }
        },
    });

    const handleSubmit = (data: VirtualCareAffiliatesUpdateInput) => {
        if (id) {
            updateVirtualCareAffiliate({ variables: { input: { id, data } } });
        }
    };
    const [deleteVirtualCareAffiliate] = useDeleteVirtualCareAffiliateMutation({
        onCompleted: data => {
            if (data.deleteVirtualCareAffiliate?.success) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'Affiliate was deleted',
                });
                navigate('/app-config/vcaffiliates');
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'Affiliate deletion failed',
                });
            }
        },
    });
    const handleDelete = () => {
        if (id) {
            TriggerGlobalConfirm({
                callback: () => deleteVirtualCareAffiliate({ variables: { input: id } }),
                message: 'Are you sure you want to delete this Affiliate?',
            });
        }
    };
    const affiliateFormValues = React.useMemo(() => mapToFormValues(data), [data]);

    const affiliateId = data?.virtualCareAffiliateById?.data?.affiliateId;
    const affiliateURL = data?.virtualCareAffiliateById?.affiliateURL;
    const affiliateSelfCheckURL = data?.virtualCareAffiliateById?.affiliateSelfCheckURL;
    const affiliateHistory = data?.virtualCareAffiliateById?.data?.history;
    return (
        <>
            <Navigation
                currentTab={TabsConfig.config}
                affiliateId={affiliateId}
                id={id}
                name={data?.virtualCareAffiliateById?.data?.internalName || ''}
            />
            <BackToVCAffiliatesList />
            <Typography variant="h6" className="title" paddingLeft="10px">
                Edit VC Affiliate
            </Typography>
            <Divider style={{ marginTop: '10px', marginBottom: '20px' }} />
            <Grid container columnSpacing={4} justifyContent="space-between">
                <Grid item xs={12} sm={6}>
                    <VCAffiliatesURLs
                        affiliateId={affiliateId}
                        affiliateURL={affiliateURL}
                        affiliateSelfCheckURL={affiliateSelfCheckURL}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    {data?.virtualCareAffiliateById?.data ? (
                        <StatusesMenu
                            affiliateId={id}
                            approvalStatus={data.virtualCareAffiliateById.data.approvalStatus}
                            onboardingStatus={data.virtualCareAffiliateById.data.onboardingStatus}
                            createdAt={data.virtualCareAffiliateById.data?.createdAt}
                            updatedAt={data.virtualCareAffiliateById.data.updatedAt}
                        />
                    ) : (
                        <Loading />
                    )}
                </Grid>
            </Grid>
            <VCAffiliateFormContextProvider>
                <VCAffiliateForm
                    handleFormSubmit={handleSubmit}
                    values={affiliateFormValues}
                    isEdit
                >
                    <Grid xs={12} container item justifyContent="space-between">
                        <Grid>
                            <FormControl>
                                <Button variant="outlined" onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </FormControl>
                            <FormControl>
                                <Button type="submit" color="secondary" variant="contained">
                                    Save
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid>
                            <FormControl>
                                <Button color="error" variant="contained" onClick={handleDelete}>
                                    Delete
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </VCAffiliateForm>
            </VCAffiliateFormContextProvider>
            <VCAffiliatesHistory history={affiliateHistory} />
        </>
    );
};
