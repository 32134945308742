import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    helperMessage: {
        margin: '-10px 0 0 24px',
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 24px',
        '& button + button': {
            marginLeft: '5px',
        },
    },
});
