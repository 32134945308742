import { OrderByDirectionEnum } from '~/schemaTypes';

export enum MeasurementSortingFields {
    takenDate = 'takenDate',
    type = 'type',
    inputType = 'inputType',
    comment = 'comment',
    createdAt = 'createdAt',
}

export interface IMeasurementsOrderChange {
    field: MeasurementSortingFields;
    direction: OrderByDirectionEnum;
    isInit: boolean;
}
