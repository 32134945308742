import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    iconButton: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    hide: {
        display: 'none',
    },
    searchField: {
        '& .MuiSelect-iconOutlined': {
            right: 0,
        },
    },
    dense: {
        '& .MuiFormControl-marginDense': {
            marginTop: 4,
        },
    },
}));

export default useStyles;
