import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    measurementTable: {
        marginBottom: '1em',
    },
    horizontalAlign: {
        display: 'flex',
        alignItems: 'center',
    },
}));
