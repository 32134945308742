import { AdvocateTaskUpdateInput, FetchAdvocateTaskForAdvocateTaskPageQuery } from '~/schemaTypes';
import {
    toAdvocateTaskChecklistInput,
    toAdvocateTaskCommentsInput,
    toAdvocateTaskHistoryInput,
} from './index';

type AdvocateTask = NonNullable<FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']>;

type ToAdvocateTaskInput = (task: AdvocateTask) => AdvocateTaskUpdateInput;

const toAdvocateTaskInput: ToAdvocateTaskInput = task => ({
    label: task.label,
    description: task.description,
    createdBy: task.createdBy,
    assignedTo: task.assignedTo,
    watchedBy: task.watchedBy,
    status: task.status,
    priority: task.priority,
    dueDate: task.dueDate,
    displayDate: task.displayDate,
    typeId: task.typeId,
    patientId: task.patientId,
    checklist: toAdvocateTaskChecklistInput(task.checklist),
    comments: toAdvocateTaskCommentsInput(task.comments),
    history: toAdvocateTaskHistoryInput(task.history),
    slaTimerId: task.slaTimerId,
});

export default toAdvocateTaskInput;
