import { CareTeamType } from '~/schemaTypes';
import { MemberType } from '~/views/ConfigDashboard/CareTeams/types';

export const toMemberTypeForm = (types: CareTeamType[]): MemberType[] =>
    types.map(({ label, id, activeMembersCount, hiddenToPhone, readOnly, isRequired }) => {
        return {
            id,
            hiddenToPhone,
            readOnly,
            isRequired,
            label: { en: label?.en || '', es: label?.es || '' },
            activeMembersCount: activeMembersCount ?? undefined,
        };
    });
