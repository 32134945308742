import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { AlertSeverity, useFetchVirtualCareAffiliateByAffiliateIdQuery } from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import Header from '~/views/VirtualCareSurvey/components/Header';
import Loading from '~/components/Loading/Loading';
import { VirtualCareSurvey } from '../VirtualCareSurvey';
import useStyles from '../styles';
import { useInitVCF, VCFContext } from '../hooks/useVCF';

type AffiliateVCFProps = {
    baseUrl?: string;
};

const AFFILIATE_FORM_DESCRIPTION = `
Securely provide your health information below. This will help us match you to
the right resources for you and your health experience. After you complete this form, you can schedule
 your lactation consult. We currently accept Cigna
insurance, at no additional cost for Cigna customers.`;

const AffiliateVCF: React.FC<AffiliateVCFProps> = props => {
    const { baseUrl } = props;
    const { affiliateId } = useParams<{
        affiliateId?: string;
    }>();
    const contextProps = useInitVCF({
        isAffiliateView: true,
        formDescriptionText: AFFILIATE_FORM_DESCRIPTION,
        affiliateId,
    });
    const { isMobileView } = contextProps;
    const { classes } = useStyles();
    const [shouldShowForm, setShouldShowForm] = useState(false);
    const { data: VCAffiliate, loading: VCAffiliateFetching } =
        useFetchVirtualCareAffiliateByAffiliateIdQuery({
            variables: {
                input: {
                    affiliateId: affiliateId ?? '',
                },
            },
            onError: () => {
                TriggerGlobalAlert({ severity: AlertSeverity.Error, message: 'Fetching failed' });
            },
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
        });
    useEffect(() => {
        if (VCAffiliate?.virtualCareAffiliateByAffiliateId?.data) {
            setShouldShowForm(true);
        }
    }, [VCAffiliate]);
    const header = useMemo(
        () => (
            <Header
                showFormDescription
                subHeading={
                    <Typography variant="body1" mb={2}>
                        In Partnership with:{' '}
                        {VCAffiliate?.virtualCareAffiliateByAffiliateId?.data?.externalName}
                    </Typography>
                }
                formDescriptionText={AFFILIATE_FORM_DESCRIPTION}
            />
        ),
        [VCAffiliate?.virtualCareAffiliateByAffiliateId?.data?.externalName],
    );
    const pathname = `${baseUrl ?? '/affiliate-care'}/${affiliateId}`;
    if (VCAffiliateFetching) {
        return <Loading />;
    }
    if (!shouldShowForm) {
        return (
            <Grid
                className={classes.form}
                container
                justifyContent={isMobileView ? 'flex-start' : 'center'}
            >
                <Header
                    showFormDescription={false}
                    subHeading={
                        <Typography variant="body2" color="textSecondary">
                            Affiliate Not Found. If you think this is in error send an email to:
                            <Button href="Lactationsupport@wildflowerhealth.com">
                                Lactationsupport@wildflowerhealth.com
                            </Button>
                        </Typography>
                    }
                />
            </Grid>
        );
    }
    return (
        <VCFContext.Provider value={contextProps}>
            <VirtualCareSurvey header={header} pathname={pathname} />
        </VCFContext.Provider>
    );
};

export default AffiliateVCF;
