import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Dialog,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import { Add, ImageOutlined } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable, {
    MaterialTableProps,
    MTableAction,
    MTableActions,
} from '@material-table/core';
import React, { ReactElement, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VC_PARENT_ORG_ID, PAGESIZE } from '~/constants';
import { displayDateLocale } from '~/helpers/dateHelpers';
import tableIcons from '~/helpers/tableIcons';
import { useQueryParams, useUser, useUserPermissions } from '~/hooks';
import {
    FetchUsersForStaffPageQueryQuery,
    useDeleteUserForStaffPageMutation,
    useFetchUsersForStaffPageQueryQuery,
    PortalVcUserType,
} from '~/schemaTypes';
import { AdminUserEnum } from '~/selectors';
import { TriggerGlobalConfirm, TriggerGlobalImagePreview } from '~/state';
import StaffModal from './StaffModal/StaffModal';
import VCAffiliateUserModal from './VCAffiliateUsersModal/VCAffiliateUsersModal';
import useUpdateCache from './useUpdateCache';

type Users = FetchUsersForStaffPageQueryQuery['getUsersByOrg'][0];

export default function Staff(): ReactElement {
    const [query] = useQueryParams();
    const [search, setSearch] = useState<string>(query.get('_search') ?? '');
    const isVCView = !!query.get('affiliate-users-only');
    const history = useNavigate();
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);

    const [showStaffModal, setShowStaffModal] = useState(false);
    const [editStaffId, setEditStaffId] = useState<string>('');
    const { data: userData } = useUser();

    const [VCTypeFilter, setVCTypeFilter] = useState<PortalVcUserType | ''>('');

    const currentOrgId = isVCView ? VC_PARENT_ORG_ID : userData?.currentUser?.rootOrg.id;
    const { updateAfterDelete } = useUpdateCache(currentOrgId, VCTypeFilter, isVCView);

    const { data: staffData, loading: staffLoading } = useFetchUsersForStaffPageQueryQuery({
        variables: {
            input: {
                orgId: currentOrgId,
                ...(isVCView && VCTypeFilter && { VCType: VCTypeFilter }),
            },
        },
    });

    const [deleteUser, { loading: deleteUserLoading }] = useDeleteUserForStaffPageMutation({
        update: (cache, res) => {
            updateAfterDelete(cache, res.data?.deleteUser?.id, res.data?.deleteUser?.vcType);
        },
    });

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div data-test={AdminUserEnum.CONTAINER} ref={tableRef}>
            <MaterialTable<Users>
                isLoading={staffLoading || deleteUserLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                actions={[
                    {
                        onClick: () => {
                            setEditStaffId('');
                            setShowStaffModal(true);
                        },
                        hidden: !pagePermissions?.Staff.Edit,
                        icon: () => <Add />,
                        tooltip: isVCView ? 'Add VC User' : 'Add Admin User',
                        isFreeAction: true,
                    },
                    ({ isActive }) => ({
                        onClick: (_e, user: any) => {
                            setEditStaffId(user.id);
                            setShowStaffModal(true);
                        },
                        disabled: !pagePermissions?.Staff.Edit || !isActive,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: isVCView ? 'Edit VC User' : 'Edit Admin User',
                    }),
                    ({ isActive }) => ({
                        onClick: (_e, { id }: any) => {
                            TriggerGlobalConfirm({
                                message: isVCView
                                    ? 'Do you want to delete this VC User?'
                                    : 'Do you want to delete this Admin User?',
                                callback: () => {
                                    deleteUser({
                                        variables: {
                                            input: {
                                                id,
                                            },
                                        },
                                    });
                                },
                            });
                        },
                        disabled: !isActive,
                        hidden: !pagePermissions?.Staff.Delete,
                        tooltip: isVCView ? 'Delete VC User' : 'Delete Admin User',
                        icon: () => <FontAwesomeIcon icon={faTrash} />,
                    }),
                ]}
                columns={[
                    {
                        title: 'Name',
                        field: 'name',
                        defaultSort: 'asc',
                        render: ({ name }) => <Typography data-test={name}>{name}</Typography>,
                    },
                    { title: 'Email', field: 'email' },
                    {
                        title: 'Active Status',
                        field: 'isActive',
                        render: ({ isActive }) =>
                            isActive ? (
                                <Typography>Active</Typography>
                            ) : (
                                <Typography color="error">Not Active</Typography>
                            ),
                    },
                    { title: 'Title', field: 'title' },
                    {
                        title: 'Last Login',
                        field: 'lastLogin',
                        align: 'center',
                        sorting: false,
                        render: ({ lastLogin }) => {
                            if (lastLogin) {
                                return displayDateLocale({ isoDateStr: lastLogin });
                            }
                            return '';
                        },
                    },
                    { title: 'Root Org', field: 'rootOrg.brandingName' },
                    { title: 'SMS Number', field: 'smsNumber' },
                    {
                        title: 'Role',
                        render: ({ role }) => (
                            <Typography>{role?.name ?? 'Deleted Role'}</Typography>
                        ),
                    },
                    {
                        title: 'Photo',
                        field: 'photo',
                        align: 'center',
                        sorting: false,
                        render: ({ photo }) => {
                            if (photo) {
                                return (
                                    <Tooltip title={photo ?? ''}>
                                        <IconButton
                                            onClick={() => {
                                                TriggerGlobalImagePreview({ imageSrc: photo });
                                            }}
                                            size="large"
                                        >
                                            <ImageOutlined />
                                        </IconButton>
                                    </Tooltip>
                                );
                            }
                            return '';
                        },
                    },
                ]}
                data={_.cloneDeep(staffData?.getUsersByOrg ?? [])}
                title={isVCView ? 'VC Users' : 'Admin Users'}
                localization={{ header: { actions: '' } }}
                onSearchChange={searchText => {
                    setSearch(searchText);
                    history(
                        isVCView
                            ? `/app-config/staff?affiliate-users-only=true${
                                  searchText && `&_search=${searchText}`
                              }`
                            : `/app-config/staff${searchText && `?_search=${searchText}`}`,
                    );
                }}
                options={{
                    searchText: search ?? '',
                    pageSize: rowsPerPage,
                }}
                components={{
                    Actions: props => {
                        const propsWithActionIndex = {
                            ...props,
                            actions: props?.actions.map((a: any, index: number) => ({
                                ...a,
                                firstAction: index === 0,
                            })),
                        };
                        return <MTableActions {...propsWithActionIndex} />;
                    },
                    Action: props => {
                        if (
                            props?.action?.position === 'toolbar' &&
                            props?.action?.firstAction &&
                            isVCView
                        ) {
                            return (
                                <Grid container minWidth={250} alignItems="center">
                                    <Grid item minWidth={170} xs={10}>
                                        <FormControl fullWidth>
                                            <InputLabel id="approval-status-filter-label" shrink>
                                                VC Type
                                            </InputLabel>
                                            <Select
                                                labelId="vc-type-filter-label"
                                                id="vc-type-filter"
                                                value={VCTypeFilter}
                                                size="small"
                                                displayEmpty
                                                onChange={evt =>
                                                    setVCTypeFilter(
                                                        evt.target.value as PortalVcUserType,
                                                    )
                                                }
                                            >
                                                <MenuItem value="">All</MenuItem>
                                                <MenuItem value={PortalVcUserType.Affiliate}>
                                                    Affiliate
                                                </MenuItem>
                                                <MenuItem value={PortalVcUserType.Doula}>
                                                    Doula
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <MTableAction {...props} />
                                    </Grid>
                                </Grid>
                            );
                        }
                        return <MTableAction {...props} />;
                    },
                }}
            />
            <Dialog
                scroll="paper"
                open={showStaffModal}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                {isVCView ? (
                    <VCAffiliateUserModal
                        setOpen={setShowStaffModal}
                        setEditStaffId={setEditStaffId}
                        id={editStaffId}
                        VCTypeFilter={VCTypeFilter}
                    />
                ) : (
                    <StaffModal
                        setOpen={setShowStaffModal}
                        setEditStaffId={setEditStaffId}
                        id={editStaffId}
                    />
                )}
            </Dialog>
        </div>
    );
}
