import ObjectId from 'bson-objectid';
import { toBase64 } from 'js-base64';
import { AdvocateTaskTemplateFormInput, AdvocateTaskTemplateInput, FormCheckList } from '../types';

export const formValuesToUpdateInput = (
    form: AdvocateTaskTemplateFormInput,
    checkList: FormCheckList[],
): AdvocateTaskTemplateInput => {
    const { label, description, priority, typeId, whenType, whenTimeline } = form;

    return {
        label,
        description,
        priority,
        checklist: checkList.map(checkListItem => ({
            id: new ObjectId().toHexString(),
            label: toBase64(checkListItem.label || ''),
            description: toBase64(checkListItem.description || ''),
            completedAt: checkListItem.completedAt
                ? new Date(checkListItem.completedAt).toDateString()
                : null,
            completedBy: checkListItem.completedBy ?? null,
            actionTypeIds: checkListItem.actionTypeIds ?? [],
        })),
        typeId,
        whenType,
        whenTimeline,
    };
};
