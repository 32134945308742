import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    root: {},
    helperMessage: {
        margin: '-10px 0 0 24px',
    },
    sortableList: {
        marginTop: 20,
    },
    addTypeButton: {
        margin: '10px 0 10px 10px',
    },
    memberTypeListError: {
        marginTop: 10,
        marginLeft: 24,
    },
    divider: {
        marginBottom: 20,
    },
}));

export default useStyles;
