import { Menu, MenuItem, MenuList } from '@mui/material';
import React from 'react';
import { SurveyDefDivisionInput } from '~/schemaTypes';
import { SurveyEnum } from '~/selectors';
import { DIVISION_ACTIONS_ENUM } from '../enums/divisionActionsEnum';

export const DivisionActionDropdown: React.FC<{
    div: SurveyDefDivisionInput | undefined;
    anchor: HTMLElement | null;
    onUserSelectAction: (action: DIVISION_ACTIONS_ENUM) => void;
    onUserCloseDropdown: () => void;
}> = props => {
    const { div, anchor, onUserSelectAction, onUserCloseDropdown } = props;
    const isDivHasScreen = div?.sections ? div.sections.length > 0 : false;
    const isDivHasSubSurvey = div?.subSurveyDefId !== undefined && div.subSurveyDefId !== null;
    const isDivEmpty = !isDivHasScreen && !isDivHasSubSurvey;
    const shouldShowSubSurveyControls = isDivEmpty;
    const shouldShowScreenControls = isDivEmpty || (!isDivHasSubSurvey && isDivHasScreen);
    return (
        <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={onUserCloseDropdown} elevation={2}>
            <MenuList dense>
                <MenuItem key="D1" onClick={() => onUserSelectAction(DIVISION_ACTIONS_ENUM.EDIT)}>
                    Edit
                </MenuItem>
                {shouldShowScreenControls && (
                    <MenuItem
                        key="D2"
                        onClick={() => onUserSelectAction(DIVISION_ACTIONS_ENUM.ADD)}
                        data-test={SurveyEnum.ADD_SCREEN}
                    >
                        Add Screen
                    </MenuItem>
                )}
                {shouldShowSubSurveyControls && (
                    <MenuItem
                        key="D4"
                        onClick={() => onUserSelectAction(DIVISION_ACTIONS_ENUM.ADD_SUBSURVEY)}
                    >
                        Add Sub Survey
                    </MenuItem>
                )}
                {div && shouldShowScreenControls && div.sections?.length > 1 && (
                    <MenuItem
                        key="D3"
                        onClick={() => onUserSelectAction(DIVISION_ACTIONS_ENUM.REORDER)}
                    >
                        Reorder Screens
                    </MenuItem>
                )}
            </MenuList>
        </Menu>
    );
};
