import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import {
    ArticleCategoryListDocument,
    ArticleCategoryListQuery,
    ArticleCategoryUpdateInput,
    useArticleCategoryListQuery,
    useCreateArticleCategoryMutation,
    useUpdateArticleCategoryMutation,
} from '~/schemaTypes';
import { ConditionTypeEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';
import { errorRed } from '~/theme/WildTheme';

type ArticleCategory = NonNullable<ArticleCategoryListQuery['articleCategorys'][0]>;

interface FormInput {
    name: string;
}
const inputValidation = Yup.object().shape({
    name: Yup.string().required('You need to enter a name'),
});

export const ArticleCategoryModal: React.FC<{
    isOpen: boolean;
    item: ArticleCategory | null;
    onClose: () => void;
}> = props => {
    const { isOpen, item, onClose } = props;
    const [nameUsed, setNameUsed] = useState(false);
    // list of conditionTypes to compare with name field of new one
    const { data: articleCategoriesList, loading: listLoading } = useArticleCategoryListQuery();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormInput>({
        resolver: yupResolver(inputValidation as any),
        defaultValues: {
            name: item?.name,
        },
    });

    useEffect(() => {
        reset({
            name: item?.name,
        });
    }, [item, reset]);

    const handleClose = () => {
        setNameUsed(false);
        onClose();
    };

    const [createArticleCategory, { loading: createLoading }] = useCreateArticleCategoryMutation({
        onCompleted: () => {
            handleClose();
        },
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    handleClose();
                },
                message: `There was a problem saving the article category: ${error.message}`,
            });
        },
        update: (cache, response) => {
            const newItem = response.data?.createArticleCategory?.resourceCreated;
            if (response.data?.createArticleCategory?.success && newItem) {
                const currentItems = cache.readQuery<ArticleCategoryListQuery>({
                    query: ArticleCategoryListDocument,
                });
                if (currentItems?.articleCategorys) {
                    cache.writeQuery<ArticleCategoryListQuery>({
                        query: ArticleCategoryListDocument,
                        data: {
                            articleCategorys: [...currentItems.articleCategorys, newItem],
                        },
                    });
                }
            }
        },
    });

    const [updateItem, { loading: updateLoading }] = useUpdateArticleCategoryMutation({
        onCompleted: () => {
            handleClose();
        },
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    handleClose();
                },
                message: `There was a problem updating the article category: ${error.message}`,
            });
        },
        update: (cache, response) => {
            const newItem = response.data?.updateArticleCategory?.resourceUpdated;
            if (response.data?.updateArticleCategory?.success && newItem) {
                const currentItems = cache.readQuery<ArticleCategoryListQuery>({
                    query: ArticleCategoryListDocument,
                });
                if (currentItems?.articleCategorys) {
                    const updatedList = currentItems.articleCategorys.map(item => {
                        if (item.id === newItem.id) {
                            return newItem;
                        }
                        return item;
                    });
                    cache.writeQuery<ArticleCategoryListQuery>({
                        query: ArticleCategoryListDocument,
                        data: {
                            articleCategorys: [...updatedList],
                        },
                    });
                }
            }
        },
    });

    const isInEditMode = item !== null;
    const onSubmit = (values: ArticleCategoryUpdateInput) => {
        setNameUsed(false);
        if (
            articleCategoriesList &&
            articleCategoriesList.articleCategorys.filter(
                t =>
                    t.name.toLowerCase() === values?.name.toLowerCase() &&
                    (!isInEditMode || t.id !== item.id),
            ).length > 0
        ) {
            setNameUsed(true);
            return;
        }
        if (isInEditMode && item !== null) {
            updateItem({
                variables: {
                    updateArticleCategoryInput: {
                        id: item.id,
                        data: values,
                    },
                },
            });
            return;
        }
        createArticleCategory({
            variables: {
                input: values,
            },
        });
    };
    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose id="dialogTitle" onClose={handleClose}>
                {isInEditMode ? 'Edit Article Category' : 'Add Article Category'}
            </DialogTitleWithClose>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    {listLoading || updateLoading || createLoading ? (
                        <Loading height={50} />
                    ) : (
                        <div>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Name *"
                                    fullWidth
                                    margin="dense"
                                    {...register('name')}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                                {nameUsed && (
                                    <div style={{ color: errorRed }}>
                                        Name already used. Please change.
                                    </div>
                                )}
                            </Grid>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={() => handleSubmit(onSubmit)}
                        data-test={ConditionTypeEnum.SAVE}
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
