import { Button, Checkbox, FormControl, FormControlLabel, Grid, MenuItem } from '@mui/material';
import { Save } from '@mui/icons-material';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useUpdateHighlightFeedItemMutation } from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import useStyles from './styles';
import { PatientHighlightForm } from './types';

const priorities = [1, 2, 3, 4, 5];

const PatientHighlightsModal: React.FC<{
    closeHandler: () => void;
    id: string;
    priority: number;
    closed: boolean;
}> = props => {
    const { closeHandler, id, priority, closed } = props;
    const { classes } = useStyles();
    const { handleSubmit, control, reset, register } = useForm<PatientHighlightForm>();

    const [updateHighlightFeedItem, { loading: isUpdateInProgress }] =
        useUpdateHighlightFeedItemMutation({
            onCompleted: () => closeHandler(),
        });

    const handleFormSubmit = useCallback(
        (formData: PatientHighlightForm) => {
            updateHighlightFeedItem({
                variables: { input: { id, priority: formData.priority, closed: formData.closed } },
            });
        },
        [id, updateHighlightFeedItem],
    );

    useEffect(() => {
        reset({
            priority,
            closed,
        });
        // eslint-disable-next-line
    }, [id]);

    if (!id) return null;

    if (isUpdateInProgress) return <Loading />;

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)} className={classes.root}>
            <Grid container direction="column">
                <Grid item>
                    <ReactHookFormSelect
                        control={control}
                        name="priority"
                        variant="outlined"
                        label="Priority"
                        margin="dense"
                        fullWidth
                        defaultValue=""
                    >
                        {priorities.map(p => (
                            <MenuItem key={p} value={p}>
                                {p}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                </Grid>
                <Grid item>
                    <FormControl variant="outlined">
                        <FormControlLabel
                            control={
                                <Controller
                                    control={control}
                                    name="closed"
                                    defaultValue={false}
                                    render={({ field: { onChange, value } }) => (
                                        <Checkbox
                                            inputRef={register('closed').ref}
                                            onChange={e => onChange(e.target.checked)}
                                            checked={value ?? false}
                                        />
                                    )}
                                />
                            }
                            label="Closed"
                        />
                    </FormControl>
                </Grid>
                <Grid item container justifyContent="space-between">
                    <FormControl variant="outlined">
                        <Button
                            startIcon={<Save />}
                            color="secondary"
                            variant="contained"
                            type="submit"
                        >
                            Save
                        </Button>
                    </FormControl>
                    <FormControl variant="outlined">
                        <Button color="primary" variant="contained" onClick={closeHandler}>
                            Cancel
                        </Button>
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    );
};

export default PatientHighlightsModal;
