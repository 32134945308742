/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/destructuring-assignment */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

interface ErrorProps {
    hasError?: boolean;
    error?: any;
    info?: any;
    children?: React.ReactNode;
}
export default class ErrorHandler extends React.Component<ErrorProps, ErrorProps> {
    constructor(props: ErrorProps) {
        super(props);
        this.state = { hasError: false };
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    componentDidCatch(error: any, info: any): void {
        // Display fallback UI
        this.setState({ hasError: true, error, info });
    }

    render() {
        if (this.state.hasError) {
            const err = this.state.error;
            const componentStack = this.state.info?.componentStack;
            return (
                <Dialog open>
                    <DialogTitle>Something went wrong, it is not your fault.</DialogTitle>
                    <DialogContent>
                        {err != null && (
                            <div>
                                <pre>{err.stack ?? err.message ?? err.toString()}</pre>
                            </div>
                        )}
                        {componentStack != null && (
                            <div>
                                <pre>{componentStack}</pre>
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            // location reload does not take parameters: https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
                            onClick={() => window.location.reload()}
                        >
                            Reload
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }
        return this.props.children;
    }
}
