import React from 'react';
import { PatientHeaderItem } from '../PatientHeaderComponent';

export const PatientHeaderItemDisplay = React.forwardRef(
    (props: { item: PatientHeaderItem }, ref: React.RefObject<HTMLAnchorElement>) => {
        const { item } = props;

        if (item.value?.startsWith('http')) {
            return (
                <a
                    ref={ref}
                    href={item.value.split(',')[0]}
                    target="blank"
                    title={item.value.split(',')[1]}
                >
                    <strong>{item.value.split(',')[1]}</strong>
                </a>
            );
        }
        return <strong ref={ref}>{item.value}</strong>;
    },
);
