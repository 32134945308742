import { IVCAffiliatesFormValues } from '~/views/ConfigDashboard/VCAffiliates/interfaces/IVCAffiliatesFormValues';
import {
    FetchVirtualCareAffiliateByIdQuery,
    VirtualCareAffiliatesUpdateInput,
    OnSuccessfulZipCodeMatch,
} from '~/schemaTypes';
import {
    coverageAreasMapper,
    officeAddressesMapper,
    lactationConsultantsMapper,
} from '~/views/AffiliateCare/Onboarding/mappers';

export const mapToFormValues = (
    data?: FetchVirtualCareAffiliateByIdQuery,
): IVCAffiliatesFormValues => ({
    internalName: data?.virtualCareAffiliateById?.data?.internalName || '',
    externalName: data?.virtualCareAffiliateById?.data?.externalName || '',
    contactName: data?.virtualCareAffiliateById?.data?.contactName || '',
    contactEmail: data?.virtualCareAffiliateById?.data?.contactEmail || '',
    contactPhoneNumber: data?.virtualCareAffiliateById?.data?.contactPhoneNumber || '',
    onSuccessfulEligibility: data?.virtualCareAffiliateById?.data?.onSuccessfulEligibility || '',
    routingURL: data?.virtualCareAffiliateById?.data?.routingURL || '',
    phoneNumberForScheduling: data?.virtualCareAffiliateById?.data?.phoneNumberForScheduling || '',
    emailAddressForScheduling:
        data?.virtualCareAffiliateById?.data?.emailAddressForScheduling || '',
    customSuccessText: data?.virtualCareAffiliateById?.data?.customSuccessText || '',
    notificationEmail: data?.virtualCareAffiliateById?.data?.notificationEmail?.join(',') || '',
    notifyCompanyWhenPatientPassesEligibility:
        data?.virtualCareAffiliateById?.data?.notifyCompanyWhenPatientPassesEligibility || false,
    onSuccessfulZipCodeMatch:
        data?.virtualCareAffiliateById?.data?.onSuccessfulZipCodeMatch ||
        OnSuccessfulZipCodeMatch.ToSuccessScreen,
    zipCodeMatchCustomSuccessString:
        data?.virtualCareAffiliateById?.data?.zipCodeMatchCustomSuccessString || '',
    BillDotComNameOnCheck:
        data?.virtualCareAffiliateById?.data?.billComVendorData?.nameOnCheck || '',
    BillDotComAddress1: data?.virtualCareAffiliateById?.data?.billComVendorData?.address1 || '',
    BillDotComAddress2: data?.virtualCareAffiliateById?.data?.billComVendorData?.address2 || '',
    BillDotComAddressCity:
        data?.virtualCareAffiliateById?.data?.billComVendorData?.addressCity || '',
    BillDotComAddressState:
        data?.virtualCareAffiliateById?.data?.billComVendorData?.addressState || '',
    BillDotComAddressZip: data?.virtualCareAffiliateById?.data?.billComVendorData?.addressZip || '',
    BillDotComVendorId: data?.virtualCareAffiliateById?.data?.BillDotComVendorId || '',
    website: data?.virtualCareAffiliateById?.data?.website || '',
    inviteAffiliate: data?.virtualCareAffiliateById?.data?.inviteAffiliate || false,
    lactationConsultants: lactationConsultantsMapper(
        data?.virtualCareAffiliateById?.data?.lactationConsultants,
    ),
    w9FileName: data?.virtualCareAffiliateById?.data?.w9FileName || '',
    w9FS3Link: data?.virtualCareAffiliateById?.data?.w9FS3Link || '',
    officeAddresses: officeAddressesMapper(data?.virtualCareAffiliateById?.data?.officeAddresses),
    coverageAreas: coverageAreasMapper(data?.virtualCareAffiliateById?.data?.coverageAreas),
    isVirtualType: data?.virtualCareAffiliateById?.data?.isVirtualType || false,
    isInOfficeType: data?.virtualCareAffiliateById?.data?.isInOfficeType || false,
    isPatientsHomeType: data?.virtualCareAffiliateById?.data?.isPatientsHomeType || false,
    supportPhone: data?.virtualCareAffiliateById?.data?.supportPhone || '',
    supportEmail: data?.virtualCareAffiliateById?.data?.supportEmail || '',
});

export const mapFromFormToInput = (
    data: IVCAffiliatesFormValues,
): VirtualCareAffiliatesUpdateInput => ({
    ...data,
    notificationEmail: data.notificationEmail
        ?.split(',')
        .map(email => email.trim())
        .filter(Boolean),
});
