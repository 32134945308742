import { makeStyles } from 'tss-react/mui';

export default makeStyles()(theme => ({
    root: {
        padding: theme.spacing(2),
    },
    inputField: {
        width: '100%',
        '& label': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '80%',
            overflow: 'hidden',
        },
    },
    selectTagsField: {
        width: '100%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    displayNone: {
        display: 'none',
    },
    chip: {
        margin: 2,
    },
}));
