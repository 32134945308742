import * as Yup from 'yup';

const schema = Yup.object().shape({
    appBundleId: Yup.string().required('App is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required'),
});

export default schema;
