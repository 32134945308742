import _ from 'lodash';
import { Permission, Target, useFetchRolesForCurrentUserPermissionsHookQuery } from '~/schemaTypes';
import { TargetGroup, UserPermissions } from '~/utils/UserPermissions';

export type PagePermissions = {
    [Page in Target]: {
        Read: boolean;
        Edit: boolean;
        Delete: boolean;
        Export: boolean;
    };
};
export const deprecatedTargets: Target[] = [
    Target.HgPatientAccess,
    Target.WhatsNewInstances,
    Target.WhatsNewTemplates,
    Target.PatientWhatsNew,
    Target.DeviceOrders,
    Target.AddPatient,
    Target.ProfileValueJobs,
    Target.Tokens,
];
export type GroupPermissions = {
    [Group in TargetGroup]: boolean;
};

const useUserPermissions = (): {
    loading: boolean;
    pagePermissions?: PagePermissions;
    groupPermissions?: GroupPermissions;
} => {
    const { data, loading } = useFetchRolesForCurrentUserPermissionsHookQuery();
    if (!loading && data?.roleOfCurrentUser?.permissions) {
        const userResult = _.mapValues(Target, () => {
            return { Read: false, Edit: false, Delete: false, Export: false };
        });
        const {
            roleOfCurrentUser: { permissions },
        } = data;
        permissions
            .filter(
                p =>
                    p &&
                    p.permission !== Permission.None &&
                    p.target &&
                    !deprecatedTargets.includes(p.target),
            )
            .forEach(p => {
                if (p && p.target && p.permission) {
                    userResult[p.target].Edit =
                        p.permission === Permission.Edit || p.permission === Permission.Delete;
                    userResult[p.target].Read = p.permission !== Permission.None;
                    userResult[p.target].Delete = p.permission === Permission.Delete;
                    userResult[p.target].Export = p.canExport || false;
                }
            });
        const userPermissions = permissions?.map(p => {
            return { target: p?.target, permission: p?.permission };
        });
        const groups = UserPermissions.PermissionsGroupsForUser(userPermissions);
        const groupResult = _.mapValues(TargetGroup, t => {
            return groups.find(g => g === t) !== undefined;
        });

        return {
            loading,
            pagePermissions: userResult,
            groupPermissions: groupResult,
        };
    }
    return { loading };
};

export default useUserPermissions;
