import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';
import {
    ApprovalStatus,
    Language,
    ReadingLevel,
    useArticleGroupsForArticleApprovalPageQuery,
    useArticlesV2ForArticleApprovalPageQuery,
    usePreFetchAppsQuery,
} from '~/schemaTypes';

type ArticleApprovalFilterProps = {
    open: boolean;
    onClose: () => void;
};

export const ArticleApprovalFilter: React.FC<ArticleApprovalFilterProps> = ({ open, onClose }) => {
    const [selectedArticleId, setSelectedArticleId] = useState<string>(
        localStorage.getItem('filteredArticleId') ?? '',
    );
    const [filteredLanguage, setFilteredLanguage] = useState<string>(
        localStorage.getItem('filteredLanguage') ?? '',
    );
    const [filteredReadingLevel, setFilteredReadingLevel] = useState<string>(
        localStorage.getItem('filteredReadingLevel') ?? '',
    );
    const [filteredVersion, setFilteredVersion] = useState<number | null>(
        Number(localStorage.getItem('filteredVersion')) ?? null,
    );
    const [filteredStatus, setFilteredStatus] = useState<string>(
        localStorage.getItem('filteredStatus') ?? '',
    );
    const [filteredApp, setFilteredApp] = useState<string>(
        localStorage.getItem('filteredApp') ?? '',
    );
    const [filteredGroups, setFilteredGroups] = useState<string[]>(
        localStorage
            .getItem('filteredGroups')
            ?.split(',')
            .filter(str => str.length > 1) ?? [],
    );
    const [filteredOperator, setFilteredOperator] = useState<string>(
        localStorage.getItem('filteredOperator') ?? 'OR',
    );

    const { data: articleData, loading: articleLoading } =
        useArticlesV2ForArticleApprovalPageQuery();

    const { data: appList, loading: appListLoading } = usePreFetchAppsQuery();

    const { data: groupList, loading: groupListLoading } =
        useArticleGroupsForArticleApprovalPageQuery();

    const handleSave = () => {
        localStorage.setItem('filteredArticleId', selectedArticleId);
        localStorage.setItem('filteredLanguage', filteredLanguage);
        localStorage.setItem('filteredReadingLevel', filteredReadingLevel);
        localStorage.setItem('filteredVersion', String(filteredVersion));
        localStorage.setItem('filteredStatus', filteredStatus);
        localStorage.setItem('filteredApp', filteredApp);
        localStorage.setItem('filteredGroups', filteredGroups.join(','));
        if (filteredGroups.length > 0) {
            localStorage.setItem('filteredOperator', filteredOperator);
        } else {
            localStorage.removeItem('filteredOperator');
        }

        const filtersAppliedCount =
            (selectedArticleId ? 1 : 0) +
            (filteredLanguage ? 1 : 0) +
            (filteredReadingLevel ? 1 : 0) +
            (filteredVersion ? 1 : 0) +
            (filteredStatus ? 1 : 0) +
            (filteredApp ? 1 : 0) +
            filteredGroups.length;

        localStorage.setItem('filtersAppliedCount', String(filtersAppliedCount));
        onClose();
    };

    const handleClearFilters = () => {
        setSelectedArticleId('');
        setFilteredLanguage('');
        setFilteredReadingLevel('');
        setFilteredVersion(null);
        setFilteredStatus('');
        setFilteredApp('');
        setFilteredGroups([]);
        setFilteredOperator('OR');
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Filter Article Approvals</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Autocomplete
                            style={{ marginTop: '10px' }}
                            options={
                                _.sortBy(appList?.applicationsV2.results, 'appBundleId').map(
                                    a => a.appBundleId,
                                ) ?? []
                            }
                            getOptionLabel={app => app}
                            filterSelectedOptions
                            value={filteredApp as string}
                            onChange={(_, value) => {
                                setFilteredApp(value ?? '');
                            }}
                            renderInput={params => (
                                <TextField
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...params}
                                    label={appListLoading ? 'Loading...' : 'Filter App'}
                                    variant="outlined"
                                />
                            )}
                            disabled={appListLoading}
                        />
                        <Grid item xs={12}>
                            <Autocomplete
                                options={Object.values(ApprovalStatus)}
                                getOptionLabel={option => option}
                                value={filteredStatus}
                                onChange={(event, value) => {
                                    setFilteredStatus(value ?? '');
                                }}
                                renderInput={params => (
                                    <TextField
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label="Filter Status"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={
                                    articleData?.articlesV2.results.map(article => article.id) ?? []
                                }
                                getOptionLabel={article =>
                                    articleData?.articlesV2.results.find(a => a.id === article)
                                        ?.name ?? ''
                                }
                                filterSelectedOptions
                                value={selectedArticleId as string}
                                onChange={(event, value) => {
                                    setSelectedArticleId(value ?? '');
                                }}
                                renderInput={params => (
                                    <TextField
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label={articleLoading ? 'Loading...' : 'Filter Article'}
                                        variant="outlined"
                                    />
                                )}
                                disabled={articleLoading}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            options={Object.values(Language)}
                            getOptionLabel={
                                option => option
                                // languageMap[option as keyof typeof languageMap]
                            }
                            value={filteredLanguage}
                            onChange={(event, value) => {
                                setFilteredLanguage(value ?? '');
                            }}
                            renderInput={params => (
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                <TextField {...params} label="Filter Language" variant="outlined" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            options={Object.values(ReadingLevel)}
                            getOptionLabel={option => option}
                            value={filteredReadingLevel}
                            onChange={(event, value) => {
                                setFilteredReadingLevel(value ?? '');
                            }}
                            renderInput={params => (
                                <TextField
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...params}
                                    label="Filter Reading Level"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Filter Version"
                            variant="outlined"
                            type="number"
                            fullWidth
                            value={filteredVersion}
                            onChange={e => {
                                setFilteredVersion(Number(e.target.value));
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            options={
                                groupList?.articleGroupsV2.results.map(group => group.id) ?? []
                            }
                            getOptionLabel={group =>
                                groupList?.articleGroupsV2.results.find(g => g.id === group)
                                    ?.name ?? ''
                            }
                            filterSelectedOptions
                            value={filteredGroups as string[]}
                            onChange={(event, value) => {
                                setFilteredGroups(value);
                            }}
                            renderInput={params => (
                                <TextField
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...params}
                                    label={groupListLoading ? 'Loading...' : 'Filter Groups'}
                                    variant="outlined"
                                />
                            )}
                            disabled={groupListLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={['AND', 'OR']}
                            defaultValue="OR"
                            getOptionLabel={option => option}
                            value={filteredOperator}
                            onChange={(event, value) => {
                                setFilteredOperator(value ?? '');
                            }}
                            renderInput={params => (
                                <TextField
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...params}
                                    label="Filter Operator"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Button onClick={handleClearFilters}>Clear Filters</Button>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClose();
                    }}
                    color="primary"
                >
                    Close
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
