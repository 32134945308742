import React from 'react';
import { VirtualCareSurvey } from '../VirtualCareSurvey';
import { useInitVCF, VCFContext } from '../hooks/useVCF';

const DOULA_FORM_DESCRIPTION = `
Securely provide your health information below. This will help us match you to
the right resources for you and your health experience. After you complete this
form, you can schedule your appointment. We currently accept Cigna
insurance.`;

const DoulaVCF = () => {
    const contextProps = useInitVCF({
        isDoulaView: true,
        formDescriptionText: DOULA_FORM_DESCRIPTION,
    });
    return (
        <VCFContext.Provider value={contextProps}>
            <VirtualCareSurvey pathname="/care-connect/doula" />
        </VCFContext.Provider>
    );
};

export default DoulaVCF;
