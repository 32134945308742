import { ImageContentItem } from '~/components/ImageContentPicker/types';
import {
    ContentType,
    ImageContentByIdForImageContentPickerQuery,
    ImageContentByTextFilterQuery,
} from '~/schemaTypes';

type SimpleContentById = NonNullable<ImageContentByIdForImageContentPickerQuery['simpleContent']>;
type SimpleContentByTextFilter = NonNullable<
    ImageContentByTextFilterQuery['contentPickerFetch']['data']
>;

type ToImagePickerForm = (
    imageContent: SimpleContentByTextFilter | SimpleContentById[],
) => ImageContentItem[];

const toImagePickerForm: ToImagePickerForm = imageContent => {
    if (imageContent) {
        return imageContent.map(item => ({
            id: item?.id ?? '',
            name: item?.name ?? '',
            content: item?.content ?? '',
            contentType: item?.contentType ?? ContentType.Image,
        }));
    }
    return [];
};

export default toImagePickerForm;
