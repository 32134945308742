import { makeStyles } from 'tss-react/mui';

export default makeStyles()({
    container: {
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '5px 30px',
        maxWidth: '1200px',
        '@media (max-width: 900px)': {
            padding: 0,
            '.MuiOutlinedInput-root, .MuiInputLabel-root, .MuiInput-root': {
                fontSize: '1rem',
            },
            '.MuiTypography-root, .MuiButton-root': {
                fontSize: '.9rem',
            },
            margin: 0,
        },
    },
    form: {
        '@media (min-width: 900px)': {
            padding: '5px 40px',
        },
        padding: '5px 0',
    },
    image: {
        width: '100%',
        height: 'auto',
        '@media (min-width: 600px)': {
            width: '60%',
        },
    },
    imageContainer: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: '30%',
        '@media (max-width: 900px)': {
            maxHeight: '30%',
            padding: '5px 30px',
            '.MuiTypography-root': {
                fontSize: '.7rem',
            },
        },
    },
    errorText: {
        width: '100%',
        marginLeft: '14px',
        marginRight: '14px',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight: '1.66',
        letterSpacing: '0.03333em',
    },
    checkbox: {
        padding: 10,
        '.MuiTypography-root': {
            fontSize: '.9rem',
        },
    },
    confirmationContainer: {
        marginTop: 20,
        marginBottom: 40,
        '@media (max-width: 900px)': {
            '.MuiTypography-h5': {
                fontSize: '1.2rem',
            },
        },
    },
    formContainer: {
        minHeight: '70%',
        padding: '40px 20px 10px 20px',
        '@media (max-width: 900px)': {
            padding: '25px 0 10px 0',
            minHeight: '70%',
        },
    },
    subHeader: {
        paddingTop: '16px !important',
        '@media (max-width: 900px)': {
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '8px !important',
            paddingBottom: '8px !important',
            '.MuiTypography-root': {
                marginBottom: '5px',
                marginTop: '15px',
            },
        },
    },
    agreementContainer: {
        margin: '0 -20px 0 0',
        paddingTop: 20,
        paddingBottom: 3,
        '@media (max-width: 900px)': {
            margin: 0,
            '.MuiTypography-body2, .MuiLink-root': {
                fontSize: '.8rem',
            },
        },
    },
    buttonContainer: {
        margin: '20px -20px',
        padding: '20px 10px',
        button: {
            paddingLeft: '40px',
            paddingRight: '40px',
        },
        '.MuiButton-contained': {
            paddingLeft: '60px',
            paddingRight: '60px',
        },
        '.MuiButton-text': {
            textDecoration: 'underline',
        },
        '@media (max-width: 900px)': {
            margin: 0,
            paddingTop: 30,
        },
    },
    fullHeight: {
        '@media (max-width: 900px)': {
            height: '100%',
        },
    },
    supportBlock: {
        padding: '10px 0',
        '@media (max-width: 900px)': {
            margin: 0,
            '.MuiTypography-body2, .MuiLink-root': {
                fontSize: '.8rem',
            },
        },
    },
    disclaimer: {
        '@media (max-width: 900px)': {
            margin: 0,
            '.MuiTypography-body2, .MuiLink-root': {
                fontSize: '.8rem',
            },
        },
    },
    greenBox: {
        backgroundColor: '#B5D7A8',
        width: '300px',
        textAlign: 'center',
        margin: '0 auto',
        border: '1px solid black',
        padding: '20px',
        marginBottom: '20px',
    },
    thankContainer: {
        textAlign: 'center',
        lineHeight: 1.75,
        button: {
            padding: 0,
        },
        p: {
            minHeight: '1.75rem',
            padding: 0,
            margin: 0,
        },
    },
    hide: {
        display: 'none',
    },
    show: {
        display: 'block',
    },
    toggle: {
        paddingBottom: 10,
        '.MuiFormLabel-root .MuiTypography-root': {
            fontWeight: 'bold',
        },
        '.MuiToggleButtonGroup-root': {
            paddingTop: 5,
            minWidth: 350,
        },
        '.MuiFormHelperText-root': {
            marginLeft: 14,
            marginRight: 14,
        },
    },
    closeButton: {
        position: 'absolute',
        right: '10px',
        top: '10px',
    },
    selectedCard: {
        backgroundColor: '#d2ddf0',
        borderColor: '#385c91',
    },
    list: {
        listStyleType: 'disc',
        paddingLeft: '2px',
        maxWidth: '450px',
        marginLeft: '30px',
        '@media (max-width: 900px)': {
            paddingLeft: '10px',
            marginLeft: 0,
        },
    },
    listItem: {
        display: 'list-item',
        fontSize: '0.875rem',
    },
    cardtitile: {
        '@media (max-width: 900px)': {
            fontSize: '1.2rem',
        },
    },
    subtitle: {
        '@media (max-width: 900px)': {
            fontSize: '1.2rem',
        },
    },
});
