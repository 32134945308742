import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useNavigate, Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Grid, Typography, Button } from '@mui/material';
import { useEligibilityMatchsV2IdsLazyQuery, OrderByDirectionEnum } from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import Header from '../components/Header';

const MatchQueue: React.FC = () => {
    const history = useNavigate();
    const { pathname } = useLocation();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const prevMatchIdFromQuery = useMemo(() => {
        return searchParams.get('prevMatchId');
    }, [searchParams]);
    const [matchStack, setMatchStack] = useState<string[]>([]);
    const { prevMatchId, leftToMatch, nextMatchId } = useMemo(() => {
        const currentIndex = matchStack.findIndex(m => m === id);
        const navigationState = { prevMatchId: '', leftToMatch: 0, nextMatchId: '' };
        navigationState.leftToMatch = matchStack.length - currentIndex;
        navigationState.nextMatchId = matchStack[currentIndex + 1];
        navigationState.prevMatchId = matchStack[currentIndex - 1];
        if (prevMatchIdFromQuery) {
            navigationState.prevMatchId = prevMatchIdFromQuery;
        }
        return navigationState;
    }, [prevMatchIdFromQuery, id, matchStack]);
    const isParent = pathname === '/app-config/eligibility-match/queue';
    const [fetchMatchesQueue, { data: queueItems, loading: fetchMatchesLoading }] =
        useEligibilityMatchsV2IdsLazyQuery({
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
        });
    const firstMatchId = queueItems?.eligibilityMatchsV2.results[0]?.id;
    useEffect(() => {
        fetchMatchesQueue({
            variables: {
                input: {
                    filter: {
                        fields: {
                            toBeReviewed: true,
                        },
                    },
                    orderBy: {
                        field: 'updatedAt',
                        order: OrderByDirectionEnum.Desc,
                    },
                },
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (queueItems?.eligibilityMatchsV2) {
            setMatchStack(queueItems.eligibilityMatchsV2.results.map(i => i.id));
        }
    }, [queueItems]);
    const startMatchingHandler = useCallback(() => {
        history(`/app-config/eligibility-match/queue/${firstMatchId}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstMatchId]);
    if (fetchMatchesLoading) {
        return <Loading />;
    }
    return isParent ? (
        <Grid container rowSpacing={4}>
            <Header lastTitle="Match Queue" lastPath="queue" />
            <Grid item container xs={12} rowSpacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4" textAlign="center">
                        {queueItems?.eligibilityMatchsV2.total} Items in the Match Queue
                    </Typography>
                </Grid>
                {firstMatchId && (
                    <Grid item xs={12} container justifyContent="center">
                        <Grid item xs={4}>
                            <Button variant="outlined" onClick={startMatchingHandler} fullWidth>
                                Start Matching
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    ) : (
        <Outlet context={{ prevMatchId, leftToMatch, nextMatchId }} />
    );
};

export default MatchQueue;
