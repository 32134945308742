import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    ChatSlasV2ForChatSlasPageQuery,
    ResponseCalculationEnum,
    SlaTimerTypeEnum,
    SlaTypeEnum,
    SlasV2ForSlAsPageDocument,
    Target,
    useCalendarsV2ForSlaModalQuery,
    useCareTeamMemberTypesByAppBundleForChatSlaModalLazyQuery,
    useCreateChatSlaForChatSlaModalMutation,
    useFetchAdvocatesForChatSlaModalQuery,
    usePreFetchAppsQuery,
    useUpdateChatSlaForChatSlaModalMutation,
} from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import {
    Autocomplete,
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import _ from 'lodash';
import { lowercaseFirstLetter } from '~/helpers';
import { UNASSIGNED } from '~/views/Dashboard/AdvocateTaskList/AdvocateTaskList';
import { CHAT_SLA_VALIDATION_SCHEMA, ChatSlaFormInput } from './ChatSlaModal.formValidation';

type ChatSlaModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    id?: string;
    defaultSLAValues?: ChatSlaFormInput | null;
};

const ChatSlaModal = ({ setOpen, id, defaultSLAValues }: ChatSlaModalProps) => {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        control,
        formState: { errors },
    } = useForm<ChatSlaFormInput>({
        resolver: yupResolver(CHAT_SLA_VALIDATION_SCHEMA as any),
        defaultValues: {
            ...defaultSLAValues,
        },
    });

    const { data: calendars, loading: loadingCalendars } = useCalendarsV2ForSlaModalQuery();

    const { data: preFetchData, loading: preFetchLoading } = usePreFetchAppsQuery();

    const [
        fetchCareTeamMemberTypes,
        { data: careTeamMemberTypes, loading: loadingCareTeamMemberTypes },
    ] = useCareTeamMemberTypesByAppBundleForChatSlaModalLazyQuery();

    const { data: advocates } = useFetchAdvocatesForChatSlaModalQuery({
        variables: {
            permission: lowercaseFirstLetter(Target.IsHealthAdvocate),
        },
    });

    const [createChatSla, { loading: loadingCreateSla }] = useCreateChatSlaForChatSlaModalMutation({
        update: (cache, response) => {
            const newChatSla = response.data?.createChatSla?.resourceCreated;
            if (response.data?.createChatSla?.success && newChatSla?.id) {
                const getSlasData = cache.readQuery<ChatSlasV2ForChatSlasPageQuery>({
                    query: SlasV2ForSlAsPageDocument,
                });
                const currentSlas = getSlasData?.chatSlasV2.results;
                if (currentSlas) {
                    const newChatSlas = [...currentSlas, newChatSla];
                    cache.writeQuery<ChatSlasV2ForChatSlasPageQuery>({
                        query: SlasV2ForSlAsPageDocument,
                        data: {
                            chatSlasV2: {
                                __typename: getSlasData.chatSlasV2.__typename,
                                results: {
                                    ...newChatSlas,
                                },
                            },
                        },
                    });
                }
            }
        },
    });

    const [updateChatSla, { loading: loadingUpdateSla }] = useUpdateChatSlaForChatSlaModalMutation({
        update: (cache, response) => {
            const updatedChatSla = response.data?.updateChatSla?.resourceUpdated;
            if (response.data?.updateChatSla?.success && updatedChatSla?.id) {
                const getChatSlasData = cache.readQuery<ChatSlasV2ForChatSlasPageQuery>({
                    query: SlasV2ForSlAsPageDocument,
                });
                const currentChatSlas = getChatSlasData?.chatSlasV2.results;
                if (currentChatSlas) {
                    currentChatSlas.map(sla =>
                        sla.id === updatedChatSla.id ? updatedChatSla : sla,
                    );
                    cache.writeQuery<ChatSlasV2ForChatSlasPageQuery>({
                        query: SlasV2ForSlAsPageDocument,
                        data: {
                            chatSlasV2: {
                                __typename: getChatSlasData.chatSlasV2.__typename,
                                results: {
                                    ...currentChatSlas,
                                },
                            },
                        },
                    });
                }
            }
        },
    });

    const onSubmit = (values: ChatSlaFormInput) => {
        const input: ChatSlaFormInput = {
            ...values,
            ...(values.assignedToId === UNASSIGNED ? { assignedToId: null } : {}),
            // notifyBefore: values.notifyBefore?.map(notification => notification.whenToNotify),
        };
        if (id) {
            updateChatSla({
                variables: {
                    input: {
                        id,
                        data: {
                            ...input,
                        },
                    },
                },
            });
        } else {
            createChatSla({
                variables: {
                    input: {
                        ...input,
                        type: SlaTypeEnum.MessageCenter,
                    },
                },
            });
        }
        setOpen(false);
    };

    useEffect(() => {
        if (defaultSLAValues?.appBundleId) {
            fetchCareTeamMemberTypes({
                variables: { input: { appBundleId: defaultSLAValues.appBundleId } },
            });
        }
    }, [defaultSLAValues?.appBundleId, fetchCareTeamMemberTypes]);

    const advocateList = useMemo(() => {
        const data = _.sortBy(advocates?.getUsersByPermission, 'name');
        return [{ id: UNASSIGNED, name: 'Unassigned' }].concat(
            data.map(a => ({ id: a?.id || '', name: a?.name || '' })),
        );
    }, [advocates?.getUsersByPermission]);

    if (loadingCreateSla || loadingUpdateSla || loadingCalendars || preFetchLoading) {
        return <Loading />;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitleWithClose id="chat-sla-modal-title" onClose={() => setOpen(false)}>
                {id ? 'Edit' : 'Add'} Chat Sla Modal
            </DialogTitleWithClose>
            <DialogContent>
                <Grid container>
                    <TextField
                        variant="outlined"
                        label="Name"
                        type="text"
                        margin="dense"
                        fullWidth
                        {...register('name')}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                    />
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Response Calculation</InputLabel>
                            <Controller
                                name="responseCalculation"
                                control={control}
                                defaultValue={defaultSLAValues?.responseCalculation}
                                render={({ field: { value } }) => (
                                    <Select
                                        variant="outlined"
                                        labelId="sla-response-calculation-label"
                                        fullWidth
                                        value={value}
                                        {...register('responseCalculation')}
                                        error={!!errors.responseCalculation}
                                    >
                                        {Object.values(ResponseCalculationEnum).map(type => (
                                            <MenuItem key={type} value={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Timer Type</InputLabel>
                            <Controller
                                name="timerType"
                                control={control}
                                defaultValue={defaultSLAValues?.timerType}
                                render={({ field: { value } }) => (
                                    <Select
                                        variant="outlined"
                                        labelId="sla-timer-type-label"
                                        fullWidth
                                        value={value}
                                        {...register('timerType')}
                                        error={!!errors.timerType}
                                    >
                                        {Object.values(SlaTimerTypeEnum).map(type => (
                                            <MenuItem key={type} value={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            label="Time to complete (measured based on timer type)"
                            type="number"
                            margin="dense"
                            fullWidth
                            {...register('timeToComplete')}
                            error={!!errors.timeToComplete}
                            helperText={errors.timeToComplete?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ReactHookFormSelect
                            control={control}
                            {...register('appBundleId', { required: true })}
                            variant="outlined"
                            defaultValue=""
                            label="App Bundle id"
                            fullWidth
                            margin="dense"
                            error={!!errors.appBundleId}
                        >
                            {_.sortBy(preFetchData?.applicationsV2.results, 'appBundleId').map(
                                ({ id, appBundleId }) => (
                                    <MenuItem
                                        key={id}
                                        value={appBundleId}
                                        onClick={() =>
                                            fetchCareTeamMemberTypes({
                                                variables: {
                                                    input: { appBundleId },
                                                },
                                            })
                                        }
                                    >
                                        {appBundleId}
                                    </MenuItem>
                                ),
                            )}
                        </ReactHookFormSelect>
                    </Grid>
                    <Grid item xs={6}>
                        <ReactHookFormSelect
                            control={control}
                            {...register('careTeamMemberTypeId', { required: true })}
                            variant="outlined"
                            defaultValue=""
                            label="Care Team Member Type"
                            fullWidth
                            margin="dense"
                            error={!!errors.careTeamMemberTypeId}
                        >
                            {!loadingCareTeamMemberTypes &&
                                careTeamMemberTypes?.careTeamMemberTypesByAppBundle?.map(type => (
                                    <MenuItem key={type?.id} value={type?.id}>
                                        {type?.name}
                                    </MenuItem>
                                ))}
                        </ReactHookFormSelect>
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="assignedToId"
                            defaultValue=""
                            control={control}
                            render={() => (
                                <Autocomplete
                                    options={advocateList}
                                    getOptionLabel={option =>
                                        advocateList.find(i => i?.id === option?.id)?.name ?? ''
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option?.id === value?.id
                                    }
                                    value={
                                        advocateList.find(i => i?.id === watch('assignedToId')) ??
                                        null
                                    }
                                    onChange={(event, value) => {
                                        if (value?.id === UNASSIGNED || value?.id == null) {
                                            setValue('assignedToId', UNASSIGNED);
                                        } else {
                                            setValue('assignedToId', value.id);
                                        }
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                            label="Assigned to"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Calendar</InputLabel>
                        <Controller
                            name="calendarId"
                            control={control}
                            defaultValue={defaultSLAValues?.calendarId}
                            render={({ field: { value } }) => (
                                <Select
                                    variant="outlined"
                                    labelId="sla-calendar-id-label"
                                    fullWidth
                                    value={value}
                                    {...register('calendarId')}
                                    error={!!errors.calendarId}
                                >
                                    {calendars?.calendarsV2.results.map(calendar => (
                                        <MenuItem key={calendar.id} value={calendar.id}>
                                            {calendar.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    startIcon={<SaveIcon />}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={loadingCreateSla || loadingUpdateSla}
                >
                    Save
                </Button>
            </DialogActions>
        </form>
    );
};

export default ChatSlaModal;
