export enum RoleEnum {
    NAV_ROLES = 'roles',
    CONTAINER = 'roles-container',
    SELECT_ORGANIZATION = 'select-organization',
    MODAL = 'roles-modal',
    NAME = 'modal-name',
    SAVE = 'modal-save',
}

export const RoleSelectors = {
    NAV_ROLES: `a[data-test="${RoleEnum.NAV_ROLES}"]`,
    ADD_ROLE_BUTTON: `div[data-test="${RoleEnum.CONTAINER}"] button[title="Add Role"]`,
    EDIT_ROLE_BUTTON: `div[data-test="${RoleEnum.CONTAINER}"] button[title="Edit Role"]`,
    SELECT_ORGANIZATION: `select[data-test="${RoleEnum.SELECT_ORGANIZATION}"]`,
    MODAL: `form[data-test="${RoleEnum.MODAL}"]`,
    NAME_INPUT: `div[data-test="${RoleEnum.NAME}"] input`,
    SAVE_BUTTON: `button[data-test="${RoleEnum.SAVE}"]`,
    SEARCH_INPUT: `div[data-test="${RoleEnum.CONTAINER}"] input[placeholder="Search"]`,
    ADMIN_USERS_PERMISSION: 'select[data-test="Admin Users"]',
};
