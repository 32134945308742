import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, MenuItem, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import {
    TimelineViewerEntryType,
    TimeUnit,
    useUpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation,
    useUpdateArticlePromotionForArticlePromotionPageMutation,
    useUpdateCarePlanOffsetsMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { getOffsets, toMinutes } from '~/helpers/minuteConverters';
import { OffsetType, OffsetTypes, TimeUnits } from '~/enums/enums';
import { selectedItem } from '../TimelineViewer';

export interface TimelineFormInput {
    offsetDays: number;
    offsetMonths: number;
    offsetYears: number;
    offsetType: OffsetType;
    durationValue: number;
    durationUnit: TimeUnit;
}

export const ValidationScheme = Yup.object().shape({
    offsetDays: Yup.number().min(0).required(),
    offsetMonths: Yup.number().min(0).required(),
    offsetYears: Yup.number().min(0).required(),
    offsetType: Yup.mixed().oneOf(OffsetTypes).required(),
    durationValue: Yup.number().min(0).required(),
    durationUnit: Yup.mixed().oneOf(TimeUnits),
});

export const toFormValuesFromItem = (item: selectedItem): TimelineFormInput => {
    const offset = getOffsets(item.offsetDays ?? 0, item.offsetMonths ?? 0, item.offsetYears ?? 0);
    return {
        offsetDays: offset.days,
        offsetMonths: offset.months,
        offsetYears: offset.years,
        offsetType: offset.type,
        durationValue: item.duration || 0,
        durationUnit: item.durationTimeUnit,
    };
};

export const EditTimelineModal: React.FC<{
    isOpen: boolean;
    item: selectedItem;
    onSave: () => void;
    onCancel: () => void;
}> = props => {
    const { isOpen, item, onSave, onCancel } = props;
    const history = useNavigate();
    const handleClose = () => {
        onCancel();
    };
    const {
        control,
        register,
        handleSubmit: handleFormSubmit,
        reset,

        formState: { errors },
    } = useForm<TimelineFormInput>({
        resolver: yupResolver(ValidationScheme as any),
    });
    const openEditor = () => {
        switch (item.type) {
            case TimelineViewerEntryType.Promotion:
                history(`/app-config/articlepromotions/${item.itemId}/`);
                break;
            case TimelineViewerEntryType.Hatask:
                history(`/portal/advocate-tasks/${item.itemId}/`);
                break;
            case TimelineViewerEntryType.Highlight:
                history(`/app-config/highlights/${item.itemId}/`);
                break;
            case TimelineViewerEntryType.Todo:
                history(`/app-config/care-plans/${item.itemId}/`);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        reset(toFormValuesFromItem(item));
    }, [reset, item]);

    const [updateArticlePromotion] = useUpdateArticlePromotionForArticlePromotionPageMutation({
        onError: error => {
            TriggerGlobalConfirm({
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                callback: () => {},
                message: `There was a problem saving the article: ${error.message}`,
            });
        },
        onCompleted: () => {
            onSave();
        },
    });
    const [updateHATask] = useUpdateAdvocateTaskTemplateForNewAdvocateTaskTemplatePageMutation({
        onError: error => {
            TriggerGlobalConfirm({
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                callback: () => {},
                message: `There was a problem saving the HA Task Template: ${error.message}`,
            });
        },
        onCompleted: () => {
            onSave();
        },
    });

    const [updateCarePlan] = useUpdateCarePlanOffsetsMutation({
        onError: error => {
            TriggerGlobalConfirm({
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                callback: () => {},
                message: `There was a problem saving the Care Plan: ${error.message}`,
            });
        },
        onCompleted: () => {
            onSave();
        },
    });
    const onSubmit: SubmitHandler<TimelineFormInput> = form => {
        const adjuster = form.offsetType === OffsetType.Before ? -1 : 1;
        const durationMinutes = toMinutes(form.durationValue, form.durationUnit);
        switch (item.type) {
            case TimelineViewerEntryType.Hatask:
                updateHATask({
                    variables: {
                        input: {
                            id: item.itemId,
                            data: {
                                whenTimeline: {
                                    timelineId: item.timelineId,
                                    offsetDays: form.offsetDays * adjuster,
                                    offsetMonths: form.offsetMonths * adjuster,
                                    offsetYears: form.offsetYears * adjuster,
                                    durationMinutes,
                                },
                            },
                        },
                    },
                });
                break;
            case TimelineViewerEntryType.Promotion:
                updateArticlePromotion({
                    variables: {
                        input: {
                            id: item.itemId,
                            data: {
                                offsetDays: form.offsetDays * adjuster,
                                offsetMonths: form.offsetMonths * adjuster,
                                offsetYears: form.offsetYears * adjuster,
                                duration: form.durationValue,
                                durationTimeUnit: form.durationUnit,
                            },
                        },
                    },
                });
                break;
            case TimelineViewerEntryType.Todo:
                if (item.groupId && item.templateId)
                    updateCarePlan({
                        variables: {
                            input: {
                                id: item.itemId,
                                groupId: item.groupId,
                                templateId: item.templateId,
                                offsetDays: form.offsetDays * adjuster,
                                offsetMonths: form.offsetMonths * adjuster,
                                offsetYears: form.offsetYears * adjuster,
                                durationMinutes,
                            },
                        },
                    });
                break;
            default:
                break;
        }
    };
    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose onClose={handleClose} id="modalTitle">
                <div>Edit Timeline Item</div>
            </DialogTitleWithClose>
            <DialogContent>
                <div>
                    <div>
                        {item.type} on {item.timelineName}
                    </div>
                    <div>{item.description}</div>
                    <div>
                        {item.itemId}-{item.groupId}-{item.templateId}
                    </div>
                    <OutlinedSection title="Offset" outlineColor="secondary">
                        <TextField
                            variant="outlined"
                            type="number"
                            name="offsetDays"
                            margin="dense"
                            label="Days *"
                            inputRef={register('offsetDays', { required: true }).ref}
                            required
                            error={Boolean(errors.offsetDays)}
                            helperText={errors.offsetDays?.message}
                        />
                        <TextField
                            variant="outlined"
                            type="number"
                            name="offsetMonths"
                            margin="dense"
                            label="Months *"
                            inputRef={register('offsetMonths', { required: true }).ref}
                            required
                            error={Boolean(errors.offsetMonths)}
                            helperText={errors.offsetMonths?.message}
                        />
                        <TextField
                            variant="outlined"
                            type="number"
                            name="offsetYears"
                            margin="dense"
                            label="Years *"
                            inputRef={register('offsetYears', { required: true }).ref}
                            required
                            error={Boolean(errors.offsetYears)}
                            helperText={errors.offsetYears?.message}
                        />
                        <ReactHookFormSelect
                            control={control}
                            name="offsetType"
                            variant="outlined"
                            defaultValue=""
                            label="Offset Type"
                            margin="dense"
                        >
                            {OffsetTypes.map(u => (
                                <MenuItem key={u} value={u}>
                                    {u}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                    </OutlinedSection>
                    <OutlinedSection title="Duration" outlineColor="secondary">
                        <TextField
                            variant="outlined"
                            type="number"
                            name="durationValue"
                            margin="dense"
                            label="Value *"
                            inputRef={register('durationValue', { required: true }).ref}
                            required
                            error={Boolean(errors.durationValue)}
                            helperText={errors.durationValue?.message}
                        />
                        <ReactHookFormSelect
                            control={control}
                            name="durationUnit"
                            variant="outlined"
                            defaultValue=""
                            label="Time Unit"
                            margin="dense"
                        >
                            {TimeUnits.map(u => (
                                <MenuItem key={u} value={u}>
                                    {u}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                    </OutlinedSection>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={openEditor} title="Open Editor">
                    <FontAwesomeIcon icon={faPenToSquare} />
                    <span style={{ marginLeft: '10px' }}>Open Editor</span>
                </Button>
                <Button onClick={handleClose} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={handleFormSubmit(onSubmit)}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
