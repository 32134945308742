export function isString(value: any): value is string {
    return typeof value === 'string';
}

export function toFormattedDateString(dateString: string): string {
    const displayDate = `${dateString.substring(5, 7)}/${dateString.substring(
        8,
        10,
    )}/${dateString.substring(0, 4)}`;
    return displayDate;
}
