import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    historySection: {
        height: '300px',
        padding: '20px',
    },
    itemsContainer: {
        overflowY: 'auto',
        paddingRight: '40px',
        maxHeight: '100%',
    },
}));
