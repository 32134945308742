import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, Grid, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import {
    FetchProvidersForProvidersPageQueryDocument,
    FetchProvidersForProvidersPageQueryQuery,
    useCreateProviderFromProvidersPageMutation,
    useFetchProviderByIdForProviderModalLazyQuery,
    useUpdateProviderFromProvidersPageMutation,
} from '~/schemaTypes';

const PROVIDER_VALIDATION_SCHEMA = Yup.object().shape({
    externalId: Yup.string(),
    name: Yup.object().shape({
        en: Yup.string().required('Required'),
        es: Yup.string(),
    }),
});

const useStyles = makeStyles()({
    root: {},
});

interface ProviderFormInput {
    name: ProviderName;
    externalId: string;
}

type ProviderModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setEditProviderId: Dispatch<SetStateAction<string>>;
    id?: string;
};

type ProviderName = {
    en: string;
    es: string;
};

const ProviderModal: React.FC<ProviderModalProps> = ({ setOpen, setEditProviderId, id }) => {
    const { classes } = useStyles();
    const {
        reset,
        register,
        handleSubmit,

        formState: { errors },
    } = useForm<ProviderFormInput>({
        resolver: yupResolver(PROVIDER_VALIDATION_SCHEMA as any),
    });

    const [getProvider, { data, loading }] = useFetchProviderByIdForProviderModalLazyQuery({
        onCompleted: data => {
            if (data.provider) {
                reset({
                    externalId: data.provider.externalId ?? '',
                    name: {
                        en: data.provider.name.en,
                        es: data.provider.name.es ?? '',
                    },
                });
            }
        },
    });

    const [updateProvider, { loading: updateProviderLoading }] =
        useUpdateProviderFromProvidersPageMutation({
            onCompleted: data => {
                if (data.updateProvider?.success) {
                    setOpen(false);
                    setEditProviderId('');
                }
            },
        });

    const [createProvider, { loading: createProviderLoading }] =
        useCreateProviderFromProvidersPageMutation({
            onCompleted: data => {
                if (data.createProvider?.success) {
                    setOpen(false);
                }
            },
            update: (cache, response) => {
                if (response.data?.createProvider?.success) {
                    const newProvider = response.data?.createProvider?.resourceCreated;
                    if (newProvider) {
                        const currentData =
                            cache.readQuery<FetchProvidersForProvidersPageQueryQuery>({
                                query: FetchProvidersForProvidersPageQueryDocument,
                            });
                        if (currentData?.providers) {
                            cache.writeQuery<FetchProvidersForProvidersPageQueryQuery>({
                                query: FetchProvidersForProvidersPageQueryDocument,
                                data: {
                                    providers: [newProvider, ...currentData.providers],
                                },
                            });
                        }
                    }
                }
            },
        });

    const onSubmit = ({ externalId, name }: ProviderFormInput) => {
        if (id) {
            updateProvider({
                variables: {
                    input: {
                        id,
                        data: {
                            externalId: externalId ?? '',
                            name: {
                                en: name.en,
                                es: name.es ?? '',
                            },
                        },
                    },
                },
            });
        } else {
            createProvider({
                variables: {
                    input: {
                        externalId: externalId ?? '',
                        name: {
                            en: name.en,
                            es: name.es ?? '',
                        },
                    },
                },
            });
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setEditProviderId('');
    };
    useEffect(() => {
        if (id) {
            getProvider({
                variables: {
                    input: {
                        id,
                    },
                },
            });
        }
    }, [id, getProvider]);

    if (loading) {
        return <Loading subtitle="Loading details..." />;
    }

    if (createProviderLoading) {
        return <Loading subtitle="Creating provider..." />;
    }

    if (updateProviderLoading) {
        return <Loading subtitle="Updating provider..." />;
    }

    const title = data?.provider?.id === undefined ? 'New Provider' : 'Edit Provider';

    return (
        <>
            <DialogTitleWithClose onClose={handleCancel} id={title}>
                {title}
            </DialogTitleWithClose>
            <DialogContent dividers>
                <form className={classes.root} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="External Id"
                                id="externalId"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('externalId')}
                                error={!!errors.externalId}
                                helperText={errors.externalId?.message}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                label="English Name"
                                id="nameEN"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('name.en')}
                                error={!!errors.name?.en}
                                helperText={errors.name?.en?.message}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                label="Spanish Name"
                                id="nameES"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('name.es')}
                                error={!!errors.name?.es}
                                helperText={errors.name?.es?.message}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    onClick={() => handleSubmit(onSubmit)()}
                    variant="contained"
                    color="primary"
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
};

export default ProviderModal;
