/* eslint-disable radix */
import { Tab, Tabs } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    FetchMeasurementsForPatientMeasurementsPageQuery,
    MeasurementType,
    ReferencePages,
    useFetchMeasurementsForPatientMeasurementsPageQuery,
    useFetchReferenceLinksForPatientMeasurementsPageQuery,
} from '~/schemaTypes';
import { BloodGlucoseMeasurementTable } from './BloodGlucose/BloodGlucoseMeasurementTable';
import { BloodPressureMeasurementTable } from './BloodPressure/BloodPressureMeasurementTable';
import { TabPanel } from './components/TabPanel';
import { WeightMeasurementTable } from './Weight/WeightMeasurementTable';
import { MeasurementHistoryModal } from '../../../Measurements/MeasurementHistoryModal';

export type Measurement = NonNullable<
    FetchMeasurementsForPatientMeasurementsPageQuery['patient']
>['measurements'][0];

const useStyles = makeStyles()(_theme => ({
    root: {
        position: 'relative',
        height: '100%',
    },
    searchChips: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
    },
}));

type RouteParams = {
    id: string;
};

type PatientMeasurementsProps = {
    setLinks?: any;
};

const TABS = new Map<MeasurementType, string>([
    [MeasurementType.BloodPressure, 'Blood Pressure'],
    [MeasurementType.BloodGlucose, 'Blood Glucose'],
    [MeasurementType.Weight, 'Weight'],
]);

const PatientMeasurements: React.FC<PatientMeasurementsProps> = ({ setLinks }) => {
    const { classes } = useStyles();
    const [activeTab, setActiveTab] = useState<MeasurementType>(MeasurementType.BloodPressure);
    const { id } = useParams<RouteParams>();
    const [bloodGlucoseTableData, setBloodGlucoseTableData] = useState<Measurement[]>([]);
    const [bloodPressureTableData, setBloodPressureTableData] = useState<Measurement[]>([]);
    const [weightTableData, setWeightTableData] = useState<Measurement[]>([]);
    const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);
    const [measurementHistoryId, setMeasurementHistoryId] = useState<string>('');
    const sortMeasurements = (data: FetchMeasurementsForPatientMeasurementsPageQuery) => {
        const bloodPressureData =
            data?.patient?.measurements.filter(
                measurement => measurement.type === MeasurementType.BloodPressure,
            ) || [];
        setBloodPressureTableData(bloodPressureData);
        const bloodGlucoseData =
            data?.patient?.measurements.filter(
                measurement => measurement.type === MeasurementType.BloodGlucose,
            ) || [];
        setBloodGlucoseTableData(bloodGlucoseData);
        const weightData =
            data?.patient?.measurements.filter(
                measurement => measurement.type === MeasurementType.Weight,
            ) || [];
        setWeightTableData(weightData);
    };
    const { loading: measurementsLoading } = useFetchMeasurementsForPatientMeasurementsPageQuery({
        variables: {
            patientInput: {
                id,
            },
        },
        onCompleted: data => {
            sortMeasurements(data);
        },
    });

    const { data: refereneLinksData, loading: refereneLinksLoading } =
        useFetchReferenceLinksForPatientMeasurementsPageQuery({
            variables: {
                input: {
                    page: ReferencePages.PatientMeasurments,
                },
            },
        });

    useEffect(() => {
        if (refereneLinksData && setLinks) {
            setLinks(refereneLinksData.getReferenceLinksForPage);
        }
    }, [refereneLinksData, setLinks]);

    const handleTabChange = (event: unknown, value: MeasurementType) => {
        setActiveTab(value);
    };

    return (
        <div className={classes.root}>
            <Tabs
                aria-label={`${TABS.get(MeasurementType.BloodPressure)} & ${TABS.get(
                    MeasurementType.Weight,
                )} measurements tabs.`}
                value={activeTab}
                onChange={handleTabChange}
            >
                <Tab
                    label={TABS.get(MeasurementType.BloodPressure)}
                    value={MeasurementType.BloodPressure}
                />
                <Tab label={TABS.get(MeasurementType.Weight)} value={MeasurementType.Weight} />
                <Tab
                    label={TABS.get(MeasurementType.BloodGlucose)}
                    value={MeasurementType.BloodGlucose}
                />
            </Tabs>
            <TabPanel value={activeTab} index={MeasurementType.BloodPressure}>
                <BloodPressureMeasurementTable
                    data={bloodPressureTableData}
                    isLoading={measurementsLoading || refereneLinksLoading}
                    openHistory={setOpenHistoryModal}
                    setHistoryId={setMeasurementHistoryId}
                />
            </TabPanel>
            <TabPanel value={activeTab} index={MeasurementType.Weight}>
                <WeightMeasurementTable
                    data={weightTableData}
                    isLoading={measurementsLoading || refereneLinksLoading}
                    openHistory={setOpenHistoryModal}
                    setHistoryId={setMeasurementHistoryId}
                />
            </TabPanel>
            <TabPanel value={activeTab} index={MeasurementType.BloodGlucose}>
                <BloodGlucoseMeasurementTable
                    data={bloodGlucoseTableData}
                    isLoading={measurementsLoading || refereneLinksLoading}
                    openHistory={setOpenHistoryModal}
                    setHistoryId={setMeasurementHistoryId}
                />
            </TabPanel>
            <MeasurementHistoryModal
                open={openHistoryModal}
                onClose={() => setOpenHistoryModal(false)}
                id={measurementHistoryId}
            />
        </div>
    );
};

export default PatientMeasurements;
