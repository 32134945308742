import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { useCdPickerFetchLazyQuery, useContentDirectoryByIdLazyQuery } from '~/schemaTypes';
import { CarePlanEnum } from '~/selectors';
import { makeStyles } from 'tss-react/mui';

const defaultFormValues = {
    textFilter: '',
    contentDirectory: null,
};

interface ContentDirPickerParams {
    selectedDirId: string;
    setSelectedDirId: (dirId: string) => void;
    setSelectedDirName?: (dirName: string) => void;
    required: boolean;
    disabled?: boolean;
    parentDirId: string;
}

type Dir = {
    name: string | undefined;
    id: string | undefined;
};

interface DirFormInput {
    textFilter: string;
}
const useStyles = makeStyles()({
    resetPadding: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    filterButtonWrapper: {
        marginTop: 10,
    },
    emptyState: {
        fontWeight: 'lighter',
    },
    articleSelect: {
        marginBottom: 10,
    },
    articleTextField: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    error: {
        color: '#e74c3c',
    },
});

const ContentDirectoryPicker: React.FC<ContentDirPickerParams> = ({
    selectedDirId,
    setSelectedDirId,
    setSelectedDirName,
    required,
    disabled = false,
    parentDirId,
}) => {
    const { classes } = useStyles();
    const isEditMode = Boolean(selectedDirId);
    const [itemsNotFound, setItemsNotFound] = useState<boolean>(false);
    const [items, setItems] = useState<Dir[]>([]);
    const [defaultItem, setDefaultItem] = useState<Dir | null>(null);
    const [textFilter, setTextFilter] = useState<string>('');
    const [itemId, setItemId] = useState<string>(selectedDirId);
    const [firstTime, setFirstTime] = useState(true);

    const { control, register, handleSubmit } = useForm({
        defaultValues: defaultFormValues,
    });

    const [fetchCDById, { loading: cdByIdLoading }] = useContentDirectoryByIdLazyQuery({
        onCompleted: ({ contentDirectory }) => {
            if (contentDirectory) {
                if (firstTime) {
                    setItems(() => [contentDirectory]);
                    setDefaultItem(contentDirectory);
                }
                setFirstTime(false);
            }
        },
    });

    const setDefaultItemWrapper = useCallback(
        (selectedItem: Dir | null) => {
            setDefaultItem(selectedItem);
        },
        [setDefaultItem],
    );

    const [itemsFetch, { loading: itemsFetchLoading }] = useCdPickerFetchLazyQuery({
        onCompleted: ({ contentDirectoryPickerFetch: { data } }) => {
            if (data) {
                const sortedItems = _.sortBy(data, 'name').map(i => ({ name: i?.name, id: i?.id }));
                setItemsNotFound(sortedItems.length === 0);
                if (sortedItems && sortedItems.length > 0) {
                    setItems(sortedItems);
                    setDefaultItemWrapper(sortedItems[0]);
                    setSelectedDirId(sortedItems[0].id);
                    setItemId(sortedItems[0].id);
                    if (setSelectedDirName) setSelectedDirName(sortedItems[0].name || '');
                }
            }
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (isEditMode) fetchCDById({ variables: { input: { id: selectedDirId } } });
    }, [selectedDirId, fetchCDById, isEditMode]);

    const handleFilter: SubmitHandler<DirFormInput> = ({ textFilter }) => {
        setItemsNotFound(false);
        itemsFetch({
            variables: {
                input: {
                    searchTerm: textFilter,
                    parentDirectoryId: parentDirId,
                },
            },
        });
    };

    const handleItemChange = (val: { name: string | undefined; id: string } | null) => {
        const cd = items.find(item => item?.id === val?.id);
        setDefaultItemWrapper(cd || null);
        setSelectedDirId(val?.id || '');
        setItemId(val?.id ?? '');
        if (setSelectedDirName) {
            setSelectedDirName(val?.name ?? '');
        }
    };

    if (itemsFetchLoading || cdByIdLoading) {
        return <Loading />;
    }

    return (
        <OutlinedSection title="Select Directory" dataTest={CarePlanEnum.ARTICLE_PICKER}>
            <OutlinedSection title="Filter">
                <form onSubmit={handleSubmit(handleFilter)}>
                    <Grid container>
                        <Grid item container direction="column" xs={12} md={10}>
                            <Grid item container>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        {...register('textFilter')}
                                        label="Text Filter"
                                        fullWidth
                                        margin="dense"
                                        className={classes.resetPadding}
                                        value={textFilter}
                                        onChange={({ target: { value } }) => setTextFilter(value)}
                                    />
                                </Grid>
                                <Grid item className={classes.filterButtonWrapper}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        data-test={CarePlanEnum.FILTER}
                                        onClick={handleSubmit(handleFilter)}
                                    >
                                        Filter
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </OutlinedSection>
            {items.length > 0 && (
                <Controller
                    control={control}
                    name="contentDirectory"
                    render={() => (
                        <Autocomplete
                            disabled={disabled}
                            data-test={CarePlanEnum.SELECT_ARTICLE}
                            className={classes.articleSelect}
                            size="small"
                            value={defaultItem}
                            onChange={(_, val) => {
                                handleItemChange({ name: val?.name, id: val?.id || '' });
                            }}
                            getOptionLabel={selected => selected.name ?? ''}
                            options={items.map(o => ({
                                name: o?.name,
                                id: o?.id,
                            }))}
                            isOptionEqualToValue={(option, val) => option?.id === val?.id}
                            renderInput={params => (
                                <TextField
                                    variant="outlined"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...params}
                                    className={classes.articleTextField}
                                    placeholder="Select Directory"
                                    label="Directory"
                                    InputLabelProps={{ shrink: true }}
                                    error={required && !itemId}
                                />
                            )}
                        />
                    )}
                />
            )}
            {itemsNotFound && (
                <Grid container justifyContent="center" alignItems="center">
                    <Typography className={classes.emptyState}>
                        No Directories found. Please refine your search criteria...
                    </Typography>
                </Grid>
            )}
            {required && !itemId && (
                <Grid container justifyContent="center" alignItems="center">
                    <Typography className={classes.error}>
                        Please search and select a Directory
                    </Typography>
                </Grid>
            )}
        </OutlinedSection>
    );
};
export default ContentDirectoryPicker;
