import { Button, Menu, MenuItem, MenuList } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import React, { useState } from 'react';
import { ExpressionEnum } from '~/selectors';
import { operator, Operators } from '../ConfigExpression/ConfigExpression';

const AddExpressionSelect: React.FC<{
    ruleId: number | undefined;
    addHandler: (op: operator, id: number | undefined) => void;
}> = props => {
    const { addHandler, ruleId } = props;
    const [actionAnchor, setActionAnchor] = useState<HTMLElement | null>(null);
    const actionsMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        setActionAnchor(e.currentTarget);
    };
    const actionsMenuClose = () => {
        setActionAnchor(null);
    };
    const itemSelected = (op: operator) => {
        setActionAnchor(null);
        addHandler(op, ruleId);
    };

    return (
        <span>
            <Button
                id="basic-button"
                aria-haspopup="true"
                onClick={actionsMenuOpen}
                endIcon={<ExpandMore />}
                data-test={ExpressionEnum.RULES_ADD_ITEM_BUTTON}
            >
                Add Item
            </Button>
            <Menu
                anchorEl={actionAnchor}
                open={Boolean(actionAnchor)}
                onClose={actionsMenuClose}
                elevation={1}
                transformOrigin={{
                    vertical: -80,
                    horizontal: 0,
                }}
            >
                <MenuList dense>
                    {Operators.map(({ value, title }) => (
                        <MenuItem key={value} onClick={() => itemSelected(value)} data-test={title}>
                            {title}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </span>
    );
};

export default AddExpressionSelect;
