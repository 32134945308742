import { OnboardingStatus, OnSuccessfulEligibility } from '~/schemaTypes';
import { FormInput as LactationConsultantFormInput } from '~/views/AffiliateCare/components/ConsultantForm/ConsultantForm';
import { OfficeAddress } from '~/views/AffiliateCare/components/OfficeAddresses/OfficeAddresses';
import { CoverageArea } from '~/views/AffiliateCare/components/CoverageAreas/CoverageAreas';
import { FormInput as BillDotComFormInput } from './steps/Payment/types';

export enum Steps {
    Consultants,
    Payment,
    ServiceAndLocations,
    Routing,
}

export type FormInput = {
    internalName: string;
    externalName: string;
    contactName?: string | null;
    contactEmail?: string | null;
    contactPhoneNumber?: string | null;
    supportEmail?: string | null;
    supportPhone?: string | null;
    onSuccessfulEligibility?: OnSuccessfulEligibility | null;
    routingURL?: string | null;
    phoneNumberForScheduling?: string | null;
    emailAddressForScheduling?: string | null;
    customSuccessText?: string | null;
    notifyCompanyWhenPatientPassesEligibility?: boolean | null;
    BillDotComVendorId?: string | null;
    website?: string | null;
    inviteAffiliate?: boolean | null;
    id: string;
    affiliateId?: string | null;
    onboardingStatus?: OnboardingStatus | null;
    lactationConsultants: LactationConsultantFormInput[];
    billDotComInitData: BillDotComFormInput;
    isVirtualType: boolean;
    isInOfficeType: boolean;
    isPatientsHomeType: boolean;
    officeAddresses: OfficeAddress[];
    coverageAreas: CoverageArea[];
};
