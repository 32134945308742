/* eslint-disable react/jsx-props-no-spreading */
import { DevTool } from '@hookform/devtools';
import { Divider, Grid, GridSize, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, {
    forwardRef,
    ReactElement,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import {
    Control,
    FieldValues,
    FormProvider,
    SubmitHandler,
    useForm,
    useFormContext,
} from 'react-hook-form';
import { deriveNestedPropertyPath } from '~/helpers/stringHelpers';
import ArrayInput from './ArrayInput/ArrayInput';
import FormInput, { FormBuilderInputType } from './FormInput/FormInput';

// Redeclare forwardRef
declare module 'react' {
    // eslint-disable-next-line @typescript-eslint/ban-types
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

const useStyles = makeStyles()({
    root: {},
    nestedForm: {
        position: 'relative',
        padding: 5,
        paddingLeft: 30,
        '&:after': {
            content: '""',
            position: 'absolute',
            width: 2,
            height: 'calc(100% + 8px)',
            backgroundColor: 'lightgray',
            top: -4,
            left: 0,
        },
    },
    nestedFormWithLine: {
        position: 'relative',
        padding: 5,
        paddingLeft: 30,
        '&:after': {
            content: '""',
            position: 'absolute',
            width: 2,
            height: 'calc(100% + 8px)',
            backgroundColor: 'lightgray',
            top: -4,
            left: 0,
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: 30,
            height: 2,
            backgroundColor: 'lightgray',
            top: '50%',
            left: 0,
        },
    },
    nestedTitle: {
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            width: 20,
            height: 2,
            backgroundColor: 'lightgray',
            top: '50%',
            left: -30,
        },
    },
});

export type ValuesArray = {
    label: string;
    value: any;
};

export type WatchFieldHandlerProps = {
    value: any;
    hide: () => void;
    show: () => void;
    setSelectorValues?: (v: any) => void;
};

export type FormBuilderFormField = {
    id?: string;
    label: string;
    name: string;
    array?: boolean;
    type: FormBuilderInputType;
    defaultValue?: any;
    values?: ValuesArray[];
    showSeconds?: boolean;
    valueAsNumber?: boolean;
    onChangeHandler?: (date: any) => void;
    date?: string;
    options?: {
        required?: boolean;
        disabled?: boolean;
    };
    watch?: {
        field: string;
        handler: ({ value, hide }: WatchFieldHandlerProps) => Promise<void> | void;
    };
    fields?: Array<FormBuilderFormField>;
};

export type FormBuilderRef = {
    triggerSubmit: () => void;
    getValues: () => any;
};

type FormBuilderNestedProps = {
    nestedName?: string;
    fieldProps?: FormBuilderFormField;
};

export type FormBuilderProps<T extends FieldValues> = {
    title?: string;
    fields: Array<FormBuilderFormField>;
    nested?: FormBuilderNestedProps;
    // validationSchema?: any;
    data?: T;
    handleSubmit: (values: T) => void;
    enableDevTools?: boolean;
    level?: number;
    setWatchedFields?: React.Dispatch<React.SetStateAction<any>>;
};

const FormBuilder = <T extends Record<string, any>>(
    {
        title,
        fields,
        nested,
        handleSubmit,
        // validationSchema,
        enableDevTools = false,
        data,
        level = 0,
    }: FormBuilderProps<T>,
    ref: React.Ref<FormBuilderRef>,
): ReactElement | null => {
    const { classes } = useStyles();
    const formContext = useFormContext();
    const formHookMethods = useForm<T>({
        defaultValues: data as any,
        shouldUnregister: false,
        // resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    });

    // Prioritize context over local state
    const formMethods = formContext ?? formHookMethods;

    const onSubmit = useCallback<SubmitHandler<FieldValues>>(
        data => {
            const transformedData = fields.reduce<T>((acc, field) => {
                if (field.array) {
                    const values = data[field.name];
                    if (values) {
                        acc[field.name as keyof T] = values.map((arrayItem: any) => {
                            if (arrayItem.value) {
                                return arrayItem.value;
                            }
                            return arrayItem;
                        });
                    }
                } else {
                    acc[field.name as keyof T] = data[field.name];
                }
                return acc;
            }, {} as T);
            handleSubmit(transformedData);
        },
        [fields, handleSubmit],
    );

    useImperativeHandle(
        ref,
        () => ({
            triggerSubmit: () => {
                formMethods.handleSubmit(onSubmit)();
            },
            getValues: formMethods.getValues,
        }),
        [formMethods, onSubmit],
    );

    return (
        <div className={classes.root}>
            {nested ? (
                <FormBuilderFields
                    title={title}
                    fields={fields}
                    enableDevTools={enableDevTools}
                    level={level}
                    nested={{
                        nestedName: nested.nestedName,
                        fieldProps: nested.fieldProps,
                    }}
                />
            ) : (
                <FormProvider {...formMethods}>
                    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                        <FormBuilderFields
                            title={title}
                            fields={fields}
                            enableDevTools={enableDevTools}
                            level={level}
                        />
                    </form>
                </FormProvider>
            )}
        </div>
    );
};

type FormBuilderFieldsProps = {
    title?: string;
    fields: Array<FormBuilderFormField>;
    nested?: FormBuilderNestedProps;
    enableDevTools?: boolean;
    level?: number;
};

function FormBuilderFields({
    title,
    fields,
    enableDevTools = false,
    level = 0,
    nested,
}: FormBuilderFieldsProps): JSX.Element {
    const { classes } = useStyles();

    const [hidden, setHidden] = useState<boolean>(false);

    const { control, watch, unregister } = useFormContext();
    const { nestedName } = nested || {};

    const ForwardReffedFormBuilder = useMemo(() => forwardRef(FormBuilder), []);

    const deriveWatchedFieldName = (field: string): string => {
        if (field.includes('&') && nested?.nestedName) {
            return deriveNestedPropertyPath(field, nested?.nestedName);
        }
        return field;
    };

    const watchField = nested?.fieldProps?.watch;
    let watchResult: any;
    if (watchField?.field) {
        watchResult = watch(deriveWatchedFieldName(watchField.field));
    }

    useEffect(() => {
        const hideInput = () => {
            if (nested?.fieldProps?.name) {
                setHidden(true);
                unregister(nested.fieldProps.name);
            }
        };
        const showInput = () => {
            setHidden(false);
        };
        if (watchField?.handler) {
            watchField?.handler({
                value: watchResult,
                hide: hideInput,
                show: showInput,
            });
        }
    }, [nested, unregister, watchField, watchResult]);

    if (hidden) {
        return <div />;
    }

    return (
        <>
            {title && (
                <>
                    <Typography
                        className={level > 1 && nestedName ? classes.nestedTitle : ''}
                        variant="h6"
                        paragraph
                    >
                        {title}
                    </Typography>
                    {level === 0 && <Divider style={{ marginBottom: 20 }} />}
                </>
            )}
            <Grid container spacing={1} wrap="wrap" alignItems="center">
                {fields.map((field, i) => {
                    const {
                        label,
                        name: baseName,
                        type,
                        array = false,
                        fields: subFields,
                        id: fieldId,
                        defaultValue,
                    } = field;

                    const name = nested?.nestedName ? `${nestedName}.${baseName}` : baseName;
                    const isOdd = (i + 1) % 2 === 1;
                    const isLast = i === fields.length - 1;
                    const isOddAndLast = isOdd && isLast;
                    const sizing: Record<string, GridSize> = {
                        xs: 12,
                        ...(type === 'textarea' ||
                        type === 'object' ||
                        array ||
                        nestedName ||
                        isOddAndLast
                            ? {}
                            : { md: 6 }),
                    };

                    return (
                        <Grid key={fieldId ?? name} item {...sizing}>
                            {array && (
                                <div
                                    className={
                                        level !== 0 && !hidden ? classes.nestedFormWithLine : ''
                                    }
                                >
                                    <ArrayInput field={{ ...field, name }} />
                                </div>
                            )}
                            {!array && !subFields && (
                                <div
                                    className={
                                        level !== 0 && !hidden ? classes.nestedFormWithLine : ''
                                    }
                                >
                                    <FormInput field={{ ...field, name }} />
                                </div>
                            )}
                            {subFields && !array && (
                                <div className={level !== 0 ? classes.nestedForm : ''}>
                                    <ForwardReffedFormBuilder
                                        title={label}
                                        nested={{
                                            nestedName: name,
                                            fieldProps: field,
                                        }}
                                        fields={
                                            defaultValue
                                                ? subFields.map(field => ({
                                                      ...field,
                                                      defaultValue: defaultValue[field.name],
                                                  }))
                                                : subFields
                                        }
                                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                                        handleSubmit={() => {}}
                                        level={level + 1}
                                    />
                                </div>
                            )}
                        </Grid>
                    );
                })}
            </Grid>
            {!nestedName && enableDevTools && process.env.NODE_ENV !== 'production' && (
                <DevTool control={control as Control} />
            )}
        </>
    );
}

export default forwardRef(FormBuilder);
