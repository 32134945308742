import React from 'react';
import { Button, Divider, FormControl, Typography } from '@mui/material';
import { VCAffiliateForm } from '~/views/ConfigDashboard/VCAffiliates/components/VCAffiliateForm';
import { VCAffiliateFormContextProvider } from '~/views/ConfigDashboard/VCAffiliates/components/VCAffiliateFormContextProvider';
import { useNavigate } from 'react-router-dom';
import {
    AlertSeverity,
    CreateVirtualCareAffiliatesInput,
    useCreateVirtualCareAffiliateMutation,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import { BackToVCAffiliatesList } from '~/views/ConfigDashboard/VCAffiliates/components/BackToVCAffiliatesList';

export const VCAffiliatesAddNew = () => {
    const navigate = useNavigate();
    const handleCancel = () => {
        navigate('/app-config/vcaffiliates');
    };
    const [createVirtualCareAffiliate] = useCreateVirtualCareAffiliateMutation({
        onCompleted: data => {
            if (data.createVirtualCareAffiliate?.success) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'New affiliate created',
                });
                navigate(`/app-config/vcaffiliates/${data.createVirtualCareAffiliate?.data?.id}`);
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'New affiliate creation failed',
                });
            }
        },
    });
    const handleSubmit = (data: CreateVirtualCareAffiliatesInput) => {
        createVirtualCareAffiliate({ variables: { input: { data } } });
    };
    return (
        <>
            <BackToVCAffiliatesList />
            <Typography variant="h6" className="title" paddingLeft="10px">
                Add VC Affiliate
            </Typography>
            <Divider style={{ marginTop: '10px', marginBottom: '20px' }} />
            <VCAffiliateFormContextProvider>
                <VCAffiliateForm handleFormSubmit={handleSubmit}>
                    <FormControl>
                        <Button variant="outlined" onClick={handleCancel}>
                            Cancel
                        </Button>
                    </FormControl>
                    <FormControl>
                        <Button type="submit" color="secondary" variant="contained">
                            Add
                        </Button>
                    </FormControl>
                </VCAffiliateForm>
            </VCAffiliateFormContextProvider>
        </>
    );
};
