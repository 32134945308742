import React, { useState } from 'react';
import { TextField, Box, InputLabelProps, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Path, UseFormRegister } from 'react-hook-form';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';

const useStyles = makeStyles()({
    inputField: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconButton: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        background: 'inherit',
        cursor: 'pointer',
    },
});

type PasswordInputProps<FormValues extends Record<string, unknown>> = {
    error: boolean;
    helperText?: string;
    register: UseFormRegister<FormValues>;
    name: Path<FormValues>;
    label: string;
    inputLabelProps?: InputLabelProps;
};

const PasswordInput = <FormValues extends Record<string, unknown>>(
    props: PasswordInputProps<FormValues>,
) => {
    const { error, helperText, register, name, label, inputLabelProps } = props;
    const { classes } = useStyles();
    const [visible, setVisible] = useState(false);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const handleClickShowPassword = () => {
        setVisible(prev => !prev);
    };
    return (
        <TextField
            variant="outlined"
            label={label}
            type={visible ? 'text' : 'password'}
            fullWidth
            error={error}
            helperText={helperText}
            InputLabelProps={inputLabelProps}
            InputProps={{
                endAdornment: (
                    <Box className={classes.inputField}>
                        <IconButton
                            className={classes.iconButton}
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseUpPassword}
                            edge="end"
                        >
                            {visible ? (
                                <VisibilityOffOutlined fontSize="small" />
                            ) : (
                                <VisibilityOutlined fontSize="small" />
                            )}
                        </IconButton>
                    </Box>
                ),
            }}
            {...register(name)}
        />
    );
};

export default PasswordInput;
