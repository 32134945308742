import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    useAddToPatientListMutation,
    useFetchPatientForAddPatientToListLazyQuery,
} from '~/schemaTypes';
import ObjectId from 'bson-objectid';

export const AddPatientModal: React.FC<{
    isOpen: boolean;
    listId: string;
    addPatient: (id: string) => void;
    onClose: () => void;
}> = props => {
    const { isOpen, onClose, addPatient, listId } = props;
    const [patientId, setPatientId] = useState('');
    const [error, setError] = useState(false);
    const [getPatient, { data: patient, loading: patientLoading }] =
        useFetchPatientForAddPatientToListLazyQuery({
            onCompleted: () => {
                setError(false);
            },
            onError: () => {
                setError(true);
            },
        });

    const [updateList, { loading: updateListLoading }] = useAddToPatientListMutation({
        onCompleted: () => {
            addPatient(patientId);
            setPatientId('');
            setError(false);
        },
    });

    useEffect(() => {
        if (patientId) {
            if (!patient) {
                setError(true);
            } else {
                updateList({
                    variables: {
                        input: {
                            id: listId,
                            patientIds: [ObjectId.createFromHexString(patientId)],
                        },
                    },
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addPatient, patient]);
    const patientChanged = (event: any) => {
        setError(false);
        setPatientId(event.target.value);
    };
    const handleAdd = () => {
        if (patientId.length !== 24) {
            setError(true);
            return;
        }
        getPatient({ variables: { input: { id: patientId } } });
    };
    const handleClose = () => {
        setError(false);
        onClose();
    };
    if (patientLoading || updateListLoading) return <Loading />;
    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose id="dialogTitle" onClose={handleClose}>
                Add Patient to List
            </DialogTitleWithClose>
            <DialogContent>
                <OutlinedSection title="Patient Id">
                    <TextField
                        variant="outlined"
                        type="text"
                        fullWidth
                        margin="dense"
                        onChange={patientChanged}
                    />
                    {error && (
                        <div style={{ color: 'red', fontWeight: 'bold' }}>
                            Patient Id not valid or not found
                        </div>
                    )}
                </OutlinedSection>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAdd} color="secondary" variant="contained">
                    Add Patient
                </Button>
                <Button onClick={handleClose} color="secondary" variant="contained">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
