const wfhEnv = (() => {
    switch (window.location.hostname) {
        case 'portal.wildflowerhealth.net':
            return 'prod';
        case 'portal.uat.wildflowerhealth.net':
            return 'uat';
        case 'portal.iat.wildflowerhealth.net':
            return 'iat';
        case 'portal.stage.wildflowerhealth.net':
            return 'stage';
        case 'portal-main.dev.wildflowerhealth.net':
            return 'dev';
        default:
            return 'local';
    }
})();

export type WfhEnv = typeof wfhEnv;

export const settings = {
    wfhEnv: wfhEnv as WfhEnv,
    aws: {
        region: (() => {
            switch (wfhEnv) {
                case 'prod':
                case 'uat':
                    return 'us-west-2';
                default:
                    return 'us-east-1';
            }
        })(),
        cognito: {
            clientId: (() => {
                switch (wfhEnv) {
                    case 'prod':
                    case 'uat':
                        return 'js6etnktfjncphhg350crcu3';
                    default:
                        return '40qk3nv2tmt7na02krahpu1867';
                }
            })(),
            userPoolId: (() => {
                switch (wfhEnv) {
                    case 'prod':
                    case 'uat':
                        return 'us-west-2_QYM5BK9MU';
                    default:
                        return 'us-east-1_01Lex5CI8';
                }
            })(),
        },
    },
} as const;
