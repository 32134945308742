import { Dialog, DialogContent, IconButton, Tooltip, Typography } from '@mui/material';
import { Info, InfoOutlined, QuestionAnswer, Warning } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { combineSurveyResponses, displayDateWithAbbrTimeZone, shouldFlagSurvey } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import { useQueryParams } from '~/hooks';
import useUserPermissions from '~/hooks/useUserPermissions';
import {
    AlertSeverity,
    FetchSurveyResponsesForPatientSurveyPageQuery,
    ReferencePages,
    useFetchSurveyResponsesForPatientSurveyPageQuery,
    useFetchReferenceLinksForPatientSurveysPageQuery,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import PostpartumModalInfo from '~/views/Dashboard/Survey/PostpartumModalInfo/PostpartumModalInfo';
import SurveyResults from '~/views/Dashboard/Survey/SurveyResults/SurveyResults';

type SurveyResponse = NonNullable<
    FetchSurveyResponsesForPatientSurveyPageQuery['patient']
>['surveyResponses'][0];

type RouteParams = {
    id: string;
};

type PatientSurveyResponsesProps = {
    setLinks?: any;
};

const PatientSurveyResponses: React.FC<PatientSurveyResponsesProps> = ({ setLinks }) => {
    const [query] = useQueryParams();
    const location = useLocation();
    const { id } = useParams<RouteParams>();
    const [search, setSearch] = useState<string>(query.get('_search') ?? '');
    const history = useNavigate();
    const [showPostpartumInfo, setShowPostpartumInfo] = useState<boolean>(false);
    const [showSurveyAnswers, setShowSurveyAnswers] = useState<boolean>(false);
    const [surveyData, setSurveyData] = useState<SurveyResponse[]>([]);
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const { loading: surveyDataLoading } = useFetchSurveyResponsesForPatientSurveyPageQuery({
        variables: {
            patientInput: {
                id,
            },
        },
        onCompleted: data => {
            if (data?.patient?.surveyResponses) {
                const combinedSurveyResponses = combineSurveyResponses(
                    data?.patient?.surveyResponses,
                );
                setSurveyData(combinedSurveyResponses);
            }
        },
    });

    const { data: refereneLinksData, loading: refereneLinksLoading } =
        useFetchReferenceLinksForPatientSurveysPageQuery({
            variables: {
                input: {
                    page: ReferencePages.PatientSurveyResponses,
                },
            },
        });

    useEffect(() => {
        if (refereneLinksData && setLinks) {
            setLinks(refereneLinksData.getReferenceLinksForPage);
        }
    }, [refereneLinksData, setLinks]);

    const [viewSurvey, setViewSurvey] = useState<SurveyResponse>();

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div ref={tableRef}>
            <MaterialTable
                isLoading={surveyDataLoading || refereneLinksLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                actions={[
                    {
                        onClick: (_, survey: any) => {
                            setViewSurvey(survey);
                            setShowSurveyAnswers(true);
                        },
                        hidden: false,
                        tooltip: 'Survey Answers',
                        icon: () => <QuestionAnswer />,
                    },
                    rowData => ({
                        icon: () => <Info />,
                        onClick: () => {
                            setShowPostpartumInfo(true);
                        },
                        tooltip: 'Survey Info',
                        disabled: rowData.surveyType !== 'Postpartum',
                        hidden: rowData.surveyType !== 'Postpartum',
                    }),
                ]}
                columns={[
                    {
                        title: 'Id',
                        field: 'id',
                        align: 'center',
                        sorting: false,
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Survey Id Link Copied to Clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'External Id',
                        field: 'externalId',
                        align: 'center',
                        sorting: false,
                        render: ({ patient }) => {
                            if (patient?.externalId) {
                                return (
                                    <Tooltip title={patient?.externalId ?? ''}>
                                        <IconButton
                                            onClick={() => {
                                                copy(`${patient?.externalId}`);
                                                TriggerGlobalAlert({
                                                    message: 'External Id Copied to Clipboard',
                                                    severity: AlertSeverity.Success,
                                                });
                                            }}
                                            size="large"
                                        >
                                            <InfoOutlined />
                                        </IconButton>
                                    </Tooltip>
                                );
                            }
                            return '';
                        },
                    },
                    { title: 'Practice', field: 'practice.brandingName' },
                    { title: 'Display Name', field: 'displayName' },
                    {
                        title: 'Flagged',
                        render: ({ isFlagged }) => {
                            if (isFlagged) {
                                return <Warning />;
                            }
                            return <div />;
                        },
                        customSort: (a, b) => Number(a.isFlagged) - Number(b.isFlagged),
                    },
                    {
                        title: 'Response Date',
                        render: ({ responseDate }) => (
                            <Typography>
                                {displayDateWithAbbrTimeZone({
                                    isoDateStr: new Date(responseDate).toISOString(),
                                    format: 'MM/DD/yyyy, hh:mm:ss A',
                                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                })}
                            </Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.responseDate ?? '').getTime() -
                            new Date(b.responseDate ?? '').getTime(),
                        defaultSort: 'desc',
                    },
                    { title: 'Score', field: 'surveyScore' },
                ]}
                data={_.cloneDeep(surveyData).map(survey => ({
                    ...survey,
                    isFlagged: shouldFlagSurvey(survey),
                }))}
                title="Surveys"
                localization={{ header: { actions: '' } }}
                onSearchChange={searchText => {
                    setSearch(searchText);
                    history(`${location.pathname}${searchText && `?_search=${searchText}`}`);
                }}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    searchText: search ?? '',
                    exportAllData: pagePermissions?.SurveyResponses.Export,
                }}
            />
            <Dialog fullWidth maxWidth="lg" open={showPostpartumInfo || showSurveyAnswers}>
                <DialogTitleWithClose
                    id="modalTitle"
                    onClose={() => {
                        setShowPostpartumInfo(false);
                        setShowSurveyAnswers(false);
                    }}
                >
                    {viewSurvey?.displayName} Answers
                </DialogTitleWithClose>
                <DialogContent>
                    {showPostpartumInfo && <PostpartumModalInfo />}
                    {showSurveyAnswers && (
                        <SurveyResults
                            survey={viewSurvey}
                            results={viewSurvey?.answers || []}
                            score={viewSurvey?.surveyScore}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default PatientSurveyResponses;
