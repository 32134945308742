import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { Save } from '@mui/icons-material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { useUserPermissions } from '~/hooks';
import {
    FetchRolesForRolesPageQueryDocument,
    FetchRolesForRolesPageQueryQuery,
    Permission,
    PermissionsInput,
    Target,
    useCreateRoleForRoleModalMutation,
    useFetchRoleByIdForRoleModalLazyQuery,
    useUpdateRoleForRoleModalMutation,
} from '~/schemaTypes';
import { RoleEnum } from '~/selectors';
import { GroupedPermission, UserPermissions } from '~/utils/UserPermissions';
import { deprecatedTargets } from '~/hooks/useUserPermissions';

const ROLE_VALIDATION_SCHEMA = Yup.object({
    name: Yup.string().required('Required'),
});

interface RoleFormInput {
    name: string;
    claims: { doesInherit: boolean; permission: string; target: string }[];
}

type RoleModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setEditRoleId: Dispatch<SetStateAction<string>>;
    id?: string;
    orgId?: string;
};

const RoleModal: React.FC<RoleModalProps> = ({ setOpen, setEditRoleId, id, orgId }) => {
    const { pagePermissions } = useUserPermissions();
    const [permissions, setPermissions] = useState<PermissionsInput[]>([]);
    const [groupList, setGroupList] = useState<GroupedPermission[]>([]);
    const {
        register,
        handleSubmit,
        reset,

        formState: { errors },
    } = useForm<RoleFormInput>({
        resolver: yupResolver(ROLE_VALIDATION_SCHEMA as any),
    });
    const [getRole, { data: roleQuery, loading }] = useFetchRoleByIdForRoleModalLazyQuery({
        onCompleted: data => {
            if (data.role) {
                reset({
                    name: data.role.name,
                });
                if (data.role.permissions)
                    setPermissions(
                        data.role.permissions
                            .filter(p => p?.target && !deprecatedTargets.includes(p.target))
                            .map(p => {
                                return {
                                    target: p?.target,
                                    permission: p?.permission,
                                    canExport: p?.canExport,
                                };
                            }),
                    );
            }
        },
    });
    let title = roleQuery?.role?.id === undefined ? 'New Role' : 'Edit Role';
    if (!orgId) title += '(Template)';

    const [updateRole, { loading: updateRoleLoading }] = useUpdateRoleForRoleModalMutation({
        onCompleted: data => {
            if (data.updateRole?.success) {
                setOpen(false);
            }
        },
    });

    const [createRole, { loading: createRoleLoading }] = useCreateRoleForRoleModalMutation({
        onCompleted: data => {
            if (data.createRole?.success) {
                setOpen(false);
            }
        },
        update: (cache, response) => {
            if (response.data?.createRole?.success) {
                const newRole = response.data?.createRole?.resourceCreated;
                if (newRole) {
                    const currentData = cache.readQuery<FetchRolesForRolesPageQueryQuery>({
                        query: FetchRolesForRolesPageQueryDocument,
                    });
                    if (currentData?.roles) {
                        cache.writeQuery<FetchRolesForRolesPageQueryQuery>({
                            query: FetchRolesForRolesPageQueryDocument,
                            data: {
                                roles: [newRole, ...currentData.roles],
                            },
                        });
                    }
                }
            }
        },
    });

    const onSubmit = ({ name }: RoleFormInput) => {
        if (id) {
            updateRole({
                variables: {
                    input: {
                        id,
                        data: {
                            name,
                            permissions,
                        },
                    },
                },
            });
        } else {
            createRole({
                variables: {
                    input: {
                        name,
                        organizationId: orgId,
                        isTemplate: orgId === null,
                        permissions,
                    },
                },
            });
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setEditRoleId('');
    };

    const permissionChanged = (permission: Permission, target: Target) => {
        if (permissions === undefined) {
            setPermissions([{ target, permission, canExport: false }]);
            setGroupList(
                UserPermissions.PermissionsGroupsForRole(
                    [{ target, permission, canExport: false }],
                    orgId,
                ),
            );
        } else {
            const perm = permissions.find(p => p.target === target);
            if (perm) {
                perm.permission = permission;
                if (permission === Permission.None) perm.canExport = false;
            } else {
                permissions.push({ target, permission, canExport: false });
            }
            setPermissions(permissions);
            setGroupList(UserPermissions.PermissionsGroupsForRole(permissions, orgId));
        }
    };
    const exportChanged = (checked: boolean, target: Target) => {
        if (permissions === undefined) {
            setPermissions([{ target, permission: Permission.None, canExport: checked }]);
            setGroupList(
                UserPermissions.PermissionsGroupsForRole(
                    [{ target, permission: Permission.None, canExport: checked }],
                    orgId,
                ),
            );
        } else {
            const perm = permissions.find(p => p.target === target);
            if (perm) perm.canExport = checked;
            else {
                permissions.push({ target, permission: Permission.None, canExport: checked });
            }
            setPermissions(permissions);
            setGroupList(UserPermissions.PermissionsGroupsForRole(permissions, orgId));
        }
    };

    useEffect(() => {
        if (id) {
            getRole({
                variables: {
                    input: {
                        id,
                    },
                },
            });
        }
    }, [id, getRole]);
    useEffect(() => {
        setGroupList(UserPermissions.PermissionsGroupsForRole(permissions, orgId));
    }, [permissions, orgId]);
    if (loading || updateRoleLoading || createRoleLoading) {
        return <Loading />;
    }
    return (
        <>
            <DialogTitleWithClose id="edit-role-modal" onClose={() => setOpen(false)}>
                {pagePermissions?.Roles.Edit ? title : 'Role Details'}
            </DialogTitleWithClose>
            <DialogContent dividers className="formCont">
                <form noValidate onSubmit={handleSubmit(onSubmit)} data-test={RoleEnum.MODAL}>
                    <TextField
                        variant="outlined"
                        label="Name"
                        id="name"
                        type="text"
                        margin="dense"
                        fullWidth
                        {...register('name')}
                        style={{ padding: 0, marginBottom: 20 }}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        disabled={!pagePermissions?.Roles.Edit}
                        data-test={RoleEnum.NAME}
                    />
                    <OutlinedSection title="Permissions">
                        <Table stickyHeader padding="checkbox">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" style={{ fontWeight: 'bold' }}>
                                        Admin Section &amp; Page
                                    </TableCell>
                                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                        Permission
                                    </TableCell>
                                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                        Export
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groupList.map(p => (
                                    <>
                                        <TableRow key={p.group}>
                                            <TableCell
                                                align="left"
                                                colSpan={3}
                                                style={{
                                                    fontWeight: 'bold',
                                                    color: '#fff',
                                                    backgroundColor: '#116b8e',
                                                    paddingLeft: '10px',
                                                }}
                                            >
                                                {p.group}
                                            </TableCell>
                                        </TableRow>
                                        {p.items.map(i => (
                                            <TableRow key={i.target}>
                                                <TableCell align="left">{i.targetName}</TableCell>
                                                <TableCell align="center">
                                                    <select
                                                        disabled={!pagePermissions?.Roles.Edit}
                                                        key={i.target}
                                                        onChange={e =>
                                                            permissionChanged(
                                                                e.target.value as Permission,
                                                                i.target,
                                                            )
                                                        }
                                                        data-test={i.targetName}
                                                    >
                                                        <option
                                                            value={Permission.None}
                                                            selected={
                                                                i.permission === Permission.None
                                                            }
                                                        >
                                                            None
                                                        </option>
                                                        <option
                                                            value={Permission.View}
                                                            selected={
                                                                i.permission === Permission.View
                                                            }
                                                        >
                                                            View
                                                        </option>
                                                        <option
                                                            value={Permission.Edit}
                                                            selected={
                                                                i.permission === Permission.Edit
                                                            }
                                                        >
                                                            Edit
                                                        </option>
                                                        <option
                                                            value={Permission.Delete}
                                                            selected={
                                                                i.permission === Permission.Delete
                                                            }
                                                        >
                                                            Delete
                                                        </option>
                                                    </select>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Checkbox
                                                        onChange={e =>
                                                            exportChanged(
                                                                e.target.checked,
                                                                i.target,
                                                            )
                                                        }
                                                        key={`export${i.target}`}
                                                        checked={i.canExport === true}
                                                        disabled={i.permission === Permission.None}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </OutlinedSection>
                </form>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    {pagePermissions?.Roles.Edit ? 'Cancel' : 'Close'}
                </Button>
                {pagePermissions?.Roles.Edit && (
                    <Button
                        startIcon={<Save />}
                        color="secondary"
                        variant="contained"
                        onClick={() => handleSubmit(onSubmit)()}
                        data-test={RoleEnum.SAVE}
                    >
                        Save
                    </Button>
                )}
            </DialogActions>
        </>
    );
};

export default RoleModal;
