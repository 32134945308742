import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import StateAutocomplete from '~/components/StateAutocomplete/StateAutocomplete';

type BillComProps = {
    vendorId?: string;
    isEdit: boolean;
};

const BillCom: React.FC<BillComProps> = ({ vendorId, isEdit }) => {
    const {
        control,
        formState: { errors },
        register,
        setValue,
        watch,
    } = useFormContext();
    const state = watch('BillDotComAddressState');
    const vendorExist = !!vendorId;
    return (
        <OutlinedSection title="Bill.com Vendor Information">
            {isEdit && (
                <Grid item>
                    <Controller
                        control={control}
                        name="BillDotComVendorId"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                error={!!errors.BillDotComVendorId}
                                label="Vendor ID"
                                disabled={vendorExist}
                            />
                        )}
                        rules={{ required: true }}
                    />
                </Grid>
            )}
            <Grid item>
                <Controller
                    control={control}
                    name="BillDotComNameOnCheck"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={!!errors.BillDotComNameOnCheck}
                            helperText={errors.BillDotComNameOnCheck && 'Name On Check is required'}
                            label="Name On Check"
                            disabled={isEdit}
                        />
                    )}
                />
            </Grid>
            <Grid item>
                <Controller
                    control={control}
                    name="BillDotComAddress1"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={!!errors.BillDotComAddress1}
                            helperText={errors.BillDotComAddress1 && 'Address 1 is required'}
                            label="Address 1"
                            disabled={isEdit}
                        />
                    )}
                />
            </Grid>
            <Grid item>
                <Controller
                    control={control}
                    name="BillDotComAddress2"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={!!errors.BillDotComAddress2}
                            label="Address 2"
                            disabled={isEdit}
                        />
                    )}
                />
            </Grid>
            <Grid item>
                <Controller
                    control={control}
                    name="BillDotComAddressCity"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={!!errors.BillDotComAddressCity}
                            helperText={errors.BillDotComAddressCity && 'City is required'}
                            label="City"
                            disabled={isEdit}
                        />
                    )}
                />
            </Grid>
            <Grid item>
                <StateAutocomplete
                    isMobileView={false}
                    name="BillDotComAddressState"
                    label="State"
                    register={register('BillDotComAddressState')}
                    onChange={value =>
                        setValue('BillDotComAddressState', value, { shouldValidate: true })
                    }
                    disabled={isEdit}
                    errorMessage={errors.BillDotComAddressState && 'State is required'}
                    value={state}
                />
            </Grid>
            <Grid item>
                <Controller
                    control={control}
                    name="BillDotComAddressZip"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={!!errors.BillDotComAddressZip}
                            helperText={errors.BillDotComAddressZip && 'Zip is required'}
                            label="Zip"
                            disabled={isEdit}
                        />
                    )}
                />
            </Grid>
        </OutlinedSection>
    );
};

export default BillCom;
