import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add, Delete } from '@mui/icons-material';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogLoader from '~/components/DialogLoader/DialogLoader';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import {
    EligibilityType,
    FetchHealthPlansForHealthPlansPageQueryDocument,
    FetchHealthPlansForHealthPlansPageQueryQuery,
    useCreateHealthPlanForModalMutation,
    useFetchHealthPlanByIdForHealthPlanModalLazyQuery,
    useUpdateHealthPlanForModalMutation,
} from '~/schemaTypes';

const HEALTH_PLAN_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string(),
    brandingName: Yup.string(),
    externalId: Yup.string(),
    healthPlanCode: Yup.string(),
    eligibilityType: Yup.string(),
    state: Yup.string(),
    surveys: Yup.array().of(
        Yup.object().shape({
            value: Yup.string(),
        }),
    ),
});

const useStyles = makeStyles()({
    root: {},
});

interface HealthPlanFormInput {
    name: string;
    brandingName: string;
    externalId: string;
    healthPlanCode: string;
    eligibilityType: EligibilityType;
    state: string;
    surveys: { value: string }[];
}

type HealthPlanModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setEditHealthPlanId: Dispatch<SetStateAction<string>>;
    id?: string;
};

const EligibilityTypes = [
    {
        name: 'Wildflower Invitation Code',
        value: EligibilityType.WfhInvitationCode,
    },
    { name: 'Dorsata', value: EligibilityType.Dorsata },
];

const HealthPlansModal: React.FC<HealthPlanModalProps> = ({ setOpen, setEditHealthPlanId, id }) => {
    const { classes } = useStyles();
    const {
        register,
        handleSubmit,
        control,
        reset,

        formState: { errors },
    } = useForm<HealthPlanFormInput>({
        resolver: yupResolver(HEALTH_PLAN_VALIDATION_SCHEMA as any),
    });

    const {
        fields: surveysFields,
        append: surveysAppend,
        remove: surveysRemove,
    } = useFieldArray({
        control,
        name: 'surveys',
    });

    const [getHealthPlan, { data: healthPlanData, loading }] =
        useFetchHealthPlanByIdForHealthPlanModalLazyQuery({
            onCompleted: data => {
                if (data.healthPlan) {
                    const {
                        name,
                        brandingName,
                        externalId,
                        healthPlanCode,
                        eligibilityType,
                        state,
                        surveys,
                    } = data.healthPlan;
                    reset({
                        name: name ?? '',
                        brandingName: brandingName ?? '',
                        externalId: externalId ?? '',
                        healthPlanCode: healthPlanCode ?? '',
                        ...(eligibilityType && { eligibilityType }),
                        state: state ?? '',
                        ...(surveys && { surveys: surveys.map(value => ({ value })) }),
                    });
                }
            },
        });

    const [updateHealthPlan, { loading: updateHealthPlanLoading }] =
        useUpdateHealthPlanForModalMutation({
            onCompleted: data => {
                if (data.updateHealthPlan?.success) {
                    setOpen(false);
                    setEditHealthPlanId('');
                }
            },
        });

    const [createHealthPlan, { loading: createHealthPlanLoading }] =
        useCreateHealthPlanForModalMutation({
            onCompleted: data => {
                if (data.createHealthPlan?.success) {
                    setOpen(false);
                    setEditHealthPlanId('');
                }
            },
            update: (cache, response) => {
                if (response.data?.createHealthPlan?.success) {
                    const newHealthPlan = response.data?.createHealthPlan?.resourceCreated;
                    if (newHealthPlan) {
                        const currentData =
                            cache.readQuery<FetchHealthPlansForHealthPlansPageQueryQuery>({
                                query: FetchHealthPlansForHealthPlansPageQueryDocument,
                            });
                        if (currentData?.healthPlans) {
                            cache.writeQuery<FetchHealthPlansForHealthPlansPageQueryQuery>({
                                query: FetchHealthPlansForHealthPlansPageQueryDocument,
                                data: {
                                    healthPlans: [newHealthPlan, ...currentData.healthPlans],
                                },
                            });
                        }
                    }
                }
            },
        });

    const onSubmit = ({
        name,
        brandingName,
        externalId,
        healthPlanCode,
        eligibilityType,
        state,
        surveys,
    }: HealthPlanFormInput) => {
        if (id) {
            updateHealthPlan({
                variables: {
                    input: {
                        id,
                        data: {
                            name,
                            brandingName,
                            externalId,
                            healthPlanCode,
                            eligibilityType,
                            state,
                            ...(surveys && { surveys: surveys.map(({ value }) => value) }),
                        },
                    },
                },
            });
        } else {
            createHealthPlan({
                variables: {
                    input: {
                        name,
                        brandingName,
                        externalId,
                        healthPlanCode,
                        eligibilityType,
                        state,
                        ...(surveys && { surveys: surveys.map(({ value }) => value) }),
                    },
                },
            });
        }
    };
    const handleCancel = () => {
        setOpen(false);
        setEditHealthPlanId('');
    };

    useEffect(() => {
        if (id) {
            getHealthPlan({
                variables: {
                    input: {
                        id,
                    },
                },
            });
        }
    }, [id, getHealthPlan]);

    if (loading) {
        return <DialogLoader subtitle="Loading health plan details..." />;
    }

    if (createHealthPlanLoading) {
        return <DialogLoader subtitle="Creating health plan..." />;
    }

    if (updateHealthPlanLoading) {
        return <DialogLoader subtitle="Updating health plan..." />;
    }

    const title =
        healthPlanData?.healthPlan?.id === undefined ? 'New Health Plan' : 'Edit Health Plan';

    return (
        <div>
            <DialogTitleWithClose id={title} onClose={handleCancel}>
                {title}
            </DialogTitleWithClose>
            <DialogContent dividers>
                <form className={classes.root} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                error={!!errors.name}
                                label="Name"
                                id="name"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('name')}
                                helperText={errors.name?.message}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                error={!!errors.brandingName}
                                label="Branding Name"
                                id="brandingName"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('brandingName')}
                                helperText={errors.brandingName?.message}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ReactHookFormSelect
                                control={control}
                                defaultValue={
                                    healthPlanData?.healthPlan?.eligibilityType ||
                                    EligibilityTypes[0].value
                                }
                                name="eligibilityType"
                                variant="outlined"
                                label="Eligibility Type"
                                fullWidth
                                margin="dense"
                            >
                                {EligibilityTypes.map(({ value, name }) => (
                                    <MenuItem key={value} value={value}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                error={!!errors.externalId}
                                label="External Id"
                                id="externalId"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('externalId')}
                                helperText={errors.externalId?.message}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                error={!!errors.healthPlanCode}
                                label="Health Plan Code"
                                id="healthPlanCode"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('healthPlanCode')}
                                helperText={errors.healthPlanCode?.message}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant="outlined"
                                error={!!errors.state}
                                label="State"
                                id="state"
                                type="text"
                                margin="dense"
                                placeholder="WA, CA, KS..."
                                fullWidth
                                {...register('state')}
                                helperText={errors.state?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="h6">Surveys:</Typography>
                                <Tooltip title="Add Survey">
                                    <IconButton
                                        color="primary"
                                        onClick={() => surveysAppend({ value: '' })}
                                        size="large"
                                    >
                                        <Add />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Grid>
                        {surveysFields.map(({ value, id }, index) => (
                            <Grid key={id} container>
                                <Grid item alignItems="center" xs={12}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            variant="outlined"
                                            name={`surveys[${index}].value`}
                                            label="Survey"
                                            id={`surveys[${index}]`}
                                            defaultValue={value ?? ''}
                                            type="text"
                                            margin="dense"
                                            fullWidth
                                            inputRef={register(`surveys.${index}.value`).ref}
                                        />
                                        <Tooltip title="Delete Survey">
                                            <IconButton
                                                onClick={() => surveysRemove(index)}
                                                size="large"
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    onClick={() => handleSubmit(onSubmit)()}
                    color="primary"
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </div>
    );
};

export default HealthPlansModal;
