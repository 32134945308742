import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
    TextField,
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import { format, toDate } from 'date-fns-tz';
import React, { useEffect, useMemo, useState } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import {
    OrderByDirectionEnum,
    useAffiliatesForZipCodeFlowForPatientListQuery,
    useAssignPatientToAffiliateForPatientListMutation,
    useFetchAffiliatesForAutocompleteForPatientListLazyQuery,
} from '~/schemaTypes';

enum ChooseAffiliateOptions {
    choose,
    region,
}

type AffiliateOption = {
    title: string;
    value: string;
};

type AssignModalProps = {
    isOpen: boolean;
    patientId: string;
    patientFullName: string;
    patientBirthDate: string;
    patientZipCode: string;
    patientAffiliates: (string | null)[];
    handleClose: () => void;
    onCompleteHandle: () => void;
};

const AssignToAffiliateModal: React.FC<AssignModalProps> = props => {
    const {
        isOpen,
        patientId,
        patientFullName,
        patientBirthDate,
        patientZipCode,
        patientAffiliates,
        handleClose,
        onCompleteHandle,
    } = props;

    /* State */
    const [chooseValue, setChooseValue] = useState(ChooseAffiliateOptions.choose);
    const [selectedAffiliateId, setSelectedAffiliate] = useState<null | string>(null);
    const [autocompleteSearch, setAutocompleteSearch] = useState('');
    const [autocompleteOptions, setAutocompleteOptions] = useState<AffiliateOption[]>([]);

    /* Queries */
    const [fetchAffiliates, { data: affiliates, loading: affiliatesLoading }] =
        useFetchAffiliatesForAutocompleteForPatientListLazyQuery();

    const { data: affiliatesForZipCodeFlow } = useAffiliatesForZipCodeFlowForPatientListQuery({
        variables: {
            input: {
                patientId,
            },
        },
        fetchPolicy: 'no-cache',
    });

    const [assignPatient, { loading: assignPatientLoading }] =
        useAssignPatientToAffiliateForPatientListMutation({
            onCompleted: () => {
                handleClose();
                onCompleteHandle();
            },
        });

    const fetchAffiliatesDebounced = useMemo(
        () => {
            return debounce(
                (search: string) =>
                    fetchAffiliates({
                        variables: {
                            input: {
                                search,
                                excludedAffiliateIds: patientAffiliates,
                                ...(chooseValue === ChooseAffiliateOptions.region && {
                                    patientZipCode,
                                }),
                                pagination: {
                                    limit: 10,
                                    skip: 0,
                                },
                                orderBy: {
                                    field: 'internalName',
                                    order: OrderByDirectionEnum.Asc,
                                },
                            },
                        },
                    }),
                500,
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [patientAffiliates, chooseValue, patientZipCode],
    );

    /* Variables */
    const regionOptionsEnabled = !!affiliatesForZipCodeFlow?.affiliatesForZipCodeFlow?.possible;
    const disableAutocomplete =
        !regionOptionsEnabled && chooseValue === ChooseAffiliateOptions.region;

    /* Effects */
    useEffect(() => {
        setAutocompleteOptions(
            (affiliates?.virtualCareAffiliates?.results || []).map(a => ({
                title: `${a.internalName} (${a.affiliateId})`,
                value: a.affiliateId as string,
            })),
        );
    }, [affiliates?.virtualCareAffiliates?.results]);

    useEffect(() => {
        fetchAffiliatesDebounced(autocompleteSearch);
    }, [autocompleteSearch, fetchAffiliatesDebounced]);

    /* Submit */
    const onSubmit = () => {
        const payload = {
            patientId,
            newAffiliateId: selectedAffiliateId,
        };
        assignPatient({
            variables: payload,
        });
    };

    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose id="add-patient-to-calims" onClose={handleClose}>
                Assign Patient to Affiliate
            </DialogTitleWithClose>
            <DialogContent dividers>
                <Grid container>
                    <Grid item xs={12} container>
                        <Grid item xs={4}>
                            <TextField
                                disabled
                                value={patientFullName}
                                fullWidth
                                label="Patient Name"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                disabled
                                value={format(toDate(patientBirthDate.split('T')[0]), 'MM/dd/yyyy')}
                                fullWidth
                                label="DOB"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            value={chooseValue}
                            onChange={e =>
                                setChooseValue(e.target.value as unknown as ChooseAffiliateOptions)
                            }
                        >
                            <MenuItem
                                key={ChooseAffiliateOptions.choose}
                                value={ChooseAffiliateOptions.choose}
                            >
                                Choose from a complete list of Affiliates
                            </MenuItem>
                            <MenuItem
                                key={ChooseAffiliateOptions.region}
                                value={ChooseAffiliateOptions.region}
                            >
                                Assign patient to an affiliate in the region
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            fullWidth
                            noOptionsText="Not match"
                            disabled={disableAutocomplete}
                            getOptionLabel={option => option.title}
                            options={autocompleteOptions}
                            loading={affiliatesLoading}
                            getOptionKey={option => option.value}
                            inputValue={autocompleteSearch}
                            onInputChange={(event, inputValue) =>
                                event && setAutocompleteSearch(inputValue)
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={
                                        disableAutocomplete
                                            ? 'No Affiliate Found In Region'
                                            : 'Type To Complete Affiliate Name'
                                    }
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {affiliatesLoading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            onChange={(_, value) => setSelectedAffiliate(value?.value || null)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={onSubmit}
                    disabled={assignPatientLoading}
                >
                    Save
                </Button>
                <Button onClick={handleClose} color="secondary" variant="outlined">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssignToAffiliateModal;
