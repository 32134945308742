import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { ApprovalParms } from '../ArticleApprovals';

const useStyles = makeStyles()(() => ({
    paper: { minWidth: '800px' },
}));

const ApproveModal: React.FC<{
    parms: ApprovalParms;
}> = props => {
    const { parms } = props;
    const { closeHandler, submitHandler, count, approve } = parms;
    const { classes } = useStyles();
    const [approvedBy, setApprovedBy] = useState('');
    const title = `${approve ? 'Approve' : 'Deny'} ${count} Article${count === 1 ? '' : 's'}`;
    const onSubmit = () => {
        submitHandler(approvedBy, approve);
    };

    return (
        <Dialog open classes={{ paper: classes.paper }}>
            <DialogTitleWithClose id="modalTitle" onClose={closeHandler}>
                {title}
            </DialogTitleWithClose>
            <DialogContent>
                <OutlinedSection title={approve ? 'Approved By' : 'Denied By'}>
                    <TextField
                        variant="outlined"
                        name="approvedBy"
                        type="text"
                        fullWidth
                        margin="dense"
                        defaultValue={approvedBy}
                        onChange={e => setApprovedBy(e.target.value)}
                    />
                </OutlinedSection>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={closeHandler} color="secondary" variant="outlined" title="Cancel">
                    Cancel
                </Button>
                {approvedBy.length > 0 && (
                    <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={onSubmit}
                        title="Update"
                    >
                        Update
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
export {};
export default ApproveModal;
