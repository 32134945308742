/* eslint-disable camelcase */
/* eslint-disable react/no-danger */

import { Button, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowBack } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { ContentVariantInput, Language, ReadingLevel } from '~/schemaTypes';

const useStyles = makeStyles()(theme => ({
    root: {},
    fab: {
        position: 'absolute',
        top: theme.spacing(12),
        right: theme.spacing(4),
    },
}));

const VariantReview: React.FC<{
    variant: ContentVariantInput;
    articleName: string;
    closeReview: () => void;
}> = props => {
    const { variant, articleName, closeReview } = props;
    const { classes } = useStyles();
    const [content, setContent] = useState<{ type: string; content: string }[]>();
    useEffect(() => {
        const newContent: { type: string; content: string }[] = [];
        if (variant.titleMarkup) {
            newContent.push({
                type: 'Title',
                content: variant.titleMarkup,
            });
        }
        if (variant.summaryMarkup) {
            newContent.push({
                type: 'Summary',
                content: variant.summaryMarkup,
            });
        }
        if (variant.bodyMarkup) {
            newContent.push({
                type: 'Body',
                content: variant.bodyMarkup,
            });
        }
        setContent(newContent);
    }, [variant]);
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Button onClick={closeReview} startIcon={<ArrowBack />}>
                    Back to Article
                </Button>
            </Grid>
            <div>
                <div>
                    {articleName}/{variant.language === Language.En ? 'English' : 'Spanish'}/
                    {variant.readingLevel === ReadingLevel.Eighth ? 'Eighth' : 'Fifth'}/
                    {variant.appBundleId ? variant.appBundleId : 'All Apps'}
                </div>
                {content?.map(c => {
                    return (
                        <OutlinedSection title={c.type}>
                            <div
                                style={{ display: 'inline-block' }}
                                dangerouslySetInnerHTML={{ __html: c.content }}
                            />
                        </OutlinedSection>
                    );
                })}
            </div>
        </Grid>
    );
};

export default VariantReview;
