import React, { useEffect, useRef } from 'react';

interface HTMLRendererProps {
    htmlString?: string;
}

const HTMLRenderer: React.FC<HTMLRendererProps> = ({ htmlString = '' }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Create a new DOMParser
        const parser = new DOMParser();

        // Parse the HTML string
        const doc = parser.parseFromString(htmlString, 'text/html');

        // Extract the body content
        const bodyContent = doc.body;

        // Clear the container before appending new content
        if (containerRef.current) {
            containerRef.current.innerHTML = '';
        }

        // Append the body content to the container
        if (containerRef.current && bodyContent) {
            containerRef.current.appendChild(bodyContent);
        }
    }, [htmlString]);

    return (
        <div style={{ padding: '10px', fontFamily: 'Arial, sans-serif' }}>
            <div
                ref={containerRef}
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '10px',
                    marginTop: '5px',
                }}
            />
        </div>
    );
};

export default HTMLRenderer;
