import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    root: {
        '& .MuiFormControl-root': {
            paddingTop: 0,
            paddingBottom: 0,
        },
        '& input[type=number]': {
            width: '2.5em',
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        'input[type=number]': {
            '-moz-appearance': 'textfield',
        },
    },
    actionBtnsWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 16px',
    },
    outlinedSection: {
        marginLeft: 0,
        width: '100%',
    },
    offsetWrap: {
        display: 'flex',
        alignContent: 'center',
    },
    offsetLabel: {
        alignSelf: 'center',
        marginRight: '10px',
    },
    checkboxWrap: {
        paddingLeft: '10px',
    },
    articlePicker: {
        width: '100%',
        margin: '0 0 10px 0',
    },
});
