import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import React, { useState } from 'react';
import Loading from '~/components/Loading/Loading';
import { useFetchProfileDefsForExpressionPageQuery } from '~/schemaTypes';

type FiltersModalProps = {
    open: boolean;
    onClose: () => void;
};

export const FiltersModal: React.FC<FiltersModalProps> = ({ open, onClose }) => {
    const [expressionsSelectedProfileDefIds, setExpressionsSelectedProfileDefIds] = useState<
        string[]
    >(
        localStorage
            .getItem('expressionsSelectedProfileDefs')
            ?.split(',')
            .filter(str => str.length > 1) ?? [],
    );

    const { data: profileData, loading: profileDefsLoading } =
        useFetchProfileDefsForExpressionPageQuery();

    const handleSelectProfileDef = (event: React.ChangeEvent<unknown>, value: any) => {
        setExpressionsSelectedProfileDefIds(value);
    };

    const handleClearFilters = () => {
        setExpressionsSelectedProfileDefIds([]);
    };

    const handleSave = () => {
        localStorage.setItem(
            'expressionsFilteredProfileDefs',
            `${expressionsSelectedProfileDefIds.join(',')}`,
        );
        onClose();
    };

    const getOptions = () => profileData?.userProfileDefs.map(profileDef => profileDef.id) ?? [];

    if (profileDefsLoading) {
        return <Loading />;
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Filters</DialogTitle>
            <DialogContent>
                <Autocomplete
                    multiple
                    options={getOptions()}
                    getOptionLabel={profileDef =>
                        profileData?.userProfileDefs.find(def => def.id === profileDef)?.name ?? ''
                    }
                    filterSelectedOptions
                    value={expressionsSelectedProfileDefIds as string[]}
                    onChange={handleSelectProfileDef}
                    disableCloseOnSelect
                    renderInput={params => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <TextField {...params} label="Filter Profile Defs" variant="outlined" />
                    )}
                />
                <Button onClick={handleClearFilters}>Clear Filters</Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="primary">
                    Close
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
