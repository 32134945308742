import ExcelJS, { Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import { LogoBase64 } from '~/components/Logo/LogoBase64';
import { displayDateLocale } from '~/helpers';
import {
    formatContentRange,
    formatSheet,
} from '~/views/Dashboard/MessageCenter/MessageCenterExcelTemplate';

const TABLE_ELEMENT_OFFSET = 1;
const WF_LOGO_OFFSET = 5;

const addWFLogo = (ws: Worksheet, imageId: number) => {
    ws.addImage(imageId, {
        tl: { col: 0, row: 0 },
        ext: { width: 350, height: 70 },
        editAs: 'absolute',
    });
    ws.getCell(1, 1).value = 'Wildflower Health';
};

export const getTimelineWorkbook = async (data: any[][]): Promise<void> => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Wildflower Health';
    const workbookDate = new Date();
    workbook.created = workbookDate;
    workbook.modified = workbookDate;
    workbook.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 0,
            visibility: 'visible',
        },
    ];
    const imageId = workbook.addImage({
        base64: LogoBase64,
        extension: 'jpeg',
    });

    const sheet = workbook.addWorksheet('Timeline Viewer Export');
    addWFLogo(sheet, imageId);
    const columns = [
        {
            name: 'Type',
        },
        {
            name: 'Timeline',
        },
        {
            name: 'Offset',
        },
        {
            name: 'Available',
        },
        {
            name: 'Duration',
        },
        {
            name: 'Priority',
        },
        {
            name: 'Tags',
        },
        {
            name: 'Apps',
        },
        {
            name: 'Details',
        },
    ];

    sheet.addTable({
        name: 'TimelineViewerExportTable',
        ref: `A${WF_LOGO_OFFSET}`,
        columns,
        rows: data.length ? data : [[]],
    });
    formatSheet(sheet);
    formatContentRange(
        sheet,
        WF_LOGO_OFFSET + TABLE_ELEMENT_OFFSET,
        sheet.rowCount,
        1,
        sheet.columnCount,
    );

    // Save workbook
    workbook.xlsx.writeBuffer().then(data => {
        const fileDate = displayDateLocale({
            isoDateStr: new Date().toISOString(),
            format: 'MM-DD-YYYY',
        });
        const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, `timelineViewer_${fileDate}.xlsx`);
    });
};
export {};
