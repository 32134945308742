/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Card,
    CardHeader,
    Checkbox,
    Dialog,
    FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add as AddIcon, ArrowBack, Save } from '@mui/icons-material';
import ObjectId from 'bson-objectid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import SortableList, {
    ListItemType,
    SortableListOnChangeProps,
} from '~/components/SortableList/SortableList';
import {
    EventTrackerType,
    EventTrigger,
    EventTriggerListDocument,
    EventTriggerListQuery,
    InterventionDateType,
    InterventionInput,
    InterventionListDocument,
    InterventionListQuery,
    useCreateEventTriggerMutation,
    useDeleteInterventionMutation,
    useFetchEventTriggerLazyQuery,
    useFetchMessageCenterTemplatesForInterventionQuery,
    useInterventionListQuery,
    usePreFetchAppsTagsQuery,
    useProfileDefsListQuery,
    useUpdateEventTriggerMutation,
    useUpdateInterventionMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import _ from 'lodash';
import InterventionsModal from '../InterventionsModal';

type InterventionListItem = NonNullable<InterventionListQuery['interventionsV2']>['results'][0];
type InterventionListProfileValue = NonNullable<InterventionListItem['profileValue']>;

const useStyles = makeStyles()(theme => ({
    root: {},
    fab: {
        position: 'absolute',
        top: theme.spacing(12),
        right: theme.spacing(4),
    },
    addInterventionButtonContainer: {
        margin: '20px 10px',
    },
}));

interface EventTriggerFormInput {
    id?: string;
    name: string;
    expressionId: string;
    canRepeat: boolean;
    ignoreSameValue: boolean;
}

const formValuesFromData = (item: EventTrigger): EventTriggerFormInput => {
    return {
        name: item.name,
        expressionId: item.expressionId,
        canRepeat: item.canRepeat,
        ignoreSameValue: item.ignoreSameValue,
    };
};

const formValuesDefault = (eventTriggerId: string): EventTriggerFormInput => {
    return {
        id: eventTriggerId,
        name: '',
        expressionId: '',
        canRepeat: false,
        ignoreSameValue: false,
    };
};

const triggerFromForm = (values: EventTriggerFormInput) => {
    return {
        name: values.name,
        expressionId: values.expressionId,
        canRepeat: values.canRepeat,
        ignoreSameValue: values.ignoreSameValue,
    };
};

const EventTriggerValidation = Yup.object().shape({
    name: Yup.string().required('Required'),
    expressionId: Yup.string().required('Required'),
});

const EventTriggerEditor: React.FC = () => {
    const history = useNavigate();
    const { classes } = useStyles();
    const { id: urlId } = useParams<{ id: string }>();
    const [showInterventionModal, setShowInterventionModal] = useState(false);
    const [eventType, setEventType] = useState<'tracker' | 'profile'>('profile');
    const [trackerType, setTrackerType] = useState<EventTrackerType | undefined>();
    const [editInterventionId, setEditInterventionId] = useState('');
    const [selectedPVId, setSelectedPVId] = useState('');
    const [isModified, setIsModified] = useState(false);
    const isEditMode = urlId !== 'new';
    const [eventTriggerId, setEventTriggerId] = useState(
        isEditMode ? urlId : new ObjectId().toHexString(),
    );
    const [close, setClose] = useState(false);
    const [interventionPriority, setInterventionPriority] = useState(0);
    const [interventionSortableList, setInterventionSortableList] = useState<ListItemType[]>([]);
    const { data: preFetchData, loading: preFetchLoading } = usePreFetchAppsTagsQuery();
    const { data: profileDefData, loading: profileDataLoading } = useProfileDefsListQuery();
    const [fetchEventTriggerById, { data: eventTriggerData, loading: eventTriggerLoading }] =
        useFetchEventTriggerLazyQuery();

    const { data: messageCenterTemplateList, loading: messageCenterTemplatesLoading } =
        useFetchMessageCenterTemplatesForInterventionQuery();

    const [updateEventTrigger, { loading: updateEventTriggerLoading }] =
        useUpdateEventTriggerMutation({
            onCompleted: () => {
                if (close) history(`/app-config/eventtriggers`);
            },
            update: (cache, response) => {
                const updatedItem = response.data?.updateEventTrigger?.resourceUpdated;
                if (response.data?.updateEventTrigger?.success && updatedItem) {
                    const currentItems = cache.readQuery<EventTriggerListQuery>({
                        query: EventTriggerListDocument,
                    });
                    if (currentItems?.eventTriggersV2.results) {
                        cache.writeQuery<EventTriggerListQuery>({
                            query: EventTriggerListDocument,
                            data: {
                                eventTriggersV2: {
                                    results: [
                                        ...currentItems.eventTriggersV2.results.map(item => {
                                            if (item.id === updatedItem.id) {
                                                return updatedItem;
                                            }
                                            return item;
                                        }),
                                    ],
                                },
                            },
                        });
                    }
                }
            },
        });

    const [createEventTrigger, { loading: createEventTriggerLoading }] =
        useCreateEventTriggerMutation({
            onCompleted: data => {
                if (close) history(`/app-config/eventtriggers`);
                else
                    history(
                        `/app-config/eventtriggers/${data.createEventTrigger?.resourceCreated?.id}`,
                    );
            },
            update: (cache, response) => {
                const newItem = response.data?.createEventTrigger?.resourceCreated;
                setEventTriggerId(newItem?.id);
                if (response.data?.createEventTrigger?.success && newItem) {
                    const currentItems = cache.readQuery<EventTriggerListQuery>({
                        query: EventTriggerListDocument,
                    });
                    if (currentItems?.eventTriggersV2.results) {
                        cache.writeQuery<EventTriggerListQuery>({
                            query: EventTriggerListDocument,
                            data: {
                                eventTriggersV2: {
                                    __typename: currentItems.eventTriggersV2.__typename,
                                    results: [...currentItems.eventTriggersV2.results, newItem],
                                },
                            },
                        });
                    }
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: EventTriggerListDocument,
                    variables: {
                        input: {},
                    },
                },
            ],
        });

    const { data: interventionData } = useInterventionListQuery({
        variables: {
            interventionListInput: { filter: { fields: { eventTriggerId } } },
        },
    });
    const [deleteIntervention] = useDeleteInterventionMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: InterventionListDocument,
                variables: {
                    interventionListInput: { filter: { fields: { eventTriggerId } } },
                },
            },
        ],
    });

    const [updateIntervention, { loading: updateInterventionLoading }] =
        useUpdateInterventionMutation();

    const {
        control,
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm<EventTriggerFormInput>({
        resolver: yupResolver(EventTriggerValidation as any),
        defaultValues: formValuesDefault(eventTriggerId ?? ''),
    });

    const getInterventionData = (intervention: InterventionListItem) => {
        const {
            advocateTaskTemplateId,
            choicesToUpdate,
            messageCenterTemplateId,
            type,
            conditionTypeId,
            profileDefId,
            profileValue,
            dateType,
            tagId,
        }: InterventionInput = intervention;

        const { bool, num, str, date, choices, choiceId }: any = profileValue;

        return {
            advocateTaskTemplateId,
            choicesToUpdate,
            messageCenterTemplateId,
            type,
            conditionTypeId,
            profileDefId,
            profileValue: { bool, num, str, date, choices, choiceId },
            dateType,
            tagId,
            priority: interventionSortableList.findIndex(item => item.id === intervention.id),
        };
    };

    const updateInterventions = async () => {
        const interventions: InterventionListQuery['interventionsV2']['results'] | undefined =
            interventionData?.interventionsV2?.results;
        if (!interventions) return;
        await Promise.all(
            interventions.map(intervention => {
                return updateIntervention({
                    variables: {
                        updateInterventionInput: {
                            id: intervention.id,
                            data: getInterventionData(intervention),
                        },
                    },
                });
            }),
        );
    };

    const profileValueOptions = useMemo(() => {
        if (!profileDefData) {
            return [];
        }
        return _.sortBy(profileDefData.userProfileDefs, 'name');
    }, [profileDefData]);

    const onSubmit = async (values: EventTriggerFormInput) => {
        const data = triggerFromForm(values);
        let selectedTrackerType;
        let selectedProfileDefId;

        if (eventType === 'profile') {
            selectedTrackerType = undefined;
            selectedProfileDefId = selectedPVId || profileValueOptions[0].id;
        } else {
            selectedTrackerType = trackerType || EventTrackerType.BloodPressure;
            selectedProfileDefId = undefined;
        }

        if (isEditMode && eventTriggerId) {
            await updateEventTrigger({
                variables: {
                    input: {
                        id: eventTriggerId,
                        data: {
                            ...data,
                            trackerType: selectedTrackerType,
                            profileDefId: selectedProfileDefId,
                        },
                    },
                },
            });
            await updateInterventions();
        } else {
            await createEventTrigger({
                variables: {
                    input: {
                        ...data,
                        trackerType: selectedTrackerType,
                        profileDefId: selectedProfileDefId,
                    },
                },
            });
            await updateInterventions();
        }
        setIsModified(false);
    };

    const onNavigateAway = () => {
        if (isModified)
            TriggerGlobalConfirm({
                message: `You have unsaved changes. Are you sure you want to return to the Event Trigger List?`,
                callback: () => {
                    history(`/app-config/eventtriggers/`);
                },
            });
        else history(`/app-config/eventtriggers/`);
    };

    const onEdit = () => {
        setIsModified(true);
    };

    const handleAddIntervention = () => {
        setInterventionPriority(interventionSortableList.length);
        setShowInterventionModal(true);
    };

    const handleEditIntervention = (listItem: ListItemType, index: number) => {
        setShowInterventionModal(true);
        setInterventionPriority(index);
        setEditInterventionId(listItem.id as string);
    };

    const handleChangeInterventions = ({ startIndex, endIndex }: SortableListOnChangeProps) => {
        const interventionistClone = [...interventionSortableList];
        const element = interventionistClone[startIndex];
        interventionistClone.splice(startIndex, 1);
        interventionistClone.splice(endIndex, 0, element);
        setInterventionSortableList(interventionistClone);
    };

    const handleDeleteIntervention = (id: string, index: number) => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: 'Do you really want to delete this Intervention?',
            callback: async () => {
                await deleteIntervention({ variables: { input: { id } } });
                setInterventionSortableList(
                    interventionSortableList.filter((_, groupIndex) => groupIndex !== index),
                );
            },
        });
    };

    const handleDeleteInterventionItemClick = (index: number) => {
        handleDeleteIntervention(interventionSortableList[index].id as string, index);
    };

    const formatTargetDate = (dateString: string) => {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');

        return `${month}/${day}/${year}`;
    };

    const renderTarget = useCallback(
        ({
            conditionType,
            profileDef,
            messageCenterTemplateId,
            messageTemplateId,
            messageTemplate,
            profileValue,
            dateType,
            advocateTaskTemplate,
        }: InterventionListItem): string => {
            const interventionProfileValue = { ...profileValue };
            if (conditionType) {
                return conditionType.name.en;
            }
            if (messageTemplateId) {
                return messageTemplate?.name ?? messageTemplateId;
            }
            if (messageCenterTemplateId) {
                return (
                    messageCenterTemplateList?.messageCenterTemplates.filter(
                        template => template.id === messageCenterTemplateId,
                    )?.[0]?.title ?? ''
                );
            }
            if (advocateTaskTemplate) {
                return advocateTaskTemplate.label;
            }

            if (!interventionProfileValue || !profileDef || !profileDef.choices) {
                return '';
            }

            const { choiceId, date, str, num, bool } = interventionProfileValue;
            const { valueType } = profileDef;
            const profileDefName = profileDef?.name;
            // Check if profile value is a string
            if (str) {
                return `${profileDefName}: ${str}`;
            }
            // Check if profile value is a number
            if (num) {
                return `${profileDefName}: ${num}`;
            }
            // Check if profile value is a date and is Today
            if (dateType === InterventionDateType.Today && valueType === 'date') {
                return `${profileDefName}: Today`;
            }
            // Check if profile value is a boolean
            if (bool !== null && dateType !== InterventionDateType.Fixed) {
                return `${profileDefName}: ${bool ? 'Yes' : 'No'}`;
            }
            interventionProfileValue.bool = null;
            // if the profile value is a fixed date
            try {
                const [[selectedKey]] = Object.entries(interventionProfileValue).filter(
                    ([, value]) => value !== 'InterventionProfileValue' && value !== null,
                );

                if (!choiceId) {
                    const value =
                        interventionProfileValue[selectedKey as keyof InterventionListProfileValue];
                    return `${profileDefName}: ${date ? formatTargetDate(value as string) : value}`;
                }
            } catch (e) {
                throw new Error('Invalid profile value');
            }

            const [selectedChoice] = profileDef.choices.filter(choice => choice?.id === choiceId);
            const choiceLabel = selectedChoice?.label?.en;

            return `${profileDefName}: ${choiceLabel}`;
        },
        [messageCenterTemplateList?.messageCenterTemplates],
    );

    const getInterventionSortableList = useCallback(
        (interventionData: InterventionListQuery | undefined): ListItemType[] => {
            const data = interventionData?.interventionsV2?.results;
            return data
                ? data
                      .map(item => ({
                          id: item.id,
                          label: {
                              en: `Type: ${item.type}. Target: ${renderTarget(item)}`,
                              es: item.type,
                          },
                          priority: item.priority as number,
                      }))
                      .sort((prev, next) => (prev.priority > next.priority ? 1 : -1))
                : [];
        },
        [renderTarget],
    );

    useEffect(() => {
        if (isEditMode) {
            fetchEventTriggerById({ variables: { input: { id: eventTriggerId } } });
            if (eventTriggerData && eventTriggerData.eventTrigger) {
                reset(formValuesFromData(eventTriggerData.eventTrigger));
                if (eventTriggerData.eventTrigger.profileDefId) {
                    setEventType('profile');
                } else {
                    setEventType('tracker');
                }
                setSelectedPVId(eventTriggerData.eventTrigger.profileDefId);
                setTrackerType(eventTriggerData.eventTrigger.trackerType || undefined);
            }
            setInterventionSortableList(getInterventionSortableList(interventionData));
        }
    }, [
        fetchEventTriggerById,
        interventionData,
        isEditMode,
        eventTriggerId,
        eventTriggerData,
        reset,
        urlId,
        getInterventionSortableList,
        profileDefData?.userProfileDefs,
    ]);

    if (
        eventTriggerLoading ||
        updateEventTriggerLoading ||
        preFetchLoading ||
        messageCenterTemplatesLoading ||
        !preFetchData ||
        profileDataLoading ||
        createEventTriggerLoading
    )
        return <Loading />;
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                    Back to Event Trigger List
                </Button>
            </Grid>
            <Grid item xs={12}>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        <CardHeader
                            title={`${
                                isEditMode ? 'Edit' : 'Create'
                            } Event Trigger and Interventions`}
                        />
                        <OutlinedSection title="Name *">
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                onChange={onEdit}
                            />
                        </OutlinedSection>
                        <Grid item xs={12}>
                            <ReactHookFormSelect
                                control={control}
                                defaultValue=""
                                name="expressionId"
                                variant="outlined"
                                label="Select Expression *"
                                fullWidth
                                margin="dense"
                                error={!!errors.expressionId}
                            >
                                {_.sortBy(preFetchData.configExpressions, 'name').map(
                                    ({ id, name }) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ),
                                )}
                            </ReactHookFormSelect>
                            {errors?.expressionId && (
                                <FormHelperText error>
                                    {errors?.expressionId?.message}
                                </FormHelperText>
                            )}

                            <table>
                                <tr>
                                    <td>Event Type:</td>
                                    <td>
                                        <select
                                            value={eventType}
                                            disabled={isEditMode}
                                            onChange={e => {
                                                setEventType(e.target.value as any);
                                                setSelectedPVId('');
                                                onEdit();
                                            }}
                                        >
                                            <option key="tracker" value="tracker">
                                                Tracker
                                            </option>
                                            <option key="profile" value="profile">
                                                Profile Value
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                {eventType === 'profile' && (
                                    <tr>
                                        <td>Profile Value:</td>
                                        <td>
                                            <select
                                                value={selectedPVId}
                                                disabled={isEditMode}
                                                onChange={e => {
                                                    setSelectedPVId(e.target.value);
                                                    setTrackerType(undefined);
                                                }}
                                            >
                                                {profileValueOptions.map(v => (
                                                    <option key={v.id} value={v.id}>
                                                        {v.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                )}
                                {eventType === 'tracker' && (
                                    <tr>
                                        <td>Tracker Type:</td>
                                        <td>
                                            <select
                                                value={trackerType}
                                                disabled={isEditMode}
                                                onChange={e =>
                                                    setTrackerType(
                                                        e.target.value as EventTrackerType,
                                                    )
                                                }
                                            >
                                                <option
                                                    value={EventTrackerType.BloodPressure}
                                                    key={EventTrackerType.BloodPressure}
                                                >
                                                    Blood Pressure
                                                </option>
                                                <option
                                                    value={EventTrackerType.GlucoseAny}
                                                    key={EventTrackerType.GlucoseAny}
                                                >
                                                    Any Glucose
                                                </option>
                                                <option
                                                    value={EventTrackerType.GlucoseFasting}
                                                    key={EventTrackerType.GlucoseFasting}
                                                >
                                                    Glucose Fasting
                                                </option>
                                                <option
                                                    value={EventTrackerType.GlucosePostBreakfast}
                                                    key={EventTrackerType.GlucosePostBreakfast}
                                                >
                                                    Glucose Post Breakfast
                                                </option>
                                                <option
                                                    value={EventTrackerType.GlucosePostLunch}
                                                    key={EventTrackerType.GlucosePostLunch}
                                                >
                                                    Glucose Post Lunch
                                                </option>
                                                <option
                                                    value={EventTrackerType.GlucosePostDinner}
                                                    key={EventTrackerType.GlucosePostDinner}
                                                >
                                                    Glucose Post Dinner
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                )}
                            </table>
                            <Grid item xs={12}>
                                <Controller
                                    name="canRepeat"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            label="Can repeat?"
                                            labelPlacement="start"
                                            control={
                                                <Checkbox
                                                    id="canRepeat"
                                                    checked={value === true}
                                                    onChange={e => {
                                                        onChange(e.target.checked);
                                                        onEdit();
                                                    }}
                                                />
                                            }
                                        />
                                    )}
                                />
                                <Controller
                                    name="ignoreSameValue"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            label="Ignore Same Value?"
                                            labelPlacement="start"
                                            control={
                                                <Checkbox
                                                    id="ignoreSameValue"
                                                    checked={value === true}
                                                    onChange={e => {
                                                        onChange(e.target.checked);
                                                        onEdit();
                                                    }}
                                                />
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        {isEditMode && (
                            <Grid item className={classes.addInterventionButtonContainer}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => handleAddIntervention()}
                                    startIcon={<AddIcon />}
                                >
                                    Add Intervention
                                </Button>
                            </Grid>
                        )}
                        {!updateInterventionLoading &&
                        interventionSortableList &&
                        interventionSortableList.length ? (
                            <Grid item>
                                <OutlinedSection title="Interventions">
                                    <SortableList
                                        list={interventionSortableList}
                                        droppableId="interventionOrder"
                                        onChange={handleChangeInterventions}
                                        onClick={handleEditIntervention}
                                        onDelete={handleDeleteInterventionItemClick}
                                    />
                                </OutlinedSection>
                            </Grid>
                        ) : null}

                        <div style={{ width: '100%', marginTop: '10px', textAlign: 'right' }}>
                            <Button
                                type="submit"
                                startIcon={<Save />}
                                color="secondary"
                                variant="contained"
                                onClick={() => handleSubmit(onSubmit)}
                            >
                                Save
                            </Button>
                            <Button
                                type="submit"
                                startIcon={<Save />}
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                    setClose(true);
                                    handleSubmit(onSubmit);
                                }}
                            >
                                Save &amp; Close
                            </Button>
                        </div>
                    </Card>
                </form>
            </Grid>
            <Dialog scroll="body" open={showInterventionModal} aria-labelledby="form-dialog-title">
                <InterventionsModal
                    setOpen={setShowInterventionModal}
                    setEditIntervention={setEditInterventionId}
                    id={editInterventionId}
                    eventTriggerId={eventTriggerId}
                    interventionPriority={interventionPriority}
                />
            </Dialog>
        </Grid>
    );
};

export default EventTriggerEditor;
