import { Dialog, Grid, TablePagination } from '@mui/material';
import { Code } from '@mui/icons-material';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import RawModal from '~/components/RawModal/RawModal';
import { displayDate } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import {
    AlertSeverity,
    ClinicalSummarysV2QueryVariables,
    OrderByDirectionEnum,
    ReferencePages,
    useClinicalSummarysV2Query,
    useGetClinicalSummaryLogByIdLazyQuery,
    useFetchReferenceLinksForPatientClinicalSummariesPageQuery,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import { PAGESIZE } from '~/constants';

type RouteParams = {
    id: string;
};

type PatientClinicalSummariesProps = {
    setLinks?: any;
};

const PatientClinicalSummaries: React.FC<PatientClinicalSummariesProps> = ({ setLinks }) => {
    const { id } = useParams<RouteParams>();
    const tableRef = useRef<HTMLDivElement>(null);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);

    const [showRawModal, setShowRawModal] = useState<boolean>(false);
    const [rawData, setRawData] = useState<string>('');

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const variables: ClinicalSummarysV2QueryVariables = {
        input: {
            orderBy: {
                order: OrderByDirectionEnum.Desc,
                field: 'createdAt',
            },
            filter: {
                fields: {
                    patientId: id,
                },
            },
            pagination: {
                limit: rowsPerPage,
                skip: page * rowsPerPage,
            },
        },
    };
    const { data, loading } = useClinicalSummarysV2Query({
        variables,
    });

    const { data: refereneLinksData, loading: refereneLinksLoading } =
        useFetchReferenceLinksForPatientClinicalSummariesPageQuery({
            variables: {
                input: {
                    page: ReferencePages.PatientClinicalSummaries,
                },
            },
        });

    useEffect(() => {
        if (refereneLinksData && setLinks) {
            setLinks(refereneLinksData.getReferenceLinksForPage);
        }
    }, [refereneLinksData, setLinks]);

    const [fetchLogById] = useGetClinicalSummaryLogByIdLazyQuery({
        onCompleted: data => {
            if (data.getClinicalSummaryLogById?.raw) {
                setShowRawModal(true);
                setRawData(data.getClinicalSummaryLogById.raw || '');
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'No Raw data found for this clinical summary',
                });
            }
        },
        onError: () => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'Server error. Please, try again later',
            });
        },
        fetchPolicy: 'network-only',
    });

    const fetchLog = (logId: string | null | undefined): void => {
        if (!logId) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'This summary has no logId',
            });
            return;
        }
        fetchLogById({ variables: { input: { logId } } });
    };

    const handleCloseModal = () => {
        setShowRawModal(false);
        setRawData('');
    };

    return (
        <Grid ref={tableRef}>
            <MaterialTable
                isLoading={loading || refereneLinksLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                columns={[
                    {
                        title: 'VisitId',
                        field: 'visitId',
                        align: 'center',
                        sorting: false,
                        render: ({ id: summaryId, visitId }) => {
                            return (
                                <Link to={`/portal/patients/${id}/clinical-summaries/${summaryId}`}>
                                    {visitId || summaryId}
                                </Link>
                            );
                        },
                    },
                    {
                        title: 'Date Time',
                        field: 'dateTime',
                        align: 'center',
                        sorting: false,
                        render: ({ createdAt }) =>
                            displayDate({ isoDateStr: createdAt, format: 'MM-DD-YYYY HH:mm:ss' }),
                    },
                    {
                        title: 'Log',
                        field: 'log',
                        align: 'center',
                        sorting: false,
                        render: ({ logId }) => (
                            <Code style={{ cursor: 'pointer' }} onClick={() => fetchLog(logId)} />
                        ),
                    },
                ]}
                data={data?.clinicalSummarysV2?.results ?? []}
                title="Clinical Summaries"
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={data?.clinicalSummarysV2?.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    search: false,
                    actionsColumnIndex: -1,
                    paginationPosition: 'both',
                }}
            />

            {showRawModal && rawData && (
                <Dialog
                    scroll="paper"
                    open={showRawModal}
                    fullWidth
                    maxWidth="lg"
                    aria-labelledby="form-dialog-title"
                >
                    <RawModal setShow={handleCloseModal} rawData={rawData} />
                </Dialog>
            )}
        </Grid>
    );
};

export default PatientClinicalSummaries;
