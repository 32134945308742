/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Divider, Link, Slide, TextField, Typography } from '@mui/material';
import history from 'history/browser';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import { useQueryParams } from '~/hooks';
import {
    AlertSeverity,
    useFetchCurrentUserLoginLazyQuery,
    useLoginAuthMethodLazyQuery,
    useLoginUserForLoginPageMutation,
    useResetPasswordLoginPageMutation,
} from '~/schemaTypes';
import { LoginEnum } from '~/selectors/login.selector';
import { ClearAuthTokens, PersistAuthTokens, TriggerGlobalAlert } from '~/state';
import { logout } from '~/state/auth/auth.svc';
import { cognitoMfaChallengeResponse, cognitoPasswordLogin } from '~/state/auth/cognito.svc';
import { log } from '~/utils/log.svc';
import { useStyles } from './styles';
import { EmailHelpLink } from './EmailHelpLink';
import { PASSWORD_REGEX } from '../../helpers/regularExpressions';

export type LoginFormInput = {
    email: string;
    password: string;
};

export const LOGIN_VALIDATION_SCHEMA = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Email is Required'),
    password: Yup.string().required('Password is required'),
});

const LOGIN_MIGRATION_VALIDATION_SCHEMA = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Email is Required'),
    newPassword: Yup.string()
        .required('Password is required')
        .matches(
            PASSWORD_REGEX,
            'Password must contain at least 8 characters, one uppercase, one number and one special case character',
        ),
    confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), undefined], `Passwords don't match`)
        .required('Password confirm is required'),
});

type LoginMigrationFormInput = {
    email: string;
    newPassword: string;
    confirmNewPassword: string;
};

const RESET_PASSWORD_VALIDATION_SCHEMA = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Email is Required'),
});

type ResetPasswordFormInput = {
    email: string;
};

const LOGIN_WITH_TOTP_VALIDATION_SCHEMA = Yup.object().shape({
    code: Yup.string().required('Code is Required'),
});

type LoginWithTOTPFormInput = {
    code: string;
};

type LoginProps = { clearAuthTokens?: boolean };

const Login: React.FC<LoginProps> = ({ clearAuthTokens }: LoginProps) => {
    const { classes } = useStyles();

    const [helpMode, setHelpMode] = useState<boolean>(false);
    // Only shown to users migrating to awsCognito (and password reset it seems?)
    const [showMigrationForm, setShowMigrationForm] = useState<{
        show: boolean;
        session?: string;
        username?: string;
    }>({ show: false });
    const [tempPasswordToken, setTempPasswordToken] = useState<string>();
    const [showMfaForm, setShowMfaForm] = useState<{
        show: boolean;
        session?: string;
        username?: string;
    }>({ show: false });
    const [showEmailEditPage, setShowEmailEditPage] = useState<boolean>(true);
    const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
    const [emailToReset, setEmailToReset] = useState<string>('');

    const [query] = useQueryParams();
    const prefilledEmail = query.get('email');

    const {
        register: loginFormRegister,
        handleSubmit: loginFormHandleSubmit,
        getValues: loginFormGetValues,
        reset: loginFormReset,
        setValue: loginFormSetValue,
        trigger: loginFormTrigger,
        clearErrors: loginClearErrors,

        formState: { errors: loginFormErrors },
    } = useForm<LoginFormInput>({
        resolver: yupResolver(LOGIN_VALIDATION_SCHEMA as any),
    });

    const [checkEmail] = useLoginAuthMethodLazyQuery({
        onCompleted: data => {
            if (data.loginAuthMethod?.type === 'password') {
                setShowEmailEditPage(false);
            } else if (
                data.loginAuthMethod?.type === 'SAMLv2' &&
                data.loginAuthMethod?.samlRequest &&
                data.loginAuthMethod.samlUrlBase
            ) {
                const form = document.createElement('form');
                form.method = 'POST';
                form.action = data.loginAuthMethod.samlUrlBase;
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = 'SAMLRequest';
                input.value = data.loginAuthMethod?.samlRequest;
                form.appendChild(input);
                document.body.appendChild(form);
                form.submit();
            }
        },
    });

    useEffect(() => {
        if (prefilledEmail && !loginFormGetValues('email')) {
            loginFormSetValue('email', prefilledEmail);
            checkEmail({ variables: { input: { email: prefilledEmail } } });
        }
    }, [checkEmail, loginFormGetValues, loginFormSetValue, prefilledEmail]);

    useEffect(() => {
        if (clearAuthTokens) {
            ClearAuthTokens();
        }
    }, [clearAuthTokens]);

    const {
        register: migrationFormRegister,
        handleSubmit: migrationFormHandleSubmit,
        setValue: migrationFormSetValue,

        formState: { errors: migrationFormErrors },
    } = useForm<LoginMigrationFormInput>({
        resolver: yupResolver(LOGIN_MIGRATION_VALIDATION_SCHEMA as any),
    });

    const {
        register: resetPasswordFormRegister,
        handleSubmit: resetPasswordFormHandleSubmit,
        reset: resetPasswordFormReset,
        setValue: resetPasswordFormSetValue,

        formState: { errors: resetPasswordFormErrors },
    } = useForm<ResetPasswordFormInput>({
        resolver: yupResolver(RESET_PASSWORD_VALIDATION_SCHEMA as any),
    });

    const {
        register: loginWithTOTPFormRegister,
        handleSubmit: loginWithTOTPFormHandleSubmit,
        reset: loginWithTOTPFormReset,
        formState: { errors: loginWithTOTPFormErrors },
    } = useForm<LoginWithTOTPFormInput>({
        resolver: yupResolver(LOGIN_WITH_TOTP_VALIDATION_SCHEMA as any),
    });
    const [fetchUser, { loading: userLoading }] = useFetchCurrentUserLoginLazyQuery({
        onCompleted: data => {
            if (clearAuthTokens) {
                history.replace('/');
            }
            if (data.currentUser) {
                window.location.reload();
            }
        },
    });

    const [loginUser, { loading: loginUserLoading }] = useLoginUserForLoginPageMutation({
        onCompleted: data => {
            if (data.loginUser?.success) {
                // User is migrating from old auth
                if (data.loginUser?.tempPasswordToken) {
                    const email = loginFormGetValues('email');
                    loginFormReset({
                        email,
                    });
                    migrationFormSetValue('email', email, {
                        shouldValidate: false,
                    });
                    setTempPasswordToken(data.loginUser?.tempPasswordToken);
                    setShowMigrationForm({ show: true });
                } else if (data.loginUser?.tokens) {
                    PersistAuthTokens(data.loginUser?.tokens);
                    setShowMigrationForm({ show: false });
                    fetchUser();
                } else {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Error,
                        message: 'An unexpected error occurred, please try again.',
                    });
                }
            }
        },
    });

    const [sendResetPasswordEmail, { loading: resetPasswordLoading }] =
        useResetPasswordLoginPageMutation({
            onCompleted: data => {
                if (data.resetPassword?.success) {
                    resetPasswordFormReset();
                }
            },
        });

    const handleLoginSubmit = async ({ email, password }: LoginFormInput) => {
        try {
            const response = await cognitoPasswordLogin(email, password);
            if (response.ChallengeName === 'SOFTWARE_TOKEN_MFA') {
                loginFormReset();
                loginWithTOTPFormReset();
                setShowMfaForm({ show: true, username: email, session: response.Session });
                return;
            }
            if (response.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                setShowMigrationForm({ show: true, username: email, session: response.Session });
                return;
            }
            await fetchUser();
        } catch (err: any) {
            log.error({ err }, 'handleLoginSubmit');
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `Invalid Credentials: ${err.message}`,
            });
        }
    };

    const handleMfaSubmit = async ({ code }: LoginWithTOTPFormInput) => {
        try {
            const { username, session } = showMfaForm;
            const response = await cognitoMfaChallengeResponse(username!, code, session!);
            if (!response.AuthenticationResult) {
                log.error({ response }, 'handleMfaSubmit: not authenticated');
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: `Unable to login. Please try again.`,
                });
            }
            await fetchUser();
        } catch (err: any) {
            log.error({ err }, 'handleMfaSubmit: error');
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `Error submitting MFA code: ${err.message}`,
            });
        }
    };

    const handleLoginMigrationSubmit = ({
        email,
        newPassword,
        confirmNewPassword,
    }: LoginMigrationFormInput) => {
        if (newPassword !== confirmNewPassword) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `Passwords don't match.`,
            });
            return;
        }
        if (!tempPasswordToken) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `Something went wrong on our end. Please reset your password using the "Password Help" button.`,
            });
            return;
        }
        loginUser({
            variables: {
                input: {
                    email,
                    newPassword,
                    passwordToken: tempPasswordToken,
                },
            },
        });
    };

    const handleResetPasswordSubmit = async ({ email }: ResetPasswordFormInput) => {
        try {
            sendResetPasswordEmail({
                variables: {
                    input: {
                        email,
                    },
                },
            });
            logout(false);
            setEmailToReset(email);
            setIsEmailSent(true);
        } catch (e: any) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `Unable to reset password: ${e.message}`,
            });
        }
    };

    const handleEmailSubmit = () => {
        const email = loginFormGetValues('email');
        if (!email) {
            loginFormTrigger('email', { shouldFocus: false });
            return;
        }
        loginClearErrors('email');
        ClearAuthTokens();
        checkEmail({ variables: { input: { email } } });
    };

    const slideTitle = () => {
        if (helpMode) {
            if (isEmailSent) {
                return 'Email Sent';
            }
            return 'Reset your password';
        }
        return 'Log in to the Wildflower Health Portal';
    };

    return (
        <div className={classes.root}>
            <Card className={classes.loginCard}>
                <Typography variant="h6" className="title">
                    {slideTitle()}
                </Typography>
                <Divider />
                {!helpMode ? (
                    <div>
                        {!showMigrationForm.show ? (
                            <div>
                                {!showMfaForm.show ? (
                                    <div>
                                        {showEmailEditPage ? (
                                            <Slide in direction="right">
                                                <form>
                                                    <div className="inputCont">
                                                        <TextField
                                                            {...loginFormRegister('email')}
                                                            variant="outlined"
                                                            label="Email"
                                                            type="email"
                                                            data-test={LoginEnum.EMAIL_INPUT}
                                                            fullWidth
                                                            error={!!loginFormErrors.email}
                                                            helperText={
                                                                loginFormErrors.email?.message
                                                            }
                                                        />
                                                        <EmailHelpLink />
                                                    </div>
                                                    <div className="bottomControl">
                                                        <Button
                                                            onClick={() => handleEmailSubmit()}
                                                            color="secondary"
                                                            variant="outlined"
                                                        >
                                                            Next
                                                        </Button>
                                                    </div>
                                                </form>
                                            </Slide>
                                        ) : (
                                            <Slide in direction="right">
                                                <form
                                                    onSubmit={loginFormHandleSubmit(
                                                        handleLoginSubmit,
                                                    )}
                                                >
                                                    <div className="inputCont">
                                                        <TextField
                                                            {...loginFormRegister('email')}
                                                            variant="outlined"
                                                            label="Email"
                                                            type="email"
                                                            data-test={LoginEnum.EMAIL_INPUT}
                                                            fullWidth
                                                            disabled
                                                            error={!!loginFormErrors.email}
                                                            helperText={
                                                                loginFormErrors.email?.message
                                                            }
                                                        />
                                                        <TextField
                                                            {...loginFormRegister('password')}
                                                            variant="outlined"
                                                            label="Password"
                                                            type="password"
                                                            data-test={LoginEnum.PASSWORD_INPUT}
                                                            fullWidth
                                                            error={!!loginFormErrors.password}
                                                            helperText={
                                                                loginFormErrors.password?.message
                                                            }
                                                        />
                                                        <EmailHelpLink />
                                                    </div>
                                                    <div className="bottomControl">
                                                        <div className="back">
                                                            <Button
                                                                onClick={() => {
                                                                    setShowEmailEditPage(true);
                                                                    loginFormReset({
                                                                        password: '',
                                                                    });
                                                                }}
                                                                color="secondary"
                                                                variant="outlined"
                                                            >
                                                                Back
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            <Button
                                                                onClick={() => {
                                                                    setHelpMode(true);
                                                                    resetPasswordFormSetValue(
                                                                        'email',
                                                                        loginFormGetValues('email'),
                                                                    );
                                                                }}
                                                                color="secondary"
                                                                variant="text"
                                                            >
                                                                Forgot your password?
                                                            </Button>
                                                            <AsyncActionButton
                                                                loading={
                                                                    loginUserLoading || userLoading
                                                                }
                                                            >
                                                                <Button
                                                                    disabled={
                                                                        loginUserLoading ||
                                                                        userLoading
                                                                    }
                                                                    type="submit"
                                                                    color="primary"
                                                                    variant="contained"
                                                                >
                                                                    Login
                                                                </Button>
                                                            </AsyncActionButton>
                                                        </div>
                                                    </div>
                                                </form>
                                            </Slide>
                                        )}
                                    </div>
                                ) : (
                                    <Slide in direction="right">
                                        <form
                                            onSubmit={loginWithTOTPFormHandleSubmit(
                                                handleMfaSubmit,
                                            )}
                                        >
                                            <div className="inputCont">
                                                <TextField
                                                    {...loginWithTOTPFormRegister('code')}
                                                    variant="outlined"
                                                    label="Code"
                                                    type="code"
                                                    fullWidth
                                                    error={!!loginWithTOTPFormErrors.code}
                                                    helperText={
                                                        loginWithTOTPFormErrors.code?.message
                                                    }
                                                />
                                            </div>
                                            <div className="bottomControl">
                                                <Button
                                                    onClick={() => {
                                                        setShowMfaForm({ show: false });
                                                    }}
                                                    color="secondary"
                                                    variant="outlined"
                                                >
                                                    Back
                                                </Button>
                                                <AsyncActionButton
                                                    loading={loginUserLoading || userLoading}
                                                >
                                                    <Button
                                                        disabled={loginUserLoading || userLoading}
                                                        type="submit"
                                                        color="primary"
                                                        variant="contained"
                                                    >
                                                        Login
                                                    </Button>
                                                </AsyncActionButton>
                                            </div>
                                        </form>
                                    </Slide>
                                )}
                            </div>
                        ) : (
                            <Slide in direction="right">
                                <form
                                    onSubmit={migrationFormHandleSubmit(handleLoginMigrationSubmit)}
                                >
                                    <div className="inputCont">
                                        <TextField
                                            {...migrationFormRegister('email')}
                                            variant="outlined"
                                            label="Email"
                                            type="email"
                                            fullWidth
                                            error={!!migrationFormErrors.email}
                                            helperText={migrationFormErrors.email?.message}
                                        />
                                        <TextField
                                            {...migrationFormRegister('newPassword')}
                                            variant="outlined"
                                            label="New Password"
                                            type="password"
                                            name="newPassword"
                                            fullWidth
                                            error={!!migrationFormErrors.newPassword}
                                            helperText={
                                                migrationFormErrors.newPassword?.message ??
                                                'Passwords must be a minimum of 8 characters, and must contain at least one uppercase character, one lowercase character, one symbol, and one number.'
                                            }
                                        />
                                        <TextField
                                            {...migrationFormRegister('confirmNewPassword')}
                                            variant="outlined"
                                            label="Confirm Password"
                                            type="password"
                                            name="confirmNewPassword"
                                            fullWidth
                                            error={!!migrationFormErrors.confirmNewPassword}
                                            helperText={
                                                migrationFormErrors.confirmNewPassword?.message
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                    <div className="bottomControl">
                                        <Button
                                            onClick={() => {
                                                setHelpMode(true);
                                            }}
                                            color="secondary"
                                            variant="outlined"
                                        >
                                            Password Help
                                        </Button>
                                        <AsyncActionButton
                                            loading={loginUserLoading || userLoading}
                                        >
                                            <Button
                                                disabled={loginUserLoading || userLoading}
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                            >
                                                Login
                                            </Button>
                                        </AsyncActionButton>
                                    </div>
                                </form>
                            </Slide>
                        )}
                    </div>
                ) : (
                    <div>
                        {!isEmailSent ? (
                            <Slide in direction="right">
                                <form
                                    onSubmit={resetPasswordFormHandleSubmit(
                                        handleResetPasswordSubmit,
                                    )}
                                >
                                    <div className="inputCont">
                                        <TextField
                                            {...resetPasswordFormRegister('email')}
                                            variant="outlined"
                                            label="Email Address"
                                            name="email"
                                            type="email"
                                            fullWidth
                                            error={!!resetPasswordFormErrors.email}
                                            helperText={resetPasswordFormErrors.email?.message}
                                        />
                                        <div className={classes.infoText}>
                                            <Typography
                                                align="center"
                                                variant="subtitle2"
                                                fontWeight="bold"
                                                className="infoText"
                                            >
                                                Click on the Reset Button below to receive an email
                                                with a link to create your new password.
                                            </Typography>
                                        </div>
                                    </div>
                                    <div />
                                    <div className="bottomControl">
                                        <div className="back">
                                            <Button
                                                onClick={() => {
                                                    setHelpMode(false);
                                                }}
                                                color="secondary"
                                                variant="outlined"
                                            >
                                                Back
                                            </Button>
                                        </div>
                                        <AsyncActionButton loading={resetPasswordLoading}>
                                            <Button
                                                disabled={resetPasswordLoading}
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                            >
                                                Reset Password
                                            </Button>
                                        </AsyncActionButton>
                                    </div>
                                </form>
                            </Slide>
                        ) : (
                            <Slide in direction="right">
                                <div className={classes.slideContainer}>
                                    <div className={classes.infoText}>
                                        <Typography
                                            align="center"
                                            variant="subtitle2"
                                            fontWeight="bold"
                                            className="infoText"
                                        >
                                            We sent an email to {emailToReset}. Please check your
                                            inbox. If you do not receive the email in a few minutes,
                                            send us a note at{' '}
                                            <Link
                                                rel="noopener noreferrer"
                                                href="mailto:support@wildflowerhealth.com"
                                                underline="hover"
                                            >
                                                support@wildflowerhealth.com
                                            </Link>
                                        </Typography>
                                    </div>
                                </div>
                            </Slide>
                        )}
                    </div>
                )}
            </Card>
        </div>
    );
};

export default Login;
