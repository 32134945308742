import { OffsetType } from '~/enums/enums';
import { TimeUnit } from '~/schemaTypes';

export const MINUTES_IN_DAY = 60 * 24;
// averages for non-leap year
export const MINUTES_IN_MONTH = 43_800;
export const MINUTES_IN_YEAR = 525_600;
export const MINUTES_IN_WEEK = 10_800;

export const toTimeUnit = (m: number): { value: number; unit: TimeUnit } => {
    if (m === 0) {
        return {
            value: 0,
            unit: TimeUnit.Days,
        };
    }

    if (m % MINUTES_IN_YEAR === 0) {
        return {
            value: m / MINUTES_IN_YEAR,
            unit: TimeUnit.Years,
        };
    }

    if (m % MINUTES_IN_MONTH === 0) {
        return {
            value: m / MINUTES_IN_MONTH,
            unit: TimeUnit.Months,
        };
    }

    return {
        value: Math.floor(m / MINUTES_IN_DAY),
        unit: TimeUnit.Days,
    };
};

export const toMinutes = (value: number, unit: TimeUnit): number => {
    switch (unit) {
        case TimeUnit.Days:
            return value * MINUTES_IN_DAY;
        case TimeUnit.Months:
            return value * MINUTES_IN_MONTH;
        case TimeUnit.Years:
            return value * MINUTES_IN_YEAR;
        default:
            throw new Error(`Unhandled TimeUnit: ${unit}`);
    }
};

export const getOffsets = (
    offsetDays: number,
    offsetMonths: number,
    offsetYears: number,
): { days: number; months: number; years: number; type: OffsetType } => {
    return {
        days: Math.abs(offsetDays),
        months: Math.abs(offsetMonths),
        years: Math.abs(offsetYears),
        type:
            offsetDays >= 0 && offsetMonths >= 0 && offsetYears >= 0
                ? OffsetType.After
                : OffsetType.Before,
    };
};
