import React from 'react';
import {
    DragDropContext,
    DragDropContextProps,
    Droppable as DNDDroppable,
} from 'react-beautiful-dnd';

type DroppableProps = {
    onDragEnd: DragDropContextProps['onDragEnd'];
    droppableId: string;
    styles?: {
        [key: string]: string;
    };
    children: React.ReactNode;
};

const Droppable: React.FC<DroppableProps> = ({ onDragEnd, children, droppableId, styles }) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <DNDDroppable droppableId={droppableId}>
                {provided => (
                    <div
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            overflowY: 'auto',
                            ...styles,
                        }}
                    >
                        {children}
                        {provided.placeholder}
                    </div>
                )}
            </DNDDroppable>
        </DragDropContext>
    );
};

export default Droppable;
