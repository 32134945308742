import { ApolloCache, FetchResult, useMutation } from '@apollo/client';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Step,
    StepLabel,
    Stepper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CloudDownload, InfoOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material';
import { DocumentNode } from 'graphql';
import { Parser } from 'json2csv';
import _ from 'lodash';
import React, { ReactElement, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import CSVReader, { IFileInfo } from 'react-csv-reader';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Loading from '~/components/Loading/Loading';
import ReactHookFormSelect from '../ReactHookFormSelect/ReactHookFormSelect';

const useStyles = makeStyles()({
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: 30,
        minHeight: 400,
        '& > .CSVButtonCont': {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'stretch',
            marginBottom: 20,
            '& .csvButtonCont': {
                display: 'flex',
                width: '45%',
                flexDirection: 'column',
                alignSelf: 'stretch',
                border: '1px solid lightgray',
                borderRadius: 20,
                padding: 20,
                justifyContent: 'space-between',
                overflow: 'hidden',
            },
            '& .CSVLinkButton': {
                width: '45%',
                border: '1px solid lightgray',
                borderRadius: 20,
                padding: 20,
                paddingLeft: 40,
                textAlign: 'center',
                '&:hover': {
                    color: 'inherit',
                },
            },
        },
        '& > .fileUploadedCont': {
            display: 'flex',
            alignSelf: 'center',
            flexDirection: 'column',
            width: '75%',
            alignItems: 'center',
            marginBottom: 20,
        },
        '& > .listCont': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            marginBottom: 20,
            '& > .MuiListItem-root': {
                flexBasis: '45%',
            },
            '&:after': {
                content: "''",
                width: '45%',
            },
        },
        '& .selectColumnsCont': {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: 20,
            '& > .listRow': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: '100%',
                '& > .selectListItem': {
                    width: '40%',
                },
                '& > .selectListItemInput': {
                    width: '55%',
                },
            },
        },
        '& > .reviewTable': {
            marginBottom: 20,
            alignSelf: 'center',
        },
        '& > .uploadButton': {
            width: 300,
            alignSelf: 'center',
            marginBottom: 20,
        },
        '& > .validationRows': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            '& > .valCol': {
                width: '45%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 20,
                textAlign: 'center',
            },
        },
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 30px',
        '& > .rightDiv': {
            '& > .MuiButton-root:not(:last-child)': {
                marginRight: 20,
            },
        },
    },
});

const ImportSteps = [
    {
        label: 'Import CSV',
    },
    {
        label: 'Select CSV Columns',
    },
    {
        label: 'Upload',
    },
];

type ConstantFieldOption = {
    label: string;
    value: string;
};

type ConstantField = {
    options: ConstantFieldOption[];
    name: string;
    label: string;
    required: boolean;
    defaultValue: string;
};

type ResourceField = {
    name: string;
    label: string;
    required: boolean;
    description?: string;
    example: string;
    isDate?: boolean;
};

export type UploadEntry = {
    row: number;
    error: string;
};

type UploadConfiguration<T> = {
    mutation: DocumentNode;
    options: {
        onCompleted: (data: T) => { success: boolean; message: string }[] | null;
        onUpdate: (cache: ApolloCache<T>, response: FetchResult<T>) => void;
    };
};

type CSVImportModalProps<T> = {
    validationSchema: yup.ObjectSchema<any>;
    modelLabel: string;
    fields: ResourceField[];
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    constantFields?: ConstantField[];
    uploadConfiguration: UploadConfiguration<T>;
    fieldMapper?: { [key: string]: (field: string) => any };
    omitNullValues?: boolean;
};

const CSVImportModal = <T,>({
    fields,
    setOpen,
    modelLabel,
    constantFields,
    validationSchema,
    uploadConfiguration,
    fieldMapper,
    omitNullValues = false,
}: CSVImportModalProps<T>): ReactElement | null => {
    const { classes } = useStyles();
    const { mutation, options: uploadOptions } = uploadConfiguration;
    const { control, handleSubmit } = useForm();

    const [activeStep, setActiveStep] = useState<number>(0);
    const [CSVData, setCSVData] = useState<Array<any>>([]);
    const [CSVHeaders, setCSVHeaders] = useState<Array<string>>([]);
    const [CSVFileInfo, setCSVFileInfo] = useState<IFileInfo>();
    const [columnBindings, setColumnBindings] = useState<Record<string, string>>({});
    const [validRows, setValidRows] = useState<Record<any, any>[]>([]);
    const [rowsValidated, setRowsValidated] = useState<boolean>(false);
    const [successfulUploads, setSuccessfulUploads] = useState<UploadEntry[]>([]);
    const [failedUploads, setFailedUploads] = useState<UploadEntry[]>([]);
    const [uploadComplete, setUploadComplete] = useState<boolean>(false);
    const [invalidRows, setInvalidRows] = useState<
        {
            resource: Record<any, any>;
            isValid: boolean;
            error?: { name: any; errors: any[] };
            index: number;
        }[]
    >([]);

    const onClose = () => {
        setOpen(false);
    };

    const [upload, { loading: isUploadLoading }] = useMutation(mutation, {
        onCompleted: data => {
            const processedData: { success: boolean; message: string }[] | null =
                uploadOptions.onCompleted(data);
            if (processedData) {
                setUploadComplete(true);

                const successfulUploads: Array<UploadEntry> = [];
                const failedUploads: Array<UploadEntry> = [];

                processedData.forEach(({ success, message }, index) => {
                    if (success) {
                        successfulUploads.push({ error: '', row: index });
                    } else {
                        failedUploads.push({
                            error: message,
                            row: index,
                        });
                    }
                });

                setSuccessfulUploads(successfulUploads);
                setFailedUploads(failedUploads);
            }
        },
        update: uploadOptions.onUpdate,
    });

    const exampleCSV = useMemo<Array<any>>(() => {
        const headerRow = fields.map(({ name }) => name);
        const values = fields.map(({ example }) => example);
        return [headerRow, values];
    }, [fields]);

    const errorCSV = useMemo<Array<any>>(() => {
        const headerRow = ['Row Number in original document', 'Error'];
        return [headerRow, ...failedUploads.map(({ row, error }) => [row, error])];
    }, [failedUploads]);

    const handleValidation = async () => {
        // Convert data to array of object
        const valueParser = ({ value, isDate }: { value: any; isDate: boolean }) => {
            if (!value) {
                return null;
            }
            if (isDate) {
                return new Date(value);
            }

            if (typeof value === 'object') {
                return value;
            }

            return String(value);
        };
        const resources = CSVData.map(row => {
            const constantFieldValues = constantFields?.reduce(
                (current, { name }) => ({ ...current, [name]: columnBindings[name] }),
                {},
            );
            const fieldValues = fields.reduce((current, { name, isDate = false }) => {
                // CSV Handle nested keys
                if (name.includes('_')) {
                    const keys = name.split('_');
                    const nestedObj = keys.reduceRight((currentNestedObj, key, index, keyArr) => {
                        // Last key (first from the right)
                        if (index === keyArr.length - 1) {
                            const value = valueParser({ value: row[columnBindings[name]], isDate });
                            if (!value) {
                                return {};
                            }
                            return {
                                [key]: value,
                            };
                        }
                        if (
                            Object.keys(currentNestedObj).every(key => {
                                const value = (currentNestedObj as Record<string, unknown>)[key];
                                return value == null;
                            })
                        ) {
                            return {};
                        }
                        return { [key]: currentNestedObj };
                    }, {});

                    return _.merge(current, nestedObj);
                }

                if (omitNullValues && !row[columnBindings[name]]) {
                    return current;
                }

                return {
                    ...current,
                    [name]: valueParser({ value: row[columnBindings[name]], isDate }),
                };
            }, {});
            return {
                ...constantFieldValues,
                ...fieldValues,
            };
        });
        const validatedResources = await Promise.all(
            resources.map((resource, i) => {
                return new Promise<{
                    resource: Record<any, any>;
                    isValid: boolean;
                    error?: { name: any; errors: any[] };
                    index: number;
                }>(resolve => {
                    validationSchema
                        .validate(resource)
                        .then(() => {
                            resolve({ resource, isValid: true, index: i });
                        })
                        .catch(err => {
                            resolve({
                                resource,
                                isValid: false,
                                error: err,
                                index: i,
                            });
                        });
                });
            }),
        );
        setValidRows(_.filter(validatedResources, 'isValid').map(({ resource }) => resource));
        setInvalidRows(_.filter(validatedResources, ({ isValid }) => !isValid));
        setRowsValidated(true);
    };

    const handleUpload = () => {
        upload({
            variables: {
                input: {
                    data: validRows as any,
                },
            },
        });
    };

    const handleColumnSelection = (formInput: Record<any, any>) => {
        setColumnBindings(formInput);
        setActiveStep(current => current + 1);
    };

    const stepsComplete = [!!CSVFileInfo?.name, true, uploadComplete];

    return (
        <>
            <DialogTitle>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {ImportSteps.map(({ label }) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </DialogTitle>
            <DialogContent className={classes.content} dividers>
                {activeStep === 0 && (
                    <>
                        <Typography gutterBottom variant="h6">
                            Lets begin by uploading your {modelLabel}s CSV File:
                        </Typography>
                        <Typography paragraph variant="subtitle2">
                            Your .csv file must contain columns for the following fields marked as
                            required, and can also contain optional fields as well. Don&apos;t worry
                            if the columns names do not match up, we will allow you to pick columns
                            in the next step.
                        </Typography>
                        <div className="CSVButtonCont">
                            <CSVReader
                                accept=".csv"
                                label="Choose CSV File"
                                cssClass="csvButtonCont"
                                onFileLoaded={(data, fileInfo) => {
                                    setCSVHeaders(Object.keys(data[0]));
                                    setCSVData(
                                        data.map(o =>
                                            Object.fromEntries(
                                                Object.entries(o)
                                                    .filter(([_, v]) => v != null)
                                                    .map(([key, value]) =>
                                                        fieldMapper?.[key]
                                                            ? [
                                                                  key,
                                                                  fieldMapper?.[key](
                                                                      value as string,
                                                                  ),
                                                              ]
                                                            : [key, value],
                                                    ),
                                            ),
                                        ),
                                    );
                                    setCSVFileInfo(fileInfo);
                                }}
                                parserOptions={{
                                    header: true,
                                    dynamicTyping: true,
                                    skipEmptyLines: true,
                                }}
                            />
                            <Button
                                startIcon={<CloudDownload color="primary" />}
                                className="CSVLinkButton"
                                component={CSVLink}
                                data={exampleCSV}
                                filename={`Wildflower Health Example ${modelLabel} Import CSV`}
                            >
                                Download Example {modelLabel} CSV
                            </Button>
                        </div>
                        {CSVFileInfo?.name && (
                            <Fade in timeout={500}>
                                <div className="fileUploadedCont">
                                    <Typography paragraph color="primary" variant="body1">
                                        {CSVFileInfo?.name}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        onClick={() => setActiveStep(current => current + 1)}
                                    >
                                        File Selected - Click to Continue
                                    </Button>
                                </div>
                            </Fade>
                        )}
                        <Typography gutterBottom paragraph variant="subtitle2">
                            We do not save information in your CSV other than inside the columns you
                            specify. Your CSV file stays inside your browser and is never uploaded
                            to our servers.
                        </Typography>
                        <Typography gutterBottom variant="h6">
                            {modelLabel} Columns:
                        </Typography>
                        <List className="listCont">
                            {fields.map(({ name, label, required }) => (
                                <ListItem key={name}>
                                    <ListItemIcon>
                                        <KeyboardArrowRightOutlined color="primary" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={label}
                                        secondary={
                                            required ? (
                                                <Typography color="primary" variant="body2">
                                                    Required
                                                </Typography>
                                            ) : (
                                                'Optional'
                                            )
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}
                {activeStep === 1 && (
                    <>
                        <Typography gutterBottom variant="h6">
                            Next lets match the columns inside your CSV with the fields on a given{' '}
                            {modelLabel}.
                        </Typography>
                        <Typography gutterBottom variant="subtitle2">
                            Below on the left will be a field for a {modelLabel}, and on the right
                            there is a drop down that shows the columns of your uploaded CSV file.
                        </Typography>
                        <form onSubmit={handleSubmit(handleColumnSelection)}>
                            <List className="selectColumnsCont">
                                {constantFields && (
                                    <Typography paragraph variant="subtitle2">
                                        These fields are not in your CSV but are required for our
                                        system, please choose an option that will apply to all
                                        {` ${modelLabel.toLowerCase()}s`} your are importing.
                                    </Typography>
                                )}
                                {constantFields?.map(
                                    ({ name, label, required, options, defaultValue }) => (
                                        <div className="listRow" key={name}>
                                            <ListItem className="selectListItem">
                                                <ListItemIcon>
                                                    <KeyboardArrowRightOutlined color="primary" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={label}
                                                    secondary={
                                                        required ? (
                                                            <Typography
                                                                color="primary"
                                                                variant="body2"
                                                            >
                                                                Required
                                                            </Typography>
                                                        ) : (
                                                            'Optional'
                                                        )
                                                    }
                                                />
                                            </ListItem>
                                            <ReactHookFormSelect
                                                className="selectListItemInput"
                                                control={control}
                                                defaultValue={defaultValue}
                                                name={name}
                                                variant="outlined"
                                                label={label}
                                                rules={{ required }}
                                            >
                                                {options.map(({ value, label: optionLabel }) => (
                                                    <MenuItem
                                                        key={`${name}input-${value}`}
                                                        value={value}
                                                    >
                                                        {optionLabel}
                                                    </MenuItem>
                                                ))}
                                            </ReactHookFormSelect>
                                        </div>
                                    ),
                                )}
                                <Typography paragraph variant="subtitle2">
                                    Please select the CSV column that matches a given {modelLabel}{' '}
                                    field.
                                </Typography>
                                {fields.map(({ name, label, required }) => (
                                    <div className="listRow" key={name}>
                                        <ListItem className="selectListItem">
                                            <ListItemIcon>
                                                <KeyboardArrowRightOutlined color="primary" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={label}
                                                secondary={
                                                    required ? (
                                                        <Typography color="primary" variant="body2">
                                                            Required
                                                        </Typography>
                                                    ) : (
                                                        'Optional'
                                                    )
                                                }
                                            />
                                        </ListItem>
                                        <ReactHookFormSelect
                                            className="selectListItemInput"
                                            control={control}
                                            defaultValue={
                                                CSVHeaders.find(header =>
                                                    name.startsWith(header),
                                                ) ?? CSVHeaders[0]
                                            }
                                            name={name}
                                            variant="outlined"
                                            label={label}
                                            rules={{ required }}
                                        >
                                            {CSVHeaders.map(key => (
                                                <MenuItem key={`${name}input-${key}`} value={key}>
                                                    {key}
                                                </MenuItem>
                                            ))}
                                        </ReactHookFormSelect>
                                    </div>
                                ))}
                            </List>
                        </form>
                    </>
                )}
                {activeStep === 2 && (
                    <div>
                        {!isUploadLoading ? (
                            <>
                                {uploadComplete && (
                                    <Fade in timeout={500}>
                                        <Grid
                                            container
                                            alignItems="center"
                                            direction="column"
                                            spacing={2}
                                        >
                                            <Grid item>
                                                <InfoOutlined
                                                    style={{
                                                        alignSelf: 'center',
                                                        textAlign: 'center',
                                                        height: 50,
                                                        width: 50,
                                                    }}
                                                    color="secondary"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    paragraph
                                                    variant="h6"
                                                    style={{
                                                        alignSelf: 'center',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {modelLabel} Upload Complete
                                                </Typography>
                                            </Grid>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={6}>
                                                    <Card elevation={0} style={{ padding: 5 }}>
                                                        <CardContent>
                                                            <Typography
                                                                style={{
                                                                    alignSelf: 'center',
                                                                    textAlign: 'center',
                                                                }}
                                                                paragraph
                                                                color="secondary"
                                                                variant="h6"
                                                            >
                                                                {successfulUploads.length}{' '}
                                                                {modelLabel}s uploaded successfully.
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    alignSelf: 'center',
                                                                    textAlign: 'center',
                                                                }}
                                                                paragraph
                                                                variant="body1"
                                                            >
                                                                These patients are now in our
                                                                system.
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                {failedUploads.length > 0 && (
                                                    <Grid item xs={6}>
                                                        <Card elevation={0} style={{ padding: 5 }}>
                                                            <CardContent>
                                                                <Typography
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                        textAlign: 'center',
                                                                    }}
                                                                    paragraph
                                                                    color="error"
                                                                    variant="h6"
                                                                >
                                                                    {failedUploads.length}{' '}
                                                                    {modelLabel}s failed to upload.
                                                                </Typography>
                                                                <Typography
                                                                    style={{
                                                                        alignSelf: 'center',
                                                                        textAlign: 'center',
                                                                    }}
                                                                    paragraph
                                                                    variant="body1"
                                                                >
                                                                    Please download the csv for more
                                                                    information.
                                                                </Typography>
                                                            </CardContent>
                                                            <CardActions
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <Button
                                                                    startIcon={
                                                                        <CloudDownload color="primary" />
                                                                    }
                                                                    disabled={
                                                                        failedUploads.length === 0
                                                                    }
                                                                    className="CSVLinkButton"
                                                                    component={CSVLink}
                                                                    data={errorCSV}
                                                                    filename={`Failed ${modelLabel} Import CSV`}
                                                                >
                                                                    Download Error CSV
                                                                </Button>
                                                            </CardActions>
                                                        </Card>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Fade>
                                )}
                                {rowsValidated && !uploadComplete && (
                                    <>
                                        <Typography
                                            style={{ alignSelf: 'center', textAlign: 'center' }}
                                            paragraph
                                            variant="h6"
                                        >
                                            {modelLabel} CSV Validation Complete
                                        </Typography>
                                        <div className="validationRows">
                                            <div className="valCol">
                                                <Typography
                                                    paragraph
                                                    variant="h4"
                                                    color="secondary"
                                                >
                                                    {validRows.length}
                                                </Typography>
                                                <Typography paragraph variant="h6">
                                                    {modelLabel}
                                                    {validRows.length > 1 ? 's' : ''} successfully
                                                    validated
                                                </Typography>
                                                <Button variant="contained" onClick={handleUpload}>
                                                    Upload {validRows.length} Valid {modelLabel}
                                                    {validRows.length > 1 ? 's' : ''}
                                                </Button>
                                            </div>
                                            {invalidRows.length > 0 && (
                                                <div className="valCol">
                                                    <Typography
                                                        paragraph
                                                        variant="h4"
                                                        color="error"
                                                    >
                                                        {invalidRows.length}
                                                    </Typography>
                                                    <Typography paragraph variant="h6">
                                                        {modelLabel}
                                                        {invalidRows.length > 1 ? 's' : ''} had
                                                        validation errors:
                                                    </Typography>
                                                    {_(invalidRows)
                                                        .map(
                                                            ({ error }) =>
                                                                error?.errors as string[],
                                                        )
                                                        .flattenDeep()
                                                        .slice(0, 5)
                                                        .map(errorString => (
                                                            <Typography
                                                                key={errorString}
                                                                paragraph
                                                                color="error"
                                                                variant="subtitle2"
                                                            >
                                                                {errorString.slice(0, 30)}...
                                                            </Typography>
                                                        ))
                                                        .value()}
                                                    <Typography paragraph variant="subtitle2">
                                                        You may proceed by uploading valid{' '}
                                                        {modelLabel}s or you can adjust your csv to
                                                        fix the invalid rows and restart the
                                                        importing process. Click the
                                                    </Typography>
                                                    <Button
                                                        startIcon={
                                                            <CloudDownload color="primary" />
                                                        }
                                                        className="CSVLinkButton"
                                                        component={CSVLink}
                                                        data={new Parser().parse(
                                                            CSVData.map(
                                                                (original, csvRowIndex) => ({
                                                                    errors: invalidRows.find(
                                                                        ({ index }) =>
                                                                            csvRowIndex === index,
                                                                    )?.error?.errors,
                                                                    ...original,
                                                                }),
                                                            ),
                                                        )}
                                                        filename={`${CSVFileInfo?.name}-with-errors.csv`}
                                                    >
                                                        Download Error {modelLabel} CSV
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                                {!rowsValidated && (
                                    <>
                                        <Typography gutterBottom variant="h6">
                                            Lets validate your CSV data before we proceed with the
                                            upload
                                        </Typography>
                                        <Button
                                            className="uploadButton"
                                            variant="contained"
                                            onClick={handleValidation}
                                        >
                                            Validate {CSVData.length} {modelLabel}
                                            {CSVData.length > 1 ? 's' : ''}
                                        </Button>
                                        <Table
                                            className="reviewTable"
                                            size="small"
                                            aria-label="Column Review"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">
                                                        {modelLabel} Field
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        CSV Column / Input
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.keys(columnBindings).map(key => (
                                                    <TableRow key={key}>
                                                        <TableCell align="center">{key}</TableCell>
                                                        <TableCell align="center">
                                                            {columnBindings[key]}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </>
                                )}
                            </>
                        ) : (
                            <Fade in timeout={500}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="h6">
                                        {rowsValidated ? 'Uploading' : 'Validating'}{' '}
                                        {`${modelLabel}s`}...
                                    </Typography>
                                    <Loading height={400} />
                                </div>
                            </Fade>
                        )}
                    </div>
                )}
            </DialogContent>
            <DialogActions className={classes.footer}>
                <Button
                    onClick={() => {
                        onClose();
                        setOpen(false);
                    }}
                >
                    Cancel
                </Button>
                <div className="rightDiv">
                    <Button
                        variant="contained"
                        disabled={!stepsComplete[activeStep]}
                        onClick={() => {
                            if (activeStep === 2) {
                                onClose();
                                setOpen(false);
                            }
                            if (activeStep === 1) {
                                handleSubmit(handleColumnSelection)();
                            } else {
                                setActiveStep(current => current + 1);
                            }
                        }}
                    >
                        {activeStep === 2 ? 'Finish' : 'Next'}
                    </Button>
                </div>
            </DialogActions>
        </>
    );
};

export default CSVImportModal;
