import * as Yup from 'yup';

export const REFERENCE_LINK_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Required'),
    organizations: Yup.array().min(1).required('Required'),
    category: Yup.string().nullable(),
    roles: Yup.array().min(1).required('Required'),
    pages: Yup.array().min(1).required('Required'),
    url: Yup.string().min(1).required('Required'),
    priority: Yup.number(),
});
