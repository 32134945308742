import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Button,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const SLATimersFilters = ({ handleFiltersChange }: { handleFiltersChange: () => void }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<string>(() => {
        const savedFilters = localStorage.getItem('slaTimersFilters');
        return savedFilters ? JSON.parse(savedFilters).dateRange : 'past3Days';
    });
    const [showCompleted, setShowCompleted] = useState<boolean>(() => {
        const savedFilters = localStorage.getItem('slaTimersFilters');
        return savedFilters ? JSON.parse(savedFilters).showCompleted : false;
    });
    const [customStartDate, setCustomStartDate] = useState<Date | null>(() => {
        const savedFilters = localStorage.getItem('slaTimersFilters');
        return savedFilters ? new Date(JSON.parse(savedFilters).customStartDate) : null;
    });
    const [customEndDate, setCustomEndDate] = useState<Date | null>(() => {
        const savedFilters = localStorage.getItem('slaTimersFilters');
        return savedFilters ? new Date(JSON.parse(savedFilters).customEndDate) : null;
    });
    const [initialState, setInitialState] = useState(() => ({
        dateRange,
        showCompleted,
        customStartDate,
        customEndDate,
    }));

    const handleClose = () => {
        // Reset state to initial values
        setDateRange(initialState.dateRange);
        setShowCompleted(initialState.showCompleted);
        setCustomStartDate(initialState.customStartDate);
        setCustomEndDate(initialState.customEndDate);
        setOpen(false);
    };

    const handleApply = () => {
        localStorage.setItem(
            'slaTimersFilters',
            JSON.stringify({ dateRange, showCompleted, customStartDate, customEndDate }),
        );
        handleFiltersChange();
        // Update initial state after applying changes
        setInitialState({ dateRange, showCompleted, customStartDate, customEndDate });
        setOpen(false);
    };

    return (
        <div>
            <Button variant="contained" onClick={() => setOpen(true)}>
                Open Filters
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Filters
                        </Typography>
                        <Button onClick={handleClose}>&times;</Button>
                    </Box>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="date-range-label">Date Range</InputLabel>
                        <Select
                            labelId="date-range-label"
                            value={dateRange}
                            label="Date Range"
                            onChange={e => setDateRange(e.target.value)}
                        >
                            <MenuItem value="past3Days">Past 3 Days</MenuItem>
                            <MenuItem value="past7Days">Past 7 Days</MenuItem>
                            <MenuItem value="past30Days">Past 30 Days</MenuItem>
                            <MenuItem value="custom">Custom Range</MenuItem>
                        </Select>
                    </FormControl>
                    {dateRange === 'custom' && (
                        <Box sx={{ mt: 2 }}>
                            <DatePicker
                                label="Start Date"
                                value={customStartDate}
                                onChange={newValue => {
                                    if (newValue) {
                                        setCustomStartDate(newValue);
                                        // Ensure end date is within one month of start date
                                        const maxEndDate = new Date(newValue);
                                        maxEndDate.setMonth(maxEndDate.getMonth() + 1);
                                        if (customEndDate && customEndDate > maxEndDate) {
                                            setCustomEndDate(maxEndDate);
                                        }
                                    }
                                }}
                            />
                            <DatePicker
                                label="End Date"
                                value={customEndDate}
                                onChange={newValue => {
                                    if (newValue) {
                                        setCustomEndDate(newValue);
                                        // Ensure start date is within one month of end date
                                        const minStartDate = new Date(newValue);
                                        minStartDate.setMonth(minStartDate.getMonth() - 1);
                                        if (customStartDate && customStartDate < minStartDate) {
                                            setCustomStartDate(minStartDate);
                                        }
                                    }
                                }}
                                minDate={customStartDate || undefined}
                                maxDate={
                                    customStartDate
                                        ? new Date(
                                              new Date(customStartDate).setMonth(
                                                  new Date(customStartDate).getMonth() + 1,
                                              ),
                                          )
                                        : undefined
                                }
                            />
                        </Box>
                    )}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showCompleted}
                                onChange={e => setShowCompleted(e.target.checked)}
                            />
                        }
                        label="Show Completed SLAs"
                        sx={{ mt: 2 }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        <Button onClick={handleClose} sx={{ mr: 2 }}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleApply}>
                            OK
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};
