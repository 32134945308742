import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Dialog } from '@mui/material';
import LactationConsultantsList from '~/views/AffiliateCare/components/LactationConsultants/LactationConsultants';
import ConsultantForm, {
    FormInput as LactationConsultantFormInput,
    FormMode,
} from '~/views/AffiliateCare/components/ConsultantForm/ConsultantForm';

const LactationConsultants: React.FC = () => {
    const { setValue, watch } = useFormContext();
    const consultants = watch('lactationConsultants') as LactationConsultantFormInput[];
    const [showConsultantForm, setShowConsultantForm] = useState(false);
    const [editableConsultant, setEditableConsultant] =
        useState<null | LactationConsultantFormInput>(null);
    const [editableConsultantIndex, setEditableConsultantIndex] = useState<null | number>(null);
    const setConsultants = useCallback((newData: LactationConsultantFormInput[]) => {
        setValue('lactationConsultants', newData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const addNewHandler = useCallback(() => {
        setShowConsultantForm(true);
        setEditableConsultantIndex(null);
        setEditableConsultant(null);
    }, []);
    const editHandler = useCallback(
        (index: number) => {
            const selectedConsultant = consultants[index];
            if (selectedConsultant) {
                setEditableConsultant(selectedConsultant);
                setEditableConsultantIndex(index);
                setShowConsultantForm(true);
            }
        },
        [consultants],
    );
    const deleteHandler = useCallback(
        (removeIndex: number) => {
            const newConsultants = consultants.filter((c, index) => index !== removeIndex);
            setConsultants(newConsultants);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [consultants],
    );
    const formCompleteCb = useCallback(
        (close: boolean, consultant?: LactationConsultantFormInput) => {
            if (consultant) {
                if (editableConsultantIndex !== null) {
                    const newConsultants = consultants.reduce((acc, item, index) => {
                        if (index === editableConsultantIndex) {
                            acc.push(consultant);
                        } else {
                            acc.push(item);
                        }
                        return acc;
                    }, [] as LactationConsultantFormInput[]);
                    setConsultants(newConsultants);
                } else {
                    setConsultants([...consultants, consultant]);
                }
            }
            setShowConsultantForm(!close);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [consultants, editableConsultantIndex],
    );
    return (
        <>
            <LactationConsultantsList
                addNewHandler={addNewHandler}
                consultants={consultants}
                editHandler={editHandler}
                deleteHandler={deleteHandler}
            />
            <Dialog open={showConsultantForm} scroll="paper" fullWidth maxWidth="lg">
                <ConsultantForm
                    isEdit={!!editableConsultant}
                    onComplete={formCompleteCb}
                    data={editableConsultant}
                    mode={FormMode.Portal}
                />
            </Dialog>
        </>
    );
};

export default LactationConsultants;

export type { LactationConsultantFormInput };
