import { ActionType, ChecklistItemForm, ChecklistItemInput } from '../types';

export const INITIAL_ACTION_TYPE: ActionType = { typeId: '', newActionTypeLabel: '' };

export const getInitialModalData = (
    data: ChecklistItemInput | null,
    completeMode?: boolean,
): ChecklistItemForm => {
    const {
        id,
        label = '',
        description = '',
        actionTypeIds = [],
        completedAt,
        completedBy,
        endSlaTimerOnComplete,
    } = data ?? {};
    const isCompleteMode = Boolean(completeMode);
    const hasActionTypes = Boolean(actionTypeIds.length > 0);
    const isNewChecklistItem = Boolean(!id);

    return {
        id,
        name: label,
        description,
        actionTypes:
            actionTypeIds.length === 0
                ? [INITIAL_ACTION_TYPE]
                : actionTypeIds.map(typeId => ({ typeId })),
        shouldCreateAction: !isCompleteMode && (isNewChecklistItem || hasActionTypes),
        completedAt: completedAt ?? new Date().toISOString(),
        completedBy,
        endSlaTimerOnComplete,
    };
};
