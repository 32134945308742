export const FILE_TYPE_TO_NAME_MAP = new Map([
    ['image/*', 'image'],
    ['application/pdf', 'pdf'],
]);
export const FILE_ACCEPT = Array.from(FILE_TYPE_TO_NAME_MAP.keys()).join(', ');

export enum FormMode {
    Onboarding,
    Portal,
}

export const MAX_FILE_SIZE = 10 * 1000000; // 10 Mb
