import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, MenuItem, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import {
    CareTeamContactInput,
    useCareTeamContactLazyQuery,
    useCareTeamMemberTypesQuery,
} from '~/schemaTypes';
import { OVERRIDEN_CONTACT } from '../../CareTeamMembers/CareTeamMembersModal/CareTeamMembersModal';

type CareTeamContactModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    createCareTeamContact?: any;
    updateCareTeamContact?: any;
    isCreateCareTeamContactLoading?: boolean;
    isUpdateCareTeamContactLoading?: boolean;
    id?: string;
    isEditMode?: boolean;
    isOverrideMode?: boolean;
    overrideCB?: (contactData: CareTeamContactInput) => void;
    defaultOverridenContactValues?: CareTeamContactInput | undefined;
};

const CARE_TEAM_CONTACTS_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Required'),
    phoneNumber: Yup.string().required('Required'),
    address: Yup.object()
        .nullable()
        .shape({
            street1: Yup.string().required('Required'),
            street2: Yup.string().required('Required'),
            city: Yup.string().required('Required'),
            state: Yup.string().required('Required'),
            code: Yup.string().required('Required'),
        }),
    emailAddress: Yup.string().email('Invalid Email').required('Email is required'),
    url: Yup.string().required('Required'),
    careTeamMemberTypeId: Yup.string().required('Required'),
});

interface CareTeamContactFormInput {
    name: string;
    phoneNumber: string;
    address: {
        street1: string;
        street2: string;
        city: string;
        state: string;
        code: string;
    };
    emailAddress: string;
    url: string;
    careTeamMemberTypeId: string;
}

const CareTeamContactModal: React.FC<CareTeamContactModalProps> = ({
    setOpen,
    id,
    isEditMode,
    createCareTeamContact,
    updateCareTeamContact,
    isCreateCareTeamContactLoading,
    isUpdateCareTeamContactLoading,
    isOverrideMode,
    overrideCB,
    defaultOverridenContactValues,
}) => {
    const {
        register,
        handleSubmit,
        control,
        reset,

        formState: { errors },
    } = useForm<CareTeamContactFormInput>({
        resolver: yupResolver(CARE_TEAM_CONTACTS_VALIDATION_SCHEMA as any),
    });

    const resetContactValues = useCallback(
        (contactData: CareTeamContactInput) => {
            const { name, phoneNumber, address, emailAddress, url, careTeamMemberTypeId } =
                contactData;

            reset({
                name: name ?? '',
                phoneNumber: phoneNumber ?? '',
                address: {
                    street1: address?.street1 ?? '',
                    street2: address?.street2 ?? '',
                    state: address?.state ?? '',
                    city: address?.city ?? '',
                    code: address?.code ?? '',
                },
                emailAddress: emailAddress ?? '',
                url: url ?? '',
                careTeamMemberTypeId: careTeamMemberTypeId ?? '',
            });
        },
        [reset],
    );

    const { data: careTeamMemberTypes, loading: careTeamMemberTypesLoading } =
        useCareTeamMemberTypesQuery();

    const [getCareTeamContact, { loading: careTeamContactLoading }] = useCareTeamContactLazyQuery({
        onCompleted: ({ careTeamContact }) => {
            if (careTeamContact) {
                resetContactValues(careTeamContact);
            }
        },
    });

    const onSubmit = (data: CareTeamContactInput) => {
        if (isEditMode) {
            updateCareTeamContact({
                variables: {
                    input: {
                        id,
                        data: {
                            name: data.name,
                            phoneNumber: data.phoneNumber,
                            address: data.address,
                            emailAddress: data.emailAddress,
                            url: data.url,
                        },
                    },
                },
            });
        } else if (isOverrideMode && overrideCB) {
            setOpen(false);
            overrideCB(data);
        } else {
            createCareTeamContact({
                variables: {
                    input: {
                        ...data,
                    },
                },
            });
        }
    };

    const handleCancel = () => {
        setOpen(false);
    };

    useEffect(() => {
        if ((isEditMode || isOverrideMode) && id && !defaultOverridenContactValues)
            getCareTeamContact({ variables: { input: { id } } });
    }, [isEditMode, id, getCareTeamContact, isOverrideMode, defaultOverridenContactValues]);

    useEffect(() => {
        if (defaultOverridenContactValues) {
            resetContactValues(defaultOverridenContactValues);
        }
    }, [defaultOverridenContactValues, resetContactValues]);

    if (isCreateCareTeamContactLoading)
        return <Loading subtitle="Creating Care Team Contact ..." />;
    if (isUpdateCareTeamContactLoading)
        return <Loading subtitle="Updating Care Team Contact ..." />;
    if (careTeamMemberTypesLoading || careTeamContactLoading)
        return <Loading subtitle="Loading Data" />;

    const renderModalTitle = () => {
        let firstWord = '';
        if (isEditMode) firstWord = 'Edit';
        if (isOverrideMode) firstWord = 'Override';
        if (id === '') firstWord = 'Create';
        if (id === OVERRIDEN_CONTACT) firstWord = 'Edit Overriden';
        return `${firstWord} Care Team Contact`;
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setOpen(false)}>
                {renderModalTitle()}
            </DialogTitleWithClose>
            <DialogContent className="formCont">
                <TextField
                    variant="outlined"
                    label="Name"
                    type="text"
                    margin="dense"
                    fullWidth
                    {...register('name')}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                />
                <OutlinedSection title="Address">
                    <TextField
                        variant="outlined"
                        label="First Address"
                        type="text"
                        margin="dense"
                        fullWidth
                        {...register('address.street1')}
                        error={!!errors.address?.street1}
                        helperText={errors.address?.street1?.message}
                    />
                    <TextField
                        variant="outlined"
                        label="Second Address"
                        type="text"
                        margin="dense"
                        fullWidth
                        {...register('address.street2')}
                        error={!!errors.address?.street2}
                        helperText={errors.address?.street2?.message}
                    />
                    <div>
                        <TextField
                            variant="outlined"
                            label="City"
                            type="text"
                            margin="dense"
                            {...register('address.city')}
                            error={!!errors.address?.city}
                            helperText={errors.address?.city?.message}
                        />
                        <TextField
                            variant="outlined"
                            label="State"
                            type="text"
                            margin="dense"
                            {...register('address.state')}
                            error={!!errors.address?.state}
                            helperText={errors.address?.state?.message}
                        />
                        <TextField
                            variant="outlined"
                            label="Code"
                            type="text"
                            margin="dense"
                            {...register('address.code')}
                            error={!!errors.address?.code}
                            helperText={errors.address?.code?.message}
                        />
                    </div>
                </OutlinedSection>
                <TextField
                    variant="outlined"
                    label="Phone"
                    type="text"
                    margin="dense"
                    fullWidth
                    {...register('phoneNumber')}
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber?.message}
                />
                <TextField
                    variant="outlined"
                    label="Email"
                    type="text"
                    margin="dense"
                    fullWidth
                    {...register('emailAddress')}
                    error={!!errors.emailAddress}
                    helperText={errors.emailAddress?.message}
                />
                <TextField
                    variant="outlined"
                    label="URL"
                    type="text"
                    margin="dense"
                    fullWidth
                    {...register('url')}
                    error={!!errors.url}
                    helperText={errors.url?.message}
                />
                <ReactHookFormSelect
                    control={control}
                    name="careTeamMemberTypeId"
                    variant="outlined"
                    defaultValue={
                        careTeamMemberTypes?.careTeamMemberTypesV2?.results &&
                        careTeamMemberTypes?.careTeamMemberTypesV2?.results.length > 0
                            ? careTeamMemberTypes?.careTeamMemberTypesV2.results[0].id
                            : ''
                    }
                    disabled={!!isEditMode}
                    label="Care Team Member Type"
                    fullWidth
                    margin="dense"
                    error={!!errors.careTeamMemberTypeId}
                >
                    {careTeamMemberTypes?.careTeamMemberTypesV2.results.map(i => (
                        <MenuItem key={i.id} value={i.id}>
                            {i.name}
                        </MenuItem>
                    ))}
                </ReactHookFormSelect>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    startIcon={<SaveIcon />}
                    type="submit"
                    color="secondary"
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </form>
    );
};

export default CareTeamContactModal;
