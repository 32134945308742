import { faPenToSquare, faTasks, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from 'tss-react/mui';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGESIZE } from '~/constants';
import { displayDate } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    GetMassUpdatePatientListsDocument,
    useDeleteMassUpdatePatientListMutation,
    useGetMassUpdatePatientListsQuery,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';

const useStyles = makeStyles()({
    root: {},
});
const MassUpdatePatientLists: React.FC = () => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);

    const { data: listData, loading: listsLoading } = useGetMassUpdatePatientListsQuery({
        variables: { input: {} },
    });
    const [deletePatientList, { loading: deletePatientListLoading }] =
        useDeleteMassUpdatePatientListMutation({
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: GetMassUpdatePatientListsDocument,
                    variables: { input: {} },
                },
            ],
        });

    const handleDelete = (id: string) => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: `Do you really want to delete this Patient List'?`,
            callback: () => {
                deletePatientList({ variables: { id } });
            },
        });
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    return (
        <div className={classes.root} style={{ marginLeft: '15px' }}>
            <MaterialTable
                title="Patient Lists"
                icons={tableIcons}
                isLoading={listsLoading || deletePatientListLoading}
                data={_.cloneDeep(_.sortBy(listData?.massUpdatePatientsV2.results, 'name')) ?? []}
                actions={[
                    {
                        onClick: () => history('/system-admin/massupdate/new'),
                        hidden: !pagePermissions?.MassUpdate.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Patient List',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => history(`/system-admin/massupdate/${id}`),
                        hidden: !pagePermissions?.MassUpdate.Read,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Patient List',
                    },
                    {
                        onClick: (_, { id }: any) => handleDelete(id),
                        hidden: !pagePermissions?.MassUpdate.Delete,
                        icon: () => <FontAwesomeIcon icon={faTrash} />,
                        tooltip: 'Delete Patient List',
                    },
                    {
                        onClick: (_, { id }: any) =>
                            history(`/system-admin/massupdate/${id}/tasks`),
                        hidden: !pagePermissions?.MassUpdate.Read,
                        icon: () => <FontAwesomeIcon icon={faTasks} />,
                        tooltip: 'Tasks',
                    },
                ]}
                columns={[
                    { title: 'Name', field: 'name' },
                    { title: 'Patient Count', field: 'patients.length' },
                    {
                        title: 'Last Updated',
                        field: 'updatedAt',
                        render: ({ updatedAt }) => (
                            <>
                                {displayDate({
                                    isoDateStr: updatedAt,
                                })}
                            </>
                        ),
                    },
                ]}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{ pageSize: rowsPerPage }}
                localization={{ header: { actions: '' } }}
            />
        </div>
    );
};

export default MassUpdatePatientLists;
