import React from 'react';
import { useParams } from 'react-router-dom';
import Patient from '~/views/AffiliateCare/Patients/Patient/Patient';

const VCPatient = () => {
    const { affiliateId } = useParams();
    return <Patient affiliateId={affiliateId} portalView />;
};

export default VCPatient;
