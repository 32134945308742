import React, { memo } from 'react';
import { DoDisturbAlt, ErrorOutline } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { AffiliateApprovalStatus } from '~/schemaTypes';

type ApprovalStatusProps = {
    status?: AffiliateApprovalStatus | null;
    iconPlace?: 'left' | 'right';
    showIcon?: boolean;
};

const ApprovalStatus: React.FC<ApprovalStatusProps> = props => {
    const { status, iconPlace = 'right', showIcon = true } = props;
    let Icon = null;
    if (showIcon && status === AffiliateApprovalStatus.ApprovalNeeded) {
        Icon = ErrorOutline;
    } else if (showIcon && status === AffiliateApprovalStatus.Denied) {
        Icon = DoDisturbAlt;
    }
    let label = '';
    switch (status) {
        case AffiliateApprovalStatus.Approved:
        case AffiliateApprovalStatus.Denied:
            label = status;
            break;
        case AffiliateApprovalStatus.ApprovalNeeded:
            label = 'Approval Needed';
            break;
        default:
            label = '';
    }
    if (!status) {
        return null;
    }
    return (
        <Grid container item xs={12} justifyContent="flex-start" columnSpacing={1}>
            {Icon && iconPlace === 'left' && (
                <Grid item>
                    <Icon />
                </Grid>
            )}
            <Grid item>
                <Typography variant="body1">{label}</Typography>
            </Grid>
            {Icon && iconPlace === 'right' && (
                <Grid item>
                    <Icon />
                </Grid>
            )}
        </Grid>
    );
};

export default memo(ApprovalStatus);
