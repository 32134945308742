import React, { memo } from 'react';
import { Divider, Grid } from '@mui/material';
import Breadcrumbs from './Breadcrumbs';
import { useStyles } from '../styles';

type HeaderProps = {
    lastTitle?: string;
    lastPath?: string;
    children?: React.ReactNode;
};

const Header: React.FC<HeaderProps> = props => {
    const { lastTitle, lastPath, children } = props;
    const { classes } = useStyles();
    return (
        <Grid item container xs={12}>
            <Grid item xs={6}>
                <Breadcrumbs lastTitle={lastTitle} lastPath={lastPath} />
            </Grid>
            {children && (
                <Grid item xs={6} container justifyContent="flex-end">
                    {children}
                </Grid>
            )}
            <Grid item xs={12}>
                <Divider className={classes.titleDivider} />
            </Grid>
        </Grid>
    );
};

export default memo(Header);
