import { CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

const DefaultHeight = 24;

const useStyles = makeStyles<{ height?: number }>()((_theme, { height }) => ({
    root: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -1 * (height ?? DefaultHeight / 2),
        marginLeft: -1 * (height ?? DefaultHeight / 2),
    },
}));

type AsyncActionButtonProps = {
    loading: boolean;
    buttonHeight?: number;
    children: React.ReactNode;
};

const AsyncActionButton: React.FC<AsyncActionButtonProps> = ({
    loading,
    children,
    buttonHeight = DefaultHeight,
}) => {
    const { classes } = useStyles({ height: buttonHeight });
    return (
        <div className={classes.root}>
            {children}
            {loading && <CircularProgress size={buttonHeight} className={classes.buttonProgress} />}
        </div>
    );
};

export default AsyncActionButton;
