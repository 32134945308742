import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, Button, DialogActions, DialogContent, TextField } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { PatientActionType } from '~/schemaTypes';

type WhereUsedModalProps = {
    title: string;
    actionTypes: PatientActionType[];
    onClose: () => void;
    onDelete: (replaceWithId: string) => void;
};

const DeleteAndReplaceModal: React.FC<WhereUsedModalProps> = ({
    title,
    actionTypes,
    onClose,
    onDelete,
}) => {
    const [replaceWithId, setReplaceWithId] = React.useState<string | null>(null);

    return (
        <>
            <DialogTitleWithClose id="edit-role-modal" onClose={() => onClose()}>
                Delete and Replace {title}
            </DialogTitleWithClose>
            <DialogContent>
                <p>Select a patient action type to replace {title}</p>
                <Autocomplete
                    id="replace-with"
                    options={_.sortBy(actionTypes, ['label'])}
                    getOptionLabel={option => option.label}
                    fullWidth
                    renderInput={params => (
                        <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="Replace With"
                            variant="outlined"
                        />
                    )}
                    onChange={(event, value) => {
                        event.preventDefault();
                        setReplaceWithId(value?.id ?? null);
                    }}
                />
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={() => onClose()} color="secondary" variant="outlined">
                    Close
                </Button>
                <Button
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    onClick={() => onDelete(replaceWithId!)}
                    startIcon={<FontAwesomeIcon icon={faTrash} />}
                    color="secondary"
                    variant="contained"
                    disabled={!replaceWithId}
                >
                    Delete
                </Button>
            </DialogActions>
        </>
    );
};

export default DeleteAndReplaceModal;
