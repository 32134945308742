import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import {
    PatientActionType,
    PatientActionTypesForConfigPortalDocument,
    PatientActionTypesForConfigPortalQuery,
    useCreatePatientActionTypeForConfigPortalMutation,
    useUpdatePatientActionTypeForConfigPortalMutation,
} from '~/schemaTypes';
import { ConditionTypeEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';

interface FormInput {
    label: string;
}

const inputValidation = Yup.object().shape({
    label: Yup.string().required('You need to enter a name'),
});

type PatientActionTypeProps = {
    isOpen: boolean;
    item: PatientActionType | null;
    onClose: () => void;
};

export const PatientActionTypesModal = ({ isOpen, item, onClose }: PatientActionTypeProps) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormInput>({
        resolver: yupResolver(inputValidation as any),
        defaultValues: {
            label: item?.label,
        },
    });

    useEffect(() => {
        reset({
            label: item?.label,
        });
    }, [item, reset]);

    const [createPatientActionType, { loading: createLoading }] =
        useCreatePatientActionTypeForConfigPortalMutation({
            onCompleted: () => {
                onClose();
            },
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        onClose();
                    },
                    message: `There was a problem saving the patient action type: ${error.message}`,
                });
            },
            update: (cache, res) => {
                const newItem = res.data?.createPatientActionType?.resourceCreated;
                if (res.data?.createPatientActionType?.success && newItem) {
                    const currentItems = cache.readQuery<PatientActionTypesForConfigPortalQuery>({
                        query: PatientActionTypesForConfigPortalDocument,
                    });
                    if (currentItems?.patientActionTypesV2.results) {
                        cache.writeQuery<PatientActionTypesForConfigPortalQuery>({
                            query: PatientActionTypesForConfigPortalDocument,
                            data: {
                                patientActionTypesV2: {
                                    ...currentItems.patientActionTypesV2,
                                    results: [
                                        ...currentItems.patientActionTypesV2.results,
                                        newItem,
                                    ],
                                },
                            },
                        });
                    }
                }
            },
        });

    const [updatePatientActionType, { loading: updateLoading }] =
        useUpdatePatientActionTypeForConfigPortalMutation({
            onCompleted: () => {
                onClose();
            },
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        onClose();
                    },
                    message: `There was a problem updating the patient action type: ${error.message}`,
                });
            },
            update: (cache, res) => {
                const newItem = res.data?.updatePatientActionType?.resourceUpdated;
                if (res.data?.updatePatientActionType?.success && newItem) {
                    const currentItems = cache.readQuery<PatientActionTypesForConfigPortalQuery>({
                        query: PatientActionTypesForConfigPortalDocument,
                    });
                    if (currentItems?.patientActionTypesV2.results) {
                        const updatedList = currentItems.patientActionTypesV2.results.map(item => {
                            if (item.id === newItem.id) {
                                return newItem;
                            }
                            return item;
                        });
                        cache.writeQuery<PatientActionTypesForConfigPortalQuery>({
                            query: PatientActionTypesForConfigPortalDocument,
                            data: {
                                patientActionTypesV2: {
                                    ...currentItems.patientActionTypesV2,
                                    results: [...updatedList],
                                },
                            },
                        });
                    }
                }
            },
        });

    const onSubmit = (input: FormInput) => {
        if (item) {
            updatePatientActionType({
                variables: {
                    input: {
                        id: item.id,
                        data: {
                            label: input.label,
                        },
                    },
                },
            });
        } else {
            createPatientActionType({
                variables: {
                    input: {
                        label: input.label,
                    },
                },
            });
        }
    };

    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose id="dialogTitle" onClose={onClose}>
                {item ? 'Edit Patient Action Type' : 'Add Patient Action Type'}
            </DialogTitleWithClose>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    {updateLoading || createLoading ? (
                        <Loading height={50} />
                    ) : (
                        <div>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="label *"
                                    fullWidth
                                    margin="dense"
                                    {...register('label', { required: true })}
                                    error={!!errors.label}
                                    helperText={errors.label?.message}
                                />
                            </Grid>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={() => handleSubmit(onSubmit)}
                        data-test={ConditionTypeEnum.SAVE}
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
