import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Grid,
    IconButton,
    Popover,
    Tooltip,
    Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import React, { useState } from 'react';
import { FetchCurrentUserForAdvocateTaskPageQuery } from '~/schemaTypes';
import { useStyles } from './styles';
import { Watcher } from './types';

type CurrentsUser = NonNullable<FetchCurrentUserForAdvocateTaskPageQuery['currentUser']>;

interface WatchersProps {
    currentUser: CurrentsUser;
    watchedBy: string[];
    watchers: Watcher[];
    onWatchersUpdated: (watchedBy: string[]) => void;
}

const Watchers: React.FC<WatchersProps> = ({
    currentUser,
    watchedBy,
    watchers,
    onWatchersUpdated,
}) => {
    const { classes } = useStyles();
    const [isWatched, setIsWatched] = useState<boolean>(watchedBy.includes(currentUser.id));
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleWatchOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleWatch = () => {
        const nextStateWatchedBy = [...watchedBy];

        if (isWatched) {
            const otherWatchers = nextStateWatchedBy.filter(id => id !== currentUser.id);
            onWatchersUpdated(otherWatchers);
            setIsWatched(false);
            setAnchorEl(null);
            return;
        }

        nextStateWatchedBy.push(currentUser.id);
        onWatchersUpdated(nextStateWatchedBy);
        setIsWatched(true);
        setAnchorEl(null);
    };

    const renderAvatar = (name: string) => {
        const [firstName, lastName] = name.split(' ');

        return `${firstName[0]}${lastName ? lastName[0] : ''}`;
    };

    return (
        <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
                <Tooltip title="Watch options">
                    <IconButton onClick={handleWatchOptions} size="large">
                        {isWatched ? (
                            <VisibilityIcon fontSize="medium" />
                        ) : (
                            <VisibilityOutlinedIcon fontSize="medium" />
                        )}
                        {watchers.length > 0 && (
                            <span className={classes.watchCount}>{watchers.length}</span>
                        )}
                    </IconButton>
                </Tooltip>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Card className={classes.watchCard}>
                        <CardActions>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                startIcon={
                                    isWatched ? (
                                        <VisibilityIcon className={classes.visibilityIcon} />
                                    ) : (
                                        <VisibilityOutlinedIcon
                                            className={classes.visibilityIcon}
                                        />
                                    )
                                }
                                onClick={handleWatch}
                            >
                                {isWatched ? 'Stop' : 'Start'} Watching
                            </Button>
                        </CardActions>
                        <Divider className={classes.divider} />
                        <CardContent className={classes.cardContent}>
                            {watchedBy && watchedBy.length > 0 ? (
                                <Grid container direction="column" alignItems="flex-start">
                                    <Grid item xs={12}>
                                        <Typography className={classes.watchMessage}>
                                            Watching this task:
                                        </Typography>
                                    </Grid>
                                    {watchers.map(({ id, name }) => (
                                        <Grid
                                            item
                                            container
                                            alignItems="center"
                                            key={id}
                                            className={classes.watcherWrapper}
                                        >
                                            <Grid item xs={2}>
                                                <Avatar className={classes.avatar}>
                                                    {renderAvatar(name)}
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography noWrap>{name}</Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <Grid container justifyContent="center" alignItems="center">
                                    <Typography className={classes.emptyState}>
                                        No watchers yet
                                    </Typography>
                                </Grid>
                            )}
                        </CardContent>
                    </Card>
                </Popover>
            </Grid>
        </Grid>
    );
};

export default Watchers;
