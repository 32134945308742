import { OrderByDirectionEnum, SlaTimersV2ForSlaTimersPageQuery } from '~/schemaTypes';

export type SlaTimer = NonNullable<SlaTimersV2ForSlaTimersPageQuery['slaTimersV2']>['results'][0];

export enum SlaTimerSortingField {
    createdAt = 'createdAt',
    completeBy = 'completeBy',
    completedAt = 'completedAt',
}

export interface ISlaTimerOrderChange {
    field: SlaTimerSortingField;
    direction: OrderByDirectionEnum;
    isInit: boolean;
}

// corresponds to columns={[]} passed to MaterialUI
export const columnIdToSortingFieldMap: Record<number, SlaTimerSortingField> = {
    1: SlaTimerSortingField.createdAt,
    2: SlaTimerSortingField.completeBy,
    3: SlaTimerSortingField.completedAt,
};
