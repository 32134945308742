import { useCallback, useEffect, useState } from 'react';
import {
    FetchCareTeamMembersForCareTeamMembersHookQuery,
    useFetchCareTeamMembersForCareTeamMembersHookLazyQuery,
} from '../schemaTypes';

type ProfileDefitionInfo = { category: string; name: string };

type UseCareTeamMembersParams = {
    profileDefinitions?: ProfileDefitionInfo[];
    bundleId?: string | null;
    careTeams?: CareTeamMembers;
};
type CareTeamMembers =
    FetchCareTeamMembersForCareTeamMembersHookQuery['careTeamMembersByTypesNames'];
export type CareTeamMember = CareTeamMembers[0]['careTeamMembers'][0];
type IsCareTeamProfileDefinitionType = (profileDefinition: ProfileDefitionInfo) => boolean;
type GetCareTeamTypeNameFromProfileDefinitionType = (
    profileDefinitions: ProfileDefitionInfo[],
) => string[];

type CareTeamMembersHookResponse = {
    careTeamsMembersByTypes: CareTeamMembers;
    isCareTeamProfileDefinition: IsCareTeamProfileDefinitionType;
};

const PROFILE_DEFINITION_CARE_TEAM_CATEGORY = 'Care Team';
const PROFILE_DEFINITION_NAME_SEPARATOR = '_';

const useCareTeamMembers = ({
    profileDefinitions = [],
    bundleId,
    careTeams = [],
}: UseCareTeamMembersParams): CareTeamMembersHookResponse => {
    const [careTeamsMembersByTypes, setCareTeamsMembersByTypes] =
        useState<CareTeamMembers>(careTeams);

    const isCareTeamProfileDefinition: IsCareTeamProfileDefinitionType = profileDefinition =>
        profileDefinition.category === PROFILE_DEFINITION_CARE_TEAM_CATEGORY;

    const [getCareTeamMembersByTypesNames] = useFetchCareTeamMembersForCareTeamMembersHookLazyQuery(
        {
            onCompleted: data => {
                setCareTeamsMembersByTypes(data?.careTeamMembersByTypesNames ?? []);
            },
        },
    );

    const getCareTeamTypeNamesFromProfileDefinitions: GetCareTeamTypeNameFromProfileDefinitionType =
        useCallback(profileDefinitions => {
            return profileDefinitions
                .filter(isCareTeamProfileDefinition)
                .map(pd => pd.name.split(PROFILE_DEFINITION_NAME_SEPARATOR).slice(-1).pop())
                .filter((pdName): pdName is string => !!pdName);
        }, []);

    useEffect(() => {
        if (profileDefinitions?.length > 0 && bundleId && careTeamsMembersByTypes.length === 0) {
            const careTeamTypeNames =
                getCareTeamTypeNamesFromProfileDefinitions(profileDefinitions);

            getCareTeamMembersByTypesNames({
                variables: {
                    input: { careTeamsTypeNames: careTeamTypeNames, bundleId },
                },
            });
        }
    }, [
        profileDefinitions,
        getCareTeamTypeNamesFromProfileDefinitions,
        getCareTeamMembersByTypesNames,
        bundleId,
        careTeamsMembersByTypes.length,
    ]);

    return {
        careTeamsMembersByTypes,
        isCareTeamProfileDefinition,
    };
};

export default useCareTeamMembers;
