export enum CarePlanEnum {
    NAV_CARE_PLANS = 'care-plans',
    CONTAINER = 'care-plans-container',
    DELETE_TODO_ITEM = 'delete-todo-item',
    REMOVE_TODO_GROUP = 'remove-todo-group',
    ADD_CARE_PLAN_MODAL = 'add-new-care-plan-modal',
    MODAL_TYPE_DROPDOWN = 'modal-type-dropdown',
    MODAL_ADD_BUTTON = 'modal-add-care-plan-button',
    LABEL = 'create-care-plan-label',
    CARE_PLAN_LABEL = 'care-plan-label',
    TIMELINE = 'create-care-plan-timeline',
    ADD_TODO_GROUP = 'add-todo-group',
    TODO_GROUP = 'todo-group-section',
    TODO_GROUP_LABEL_EN = 'todo-group-label-en',
    TODO_GROUP_LABEL_ES = 'todo-group-label-es',
    ADD_TODO = 'todo-group-add-todo',
    CREATE_TODO_MODAL = 'create-todo-modal',
    PORTAL_LABEL = 'portal-label',
    MODAL_SAVE = 'modal-save',
    SELECT_ARTICLE = 'select-article',
    ARTICLE_GROUP = 'article-group',
    ARTICLE_CATEGORY = 'article-category',
    ARTICLE_PICKER = 'article-picker',
    TODO_LIST_ITEM_NAME = 'todo-list-item-name',
    SAVE_AND_CLOSE = 'care-plan-save-and-close',
    FILTER = 'care-plan-filter',
    BASE_CARE_PLAN_DROPDOWN = 'base-care-plan-dropdown',
    DERIVED_LABEL = 'derived-care-plan-label',
}

export const CarePlanSelectors = {
    NAV_CARE_PLANS: `a[data-test="${CarePlanEnum.NAV_CARE_PLANS}"]`,
    ADD_CARE_PLAN_BUTTON: `div[data-test="${CarePlanEnum.CONTAINER}"] button[title="Add Care Plan"]`,
    ADD_CARE_PLAN_MODAL: `div[data-test="${CarePlanEnum.ADD_CARE_PLAN_MODAL}"] div[role="dialog"]`,
    MODAL_TYPE_DROPDOWN: `div[data-test="${CarePlanEnum.MODAL_TYPE_DROPDOWN}"] input`,
    TYPE_DROPDOWN_BASE_OPTION: `div[role="presentation"] ul[role="listbox"] li[data-option-index="0"]`,
    TYPE_DROPDOWN_DERIVED_OPTION: `div[role="presentation"] ul[role="listbox"] li[data-option-index="1"]`,
    MODAL_ADD_BUTTON: `button[data-test="${CarePlanEnum.MODAL_ADD_BUTTON}"]`,
    EDIT_CARE_PLAN_BUTTON: `div[data-test="${CarePlanEnum.CONTAINER}"] button[title="Edit Care Plan"]`,
    DELETE_CARE_PLAN_BUTTON: `div[data-test="${CarePlanEnum.CONTAINER}"] button[title="Delete Care Plan"]`,
    DELETE_TODO_ITEM_BUTTON: (value: number): string =>
        `button[data-test="${CarePlanEnum.DELETE_TODO_ITEM}-${value}"]`,
    REMOVE_TODO_GROUP: (value: number): string =>
        `button[data-test="${CarePlanEnum.REMOVE_TODO_GROUP}-${value}"]`,
    LABEL_INPUT: `div[data-test="${CarePlanEnum.LABEL}"] input`,
    CARE_PLAN_LABEL_EN_INPUT: `div[data-test="${CarePlanEnum.CARE_PLAN_LABEL}-en"] input`,
    CARE_PLAN_LABEL_ES_INPUT: `div[data-test="${CarePlanEnum.CARE_PLAN_LABEL}-es"] input`,
    TIMELINE_DROPDOWN: `div[data-test="${CarePlanEnum.TIMELINE}"] div[role="button"]`,
    TIMELINE_DROPDOWN_OPTIONS: 'div[role="presentation"] ul[aria-labelledby="timelineId-label"]',
    BASE_CARE_PLAN_DROPDOWN: `div[data-test="${CarePlanEnum.BASE_CARE_PLAN_DROPDOWN}"] input`,
    ADD_TODO_GROUP_BUTTON: `button[data-test="${CarePlanEnum.ADD_TODO_GROUP}"]`,
    TODO_GROUP_SECTION: (value: number): string =>
        `div[data-test="${CarePlanEnum.TODO_GROUP}-${value}"]`,
    TODO_GROUP_LABEL_EN_INPUT: (value: number): string =>
        `div[data-test="${CarePlanEnum.TODO_GROUP_LABEL_EN}-${value}"] input`,
    TODO_GROUP_LABEL_ES_INPUT: (value: number): string =>
        `div[data-test="${CarePlanEnum.TODO_GROUP_LABEL_ES}-${value}"] input`,
    ADD_TODO_BUTTON: (value: number): string =>
        `button[data-test="${CarePlanEnum.ADD_TODO}-${value}"]`,
    CREATE_TODO_MODAL: `form[data-test="${CarePlanEnum.CREATE_TODO_MODAL}"]`,
    PORTAL_LABEL_INPUT: `div[data-test="${CarePlanEnum.PORTAL_LABEL}"] input`,
    DERIVED_LABEL_INPUT: `div[data-test="${CarePlanEnum.DERIVED_LABEL}"] input`,
    MODAL_SAVE_BUTTON: `button[data-test="${CarePlanEnum.MODAL_SAVE}"]`,
    SELECT_ARTICLE: `div[data-test="${CarePlanEnum.SELECT_ARTICLE}"] input`,
    ARTICLE_GROUP: `div[data-test="${CarePlanEnum.ARTICLE_GROUP}"] input`,
    ARTICLE_CATEGORY: `div[data-test="${CarePlanEnum.ARTICLE_CATEGORY}"] input`,
    ARTICLE_PICKER: `div[data-test="${CarePlanEnum.ARTICLE_PICKER}"]`,
    AUTOCOMPLETE_BASE_OPTION:
        'div[role="presentation"] ul[role="listbox"] li[data-option-index="0"]',
    SAVE_AND_CLOSE_BUTTON: `button[data-test="${CarePlanEnum.SAVE_AND_CLOSE}"]`,
    FILTER_BUTTON: `button[data-test="${CarePlanEnum.FILTER}"]`,
    SEARCH_INPUT: `div[data-test="${CarePlanEnum.CONTAINER}"] input[placeholder="Search"]`,
    TODO_ITEM_BY_VALUE: (value: string): string =>
        `td[value="${value}"] div[data-test="${CarePlanEnum.TODO_LIST_ITEM_NAME}"]`,
    FIRST_TABLE_ROW_TD: (value: string): string =>
        `div[data-test="${CarePlanEnum.CONTAINER}"] tbody tr[index="0"] td[value="${value}"]`,
    FIRST_TABLE_ROW_TD_PARAGRAPH: (value: string): string =>
        `div[data-test="${CarePlanEnum.CONTAINER}"] tr[index="0"] td p[data-test="${value}"]`,
};
