import { Typography } from '@mui/material';
import React from 'react';
import { GetTriggerInterventionClearProfileChoiceForPatientTranscriptQuery } from '~/schemaTypes';

type TriggerInterventionClearProfileChoice = NonNullable<
    GetTriggerInterventionClearProfileChoiceForPatientTranscriptQuery['getTriggerInterventionClearProfileChoiceForPatientTranscript']
>[0];

export const TriggerInterventionClearProfileChoiceCard = ({
    intervention,
}: {
    intervention: TriggerInterventionClearProfileChoice;
}) => {
    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <Typography variant="h6">Trigger Intervention Clear Profile Choice</Typography>
            <p className="m-0">{`Profile: ${intervention?.profileDef?.name || 'Unknown'}`}</p>
            <p className="m-0">
                {`Choices to Update: ${
                    intervention?.event?.data?.choicesToUpdate?.join(', ') || 'Unknown'
                }`}
            </p>
        </div>
    );
};
