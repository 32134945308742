/* eslint-disable max-len */
import { Link, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

const useStyles = makeStyles()({
    root: {
        padding: 40,
        '& > *:not(:last-child)': {
            marginBottom: 15,
        },
    },
});

const PostpartumModalInfo: React.FC = () => {
    const { classes } = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant="h5">Background</Typography>
            <Typography>
                The Edinburgh Postnatal Depression Scale is a 10-question survey designed to
                efficiently screen moms who are at risk for perinatal depression. Patients scoring
                above 10 may suffer from postpartum depression and further clinical evaluation is
                necessary. The survey and score should never override clinical judgement. This
                survey reflects feelings of the mother in the last week and may be repeated in 2
                weeks if warranted. All items should be answered by the mother herself. The EPDS is
                available in multiple languages if needed.
            </Typography>
            <Typography variant="h5">Scoring</Typography>
            <Typography>
                Maximum score is 30. Scores of 10 or greater indicate possible depression but are
                not diagnostic. The mother should speak with a healthcare provider about these
                symptoms. Always look at answers to question 10 regarding suicidal thoughts. The
                EPDS does not screen for anxiety or bipolar disorder.
            </Typography>
            <Typography variant="h5">Score Interpretation</Typography>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Scores less than 8</TableCell>
                        <TableCell>Depression is not likely. Continue support.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Scores 9-11</TableCell>
                        <TableCell>
                            Depression is possible. Support, rescreen in 2-4 weeks, and consider
                            referral to primary care provider.
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Scores 12-13</TableCell>
                        <TableCell>
                            Fairly high possibility of depression. Monitor, support, offer
                            education, and refer to primary care provider.
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Scores 14 and higher (positive screen)</TableCell>
                        <TableCell>
                            Probable depression. Diagnostic assessment and treatment by PCP and/or
                            specialist.
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Positive score (1,2, or 3) on question 10 (suicidality risk):
                        </TableCell>
                        <TableCell>
                            Immediate discussion required. Refer to PCP ± mental health specialist
                            or emergency resource for further assessment and intervention as
                            appropriate.
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Typography>
                Correlation with clinical presentation is important. Refer to mental health services
                as needed for immediate assistance.
            </Typography>
            <Typography>
                <Link
                    href="https://www.fresno.ucsf.edu/pediatrics/downloads/edinburghscale.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                >
                    https://www.fresno.ucsf.edu/pediatrics/downloads/edinburghscale.pdf
                </Link>
            </Typography>
            <Typography>
                <Link
                    href="http://www.perinatalservicesbc.ca/health-professionals/professional-resources/health-promo/edinburgh-postnatal-depression-scale-(epds)"
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                >
                    http://www.perinatalservicesbc.ca/health-professionals/professional-resources/health-promo/edinburgh-postnatal-depression-scale-(epds)
                </Link>
            </Typography>
            <Typography>
                <Link
                    href="https://link.springer.com/article/10.1007/s00737-017-0789-7/tables/1"
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                >
                    https://link.springer.com/article/10.1007/s00737-017-0789-7/tables/1
                </Link>
            </Typography>
        </div>
    );
};

export default PostpartumModalInfo;
