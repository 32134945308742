import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    TextField,
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import React, { useState } from 'react';
import {
    useFetchTagsForFilterModalPageQuery,
    useGetUsersByPermissionForFilterModalPageQuery,
} from '~/schemaTypes';

type FilterSurveysModalProps = {
    suffix?: string;
    open: boolean;
    onClose: () => void;
};

export const FilterSurveysModal: React.FC<FilterSurveysModalProps> = ({
    suffix = 'Surveys',
    open,
    onClose,
}) => {
    const [filteredTags, setFilteredTags] = useState<string[]>(
        localStorage
            .getItem(`filteredTags${suffix}`)
            ?.split(',')
            .filter(str => str.length > 1) || [],
    );
    const [filteredAdvocate, setFilteredAdvocate] = useState<string>(
        localStorage.getItem(`filteredAdvocate${suffix}`) || '',
    );
    const [filterTestData, setFilterTestData] = useState<boolean>(
        localStorage.getItem('filterTestData') === 'true' ?? false,
    );
    const [filterFlagged, setFilterFlagged] = useState<boolean>(
        localStorage.getItem(`filterFlagged${suffix}`) === 'true' ?? false,
    );
    const [filterDisplayName, setFilterDisplayName] = useState<string>(
        localStorage.getItem(`filterDisplayName${suffix}`) || '',
    );
    const [filterFirstName, setFilterFirstName] = useState<string>(
        localStorage.getItem(`filterFirstName${suffix}`) || '',
    );
    const [filterLastName, setFilterLastName] = useState<string>(
        localStorage.getItem(`filterLastName${suffix}`) || '',
    );
    const [filterResponseDate, setFilterResponseDate] = useState<string>(
        localStorage.getItem(`filterResponseDate${suffix}`) || '',
    );
    const [filterDueDate, setFilterDueDate] = useState<string>(
        localStorage.getItem(`filterDueDate${suffix}`) || '',
    );
    const [filterScore, setFilterScore] = useState<number>(
        Number(localStorage.getItem(`filterScore${suffix}`)) || 0,
    );

    const { data: tagData, loading: tagLoading } = useFetchTagsForFilterModalPageQuery({
        variables: {
            input: {
                filter: {
                    fields: {
                        isPatientFilter: true,
                    },
                },
            },
        },
    });

    const { data: advocates, loading: advocatesLoading } =
        useGetUsersByPermissionForFilterModalPageQuery({
            variables: {
                permission: 'isHealthAdvocate',
            },
        });

    const handleSave = () => {
        localStorage.setItem(`filteredTags${suffix}`, filteredTags.join(','));
        localStorage.setItem(`filteredAdvocate${suffix}`, filteredAdvocate);
        localStorage.setItem('filterTestData', `${filterTestData}`);
        localStorage.setItem(`filterFlagged${suffix}`, `${filterFlagged}`);
        localStorage.setItem(`filterDisplayName${suffix}`, filterDisplayName);
        localStorage.setItem(`filterFirstName${suffix}`, filterFirstName);
        localStorage.setItem(`filterLastName${suffix}`, filterLastName);
        localStorage.setItem(`filterResponseDate${suffix}`, filterResponseDate);
        localStorage.setItem(`filterDueDate${suffix}`, filterDueDate);
        localStorage.setItem(`filterScore${suffix}`, `${filterScore}`);

        const filtersAppliedCount =
            (filteredAdvocate ? 1 : 0) +
            (filterTestData ? 1 : 0) +
            filteredTags.length +
            (filterFlagged ? 1 : 0) +
            (filterDisplayName ? 1 : 0) +
            (filterFirstName ? 1 : 0) +
            (filterLastName ? 1 : 0) +
            (filterResponseDate ? 1 : 0) +
            (filterDueDate ? 1 : 0) +
            (filterScore ? 1 : 0);

        localStorage.setItem(`filtersAppliedCount${suffix}`, String(filtersAppliedCount));
        onClose();
    };

    const handleClearFilters = () => {
        setFilteredTags([]);
        setFilteredAdvocate('');
        setFilterTestData(false);
        setFilterFlagged(false);
        setFilterDisplayName('');
        setFilterFirstName('');
        setFilterLastName('');
        setFilterResponseDate('');
        setFilterDueDate('');
        setFilterScore(0);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Filter {suffix}</DialogTitle>
            <DialogContent>
                <Autocomplete
                    multiple
                    options={tagData?.tagsV2?.results.map(tag => tag.id) || []}
                    getOptionLabel={tag =>
                        tagData?.tagsV2.results.find(t => t.id === tag)?.name ?? ''
                    }
                    filterSelectedOptions
                    value={filteredTags}
                    onChange={(_, value) => setFilteredTags(value)}
                    disabled={tagLoading}
                    renderInput={params => (
                        <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            variant="outlined"
                            label="Tags"
                            placeholder="Tags"
                        />
                    )}
                />
                <Autocomplete
                    options={
                        advocates?.getUsersByPermission
                            ?.map(user => user?.id)
                            .concat(['unassigned']) ?? []
                    }
                    getOptionLabel={user =>
                        advocates?.getUsersByPermission?.find(u => u?.id === user)?.name ?? user
                    }
                    filterSelectedOptions
                    value={filteredAdvocate}
                    onChange={(_, value) => setFilteredAdvocate(value ?? '')}
                    disabled={advocatesLoading}
                    renderInput={params => (
                        <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="Filter Assigned Health Advocates"
                            variant="outlined"
                        />
                    )}
                />
                <TextField
                    label="Filter Display Name"
                    variant="outlined"
                    value={filterDisplayName}
                    fullWidth
                    onChange={e => {
                        setFilterDisplayName(e.target.value);
                        localStorage.setItem(`filterDisplayName${suffix}`, e.target.value);
                    }}
                />
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <TextField
                            label="Filter First Name"
                            variant="outlined"
                            value={filterFirstName}
                            fullWidth
                            onChange={e => {
                                setFilterFirstName(e.target.value);
                                localStorage.setItem(`filterFirstName${suffix}`, e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Filter Last Name"
                            variant="outlined"
                            value={filterLastName}
                            fullWidth
                            onChange={e => {
                                setFilterLastName(e.target.value);
                                localStorage.setItem(`filterLastName${suffix}`, e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <DatePicker
                            disableToolbar
                            fullWidth
                            variant="inline"
                            format="MM/dd/yyyy"
                            label="Filter Response Date"
                            openTo="year"
                            value={filterResponseDate || null}
                            onChange={(date: Date) => {
                                setFilterResponseDate(date?.toString() ?? '');
                                localStorage.setItem(
                                    `filterResponseDate${suffix}`,
                                    date?.toString() ?? '',
                                );
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker
                            disableToolbar
                            fullWidth
                            variant="inline"
                            format="MM/dd/yyyy"
                            label="Filter Due Date"
                            openTo="year"
                            value={filterDueDate || null}
                            onChange={(date: Date) => {
                                setFilterDueDate(date?.toString() ?? '');
                                localStorage.setItem(
                                    `filterDueDate${suffix}`,
                                    date?.toString() ?? '',
                                );
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </Grid>
                <TextField
                    label="Filter Score"
                    variant="outlined"
                    value={filterScore}
                    fullWidth
                    onChange={e => {
                        setFilterScore(parseInt(e.target.value, 10));
                        localStorage.setItem(`filterScore${suffix}`, e.target.value);
                    }}
                    type="number"
                />
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterFlagged}
                                onChange={() => {
                                    setFilterFlagged(!filterFlagged);
                                    localStorage.setItem(
                                        `filterFlagged${suffix}`,
                                        `${!filterFlagged}`,
                                    );
                                }}
                            />
                        }
                        label="Filter Flagged Surveys"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterTestData}
                                onChange={() => {
                                    setFilterTestData(!filterTestData);
                                    localStorage.setItem('filterTestData', `${!filterTestData}`);
                                }}
                            />
                        }
                        label="Enable Test Data To Be Displayed"
                    />
                </div>
                <Button onClick={handleClearFilters}>Clear Filters</Button>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setFilteredTags(
                            localStorage
                                .getItem(`filteredTags${suffix}`)
                                ?.split(',')
                                .filter(str => str.length > 1) ?? [],
                        );
                        setFilteredAdvocate(
                            localStorage.getItem(`filteredAdvocate${suffix}`) ?? '',
                        );
                        onClose();
                    }}
                    color="primary"
                >
                    Close
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
