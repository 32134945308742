import React, { useCallback, useEffect, useState } from 'react';
import MaterialTable, { MaterialTableProps, MTableToolbar } from '@material-table/core';
import tableIcons from '~/helpers/tableIcons';
import { Button, Grid, Typography, Dialog } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import AddPVModal from './AddPVModal';
import { SelectedPV } from './types';

type PVToSetListProps = {
    canEdit: boolean;
    currentPvs?: SelectedPV[];
    setPVs: (PVs: SelectedPV[]) => void;
};

const PVToSetList: React.FC<PVToSetListProps> = props => {
    const { canEdit, setPVs, currentPvs } = props;
    const [showPvModal, setShowPvModal] = useState<boolean>(false);
    const [selectedPVs, setSelectedPVs] = useState<SelectedPV[]>(currentPvs || []);
    const addPVHandler = useCallback(
        (newPv: SelectedPV) => {
            setSelectedPVs([...selectedPVs.filter(PV => PV.id !== newPv.id), newPv]);
        },
        [selectedPVs],
    );
    const PVDeleteHandler = useCallback(
        (id: string) => {
            setSelectedPVs([...selectedPVs.filter(PV => PV.id !== id)]);
        },
        [selectedPVs],
    );
    useEffect(() => {
        setPVs(selectedPVs);
    }, [selectedPVs, setPVs]);

    return (
        <>
            <div>State: {canEdit}</div>
            <MaterialTable<SelectedPV>
                icons={tableIcons as MaterialTableProps<any>['icons']}
                columns={[
                    {
                        title: 'PV Name',
                        field: 'name',
                        render: ({ name }) => <Typography data-test={name}>{name}</Typography>,
                        sorting: false,
                    },
                    {
                        title: 'Type',
                        field: 'type',
                        render: ({ type }) => <Typography data-test={type}>{type}</Typography>,
                        sorting: false,
                    },
                    {
                        title: 'Value',
                        field: 'displayValue',
                        render: ({ displayValue }) => (
                            <Typography data-test={displayValue}>{displayValue}</Typography>
                        ),
                        sorting: false,
                    },
                ]}
                title="PVs to set"
                data={selectedPVs}
                options={{
                    search: false,
                    paging: false,
                }}
                localization={{ header: { actions: '' } }}
                actions={[
                    {
                        onClick: (_, { id }: any) => PVDeleteHandler(id),
                        icon: () => <FontAwesomeIcon icon={faTrash} />,
                        tooltip: 'Delete PV',
                        hidden: !canEdit,
                    },
                ]}
                components={{
                    Toolbar: props => (
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            paddingRight={2}
                        >
                            <Grid item xs={5}>
                                <MTableToolbar {...props} />
                            </Grid>
                            {canEdit && (
                                <Grid item>
                                    <Grid container columnSpacing={1}>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                component="label"
                                                onClick={() => setShowPvModal(true)}
                                            >
                                                Add Pv to Set
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    ),
                }}
            />
            <Dialog scroll="paper" open={showPvModal} fullWidth>
                <AddPVModal setOpen={setShowPvModal} addPVHandler={addPVHandler} />
            </Dialog>
        </>
    );
};

export default PVToSetList;
