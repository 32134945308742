import { Card, Grid, Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppPlatform, useFetchOrgByInviteCodeForPatientInvitePageLazyQuery } from '~/schemaTypes';
import SWCInviteSteps from './SWCInviteSteps';
import VBCInviteSteps from './VBCInviteSteps';

const useStyles = makeStyles()(theme => ({
    root: {
        padding: 20,
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 600,
        '& .content': {
            padding: 10,
            [theme.breakpoints.up('md')]: {
                padding: 40,
            },
        },
    },
}));

const PatientInvite: React.FC = () => {
    const { classes } = useStyles();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const practiceCode = searchParams.get('practiceCode') ?? '';
    const [appPlatform, setAppPlatform] = useState<AppPlatform>(AppPlatform.Swc);

    const [fetchOrgByPracticeCode, { data: orgDataReceived, loading: orgLoading }] =
        useFetchOrgByInviteCodeForPatientInvitePageLazyQuery({
            onCompleted: data => {
                if (data?.orgByInviteCode) {
                    const { appPlatform } = data.orgByInviteCode;
                    if (appPlatform) {
                        setAppPlatform(appPlatform as AppPlatform);
                    }
                }
            },
        });

    useEffect(() => {
        if (practiceCode) {
            fetchOrgByPracticeCode({
                variables: {
                    input: {
                        inviteCode: practiceCode,
                    },
                },
            });
        }
    }, [fetchOrgByPracticeCode, practiceCode]);

    const platformSpecificSteps = (): JSX.Element | null => {
        if (orgDataReceived === undefined) {
            return null;
        }
        const { orgByInviteCode } = orgDataReceived;
        const VBC = <VBCInviteSteps orgData={orgByInviteCode} />;
        const SWC = <SWCInviteSteps orgData={orgByInviteCode} />;
        switch (appPlatform) {
            case AppPlatform.Swc:
                return SWC;
            default:
                return VBC;
        }
    };

    return (
        <div className={classes.root}>
            <Card>
                <Grid item xs={12}>
                    {orgLoading ? (
                        <Skeleton variant="rectangular" width={250} height={100} />
                    ) : (
                        appPlatform && platformSpecificSteps()
                    )}
                </Grid>
            </Card>
        </div>
    );
};

export default PatientInvite;
