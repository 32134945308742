import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { StepHandlerOptions, Steps, validStepsForView } from '../types';
import { useVCFContext } from './useVCF';

const defaultStepState = {
    [Steps.PATIENT_INFO]: false,
    [Steps.PATIENT_INFO_CONTACT]: false,
    [Steps.APPOINTMENT_METHOD]: false,
    [Steps.CONFIRMATION]: true,
    [Steps.PACIFY_CONFIRMATION]: true,
    [Steps.AFFILIATE_THANK]: true,
    [Steps.SUCCESS_SCREEN]: false,
};

type useVCFRouterProps = {
    pathname: string;
    baseUrl?: string;
};

const useVCFRouter = (props: useVCFRouterProps) => {
    const { pathname, baseUrl } = props;
    const { setStep, step, isAffiliateView, isDoulaView, enableAppointmentStep } = useVCFContext();
    const [validSteps, setValidSteps] = React.useState<Record<Steps, boolean>>(defaultStepState);
    const [searchParams, setSearchParams] = useSearchParams();
    const selfCheck = searchParams.get('sc') === 'true';
    const affililateIdApta = searchParams.get('apta');
    const history = useNavigate();
    const { step: routeStepId = '1' } = useParams<{
        step: string;
    }>();
    const searchStr = new URLSearchParams(searchParams).toString();
    const setStepHandler = React.useCallback(
        (step: Steps) => {
            const fullPathname = `${pathname}/${step}`;
            history({ pathname: fullPathname, search: searchStr });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pathname, baseUrl, searchStr],
    );
    React.useEffect(() => {
        const routeStepIdNumber = parseInt(routeStepId, 10);
        const shouldValidSteps = validStepsForView.get(routeStepIdNumber);
        if (shouldValidSteps) {
            const isFilledForm = shouldValidSteps.reduce((isFilledForm, step) => {
                if (isFilledForm) {
                    return validSteps[step];
                }
                return isFilledForm;
            }, true);
            if (isFilledForm) {
                setStep(routeStepIdNumber);
                return;
            }
        }
        setStepHandler(Steps.PATIENT_INFO);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routeStepId]);
    const predefinedFirstName = searchParams.get('firstName') ?? '';
    const predefinedLastName = searchParams.get('lastName') ?? '';
    const getNextStep = React.useCallback(
        (step: Steps, options?: StepHandlerOptions) => {
            switch (step) {
                case Steps.PATIENT_INFO:
                    return Steps.PATIENT_INFO_CONTACT;
                case Steps.PATIENT_INFO_CONTACT:
                    if (isAffiliateView) {
                        return false;
                    }
                    if (isDoulaView) {
                        return false;
                    }
                    if (enableAppointmentStep && options?.atHomeOptionEnabled) {
                        return Steps.APPOINTMENT_METHOD;
                    }
                    return false;
                case Steps.APPOINTMENT_METHOD:
                    return false;
                default:
                    return false;
            }
        },
        [enableAppointmentStep, isDoulaView, isAffiliateView],
    );
    const formSteps = React.useMemo(() => {
        if (enableAppointmentStep) {
            return [Steps.PATIENT_INFO, Steps.PATIENT_INFO_CONTACT, Steps.APPOINTMENT_METHOD];
        }
        return [Steps.PATIENT_INFO, Steps.PATIENT_INFO_CONTACT];
    }, [enableAppointmentStep]);
    const isFormStep = formSteps.includes(step);
    return {
        getNextStep,
        predefinedLastName,
        predefinedFirstName,
        setStepHandler,
        selfCheck,
        setValidSteps,
        validSteps,
        setSearchParams,
        formSteps,
        isFormStep,
        affililateIdApta,
    };
};

export default useVCFRouter;
