// This component has a UI Test
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, IconButton, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add, InfoOutlined } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    AlertSeverity,
    useFetchCareTeamMembersForCareTeamMembersPageQuery,
    usePreFetchAppsQuery,
    useUsStateListQuery,
} from '~/schemaTypes';
import { CareTeamMemberEnum } from '~/selectors';
import { TriggerGlobalAlert } from '~/state';

import CareTeamMemberModal from './CareTeamMembersModal/CareTeamMembersModal';

const useStyles = makeStyles()(theme => ({
    root: {
        textOverflow: 'ellipsis',
        ...theme.containerStyles(theme),
        marginTop: 50,
    },
    detailPanel: {
        padding: '10px 40px',
    },
    idButton: {
        paddingLeft: 0,
    },
}));

const CareTeamMembers: React.FC = () => {
    const { classes } = useStyles();
    const [showCareTeamMemberModal, setShowCareTeamMemberModal] = useState(false);
    const [editCareTeamMemberId, setEditCareTeamMemberId] = useState('');
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);

    const { data: preFetchData, loading: preFetchLoading } = usePreFetchAppsQuery();

    const { data: usStateList, loading: usStateListLoading } = useUsStateListQuery();

    const appLookup = preFetchData?.applicationsV2.results.reduce((acc, curr) => {
        acc[curr.appBundleId] = curr.appBundleId;
        return acc;
    }, {} as { [key: string]: string });

    const { data: queryData, loading: queryLoading } =
        useFetchCareTeamMembersForCareTeamMembersPageQuery();

    const handleAddCareTeamMember = () => {
        setEditCareTeamMemberId('');
        setShowCareTeamMemberModal(true);
    };

    const handleEditCareTeamMember = (event: any, element: any) => {
        setEditCareTeamMemberId(element.id);
        setShowCareTeamMemberModal(true);
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div className={classes.root} data-test={CareTeamMemberEnum.CONTAINER} ref={tableRef}>
            <MaterialTable
                title="Care Team Members"
                isLoading={queryLoading || preFetchLoading || usStateListLoading}
                icons={tableIcons}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                data={[
                    ...(queryData?.careTeamMembersV2.results ?? [])
                        .map(o => ({ ...o }))
                        .sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1)),
                ]}
                actions={[
                    {
                        onClick: () => handleAddCareTeamMember(),
                        icon: () => <Add />,
                        tooltip: 'Add Care Team Member',
                        isFreeAction: true,
                        hidden: !pagePermissions?.CareTeamMembers.Edit,
                    },
                    {
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        onClick: (event, element) => handleEditCareTeamMember(event, element),
                        tooltip: 'Edit Care Team Member',
                        hidden: !pagePermissions?.CareTeamMembers.Edit,
                    },
                ]}
                columns={[
                    {
                        title: 'Id',
                        field: 'id',
                        sorting: false,
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    className={classes.idButton}
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Care Team Member Id copied to clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'Profile Name',
                        defaultSort: 'asc',
                        field: 'label.en',
                        render: ({ label: { en } }) => <Typography data-test={en}>{en}</Typography>,
                    },
                    {
                        title: 'Active Status',
                        field: 'isActive',
                        render: ({ isActive }) =>
                            isActive === false ? (
                                <Typography color="error">Not Active</Typography>
                            ) : (
                                <Typography>Active</Typography>
                            ),
                    },
                    {
                        title: 'App Id',
                        field: 'appBundleId',
                        lookup: appLookup,
                        filterCellStyle: { width: '500px' },
                    },
                    {
                        title: 'Care Team Member Type',
                        field: 'careTeamMemberType.name',
                        sorting: true,
                    },
                    {
                        title: 'Prerequisite Care Team Members',
                        field: 'prerequisiteTeamMemberItems',
                        render: ({ prerequisiteTeamMemberItems }) => {
                            return (
                                <Typography>
                                    {prerequisiteTeamMemberItems.map(
                                        (item: { nameWithType: string }, index: number) =>
                                            index === prerequisiteTeamMemberItems.length - 1
                                                ? `${item.nameWithType}`
                                                : `${item.nameWithType}, `,
                                    )}
                                </Typography>
                            );
                        },
                        sorting: true,
                    },
                    {
                        title: 'US State',
                        field: 'usState',
                        sorting: true,
                        lookup: usStateList?.usStateList.reduce(
                            (acc, cur) => {
                                const existingStates = queryData?.careTeamMembersV2.results.map(
                                    o => o.usState,
                                );
                                const abbr = cur.abbreviation ?? '';
                                if (existingStates?.includes(abbr))
                                    return { ...acc, [abbr]: cur.name };
                                return { ...acc };
                            },
                            { '': 'None' },
                        ),
                        render: ({ usState }) => {
                            return (
                                <Typography data-test={usState}>
                                    {
                                        usStateList?.usStateList.find(
                                            i => i.abbreviation === usState,
                                        )?.name
                                    }
                                </Typography>
                            );
                        },
                    },
                    {
                        title: 'Zip Code',
                        field: 'zipCode',
                        sorting: true,
                        render: ({ zipCode }) => (
                            <Typography data-test={zipCode}>{zipCode}</Typography>
                        ),
                    },
                ]}
                localization={{ header: { actions: '' } }}
                options={{
                    pageSize: rowsPerPage,
                    search: true,
                    pageSizeOptions: [25, 50, 100],
                }}
            />
            <Dialog
                scroll="body"
                open={showCareTeamMemberModal}
                fullWidth
                maxWidth="lg"
                aria-labelledby="form-dialog-title"
            >
                <CareTeamMemberModal
                    setOpen={setShowCareTeamMemberModal}
                    setCareTeamMemberId={setEditCareTeamMemberId}
                    id={editCareTeamMemberId}
                    usStateList={usStateList}
                />
            </Dialog>
        </div>
    );
};

export default CareTeamMembers;
