import moment from 'moment';
import isEqual from 'lodash/isEqual';
import {
    AffiliateApprovalStatus,
    AffiliateHistory,
    AffiliateHistoryData,
    LactationConsultant,
    Maybe,
    OnboardingStatus,
} from '~/schemaTypes';

export function displayDate(date: Date) {
    return moment(date).format('MMMM D, YYYY \\at h:mm A');
}

function differenceWithDuplicates<T>(
    array1: Array<T>,
    array2: Array<T>,
    cmp: (a: T, b: T) => boolean,
) {
    const excludeByIndex = (array: Array<T>, index: number) => {
        if (index < 0) {
            return array;
        }
        if (index === 0) {
            return array.slice(1, array.length);
        }
        return [...array.slice(0, index), ...array.slice(index + 1, array.length)];
    };

    return array2.reduce(
        (result, item) =>
            excludeByIndex(
                result,
                result.findIndex(curr => cmp(curr, item)),
            ),
        array1,
    );
}

function displayLactationConsultantsMessage(
    addedLactationConsultants: Maybe<Partial<LactationConsultant>>[],
    isAdded: boolean,
) {
    return `${addedLactationConsultants.length} Lactation Consultant(s) ${
        isAdded ? 'Added' : 'Removed'
    } (${addedLactationConsultants.map(lc => lc?.name ?? '').join(', ')})`;
}

function displayLactationConsultantsChanges(
    oldData: AffiliateHistoryData,
    newData: AffiliateHistoryData,
) {
    const { lactationConsultants: newLactationConsultants } = newData;
    const { lactationConsultants: oldLactationConsultants } = oldData;

    if (!newLactationConsultants || !oldLactationConsultants) {
        return '';
    }

    const hasNewLactationConsultants = newLactationConsultants.length > 0;
    const hasOldLactationConsultants = oldLactationConsultants.length > 0;

    if (!hasNewLactationConsultants && !hasOldLactationConsultants) {
        return '';
    }

    if (!hasNewLactationConsultants && hasOldLactationConsultants) {
        return displayLactationConsultantsMessage(oldLactationConsultants, false);
    }

    if (hasNewLactationConsultants && !hasOldLactationConsultants) {
        return displayLactationConsultantsMessage(newLactationConsultants, true);
    }

    const removedLactationConsultants = differenceWithDuplicates(
        oldLactationConsultants,
        newLactationConsultants,
        isEqual,
    );

    const result = [];
    if (removedLactationConsultants.length) {
        result.push(displayLactationConsultantsMessage(removedLactationConsultants, false));
    }

    const addedLactationConsultants = differenceWithDuplicates(
        newLactationConsultants,
        oldLactationConsultants,
        isEqual,
    );
    if (addedLactationConsultants.length) {
        result.push(displayLactationConsultantsMessage(addedLactationConsultants, true));
    }

    return result.join(' and ');
}

export function displayHistoryEntry({
    oldData,
    newData,
}: Pick<AffiliateHistory, 'oldData' | 'newData'>) {
    if (!newData || !oldData) {
        return null;
    }

    const updateMessages = [];

    if (newData?.onboardingStatus) {
        switch (newData?.onboardingStatus) {
            case OnboardingStatus.Completed: {
                updateMessages.push('Completed Onboarding');
                break;
            }
            default:
                break;
        }
    }

    if (newData?.approvalStatus) {
        switch (newData?.approvalStatus) {
            case AffiliateApprovalStatus.Approved: {
                updateMessages.push(`Marked Approved ✓`);
                break;
            }
            case AffiliateApprovalStatus.Denied: {
                updateMessages.push(`Marked Denied ✗`);
                break;
            }
            case AffiliateApprovalStatus.ApprovalNeeded: {
                updateMessages.push(`Requested Approval`);
                break;
            }
            default:
                break;
        }
    }

    const lactationConsultantsChanges = displayLactationConsultantsChanges(oldData, newData);
    if (lactationConsultantsChanges) {
        updateMessages.push(lactationConsultantsChanges);
    }

    if (newData?.w9FS3Link) {
        if (oldData?.w9FS3Link) {
            updateMessages.push('W9 Updated');
        } else {
            updateMessages.push('W9 Added');
        }
    }

    if (oldData?.w9FS3Link && !newData?.w9FS3Link) {
        updateMessages.push('W9 Removed');
    }

    return updateMessages.join('; ');
}
