import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Box, Button, Grid, Typography } from '@mui/material';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import { FILE_ACCEPT } from '~/views/AffiliateClaimSubmission/constants';
import { useStyles } from '~/views/AffiliateClaimSubmission/styles';
import useAffiliateCareView from '../../../hooks/useAffiliateCareView';

type FileUploadProps = {
    handleClearForm: () => void;
    isProcessing: boolean;
    register: UseFormRegisterReturn;
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    affiliateId?: string | null;
    title: string;
};

const FileUpload: React.FC<FileUploadProps> = props => {
    const { handleClearForm, isProcessing, register, handleFileChange, affiliateId, title } = props;
    const { classes } = useStyles();
    const { isAffiliateView } = useAffiliateCareView();
    return (
        <Box className={classes.uploadForm}>
            <form onReset={handleClearForm}>
                <Grid container flexDirection="column" rowSpacing={4} alignContent="center">
                    {!isAffiliateView && (
                        <Grid item xs={12}>
                            <Typography variant="body1">Affiliate ID</Typography>
                            <Box className={classes.affiliateBox} sx={{ p: 2 }}>
                                {affiliateId}
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <AsyncActionButton loading={isProcessing}>
                            <Button
                                color="secondary"
                                variant="outlined"
                                component="label"
                                disabled={isProcessing}
                            >
                                {title}
                                <input
                                    {...register}
                                    className={classes.inputFile}
                                    type="file"
                                    onChange={handleFileChange}
                                    accept={FILE_ACCEPT}
                                />
                            </Button>
                        </AsyncActionButton>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default FileUpload;
