import { OrderByDirectionEnum } from '~/schemaTypes';

export enum SurveyResponsesSortingFields {
    reviewed = 'reviewed',
    dueDate = 'dueDate',
    responseDate = 'responseDate',
    displayName = 'displayName',
    surveyScore = 'surveyScore',
}

export interface ISurveyResponsesOrderChange {
    field: SurveyResponsesSortingFields;
    direction: OrderByDirectionEnum;
    isInit: boolean;
}
