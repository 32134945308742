import { Box, Dialog, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { InfoOutlined } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import { displayDate } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import {
    AlertSeverity,
    ClinicalSummaryQueryVariables,
    Indication,
    Medication,
    Problem,
    ProblemComment,
    useClinicalSummaryQuery,
    useGetClinicalSummaryLogByIdLazyQuery,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import CommentsModal from './CommentsModal/CommentsModal';
import IndicationsModal from './IndicationsModal/IndicationsModal';
import MedicationModal from './MedicationModal/MedicationModal';
import HTMLRenderer from './HTMLRenderer';
import Dropdown from './Dropdown';

type RouteParams = {
    summaryId: string;
};

const ICD_10 = 'ICD-10-CM';

const useStyles = makeStyles()(theme => ({
    typographyValues: {
        padding: theme.spacing(1),
        border: '1px solid lightgray',
    },
    link: {
        color: 'blue',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export const tableHeaderStyle = {
    backgroundColor: 'lightgray',
    color: '#FFF',
    fontWeight: 600,
};

const ClinicalSummary = () => {
    const { summaryId } = useParams<RouteParams>();
    const { classes } = useStyles();

    const [showMedicationModal, setShowMedicationModal] = useState<boolean>(false);
    const [medicationData, setMedicationData] = useState<Medication | null>(null);

    const [showCommentsModal, setShowCommentsModal] = useState<boolean>(false);
    const [comments, setComments] = useState<ProblemComment[]>([]);

    const [showIndicationsModal, setShowIndicationsModal] = useState<boolean>(false);
    const [indications, setIndications] = useState<Indication[]>([]);

    const [rawLog, setRawLog] = useState<string>('');
    const [HTMLTextFieldValue, setHTMLTextFieldValue] = useState<string>('');

    const [fetchLogById] = useGetClinicalSummaryLogByIdLazyQuery({
        onCompleted: data => {
            if (data.getClinicalSummaryLogById?.raw) {
                setRawLog(data.getClinicalSummaryLogById.raw || '');
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'No Raw data found for this clinical summary',
                });
            }
        },
        onError: () => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'Error fetching raw log data for this clinical summary',
            });
        },
        fetchPolicy: 'network-only',
    });

    const fetchLog = (logId: string | null | undefined): void => {
        if (!logId) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'This summary has no logId',
            });
            return;
        }
        fetchLogById({ variables: { input: { logId } } });
    };

    const variables: ClinicalSummaryQueryVariables = {
        input: {
            id: summaryId,
        },
    };
    const { data, loading } = useClinicalSummaryQuery({
        variables,
        onCompleted: data => {
            if (data?.clinicalSummary?.logId) {
                fetchLog(data?.clinicalSummary?.logId);
            }
        },
    });

    const clinicalSummary = data ? data.clinicalSummary : undefined;

    const handleShowMedicationModal = (medication: Medication) => {
        setShowMedicationModal(true);
        setMedicationData(medication);
    };

    const getFilteredProblems = (problems: Problem[]): Problem[] => {
        return problems.filter(problem => {
            if (problem.codeSystemName === ICD_10) {
                return true;
            }
            if (problem.altCodes?.find(altCode => altCode.codeSystemName === ICD_10)) {
                return true;
            }

            return false;
        });
    };

    const filteredProblems = clinicalSummary?.problems
        ? getFilteredProblems(clinicalSummary?.problems)
        : [];

    const handleShowCommentsModal = (comments: ProblemComment[]) => {
        setShowCommentsModal(true);
        setComments(comments);
    };

    const handleCloseCommentsModal = () => {
        setShowCommentsModal(false);
        setComments([]);
    };

    const handleShowIndicationsModal = (indications: Indication[]) => {
        setShowIndicationsModal(true);
        setIndications(indications);
    };

    const handleCloseIndicationsModal = () => {
        setShowIndicationsModal(false);
        setIndications([]);
    };

    const rawLogFieldsDropdownTextToValueMap = {
        'Advance Directives': 'AdvanceDirectivesText',
        Allergies: 'AllergyText',
        Encounters: 'EncountersText',
        'Family History': 'FamilyHistoryText',
        Medications: 'MedicationsText',
        'Plan Of Care': 'PlanOfCareText',
        Problems: 'ProblemsText',
        'Resolved Problems': 'ResolvedProblemsText',
        Results: 'ResultText',
        'Social History': 'SocialHistoryText',
        'Vital Signs': 'VitalSignsText',
    };

    return (
        <>
            <Typography variant="h4">Clinical Summary</Typography>
            {loading && <Loading subtitle="Loading clinical summary" height={150} />}
            {!loading && clinicalSummary && (
                <Grid>
                    <Grid container spacing={2} wrap="wrap">
                        <Grid item xs={12} md={7}>
                            <Box mt={2} mb={2}>
                                <Typography variant="h6">Visit ID:</Typography>
                                <Typography className={classes.typographyValues}>
                                    {clinicalSummary.visitId}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Box mb={2}>
                                <Typography variant="h6">Patient Name:</Typography>
                                <Typography className={classes.typographyValues}>
                                    {clinicalSummary.patient?.fullName}
                                </Typography>
                            </Box>
                        </Grid>

                        {filteredProblems.length && (
                            <Grid item xs={12}>
                                <Box mb={2}>
                                    <Box mb={1}>
                                        <Typography variant="h6">Problems</Typography>
                                    </Box>
                                    <MaterialTable
                                        isLoading={loading}
                                        icons={tableIcons as MaterialTableProps<any>['icons']}
                                        columns={[
                                            {
                                                title: 'Name',
                                                field: 'name',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ name }) => name,
                                            },
                                            {
                                                title: 'Code (ICD-10-CM)',
                                                field: 'code',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ code }) => code,
                                            },
                                            {
                                                title: 'Start Date',
                                                field: 'startDate',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ startDate }) =>
                                                    startDate
                                                        ? displayDate({
                                                              isoDateStr: startDate,
                                                              format: 'MM/DD/YYYY',
                                                          })
                                                        : '-',
                                            },
                                            {
                                                title: 'End Date',
                                                field: 'endDate',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ endDate }) =>
                                                    endDate
                                                        ? displayDate({
                                                              isoDateStr: endDate,
                                                              format: 'MM/DD/YYYY',
                                                          })
                                                        : '-',
                                            },
                                            {
                                                title: 'Status',
                                                field: 'status',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ status }) => status?.name,
                                            },
                                            {
                                                title: 'Comments',
                                                field: 'comments',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ comments }) =>
                                                    comments?.length ? (
                                                        <Grid
                                                            className={classes.link}
                                                            onClick={() =>
                                                                handleShowCommentsModal(comments)
                                                            }
                                                        >
                                                            {comments?.length}
                                                        </Grid>
                                                    ) : (
                                                        0
                                                    ),
                                            },
                                        ]}
                                        data={_.cloneDeep(filteredProblems)}
                                        options={{
                                            thirdSortClick: false,
                                            search: false,
                                            paging: false,
                                            showTitle: false,
                                            toolbar: false,
                                            headerStyle: tableHeaderStyle,
                                        }}
                                    />
                                </Box>
                            </Grid>
                        )}

                        {clinicalSummary.resolvedProblems?.length && (
                            <Grid item xs={12}>
                                <Box mb={2}>
                                    <Box mb={1}>
                                        <Typography variant="h6">Resolved Problems</Typography>
                                    </Box>
                                    <MaterialTable
                                        isLoading={loading}
                                        icons={tableIcons as MaterialTableProps<any>['icons']}
                                        columns={[
                                            {
                                                title: 'Start Date',
                                                field: 'startDate',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ startDate }) =>
                                                    startDate
                                                        ? displayDate({
                                                              isoDateStr: startDate,
                                                              format: 'MM/DD/YYYY',
                                                          })
                                                        : '-',
                                            },
                                            {
                                                title: 'End Date',
                                                field: 'endDate',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ endDate }) =>
                                                    endDate
                                                        ? displayDate({
                                                              isoDateStr: endDate,
                                                              format: 'MM/DD/YYYY',
                                                          })
                                                        : '-',
                                            },

                                            {
                                                title: 'Code (ICD-10-CM)',
                                                field: 'code',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ code }) => code,
                                            },

                                            {
                                                title: 'Name',
                                                field: 'name',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ name }) => name,
                                            },

                                            {
                                                title: 'Status',
                                                field: 'status',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ status }) => status?.name,
                                            },
                                            {
                                                title: 'Comments',
                                                field: 'comments',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ comments }) =>
                                                    comments?.length ? (
                                                        <Grid
                                                            className={classes.link}
                                                            onClick={() =>
                                                                handleShowCommentsModal(comments)
                                                            }
                                                        >
                                                            {comments?.length}
                                                        </Grid>
                                                    ) : (
                                                        0
                                                    ),
                                            },
                                        ]}
                                        data={_.cloneDeep(clinicalSummary.resolvedProblems)}
                                        options={{
                                            thirdSortClick: false,
                                            search: false,
                                            paging: false,
                                            showTitle: false,
                                            toolbar: false,
                                            headerStyle: tableHeaderStyle,
                                        }}
                                    />
                                </Box>
                            </Grid>
                        )}

                        {clinicalSummary.medications?.length && (
                            <Grid item xs={12}>
                                <Box mb={2}>
                                    <Box mb={1}>
                                        <Typography variant="h6">Medication</Typography>
                                    </Box>
                                    <MaterialTable
                                        isLoading={loading}
                                        icons={tableIcons as MaterialTableProps<any>['icons']}
                                        columns={[
                                            {
                                                title: '',
                                                field: 'information',
                                                align: 'center',
                                                sorting: false,
                                                render: medication => (
                                                    <InfoOutlined
                                                        onClick={() =>
                                                            handleShowMedicationModal(medication)
                                                        }
                                                    />
                                                ),
                                            },
                                            {
                                                title: 'Medication',
                                                field: 'medicationName',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ product }) => product?.name,
                                            },
                                            {
                                                title: 'Status',
                                                field: 'status',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ status }) => status,
                                            },
                                            {
                                                title: 'Start Date',
                                                field: 'startDate',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ startDate }) =>
                                                    startDate
                                                        ? displayDate({
                                                              isoDateStr: startDate,
                                                              format: 'MM/DD/YYYY',
                                                          })
                                                        : '-',
                                            },
                                            {
                                                title: 'End Date',
                                                field: 'endDate',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ endDate }) =>
                                                    endDate
                                                        ? displayDate({
                                                              isoDateStr: endDate,
                                                              format: 'MM/DD/YYYY',
                                                          })
                                                        : '-',
                                            },

                                            {
                                                title: 'Free Text',
                                                field: 'freeText',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ freeTextSig }) => freeTextSig,
                                            },

                                            {
                                                title: 'Indications',
                                                field: 'indications',
                                                align: 'center',
                                                sorting: false,
                                                render: ({ indications }) =>
                                                    indications?.length ? (
                                                        <Grid
                                                            className={classes.link}
                                                            onClick={() =>
                                                                handleShowIndicationsModal(
                                                                    indications as Indication[],
                                                                )
                                                            }
                                                        >
                                                            {indications.length}
                                                        </Grid>
                                                    ) : (
                                                        0
                                                    ),
                                            },
                                        ]}
                                        data={_.cloneDeep(clinicalSummary.medications)}
                                        options={{
                                            thirdSortClick: false,
                                            search: false,
                                            paging: false,
                                            showTitle: false,
                                            toolbar: false,
                                            headerStyle: tableHeaderStyle,
                                        }}
                                    />
                                </Box>
                            </Grid>
                        )}

                        <Grid item xs={12} md={7}>
                            <Box mt={2} mb={2}>
                                <Typography variant="h6">Created At:</Typography>
                                <Typography className={classes.typographyValues}>
                                    {displayDate({
                                        isoDateStr: clinicalSummary.createdAt,
                                        format: 'MM-DD-YYYY HH:mm:ss',
                                    })}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={7}>
                            <Box mb={2}>
                                <Typography variant="h6">Updated At:</Typography>
                                <Typography className={classes.typographyValues}>
                                    {displayDate({
                                        isoDateStr: clinicalSummary.updatedAt,
                                        format: 'MM-DD-YYYY HH:mm:ss',
                                    })}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={7}>
                            <Box mb={5}>
                                <Typography variant="h6">Redox EHR ID:</Typography>
                                <Typography className={classes.typographyValues}>
                                    {clinicalSummary.redoxEhrId}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Clinical Summary HTML Viewer:</Typography>
                        {rawLog && data ? (
                            <div style={{ marginTop: '15px', marginBottom: '100px' }}>
                                <Dropdown
                                    options={Object.keys(rawLogFieldsDropdownTextToValueMap)}
                                    label="Clinical Summary Log Field"
                                    onSelect={value => {
                                        const key =
                                            rawLogFieldsDropdownTextToValueMap[
                                                value as keyof typeof rawLogFieldsDropdownTextToValueMap
                                            ];
                                        const parsedLog = JSON.parse(rawLog);
                                        if (key in parsedLog) {
                                            setHTMLTextFieldValue(
                                                parsedLog[key as keyof typeof parsedLog],
                                            );
                                        }
                                    }}
                                />
                                <HTMLRenderer htmlString={HTMLTextFieldValue} />
                            </div>
                        ) : (
                            <div>Raw log not found</div>
                        )}
                    </Grid>
                </Grid>
            )}

            {showCommentsModal && comments && (
                <Dialog
                    scroll="paper"
                    open={showCommentsModal}
                    maxWidth="md"
                    fullWidth
                    aria-labelledby="form-dialog-title"
                >
                    <CommentsModal setShow={handleCloseCommentsModal} comments={comments} />
                </Dialog>
            )}

            {showIndicationsModal && indications && (
                <Dialog
                    scroll="paper"
                    open={showIndicationsModal}
                    maxWidth="md"
                    fullWidth
                    aria-labelledby="form-dialog-title"
                >
                    <IndicationsModal
                        setShow={handleCloseIndicationsModal}
                        indications={indications}
                    />
                </Dialog>
            )}

            {showMedicationModal && medicationData && (
                <Dialog
                    scroll="paper"
                    open={showMedicationModal}
                    fullWidth
                    maxWidth="lg"
                    aria-labelledby="form-dialog-title"
                >
                    <MedicationModal setShow={setShowMedicationModal} medication={medicationData} />
                </Dialog>
            )}
        </>
    );
};

export default ClinicalSummary;
