import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormHelperText,
    Grid,
    IconButton,
    Link,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import React, { useEffect, useMemo } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import {
    useCreateCompoundQuestionMutation,
    useUpdateCompoundQuestionMutation,
    useFetchProfileDefsForCompoundQuestionsQuery,
    UserProfileValueType,
    useGetSurveyDefsByCompoundQuestionIdLazyQuery,
    useDeleteCompoundQuestionMutation,
    useCompoundQuestionLazyQuery,
    CompoundQuestionQuery,
    GetCompoundQuestionInput,
    Exact,
} from '~/schemaTypes';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import { DeleteOutline } from '@mui/icons-material';
import { TriggerGlobalConfirm } from '~/state';
import Loading from '~/components/Loading/Loading';
import { ApolloError, LazyQueryExecFunction } from '@apollo/client';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { useStyles } from './styles';

import {
    CompoundQuestionProfileDef,
    ProfileDef,
    CompoundQuestion,
    CompoundQuestionModalProps,
    ErrorType,
    CompoundQuestionModalCloseType,
    CompoundQuestionFormInput,
} from '../types';
import { COMPOUND_QUESTION_SCHEMA } from './yupSchema';

const EMPTY_PROFILE_DEF_ID = '';
const PROFILE_VALUES_TYPES = [UserProfileValueType.Bool];

const getEmptyProfileDefinitionItem = (): CompoundQuestionProfileDef => ({
    questionProfileDefId: EMPTY_PROFILE_DEF_ID,
    labelOverride: {},
});

const hasEmptyProfileDef = (profileDefs: CompoundQuestionProfileDef[]): boolean =>
    profileDefs.some(item => item.questionProfileDefId === EMPTY_PROFILE_DEF_ID);

const getProfileDefsByValueType = (
    profileDefs: ProfileDef[],
    profileValueType: UserProfileValueType,
): ProfileDef[] =>
    profileDefs
        .filter(item => item.valueType === profileValueType && !item.surveyQuestionDisabled)
        .sort((a, b) => (a.name > b.name ? 1 : -1));

const getDataToSave = (input: CompoundQuestion) => ({
    label: {
        en: input.label.en,
        es: input.label.es,
    },
    profileValueType: input.profileValueType,
    questionProfileDefs: input.questionProfileDefs.map(profileDef => {
        const en = profileDef.labelOverride?.en ?? '';
        const es = profileDef.labelOverride?.es ?? '';
        const labelOverride = en !== '' || es !== '' ? { en, es } : undefined;
        return {
            questionProfileDefId: profileDef.questionProfileDefId,
            labelOverride,
            value: {
                bool: profileDef.value?.bool,
            },
        };
    }),
});

const errorMsg = {
    [ErrorType.EXTRACT]: (message: string) =>
        `There was a problem extracting the compound question: ${message}`,
    [ErrorType.CREATE]: (message: string) =>
        `There was a problem saving the compound question: ${message}`,
    [ErrorType.UPDATE]: (message: string) =>
        `There was a problem updating the compound question: ${message}`,
    [ErrorType.DELETE]: (message: string) =>
        `There was a problem deleting the compound question: ${message}`,
    [ErrorType.EXTRACT_SURVEYS]: (message: string) =>
        `There was a problem extracting surveys: ${message}`,
};

const handleErrorMutation = (errorType: ErrorType, error: ApolloError, onClose: () => void) => {
    TriggerGlobalConfirm({
        callback: () => onClose(),
        message: errorMsg[errorType](error.message),
    });
};

const setEmptyValues = (compoundQuestion: CompoundQuestion): CompoundQuestion => {
    return {
        ...compoundQuestion,
        questionProfileDefs: compoundQuestion.questionProfileDefs.map(item => {
            return {
                ...item,
                labelOverride: item.labelOverride ?? {},
            };
        }),
    };
};

const getDefaultValues = async (
    getCompoundQuestion: LazyQueryExecFunction<
        CompoundQuestionQuery,
        Exact<{ input: GetCompoundQuestionInput }>
    >,
    { id, compoundQuestion, onClose }: CompoundQuestionModalProps,
): Promise<CompoundQuestion> => {
    if (compoundQuestion) {
        return setEmptyValues(compoundQuestion);
    }
    if (id) {
        const { data } = await getCompoundQuestion({
            variables: {
                input: {
                    id,
                },
            },
            onError: error => handleErrorMutation(ErrorType.EXTRACT, error, onClose),
        });
        const compoundQuestionById = data?.compoundQuestion;
        if (compoundQuestionById) {
            return setEmptyValues(compoundQuestionById);
        }
    }

    const isOneType = PROFILE_VALUES_TYPES.length === 1;

    return {
        id: null,
        label: { en: '', es: '' },
        profileValueType: isOneType ? PROFILE_VALUES_TYPES[0] : ('' as UserProfileValueType),
        questionProfileDefs: isOneType ? [getEmptyProfileDefinitionItem()] : [],
    };
};

const CompoundQuestionModal = (props: CompoundQuestionModalProps) => {
    const { id, onClose } = props;
    const { classes } = useStyles();

    const [getCompoundQuestion] = useCompoundQuestionLazyQuery();

    const {
        setValue,
        watch,
        control,
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isDirty, isLoading: isLoadingDefaultValues },
    } = useForm<CompoundQuestionFormInput>({
        resolver: yupResolver(COMPOUND_QUESTION_SCHEMA as any),
        defaultValues: () => getDefaultValues(getCompoundQuestion, props),
    });
    const profileValueType = watch('profileValueType');
    const questionProfileDefs = watch('questionProfileDefs') ?? [];

    const {
        fields: profileDefinitionsFields,
        append: appendProfileDefinitionField,
        remove: removeProfileDefinitionFields,
    } = useFieldArray({
        name: 'questionProfileDefs',
        control,
    });

    const { data: profileDefs, loading: loadingProfileDefs } =
        useFetchProfileDefsForCompoundQuestionsQuery();

    const [
        getSurveyDefsByCompoundQuestionId,
        { data: surveysData, loading: loadingSurveyDefsByCompoundQuestionId, error: surveysError },
    ] = useGetSurveyDefsByCompoundQuestionIdLazyQuery({
        fetchPolicy: 'network-only',
        onError: error => handleErrorMutation(ErrorType.EXTRACT_SURVEYS, error, onClose),
    });

    const surveys = surveysData?.getSurveyDefsByCompoundQuestionId ?? [];

    useEffect(() => {
        if (id) {
            getSurveyDefsByCompoundQuestionId({
                variables: {
                    compoundQuestionId: id,
                },
            });
        }
    }, [id, getSurveyDefsByCompoundQuestionId]);

    const profileDefsByValueType = useMemo(
        () =>
            getProfileDefsByValueType(
                profileDefs?.userProfileDefs ?? [],
                profileValueType as UserProfileValueType,
            ),
        [profileValueType, profileDefs],
    );

    const [createCompoundQuestion, { loading: loadingCreateCompoundQuestion }] =
        useCreateCompoundQuestionMutation({
            awaitRefetchQueries: true,
            refetchQueries: ['CompoundQuestionsV2'],
            onCompleted: () => onClose(),
            onError: error => handleErrorMutation(ErrorType.CREATE, error, onClose),
        });

    const [updateCompoundQuestion, { loading: loadingUpdateCompoundQuestion }] =
        useUpdateCompoundQuestionMutation({
            awaitRefetchQueries: true,
            refetchQueries: ['CompoundQuestionsV2'],
            onCompleted: () => onClose(),
            onError: error => handleErrorMutation(ErrorType.UPDATE, error, onClose),
        });

    const [deleteCompoundQuestion, { loading: loadingDeleteCompoundQuestion }] =
        useDeleteCompoundQuestionMutation({
            awaitRefetchQueries: true,
            refetchQueries: ['CompoundQuestionsV2'],
            onCompleted: () => onClose(CompoundQuestionModalCloseType.DELETED),
            onError: error => handleErrorMutation(ErrorType.DELETE, error, onClose),
        });

    const handleDeleteCompoundQuestion = async () => {
        TriggerGlobalConfirm({
            message: 'Do you really want to delete this Compound Question?',
            callback: async () => {
                if (id) {
                    await deleteCompoundQuestion({
                        variables: {
                            input: {
                                id,
                            },
                        },
                    });
                }
            },
        });
    };

    const handleSubmitForm = async (input: CompoundQuestion) => {
        const data = getDataToSave(input);

        if (id) {
            await updateCompoundQuestion({
                variables: {
                    updateCompoundQuestionInput: { id, data },
                },
            });
        } else {
            await createCompoundQuestion({
                variables: {
                    createCompoundQuestionInput: data,
                },
            });
        }
    };

    const isLoading =
        isLoadingDefaultValues ||
        loadingCreateCompoundQuestion ||
        loadingUpdateCompoundQuestion ||
        loadingDeleteCompoundQuestion;

    return (
        <Dialog open fullWidth>
            <form noValidate onSubmit={handleSubmit(handleSubmitForm)}>
                <DialogTitleWithClose id="modalTitle" onClose={onClose}>
                    {id ? 'Edit' : 'Add'} Compound Question
                </DialogTitleWithClose>
                <DialogContent dividers>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <div className={classes.content}>
                            <OutlinedSection title="Label">
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="English *"
                                    fullWidth
                                    margin="dense"
                                    {...register('label.en')}
                                    error={!!errors?.label?.en}
                                    helperText={errors.label?.en?.message}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Spanish"
                                    fullWidth
                                    margin="dense"
                                    {...register('label.es')}
                                    error={!!errors?.label?.es}
                                    helperText={errors.label?.es?.message}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </OutlinedSection>
                            <ReactHookFormSelect
                                control={control}
                                defaultValue=""
                                name="profileValueType"
                                variant="outlined"
                                label="Profile Value Type *"
                                fullWidth
                                margin="dense"
                                error={!!errors.profileValueType}
                                onChangeHandler={() => {
                                    removeProfileDefinitionFields();
                                    appendProfileDefinitionField(getEmptyProfileDefinitionItem());
                                }}
                                disabled={PROFILE_VALUES_TYPES.length === 1}
                            >
                                {Object.values(PROFILE_VALUES_TYPES).map(profileValueType => (
                                    <MenuItem key={profileValueType} value={profileValueType}>
                                        {profileValueType}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                            {errors?.profileValueType && (
                                <FormHelperText error className={classes.helperMessage}>
                                    {errors.profileValueType?.message}
                                </FormHelperText>
                            )}
                            <div>
                                <strong>Profile Definitions:</strong>
                            </div>
                            {loadingProfileDefs && <Loading height={70} />}
                            {!loadingProfileDefs &&
                                profileDefinitionsFields.map((profileDefField, index) => (
                                    <OutlinedSection
                                        key={profileDefField.id}
                                        outlineColor="secondary"
                                        title=""
                                    >
                                        <Grid container item xs={12}>
                                            <Grid item xs={11}>
                                                <ReactHookFormSelect
                                                    control={control}
                                                    defaultValue=""
                                                    name={`questionProfileDefs.${index}.questionProfileDefId`}
                                                    variant="outlined"
                                                    label="Profile Definition *"
                                                    fullWidth
                                                    margin="dense"
                                                    loading={loadingProfileDefs}
                                                    error={
                                                        !!errors?.questionProfileDefs?.[index]
                                                            ?.questionProfileDefId?.message
                                                    }
                                                    onChangeHandler={id => {
                                                        const profileDef =
                                                            profileDefsByValueType.find(
                                                                i => i.id === id,
                                                            );
                                                        if (profileDef) {
                                                            setValue(
                                                                `questionProfileDefs.${index}.labelOverride.en`,
                                                                profileDef.label?.en ?? '',
                                                            );
                                                            setValue(
                                                                `questionProfileDefs.${index}.labelOverride.es`,
                                                                profileDef.label?.es ?? '',
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {profileDefsByValueType.map(({ id, name }) => (
                                                        <MenuItem key={id} value={id}>
                                                            {name}
                                                        </MenuItem>
                                                    ))}
                                                </ReactHookFormSelect>
                                                {errors?.questionProfileDefs?.[index]
                                                    ?.questionProfileDefId?.message && (
                                                    <FormHelperText
                                                        error
                                                        className={classes.helperMessage}
                                                    >
                                                        {
                                                            errors?.questionProfileDefs[index]
                                                                ?.questionProfileDefId
                                                                ?.message as string
                                                        }
                                                    </FormHelperText>
                                                )}
                                                <OutlinedSection
                                                    title="Override Label"
                                                    outlineColor="secondary"
                                                >
                                                    <Grid container item xs={12}>
                                                        <TextField
                                                            variant="outlined"
                                                            type="text"
                                                            label="English"
                                                            fullWidth
                                                            margin="dense"
                                                            defaultValue={
                                                                profileDefField?.labelOverride?.en
                                                            }
                                                            {...register(
                                                                `questionProfileDefs.${index}.labelOverride.en`,
                                                            )}
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    </Grid>
                                                    <Grid container item xs={12}>
                                                        <TextField
                                                            variant="outlined"
                                                            type="text"
                                                            label="Spanish"
                                                            fullWidth
                                                            margin="dense"
                                                            defaultValue=""
                                                            {...register(
                                                                `questionProfileDefs.${index}.labelOverride.es`,
                                                            )}
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    </Grid>
                                                </OutlinedSection>
                                                <ReactHookFormSelect
                                                    control={control}
                                                    name={`questionProfileDefs.${index}.value.bool`}
                                                    variant="outlined"
                                                    label="Profile Value *"
                                                    fullWidth
                                                    margin="dense"
                                                    error={
                                                        !!errors?.questionProfileDefs?.[index]
                                                            ?.value?.bool?.message
                                                    }
                                                >
                                                    <MenuItem value={true as any}>true</MenuItem>
                                                    <MenuItem value={false as any}>false</MenuItem>
                                                </ReactHookFormSelect>
                                                {errors?.questionProfileDefs?.[index]?.value?.bool
                                                    ?.message && (
                                                    <FormHelperText
                                                        error
                                                        className={classes.helperMessage}
                                                    >
                                                        {
                                                            errors?.questionProfileDefs[index]
                                                                ?.value?.bool?.message as string
                                                        }
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={1}
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <IconButton
                                                    onClick={() =>
                                                        removeProfileDefinitionFields(index)
                                                    }
                                                    size="large"
                                                    color="primary"
                                                >
                                                    <DeleteOutline />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </OutlinedSection>
                                ))}
                            {errors?.questionProfileDefs && (
                                <FormHelperText error>
                                    {errors.questionProfileDefs?.message}
                                </FormHelperText>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    appendProfileDefinitionField(getEmptyProfileDefinitionItem())
                                }
                                disabled={
                                    hasEmptyProfileDef(questionProfileDefs) || !profileValueType
                                }
                            >
                                Add Profile Definition
                            </Button>
                            {id && !surveysError && (
                                <>
                                    <div className={classes.surveys}>
                                        <strong>
                                            Surveys where the compound question is used:
                                        </strong>
                                    </div>
                                    {loadingSurveyDefsByCompoundQuestionId && (
                                        <Loading height={70} />
                                    )}
                                    {!loadingSurveyDefsByCompoundQuestionId &&
                                        (surveys.length > 0 ? (
                                            surveys.map(survey => (
                                                <Link
                                                    key={survey?.id}
                                                    href={`/app-config/surveys/${survey?.id}`}
                                                    underline="hover"
                                                >
                                                    <Typography className={classes.surveyItem}>
                                                        {survey?.name}
                                                    </Typography>
                                                </Link>
                                            ))
                                        ) : (
                                            <Typography className={classes.surveyItem}>
                                                No surveys
                                            </Typography>
                                        ))}
                                </>
                            )}
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        disabled={isLoading || loadingProfileDefs || isSubmitting || !isDirty}
                    >
                        {id ? 'Update ' : 'Add '}
                    </Button>
                    {!surveysError &&
                        !isLoading &&
                        !loadingSurveyDefsByCompoundQuestionId &&
                        id &&
                        surveys.length === 0 && (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleDeleteCompoundQuestion}
                            >
                                Remove
                            </Button>
                        )}
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CompoundQuestionModal;
