import { DialogContent, Grid, Divider } from '@mui/material';
import moment from 'moment';
import React, { Dispatch, SetStateAction } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { useFetchUserProfileDefHistoryByIdQuery } from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';

type ProfileDefsModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    id: string;
};

const ProfileDefsHistoryModal: React.FC<ProfileDefsModalProps> = ({ setOpen, id }) => {
    const { data: profileDefinitionData, loading: profileDefinitionLoading } =
        useFetchUserProfileDefHistoryByIdQuery({ variables: { input: { id } } });

    const userProfileDef = profileDefinitionData?.userProfileDef;
    if (profileDefinitionLoading) return <Loading />;
    return (
        <form noValidate>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setOpen(false)}>
                {userProfileDef?.name} Profile Definition History
            </DialogTitleWithClose>
            <Divider style={{ marginLeft: 10, marginRight: 10 }} />
            <DialogContent className="formCont">
                <Grid container>
                    {userProfileDef?.history &&
                        userProfileDef?.history.map(item => (
                            <Grid item xs={12} style={{ marginBottom: 10 }}>
                                <span>
                                    <b>{item?.username}</b>
                                </span>
                                &nbsp;
                                <span>{item?.type}</span>&nbsp;
                                <span>
                                    <b>{item?.name}</b>
                                </span>
                                &nbsp;
                                <span>
                                    {moment(item?.datetime).local().format('MM/DD/YYYY h:mm a')}
                                </span>
                                <br />
                                <span style={{ marginLeft: 20 }}>{item?.beforeState}</span>
                                &nbsp;&mdash;&gt;&nbsp;
                                <span>{item?.afterState}</span>&nbsp;
                            </Grid>
                        ))}
                    {userProfileDef?.history && userProfileDef?.history.length <= 0 && (
                        <span>No history logged for this item</span>
                    )}
                </Grid>
            </DialogContent>
        </form>
    );
};

export default ProfileDefsHistoryModal;
