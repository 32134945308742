/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AccessToken, PersistAuthTokens, RefreshToken } from '~/state';
import {
    AssociateSoftwareTokenCommand,
    CognitoIdentityProviderClient,
    GetUserCommand,
    GlobalSignOutCommand,
    InitiateAuthCommand,
    InitiateAuthResponse,
    RespondToAuthChallengeCommand,
    RespondToAuthChallengeResponse,
    SetUserMFAPreferenceCommand,
    VerifySoftwareTokenCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { settings } from '~/utils/settings';
import { log } from '~/utils/log.svc';
import { cognitoGenerateSrp } from './cognito-srp.svc';

export const cognitoClient = new CognitoIdentityProviderClient({
    region: settings.aws.region,
});

const storeAuthResponse = (authResult: InitiateAuthResponse | RespondToAuthChallengeResponse) => {
    if (authResult.AuthenticationResult?.AccessToken) {
        const { AccessToken, IdToken, RefreshToken } = authResult.AuthenticationResult;
        PersistAuthTokens({
            accessToken: AccessToken!,
            idToken: IdToken!,
            refreshToken: RefreshToken,
        });
    }
};

export const cognitoLogout = async () => {
    const token = AccessToken()?.token;
    const signOutResponse = token
        ? await cognitoClient.send(new GlobalSignOutCommand({ AccessToken: token }))
        : undefined;
    log.info({ signOutResponse }, 'cognitoLogout');
    return signOutResponse;
};

export const cognitoPasswordLogin = async (username: string, password: string) => {
    const { SRP_A, calculateSrpSignature } = await cognitoGenerateSrp(
        settings.aws.cognito.userPoolId,
    );
    log.debug(`Invoking initiateAuth ...`);
    const challenge = await cognitoClient.send(
        new InitiateAuthCommand({
            ClientId: settings.aws.cognito.clientId,
            AuthFlow: 'USER_SRP_AUTH',
            AuthParameters: {
                SRP_A,
                USERNAME: username,
                CHALLENGE_NAME: 'SRP_A',
            },
        }),
    );
    log.debug({ challenge }, `Response from initiateAuth:`);
    // assertIsChallengeResponse(challenge);
    const { SALT, SRP_B, SECRET_BLOCK, USER_ID_FOR_SRP } = challenge.ChallengeParameters!;
    const { PASSWORD_CLAIM_SIGNATURE, TIMESTAMP } = await calculateSrpSignature({
        SECRET_BLOCK,
        SALT,
        SRP_B,
        USER_ID_FOR_SRP,
        password,
    });
    log.debug(`Invoking respondToAuthChallenge ...`);
    const authResult = await cognitoClient.send(
        new RespondToAuthChallengeCommand({
            ClientId: settings.aws.cognito.clientId,
            ChallengeName: challenge.ChallengeName,
            ChallengeResponses: {
                USERNAME: username,
                PASSWORD_CLAIM_SECRET_BLOCK: SECRET_BLOCK,
                TIMESTAMP,
                PASSWORD_CLAIM_SIGNATURE,
            },
            Session: challenge.Session,
        }),
    );
    log.debug({ authResult }, `Response from respondToAuthChallenge:`);
    storeAuthResponse(authResult);
    return authResult;
};

export const cognitoMfaChallengeResponse = async (
    username: string,
    code: string,
    session: string,
) => {
    const response = await cognitoClient.send(
        new RespondToAuthChallengeCommand({
            ClientId: settings.aws.cognito.clientId,
            ChallengeName: 'SOFTWARE_TOKEN_MFA',
            ChallengeResponses: {
                USERNAME: username,
                SOFTWARE_TOKEN_MFA_CODE: code,
            },
            Session: session,
        }),
    );
    log.info({ response }, 'cognitoMfaChallengeResponse');
    storeAuthResponse(response);
    return response;
};

export const cognitoNewPasswordChallengeResponse = async (
    username: string,
    newPassword: string,
    session: string,
) => {
    const response = await cognitoClient.send(
        new RespondToAuthChallengeCommand({
            ClientId: settings.aws.cognito.clientId,
            ChallengeName: 'NEW_PASSWORD_REQUIRED',
            ChallengeResponses: {
                USERNAME: username,
                NEW_PASSWORD: newPassword,
            },
            Session: session,
        }),
    );
    log.info({ response }, 'cognitoNewPasswordChallengeResponse');
    storeAuthResponse(response);
    return response;
};

let cognitoRefreshTokensPromise: Promise<void> | null = null;
export const cognitoRefreshTokens = async (throwMissingRefreshToken = false) => {
    if (!cognitoRefreshTokensPromise) {
        const refreshToken = RefreshToken()?.token;
        if (!refreshToken) {
            const err = new Error('No refresh token found');
            log.error({ err }, 'cognitoRefreshTokens: no refresh token');
            if (throwMissingRefreshToken) throw err;
            return;
        }
        cognitoRefreshTokensPromise = (async () => {
            try {
                const response = await cognitoClient.send(
                    new InitiateAuthCommand({
                        AuthFlow: 'REFRESH_TOKEN_AUTH',
                        ClientId: settings.aws.cognito.clientId,
                        AuthParameters: {
                            REFRESH_TOKEN: refreshToken,
                        },
                    }),
                );
                if (response.AuthenticationResult?.AccessToken) {
                    PersistAuthTokens({
                        accessToken: response.AuthenticationResult!.AccessToken!,
                        idToken: response.AuthenticationResult!.IdToken!,
                    });
                } else {
                    log.error({ response }, 'cognitoRefreshTokens: unexpected response');
                }
            } catch (err) {
                log.error({ err }, 'cognitoRefreshTokens: error');
            } finally {
                cognitoRefreshTokensPromise = null;
            }
        })();
    }
    await cognitoRefreshTokensPromise;
};

export const cognitoCurrentUser = async () => {
    const response = await cognitoClient.send(
        new GetUserCommand({
            AccessToken: AccessToken()?.token,
        }),
    );
    return response;
};

export const cognitoSetupMfa = async () => {
    const response = await cognitoClient.send(
        new AssociateSoftwareTokenCommand({
            AccessToken: AccessToken()?.token,
        }),
    );
    return response;
};

export const cognitoVerifyMfaSetup = async (code: string) => {
    if (!AccessToken()?.token) {
        throw new Error('No access token found');
    }
    const verifyResponse = await cognitoClient.send(
        new VerifySoftwareTokenCommand({
            AccessToken: AccessToken()?.token,
            UserCode: code,
        }),
    );
    if (verifyResponse.Status !== 'SUCCESS') {
        throw new Error('MFA verification failed');
    }
    const prefsResponse = await cognitoClient.send(
        new SetUserMFAPreferenceCommand({
            AccessToken: AccessToken()?.token,
            SMSMfaSettings: { Enabled: false },
            SoftwareTokenMfaSettings: { Enabled: true, PreferredMfa: true },
            EmailMfaSettings: { Enabled: false },
        }),
    );
    return prefsResponse;
};

export const cognitoDisableMfa = async () => {
    if (!AccessToken()?.token) {
        throw new Error('No access token found');
    }
    const prefsResponse = await cognitoClient.send(
        new SetUserMFAPreferenceCommand({
            AccessToken: AccessToken()?.token,
            SMSMfaSettings: { Enabled: false },
            SoftwareTokenMfaSettings: { Enabled: false },
            EmailMfaSettings: { Enabled: false },
        }),
    );
    return prefsResponse;
};
