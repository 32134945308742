import React, { useCallback, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Grid, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormInput } from '~/views/AffiliateCare/Onboarding/steps/Routing/types';
import RoutingForm from '~/views/AffiliateCare/Onboarding/steps/Routing/components/RoutingForm';
import {
    useRoutingSettingsFetchAffiliateLazyQuery,
    RoutingSettingsFetchAffiliateQuery,
    OnSuccessfulEligibility,
    useUpdateRoutingInfoForAffiliateMutation,
} from '~/schemaTypes';
import { useAffiliateCareView } from '~/hooks';
import Loading from '~/components/Loading/Loading';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import Layout from '../components/Layout';
import { ROUTING_FORM_SCHEMA } from './yupSchema';

const formDataMapper = (data: RoutingSettingsFetchAffiliateQuery): FormInput => ({
    onSuccessfulEligibility: (data.virtualCareAffiliateByAffiliateId?.data
        ?.onSuccessfulEligibility ??
        OnSuccessfulEligibility.RouteToLanding) as OnSuccessfulEligibility,
    routingURL: data.virtualCareAffiliateByAffiliateId?.data?.routingURL,
    customSuccessText: data.virtualCareAffiliateByAffiliateId?.data?.customSuccessText,
});

const Routing = () => {
    const { userAffiliateId, id } = useAffiliateCareView();
    const [formInit, setFormInit] = useState(false);
    const [fetchRoutingSettings, { loading: fetchRoutingDataLazyLoading, data }] =
        useRoutingSettingsFetchAffiliateLazyQuery({
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
        });
    const formData = useForm<FormInput>({
        resolver: yupResolver(ROUTING_FORM_SCHEMA as any),
        defaultValues: async () => {
            const response = await fetchRoutingSettings({
                variables: {
                    input: {
                        affiliateId: userAffiliateId,
                    },
                },
            });
            if (!response.data) {
                return {
                    onSuccessfulEligibility: OnSuccessfulEligibility.RouteToLanding,
                };
            }
            setFormInit(true);
            return formDataMapper(response.data);
        },
    });
    const {
        formState: { isDirty },
        handleSubmit,
        reset,
    } = formData;
    const [updateRouting, { loading: updateLoading }] = useUpdateRoutingInfoForAffiliateMutation({
        onCompleted: async () => {
            const response = await fetchRoutingSettings({
                variables: {
                    input: {
                        affiliateId: userAffiliateId,
                    },
                },
            });
            if (response.data) {
                reset(formDataMapper(response.data));
            }
        },
    });

    const loading =
        fetchRoutingDataLazyLoading ||
        !data?.virtualCareAffiliateByAffiliateId?.data ||
        !formInit ||
        updateLoading;
    const onSubmit = useCallback(
        (data: FormInput) => {
            updateRouting({
                variables: {
                    input: {
                        id,
                        data,
                    },
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id],
    );
    return (
        <Layout title="Routing Customization">
            <Grid item xs={12} container rowSpacing={5}>
                <Grid item xs={12} container>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            We will give you a unique link to a form that you can give to patients
                            to verify their insurance eligibility.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            What action would you like to take when they complete the form? (You can
                            change this answer later)
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={9} lg={7} xl={6} minHeight={350}>
                    {loading ? (
                        <Loading />
                    ) : (
                        <FormProvider {...formData}>
                            <RoutingForm
                                affiliate={data?.virtualCareAffiliateByAffiliateId?.data || null}
                            />
                        </FormProvider>
                    )}
                </Grid>
                <Grid item xs={12} container>
                    <Grid item>
                        <AsyncActionButton loading={loading}>
                            <Button
                                disabled={loading || !isDirty}
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit(onSubmit)}
                            >
                                Update Routing Customization
                            </Button>
                        </AsyncActionButton>
                    </Grid>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default Routing;
