import { Dialog, DialogContent, TablePagination } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import MaterialTable from '@material-table/core';
import React, { useEffect, useRef, useState } from 'react';
import { JsonViewer } from '@textea/json-viewer';
import { useParams } from 'react-router-dom';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import tableIcons from '~/helpers/tableIcons';
import {
    useFetchAppUserTelemetryLazyQuery,
    useFetchPatientAppUsersForArticleFeedQuery,
} from '~/schemaTypes';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import Loading from '~/components/Loading/Loading';
import { PAGESIZE } from '~/constants';

const PatientAppEvents: React.FC = () => {
    const { id: patientId } = useParams<{ id: string }>();
    const [appUserId, setAppUserId] = useState<string>('');
    const [eventData, setEventData] = useState<string>('');
    const [eventType, setSelectedEventType] = useState<string | undefined>();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const tableRef = useRef<HTMLDivElement>(null);

    const { data: patientData, loading: patientDataLoading } =
        useFetchPatientAppUsersForArticleFeedQuery({ variables: { input: { id: patientId } } });

    const [getTelemetry, { data, loading: isLoading }] = useFetchAppUserTelemetryLazyQuery({
        variables: {
            input: { skip: page * rowsPerPage, limit: rowsPerPage, appUserId, eventType },
        },
    });
    useEffect(() => {
        if (patientData?.patient?.appUserId) {
            setAppUserId(patientData.patient.appUserId);
        }
    }, [patientData]);

    useEffect(() => {
        if (appUserId) getTelemetry();
    }, [appUserId, eventType, getTelemetry]);

    const handleClose = () => {
        setEventData('');
    };
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    if (isLoading || patientDataLoading) return <Loading />;
    return (
        <>
            {appUserId && (
                <>
                    <OutlinedSection title="Select Event Type">
                        <select
                            value={eventType}
                            onChange={e => setSelectedEventType(e.target.value)}
                        >
                            <option value="" key="_z">
                                All Events
                            </option>
                            {data?.appUserTelemetrys?.eventTypes?.map(
                                t =>
                                    t && (
                                        <option value={t} key={t}>
                                            {t}
                                        </option>
                                    ),
                            )}
                        </select>
                    </OutlinedSection>
                    <div>
                        <MaterialTable
                            components={{
                                Pagination: props => (
                                    <TablePagination
                                        {...props}
                                        count={data?.appUserTelemetrys?.itemcount ?? 0}
                                        page={page}
                                        onPageChange={handleChangePage}
                                    />
                                ),
                            }}
                            title="App Events"
                            columns={[
                                {
                                    title: 'Event Date',
                                    field: 'eventDate',
                                    filtering: false,
                                },
                                {
                                    title: 'Event Type',
                                    field: 'eventType',
                                    filtering: false,
                                },
                                {
                                    title: 'Data',
                                    field: 'data',
                                    filtering: false,
                                },
                            ]}
                            actions={[
                                {
                                    icon: () => <Visibility />,
                                    tooltip: 'View',
                                    onClick: (_event, row) => {
                                        if (Array.isArray(row)) return;
                                        setEventData(row.data);
                                    },
                                },
                            ]}
                            data={data?.appUserTelemetrys?.items || []}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            options={{
                                pageSize: rowsPerPage,
                                pageSizeOptions: [25, 50, 100],
                                search: false,
                            }}
                            icons={tableIcons}
                        />
                    </div>
                </>
            )}
            {eventData && (
                <Dialog open>
                    <DialogTitleWithClose id="dialogTitle" onClose={handleClose}>
                        Event Details
                    </DialogTitleWithClose>
                    <DialogContent>
                        <JsonViewer value={JSON.parse(eventData)} />
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default PatientAppEvents;
