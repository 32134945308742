import React from 'react';
import { Link, Grid, Typography } from '@mui/material';
import useStyles from '../styles';

type AssignmentOfBenefitsProps = {
    isMobileView: boolean;
};

const blockLink = 'https://www.wildflowerhealth.com/privacy-policy/wfthpp/wfth-assignment-benefits';

const AssignmentOfBenefits: React.FC<AssignmentOfBenefitsProps> = props => {
    const { isMobileView } = props;
    const { classes } = useStyles();
    return (
        <Grid container justifyContent="center">
            <Grid item xs={10} md={12} className={classes.disclaimer}>
                <Typography
                    variant="body2"
                    style={{ textAlign: isMobileView ? 'center' : 'right' }}
                    fontWeight="bold"
                >
                    <Link underline="always" href={blockLink} target="_blank" rel="noreferrer">
                        Assignment of Benefits
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default React.memo(AssignmentOfBenefits);
