import { CheckBox } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { displayDateWithLocalTimeZoneAbbr } from '~/helpers';
import { GetAdvocateTaskCheckListChangesForPatientTranscriptPageQuery } from '~/schemaTypes';

type AdvocateTask = NonNullable<
    GetAdvocateTaskCheckListChangesForPatientTranscriptPageQuery['getAdvocateTaskCheckListChangesForPatientTranscript']
>[0];

export const AdvocateTaskCard = ({ advocateTask }: { advocateTask: AdvocateTask }) => {
    const actions = advocateTask?.patientActions;
    const history = advocateTask?.history.find(history =>
        history.what.includes('tag trigger'),
    )?.what;
    const changingTo = advocateTask?.history.find(history =>
        history.what.includes('profile value changing to'),
    )?.what;
    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <Typography variant="h6">Advocate Task</Typography>
            <p className="m-0">
                Title:{' '}
                <Link to={`/portal/advocate-tasks/${advocateTask?.id}`}>{advocateTask?.label}</Link>
            </p>
            <p className="m-0">Status: {advocateTask?.status}</p>
            <p className="m-0">History: {history}</p>
            <p className="m-0">changing to: {changingTo}</p>
            <p className="m-0">Actions Completed</p>
            {advocateTask?.checklist
                .filter(step => step.completedAt != null)
                .map(step => {
                    const labels = actions?.filter(action => action.stepId === step.id);
                    return (
                        <div key={step.id} className="pl-2 pb-2 flex flex-row items-center gap-2">
                            <CheckBox />
                            <div className="flex flex-col gap-2">
                                {labels?.length && labels.length > 0 ? (
                                    labels?.map(label => (
                                        <p
                                            key={label.type?.id}
                                            className="m-0"
                                            // eslint-disable-next-line
                                            dangerouslySetInnerHTML={{
                                                __html: label.type?.label || '',
                                            }}
                                        />
                                    ))
                                ) : (
                                    <p
                                        className="m-0"
                                        style={{ display: 'inline-block' }}
                                        // eslint-disable-next-line
                                        dangerouslySetInnerHTML={{ __html: step.label || '' }}
                                    />
                                )}
                            </div>
                            <p>
                                -{' '}
                                {step.completedAt
                                    ? displayDateWithLocalTimeZoneAbbr({
                                          isoDateStr: new Date(step.completedAt).toISOString(),
                                      })
                                    : 'Not completed'}
                            </p>
                        </div>
                    );
                })}
        </div>
    );
};
