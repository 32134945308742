import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    MessageCenterTemplatesDocument,
    useDeleteMessageCenterTemplateMutation,
    useMessageCenterTemplatesQuery,
    useMessageCenterTemplateWhereUsedLazyQuery,
} from '~/schemaTypes';
import { MessageCenterTemplatesEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';

import { MessageCenterTemplates } from './types/Schema';

const PAGESIZE = 10;

const useStyles = makeStyles()({
    root: {},
    titleDivider: {
        marginBottom: '20px',
    },
});

const MessageCenter: React.FC = () => {
    const { classes } = useStyles();
    const history = useNavigate();
    const { pagePermissions } = useUserPermissions();
    const { data: messageCenterTemplateData, loading: messageCenterTemplateDataLoading } =
        useMessageCenterTemplatesQuery();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [showUsageModal, setShowUsageModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<MessageCenterTemplates>();
    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        useMessageCenterTemplateWhereUsedLazyQuery();
    const [deleteItem, { loading: deleteItemLoading }] = useDeleteMessageCenterTemplateMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: MessageCenterTemplatesDocument,
                variables: {},
            },
        ],
    });
    const handleDelete = () => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${selectedTemplate?.title}'?`,
            callback: () => {
                deleteItem({ variables: { id: selectedTemplate?.id } });
                setShowUsageModal(false);
            },
        });
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleShowUsage = (item: MessageCenterTemplates) => {
        whereUsed({
            variables: {
                id: item?.id,
            },
        });
        setShowUsageModal(true);
    };

    return (
        <div
            className={classes.root}
            data-test={MessageCenterTemplatesEnum.CONTAINER}
            ref={tableRef}
        >
            <MaterialTable<MessageCenterTemplates>
                title="Message Center Templates"
                isLoading={
                    messageCenterTemplateDataLoading || whereUsedLoading || deleteItemLoading
                }
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{ pageSize: rowsPerPage }}
                icons={tableIcons}
                actions={[
                    {
                        onClick: () => history(`/app-config/message-center-templates/new`),
                        hidden: !pagePermissions?.MessageCenterTemplates.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add New Message Center Template',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, messageTemplate) =>
                            !Array.isArray(messageTemplate) &&
                            history(
                                `/app-config/message-center-templates/edit/${messageTemplate.id}`,
                            ),
                        hidden: !pagePermissions?.MessageCenterTemplates.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Message Center Template',
                    },
                    {
                        onClick: (_e, item) => {
                            setSelectedTemplate(item as MessageCenterTemplates);
                            handleShowUsage(item as MessageCenterTemplates);
                        },
                        icon: () => <FontAwesomeIcon icon={faEye} />,
                        tooltip: 'View References',
                    },
                ]}
                data={
                    _.cloneDeep(
                        _.sortBy(messageCenterTemplateData?.messageCenterTemplates, 'title'),
                    ) ?? []
                }
                columns={[
                    {
                        title: 'Title',
                        field: 'title',
                        defaultSort: 'asc',
                        render: ({ title }) => <Typography data-test={title}>{title}</Typography>,
                    },
                ]}
                localization={{ header: { actions: '' } }}
            />
            <Dialog scroll="paper" open={!whereUsedLoading && showUsageModal}>
                <WhereUsedModal
                    onClose={() => setShowUsageModal(false)}
                    onDelete={handleDelete}
                    title={`Usage for ${selectedTemplate?.title}`}
                    canDelete={pagePermissions?.MessageCenterTemplates.Delete || false}
                    usageData={whereUsedData?.messageCenterTemplateWhereUsed?.dependencies || []}
                    permissions={pagePermissions}
                />
            </Dialog>
        </div>
    );
};

export default MessageCenter;
