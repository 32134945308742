import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import { useProfileValueOriginListQuery } from '~/schemaTypes';
import { ProfileValueOriginSelectorEnum } from '~/selectors/profileValueOrigin.selector';
import { ProfileValueOriginModal } from './components/ProfileValueOriginModal';
import { useStyles } from './styles';
import { ProfileValueOrigin } from './types';

const ProfileValueOrigins: React.FC = () => {
    const { classes } = useStyles();
    const { data, loading } = useProfileValueOriginListQuery();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);

    const [isProfileValueOriginModalOpen, setProfileValueOriginModalOpen] =
        useState<boolean>(false);
    const [selectedProfileValueOrigin, setSelectedProfileValueOrigin] =
        useState<ProfileValueOrigin | null>(null);
    const { pagePermissions } = useUserPermissions();

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <>
            <div
                className={classes.root}
                data-test={ProfileValueOriginSelectorEnum.CONTAINER}
                ref={tableRef}
            >
                <MaterialTable<ProfileValueOrigin>
                    title="Profile Value Origin"
                    icons={tableIcons}
                    isLoading={loading}
                    data={_.cloneDeep(data?.profileValueOrigins) ?? []}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    actions={[
                        {
                            onClick: () => setProfileValueOriginModalOpen(true),
                            icon: () => <Add />,
                            tooltip: 'Add Profile Value Origin',
                            isFreeAction: true,
                            hidden: !pagePermissions?.ProfileValueOrigin.Edit,
                        },
                        {
                            onClick: (_, profileValueOrigin) => {
                                const isSingleItemSelected = !Array.isArray(profileValueOrigin);
                                if (isSingleItemSelected) {
                                    setProfileValueOriginModalOpen(true);
                                    setSelectedProfileValueOrigin(profileValueOrigin);
                                }
                            },
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit Profile Value Origin',
                            hidden: !pagePermissions?.ProfileValueOrigin.Edit,
                        },
                    ]}
                    columns={[
                        {
                            title: 'Label',
                            field: 'label.en',
                            defaultSort: 'asc',
                            render: ({ label }) => (
                                <Typography data-test={label.en}>{label.en}</Typography>
                            ),
                        },
                    ]}
                    options={{ pageSize: rowsPerPage }}
                    localization={{ header: { actions: '' } }}
                />
            </div>
            <ProfileValueOriginModal
                isOpen={isProfileValueOriginModalOpen}
                profileValueOrigin={selectedProfileValueOrigin || null}
                onClose={() => {
                    setProfileValueOriginModalOpen(false);
                    setSelectedProfileValueOrigin(null);
                }}
            />
        </>
    );
};

export default ProfileValueOrigins;
