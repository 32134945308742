import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    AdvocateTaskTemplateForAdvocateTaskTemplatePageDocument,
    useAdvocateTaskTemplateForAdvocateTaskTemplatesPageQuery,
    useAdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery,
    useCreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { errorRed } from '~/theme/WildTheme';
import { formValuesToUpdateInput } from '../../AdvocateTaskTemplates/helpers';
import { toFormValuesFromAdvocateTaskTemplates } from '../../AdvocateTaskTemplates/helpers/toFormValues';
import {
    AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery,
    Template,
} from '../../AdvocateTaskTemplates/types';

interface ICloneAdvocateTaskTemplatesFormInput {
    label: string;
}

const CloneAdvocateTaskTemplateFormInputValidation = Yup.object().shape({
    label: Yup.string().required('Required'),
});

export const CloneAdvocateTaskTemplateModal: React.FC<{
    isOpen: boolean;
    advocateTaskTemplateId: string;
    onClose: () => void;
}> = props => {
    const { isOpen, advocateTaskTemplateId, onClose } = props;
    const [selectedAdvocateTaskTemplate, setSelectedAdvocateTaskTemplate] =
        useState<Template | null>(null);
    const [taskTemplateLabels, setTaskTemplateLabels] = useState<Set<string> | null>(null);
    const [labelUsed, setLabelUsed] = useState(false);
    const {
        register,
        handleSubmit,

        formState: { errors },
    } = useForm<ICloneAdvocateTaskTemplatesFormInput>({
        resolver: yupResolver(CloneAdvocateTaskTemplateFormInputValidation as any),
    });

    const { loading: advocateTaskTemplatesLoading } =
        useAdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery({
            onCompleted: ({ advocateTaskTemplatesV2: { results } }) => {
                if (results.length) {
                    const labels = results.map(({ label }) => label.toLowerCase());
                    setTaskTemplateLabels(new Set(labels));
                }
            },
        });

    const { data: advocateTaskTemplateData, loading: advocateTaskTemplateLoading } =
        useAdvocateTaskTemplateForAdvocateTaskTemplatesPageQuery({
            variables: { advocateTaskTemplateInput: { id: advocateTaskTemplateId } },
            onCompleted: ({ advocateTaskTemplate }) => {
                if (advocateTaskTemplate) {
                    setSelectedAdvocateTaskTemplate(advocateTaskTemplate);
                }
            },
        });

    const handleClose = () => {
        onClose();
    };

    const [createAdvocateTaskTemplate, { loading: createadvocateTaskTemplateLoading }] =
        useCreateAdvocateTaskTemplateForAdvocateTaskTemplatesPageMutation({
            onCompleted: () => {
                handleClose();
            },
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        handleClose();
                    },
                    message: `There was a problem saving the Advocate Task Template: ${error.message}`,
                });
            },
            update: (cache, response) => {
                const newAdvocateTaskTemplate =
                    response.data?.createAdvocateTaskTemplateEncoded?.resourceCreated;
                if (
                    response.data?.createAdvocateTaskTemplateEncoded?.success &&
                    newAdvocateTaskTemplate
                ) {
                    taskTemplateLabels?.add(newAdvocateTaskTemplate.label);
                    setTaskTemplateLabels(taskTemplateLabels);
                    const currentAdvocateTaskTemplates =
                        cache.readQuery<AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery>({
                            query: AdvocateTaskTemplateForAdvocateTaskTemplatePageDocument,
                        });
                    if (currentAdvocateTaskTemplates?.advocateTaskTemplatesV2?.results) {
                        cache.writeQuery<AdvocateTaskTemplatesForAdvocateTaskTemplatesPageQuery>({
                            query: AdvocateTaskTemplateForAdvocateTaskTemplatePageDocument,
                            data: {
                                advocateTaskTemplatesV2: {
                                    __typename:
                                        currentAdvocateTaskTemplates.advocateTaskTemplatesV2
                                            .__typename,
                                    results: [
                                        ...currentAdvocateTaskTemplates.advocateTaskTemplatesV2
                                            .results,
                                        newAdvocateTaskTemplate,
                                    ],
                                },
                            },
                        });
                    }
                }
            },
        });

    const onSubmit = ({ label }: ICloneAdvocateTaskTemplatesFormInput) => {
        setLabelUsed(false);

        if (taskTemplateLabels?.has(label.toLowerCase())) {
            setLabelUsed(true);
            return;
        }

        if (selectedAdvocateTaskTemplate === null) {
            return;
        }

        const { checklist: checklistFields } = selectedAdvocateTaskTemplate;
        const advocateTaskTemplateFormValues = toFormValuesFromAdvocateTaskTemplates(
            selectedAdvocateTaskTemplate,
        );
        const resultingAdvocateTaskTemplates = formValuesToUpdateInput(
            advocateTaskTemplateFormValues,
            checklistFields.map((checklistItem, index) => ({
                ...checklistItem,
                ...advocateTaskTemplateFormValues.checklist[index],
            })),
        );

        createAdvocateTaskTemplate({
            variables: {
                input: {
                    ...resultingAdvocateTaskTemplates,
                    label,
                },
            },
        });
    };

    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose id="modalTitle" onClose={handleClose}>
                Clone {advocateTaskTemplateData?.advocateTaskTemplate?.label}
            </DialogTitleWithClose>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    {advocateTaskTemplatesLoading ||
                    advocateTaskTemplateLoading ||
                    createadvocateTaskTemplateLoading ? (
                        <Loading height={140} />
                    ) : (
                        <OutlinedSection title="Advocate Task Template Name">
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                defaultValue={advocateTaskTemplateData?.advocateTaskTemplate?.label}
                                {...register('label')}
                                error={!!errors.label}
                                helperText={errors.label?.message}
                            />
                            {labelUsed && (
                                <div style={{ color: errorRed }}>
                                    Name already used. Please change.
                                </div>
                            )}
                        </OutlinedSection>
                    )}
                    {/* show input?select for name */}
                    {/* input should be unique */}
                </DialogContent>
                <DialogActions>
                    {/* show buttons cancel and clone */}
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={() => handleSubmit(onSubmit)}
                    >
                        Clone
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
