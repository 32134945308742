import React from 'react';
import { Grid, InputLabel, MenuItem, Select } from '@mui/material';

type UnitNameSelectProps = {
    setUnitName: (unitName: string) => void;
    unitName: string;
    options: {
        title: string;
        value: string;
    }[];
};

const UnitNameSelect: React.FC<UnitNameSelectProps> = props => {
    const { setUnitName, unitName, options } = props;

    return (
        <Grid item container columnSpacing={1} alignItems="center" justifyContent="flex-end">
            <Grid item>
                <InputLabel id="unitName">Filter By Unit</InputLabel>
            </Grid>
            <Grid item xs={4}>
                <Select
                    labelId="unitName"
                    onChange={e => setUnitName(e.target.value as string)}
                    value={unitName}
                    displayEmpty
                    fullWidth
                >
                    {options.map(un => (
                        <MenuItem key={un.title} value={un.value}>
                            {un.title}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        </Grid>
    );
};

export default UnitNameSelect;
