import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    root: {
        position: 'relative',
        height: '100%',
    },
    searchChips: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
    },
    link: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        display: 'inline',
        margin: 0,
        padding: 0,
        color: '#116b8e',
        '&:hover': {
            cursor: 'pointer',
            filter: 'brightness(50%)',
        },
    },
});
