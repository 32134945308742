import { styled } from '@mui/material/styles';
import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import moment, { Moment, MomentInput } from 'moment';
import React, { useCallback, ComponentType } from 'react';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: prop =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
})) as ComponentType<CustomPickerDayProps>;

interface CustomPickerDayProps extends PickersDayProps<Moment> {
    dayIsBetween: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
}

const WeeklyDatePicker: React.FC<DatePickerProps<Moment>> = ({
    value,
    format,
    onChange,
    ...restProps
}) => {
    const handleWeekChange = useCallback(
        (date: Moment | null, context: any) => {
            if (date && date.isValid() && onChange) {
                const startWeek = date.clone().startOf('week');
                onChange(startWeek, context);
            } else if (onChange) {
                onChange(date, context);
            }
        },
        [onChange],
    );
    const renderInput = useCallback(
        (props: TextFieldProps) => {
            const { value: inputValue } = props as TextFieldProps & { value: MomentInput | null };
            let displayValue = `${format?.toLowerCase()}-${format?.toLowerCase()}`;
            if (inputValue && moment(inputValue).isValid()) {
                const startWeek = moment(inputValue, format).format(format);
                const endWeek = moment(inputValue, format).add(6, 'd').format(format);
                displayValue = `${startWeek}-${endWeek}`;
            }
            return <TextField {...props} value={displayValue} />;
        },
        [format],
    );
    const renderDay = useCallback(
        (props: PickersDayProps<Moment> & { selectedDay?: Moment | null }) => {
            const { day: date, selectedDay: selectedDate, ...other } = props;
            if (!selectedDate) {
                return <PickersDay day={date} {...other} />;
            }
            const start = selectedDate.clone().startOf('week').toDate();
            const end = selectedDate.clone().endOf('week').toDate();

            const dayIsBetween = date.isBetween(start, end, null, '[]');
            const isFirstDay = date.isSame(start, 'day');
            const isLastDay = date.isSame(end, 'day');

            return (
                <CustomPickersDay
                    {...other}
                    day={date}
                    sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
                    dayIsBetween={dayIsBetween}
                    isFirstDay={isFirstDay}
                    isLastDay={isLastDay}
                />
            );
        },
        [],
    );

    return (
        <DatePicker
            {...restProps}
            format={format}
            value={value}
            onChange={handleWeekChange}
            slots={{
                day: renderDay,
                textField: renderInput,
            }}
            showDaysOutsideCurrentMonth
            slotProps={{
                day: {
                    selectedDay: value,
                } as any,
                textField: {
                    size: 'small',
                },
            }}
        />
    );
};

export default WeeklyDatePicker;
