import { InMemoryCache, makeVar } from '@apollo/client';
import { FileInfo } from '@uploadcare/react-widget';
import { AUTH_TOKEN_KEY } from '~/models/auth';
import { AlertSeverity } from '~/schemaTypes';
import { OpaqueWebToken } from '~/utils/jwt.model';
import { log } from '~/utils/log.svc';
import { CognitoAccessToken, CognitoIdToken } from './auth/cognito.model';

export enum AuthTokens {
    AccessToken = 'AccessToken',
    IdToken = 'IdToken',
    WfhAuthToken = 'WfhAuthToken',
    RefreshToken = 'RefreshToken',
}

log.info(
    {
        [AuthTokens.AccessToken]: localStorage.getItem(AuthTokens.AccessToken) ?? null,
        [AuthTokens.IdToken]: localStorage.getItem(AuthTokens.IdToken) ?? null,
        [AuthTokens.RefreshToken]: localStorage.getItem(AuthTokens.RefreshToken) ?? null,
    },
    'localStorage',
);

const lsAccessToken = localStorage.getItem(AuthTokens.AccessToken);
export const AccessToken = makeVar(
    lsAccessToken ? new CognitoAccessToken(lsAccessToken) : undefined,
);

const lsIdToken = localStorage.getItem(AuthTokens.IdToken);
export const IdToken = makeVar(lsIdToken ? new CognitoIdToken(lsIdToken) : undefined);

const lsRefreshToken = localStorage.getItem(AuthTokens.RefreshToken);
export const RefreshToken = makeVar(
    lsRefreshToken ? new OpaqueWebToken(lsRefreshToken) : undefined,
);

export const AuthUsername = makeVar<string>(IdToken()?.payload.email ?? '');

export const ClearAuthTokens = () => {
    log.info('ClearAuthTokens');
    AccessToken(undefined);
    localStorage.removeItem(AuthTokens.AccessToken);
    IdToken(undefined);
    localStorage.removeItem(AuthTokens.IdToken);
    RefreshToken(undefined);
    localStorage.removeItem(AuthTokens.RefreshToken);
    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(AuthTokens.WfhAuthToken);
};

export const PersistAuthTokens = ({
    accessToken: accessTokenRaw,
    idToken: idTokenRaw,
    refreshToken: refreshTokenRaw,
}: {
    accessToken: string;
    idToken: string;
    refreshToken?: string;
}): void => {
    const accessToken = new CognitoAccessToken(accessTokenRaw);
    const idToken = new CognitoIdToken(idTokenRaw);
    const refreshToken = refreshTokenRaw ? new OpaqueWebToken(refreshTokenRaw) : undefined;
    log.info({ accessToken, idToken, refreshToken }, 'PersistUserCredentials');
    localStorage.setItem(AuthTokens.AccessToken, accessToken.token);
    AccessToken(accessToken);
    localStorage.setItem(AuthTokens.IdToken, idToken.token);
    IdToken(idToken);
    if (refreshToken) {
        localStorage.setItem(AuthTokens.RefreshToken, refreshToken.token);
        RefreshToken(refreshToken);
    }
    AuthUsername(idToken.payload.email);
};

export const GlobalLoading = makeVar<{
    open: boolean;
    message: string;
}>({
    open: false,
    message: '',
});

export const GlobalImageUpload = makeVar<{
    open: boolean;
    isImage: boolean;
    callback: (fileInfo: FileInfo) => void;
}>({
    open: false,
    isImage: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback: () => {},
});

export const GlobalAlertSeverity = makeVar<AlertSeverity>(AlertSeverity.Warning);
export const GlobalAlertMessage = makeVar<string>('');
export const GlobalAlertOpen = makeVar<boolean>(false);
export const SuppressNextGlobalAlert = makeVar<boolean>(false); // Suppresses only "success: true"
export const BraveNotificationSent = makeVar<boolean>(
    !!localStorage.getItem('BraveNotificationSent'),
);
export const PortalVersionMismatchLastCheck = makeVar<Date | null>(null);
export const GlobalConfirm = makeVar<{
    open: boolean;
    message: string;
    callback: () => void;
    description?: string;
}>({
    open: false,
    message: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback: () => {},
    description: '',
});
export const GlobalImagePreview = makeVar<{ open: boolean; imageSrc: string }>({
    open: false,
    imageSrc: '',
});

export const GlobalBanner = makeVar<{
    open: boolean;
    message: string;
    actionText: string;
    severity: AlertSeverity;
    callback: () => void;
}>({
    open: false,
    message: '',
    actionText: '',
    severity: AlertSeverity.Info,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback: () => {},
});

export const TriggerGlobalAlert = ({
    severity,
    message,
}: {
    severity: AlertSeverity;
    message: string;
}): void => {
    if (SuppressNextGlobalAlert()) {
        SuppressNextGlobalAlert(false);
        return;
    }

    if (!GlobalAlertOpen()) {
        GlobalAlertSeverity(severity);
        GlobalAlertMessage(message);
        GlobalAlertOpen(true);
    }
};

export const TriggerGlobalConfirm = ({
    open = true,
    callback,
    message,
    description = '',
}: {
    open?: boolean;
    callback: () => void;
    message: string;
    description?: string;
}): void => {
    GlobalConfirm({
        open,
        message,
        callback,
        description,
    });
};

export const TriggerGlobalImageUploader = ({
    callback,
    isImage = false,
}: {
    callback: (fileInfo: FileInfo) => void;
    isImage?: boolean;
}): void => {
    GlobalImageUpload({
        open: true,
        isImage,
        callback: (fileInfo: FileInfo) => {
            callback(fileInfo);
            GlobalImageUpload({
                open: false,
                isImage: false,
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                callback: () => {},
            });
        },
    });
};

export const TriggerGlobalImagePreview = ({ imageSrc }: { imageSrc: string }): void => {
    GlobalImagePreview({
        open: true,
        imageSrc,
    });
};

export const TriggerGlobalBanner = ({
    open = true,
    message,
    actionText,
    severity,
    callback,
}: {
    open?: boolean;
    message: string;
    actionText: string;
    severity: AlertSeverity;
    callback: () => void;
}): void => {
    GlobalBanner({
        open,
        message,
        actionText,
        severity,
        callback,
    });
};

export const ApolloCache = new InMemoryCache({
    typePolicies: {
        CarePlanTodoGroup: {
            keyFields: false,
        },
        CarePlanTodoTemplate: {
            keyFields: false,
        },
        Query: {
            fields: {
                GlobalAlert: {
                    read() {
                        return {
                            message: GlobalAlertMessage(),
                            open: GlobalAlertOpen(),
                            severity: GlobalAlertSeverity(),
                        };
                    },
                },
                GlobalLoading: {
                    read() {
                        return {
                            message: GlobalLoading().message,
                            open: GlobalLoading().open,
                        };
                    },
                },
                GlobalConfirm: {
                    read() {
                        return {
                            open: GlobalConfirm().open,
                            message: GlobalConfirm().message,
                            description: GlobalConfirm().description,
                        };
                    },
                },
                GlobalImagePreview: {
                    read() {
                        return {
                            open: GlobalImagePreview().open,
                            imageSrc: GlobalImagePreview().imageSrc,
                        };
                    },
                },
                GlobalImageUpload: {
                    read() {
                        return {
                            open: GlobalImageUpload().open,
                            isImage: GlobalImageUpload().isImage,
                        };
                    },
                },
                GlobalBanner: {
                    read() {
                        return {
                            open: GlobalBanner().open,
                            message: GlobalBanner().message,
                            actionText: GlobalBanner().actionText,
                            severity: GlobalBanner().severity,
                        };
                    },
                },
            },
        },
    },
});
