import { Autocomplete, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import DateInput from '~/components/DateInput/DateInput';
import StateAutocomplete from '~/components/StateAutocomplete/StateAutocomplete';
import Agreement from '../components/Agreement';
import AssignmentOfBenefits from '../components/AssignmentOfBenefits';
import ButtonContainer from '../components/ButtonContainer';
import Disclaimer from '../components/Disclaimer';
import FooterDivider from '../components/FooterDivider';
import SupportBlock from '../components/SupportBlock';
import ToggleButtonSwitch from '../components/ToggleButtonSwitch';
import { useVCFContext } from '../hooks/useVCF';
import useStyles from '../styles';
import { EMPTY_GENDER_VALUE, inputBabyGenders } from '../types';

type PatientInfoContactProps = {
    backHandler: () => void;
    stepHandler: () => void;
    stepLoading: boolean;
    selfCheck: boolean;
};

const PatientInfoContact: React.FC<PatientInfoContactProps> = props => {
    const { backHandler, stepHandler, stepLoading, selfCheck } = props;
    const { classes } = useStyles();
    const { isMobileView, isAffiliateView } = useVCFContext();
    const {
        register,
        formState: { errors },
        setValue,
        getValues,
        control,
        watch,
    } = useFormContext();
    const sameAddress = watch('insuredAddressSameAsPatient');
    const [babyArrived, setBabyArrived] = useState(getValues('babyArrived'));
    const showBabyInfoSection = babyArrived && !isAffiliateView && !selfCheck;
    const showBabyArrivedSection = !isAffiliateView && !selfCheck;
    const showDueDateSection = babyArrived === false && !isAffiliateView && !selfCheck;
    return (
        <>
            <Grid item xs={12} className={classes.subHeader}>
                <Typography paragraph variant="h6">
                    Additional Patient Information
                </Typography>
            </Grid>
            <Grid container justifyContent="space-between" rowSpacing={{ md: 2, xs: 1 }}>
                <Grid item md={12} xs={12}>
                    <TextField
                        label="Address Line 1"
                        {...register('addressLine1')}
                        fullWidth
                        error={!!errors.addressLine1}
                        helperText={errors.addressLine1?.message as string}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <TextField
                        label="Address Line 2"
                        fullWidth
                        {...register('addressLine2')}
                        error={!!errors.addressLine2}
                        helperText={errors.addressLine2?.message as string}
                    />
                </Grid>
                <Grid item md={5} xs={12}>
                    <TextField
                        label="City"
                        fullWidth
                        {...register('city')}
                        error={!!errors.city}
                        helperText={errors.city?.message as string}
                    />
                </Grid>
                <Grid item md={5} xs={12}>
                    <StateAutocomplete
                        errorMessage={errors.state?.message as string}
                        name="state"
                        label="State"
                        register={register('state')}
                        onChange={(value: string) => {
                            setValue('state', value, { shouldValidate: true });
                        }}
                    />
                </Grid>
                <Grid item md={5} xs={12}>
                    <TextField
                        label="Zip Code"
                        fullWidth
                        {...register('zipCode')}
                        error={!!errors.zipCode}
                        helperText={errors.zipCode?.message as string}
                    />
                </Grid>
                <Grid item xs={5} />
            </Grid>
            <Grid container justifyContent="space-between" rowSpacing={{ md: 2, xs: 0 }}>
                <Grid item md={11} xs={12}>
                    <ToggleButtonSwitch
                        onChange={(_, value) => {
                            if (value !== null) {
                                setValue('insuredAddressSameAsPatient', value);
                            }
                        }}
                        label="Is Insured Address Same as Patient Address?"
                        value={sameAddress}
                    />
                </Grid>
                <Grid item md={12} xs={12} className={sameAddress ? classes.hide : classes.show}>
                    <TextField
                        label="Insured Address Line 1"
                        fullWidth
                        {...register('insuredAddressLine1')}
                        error={!!errors.insuredAddressLine1}
                        helperText={errors.insuredAddressLine1?.message as string}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(sameAddress && { InputLabelProps: { shrink: true } })}
                    />
                </Grid>
                <Grid item md={12} xs={12} className={sameAddress ? classes.hide : classes.show}>
                    <TextField
                        label="Insured Address Line 2"
                        fullWidth
                        {...register('insuredAddressLine2')}
                        error={!!errors.insuredAddressLine2}
                        helperText={errors.insuredAddressLine2?.message as string}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(sameAddress && { InputLabelProps: { shrink: true } })}
                    />
                </Grid>
                <Grid item md={5} xs={12} className={sameAddress ? classes.hide : classes.show}>
                    <TextField
                        label="Insured City"
                        fullWidth
                        {...register('insuredCity')}
                        error={!!errors.insuredCity}
                        helperText={errors.insuredCity?.message as string}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(sameAddress && { InputLabelProps: { shrink: true } })}
                    />
                </Grid>
                <Grid item md={5} xs={12} className={sameAddress ? classes.hide : classes.show}>
                    <StateAutocomplete
                        name="insuredState"
                        label="Insured State"
                        register={register('insuredState')}
                        onChange={value => {
                            setValue('insuredState', value, { shouldValidate: true });
                        }}
                        errorMessage={errors.insuredState?.message as string}
                        value={
                            // eslint-disable-next-line no-nested-ternary
                            getValues
                                ? getValues('insuredAddressSameAsPatient')
                                    ? getValues('state')
                                    : getValues('insuredState') ?? ''
                                : ''
                        }
                    />
                </Grid>
                <Grid
                    item
                    md={5}
                    xs={12}
                    alignSelf="flex-end"
                    className={sameAddress ? classes.hide : classes.show}
                >
                    <TextField
                        label="Insured Zip Code"
                        fullWidth
                        {...register('insuredZipCode')}
                        error={!!errors.insuredZipCode}
                        helperText={errors.insuredZipCode?.message as string}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(sameAddress && { InputLabelProps: { shrink: true } })}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.subHeader}>
                <Typography paragraph variant="h6">
                    Baby&apos;s Information
                </Typography>
            </Grid>
            <Grid
                container
                justifyContent="space-between"
                rowSpacing={{ md: 2, xs: 0 }}
                paddingBottom={{ xs: 2, md: 1 }}
            >
                <Grid item md={11} xs={12}>
                    <Controller
                        render={({ field: { onChange, value } }) => (
                            <ToggleButtonSwitch
                                onChange={(_, value) => {
                                    if (value !== null) {
                                        onChange(value);
                                    }
                                }}
                                label="Are you having twins/multiples?"
                                value={value}
                            />
                        )}
                        name="babyMultiples"
                        control={control}
                    />
                    <FormHelperText error={!!errors.babyMultiples}>
                        {errors.babyMultiples?.message as string}
                    </FormHelperText>
                </Grid>
                {showBabyArrivedSection && (
                    <Grid item md={12} xs={12}>
                        <Controller
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <ToggleButtonSwitch
                                    onChange={(_, value) => {
                                        if (value !== null) {
                                            onChange(value);
                                            setBabyArrived(value);
                                        }
                                    }}
                                    label="Has the baby arrived?"
                                    value={value}
                                />
                            )}
                            name="babyArrived"
                        />
                        <FormHelperText error={!!errors.babyArrived}>
                            {errors.babyArrived?.message as string}
                        </FormHelperText>
                    </Grid>
                )}
                {showBabyInfoSection && (
                    <>
                        <Grid item md={5} xs={12}>
                            <TextField
                                label={`Baby's First Name`}
                                fullWidth
                                {...register('babyFirstName')}
                                error={!!errors.babyFirstName}
                                helperText={errors.babyFirstName?.message as string}
                            />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <TextField
                                label={`Baby's Last Name`}
                                fullWidth
                                {...register('babyLastName')}
                                error={!!errors.babyLastName}
                                helperText={errors.babyLastName?.message as string}
                            />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <Controller
                                control={control}
                                name="babyBirthDate"
                                render={({ field }) => (
                                    <DateInput
                                        label={`Baby's Date of Birth`}
                                        field={field}
                                        error={errors.babyBirthDate as FieldError}
                                        inputProps={{
                                            fullWidth: true,
                                            InputLabelProps: {
                                                shrink: true,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <Autocomplete
                                id="babySex"
                                disablePortal
                                options={inputBabyGenders}
                                value={
                                    getValues
                                        ? inputBabyGenders.find(v => v.id === getValues('babySex'))
                                        : EMPTY_GENDER_VALUE
                                }
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        name="babySex"
                                        error={!!errors.babySex}
                                        helperText={errors.babySex?.message as string}
                                        label="Assigned Sex At Birth"
                                    />
                                )}
                                {...register('babySex')}
                                onChange={(event, value) => {
                                    setValue('babySex', value?.id || '', { shouldValidate: true });
                                }}
                                onBlur={e => {
                                    e.preventDefault();
                                }}
                            />
                        </Grid>
                    </>
                )}
                {showDueDateSection && (
                    <Grid item md={5} xs={12}>
                        <Controller
                            control={control}
                            name="babyDueDate"
                            render={({ field }) => (
                                <DateInput
                                    label="Expected Due Date"
                                    field={field}
                                    error={errors.babyDueDate as FieldError}
                                    inputProps={{
                                        fullWidth: true,
                                        InputLabelProps: {
                                            shrink: true,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                )}
                {!selfCheck && (
                    <Grid item md={12} xs={12}>
                        <Controller
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <ToggleButtonSwitch
                                    onChange={(_, value) => {
                                        if (value !== null) {
                                            onChange(value);
                                        }
                                    }}
                                    label="Have you already chosen a breast pump through your insurance?"
                                    value={value}
                                />
                            )}
                            name="breastPump"
                        />
                        <FormHelperText error={!!errors.breastPump}>
                            {errors.breastPump?.message as string}
                        </FormHelperText>
                    </Grid>
                )}
            </Grid>
            <FooterDivider isMobileView={isMobileView} bottomPadding={0} />
            {!isAffiliateView && <Agreement isMobileView={isMobileView} />}
            {!isAffiliateView && <Disclaimer isMobileView={isMobileView} />}
            {!isAffiliateView && <AssignmentOfBenefits isMobileView={isMobileView} />}
            <SupportBlock isMobileView={isMobileView} />
            <ButtonContainer
                nextButton={{
                    handler: stepHandler,
                    label: 'Continue',
                    disabled: stepLoading,
                    loading: stepLoading,
                }}
                prevButton={{
                    handler: backHandler,
                    label: 'Back',
                }}
            />
        </>
    );
};

export default PatientInfoContact;
