// This component has a UI Test
import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add } from '@mui/icons-material';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    useDeleteTimelineMutation,
    useTimelineWhereUsedLazyQuery,
    useGetTimelinesQuery,
    GetTimelinesDocument,
    GetTimelinesQuery,
} from '~/schemaTypes';
import { TimelineEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';

type WhatsNewTimeline = NonNullable<GetTimelinesQuery['whatsNewTimelinesV2']>['results'][0];

const useStyles = makeStyles()({
    root: {},
});

const Timelines: React.FC = () => {
    const { classes } = useStyles();
    const history = useNavigate();
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [showUsageModal, setShowUsageModal] = useState(false);
    const [timeline, setTimeline] = useState<WhatsNewTimeline>();

    const { data: timelineData, loading } = useGetTimelinesQuery();
    const [deleteTimeline, { loading: isDeletingTimeline }] = useDeleteTimelineMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: GetTimelinesDocument,
                variables: {},
            },
        ],
    });
    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        useTimelineWhereUsedLazyQuery();

    const handleDelete = () => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete this Timeline?`,
            callback: () => {
                deleteTimeline({
                    variables: {
                        id: timeline?.id,
                    },
                });
            },
        });
        setShowUsageModal(false);
    };
    const handleShowUsage = (item: WhatsNewTimeline) => {
        whereUsed({ variables: { id: item.id } });
        setShowUsageModal(true);
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div className={classes.root} data-test={TimelineEnum.CONTAINER} ref={tableRef}>
            <MaterialTable<WhatsNewTimeline>
                title="Timelines"
                icons={tableIcons}
                isLoading={loading || isDeletingTimeline || whereUsedLoading}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                data={timelineData?.whatsNewTimelinesV2.results ?? []}
                actions={[
                    {
                        onClick: () => history('/app-config/timelines/new'),
                        hidden: !pagePermissions?.WhatsNewTimelines.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Timeline',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => history(`/app-config/timelines/${id}`),
                        hidden: !pagePermissions?.WhatsNewTimelines.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Timeline',
                    },
                    {
                        onClick: (_e, item) => {
                            setTimeline(item as WhatsNewTimeline);
                            handleShowUsage(item as WhatsNewTimeline);
                        },
                        icon: () => <FontAwesomeIcon icon={faEye} />,
                        tooltip: 'View References',
                    },
                ]}
                columns={[
                    { title: 'Name', field: 'label.en' },
                    { title: 'Type', field: 'type' },
                ]}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100] }}
                localization={{ header: { actions: '' } }}
            />
            {!whereUsedLoading && (
                <Dialog scroll="paper" open={!whereUsedLoading && showUsageModal}>
                    <WhereUsedModal
                        onClose={() => setShowUsageModal(false)}
                        onDelete={handleDelete}
                        title={`Usage for ${timeline?.label.en}`}
                        canDelete={pagePermissions?.WhatsNewTimelines.Delete || false}
                        usageData={whereUsedData?.timelineWhereUsed?.dependencies || []}
                        permissions={pagePermissions}
                    />
                </Dialog>
            )}
        </div>
    );
};

export default Timelines;
