import * as Yup from 'yup';

export const CREATE_NEW_TYPE = 'Create New Action Type';

export const CHECKLIST_ITEM_SCHEMA = (isCompleteMode: boolean) =>
    Yup.object().shape({
        name: Yup.string().when([], (_, schema) => {
            if (!isCompleteMode) {
                return schema.required('Name is a required field');
            }
            return schema;
        }),
        actionTypes: Yup.array().of(
            Yup.object().shape({
                typeId: Yup.string().required('Action type is required'),
                newActionTypeLabel: Yup.string().when('typeId', ([typeId], schema) => {
                    if (typeId === CREATE_NEW_TYPE) {
                        return schema.required('Action type is required');
                    }
                    return schema;
                }),
            }),
        ),
    });
