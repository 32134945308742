import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import React from 'react';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { FetchProfileDefsForVbcPatientModalQuery, UserProfileValueType } from '~/schemaTypes';
import useCareTeamMembers, { CareTeamMember } from '../../hooks/useCareTeamMembers';

type AdditionalPatientFields =
    FetchProfileDefsForVbcPatientModalQuery['additionalPatientFieldsProfileDefs'];
type ProfileDefinitionsData = AdditionalPatientFields['profileDefs'];

export interface VBCInviteInputsProps {
    additionalPatientFields: AdditionalPatientFields;
    profileVariablesLoading: boolean;
    onChangeHandler: (value: { [key: string]: any }) => void;
    profileVariableData: { [key: string]: any };
    bundleId: string | null;
}
export const VBCInviteInputs: React.FC<VBCInviteInputsProps> = ({
    additionalPatientFields,
    onChangeHandler,
    profileVariableData,
    profileVariablesLoading,
    bundleId,
}) => {
    const { careTeamsMembersByTypes, isCareTeamProfileDefinition } = useCareTeamMembers({
        profileDefinitions: additionalPatientFields.profileDefs,
        bundleId,
        careTeams: additionalPatientFields.careTeamMembers,
    });

    const renderProfileVariableFields = (profileDefinitionsData: ProfileDefinitionsData) => {
        if (!profileDefinitionsData) return null;
        return (
            <Grid container alignItems="center">
                {profileDefinitionsData.map(profileDef => {
                    const isCareTeamProfileDef = isCareTeamProfileDefinition(profileDef);

                    if (isCareTeamProfileDef) {
                        const members =
                            careTeamsMembersByTypes.find(
                                item => item.careTeamType?.profileDefId === profileDef.id,
                            )?.careTeamMembers ?? [];
                        return (
                            <Grid key={profileDef?.id} item xs={12}>
                                <Autocomplete<CareTeamMember, false>
                                    size="medium"
                                    onChange={(_, val) => {
                                        onChangeHandler({
                                            [profileDef.name]: val?.id,
                                        });
                                    }}
                                    getOptionLabel={selected => selected?.label?.en ?? ''}
                                    isOptionEqualToValue={(option, val) => option?.id === val?.id}
                                    options={members}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                            placeholder={`Select ${profileDef.name}`}
                                            label={profileDef.name}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </Grid>
                        );
                    }
                    switch (profileDef.valueType) {
                        case UserProfileValueType.Str:
                            return (
                                <Grid key={profileDef.name} item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        fullWidth
                                        label={profileDef.label?.en}
                                        value={profileVariableData[profileDef.name] || ''}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={e =>
                                            onChangeHandler({ [profileDef.name]: e.target.value })
                                        }
                                    />
                                </Grid>
                            );
                        case UserProfileValueType.Date:
                            return (
                                <Grid key={profileDef.name} item xs={12} md={6}>
                                    <DesktopDatePicker
                                        label={profileDef.label?.en}
                                        value={profileVariableData[profileDef.name]}
                                        onChange={(date: moment.MomentInput) => {
                                            if (date) {
                                                onChangeHandler({
                                                    [profileDef.name]: moment(date).format(
                                                        moment.HTML5_FMT.DATE,
                                                    ),
                                                });
                                            }
                                        }}
                                        disableFuture
                                        openTo="year"
                                        format="MM/dd/yyyy"
                                    />
                                </Grid>
                            );
                        case UserProfileValueType.Bool:
                            return (
                                <Grid key={profileDef.name} item xs={12}>
                                    <FormControl variant="outlined">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={profileVariableData[profileDef.name]}
                                                    onChange={e =>
                                                        onChangeHandler({
                                                            [profileDef.name]: e.target.checked,
                                                        })
                                                    }
                                                />
                                            }
                                            label={profileDef.label?.en}
                                        />
                                    </FormControl>
                                </Grid>
                            );
                        case UserProfileValueType.Num:
                            return (
                                <Grid key={profileDef.name} item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        fullWidth
                                        label={profileDef.label?.en}
                                        value={profileVariableData[profileDef.name]}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            onChangeHandler({
                                                [profileDef.name]: Number(e.target.value),
                                            })
                                        }
                                        InputProps={{
                                            inputProps: { min: 0, step: 1 },
                                        }}
                                        defaultValue={0}
                                    />
                                </Grid>
                            );
                        case UserProfileValueType.Choice:
                            return (
                                <Grid key={profileDef.name} item xs={12}>
                                    <OutlinedSection
                                        key={profileDef.name}
                                        outlineColor="secondary"
                                        title={profileDef?.label?.en ?? ''}
                                    >
                                        <RadioGroup
                                            value={profileVariableData[profileDef.name]}
                                            onChange={e => {
                                                onChangeHandler({
                                                    [profileDef.name]: e.target.value,
                                                });
                                            }}
                                        >
                                            <Grid container spacing={2} direction="column">
                                                {profileDef?.choices?.map(choice => {
                                                    return (
                                                        <Grid item key={choice?.id}>
                                                            <FormControlLabel
                                                                value={choice?.id}
                                                                control={<Radio />}
                                                                label={choice?.label?.en}
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </RadioGroup>
                                    </OutlinedSection>
                                </Grid>
                            );
                        case UserProfileValueType.Choices:
                            return (
                                <Grid key={profileDef.name} item xs={12}>
                                    <OutlinedSection
                                        outlineColor="secondary"
                                        title={profileDef?.label?.en ?? ''}
                                    >
                                        <Grid container direction="column">
                                            {profileDef.choices?.map(choice => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={profileVariableData[
                                                                profileDef.name
                                                            ]?.includes(choice?.id)}
                                                            value={choice?.id}
                                                            onChange={({
                                                                target: { checked, value },
                                                            }) => {
                                                                const currentChoiceIds =
                                                                    profileVariableData[
                                                                        profileDef.name
                                                                    ] ?? [];
                                                                const currentChoiceIdsSet = new Set(
                                                                    [...currentChoiceIds],
                                                                );

                                                                currentChoiceIdsSet[
                                                                    checked ? 'add' : 'delete'
                                                                ](value);

                                                                onChangeHandler({
                                                                    [profileDef.name]:
                                                                        Array.from(
                                                                            currentChoiceIdsSet,
                                                                        ),
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label={choice?.label?.en}
                                                />
                                            ))}
                                        </Grid>
                                    </OutlinedSection>
                                </Grid>
                            );
                        default:
                            return null;
                    }
                })}
            </Grid>
        );
    };
    return (
        <div>
            {profileVariablesLoading ? (
                <Loading />
            ) : (
                renderProfileVariableFields(additionalPatientFields.profileDefs)
            )}
        </div>
    );
};

export default VBCInviteInputs;
