import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useState } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import { useOrderForOrderModalQuery } from '~/schemaTypes';

const useStyles = makeStyles()(() => ({
    root: {
        marginBottom: '15px',
        '& .MuiPaper-root': {
            padding: '10px',
        },
        '& .MuiListItem-root:nth-child(odd)': {
            backgroundColor: '#f2f2f2',
        },
    },
}));

type OrderModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    orderId?: string | undefined;
};

type NotesModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    notes: (string | null)[] | null | undefined;
};

const NotesModal: React.FC<NotesModalProps> = ({ setOpen, notes }) => {
    const { classes } = useStyles();

    return (
        <div>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setOpen(false)}>
                Notes
            </DialogTitleWithClose>
            <DialogContent>
                <Grid container spacing={2} className={classes.root}>
                    <Grid item xs={12}>
                        <Paper variant="outlined" elevation={2}>
                            <List>
                                {notes?.map(note => (
                                    <ListItem>
                                        <ListItemText primary={note} />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </DialogContent>
        </div>
    );
};

const OrderModal: React.FC<OrderModalProps> = ({ setOpen, orderId }) => {
    const { classes } = useStyles();
    const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
    const [notesToShow, setNotesToShow] = useState<(string | null)[] | null | undefined>([]);

    const { data: orderData, loading: orderLoading } = useOrderForOrderModalQuery({
        variables: {
            input: {
                id: orderId,
            },
        },
        fetchPolicy: 'network-only',
    });

    if (orderLoading) return <Loading />;

    return (
        <div>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setOpen(false)}>
                Order Info
            </DialogTitleWithClose>

            <DialogContent>
                <Grid container spacing={2} className={classes.root}>
                    <Grid item xs={12}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">Order Id:</Typography>
                            <Typography>{orderId}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">Patient Name:</Typography>
                            <Typography>{orderData?.order?.patientFullName}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">Status:</Typography>
                            <Typography>{orderData?.order?.status}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">is Device Order:</Typography>
                            <Typography>
                                {orderData?.order?.isDeviceOrder ? 'true' : 'false'}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">Date Time:</Typography>
                            <Typography>
                                {orderData?.order?.transactionDateTime
                                    ? moment
                                          .utc(orderData?.order?.transactionDateTime)
                                          .local()
                                          .format('YYYY-MM-DD HH:mm:ss')
                                    : ''}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">Procedure:</Typography>
                            <Typography>{orderData?.order?.procedure}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">Procedure Description:</Typography>
                            <Typography>{orderData?.order?.procedureDescription}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">Notes:</Typography>
                            <List>
                                {orderData?.order?.notes?.map(note => (
                                    <ListItem>
                                        <ListItemText primary={note} />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">Diagnoses:</Typography>
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Code</TableCell>
                                            <TableCell align="right">Code Set</TableCell>
                                            <TableCell align="right">Name</TableCell>
                                            <TableCell align="right">Type</TableCell>
                                            <TableCell align="right">
                                                Documented Date Time
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orderData?.order?.diagnoses?.map(row => (
                                            <TableRow key={row.code}>
                                                <TableCell component="th" scope="row">
                                                    {row.code}
                                                </TableCell>
                                                <TableCell align="right">{row.codeset}</TableCell>
                                                <TableCell align="right">{row.name}</TableCell>
                                                <TableCell align="right">{row.type}</TableCell>
                                                <TableCell align="right">
                                                    {row.documentedDateTime
                                                        ? moment
                                                              .utc(row.documentedDateTime)
                                                              .local()
                                                              .format('YYYY-MM-DD HH:mm:ss')
                                                        : ''}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">Clinical Info:</Typography>
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Code</TableCell>
                                            <TableCell align="right">Code Set</TableCell>
                                            <TableCell align="right">Description</TableCell>
                                            <TableCell align="right">Value</TableCell>
                                            <TableCell align="right">Units</TableCell>
                                            <TableCell align="right">Abbreviation</TableCell>
                                            <TableCell align="right">Notes</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orderData?.order?.clinicalInfo?.map(row => (
                                            <TableRow key={row.code}>
                                                <TableCell component="th" scope="row">
                                                    {row.code}
                                                </TableCell>
                                                <TableCell align="right">{row.codeset}</TableCell>
                                                <TableCell align="right">
                                                    {row.description}
                                                </TableCell>
                                                <TableCell align="right">{row.value}</TableCell>
                                                <TableCell align="right">{row.units}</TableCell>
                                                <TableCell align="right">
                                                    {row.abbreviation}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.notes?.length === 0 ? (
                                                        0
                                                    ) : (
                                                        <Button
                                                            variant="text"
                                                            onClick={() => {
                                                                setShowNotesModal(true);
                                                                setNotesToShow(row.notes);
                                                            }}
                                                        >
                                                            {row.notes?.length}
                                                        </Button>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">Comments:</Typography>
                            <Typography>{orderData?.order?.comments}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">ProviderNPI:</Typography>
                            <Typography>{orderData?.order?.providerNPI}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">Provider First Name:</Typography>
                            <Typography>{orderData?.order?.providerFirstName}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">Provider Last Name:</Typography>
                            <Typography>{orderData?.order?.providerLastName}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">Created AT:</Typography>
                            <Typography>{orderData?.order?.createdAt}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">Updated AT:</Typography>
                            <Typography>{orderData?.order?.updatedAt}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant="outlined" elevation={2}>
                            <Typography variant="h6">Redox EHR ID AT:</Typography>
                            <Typography>{orderData?.order?.redoxEhrId}</Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="secondary" variant="outlined">
                    Close
                </Button>
            </DialogActions>

            <Dialog
                scroll="paper"
                open={showNotesModal}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <NotesModal setOpen={setShowNotesModal} notes={notesToShow} />
            </Dialog>
        </div>
    );
};

export default OrderModal;
