import {
    ClockType,
    HighlightIcon,
    HighlightInput,
    HighlightType,
    HighlightUpdateInput,
    TimeUnit,
} from '~/schemaTypes';
import { ClientSelectionType, OffsetType } from '~/enums/enums';
import { HighlightFormInput } from '../types/types';

const toIncludeOrExclude = ({
    clientSelectionType,
    includeApps,
    excludeApps,
}: Partial<HighlightFormInput>) => {
    switch (clientSelectionType) {
        case ClientSelectionType.IncludeCertainApps:
            return {
                includeApps: includeApps ?? [],
                excludeApps: [],
            };
        case ClientSelectionType.ExcludeCertainApps:
            return {
                includeApps: [],
                excludeApps: excludeApps ?? [],
            };
        case ClientSelectionType.IncludeAllApps:
            return {
                includeApps: [],
                excludeApps: [],
            };
        default:
            return {};
    }
};

export const toUpdateInput = (form: Partial<HighlightFormInput>): HighlightUpdateInput => {
    const {
        name,
        type,
        clockType,
        startDate,
        endDate,
        timelineId,
        offsetDays: offsetDaysInput,
        offsetMonths: offsetMonthsInput,
        offsetYears: offsetYearsInput,
        offsetType,
        duration,
        durationTimeUnit,
        priority,
        tagIds,
        icon,
        imageContentId,
        simpleContentId,
        closeable,
        highlighted,
        shouldSendNotification,
        notificationText,
        linkText,
        displayAsModal,
        displayAsModalButtonText,
    } = form;
    const offsetDays =
        offsetType === OffsetType.After ? Number(offsetDaysInput) : -(offsetDaysInput ?? 0);
    const offsetMonths =
        offsetType === OffsetType.After ? Number(offsetMonthsInput) : -(offsetMonthsInput ?? 0);
    const offsetYears =
        offsetType === OffsetType.After ? Number(offsetYearsInput) : -(offsetYearsInput ?? 0);
    let canClose = true;
    let isHighlighted = false;
    if (closeable !== undefined) {
        canClose = closeable;
    }
    if (highlighted !== undefined) {
        isHighlighted = highlighted;
    }
    return {
        ...toIncludeOrExclude(form),
        name: name || '',
        type: type || HighlightType.AppLink,
        clockType: clockType || ClockType.Timeline,
        startDate,
        endDate,
        timelineId,
        offsetDays,
        offsetMonths,
        offsetYears,
        duration: (typeof duration === 'string' ? Number(duration) : duration) || 1,
        durationTimeUnit: durationTimeUnit || TimeUnit.Days,
        priority: priority != null ? priority : 5,
        tags: tagIds || null,
        icon,
        imageContentId,
        simpleContentId,
        closeable: canClose,
        highlighted: isHighlighted,
        shouldSendNotification: shouldSendNotification || false,
        notificationText: { en: notificationText?.en, es: notificationText?.es },
        linkText: { en: linkText?.en, es: linkText?.es },
        displayAsModal: displayAsModal || false,
        displayAsModalButtonText: {
            en: displayAsModalButtonText?.en,
            es: displayAsModalButtonText?.es,
        },
    };
};
export const toCreateInput = (form: Partial<HighlightFormInput>): HighlightInput => {
    const {
        name,
        type,
        clockType,
        startDate,
        endDate,
        timelineId,
        offsetDays: offsetDaysInput,
        offsetMonths: offsetMonthsInput,
        offsetYears: offsetYearsInput,
        offsetType,
        duration,
        durationTimeUnit,
        priority,
        tagIds,
        icon,
        imageContentId,
        simpleContentId,
        closeable,
        highlighted,
        shouldSendNotification,
        notificationText,
        linkText,
        displayAsModal,
        displayAsModalButtonText,
    } = form;
    const offsetDays =
        offsetType === OffsetType.After ? Number(offsetDaysInput) : -(offsetDaysInput ?? 0);
    const offsetMonths =
        offsetType === OffsetType.After ? Number(offsetMonthsInput) : -(offsetMonthsInput ?? 0);
    const offsetYears =
        offsetType === OffsetType.After ? Number(offsetYearsInput) : -(offsetYearsInput ?? 0);
    return {
        ...toIncludeOrExclude(form),
        name: name || '',
        type: type || HighlightType.AppLink,
        clockType: clockType || ClockType.Timeline,
        startDate,
        endDate,
        timelineId,
        offsetDays,
        offsetMonths,
        offsetYears,
        duration: (typeof duration === 'string' ? Number(duration) : duration) || 1,
        durationTimeUnit: durationTimeUnit || TimeUnit.Days,
        priority: priority || 5,
        tags: tagIds || null,
        icon: icon || HighlightIcon.None,
        imageContentId,
        simpleContentId,
        closeable: closeable || true,
        highlighted: highlighted || false,
        shouldSendNotification: shouldSendNotification || false,
        notificationText: { en: notificationText?.en, es: notificationText?.es },
        linkText: { en: linkText?.en, es: linkText?.es },
        displayAsModal: displayAsModal || false,
        displayAsModalButtonText: {
            en: displayAsModalButtonText?.en,
            es: displayAsModalButtonText?.es,
        },
    };
};
