import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Dialog, Grid, Typography, Link, TablePagination } from '@mui/material';
import MaterialTable from '@material-table/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
    AlertSeverity,
    OrderByDirectionEnum,
    useDeleteVcZipCodeToLocationMutation,
    useVcZipCodesAffiliatessListQuery,
    useVcZipCodeToLocationsV2LazyQuery,
    VcZipCodeToLocation as VcZipCodeToLocationType,
    VcZipCodeToLocationsV2Document,
    VcZipCodeToLocationsV2Query,
} from '~/schemaTypes';
import { TriggerGlobalAlert, TriggerGlobalConfirm, SuppressNextGlobalAlert } from '~/state';
import { useUserPermissions } from '~/hooks';
import { PAGESIZE } from '~/constants';
import { VCZipCodeToLocationEditorModal } from './VCZipCodeToLocationEditorModal';
import { EditFormData } from './types';

export const VCZipCodeToLocationList = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(PAGESIZE);
    const [search, setSearch] = useState<string>();
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement>, pageNumber: number) => {
        setPage(pageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const [VCZipCodeToLocationQuery, { data: VCZipCodeToLocationData, loading: fetchLoading }] =
        useVcZipCodeToLocationsV2LazyQuery({
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
        });
    const { pagePermissions } = useUserPermissions();
    const [editEntity, setEditEntity] = useState<EditFormData>();
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const { data: affiliates } = useVcZipCodesAffiliatessListQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    });
    const getVcZipCodesPayload = useMemo(
        () => ({
            input: {
                orderBy: {
                    field: 'locationName',
                    order: OrderByDirectionEnum.Asc,
                },
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                ...(search && { search }),
            },
        }),
        [search, page, rowsPerPage],
    );
    const filteredAffiliates = useMemo(
        () =>
            (affiliates?.virtualCareAffiliatessV2.results || []).filter(
                a => a.onSuccessfulZipCodeMatch,
            ),
        [affiliates?.virtualCareAffiliatessV2.results],
    );
    useEffect(() => {
        VCZipCodeToLocationQuery({
            variables: getVcZipCodesPayload,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getVcZipCodesPayload]);
    const [deleteEntity, { loading: deleteLoading }] = useDeleteVcZipCodeToLocationMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: VcZipCodeToLocationsV2Document,
                variables: getVcZipCodesPayload,
            },
        ],
        onCompleted: data => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Success,
                message: `${data.deleteVCZipCodeToLocation?.id} Virtual Care Zip Code to Location was deleted`,
            });
        },
    });
    const editEntityCallback = useCallback(
        (saved: boolean) => {
            if (saved) {
                VCZipCodeToLocationQuery({ variables: getVcZipCodesPayload });
                if (tableRef.current) {
                    tableRef.current.scrollIntoView();
                }
            }
            setShowEditModal(false);
        },
        [VCZipCodeToLocationQuery, getVcZipCodesPayload],
    );
    const addNewItem = useCallback(() => {
        setEditEntity(undefined);
        setShowEditModal(true);
    }, []);
    const editItem = useCallback(
        (_: any, { id }: VcZipCodeToLocationType) => {
            const data = VCZipCodeToLocationData?.VCZipCodeToLocationSearch?.results.find(
                r => r.id === id,
            );
            setEditEntity(data);
            setShowEditModal(true);
        },
        [VCZipCodeToLocationData],
    );
    const deleteItem = useCallback((_: any, { id }: VcZipCodeToLocationType) => {
        TriggerGlobalConfirm({
            message: `Do you want to delete Virtual Care Zip Code to Location ${id}`,
            callback: () => {
                SuppressNextGlobalAlert(true);
                deleteEntity({
                    variables: {
                        input: {
                            id,
                        },
                    },
                });
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onSearchChange = useCallback((str: string) => setSearch(str), []);

    return (
        <div style={{ marginRight: '1rem' }} ref={tableRef}>
            <Grid>
                <MaterialTable<
                    NonNullable<
                        VcZipCodeToLocationsV2Query['VCZipCodeToLocationSearch']
                    >['results'][0]
                >
                    title="Virtual Care Zip Code to Location"
                    columns={[
                        {
                            title: 'Location Name',
                            field: 'locationName',
                            render: ({ locationName }) => (
                                <Typography data-test={locationName}>{locationName}</Typography>
                            ),
                            sorting: false,
                            customFilterAndSearch: () => true,
                        },
                        {
                            title: 'Zip Code',
                            field: 'zipCode',
                            render: ({ zipCode }) => (
                                <Typography data-test={zipCode}>{zipCode}</Typography>
                            ),
                            sorting: false,
                            searchable: false,
                        },
                        {
                            title: 'TT Location ID',
                            field: 'locationId',
                            render: ({ locationId }) => (
                                <Typography data-test={locationId}>{locationId}</Typography>
                            ),
                            sorting: false,
                            searchable: false,
                        },
                        {
                            title: 'Affiliate',
                            field: 'affiliate',
                            render: ({ affiliate }) => (
                                <Typography data-test={affiliate?.id}>
                                    <Link
                                        href={`/app-config/vcaffiliates/${affiliate?.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {affiliate?.internalName}
                                    </Link>
                                </Typography>
                            ),
                            sorting: false,
                            searchable: false,
                        },
                    ]}
                    data={
                        VCZipCodeToLocationData?.VCZipCodeToLocationSearch?.results.map(
                            (o: any) => ({
                                ...o,
                            }),
                        ) || []
                    }
                    options={{
                        search: true,
                        paging: true,
                        pageSize: rowsPerPage,
                        pageSizeOptions: [10, PAGESIZE, 50],
                        debounceInterval: 500,
                    }}
                    localization={{ header: { actions: '' } }}
                    isLoading={fetchLoading || deleteLoading}
                    onSearchChange={onSearchChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    page={page}
                    totalCount={VCZipCodeToLocationData?.VCZipCodeToLocationSearch?.total || 0}
                    actions={[
                        {
                            icon: () => <FontAwesomeIcon icon={faPlus} />,
                            isFreeAction: true,
                            onClick: addNewItem,
                            tooltip: 'Create New Entry',
                            hidden: !pagePermissions?.VirtualCareZipCodeMap.Edit,
                        },
                        {
                            onClick: editItem,
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit',
                            hidden: !pagePermissions?.VirtualCareZipCodeMap.Edit,
                        },
                        {
                            onClick: deleteItem,
                            icon: () => <FontAwesomeIcon icon={faTrash} />,
                            tooltip: 'Delete',
                            hidden: !pagePermissions?.VirtualCareZipCodeMap.Delete,
                        },
                    ]}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                page={page}
                                onPageChange={handleChangePage}
                                count={
                                    VCZipCodeToLocationData?.VCZipCodeToLocationSearch?.total || 0
                                }
                            />
                        ),
                    }}
                />
            </Grid>
            {showEditModal && (
                <Dialog open={showEditModal} scroll="paper">
                    <VCZipCodeToLocationEditorModal
                        defaultData={editEntity}
                        onComplete={editEntityCallback}
                        affiliates={filteredAffiliates}
                    />
                </Dialog>
            )}
        </div>
    );
};

export default VCZipCodeToLocationList;
