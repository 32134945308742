import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import {
    CareTeamMemberType,
    FetchCareTeamMemberTypesForConfigPageDocument,
    FetchCareTeamMemberTypesForConfigPageQuery,
    useCreateCareTeamMemberTypeMutation,
    useFetchCareTeamMemberTypesForConfigPageQuery,
    useUpdateCareTeamMemberTypeMutation,
} from '~/schemaTypes';
import { ConditionTypeEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';
import { errorRed } from '~/theme/WildTheme';

interface FormInput {
    name: string;
    isJob: boolean;
}
const inputValidation = Yup.object().shape({
    name: Yup.string().required('You need to enter a name'),
    isJob: Yup.boolean().default(false),
});

type CareTeamMemberTypeModalProps = {
    isOpen: boolean;
    item: CareTeamMemberType | null;
    onClose: () => void;
};

export const CareTeamMemberTypeModal = ({
    isOpen,
    item,
    onClose,
}: CareTeamMemberTypeModalProps) => {
    const [nameUsed, setNameUsed] = useState(false);
    // list of conditionTypes to compare with name field of new one
    const { data: careTeamMemberTypeList, loading: listLoading } =
        useFetchCareTeamMemberTypesForConfigPageQuery();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormInput>({
        resolver: yupResolver(inputValidation as any),
        values: {
            name: item?.name ?? '',
            isJob: item?.isJob ?? false,
        },
    });

    const handleClose = () => {
        setNameUsed(false);
        onClose();
    };

    const [createCareTeamMemberType, { loading: createLoading }] =
        useCreateCareTeamMemberTypeMutation({
            onCompleted: () => {
                handleClose();
            },
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        handleClose();
                    },
                    message: `There was a problem saving the Care Team Member Type: ${error.message}`,
                });
            },
            update: (cache, response) => {
                const newItem = response.data?.createCareTeamMemberType?.resourceCreated;
                if (response.data?.createCareTeamMemberType?.success && newItem) {
                    const currentItems =
                        cache.readQuery<FetchCareTeamMemberTypesForConfigPageQuery>({
                            query: FetchCareTeamMemberTypesForConfigPageDocument,
                        });
                    if (currentItems?.careTeamMemberTypesV2.results) {
                        cache.writeQuery<FetchCareTeamMemberTypesForConfigPageQuery>({
                            query: FetchCareTeamMemberTypesForConfigPageDocument,
                            data: {
                                careTeamMemberTypesV2: {
                                    total: currentItems.careTeamMemberTypesV2.total + 1,
                                    results: [
                                        ...currentItems.careTeamMemberTypesV2.results,
                                        newItem,
                                    ],
                                },
                            },
                        });
                    }
                }
            },
        });

    const [updateItem, { loading: updateLoading }] = useUpdateCareTeamMemberTypeMutation({
        onCompleted: () => {
            handleClose();
        },
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    handleClose();
                },
                message: `There was a problem updating the Care Team Member Type: ${error.message}`,
            });
        },
        update: (cache, response) => {
            const newItem = response.data?.updateCareTeamMemberType?.resourceUpdated;
            if (response.data?.updateCareTeamMemberType?.success && newItem) {
                const currentItems = cache.readQuery<FetchCareTeamMemberTypesForConfigPageQuery>({
                    query: FetchCareTeamMemberTypesForConfigPageDocument,
                });
                if (currentItems?.careTeamMemberTypesV2.results) {
                    cache.writeQuery<FetchCareTeamMemberTypesForConfigPageQuery>({
                        query: FetchCareTeamMemberTypesForConfigPageDocument,
                        data: {
                            careTeamMemberTypesV2: {
                                total: currentItems.careTeamMemberTypesV2.total,
                                results: [
                                    ...currentItems.careTeamMemberTypesV2.results.filter(
                                        item => item.id !== newItem.id,
                                    ),
                                    newItem,
                                ],
                            },
                        },
                    });
                }
            }
        },
    });

    const isInEditMode = item !== null;

    const onSubmit = (values: FormInput) => {
        setNameUsed(false);
        if (
            careTeamMemberTypeList &&
            careTeamMemberTypeList.careTeamMemberTypesV2.results.filter(
                t =>
                    t.name.toLowerCase() === values?.name.toLowerCase() &&
                    (!isInEditMode || t.id !== item.id),
            ).length > 0
        ) {
            setNameUsed(true);
            return;
        }
        if (isInEditMode && item !== null) {
            updateItem({
                variables: {
                    input: {
                        id: item.id,
                        data: {
                            name: values.name,
                            isJob: values.isJob,
                        },
                    },
                },
            });
            return;
        }
        createCareTeamMemberType({
            variables: {
                input: values,
            },
        });
    };

    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose id="dialogTitle" onClose={handleClose}>
                {`${item?.id ? 'Edit' : 'Add'} Care Team Member Type`}
            </DialogTitleWithClose>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    {listLoading || updateLoading || createLoading ? (
                        <Loading height={50} />
                    ) : (
                        <div>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Name *"
                                    fullWidth
                                    margin="dense"
                                    defaultValue={item?.name}
                                    {...register('name')}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                                {nameUsed && (
                                    <div style={{ color: errorRed }}>
                                        Name already used. Please change.
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="isJob"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox {...field} checked={!!field.value} />
                                            }
                                            label="Is Job"
                                        />
                                    )}
                                />
                            </Grid>
                            {isInEditMode && (
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        name="profileDefName"
                                        label="Profile Definition"
                                        fullWidth
                                        select
                                        margin="dense"
                                        defaultValue={item?.profileDef?.name}
                                        value={item?.profileDef?.name}
                                        disabled
                                    >
                                        <MenuItem value={item?.profileDef?.name}>
                                            {item?.profileDef?.name}
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                            )}
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={() => handleSubmit(onSubmit)}
                        data-test={ConditionTypeEnum.SAVE}
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
