import { client } from '~/ApolloClientWrapper';
import { ClearAuthTokens } from '..';
import { cognitoLogout } from './cognito.svc';

export const logout = async (reload = true) => {
    await Promise.allSettled([cognitoLogout(), client.clearStore()]);
    ClearAuthTokens();
    // eslint-disable-next-line no-self-assign
    if (reload) window.location = window.location;
};
