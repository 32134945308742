import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { RefCallBack } from 'react-hook-form';
import { SurveyDefQuestionDisplayType, UserProfileValueType } from '~/schemaTypes';

const PieceQuestionSelect: React.FC<{
    valueType?: UserProfileValueType;
    selectedType: SurveyDefQuestionDisplayType;
    displayTypeChanged: (value: any) => void;
    registerField: RefCallBack;
}> = props => {
    const { valueType, displayTypeChanged, registerField } = props;
    let { selectedType } = props;
    switch (valueType) {
        case UserProfileValueType.Bool:
            if (
                selectedType !== SurveyDefQuestionDisplayType.CheckBox &&
                selectedType !== SurveyDefQuestionDisplayType.Toggle
            ) {
                selectedType = SurveyDefQuestionDisplayType.CheckBox;
                displayTypeChanged(selectedType);
            }
            return (
                <Select
                    variant="outlined"
                    id="displayType"
                    inputRef={registerField}
                    name="displayType"
                    value={selectedType}
                    onChange={e => displayTypeChanged(e.target.value)}
                >
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.CheckBox}
                        value={SurveyDefQuestionDisplayType.CheckBox}
                    >
                        Checkbox
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.Toggle}
                        value={SurveyDefQuestionDisplayType.Toggle}
                    >
                        Toggle
                    </MenuItem>
                </Select>
            );
        case UserProfileValueType.Choice:
            if (
                selectedType !== SurveyDefQuestionDisplayType.RadioButtons &&
                selectedType !== SurveyDefQuestionDisplayType.DropDown &&
                selectedType !== SurveyDefQuestionDisplayType.ButtonGroup
            ) {
                selectedType = SurveyDefQuestionDisplayType.DropDown;
                displayTypeChanged(selectedType);
            }
            return (
                <Select
                    variant="outlined"
                    id="displayType"
                    name="displayType"
                    inputRef={registerField}
                    value={selectedType}
                    onChange={e => displayTypeChanged(e.target.value)}
                >
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.RadioButtons}
                        value={SurveyDefQuestionDisplayType.RadioButtons}
                    >
                        Radio Buttons
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.DropDown}
                        value={SurveyDefQuestionDisplayType.DropDown}
                    >
                        Dropdown
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.ButtonGroup}
                        value={SurveyDefQuestionDisplayType.ButtonGroup}
                    >
                        Button Group
                    </MenuItem>
                </Select>
            );
        case UserProfileValueType.Choices:
            if (
                selectedType !== SurveyDefQuestionDisplayType.CheckBoxes &&
                selectedType !== SurveyDefQuestionDisplayType.DropDownMultipleSelect
            ) {
                selectedType = SurveyDefQuestionDisplayType.CheckBoxes;
                displayTypeChanged(selectedType);
            }
            return (
                <Select
                    variant="outlined"
                    id="displayType"
                    name="displayType"
                    inputRef={registerField}
                    value={selectedType}
                    onChange={e => displayTypeChanged(e.target.value)}
                >
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.CheckBoxes}
                        value={SurveyDefQuestionDisplayType.CheckBoxes}
                    >
                        Checkboxes
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.DropDownMultipleSelect}
                        value={SurveyDefQuestionDisplayType.DropDownMultipleSelect}
                    >
                        Dropdown with Multiple Select
                    </MenuItem>
                </Select>
            );
        case UserProfileValueType.Str:
        case UserProfileValueType.Strs:
            if (
                selectedType !== SurveyDefQuestionDisplayType.LineInput &&
                selectedType !== SurveyDefQuestionDisplayType.TextInput &&
                selectedType !== SurveyDefQuestionDisplayType.PhoneNumber &&
                selectedType !== SurveyDefQuestionDisplayType.Email &&
                selectedType !== SurveyDefQuestionDisplayType.Avatar
            ) {
                selectedType = SurveyDefQuestionDisplayType.LineInput;
                displayTypeChanged(selectedType);
            }
            return (
                <Select
                    variant="outlined"
                    id="displayType"
                    name="displayType"
                    inputRef={registerField}
                    value={selectedType}
                    onChange={e => displayTypeChanged(e.target.value)}
                >
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.LineInput}
                        value={SurveyDefQuestionDisplayType.LineInput}
                    >
                        Line Input
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.TextInput}
                        value={SurveyDefQuestionDisplayType.TextInput}
                    >
                        Text Input
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.PhoneNumber}
                        value={SurveyDefQuestionDisplayType.PhoneNumber}
                    >
                        Phone Number
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.Email}
                        value={SurveyDefQuestionDisplayType.Email}
                    >
                        Email Address
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.Avatar}
                        value={SurveyDefQuestionDisplayType.Avatar}
                    >
                        Avatar
                    </MenuItem>
                </Select>
            );
        case UserProfileValueType.Date:
        case UserProfileValueType.Dates:
            if (
                selectedType !== SurveyDefQuestionDisplayType.Date &&
                selectedType !== SurveyDefQuestionDisplayType.DateTime &&
                selectedType !== SurveyDefQuestionDisplayType.DueDate &&
                selectedType !== SurveyDefQuestionDisplayType.DateInTheFuture &&
                selectedType !== SurveyDefQuestionDisplayType.DateInThePast &&
                selectedType !== SurveyDefQuestionDisplayType.Date13YearsInThePast &&
                selectedType !== SurveyDefQuestionDisplayType.Date18YearsInThePast
            ) {
                selectedType = SurveyDefQuestionDisplayType.Date;
                displayTypeChanged(selectedType);
            }
            return (
                <Select
                    variant="outlined"
                    id="displayType"
                    name="displayType"
                    value={selectedType}
                    inputRef={registerField}
                    onChange={e => displayTypeChanged(e.target.value)}
                >
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.Date}
                        value={SurveyDefQuestionDisplayType.Date}
                    >
                        Date
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.DateTime}
                        value={SurveyDefQuestionDisplayType.DateTime}
                    >
                        Date/Time
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.DueDate}
                        value={SurveyDefQuestionDisplayType.DueDate}
                    >
                        Due Date
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.DateInTheFuture}
                        value={SurveyDefQuestionDisplayType.DateInTheFuture}
                    >
                        Date in the Future
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.DateInThePast}
                        value={SurveyDefQuestionDisplayType.DateInThePast}
                    >
                        Date in the Past
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.Date13YearsInThePast}
                        value={SurveyDefQuestionDisplayType.Date13YearsInThePast}
                    >
                        Date 13 years in the past
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.Date18YearsInThePast}
                        value={SurveyDefQuestionDisplayType.Date18YearsInThePast}
                    >
                        Date 18 years in the past
                    </MenuItem>
                </Select>
            );
        case UserProfileValueType.Num:
            if (
                selectedType !== SurveyDefQuestionDisplayType.Height &&
                selectedType !== SurveyDefQuestionDisplayType.Weight &&
                selectedType !== SurveyDefQuestionDisplayType.Numeric &&
                selectedType !== SurveyDefQuestionDisplayType.BabyWeight &&
                selectedType !== SurveyDefQuestionDisplayType.Year
            ) {
                selectedType = SurveyDefQuestionDisplayType.Numeric;
                displayTypeChanged(selectedType);
            }
            return (
                <Select
                    variant="outlined"
                    id="displayType"
                    name="displayType"
                    value={selectedType}
                    inputRef={registerField}
                    onChange={e => displayTypeChanged(e.target.value)}
                >
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.Numeric}
                        value={SurveyDefQuestionDisplayType.Numeric}
                    >
                        Number
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.Height}
                        value={SurveyDefQuestionDisplayType.Height}
                    >
                        Height
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.Weight}
                        value={SurveyDefQuestionDisplayType.Weight}
                    >
                        Weight
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.BabyWeight}
                        value={SurveyDefQuestionDisplayType.BabyWeight}
                    >
                        Baby Weight
                    </MenuItem>
                    <MenuItem
                        id={SurveyDefQuestionDisplayType.Year}
                        value={SurveyDefQuestionDisplayType.Year}
                    >
                        Year
                    </MenuItem>
                </Select>
            );
        default:
            break;
    }
    return <div />;
};
export default PieceQuestionSelect;
