import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, Grid, TextField } from '@mui/material';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import AddressAutocomplete, {
    MetaData,
} from '~/views/AffiliateCare/components/AddressAutocomplete/AddressAutocomplete';
import { OFFICE_ADDRESS_FORM_SCHEMA } from './yupSchema';
import { OfficeAddress } from '../types';

type AddOfficeAddressProps = {
    close: () => void;
    addNewOffice: (office: OfficeAddress) => void;
};

const AddOfficeAddress: React.FC<AddOfficeAddressProps> = props => {
    const { close, addNewOffice } = props;
    const [validAddress, setIsValidAddress] = useState(true);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        setError,
        clearErrors,
    } = useForm<OfficeAddress>({
        resolver: yupResolver(OFFICE_ADDRESS_FORM_SCHEMA() as any),
    });
    const onSubmit = (data: OfficeAddress) => {
        addNewOffice(data);
        close();
    };
    const addAddressHandler = useCallback((value: string, meta: MetaData) => {
        setValue('address', meta.inputAddress);
        setValue('addressZipCode', meta.zipCode ?? '');
        if (meta.message) {
            setIsValidAddress(false);
            setError('address', { message: meta.message, type: 'custom' });
        } else {
            setIsValidAddress(true);
            clearErrors('address');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <DialogTitleWithClose id="form-dialog-title" onClose={close}>
                Add Office Address
            </DialogTitleWithClose>
            <DialogContent dividers>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justifyContent="flex-start" alignItems="center">
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label="Office Name"
                                fullWidth
                                margin="dense"
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AddressAutocomplete
                                setValue={addAddressHandler}
                                errorMessage={errors.address?.message}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Grid item>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!validAddress}
                        onClick={handleSubmit(onSubmit)}
                    >
                        Add
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={close} color="primary" variant="outlined">
                        Cancel
                    </Button>
                </Grid>
            </DialogActions>
        </div>
    );
};

export default AddOfficeAddress;
