import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
    useChatTypingsV2ForTypingMessagesQuery,
    useActualChatTypingsForTypingMessagesSubscription,
} from '~/schemaTypes';

const useStyles = makeStyles()(() => ({
    root: {
        paddingTop: 20,
    },
    item: {
        fontStyle: 'italic',
    },
}));

const TypingMessages = ({
    chatRoomId,
    currentUserId,
    scrollToLastMessage,
}: {
    chatRoomId: string;
    currentUserId: string;
    scrollToLastMessage: (behavior: 'smooth') => void;
}) => {
    const { classes } = useStyles();
    const [typingUserNames, setTypingUserNames] = useState<(string | undefined)[]>([]);

    // initial load of typing users
    useChatTypingsV2ForTypingMessagesQuery({
        variables: { input: { filter: { fields: { chatRoomId } } } },
        onCompleted: data => {
            const typingUserNames =
                data?.chatTypingsV2?.results
                    ?.filter(i => i?.user?.id !== currentUserId)
                    .map(i => i?.user?.name)
                    .sort() ?? [];

            setTypingUserNames(typingUserNames);
        },
        pollInterval: 60000, // check if user is still typing every minute
        notifyOnNetworkStatusChange: true,
    });

    // subscribe to new typing users
    useActualChatTypingsForTypingMessagesSubscription({
        variables: { input: { chatRoomId } },
        shouldResubscribe: true,
        onData: ({ data }) => {
            const typingUserNames =
                data?.data?.actualChatTypings?.chatTypings?.map(i => i?.user?.name).sort() ?? [];

            setTypingUserNames(typingUserNames);
        },
    });

    const typingUserNamesStr = typingUserNames.join(',');

    // scroll to new user starts typing
    useEffect(() => {
        scrollToLastMessage('smooth');
    }, [typingUserNamesStr, scrollToLastMessage]);

    if (!typingUserNames.length) return null;

    return (
        <div className={classes.root}>
            {typingUserNames.map(userName => (
                <div className={classes.item} key={userName}>
                    {`${userName ?? 'unknown user'} typing...`}
                </div>
            ))}
        </div>
    );
};

export default TypingMessages;
