/* eslint-disable camelcase */
import {
    faClone,
    faPenToSquare,
    faRectangleList,
    faTrash,
    faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Autocomplete,
    Button,
    Card,
    CardHeader,
    Checkbox,
    Dialog,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowBack, Save } from '@mui/icons-material';
import ObjectID from 'bson-objectid';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import ContentIconSelect from '~/components/ContentIconSelect/ContentIconSelect';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import ImageContentPicker from '~/components/ImageContentPicker/ImageContentPicker';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { displayDate } from '~/helpers';
import {
    ArticleCategory,
    ArticleListDocument,
    ArticleListQuery,
    CallToActionInput,
    ContentIcon,
    ContentType,
    ContentVariantInput,
    CtaType,
    Language,
    ReadingLevel,
    SimpleContentListDocument,
    SimpleContentListQuery,
    TagUsageType,
    useArticleByIdLazyQuery,
    useArticleCategoryListQuery,
    useArticleGroupListQuery,
    useCloneVariantMutation,
    useCreateArticleMutation,
    useCreateSimpleContentMutation,
    useDeleteVariantMutation,
    usePublishArticleMutation,
    useReportingCategoryListQuery,
    useTagListQuery,
    useUpdateArticleMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { toBase64 } from '~/helpers/base64Helper';
import CloneVariantModal from '../components/CloneVariantModal';
import CTAModal from '../components/CTAModal';
import VariantReview from '../components/Review';

type Article = NonNullable<ArticleListQuery['articlesV2']>['results'][0];

export interface RelatedModalParms {
    articles: Article[];
    related: string[];
    closeHandler: () => void;
    submitHandler: (selected: string[]) => void;
}

export type VariantKey = {
    language: Language;
    readingLevel: ReadingLevel;
    appBundleId?: string | null | undefined;
};
export interface CloneVariantModalParms {
    existingVariants: VariantKey[];
    submitHandler: (selected: VariantKey) => void;
}

export interface ListItem {
    id: string;
    name: string;
}

const useStyles = makeStyles()(theme => ({
    root: {},
    fab: {
        position: 'absolute',
        top: theme.spacing(12),
        right: theme.spacing(4),
    },
}));

const ctaTypeName = (type: CtaType): string => {
    switch (type) {
        case CtaType.AddToQList:
            return 'Add to QList';
        case CtaType.OpenExternalLink:
            return 'Open External Link';
        case CtaType.OpenInternalLink:
            return 'Open Internal Link';
        case CtaType.PopUpMessage:
            return 'Pop-up Message';
        case CtaType.TapToCall:
            return 'Tap to Call';
        case CtaType.TapToCallPopUp:
            return 'Tap to Call Pop-up';
        default:
            return 'Unknown';
    }
};

const ArticleValidation = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().nullable(),
    references: Yup.string().nullable(),
    keywords: Yup.string().nullable(),
});

const variantInfo = (v: ContentVariantInput): string => {
    if (!v.titleMarkup && !v.bodyMarkup && !v.summaryMarkup) return 'Variant has no content.';
    const edited = `Last edited on ${displayDate({ isoDateStr: v.dateEdited })} by ${v.editedBy}`;
    if (!v.datePublished) {
        return `Changes to publish. ${edited}`;
    }
    return `Published on ${displayDate({ isoDateStr: v.datePublished })}. ${edited}`;
};
const canPublish = (variants: ContentVariantInput[]): boolean => {
    for (const variant of variants)
        if (!variant.datePublished) {
            return true;
        }
    return false;
};

const ArticleEditor: React.FC = () => {
    const history = useNavigate();
    const { classes } = useStyles();
    const [isModified, setIsModified] = useState(false);
    const [close, setClose] = useState(false);
    const [cloneModalOpen, setCloneModalOpen] = useState(false);
    const [cloneVariant, setCloneVariant] = useState<VariantKey>();
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedImageId, setSelectedImageId] = useState<string>();
    const [selectedIcon, setSelectedIcon] = useState<ContentIcon>(ContentIcon.None);
    const { id: articleId } = useParams<{ id: string }>();
    const [variants, setVariants] = useState<ContentVariantInput[]>([]);
    const [selectedVariant, setSelectedVariant] = useState<ContentVariantInput>();
    const [reviewOpen, setReviewOpen] = useState(false);
    const [groups, setGroups] = useState<string[]>([]);
    const [reportingCats, setReportingCats] = useState<string[]>([]);
    const [callToActions, setCallToActions] = useState<
        {
            cta: CallToActionInput;
            tagList?: ListItem[];
            contentName?: string;
        }[]
    >([]);
    const [selectedCTA, setSelectedCTA] = useState<{
        cta: CallToActionInput;
        tagNames?: string[];
        contentName?: string;
    } | null>(null);
    const [ctaModalOpen, setCtaModalOpen] = useState(false);

    let isEditMode = articleId !== 'new';
    const {
        register,
        handleSubmit,
        setValue,

        formState: { errors },
    } = useForm({
        resolver: yupResolver(ArticleValidation),
    });
    const { data: categoryList, loading: categoryListLoading } = useArticleCategoryListQuery({
        onCompleted: ({ articleCategorys }) => {
            if (articleCategorys) {
                setSelectedCategory(articleCategorys[0].id || '');
            }
        },
    });
    const { data: groupList, loading: groupListLoading } = useArticleGroupListQuery({
        variables: { articleGroupListInput: {} },
    });
    const { data: reportingList, loading: reportingListLoading } = useReportingCategoryListQuery({
        variables: {},
    });

    const { data: tagData, loading: tagsLoading } = useTagListQuery({
        variables: { tagListInput: [TagUsageType.Filtering, TagUsageType.PatientRiskScore] },
    });

    const [fetchArticleById, { data: articleData, loading: articleLoading }] =
        useArticleByIdLazyQuery({
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
            onCompleted: ({ article }) => {
                if (article) {
                    setSelectedCategory(article.articleCategoryId);
                    setValue('name', article.name);
                    setValue('description', article.description);
                    setValue('keywords', article.keywords);
                    setValue('references', article.references);
                    setGroups(article.groups || []);
                    setReportingCats(article.reportingCategorys || []);
                    setSelectedImageId(article.imageContentId);
                    if (article.icon) setSelectedIcon(article.icon);
                    if (article.variants) {
                        setVariants(
                            article.variants.map(v => {
                                return { ...v };
                            }),
                        );
                    }
                    if (article.callToActions) {
                        setCallToActions(
                            article.callToActions.map(c => ({
                                cta: {
                                    id: c.id,
                                    type: c.type,
                                    tagList: c.tagList,
                                    simpleContentId: c.simpleContentId,
                                    parameter1: { en: c.parameter1?.en, es: c.parameter1?.es },
                                    parameter2: { en: c.parameter2?.en, es: c.parameter2?.es },
                                    buttonText: { en: c.buttonText?.en, es: c.buttonText?.es },
                                    defaultButtonText: c.defaultButtonText,
                                },
                                tagList: c.tagList?.map(tag => ({
                                    id: tag,
                                    name:
                                        tagData?.getTagListByUsageTypes.find(t => t.id === tag)
                                            ?.name || 'Tag not found',
                                })),
                                contentName: c.simpleContent?.name || '',
                            })),
                        );
                    }
                }
            },
        });

    const handlePreview = (
        l: Language,
        rl: ReadingLevel,
        appBundleId: string | null | undefined,
    ) => {
        const variant = variants.find(
            v => v.language === l && v.readingLevel === rl && v.appBundleId === appBundleId,
        );
        if (variant) {
            setSelectedVariant(variant);
            setReviewOpen(true);
        }
    };
    const [cloneVariantMutation, { loading: cloneVariantLoading }] = useCloneVariantMutation({
        onCompleted: data => {
            history(
                `/app-config/variants/${articleId}/${data.cloneVariant?.language}/${
                    data.cloneVariant?.readingLevel
                }/${data.cloneVariant?.appBundleId ? btoa(data.cloneVariant?.appBundleId) : null}`,
            );
        },
        update: (cache, response) => {
            const updatedArticle = response.data?.cloneVariant?.article;
            if (response.data?.cloneVariant?.success && updatedArticle) {
                const currentArticles = cache.readQuery<ArticleListQuery>({
                    query: ArticleListDocument,
                });
                if (currentArticles?.articlesV2) {
                    cache.writeQuery<ArticleListQuery>({
                        query: ArticleListDocument,
                        data: {
                            articlesV2: {
                                ...currentArticles.articlesV2,
                                results: [
                                    ...currentArticles.articlesV2.results.map(article => {
                                        if (article.id === updatedArticle.id) {
                                            return updatedArticle;
                                        }
                                        return article;
                                    }),
                                ],
                            },
                        },
                    });
                }
            }
        },
    });
    const [deleteVariant, { loading: deleteVariantLoading }] = useDeleteVariantMutation({
        update: (cache, response) => {
            const updatedArticle = response.data?.deleteVariant?.article;
            if (response.data?.deleteVariant?.success && updatedArticle) {
                if (updatedArticle.variants) {
                    setVariants(
                        updatedArticle.variants.map(v => {
                            return { ...v };
                        }),
                    );
                }
                const currentArticles = cache.readQuery<ArticleListQuery>({
                    query: ArticleListDocument,
                });
                if (currentArticles?.articlesV2) {
                    cache.writeQuery<ArticleListQuery>({
                        query: ArticleListDocument,
                        data: {
                            articlesV2: {
                                ...currentArticles.articlesV2,
                                results: [
                                    ...currentArticles.articlesV2.results.map(article => {
                                        if (article.id === updatedArticle.id) {
                                            return updatedArticle;
                                        }
                                        return article;
                                    }),
                                ],
                            },
                        },
                    });
                }
            }
        },
    });
    const [updateArticle, { loading: updateLoading }] = useUpdateArticleMutation({
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    setIsModified(true);
                    setClose(false);
                },
                message: `There was a problem saving the article: ${error.message}`,
            });
        },
        onCompleted: () => {
            if (close) {
                history('/app-config/articles/');
            }
        },
        update: (cache, response) => {
            const updatedArticle = response.data?.updateArticleEncoded?.resourceUpdated;
            if (response.data?.updateArticleEncoded?.success && updatedArticle) {
                const currentArticles = cache.readQuery<ArticleListQuery>({
                    query: ArticleListDocument,
                });
                if (currentArticles?.articlesV2) {
                    cache.writeQuery<ArticleListQuery>({
                        query: ArticleListDocument,
                        data: {
                            articlesV2: {
                                ...currentArticles.articlesV2,
                                results: [
                                    ...currentArticles.articlesV2.results.map(article => {
                                        if (article.id === updatedArticle.id) {
                                            return updatedArticle;
                                        }
                                        return article;
                                    }),
                                ],
                            },
                        },
                    });
                }
            }
        },
    });

    const handleCtaEdit = (id: string) => {
        const [editedCTA] = callToActions.filter(callToAction => callToAction.cta.id === id);
        setSelectedCTA({ ...editedCTA });
        setCtaModalOpen(true);
    };

    const handleCtaDelete = (id: string) => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete this Call to Action?`,
            callback: () => {
                const newCallToActions = callToActions.filter(a => a.cta.id !== id);
                setCallToActions(newCallToActions);
                setIsModified(true);
                setSelectedCTA(null);
            },
        });
    };
    const [createSimpleContent, { loading: createSCLoading }] = useCreateSimpleContentMutation({
        update: (cache, response) => {
            const newItem = response.data?.createSimpleContent?.resourceCreated;
            if (response.data?.createSimpleContent?.success && newItem) {
                const currentItems = cache.readQuery<SimpleContentListQuery>({
                    query: SimpleContentListDocument,
                });
                if (currentItems?.simpleContentsV2.results) {
                    cache.writeQuery<SimpleContentListQuery>({
                        query: SimpleContentListDocument,
                        data: {
                            simpleContentsV2: {
                                ...currentItems.simpleContentsV2,
                                results: [...currentItems.simpleContentsV2.results, newItem],
                            },
                        },
                    });
                }
            }
        },
    });

    const [createArticle, { loading: createLoading }] = useCreateArticleMutation({
        onCompleted: data => {
            isEditMode = true;
            if (data.createArticleEncoded?.resourceCreated)
                createSimpleContent({
                    variables: {
                        input: {
                            name: `${data.createArticleEncoded.resourceCreated.name} (article link)`,
                            label: { en: data.createArticleEncoded.resourceCreated.name },
                            contentType: ContentType.ArticleLink,
                            articleId: data.createArticleEncoded.resourceCreated.id,
                        },
                    },
                });
            if (close) {
                history('/app-config/articles/');
            } else {
                history(
                    `/app-config/articles/${
                        data.createArticleEncoded?.resourceCreated?.id ?? 'new'
                    }`,
                );
            }
        },
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    setIsModified(true);
                    setClose(false);
                },
                message: `There was a problem saving the article: ${error.message}`,
            });
        },
        update: (cache, response) => {
            const newArticle = response.data?.createArticleEncoded?.resourceCreated;
            if (response.data?.createArticleEncoded?.success && newArticle) {
                const currentArticles = cache.readQuery<ArticleListQuery>({
                    query: ArticleListDocument,
                });
                if (currentArticles?.articlesV2) {
                    cache.writeQuery<ArticleListQuery>({
                        query: ArticleListDocument,
                        data: {
                            articlesV2: {
                                ...currentArticles.articlesV2,
                                results: [...currentArticles.articlesV2.results, newArticle],
                            },
                        },
                    });
                }
            }
        },
    });

    const [publishArticle, { loading: publishArticleLoading }] = usePublishArticleMutation({
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    setIsModified(true);
                    setClose(false);
                },
                message: `There was a problem publishing the article: ${error.message}`,
            });
        },
    });
    const handleCtaModalSubmit = (
        isEditMode: boolean,
        cta: CallToActionInput,
        tagList?: ListItem[],
        contentName?: string,
    ) => {
        if (isEditMode) {
            const editedCallToActions = callToActions.map(action => {
                if (action.cta.id === cta.id) {
                    return {
                        cta,
                        tagList,
                        contentName,
                    };
                }
                return action;
            });
            setCallToActions(editedCallToActions);
        } else {
            setCallToActions([...callToActions, { cta, tagList, contentName }]);
        }
        setCtaModalOpen(false);
        setSelectedCTA(null);
        setIsModified(true);
    };
    const handleCtaModalClose = () => {
        setCtaModalOpen(false);
        setSelectedCTA(null);
    };
    const onSubmit = (values: any) => {
        if (isEditMode && articleId) {
            updateArticle({
                variables: {
                    updateArticleInput: {
                        id: articleId,
                        data: {
                            ...values,
                            callToActions: callToActions.map(c => c.cta),
                            articleCategoryId: new ObjectID(selectedCategory),
                            relatedArticleIds: [],
                            groups,
                            references: toBase64(values.references),
                            reportingCategorys: reportingCats,
                            ...(selectedIcon ? { icon: selectedIcon } : {}),
                            imageContentId: selectedImageId || null,
                        },
                    },
                },
            });
        } else {
            createArticle({
                variables: {
                    input: {
                        ...values,
                        callToActions: callToActions.map(c => c.cta),
                        articleCategoryId: selectedCategory,
                        groups,
                        references: toBase64(values.references),
                        reportingCategorys: reportingCats,
                        relatedArticleIds: [],
                        ...(selectedIcon ? { icon: selectedIcon } : {}),
                        ...(selectedImageId ? { imageContentId: selectedImageId } : {}),
                    },
                },
            });
        }
        setIsModified(false);
    };
    useEffect(() => {
        if (isEditMode && tagData?.getTagListByUsageTypes) {
            fetchArticleById({ variables: { input: { id: articleId } } });
        }
    }, [fetchArticleById, isEditMode, articleId, tagData?.getTagListByUsageTypes]);
    const onEdit = () => {
        setIsModified(true);
    };
    const onNavigateAway = () => {
        if (isModified)
            TriggerGlobalConfirm({
                message: `You have unsaved changes. Are you sure you want to return to the article page?`,
                callback: () => {
                    history(`/app-config/articles/`);
                },
            });
        else history(`/app-config/articles/`);
    };
    const handlePublishClick = () => {
        if (articleId) {
            TriggerGlobalConfirm({
                message: `Are you sure you want to publish this Article?`,
                callback: () => {
                    publishArticle({ variables: { input: articleId } });
                    history('/app-config/articles/');
                },
            });
        }
    };
    const handleDeleteVariantClick = (variant: VariantKey) => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete this Variant?`,
            callback: () => {
                deleteVariant({
                    variables: {
                        input: {
                            articleId,
                            language: variant.language,
                            readingLevel: variant.readingLevel,
                            appBundleId: variant.appBundleId,
                        },
                    },
                });
            },
        });
    };
    const handleCloneSubmit = (variant: VariantKey) => {
        if (cloneVariant) {
            cloneVariantMutation({
                variables: {
                    input: {
                        fromAppBundleId: cloneVariant.appBundleId,
                        fromLanguage: cloneVariant.language,
                        fromReadingLevel: cloneVariant.readingLevel,
                        toAppBundleId: variant.appBundleId,
                        toLanguage: variant.language,
                        toReadingLevel: variant.readingLevel,
                        articleId,
                    },
                },
            });
        }
        setCloneModalOpen(false);
    };
    const handleOpenClone = (l: Language, rl: ReadingLevel, appBundleId?: string) => {
        setCloneVariant({ language: l, readingLevel: rl, appBundleId: appBundleId || undefined });
        setCloneModalOpen(true);
    };
    const groupSelected = (selected: string, checked: boolean) => {
        if (checked) {
            setGroups(groups.concat(selected));
        } else setGroups(groups.filter(g => g !== selected));
        onEdit();
    };
    const reportingCatSelected = (selected: string, checked: boolean) => {
        if (checked) {
            setReportingCats(reportingCats.concat(selected));
        } else setReportingCats(reportingCats.filter(c => c !== selected));
        onEdit();
    };
    if (
        articleLoading ||
        categoryListLoading ||
        createLoading ||
        updateLoading ||
        reportingListLoading ||
        publishArticleLoading ||
        tagsLoading ||
        groupListLoading ||
        cloneVariantLoading ||
        deleteVariantLoading ||
        createSCLoading
    ) {
        return <Loading />;
    }
    if (categoryList?.articleCategorys === undefined || categoryList.articleCategorys.length === 0)
        return (
            <div>
                Article Categories must be created before adding an Article
                <div>
                    <Button
                        onClick={() => {
                            history(`/app-config/articlecategories/`);
                        }}
                    >
                        Go to Article Categories
                    </Button>
                </div>
            </div>
        );
    if (reviewOpen && selectedVariant)
        return (
            <VariantReview
                variant={selectedVariant}
                articleName={articleData?.article?.name || ''}
                closeReview={() => setReviewOpen(false)}
            />
        );
    return (
        <>
            {tagData && ctaModalOpen && (
                <CTAModal
                    submitHandler={handleCtaModalSubmit}
                    closeHandler={handleCtaModalClose}
                    tags={tagData.getTagListByUsageTypes}
                    ctaData={selectedCTA}
                />
            )}
            {cloneModalOpen && (
                <Dialog scroll="paper" open={cloneModalOpen}>
                    <DialogTitleWithClose
                        id="edit-role-modal"
                        onClose={() => setCloneModalOpen(false)}
                    >
                        Clone {cloneVariant?.language}/{cloneVariant?.readingLevel}/
                        {cloneVariant?.appBundleId}
                    </DialogTitleWithClose>
                    <CloneVariantModal
                        submitHandler={handleCloneSubmit}
                        existingVariants={variants.map(v => ({
                            language: v.language,
                            readingLevel: v.readingLevel,
                            appBundleId: v.appBundleId || undefined,
                        }))}
                    />
                </Dialog>
            )}
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12}>
                    <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                        Back to Articles
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <form noValidate>
                        <Card>
                            <CardHeader
                                title={`Article Settings: ${
                                    articleData?.article?.name ?? 'New Article'
                                }`}
                            />
                            <OutlinedSection title="Article Name *">
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    defaultValue={articleData?.article?.name}
                                    {...register('name')}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                    onChange={onEdit}
                                />
                            </OutlinedSection>
                            <OutlinedSection title="Description">
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    defaultValue={articleData?.article?.description}
                                    {...register('description')}
                                    onChange={onEdit}
                                />
                            </OutlinedSection>
                            <OutlinedSection title="Category">
                                <Autocomplete
                                    size="small"
                                    value={selectedCategory as ArticleCategory['id']}
                                    onChange={(_, val) => {
                                        setSelectedCategory(val as string);
                                        onEdit();
                                    }}
                                    getOptionLabel={id => {
                                        const selectedCategory = categoryList.articleCategorys.find(
                                            item => item.id === id,
                                        );
                                        return `${selectedCategory?.name}`;
                                    }}
                                    options={categoryList?.articleCategorys.map(
                                        category => category.id,
                                    )}
                                    renderInput={params => (
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        <TextField variant="outlined" {...params} />
                                    )}
                                />
                            </OutlinedSection>
                            <OutlinedSection title="Groups">
                                {groupList &&
                                    _.sortBy(groupList.articleGroups, 'name').map(g => (
                                        <span style={{ marginLeft: '10px' }}>
                                            {g.name}
                                            <Checkbox
                                                value={g.id}
                                                key={g.id}
                                                checked={
                                                    groups.find(grp => grp === g.id) !== undefined
                                                }
                                                onChange={e =>
                                                    groupSelected(e.target.value, e.target.checked)
                                                }
                                            />
                                        </span>
                                    ))}
                            </OutlinedSection>
                            <OutlinedSection title="Reporting categories">
                                {reportingList &&
                                    _.sortBy(reportingList.reportingCategorys, 'name').map(rc => (
                                        <span style={{ marginLeft: '10px' }}>
                                            {rc.name}
                                            <Checkbox
                                                value={rc.id}
                                                key={rc.id}
                                                checked={
                                                    reportingCats.find(cat => cat === rc.id) !==
                                                    undefined
                                                }
                                                onChange={e =>
                                                    reportingCatSelected(
                                                        e.target.value,
                                                        e.target.checked,
                                                    )
                                                }
                                            />
                                        </span>
                                    ))}
                            </OutlinedSection>
                            <ContentIconSelect
                                selectedIcon={selectedIcon}
                                setSelectedIcon={setSelectedIcon}
                                onEdit={onEdit}
                            />
                            <ImageContentPicker
                                selectedImageContentId={selectedImageId}
                                setSelectedImageContentId={setSelectedImageId}
                                onEdit={onEdit}
                            />
                            <OutlinedSection title="Keywords">
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    margin="dense"
                                    defaultValue={articleData?.article?.keywords}
                                    {...register('keywords')}
                                    onChange={onEdit}
                                />
                            </OutlinedSection>
                            <OutlinedSection title="References">
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    margin="dense"
                                    defaultValue={articleData?.article?.references}
                                    {...register('references')}
                                    onChange={onEdit}
                                />
                            </OutlinedSection>
                            <OutlinedSection title="Calls To Action">
                                <Button
                                    onClick={() => {
                                        setCtaModalOpen(true);
                                    }}
                                >
                                    Add Call To Action
                                </Button>
                                {callToActions.length > 0 && (
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell>Type</TableCell>
                                                <TableCell>Button text</TableCell>
                                                <TableCell />
                                                <TableCell>Tags</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {callToActions.map(a => (
                                                <TableRow>
                                                    <TableCell>
                                                        <Button
                                                            title="Edit"
                                                            onClick={() => {
                                                                handleCtaEdit(a.cta.id);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faPenToSquare} />
                                                        </Button>
                                                        <Button
                                                            title="Delete"
                                                            onClick={() => {
                                                                handleCtaDelete(a.cta.id);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell>{ctaTypeName(a.cta.type)}</TableCell>
                                                    <TableCell>{a.cta.buttonText?.en}</TableCell>
                                                    <TableCell>{a.contentName}</TableCell>
                                                    <TableCell>
                                                        {a.tagList?.map(tag => tag.name).join(', ')}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                )}
                            </OutlinedSection>
                            {isEditMode && canPublish(variants) && (
                                <div>
                                    <Button
                                        title="Publish"
                                        startIcon={<FontAwesomeIcon icon={faUpload} />}
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => handlePublishClick()}
                                    >
                                        Publish
                                    </Button>
                                </div>
                            )}
                            <div style={{ width: '100%', textAlign: 'right' }}>
                                <Button
                                    aria-label="save"
                                    startIcon={<Save />}
                                    color="secondary"
                                    variant="contained"
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    Save
                                </Button>
                                <Button
                                    aria-label="saveandclose"
                                    startIcon={<Save />}
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        setClose(true);
                                        handleSubmit(onSubmit)();
                                    }}
                                >
                                    Save &amp; Close
                                </Button>
                            </div>
                        </Card>
                        {isEditMode && !isModified && (
                            <div>
                                <h2>
                                    Variants{' '}
                                    <Button
                                        onClick={() =>
                                            history(
                                                `/app-config/variants/${articleData?.article?.id}/new/new/new`,
                                            )
                                        }
                                    >
                                        Add Variant
                                    </Button>
                                </h2>
                                {variants && (
                                    <div>
                                        {variants.map(v => (
                                            <div>
                                                <Button
                                                    title="Edit"
                                                    onClick={() => {
                                                        history(
                                                            `/app-config/variants/${
                                                                articleData?.article?.id
                                                            }/${v.language}/${v.readingLevel}/${
                                                                v.appBundleId
                                                                    ? btoa(v.appBundleId)
                                                                    : null
                                                            }`,
                                                        );
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faPenToSquare} />
                                                </Button>
                                                <Button
                                                    title="Clone"
                                                    onClick={() => {
                                                        handleOpenClone(
                                                            v.language,
                                                            v.readingLevel,
                                                            v.appBundleId || undefined,
                                                        );
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faClone} />
                                                </Button>
                                                <Button
                                                    title="Delete"
                                                    onClick={() => {
                                                        handleDeleteVariantClick({
                                                            language: v.language,
                                                            readingLevel: v.readingLevel,
                                                            appBundleId: v.appBundleId || undefined,
                                                        });
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                                <Button
                                                    title="Preview"
                                                    variant="text"
                                                    onClick={() => {
                                                        handlePreview(
                                                            v.language,
                                                            v.readingLevel,
                                                            v.appBundleId,
                                                        );
                                                    }}
                                                    style={{ marginRight: '10px' }}
                                                >
                                                    <FontAwesomeIcon icon={faRectangleList} />
                                                </Button>
                                                {v.language === Language.En ? 'English' : 'Spanish'}
                                                /
                                                {v.readingLevel === ReadingLevel.Eighth
                                                    ? 'Eighth'
                                                    : 'Fifth'}
                                                /{v.appBundleId ? v.appBundleId : 'All Apps'}
                                                <span style={{ paddingLeft: '10px' }}>
                                                    {variantInfo(v)}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </form>
                </Grid>
            </Grid>
        </>
    );
};

export default ArticleEditor;
