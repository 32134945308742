import React, { useEffect, useState, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { TriggerGlobalAlert } from '~/state';
import { Button, Card, Checkbox, Divider, Grid, Typography } from '@mui/material';

import {
    UserProfileValueType,
    AlertSeverity,
    useCreateMassUpdateTaskMutation,
    MassUpdateTaskStatus,
    MassUpdateTaskProcess,
    useUpdateMassUpdateTaskMutation,
    useGetMassUpdateTaskSelectorsQuery,
    useGetMassUpdateTaskLazyQuery,
    useGetMassUpdatePatientListQuery,
    MassUpdateTaskPvInput,
} from '~/schemaTypes';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import Loading from '~/components/Loading/Loading';
import _ from 'lodash';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import { ArrowBack } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import PVToSetList from './PVToSetList';
import { FormInput, SelectedPV } from './types';
import { SetDisplayName } from './AddPVModal';

const MassUpdateTaskEditor: React.FC = () => {
    const history = useNavigate();
    const { id: massUpdatePatientsId, taskId } = useParams<{ id: string; taskId: string }>();
    const isEdit = taskId !== 'new';
    const [processType, setProcessType] = useState<MassUpdateTaskProcess>(
        MassUpdateTaskProcess.RebuildCache,
    );
    const [sendEmail, setSendEmail] = useState(false);
    const [postToSlack, setPostToSlack] = useState(false);
    const [pvError, setpvError] = useState(false);
    const [canEdit, setCanEdit] = useState(true);
    const [conditionTypeId, setConditionTypeId] = useState<string | undefined>();
    const { data: selectorData, loading: selectorDataLoading } = useGetMassUpdateTaskSelectorsQuery(
        {
            fetchPolicy: 'no-cache',
        },
    );
    const [fetchTask, { data: taskData, loading: taskDataLoading }] =
        useGetMassUpdateTaskLazyQuery();
    const { data: listData, loading: listDataLoading } = useGetMassUpdatePatientListQuery({
        variables: { input: { id: massUpdatePatientsId } },
    });
    const [profileValues, setPVs] = useState<SelectedPV[]>([]);
    const formRef = useRef<HTMLFormElement>(null);
    const {
        handleSubmit: handleFormSubmit,
        setValue,
        formState: { errors },
        trigger,
        control,
    } = useForm();
    useEffect(() => {
        if (isEdit) {
            fetchTask({ variables: { input: { id: taskId } } });
            if (taskData && taskData.massUpdateTask) {
                setValue('tagIds', taskData.massUpdateTask.tagIds);
                setProcessType(taskData.massUpdateTask.processType);
                setConditionTypeId(taskData.massUpdateTask.conditionTypeId);
                setPostToSlack(taskData.massUpdateTask.postToSlack);
                setSendEmail(taskData.massUpdateTask.sendEmail);
                const pvs: SelectedPV[] = [];
                for (const pv of taskData.massUpdateTask.profileValues || []) {
                    let value: any = pv.valueStr;
                    switch (pv.type as UserProfileValueType) {
                        case UserProfileValueType.Bool:
                            value = pv.valueBool;
                            break;
                        case UserProfileValueType.Choice:
                            value = pv.valueId;
                            break;
                        case UserProfileValueType.Choices:
                            value = pv.valueId;
                            break;
                        case UserProfileValueType.Date:
                            value = pv.valueDate;
                            break;
                        case UserProfileValueType.Num:
                            value = pv.valueNum;
                            break;
                        default:
                            break;
                    }
                    const vals = SetDisplayName(pv.type as UserProfileValueType, value);
                    pvs.push({
                        id: pv.profileVariableDefId,
                        type: pv.type as UserProfileValueType,
                        value: vals.value,
                        displayValue: vals.displayValue,
                        name: pv.pvName,
                    });
                }
                setPVs(pvs);
                setpvError(false);
                if (taskData.massUpdateTask.status !== MassUpdateTaskStatus.InSetup) {
                    setCanEdit(false);
                }
            }
        }
    }, [fetchTask, isEdit, setValue, taskData, taskId]);

    const [createTask] = useCreateMassUpdateTaskMutation({
        onCompleted: () => {
            history(`/system-admin/massupdate/${massUpdatePatientsId}/tasks`);
        },
        onError: error =>
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: error.message,
            }),
    });
    const [updateTask] = useUpdateMassUpdateTaskMutation({
        onCompleted: () => {
            history(`/system-admin/massupdate/${massUpdatePatientsId}/tasks`);
        },
        onError: error =>
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: error.message,
            }),
    });
    const handleSetPvs = (pvs: SelectedPV[]) => {
        setPVs(pvs);
        setpvError(pvs.length === 0);
    };
    useEffect(() => {
        setValue('profileValues', profileValues);
        if (profileValues.length) {
            trigger('profileValues');
        }
    }, [profileValues, setValue, trigger]);
    const handleSubmit: SubmitHandler<FormInput> = async form => {
        let noPV = false;
        if (processType === MassUpdateTaskProcess.ProfileValue && form.profileValues.length === 0) {
            noPV = true;
        }
        setpvError(noPV);
        if (noPV) {
            return;
        }
        const profileValues: MassUpdateTaskPvInput[] = [];
        for (const pv of form.profileValues) {
            const profileValue: MassUpdateTaskPvInput = {
                type: pv.type,
                pvName: pv.name,
                profileVariableDefId: pv.id,
            };
            if (pv.value) {
                switch (pv.type) {
                    case UserProfileValueType.Bool:
                        profileValue.valueBool = pv.value;
                        break;
                    case UserProfileValueType.Choice:
                        profileValue.valueId = pv.value;
                        break;
                    case UserProfileValueType.Choices:
                        profileValue.valueIds = pv.value;
                        break;
                    case UserProfileValueType.Date:
                        profileValue.valueDate = moment(pv.value).utc();
                        break;
                    case UserProfileValueType.Num:
                        profileValue.valueNum = parseFloat(pv.value);
                        break;
                    case UserProfileValueType.Str:
                        profileValue.valueStr = pv.value;
                        break;
                    default:
                        break;
                }
            } else {
                profileValue.clear = true;
            }
            profileValues.push(profileValue);
        }
        const task = {
            sendEmail,
            postToSlack,
            massUpdatePatientsId,
            processType,
            status: MassUpdateTaskStatus.InSetup,
            conditionTypeId,
            tagIds: form.tagIds,
            profileValues,
        };
        if (isEdit && taskId) {
            await updateTask({
                variables: {
                    input: {
                        id: taskId,
                        data: task,
                    },
                },
            });
        } else {
            await createTask({
                variables: {
                    input: task,
                },
            });
        }
    };
    const onNavigateAway = () => {
        history(`/system-admin/massupdate/${massUpdatePatientsId}/tasks`);
    };
    const title = (): string => {
        if (!isEdit) {
            return 'Create Task';
        }
        if (canEdit) {
            return 'Update Task';
        }
        return 'Task Details';
    };
    if (selectorDataLoading || taskDataLoading || listDataLoading) {
        return <Loading />;
    }
    return (
        <div style={{ marginLeft: '15px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                        Back to {listData?.massUpdatePatient?.name}
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <Typography variant="h6" paragraph>
                            {title()}
                        </Typography>
                        <Divider style={{ marginBottom: 20 }} />
                        <form onSubmit={handleFormSubmit(handleSubmit)} ref={formRef}>
                            <OutlinedSection title="Task Type">
                                <select
                                    value={processType}
                                    onChange={e => {
                                        setProcessType(e.target.value as MassUpdateTaskProcess);
                                    }}
                                    disabled={isEdit}
                                >
                                    <option value={MassUpdateTaskProcess.DeletePatient}>
                                        Delete Patient
                                    </option>
                                    <option value={MassUpdateTaskProcess.EndEpisode}>
                                        End Episode
                                    </option>
                                    <option value={MassUpdateTaskProcess.ProfileValue}>
                                        Set profile value(s)
                                    </option>
                                    <option value={MassUpdateTaskProcess.RebuildCache}>
                                        Rebuild Patient cache
                                    </option>
                                    <option value={MassUpdateTaskProcess.RecalcTags}>
                                        Recalculate Patient tags
                                    </option>
                                </select>
                            </OutlinedSection>
                            {processType === MassUpdateTaskProcess.ProfileValue && (
                                <Grid item xs={12}>
                                    <PVToSetList
                                        canEdit={canEdit}
                                        setPVs={handleSetPvs}
                                        currentPvs={profileValues}
                                    />
                                    {pvError && (
                                        <div
                                            style={{
                                                color: 'red',
                                                fontWeight: 'bold',
                                                marginTop: '15px',
                                                marginBottom: '15px',
                                            }}
                                        >
                                            At least one Profile Value is required
                                        </div>
                                    )}
                                </Grid>
                            )}
                            {processType === MassUpdateTaskProcess.EndEpisode && (
                                <OutlinedSection title="Select Condition Type">
                                    <select
                                        value={conditionTypeId}
                                        disabled={!canEdit}
                                        onChange={e => {
                                            setConditionTypeId(e.target.value as string);
                                        }}
                                    >
                                        {_.sortBy(selectorData?.conditionTypes, [
                                            condition => condition.name.en.toLowerCase(),
                                        ]).map(c => (
                                            <option value={c.id}>{c.name.en}</option>
                                        ))}
                                    </select>
                                </OutlinedSection>
                            )}
                            {processType === MassUpdateTaskProcess.RecalcTags && (
                                <AutocompleteWithRecordOptions
                                    options={
                                        _.sortBy(selectorData?.tags, [
                                            tag => tag.name.toLowerCase(),
                                        ]) ?? []
                                    }
                                    valueKey="id"
                                    labelKey="name"
                                    control={control}
                                    name="tagIds"
                                    label="Tags"
                                    placeholder="Select tags ..."
                                    error={Boolean(errors.tagIds)}
                                    disabled={!canEdit}
                                />
                            )}
                            <OutlinedSection title="Completion Options">
                                <span>
                                    Send Email
                                    <Checkbox
                                        value={sendEmail}
                                        checked={sendEmail}
                                        onChange={e => setSendEmail(e.target.checked)}
                                        disabled={!canEdit}
                                    />
                                </span>
                                <span style={{ marginLeft: '15px' }}>
                                    Post to Slack
                                    <Checkbox
                                        value={postToSlack}
                                        checked={postToSlack}
                                        onChange={e => setPostToSlack(e.target.checked)}
                                        disabled={!canEdit}
                                    />
                                </span>
                            </OutlinedSection>
                            <Grid item xs={12} paddingLeft={2} paddingTop={5}>
                                {canEdit && (
                                    <div style={{ width: '100%', textAlign: 'right' }}>
                                        <Button
                                            startIcon={<FontAwesomeIcon icon={faSave} />}
                                            variant="contained"
                                            component="label"
                                            color="secondary"
                                            onClick={handleFormSubmit(handleSubmit)}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                )}
                            </Grid>
                        </form>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default MassUpdateTaskEditor;
