import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    AlertSeverity,
    ConditionTypeInput,
    ConditionTypeListDocument,
    ConditionTypeListQuery,
    ConditionTypeUpdateInput,
    useConditionTypeByIdLazyQuery,
    useConditionTypePageListQuery,
    useCreateConditionTypeMutation,
    useUpdateConditionTypeMutation,
} from '~/schemaTypes';
import { ConditionTypeEnum } from '~/selectors';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import { errorRed } from '~/theme/WildTheme';
import { ConditionType } from '../types';

const ConditionTypeUpdateInputValidation = Yup.object().shape({
    name: Yup.object().shape({
        en: Yup.string().required('You need to enter a name'),
        es: Yup.string(),
    }),
});

const ConditionTypeInputValidation = Yup.object().shape({
    name: Yup.object()
        .required()
        .shape({
            en: Yup.string().required('You need to enter a name'),
            es: Yup.string(),
        }),
});

const isConditionTypeInput = (input: unknown): input is ConditionTypeInput => {
    let valid = false;
    try {
        ConditionTypeInputValidation.validateSync(input);
        valid = true;
    } catch (error: any) {
        TriggerGlobalAlert({
            severity: AlertSeverity.Error,
            message: JSON.stringify(error?.errors),
        });
    }
    return valid;
};
interface ConditionTypeFormInput {
    name: {
        en: string;
        es?: string;
    };
}
export const ConditionTypeModal: React.FC<{
    isOpen: boolean;
    conditionType: ConditionType | null;
    onClose: () => void;
}> = props => {
    const { isOpen, conditionType, onClose } = props;
    const [selectedConditionType, setSelectedConditionType] = useState<ConditionType | null>(null);
    const [nameUsed, setNameUsed] = useState(false);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<ConditionTypeFormInput>({
        resolver: yupResolver(ConditionTypeUpdateInputValidation as any),
    });

    // list of conditionTypes to compare with name field of new one
    const { data: conditonTypeList, loading: conditonTypeListLoading } =
        useConditionTypePageListQuery();

    const [fetchConditonTypeById, { data: conditionTypeData, loading: conditionTypeLoading }] =
        useConditionTypeByIdLazyQuery();

    useEffect(() => {
        if (conditionType !== null) {
            const { id: conditionTypeId } = conditionType;
            fetchConditonTypeById({ variables: { input: { id: conditionTypeId } } });
        }
    }, [fetchConditonTypeById, conditionType]);

    useEffect(() => {
        if (conditionTypeData && conditionTypeData.conditionType) {
            setSelectedConditionType({ ...conditionTypeData.conditionType });
            setValue('name.en', conditionTypeData.conditionType.name.en);
            setValue('name.es', conditionTypeData.conditionType.name.es || '');
        }
    }, [conditionTypeData, setValue]);

    const handleClose = () => {
        onClose();
        setSelectedConditionType(null);
        setNameUsed(false);
    };
    const [createConditionType, { loading: createConditionTypeLoading }] =
        useCreateConditionTypeMutation({
            onCompleted: () => {
                handleClose();
            },
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        handleClose();
                    },
                    message: `There was a problem saving the condtiton: ${error.message}`,
                });
            },
            update: (cache, response) => {
                const newConditiontype = response.data?.createConditionType?.resourceCreated;
                if (response.data?.createConditionType?.success && newConditiontype) {
                    const currentСonditionTypes = cache.readQuery<ConditionTypeListQuery>({
                        query: ConditionTypeListDocument,
                    });
                    if (currentСonditionTypes?.conditionTypes) {
                        cache.writeQuery<ConditionTypeListQuery>({
                            query: ConditionTypeListDocument,
                            data: {
                                conditionTypes: [
                                    ...currentСonditionTypes.conditionTypes,
                                    newConditiontype,
                                ],
                            },
                        });
                    }
                }
            },
        });

    const [updateConditionType, { loading: updateConditionTypeLoading }] =
        useUpdateConditionTypeMutation({
            onCompleted: () => {
                handleClose();
            },
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        handleClose();
                    },
                    message: `There was a problem updating the condtiton: ${error.message}`,
                });
            },
            update: (cache, response) => {
                const newConditiontype = response.data?.updateConditionType?.resourceUpdated;
                if (response.data?.updateConditionType?.success && newConditiontype) {
                    const currentСonditionTypes = cache.readQuery<ConditionTypeListQuery>({
                        query: ConditionTypeListDocument,
                    });
                    if (currentСonditionTypes?.conditionTypes) {
                        const updatedConditionList = currentСonditionTypes.conditionTypes.map(
                            condition => {
                                if (condition.id === newConditiontype.id) {
                                    return newConditiontype;
                                }
                                return condition;
                            },
                        );
                        cache.writeQuery<ConditionTypeListQuery>({
                            query: ConditionTypeListDocument,
                            data: {
                                conditionTypes: [...updatedConditionList],
                            },
                        });
                    }
                }
            },
        });
    const isInEditMode = conditionType !== null;
    const onSubmit = (values: ConditionTypeUpdateInput) => {
        if (!isConditionTypeInput(values)) {
            return;
        }
        if (isInEditMode && selectedConditionType !== null) {
            updateConditionType({
                variables: {
                    updateConditionTypeInput: {
                        id: selectedConditionType.id,
                        data: values,
                    },
                },
            });
            return;
        }
        setNameUsed(false);
        if (
            conditonTypeList &&
            conditonTypeList.conditionTypes.filter(
                t => t.name.en?.toLowerCase() === values?.name?.en.toLowerCase(),
            ).length > 0
        ) {
            setNameUsed(true);
            return;
        }
        createConditionType({
            variables: {
                input: values,
            },
        });
    };
    return (
        <Dialog open={isOpen} data-test={ConditionTypeEnum.MODAL}>
            <DialogTitleWithClose id="dialogTitle" onClose={handleClose}>
                {isInEditMode ? 'Edit Condition' : 'Add Condition'}
            </DialogTitleWithClose>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    {conditonTypeListLoading ||
                    conditionTypeLoading ||
                    updateConditionTypeLoading ||
                    createConditionTypeLoading ? (
                        <Loading height={140} />
                    ) : (
                        <div>
                            <Grid item xs={12}>
                                <OutlinedSection title="Name">
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        label="English *"
                                        fullWidth
                                        margin="dense"
                                        defaultValue={conditionType?.name.en}
                                        {...register('name.en')}
                                        error={!!errors.name?.en}
                                        helperText={errors.name?.en?.message}
                                        data-test={ConditionTypeEnum.NAME_EN}
                                    />
                                    {nameUsed && (
                                        <div style={{ color: errorRed }}>
                                            Name already used. Please change.
                                        </div>
                                    )}
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        label="Spanish"
                                        fullWidth
                                        margin="dense"
                                        defaultValue={conditionType?.name.es}
                                        {...register('name.es')}
                                        error={!!errors.name?.es}
                                        helperText={errors.name?.es?.message}
                                        data-test={ConditionTypeEnum.NAME_ES}
                                    />
                                </OutlinedSection>
                            </Grid>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={() => handleSubmit(onSubmit)}
                        data-test={ConditionTypeEnum.SAVE}
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
