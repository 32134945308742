import React, { Dispatch, SetStateAction } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Loading from '~/components/Loading/Loading';
import SaveIcon from '@mui/icons-material/Save';
import {
    CalendarInput,
    FetchCalendarsV2ForCalendarsPageDocument,
    FetchCalendarsV2ForCalendarsPageQuery,
    useCreateCalendarForCalendarModalMutation,
    useUpdateCalendarForCalendarModalMutation,
} from '~/schemaTypes';
import { yupResolver } from '@hookform/resolvers/yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { Button, DialogActions, DialogContent, Grid, IconButton, TextField } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { Delete } from '@mui/icons-material';
import { CALENDAR_VALIDATION_SCHEMA, CalendarFormInput } from './CalendarModal.formValidation';

type CalendarModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    id?: string;
    defaultCalendarValues?: CalendarFormInput | null;
};

export const CalendarModal = ({ setOpen, id, defaultCalendarValues }: CalendarModalProps) => {
    const {
        register,
        handleSubmit,
        getValues,
        control,
        formState: { errors },
    } = useForm<CalendarFormInput>({
        resolver: yupResolver(CALENDAR_VALIDATION_SCHEMA as any),
        defaultValues: {
            name: defaultCalendarValues?.name ?? '',
            startDate: new Date(defaultCalendarValues?.startDate?.toLocaleString() ?? new Date()),
            endDate: new Date(defaultCalendarValues?.endDate?.toLocaleString() ?? new Date()),
            offDays:
                defaultCalendarValues?.offDays?.map(offDay => {
                    return { day: new Date(offDay?.toLocaleString()) };
                }) ?? [],
            startTime: new Date(
                defaultCalendarValues?.startTime?.toLocaleString() ??
                    new Date(new Date().setHours(8, 0, 0, 0)),
            ),
            endTime: new Date(
                defaultCalendarValues?.endTime?.toLocaleString() ??
                    new Date(new Date().setHours(17, 0, 0, 0)),
            ),
        },
    });

    const {
        fields: offDays,
        append,
        remove,
    } = useFieldArray({
        control,
        name: 'offDays',
    });

    const [createCalendar, { loading: loadingCreateCalendar }] =
        useCreateCalendarForCalendarModalMutation({
            update: (cache, response) => {
                const newCalendar = response.data?.createCalendar?.result;
                if (response.data?.createCalendar?.success && newCalendar?.id) {
                    const getCalendarsData = cache.readQuery<FetchCalendarsV2ForCalendarsPageQuery>(
                        {
                            query: FetchCalendarsV2ForCalendarsPageDocument,
                        },
                    );
                    const currentCalendars = getCalendarsData?.calendarsV2.results;
                    if (currentCalendars) {
                        const newCalendars = [...currentCalendars, newCalendar];
                        cache.writeQuery<FetchCalendarsV2ForCalendarsPageQuery>({
                            query: FetchCalendarsV2ForCalendarsPageDocument,
                            data: {
                                calendarsV2: {
                                    __typename: getCalendarsData.calendarsV2.__typename,
                                    results: {
                                        ...newCalendars,
                                    },
                                },
                            },
                        });
                    }
                }
            },
        });

    const [updateCalendar, { loading: loadingUpdateCalendar }] =
        useUpdateCalendarForCalendarModalMutation({
            update: (cache, response) => {
                const updatedCalendar = response.data?.updateCalendar?.updated;
                if (response.data?.updateCalendar?.success && updatedCalendar?.id) {
                    const getCalendarsData = cache.readQuery<FetchCalendarsV2ForCalendarsPageQuery>(
                        {
                            query: FetchCalendarsV2ForCalendarsPageDocument,
                        },
                    );
                    const currentCalendars = getCalendarsData?.calendarsV2.results;
                    if (currentCalendars) {
                        currentCalendars.map(calendar =>
                            calendar.id === updatedCalendar.id ? updatedCalendar : calendar,
                        );
                        cache.writeQuery<FetchCalendarsV2ForCalendarsPageQuery>({
                            query: FetchCalendarsV2ForCalendarsPageDocument,
                            data: {
                                calendarsV2: {
                                    __typename: getCalendarsData.calendarsV2.__typename,
                                    results: {
                                        ...currentCalendars,
                                    },
                                },
                            },
                        });
                    }
                }
            },
        });

    const onSubmit = (values: CalendarFormInput) => {
        const input: CalendarInput = {
            ...values,
            startDate: new Date(values.startDate.setUTCHours(12, 0, 0, 0)),
            endDate: new Date(values.endDate.setUTCHours(12, 0, 0, 0)),
            offDays: values.offDays?.map(day => new Date(day.day.setUTCHours(12, 0, 0, 0))),
            startTime: new Date(
                values.startDate.setHours(
                    values.startTime.getHours(),
                    values.startTime.getMinutes(),
                ),
            ),
            endTime: new Date(
                values.startDate.setHours(values.endTime.getHours(), values.endTime.getMinutes()),
            ),
        };
        if (id) {
            updateCalendar({
                variables: {
                    input: {
                        id,
                        ...input,
                    },
                },
            });
        } else {
            createCalendar({
                variables: {
                    input: {
                        ...input,
                    },
                },
            });
        }
        setOpen(false);
    };

    if (loadingCreateCalendar) {
        return <Loading />;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitleWithClose id="calendar-form-title" onClose={() => setOpen(false)}>
                {id ? 'Edit' : 'Add'} Calendar Modal
            </DialogTitleWithClose>
            <DialogContent>
                <TextField
                    variant="outlined"
                    label="Name"
                    type="text"
                    margin="dense"
                    fullWidth
                    {...register('name')}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                />
                <Grid container xs={12}>
                    <Grid item md={6} xs={12}>
                        <Controller
                            name="startDate"
                            control={control}
                            render={({ field }) => (
                                <DatePicker label="Start date" format="MM/dd/yyyy" {...field} />
                            )}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Controller
                            name="endDate"
                            control={control}
                            render={({ field }) => (
                                <DatePicker label="End date" format="MM/dd/yyyy" {...field} />
                            )}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Controller
                            name="startTime"
                            control={control}
                            render={({ field }) => (
                                <TimePicker label="Start of work day" {...field} />
                            )}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Controller
                            name="endTime"
                            control={control}
                            render={({ field }) => (
                                <TimePicker label="End of work day" {...field} />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    {offDays.map((field, index) => (
                        <Grid
                            item
                            key={field.id}
                            xs={12}
                            md={4}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <Controller
                                name={`offDays.${index}.day`}
                                control={control}
                                render={({ field: date }) => (
                                    <DatePicker
                                        label="Holiday/No work"
                                        format="MM/dd/yyyy"
                                        minDate={getValues('startDate')}
                                        maxDate={getValues('endDate')}
                                        shouldDisableDate={(date: Date) =>
                                            getValues('offDays')?.find(
                                                (offDay, idx) =>
                                                    offDay.day.toDateString() ===
                                                        date.toDateString() && idx !== index,
                                            ) != null
                                        }
                                        {...date}
                                    />
                                )}
                            />
                            <IconButton type="button" onClick={() => remove(index)}>
                                <Delete />
                            </IconButton>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            type="button"
                            onClick={() => append({ day: new Date() })}
                        >
                            add holiday
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    startIcon={<SaveIcon />}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={loadingCreateCalendar || loadingUpdateCalendar}
                >
                    Save
                </Button>
            </DialogActions>
        </form>
    );
};
