/* eslint-disable radix */
import {
    Badge,
    Checkbox,
    IconButton,
    TablePagination,
    Tooltip,
    Typography,
    Link,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { InfoOutlined, PersonOutlined, Warning, Restore } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import MaterialTable, { MaterialTableProps, OrderByCollection } from '@material-table/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import CustomFilter, { IFilterChange } from '~/components/CustomFilter/CustomFilter';
import Loading from '~/components/Loading/Loading';
import { displayBirthday, displayDateWithAbbrTimeZone } from '~/helpers/dateHelpers';
import { flipDirection } from '~/helpers/flipDirection';
import tableIcons from '~/helpers/tableIcons';
import useUserPermissions from '~/hooks/useUserPermissions';
import {
    AlertSeverity,
    FetchMeasurementsForMeasurementsPageQueryQuery,
    FetchMeasurementsForMeasurementsPageQueryQueryVariables,
    MeasurementType,
    OrderByDirectionEnum,
    ReferencePages,
    useFetchMeasurementsForMeasurementsPageQueryQuery,
    UserMeasurementsFilterInput,
    useUpdateMeasurementReviewedMutation,
    useFetchReferenceLinksForMeasurmentsPageQuery,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import { PAGESIZE } from '~/constants';
import { primaryColor } from '~/theme/WildTheme';
import WeightValue from '~/components/WeightValue/WeightValue';
import { FilterModal } from '../FilterModal/FilterModal';
import { MeasurementHistoryModal } from './MeasurementHistoryModal';
import { IMeasurementsOrderChange, MeasurementSortingFields } from './types';

type Measurement = NonNullable<
    NonNullable<
        NonNullable<FetchMeasurementsForMeasurementsPageQueryQuery['currentUser']>['measurementsV2']
    >['results'][0]
>;

type BloodPressureMeasurement = NonNullable<NonNullable<Measurement['value']>['bloodPressure']>;

type MeasurementsProps = {
    setLinks?: any;
};

const useStyles = makeStyles()(_theme => ({
    root: {
        position: 'relative',
        height: '100%',
        '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
            opacity: '0',
        },
    },
    searchChips: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
    },
    horizontalAlign: {
        display: 'flex',
        alignItems: 'center',
    },
    patientLink: {
        display: 'inline-flex',
        paddingTop: '5px',
    },
}));

// corresponds to columns={[]} passed to MaterialUI
const columnIdToSortingFieldMap: Record<number, MeasurementSortingFields> = {
    7: MeasurementSortingFields.takenDate,
    8: MeasurementSortingFields.createdAt,
    10: MeasurementSortingFields.type,
    12: MeasurementSortingFields.inputType,
    13: MeasurementSortingFields.comment,
};

const Measurements: React.FC<MeasurementsProps> = ({ setLinks }) => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [order, setOrder] = useState<IMeasurementsOrderChange>({
        field: MeasurementSortingFields.takenDate,
        direction: OrderByDirectionEnum.Desc,
        isInit: true,
    });
    const [filter, setFilter] = useState<IFilterChange<Measurement> | null>(null);
    const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
    const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);
    const [updatingMeasurementId, setUpdatingMeasurementId] = useState<string>('');
    const [measurementHistoryId, setMeasurementHistoryId] = useState<string>('');
    const [updateMeasurementLoading, setUpdateMeasurementLoading] = useState<boolean>(false);
    const suffix = 'Measurements';
    const filterTestData = localStorage.getItem(`filterTestData${suffix}`) === 'true' ?? false;

    const [updateMeasurement] = useUpdateMeasurementReviewedMutation();

    const filteredTagIds = localStorage
        .getItem(`filteredTags${suffix}`)
        ?.split(',')
        .filter(str => str.length > 1);
    const filteredAdvocate = localStorage.getItem(`filteredAdvocate${suffix}`);

    const fetchMeasurementsForMeasurementsPageQueryVariables: FetchMeasurementsForMeasurementsPageQueryQueryVariables =
        {
            input: {
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                orderBy: {
                    field: order.field,
                    order: order.direction,
                },
                filter: filter as UserMeasurementsFilterInput | null,
                ...(filteredTagIds && {
                    tagIds: filteredTagIds,
                }),
                ...(filteredAdvocate && {
                    assignedTo: filteredAdvocate,
                }),
                filterTestData,
            },
        };

    const {
        data: measurementsData,
        loading: measurementsLoading,
        refetch,
    } = useFetchMeasurementsForMeasurementsPageQueryQuery({
        variables: fetchMeasurementsForMeasurementsPageQueryVariables,
        fetchPolicy: 'network-only',
    });

    const { data: refereneLinksData, loading: refereneLinksLoading } =
        useFetchReferenceLinksForMeasurmentsPageQuery({
            variables: {
                input: {
                    page: ReferencePages.Measurements,
                },
            },
        });

    const getDirection = (order: string): OrderByDirectionEnum =>
        order.toUpperCase() === OrderByDirectionEnum.Asc
            ? OrderByDirectionEnum.Asc
            : OrderByDirectionEnum.Desc;

    const changeDirection = (previousDirection: OrderByDirectionEnum): void => {
        setOrder(state => ({
            ...state,
            direction: flipDirection(previousDirection),
            isInit: false,
        }));
    };

    useEffect(() => {
        if (refereneLinksData && setLinks) {
            setLinks(refereneLinksData.getReferenceLinksForPage);
        }
    }, [refereneLinksData, setLinks]);

    const handleOrderChange = (orderByCollection: OrderByCollection[]) => {
        // material table passes empty array some times, which causes undefined error
        if (!orderByCollection || orderByCollection.length === 0) {
            return;
        }
        const orderBy = orderByCollection[0];
        const field = columnIdToSortingFieldMap[orderBy.orderBy];
        if (field) {
            if (field !== order.field) {
                setOrder(state => ({
                    ...state,
                    isInit: true,
                }));
            }
            const direction = order.isInit ? getDirection(orderBy.orderDirection) : order.direction;
            changeDirection(direction);
            setOrder(state => ({
                ...state,
                field,
            }));
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const getHeaderStyle = (field: MeasurementSortingFields): React.CSSProperties => ({
        fontWeight: order.field === field ? 'bold' : 'initial',
    });

    const checkMeasurmentItem = useCallback(
        async (reviewed: boolean, id: string) => {
            setUpdatingMeasurementId(id);
            setUpdateMeasurementLoading(true);
            await updateMeasurement({
                variables: {
                    input: {
                        id,
                        reviewed: !reviewed,
                    },
                },
            });
            await refetch();
            setUpdateMeasurementLoading(false);
        },
        [refetch, updateMeasurement],
    );

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Measurements"
                isLoading={measurementsLoading || refereneLinksLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                actions={[
                    {
                        tooltip: 'Filter Measurements',
                        onClick: () => {
                            setOpenFilterModal(true);
                        },
                        icon: () => (
                            <Badge
                                invisible={
                                    parseInt(
                                        localStorage.getItem(`filtersAppliedCount${suffix}`) ?? '',
                                        10,
                                    ) === 0
                                }
                                badgeContent={localStorage.getItem(`filtersAppliedCount${suffix}`)}
                                color="error"
                                style={{
                                    padding: '12px',
                                    backgroundColor: primaryColor,
                                    color: 'white',
                                    fontSize: '1rem',
                                    borderRadius: '1rem',
                                }}
                            >
                                Filter Measurements
                            </Badge>
                        ),
                        isFreeAction: true,
                    },
                    {
                        onClick: event => event.preventDefault(),
                        icon: () => (
                            <CustomFilter<Measurement>
                                setFilter={setFilter}
                                fields={['externalId', 'takenDate', 'type', 'inputType', 'comment']}
                            />
                        ),
                        isFreeAction: true,
                    },
                ]}
                columns={[
                    {
                        title: 'Reviewed',
                        field: 'reviewed',
                        align: 'center',
                        sorting: false,
                        render: ({ reviewed, id }) =>
                            updatingMeasurementId === id && updateMeasurementLoading ? (
                                <Loading height={50} />
                            ) : (
                                <Checkbox
                                    checked={reviewed ?? false}
                                    disabled={!pagePermissions?.Measurements.Edit}
                                    onChange={() => checkMeasurmentItem(reviewed, id)}
                                />
                            ),
                    },
                    {
                        title: 'Id',
                        field: 'id',
                        align: 'center',
                        sorting: false,
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Measurement Id Copied to Clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'Patient Id',
                        field: 'patient.id',
                        align: 'center',
                        sorting: false,
                        render: (
                            { patient }: any, // TODO: check this any
                        ) => (
                            <Tooltip title="View Patient">
                                <Link
                                    target="_blank"
                                    variant="inherit"
                                    color="inherit"
                                    href={`/portal/patients/${patient?.id}/patient-details`}
                                    rel="noopener noreferrer"
                                    className={classes.patientLink}
                                >
                                    <PersonOutlined />
                                </Link>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'External Id',
                        field: 'externalId',
                        align: 'center',
                        sorting: false,
                        render: ({ patient }) => {
                            if (patient?.externalId) {
                                return (
                                    <Tooltip title={patient?.externalId ?? ''}>
                                        <IconButton
                                            onClick={() => {
                                                copy(`${patient?.externalId}`);
                                                TriggerGlobalAlert({
                                                    message: 'External Id Copied to Clipboard',
                                                    severity: AlertSeverity.Success,
                                                });
                                            }}
                                            size="large"
                                        >
                                            <InfoOutlined />
                                        </IconButton>
                                    </Tooltip>
                                );
                            }
                            return '';
                        },
                    },
                    { title: 'First', field: 'patient.firstName', sorting: false },
                    { title: 'Last', field: 'patient.lastName', sorting: false },
                    {
                        title: 'DOB',
                        field: 'patient.birthDate',
                        render: ({ patient }) => {
                            return (
                                <Typography>
                                    {patient?.birthDate &&
                                        displayBirthday({ isoDateStr: patient?.birthDate })}
                                </Typography>
                            );
                        },
                        sorting: false,
                    },
                    {
                        title: 'Date of Test',
                        headerStyle: getHeaderStyle(MeasurementSortingFields.takenDate),
                        render: ({ takenDate, patient, timezoneName }) => (
                            <Typography>
                                {displayDateWithAbbrTimeZone({
                                    isoDateStr: takenDate,
                                    format: 'MM/DD/yyyy, hh:mm:ss A',
                                    timeZone:
                                        timezoneName ??
                                        patient?.practice.timezone ??
                                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                                })}
                            </Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.takenDate).getTime() - new Date(b.takenDate).getTime(),
                    },
                    {
                        title: 'Date of Report',
                        headerStyle: getHeaderStyle(MeasurementSortingFields.createdAt),
                        render: ({ updatedAt }) => (
                            <Typography>
                                {displayDateWithAbbrTimeZone({
                                    isoDateStr: updatedAt,
                                    format: 'MM/DD/yyyy, hh:mm:ss A',
                                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                })}
                            </Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
                    },
                    {
                        title: 'Flagged',
                        render: ({ flagged }) => {
                            if (flagged) {
                                return <Warning />;
                            }
                            return <div />;
                        },
                        sorting: false,
                    },
                    {
                        title: 'Type',
                        field: 'type',
                        headerStyle: getHeaderStyle(MeasurementSortingFields.type),
                    },
                    { title: 'Device', field: 'device.localizedName', sorting: false },
                    {
                        title: 'Input Type',
                        field: 'inputType',
                        headerStyle: getHeaderStyle(MeasurementSortingFields.inputType),
                    },
                    {
                        title: 'Comment',
                        field: 'comment',
                        headerStyle: getHeaderStyle(MeasurementSortingFields.comment),
                    },
                    {
                        title: 'Value',
                        sorting: false,
                        render: ({ type, value, externalId, hasHistory }) => {
                            const measurementBlock = () => {
                                switch (type) {
                                    case MeasurementType.Movement:
                                        return (
                                            <Typography>
                                                {' '}
                                                {value?.movement?.duration} (hr:min:sec)
                                            </Typography>
                                        );
                                    case MeasurementType.BloodPressure:
                                        if (value?.bloodPressure) {
                                            const { systolic, diastolic, pulse } =
                                                value.bloodPressure as BloodPressureMeasurement;
                                            return (
                                                <Typography>
                                                    {systolic &&
                                                        diastolic &&
                                                        `${systolic}/${diastolic} mmHg`}
                                                    {systolic && diastolic && pulse && <br />}
                                                    {pulse && `${pulse} bpm`}
                                                </Typography>
                                            );
                                        }
                                        break;
                                    case MeasurementType.Weight: {
                                        if (value?.weight?.measure) {
                                            return (
                                                <WeightValue
                                                    value={value.weight.measure}
                                                    showUnit
                                                />
                                            );
                                        }
                                        return <div />;
                                    }
                                    case MeasurementType.BloodGlucose: {
                                        if (value?.bloodGlucose?.glucose) {
                                            return (
                                                <Typography>
                                                    {' '}
                                                    {`${value?.bloodGlucose?.glucose} mg/dL`}
                                                </Typography>
                                            );
                                        }
                                        return <div />;
                                    }
                                    case MeasurementType.DailyMeasurement: {
                                        const dailyMeasurement = `Glucose: ${value?.dailyMeasurement?.glucose}, Mood Level:
                                                ${value?.dailyMeasurement?.moodLevel}, Pain Level:
                                                ${value?.dailyMeasurement?.painLevel}, Steps:
                                                ${value?.dailyMeasurement?.steps}, Temperature:
                                                ${value?.dailyMeasurement?.temperature} C, Start of
                                                sleep: ${value?.dailyMeasurement?.sleepStarts}, End of
                                                sleep: ${value?.dailyMeasurement?.sleepEnds}, Notes:
                                                ${value?.dailyMeasurement?.notes}`;
                                        return (
                                            <Typography>
                                                <Tooltip title={dailyMeasurement ?? ''}>
                                                    <IconButton size="large">
                                                        <InfoOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            </Typography>
                                        );
                                    }
                                    default:
                                        return <div />;
                                }
                                return <div />;
                            };
                            return (
                                <div className={classes.horizontalAlign}>
                                    {measurementBlock()}
                                    {hasHistory && (
                                        <Tooltip title="History">
                                            <IconButton
                                                onClick={() => {
                                                    setMeasurementHistoryId(externalId);
                                                    setOpenHistoryModal(true);
                                                }}
                                            >
                                                <Restore />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            );
                        },
                    },
                    { title: '', field: 'patient.id', hidden: true },
                ]}
                data={measurementsData?.currentUser?.measurementsV2.results ?? []}
                onOrderCollectionChange={handleOrderChange}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={measurementsData?.currentUser?.measurementsV2.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    search: false,
                    exportAllData: pagePermissions?.Measurements.Export,
                    grouping: true,
                    paginationPosition: 'both',
                }}
            />
            <FilterModal
                suffix={suffix}
                open={openFilterModal}
                onClose={() => setOpenFilterModal(false)}
            />
            <MeasurementHistoryModal
                open={openHistoryModal}
                onClose={() => setOpenHistoryModal(false)}
                id={measurementHistoryId}
            />
        </div>
    );
};

export default Measurements;
