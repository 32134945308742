import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { Dispatch, SetStateAction } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { ProblemComment } from '~/schemaTypes';

const useStyles = makeStyles()({
    comment: {
        padding: '2px 0',
        borderBottom: '1px solid lightgray',
    },
});

type MedicationModalProps = {
    setShow: Dispatch<SetStateAction<boolean>>;
    comments: ProblemComment[];
};

const CommentsModal: React.FC<MedicationModalProps> = ({ setShow, comments }) => {
    const { classes } = useStyles();

    return (
        <Grid>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setShow(false)}>
                Comments
            </DialogTitleWithClose>

            <DialogContent>
                {comments.map(comment => (
                    <Grid className={classes.comment}>{comment.text}</Grid>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShow(false)} color="secondary" variant="outlined">
                    Close
                </Button>
            </DialogActions>
        </Grid>
    );
};

export default CommentsModal;
