import React from 'react';
import { useParams } from 'react-router-dom';
import InvoiceView from '~/views/AffiliateCare/Invoices/Invoice/Invoice';

const Invoice = () => {
    const { affiliateId } = useParams();
    const patientPathname = `/app-config/vcaffiliates/patients/${affiliateId}/patient`;
    const invoicesPathname = `/app-config/vcaffiliates/invoices/${affiliateId}`;
    return (
        <InvoiceView patientRedirectUrl={patientPathname} invoicesRedirectUrl={invoicesPathname} />
    );
};

export default Invoice;
