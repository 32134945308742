import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    root: {},
    deleteIcon: {
        minWidth: 'auto',
        cursor: 'pointer',
        marginRight: '20px',
    },
    editIcon: {
        minWidth: 'auto',
        cursor: 'pointer',
        marginRight: '20px',
        fontSize: '18px',
    },
    tagBlock: {
        marginLeft: '20px',
    },
    itemBlock: {
        display: 'flex',
        fontSize: '16px',
    },
});
