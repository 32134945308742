import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { addSpacesBetweenCapitalsHelper } from '~/helpers/addSpaceBetweenCapitalLetters';
import { ContentIcon } from '~/schemaTypes';

export interface SelectIconParms {
    selectedIcon: ContentIcon;
    setSelectedIcon: (icon: ContentIcon) => void;
    onEdit: () => void;
}

const ContentIconSelect: React.FC<SelectIconParms> = parms => {
    const { selectedIcon, setSelectedIcon, onEdit } = parms;
    return (
        <OutlinedSection title="Icon">
            <Autocomplete
                size="small"
                value={selectedIcon}
                onChange={(_, val) => {
                    setSelectedIcon(val as ContentIcon);
                    onEdit();
                }}
                getOptionLabel={icon => {
                    return addSpacesBetweenCapitalsHelper(icon);
                }}
                options={Object.values(ContentIcon)}
                // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={params => <TextField variant="outlined" {...params} />}
            />
        </OutlinedSection>
    );
};
export default ContentIconSelect;
