import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import SortableList, { SortableListOnChangeProps } from './components/SortableList';

const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
const ReorderModal: React.FC<{
    divisionId?: string;
    sectionId?: string;
    title: string;
    items: { id: number; name: string }[];
    closeHandler: () => void;
    submitHandler: (
        items: { id: number; name: string }[],
        divisionId?: string,
        sectionId?: string,
    ) => void;
}> = props => {
    const { divisionId, sectionId, items, title, closeHandler, submitHandler } = props;
    const [reorderedItems, setReorderedItems] = useState(items);

    const onSave = () => {
        submitHandler(reorderedItems, divisionId, sectionId);
    };
    const handleOrderChange = ({ startIndex, endIndex }: SortableListOnChangeProps) => {
        const newItems = reorder(reorderedItems, startIndex, endIndex);
        setReorderedItems(newItems);
    };
    return (
        <Dialog open>
            <DialogTitleWithClose id="modalTitle" onClose={closeHandler}>
                {title}
            </DialogTitleWithClose>
            <DialogContent>
                <SortableList
                    list={reorderedItems}
                    droppableId="itemOrder"
                    onChange={handleOrderChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeHandler} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button type="submit" color="secondary" variant="contained" onClick={onSave}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export {};
export default ReorderModal;
