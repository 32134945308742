/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, FormControlLabel, MenuItem, TextField, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DatePicker } from '@mui/x-date-pickers';
import _ from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TimeField from 'react-simple-timefield';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import { deriveNestedPropertyPath } from '../../../helpers/stringHelpers';
import { FormBuilderFormField } from '../FormBuilder';

const useStyles = makeStyles()({
    checkbox: {
        padding: 10,
        marginLeft: 2,
    },
});

// Supported form types
export type FormBuilderInputType =
    | 'string'
    | 'textarea'
    | 'number'
    | 'boolean'
    | 'object'
    | 'time'
    | 'selector'
    | 'none'
    | 'date';

type FormInputProps = {
    field: FormBuilderFormField;
};

const FormInput = ({
    field: {
        name,
        label,
        type,
        options = {},
        defaultValue,
        showSeconds,
        values: selectorValues,
        onChangeHandler,
        date,
        watch: watchField,
        valueAsNumber: valueAsNumberProp,
    },
}: FormInputProps): ReactElement | null => {
    const { classes } = useStyles();
    const [hidden, setHidden] = useState<boolean>(false);
    // eslint-disable-next-line prettier/prettier
    const [stateSelectorValues, setStateSelectorValues] = useState<Array<any> | undefined>(
        undefined,
    );
    const {
        control,
        register,
        unregister,
        watch,

        formState: { errors },
    } = useFormContext();
    const values = selectorValues || stateSelectorValues || undefined;
    let htmlInputType = type === 'string' || type === 'time' ? 'text' : type;
    if (values) {
        htmlInputType =
            type === 'selector' && (typeof values[0].value).toString().toLowerCase() === 'string'
                ? 'text'
                : (typeof values[0].value).toString().toLowerCase();
    }
    const { required, disabled = false } = options;
    const htmlShowSeconds = showSeconds === null ? false : showSeconds;
    const error = _.get(errors, name, undefined);

    const deriveWatchedFieldName = (field: string): string => {
        if (field.includes('&') && name) {
            return deriveNestedPropertyPath(field, name);
        }
        return field;
    };

    let watchResult: any;
    if (watchField?.field) {
        watchResult = watch(deriveWatchedFieldName(watchField?.field));
    }

    useEffect(() => {
        const hideInput = () => {
            setHidden(true);
            unregister(name);
        };
        const showInput = () => {
            setHidden(false);
        };
        if (watchField?.handler) {
            watchField?.handler({
                value: watchResult,
                hide: hideInput,
                show: showInput,
                setSelectorValues: v => setStateSelectorValues(v),
            });
        }
    }, [name, unregister, watchField, watchResult]);

    if (hidden) {
        return null;
    }

    const valueAsNumber = valueAsNumberProp && type === 'number';
    const { ref, ...rest } = register(name, { required, valueAsNumber });

    switch (type) {
        case 'string':
            return (
                <TextField
                    variant="outlined"
                    {...rest}
                    inputRef={ref}
                    InputLabelProps={{ required }}
                    margin="dense"
                    name={name}
                    label={label}
                    type={htmlInputType}
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...(defaultValue && { defaultValue })}
                    {...(disabled && { disabled })}
                />
            );

        case 'textarea':
            return (
                <TextField
                    variant="outlined"
                    {...rest}
                    inputRef={ref}
                    InputLabelProps={{ required }}
                    margin="dense"
                    name={name}
                    label={label}
                    type={htmlInputType}
                    multiline
                    rows={5}
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...(defaultValue && { defaultValue })}
                    {...(disabled && { disabled })}
                />
            );
        case 'number':
            return (
                <TextField
                    variant="outlined"
                    {...rest}
                    inputRef={ref}
                    InputLabelProps={{ required }}
                    margin="dense"
                    name={name}
                    label={label}
                    type={htmlInputType}
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...(defaultValue && { defaultValue })}
                    {...(disabled && { disabled })}
                />
            );
        case 'boolean':
            return (
                <Controller
                    name={name}
                    control={control}
                    {...(defaultValue && { defaultValue })}
                    {...(disabled && { disabled })}
                    rules={{
                        ...(required && { required: 'required' }),
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <FormControlLabel
                            className={classes.checkbox}
                            control={
                                <Checkbox
                                    onBlur={onBlur}
                                    onChange={e => onChange(e.target.checked)}
                                    checked={value}
                                    inputRef={ref}
                                />
                            }
                            label={label}
                            labelPlacement="end"
                        />
                    )}
                />
            );
        case 'time':
            return (
                <TimeField
                    showSeconds={htmlShowSeconds}
                    value={defaultValue}
                    input={
                        <TextField
                            variant="outlined"
                            {...rest}
                            inputRef={ref}
                            margin="dense"
                            name={name}
                            InputLabelProps={{ required }}
                            label={label}
                            type={htmlInputType}
                            fullWidth
                            error={!!error}
                            helperText={error?.message as string}
                        />
                    }
                />
            );
        case 'selector':
            return (
                <div>
                    {values && (
                        <ReactHookFormSelect
                            {...rest}
                            inputRef={ref}
                            control={control}
                            name={name}
                            variant="outlined"
                            label={`${label} ${required ? '*' : ''}`}
                            margin="dense"
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                            {...(defaultValue && { defaultValue })}
                            {...(disabled && { disabled })}
                        >
                            {values.map(({ label, value }) => (
                                <MenuItem key={`${name}input-${value}`} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                    )}
                </div>
            );
        case 'date':
            return (
                <DatePicker
                    {...rest}
                    inputRef={ref}
                    variant="inline"
                    fullWidth
                    InputLabelProps={{ required }}
                    label={label}
                    onChange={onChangeHandler}
                    mask="mm/dd/yyyy"
                    maxDate={new Date()}
                    disableFuture
                    value={date || new Date()}
                    openTo="year"
                    format="MM/dd/yyyy"
                    error={!!error}
                    helperText={error?.message}
                    {...(defaultValue && { defaultValue })}
                    {...(disabled && { disabled })}
                />
            );
        case 'none':
            return null;
        default:
            return <Typography>Field Type: &quot;{type}&quot; not supported</Typography>;
    }
};

export default FormInput;
