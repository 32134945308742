import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialTable from '@material-table/core';
import { Add } from '@mui/icons-material';
import { Dialog, Typography } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import Loading from '~/components/Loading/Loading';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    FetchCalendarsV2ForCalendarsPageQuery,
    useFetchCalendarsV2ForCalendarsPageQuery,
} from '~/schemaTypes';
import { displayDateLocale } from '~/helpers';
import { CalendarModal } from './CalendarModal/CalendarModal';
import { CalendarFormInput } from './CalendarModal/CalendarModal.formValidation';

export type Calendar = NonNullable<
    FetchCalendarsV2ForCalendarsPageQuery['calendarsV2']
>['results'][0];

export const Calendars = () => {
    const { pagePermissions } = useUserPermissions();
    const tableRef = React.useRef<HTMLDivElement>(null);
    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(20);
    const [showCalendarModal, setShowCalendarModal] = React.useState<boolean>(false);
    const [selectedId, setSelectedId] = React.useState<string>('');
    const [selectedCalendar, setSelectedCalendar] = React.useState<CalendarFormInput | null>(null);

    const { data: calendars, loading } = useFetchCalendarsV2ForCalendarsPageQuery();

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    if (loading) {
        <Loading subtitle="loading calendars" />;
    }

    return (
        <div>
            <MaterialTable<Calendar>
                title="Calendars"
                icons={tableIcons}
                isLoading={loading}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                data={_.cloneDeep(calendars?.calendarsV2.results) ?? []}
                actions={[
                    {
                        onClick: () => {
                            setSelectedId('');
                            setSelectedCalendar(null);
                            setShowCalendarModal(true);
                        },
                        hidden: !pagePermissions?.Calendars.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Calendar',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, calendar: Calendar) => {
                            setSelectedId(calendar.id);
                            setSelectedCalendar({
                                ...calendar,
                                offDays: calendar.offDays ?? [],
                            });
                            setShowCalendarModal(true);
                        },
                        hidden: !pagePermissions?.Calendars.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Calendar',
                    },
                ]}
                columns={[
                    {
                        title: 'Name',
                        field: 'name',
                        defaultSort: 'asc',
                        render: ({ name }) => <Typography>{name}</Typography>,
                    },
                    {
                        title: 'Start Date',
                        field: 'startDate',
                        render: ({ startDate }) =>
                            displayDateLocale({ isoDateStr: startDate, format: 'MMM DD YYYY' }),
                    },
                    {
                        title: 'End Date',
                        field: 'endDate',
                        render: ({ endDate }) =>
                            displayDateLocale({ isoDateStr: endDate, format: 'MMM DD YYYY' }),
                    },
                ]}
                options={{ pageSize: rowsPerPage }}
            />
            <Dialog scroll="body" open={showCalendarModal} fullWidth maxWidth="lg">
                <CalendarModal
                    setOpen={setShowCalendarModal}
                    id={selectedId}
                    defaultCalendarValues={selectedCalendar}
                />
            </Dialog>
        </div>
    );
};
