import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Divider, Slide, TextField, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import * as Yup from 'yup';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import { useQueryParams } from '~/hooks';
import {
    AlertSeverity,
    useLoginUserForResetPasswordPageMutation,
    useResetPasswordLoginPageMutation,
} from '~/schemaTypes';
import { PersistAuthTokens, SuppressNextGlobalAlert, TriggerGlobalAlert } from '~/state';
import { logout } from '~/state/auth/auth.svc';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import { PASSWORD_REGEX } from '../../helpers/regularExpressions';
import { ResetPasswordFormInput } from './types';

const RESET_PASSWORD_FORM_VALIDATION_SCHEMA = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Email is Required'),
    newPassword: Yup.string()
        .required('Password is required')
        .matches(
            PASSWORD_REGEX,
            'Password must contain at least 8 characters, one uppercase, one number and one special case character',
        ),
    confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), undefined], `Passwords don't match`)
        .required('Password confirm is required'),
});

const useStyles = makeStyles()(theme => ({
    root: {
        ...theme.wildflowerBackgroundContainer,
    },
    loginCard: {
        padding: 0,
        width: 400,
        '& .title': {
            padding: 20,
            borderBottom: '1px solid lightgray',
        },
        '& .inputCont': {
            padding: 20,
            minHeight: 200,
        },
        '& .bottomControl': {
            borderTop: '1px solid lightgray',
            width: '100%',
            padding: 20,
            '& > button': {
                '&:not(:last-child)': {
                    marginRight: 10,
                },
            },
        },
        ' & .credit': {
            position: 'absolute',
            bottom: 5,
            left: 5,
            fontSize: '75%',
            fontWeight: 'bold',
            color: 'lightGrey',
        },
    },
}));

const ResetPassword: React.FC = () => {
    const { classes } = useStyles();

    const [query] = useQueryParams();
    const passwordToken = query.get('token');
    const prefilledEmail = query.get('email') ?? '';
    const history = useNavigate();
    const [loginLoading, setLoginLoading] = React.useState(false);
    const [loginFailed, setLoginFailed] = React.useState(false);

    const {
        watch,
        register: resetPasswordFormRegister,
        handleSubmit: resetPasswordFormHandleSubmit,
        formState: { errors: resetPasswordFormErrors },
    } = useForm<ResetPasswordFormInput>({
        resolver: yupResolver(RESET_PASSWORD_FORM_VALIDATION_SCHEMA as any),
        defaultValues: {
            email: prefilledEmail,
        },
    });

    const [sendResetPasswordEmail, { loading: resetPasswordEmailLoading }] =
        useResetPasswordLoginPageMutation({});

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [newPassword, email] = watch(['newPassword', 'email']);
    const [loginUser, { loading: resetPasswordLoading }] = useLoginUserForResetPasswordPageMutation(
        {
            onCompleted: async data => {
                if (data.loginUser?.success && data.loginUser?.tokens) {
                    PersistAuthTokens(data.loginUser.tokens);
                    history('/');
                    window.location.reload();
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Success,
                        message: 'Successfully reset password, please login.',
                    });
                } else {
                    setLoginLoading(false);
                }
            },
            onError: error => {
                setLoginLoading(false);
                setLoginFailed(true);
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: error.message,
                });
            },
        },
    );
    const loading = resetPasswordLoading || loginLoading;
    React.useEffect(() => {
        logout(false);
    }, []);

    const handleResetPasswordFormSubmit = ({
        email,
        newPassword,
        confirmNewPassword,
    }: ResetPasswordFormInput) => {
        if (newPassword !== confirmNewPassword) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `Passwords don't match.`,
            });
            return;
        }
        setLoginLoading(true);
        loginUser({
            variables: {
                input: {
                    email,
                    newPassword,
                    passwordToken,
                },
            },
        });
        SuppressNextGlobalAlert(true);
    };

    const handleResendResetPasswordEmail = async () => {
        const emailToCheck = email || prefilledEmail;
        try {
            sendResetPasswordEmail({
                variables: {
                    input: {
                        email: emailToCheck,
                    },
                },
            });
        } catch (e: any) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `Unable to reset password: ${e.message}`,
            });
        }
    };

    return (
        <div className={classes.root}>
            <Card className={classes.loginCard}>
                <Typography variant="h6" className="title">
                    Reset Password
                </Typography>
                <Divider />
                <Slide in direction="right">
                    <form onSubmit={resetPasswordFormHandleSubmit(handleResetPasswordFormSubmit)}>
                        <div className="inputCont">
                            <TextField
                                variant="outlined"
                                label="Email"
                                type="email"
                                fullWidth
                                error={!!resetPasswordFormErrors.email}
                                helperText={resetPasswordFormErrors.email?.message}
                                disabled={!!prefilledEmail}
                                {...resetPasswordFormRegister('email')}
                            />
                            <PasswordInput
                                error={!!resetPasswordFormErrors.newPassword}
                                helperText={
                                    resetPasswordFormErrors.newPassword?.message ??
                                    'Passwords must be a minimum of 8 characters, and must contain at least one uppercase character, one lowercase character, one symbol, and one number.'
                                }
                                register={resetPasswordFormRegister}
                                name="newPassword"
                                label="New Password"
                            />
                            <PasswordInput
                                error={!!resetPasswordFormErrors.confirmNewPassword}
                                register={resetPasswordFormRegister}
                                name="confirmNewPassword"
                                label="Confirm Password"
                                helperText={resetPasswordFormErrors.confirmNewPassword?.message}
                                inputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        <div
                            className="bottomControl flex justify-between gap-4 w-full"
                            style={{
                                ...(loginFailed
                                    ? { flexDirection: 'row' }
                                    : { flexDirection: 'row-reverse' }),
                            }}
                        >
                            {loginFailed && (
                                <AsyncActionButton loading={resetPasswordEmailLoading}>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        disabled={resetPasswordEmailLoading}
                                        onClick={handleResendResetPasswordEmail}
                                    >
                                        Resend Email
                                    </Button>
                                </AsyncActionButton>
                            )}
                            <AsyncActionButton loading={loading}>
                                <Button
                                    disabled={loading}
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                >
                                    Reset Password
                                </Button>
                            </AsyncActionButton>
                        </div>
                    </form>
                </Slide>
            </Card>
        </div>
    );
};

export default ResetPassword;
