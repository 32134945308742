// This component has a UI Test
import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Typography } from '@mui/material';
import { Add, InfoOutlined } from '@mui/icons-material';
import { format } from 'date-fns';
import _ from 'lodash';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import { PAGESIZE, ROOT_ORG_ID } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    FetchRolesForRolesPageQueryDocument,
    FetchRolesForRolesPageQueryQuery,
    Permission,
    useDeleteRoleMutation,
    useFetchRolesForRolesPageQueryQuery,
    useOrgListQuery,
    useRoleWhereUsedLazyQuery,
} from '~/schemaTypes';
import { RoleEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';
import RoleModal from './RoleModal/RoleModal';

type Role = NonNullable<FetchRolesForRolesPageQueryQuery['roles'][0]>;

export default function Roles(): ReactElement {
    const [showRoleModal, setShowRoleModal] = useState(false);
    const [showUsageModal, setShowUsageModal] = useState(false);
    const [editRoleId, setEditRoleId] = useState<string>('');
    const [roleName, setRoleName] = useState<string>('');
    const { pagePermissions } = useUserPermissions();
    const [selectedOrgId, setSelectedOrgId] = useState(ROOT_ORG_ID);
    const [roleData, setRoleData] = useState<Role[]>();
    const handleOrgChange = (event: any) => {
        setSelectedOrgId(event.target.value === '' ? null : event.target.value);
    };
    const { data, loading: rolesLoading } = useFetchRolesForRolesPageQueryQuery();
    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        useRoleWhereUsedLazyQuery();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    useEffect(() => {
        setRoleData(_.cloneDeep(data?.roles.filter(r => r.organizationId === selectedOrgId) ?? []));
    }, [selectedOrgId, setRoleData, data?.roles]);
    const { data: orgData, loading: orgsLoading } = useOrgListQuery();
    const handleShowUsage = (id: string) => {
        whereUsed({ variables: { roleId: id } });
        setEditRoleId(id);
        setShowUsageModal(true);
    };
    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const [deleteRole] = useDeleteRoleMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: FetchRolesForRolesPageQueryDocument,
                variables: {},
            },
        ],
    });
    const handleDelete = () => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete ${roleName}?`,
            callback: () => {
                deleteRole({ variables: { input: editRoleId } });
            },
        });
        setShowUsageModal(false);
    };
    return (
        <div data-test={RoleEnum.CONTAINER} ref={tableRef}>
            <OutlinedSection title="Select Organization">
                <select onChange={handleOrgChange} data-test={RoleEnum.SELECT_ORGANIZATION}>
                    {orgData &&
                        orgData.organizations
                            .filter(o => o.id === ROOT_ORG_ID || o.parentId === ROOT_ORG_ID)
                            .map(o => (
                                <option id={o.id} value={o.id} selected={o.id === selectedOrgId}>
                                    {o.brandingName}
                                </option>
                            ))}
                    <option value="" id="1">
                        Templates
                    </option>
                </select>
            </OutlinedSection>
            <MaterialTable<Role>
                icons={tableIcons as MaterialTableProps<any>['icons']}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                actions={[
                    {
                        onClick: () => {
                            setEditRoleId('');
                            setShowRoleModal(true);
                        },
                        hidden: !pagePermissions?.Roles.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Role',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_e, role) => {
                            setEditRoleId((role as Role).id);
                            setShowRoleModal(true);
                        },
                        icon: () => (
                            <span>
                                {pagePermissions?.Roles.Edit ? (
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                ) : (
                                    <InfoOutlined />
                                )}
                            </span>
                        ),
                        tooltip: pagePermissions?.Roles.Edit ? 'Edit Role' : 'View Role Details',
                    },
                    {
                        onClick: (_e, role) => {
                            setRoleName((role as Role).name);
                            handleShowUsage((role as Role).id);
                        },
                        icon: () => <FontAwesomeIcon icon={faEye} />,
                        tooltip: 'View References',
                    },
                ]}
                columns={[
                    {
                        title: 'Name',
                        field: 'name',
                        defaultSort: 'asc',
                        render: ({ name }) => <Typography data-test={name}>{name}</Typography>,
                    },
                    {
                        title: 'Date Created',
                        render: ({ createdAt }) => (
                            <Typography>{format(new Date(createdAt), 'MM/dd/yyyy')}</Typography>
                        ),
                    },
                    {
                        title: 'Last Updated',
                        render: ({ updatedAt }) => (
                            <Typography>{format(new Date(updatedAt), 'MM/dd/yyyy')}</Typography>
                        ),
                    },
                    {
                        title: 'Permissions',
                        render: ({ permissions }) =>
                            permissions?.filter(p => p?.permission !== Permission.None).length,
                        sorting: false,
                    },
                ]}
                isLoading={rolesLoading || orgsLoading || whereUsedLoading}
                data={roleData ?? []}
                title="User Roles"
                localization={{ header: { actions: '' } }}
                options={{
                    pageSize: rowsPerPage,
                }}
            />
            <Dialog
                scroll="paper"
                open={showRoleModal}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <RoleModal
                    setOpen={setShowRoleModal}
                    setEditRoleId={setEditRoleId}
                    id={editRoleId}
                    orgId={selectedOrgId === '' ? undefined : selectedOrgId}
                />
            </Dialog>
            <Dialog scroll="paper" open={!whereUsedLoading && showUsageModal}>
                <WhereUsedModal
                    onClose={() => setShowUsageModal(false)}
                    onDelete={handleDelete}
                    title={`Usage for ${roleName}`}
                    canDelete={pagePermissions?.Roles.Delete || false}
                    usageData={whereUsedData?.roleWhereUsed.dependencies || []}
                    permissions={pagePermissions}
                />
            </Dialog>
        </div>
    );
}
