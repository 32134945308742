import React, { useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Grid, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import StateAutocomplete from '~/components/StateAutocomplete/StateAutocomplete';
import { useGetZipCodesByFilterLazyQuery } from '~/schemaTypes';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import { ZipCodesType } from './constants';
import CloseButton from './CloseButton';

type AddByCityAndStateProps = {
    onComplete: (zipCodes: string[], type?: ZipCodesType) => void;
};

const useStyles = makeStyles()(() => ({
    container: {
        minHeight: 120,
        flexWrap: 'nowrap',
        '@media (max-width: 1000px)': {
            flexWrap: 'wrap',
        },
    },
}));

const AddByCityAndState: React.FC<AddByCityAndStateProps> = props => {
    const { onComplete } = props;
    const { classes } = useStyles();
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCodes, setZipCodes] = useState<string[]>([]);
    const { register } = useForm();
    const [getZipCodesByCityState, { loading, data: zipCoedByCityState }] =
        useGetZipCodesByFilterLazyQuery();
    useEffect(() => {
        if (city && state) {
            getZipCodesByCityState({
                variables: {
                    input: {
                        city,
                        state,
                    },
                },
            });
        } else {
            setZipCodes([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [city, state]);
    useEffect(() => {
        setZipCodes(zipCoedByCityState?.getZipCodesByFilter?.zipCodes || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipCoedByCityState]);
    const handleClick = useCallback(() => {
        onComplete(zipCodes, ZipCodesType.CityAndState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipCodes]);
    const handleClose = useCallback(() => {
        onComplete([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <OutlinedSection title="Add Zip Codes By City and State">
            <CloseButton handleClose={handleClose} />
            <Grid
                item
                container
                xs={12}
                alignItems="center"
                columnSpacing={2}
                className={classes.container}
                rowSpacing={2}
            >
                <Grid item sm={5} xs={12}>
                    <TextField
                        label="City"
                        placeholder="Ex. Seattle"
                        value={city}
                        onChange={e => setCity(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={5} xs={12}>
                    <StateAutocomplete
                        name="state"
                        label="State"
                        register={register('state')}
                        onChange={value => setState(value)}
                        value={state}
                    />
                </Grid>
                <Grid item sm="auto" xs={12}>
                    <AsyncActionButton loading={loading}>
                        <Button
                            disabled={loading || !zipCodes.length}
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                            size="large"
                        >
                            Add {zipCodes.length} Zip Codes
                        </Button>
                    </AsyncActionButton>
                </Grid>
            </Grid>
        </OutlinedSection>
    );
};

export default AddByCityAndState;
