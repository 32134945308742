import {
    Button,
    Card,
    Dialog,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
    CalendarTodayOutlined,
    CheckCircleOutlined,
    ErrorOutline,
    InfoOutlined,
    OpenInNewOutlined,
    Warning,
} from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import { format, toDate } from 'date-fns-tz';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import Loading from '~/components/Loading/Loading';
import { displayDate } from '~/helpers/dateHelpers';
import {
    AlertSeverity,
    FetchPatientsForPatientsPageQueryQuery,
    OrderByDirectionEnum,
    useFetchPatientForPatientOverviewPageQuery,
    useResendPatientInviteOnPatientsPageMutation,
    useResetPatientPasswordOnPatientsPageMutation,
} from '~/schemaTypes';
import { PatientEnum } from '~/selectors';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';

import { useUserPermissions } from '~/hooks';
import PatientSummaryModal from './PatientSummaryModal';

type PatientRiskIndicators = NonNullable<
    NonNullable<
        NonNullable<FetchPatientsForPatientsPageQueryQuery['currentUser']>['patientsV2']
    >['results'][0]
>['riskIndicators'];

type PatientRiskIndicator = NonNullable<
    NonNullable<
        NonNullable<
            NonNullable<FetchPatientsForPatientsPageQueryQuery['currentUser']>['patientsV2']
        >['results'][0]
    >['riskIndicators']
>['obesity'];

type RouteParams = {
    id: string;
};

const useStyles = makeStyles()({
    root: {},
});

const PatientOverview: React.FC = () => {
    const { id } = useParams<RouteParams>();
    const { classes } = useStyles();
    const history = useNavigate();
    const { pagePermissions } = useUserPermissions();
    const { data: patientData, loading: patientLoading } =
        useFetchPatientForPatientOverviewPageQuery({
            variables: {
                patientInput: { id },
                appointmentInput: {
                    excludePatientCreated: true,
                    orderBy: { field: 'startDate', order: OrderByDirectionEnum.Desc },
                },
            },
        });

    const [showPatientSummaryModal, setShowPatientSummaryModal] = useState(false);

    const [resendPatientInvite, { loading: patientInviteLoading }] =
        useResendPatientInviteOnPatientsPageMutation();

    const [resetPatientPassword, { loading: patientResetPasswordLoading }] =
        useResetPatientPasswordOnPatientsPageMutation();

    type PatientRiskIndicatorsMap = Omit<PatientRiskIndicators, '__typename'>;

    const handleResend = () => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to resend the invite?`,
            callback: () => {
                resendPatientInvite({
                    variables: {
                        input: {
                            id,
                        },
                    },
                });
            },
        });
    };

    const handleResetPassword = () => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to reset the password?`,
            callback: () => {
                resetPatientPassword({
                    variables: {
                        input: {
                            id,
                        },
                    },
                });
            },
        });
    };

    const displayNames: Record<keyof PatientRiskIndicatorsMap, string> = {
        chronicHypertension: 'High Risk Chronic Hypertension',
        obesity: 'High Risk Obesity',
        preterm: 'High Risk Previous Preterm',
        other: 'Other Risk (see comments for details)',
    };

    const risks = Object.keys(_.omit(patientData?.patient?.riskIndicators, '__typename')).map(
        key =>
            ({
                ...(patientData?.patient?.riskIndicators != null
                    ? (patientData?.patient?.riskIndicators[
                          key as keyof PatientRiskIndicatorsMap
                      ] as PatientRiskIndicator)
                    : null),
                key,
                name: displayNames[key as keyof PatientRiskIndicatorsMap],
            } as PatientRiskIndicator & {
                key: string;
                name: string;
            }),
    );
    const flaggedRisks = risks.filter(({ flag }) => flag);

    if (patientLoading) {
        return <Loading />;
    }

    return (
        <Grid className={classes.root} container spacing={2}>
            <Grid item xs={12}>
                <Card style={{ marginBottom: 0 }}>
                    <Grid container spacing={2} alignItems="flex-start">
                        <Grid item xs={4}>
                            <Typography variant="h6">Patient Info</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            container
                            spacing={1}
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            {pagePermissions?.PatientDetails.Export && (
                                <Grid item>
                                    <Tooltip title="Export Patient Data">
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                setShowPatientSummaryModal(true);
                                            }}
                                        >
                                            Download Patient Data
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            )}{' '}
                            <Grid item>
                                <Tooltip title={id}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            copy(`${id}`);
                                            TriggerGlobalAlert({
                                                message: 'Patient Id Copied to Clipboard',
                                                severity: AlertSeverity.Success,
                                            });
                                        }}
                                    >
                                        Patient Id
                                    </Button>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={patientData?.patient?.externalId ?? ''}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            copy(`${patientData?.patient?.externalId}`);
                                            TriggerGlobalAlert({
                                                message: 'Patient External Id Copied to Clipboard',
                                                severity: AlertSeverity.Success,
                                            });
                                        }}
                                    >
                                        External Id
                                    </Button>
                                </Tooltip>
                            </Grid>
                            {pagePermissions?.PatientDetails.Edit && (
                                <Grid item>
                                    <AsyncActionButton loading={patientInviteLoading}>
                                        <Button
                                            variant="outlined"
                                            style={{ whiteSpace: 'nowrap' }}
                                            color="primary"
                                            onClick={() => {
                                                handleResend();
                                            }}
                                            disabled={!patientData?.patient?.invitationCode}
                                        >
                                            {' '}
                                            Resend Invite
                                        </Button>
                                    </AsyncActionButton>
                                </Grid>
                            )}
                            {pagePermissions?.PatientDetails.Edit && (
                                <Grid item>
                                    <AsyncActionButton loading={patientResetPasswordLoading}>
                                        <Button
                                            variant="outlined"
                                            style={{ whiteSpace: 'nowrap' }}
                                            color="primary"
                                            onClick={() => {
                                                handleResetPassword();
                                            }}
                                        >
                                            {' '}
                                            Reset Password
                                        </Button>
                                    </AsyncActionButton>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={3}>
                            <Typography color="primary" gutterBottom variant="body1">
                                Full Name:
                            </Typography>
                            <Typography variant="subtitle1">
                                {patientData?.patient?.fullName}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color="primary" gutterBottom variant="body1">
                                Email:
                            </Typography>
                            <Typography variant="subtitle1">
                                {patientData?.patient?.email}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography color="primary" gutterBottom variant="body1">
                                DOB:
                            </Typography>
                            <Typography variant="subtitle1">
                                {patientData?.patient?.birthDate &&
                                    format(
                                        // dates are recieved as UTC ISO strings by default, we need to screen out the timestamp to avoid recalculation
                                        toDate(patientData?.patient?.birthDate.split('T')[0]),
                                        'MM/dd/yyyy',
                                    )}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography color="primary" gutterBottom variant="body1">
                                Practice Name:
                            </Typography>
                            <Typography variant="subtitle1">
                                {patientData?.patient?.practice.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography color="primary" gutterBottom variant="body1">
                                App Registration:
                            </Typography>
                            <Typography variant="subtitle1">
                                {patientData?.patient?.onboardingDate
                                    ? new Date(
                                          patientData?.patient?.onboardingDate,
                                      ).toLocaleDateString()
                                    : 'Not registered'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography color="primary" gutterBottom variant="body1">
                                Risk Status:
                            </Typography>
                            <Grid>
                                <Typography style={{ display: 'flex', alignItems: 'center' }}>
                                    {flaggedRisks.length > 0 && (
                                        <Tooltip
                                            title={
                                                <List>
                                                    {flaggedRisks.map(
                                                        ({ key, name, flag, comment }, i) => (
                                                            <div key={key}>
                                                                <ListItem>
                                                                    <ListItemIcon>
                                                                        {flag ? (
                                                                            <Warning color="error" />
                                                                        ) : (
                                                                            <CheckCircleOutlined color="secondary" />
                                                                        )}
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primaryTypographyProps={{
                                                                            color: flag
                                                                                ? 'error'
                                                                                : 'inherit',
                                                                        }}
                                                                        primary={name}
                                                                        secondary={comment}
                                                                    />
                                                                </ListItem>
                                                                {i !== flaggedRisks.length - 1 && (
                                                                    <Divider />
                                                                )}
                                                            </div>
                                                        ),
                                                    )}
                                                </List>
                                            }
                                        >
                                            {flaggedRisks.length > 0 ? (
                                                <ErrorOutline color="error" />
                                            ) : (
                                                <InfoOutlined color="secondary" />
                                            )}
                                        </Tooltip>
                                    )}
                                    {flaggedRisks.length === 0
                                        ? 'Routine'
                                        : `${flaggedRisks.length} ${
                                              flaggedRisks.length > 1 ? 'Risks' : 'Risk'
                                          }`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography color="primary" gutterBottom variant="body1">
                                ADI:
                            </Typography>
                            <Typography variant="subtitle1">{patientData?.patient?.adi}</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            justifyContent="center"
                            style={{ marginTop: 20 }}
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                component={Link}
                                to={`/portal/patients/${id}/patient-details`}
                            >
                                View Full Patient Info
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card style={{ marginBottom: 0 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} container alignItems="center" spacing={1}>
                            <Grid item>
                                <CalendarTodayOutlined fontSize="large" color="primary" />
                            </Grid>
                            <Grid item>
                                <Typography color="primary" variant="h6" align="center">
                                    Upcoming Appointments
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            justifyContent="center"
                            alignItems="center"
                            style={{ minHeight: 200 }}
                        >
                            {(
                                patientData?.patient?.appointments?.filter(({ startDate }) =>
                                    moment(startDate).isAfter(moment(new Date())),
                                ) ?? []
                            ).length > 0 ? (
                                <Grid item xs={12}>
                                    <List>
                                        {patientData?.patient?.appointments
                                            ?.filter(({ startDate }) =>
                                                moment(startDate).isAfter(moment()),
                                            )
                                            .slice(0, 3)
                                            .map(
                                                ({
                                                    id: appointmentId,
                                                    startDate,
                                                    description,
                                                    practice,
                                                }) => {
                                                    return (
                                                        <ListItem
                                                            key={appointmentId}
                                                            button
                                                            onClick={() => {
                                                                history(
                                                                    `/portal/patients/${id}/appointments?_search=${appointmentId}`,
                                                                );
                                                            }}
                                                        >
                                                            <ListItemText
                                                                primaryTypographyProps={{
                                                                    color: 'primary',
                                                                }}
                                                                primary={`${displayDate({
                                                                    isoDateStr: startDate,
                                                                    format: 'h:mm A MMM DD',

                                                                    displayTimeZone: false,
                                                                    ...(practice?.timezone && {
                                                                        timeZone:
                                                                            practice?.timezone,
                                                                    }),
                                                                })} at ${practice?.name}`}
                                                                secondary={
                                                                    description ||
                                                                    'Description not provided'
                                                                }
                                                                secondaryTypographyProps={{
                                                                    variant: 'body1',
                                                                    noWrap: true,
                                                                }}
                                                            />
                                                            <OpenInNewOutlined color="primary" />
                                                        </ListItem>
                                                    );
                                                },
                                            )}
                                        <ListItem
                                            button
                                            onClick={() => {
                                                history(`/portal/patients/${id}/appointments`);
                                            }}
                                        >
                                            <ListItemText
                                                primary="View all appointments"
                                                primaryTypographyProps={{
                                                    color: 'primary',
                                                    align: 'center',
                                                }}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                            ) : (
                                <Typography variant="subtitle1" align="center">
                                    No upcoming appointments
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Dialog
                        scroll="paper"
                        open={showPatientSummaryModal}
                        fullWidth
                        aria-labelledby="form-dialog-title"
                        data-test={PatientEnum.PATIENT_SUMMARY_MODAL}
                    >
                        <PatientSummaryModal
                            setOpen={setShowPatientSummaryModal}
                            patient={patientData?.patient}
                        />
                    </Dialog>
                </Card>
            </Grid>
        </Grid>
    );
};

export default PatientOverview;
