import { SurveyDefQuestionDisplayType, UserProfileValueType } from '~/schemaTypes';

export enum ContentType {
    Summary = 'summary',
    Content = 'content',
    Text = 'text',
    Question = 'question',
    TutorialContent = 'tutorialContent',
}

export type SurveyContent = {
    type: ContentType;
    divName?: {
        en: string;
        es?: string | null;
    };
    secName?: {
        en: string;
        es?: string | null;
    };
    expressionName?: string;
    content?: {
        articleId: string;
    };
    summary?: {
        title?: {
            en: string;
            es?: string;
        };
        body?: {
            en: string;
            es?: string;
        };
    };
    text?: {
        title?: {
            en: string;
            es?: string;
        };
        body?: {
            en: string;
            es?: string;
        };
    };
    question?: {
        label?: { en: string; es?: string };
        profileDefId: string;
        displayType: SurveyDefQuestionDisplayType;
        valueType: UserProfileValueType;
        required: boolean;
        hidePrevious: boolean;
        choices?: {
            en: string;
            es?: string;
            choiceId: string;
            choiceDisplayType: UserProfileValueType; // currently only supporting string, number, boolean, and Date
        };
    };
    tutorialContent?: {
        videoId: string;
    };
};
export type Response = {
    index: number;
    profileDefId: string;
    value: string;
    profileName: string;
    questionText: string;
};
