import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '@mui/material';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    ArticleCategoryListDocument,
    ArticleCategoryListQuery,
    useArticleCategoryListQuery,
    useArticleCategoryWhereUsedLazyQuery,
    useDeleteArticleCategoryMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import Loading from '~/components/Loading/Loading';
import { ArticleCategoryModal } from './components/ArticleCategoryModal';
import { useStyles } from './styles';

type ArticleCategory = NonNullable<ArticleCategoryListQuery['articleCategorys'][0]>;

const ArticleCategories: React.FC = () => {
    const { classes } = useStyles();
    const { data, loading } = useArticleCategoryListQuery();
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedArticleCategory, setSelectedArticleCategory] = useState<ArticleCategory | null>(
        null,
    );
    const [showUsageModal, setShowUsageModal] = useState(false);
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        useArticleCategoryWhereUsedLazyQuery();
    const [deleteItem, { loading: deleteItemLoading }] = useDeleteArticleCategoryMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: ArticleCategoryListDocument,
                variables: {},
            },
        ],
    });
    const handleDelete = () => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${selectedArticleCategory?.name}'?`,
            callback: () => {
                deleteItem({ variables: { id: selectedArticleCategory?.id } });
                setShowUsageModal(false);
            },
        });
    };
    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleShowUsage = (content: ArticleCategory) => {
        whereUsed({
            variables: {
                id: content?.id,
            },
        });
        setShowUsageModal(true);
    };

    if (loading || whereUsedLoading || deleteItemLoading) {
        return <Loading />;
    }
    return (
        <>
            <div className={classes.root} ref={tableRef}>
                <MaterialTable<ArticleCategory>
                    title="Article Categories"
                    icons={tableIcons}
                    isLoading={loading || whereUsedLoading || deleteItemLoading}
                    data={_.cloneDeep(data?.articleCategorys) ?? []}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    actions={[
                        {
                            onClick: () => setModalOpen(true),
                            icon: () => <Add />,
                            tooltip: 'Add Article Category',
                            isFreeAction: true,
                            hidden: !pagePermissions?.ArticleCategory.Edit,
                        },
                        {
                            onClick: (_, item) => {
                                const isSingleItemSelected = !Array.isArray(item);
                                if (isSingleItemSelected) {
                                    setModalOpen(true);
                                    setSelectedArticleCategory(item);
                                }
                            },
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit Article Category',
                            hidden: !pagePermissions?.ArticleCategory.Edit,
                        },
                        {
                            onClick: (_e, item) => {
                                setSelectedArticleCategory(item as ArticleCategory);
                                handleShowUsage(item as ArticleCategory);
                            },
                            icon: () => <FontAwesomeIcon icon={faEye} />,
                            tooltip: 'View References',
                        },
                    ]}
                    columns={[
                        {
                            title: 'Name',
                            field: 'name',
                        },
                    ]}
                    options={{ pageSize: rowsPerPage }}
                    localization={{ header: { actions: '' } }}
                />
            </div>
            <ArticleCategoryModal
                isOpen={isModalOpen}
                item={selectedArticleCategory || null}
                onClose={() => {
                    setModalOpen(false);
                    setSelectedArticleCategory(null);
                }}
            />
            <Dialog scroll="paper" open={showUsageModal}>
                <WhereUsedModal
                    onClose={() => setShowUsageModal(false)}
                    onDelete={handleDelete}
                    title={`Usage for ${selectedArticleCategory?.name}`}
                    canDelete={pagePermissions?.ArticleCategory.Delete || false}
                    usageData={whereUsedData?.articleCategoryWhereUsed?.dependencies || []}
                    permissions={pagePermissions}
                />
            </Dialog>
        </>
    );
};

export default ArticleCategories;
