import React, { useCallback } from 'react';
import { Grid, DialogContent } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { useUpdateEligibilityMatchMutation } from '~/schemaTypes';
import { useStyles } from '../styles';

type ActionDialogProps = {
    id: string;
    callback: () => void;
};

const ActionDialog: React.FC<ActionDialogProps> = props => {
    const { id, callback } = props;
    const { classes } = useStyles();
    const [removeMatchMutation, { loading }] = useUpdateEligibilityMatchMutation({
        onCompleted: () => callback(),
    });
    const putIntoQueueHandler = useCallback(() => {
        removeMatchMutation({
            variables: {
                input: {
                    data: {
                        hasMatch: false,
                        toBeReviewed: true,
                    },
                    id,
                },
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return (
        <div>
            <DialogTitleWithClose id="form-dialog-title" onClose={callback}>
                Add back into the queue?
            </DialogTitleWithClose>
            <DialogContent>
                <Grid container alignContent="center" rowSpacing={2}>
                    <Grid item xs={12}>
                        <LoadingButton
                            variant="outlined"
                            onClick={putIntoQueueHandler}
                            className={classes.actionButton}
                            loading={loading}
                        >
                            Put back into the match queue
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingButton
                            onClick={callback}
                            variant="outlined"
                            className={classes.actionButton}
                            loading={loading}
                        >
                            Cancel
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </div>
    );
};

export default ActionDialog;
