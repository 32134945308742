import * as Yup from 'yup';
import { yupLocalizedString } from '~/helpers/yupLocalizedString';
import { FIELD_NAMES } from '../enums';

export const UPDATE_TODO_CP_UPCOMING_INPUT = Yup.object().shape({
    id: Yup.string().required('TODO id is required'),
    dueDate: Yup.string().required('dueDate is required'),
    effectiveDate: Yup.string().required('effectiveDate is required'),
    priority: Yup.number().required('priority field is required'),
    isEssential: Yup.bool().required('isEssential field is required'),
});

export const CREATE_TODO_FORM_INPUT = Yup.object().shape({
    [FIELD_NAMES.title]: yupLocalizedString({ required: true }),
    [FIELD_NAMES.description]: Yup.string(),
    [FIELD_NAMES.dueDate]: Yup.string(),
    [FIELD_NAMES.effectiveDateDaysInterval]: Yup.number(),
    [FIELD_NAMES.effectiveDateMonthsInterval]: Yup.number(),
    [FIELD_NAMES.effectiveDateYearsInterval]: Yup.number(),
    [FIELD_NAMES.priority]: Yup.number(),
    [FIELD_NAMES.isEssential]: Yup.bool(),
});
