import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    historyWrapper: {
        marginBottom: 10,
    },
    historySummary: {
        fontWeight: 'bold',
    },
    historyItem: {
        marginLeft: 10,
        marginBottom: 5,
    },
    whatAndWhen: {
        fontWeight: 'lighter',
    },
});
