import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';

export type MessageModalProps = {
    header?: string;
    message: string;
    closeHandler: () => void;
};

const MessageModal = ({ header, message, closeHandler }: MessageModalProps) => {
    return (
        <Dialog open>
            {header && (
                <DialogTitleWithClose id="modalTitle" onClose={closeHandler}>
                    {header}
                </DialogTitleWithClose>
            )}
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button onClick={closeHandler} color="secondary" variant="outlined">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export {};
export default MessageModal;
