import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import {
    ReferenceLinkCategoriesListDocument,
    useCreateReferenceLinkCategoryMutation,
    useUpdateReferenceLinkCategoryMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { ReferenceLinkCategory } from '../ReferenceLinkCategories';

export interface ReferenceLinkCategoryInput {
    name: string;
}

const ValidationScheme = Yup.object().shape({
    name: Yup.string().required('Required'),
});

export const toFormValuesFromItem = (item: ReferenceLinkCategory): ReferenceLinkCategoryInput => ({
    name: item ? item.name : '',
});

export const EditReferenceLinkCategoryModal: React.FC<{
    isOpen: boolean;
    item?: ReferenceLinkCategory;
    onSave: () => void;
    onCancel: () => void;
}> = props => {
    const { isOpen, item, onSave, onCancel } = props;
    const handleClose = () => {
        onCancel();
    };
    const {
        control,
        handleSubmit: handleFormSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ValidationScheme as any),
    });

    useEffect(() => {
        if (item) reset(toFormValuesFromItem(item));
    }, [reset, item]);

    const [createCategory] = useCreateReferenceLinkCategoryMutation({
        onError: error => {
            TriggerGlobalConfirm({
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                callback: () => {},
                message: `There was a problem saving the Reference Link Category: ${error.message}`,
            });
        },
        onCompleted: () => {
            onSave();
        },
        refetchQueries: [{ query: ReferenceLinkCategoriesListDocument }],
    });

    const [updateCategory] = useUpdateReferenceLinkCategoryMutation({
        onError: error => {
            TriggerGlobalConfirm({
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                callback: () => {},
                message: `There was a problem saving the Reference Link Category: ${error.message}`,
            });
        },
        onCompleted: () => {
            onSave();
        },
        refetchQueries: [{ query: ReferenceLinkCategoriesListDocument }],
    });

    const onSubmit: SubmitHandler<ReferenceLinkCategoryInput> = form => {
        if (item) {
            updateCategory({
                variables: {
                    input: {
                        id: item.id,
                        data: {
                            name: form.name,
                        },
                    },
                },
            });
        } else {
            createCategory({
                variables: {
                    input: {
                        name: form.name,
                    },
                },
            });
        }
    };
    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose onClose={handleClose} id="modalTitle">
                <div>{item ? 'Edit' : 'Add'} Reference Link Category Item</div>
            </DialogTitleWithClose>
            <DialogContent>
                <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            label="Enter Category Name"
                            required
                            error={Boolean(errors.name)}
                            helperText={errors.name?.message?.toString()}
                        />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    onClick={handleFormSubmit(onSubmit)}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
