import { Typography } from '@mui/material';
import React from 'react';
import { GetTriggerFiredEventsForPatientTranscriptQuery } from '~/schemaTypes';

type TriggerFiredEvent = NonNullable<
    GetTriggerFiredEventsForPatientTranscriptQuery['getTriggerFiredEventsForPatientTranscript']
>[0];

export const TriggerFiredEventCard = ({ event }: { event: TriggerFiredEvent }) => {
    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <Typography variant="h6">Trigger Fired Event</Typography>
            <p className="m-0">{`Tag: ${event?.tag?.name || 'Unknown'}`}</p>
            <p className="m-0">{`Expression: ${event?.tag?.expression?.name || 'Unknown'}`}</p>
            {event?.dateTrigger && (
                <p className="m-0">{`Date Trigger: ${event.dateTrigger.name}`}</p>
            )}
            {event?.eventTrigger && (
                <p className="m-0">{`Event Trigger: ${event.eventTrigger.name}`}</p>
            )}
            {event?.trendTrigger && (
                <p className="m-0">{`Trend Trigger: ${event.trendTrigger.name}`}</p>
            )}
        </div>
    );
};
