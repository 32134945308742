import { faTrash, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add, InfoOutlined } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    AlertSeverity,
    ReferenceLinksListDocument,
    useReferenceLinksListQuery,
    useDeleteReferenceLinkMutation,
} from '~/schemaTypes';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';

const useStyles = makeStyles()({
    root: {},
});
const ReferenceLinks: React.FC = () => {
    const { classes } = useStyles();

    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);

    const { data: referenceLinksData, loading: referenceLinksLoading } =
        useReferenceLinksListQuery();

    const [deleteReferenceLink, { loading: deleteReferenceLinkLoading }] =
        useDeleteReferenceLinkMutation({
            awaitRefetchQueries: true,
            refetchQueries: [{ query: ReferenceLinksListDocument }],
        });

    const handleDelete = (id: string) => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete this Reference Link?`,
            callback: () => {
                deleteReferenceLink({ variables: { input: { id } } });
            },
        });
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Reference Links"
                icons={tableIcons}
                isLoading={referenceLinksLoading || deleteReferenceLinkLoading}
                data={_.cloneDeep(referenceLinksData?.referenceLinksV2?.results) ?? []}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                actions={[
                    {
                        onClick: () => history('/app-config/referencelinks/new'),
                        hidden: !pagePermissions?.ReferenceLinks.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Reference Link',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => history(`/app-config/referencelinks/${id}`),
                        hidden: !pagePermissions?.ReferenceLinks.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Reference Link',
                    },
                    {
                        onClick: (_, { id }: any) => handleDelete(id),
                        hidden: !pagePermissions?.ReferenceLinks.Delete,
                        icon: () => <FontAwesomeIcon icon={faTrash} />,
                        tooltip: 'Delete Reference Link',
                    },
                ]}
                columns={[
                    {
                        title: 'Id',
                        field: 'id',
                        sorting: false,
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Reference Link Id Copied to Clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    { title: 'Label', field: 'label', defaultSort: 'asc' },
                    { title: 'CategoryId', field: 'categoryId' },
                    { title: 'URL', field: 'url' },
                ]}
                options={{ pageSize: rowsPerPage }}
                localization={{ header: { actions: '' } }}
            />
        </div>
    );
};

export default ReferenceLinks;
