import ObjectId from 'bson-objectid';
import { ClientSelectionType } from '~/enums/enums';
import {
    MINUTES_IN_DAY,
    MINUTES_IN_WEEK,
    getOffsets,
    toTimeUnit,
} from '~/helpers/minuteConverters';
import {
    CarePlan,
    CarePlanFormInput,
    FormTodoGroup,
    FormTodoTemplate,
    TodoGroup,
    TodoTemplate,
} from '../types';
import { toRepeatIntervalUnit, toViewLimitUnit } from './index';

const toClientSelectionType = ({ excludeApps, includeApps }: CarePlan): ClientSelectionType => {
    if (includeApps && includeApps.length > 0) return ClientSelectionType.IncludeCertainApps;
    if (excludeApps && excludeApps.length > 0) return ClientSelectionType.ExcludeCertainApps;
    return ClientSelectionType.IncludeAllApps;
};

const toFormTemplates = (templates: TodoTemplate[]): FormTodoTemplate[] => {
    return templates.map(template => {
        const offset = getOffsets(
            template.offsetDays ?? 0,
            template.offsetMonths ?? 0,
            template.offsetYears ?? 0,
        );
        const duration = toTimeUnit(template.durationMinutes ?? 0);
        const repeatIntervalUnit = toRepeatIntervalUnit(
            template.repeatIntervalMinutes || MINUTES_IN_DAY,
        );

        return {
            id: new ObjectId(template.id),
            title: template.title || { en: '' },
            articleId: template.articleId,
            offsetDays: offset.days,
            offsetMonths: offset.months,
            offsetYears: offset.years,
            offsetType: offset.type,
            durationValue: duration.value,
            durationUnit: duration.unit,
            isRecurring: template.isRepeating,
            repeatIntervalUnit,
            repeatCount: template.repeatCount ?? 0,
            priority: template.priority,
            isEssential: template.isEssential,
            tags: template.tags ?? [],
            notificationData: {
                shouldSendNotification: template.notificationData?.shouldSendNotification ?? false,
                en: template.notificationData?.en,
                es: template.notificationData?.es,
            },
        };
    });
};

const toFormGroups = (groups: TodoGroup[]): FormTodoGroup[] => {
    return groups.map(group => ({
        id: new ObjectId(group.id),
        label: group.label ?? { en: '', es: '' },
        templates: toFormTemplates(group.templates),
        suppressedTemplates: group.suppressedTemplates,
    }));
};

export const toFormValuesFromCarePlan = (carePlan: CarePlan): CarePlanFormInput => {
    const { value, unit } = toViewLimitUnit(carePlan?.viewLimitMinutes || MINUTES_IN_WEEK);

    return {
        portalLabel: carePlan?.portalLabel?.en ?? '',
        appLabel: carePlan?.appLabel ?? { en: ' ' },
        includeApps: carePlan.includeApps ?? [],
        excludeApps: carePlan.excludeApps ?? [],
        clientSelectionType: toClientSelectionType(carePlan),
        groups: toFormGroups(carePlan.groups),
        timelineId: carePlan?.timelineId ?? '',
        tags: carePlan.tags || [],
        viewLimitValue: value,
        viewLimitUnit: unit,
        baseCarePlanAppLabel: carePlan.baseCarePlan?.appLabel,
        baseCarePlanTags: carePlan.baseCarePlan?.tags ?? [],
    };
};
