import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { Save } from '@mui/icons-material';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useStyles } from './styles';
import { LABEL_SCHEMA } from './yupSchema';

type LabelInput = {
    label: string;
};

interface TaskLabelProps {
    label: string;
    onTaskLabelUpdated: (label: string) => void;
}

const TaskLabel: React.FC<TaskLabelProps> = ({ onTaskLabelUpdated, label }) => {
    const { classes } = useStyles();
    const [isEditLabel, setIsEditLabel] = useState<boolean>(false);
    const {
        reset,
        handleSubmit,
        register,

        formState: { errors },
    } = useForm<LabelInput>({
        resolver: yupResolver(LABEL_SCHEMA as any),
    });

    const onSubmit: SubmitHandler<LabelInput> = ({ label }) => {
        onTaskLabelUpdated(label);
        setIsEditLabel(false);
    };

    const handleCancel = () => {
        setIsEditLabel(false);
        reset();
    };

    return isEditLabel ? (
        <Grid item xs={12}>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Grid item container alignItems="center" xs={12} spacing={1}>
                    <Grid item xs={10}>
                        <TextField
                            id="label"
                            label="Edit label"
                            variant="outlined"
                            size="medium"
                            autoFocus
                            fullWidth
                            multiline
                            {...register('label')}
                            defaultValue={label}
                            error={!!errors.label}
                            helperText={errors.label?.message}
                            className={classes.editLabelInput}
                        />
                    </Grid>
                    <Grid item container alignItems="center" xs={2} spacing={1}>
                        <Grid item>
                            <Button
                                color="secondary"
                                variant="outlined"
                                size="small"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                startIcon={<Save />}
                                type="submit"
                                color="secondary"
                                variant="contained"
                                size="small"
                                onClick={handleSubmit(onSubmit)}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    ) : (
        <Grid item container alignItems="center" wrap="nowrap">
            <Grid item>
                <Typography variant="h6">{label}</Typography>
            </Grid>
        </Grid>
    );
};

export default TaskLabel;
