import { makeStyles } from 'tss-react/mui';

export default makeStyles()({
    container: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        minHeight: '100vh',
        padding: '5px 30px',
        marginBottom: '20px',
        '@media (max-width: 900px)': {
            padding: '5px 10px',
            '.MuiOutlinedInput-root, .MuiInputLabel-root, .MuiInput-root': {
                fontSize: '1rem',
            },
            '.MuiTypography-root, .MuiButton-root': {
                fontSize: '.9rem',
            },
            margin: 0,
        },
    },
    header: {
        '@media (max-width: 900px)': {
            'h1.MuiTypography-root': {
                fontSize: '1.2rem',
            },
        },
    },
    content: {
        flexGrow: 1,
    },
    footer: {
        alignSelf: 'flex-start',
        button: {
            padding: '10px 40px',
        },
        '@media (max-width: 900px)': {
            width: '100%',
            button: {
                width: '100%',
                padding: '5px 0',
            },
        },
        paddingBottom: '10px',
    },
    contentContainer: {
        alignItems: 'flex-start',
        '>div': {
            width: '100%',
        },
        '.MuiFormControl-root': {
            paddingLeft: 0,
        },
    },
});
