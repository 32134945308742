import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Dialog, Grid, IconButton, TablePagination, Typography } from '@mui/material';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import { AccessCode as AccessCodeType, useUnitNamesLazyQuery } from '~/schemaTypes';
import tableIcons from '~/helpers/tableIcons';
import { Add } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ActionDialog from './ActionDialog';
import { IAccessCodeFormValue } from './types';
import Header from '../components/Header';
import { useAccessCodeTable } from '../hooks/useTable';
import UnitNameFilter from './UnitNameSelect';

const AccessCode = () => {
    const [getUnitNames, { data: unitNamesQuery }] = useUnitNamesLazyQuery();
    const [showActionDialog, setShowActionDialog] = useState(false);
    const [selectedCode, setSelectedCode] = useState<IAccessCodeFormValue>();
    const [selectedId, setSelectedId] = useState<string>();
    useEffect(() => {
        getUnitNames();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [unitName, setUnitName] = useState<string>('');
    const unitNames: string[] = useMemo(() => {
        return unitNamesQuery?.unitNames?.results ?? [];
    }, [unitNamesQuery]);
    const unitNamesOptions = useMemo(() => {
        return [
            { value: '', title: 'Select Unit' },
            ...(unitNames.map(un => ({
                value: un,
                title: un,
            })) || []),
        ];
    }, [unitNames]);
    const {
        page,
        rowsPerPage,
        handleChangeRowsPerPage,
        handleChangePage,
        tableRef,
        data: { loading, data },
        fetchQuery,
    } = useAccessCodeTable(unitName);
    const actionDialogCallback = useCallback(() => {
        fetchQuery();
        setShowActionDialog(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchQuery, setShowActionDialog]);
    const editAccessCodeHandler = useCallback(
        (_id: string) => {
            const accessCode = data?.accessCodes?.results?.find(ac => ac?._id === _id);
            if (accessCode) {
                const formValues = {
                    lastName: accessCode?.lastName,
                    firstName: accessCode?.firstName,
                    birthDate: accessCode?.birthDate,
                    unitName: accessCode?.unitName,
                };
                setSelectedCode(formValues);
                setSelectedId(_id);
                setShowActionDialog(true);
            }
        },
        [data],
    );
    const addAccessCodeHandler = useCallback(() => {
        setSelectedCode(undefined);
        setSelectedId(undefined);
        setShowActionDialog(true);
    }, []);
    return (
        <>
            <Grid container rowSpacing={4}>
                <Header lastTitle="Eligibility Data" lastPath="accessocde">
                    <Grid item>
                        <IconButton onClick={addAccessCodeHandler}>
                            <Add fontSize="large" />
                        </IconButton>
                    </Grid>
                </Header>
                <Grid item container justifyContent="flex-end">
                    <UnitNameFilter
                        setUnitName={setUnitName}
                        unitName={unitName}
                        options={unitNamesOptions}
                    />
                </Grid>
                <Grid item xs={12} marginRight="1rem" ref={tableRef}>
                    <MaterialTable<AccessCodeType>
                        icons={tableIcons as MaterialTableProps<any>['icons']}
                        columns={[
                            {
                                title: 'Patient FN',
                                field: 'firstName',
                                render: ({ firstName }) => (
                                    <Typography data-test={firstName}>{firstName}</Typography>
                                ),
                                sorting: false,
                            },
                            {
                                title: 'Patient LN',
                                field: 'lastName',
                                render: ({ lastName }) => (
                                    <Typography data-test={lastName}>{lastName}</Typography>
                                ),
                                sorting: false,
                            },
                            {
                                title: 'DOB',
                                field: 'birthDate',
                                render: ({ birthDate }) => (
                                    <Typography data-test={birthDate}>{birthDate}</Typography>
                                ),
                                sorting: false,
                            },
                            {
                                title: 'Unit',
                                field: 'unitName',
                                render: ({ unitName }) => (
                                    <Typography data-test={unitName}>{unitName}</Typography>
                                ),
                                sorting: false,
                            },
                        ]}
                        data={
                            data?.accessCodes?.results?.map((o: any) => ({
                                ...o,
                            })) || []
                        }
                        options={{
                            search: false,
                            paginationPosition: 'bottom',
                            pageSize: rowsPerPage,
                            pageSizeOptions: [25, 50, 100],
                            toolbar: false,
                        }}
                        isLoading={loading}
                        localization={{ header: { actions: '' } }}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        components={{
                            Pagination: props => (
                                <TablePagination
                                    {...props}
                                    count={data?.accessCodes?.total ?? 0}
                                    page={page}
                                    onPageChange={handleChangePage}
                                />
                            ),
                        }}
                        actions={[
                            {
                                onClick: (_, { _id }: any) => editAccessCodeHandler(_id),
                                icon: () => <FontAwesomeIcon icon={faEdit} />,
                                tooltip: 'Edit Access Code',
                            },
                        ]}
                    />
                </Grid>
            </Grid>
            <Dialog open={showActionDialog} scroll="paper">
                <ActionDialog
                    callback={actionDialogCallback}
                    values={selectedCode}
                    unitNames={unitNames}
                    id={selectedId}
                />
            </Dialog>
        </>
    );
};

export default AccessCode;
