import React, { useState, useCallback } from 'react';
import { Grid, Button, TextField } from '@mui/material';
import AddManuallyButton from './AddManuallyButton';
import { FormMode, ZipCodesType } from './constants';

type AddZipCodeProps = {
    addHandler: (zipCodes: string[], type?: ZipCodesType) => void;
    mode: FormMode;
    isEdit: boolean;
    setMode: (mode: FormMode) => void;
};

const AddZipCode: React.FC<AddZipCodeProps> = props => {
    const { addHandler: addHandlerCb, mode, isEdit, setMode } = props;
    const [zipCode, setZipCode] = useState<string>('');
    const addHandler = useCallback(() => {
        addHandlerCb([zipCode], ZipCodesType.Mixed);
        setZipCode('');
        setMode(FormMode.View);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addHandlerCb, zipCode]);
    const isValidZipCode = /^[\d]{5}$/.test(zipCode);
    if ([FormMode.AddByAddress, FormMode.AddByCityState].includes(mode)) {
        return null;
    }
    if (FormMode.View === mode) {
        return <AddManuallyButton isEdit={isEdit} setMode={() => setMode(FormMode.AddManually)} />;
    }
    return (
        <Grid container item alignItems="center" justifyContent="flex-end" xs={12}>
            <Grid item sm="auto" xs={8}>
                <TextField
                    placeholder="Ex. 98112"
                    value={zipCode}
                    onChange={e => setZipCode(e.target.value)}
                    size="small"
                    inputProps={{
                        maxLength: 5,
                    }}
                />
            </Grid>
            <Grid item>
                <Button variant="outlined" onClick={addHandler} disabled={!isValidZipCode}>
                    Add
                </Button>
            </Grid>
        </Grid>
    );
};

export default AddZipCode;
