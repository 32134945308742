import { DialogContent, Grid, Divider, Dialog, Typography } from '@mui/material';
import MaterialTable from '@material-table/core';
import { makeStyles } from 'tss-react/mui';
import Loading from '~/components/Loading/Loading';
import React, { useEffect } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { displayDateWithAbbrTimeZone } from '~/helpers/dateHelpers';
import { TrackerHistory, useFetchTrackerHistoryByRecordIdLazyQuery } from '~/schemaTypes';
import WeightValue from '~/components/WeightValue/WeightValue';

const useStyles = makeStyles()(_theme => ({
    root: {
        position: 'relative',
        height: '100%',
        padding: _theme.spacing(1),
    },
}));

type MeasurementHistoryModalProps = {
    open: boolean;
    id: string;
    onClose: () => void;
};

enum RECORD_TYPE {
    WEIGHT_TRACKER = 'WeightTrackerEntryRecord',
    BLOOD_PRESSURE_TRACKER = 'BloodPressureTrackerRecord',
    DAILY_TRACKER = 'DailyTrackerRecord',
    MOVEMENT_TRACKER = 'MovementTrackerRecord',
    GLUCOSE_TRACKER = 'GlucoseTrackerRecord',
}

export const MeasurementHistoryModal: React.FC<MeasurementHistoryModalProps> = ({
    open,
    id,
    onClose,
}) => {
    const { classes } = useStyles();
    const [getHistory, { data: trackerHistory, loading: trackerHistoryLoading }] =
        useFetchTrackerHistoryByRecordIdLazyQuery();

    useEffect(() => {
        if (id) getHistory({ variables: { uuid: id } });
    }, [id, getHistory]);

    if (trackerHistoryLoading) return <Loading />;

    return (
        <Dialog open={open}>
            <DialogTitleWithClose id="form-dialog-title" onClose={onClose}>
                Measurement History
            </DialogTitleWithClose>
            <Divider style={{ marginLeft: 10, marginRight: 10 }} />
            <DialogContent className={classes.root}>
                <Grid>
                    <MaterialTable
                        isLoading={trackerHistoryLoading}
                        data={
                            (trackerHistory?.getTrackerHistoryByRecordId as TrackerHistory[]) ?? []
                        }
                        columns={[
                            {
                                title: 'Date Changed',
                                field: 'updatedAt',
                                type: 'datetime',
                                sorting: true,
                                defaultSort: 'desc',
                                render: ({ updatedAt, timezoneName }) => {
                                    return displayDateWithAbbrTimeZone({
                                        isoDateStr: updatedAt,
                                        format: 'MM/DD/yyyy, hh:mm:ss A',
                                        timeZone:
                                            timezoneName ??
                                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    });
                                },
                            },
                            {
                                title: 'Value',
                                render: ({
                                    movement,
                                    startDate,
                                    endDate,
                                    weight,
                                    pulse,
                                    diastolicBP,
                                    systolicBP,
                                    glucose,
                                    moodLevel,
                                    painLevel,
                                    steps,
                                    temperature,
                                    sleepStart,
                                    sleepEnd,
                                    notes,
                                    type,
                                }) => {
                                    switch (type) {
                                        case RECORD_TYPE.MOVEMENT_TRACKER:
                                            return (
                                                <Typography>
                                                    {`${movement} movements`}
                                                    {`Start date: ${startDate}`}
                                                    {`End date: ${endDate}`}
                                                </Typography>
                                            );
                                        case RECORD_TYPE.BLOOD_PRESSURE_TRACKER:
                                            return (
                                                <Typography>
                                                    {systolicBP &&
                                                        diastolicBP &&
                                                        `${systolicBP}/${diastolicBP} mmHg`}
                                                    {systolicBP && diastolicBP && pulse && <br />}
                                                    {pulse && `${pulse} bpm`}
                                                </Typography>
                                            );
                                        case RECORD_TYPE.WEIGHT_TRACKER: {
                                            return <WeightValue value={Number(weight)} showUnit />;
                                        }
                                        case RECORD_TYPE.GLUCOSE_TRACKER: {
                                            return <Typography>{`${glucose} mg/dL`}</Typography>;
                                        }
                                        case RECORD_TYPE.DAILY_TRACKER: {
                                            const dailyMeasurement = `Glucose: ${glucose}, Mood Level:
                                                    ${moodLevel}, Pain Level:
                                                    ${painLevel}, Steps:
                                                    ${steps}, Temperature:
                                                    ${temperature} C, Start of
                                                    sleep: ${sleepStart}, End of
                                                    sleep: ${sleepEnd}, Notes:
                                                    ${notes}`;
                                            return <Typography>{dailyMeasurement}</Typography>;
                                        }
                                        default:
                                            return <div />;
                                    }
                                },
                            },
                        ]}
                        options={{
                            search: false,
                            toolbar: false,
                            paging: false,
                        }}
                    />
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
