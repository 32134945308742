import * as Yup from 'yup';
import {
    Autocomplete,
    Button,
    Card,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { ArrowBack, Save as SaveIcon } from '@mui/icons-material';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import {
    HighlightIcon,
    useSaveHighlightMutation,
    HighlightUpdateInput,
    HighlightType,
    ClockType,
    TimeUnit,
    useGetAppointmentTemplateHighlightQuery,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { LocalizedStringTextFields } from '~/components/LocalizedStringTextFields/LocalizedStringTextFields';
import { addSpacesBetweenCapitalsHelper } from '~/helpers/addSpaceBetweenCapitalLetters';
import RichTextEditor from '~/components/RichTextEditor/RichTextEditor';
import { toBase64 } from '~/helpers/base64Helper';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStyles } from './styles';
import { HighlightFormInput } from './types/types';

const SCHEMA = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    priority: Yup.number().required('Priority is required'),
});

const Priorities = Array.from({ length: 5 }, (_, i) => (i + 1).toString());
const toInput = (form: Partial<HighlightFormInput>): HighlightUpdateInput => {
    const {
        name,
        priority,
        icon,
        imageContentId,
        closeable,
        highlighted,
        displayAsModal,
        displayAsModalButtonText,
    } = form;
    return {
        name: name || '',
        type: HighlightType.AppointmentTemplate,
        clockType: ClockType.FixedDate,
        priority: priority || 5,
        icon,
        imageContentId,
        duration: 0,
        durationTimeUnit: TimeUnit.Days,
        closeable: closeable || true,
        highlighted: highlighted || false,
        shouldSendNotification: false,
        displayAsModal: displayAsModal || false,
        displayAsModalButtonText: {
            en: displayAsModalButtonText?.en,
            es: displayAsModalButtonText?.es,
        },
    };
};

const toForm = (item: any): any => {
    return {
        name: item.name,
        priority: item.priority,
        icon: item.icon,
        closeable: item.closeable,
        highlighted: item.highlighted,
        displayAsModal: item.displayAsModal ? item.displayAsModal : false,
        displayAsModalButtonText: {
            en: item.displayAsModalButtonText?.en,
            es: item.displayAsModalButtonText?.es,
        },
    };
};

const AppointmentTemplateEditor: React.FC = () => {
    const { classes } = useStyles();
    const history = useNavigate();
    const [selectedIcon, setSelectedIcon] = useState<HighlightIcon>(HighlightIcon.None);
    const [titleEn, setTitleEn] = useState<string>();
    const [titleEs, setTitleEs] = useState<string>();
    const [headerEn, setHeaderEn] = useState<string>();
    const [headerEs, setHeaderEs] = useState<string>();
    const [bodyEn, setBodyEn] = useState<string>();
    const [bodyEs, setBodyEs] = useState<string>();
    const [templateId, setTemplateId] = useState<string>();

    const useFormMethods = useForm({
        resolver: yupResolver(SCHEMA as any),
        defaultValues: {
            name: '',
            priority: 5,
            closeable: false,
            highlighted: false,
            displayAsModal: false,
        },
    });

    const {
        control,
        register,
        handleSubmit: handleFormSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useFormMethods;

    const { loading: highlightLoading } = useGetAppointmentTemplateHighlightQuery({
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if (data.appointmentTemplateHighlight) {
                const highlight = data.appointmentTemplateHighlight;
                const formValues = toForm(highlight);
                reset({ ...formValues });
                setSelectedIcon(highlight.icon);
                setHeaderEn(highlight.headerMarkup?.en || undefined);
                setHeaderEs(highlight.headerMarkup?.es || undefined);
                setTitleEn(highlight.titleMarkup?.en || undefined);
                setTitleEs(highlight.titleMarkup?.es || undefined);
                setBodyEn(highlight.bodyMarkup?.en || undefined);
                setBodyEs(highlight.bodyMarkup?.es || undefined);
                setTemplateId(highlight.id);
            }
        },
    });

    const [saveHighlight, { loading: saveHighlightLoading }] = useSaveHighlightMutation({
        onCompleted: () => {
            history('/app-config/highlights/');
        },
        onError: error => {
            TriggerGlobalConfirm({
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                callback: () => {},
                message: `There was a problem saving the highlight: ${error.message}`,
            });
        },
    });

    const handleSubmit: SubmitHandler<Partial<HighlightFormInput>> = form => {
        saveHighlight({
            variables: {
                input: {
                    id: templateId,
                    data: {
                        ...toInput(form),
                        icon: selectedIcon,
                        titleMarkup: { en: toBase64(titleEn), es: toBase64(titleEs) },
                        headerMarkup: { en: toBase64(headerEn), es: toBase64(headerEs) },
                        bodyMarkup: { en: toBase64(bodyEn), es: toBase64(bodyEs) },
                    },
                },
            },
        });
    };
    if (saveHighlightLoading || highlightLoading) {
        return <Loading />;
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <Button component={Link} to="/app-config/highlights/" startIcon={<ArrowBack />}>
                    Back to Highlights List
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <Typography variant="h6" paragraph>
                        {templateId ? 'Edit' : 'Create'} Appointment Template
                    </Typography>
                    <Divider className={classes.divider} />
                    <form onSubmit={handleFormSubmit(handleSubmit)}>
                        <Grid item>
                            <OutlinedSection title="Name *">
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    defaultValue=""
                                    {...register('name')}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                            </OutlinedSection>
                        </Grid>

                        <Grid item>
                            <ReactHookFormSelect
                                control={control}
                                name="priority"
                                variant="outlined"
                                label="Priority"
                                margin="dense"
                                defaultValue=""
                            >
                                {Priorities.map(i => (
                                    <MenuItem key={i} value={i}>
                                        {i}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                            <Controller
                                name="closeable"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        label="Can be Closed?"
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                id="closeable"
                                                checked={value === true}
                                                onChange={e => {
                                                    onChange(e.target.checked);
                                                }}
                                            />
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="highlighted"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        label="Highlight?"
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                id="highlighted"
                                                checked={value === true}
                                                onChange={e => {
                                                    onChange(e.target.checked);
                                                }}
                                            />
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <OutlinedSection title="Title Markup">
                                <Grid item xs={12} style={{ marginTop: '8px', padding: '10px' }}>
                                    <RichTextEditor
                                        onChange={value => {
                                            setTitleEn(value);
                                        }}
                                        placeholder="English"
                                        label="English"
                                        initialValue={titleEn}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '8px', padding: '10px' }}>
                                    <RichTextEditor
                                        onChange={value => {
                                            setTitleEs(value);
                                        }}
                                        placeholder="Spanish"
                                        label="Spanish"
                                        initialValue={titleEs}
                                    />
                                </Grid>
                            </OutlinedSection>
                        </Grid>
                        <Grid item>
                            <OutlinedSection title="Header Markup">
                                <Grid item xs={12} style={{ marginTop: '8px', padding: '10px' }}>
                                    <RichTextEditor
                                        onChange={value => {
                                            setHeaderEn(value);
                                        }}
                                        placeholder="English"
                                        label="English"
                                        initialValue={headerEn}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '8px', padding: '10px' }}>
                                    <RichTextEditor
                                        onChange={value => {
                                            setHeaderEs(value);
                                        }}
                                        placeholder="Spanish"
                                        label="Spanish"
                                        initialValue={headerEs}
                                    />
                                </Grid>
                            </OutlinedSection>
                        </Grid>
                        <Grid item>
                            <OutlinedSection title="Body Markup">
                                <Grid item xs={12} style={{ marginTop: '8px', padding: '10px' }}>
                                    <RichTextEditor
                                        onChange={value => {
                                            setBodyEn(value);
                                        }}
                                        placeholder="English"
                                        label="English"
                                        initialValue={bodyEn}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '8px', padding: '10px' }}>
                                    <RichTextEditor
                                        onChange={value => {
                                            setBodyEs(value);
                                        }}
                                        placeholder="Spanish"
                                        label="Spanish"
                                        initialValue={bodyEs}
                                    />
                                </Grid>
                            </OutlinedSection>
                        </Grid>
                        <OutlinedSection title="Icon">
                            <Autocomplete
                                size="small"
                                value={selectedIcon}
                                onChange={(_, val) => {
                                    setSelectedIcon(val as HighlightIcon);
                                }}
                                getOptionLabel={icon => {
                                    return addSpacesBetweenCapitalsHelper(icon);
                                }}
                                options={Object.values(HighlightIcon)}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                renderInput={params => <TextField variant="outlined" {...params} />}
                            />
                        </OutlinedSection>
                        <OutlinedSection title="Take an Action Modal">
                            <Grid item xs={12}>
                                <Controller
                                    name="displayAsModal"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <FormControlLabel
                                            label="Display as modal window"
                                            control={
                                                <Checkbox
                                                    checked={value}
                                                    onChange={e => {
                                                        onChange(e.target.checked);
                                                        setValue('highlighted', false);
                                                    }}
                                                />
                                            }
                                        />
                                    )}
                                />
                                <LocalizedStringTextFields
                                    name="displayAsModalButtonText"
                                    label="Modal window button label"
                                    register={register}
                                    required={{ en: true, es: false }}
                                />
                            </Grid>
                        </OutlinedSection>
                        <Grid
                            container
                            item
                            spacing={2}
                            justifyContent="flex-end"
                            direction="row"
                            className={classes.actionButtons}
                            alignItems="flex-end"
                        >
                            <Button
                                onClick={handleFormSubmit(handleSubmit)}
                                startIcon={<SaveIcon />}
                                type="submit"
                                color="secondary"
                                variant="contained"
                            >
                                Save
                            </Button>
                        </Grid>
                    </form>
                </Card>
            </Grid>
        </Grid>
    );
};

export default AppointmentTemplateEditor;
