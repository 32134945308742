import { faEye, faPenToSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '@mui/material';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    PatientActionType,
    PatientActionTypesForConfigPortalDocument,
    useDeleteAndReplacePatientActionTypeMutation,
    useDeletePatientActionTypeMutation,
    usePatientActionTypesForConfigPortalQuery,
    usePatientActionTypeWhereUsedLazyQuery,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import DeleteAndReplaceModal from './DeleteAndReplaceModal/DeleteAndReplaceModal';
import { PatientActionTypesModal } from './PatientActionTypesModal/PatientActionTypesModal';

export const PatientActionTypes: React.FC = () => {
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedPatientActionType, setSelectedPatientActionType] =
        useState<PatientActionType | null>(null);
    const [showUsageModal, setShowUsageModal] = useState<boolean>(false);
    const [showDeleteAndReplaceModal, setShowDeleteAndReplaceModal] = useState<boolean>(false);

    const { data, loading } = usePatientActionTypesForConfigPortalQuery();

    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        usePatientActionTypeWhereUsedLazyQuery();
    const [deleteItem, { loading: deleteItemLoading }] = useDeletePatientActionTypeMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: PatientActionTypesForConfigPortalDocument,
                variables: {},
            },
        ],
    });
    const [deleteAndReplace, { loading: deleteAndReplaceLoading }] =
        useDeleteAndReplacePatientActionTypeMutation({
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: PatientActionTypesForConfigPortalDocument,
                    variables: {},
                },
            ],
        });

    const handleDelete = () => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${selectedPatientActionType?.label}'?`,
            callback: () => {
                deleteItem({
                    variables: { deletePatientActionTypeId: selectedPatientActionType?.id },
                });
                setShowUsageModal(false);
            },
        });
    };

    const handleDeleteAndReplace = (replaceWithId: string) => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${selectedPatientActionType?.label}'?`,
            callback: () => {
                deleteAndReplace({
                    variables: {
                        input: {
                            id: selectedPatientActionType?.id,
                            replaceWithId,
                        },
                    },
                });
                setShowDeleteAndReplaceModal(false);
            },
        });
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    const handleShowUsage = (content: PatientActionType) => {
        whereUsed({
            variables: {
                patientActionTypeWhereUsedId: content.id,
            },
        });
        setShowUsageModal(true);
    };

    return (
        <>
            <MaterialTable<PatientActionType>
                title="Patient Action Types"
                icons={tableIcons}
                isLoading={loading || whereUsedLoading || deleteItemLoading}
                data={_.cloneDeep(data?.patientActionTypesV2.results) ?? []}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                actions={[
                    {
                        onClick: () => setModalOpen(true),
                        icon: () => <Add />,
                        tooltip: 'Add Patient Action Type',
                        isFreeAction: true,
                        hidden: !pagePermissions?.PatientActionTypes.Edit,
                    },
                    {
                        onClick: (_, item) => {
                            const isSingleItemSelected = !Array.isArray(item);
                            if (isSingleItemSelected) {
                                setSelectedPatientActionType(item);
                                setModalOpen(true);
                            }
                        },
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Patient Action Type',
                        hidden: !pagePermissions?.PatientActionTypes.Edit,
                    },
                    {
                        onClick: (_e, item) => {
                            setSelectedPatientActionType(item as PatientActionType);
                            handleShowUsage(item as PatientActionType);
                        },
                        icon: () => <FontAwesomeIcon icon={faEye} />,
                        tooltip: 'View References',
                    },
                    {
                        onClick: (_, item) => {
                            setSelectedPatientActionType(item as PatientActionType);
                            setShowDeleteAndReplaceModal(true);
                        },
                        icon: () => <FontAwesomeIcon icon={faTrashAlt} />,
                        tooltip: 'Delete and Replace Patient Action Type',
                        hidden: !pagePermissions?.PatientActionTypes.Delete,
                    },
                ]}
                columns={[
                    {
                        title: 'Label',
                        field: 'label',
                    },
                ]}
                options={{ pageSize: rowsPerPage }}
                localization={{ header: { actions: '' } }}
            />
            <PatientActionTypesModal
                isOpen={isModalOpen}
                onClose={() => {
                    setModalOpen(false);
                    setSelectedPatientActionType(null);
                }}
                item={selectedPatientActionType}
            />
            <Dialog scroll="paper" open={!whereUsedLoading && showUsageModal}>
                <WhereUsedModal
                    onClose={() => {
                        setShowUsageModal(false);
                        setSelectedPatientActionType(null);
                    }}
                    onDelete={handleDelete}
                    title={`Usage for ${selectedPatientActionType?.label}`}
                    canDelete={pagePermissions?.PatientActionTypes.Delete || false}
                    usageData={whereUsedData?.patientActionTypeWhereUsed?.dependencies || []}
                    permissions={pagePermissions}
                />
            </Dialog>
            <Dialog scroll="paper" open={!deleteAndReplaceLoading && showDeleteAndReplaceModal}>
                <DeleteAndReplaceModal
                    title={`${selectedPatientActionType?.label}`}
                    actionTypes={
                        data?.patientActionTypesV2.results.filter(
                            a => a.id !== selectedPatientActionType?.id,
                        ) || []
                    }
                    onClose={() => {
                        setShowDeleteAndReplaceModal(false);
                        setSelectedPatientActionType(null);
                    }}
                    onDelete={handleDeleteAndReplace}
                />
            </Dialog>
        </>
    );
};
