import React from 'react';
import { Link, Grid, Typography } from '@mui/material';
import useStyles from '../styles';

type DisclaimerProps = {
    isMobileView: boolean;
};

const DISCLAIMER_LINK = 'https://www.wildflowerhealth.com/privacy-policy/patient-consent-policy';

const Disclaimer: React.FC<DisclaimerProps> = props => {
    const { isMobileView } = props;
    const { classes } = useStyles();
    return (
        <Grid container justifyContent="center">
            <Grid item xs={10} md={12} className={classes.disclaimer}>
                <Typography
                    variant="body2"
                    style={{ textAlign: isMobileView ? 'center' : 'right' }}
                    fontWeight="bold"
                >
                    <Link
                        underline="always"
                        href={DISCLAIMER_LINK}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Patient Consent for Treatment Policy
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default React.memo(Disclaimer);
