import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Grid, Typography, Link } from '@mui/material';
import { logout } from '~/state/auth/auth.svc';

const useStyles = makeStyles()(() => ({
    container: {
        marginBottom: '30px',
        '@media (max-width: 800px)': {
            margin: '0 0 15px',
        },
    },
}));

type FooterProps = {
    enableLogout?: boolean;
};

const Footer: React.FC<FooterProps> = props => {
    const { enableLogout = false } = props;
    const { classes } = useStyles();
    return (
        <Grid
            item
            xs={12}
            container
            alignItems="center"
            flexDirection="column"
            rowSpacing={2}
            className={classes.container}
        >
            <Grid item container justifyContent="center">
                <Grid item>
                    <Typography>
                        <b>Questions?</b>&nbsp;
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography>
                        <b>Email</b>&nbsp;
                        <Link href="mailto:lactationsupport@wildflowerhealth.com">
                            lactationsupport@wildflowerhealth.com
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
            {enableLogout && (
                <Grid item>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                        onClick={() => logout()}
                        sx={{ cursor: 'pointer' }}
                        underline="always"
                        fontSize="smaller"
                    >
                        Logout and Complete Onboarding Later
                    </Link>
                </Grid>
            )}
        </Grid>
    );
};

export default Footer;
