import { FetchAdvocateTaskForAdvocateTaskPageQuery } from '~/schemaTypes';
import { actionTypeLabelById, getActionTypeIdsDiff } from './index';

type ActionType = NonNullable<
    FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']
>['actionTypes'][0];

const renderActionTypeLabels = (
    actionTypeIds: string[],
    prevActionTypeIds: string[],
    actionTypesData: ActionType[],
): string => {
    const actionTypeIdsDiff = getActionTypeIdsDiff(actionTypeIds, prevActionTypeIds);
    const newActionTypeLabels = actionTypeIdsDiff.map(id =>
        actionTypeLabelById(id, actionTypesData),
    );

    return newActionTypeLabels.join(', ');
};

export default renderActionTypeLabels;
