import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import uniq from 'lodash/uniq';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { COVERAGE_AREA_FORM_SCHEMA } from './yupSchema';
import { CoverageArea } from '../types';
import ActionButtons from './ActionButtons';
import HelperText from './HelperText';
import ZipCodesLabel from './ZipCodesLabel';
import ZipCodesViewer from './ZipCodesViewer';
import { FormMode, ZipCodesType } from './constants';
import AddZipCode from './AddZipCode';
import AddByCityAndState from './AddByCityAndState';
import AddZipCodesByAddress from './AddZipCodesByAddress';

type AddOfficeAddressProps = {
    close: () => void;
    onSubmit: (area: CoverageArea) => void;
    data: CoverageArea | null;
};

const CoverageAreaForm: React.FC<AddOfficeAddressProps> = props => {
    const { close, onSubmit: onSubmitCb, data } = props;
    const [sourceOfZipCodes, setSourceOfZipCodes] = useState<ZipCodesType | null>(
        data?.zipCodeSource || null,
    );
    const [formMode, setFormMode] = useState<FormMode>(FormMode.View);
    const [isAttest, setIsAttest] = useState(false);
    const isEdit = !!data;
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = useForm<CoverageArea>({
        resolver: yupResolver(COVERAGE_AREA_FORM_SCHEMA() as any),
        defaultValues: {
            zipCodes: data?.zipCodes || [],
            name: data?.name || '',
        },
    });
    useEffect(() => {
        setValue('zipCodeSource', sourceOfZipCodes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceOfZipCodes]);
    const onSubmit = (data: CoverageArea) => {
        onSubmitCb(data);
        close();
    };
    const zipCodes = watch('zipCodes');
    const handleDeleteZipCode = useCallback(
        (value: string) => {
            const newZipCodes = zipCodes.filter(zipCode => zipCode !== value);
            setValue('zipCodes', newZipCodes, { shouldValidate: true });
            if (!newZipCodes.length) {
                setSourceOfZipCodes(null);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [zipCodes],
    );
    const handleDeleteAllZipCodes = useCallback(() => {
        setValue('zipCodes', [], { shouldValidate: true });
        setSourceOfZipCodes(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const addZipCodeHandler = useCallback(
        (newZipCodes: string[], type?: ZipCodesType) => {
            const uniqueZipCodes = uniq([...zipCodes, ...newZipCodes]);
            setValue('zipCodes', uniqueZipCodes, { shouldValidate: true });
            setFormMode(FormMode.View);
            if (!sourceOfZipCodes && type) {
                setSourceOfZipCodes(type);
            } else if (type && type !== sourceOfZipCodes) {
                setSourceOfZipCodes(ZipCodesType.Mixed);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [zipCodes, sourceOfZipCodes],
    );
    const zipCodesForViewer = useMemo(() => zipCodes.map(zc => ({ value: zc })), [zipCodes]);
    const disableButton = Boolean(!isAttest && zipCodes.length);
    return (
        <div>
            <DialogTitleWithClose id="form-dialog-title" onClose={close}>
                Coverage Area
            </DialogTitleWithClose>
            <DialogContent dividers>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justifyContent="flex-start" alignItems="center" rowSpacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label="Name Of Coverage Area"
                                placeholder="Name of Coverage Area (ex. “Jane Doe Coverage Area” or “15 Mile Radius from Home”)"
                                fullWidth
                                margin="dense"
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ActionButtons
                                isEdit={isEdit}
                                setMode={(mode: FormMode) => setFormMode(mode)}
                                mode={formMode}
                                addHandler={addZipCodeHandler}
                            />
                            {formMode === FormMode.AddByCityState && (
                                <AddByCityAndState onComplete={addZipCodeHandler} />
                            )}
                            {formMode === FormMode.AddByAddress && (
                                <AddZipCodesByAddress onComplete={addZipCodeHandler} />
                            )}
                        </Grid>
                        <Grid item xs={12} container rowSpacing={2}>
                            {isEdit && (
                                <Grid item xs={12}>
                                    <ZipCodesLabel
                                        count={zipCodes.length}
                                        clearHandler={handleDeleteAllZipCodes}
                                    >
                                        <AddZipCode
                                            addHandler={addZipCodeHandler}
                                            mode={formMode}
                                            isEdit={isEdit}
                                            setMode={setFormMode}
                                        />
                                    </ZipCodesLabel>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <ZipCodesViewer
                                    zipCodes={zipCodesForViewer}
                                    handleDeleteZipCode={handleDeleteZipCode}
                                />
                            </Grid>
                            {!isEdit && (
                                <Grid item xs={12}>
                                    <ZipCodesLabel
                                        count={zipCodes.length}
                                        clearHandler={handleDeleteAllZipCodes}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} container minHeight={30}>
                            {!!zipCodes.length && <HelperText type={sourceOfZipCodes} />}
                        </Grid>
                        {!!zipCodes.length && (
                            <Grid item xs={12} container>
                                <FormControlLabel
                                    sx={{
                                        alignItems: 'flex-start',
                                    }}
                                    control={
                                        <Checkbox
                                            checked={isAttest}
                                            onChange={e => {
                                                setIsAttest(e.target.checked);
                                            }}
                                            sx={{
                                                paddingTop: 0,
                                            }}
                                        />
                                    }
                                    label={
                                        <Grid container rowSpacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1">
                                                    I attest that we can travel to the{' '}
                                                    {zipCodes.length} Zip Codes Listed Above
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    Note: You can add or remove zip codes at a later
                                                    time if something changes
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Grid item>
                    <Button
                        type="submit"
                        color="primary"
                        variant={isEdit ? 'outlined' : 'contained'}
                        onClick={handleSubmit(onSubmit)}
                        disabled={disableButton}
                    >
                        {isEdit ? 'Update Area of Coverage' : 'Create Area of Coverage'}
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={close} color="primary" variant="outlined">
                        Cancel
                    </Button>
                </Grid>
            </DialogActions>
        </div>
    );
};

export default CoverageAreaForm;
