import { Button, Card, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { JsonViewer } from '@textea/json-viewer';
import React, { Dispatch, SetStateAction } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';

type ModalProps = {
    setShow: Dispatch<SetStateAction<boolean>>;
    rawData: string;
    title?: string;
};

const RawModal: React.FC<ModalProps> = ({ setShow, rawData, title }) => {
    return (
        <>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setShow(false)} />

            <DialogContent>
                <Grid item xs={12}>
                    <Card>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6">{title ?? 'Config Preview:'}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <JsonViewer
                                    value={{
                                        config: JSON.parse(rawData),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShow(false)} color="secondary" variant="outlined">
                    Close
                </Button>
            </DialogActions>
        </>
    );
};

export default RawModal;
