import { makeStyles } from 'tss-react/mui';
import { errorRed } from '~/theme/WildTheme';

export const useStyles = makeStyles()({
    sortableList: {
        marginTop: '20px',
    },
    addTodoBtnWrap: {
        padding: '0 10px 0',
    },
    whenTypeTitle: {
        '& > div::first-letter': {
            textTransform: 'capitalize',
        },
    },
    sectionLabel: {
        display: 'inline-block',
        paddingLeft: '10px',
    },
    offsetWrap: {
        display: 'flex',
        alignContent: 'center',
    },
    offsetLabel: {
        alignSelf: 'center',
        marginRight: '10px',
    },
    checkboxWrap: {
        paddingLeft: '10px',
    },
    actionButtons: {
        marginTop: '5px',
        '& button': {
            marginLeft: '5px',
        },
    },
    helperMessage: {
        margin: '-10px 0 0 24px',
    },
    checklistError: {
        marginTop: '10px',
        marginLeft: '10px',
    },
    actionType: {
        fontWeight: 'bold',
    },
    validationError: {
        color: errorRed,
    },
});
