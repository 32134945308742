import React from 'react';
import PatientClaims from '~/views/AffiliateCare/Claims/PatientClaims/PatientClaims';

const Claims = () => {
    return (
        <PatientClaims
            invoicePathName="/doula/portal/invoices"
            patientPathName="/doula/portal/patients"
            VCView
        />
    );
};

export default Claims;
