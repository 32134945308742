import React, { useState } from 'react';
import { Button, DialogActions, DialogContent, Grid, TextField } from '@mui/material';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';

type AddEmailDialogProps = {
    onComplete: (email?: string) => void;
};

const AddEmailDialog: React.FC<AddEmailDialogProps> = props => {
    const { onComplete } = props;
    const [email, setEmail] = useState('');
    return (
        <div>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => onComplete()}>
                Add Notification Email
            </DialogTitleWithClose>
            <DialogContent dividers>
                <Grid item container xs={12}>
                    <Grid item xs={6}>
                        <TextField
                            label="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid item container xs={12} columnSpacing={2}>
                    <Grid item>
                        <Button
                            onClick={() => onComplete(email)}
                            disabled={!email}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => onComplete()} variant="outlined" color="primary">
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </div>
    );
};

export default AddEmailDialog;
