import { makeStyles } from 'tss-react/mui';

export const useDefStyles = makeStyles()(theme => ({
    root: {
        padding: theme.spacing(2),
    },
    inputField: {
        width: '100%',
        '& label': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '80%',
            overflow: 'hidden',
        },
    },
}));
