import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    addTodoBtnWrap: {
        padding: '0 10px 0',
    },
    stepLabel: {
        display: 'inherit',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
    },
    stepsInfo: {
        fontWeight: 'bold',
    },
    addButton: {
        color: 'rgba(0, 0, 0, 0.87)',
    },
    link: {
        fontSize: '.8rem',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        display: 'inline',
        margin: 0,
        padding: 0,
        color: '#116b8e',
        '&:hover': {
            cursor: 'pointer',
            filter: 'brightness(75%)',
            textDecoration: 'underline',
        },
    },
    addButtonWrapper: {
        marginLeft: 5,
    },
});
