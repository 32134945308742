import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    image: {
        objectFit: 'contain',
        width: '100%',
        height: 'auto',
        maxHeight: 400,
    },
}));

type HeaderProps = {
    onboarding?: boolean;
};

const Header: React.FC<HeaderProps> = props => {
    const { onboarding = false } = props;
    const { classes } = useStyles();
    const logoImg = onboarding ? 'header-onboarding' : 'header';
    return (
        <Grid xs={12} md={8} item>
            <img
                src={`/affiliate-onboarding/${logoImg}.png`}
                alt="logo"
                className={classes.image}
            />
        </Grid>
    );
};

export default Header;
