import { NonImageContentItem } from '~/components/NonImageContentPicker/types';
import {
    ContentByCriteriaQuery,
    ContentByIdForContentPickerQuery,
    ContentType,
} from '~/schemaTypes';

type SimpleContentById = NonNullable<ContentByIdForContentPickerQuery['simpleContent']>;
type SimpleContentByCriteria = NonNullable<ContentByCriteriaQuery['contentPickerFetch']['data']>;

type ToContentPickerForm = (
    content: SimpleContentByCriteria | SimpleContentById[],
) => NonImageContentItem[];

const toContentPickerForm: ToContentPickerForm = content => {
    if (content) {
        return content.map(item => ({
            id: item?.id ?? '',
            name: item?.name ?? '',
            contentType: item?.contentType ?? ContentType.ExternalLink,
        }));
    }
    return [];
};

export default toContentPickerForm;
