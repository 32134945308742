export enum PatientEnum {
    SIDE_NAV_PATIENTS = 'patients',
    PATIENTS_CONTAINER = 'patients-container',
    PATIENT_MODAL = 'patient-modal',
    PATIENT_SUMMARY_MODAL = 'patient-summary-modal',
    EMAIL_INPUT = 'patient-email-input',
    FIRST_NAME_INPUT = 'patient-first-name-input',
    LAST_NAME_INPUT = 'patient-last-name-input',
    MEDICAL_RECORD_ID_INPUT = 'patient-medical-record-id-input',
    PHONE_NUMBER_INPUT = 'patient-phone-number-input',
    STREET_ADDRESS_1_INPUT = 'patient-street-address-1-input',
    STREET_ADDRESS_2_INPUT = 'patient-street-address-1-input',
    CITY_INPUT = 'patient-city-input',
    STATE_INPUT = 'patient-state-input',
    ZIP_CODE_INPUT = 'patient-zip-code-input',
    FILTER_PATIENTS_BUTTON = 'filter-patients-button',
    SUBMIT_PATIENT = 'submit-patient-button',
    FILTER_PATIENTS_MODAL = 'filter-patients-modal',
    FILTER_FIRST_NAME = 'filter-first-name',
    FILTER_LAST_NAME = 'filter-last-name',
    SUBMIT_FILTER = 'filter-patients-submit',
}

export const PatientSelectors = {
    SIDE_NAV_PATIENTS: `a[data-test="${PatientEnum.SIDE_NAV_PATIENTS}"]`,
    ADD_NEW_PATIENT_BUTTON: `div[data-test="${PatientEnum.PATIENTS_CONTAINER}"] button[title="Add New Patient"]`,
    FILTER_PATIENTS_BUTTON: `button[data-test="${PatientEnum.FILTER_PATIENTS_BUTTON}"]`,
    SUBMIT_PATIENT: `button[data-test="${PatientEnum.SUBMIT_PATIENT}"]`,
    SUBMIT_FILTER: `button[data-test="${PatientEnum.SUBMIT_FILTER}"]`,
    EDIT_PATIENT_BUTTON: `div[data-test="${PatientEnum.PATIENTS_CONTAINER}"] button[title="Edit"]`,
    PATIENT_MODAL: `div[data-test="${PatientEnum.PATIENT_MODAL}"] div[role="dialog"]`,
    PATIENT_MODAL_TITLE: `div[data-test="${PatientEnum.PATIENT_MODAL}"] div[id="form-dialog-title"] h2`,
    PATIENT_SUMMARY_MODAL: `div[data-test="${PatientEnum.PATIENT_SUMMARY_MODAL}"] div[role="dialog"]`,
    FILTER_PATIENTS_MODAL: `div[data-test="${PatientEnum.FILTER_PATIENTS_MODAL}"] div[role="dialog"]`,
    EMAIL_INPUT: `div[data-test="${PatientEnum.EMAIL_INPUT}"] input`,
    FIRST_NAME_INPUT: `div[data-test="${PatientEnum.FIRST_NAME_INPUT}"] input`,
    LAST_NAME_INPUT: `div[data-test="${PatientEnum.LAST_NAME_INPUT}"] input`,
    FILTER_FIRST_NAME: `div[data-test="${PatientEnum.FILTER_FIRST_NAME}"] input`,
    FILTER_LAST_NAME: `div[data-test="${PatientEnum.FILTER_LAST_NAME}"] input`,
    MEDICAL_RECORD_ID_INPUT: `div[data-test="${PatientEnum.MEDICAL_RECORD_ID_INPUT}"] input`,
    PHONE_NUMBER_INPUT: `div[data-test="${PatientEnum.PHONE_NUMBER_INPUT}"] input`,
    STREET_ADDRESS_1_INPUT: `div[data-test="${PatientEnum.STREET_ADDRESS_1_INPUT}"] input`,
    STREET_ADDRESS_2_INPUT: `div[data-test="${PatientEnum.STREET_ADDRESS_2_INPUT}"] input`,
    CITY_INPUT: `div[data-test="${PatientEnum.CITY_INPUT}"] input`,
    STATE_INPUT: `div[data-test="${PatientEnum.STATE_INPUT}"] input`,
    ZIP_CODE_INPUT: `div[data-test="${PatientEnum.ZIP_CODE_INPUT}"] input`,
};
