/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Card,
    CardHeader,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
} from '@mui/material';
import { ArrowBack, Save } from '@mui/icons-material';
import ObjectId from 'bson-objectid';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { TriggerGlobalConfirm } from '~/state';
import {
    ApplicationListDocument,
    ApplicationListQuery,
    useCreateApplicationMutation,
    useFetchApplicationLazyQuery,
    useUpdateApplicationMutation,
} from '~/schemaTypes';

type Application = NonNullable<ApplicationListQuery['applicationsV2']['results'][0]>;

interface ApplicationFormInput {
    name: string;
    fullName: string;
    appBundleId: string;
    devDeepLink: string;
    stageDeepLink: string;
    iatDeepLink: string;
    uatDeepLink: string;
    prodDeepLink: string;
    emailLogoFile?: string | null | undefined;
    isMaintenance: boolean;
    outOfHoursEnabled: boolean;
    isActive: boolean;
}

const formValuesFromData = (item: Application): ApplicationFormInput => {
    return {
        name: item.name,
        fullName: item.fullName,
        appBundleId: item.appBundleId,
        devDeepLink: item.deepLinkUrls.find(l => l.environment === 'dev')?.url || '',
        stageDeepLink: item.deepLinkUrls.find(l => l.environment === 'stage')?.url || '',
        iatDeepLink: item.deepLinkUrls.find(l => l.environment === 'iat')?.url || '',
        uatDeepLink: item.deepLinkUrls.find(l => l.environment === 'uat')?.url || '',
        prodDeepLink: item.deepLinkUrls.find(l => l.environment === 'prod')?.url || '',
        emailLogoFile: item.emailLogoFile,
        isMaintenance: item.isMaintenance || false,
        isActive: item.isActive,
        outOfHoursEnabled: item.outOfHoursEnabled || false,
    };
};

const formValuesDefault = (): ApplicationFormInput => {
    return {
        name: '',
        fullName: '',
        appBundleId: '',
        devDeepLink: '',
        stageDeepLink: '',
        iatDeepLink: '',
        uatDeepLink: '',
        prodDeepLink: '',
        emailLogoFile: '',
        isMaintenance: false,
        isActive: true,
        outOfHoursEnabled: false,
    };
};

const appFromForm = (values: ApplicationFormInput) => {
    const deepLinkUrls: { environment: string; url: string }[] = [];
    if (values.devDeepLink) {
        deepLinkUrls.push({ environment: 'dev', url: values.devDeepLink });
    }
    if (values.stageDeepLink) {
        deepLinkUrls.push({ environment: 'stage', url: values.stageDeepLink });
    }
    if (values.iatDeepLink) {
        deepLinkUrls.push({ environment: 'iat', url: values.iatDeepLink });
    }
    if (values.uatDeepLink) {
        deepLinkUrls.push({ environment: 'uat', url: values.uatDeepLink });
    }
    if (values.prodDeepLink) {
        deepLinkUrls.push({ environment: 'prod', url: values.prodDeepLink });
    }
    return {
        name: values.name,
        fullName: values.fullName,
        appBundleId: values.appBundleId,
        deepLinkUrls,
        emailLogoFile: values.emailLogoFile,
        isMaintenance: values.isMaintenance,
        isActive: values.isActive,
        outOfHoursEnabled: values.outOfHoursEnabled,
    };
};

const appValidation = Yup.object().shape({
    name: Yup.string().required('Required'),
    fullName: Yup.string().required('Required'),
    appBundleId: Yup.string().required('Required'),
    devDeepLink: Yup.string(),
    stageDeepLink: Yup.string(),
    iatDeepLink: Yup.string(),
    uatDeepLink: Yup.string(),
    prodDeepLink: Yup.string(),
    emailLogoPath: Yup.string(),
    isMaintenance: Yup.boolean(),
    isActive: Yup.boolean(),
    outOfHoursEnabled: Yup.boolean(),
});

const ApplicationEditor: React.FC = () => {
    const history = useNavigate();
    const { id: urlId } = useParams<{ id: string }>();
    const [isModified, setIsModified] = useState(false);
    const isEditMode = urlId !== 'new';
    const [appId, setAppId] = useState(isEditMode ? urlId : new ObjectId().toHexString());
    const [close, setClose] = useState(false);

    const {
        register,
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<ApplicationFormInput>({
        resolver: yupResolver(appValidation as any),
        defaultValues: formValuesDefault(),
    });

    const [fetchAppById, { data: appData, loading: appLoading }] = useFetchApplicationLazyQuery();

    const [updateApp, { loading: updateLoading }] = useUpdateApplicationMutation({
        onCompleted: () => {
            if (close) history(`/app-config/apps`);
        },
        update: (cache, response) => {
            const updatedItem = response.data?.updateApplication?.resourceUpdated;
            if (response.data?.updateApplication?.success && updatedItem) {
                const currentItems = cache.readQuery<ApplicationListQuery>({
                    query: ApplicationListDocument,
                });
                if (currentItems?.applicationsV2.results) {
                    cache.writeQuery<ApplicationListQuery>({
                        query: ApplicationListDocument,
                        data: {
                            applicationsV2: {
                                results: [
                                    ...currentItems.applicationsV2.results.map(item => {
                                        if (item.id === updatedItem.id) {
                                            return updatedItem;
                                        }
                                        return item;
                                    }),
                                ],
                            },
                        },
                    });
                }
            }
        },
    });

    const [createApp, { loading: createLoading }] = useCreateApplicationMutation({
        onCompleted: data => {
            if (close) history(`/app-config/apps`);
            else history(`/app-config/apps/${data.createApplication?.resourceCreated?.id}`);
        },
        update: (cache, response) => {
            const newItem = response.data?.createApplication?.resourceCreated;
            setAppId(newItem?.id);
            if (response.data?.createApplication?.success && newItem) {
                const currentItems = cache.readQuery<ApplicationListQuery>({
                    query: ApplicationListDocument,
                });
                if (currentItems?.applicationsV2.results) {
                    cache.writeQuery<ApplicationListQuery>({
                        query: ApplicationListDocument,
                        data: {
                            applicationsV2: {
                                __typename: currentItems.applicationsV2.__typename,
                                results: [...currentItems.applicationsV2.results, newItem],
                            },
                        },
                    });
                }
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: ApplicationListDocument,
                variables: {
                    input: {},
                },
            },
        ],
    });

    const onSubmit = async (values: ApplicationFormInput) => {
        const data = appFromForm(values);
        if (isEditMode && appId) {
            await updateApp({
                variables: {
                    input: {
                        id: appId,
                        data: {
                            ...data,
                        },
                    },
                },
            });
        } else {
            await createApp({
                variables: {
                    input: {
                        ...data,
                    },
                },
            });
        }
        setIsModified(false);
    };

    const onNavigateAway = () => {
        if (isModified)
            TriggerGlobalConfirm({
                message: `You have unsaved changes. Are you sure you want to return to the Application List?`,
                callback: () => {
                    history(`/app-config/apps/`);
                },
            });
        else history(`/app-config/apps/`);
    };

    const onEdit = () => {
        setIsModified(true);
    };

    useEffect(() => {
        if (isEditMode) {
            fetchAppById({ variables: { input: { id: appId } } });
            if (appData && appData.application) {
                reset(formValuesFromData(appData.application));
            }
        }
    }, [appData, appId, fetchAppById, isEditMode, reset]);

    if (appLoading || updateLoading || createLoading) return <Loading />;
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                    Back to Application List
                </Button>
            </Grid>
            <Grid item xs={12}>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        <CardHeader title={`${isEditMode ? 'Edit' : 'Create'} Application`} />
                        <OutlinedSection title="Full Name *">
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                {...register('fullName')}
                                error={!!errors.fullName}
                                helperText={errors.fullName?.message}
                                onChange={onEdit}
                            />
                        </OutlinedSection>
                        <OutlinedSection title="From Name *">
                            <div style={{ fontStyle: 'italic', fontSize: '.8em' }}>
                                (used as &apos;From&apos; in emails)
                            </div>
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                onChange={onEdit}
                            />
                        </OutlinedSection>
                        <OutlinedSection title="App Bundle Id *">
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                {...register('appBundleId')}
                                error={!!errors.appBundleId}
                                helperText={errors.appBundleId?.message}
                                onChange={onEdit}
                                disabled={isEditMode}
                            />
                        </OutlinedSection>
                        <OutlinedSection title="Email Logo Filename">
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                {...register('emailLogoFile')}
                                error={!!errors.emailLogoFile}
                                helperText={errors.emailLogoFile?.message}
                                onChange={onEdit}
                            />
                        </OutlinedSection>
                        <OutlinedSection title="Deep Link URLs">
                            <div>
                                Dev:{' '}
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    {...register('devDeepLink')}
                                    error={!!errors.devDeepLink}
                                    helperText={errors.devDeepLink?.message}
                                    onChange={onEdit}
                                />
                            </div>
                            <div>
                                Staging:{' '}
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    {...register('stageDeepLink')}
                                    error={!!errors.stageDeepLink}
                                    helperText={errors.stageDeepLink?.message}
                                    onChange={onEdit}
                                />
                            </div>
                            <div>
                                IAT:{' '}
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    {...register('iatDeepLink')}
                                    error={!!errors.iatDeepLink}
                                    helperText={errors.iatDeepLink?.message}
                                    onChange={onEdit}
                                />
                            </div>
                            <div>
                                UAT:{' '}
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    {...register('uatDeepLink')}
                                    error={!!errors.uatDeepLink}
                                    helperText={errors.uatDeepLink?.message}
                                    onChange={onEdit}
                                />
                            </div>
                            <div>
                                Production:{' '}
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    {...register('prodDeepLink')}
                                    error={!!errors.prodDeepLink}
                                    helperText={errors.prodDeepLink?.message}
                                    onChange={onEdit}
                                />
                            </div>
                        </OutlinedSection>
                        <OutlinedSection title="Settings">
                            <Controller
                                name="isActive"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        style={{ paddingRight: '10px' }}
                                        label="Is Active"
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={!!field.value}
                                                color="primary"
                                            />
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="isMaintenance"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        style={{ paddingRight: '10px' }}
                                        label="In Maintenance Mode"
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={!!field.value}
                                                color="primary"
                                            />
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="outOfHoursEnabled"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        style={{ paddingRight: '10px' }}
                                        label="Out Of Hours Adjustment Enabled"
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={!!field.value}
                                                color="primary"
                                            />
                                        }
                                    />
                                )}
                            />
                        </OutlinedSection>
                        <div style={{ width: '100%', marginTop: '10px', textAlign: 'right' }}>
                            <Button
                                type="submit"
                                startIcon={<Save />}
                                color="secondary"
                                variant="contained"
                                onClick={() => handleSubmit(onSubmit)}
                            >
                                Save
                            </Button>
                            <Button
                                type="submit"
                                startIcon={<Save />}
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                    setClose(true);
                                    handleSubmit(onSubmit);
                                }}
                            >
                                Save &amp; Close
                            </Button>
                        </div>
                    </Card>
                </form>
            </Grid>
        </Grid>
    );
};

export default ApplicationEditor;
