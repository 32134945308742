import React, { useState, useCallback, useEffect } from 'react';
import { Grid, Typography, Button, Dialog, Checkbox, FormControlLabel } from '@mui/material';
import MaterialTable from '@material-table/core';
import { Add } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import {
    useNotificationSettingsFetchAffiliateQuery,
    useNotificationSettingsUpdateForAffiliateMutation,
    NotificationSettingsFetchAffiliateDocument,
} from '~/schemaTypes';
import { useAffiliateCareView } from '~/hooks';
import { FormInput } from './types';
import AddEmailDialog from './AddEmailDialog';
import Layout from '../components/Layout';

const Notification = () => {
    const { userAffiliateId, id } = useAffiliateCareView();
    const [showAddForm, setShowAddForm] = useState(false);
    const [
        notifyCompanyWhenPatientPassesEligibility,
        setNotifyCompanyWhenPatientPassesEligibility,
    ] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [tableData, setTableData] = useState<FormInput[]>([]);
    const { data, loading: fetchNotificationLoading } = useNotificationSettingsFetchAffiliateQuery({
        variables: {
            input: {
                affiliateId: userAffiliateId,
            },
        },
    });
    const [updateSettings, { loading: updateLoading }] =
        useNotificationSettingsUpdateForAffiliateMutation({
            refetchQueries: [
                {
                    query: NotificationSettingsFetchAffiliateDocument,
                    variables: { input: { affiliateId: userAffiliateId } },
                },
            ],
        });
    useEffect(() => {
        if (data?.virtualCareAffiliateByAffiliateId?.data) {
            setTableData(
                (data.virtualCareAffiliateByAffiliateId.data.notificationEmail || []).map(
                    (email: string) => ({
                        email,
                    }),
                ),
            );
            setNotifyCompanyWhenPatientPassesEligibility(
                !!data.virtualCareAffiliateByAffiliateId.data
                    .notifyCompanyWhenPatientPassesEligibility,
            );
        }
    }, [data?.virtualCareAffiliateByAffiliateId?.data]);
    useEffect(() => {
        const initNotifyValue =
            !!data?.virtualCareAffiliateByAffiliateId?.data
                ?.notifyCompanyWhenPatientPassesEligibility;
        const initEmails = [
            ...(data?.virtualCareAffiliateByAffiliateId?.data?.notificationEmail || []),
        ];
        initEmails.sort();
        const initEmailsASString = initEmails.join(',');
        const currentEmails = tableData.map(({ email }) => email);
        currentEmails.sort();
        const currentEmailsAsString = currentEmails.join(',');
        setIsDirty(
            initNotifyValue !== notifyCompanyWhenPatientPassesEligibility ||
                currentEmailsAsString !== initEmailsASString,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData, notifyCompanyWhenPatientPassesEligibility]);
    const addHandler = useCallback((newEmail?: string) => {
        if (newEmail) {
            setTableData(prev => [...prev, { email: newEmail }]);
        }
        setShowAddForm(false);
    }, []);
    const deleteHandler = useCallback((deleteEmail: string) => {
        setTableData(prev => {
            const emailIndex = prev.findIndex(({ email }) => email === deleteEmail);
            const newList = [...prev];
            if (emailIndex !== -1) {
                newList.splice(emailIndex, 1);
                return newList;
            }
            return newList;
        });
    }, []);
    const saveHandler = useCallback(() => {
        updateSettings({
            variables: {
                input: {
                    id,
                    data: {
                        notificationEmail: tableData.map(({ email }) => email),
                        notifyCompanyWhenPatientPassesEligibility,
                    },
                },
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData, notifyCompanyWhenPatientPassesEligibility, id]);
    const loading = fetchNotificationLoading || updateLoading;
    return (
        <>
            <Layout title="Notifications">
                <Grid item xs={12} md={8} container>
                    <OutlinedSection title="Notifications">
                        <Grid
                            item
                            container
                            xs={12}
                            rowSpacing={2}
                            paddingTop={3}
                            paddingBottom={3}
                        >
                            <Grid item xs={12}>
                                <MaterialTable<FormInput>
                                    title="Notification emails"
                                    columns={[
                                        {
                                            title: 'Email',
                                            render: ({ email }) => <Typography>{email}</Typography>,
                                            sorting: false,
                                        },
                                    ]}
                                    data={tableData}
                                    localization={{ header: { actions: '' } }}
                                    options={{
                                        paging: false,
                                        search: false,
                                        actionsColumnIndex: -1,
                                    }}
                                    actions={[
                                        {
                                            icon: () => <Add />,
                                            tooltip: 'Add Notification Email',
                                            isFreeAction: true,
                                            onClick: () => setShowAddForm(true),
                                        },
                                        {
                                            icon: () => <FontAwesomeIcon icon={faTrash} />,
                                            tooltip: 'Remove Email',
                                            onClick: (_, rowData: FormInput) =>
                                                deleteHandler(rowData.email),
                                        },
                                    ]}
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    sx={{
                                        alignItems: 'flex-start',
                                    }}
                                    control={
                                        <Checkbox
                                            checked={notifyCompanyWhenPatientPassesEligibility}
                                            onChange={e => {
                                                setNotifyCompanyWhenPatientPassesEligibility(
                                                    e.target.checked,
                                                );
                                            }}
                                            sx={{
                                                paddingTop: 0,
                                            }}
                                        />
                                    }
                                    label="Notify When Patient Passes Eligibility"
                                />
                            </Grid>
                        </Grid>
                    </OutlinedSection>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item>
                        <AsyncActionButton loading={loading}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading || !isDirty}
                                onClick={saveHandler}
                            >
                                Update Notification Preferences
                            </Button>
                        </AsyncActionButton>
                    </Grid>
                </Grid>
            </Layout>
            <Dialog open={showAddForm} scroll="paper">
                <AddEmailDialog onComplete={addHandler} />
            </Dialog>
        </>
    );
};

export default Notification;
