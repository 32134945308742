import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import Patients from '~/views/AffiliateCare/Patients/Patients';
import Loading from '~/components/Loading/Loading';
import Navigation, { TabsConfig } from './Navigation';
import { useAffiliate } from './hooks';

const VCPatients = () => {
    const { affiliateId = '', patientId } = useParams();
    const { affiliate } = useAffiliate(affiliateId);
    if (!affiliate) {
        return <Loading />;
    }
    return (
        <Grid container>
            <Navigation
                currentTab={TabsConfig.patients}
                affiliateId={affiliate.affiliateId}
                id={affiliate.id}
                name={affiliate.internalName}
            />
            <Grid item xs={12}>
                <Patients affiliateId={affiliateId} initPatientId={patientId} portalView />
            </Grid>
        </Grid>
    );
};

export default VCPatients;
