import { PanInfo } from 'framer-motion';
import { AlertSeverity } from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import { clone } from 'lodash';
import { ChatConversation } from '../../MessageCenter.types';

export const getDraggedItemHelper = (
    event: PointerEvent,
    info: PanInfo,
    chatConversations: ChatConversation[],
) => {
    const droppedOn = document.elementsFromPoint(info.point.x, info.point.y);
    const conversationElem = droppedOn.find(elem => elem.getAttribute('data-conversation-id'));
    if (conversationElem == null) {
        return null;
    }

    const endingConversationId = conversationElem.getAttribute('data-conversation-id');
    if (endingConversationId == null) {
        return null;
    }

    const endConversation = chatConversations.find(
        conversation => conversation.id === endingConversationId,
    );
    if (endConversation == null) {
        return null;
    }

    // check if dropped at the bottom of the conversation where no message id will be found
    const position = droppedOn
        .find(elem => elem.getAttribute('data-position'))
        ?.getAttribute('data-position');
    if (position === 'bottom') {
        if (endConversation.messages?.length == null) {
            return null;
        }
        return {
            endConversation,
            messageIndex: endConversation.messages.length - 1,
        };
    }

    const messageElem = droppedOn.find(elem => elem.getAttribute('data-message-id'));
    if (messageElem == null) {
        return null;
    }

    const messageId = messageElem.getAttribute('data-message-id');

    const messageIndex = endConversation.messages?.findIndex(message => message.id === messageId);
    if (messageIndex == null) {
        return null;
    }

    return {
        endConversation,
        messageIndex,
    };
};

export const handleMoveChatMessages = ({
    conversations,
    startingConversationId,
    endingConversationId,
    messageIds,
}: {
    conversations: ChatConversation[];
    startingConversationId: string;
    endingConversationId: string;
    messageIds: string[];
}) => {
    const clonedConversations = clone(conversations);

    const startConversationIndex = clonedConversations.findIndex(
        conversation => conversation.id === startingConversationId,
    );
    const startConversation = clonedConversations[startConversationIndex];

    if (startConversation == null) {
        TriggerGlobalAlert({
            message: `Failed to move messages: starting conversation not found with id: ${startingConversationId}`,
            severity: AlertSeverity.Error,
        });
        return {
            success: false,
        };
    }

    let endConversationIndex = clonedConversations.findIndex(
        conversation => conversation.id === endingConversationId,
    );
    const originalEndConversationIndex = endConversationIndex;

    if (startingConversationId === endingConversationId) {
        endConversationIndex = startConversationIndex - 1;
    }
    const endConversation = clonedConversations[endConversationIndex];

    if (endConversation == null) {
        TriggerGlobalAlert({
            message: `Failed to move messages: ending conversation not found with id: ${endingConversationId}`,
            severity: AlertSeverity.Error,
        });
        return {
            success: false,
        };
    }

    let updatedConversation: ChatConversation[] | undefined;

    if (startConversationIndex <= originalEndConversationIndex) {
        // drag down
        updatedConversation = clonedConversations.map(conversation => {
            if (conversation.id === endConversation.id) {
                if (startConversation.messages == null) {
                    TriggerGlobalAlert({
                        message: `Failed to move messages: starting conversation has no messages`,
                        severity: AlertSeverity.Error,
                    });
                    return conversation;
                }
                return {
                    ...conversation,
                    messages: [
                        ...(conversation.messages != null ? conversation.messages : []),
                        ...startConversation.messages.filter(message =>
                            messageIds.includes(message.id),
                        ),
                    ],
                };
            }
            if (conversation.id === startConversation.id) {
                const messages = conversation.messages?.filter(
                    message => !messageIds.includes(message.id),
                );

                return {
                    ...conversation,
                    messages,
                    startedAt:
                        messages != null && messages.length > 0 ? messages[0].createdAt : null,
                };
            }
            return conversation;
        });
    } else {
        // drag up
        updatedConversation = clonedConversations.map(conversation => {
            if (conversation.id === endConversation.id) {
                return {
                    ...conversation,
                    messages: endConversation.messages?.filter(
                        message => !messageIds.includes(message.id),
                    ),
                };
            }
            if (conversation.id === startConversation.id) {
                if (endConversation.messages == null) {
                    TriggerGlobalAlert({
                        message: `Failed to move messages: ending conversation has no messages`,
                        severity: AlertSeverity.Error,
                    });
                    return conversation;
                }
                const messages = [
                    ...endConversation.messages?.filter(message => messageIds.includes(message.id)),
                    ...(startConversation.messages != null ? startConversation.messages : []),
                ];
                return {
                    ...conversation,
                    messages,
                    startedAt: messages[0].createdAt,
                };
            }
            return conversation;
        });
    }

    return {
        success: true,
        endConversationId:
            startConversationIndex <= originalEndConversationIndex
                ? endConversation.id
                : startConversation.id,
        updatedStartedAt: updatedConversation[startConversationIndex].startedAt,
    };
};
