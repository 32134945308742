import React from 'react';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { Grid } from '@mui/material';
import { CoverageArea } from '~/views/AffiliateCare/components/CoverageAreas/CoverageAreas';
import { OfficeAddress } from '~/views/AffiliateCare/components/OfficeAddresses/OfficeAddresses';
import VirtualType from '~/views/AffiliateCare/Onboarding/steps/ServiceTypesAndLocations/components/VirtualType/VirtualType';
import InOfficeType from '~/views/AffiliateCare/Onboarding/steps/ServiceTypesAndLocations/components/InOfficeType/InOfficeType';
import PatientsHomeType from '~/views/AffiliateCare/Onboarding/steps/ServiceTypesAndLocations/components/PatientsHomeType/PatientsHomeType';

const ServiceTypesCoverages = () => {
    return (
        <OutlinedSection title="Service Types and Coverage">
            <Grid item xs={12} container>
                <VirtualType />
            </Grid>
            <Grid item xs={12} container>
                <InOfficeType showHelperText={false} />
            </Grid>
            <Grid item xs={12} container>
                <PatientsHomeType showHelperText={false} />
            </Grid>
        </OutlinedSection>
    );
};

export default ServiceTypesCoverages;

export type { CoverageArea, OfficeAddress };
