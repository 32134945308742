import { CircularProgress, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

const useStyles = makeStyles<{ height: number }>()((_, { height }) => ({
    root: {
        height,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    spinner: {
        marginBottom: 20,
    },
}));

type LoadingProps = {
    height?: number | string;
    subtitle?: string;
};

const Loading: React.FC<LoadingProps> = ({ height = 400, subtitle = '' }) => {
    const { classes } = useStyles({
        height: typeof height === 'number' ? height : parseInt(height, 10),
    });

    return (
        <div className={classes.root}>
            <CircularProgress className={classes.spinner} />
            <Typography variant="h6">{subtitle}</Typography>
        </div>
    );
};

export default Loading;
