import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

export const VCAffiliateFormContext = createContext<{
    isValid: boolean;
    setIsValid: (v: boolean) => void;
}>({
    isValid: false,
    setIsValid: () => undefined,
});
export const VCAffiliateFormContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [isValid, setIsValid] = useState(false);
    const providerValues = useMemo(() => ({ isValid, setIsValid }), [isValid, setIsValid]);
    return (
        <VCAffiliateFormContext.Provider value={providerValues}>
            {children}
        </VCAffiliateFormContext.Provider>
    );
};
export const useVCAffiliateFormContext = () => {
    return useContext(VCAffiliateFormContext);
};
