import React from 'react';
import { Button, Grid } from '@mui/material';
import { Close } from '@mui/icons-material';

type CloseButtonProps = {
    handleClose: () => void;
};

const CloseButton: React.FC<CloseButtonProps> = props => {
    const { handleClose } = props;
    return (
        <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
            <Grid item xs="auto">
                <Button variant="text" color="inherit" size="small" onClick={handleClose}>
                    <Close />
                </Button>
            </Grid>
        </Grid>
    );
};

export default CloseButton;
