import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    resetPadding: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    filterButtonWrapper: {
        marginTop: 10,
    },
    emptyState: {
        fontWeight: 'lighter',
    },
    contentSelect: {
        marginBottom: 10,
    },
    contentTextField: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    error: {
        color: '#e74c3c',
    },
});
