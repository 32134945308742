import { FetchAdvocateTaskForAdvocateTaskPageQuery } from '~/schemaTypes';
import { HistoryFigure } from '../History/types';

type historyFigure = NonNullable<
    FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']
>['historyFigures'][0];

type ToHistoryFiguresForm = (historyFigures: historyFigure[]) => HistoryFigure[];

const toHistoryFiguresForm: ToHistoryFiguresForm = historyFigures =>
    historyFigures.map(({ id, name }) => ({
        id,
        name,
    }));

export default toHistoryFiguresForm;
