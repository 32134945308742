/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Accordion,
    Button,
    Card,
    CardActions,
    CardHeader,
    Checkbox,
    Collapse,
    FormControlLabel,
    Grid,
    TextField,
} from '@mui/material';
import { ArrowBack, ExpandLess, ExpandMore, Save } from '@mui/icons-material';
import ObjectID from 'bson-objectid';
import omitDeep from 'omit-deep-lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import ContentIconSelect from '~/components/ContentIconSelect/ContentIconSelect';
import ImageContentPicker from '~/components/ImageContentPicker/ImageContentPicker';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { ROOT_ORG_ID } from '~/constants';
import {
    ContentIcon,
    SurveyDef,
    SurveyDefButtonsInput,
    SurveyDefCompoundQuestionInput,
    SurveyDefContentInput,
    SurveyDefDivision,
    SurveyDefListDocument,
    SurveyDefListQuery,
    SurveyDefPiece,
    SurveyDefPieceInput,
    SurveyDefPieceType,
    SurveyDefQuestionDisplayType,
    SurveyDefQuestionInput,
    SurveyDefSection,
    SurveyDefTextInput,
    SurveyDefTutorialContentInput,
    useCreateSurveyDefForSurveyBuilderMutation,
    useFetchSurveyDefForSurveyBuilderLazyQuery,
    useParentSurveysDefsLazyQuery,
    usePreFetchOrgsProfileDefsForSurveyBuilderQuery,
    useSurveyDefListQuery,
    useUpdateSurveyDefForSurveyBuilderMutation,
} from '~/schemaTypes';
import { SurveyEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';
import { CompoundQuestionModalCloseType } from '~/views/ConfigDashboard/CompoundQuestions/types';
import PieceCompoundQuestionModal from './PieceCompoundQuestionModal/PieceCompoundQuestionModal';
import CompoundQuestionModal from '../../CompoundQuestions/CompoundQuestionModel/CompoundQuestionModal';
import { SurveyCompoundQuestionFormInput } from './PieceCompoundQuestionModal/types';
import MessageModal, { MessageModalProps } from './MessageModal';
import ButtonTextModal from './ButtonTextModal';
import { DivisionActionDropdown } from './components/DivisionActionDropdown';
import { SectionActionDropdown } from './components/SectionActionDropdown';
import { DIVISION_ACTIONS_ENUM } from './enums/divisionActionsEnum';
import { SECTION_ACTIONS_ENUM } from './enums/sectionActionsEnum';
import NameModal from './NameModal';
import PieceContentModal from './PieceContentModal';
import PieceQuestionModal from './PieceQuestionModal';
import PieceTextModal from './PieceTextModal';
import PieceTutorialContentModal from './PieceTutorialContentModal';
import ReorderModal from './ReorderModal';
import { useStyles } from './styles';
import SubSurveyModal from './SubSurveyModal';

/*
NOTE: The text shown on the page for Divisions and Sections has been changed by the Product Team.
'Division' is displayed as 'Section'. 'Section' is displayed as 'Screen'.
*/

interface pieceModalParms {
    divId: string;
    secId: string;
    pieceId: string;
    expressionList?: (string | null)[] | null | undefined;
    content?: SurveyDefContentInput | null | undefined;
    text?: SurveyDefTextInput | null | undefined;
    question?: SurveyDefQuestionInput | null | undefined;
    compoundQuestion?: SurveyDefCompoundQuestionInput | null | undefined;
    tutorialContent?: SurveyDefTutorialContentInput | null | undefined;
}

interface SurveyDefFormInput {
    name?: string | null;
    label: { en: string; es?: string | null };
    surveyType: string;
    summaryText: {
        title: { en: string; es?: string | null };
        body: { en: string; es?: string | null };
    };
    disableRestart?: boolean | null;
    restartFromBeginning?: boolean | null;
    requiresAdvocateReview?: boolean | null;
    summary?: boolean | null;
    shouldScore?: boolean | null;
}

const SurveyValidation = Yup.object().shape({
    name: Yup.string().required('Required'),
    label: Yup.object().shape({
        en: Yup.string().required('Required'),
        es: Yup.string(),
    }),
    surveyType: Yup.string().required('Required'),
    summaryText: Yup.object().shape({
        title: Yup.object().shape({
            en: Yup.string().required('Required'),
            es: Yup.string(),
        }),
        body: Yup.object().shape({
            en: Yup.string().required('Required'),
            es: Yup.string(),
        }),
    }),
});

const NEW_SURVEY_ID = 'new';

const SurveyEditor: React.FC = () => {
    const history = useNavigate();
    const { classes } = useStyles();
    const { id: surveyDefId } = useParams<{ id: string }>();
    let isEditMode = surveyDefId !== NEW_SURVEY_ID;
    const [nameModalIsOpen, setNameModalState] = useState(false);
    const [reorderModalIsOpen, setReorderModalState] = useState(false);
    const [buttonModalIsOpen, setButtonModalState] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState<ContentIcon>(ContentIcon.None);
    const [pieceContentModalIsOpen, setPieceContentModalState] = useState(false);
    const [pieceTextModalIsOpen, setPieceTextModalState] = useState(false);
    const [pieceQuestionModalIsOpen, setPieceQuestionModalState] = useState(false);
    const [pieceCompoundQuestionModalIsOpen, setPieceCompoundQuestionModalState] = useState(false);
    const [compoundQuestionModalData, setCompoundQuestionModalData] = useState<{
        id: string;
        payload: {
            divId: string;
            secId: string;
            pieceId: string;
        };
    } | null>(null);
    const [pieceTutorialContentModalIsOpen, setPieceTutorialContentModalState] = useState(false);
    const [divActionAnchor, setDivActionAnchor] = useState<null | HTMLElement>(null);
    const [secActionAnchor, setSecActionAnchor] = useState<null | HTMLElement>(null);
    const [divisions, setDivisions] = useState<SurveyDefDivision[]>([]);
    const [divisionSelected, setDivisionSelected] = useState<SurveyDefDivision>();
    const [sectionSelected, setSectionSelected] = useState<SurveyDefSection>();
    const [buttonText, setButtonText] = useState<SurveyDefButtonsInput>();
    const [detailsExpanded, setDetailsExpanded] = useState(!isEditMode);
    const [isModified, setIsModified] = useState(false);
    const [pieceModalParms, setPieceModalParms] = useState<pieceModalParms>();
    const [close, setClose] = useState(false);
    const [selectedImageId, setSelectedImageId] = useState<string>();
    const [nameModalParms, setNameModalParms] = useState<{
        title: string;
        typeName: string;
        expressionList: (string | null)[] | null | undefined;
        divisionId: string;
        sectionId: string;
        label: { en: string; es: string };
    }>();
    const [reorderModalParms, setReorderModalParms] = useState<{
        title: string;
        divisionId: string;
        sectionId: string;
        items: { id: number; name: string }[];
    }>();
    const [subSurveyModalIsOpen, setSubSurveyModalState] = useState(false);
    const subSurveyModalParamsDefaults = {
        title: '',
        divisionId: '',
        editMode: false,
        surveyDefId: '',
    };
    const [subSurveyModalParms, setSubSurveyModalParms] = useState<{
        title: string;
        divisionId: string;
        editMode: boolean;
        surveyDefId: string;
    }>(subSurveyModalParamsDefaults);

    const [messageModalProps, setMessageModalProps] = useState<MessageModalProps | null>(null);

    const {
        control,
        register,
        handleSubmit,
        setError,

        formState: { errors },
        setValue,
    } = useForm<SurveyDefFormInput>({
        // @ts-expect-error RHF V7 limitation #7895 will be fixed in V8 of react-hook-form
        resolver: yupResolver(SurveyValidation),
    });

    const [getParentSurveysDefs] = useParentSurveysDefsLazyQuery();
    const { data: surveyList, loading: surveyListLoading } = useSurveyDefListQuery();

    const { data: preFetchData, loading: preFetchLoading } =
        usePreFetchOrgsProfileDefsForSurveyBuilderQuery();

    const [fetchSurveyById, { data: surveyData, loading: surveyLoading }] =
        useFetchSurveyDefForSurveyBuilderLazyQuery();

    useEffect(() => {
        if (surveyData && surveyData.surveyDef) {
            setValue('name', surveyData.surveyDef.name);
            setValue('label.en', surveyData.surveyDef.label.en);
            setValue('label.es', surveyData.surveyDef.label.es);
            setValue('surveyType', surveyData.surveyDef.surveyType);
            setValue('disableRestart', surveyData.surveyDef.disableRestart);
            setValue('restartFromBeginning', surveyData.surveyDef.restartFromBeginning);
            setValue('requiresAdvocateReview', surveyData.surveyDef.requiresAdvocateReview);
            setValue('summary', surveyData.surveyDef.summary);
            setValue('shouldScore', surveyData.surveyDef.shouldScore);
            setValue('summaryText.title.en', surveyData.surveyDef.summaryText.title.en);
            setValue('summaryText.title.es', surveyData.surveyDef.summaryText.title.es);
            setValue('summaryText.body.en', surveyData.surveyDef.summaryText.body.en);
            setValue('summaryText.body.es', surveyData.surveyDef.summaryText.body.es);
            setDivisions(surveyData.surveyDef.divisions as SurveyDefDivision[]);
            setButtonText(surveyData.surveyDef.buttons);
            if (surveyData.surveyDef.icon) setSelectedIcon(surveyData.surveyDef.icon);
            if (surveyData.surveyDef.imageContentId)
                setSelectedImageId(surveyData.surveyDef.imageContentId);
        }
    }, [surveyData, setValue]);
    // eslint-disable-next-line
    const [updateSurveyDef, { loading: updateSurveyDefLoading }] =
        useUpdateSurveyDefForSurveyBuilderMutation({
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        setIsModified(true);
                        setClose(false);
                    },
                    message: `There was a problem saving the survey: ${error.message}`,
                });
            },
            onCompleted: () => {
                if (close) {
                    history('/app-config/surveys/');
                }
            },
            update: (cache, response) => {
                const updatedSurveyDef = response.data?.updateSurveyDef?.resourceUpdated;
                if (response.data?.updateSurveyDef?.success && updatedSurveyDef) {
                    const currentSurveys = cache.readQuery<SurveyDefListQuery>({
                        query: SurveyDefListDocument,
                    });
                    if (currentSurveys?.surveyDefs) {
                        cache.writeQuery<SurveyDefListQuery>({
                            query: SurveyDefListDocument,
                            data: {
                                surveyDefs: [
                                    ...currentSurveys.surveyDefs.map(surveyDef => {
                                        if (surveyDef.id === updatedSurveyDef.id) {
                                            return updatedSurveyDef;
                                        }
                                        return surveyDef;
                                    }),
                                ],
                            },
                        });
                    }
                }
            },
        });
    // eslint-disable-next-line
    const [createSurveyDef, { loading: createSurveyDefLoading }] =
        useCreateSurveyDefForSurveyBuilderMutation({
            onCompleted: data => {
                isEditMode = true;
                setDetailsExpanded(false);
                if (close) {
                    history('/app-config/surveys/');
                } else {
                    history(
                        `/app-config/surveys/${
                            data.createSurveyDef?.resourceCreated?.id ?? NEW_SURVEY_ID
                        }`,
                    );
                }
            },
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        setIsModified(true);
                        setClose(false);
                    },
                    message: `There was a problem saving the survey: ${error.message}`,
                });
            },
            update: (cache, response) => {
                const newSurveyDef = response.data?.createSurveyDef?.resourceCreated;
                if (response.data?.createSurveyDef?.success && newSurveyDef) {
                    const currentSurveys = cache.readQuery<SurveyDefListQuery>({
                        query: SurveyDefListDocument,
                    });
                    if (currentSurveys?.surveyDefs) {
                        cache.writeQuery<SurveyDefListQuery>({
                            query: SurveyDefListDocument,
                            data: {
                                surveyDefs: [...currentSurveys.surveyDefs, newSurveyDef],
                            },
                        });
                    }
                }
            },
        });

    const onInvalid = () => {
        setDetailsExpanded(true);
    };

    const onSubmit = (values: any) => {
        if (
            divisions.length === 0 ||
            divisions.filter(div => {
                const isSectionEmpty = div.sections.length === 0;
                const isSectionPiecesEmpty =
                    isSectionEmpty ||
                    div.sections.filter(sec => sec.pieces.length === 0).length > 0;
                const isSubSurveyEmpty =
                    div.subSurveyDefId === '' ||
                    div.subSurveyDefId === null ||
                    div.subSurveyDefId === undefined;
                return isSectionPiecesEmpty && isSubSurveyEmpty;
            }).length > 0
        ) {
            TriggerGlobalConfirm({
                callback: () => {
                    setIsModified(true);
                },
                message: 'Section, Screen or Item missing',
            });
        } else if (
            surveyList &&
            ((isEditMode &&
                surveyList.surveyDefs.filter(
                    t =>
                        t.id !== surveyDefId && t.name?.toLowerCase() === values.name.toLowerCase(),
                ).length > 0) ||
                (!isEditMode &&
                    surveyList.surveyDefs.filter(
                        t => t.name?.toLowerCase() === values.name.toLowerCase(),
                    ).length > 0))
        ) {
            setError('name', { message: 'Name already used. Please change.' });
        } else {
            const newValues = {
                ...values,
                divisions: divisions
                    ? omitDeep(divisions, '__typename', 'articleName', 'videoName', 'subSurveyDef')
                    : null,
                ...(selectedIcon ? { icon: selectedIcon } : {}),
                ...(selectedImageId ? { imageContentId: selectedImageId } : {}),
                buttons: buttonText
                    ? omitDeep(buttonText, '__typename')
                    : {
                          saveSurvey: { label: { en: 'Save', es: 'Guardar' } },
                          end: { label: { en: 'Submit my answers', es: 'Enviar mis respuestas' } },
                          next: { label: { en: 'Next', es: 'Siguiente' } },
                          previous: { label: { en: 'Previous', es: 'Semana anterior' } },
                      },
            };
            if (isEditMode && surveyDefId) {
                updateSurveyDef({
                    variables: {
                        surveyDefUpdateInput: {
                            id: surveyDefId,
                            data: {
                                ...newValues,
                            },
                        },
                    },
                });
            } else {
                createSurveyDef({
                    variables: {
                        createSurveyDefInput: {
                            ...newValues,
                            organizationId: ROOT_ORG_ID, // All surveys are assigned to Wildflower Health
                        },
                    },
                });
            }
            setIsModified(false);
        }
    };

    const onEdit = () => {
        setIsModified(true);
    };

    const addDivisionDialog = () => {
        setNameModalParms({
            title: 'Add a ',
            typeName: 'Division',
            divisionId: '',
            sectionId: '',
            label: { en: '', es: '' },
            expressionList: null,
        });
        setNameModalState(true);
    };
    const editDivisionDialog = (div: SurveyDefDivision) => {
        setNameModalParms({
            title: 'Edit ',
            typeName: 'Division',
            divisionId: div.id,
            sectionId: '',
            expressionList: div.conditionExpressionIds,
            label: { en: div.label.en, es: div.label?.es || '' },
        });
        setNameModalState(true);
    };
    const editSectionDialog = (div: SurveyDefDivision, sec: SurveyDefSection) => {
        setNameModalParms({
            title: 'Edit ',
            typeName: 'Section',
            divisionId: div.id,
            sectionId: sec.id,
            expressionList: sec.conditionExpressionIds,
            label: { en: sec.label.en, es: sec.label?.es || '' },
        });
        setNameModalState(true);
    };
    const addSectionDialog = (div: SurveyDefDivision) => {
        setNameModalParms({
            title: 'Add a ',
            typeName: 'Section',
            divisionId: div.id,
            sectionId: '',
            expressionList: [null],
            label: { en: '', es: '' },
        });
        setNameModalState(true);
    };
    const reorderDivisionsDialog = () => {
        setReorderModalParms({
            title: 'Reorder Sections',
            divisionId: '',
            sectionId: '',
            items: divisions.map((div, index) => {
                return { id: index + 1, name: div.label.en };
            }),
        });
        setReorderModalState(true);
    };
    const reorderSectionsDialog = (div: SurveyDefDivision) => {
        setReorderModalParms({
            title: `Reorder Screens for ${div.label.en}`,
            divisionId: div.id,
            sectionId: '',
            items: div.sections.map((sec, index) => {
                return { id: index + 1, name: sec.label.en };
            }),
        });
        setReorderModalState(true);
    };
    const addSubSurveyDialog = (div: SurveyDefDivision) => {
        setSubSurveyModalParms({
            title: `Add Sub Survey for ${div.label.en}`,
            divisionId: div.id,
            editMode: false,
            surveyDefId: '',
        });
        setSubSurveyModalState(true);
    };
    const editSubSurveyDialog = (div: SurveyDefDivision) => {
        setSubSurveyModalParms({
            title: `Edit Sub Survey for ${div.label.en}`,
            divisionId: div.id,
            editMode: true,
            surveyDefId: div.subSurveyDefId,
        });
        setSubSurveyModalState(true);
    };
    const reorderPiecesDialog = (divId: string, sec: SurveyDefSection) => {
        setReorderModalParms({
            title: 'Reorder Items',
            divisionId: divId,
            sectionId: sec.id,
            items: sec.pieces.map((piece, index) => {
                let pieceName: string | null | undefined;
                switch (piece.type) {
                    case SurveyDefPieceType.Content:
                        pieceName = `Article: ${piece.content?.articleName}`;
                        break;
                    case SurveyDefPieceType.Text:
                        pieceName = `Text: ${piece.text?.title?.en}`;
                        break;
                    case SurveyDefPieceType.TutorialContent:
                        pieceName = `Tutorial Content: ${piece.tutorialContent?.videoName}`;
                        break;
                    default:
                        pieceName = `Question: ${
                            piece.question?.labelOverride?.en ||
                            preFetchData?.userProfileDefs.find(
                                p => p.id === piece.question?.questionProfileDefId,
                            )?.questionLabel?.en
                        }`;
                        break;
                }
                return { id: index + 1, name: pieceName != null ? pieceName : 'Unknown' };
            }),
        });
        setReorderModalState(true);
    };
    const editPieceContent = (
        divId: string,
        secId: string,
        pieceId: string,
        expressionList: (string | null)[] | null | undefined,
        content: SurveyDefContentInput | null | undefined,
    ) => {
        setPieceModalParms({
            divId,
            secId,
            pieceId,
            content,
            expressionList,
            text: null,
            question: null,
            compoundQuestion: null,
            tutorialContent: null,
        });
        setPieceContentModalState(true);
    };
    const editPieceText = (
        divId: string,
        secId: string,
        pieceId: string,
        expressionList: (string | null)[] | null | undefined,
        text: SurveyDefTextInput | null | undefined,
    ) => {
        setPieceModalParms({
            divId,
            secId,
            pieceId,
            expressionList,
            content: null,
            text,
            question: null,
            compoundQuestion: null,
            tutorialContent: null,
        });

        setPieceTextModalState(true);
    };
    const editPieceQuestion = (
        divId: string,
        secId: string,
        pieceId: string,
        expressionList: (string | null)[] | null | undefined,
        question: SurveyDefQuestionInput | null | undefined,
    ) => {
        if (preFetchData != null && preFetchData.userProfileDefs != null) {
            setPieceModalParms({
                divId,
                secId,
                pieceId,
                expressionList,
                content: null,
                text: null,
                question,
                compoundQuestion: null,
                tutorialContent: null,
            });
            setPieceQuestionModalState(true);
        }
    };
    const editPieceCompoundQuestion = (
        divId: string,
        secId: string,
        pieceId: string,
        expressionList: (string | null)[] | null | undefined,
        compoundQuestion: SurveyDefCompoundQuestionInput | null | undefined,
    ) => {
        setPieceModalParms({
            divId,
            secId,
            pieceId,
            expressionList,
            content: null,
            text: null,
            question: null,
            compoundQuestion,
            tutorialContent: null,
        });
        setPieceCompoundQuestionModalState(true);
    };

    const editPieceTutorialContent = (
        divId: string,
        secId: string,
        pieceId: string,
        expressionList: (string | null)[] | null | undefined,
        tutorialContent: SurveyDefTutorialContentInput | null | undefined,
    ) => {
        if (preFetchData != null && preFetchData.userProfileDefs != null) {
            setPieceModalParms({
                divId,
                secId,
                pieceId,
                expressionList,
                content: null,
                text: null,
                question: null,
                compoundQuestion: null,
                tutorialContent,
            });
            setPieceTutorialContentModalState(true);
        }
    };
    const editButtonText = () => {
        setButtonModalState(true);
    };
    useEffect(() => {
        if (isEditMode) {
            fetchSurveyById({ variables: { surveyDefInput: { id: surveyDefId } } });
        }
    }, [fetchSurveyById, isEditMode, surveyDefId]);

    if (preFetchLoading || surveyLoading || surveyListLoading) {
        return <Loading />;
    }
    const handleNameModalClose = () => {
        setNameModalState(false);
    };
    const handleReorderModalClose = () => {
        setReorderModalState(false);
    };
    const handleReorderModalSave = (
        items: { id: number; name: string }[],
        divId?: string,
        secId?: string,
    ) => {
        if (divId && secId)
            setDivisions(
                divisions.map(div =>
                    div.id === divId
                        ? {
                              ...div,
                              sections: div.sections.map(sec =>
                                  sec.id === secId
                                      ? {
                                            ...sec,
                                            pieces: items.map(item => sec.pieces[item.id - 1]),
                                        }
                                      : {
                                            ...sec,
                                        },
                              ),
                          }
                        : { ...div },
                ),
            );
        else if (divId) {
            setDivisions(
                divisions.map(div =>
                    div.id === divId
                        ? {
                              ...div,
                              sections: items.map(item => div.sections[item.id - 1]),
                          }
                        : { ...div },
                ),
            );
        } else setDivisions(items.map(item => divisions[item.id - 1]));
        setReorderModalState(false);
        onEdit();
    };
    const handleSubSurveyModalClose = () => {
        setSubSurveyModalState(false);
    };
    const handleSubSurveyModalSave = (subSurvey: SurveyDef, divId: string) => {
        const currentDivision = divisions.find(div => div.id === divId);
        if (!currentDivision) {
            return;
        }
        const { id } = subSurvey;
        const updated = { ...currentDivision, subSurveyDefId: id, subSurveyDef: subSurvey };
        setDivisions(oldValues => {
            return oldValues?.map(div => (div.id === divId ? updated : div));
        });
        setDivisionSelected(updated);
        setSubSurveyModalParms(subSurveyModalParamsDefaults);
        setSubSurveyModalState(false);
        onEdit();
    };
    const handleSubSurveyModalDelete = (subSurveyDefId: string, divId: string) => {
        setDivisions(oldValues => {
            const filtered = oldValues?.find(div => div.id === divId);
            if (filtered && filtered.subSurveyDefId) {
                filtered.subSurveyDefId = null;
                filtered.subSurveyDef = null;
            }
            return oldValues.map(val => {
                if (val.id === divId && filtered) {
                    return filtered;
                }
                return val;
            });
        });
        setSubSurveyModalParms(subSurveyModalParamsDefaults);
        setSubSurveyModalState(false);
        onEdit();
    };
    const deleteDivision = (divId: string) => {
        setDivisions(divisions?.filter(div => div.id !== divId));
    };
    const deleteSection = (divId: string, secId: string) => {
        setDivisions(
            divisions?.map(div =>
                div.id === divId
                    ? { ...div, sections: div.sections.filter(sec => sec.id !== secId) }
                    : { ...div },
            ),
        );
    };
    const handleNameModalDelete = (divId: string, secId?: string) => {
        if (secId) deleteSection(divId, secId);
        else deleteDivision(divId);
        onEdit();
        setNameModalState(false);
    };
    const handleNameModalSubmit = (data: any) => {
        if (data.typeName === 'Division') {
            if (!data.divisionId) {
                const newDiv: SurveyDefDivision = {
                    id: new ObjectID().toHexString(),
                    sections: [],
                    conditionExpressionIds: data.selectedExpressions,
                    label: { en: data.label.en, es: data.label.es },
                };
                setDivisions(prevState => [...prevState, newDiv]);
            } else {
                setDivisions(
                    divisions?.map((div: SurveyDefDivision) =>
                        div.id === data.divisionId
                            ? {
                                  ...div,
                                  conditionExpressionIds: data.selectedExpressions,
                                  label: { en: data.label.en, es: data.label.es },
                              }
                            : { ...div },
                    ),
                );
            }
        } else if (data.typeName === 'Section') {
            if (data.sectionId)
                setDivisions(
                    divisions?.map((div: SurveyDefDivision) =>
                        div.id === data.divisionId
                            ? {
                                  ...div,
                                  sections: div.sections.map((sec: SurveyDefSection) =>
                                      sec.id === data.sectionId
                                          ? {
                                                ...sec,
                                                conditionExpressionIds: data.selectedExpressions,
                                                label: { en: data.label.en, es: data.label.es },
                                            }
                                          : { ...sec },
                                  ),
                              }
                            : { ...div },
                    ),
                );
            else {
                const newSec: SurveyDefSection = {
                    id: new ObjectID().toHexString(),
                    pieces: [],
                    conditionExpressionIds: data.selectedExpressions,
                    label: { en: data.label.en, es: data.label.es },
                };
                setDivisions(
                    divisions?.map((div: SurveyDefDivision) =>
                        div.id === data.divisionId
                            ? {
                                  ...div,
                                  sections: [...div.sections, newSec],
                              }
                            : { ...div },
                    ),
                );
            }
        }
        onEdit();
        setNameModalState(false);
    };
    const handleButtonTextModalClose = () => {
        setButtonModalState(false);
    };
    const handleButtonTextModalSubmit = (data: any) => {
        setButtonText(data);
        onEdit();
        setButtonModalState(false);
    };
    const handlePieceContentModalClose = () => {
        setPieceContentModalState(false);
    };
    const handlePieceContentModalSubmit = (data: any) => {
        if (data.pieceId)
            setDivisions(
                divisions?.map((div: SurveyDefDivision) =>
                    div.id === data.divisionId
                        ? {
                              ...div,
                              sections: div.sections.map((sec: SurveyDefSection) =>
                                  sec.id === data.sectionId
                                      ? {
                                            ...sec,
                                            pieces: sec.pieces.map(piece =>
                                                piece.id === data.pieceId
                                                    ? {
                                                          ...piece,
                                                          content: {
                                                              articleId: data.articleId,
                                                              articleName: data.articleName,
                                                          },
                                                          conditionExpressionIds:
                                                              data.selectedExpressions,
                                                      }
                                                    : { ...piece },
                                            ),
                                        }
                                      : { ...sec },
                              ),
                          }
                        : { ...div },
                ),
            );
        else {
            const newPiece: SurveyDefPiece = {
                id: new ObjectID().toHexString(),
                type: SurveyDefPieceType.Content,
                conditionExpressionIds: data.selectedExpressions,
                content: { articleId: data.articleId, articleName: data.articleName },
            };
            setDivisions(
                divisions?.map((div: SurveyDefDivision) =>
                    div.id === data.divisionId
                        ? {
                              ...div,
                              sections: div.sections.map((sec: SurveyDefSection) =>
                                  sec.id === data.sectionId
                                      ? {
                                            ...sec,
                                            pieces: sec.pieces.concat(newPiece),
                                        }
                                      : { ...sec },
                              ),
                          }
                        : { ...div },
                ),
            );
        }
        onEdit();
        setPieceContentModalState(false);
    };
    const handlePieceTextModalClose = () => {
        setPieceTextModalState(false);
    };
    const handlePieceTextModalSubmit = (data: any) => {
        if (data.pieceId)
            setDivisions(
                divisions?.map((div: SurveyDefDivision) =>
                    div.id === data.divisionId
                        ? {
                              ...div,
                              sections: div.sections.map((sec: SurveyDefSection) =>
                                  sec.id === data.sectionId
                                      ? {
                                            ...sec,
                                            pieces: sec.pieces.map(piece =>
                                                piece.id === data.pieceId
                                                    ? {
                                                          ...piece,
                                                          conditionExpressionIds:
                                                              data.selectedExpressions,
                                                          text: {
                                                              title: {
                                                                  en: data.title.en,
                                                                  es: data.title.es,
                                                              },
                                                              body: {
                                                                  en: data.body.en,
                                                                  es: data.body.es,
                                                              },
                                                          },
                                                      }
                                                    : { ...piece },
                                            ),
                                        }
                                      : { ...sec },
                              ),
                          }
                        : { ...div },
                ),
            );
        else {
            const newPiece: SurveyDefPieceInput = {
                id: new ObjectID().toHexString(),
                type: SurveyDefPieceType.Text,
                conditionExpressionIds: data.selectedExpressions,
                text: {
                    title: {
                        en: data.title.en,
                        es: data.title.es,
                    },
                    body: {
                        en: data.body.en,
                        es: data.body.es,
                    },
                },
            };
            setDivisions(
                divisions?.map((div: SurveyDefDivision) =>
                    div.id === data.divisionId
                        ? {
                              ...div,
                              sections: div.sections.map((sec: SurveyDefSection) =>
                                  sec.id === data.sectionId
                                      ? {
                                            ...sec,
                                            pieces: sec.pieces.concat(newPiece),
                                        }
                                      : { ...sec },
                              ),
                          }
                        : { ...div },
                ),
            );
        }
        onEdit();
        setPieceTextModalState(false);
    };
    const handlePieceQuestionModalClose = () => {
        setPieceQuestionModalState(false);
    };
    const handlePieceQuestionModalSubmit = (data: any) => {
        if (data.pieceId)
            setDivisions(
                divisions?.map((div: SurveyDefDivision) =>
                    div.id === data.divisionId
                        ? {
                              ...div,
                              sections: div.sections.map((sec: SurveyDefSection) =>
                                  sec.id === data.sectionId
                                      ? {
                                            ...sec,
                                            pieces: sec.pieces.map(piece =>
                                                piece.id === data.pieceId
                                                    ? {
                                                          ...piece,
                                                          conditionExpressionIds:
                                                              data.selectedExpressions,
                                                          question: {
                                                              displayType: data.selectedDisplayType,
                                                              answerRequired: data.answerRequired,
                                                              labelOverride: {
                                                                  en: data.labelOverride.en,
                                                                  es: data.labelOverride.es,
                                                              },
                                                              hidePreviousAnswer:
                                                                  data.hidePreviousAnswer,
                                                              questionProfileDefId:
                                                                  data.selectedProfileId,
                                                              choiceOverrides:
                                                                  data.choiceLabelOverrides,
                                                          },
                                                      }
                                                    : { ...piece },
                                            ),
                                        }
                                      : { ...sec },
                              ),
                          }
                        : { ...div },
                ),
            );
        else {
            const isLabelOverride =
                data.labelOverride.en.trim() !== '' || data.labelOverride.es.trim() !== '';
            const newPiece: SurveyDefPieceInput = {
                id: new ObjectID().toHexString(),
                type: SurveyDefPieceType.Question,
                conditionExpressionIds: data.selectedExpressions,
                question: {
                    displayType: data.selectedDisplayType,
                    answerRequired: data.answerRequired,
                    hidePreviousAnswer: data.hidePreviousAnswer,
                    questionProfileDefId: data.selectedProfileId,
                    choiceOverrides: data.choiceLabelOverrides,
                    labelOverride: isLabelOverride
                        ? { en: data.labelOverride.en, es: data.labelOverride.es }
                        : null,
                },
            };
            setDivisions(
                divisions?.map((div: SurveyDefDivision) =>
                    div.id === data.divisionId
                        ? {
                              ...div,
                              sections: div.sections.map((sec: SurveyDefSection) =>
                                  sec.id === data.sectionId
                                      ? {
                                            ...sec,
                                            pieces: sec.pieces.concat(newPiece),
                                        }
                                      : { ...sec },
                              ),
                          }
                        : { ...div },
                ),
            );
        }
        onEdit();
        setPieceQuestionModalState(false);
    };

    const handlePieceCompoundQuestionModalSubmit =
        (divisionId: string, sectionId: string, pieceId?: string) =>
        (data: SurveyCompoundQuestionFormInput) => {
            if (pieceId)
                setDivisions(
                    divisions?.map((div: SurveyDefDivision) =>
                        div.id === divisionId
                            ? {
                                  ...div,
                                  sections: div.sections.map((sec: SurveyDefSection) =>
                                      sec.id === sectionId
                                          ? {
                                                ...sec,
                                                pieces: sec.pieces.map(piece =>
                                                    piece.id === pieceId
                                                        ? {
                                                              ...piece,
                                                              conditionExpressionIds:
                                                                  data.expressions,
                                                              surveyDefCompoundQuestion: {
                                                                  label: data.label,
                                                                  compoundQuestionId:
                                                                      data.compoundQuestionId,
                                                                  displayType: data.displayType,
                                                                  answerRequired:
                                                                      data.answerRequired,
                                                                  hidePreviousAnswer:
                                                                      data.hidePreviousAnswer,
                                                              },
                                                          }
                                                        : { ...piece },
                                                ),
                                            }
                                          : { ...sec },
                                  ),
                              }
                            : { ...div },
                    ),
                );
            else {
                const newPiece: SurveyDefPieceInput = {
                    id: new ObjectID().toHexString(),
                    type: SurveyDefPieceType.CompoundQuestion,
                    conditionExpressionIds: data.expressions,
                    surveyDefCompoundQuestion: {
                        label: data.label,
                        compoundQuestionId: data.compoundQuestionId,
                        displayType: data.displayType,
                        answerRequired: data.answerRequired,
                        hidePreviousAnswer: data.hidePreviousAnswer,
                    },
                };
                setDivisions(
                    divisions?.map((div: SurveyDefDivision) =>
                        div.id === divisionId
                            ? {
                                  ...div,
                                  sections: div.sections.map((sec: SurveyDefSection) =>
                                      sec.id === sectionId
                                          ? {
                                                ...sec,
                                                pieces: sec.pieces.concat(newPiece),
                                            }
                                          : { ...sec },
                                  ),
                              }
                            : { ...div },
                    ),
                );
            }
            onEdit();
            setPieceCompoundQuestionModalState(false);
        };
    const handlePieceTutorialContentModalClose = () => {
        setPieceTutorialContentModalState(false);
    };
    const handlePieceTutorialContentModalSubmit = (data: any) => {
        if (data.pieceId)
            setDivisions(
                divisions?.map((div: SurveyDefDivision) =>
                    div.id === data.divisionId
                        ? {
                              ...div,
                              sections: div.sections.map((sec: SurveyDefSection) =>
                                  sec.id === data.sectionId
                                      ? {
                                            ...sec,
                                            pieces: sec.pieces.map(piece =>
                                                piece.id === data.pieceId
                                                    ? {
                                                          ...piece,
                                                          tutorialContent: {
                                                              videoId: data.videoId,
                                                              videoName: data.videoName,
                                                          },
                                                          conditionExpressionIds:
                                                              data.selectedExpressions,
                                                      }
                                                    : { ...piece },
                                            ),
                                        }
                                      : { ...sec },
                              ),
                          }
                        : { ...div },
                ),
            );
        else {
            const newPiece: SurveyDefPiece = {
                id: new ObjectID().toHexString(),
                type: SurveyDefPieceType.TutorialContent,
                conditionExpressionIds: data.selectedExpressions,
                tutorialContent: { videoId: data.videoId, videoName: data.videoName },
            };
            setDivisions(
                divisions?.map((div: SurveyDefDivision) =>
                    div.id === data.divisionId
                        ? {
                              ...div,
                              sections: div.sections.map((sec: SurveyDefSection) =>
                                  sec.id === data.sectionId
                                      ? {
                                            ...sec,
                                            pieces: sec.pieces.concat(newPiece),
                                        }
                                      : { ...sec },
                              ),
                          }
                        : { ...div },
                ),
            );
        }
        onEdit();
        setPieceTutorialContentModalState(false);
    };
    const handlePieceDelete = (divId: string, secId: string, pieceId: string) => {
        setDivisions(
            divisions?.map(div =>
                div.id === divId
                    ? {
                          ...div,
                          sections: div.sections.map(sec =>
                              sec.id === secId
                                  ? {
                                        ...sec,
                                        pieces: sec.pieces.filter(piece => piece.id !== pieceId),
                                    }
                                  : { ...sec },
                          ),
                      }
                    : { ...div },
            ),
        );
        setPieceTextModalState(false);
        setPieceQuestionModalState(false);
        setPieceContentModalState(false);
        setPieceCompoundQuestionModalState(false);
        onEdit();
    };
    const handleExpandClick = () => {
        setDetailsExpanded(!detailsExpanded);
    };
    const divActionsMenuOpen = (anchor: HTMLElement, div: SurveyDefDivision) => {
        setDivActionAnchor(anchor);
        setDivisionSelected(div);
    };
    const secActionsMenuOpen = (
        anchor: HTMLElement,
        div: SurveyDefDivision,
        sec: SurveyDefSection,
    ) => {
        setSecActionAnchor(anchor);
        setDivisionSelected(div);
        setSectionSelected(sec);
    };
    const divActionsMenuClose = () => {
        setDivActionAnchor(null);
    };
    const isSection = (input: unknown): input is SurveyDefSection =>
        input !== undefined && input !== null;
    const isDivision = (input: unknown): input is SurveyDefDivision =>
        input !== undefined && input !== null;
    const divActionHandler = async (action: DIVISION_ACTIONS_ENUM) => {
        const div = divisionSelected;
        if (isDivision(div)) {
            switch (action) {
                case DIVISION_ACTIONS_ENUM.EDIT:
                    editDivisionDialog(div);
                    break;
                case DIVISION_ACTIONS_ENUM.ADD:
                    addSectionDialog(div);
                    break;
                case DIVISION_ACTIONS_ENUM.REORDER:
                    reorderSectionsDialog(div);
                    break;
                case DIVISION_ACTIONS_ENUM.ADD_SUBSURVEY:
                    if (surveyDefId === NEW_SURVEY_ID) {
                        addSubSurveyDialog(div);
                    } else if (surveyDefId) {
                        const { data } = await getParentSurveysDefs({
                            variables: { surveyId: surveyDefId },
                        });
                        const parentSurveysDefs = data?.parentSurveysDefs ?? [];
                        if (parentSurveysDefs.length) {
                            const parentSurveysNames = parentSurveysDefs.map(i => i.name).join(',');
                            setMessageModalProps({
                                message: `This survey is already used as sub survey for the ${parentSurveysNames} ${
                                    parentSurveysDefs.length > 1 ? 'surveys' : 'survey'
                                }.`,
                                closeHandler: () => setMessageModalProps(null),
                            });
                        } else {
                            addSubSurveyDialog(div);
                        }
                    }
                    break;
                case DIVISION_ACTIONS_ENUM.EDIT_SUBSURVEY:
                    editSubSurveyDialog(div);
                    break;
                default:
                    break;
            }
        }
        divActionsMenuClose();
    };
    const secActionsMenuClose = () => {
        setSecActionAnchor(null);
    };
    const secActionHandler = (action: SECTION_ACTIONS_ENUM) => {
        const sec = sectionSelected;
        const div = divisionSelected;
        if (isSection(sec) && isDivision(div)) {
            switch (action) {
                case SECTION_ACTIONS_ENUM.EDIT:
                    editSectionDialog(div, sec);
                    break;
                case SECTION_ACTIONS_ENUM.ADD_CONTENT:
                    editPieceContent(div.id, sec.id, '', [], null);
                    break;
                case SECTION_ACTIONS_ENUM.ADD_TEXT:
                    editPieceText(div.id, sec.id, '', [], null);
                    break;
                case SECTION_ACTIONS_ENUM.ADD_QUESTION:
                    editPieceQuestion(div.id, sec.id, '', [], null);
                    break;
                case SECTION_ACTIONS_ENUM.ADD_COMPOUND_QUESTION:
                    editPieceCompoundQuestion(div.id, sec.id, '', [], null);
                    break;
                case SECTION_ACTIONS_ENUM.ADD_TUTORIAL_CONTENT:
                    editPieceTutorialContent(div.id, sec.id, '', [], null);
                    break;
                case SECTION_ACTIONS_ENUM.REORDER:
                    reorderPiecesDialog(div.id, sec);
                    break;
                default:
                    break;
            }
        }
        secActionsMenuClose();
    };

    const onNavigateAway = () => {
        if (isModified)
            TriggerGlobalConfirm({
                message: `You have unsaved changes. Are you sure you want to return to the survey page?`,
                callback: () => {
                    history(`/app-config/surveys/`);
                },
            });
        else history(`/app-config/surveys/`);
    };

    if (preFetchLoading || surveyLoading || updateSurveyDefLoading || createSurveyDefLoading) {
        return <Loading />;
    }
    return (
        <>
            {messageModalProps && <MessageModal {...messageModalProps} />}
            {reorderModalIsOpen && (
                <ReorderModal
                    divisionId={reorderModalParms?.divisionId}
                    sectionId={reorderModalParms?.sectionId}
                    items={reorderModalParms?.items || []}
                    title={reorderModalParms?.title || ''}
                    closeHandler={handleReorderModalClose}
                    submitHandler={handleReorderModalSave}
                />
            )}
            {subSurveyModalIsOpen && (
                <SubSurveyModal
                    divisionId={subSurveyModalParms.divisionId}
                    title={subSurveyModalParms?.title || ''}
                    closeHandler={handleSubSurveyModalClose}
                    submitHandler={handleSubSurveyModalSave}
                    deleteHandler={handleSubSurveyModalDelete}
                    editMode={subSurveyModalParms.editMode}
                    surveyDefId={subSurveyModalParms.surveyDefId}
                />
            )}
            {nameModalIsOpen && (
                <NameModal
                    divisionId={nameModalParms?.divisionId}
                    sectionId={nameModalParms?.sectionId}
                    title={nameModalParms?.title || ''}
                    typeName={nameModalParms?.typeName || ''}
                    closeHandler={handleNameModalClose}
                    submitHandler={handleNameModalSubmit}
                    label={nameModalParms?.label}
                    deleteHandler={handleNameModalDelete}
                    expressionList={nameModalParms?.expressionList}
                />
            )}
            {pieceContentModalIsOpen && (
                <PieceContentModal
                    divisionId={pieceModalParms?.divId || ''}
                    sectionId={pieceModalParms?.secId || ''}
                    pieceId={pieceModalParms?.pieceId}
                    articleId={pieceModalParms?.content?.articleId || ''}
                    expressionList={pieceModalParms?.expressionList}
                    closeHandler={handlePieceContentModalClose}
                    submitHandler={handlePieceContentModalSubmit}
                    deleteHandler={handlePieceDelete}
                />
            )}
            {pieceTextModalIsOpen && (
                <PieceTextModal
                    divisionId={pieceModalParms?.divId || ''}
                    sectionId={pieceModalParms?.secId || ''}
                    pieceId={pieceModalParms?.pieceId}
                    body={pieceModalParms?.text?.body || { en: '', es: '' }}
                    title={pieceModalParms?.text?.title || { en: '', es: '' }}
                    expressionList={pieceModalParms?.expressionList}
                    closeHandler={handlePieceTextModalClose}
                    submitHandler={handlePieceTextModalSubmit}
                    deleteHandler={handlePieceDelete}
                />
            )}
            {pieceQuestionModalIsOpen && preFetchData?.userProfileDefs && (
                <PieceQuestionModal
                    divisionId={pieceModalParms?.divId || ''}
                    sectionId={pieceModalParms?.secId || ''}
                    pieceId={pieceModalParms?.pieceId}
                    questionProfileDefId={pieceModalParms?.question?.questionProfileDefId ?? ''}
                    displayType={
                        pieceModalParms?.question == null
                            ? SurveyDefQuestionDisplayType.LineInput
                            : pieceModalParms?.question.displayType
                    }
                    labelOverride={pieceModalParms?.question?.labelOverride}
                    choiceOverrides={pieceModalParms?.question?.choiceOverrides}
                    answerRequired={pieceModalParms?.question?.answerRequired}
                    hidePreviousAnswer={pieceModalParms?.question?.hidePreviousAnswer}
                    expressionList={pieceModalParms?.expressionList}
                    profileDefList={preFetchData.userProfileDefs.filter(
                        p => !p.surveyQuestionDisabled,
                    )}
                    closeHandler={handlePieceQuestionModalClose}
                    deleteHandler={handlePieceDelete}
                    submitHandler={handlePieceQuestionModalSubmit}
                />
            )}
            {pieceCompoundQuestionModalIsOpen && (
                <PieceCompoundQuestionModal
                    compoundQuestion={pieceModalParms?.compoundQuestion ?? null}
                    expressions={
                        (pieceModalParms?.expressionList?.filter(i => i !== null) as string[]) ?? []
                    }
                    closeHandler={() => setPieceCompoundQuestionModalState(false)}
                    deleteHandler={() =>
                        handlePieceDelete(
                            pieceModalParms?.divId || '',
                            pieceModalParms?.secId || '',
                            pieceModalParms?.pieceId || '',
                        )
                    }
                    submitHandler={handlePieceCompoundQuestionModalSubmit(
                        pieceModalParms?.divId || '',
                        pieceModalParms?.secId || '',
                        pieceModalParms?.pieceId,
                    )}
                />
            )}
            {compoundQuestionModalData && (
                <CompoundQuestionModal
                    id={compoundQuestionModalData.id}
                    compoundQuestion={null}
                    onClose={(closeType: CompoundQuestionModalCloseType) => {
                        if (closeType === CompoundQuestionModalCloseType.DELETED) {
                            const { divId, secId, pieceId } = compoundQuestionModalData.payload;
                            handlePieceDelete(divId, secId, pieceId);
                        }
                        setCompoundQuestionModalData(null);
                    }}
                />
            )}
            {pieceTutorialContentModalIsOpen && (
                <PieceTutorialContentModal
                    divisionId={pieceModalParms?.divId || ''}
                    sectionId={pieceModalParms?.secId || ''}
                    pieceId={pieceModalParms?.pieceId}
                    expressionList={pieceModalParms?.expressionList}
                    video={pieceModalParms?.tutorialContent?.videoId || ''}
                    closeHandler={handlePieceTutorialContentModalClose}
                    submitHandler={handlePieceTutorialContentModalSubmit}
                    deleteHandler={handlePieceDelete}
                />
            )}
            {buttonModalIsOpen && (
                <ButtonTextModal
                    submitHandler={handleButtonTextModalSubmit}
                    closeHandler={handleButtonTextModalClose}
                    buttonText={buttonText}
                />
            )}
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12}>
                    <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                        Back to Surveys
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <form noValidate>
                        <Card>
                            <CardHeader
                                title={`Survey Settings: ${
                                    surveyData?.surveyDef?.name ?? 'New Survey'
                                }`}
                            />
                            <CardActions>
                                <Accordion
                                    expanded={detailsExpanded}
                                    onClick={handleExpandClick}
                                    aria-expanded={detailsExpanded}
                                >
                                    {detailsExpanded && <ExpandLess />}
                                    {!detailsExpanded && <ExpandMore />}
                                </Accordion>
                            </CardActions>
                            <Collapse in={detailsExpanded}>
                                <OutlinedSection title="Survey Name *">
                                    <TextField
                                        variant="outlined"
                                        type="text"
                                        fullWidth
                                        margin="dense"
                                        {...register('name')}
                                        error={!!errors.name}
                                        helperText={errors.name?.message}
                                        onChange={onEdit}
                                        data-test={SurveyEnum.SURVEY_NAME}
                                    />
                                </OutlinedSection>
                                <Card>
                                    <h2>Label</h2>
                                    <TextField
                                        variant="outlined"
                                        label="English *"
                                        type="text"
                                        fullWidth
                                        margin="dense"
                                        {...register('label.en')}
                                        error={!!errors.label?.en}
                                        helperText={errors.label?.en?.message}
                                        onChange={onEdit}
                                        data-test={SurveyEnum.LABEL}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="Spanish"
                                        type="text"
                                        fullWidth
                                        margin="dense"
                                        {...register('label.es')}
                                        error={!!errors.label?.es}
                                        helperText={errors.label?.es?.message}
                                        onChange={onEdit}
                                    />
                                </Card>
                                <Card>
                                    <h2>Survey Type</h2>
                                    <TextField
                                        variant="outlined"
                                        label="Survey Type *"
                                        type="text"
                                        fullWidth
                                        margin="dense"
                                        {...register('surveyType')}
                                        error={!!errors.surveyType}
                                        helperText={errors.surveyType?.message}
                                        onChange={onEdit}
                                        data-test={SurveyEnum.SURVEY_TYPE}
                                    />
                                </Card>
                                <ContentIconSelect
                                    selectedIcon={selectedIcon}
                                    setSelectedIcon={setSelectedIcon}
                                    onEdit={onEdit}
                                />
                                <ImageContentPicker
                                    selectedImageContentId={selectedImageId}
                                    setSelectedImageContentId={setSelectedImageId}
                                    onEdit={onEdit}
                                />
                                <Card style={{ fontSize: '1.2em' }}>
                                    <Controller
                                        name="disableRestart"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControlLabel
                                                label="Disable restart?"
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        id="disableRestart"
                                                        checked={value === true}
                                                        onChange={e => {
                                                            onChange(e.target.checked);
                                                            onEdit();
                                                        }}
                                                    />
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="restartFromBeginning"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControlLabel
                                                label="Restart from beginning?"
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        id="restartFromBeginning"
                                                        checked={value === true}
                                                        onChange={e => {
                                                            onChange(e.target.checked);
                                                            onEdit();
                                                        }}
                                                    />
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="requiresAdvocateReview"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControlLabel
                                                label="Require advocate review?"
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        id="requiresAdvocateReview"
                                                        checked={value === true}
                                                        onChange={e => {
                                                            onChange(e.target.checked);
                                                            onEdit();
                                                        }}
                                                    />
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="summary"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControlLabel
                                                label="Show summary?"
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        id="summary"
                                                        checked={value === true}
                                                        onChange={e => {
                                                            onChange(e.target.checked);
                                                            onEdit();
                                                        }}
                                                    />
                                                }
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="shouldScore"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControlLabel
                                                label="Should score?"
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        id="shouldScore"
                                                        checked={value === true}
                                                        onChange={e => {
                                                            onChange(e.target.checked);
                                                            onEdit();
                                                        }}
                                                    />
                                                }
                                            />
                                        )}
                                    />
                                </Card>
                                <Card>
                                    <h2>Summary Title</h2>
                                    <TextField
                                        variant="outlined"
                                        label="English"
                                        type="text"
                                        fullWidth
                                        margin="dense"
                                        {...register('summaryText.title.en')}
                                        error={!!errors.summaryText}
                                        helperText={errors.summaryText?.title?.en?.message}
                                        onChange={onEdit}
                                        data-test={SurveyEnum.SUMMARY_TITLE}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="Spanish"
                                        type="text"
                                        fullWidth
                                        margin="dense"
                                        {...register('summaryText.title.es')}
                                        onChange={onEdit}
                                    />
                                    <h2>Summary Body</h2>
                                    <TextField
                                        variant="outlined"
                                        label="English"
                                        type="text"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        margin="dense"
                                        {...register('summaryText.body.en')}
                                        error={!!errors.summaryText?.body?.en}
                                        helperText={errors.summaryText?.body?.en?.message}
                                        onChange={onEdit}
                                        data-test={SurveyEnum.SUMMARY_BODY}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="Spanish"
                                        type="text"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        margin="dense"
                                        {...register('summaryText.body.es')}
                                        error={!!errors.summaryText?.body?.es}
                                        helperText={errors.summaryText?.body?.es?.message}
                                        onChange={onEdit}
                                    />
                                </Card>
                            </Collapse>
                            <Button onClick={editButtonText}>Edit Button Text</Button>
                        </Card>
                    </form>

                    <h2>
                        Sections{' '}
                        <Button
                            onClick={addDivisionDialog}
                            data-test={SurveyEnum.ADD_SECTION_BUTTON}
                        >
                            Add Section
                        </Button>
                        {divisions?.length > 1 && (
                            <Button onClick={reorderDivisionsDialog}>Reorder Sections</Button>
                        )}
                    </h2>
                </Grid>
            </Grid>
            {divisions && (
                <>
                    <DivisionActionDropdown
                        anchor={divActionAnchor}
                        div={divisionSelected}
                        onUserSelectAction={divActionHandler}
                        onUserCloseDropdown={divActionsMenuClose}
                    />
                    <SectionActionDropdown
                        anchor={secActionAnchor}
                        sec={sectionSelected}
                        onUserSelectAction={secActionHandler}
                        onUserCloseDropdown={secActionsMenuClose}
                    />
                    <ol>
                        {divisions?.map(div => {
                            return (
                                <Card key={div.id} style={{ maxWidth: 800 }}>
                                    <li key={div.id}>
                                        {div.label.en}{' '}
                                        <Button
                                            id={`div-action-${div.id}`}
                                            aria-haspopup="true"
                                            onClick={(e: React.MouseEvent<HTMLElement>): void => {
                                                divActionsMenuOpen(e.currentTarget, div);
                                            }}
                                            endIcon={<ExpandMore />}
                                            data-test={div.label.en}
                                        >
                                            Actions
                                        </Button>
                                        {div.subSurveyDef ? (
                                            <ul>
                                                <li>
                                                    Sub Survey: {div.subSurveyDef.name}
                                                    <Button
                                                        className={classes.editButton}
                                                        onClick={() => {
                                                            setDivisionSelected(div);
                                                            divActionHandler(
                                                                DIVISION_ACTIONS_ENUM.EDIT_SUBSURVEY,
                                                            );
                                                        }}
                                                    >
                                                        {' '}
                                                        Edit
                                                    </Button>
                                                </li>
                                            </ul>
                                        ) : (
                                            <ol>
                                                {div.sections.map((sec, index) => {
                                                    return (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <li key={index}>
                                                            {sec.label.en}{' '}
                                                            <Button
                                                                id={`sec-action-${sec.id}`}
                                                                aria-haspopup="true"
                                                                onClick={(
                                                                    e: React.MouseEvent<HTMLElement>,
                                                                ): void => {
                                                                    secActionsMenuOpen(
                                                                        e.currentTarget,
                                                                        div,
                                                                        sec,
                                                                    );
                                                                }}
                                                                endIcon={<ExpandMore />}
                                                                data-test={sec.label.en}
                                                            >
                                                                Actions
                                                            </Button>
                                                            <ul style={{ listStyleType: 'none' }}>
                                                                {sec.pieces.map(piece => {
                                                                    return (
                                                                        <li key={piece.id}>
                                                                            {piece.type ===
                                                                                SurveyDefPieceType.Question && (
                                                                                <div>
                                                                                    Question:{' '}
                                                                                    {piece.question
                                                                                        ?.labelOverride
                                                                                        ?.en ||
                                                                                        preFetchData?.userProfileDefs.find(
                                                                                            d =>
                                                                                                d.id ===
                                                                                                piece
                                                                                                    .question
                                                                                                    ?.questionProfileDefId,
                                                                                        )
                                                                                            ?.questionLabel
                                                                                            ?.en}
                                                                                    <Button
                                                                                        className={
                                                                                            classes.editButton
                                                                                        }
                                                                                        onClick={() => {
                                                                                            editPieceQuestion(
                                                                                                div.id,
                                                                                                sec.id,
                                                                                                piece.id,
                                                                                                piece.conditionExpressionIds ??
                                                                                                    [],
                                                                                                piece.question,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        Edit
                                                                                    </Button>
                                                                                </div>
                                                                            )}
                                                                            {piece.type ===
                                                                                SurveyDefPieceType.CompoundQuestion && (
                                                                                <div>
                                                                                    Compound
                                                                                    Question:{' '}
                                                                                    {
                                                                                        piece
                                                                                            .surveyDefCompoundQuestion
                                                                                            ?.label
                                                                                            ?.en
                                                                                    }
                                                                                    <Button
                                                                                        className={
                                                                                            classes.editButton
                                                                                        }
                                                                                        onClick={() => {
                                                                                            editPieceCompoundQuestion(
                                                                                                div.id,
                                                                                                sec.id,
                                                                                                piece.id,
                                                                                                piece.conditionExpressionIds ??
                                                                                                    [],
                                                                                                piece.surveyDefCompoundQuestion,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        Edit
                                                                                    </Button>
                                                                                    <Button
                                                                                        className={
                                                                                            classes.editButton
                                                                                        }
                                                                                        onClick={() => {
                                                                                            const id =
                                                                                                piece
                                                                                                    .surveyDefCompoundQuestion
                                                                                                    ?.compoundQuestionId ??
                                                                                                null;

                                                                                            setCompoundQuestionModalData(
                                                                                                {
                                                                                                    id,
                                                                                                    payload:
                                                                                                        {
                                                                                                            divId: div.id,
                                                                                                            secId: sec.id,
                                                                                                            pieceId:
                                                                                                                piece.id,
                                                                                                        },
                                                                                                },
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        Edit
                                                                                        Compound
                                                                                        Question
                                                                                    </Button>
                                                                                </div>
                                                                            )}
                                                                            {piece.type ===
                                                                                SurveyDefPieceType.Text && (
                                                                                <div>
                                                                                    Text:{' '}
                                                                                    {
                                                                                        piece.text
                                                                                            ?.title
                                                                                            ?.en
                                                                                    }
                                                                                    <Button
                                                                                        className={
                                                                                            classes.editButton
                                                                                        }
                                                                                        onClick={() => {
                                                                                            editPieceText(
                                                                                                div.id,
                                                                                                sec.id,
                                                                                                piece.id,
                                                                                                piece.conditionExpressionIds,
                                                                                                piece.text,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        Edit
                                                                                    </Button>
                                                                                </div>
                                                                            )}
                                                                            {piece.type ===
                                                                                SurveyDefPieceType.Content && (
                                                                                <div>
                                                                                    Article:{' '}
                                                                                    {
                                                                                        piece
                                                                                            .content
                                                                                            ?.articleName
                                                                                    }
                                                                                    <Button
                                                                                        className={
                                                                                            classes.editButton
                                                                                        }
                                                                                        onClick={() => {
                                                                                            editPieceContent(
                                                                                                div.id,
                                                                                                sec.id,
                                                                                                piece.id,
                                                                                                piece.conditionExpressionIds,
                                                                                                piece.content,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        Edit
                                                                                    </Button>
                                                                                </div>
                                                                            )}
                                                                            {piece.type ===
                                                                                SurveyDefPieceType.TutorialContent && (
                                                                                <div>
                                                                                    Tutorial
                                                                                    Content:{' '}
                                                                                    {
                                                                                        piece
                                                                                            .tutorialContent
                                                                                            ?.videoName
                                                                                    }
                                                                                    <Button
                                                                                        className={
                                                                                            classes.editButton
                                                                                        }
                                                                                        onClick={() => {
                                                                                            editPieceTutorialContent(
                                                                                                div.id,
                                                                                                sec.id,
                                                                                                piece.id,
                                                                                                piece.conditionExpressionIds,
                                                                                                piece.tutorialContent,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        Edit
                                                                                    </Button>
                                                                                </div>
                                                                            )}
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </li>
                                                    );
                                                })}
                                            </ol>
                                        )}
                                    </li>
                                </Card>
                            );
                        })}
                    </ol>
                </>
            )}{' '}
            <div style={{ width: '100%', textAlign: 'right' }}>
                <Button
                    aria-label="save"
                    type="submit"
                    startIcon={<Save />}
                    color="secondary"
                    variant="contained"
                    onClick={() => handleSubmit(onSubmit, onInvalid)()}
                >
                    Save
                </Button>
                <Button
                    aria-label="saveandclose"
                    type="submit"
                    startIcon={<Save />}
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                        setClose(true);
                        handleSubmit(onSubmit, onInvalid)();
                    }}
                    data-test={SurveyEnum.SAVE_AND_CLOSE}
                >
                    Save &amp; Close
                </Button>
            </div>
        </>
    );
};

export default SurveyEditor;
