import moment from 'moment';
import { InsurancePlanType, PatientInsurancePlan } from '../schemaTypes';

export function getActiveInsurancePlanData(
    patientInsurancePlans?: (PatientInsurancePlan | null)[],
): PatientInsurancePlan | null {
    if (!patientInsurancePlans || !patientInsurancePlans.length) {
        return null;
    }

    const validInsurancePlans = patientInsurancePlans.filter(
        plan => plan !== null,
    ) as PatientInsurancePlan[];
    const activeInsurancePlan = validInsurancePlans.find(
        ({ isActive, type }) => isActive && type === InsurancePlanType.Cigna,
    );

    if (!activeInsurancePlan) {
        return null;
    }

    return activeInsurancePlan;
}

function isEligibleForLactationServicesByDate(
    insurancePlan: PatientInsurancePlan | null,
    date?: string,
) {
    if (!insurancePlan) {
        return false;
    }
    const datesFormat = 'MM/DD/YYYY';
    const dateToCheck = date ? moment(date, datesFormat) : moment();
    const { eligibilityStartDate: start, eligibilityEndDate: end } = insurancePlan;
    return dateToCheck.isBetween(
        moment(start, datesFormat),
        moment(end, datesFormat),
        undefined,
        '[]',
    );
}

export function isEligibleForLactationServices(insurancePlan: PatientInsurancePlan | null) {
    if (!insurancePlan) {
        return false;
    }
    return isEligibleForLactationServicesByDate(insurancePlan);
}

export function wasEligibleForLactationServicesAtDate(
    patientInsurancePlans?: (PatientInsurancePlan | null)[],
    date?: string,
    allowNoInsuranceClaims = false,
) {
    if (!patientInsurancePlans || !patientInsurancePlans.length) {
        return allowNoInsuranceClaims;
    }

    const validInsurancePlans = patientInsurancePlans.filter(
        plan => plan !== null,
    ) as PatientInsurancePlan[];
    const isEligible = validInsurancePlans.some(
        insurancePlan =>
            insurancePlan.type === InsurancePlanType.Cigna &&
            isEligibleForLactationServicesByDate(insurancePlan, date),
    );

    return isEligible;
}
