import { OffsetType } from '~/enums/enums';

export const getOffsets = (
    offsetDays: number,
    offsetMonths: number,
    offsetYears: number,
): { days: number; months: number; years: number; type: OffsetType } => {
    return {
        days: Math.abs(offsetDays),
        months: Math.abs(offsetMonths),
        years: Math.abs(offsetYears),
        type:
            offsetDays >= 0 && offsetMonths >= 0 && offsetYears >= 0
                ? OffsetType.After
                : OffsetType.Before,
    };
};
