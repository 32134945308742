import { makeStyles } from 'tss-react/mui';

type useStylesProps = {
    height: string | number;
};

export const useStyles = makeStyles<useStylesProps>()((theme, { height }) => ({
    root: {
        gridArea: 'topNav',
        height,
        width: 'calc(100% + 110px)',
        paddingRight: '5%',
        paddingLeft: 'calc(110px + 3%)',
        marginLeft: '-110px',
        zIndex: 500,
        boxShadow: 'none',
    },
    navLink: {
        color: '#fff',
        transition: 'color .2s',
        '&:hover': {
            color: theme.colors.Secondary(),
        },
    },
    activeLink: {
        color: `${theme.colors.Secondary()} !important`,
    },
    orgDropDown: {
        '& .MuiInputBase-input': {
            color: 'white',
        },
        '& .MuiAutocomplete-popupIndicatorOpen': {
            transform: 'rotate(0deg)',
        },
        width: '250px',
    },
    dropDownOption: {
        fontSize: '14px',
    },
    orgInput: {
        fontSize: '14px',
        '& > .MuiInputBase-root': {
            borderBottom: 'none',
            '&:before': {
                content: 'none',
            },
            '&:after': {
                borderBottomColor: 'transparent',
            },
            '& > .MuiAutocomplete-endAdornment .MuiIconButton-label': {
                color: '#fff !important',
                fill: '#fff !important',
            },
        },
    },
    advocateNotificationsMenu: {
        '& .MuiMenu-list': {
            paddingTop: '0',
        },
        '& .MuiMenu-paper': {
            border: '1px solid black',
            borderRadius: '10px',
            overflow: 'hidden',
        },
    },
    advocateNotificationsContainer: {
        overflow: 'scroll',
        maxHeight: '300px',
        width: 'min(70vw, 500px)',
    },
    notificationTime: {
        flex: 'none',
        marginLeft: 'auto',
    },
    notificationsHeader: {
        height: '50px',
        backgroundColor: '#1fa0d6',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    notificationsHeaderText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: '20px',
    },
    emptyNotificationsList: {
        width: '100%',
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
    },
    taskInfo: {
        margin: 0,
        color: 'black',
        '& .MuiTypography-root': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            fontWeight: 'bold',
            textOverflow: 'ellipsis',
        },
    },
    taskLabel: {
        margin: 0,
        color: 'black',
        '& .MuiTypography-root': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    dividerColor: {
        color: 'black',
    },
    notificationItemContainer: {
        display: 'flex',
    },
    isViewed: {
        minWidth: '20px',
        marginRight: '20px',
    },
    isViewedIcon: {
        minWidth: '20px',
        color: '#1fa0d6',
    },
    taskInfoContainer: {
        display: 'flex',
        width: '75%',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    openConvBadge: {
        '& span': {
            borderRadius: '4px',
        },
    },
    convsBadgeContainer: {
        marginTop: '10px',
        '& button': {
            minWidth: 0,
        },
    },
}));
