import Color from 'color';

export type ColorSpectrumFunc = (
    value?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 1000,
) => string;

const colorTineShadeHelperConstructor =
    (baseColor: string): ColorSpectrumFunc =>
    (value): string => {
        switch (value) {
            case 100:
                return Color(baseColor).mix(Color('#fff'), 0.9).hex().toString();
            case 200:
                return Color(baseColor).mix(Color('#fff'), 0.6).hex().toString();
            case 300:
                return Color(baseColor).mix(Color('#fff'), 0.4).hex().toString();
            case 400:
                return Color(baseColor).mix(Color('#fff'), 0.2).hex().toString();
            case 500:
                return Color(baseColor).mix(Color('#fff'), 0.05).hex().toString();
            case 600:
                return Color(baseColor).mix(Color('#000'), 0.05).hex().toString();
            case 700:
                return Color(baseColor).mix(Color('#000'), 0.1).hex().toString();
            case 800:
                return Color(baseColor).mix(Color('#000'), 0.2).hex().toString();
            case 900:
                return Color(baseColor).mix(Color('#000'), 0.4).hex().toString();
            case 1000:
                return Color(baseColor).mix(Color('#000'), 0.6).hex().toString();
            default:
                return baseColor;
        }
    };

export default colorTineShadeHelperConstructor;
