// This component has a UI Test
import { faFileDownload, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, IconButton, Link, TablePagination, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add, Delete, InfoOutlined, PersonOutlined } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import MaterialTable, { MaterialTableProps, OrderByCollection } from '@material-table/core';
import React, { ReactElement, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { displayBirthday, displayDateWithAbbrTimeZone } from '~/helpers/dateHelpers';
import { flipDirection } from '~/helpers/flipDirection';
import tableIcons from '~/helpers/tableIcons';
import { useQueryParams } from '~/hooks';
import useUserPermissions from '~/hooks/useUserPermissions';
import {
    AlertSeverity,
    Appointment,
    FetchApptsForApptsPageQueryDocument,
    FetchApptsForApptsPageQueryQuery,
    FetchApptsForApptsPageQueryQueryVariables,
    OrderByDirectionEnum,
    useDeleteAppointmentByIdMutation,
    useFetchApptsForApptsPageQueryQuery,
    useGetPatientReportByKeyLazyQuery,
} from '~/schemaTypes';
import { AppointmentEnum } from '~/selectors';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import { PAGESIZE } from '~/constants';
import AppointmentModal from './AppointmentModal/AppointmentModal';
import { AppointmentSortingFields, IAppointmentOrderChange } from './types';

const useStyles = makeStyles()(_theme => ({
    root: {
        position: 'relative',
        height: '100%',
        '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
            opacity: '0',
        },
    },
    searchChips: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
    },
}));

const columnIdToSortingFieldMap: Record<number, AppointmentSortingFields> = {
    7: AppointmentSortingFields.durationMinutes,
    9: AppointmentSortingFields.startDate,
    12: AppointmentSortingFields.description,
    13: AppointmentSortingFields.note,
};

export default function Appointments(): ReactElement {
    const [query] = useQueryParams();
    const [search, setSearch] = useState<string>(query.get('_search') ?? '');
    const history = useNavigate();
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const [showApptModal, setShowApptModal] = useState(false);
    const [editApptId, setEditApptId] = useState<string>('');
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [order, setOrder] = useState<IAppointmentOrderChange>({
        field: AppointmentSortingFields.startDate,
        direction: OrderByDirectionEnum.Desc,
        isInit: true,
    });

    const suffix = 'Appointments';
    const filterTestData = localStorage.getItem(`filterTestData${suffix}`) === 'true' ?? false;

    const fetchAppointmentsVariables: FetchApptsForApptsPageQueryQueryVariables = useMemo(
        () => ({
            input: {
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                orderBy: {
                    field: order.field,
                    order: order.direction,
                },
                filterTestData,
            },
        }),
        [order, page, rowsPerPage, filterTestData],
    );

    const { data: apptsQueryData, loading: apptsLoading } = useFetchApptsForApptsPageQueryQuery({
        variables: fetchAppointmentsVariables,
    });

    const [deleteAppt, { loading: deletingAppt }] = useDeleteAppointmentByIdMutation({
        update: (cache, res) => {
            const apptsQuery = cache.readQuery<FetchApptsForApptsPageQueryQuery>({
                query: FetchApptsForApptsPageQueryDocument,
            });
            const filteredAppts =
                apptsQuery?.currentUser?.appointmentsV2.results.filter(
                    p => p?.id !== res.data?.deleteAppointment?.id,
                ) ?? [];
            if (apptsQuery?.currentUser) {
                cache.writeQuery<FetchApptsForApptsPageQueryQuery>({
                    query: FetchApptsForApptsPageQueryDocument,
                    data: {
                        ...apptsQuery,
                        currentUser: {
                            ...apptsQuery.currentUser,
                            appointmentsV2: {
                                total: apptsQuery.currentUser.appointmentsV2.total - 1,
                                results: filteredAppts,
                            },
                        },
                    },
                });
            }
        },
    });

    const getDirection = (order: string): OrderByDirectionEnum =>
        order.toUpperCase() === OrderByDirectionEnum.Asc
            ? OrderByDirectionEnum.Asc
            : OrderByDirectionEnum.Desc;

    const changeDirection = (previousDirection: OrderByDirectionEnum): void => {
        setOrder(state => ({
            ...state,
            direction: flipDirection(previousDirection),
            isInit: false,
        }));
    };

    const handleOrderChange = (orderByCollection: OrderByCollection[]) => {
        // material table passes empty array some times, which causes undefined error
        if (!orderByCollection || orderByCollection.length === 0) {
            return;
        }
        const orderBy = orderByCollection[0];
        const field = columnIdToSortingFieldMap[orderBy.orderBy];
        if (field) {
            if (field !== order.field) {
                setOrder(state => ({
                    ...state,
                    isInit: true,
                }));
            }
            const direction = order.isInit ? getDirection(orderBy.orderDirection) : order.direction;
            changeDirection(direction);
            setOrder(state => ({
                ...state,
                field,
            }));
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const getHeaderStyle = (field: AppointmentSortingFields): React.CSSProperties => ({
        fontWeight: order.field === field ? 'bold' : 'initial',
    });

    const [fetchPatientReportS3ObjectByKey] = useGetPatientReportByKeyLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted: res => {
            if (res.getPatientReportByKey?.data) {
                const blob = new Blob([
                    new Uint8Array(res.getPatientReportByKey.data as unknown as Buffer).buffer,
                ]);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download =
                    res?.getPatientReportByKey?.filename || 'patient-summary-report.pdf';
                link.click();
                link.remove();
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'Report for this patient not found',
                });
            }
        },
    });

    const downloadReport = (reportKey: string) => {
        fetchPatientReportS3ObjectByKey({
            variables: {
                input: {
                    key: reportKey,
                },
            },
        });
    };

    return (
        <div
            className={classes.root}
            data-test={AppointmentEnum.APPOINTMENTS_CONTAINER}
            ref={tableRef}
        >
            <MaterialTable
                title="Appointments"
                icons={tableIcons as MaterialTableProps<Appointment>['icons']}
                actions={[
                    {
                        onClick: () => setShowApptModal(true),
                        hidden: !pagePermissions?.Appointments.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add New Appointment',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, appt: any) => {
                            setEditApptId(appt.id);
                            setShowApptModal(true);
                        },
                        disabled: !pagePermissions?.Appointments.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit',
                    },
                    {
                        onClick: (_, { id }: any) => {
                            // eslint-disable-next-line no-restricted-globals, no-alert
                            TriggerGlobalConfirm({
                                message: 'Do you really want to delete this appointment?',
                                callback: () => {
                                    deleteAppt({ variables: { input: { id } } });
                                },
                            });
                        },
                        hidden: !pagePermissions?.Appointments.Delete,
                        tooltip: 'Delete',
                        icon: () => <Delete />,
                    },
                ]}
                columns={[
                    {
                        title: 'Patient Summary',
                        field: 'patientSummary',
                        align: 'center',
                        sorting: false,
                        render: appt =>
                            appt.reportKey ? (
                                <FontAwesomeIcon
                                    key={appt.id}
                                    onClick={() => downloadReport(appt.reportKey || '')}
                                    icon={faFileDownload}
                                    size="2x"
                                />
                            ) : (
                                '—'
                            ),
                    },
                    {
                        title: 'Appt Id',
                        field: 'id',
                        align: 'center',
                        sorting: false,
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Appointment Id copied to clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'Patient Id',
                        field: 'patient.id',
                        align: 'center',
                        sorting: false,
                        render: ({ patient }) => (
                            <Tooltip title={patient?.id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${patient?.id}`);
                                        TriggerGlobalAlert({
                                            message: 'Patient Id copied to clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <PersonOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'External Appt Id',
                        field: 'externalId',
                        align: 'center',
                        sorting: false,
                        render: ({ externalId }) => {
                            if (externalId) {
                                return (
                                    <Tooltip title={externalId ?? ''}>
                                        <IconButton
                                            onClick={() => {
                                                copy(`${externalId}`);
                                                TriggerGlobalAlert({
                                                    message:
                                                        'External Appointment Id copied to clipboard',
                                                    severity: AlertSeverity.Success,
                                                });
                                            }}
                                            size="large"
                                        >
                                            <InfoOutlined />
                                        </IconButton>
                                    </Tooltip>
                                );
                            }
                            return '';
                        },
                    },
                    {
                        title: 'First Name',
                        field: 'patient.firstName',
                        width: 46,
                        sorting: false,
                    },
                    {
                        title: 'Last Name',
                        field: 'patient.lastName',
                        width: 46,
                        sorting: false,
                    },
                    {
                        title: 'DOB',
                        field: 'patient.birthDate',
                        render: ({ patient }) => {
                            return (
                                <Typography>
                                    {patient.birthDate &&
                                        displayBirthday({ isoDateStr: patient.birthDate })}
                                </Typography>
                            );
                        },
                        customSort: (a, b) =>
                            new Date(a.patient.birthDate ?? '').getTime() -
                            new Date(b.patient.birthDate ?? '').getTime(),
                        sorting: false,
                    },
                    {
                        title: 'Duration (minutes)',
                        field: 'durationMinutes',
                        width: 24,
                        headerStyle: getHeaderStyle(AppointmentSortingFields.durationMinutes),
                    },
                    {
                        title: 'Staff Name',
                        field: 'appointment.staffName',
                        sorting: false,
                        searchable: false,
                        render: ({ staff, staffName }) => {
                            return (
                                <Typography>{(staff ? staff.name : staffName) ?? ''}</Typography>
                            );
                        },
                    },
                    {
                        title: 'Start Date & Time',
                        render: ({ startDate, timezoneName }) => (
                            <Typography noWrap>
                                {displayDateWithAbbrTimeZone({
                                    isoDateStr: startDate,
                                    format: 'MM/DD/YYYY • hh:mm A',
                                    timeZone:
                                        timezoneName ??
                                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                                })}
                            </Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
                        headerStyle: getHeaderStyle(AppointmentSortingFields.startDate),
                    },
                    {
                        title: 'Practice',
                        field: 'practice.name',
                        sorting: false,
                    },
                    {
                        title: 'Location',
                        render: ({ physicalLocation, isVirtualVisit, virtualLink }) => {
                            if (!isVirtualVisit || !virtualLink) {
                                return <Typography> {physicalLocation}</Typography>;
                            }
                            return (
                                <Link
                                    href={virtualLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    underline="hover"
                                >
                                    Virtual Link
                                </Link>
                            );
                        },
                        sorting: false,
                    },
                    {
                        title: 'Description',
                        field: 'description',
                        headerStyle: getHeaderStyle(AppointmentSortingFields.description),
                    },
                    {
                        title: 'Note',
                        field: 'note',
                        headerStyle: getHeaderStyle(AppointmentSortingFields.note),
                    },
                    { title: '', field: 'patient.id', hidden: true, searchable: true },
                ]}
                isLoading={apptsLoading || deletingAppt}
                data={apptsQueryData?.currentUser?.appointmentsV2?.results ?? []}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={apptsQueryData?.currentUser?.appointmentsV2.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                onOrderCollectionChange={handleOrderChange}
                onSearchChange={searchText => {
                    setSearch(searchText);
                    history(`/appointments${searchText && `?_search=${searchText}`}`);
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    searchText: search ?? '',
                    exportAllData: pagePermissions?.Appointments.Export,
                    paginationPosition: 'both',
                }}
            />
            <Dialog
                scroll="paper"
                open={showApptModal}
                fullWidth
                aria-labelledby="form-dialog-title"
                data-test={AppointmentEnum.APPOINTMENT_MODAL}
            >
                <AppointmentModal
                    setOpen={setShowApptModal}
                    setEditAppointmentId={setEditApptId}
                    id={editApptId}
                />
            </Dialog>
        </div>
    );
}
