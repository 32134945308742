import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import { Grid, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    useStcOverageSettingsFetchAffiliateQuery,
    useUpdateStCoverageInfoForAffiliateMutation,
    StcOverageSettingsFetchAffiliateDocument,
} from '~/schemaTypes';
import { useAffiliateCareView } from '~/hooks';
import Loading from '~/components/Loading/Loading';
import {
    officeAddressesMapper,
    coverageAreasMapper,
} from '~/views/AffiliateCare/Onboarding/mappers';
import VirtualType from '~/views/AffiliateCare/Onboarding/steps/ServiceTypesAndLocations/components/VirtualType/VirtualType';
import InOfficeType from '~/views/AffiliateCare/Onboarding/steps/ServiceTypesAndLocations/components/InOfficeType/InOfficeType';
import PatientsHomeType from '~/views/AffiliateCare/Onboarding/steps/ServiceTypesAndLocations/components/PatientsHomeType/PatientsHomeType';
import Layout from '../components/Layout';
import { FormInput } from './types';
import { ST_SETTINGS_FORM_SCHEMA } from './yupSchema';

const ServiceTypesAndLocations = () => {
    const { userAffiliateId, id } = useAffiliateCareView();
    const { data, loading: fetchLoading } = useStcOverageSettingsFetchAffiliateQuery({
        variables: {
            input: {
                affiliateId: userAffiliateId,
            },
        },
    });
    const [updateLocations, { loading: updateLoading }] =
        useUpdateStCoverageInfoForAffiliateMutation({
            refetchQueries: [
                {
                    query: StcOverageSettingsFetchAffiliateDocument,
                    variables: { input: { affiliateId: userAffiliateId } },
                },
            ],
        });
    const formData = useForm<FormInput>({
        resolver: yupResolver(ST_SETTINGS_FORM_SCHEMA as any),
        mode: 'all',
        defaultValues: {
            coverageAreas: [],
            officeAddresses: [],
            isInOfficeType: false,
            isVirtualType: false,
            isPatientsHomeType: false,
        },
    });
    const {
        getValues,
        formState: { isDirty, defaultValues, isValid },
        reset,
        watch,
        trigger,
    } = formData;
    useEffect(() => {
        if (!isValid) {
            trigger();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);
    const coverageAreas = watch('coverageAreas');
    const officeAddresses = watch('officeAddresses');
    useEffect(() => {
        if (
            isValid &&
            (isDirty ||
                (!isEqual(coverageAreas, defaultValues?.coverageAreas) && coverageAreas.length) ||
                (!isEqual(officeAddresses, defaultValues?.officeAddresses) &&
                    officeAddresses.length))
        ) {
            updateLocations({
                variables: {
                    input: {
                        id,
                        data: getValues(),
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coverageAreas, officeAddresses, isDirty, isValid]);

    useEffect(() => {
        if (data?.virtualCareAffiliateByAffiliateId?.data) {
            const {
                virtualCareAffiliateByAffiliateId: { data: affiliateData },
            } = data;
            const newDefaultValues = {
                coverageAreas: coverageAreasMapper(affiliateData.coverageAreas),
                isInOfficeType: !!affiliateData.isInOfficeType,
                officeAddresses: officeAddressesMapper(affiliateData.officeAddresses),
                isVirtualType: !!affiliateData.isVirtualType,
                isPatientsHomeType: !!affiliateData.isPatientsHomeType,
            };

            reset(newDefaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.virtualCareAffiliateByAffiliateId?.data]);
    const loading = fetchLoading || !data?.virtualCareAffiliateByAffiliateId?.data || updateLoading;
    return (
        <Layout title="Service Types and Coverage">
            <Grid item xs={12}>
                <Typography variant="subtitle1">
                    What types of Lactation Services will you provide? (Select All That Apply)
                </Typography>
            </Grid>
            {loading ? (
                <Loading />
            ) : (
                <FormProvider {...formData}>
                    <Grid item xs={12} container>
                        <VirtualType />
                    </Grid>
                    <Grid item xs={12} container>
                        <InOfficeType deleteConfirm />
                    </Grid>
                    <Grid item xs={12} container>
                        <PatientsHomeType deleteConfirm />
                    </Grid>
                </FormProvider>
            )}
        </Layout>
    );
};

export default ServiceTypesAndLocations;
