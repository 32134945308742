import { faFileDownload, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add, Delete, InfoOutlined } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { displayDateWithAbbrTimeZone } from '~/helpers/dateHelpers';
import tableIcons from '~/helpers/tableIcons';
import { useQueryParams } from '~/hooks';
import useUserPermissions from '~/hooks/useUserPermissions';
import {
    AlertSeverity,
    FetchApptsForPatientApptsPageQueryDocument,
    FetchApptsForPatientApptsPageQueryQuery,
    FetchApptsForPatientApptsPageQueryQueryVariables,
    useDeleteAppointmentByIdMutation,
    useFetchApptsForPatientApptsPageQueryQuery,
    useGetPatientReportByKeyLazyQuery,
} from '~/schemaTypes';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';

import AppointmentModal from '~/views/Dashboard/Appointments/AppointmentModal/AppointmentModal';

const useStyles = makeStyles()(_theme => ({
    root: {
        position: 'relative',
        height: '100%',
    },
    searchChips: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
    },
}));

type RouteParams = {
    id: string;
};

const PatientAppointments: React.FC = () => {
    const [query] = useQueryParams();
    const location = useLocation();
    const { id } = useParams<RouteParams>();
    const [search, setSearch] = useState<string>(query.get('_search') ?? '');
    const history = useNavigate();
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [showApptModal, setShowApptModal] = useState(false);
    const [editApptId, setEditApptId] = useState<string>('');
    const { data: apptsQueryData, loading: apptsLoading } =
        useFetchApptsForPatientApptsPageQueryQuery({
            variables: {
                patientInput: {
                    id,
                },
                appointmentInput: {
                    excludePatientCreated: true,
                },
            },
        });

    const [deleteAppt, { loading: deletingAppt }] = useDeleteAppointmentByIdMutation({
        update: (cache, res) => {
            const apptsQuery = cache.readQuery<FetchApptsForPatientApptsPageQueryQuery>({
                query: FetchApptsForPatientApptsPageQueryDocument,
                variables: {
                    patientInput: {
                        id,
                    },
                    appointmentInput: {
                        excludePatientCreated: true,
                    },
                },
            });
            const filteredAppts =
                apptsQuery?.patient?.appointments?.filter(
                    p => p?.id !== res.data?.deleteAppointment?.id,
                ) ?? [];
            if (apptsQuery?.patient) {
                cache.writeQuery<
                    FetchApptsForPatientApptsPageQueryQuery,
                    FetchApptsForPatientApptsPageQueryQueryVariables
                >({
                    variables: {
                        patientInput: { id },
                        appointmentInput: {
                            excludePatientCreated: true,
                        },
                    },
                    query: FetchApptsForPatientApptsPageQueryDocument,
                    data: {
                        ...apptsQuery,
                        patient: {
                            ...apptsQuery.patient,
                            appointments: filteredAppts,
                        },
                    },
                });
            }
        },
    });

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    const [fetchPatientReportS3ObjectByKey] = useGetPatientReportByKeyLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted: res => {
            if (res.getPatientReportByKey?.data) {
                const blob = new Blob([
                    new Uint8Array(res.getPatientReportByKey.data as unknown as Buffer).buffer,
                ]);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = res?.getPatientReportByKey.filename || 'patient-summary-report.pdf';
                link.click();
                link.remove();
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'Report for this patient not found',
                });
            }
        },
    });

    const downloadReport = (reportKey: string) => {
        fetchPatientReportS3ObjectByKey({
            variables: {
                input: {
                    key: reportKey,
                },
            },
        });
    };

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Appointments"
                icons={tableIcons as MaterialTableProps<any>['icons']}
                actions={[
                    {
                        onClick: () => setShowApptModal(true),
                        hidden: !pagePermissions?.Appointments.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add New Appointment',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, appt: any) => {
                            setEditApptId(appt.id);
                            setShowApptModal(true);
                        },
                        disabled: !pagePermissions?.Appointments.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit',
                    },
                    {
                        onClick: (_, { id }: any) => {
                            // eslint-disable-next-line no-restricted-globals, no-alert
                            TriggerGlobalConfirm({
                                message: 'Do you really want to delete this appointment?',
                                callback: () => {
                                    deleteAppt({ variables: { input: { id } } });
                                },
                            });
                        },
                        hidden: !pagePermissions?.Appointments.Delete,
                        tooltip: 'Delete',
                        icon: () => <Delete />,
                    },
                ]}
                columns={[
                    {
                        title: 'Patient Summary',
                        field: 'patientSummary',
                        align: 'center',
                        sorting: false,
                        render: appt =>
                            appt.reportKey ? (
                                <FontAwesomeIcon
                                    key={appt.id}
                                    onClick={() => downloadReport(appt.reportKey)}
                                    icon={faFileDownload}
                                    size="2x"
                                />
                            ) : (
                                '—'
                            ),
                    },
                    {
                        title: 'Appt Id',
                        field: 'id',
                        align: 'center',
                        sorting: false,
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Appointment Id Copied to Clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'External Appt Id',
                        field: 'externalId',
                        align: 'center',
                        sorting: false,
                        render: ({ externalId }) => {
                            if (externalId) {
                                return (
                                    <Tooltip title={externalId ?? ''}>
                                        <IconButton
                                            onClick={() => {
                                                copy(`${externalId}`);
                                                TriggerGlobalAlert({
                                                    message:
                                                        'External Appointment Id Copied to Clipboard',
                                                    severity: AlertSeverity.Success,
                                                });
                                            }}
                                            size="large"
                                        >
                                            <InfoOutlined />
                                        </IconButton>
                                    </Tooltip>
                                );
                            }
                            return '';
                        },
                    },
                    { title: 'Duration (minutes)', field: 'durationMinutes', width: 24 },
                    {
                        title: 'Staff Name',
                        field: 'appointment.staffName',
                        sorting: false,
                        searchable: false,
                        render: ({ staff, staffName }) => {
                            return (
                                <Typography>{(staff ? staff.name : staffName) ?? ''}</Typography>
                            );
                        },
                    },
                    {
                        title: 'Start Date & Time',
                        render: ({ startDate, timezoneName }) => (
                            <Typography>
                                {displayDateWithAbbrTimeZone({
                                    isoDateStr: startDate,
                                    format: 'MM/DD/YYYY • hh:mm A',
                                    timeZone:
                                        timezoneName ??
                                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                                })}
                            </Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
                        defaultSort: 'desc',
                    },
                    { title: 'Practice', field: 'practice.name' },
                    {
                        title: 'Location',
                        render: ({ physicalLocation, isVirtualVisit, virtualLink }) => {
                            if (!isVirtualVisit) {
                                return <Typography> {physicalLocation}</Typography>;
                            }
                            return (
                                <Link
                                    href={virtualLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    underline="hover"
                                >
                                    Virtual Link
                                </Link>
                            );
                        },
                    },
                    { title: 'Description', field: 'description' },
                    { title: 'Note', field: 'note' },
                    { title: '', field: 'patient.id', hidden: true, searchable: true },
                ]}
                isLoading={apptsLoading || deletingAppt}
                data={[...(apptsQueryData?.patient?.appointments ?? []).map(role => ({ ...role }))]}
                onSearchChange={searchText => {
                    setSearch(searchText);
                    history(`${location.pathname}${searchText && `?_search=${searchText}`}`);
                }}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    searchText: search ?? '',
                    exportAllData: pagePermissions?.Appointments.Export,
                }}
                localization={{ header: { actions: '' } }}
            />
            <Dialog
                scroll="paper"
                open={showApptModal}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <AppointmentModal
                    setOpen={setShowApptModal}
                    setEditAppointmentId={setEditApptId}
                    id={editApptId}
                    defaultValues={{
                        patientId: id,
                        patientName: `${apptsQueryData?.patient?.lastName}, ${apptsQueryData?.patient?.firstName}`,
                    }}
                />
            </Dialog>
        </div>
    );
};

export default PatientAppointments;
