// This component has a UI Test
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialTable from '@material-table/core';
import { Add } from '@mui/icons-material';
import { Dialog, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { PAGESIZE } from '~/constants';
import { lowercaseFirstLetter } from '~/helpers/lowercaseFirstLetter';
import tableIcons from '~/helpers/tableIcons';
import useUserPermissions from '~/hooks/useUserPermissions';
import { useFetchConfigValueDefsForConfigValuesPageQuery } from '~/schemaTypes';
import { AppDefaultEnum } from '~/selectors';

import ValueDefsModal from './ValueDefsModal/ValueDefsModal';

const useStyles = makeStyles()({
    root: {
        position: 'relative',
        height: '100%',
    },
});

const ConfigValueDefs: React.FC = () => {
    const { classes } = useStyles();
    const [showValueDefsModal, setShowValueDefsModal] = useState<boolean>(false);
    const [editValueDefId, setEditValueDefId] = useState<string>('');
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    const { data: configValueData, loading: configValueLoading } =
        useFetchConfigValueDefsForConfigValuesPageQuery();

    return (
        <div className={classes.root} data-test={AppDefaultEnum.CONTAINER} ref={tableRef}>
            <MaterialTable
                title="App Defaults"
                icons={tableIcons}
                isLoading={configValueLoading}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                data={_.cloneDeep(configValueData?.configValueDefs) ?? []}
                actions={[
                    {
                        onClick: () => setShowValueDefsModal(true),
                        hidden: !pagePermissions?.AppDefaults.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add App Default',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, user: any) => {
                            setEditValueDefId(user.id);
                            setShowValueDefsModal(true);
                        },
                        hidden: !pagePermissions?.AppDefaults.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit App Default',
                    },
                ]}
                columns={[
                    { title: 'Name', field: 'name', defaultSort: 'asc' },
                    { title: 'Description', field: 'description' },
                    {
                        title: 'Default Value',
                        field: 'defaultValue',
                        render: ({ defaultValue, valueType }) => {
                            return (
                                <Typography>
                                    {defaultValue[lowercaseFirstLetter(valueType)] &&
                                        JSON.stringify(
                                            defaultValue[lowercaseFirstLetter(valueType)],
                                        )
                                            .replaceAll('"', '')
                                            .replaceAll('{', '')
                                            .replaceAll('}', '')
                                            .replaceAll('[', '')
                                            .replaceAll(']', '')
                                            .replace(/__typename[^,]*,/, '')}
                                </Typography>
                            );
                        },
                    },
                    { title: 'Value Type', field: 'valueType' },
                ]}
                options={{ pageSize: rowsPerPage, exportAllData: true }}
                localization={{ header: { actions: '' } }}
            />
            <Dialog
                scroll="paper"
                open={showValueDefsModal}
                fullWidth
                aria-labelledby="form-dialog-title"
                data-test={AppDefaultEnum.MODAL}
            >
                <ValueDefsModal
                    setOpen={setShowValueDefsModal}
                    setEditValueDefId={setEditValueDefId}
                    id={editValueDefId}
                />
            </Dialog>
        </div>
    );
};

export default ConfigValueDefs;
