/* eslint-disable camelcase */
import { format } from 'date-fns';
import { UserProfileValueType } from '../shared/enums';
import { Choice, ChoiceValueType, PatientProfileVariableModalViewModalType } from '../shared/types';

export const getChoiceValue = (
    choice: Choice | null,
    choiceValueType: ChoiceValueType,
    viewModelValue: PatientProfileVariableModalViewModalType,
): string | number | Date | boolean | null => {
    const { id, value, specifyValue } = choice ?? {};

    let choiceValue: string | number | Date | boolean | null = value?.[choiceValueType] ?? '';
    const shouldSpecifyValue = specifyValue ?? false;
    choiceValue = shouldSpecifyValue
        ? viewModelValue.specified?.get(id ?? '')?.val?.[choiceValueType] ?? null
        : choiceValue;

    if (choiceValueType === UserProfileValueType.Date && choiceValue) {
        choiceValue = format(new Date(choiceValue as string), 'MM/dd/yyyy');
    }

    return choiceValue;
};
