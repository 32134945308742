import ObjectId from 'bson-objectid';
import { toBase64 } from '~/helpers/base64Helper';
import { AdvocateTaskHistoryItemInput } from '~/schemaTypes';

type CreateHistoryItem = (whoId: string, what: string) => AdvocateTaskHistoryItemInput;
const createHistoryItem: CreateHistoryItem = (whoId, what) => ({
    id: new ObjectId().toHexString(),
    whoId,
    what,
    when: new Date().toISOString(),
});

export const createHistoryItemEncoded: CreateHistoryItem = (whoId, what) => ({
    id: new ObjectId().toHexString(),
    whoId,
    what: toBase64(what) || '',
    when: new Date().toISOString(),
});

export default createHistoryItem;
