import React, { Dispatch, SetStateAction } from 'react';
import { Grid, TextField, DialogContent, Typography } from '@mui/material';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { useFetchSubmittedClaimQuery } from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';

type ViewClaimModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    claimId?: string;
};

const ViewClaim: React.FC<ViewClaimModalProps> = props => {
    const { claimId, setOpen } = props;
    const { data, loading } = useFetchSubmittedClaimQuery({
        variables: {
            input: {
                id: claimId,
            },
        },
    });
    return (
        <div>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setOpen(false)}>
                Submitted Claim - {claimId}
            </DialogTitleWithClose>
            <DialogContent dividers>
                {loading && <Loading />}
                {!loading && (
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item>
                            <Typography>Status: {data?.submittedClaim?.status}</Typography>
                        </Grid>
                        {data?.submittedClaim?.errorText && (
                            <Grid item>
                                <Typography>Error: {data?.submittedClaim?.errorText}</Typography>
                            </Grid>
                        )}
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Subscriber ID"
                                fullWidth
                                type="text"
                                size="small"
                                margin="dense"
                                value={data?.submittedClaim?.SbrID}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Patient First Name"
                                fullWidth
                                type="text"
                                size="small"
                                value={data?.submittedClaim?.PatFName}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Patient Last Name"
                                fullWidth
                                type="text"
                                size="small"
                                value={data?.submittedClaim?.PatLName}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Patient Birthday"
                                fullWidth
                                type="text"
                                size="small"
                                value={data?.submittedClaim?.PatBirthdate}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Patient Sex"
                                fullWidth
                                type="text"
                                size="small"
                                value={data?.submittedClaim?.PatSex}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Insured First Name"
                                fullWidth
                                type="text"
                                size="small"
                                value={data?.submittedClaim?.SbrFName}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Insured Last Name"
                                fullWidth
                                type="text"
                                size="small"
                                value={data?.submittedClaim?.SbrLName}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Patient Adress"
                                fullWidth
                                type="text"
                                size="small"
                                value={data?.submittedClaim?.PatAddr}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Patient City"
                                fullWidth
                                type="text"
                                size="small"
                                value={data?.submittedClaim?.PatCity}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Patient State"
                                fullWidth
                                type="text"
                                size="small"
                                value={data?.submittedClaim?.PatState}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Patient Zip"
                                fullWidth
                                type="text"
                                size="small"
                                value={data?.submittedClaim?.PatZip}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Subscriber Address"
                                fullWidth
                                type="text"
                                size="small"
                                value={data?.submittedClaim?.SbrAddr}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Subscriber City"
                                fullWidth
                                type="text"
                                size="small"
                                value={data?.submittedClaim?.SbrCity}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Subscriber State"
                                fullWidth
                                type="text"
                                size="small"
                                value={data?.submittedClaim?.SbrState}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Subscriber Zip"
                                fullWidth
                                type="text"
                                size="small"
                                value={data?.submittedClaim?.SbrZip}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </div>
    );
};

export default ViewClaim;
