import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ObjectID from 'bson-objectid';
import React, { useState } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import NonImageContentPicker from '~/components/NonImageContentPicker/NonImageContentPicker';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { ContentType, DirectoryItemType } from '~/schemaTypes';
import { ListItem } from '~/views/ConfigDashboard/Articles/Editors/ArticleEditor';
import { ClientSelectionType } from '~/enums/enums';
import { toClientSelectionType } from '../ContentDirectoryEditor';
import { ModalGeneralParams } from '../types';

const useStyles = makeStyles()(() => ({
    paper: { minWidth: '800px' },
    select: { width: '100%' },
}));

const SimpleModal: React.FC<ModalGeneralParams> = ({
    closeHandler,
    submitHandler,
    tags,
    apps,
    selectedItem,
}) => {
    const { classes } = useStyles();

    const [selectedContentId, setSelectedContentId] = useState<string>(
        selectedItem?.simpleContentId ?? '',
    );
    const [selectedContentName, setSelectedContentName] = useState<string>(
        selectedItem?.simpleContentName ?? '',
    );
    const [appSelection, setAppSelection] = useState<ClientSelectionType>(
        toClientSelectionType(selectedItem?.includeApps, selectedItem?.excludeApps),
    );
    const [tagList, setTagList] = useState<ListItem[]>(selectedItem?.tagList || []);
    const [contentRequired, setContentRequired] = useState<boolean>(false);
    const [appRequired, setAppRequired] = useState<boolean>(false);
    const [includeApps, setIncludeApps] = useState<ListItem[]>(
        selectedItem?.includeApps?.map(a => ({ id: a, name: a })) || [],
    );
    const [excludeApps, setExcludeApps] = useState<ListItem[]>(
        selectedItem?.excludeApps?.map(a => ({ id: a, name: a })) || [],
    );
    const onSubmit = () => {
        if (!selectedContentId) {
            setContentRequired(true);
            return;
        }
        setAppRequired(false);
        if (appSelection === ClientSelectionType.ExcludeCertainApps && excludeApps.length === 0) {
            setAppRequired(true);
            return;
        }
        if (appSelection === ClientSelectionType.IncludeCertainApps && includeApps.length === 0) {
            setAppRequired(true);
            return;
        }
        submitHandler({
            id: selectedItem ? selectedItem.id : new ObjectID().toHexString(),
            type: DirectoryItemType.SimpleContent,
            tagList,
            simpleContentId: selectedContentId,
            simpleContentName: selectedContentName,
            includeApps: includeApps.map(a => a.id),
            excludeApps: excludeApps.map(a => a.id),
        });
    };

    return (
        <Dialog open classes={{ paper: classes.paper }}>
            <DialogTitleWithClose id="modalTitle" onClose={closeHandler}>
                {selectedItem ? 'Edit Simple Content' : 'Add Simple Content'}
            </DialogTitleWithClose>
            <DialogContent>
                <NonImageContentPicker
                    selectedContentId={selectedContentId}
                    setSelectedContentId={setSelectedContentId}
                    allowedTypes={[
                        ContentType.BabyBoostLink,
                        ContentType.AppointmentHistory,
                        ContentType.CareTeam,
                        ContentType.MessageCenter,
                        ContentType.QListLink,
                        ContentType.Tracker,
                        ContentType.Milestones,
                        ContentType.WeekByWeek,
                        ContentType.WhoToCall,
                        ContentType.LearnLibrary,
                        ContentType.Surveys,
                        ContentType.PhoneNumber,
                        ContentType.Highlights,
                        ContentType.Todolist,
                        ContentType.DueDateCalculator,
                        ContentType.Resources,
                        ContentType.ExploreScreen,
                        ContentType.TrackerView,
                        ContentType.AddDependentProfile,
                        ContentType.UserBio,
                        ContentType.EligibilityCheckLink,
                    ]}
                    setSelectedContentName={setSelectedContentName}
                    required={contentRequired}
                    setRequired={setContentRequired}
                />
                <OutlinedSection title="Select Tags">
                    <Autocomplete
                        multiple
                        value={tagList}
                        options={
                            tags &&
                            tags
                                .slice()
                                .sort((a, b) => {
                                    if (a.name < b.name) return -1;
                                    if (a.name === b.name) return 0;
                                    return 1;
                                })
                                .map(tag => ({ id: tag.id, name: tag.name }))
                        }
                        onChange={(_, val) => {
                            setTagList(val);
                        }}
                        getOptionLabel={tag => tag.name}
                        renderInput={params => (
                            <TextField
                                variant="outlined"
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                label="Tag Filter"
                                placeholder="Select a Tag to filter this Item"
                            />
                        )}
                    />
                </OutlinedSection>
                <OutlinedSection title="Select Apps">
                    <select
                        key="appSelectionType"
                        value={appSelection}
                        onChange={e => {
                            setAppSelection(e.target.value as ClientSelectionType);
                            setIncludeApps([]);
                            setExcludeApps([]);
                        }}
                    >
                        <option value={ClientSelectionType.IncludeAllApps}>All Apps</option>
                        <option value={ClientSelectionType.IncludeCertainApps}>
                            Include Selected
                        </option>
                        <option value={ClientSelectionType.ExcludeCertainApps}>
                            Exclude Selected
                        </option>
                    </select>
                    {appSelection !== ClientSelectionType.IncludeAllApps && (
                        <Autocomplete
                            multiple
                            value={
                                appSelection === ClientSelectionType.IncludeCertainApps
                                    ? includeApps
                                    : excludeApps
                            }
                            options={apps && apps.slice().map(app => ({ id: app, name: app }))}
                            onChange={(_, val) => {
                                if (appSelection === ClientSelectionType.IncludeCertainApps)
                                    setIncludeApps(val);
                                else setExcludeApps(val);
                            }}
                            getOptionLabel={app => app.name}
                            renderInput={params => (
                                <TextField
                                    variant="outlined"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...params}
                                    label="App Selection"
                                    placeholder="Select an App"
                                />
                            )}
                        />
                    )}
                    {appRequired && (
                        <div style={{ color: 'red' }}>At least one app must be selected</div>
                    )}
                </OutlinedSection>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={closeHandler} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={onSubmit} color="secondary" variant="contained">
                    {selectedItem ? 'Update' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export {};
export default SimpleModal;
