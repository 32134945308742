import React, { useMemo, useRef, useState, useCallback } from 'react';
import { PAGESIZE } from '~/constants';
import {
    OrderByDirectionEnum,
    SlaTimersV2ForSlaTimersPageQueryVariables,
    useSlaTimersV2ForSlaTimersPageQuery,
} from '~/schemaTypes';
import MaterialTable, { MaterialTableProps, OrderByCollection } from '@material-table/core';
import tableIcons from '~/helpers/tableIcons';
import { TablePagination, Typography, Button } from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ISlaTimerOrderChange, SlaTimerSortingField, columnIdToSortingFieldMap } from './types';
import type { SlaTimer } from './types';
import { SLATimersFilters } from './SLATimersFilters/SLATimersFilters';

moment.updateLocale('en', {
    relativeTime: {
        m: '%d minute',
        h: '%d hour',
        d: '%d day',
        w: '%s week',
        M: '%d month',
        y: '%d year',
    },
});

const SLATimers = () => {
    const tableRef = useRef<HTMLDivElement>(null);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [order, setOrder] = useState<ISlaTimerOrderChange>({
        field: SlaTimerSortingField.completeBy,
        direction: OrderByDirectionEnum.Asc,
        isInit: false,
    });
    const [lastRefreshed, setLastRefreshed] = useState<Date>(new Date());

    const [localStorageFilters, setLocalStorageFilters] = useState(() =>
        localStorage.getItem('slaTimersFilters'),
    );

    const filters = useMemo(() => {
        const parsedFilters = localStorageFilters ? JSON.parse(localStorageFilters) : {};
        const range: { gte: Date | null; lte: Date | null } = {
            gte: null,
            lte: null,
        };

        if (parsedFilters.dateRange) {
            const now = new Date();
            switch (parsedFilters.dateRange) {
                case 'past3Days':
                    range.gte = new Date(new Date().setDate(now.getDate() - 3));
                    range.lte = now;
                    break;
                case 'past7Days':
                    range.gte = new Date(new Date().setDate(now.getDate() - 7));
                    range.lte = now;
                    break;
                case 'past30Days':
                    range.gte = new Date(new Date().setDate(now.getDate() - 30));
                    range.lte = now;
                    break;
                case 'custom':
                    range.gte = parsedFilters.customStartDate
                        ? new Date(parsedFilters.customStartDate)
                        : null;
                    range.lte = parsedFilters.customEndDate
                        ? new Date(parsedFilters.customEndDate)
                        : null;
                    break;
                default:
                    break;
            }
        }

        return {
            filter: {
                fields: {
                    ...(parsedFilters.showCompleted ? {} : { completedAt: null }),
                },
            },
            ...(range.gte ? { greaterThanDate: range.gte } : {}),
            ...(range.lte ? { lessThanDate: range.lte } : {}),
        };
    }, [localStorageFilters]);

    const handleFiltersChange = useCallback(() => {
        setLocalStorageFilters(localStorage.getItem('slaTimersFilters'));
    }, []);

    const SlaTimersFilters = useMemo(() => {
        const variables: SlaTimersV2ForSlaTimersPageQueryVariables = {
            input: {
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                orderBy: {
                    field: order.field,
                    order: order.direction,
                },
                ...filters,
            },
        };
        return variables;
    }, [page, rowsPerPage, order, filters]);

    const {
        data: slaTimers,
        loading: slaTimersLoading,
        refetch,
    } = useSlaTimersV2ForSlaTimersPageQuery({
        variables: SlaTimersFilters,
    });

    const getDirection = (order: string): OrderByDirectionEnum =>
        order.toUpperCase() === OrderByDirectionEnum.Asc
            ? OrderByDirectionEnum.Asc
            : OrderByDirectionEnum.Desc;

    const handleOrderChange = (orderByCollection: OrderByCollection[]) => {
        // material table passes empty array some times, which causes undefined error
        if (!orderByCollection || orderByCollection.length === 0) {
            return;
        }
        const orderBy = orderByCollection[0];
        const field = columnIdToSortingFieldMap[orderBy.orderBy];
        let isInit = false;
        if (field) {
            if (field !== order.field) {
                isInit = true;
            }
            const direction = getDirection(orderBy.orderDirection);
            const stateObject: ISlaTimerOrderChange = {
                direction,
                field,
                isInit,
            };
            setOrder(stateObject);
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    const handleRefresh = useCallback(() => {
        refetch();
        setLastRefreshed(new Date());
    }, [refetch]);

    return (
        <div ref={tableRef}>
            <div className="mb-2">
                <Typography variant="h4">SLA Timers</Typography>
            </div>
            <div className="flex flex-row justify-between items-center gap-2 mb-2">
                <div className="flex justify-start items-center gap-2 mb-2">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<RefreshIcon />}
                        onClick={handleRefresh}
                    >
                        Refresh
                    </Button>
                    <Typography>Page last updated: {lastRefreshed.toLocaleString()}</Typography>
                </div>
                <SLATimersFilters handleFiltersChange={handleFiltersChange} />
            </div>
            <MaterialTable<SlaTimer>
                isLoading={slaTimersLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                actions={[]}
                columns={[
                    {
                        title: 'Sla Name',
                        field: 'sla.name',
                        render: ({ sla, chatSla, task, chatConversation, patientId }) => {
                            if (task != null && sla != null) {
                                return (
                                    <Link to={`/portal/advocate-tasks/${task.id}`}>{sla.name}</Link>
                                );
                            }
                            if (chatConversation != null && chatSla != null) {
                                return (
                                    <Link to={`/portal/patients/${patientId}/chat`}>
                                        {chatSla.name}
                                    </Link>
                                );
                            }
                            return <Typography>No name found</Typography>;
                        },
                    },
                    {
                        title: 'Organization',
                        field: 'organization.name',
                    },
                    {
                        title: 'Assigned To',
                        field: 'assignedTo.name',
                        render: ({ assignedTo }) => {
                            if (assignedTo) {
                                return <Typography>{assignedTo.name}</Typography>;
                            }
                            return <Typography>Unassigned</Typography>;
                        },
                    },
                    {
                        title: 'Time to Complete By',
                        field: 'completeBy',
                        render: ({ completeBy }) => {
                            const completeByMoment = moment.utc(completeBy).local();
                            return (
                                <Typography>
                                    {`${completeByMoment.format('MM/DD/yyyy hh:mm:ss a')} ${
                                        new Date()
                                            .toLocaleTimeString('en-us', {
                                                timeZoneName: 'short',
                                            })
                                            .split(' ')[2]
                                    }`}
                                </Typography>
                            );
                        },
                    },
                    {
                        title: 'Time Remaining',
                        field: 'completeBy',
                        render: ({ completeBy, completedAt }) => {
                            if (completedAt) {
                                return <Typography>Complete</Typography>;
                            }
                            const completeByMoment = moment.utc(completeBy).local();
                            const now = moment();
                            const timeDiff = completeByMoment.diff(now, 'hours');
                            const minuteDiff = completeByMoment.diff(now, 'minutes');
                            let textColor = 'inherit';
                            if (timeDiff <= 0 && minuteDiff <= 0) {
                                textColor = 'red';
                            } else if (timeDiff < 2) {
                                textColor = 'orange';
                            }

                            return (
                                <Typography style={{ color: textColor }}>
                                    {timeDiff <= 0 && minuteDiff <= 0 ? '-' : ''}
                                    {moment.utc(completeBy).local().fromNow(true)}
                                </Typography>
                            );
                        },
                        customSort: (a, b) => {
                            if (a.completedAt) {
                                return -1;
                            }
                            if (b.completedAt) {
                                return 1;
                            }
                            return (
                                new Date(b.completeBy).getTime() - new Date(a.completeBy).getTime()
                            );
                        },
                    },
                    {
                        title: 'Completed',
                        field: 'completedAt',
                        render: ({ completedAt }) => (
                            <Typography>
                                {completedAt
                                    ? `${moment
                                          .utc(completedAt)
                                          .local()
                                          .format('MM/DD/yyyy hh:mm:ss a')}
                                          ${
                                              new Date()
                                                  .toLocaleTimeString('en-us', {
                                                      timeZoneName: 'short',
                                                  })
                                                  .split(' ')[2]
                                          }`
                                    : 'N/A'}
                            </Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.completedAt).getTime() - new Date(b.completedAt).getTime(),
                    },
                    {
                        title: 'Created On',
                        field: 'createdAt',
                        render: ({ createdAt }) => {
                            const createdAtMoment = moment.utc(createdAt).local();
                            return (
                                <Typography>{`${createdAtMoment.format('MM/DD/yyyy hh:mm:ss a')} ${
                                    new Date()
                                        .toLocaleTimeString('en-us', {
                                            timeZoneName: 'short',
                                        })
                                        .split(' ')[2]
                                }`}</Typography>
                            );
                        },
                        customSort: (a, b) =>
                            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
                    },
                ]}
                data={slaTimers?.slaTimersV2?.results ?? []}
                onOrderCollectionChange={handleOrderChange}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={slaTimers?.slaTimersV2?.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    search: false,
                    paginationPosition: 'both',
                }}
                title=""
            />
        </div>
    );
};

export default SLATimers;
