import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { ExpressionUseType } from '~/schemaTypes';
import { SurveyEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';
import ConfigExpressionPicker from '~/views/ConfigDashboard/ConfigExpressions/components/ConfigExpressionPicker';

interface NameFormInput {
    label: {
        en: string;
        es: string;
    };
}
const NameInputValidation = Yup.object().shape({
    label: Yup.object().shape({
        en: Yup.string().required('Required'),
        es: Yup.string(),
    }),
});

const NameModal: React.FC<{
    divisionId?: string;
    sectionId?: string;
    title: string;
    typeName: string;
    expressionList?: (string | null)[] | null | undefined;
    label?: { en: string; es?: string };
    closeHandler: () => void;
    submitHandler: (data: any) => void;
    deleteHandler: (divId: string, secId?: string) => void;
}> = props => {
    const {
        register,
        handleSubmit,

        formState: { errors },
    } = useForm<NameFormInput>({
        resolver: yupResolver(NameInputValidation as any),
    });
    const {
        divisionId,
        sectionId,
        title,
        typeName,
        expressionList,
        closeHandler,
        submitHandler,
        deleteHandler,
        label,
    } = props;
    const [selectedExpressions, setSelectedExpressions] = useState<(string | null)[]>(
        expressionList ?? [],
    );
    const onSubmit = (nameVariables: any) => {
        const data = { ...nameVariables, divisionId, sectionId, typeName, selectedExpressions };
        submitHandler(data);
    };
    const displayType = typeName === 'Division' ? 'Section' : 'Screen';

    const onDelete = () => {
        if (divisionId)
            TriggerGlobalConfirm({
                message: `Do you really want to delete this ${displayType}?`,
                callback: () => {
                    deleteHandler(divisionId, sectionId);
                },
            });
    };
    const expressionAdded = (id: string) => {
        setSelectedExpressions(selectedExpressions?.concat(id));
    };
    const expressionDropped = (id: string) => {
        setSelectedExpressions(selectedExpressions?.filter(exp => exp !== id));
    };
    return (
        <Dialog open>
            <form noValidate onSubmit={handleSubmit(onSubmit)} data-test={SurveyEnum.ADD_MODAL}>
                <DialogTitleWithClose id="modalTitle" onClose={closeHandler}>
                    {title} {displayType}
                </DialogTitleWithClose>
                <DialogContent>
                    <OutlinedSection title="Label">
                        <TextField
                            variant="outlined"
                            label="English"
                            type="text"
                            margin="dense"
                            defaultValue={label?.en}
                            fullWidth
                            {...register('label.en')}
                            error={!!errors.label?.en}
                            helperText={errors.label?.en?.message}
                            data-test={SurveyEnum.ADD_MODAL_LABEL}
                        />
                        <TextField
                            variant="outlined"
                            label="Spanish"
                            type="text"
                            margin="dense"
                            defaultValue={label?.es}
                            fullWidth
                            {...register('label.es')}
                            error={!!errors.label?.es}
                            helperText={errors.label?.es?.message}
                        />
                    </OutlinedSection>
                    <ConfigExpressionPicker
                        inputList={selectedExpressions ?? []}
                        itemAddedHandler={expressionAdded}
                        itemDroppedHandler={expressionDropped}
                        useType={ExpressionUseType.Surveys}
                        canChange
                    />
                </DialogContent>
                <DialogActions
                    style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: 'white',
                        zIndex: 1000,
                    }}
                >
                    <Button onClick={closeHandler} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        data-test={SurveyEnum.ADD_MODAL_SUBMIT}
                    >
                        {(typeName === 'Division' && divisionId) ||
                        (typeName === 'Section' && sectionId) ? (
                            <Typography> Update {displayType}</Typography>
                        ) : (
                            <Typography> Add {displayType}</Typography>
                        )}
                    </Button>
                    {((typeName === 'Division' && divisionId) ||
                        (typeName === 'Section' && sectionId)) && (
                        <Button color="primary" variant="contained" onClick={onDelete}>
                            Remove {displayType}
                        </Button>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
};
export {};
export default NameModal;
