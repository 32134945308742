import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    TablePagination,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import MaterialTable, { MaterialTableProps, MTableAction } from '@material-table/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { OrgTreeNode } from '~/hooks/useOrgTree';
import {
    FetchSubmittingClaimsQuery,
    OrderByDirectionEnum,
    useFetchSubmittingClaimsLazyQuery,
    ClaimSubmitted,
    ClaimSubmittedStatus,
} from '~/schemaTypes';
import tableIcons from '~/helpers/tableIcons';
import { PAGESIZE } from '~/constants';
import useSubmittedClaims from './useSubmittedClaims';
import SubmitClaimsFileModal from './modals/SubmitClaimsFile';
import ViewClaimModal from './modals/ViewClaim';
import ClaimStatus from './components/ClaimStatus';
import UploadClaim from './components/UploadClaim';
import { INCOMPLETE_FILTER } from './constants';
import allowUpload from './helpers/allowUpload';

type OrganizationClaimsProps = {
    orgNode: OrgTreeNode;
};

const POLL_INTERVAL = 30 * 1000;

const OrganizationClaims: React.FC<OrganizationClaimsProps> = ({ orgNode }) => {
    const { org } = orgNode;
    const tableRef = useRef<HTMLDivElement>(null);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [page, setPage] = useState<number>(0);
    const { hasClaimsFeature } = useSubmittedClaims({ orgId: org.id });
    const [incompleteFilter, setIncompleteFilter] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setPollIndex] = useState(0);
    const [
        submittedClaims,
        { refetch, data: submittedClaimsData, loading, stopPolling, startPolling },
    ] = useFetchSubmittingClaimsLazyQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted: () => setPollIndex(prev => prev++),
        notifyOnNetworkStatusChange: true,
    });
    const [currentClaim, setCurrentClaim] = useState<string | undefined>();
    const [showViewModal, setShowViewModal] = useState<boolean>(false);
    const [showCreateClaimsModal, setShowCreateClaimsModal] = useState<boolean>(false);
    const fetchClaimsPayload = useMemo(
        () => ({
            variables: {
                input: {
                    filter: {
                        fields: {
                            organizationId: org.id,
                        },
                        fieldsInList: {
                            ...(incompleteFilter && { status: INCOMPLETE_FILTER }),
                        },
                    },
                    orderBy: {
                        field: 'createdAt',
                        order: OrderByDirectionEnum.Desc,
                    },
                    pagination: {
                        skip: page * rowsPerPage,
                        limit: rowsPerPage,
                    },
                },
            },
        }),
        [org.id, page, rowsPerPage, incompleteFilter],
    );
    useEffect(() => {
        if (org.id) {
            submittedClaims(fetchClaimsPayload);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchClaimsPayload]);
    useEffect(() => {
        const inCompletedClaim = submittedClaimsData?.submittedClaimsV2.results.find(
            claim =>
                claim.status &&
                [ClaimSubmittedStatus.Ready, ClaimSubmittedStatus.InProgress].includes(
                    claim.status,
                ),
        );
        if (inCompletedClaim) {
            startPolling(POLL_INTERVAL);
        } else {
            stopPolling();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submittedClaimsData]);
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const claimViewHandler = useCallback((_: any, item: ClaimSubmitted) => {
        setCurrentClaim(item.ClaimRef);
        setShowViewModal(true);
    }, []);
    const addNewItem = useCallback(() => {
        setShowCreateClaimsModal(true);
    }, []);
    const addClaimsCallback = useCallback(() => {
        submittedClaims(fetchClaimsPayload);
        setPage(0);
        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
        setShowCreateClaimsModal(false);
    }, [submittedClaims, fetchClaimsPayload]);
    const uploadClaimCallback = useCallback(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!hasClaimsFeature) {
        return null;
    }
    return (
        <div style={{ marginRight: '1rem' }} ref={tableRef}>
            <Grid>
                <MaterialTable<FetchSubmittingClaimsQuery['submittedClaimsV2']['results'][0]>
                    icons={tableIcons as MaterialTableProps<any>['icons']}
                    columns={[
                        {
                            title: 'Claim ID',
                            field: 'ClaimRef',
                            render: ({ ClaimRef }) => (
                                <Typography data-test={ClaimRef}>{ClaimRef}</Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Date Submitted',
                            field: 'createdAt',
                            render: ({ createdAt }) => (
                                <Typography data-test={createdAt}>
                                    {moment(createdAt).local().format('MM-DD-YYYY hh:mm A')}
                                </Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Patient First Name',
                            field: 'PatFName',
                            render: ({ PatFName }) => (
                                <Typography data-test={PatFName}>{PatFName}</Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Patient Last Name',
                            field: 'PatLName',
                            render: ({ PatLName }) => (
                                <Typography data-test={PatLName}>{PatLName}</Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Visit Date',
                            field: 'ServiceFrom_1',
                            render: ({ ServiceFrom_1: visitDate }) => (
                                <Typography data-test={visitDate}>
                                    {moment(visitDate, 'MM/DD/YYYY').format('MM-DD-YYYY')}
                                </Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            render: claim => (
                                <ClaimStatus claim={claim} allowUpload={allowUpload(claim)} />
                            ),
                            sorting: false,
                        },
                        {
                            render: claim =>
                                allowUpload(claim) && (
                                    <UploadClaim claim={claim} callback={uploadClaimCallback} />
                                ),
                            sorting: false,
                        },
                    ]}
                    title="Claims Submitted"
                    data={
                        submittedClaimsData?.submittedClaimsV2.results.map((o: any) => ({
                            ...o,
                        })) || []
                    }
                    options={{
                        search: false,
                        paginationPosition: 'both',
                        pageSize: rowsPerPage,
                        pageSizeOptions: [25, 50, 100],
                    }}
                    isLoading={loading}
                    localization={{ header: { actions: '' } }}
                    actions={[
                        {
                            isFreeAction: true,
                            icon: () => (
                                <FormControlLabel
                                    label="Show Only Incomplete Claims"
                                    control={
                                        <Checkbox
                                            checked={incompleteFilter}
                                            onChange={event =>
                                                setIncompleteFilter(event.target.checked)
                                            }
                                        />
                                    }
                                />
                            ),
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onClick: () => {},
                        },
                        {
                            icon: 'Submit Claims to Waystar',
                            isFreeAction: true,
                            onClick: addNewItem,
                        },
                        {
                            onClick: claimViewHandler,
                            icon: () => <FontAwesomeIcon icon={faEye} />,
                            tooltip: 'View Claim',
                        },
                    ]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    components={{
                        Action: props => {
                            const {
                                action: { isFreeAction, icon, onClick },
                            } = props;
                            if (isFreeAction) {
                                if (typeof icon === 'string') {
                                    return (
                                        <Button variant="contained" onClick={onClick}>
                                            {icon}
                                        </Button>
                                    );
                                }
                                return icon();
                            }
                            return <MTableAction {...props} />;
                        },
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                count={submittedClaimsData?.submittedClaimsV2.total ?? 0}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        ),
                    }}
                />
            </Grid>
            {showCreateClaimsModal && (
                <Dialog
                    scroll="paper"
                    open={showCreateClaimsModal}
                    maxWidth="sm"
                    aria-labelledby="form-dialog-title"
                >
                    <SubmitClaimsFileModal onComplete={addClaimsCallback} orgId={org.id} />
                </Dialog>
            )}
            {showViewModal && (
                <Dialog open={showViewModal} scroll="paper">
                    <ViewClaimModal claimId={currentClaim} setOpen={setShowViewModal} />
                </Dialog>
            )}
        </div>
    );
};

export default OrganizationClaims;
export { useSubmittedClaims };
