import React from 'react';
import { Button, Grid } from '@mui/material';
import { FormMode } from './constants';

type AddManuallyButtonProps = {
    isEdit: boolean;
    setMode: (mode: FormMode) => void;
};

const AddManuallyButton: React.FC<AddManuallyButtonProps> = props => {
    const { isEdit, setMode } = props;
    return (
        <Grid item>
            <Button
                variant={isEdit ? 'text' : 'outlined'}
                size="small"
                onClick={() => setMode(FormMode.AddManually)}
            >
                Add Single Zip Code
            </Button>
        </Grid>
    );
};

export default AddManuallyButton;
