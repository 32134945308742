export enum VCUserType {
    Affiliate = 'Affiliate',
    Doula = 'Doula',
}

export interface AffiliateUserFormInput {
    name: string;
    email: string;
    affiliateId: string;
    userType: VCUserType;
    createVendor?: boolean;
    vendorId?: string | null;
    nameOnCheck?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    vendorName?: string;
}
