// This component has a UI Test
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { Add, Delete, FileCopy } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    CarePlansForAddPageDocument,
    CarePlansForAddPageQuery,
    CarePlansForCarePlansPageDocument,
    CarePlanType,
    useCarePlansForCarePlansPageQuery,
    useDeleteCarePlanEditCarePlanPageMutation,
} from '~/schemaTypes';
import { CarePlanEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';
import { AddCarePlanModal, CloneCarePlanModal } from '../components';
import { useStyles } from './styles';
import { CarePlan } from './types';

const CarePlanList: React.FC = () => {
    const history = useNavigate();
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const [isCloneDialogOpen, setCloneDialogOpen] = useState<boolean>(false);
    const [isAddDialogOpen, setAddDialogOpen] = useState<boolean>(false);
    const [selectedCarePlanId, setSelectedCarePlanId] = useState<string | null>(null);
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);

    const { data: carePlans, loading } = useCarePlansForCarePlansPageQuery();

    const [deleteCarePlan, { loading: deleteCarePlanLoading }] =
        useDeleteCarePlanEditCarePlanPageMutation({
            onCompleted: () => history('/app-config/care-plans'),
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: CarePlansForAddPageDocument,
                    variables: { input: { filter: { fields: { type: CarePlanType.Base } } } },
                },
                {
                    query: CarePlansForCarePlansPageDocument,
                },
            ],
            // updating CARE_PLANS_FOR_ADD_PAGE_QUERY cache for avoiding refetching the same query (carePlansV2) for AddCarePlanModal
            update: (cache, response) => {
                const deletedCarePlan = response.data?.deleteCarePlan;
                if (deletedCarePlan?.success && deletedCarePlan.id) {
                    const currentCarePlansData = cache.readQuery<CarePlansForAddPageQuery>({
                        query: CarePlansForAddPageDocument,
                        variables: {
                            input: { filter: { fields: { type: CarePlanType.Base } } },
                        },
                    });
                    const currentCarePlans = currentCarePlansData?.carePlansV2?.results;
                    if (currentCarePlans) {
                        const filteredCarePlans = currentCarePlans.filter(
                            carePlan => carePlan.id !== deletedCarePlan.id,
                        );
                        cache.writeQuery<CarePlansForAddPageQuery>({
                            query: CarePlansForAddPageDocument,
                            variables: {
                                input: { filter: { fields: { type: CarePlanType.Base } } },
                            },
                            data: {
                                carePlansV2: {
                                    __typename: currentCarePlansData.carePlansV2.__typename,
                                    results: filteredCarePlans,
                                },
                            },
                        });
                    }
                }
            },
        });
    const handleDelete = (carePlanId: string) =>
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete this Care Plan?`,
            callback: () => {
                deleteCarePlan({ variables: { input: { id: carePlanId } } });
            },
        });

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <>
            <div className={classes.root} data-test={CarePlanEnum.CONTAINER} ref={tableRef}>
                <MaterialTable<CarePlan>
                    title="Care Plans"
                    icons={tableIcons}
                    isLoading={loading || deleteCarePlanLoading}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    data={_.cloneDeep(carePlans?.carePlansV2.results) ?? []}
                    actions={[
                        {
                            onClick: () => setAddDialogOpen(true),
                            hidden: !pagePermissions?.CarePlans.Edit,
                            icon: () => <Add />,
                            tooltip: 'Add Care Plan',
                            isFreeAction: true,
                        },
                        {
                            onClick: (_, carePlan) =>
                                !Array.isArray(carePlan) &&
                                history(`/app-config/care-plans/${carePlan.id}`),
                            hidden: !pagePermissions?.CarePlans.Edit,
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit Care Plan',
                        },
                        {
                            onClick: (_, carePlan) =>
                                !Array.isArray(carePlan) && handleDelete(carePlan.id),
                            hidden: !pagePermissions?.CarePlans.Delete,
                            icon: () => <Delete />,
                            tooltip: 'Delete Care Plan',
                        },
                        {
                            onClick: (_, carePlan) => {
                                if (!Array.isArray(carePlan)) {
                                    const { id } = carePlan;
                                    setCloneDialogOpen(true);
                                    setSelectedCarePlanId(id);
                                }
                            },
                            hidden: !pagePermissions?.CarePlans.Edit,
                            icon: () => <FileCopy />,
                            tooltip: 'Clone Care Plan',
                        },
                    ]}
                    columns={[
                        {
                            title: 'Name',
                            field: 'portalLabel.en',
                            defaultSort: 'asc',
                            render: ({ portalLabel }) => (
                                <Typography data-test={portalLabel?.en}>
                                    {portalLabel?.en}
                                </Typography>
                            ),
                        },
                        { title: 'Timeline', field: 'timeline.label.en' },
                        {
                            title: 'Base Care Plan',
                            sorting: false,
                            render: ({ baseCarePlan }) => {
                                if (baseCarePlan) {
                                    return baseCarePlan?.portalLabel?.en;
                                }
                                return '';
                            },
                        },
                    ]}
                    options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100] }}
                    localization={{ header: { actions: '' } }}
                />
            </div>
            {selectedCarePlanId && (
                <CloneCarePlanModal
                    isOpen={isCloneDialogOpen}
                    carePlanId={selectedCarePlanId}
                    onClose={() => setCloneDialogOpen(false)}
                />
            )}
            <AddCarePlanModal isOpen={isAddDialogOpen} onClose={() => setAddDialogOpen(false)} />
        </>
    );
};

export default CarePlanList;
