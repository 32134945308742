import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    fileInfo: {
        width: '100%',
        justifyContent: 'space-between',
    },
    fileError: {
        margin: '0 14px',
    },
}));
