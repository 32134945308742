// import ObjectId from 'bson-objectid';
import { ClientSelectionType, OffsetType } from '~/enums/enums';
import { toMinutes } from '~/helpers/minuteConverters';
import {
    CarePlanFormInput,
    CarePlanInput,
    CarePlanTodoTemplateClockType,
    CarePlanType,
    FormTodoGroup,
    FormTodoTemplate,
} from '../types';
import { fromViewLimitToMinutes, toRepeatIntervalMinutes } from './minuteConverters';

const toIncludeOrExclude = ({
    clientSelectionType,
    includeApps,
    excludeApps,
}: CarePlanFormInput) => {
    switch (clientSelectionType) {
        case ClientSelectionType.IncludeCertainApps:
            return {
                includeApps: includeApps ?? [],
                excludeApps: [],
            };
        case ClientSelectionType.ExcludeCertainApps:
            return {
                includeApps: [],
                excludeApps: excludeApps ?? [],
            };
        case ClientSelectionType.IncludeAllApps:
            return {
                includeApps: [],
                excludeApps: [],
            };
        default:
            return {};
    }
};

const toOffsetValue = (offsetValue: number, offsetType: OffsetType) =>
    offsetType === OffsetType.After ? Number(offsetValue) : Number(offsetValue) * -1;

const toOffsetAndDuration = ({
    offsetDays,
    offsetMonths,
    offsetYears,
    offsetType,
    durationValue,
    durationUnit,
}: FormTodoTemplate) => ({
    offsetDays: offsetDays && offsetType ? toOffsetValue(offsetDays, offsetType) : 0,
    offsetMonths: offsetMonths && offsetType ? toOffsetValue(offsetMonths, offsetType) : 0,
    offsetYears: offsetYears && offsetType ? toOffsetValue(offsetYears, offsetType) : 0,
    durationMinutes: durationValue && durationUnit ? toMinutes(durationValue, durationUnit) : 0,
});

export const formValuesToUpdateInput = (
    form: CarePlanFormInput,
    groups: FormTodoGroup[],
    type: CarePlanType = CarePlanType.Base,
    overrideState: {
        appLabel: boolean;
        groupLabels: string[];
        tags: boolean;
    } = {
        appLabel: false,
        groupLabels: [],
        tags: false,
    },
): CarePlanInput => {
    const {
        portalLabel,
        appLabel,
        viewLimitUnit,
        viewLimitValue,
        timelineId,
        tags,
        baseCarePlanAppLabel,
        baseCarePlanTags,
    } = form;

    return {
        ...toIncludeOrExclude(form),
        portalLabel: { en: portalLabel },
        type,
        timelineId,
        tags: type === CarePlanType.Base || overrideState.tags ? tags : baseCarePlanTags,
        appLabel:
            type === CarePlanType.Base || overrideState.appLabel ? appLabel : baseCarePlanAppLabel,
        viewLimitMinutes: fromViewLimitToMinutes(viewLimitValue, viewLimitUnit),
        groups: groups.map(({ baseCarePlanLabel, ...group }) => ({
            ...group,
            // id: new ObjectId().toHexString(),
            id: group.id.toHexString() ?? '',
            label: {
                en:
                    type === CarePlanType.Base ||
                    overrideState.groupLabels.includes(group.id.toString())
                        ? group.label.en
                        : baseCarePlanLabel?.en ?? '',
                es:
                    type === CarePlanType.Base ||
                    overrideState.groupLabels.includes(group.id.toString())
                        ? group.label.es
                        : baseCarePlanLabel?.es,
            },
            templates: group.templates.map(template => ({
                // id: new ObjectId().toHexString() ?? '',
                id: template.id?.toHexString() ?? '',
                ...toOffsetAndDuration(template),
                title: { en: template.title.en, es: template.title.es },
                type: CarePlanTodoTemplateClockType.Timeline,
                isRepeating: template.isRecurring,
                repeatCount:
                    typeof template.repeatCount === 'string'
                        ? parseInt(template.repeatCount, 10)
                        : template.repeatCount,
                priority: template.priority,
                articleId: template.articleId,
                isEssential: template.isEssential,
                tags: template.tags,
                notificationData: {
                    shouldSendNotification: template.notificationData?.shouldSendNotification,
                    en: template.notificationData?.en,
                    es: template.notificationData?.es,
                },
                repeatIntervalMinutes:
                    template.repeatIntervalUnit &&
                    toRepeatIntervalMinutes(template.repeatIntervalUnit),
            })),
            suppressedTemplates: group.suppressedTemplates || [],
        })),
    };
};
