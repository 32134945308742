import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { Dispatch, SetStateAction } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { Indication } from '~/schemaTypes';
import { tableHeaderStyle } from '../PatientClinicalSummary';

type MedicationModalProps = {
    setShow: Dispatch<SetStateAction<boolean>>;
    indications: Indication[];
};

const IndicationsModal: React.FC<MedicationModalProps> = ({ setShow, indications }) => {
    return (
        <Grid>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setShow(false)}>
                Indications
            </DialogTitleWithClose>

            <DialogContent>
                <MaterialTable
                    columns={[
                        {
                            title: 'Name',
                            field: 'name',
                            align: 'center',
                            sorting: false,
                            cellStyle: {
                                width: '70%',
                            },
                            render: ({ name }) => name,
                        },
                        {
                            title: 'Code',
                            field: 'code',
                            align: 'center',
                            sorting: false,
                            render: ({ code }) => code,
                        },
                    ]}
                    data={_.cloneDeep(indications)}
                    options={{
                        thirdSortClick: false,
                        search: false,
                        paging: false,
                        showTitle: false,
                        toolbar: false,
                        headerStyle: tableHeaderStyle,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShow(false)} color="secondary" variant="outlined">
                    Close
                </Button>
            </DialogActions>
        </Grid>
    );
};

export default IndicationsModal;
