import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    overrideButton: {
        marginLeft: 10,
        marginBottom: 4,
    },
    overriddenContactWrapper: {
        marginLeft: 10,
        marginBottom: 14,
    },
    leftIndent: {
        marginLeft: 10,
    },
    numberField: {
        width: 80,
    },
});
