import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    titleDivider: {
        marginTop: '20px',
    },
    breadcrumbs: {
        textDecoration: 'none',
    },
    actionButton: {
        width: '100%',
        textAlign: 'center',
    },
});
