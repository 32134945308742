import { ArticlePromotionType } from '../../../../../schemaTypes';

const articlePromotionTypeMapperToViewItem: {
    [key in ArticlePromotionType]: string;
} = {
    [ArticlePromotionType.FixedDate]: 'Fixed Date',
    [ArticlePromotionType.Timeline]: 'Timeline',
};

export default articlePromotionTypeMapperToViewItem;
