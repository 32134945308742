import {
    ChatConversationsV2ForMessageCenterQuery,
    FetchChatRoomMessagesForMessageCenterQuery,
} from '~/schemaTypes';

export type ChatRoom = NonNullable<FetchChatRoomMessagesForMessageCenterQuery['chatRoom']>;
export type ChatConversation = NonNullable<
    ChatConversationsV2ForMessageCenterQuery['chatConversationsV2']['results'][0]
>;

export const isChatConversations = (conversations: any): conversations is ChatConversation[] => {
    // eslint-disable-next-line consistent-return
    conversations.forEach((conversation: any) => {
        if (!conversation.id || !conversation.chatRoomId) {
            return false;
        }
    });
    return true;
};
