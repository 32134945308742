import React, { useMemo } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Divider, Link, Typography } from '@mui/material';
import { useStyles } from '../styles';
import FileUpload from './FileUpload';

type PortalUserViewProps = {
    handleClearForm: () => void;
    isProcessing: boolean;
    register: UseFormRegisterReturn;
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const PortalUserView: React.FC<PortalUserViewProps> = props => {
    const { search } = useLocation();
    const searchParam = useMemo(() => new URLSearchParams(search), [search]);
    const aid = searchParam.get('aid');
    const { handleClearForm, isProcessing, register, handleFileChange } = props;
    const { classes } = useStyles();
    return (
        <>
            <Typography className={classes.headingTitle} variant="body2">
                Submit Claims for Reimbursement. Template for submission found{' '}
                <Link
                    href="https://www.wildflowerhealth.com/cms/resources/admin/affiliate-claim-submission-template.csv"
                    variant="body2"
                >
                    here
                </Link>
            </Typography>
            <Divider />
            <FileUpload
                handleClearForm={handleClearForm}
                isProcessing={isProcessing}
                register={register}
                handleFileChange={handleFileChange}
                affiliateId={aid}
                title="Upload Claim Template"
            />
        </>
    );
};

export default PortalUserView;
