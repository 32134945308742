import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    helperMessage: {
        margin: '-10px 0 0 24px',
    },
    actionButtons: {
        marginTop: 5,
        marginLeft: 5,
        marginRight: 5,
    },
    sectionLabel: {
        display: 'inline-block',
        paddingLeft: 10,
    },
    offsetWrap: {
        display: 'flex',
        alignContent: 'center',
    },
    divider: {
        marginBottom: 20,
    },
    offsetDate: {
        width: 90,
        marginRight: 10,
    },
    timeDropdown: {
        width: 150,
        marginRight: 10,
    },
    editorsWrap: {
        marginTop: 0,
        marginBottom: 8,
    },
});
