import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    root: {},
    saveBtn: {
        margin: '10px',
    },
}));

export default useStyles;
