import getOrgTree, { OrgTreeNode } from '~/hooks/useOrgTree';
import { OrgListQuery } from '~/schemaTypes';
import { useCallback, useReducer } from 'react';
import { useQueryParams } from '~/hooks';
import {
    Act,
    Action,
    ActionEnum,
    FlattedOrgNode,
    OrgId,
    State,
} from '~/views/ConfigDashboard/Organizations/hooks/useOrganizations.types';

const getFlattedOrgTreeInfo = (orgTree: OrgTreeNode): FlattedOrgNode[] => {
    if (orgTree) {
        const traverseOrgs = (node: OrgTreeNode) => {
            const {
                orgChildren,
                org: { id, brandingName },
            } = node;
            const info: FlattedOrgNode[] = [
                { id: id.toLowerCase(), name: brandingName.toLowerCase(), node },
            ];

            if (!orgChildren.length) {
                return info;
            }

            const child: any = orgChildren.map(oc => traverseOrgs(oc));

            return [...info, ...child.flat()];
        };
        return traverseOrgs(orgTree);
    }
    return [];
};

const getChainIds = (
    orgId: OrgId,
    flattedOrgTreeInfoMap: Record<OrgId, FlattedOrgNode>,
): OrgId[] => {
    const orgIds = [orgId];
    const getIds = (id: OrgId, ids: string[]): void => {
        const parentId = flattedOrgTreeInfoMap[id]?.node.org.parentId;
        if (parentId) {
            ids.push(parentId);
            getIds(parentId, ids);
        }
    };
    getIds(orgId, orgIds);
    return orgIds;
};

const getOrgState = (
    id: OrgId,
    flattedOrgTreeInfoMap: Record<OrgId, FlattedOrgNode>,
    prevOrgState: Record<OrgId, boolean>,
): Record<OrgId, boolean> => {
    const chainIdsMap = getChainIds(id, flattedOrgTreeInfoMap).reduce(
        (accum, id) => ({ ...accum, [id]: true }),
        {} as Record<OrgId, boolean>,
    );
    return {
        ...chainIdsMap,
        ...(prevOrgState[id] !== undefined ? { [id]: !prevOrgState[id] } : { [id]: true }),
    };
};

const getDefaultOrgState = (
    state: State,
    flattedOrgTreeInfoMap: Record<OrgId, FlattedOrgNode>,
    orgTree: OrgTreeNode,
): Record<OrgId, boolean> => {
    const hasOrgState = (value: Record<OrgId, boolean>) => Object.keys(value).length > 0;

    if (state.selectedOrgId && !hasOrgState(state.orgState)) {
        const orgStateBySelectedId = getOrgState(
            state.selectedOrgId,
            flattedOrgTreeInfoMap,
            state.orgState,
        );
        if (hasOrgState(orgStateBySelectedId)) {
            return orgStateBySelectedId;
        }
    }

    return {
        [orgTree.org.id]: true,
    };
};

const initialState: State = {
    orgTree: null,
    orgState: {},
    selectedOrgId: null,
    calculated: {
        flattedOrgTreeInfo: [],
        flattedOrgTreeInfoMap: {},
    },
};

function reducer(
    state: State,
    action:
        | Action<ActionEnum.LOADED_ORGS, OrgListQuery>
        | Action<ActionEnum.SET_SELECTED_ORG_ID, OrgId>,
): State {
    switch (action.type) {
        case ActionEnum.LOADED_ORGS: {
            const orgTree = getOrgTree({ orgs: action.payload.organizations });
            const flattedOrgTreeInfo = getFlattedOrgTreeInfo(orgTree);
            const flattedOrgTreeInfoMap = flattedOrgTreeInfo.reduce((accum, item) => {
                accum[item.id] = item;
                return accum;
            }, {} as Record<OrgId, FlattedOrgNode>);

            return {
                ...state,
                orgTree,
                orgState: getDefaultOrgState(state, flattedOrgTreeInfoMap, orgTree),
                calculated: {
                    ...state.calculated,
                    flattedOrgTreeInfo,
                    flattedOrgTreeInfoMap,
                },
            };
        }
        case ActionEnum.SET_SELECTED_ORG_ID: {
            return {
                ...state,
                selectedOrgId: action.payload,
                orgState: getOrgState(
                    action.payload,
                    state.calculated.flattedOrgTreeInfoMap,
                    state.orgState,
                ),
            };
        }
        default:
            return state;
    }
}

const useOrganizations = (): [State, Act] => {
    const [query] = useQueryParams();
    const [state, dispatch] = useReducer(
        reducer,
        initialState,
        (state: State): State => ({
            ...state,
            selectedOrgId: query.get('id') ?? state.selectedOrgId,
        }),
    );

    const loadOrgs = useCallback(
        (data: OrgListQuery) => {
            dispatch({ type: ActionEnum.LOADED_ORGS, payload: data });
        },
        [dispatch],
    );

    const selectOrgId = useCallback(
        (id: OrgId) => {
            dispatch({ type: ActionEnum.SET_SELECTED_ORG_ID, payload: id });
        },
        [dispatch],
    );

    return [state, { loadOrgs, selectOrgId }];
};

export default useOrganizations;
