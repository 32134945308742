import React, { useEffect, useState } from 'react';
import { Button, Grid, Link, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import PasswordInput from '~/components/PasswordInput/PasswordInput';
import { PersistAuthTokens, TriggerGlobalAlert, SuppressNextGlobalAlert } from '~/state';
import {
    AffiliateUserOnOnboardingInput,
    AlertSeverity,
    useCreateUserOnAffiliateOnboardingMutation,
} from '~/schemaTypes';
import { PASSWORD_REGEX } from '~/helpers/regularExpressions';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import { logout } from '~/state/auth/auth.svc';
import { log } from '~/utils/log.svc';
import { useStyles } from './styles';
import Header from '../components/Header';
import Footer from '../components/Footer';

const HelperPasswordText =
    'Password must contain at least 8 characters, one uppercase, one number and one special case character';

const PHONE_REGEX = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/; // 10 digital

const AFFILIATE_CREATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Full Name is required'),
    nameOfBusiness: Yup.string().required('Name of Business is required'),
    phoneNumber: Yup.string()
        .required('Phone number is required')
        .matches(PHONE_REGEX, 'Invalid phone number'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
        .required('Password is required')
        .matches(PASSWORD_REGEX, HelperPasswordText),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), undefined], `Passwords don't match`)
        .required('Password confirm is required'),
});

const duplicateEntityHandler = (message: string, data: AffiliateCreationFormInput) => {
    if (message.includes('E11000 duplicate key')) {
        TriggerGlobalAlert({
            severity: AlertSeverity.Error,
            message: `Affiliate with "${data.nameOfBusiness} (${data.name})" name already exists`,
        });
        SuppressNextGlobalAlert(true);
    }
};

type AffiliateCreationFormInput = {
    name: string;
    nameOfBusiness: string;
    phoneNumber: string;
    email: string;
    password: string;
    confirmPassword: string;
};

const AffiliateCreation = () => {
    const { classes } = useStyles();
    const history = useNavigate();
    const [loginLoading, setLoginLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<AffiliateCreationFormInput>({
        resolver: yupResolver(AFFILIATE_CREATION_SCHEMA as any),
    });

    const [createAffiliate, { loading: createLoading }] =
        useCreateUserOnAffiliateOnboardingMutation({
            onCompleted: async ({ createAffiliateUserOnOnboarding: response }) => {
                log.info({ response }, 'useCreateUserOnAffiliateOnboardingMutation:onCompleted');
                try {
                    setLoginLoading(true);
                    if (response?.success && response.user && response.tokens) {
                        PersistAuthTokens(response.tokens);
                        // await handleLoginUser();
                        history('/affiliate-care-portal/affiliate-onboarding/1');
                    } else if (response?.success === false) {
                        TriggerGlobalAlert({
                            severity: AlertSeverity.Error,
                            message: response?.message ?? 'An error occurred.',
                        });
                    }
                    setLoginLoading(false);
                } catch (err) {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Error,
                        message: 'Login Failed',
                    });
                    log.error({ err }, 'useCreateUserOnAffiliateOnboardingMutation:onCompleted');
                } finally {
                    setLoginLoading(false);
                }
            },
            onError: (err, data) => {
                log.error({ err, data }, 'useCreateUserOnAffiliateOnboardingMutation:onError');
                duplicateEntityHandler(err.message, data?.variables?.input);
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: err.message ?? 'An error occurred.',
                });
                setLoginLoading(false);
            },
        });

    const onSubmit = async ({
        email,
        name,
        nameOfBusiness,
        password,
        phoneNumber,
    }: AffiliateUserOnOnboardingInput) => {
        SuppressNextGlobalAlert(true);
        createAffiliate({
            variables: {
                input: {
                    name,
                    email,
                    nameOfBusiness,
                    password,
                    phoneNumber,
                },
            },
        });
    };

    useEffect(() => {
        logout(false);
    }, []);
    const loading = createLoading || loginLoading;

    return (
        <Grid container rowSpacing={5}>
            <Grid xs={12} item container justifyContent="center">
                <Header />
            </Grid>
            <Grid container item xs={12} justifyContent="center">
                <Grid item xs={12} md={6} container justifyContent="center">
                    <Link underline="always" href="/affiliate-care-portal">
                        I already have an account
                    </Link>
                    <form
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                        className={classes.formContainer}
                    >
                        <Grid item xs={12} container justifyContent="center">
                            <Grid item md={8} xs={12}>
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            error={!!errors.name}
                                            helperText={errors.name?.message}
                                            label="Full Name"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={8} xs={12}>
                                <Controller
                                    control={control}
                                    name="nameOfBusiness"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            error={!!errors.nameOfBusiness}
                                            helperText={errors.nameOfBusiness?.message}
                                            label="Name of Business"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={8} xs={12}>
                                <Controller
                                    control={control}
                                    name="phoneNumber"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            error={!!errors.phoneNumber}
                                            helperText={errors.phoneNumber?.message}
                                            label="Phone Number"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={8} xs={12}>
                                <Controller
                                    control={control}
                                    name="email"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            error={!!errors.email}
                                            helperText={errors.email?.message}
                                            label="Email"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={8} xs={12}>
                                <PasswordInput
                                    error={!!errors.password}
                                    register={register}
                                    label="Password"
                                    name="password"
                                    helperText={errors.password?.message || HelperPasswordText}
                                />
                            </Grid>
                            <Grid
                                item
                                md={8}
                                xs={12}
                                sx={{
                                    marginBottom: 6,
                                }}
                            >
                                <PasswordInput
                                    error={!!errors.confirmPassword}
                                    register={register}
                                    name="confirmPassword"
                                    label="Password (again)"
                                    helperText={errors.confirmPassword?.message}
                                />
                            </Grid>
                            <Grid
                                item
                                md={8}
                                xs={12}
                                paddingRight={1}
                                container
                                justifyContent="flex-end"
                                marginBottom={2}
                            >
                                <Grid item>
                                    <AsyncActionButton loading={loading}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            size="large"
                                            disabled={loading}
                                        >
                                            Create Your Affiliate Account
                                        </Button>
                                    </AsyncActionButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
            <Grid xs={12} item container justifyContent="center" className={classes.footer}>
                <Footer />
            </Grid>
        </Grid>
    );
};

export default AffiliateCreation;
