import React, { useMemo, useState, useCallback } from 'react';
import { Button, Grid, Typography, Dialog } from '@mui/material';
import MaterialTable from '@material-table/core';
import { DeleteOutline } from '@mui/icons-material';
import { TriggerGlobalConfirm } from '~/state';
import CoverageAreaForm from './components/CoverageAreaForm';
import { CoverageArea } from './types';

type CoverageAreasProps = {
    data: CoverageArea[];
    setData: (areas: CoverageArea[]) => void;
    deleteConfirm?: boolean;
};

type TableData = {
    index: number;
} & CoverageArea;

const CoverageAreas: React.FC<CoverageAreasProps> = props => {
    const { data, setData, deleteConfirm = false } = props;
    const [showForm, setShowForm] = useState(false);
    const [editIndex, setEditIndex] = useState<null | number>(null);
    const [editableItem, setEditableItem] = useState<null | CoverageArea>(null);
    const tableData: TableData[] = useMemo(
        () =>
            data.map((row, index) => ({
                ...row,
                index,
            })),
        [data],
    );
    const deleteHandler = useCallback(
        (deleteIndex: number) => {
            const newData = data.filter((_, index) => index !== deleteIndex);
            if (deleteConfirm) {
                TriggerGlobalConfirm({
                    open: true,
                    message: 'Are you sure you want to delete?',
                    callback: () => setData(newData),
                    description: '',
                });
            } else {
                setData(newData);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, deleteConfirm],
    );
    const onComplete = useCallback(
        (newArea: CoverageArea) => {
            if (editIndex !== null) {
                const newData = data.reduce((acc, item, index) => {
                    if (index === editIndex) {
                        acc.push(newArea);
                    } else {
                        acc.push(item);
                    }
                    return acc;
                }, [] as CoverageArea[]);
                setData(newData);
            } else {
                const newData = [...data, newArea];
                setData(newData);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, editIndex],
    );
    const addNewAreaHandler = useCallback(() => {
        setEditIndex(null);
        setEditableItem(null);
        setShowForm(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const editAreaHandler = useCallback(
        (index: number) => {
            const editableRow = data[index];
            if (editableRow) {
                setEditableItem(editableRow);
                setEditIndex(index);
                setShowForm(true);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data],
    );
    return (
        <>
            <Grid container item xs={12} rowSpacing={2}>
                <Grid item xs={12} container alignItems="center" columnSpacing={2}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={addNewAreaHandler}
                            disabled={showForm}
                        >
                            Add Coverage Area
                        </Button>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">
                            Areas of Coverage {!!data.length && `(${data.length})`}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        columns={[
                            {
                                title: 'Name',
                                field: 'name',
                                render: ({ name, index }) => (
                                    <Button variant="text" onClick={() => editAreaHandler(index)}>
                                        {name}
                                    </Button>
                                ),
                                sorting: false,
                            },
                            {
                                title: 'Zip Codes',
                                field: 'address',
                                render: ({ zipCodes }) => (
                                    <Typography>{zipCodes.join(', ')}</Typography>
                                ),
                                sorting: false,
                            },
                        ]}
                        data={tableData}
                        options={{
                            showTitle: false,
                            paging: false,
                            search: false,
                            toolbar: false,
                            actionsColumnIndex: -1,
                        }}
                        actions={[
                            {
                                icon: () => <DeleteOutline />,
                                tooltip: 'Delete',
                                onClick: (_, rowData: TableData) => deleteHandler(rowData.index),
                                isFreeAction: false,
                            },
                        ]}
                        localization={{ header: { actions: '' } }}
                    />
                </Grid>
            </Grid>
            <Dialog open={showForm} fullWidth maxWidth="lg">
                <CoverageAreaForm
                    close={() => setShowForm(false)}
                    onSubmit={onComplete}
                    data={editableItem}
                />
            </Dialog>
        </>
    );
};

export default CoverageAreas;

export type { CoverageArea };
