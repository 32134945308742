import { faArrowRightToBracket, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Add } from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import DateEditor from '~/components/DateEditor/DateEditor';
import { AutocompleteWithRecordOptions } from '../../../components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import ReactHookFormSelect from '../../../components/ReactHookFormSelect/ReactHookFormSelect';
import { PAGESIZE } from '../../../constants';
import { lowercaseFirstLetter } from '../../../helpers';
import { useUserPermissions } from '../../../hooks';
import {
    AdvocateTaskTypeStatus,
    AdvocateTasksForAdvocateTaskListPageQuery,
    FetchAdvocatesForAdvocateTaskListPageQueryQuery,
    FetchCurrentUserForAdvocateTaskListPageQuery,
    OrderByDirectionEnum,
    ReferencePages,
    Target,
    useAdvocateTasksForAdvocateTaskListPageLazyQuery,
    useFetchAdvocateTaskTypesQueryQuery,
    useFetchAdvocatesForAdvocateTaskListPageQueryQuery,
    useFetchCurrentUserForAdvocateTaskListPageQuery,
    useFetchReferenceLinksForAdvocateTasksListPageQuery,
    useUpdateSelectedAdvocateTasksMutation,
} from '../../../schemaTypes';
import { createHistoryItemEncoded } from '../../ConfigDashboard/AdvocateTasks/AdvocateTask/History/helpers/createHistoryItem';
import { Priorities } from '../../ConfigDashboard/AdvocateTasks/helpers';
import AdvocateTasksAssignModal from './AdvocateTaskAssignModal';
import AdvocateTasksStatusModal from './AdvocateTaskStatusModal';
import { AdvocateTaskTable } from './AdvocateTaskTable';
import { DueFilterConfig } from './shared/configs';

type AdvocateTaskListProps = { setLinks?: any };
type UsersByPermission = FetchAdvocatesForAdvocateTaskListPageQueryQuery['getUsersByPermission'];
type User = Exclude<Exclude<UsersByPermission, null | undefined>[number], null>;
type Patient = NonNullable<
    FetchCurrentUserForAdvocateTaskListPageQuery['currentUser']
>['patientsV2']['results'][number];
type AdvocateTask =
    AdvocateTasksForAdvocateTaskListPageQuery['customFilteredAdvocateTasks']['results'][number];
type AdvocateTaskListFilters = {
    assignedTo: string[];
    patientsIds: string[];
    title: string;
    status: AdvocateTaskTypeStatus;
    due: string;
    customDue?: string;
    importance: number[];
    typeId: string;
    filterTestData: boolean;
    selectedItems: string[];
};

// --- Constants Section ---
const SHOW_ALL = 'Show All';
const SORT_FIELD = 'firstName';
const ADDITINAL_SORT_FIELD = 'lastName';
const STORAGE_KEYS = {
    HA_FILTERS: 'haFilters',
};
export const UNASSIGNED = 'unassigned';
export const NOT_APPLICABLE = 'N/A';
const INITIAL_FILTERS: AdvocateTaskListFilters = {
    assignedTo: [],
    patientsIds: [],
    title: '',
    status: AdvocateTaskTypeStatus.Open,
    due: SHOW_ALL,
    customDue: new Date().toISOString(),
    importance: [],
    typeId: SHOW_ALL,
    filterTestData: false,
    selectedItems: [],
};

// --- Utility Functions Section ---
const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    }).format(date);
};

const isPastDate = (dueDate: Date): boolean => {
    return new Date() > dueDate;
};

const getFiltersFromLocalStorage = () => {
    try {
        const filtersData = localStorage.getItem(STORAGE_KEYS.HA_FILTERS);
        return filtersData ? JSON.parse(filtersData) : null;
    } catch {
        return null;
    }
};

const getInitialFilters = (currentUserId: string): AdvocateTaskListFilters => {
    const storedFilters = getFiltersFromLocalStorage();
    return {
        ...INITIAL_FILTERS,
        ...storedFilters,
        assignedTo: storedFilters?.assignedTo?.length ? storedFilters?.assignedTo : [currentUserId],
        due: storedFilters?.due || SHOW_ALL,
        typeId: storedFilters?.typeId || SHOW_ALL,
        patientsIds: [],
    };
};

const getSortedAdvocateList = (usersByPermission: UsersByPermission) => {
    return (
        usersByPermission
            ?.filter((user): user is { name: string; id: string } => user?.name && user?.id)
            .sort((a, b) => a.name.localeCompare(b.name)) ?? []
    );
};

const getUserList = (sortedUsers: User[], assignedTo: string[]): User[] => {
    if (assignedTo?.length > 0) {
        if (assignedTo.includes(NOT_APPLICABLE)) {
            return [{ id: NOT_APPLICABLE, name: 'All Tasks' }];
        }
        return [
            { id: UNASSIGNED, name: 'Unassigned' },
            ...sortedUsers.map(user => ({ id: user.id, name: user.name })),
        ];
    }
    return [
        { id: NOT_APPLICABLE, name: 'All Tasks' },
        { id: UNASSIGNED, name: 'Unassigned' },
        ...sortedUsers.map(user => ({ id: user.id, name: user.name })),
    ];
};

// --- Main Component ---
const AdvocateTaskList: React.FC<AdvocateTaskListProps> = ({ setLinks }) => {
    // --- State Hooks ---
    const [patients, setPatients] = useState<Patient[]>([]);
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [patientSearchText, setPatientSearchText] = useState<string>('');
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(PAGESIZE);
    const [advocateTasks, setAdvocateTasks] = useState<AdvocateTask[]>([]);
    const [advocateTasksCount, setAdvocateTasksCount] = useState(0);
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [loadingInitialData, setLoadingInitialData] = useState(true);
    const [loadingTableData, setLoadingTableData] = useState(false);
    const [sortField, setSortField] = useState<keyof AdvocateTask>('dueDate');
    const [sortDirection, setSortDirection] = useState<OrderByDirectionEnum>(
        OrderByDirectionEnum.Desc,
    );

    // --- Form Hooks ---
    const { control, getValues, setValue, reset, handleSubmit, watch } =
        useForm<AdvocateTaskListFilters>({
            defaultValues: INITIAL_FILTERS,
        });
    const assignedTo = watch('assignedTo');
    const due = watch('due');
    const selectedItems = watch('selectedItems');

    // --- Query Hooks ---
    const {
        data: currentUserData,
        loading: loadingCurrentUser,
        fetchMore: fetchMoreCurrentUserPatients,
        refetch: refetchCurrentUserPatients,
    } = useFetchCurrentUserForAdvocateTaskListPageQuery({
        variables: {
            input: {
                pagination: { limit: 20, skip: 0 },
                orderBy: {
                    field: SORT_FIELD,
                    additionalField: ADDITINAL_SORT_FIELD,
                    order: OrderByDirectionEnum.Asc,
                },
            },
        },
        onCompleted: data => {
            const patients = data.currentUser?.patientsV2?.results ?? [];
            if (patients.length > 0) {
                setPatients(patients);
            }
        },
    });

    const { data: usersByPermissionData, loading: loadingHealthAdvocateList } =
        useFetchAdvocatesForAdvocateTaskListPageQueryQuery({
            variables: { permission: lowercaseFirstLetter(Target.IsHealthAdvocate) },
        });

    const [fetchAdvocateTasks, { data: advocateTasksData, loading: loadingAdvocateTasks }] =
        useAdvocateTasksForAdvocateTaskListPageLazyQuery({
            fetchPolicy: 'network-only',
        });

    const { data: refereneLinksData } = useFetchReferenceLinksForAdvocateTasksListPageQuery({
        variables: {
            input: {
                page: ReferencePages.HaTaskList,
            },
        },
    });

    const [updateTasks, { loading: loadingUpdateTasks }] = useUpdateSelectedAdvocateTasksMutation();

    const { data: advocateTaskTypes } = useFetchAdvocateTaskTypesQueryQuery();
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();

    // --- Memoized Values ---
    const sortedAdvocateList = useMemo(
        () => getSortedAdvocateList(usersByPermissionData?.getUsersByPermission),
        [usersByPermissionData?.getUsersByPermission],
    );

    const userList = useMemo(() => {
        if (loadingCurrentUser || loadingHealthAdvocateList) return [];
        return getUserList(sortedAdvocateList, assignedTo);
    }, [sortedAdvocateList, assignedTo, loadingCurrentUser, loadingHealthAdvocateList]);

    const fetchTasks = useCallback(
        (filters: AdvocateTaskListFilters, page = 0, pageSize: number = PAGESIZE) => {
            const assignedToInput =
                !filters.assignedTo || filters.assignedTo[0] === NOT_APPLICABLE
                    ? undefined
                    : filters.assignedTo.map(id => (id === UNASSIGNED ? null : id));
            setLoadingTableData(true);
            if (assignedToInput?.length === 0) {
                setValue('assignedTo', [NOT_APPLICABLE]);
            }
            fetchAdvocateTasks({
                variables: {
                    input: {
                        customFilters: {
                            assignedTo: assignedToInput,
                            filterTestData: filters.filterTestData,
                            importance: filters.importance,
                            patients: filters.patientsIds,
                            ...(filters.title && { label: filters.title }),
                            ...(filters.due !== SHOW_ALL && { due: filters.due }),
                            ...(filters.due === 'custom' && { customDue: filters.customDue }),
                        },
                        filter: {
                            fields: {
                                status: filters.status,
                                ...(filters.typeId !== SHOW_ALL && { typeId: filters.typeId }),
                            },
                        },
                        pagination: {
                            skip: page * pageSize,
                            limit: pageSize,
                        },
                        orderBy: {
                            field: sortField,
                            order: sortDirection,
                        },
                    },
                },
            }).then(() => {
                setPage(page);
                setPageSize(pageSize);
            });
        },
        [fetchAdvocateTasks, sortField, sortDirection, setValue],
    );

    const selectAllTasks = useCallback(() => {
        const { selectedItems } = getValues();
        const allTaskIds = advocateTasks.map(task => task.id);
        setValue('selectedItems', [...selectedItems, ...allTaskIds]);
    }, [advocateTasks, setValue, getValues]);

    const clearSelectedTasks = useCallback(() => {
        const { selectedItems } = getValues();
        const allTaskIds = advocateTasks.map(task => task.id);
        setValue(
            'selectedItems',
            selectedItems.filter(id => !allTaskIds.includes(id)),
        );
    }, [advocateTasks, setValue, getValues]);

    const checkboxColumn = useMemo(() => {
        return {
            title: (
                <FormControlLabel
                    label=""
                    labelPlacement="start"
                    control={
                        <div>
                            <Checkbox
                                name="select-all-checkbox"
                                checked={
                                    selectedItems.length > 0 &&
                                    advocateTasks.every(task => selectedItems.includes(task.id))
                                }
                                onChange={e => {
                                    if (e.target.checked) {
                                        selectAllTasks();
                                    } else {
                                        clearSelectedTasks();
                                    }
                                }}
                            />
                            <Select
                                name="select-all-select"
                                variant="standard"
                                disableUnderline
                                style={{ width: 30 }}
                                value=""
                                onChange={e => {
                                    if (e.target.value === '0') {
                                        selectAllTasks();
                                    } else {
                                        clearSelectedTasks();
                                    }
                                }}
                            >
                                <MenuItem key="0" value="0">
                                    All
                                </MenuItem>
                                <MenuItem key="1" value="1">
                                    None
                                </MenuItem>
                            </Select>
                        </div>
                    }
                />
            ),
            width: 150,
            render: ({ id }: AdvocateTask) => {
                return (
                    <Controller
                        name="selectedItems"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={`task-checkbox-${id}`}
                                        checked={value.includes(id)}
                                        onChange={e => {
                                            const newValue = e.target.checked
                                                ? [...value, id]
                                                : value.filter(val => val !== id);
                                            onChange(newValue);
                                        }}
                                    />
                                }
                                label=""
                                labelPlacement="start"
                            />
                        )}
                    />
                );
            },
            sorting: false,
        };
    }, [selectedItems, advocateTasks, control, selectAllTasks, clearSelectedTasks]);

    const mainColumns = useMemo(() => {
        const { status } = getValues();
        return [
            {
                title: 'Title',
                field: 'label',
                render: ({ id, label }: AdvocateTask) => (
                    <Link
                        to={`/portal/advocate-tasks/${id}`}
                        component={RouterLink}
                        underline="hover"
                    >
                        {label}
                    </Link>
                ),
                sorting: false,
            },
            {
                title: 'Patient',
                field: 'patient',
                render: ({ patient }: AdvocateTask) => (
                    <Link
                        to={`/portal/patients/${patient?.id}/overview`}
                        component={RouterLink}
                        underline="hover"
                    >
                        {patient?.fullName}
                    </Link>
                ),
                sorting: false,
            },
            {
                title: 'Complete By',
                field: 'dueDate',
                render: ({ dueDate }: AdvocateTask) => {
                    const dueDateObj = new Date(dueDate);
                    return (
                        <>
                            {formatDate(dueDate)}
                            {isPastDate(dueDateObj) && (
                                <span style={{ color: 'red', fontWeight: 'bold' }}> !</span>
                            )}
                        </>
                    );
                },
                hidden: status === AdvocateTaskTypeStatus.Closed,
                headerStyle: sortField === 'dueDate' ? { fontWeight: 'bold' } : {},
            },
            {
                title: 'Closed On',
                field: 'completedAt',
                render: ({ completedAt }: AdvocateTask) => formatDate(completedAt),
                hidden: status === AdvocateTaskTypeStatus.Open,
                headerStyle: sortField === 'completedAt' ? { fontWeight: 'bold' } : {},
            },
            {
                title: 'Created On',
                field: 'createdAt',
                render: ({ createdAt }: AdvocateTask) => formatDate(createdAt),
                headerStyle: sortField === 'createdAt' ? { fontWeight: 'bold' } : {},
            },
            {
                title: 'Priority',
                field: 'priority',
                width: 60,
                headerStyle: sortField === 'priority' ? { fontWeight: 'bold' } : {},
            },
            {
                title: 'Assigned To',
                field: 'assignedToUser.name',
                render: ({ assignedToUser }: AdvocateTask) => assignedToUser?.name ?? 'Unassigned',
                sorting: false,
            },
            {
                title: 'App',
                field: 'patient.practice.brandingName',
                sorting: false,
            },
        ];
    }, [getValues, sortField]);

    const columns = useMemo(() => [checkboxColumn, ...mainColumns], [checkboxColumn, mainColumns]);

    // --- Callback Handlers ---
    const handlePatientSearch = useCallback(
        (searchText: string) => {
            setPatientSearchText(searchText);
            setPatients([]);
            setIsFetchingMore(true);
            const searchWords = searchText.split(/ (.+)/).filter(Boolean);

            refetchCurrentUserPatients({
                input: {
                    pagination: { limit: 20, skip: 0 },
                    ...(searchText && {
                        filterOr: {
                            firstName: searchWords[0],
                            lastName: searchWords[1] ?? searchWords[0],
                        },
                    }),
                    orderBy: {
                        field: SORT_FIELD,
                        additionalField: ADDITINAL_SORT_FIELD,
                        order: OrderByDirectionEnum.Asc,
                    },
                },
            })
                .then(({ data }) => {
                    const searchResult = data?.currentUser?.patientsV2?.results ?? [];
                    if (searchResult.length > 0) {
                        setPatients([...searchResult]);
                    }
                })
                .finally(() => setIsFetchingMore(false));
        },
        [refetchCurrentUserPatients],
    );

    const handleLoadMorePatients = () => {
        if (isFetchingMore) return;
        setIsFetchingMore(true);
        const searchWords = patientSearchText.split(' ').filter(Boolean);
        fetchMoreCurrentUserPatients({
            variables: {
                input: {
                    pagination: { limit: 20, skip: patients.length },
                    ...(patientSearchText && {
                        filterOr: {
                            firstName: searchWords[0],
                            lastName: searchWords[1] ?? searchWords[0],
                        },
                    }),
                    orderBy: {
                        field: SORT_FIELD,
                        additionalField: ADDITINAL_SORT_FIELD,
                        order: OrderByDirectionEnum.Asc,
                    },
                },
            },
        })
            .then(fetchMoreResult => {
                const newPatients = fetchMoreResult?.data?.currentUser?.patientsV2?.results ?? [];
                setPatients(prevPatients => [...prevPatients, ...newPatients]);
            })
            .finally(() => setIsFetchingMore(false));
    };

    const handlePageChange = (newPage: number, newPageSize: number) => {
        const filters = getValues();
        fetchTasks(filters, newPage, newPageSize);
    };

    const handleOrderChange = (
        orderBy: keyof AdvocateTask,
        orderDirection: OrderByDirectionEnum,
    ) => {
        setSortField(orderBy);
        setSortDirection(orderDirection);
        fetchTasks(getValues(), page, pageSize);
    };

    const handleRessignSelected = (id: string) => {
        const filters = getValues();
        updateTasks({
            variables: {
                input: {
                    ids: selectedItems,
                    data: {
                        assignedTo: id === UNASSIGNED ? null : id,
                        history: [
                            createHistoryItemEncoded(
                                currentUserData?.currentUser?.id ?? '',
                                `changed the Assignee from '${
                                    currentUserData?.currentUser?.name
                                }' to '${
                                    usersByPermissionData?.getUsersByPermission?.find(
                                        user => user?.id === id,
                                    )?.name
                                }'`,
                            ),
                        ],
                    },
                },
            },
        }).then(() => {
            fetchTasks(filters, page, pageSize);
            setValue('selectedItems', []);
            setIsAssignModalOpen(false);
        });
    };

    const handleUpdateStatus = (newStatus: AdvocateTaskTypeStatus) => {
        const filters = getValues();
        if (newStatus === filters.status) {
            setIsStatusModalOpen(false);
            return;
        }
        updateTasks({
            variables: {
                input: {
                    ids: selectedItems,
                    data: {
                        status: newStatus,
                        completedAt:
                            filters.status === AdvocateTaskTypeStatus.Open ? new Date() : null,
                        history: [
                            createHistoryItemEncoded(
                                currentUserData?.currentUser?.id ?? '',
                                `changed Status from '${filters.status}' to '${newStatus}'`,
                            ),
                        ],
                    },
                },
            },
        }).then(() => {
            fetchTasks(filters, page, pageSize);
            setValue('selectedItems', []);
            setIsStatusModalOpen(false);
        });
    };

    const onSubmit = (data: AdvocateTaskListFilters) => {
        fetchTasks(data, 0, pageSize);
        localStorage.setItem(STORAGE_KEYS.HA_FILTERS, JSON.stringify({ ...data }));
    };

    // --- Effect Hooks ---
    useEffect(() => {
        if (currentUserData?.currentUser?.id) {
            const initialFilters = getInitialFilters(currentUserData?.currentUser?.id);
            reset(initialFilters);
            fetchTasks(initialFilters);
            setLoadingInitialData(false);
        }
    }, [currentUserData?.currentUser?.id, reset, fetchTasks]);

    useEffect(() => {
        if (!loadingAdvocateTasks) {
            setAdvocateTasks(advocateTasksData?.customFilteredAdvocateTasks?.results ?? []);
            setAdvocateTasksCount(advocateTasksData?.customFilteredAdvocateTasks?.total ?? 0);
            setLoadingTableData(false);
        }
    }, [advocateTasksData?.customFilteredAdvocateTasks, loadingAdvocateTasks]);

    useEffect(() => {
        if (refereneLinksData && setLinks) {
            setLinks(refereneLinksData.getReferenceLinksForPage);
        }
    }, [refereneLinksData, setLinks]);

    useEffect(() => {
        if (currentUserData?.currentUser?.currentOrg?.id) {
            setValue('patientsIds', []);
        }
    }, [currentUserData?.currentUser?.currentOrg?.id, setValue]);

    // --- Render Section ---
    return (
        <>
            <Grid container item spacing={2} wrap="nowrap">
                <Grid item>
                    <Box sx={{ width: 250 }}>
                        <FormControl variant="outlined" fullWidth style={{ padding: 0 }}>
                            <AutocompleteWithRecordOptions<User, 'id', 'name'>
                                options={userList}
                                control={control}
                                valueKey="id"
                                labelKey="name"
                                name="assignedTo"
                                label="Assigned To"
                                loadingText="Loading..."
                                loading={Boolean(loadingHealthAdvocateList)}
                                disabled={loadingInitialData}
                            />
                        </FormControl>
                        <FormControl variant="outlined" fullWidth style={{ padding: 0 }}>
                            <AutocompleteWithRecordOptions
                                options={patients}
                                valueKey="id"
                                labelKey="fullName"
                                name="patientsIds"
                                label="Patients"
                                placeholder="Select patients..."
                                loadingText="Loading..."
                                loading={Boolean(loadingCurrentUser || isFetchingMore)}
                                control={control}
                                onLoadMore={handleLoadMorePatients}
                                onSearch={handlePatientSearch}
                                disabled={loadingInitialData}
                                isFilteringDisabled
                                hasMoreOptions={
                                    currentUserData?.currentUser?.patientsV2.hasMore ?? false
                                }
                            />
                        </FormControl>
                        <Controller
                            name="title"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    label="Search by title"
                                    type="text"
                                    fullWidth
                                    id="title"
                                    disabled={loadingInitialData}
                                />
                            )}
                        />
                        <FormControl variant="outlined" fullWidth>
                            <ReactHookFormSelect
                                style={{ padding: 0 }}
                                label="Status"
                                control={control}
                                name="status"
                                variant="outlined"
                                disabled={loadingInitialData}
                            >
                                <MenuItem value={AdvocateTaskTypeStatus.Open}>Open</MenuItem>
                                <MenuItem value={AdvocateTaskTypeStatus.Closed}>Closed</MenuItem>
                            </ReactHookFormSelect>
                        </FormControl>
                        <FormControl variant="outlined" fullWidth>
                            <ReactHookFormSelect
                                style={{ padding: 0 }}
                                label="Due"
                                control={control}
                                name="due"
                                variant="outlined"
                                disabled={loadingInitialData}
                            >
                                <MenuItem key="All" value={SHOW_ALL}>
                                    {SHOW_ALL}
                                </MenuItem>
                                {DueFilterConfig.map(({ label, value }) => (
                                    <MenuItem value={value} key={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                        </FormControl>
                        {due === 'custom' && (
                            <Controller
                                name="customDue"
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <FormControl
                                            style={{ padding: 0 }}
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <DateEditor
                                                title="Custom Date"
                                                setSelectedDate={d => onChange(d)}
                                                initialDate={value || new Date().toISOString()}
                                                separateLines
                                            />
                                        </FormControl>
                                    );
                                }}
                            />
                        )}
                        <FormControl variant="outlined" fullWidth>
                            <Typography gutterBottom>Filter Importance</Typography>
                            {Priorities.map(priority => (
                                <Controller
                                    key={priority}
                                    name="importance"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    value={priority}
                                                    disabled={loadingInitialData}
                                                    id={`priority-${priority}`}
                                                    checked={field.value.includes(priority)}
                                                    onChange={e => {
                                                        const newValue = e.target.checked
                                                            ? [...field.value, priority]
                                                            : field.value.filter(
                                                                  val => val !== priority,
                                                              );
                                                        field.onChange(newValue);
                                                    }}
                                                />
                                            }
                                            label={`Priority ${priority}`}
                                        />
                                    )}
                                />
                            ))}
                        </FormControl>
                        <FormControl variant="outlined" fullWidth>
                            <ReactHookFormSelect
                                style={{ padding: 0 }}
                                label="Type"
                                control={control}
                                name="typeId"
                                variant="outlined"
                                disabled={loadingInitialData}
                            >
                                <MenuItem key="All" value={SHOW_ALL}>
                                    {SHOW_ALL}
                                </MenuItem>
                                {advocateTaskTypes?.advocateTaskTypesV2.results.map(
                                    ({ label, id }) => (
                                        <MenuItem value={id} key={id}>
                                            {label}
                                        </MenuItem>
                                    ),
                                )}
                            </ReactHookFormSelect>
                        </FormControl>
                        <FormControl variant="outlined" fullWidth>
                            <Controller
                                name="filterTestData"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...field}
                                                id="filterTestData"
                                                checked={field.value}
                                                onChange={e => field.onChange(e.target.checked)}
                                                disabled={loadingInitialData}
                                            />
                                        }
                                        label="Show Test Data"
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl variant="outlined" fullWidth>
                            <Button
                                disabled={loadingInitialData || loadingAdvocateTasks}
                                variant="contained"
                                onClick={handleSubmit(onSubmit)}
                            >
                                Apply
                            </Button>
                        </FormControl>
                        <FormControl variant="outlined" fullWidth>
                            <Button
                                variant="outlined"
                                disabled={loadingInitialData}
                                onClick={() => {
                                    localStorage.removeItem(STORAGE_KEYS.HA_FILTERS);
                                    reset({
                                        ...INITIAL_FILTERS,
                                        assignedTo: [NOT_APPLICABLE],
                                    });
                                }}
                            >
                                Clear Filters
                            </Button>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item style={{ flex: 1 }}>
                    <AdvocateTaskTable
                        data={advocateTasks}
                        page={page}
                        pageSize={pageSize}
                        columns={columns}
                        totalCount={advocateTasksCount}
                        onPageChange={handlePageChange}
                        isLoading={loadingTableData}
                        onOrderChange={handleOrderChange}
                        actions={[
                            {
                                onClick: () => setIsAssignModalOpen(true),
                                hidden:
                                    !pagePermissions?.AdvocateTaskList.Edit ||
                                    !selectedItems.length,
                                icon: () => (
                                    <Button
                                        size="medium"
                                        title="Assign"
                                        variant="text"
                                        startIcon={<FontAwesomeIcon icon={faArrowRightToBracket} />}
                                        color="inherit"
                                    >
                                        Assign
                                    </Button>
                                ),
                                tooltip: 'Assign',
                                isFreeAction: true,
                            },
                            {
                                onClick: () => setIsStatusModalOpen(true),
                                hidden:
                                    !pagePermissions?.AdvocateTaskList.Edit ||
                                    !selectedItems.length,
                                icon: () => (
                                    <Button
                                        size="medium"
                                        title="Update Status"
                                        variant="text"
                                        startIcon={<FontAwesomeIcon icon={faPenToSquare} />}
                                        color="inherit"
                                    >
                                        Update Status
                                    </Button>
                                ),
                                tooltip: 'Update Status',
                                isFreeAction: true,
                            },
                            {
                                onClick: () => history('/portal/advocate-tasks/new'),
                                hidden: !pagePermissions?.AdvocateTaskList.Edit,
                                icon: () => <Add />,
                                tooltip: 'Add Advocate Task',
                                isFreeAction: true,
                            },
                        ]}
                    />
                </Grid>
            </Grid>
            <Dialog
                open={isAssignModalOpen}
                fullWidth
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
            >
                <AdvocateTasksAssignModal
                    setOpen={setIsAssignModalOpen}
                    advocates={usersByPermissionData}
                    reassignHandler={handleRessignSelected}
                    isLoading={loadingUpdateTasks}
                />
            </Dialog>
            <Dialog
                open={isStatusModalOpen}
                fullWidth
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
            >
                <AdvocateTasksStatusModal
                    setOpen={setIsStatusModalOpen}
                    reassignHandler={handleUpdateStatus}
                    isLoading={loadingUpdateTasks}
                />
            </Dialog>
        </>
    );
};

export default AdvocateTaskList;
