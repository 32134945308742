// This component has a UI Test
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add, FileCopy, InfoOutlined } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGESIZE } from '~/constants';
import { displayDate } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    AlertSeverity,
    BioScreensForProfileScreensPageDocument,
    BioScreensForProfileScreensPageQuery,
    useBioScreensForProfileScreensPageQuery,
    useDeleteBioScreenForBioScreenBuilderMutation,
} from '~/schemaTypes';
import { ProfileScreenEnum } from '~/selectors';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';

import BioScreenCloneModal from './BioScreenCloneModal';

const PAGE_NAME = 'Profile Screens';

const useStyles = makeStyles()({
    root: {
        '& .MuiButton-textPrimary:hover': {
            background: 'none',
        },
    },
});

const BioScreen: React.FC = () => {
    const [showBioScreenCloneModal, setShowBioScreenCloneModal] = useState(false);
    const { classes } = useStyles();
    const [selectedScreenId, setSelectedScreenId] = useState<string>('');
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);

    const { data: bioScreensData, loading: bioScreensLoading } =
        useBioScreensForProfileScreensPageQuery();

    const bioScreens = _.cloneDeep(bioScreensData?.bioScreensV2?.results) || [];

    const [deleteBioScreen, { loading: deleteBioScreenLoading }] =
        useDeleteBioScreenForBioScreenBuilderMutation({
            onCompleted: () => {
                history('/app-config/bio-screens');
            },
            update: (cache, response) => {
                const id = response.data?.deleteBioScreen?.id;
                if (response.data?.deleteBioScreen?.success && id) {
                    const currentBioScreens = cache.readQuery<BioScreensForProfileScreensPageQuery>(
                        {
                            query: BioScreensForProfileScreensPageDocument,
                        },
                    );
                    if (currentBioScreens?.bioScreensV2) {
                        cache.writeQuery<BioScreensForProfileScreensPageQuery>({
                            query: BioScreensForProfileScreensPageDocument,
                            data: {
                                bioScreensV2: {
                                    __typename: currentBioScreens.bioScreensV2.__typename,
                                    results: currentBioScreens.bioScreensV2.results.filter(
                                        item => item.id !== id,
                                    ),
                                },
                            },
                        });
                    }
                }
            },
        });
    const handleDelete = (bioScreenId: string) =>
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete this Profile Screen?`,
            callback: () => {
                deleteBioScreen({
                    variables: {
                        deleteBioScreenInput: {
                            id: bioScreenId,
                        },
                    },
                });
            },
        });
    const handleClone = (bioScreenId: string) => {
        setSelectedScreenId(bioScreenId);
        setShowBioScreenCloneModal(true);
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <>
            <div
                className={classes.root}
                data-test={ProfileScreenEnum.PROFILE_SCREENS_CONTAINER}
                ref={tableRef}
            >
                <MaterialTable
                    title={PAGE_NAME}
                    icons={tableIcons}
                    isLoading={bioScreensLoading || deleteBioScreenLoading}
                    data={bioScreens}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    actions={[
                        {
                            onClick: () => history('/app-config/bio-screens/new'),
                            hidden: !pagePermissions?.ProfileScreens.Edit,
                            icon: () => <Add />,
                            tooltip: 'Add Profile Screen',
                            isFreeAction: true,
                        },
                        {
                            onClick: (_, { id }: any) => history(`/app-config/bio-screens/${id}`),
                            hidden: !pagePermissions?.ProfileScreens.Edit,
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit Profile Screen',
                        },
                        {
                            onClick: (_, { id }: any) => handleDelete(id),
                            hidden: !pagePermissions?.ProfileScreens.Delete,
                            icon: () => <FontAwesomeIcon icon={faTrash} />,
                            tooltip: 'Delete Profile Screen',
                        },
                        {
                            onClick: (_, { id }: any) => handleClone(id),
                            hidden: !pagePermissions?.ProfileScreens.Edit,
                            icon: () => <FileCopy />,
                            tooltip: 'Clone Profile Screen',
                        },
                    ]}
                    columns={[
                        {
                            title: 'ID',
                            field: 'id',
                            render: ({ id }) => (
                                <Tooltip title={id}>
                                    <IconButton
                                        onClick={() => {
                                            copy(`${id}`);
                                            TriggerGlobalAlert({
                                                message: 'Profile Screen Id copied to clipboard',
                                                severity: AlertSeverity.Success,
                                            });
                                        }}
                                        size="large"
                                    >
                                        <InfoOutlined />
                                    </IconButton>
                                </Tooltip>
                            ),
                        },
                        { title: 'Name', field: 'label.en', defaultSort: 'asc' },
                        {
                            title: 'Last Updated',
                            field: 'updatedAt',
                            render: ({ updatedAt }) => (
                                <>
                                    {displayDate({
                                        isoDateStr: updatedAt,
                                    })}
                                </>
                            ),
                        },
                    ]}
                    options={{ pageSize: rowsPerPage }}
                    localization={{ header: { actions: '' } }}
                />
            </div>
            <Dialog
                scroll="paper"
                open={showBioScreenCloneModal}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <BioScreenCloneModal
                    bioScreenId={selectedScreenId}
                    bioScreens={bioScreens}
                    setOpen={setShowBioScreenCloneModal}
                />
            </Dialog>
        </>
    );
};

export default BioScreen;
