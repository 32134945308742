import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    progressContainer: {
        margin: '0 100px',
    },
    stepContainer: {
        padding: '0 150px',
        '@media (max-width: 800px)': {
            padding: '0 30px',
        },
    },
    navigationContainer: {
        margin: '10px 100px',
        button: {
            minWidth: 100,
        },
        '@media (max-width: 800px)': {
            margin: '0 30px 15px',
        },
    },
    footer: {
        paddingTop: '80px !important',
    },
}));
