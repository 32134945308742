import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Loading from '~/components/Loading/Loading';
import { ExpressionUseType, useFetchConfigExpressionListQuery } from '~/schemaTypes';
import ConfigExpressionSelect from './ConfigExpressionSelect';

const ConfigExpressionPicker: React.FC<{
    inputList: (string | null)[];
    itemAddedHandler: (id: string) => void;
    itemDroppedHandler: (id: string) => void;
    useType: ExpressionUseType;
    canChange: boolean;
}> = props => {
    const { inputList, itemAddedHandler, itemDroppedHandler, useType, canChange } = props;
    const [displayList, setDisplayList] = useState<{ id: string; name: string }[]>([]);
    const [selectedList, setSelectedList] = useState(inputList);
    const [selectedItem, setSelectedItem] = useState<{ id: string; name: string }>({
        id: '0',
        name: '',
    });
    const { data: expressionData, loading: expressionsLoading } =
        useFetchConfigExpressionListQuery();

    useEffect(() => {
        if (expressionData && expressionData.configExpressions) {
            const newList: { id: string; name: string }[] = [];
            expressionData.configExpressions.forEach(e => {
                if (inputList.find(i => i === e.id) !== undefined) {
                    newList.push({ id: e.id, name: e.name });
                }
            });
            setDisplayList(newList);
        }
    }, [expressionData, inputList]);
    const selectChanged = (id: string, name: string) => {
        setSelectedItem({
            id,
            name,
        });
    };
    const itemAdded = () => {
        if (selectedItem) {
            setSelectedList(selectedList.concat(selectedItem.id));
            setDisplayList(displayList.concat(selectedItem));
            itemAddedHandler(selectedItem.id);
        }
        setSelectedItem({ id: '0', name: '' });
    };
    const itemDropped = (id: string) => {
        setSelectedList(selectedList.filter(i => i !== id));
        setDisplayList(displayList.filter(i => i.id !== id));
        itemDroppedHandler(id);
    };
    if (expressionsLoading) return <Loading height={100} subtitle="Loading Expressions" />;

    return (
        <>
            <div>
                <ConfigExpressionSelect
                    inputId={selectedItem.id}
                    itemSelected={selectChanged}
                    useType={useType}
                    canChange={canChange}
                />
                {selectedItem && selectedItem.id !== '0' && (
                    <Button onClick={itemAdded}>Add Selected Expression</Button>
                )}
            </div>
            <div>
                {displayList.map(i => {
                    return (
                        <div key={i.id}>
                            {i.name}
                            <Button onClick={() => itemDropped(i.id)}>Delete</Button>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default ConfigExpressionPicker;
