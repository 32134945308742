import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Divider, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowBack, FileCopy, Save } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import RichTextEditor, { RichTextEditorCommands } from '~/components/RichTextEditor/RichTextEditor';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    AlertSeverity,
    AllMessageCenterTemplatesDocument,
    MessageCenterTemplateDocument,
    useCreateMessageCenterTemplateMutation,
    useMessageCenterTemplateLazyQuery,
    usePreFetchAppsQuery,
    useUpdateMessageCenterTemplateMutation,
} from '~/schemaTypes';
import { MessageCenterTemplatesEnum } from '~/selectors';
import { TriggerGlobalAlert } from '~/state';
import { ClientSelectionType, ClientSelectionTypes } from '~/enums/enums';
import _ from 'lodash';
import { MessageCenterTemplates } from './types/Schema';
import { templateTokens } from './helpers/templateTokens';
import { defaultFormValues, toFormValuesFromMessageTemplate } from './helpers/toFormValues';
import { formValuesToCreateOrUpdateInput } from './helpers/toRequestInput';
import { MessageCenterTemplateFormInput } from './types/MessageCenterTemplateFormInput';
import { MESSAGE_CENTER_TEMPLATE_SCHEMA } from './yupSchema';

const MESSAGE_CENTER_TEMPLATES_PATHNAME = '/app-config/message-center-templates';

export const useStyles = makeStyles()({
    root: {},
    buttonWrap: {
        paddingLeft: '10px',
        paddingBottom: '10px',
    },
    checkboxWrap: {
        paddingLeft: '10px',
    },
    actionButtons: {
        marginTop: '5px',
        marginLeft: '5px',
        marginRight: '5px',
    },
});

const MessageTemplate: React.FC = () => {
    const { classes } = useStyles();
    const history = useNavigate();
    const [close, setClose] = useState(false);
    const [textEn, setTextEn] = useState<string>('');
    const [textEs, setTextEs] = useState<string>('');
    const [textEnEditorCommands, setTextEnEditorCommands] = useState<RichTextEditorCommands | null>(
        null,
    );
    const [textEsEditorCommands, setTextEsEditorCommands] = useState<RichTextEditorCommands | null>(
        null,
    );
    const params = useParams<{ id: string }>();
    const isEditMode = 'id' in params;
    const messageTemplateId = isEditMode ? params.id : '';

    const {
        control,
        register,
        handleSubmit: handleFormSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm<MessageCenterTemplateFormInput>({
        resolver: yupResolver(MESSAGE_CENTER_TEMPLATE_SCHEMA),
        defaultValues: defaultFormValues(),
    });

    const clientSelectionType = watch('clientSelectionType');

    const [getMessageTemplate, { loading: messageTemplateLoading }] =
        useMessageCenterTemplateLazyQuery({
            onCompleted: data => {
                if (data.messageCenterTemplate) {
                    const { content } = data.messageCenterTemplate;

                    reset(
                        toFormValuesFromMessageTemplate(
                            data.messageCenterTemplate as MessageCenterTemplates,
                        ),
                    );

                    const textEn = content?.en || '';
                    const textEs = content?.es || '';

                    setTextEn(textEn);
                    setTextEs(textEs);

                    textEnEditorCommands?.commands.setContent(textEn);
                    textEsEditorCommands?.commands.setContent(textEs);
                }
            },
        });

    const { data: preFetchData, loading: preFetchLoading } = usePreFetchAppsQuery();

    const [createMessageTemplate, { loading: createMessageTemplateLoading }] =
        useCreateMessageCenterTemplateMutation({
            awaitRefetchQueries: true,
            onCompleted: data => {
                if (close) history(MESSAGE_CENTER_TEMPLATES_PATHNAME);
                else
                    history(
                        `${MESSAGE_CENTER_TEMPLATES_PATHNAME}/edit/${data?.createMessageCenterTemplate?.resourceCreated?.id}`,
                    );
            },
            refetchQueries: [{ query: AllMessageCenterTemplatesDocument }],
        });

    const [updateMessageTemplate, { loading: updateMessageTemplateLoading }] =
        useUpdateMessageCenterTemplateMutation({
            awaitRefetchQueries: true,
            onCompleted: () => {
                if (close) history(MESSAGE_CENTER_TEMPLATES_PATHNAME);
            },
            refetchQueries: [
                {
                    query: MessageCenterTemplateDocument,
                    variables: { input: { id: isEditMode ? messageTemplateId : '' } },
                },
            ],
        });

    useEffect(() => {
        if (isEditMode) {
            getMessageTemplate({ variables: { input: { id: messageTemplateId } } });
        } else {
            reset(defaultFormValues());
            textEnEditorCommands?.commands.clearContent();
            textEsEditorCommands?.commands.clearContent();
        }
    }, [
        messageTemplateId,
        isEditMode,
        getMessageTemplate,
        textEnEditorCommands?.commands,
        textEsEditorCommands?.commands,
        reset,
    ]);

    const apps = preFetchData?.applicationsV2.results ?? [];

    const handleSubmit: SubmitHandler<MessageCenterTemplateFormInput> = form => {
        if (errors.content || errors.title) {
            return;
        }

        if (isEditMode && messageTemplateId) {
            updateMessageTemplate({
                variables: {
                    input: { id: messageTemplateId, data: formValuesToCreateOrUpdateInput(form) },
                },
            });
        } else {
            createMessageTemplate({
                variables: {
                    input: formValuesToCreateOrUpdateInput(form),
                },
            });
        }
    };

    const renderForm = () => {
        return (
            <form className={classes.root} onSubmit={handleFormSubmit(handleSubmit)}>
                <Grid container item xs={12}>
                    <Grid container item xs={12}>
                        <TextField
                            variant="outlined"
                            type="text"
                            label="Title"
                            {...register('title', { required: true })}
                            required
                            error={Boolean(errors.title)}
                            helperText={errors.title?.message}
                            fullWidth
                        />
                    </Grid>
                    <OutlinedSection title="Apps">
                        <ReactHookFormSelect
                            control={control}
                            name="clientSelectionType"
                            variant="outlined"
                            defaultValue=""
                            label="Selection type *"
                            fullWidth
                            margin="dense"
                        >
                            {ClientSelectionTypes.map(m => (
                                <MenuItem key={m} value={m}>
                                    {m}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                        {(clientSelectionType as unknown) ===
                            ClientSelectionType.IncludeCertainApps && (
                            <AutocompleteWithRecordOptions
                                options={_.sortBy(apps, 'appBundleId') ?? []}
                                valueKey="appBundleId"
                                labelKey="appBundleId"
                                control={control}
                                name="includeApps"
                                label="Include apps *"
                                placeholder="Select apps to include..."
                            />
                        )}
                        {(clientSelectionType as unknown) ===
                            ClientSelectionType.ExcludeCertainApps && (
                            <AutocompleteWithRecordOptions
                                options={_.sortBy(apps, 'appBundleId') ?? []}
                                valueKey="appBundleId"
                                labelKey="appBundleId"
                                control={control}
                                name="excludeApps"
                                label="Exclude apps *"
                                placeholder="Select apps to exclude..."
                            />
                        )}
                    </OutlinedSection>
                    <Grid container item xs={12}>
                        <OutlinedSection title="Message Content">
                            <Grid item xs={12} style={{ marginTop: '8px', padding: '10px' }}>
                                <Controller
                                    name="content.en"
                                    control={control}
                                    render={({ field: { onChange } }) => {
                                        return (
                                            <RichTextEditor
                                                onInit={setTextEnEditorCommands}
                                                onChange={value => {
                                                    onChange(value);
                                                    setTextEn(value);
                                                }}
                                                placeholder="English"
                                                label="English"
                                                initialValue={textEn}
                                                allowImages={false}
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '8px', padding: '10px' }}>
                                <Controller
                                    name="content.es"
                                    control={control}
                                    render={({ field: { onChange } }) => (
                                        <RichTextEditor
                                            onInit={setTextEsEditorCommands}
                                            onChange={value => {
                                                onChange(value);
                                                setTextEs(value);
                                            }}
                                            placeholder="Spanish"
                                            label="Spanish"
                                            initialValue={textEs}
                                            allowImages={false}
                                        />
                                    )}
                                />
                            </Grid>
                        </OutlinedSection>
                    </Grid>
                    <Grid container item xs={12}>
                        <OutlinedSection title="Click To Copy Tokens">
                            <Grid item xs={12}>
                                {templateTokens.map(token => {
                                    return (
                                        <Button
                                            key={token.testId}
                                            variant="contained"
                                            onClick={() => {
                                                copy(token.token);
                                                TriggerGlobalAlert({
                                                    severity: AlertSeverity.Success,
                                                    message: `${token.text} copied`,
                                                });
                                            }}
                                            className={classes.actionButtons}
                                            data-test={token.testId}
                                        >
                                            <FileCopy />
                                            {token.text}
                                        </Button>
                                    );
                                })}
                            </Grid>
                        </OutlinedSection>
                    </Grid>
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        <Button
                            onClick={() => {
                                handleFormSubmit(handleSubmit);
                            }}
                            type="submit"
                            startIcon={<Save />}
                            color="secondary"
                            variant="contained"
                            className={classes.actionButtons}
                        >
                            Save
                        </Button>
                        <Button
                            onClick={() => {
                                setClose(true);
                                handleFormSubmit(handleSubmit);
                            }}
                            type="submit"
                            startIcon={<Save />}
                            color="secondary"
                            variant="contained"
                            className={classes.actionButtons}
                            data-test={MessageCenterTemplatesEnum.SAVE_AND_CLOSE}
                        >
                            Save &amp; Close
                        </Button>
                    </div>
                </Grid>
            </form>
        );
    };

    if (
        createMessageTemplateLoading ||
        updateMessageTemplateLoading ||
        messageTemplateLoading ||
        preFetchLoading
    ) {
        return <Loading />;
    }

    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Button
                    component={Link}
                    to={MESSAGE_CENTER_TEMPLATES_PATHNAME}
                    startIcon={<ArrowBack />}
                >
                    Back to Message Center Templates
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <Typography
                        variant="h6"
                        paragraph
                        data-test={MessageCenterTemplatesEnum.ADD_MESSAGE_CENTER_TEMPLATE}
                    >
                        {isEditMode ? 'Edit' : 'Add'} Message Center Template
                    </Typography>
                    <Divider style={{ marginBottom: 20 }} />
                    {renderForm()}
                </Card>
            </Grid>
        </Grid>
    );
};

export default MessageTemplate;
