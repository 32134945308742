import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { useFetchPatientForAdvocateTaskPageLazyQuery } from '~/schemaTypes';
import usePatientsSearch from './usePatientsSearch';
import { PatientPickerProps } from './PatientPicker.types';

const defaultPageNumber = 0;
const defaultQuery = '';

const PatientPicker = ({
    name,
    control,
    patientId,
    onChange,
    watch,
    setValue,
    orgId,
}: PatientPickerProps) => {
    const [query, setQuery] = useState(defaultQuery);
    const [pageNumber, setPageNumber] = useState(defaultPageNumber);
    const observer = useRef<IntersectionObserver>();
    const { patients, hasMore, loading, reset, fetchPatients } = usePatientsSearch(
        query,
        pageNumber,
    );
    const [getPatient] = useFetchPatientForAdvocateTaskPageLazyQuery();

    const resetState = useCallback(() => {
        setQuery(defaultQuery);
        setPageNumber(defaultPageNumber);
        reset();
    }, [reset]);

    // resets the state if the parent component has cleared the form value
    const formValue = watch(name);
    useEffect(() => {
        if (formValue === '') {
            resetState();
        }
    }, [formValue, resetState]);

    // reset state if organization was changed
    useEffect(() => {
        resetState();
    }, [orgId, resetState]);

    // set selected patient if patientId prop has passed to the component
    useEffect(() => {
        if (!patientId) return;
        (async () => {
            const { data } = await getPatient({ variables: { input: { id: patientId } } });
            const fullName = data?.patient?.fullName;

            if (fullName) {
                const { data: patientData } = await fetchPatients(fullName, defaultPageNumber);
                if (patientData?.currentUser?.patientsV2.results.length) {
                    setValue(name, patientId);
                    setQuery(fullName);
                    setPageNumber(defaultPageNumber);
                } else {
                    resetState();
                }
            }
        })();
    }, [patientId, fetchPatients, getPatient, name, resetState, setValue]);

    const lastPatientElementRef = useCallback(
        (node: HTMLLIElement | null) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && hasMore) {
                    setPageNumber(prevPageNumber => prevPageNumber + 1);
                }
            });

            if (node) {
                observer.current?.observe(node);
            }
        },
        [loading, hasMore],
    );

    const lastPatient = patients?.slice(-1)[0];

    return (
        <Controller
            control={control}
            name={name}
            defaultValue=""
            render={({ field: { onChange: _onChange } }) => (
                <Autocomplete
                    inputValue={query}
                    size="small"
                    onChange={(_, patient) => {
                        if (patient) {
                            _onChange(patient.id);
                            onChange({ patient });
                            setQuery(patient.fullName);
                        }
                    }}
                    noOptionsText={loading ? 'Loading ...' : undefined}
                    getOptionLabel={selected => selected.fullName}
                    options={
                        loading ? [...patients, { id: '', fullName: 'Loading ...' }] : patients
                    }
                    isOptionEqualToValue={(option, val) => option.id === val.id}
                    renderOption={(props, option) =>
                        option?.id === lastPatient?.id ? (
                            <li {...props} key={option.id} ref={lastPatientElementRef}>
                                {option.fullName}
                            </li>
                        ) : (
                            <li {...props} key={option.id}>
                                {option.fullName}
                            </li>
                        )
                    }
                    renderInput={params => (
                        <TextField
                            onChange={e => {
                                setQuery(e.target.value);
                                setPageNumber(0);
                            }}
                            variant="outlined"
                            margin="dense"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            placeholder="Select Patient"
                            label="Patient"
                            InputLabelProps={{ shrink: true }}
                        />
                    )}
                />
            )}
        />
    );
};

export default PatientPicker;
