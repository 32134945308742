import React, { useState, useEffect, useMemo, useRef } from 'react';
import debounce from 'lodash/debounce';
import { format, toDate } from 'date-fns-tz';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useClaimsBuilderAffiliatePatientsLazyQuery, OrderByDirectionEnum } from '~/schemaTypes';
import { SelectedPatient } from './types';
import NoOptionText from './NoOptionText';

const MIN_SEARCH_INPUT_LENGTH = 3;
const SEARCH_DEBOUNCE_INTERVAL = 500;

type PatientSearchProps = {
    setSelectedPatient: (patient: SelectedPatient) => void;
    affiliateId: string;
    portalView?: boolean;
    VCView?: boolean;
};

type Option = { title: string; value: string };
type PatientData = { id: string; birthDate: string; fullName: string };

const mapPatientsToOptions = (patients?: PatientData[]) => {
    if (!patients) {
        return [];
    }
    return patients.map(p => ({
        title: `${p.fullName} -- ${format(toDate(p.birthDate.split('T')[0]), 'MM/dd/yyyy')}`,
        value: p.id,
    }));
};

const PatientsSearch: React.FC<PatientSearchProps> = props => {
    const { setSelectedPatient, affiliateId, portalView = false, VCView = false } = props;
    const [inputValue, setInputValue] = useState<string>('');
    const [search, setSearch] = useState<string>('');
    const setSearchDebounced = useRef(
        debounce(newSearch => setSearch(newSearch), SEARCH_DEBOUNCE_INTERVAL),
    ).current;
    const onInputChange = (_e: any, newInputValue: string) => {
        setInputValue(newInputValue);
        setSearchDebounced(newInputValue);
    };
    const [prefilledOptions, setPrefilledOptions] = useState<Option[]>([]);
    const [fetchPatients, { data, loading }] = useClaimsBuilderAffiliatePatientsLazyQuery({
        fetchPolicy: 'network-only',
    });
    useEffect(() => {
        if (search.length >= MIN_SEARCH_INPUT_LENGTH) {
            fetchPatients({
                variables: {
                    input: {
                        affiliateId,
                        onlyName: true,
                        search: search.trim(),
                        orderBy: {
                            field: 'createdAt',
                            order: OrderByDirectionEnum.Desc,
                        },
                        pagination: {
                            skip: 0,
                            limit: 5,
                        },
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, affiliateId]);
    const options = useMemo(
        () => mapPatientsToOptions(data?.virtualCareAffiliatePatients?.results),
        [data?.virtualCareAffiliatePatients?.results],
    );
    useEffect(() => {
        return () => {
            setSearchDebounced.cancel();
        };
    }, [setSearchDebounced]);

    useEffect(() => {
        fetchPatients({
            variables: {
                input: {
                    affiliateId,
                    onlyName: true,
                    orderBy: {
                        field: 'firstName',
                        order: OrderByDirectionEnum.Asc,
                    },
                    pagination: {
                        skip: 0,
                        limit: 5,
                    },
                },
            },
        }).then(({ data }) => {
            setPrefilledOptions(mapPatientsToOptions(data?.virtualCareAffiliatePatients?.results));
        });
    }, [fetchPatients, affiliateId]);
    const noOptionText = useMemo(
        () => (
            <NoOptionText
                fullName={search}
                portalView={portalView}
                affiliateId={affiliateId}
                VCView={VCView}
            />
        ),
        [search, portalView, affiliateId, VCView],
    );

    return (
        <Autocomplete
            options={search.length < MIN_SEARCH_INPUT_LENGTH ? prefilledOptions : options}
            loading={loading}
            inputValue={inputValue}
            onInputChange={onInputChange}
            getOptionLabel={option => option.title}
            getOptionKey={option => option.value}
            value={null}
            blurOnSelect
            noOptionsText={noOptionText}
            filterOptions={options => options}
            onChange={(event, value) => {
                if (value?.value) {
                    const patient = data?.virtualCareAffiliatePatients?.results.find(
                        p => p.id === value.value,
                    );
                    if (patient) {
                        setSelectedPatient({
                            id: patient.id,
                            firstName: patient.firstName,
                            lastName: patient.lastName,
                            birthDate: patient.birthDate,
                            email: patient.email,
                            fullName: patient.fullName,
                            multiples: patient.VCF?.babyMultiples,
                            insurancePlans: patient.insurancePlans ?? [],
                            insuranceId: patient.insuranceId ?? '',
                        });
                        onInputChange(null, '');
                        setSearch('');
                    }
                }
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Search and Add Patient Visit"
                    InputLabelProps={{
                        ...params.InputLabelProps,
                        shrink: true,
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="end">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            fullWidth
        />
    );
};

export default PatientsSearch;
