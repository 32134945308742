import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import ObjectID from 'bson-objectid';
import omitDeep from 'omit-deep-lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    CarePlansForCarePlansPageDocument,
    CarePlansForCarePlansPageQuery,
    useCarePlanForCarePlanPageLazyQuery,
    useCarePlansForCarePlansPageQuery,
    useCreateCarePlanForNewCarePlanPageMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { errorRed } from '~/theme/WildTheme';
import { CarePlan, CarePlanInput } from '../CarePlan/types';

interface ICloneCarePlanFormInput {
    label: string;
}
const CloneCarePlanFormInputValidation = Yup.object().shape({
    label: Yup.string().required('Required'),
});

export const CloneCarePlanModal: React.FC<{
    isOpen: boolean;
    carePlanId: string;
    onClose: () => void;
}> = props => {
    const { isOpen, carePlanId, onClose } = props;
    const [selectedCarePlan, setSelectedCarePlan] = useState<CarePlan | null>(null);
    const [labelUsed, setLabelUsed] = useState(false);

    const { data: carePlanList, loading: carePlanListLoading } =
        useCarePlansForCarePlansPageQuery();

    const [getCarePlan, { data: carePlanData, loading: carePlanLoading }] =
        useCarePlanForCarePlanPageLazyQuery();

    useEffect(() => {
        getCarePlan({ variables: { carePlanInput: { id: carePlanId } } });
    }, [getCarePlan, carePlanId]);

    useEffect(() => {
        if (carePlanData && carePlanData.carePlan) {
            setSelectedCarePlan({ ...carePlanData.carePlan });
        }
    }, [carePlanData]);

    const {
        register,
        handleSubmit,

        formState: { errors },
    } = useForm<ICloneCarePlanFormInput>({
        resolver: yupResolver(CloneCarePlanFormInputValidation as any),
    });

    const handleClose = () => {
        onClose();
    };

    const [createCarePlan, { loading: createCarePlanLoading }] =
        useCreateCarePlanForNewCarePlanPageMutation({
            onCompleted: () => {
                handleClose();
            },
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        handleClose();
                    },
                    message: `There was a problem saving the care plan: ${error.message}`,
                });
            },
            update: (cache, response) => {
                const newCarePlan = response.data?.createCarePlan?.resourceCreated;
                if (response.data?.createCarePlan?.success && newCarePlan) {
                    const currentCarePlans = cache.readQuery<CarePlansForCarePlansPageQuery>({
                        query: CarePlansForCarePlansPageDocument,
                    });
                    if (currentCarePlans?.carePlansV2?.results) {
                        cache.writeQuery<CarePlansForCarePlansPageQuery>({
                            query: CarePlansForCarePlansPageDocument,
                            data: {
                                carePlansV2: {
                                    __typename: currentCarePlans.carePlansV2.__typename,
                                    results: [...currentCarePlans.carePlansV2.results, newCarePlan],
                                },
                            },
                        });
                    }
                }
            },
        });
    const onSubmit = (values: ICloneCarePlanFormInput) => {
        setLabelUsed(false);
        if (
            carePlanList &&
            carePlanList.carePlansV2.results.filter(
                t => t?.portalLabel?.en.toLowerCase() === values.label.toLowerCase(),
            ).length > 0
        ) {
            setLabelUsed(true);
        } else {
            if (selectedCarePlan === null) {
                return;
            }

            const resultingCarePlan: CarePlanInput = {
                type: selectedCarePlan.type,
                ...(selectedCarePlan.baseCarePlanId && {
                    baseCarePlanId: selectedCarePlan.baseCarePlanId,
                }),
                timelineId: selectedCarePlan.timelineId,
                ...(selectedCarePlan.includeApps && {
                    includeApps: selectedCarePlan.includeApps,
                }),
                ...(selectedCarePlan.excludeApps && {
                    excludeApps: selectedCarePlan.excludeApps,
                }),
                tags: selectedCarePlan.tags,
                portalLabel: {
                    en: values.label,
                },
                appLabel: selectedCarePlan.appLabel,
                viewLimitMinutes: selectedCarePlan.viewLimitMinutes,
                groups: selectedCarePlan.groups,
            };

            const cleared = omitDeep(resultingCarePlan, '__typename') as CarePlanInput;

            // TODO: Care Plan Updates
            for (const group of cleared.groups) {
                group.id = new ObjectID().toHexString();
                for (const tmpl of group.templates) {
                    tmpl.id = new ObjectID().toHexString();
                }
            }

            createCarePlan({
                variables: {
                    input: {
                        ...cleared,
                        portalLabel: {
                            en: values.label,
                        },
                    },
                },
            });
        }
    };
    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose id="modalTitle" onClose={handleClose}>
                Clone {carePlanData?.carePlan?.portalLabel?.en}
            </DialogTitleWithClose>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    {carePlanListLoading || carePlanLoading || createCarePlanLoading ? (
                        <Loading height={140} />
                    ) : (
                        <OutlinedSection title="Care Plan Name">
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                defaultValue={carePlanData?.carePlan?.portalLabel?.en}
                                {...register('label')}
                                error={!!errors.label}
                                helperText={errors.label?.message}
                            />
                            {labelUsed && (
                                <div style={{ color: errorRed }}>
                                    Name already used. Please change.
                                </div>
                            )}
                        </OutlinedSection>
                    )}
                    {/* show input?select for name */}
                    {/* input should be unique */}
                </DialogContent>
                <DialogActions>
                    {/* show buttons cancel and clone */}
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={() => handleSubmit(onSubmit)}
                    >
                        Clone
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
