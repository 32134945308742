import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import {
    ReportingCategoryListDocument,
    ReportingCategoryListQuery,
    ReportingCategoryUpdateInput,
    useCreateReportingCategoryMutation,
    useReportingCategoryListQuery,
    useUpdateReportingCategoryMutation,
} from '~/schemaTypes';
import { ConditionTypeEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';
import { errorRed } from '~/theme/WildTheme';

type ReportingCategory = NonNullable<ReportingCategoryListQuery['reportingCategorys'][0]>;

interface FormInput {
    name: string;
}
const inputValidation = Yup.object().shape({
    name: Yup.string().required('You need to enter a name'),
});

export const ReportingCategoryModal: React.FC<{
    isOpen: boolean;
    item: ReportingCategory | null;
    onClose: () => void;
}> = props => {
    const { isOpen, item, onClose } = props;
    const [nameUsed, setNameUsed] = useState(false);
    // list of conditionTypes to compare with name field of new one
    const { data: reportingCategoriesList, loading: listLoading } = useReportingCategoryListQuery();

    const {
        register,
        handleSubmit,

        formState: { errors },
    } = useForm<FormInput>({
        resolver: yupResolver(inputValidation as any),
    });

    const handleClose = () => {
        setNameUsed(false);
        onClose();
    };

    const [createReportingCategory, { loading: createLoading }] =
        useCreateReportingCategoryMutation({
            onCompleted: () => {
                handleClose();
            },
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        handleClose();
                    },
                    message: `There was a problem saving the reporting category: ${error.message}`,
                });
            },
            update: (cache, response) => {
                const newItem = response.data?.createReportingCategory?.resourceCreated;
                if (response.data?.createReportingCategory?.success && newItem) {
                    const currentItems = cache.readQuery<ReportingCategoryListQuery>({
                        query: ReportingCategoryListDocument,
                    });
                    if (currentItems?.reportingCategorys) {
                        cache.writeQuery<ReportingCategoryListQuery>({
                            query: ReportingCategoryListDocument,
                            data: {
                                reportingCategorys: [...currentItems.reportingCategorys, newItem],
                            },
                        });
                    }
                }
            },
        });

    const [updateItem, { loading: updateLoading }] = useUpdateReportingCategoryMutation({
        onCompleted: () => {
            handleClose();
        },
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    handleClose();
                },
                message: `There was a problem updating the reporting category: ${error.message}`,
            });
        },
        update: (cache, response) => {
            const newItem = response.data?.updateReportingCategory?.resourceUpdated;
            if (response.data?.updateReportingCategory?.success && newItem) {
                const currentItems = cache.readQuery<ReportingCategoryListQuery>({
                    query: ReportingCategoryListDocument,
                });
                if (currentItems?.reportingCategorys) {
                    const updatedList = currentItems.reportingCategorys.map(item => {
                        if (item.id === newItem.id) {
                            return newItem;
                        }
                        return item;
                    });
                    cache.writeQuery<ReportingCategoryListQuery>({
                        query: ReportingCategoryListDocument,
                        data: {
                            reportingCategorys: [...updatedList],
                        },
                    });
                }
            }
        },
    });

    const isInEditMode = item !== null;
    const onSubmit = (values: ReportingCategoryUpdateInput) => {
        setNameUsed(false);
        if (
            reportingCategoriesList &&
            reportingCategoriesList.reportingCategorys.filter(
                t =>
                    t.name.toLowerCase() === values?.name.toLowerCase() &&
                    (!isInEditMode || t.id !== item.id),
            ).length > 0
        ) {
            setNameUsed(true);
            return;
        }
        if (isInEditMode && item !== null) {
            updateItem({
                variables: {
                    updateReportingCategoryInput: {
                        id: item.id,
                        data: values,
                    },
                },
            });
            return;
        }
        createReportingCategory({
            variables: {
                input: values,
            },
        });
    };
    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose id="dialogTitle" onClose={handleClose}>
                {isInEditMode ? 'Edit Reporting Category' : 'Add Reporting Category'}
            </DialogTitleWithClose>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    {listLoading || updateLoading || createLoading ? (
                        <Loading height={50} />
                    ) : (
                        <div>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Name *"
                                    fullWidth
                                    margin="dense"
                                    defaultValue={item?.name}
                                    {...register('name')}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                                {nameUsed && (
                                    <div style={{ color: errorRed }}>
                                        Name already used. Please change.
                                    </div>
                                )}
                            </Grid>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={() => handleSubmit(onSubmit)}
                        data-test={ConditionTypeEnum.SAVE}
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
