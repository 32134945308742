import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    OnSuccessfulEligibility,
    OnSuccessfulZipCodeMatch,
    VirtualCareAffiliatesEntity,
} from '~/schemaTypes';
import PreviewSuccessScreen from '~/views/AffiliateCare/Onboarding/steps/Routing/components/PreviewSuccessScreen';
import Editor from './Editor';
import { zipCodeSuccessText, zipCodeWithUrlSuccessText, eligibilitySuccessText } from '../helpers';

const useStyles = makeStyles()(() => ({
    select: {
        '.MuiSelect-select': {
            whiteSpace: 'break-spaces !important',
        },
    },
    menuItem: {
        whiteSpace: 'break-spaces !important',
    },
}));

type RoutingFormProps = {
    affiliate: Pick<
        VirtualCareAffiliatesEntity,
        'externalName' | 'supportPhone' | 'contactName' | 'supportEmail' | 'website'
    > | null;
    disabled?: boolean;
    showPreview?: boolean;
    showZipCode?: boolean;
    watchSupportData?: boolean;
};

const RoutingForm: React.FC<RoutingFormProps> = props => {
    const {
        affiliate,
        disabled = true,
        showPreview = true,
        showZipCode = false,
        watchSupportData = false,
    } = props;
    const {
        control,
        formState: { errors, defaultValues },
        resetField,
        watch,
        setValue,
    } = useFormContext();
    const { classes } = useStyles();
    const clearDependentValues = () => {
        resetField('customSuccessText', { defaultValue: '' });
        resetField('routingURL', { defaultValue: '' });
    };
    const onSuccessfulEligibilityWatch = watch('onSuccessfulEligibility');
    const onSuccessfulZipCodeMatch = watch('onSuccessfulZipCodeMatch');
    const customSuccessText = watch('customSuccessText');
    const routingUrlWatch = watch('routingURL');
    const [editorInitContent, setEditorInitContent] = useState(
        defaultValues?.zipCodeMatchCustomSuccessString || '',
    );
    useEffect(() => {
        if (!defaultValues?.zipCodeMatchCustomSuccessString && showZipCode && affiliate) {
            if (onSuccessfulEligibilityWatch === OnSuccessfulEligibility.RouteToLanding) {
                setEditorInitContent(zipCodeSuccessText(affiliate));
            } else if (onSuccessfulEligibilityWatch === OnSuccessfulEligibility.RouteDirectly) {
                setEditorInitContent(
                    zipCodeWithUrlSuccessText({
                        ...affiliate,
                        routingURL: routingUrlWatch,
                    }),
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSuccessfulEligibilityWatch, affiliate, routingUrlWatch]);
    useEffect(() => {
        if (showZipCode && defaultValues?.zipCodeMatchCustomSuccessString) {
            setEditorInitContent(defaultValues.zipCodeMatchCustomSuccessString);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues?.zipCodeMatchCustomSuccessString]);
    useEffect(() => {
        if (
            onSuccessfulEligibilityWatch === OnSuccessfulEligibility.RouteToLanding &&
            (!defaultValues?.customSuccessText || watchSupportData)
        ) {
            setValue('customSuccessText', eligibilitySuccessText(affiliate));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSuccessfulEligibilityWatch, affiliate, watchSupportData]);
    useEffect(() => {
        if (showZipCode) {
            setValue('zipCodeMatchCustomSuccessString', editorInitContent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorInitContent]);
    const editorDataReady = true;
    return (
        <>
            <Grid item xs={12} container>
                <OutlinedSection title="On Successful Eligibility">
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="onSuccessfulEligibility"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    select
                                    fullWidth
                                    error={!!errors.onSuccessfulEligibility}
                                    helperText={
                                        errors.onSuccessfulEligibility && 'Please select an option'
                                    }
                                    onChange={e => {
                                        clearDependentValues();
                                        field.onChange(e);
                                    }}
                                    className={classes.select}
                                >
                                    <MenuItem
                                        key={OnSuccessfulEligibility.RouteToLanding}
                                        value={OnSuccessfulEligibility.RouteToLanding}
                                        className={classes.menuItem}
                                    >
                                        <Typography>
                                            I would like to route the patient to a success screen
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        key={OnSuccessfulEligibility.RouteDirectly}
                                        value={OnSuccessfulEligibility.RouteDirectly}
                                        className={classes.menuItem}
                                    >
                                        <Typography>
                                            I would like to route the patient to my scheduling URL
                                        </Typography>
                                    </MenuItem>
                                </TextField>
                            )}
                        />
                    </Grid>
                    {onSuccessfulEligibilityWatch === OnSuccessfulEligibility.RouteDirectly ? (
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="routingURL"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label="Routing URL"
                                        error={!!errors.routingURL}
                                        helperText={errors.routingURL?.message as string}
                                    />
                                )}
                            />
                        </Grid>
                    ) : null}

                    {/* in case of Landing Page URL selected */}
                    {onSuccessfulEligibilityWatch === OnSuccessfulEligibility.RouteToLanding ? (
                        <>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="customSuccessText"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            multiline
                                            rows={6}
                                            label="On Successful Eligibility Custom Success Text"
                                            error={!!errors.customSuccessText}
                                            helperText={errors.customSuccessText?.message as string}
                                            disabled={disabled}
                                        />
                                    )}
                                />
                            </Grid>
                            {showPreview && (
                                <PreviewSuccessScreen
                                    affiliateName={affiliate?.externalName}
                                    customSuccessText={customSuccessText}
                                />
                            )}
                        </>
                    ) : null}
                </OutlinedSection>
            </Grid>
            {showZipCode && (
                <Grid item xs={12} container>
                    <OutlinedSection title="Zip Code Routing Custom Success Text">
                        {onSuccessfulZipCodeMatch === OnSuccessfulZipCodeMatch.ToSuccessScreen && (
                            <Grid item xs={12}>
                                {editorDataReady && (
                                    <Editor
                                        content={editorInitContent}
                                        setFormValue={(data: string) =>
                                            setValue('zipCodeMatchCustomSuccessString', data, {
                                                shouldValidate: true,
                                            })
                                        }
                                        error={
                                            errors.zipCodeMatchCustomSuccessString
                                                ?.message as string
                                        }
                                    />
                                )}
                            </Grid>
                        )}
                    </OutlinedSection>
                </Grid>
            )}
        </>
    );
};

export default RoutingForm;
export { zipCodeSuccessText, zipCodeWithUrlSuccessText, eligibilitySuccessText };
