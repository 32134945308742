import {
    MINUTES_IN_DAY,
    MINUTES_IN_MONTH,
    MINUTES_IN_WEEK,
    MINUTES_IN_YEAR,
} from '~/helpers/minuteConverters';
import { RepeatIntervalUnit, ViewLimitUnit } from '../types';

export const toRepeatIntervalUnit = (m: number): RepeatIntervalUnit => {
    switch (m) {
        case MINUTES_IN_DAY:
            return RepeatIntervalUnit.Daily;
        case MINUTES_IN_WEEK:
            return RepeatIntervalUnit.Weekly;
        case MINUTES_IN_MONTH:
            return RepeatIntervalUnit.Monthly;
        default:
            throw new Error(`Unhandled Repeat Interval: ${m}`);
    }
};

export const toRepeatIntervalMinutes = (unit: RepeatIntervalUnit): number => {
    switch (unit) {
        case RepeatIntervalUnit.Daily:
            return MINUTES_IN_DAY;
        case RepeatIntervalUnit.Weekly:
            return MINUTES_IN_WEEK;
        case RepeatIntervalUnit.Monthly:
            return MINUTES_IN_MONTH;
        default:
            throw new Error(`Unhandled RepeatIntervalUnit: ${unit}`);
    }
};

export const toViewLimitUnit = (m: number): { value: number; unit: ViewLimitUnit } => {
    if (m === 0) {
        return {
            value: 0,
            unit: ViewLimitUnit.Weeks,
        };
    }

    if (m % MINUTES_IN_YEAR === 0) {
        return {
            value: m / MINUTES_IN_YEAR,
            unit: ViewLimitUnit.Years,
        };
    }

    if (m % MINUTES_IN_MONTH === 0) {
        return {
            value: m / MINUTES_IN_MONTH,
            unit: ViewLimitUnit.Months,
        };
    }

    return {
        value: Math.floor(m / MINUTES_IN_WEEK),
        unit: ViewLimitUnit.Weeks,
    };
};

export const fromViewLimitToMinutes = (value: number, unit: ViewLimitUnit): number => {
    switch (unit) {
        case ViewLimitUnit.Weeks:
            return value * MINUTES_IN_WEEK;
        case ViewLimitUnit.Months:
            return value * MINUTES_IN_MONTH;
        case ViewLimitUnit.Years:
            return value * MINUTES_IN_YEAR;
        default:
            throw new Error(`Unhandled ViewLimitUnit: ${unit}`);
    }
};
