import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    watchCount: {
        fontSize: 16,
        marginLeft: 5,
        marginTop: 1,
        fontWeight: 'bolder',
    },
    watchCard: {
        minWidth: 275,
        maxWidth: 400,
        padding: 10,
        marginBottom: 0,
    },
    visibilityIcon: {
        width: 25,
        height: 25,
    },
    divider: {
        marginTop: 5,
    },
    cardContent: {
        padding: 10,
        '&:last-child': {
            paddingBottom: 14,
        },
    },
    watchMessage: {
        fontWeight: 'lighter',
        marginBottom: 10,
        fontSize: 14,
    },
    watcherWrapper: {
        marginBottom: 10,
    },
    avatar: {
        backgroundColor: '#8dc42e',
        fontSize: 15,
        width: 30,
        height: 30,
    },
    emptyState: {
        fontWeight: 'lighter',
    },
});
