type ObjectType = {
    [key: string]: any;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const setValueForKeyPath = (keyPath: string, value: any, object: ObjectType = {}): ObjectType => {
    const [key, ...restKeys] = keyPath.split('.');

    if (!restKeys.length) {
        return {
            ...object,
            [key]: value,
        };
    }

    const result: ObjectType = {
        ...object,
    };

    result[key] = {
        ...object[key],
        ...setValueForKeyPath(restKeys.join('.'), value),
    };

    return result;
};

export default setValueForKeyPath;
