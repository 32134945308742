import {
    createTheme,
    Theme,
    DeprecatedThemeOptions,
    adaptV4Theme,
    ThemeOptions,
} from '@mui/material/styles';
import wfBackground from '../assets/wfBackground.jpg';
import colorTineShadeHelperConstructor, { ColorSpectrumFunc } from './mixins/colorTintShadeHelper';

export const primaryColor = '#116b8e';
export const secondaryColor = '#8dc42e';
export const errorRed = '#e74c3c';

const baseTheme: DeprecatedThemeOptions = {
    palette: {
        primary: {
            main: primaryColor,
        },
        secondary: {
            main: secondaryColor,
        },
        error: {
            main: errorRed,
        },
    },
    props: {
        MuiInputLabel: {
            required: false,
        },
        MuiTextField: {
            variant: 'outlined',
            color: 'secondary',
            inputProps: {
                onWheel: e => e.currentTarget.blur(),
                onTouchMove: e => e.currentTarget.blur(),
            },
        },
        MuiLink: {
            underline: 'none',
        },
        MuiButtonBase: {
            disableRipple: true,
        },
        MuiButton: {
            color: 'primary',
            disableRipple: true,
        },
        MuiSnackbar: {
            anchorOrigin: {
                horizontal: 'center',
                vertical: 'top',
            },
        },
        MuiIconButton: {
            disableRipple: true,
        },
        MuiDialog: {
            fullWidth: true,
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                html: {
                    '& .MuiTableSortLabel-active': {
                        fontWeight: 'bold !important',
                    },
                    '& body': {
                        background: '#fff',
                    },
                    '& .MuiAlert-icon': {
                        alignItems: 'center',
                    },
                },
            },
        },
        MuiButtonBase: {
            root: {
                '&:focus': {
                    outline: 0,
                },
            },
        },
        MuiButton: {
            root: {
                borderRadius: '10px',
                textTransform: 'none',
            },
            containedSecondary: {
                color: 'white',
            },
        },
        MuiCard: {
            root: {
                padding: '30px',
                marginBottom: '20px',
            },
        },
        MuiPaper: {
            elevation0: {
                border: '1px solid lightgray',
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: 'white',
                color: 'black',
                fontSize: 12,
                padding: 5,
                border: '1px solid lightgray',
            },
            popper: {
                marginTop: -15,
            },
        },
        MuiTypography: {
            h1: {
                fontFamily: ['Lato', 'sans-serif'].join(','),
                fontWeight: 700,
            },
            h2: {
                fontFamily: ['Lato', 'sans-serif'].join(','),
                fontWeight: 700,
            },
            h3: {
                fontFamily: ['Lato', 'sans-serif'].join(','),
                fontWeight: 700,
            },
            h4: {
                fontFamily: ['Lato', 'sans-serif'].join(','),
                fontWeight: 700,
            },
            h5: {
                fontFamily: ['Lato', 'sans-serif'].join(','),
                fontWeight: 700,
            },
            h6: {
                fontFamily: ['Lato', 'sans-serif'].join(','),
                fontWeight: 700,
                lineHeight: '1.4',
            },
            body1: {
                fontFamily: ['Lato', 'sans-serif'].join(','),
                fontSize: '16px',
            },
            body2: {
                fontFamily: ['Lato', 'sans-serif'].join(','),
                fontSize: '14px',
            },
            button: {
                fontFamily: ['Lato', 'sans-serif'].join(','),
                fontSize: '12px',
            },
        },
        MuiTabs: {
            root: {},
        },
        MuiTab: {
            root: { textTransform: 'none' },
        },
        MuiInputLabel: {
            formControl: {
                top: 'auto',
                left: 'auto',
            },
        },
        MuiListItem: {
            button: {
                color: secondaryColor,
            },
        },
        MuiListItemIcon: {
            root: {
                color: secondaryColor,
            },
        },
        MuiFormControl: {
            root: {
                padding: '10px',
            },
        },
        MuiStepper: {
            root: {
                backgroundColor: '#fff',
            },
        },
    },
};
type JSSStyles = Record<any, any>;

declare module '@mui/material/styles' {
    interface Theme {
        colors: {
            Primary: ColorSpectrumFunc;
            Secondary: ColorSpectrumFunc;
            SuccessGreen: string;
            ErrorRed: string;
        };
        wildflowerBackgroundContainer: {
            position: any;
            backgroundImage: string;
            backgroundPosition: string;
            backgroundRepeat: string;
            backgroundAttachment: string;
            backgroundSize: string;
            backgroundColor: string;
            display: string;
            alignItems: string;
            justifyContent: string;
            height: string;
            width: string;
        };
        containerStyles: (theme: Theme) => JSSStyles;
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        colors: {
            Primary: ColorSpectrumFunc;
            Secondary: ColorSpectrumFunc;
            SuccessGreen: string;
            ErrorRed: string;
        };
        wildflowerBackgroundContainer: {
            position: any;
            backgroundImage: string;
            backgroundPosition: string;
            backgroundRepeat: string;
            backgroundAttachment: string;
            backgroundSize: string;
            backgroundColor: string;
            display: string;
            alignItems: string;
            justifyContent: string;
            height: string;
            width: string;
        };
        containerStyles: (theme: Theme) => JSSStyles;
    }
}
const WildThemeOptions: ThemeOptions = {
    colors: {
        Primary: colorTineShadeHelperConstructor(primaryColor),
        Secondary: colorTineShadeHelperConstructor(secondaryColor),
        SuccessGreen: '#558b2f',
        ErrorRed: errorRed,
    },
    wildflowerBackgroundContainer: {
        position: 'relative',
        backgroundImage: `url(${wfBackground});`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundColor: '#464646',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
    },
    containerStyles: (theme: Theme) => ({
        paddingLeft: '2%',
        paddingRight: '2%',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '3%',
            paddingRight: '3%',
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '3%',
            paddingRight: '3%',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '3%',
            paddingRight: '3%',
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: '3%',
            paddingRight: '3%',
        },
    }),
};

const combinedOptions = {
    ...baseTheme,
    ...WildThemeOptions,
};

const CustomWildTheme = createTheme(adaptV4Theme(combinedOptions));

export type WildTheme = Theme & typeof WildThemeOptions;

export default CustomWildTheme;
