import * as Yup from 'yup';

export const CALENDAR_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    startDate: Yup.date().required('Start date of calendar is required'),
    endDate: Yup.date().required('End date of calendar is required'),
    offDays: Yup.array().of(
        Yup.object().shape({
            day: Yup.date().required(),
        }),
    ),
    startTime: Yup.date().required('Start time is required'),
    endTime: Yup.date().required('End time is required'),
});

export type CalendarFormInput = {
    name: string;
    startDate: Date;
    endDate: Date;
    offDays?: {
        day: Date;
    }[];
    startTime: Date;
    endTime: Date;
};
