/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, TableBody, TextField } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { SurveyDefButtonsInput } from '~/schemaTypes';

interface ButtonTextInputParms {
    buttonText?: SurveyDefButtonsInput;
    submitHandler: (data: any) => void;
    closeHandler: () => void;
}
const NameInputValidation = Yup.object().shape({
    saveSurvey: Yup.object().shape({
        en: Yup.string(),
        es: Yup.string(),
    }),
    end: Yup.object().shape({
        en: Yup.string(),
        es: Yup.string(),
    }),
    next: Yup.object().shape({
        en: Yup.string(),
        es: Yup.string(),
    }),
    previous: Yup.object().shape({
        en: Yup.string(),
        es: Yup.string(),
    }),
});

const ButtonTextModal: React.FC<ButtonTextInputParms> = props => {
    const {
        register,
        handleSubmit,

        formState: { errors },
    } = useForm<SurveyDefButtonsInput>({
        resolver: yupResolver(NameInputValidation as any),
    });
    const { buttonText, submitHandler, closeHandler } = props;

    const onSubmit = (nameVariables: any) => {
        submitHandler(nameVariables);
    };
    return (
        <Dialog open>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogTitleWithClose id="modalTitle" onClose={closeHandler}>
                    Edit Button Text
                </DialogTitleWithClose>
                <DialogContent>
                    <table>
                        <TableBody>
                            <tr>
                                <td colSpan={3}>
                                    <h3>Button</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>Save</td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        label="English"
                                        type="text"
                                        margin="dense"
                                        defaultValue={buttonText?.saveSurvey?.label?.en}
                                        fullWidth
                                        {...register('saveSurvey.label.en')}
                                        error={!!errors.saveSurvey?.label}
                                        helperText={errors.saveSurvey?.label?.message}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        label="Spanish"
                                        type="text"
                                        margin="dense"
                                        defaultValue={buttonText?.saveSurvey?.label?.es}
                                        fullWidth
                                        {...register('saveSurvey.label.es')}
                                        error={!!errors.saveSurvey?.label}
                                        helperText={errors.saveSurvey?.label?.message}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>End</td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        label="English"
                                        type="text"
                                        margin="dense"
                                        defaultValue={buttonText?.end?.label?.en}
                                        fullWidth
                                        {...register('end.label.en')}
                                        error={!!errors.end?.label}
                                        helperText={errors.end?.label?.message}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        label="Spanish"
                                        type="text"
                                        margin="dense"
                                        defaultValue={buttonText?.end?.label?.es}
                                        fullWidth
                                        {...register('end.label.es')}
                                        error={!!errors.end?.label}
                                        helperText={errors.end?.label?.message}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Next</td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        label="English"
                                        type="text"
                                        margin="dense"
                                        defaultValue={buttonText?.next?.label?.en}
                                        fullWidth
                                        {...register('next.label.en')}
                                        error={!!errors.next?.label}
                                        helperText={errors.next?.label?.message}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        label="Spanish"
                                        type="text"
                                        margin="dense"
                                        defaultValue={buttonText?.next?.label?.es}
                                        fullWidth
                                        {...register('next.label.es')}
                                        error={!!errors.next?.label}
                                        helperText={errors.next?.label?.message}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Previous</td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        label="English"
                                        type="text"
                                        margin="dense"
                                        defaultValue={buttonText?.previous?.label?.en}
                                        fullWidth
                                        {...register('previous.label.en')}
                                        error={!!errors.previous?.label}
                                        helperText={errors.previous?.label?.message}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        label="Spanish"
                                        type="text"
                                        margin="dense"
                                        defaultValue={buttonText?.previous?.label?.es}
                                        fullWidth
                                        {...register('previous.label.es')}
                                        error={!!errors.previous?.label}
                                        helperText={errors.previous?.label?.message}
                                    />
                                </td>
                            </tr>
                        </TableBody>
                    </table>
                </DialogContent>
                <DialogActions
                    style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: 'white',
                        zIndex: 1000,
                    }}
                >
                    <Button onClick={closeHandler} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button type="submit" color="secondary" variant="contained">
                        Update Button Text
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
export {};
export default ButtonTextModal;
