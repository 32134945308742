import { OrderByDirectionEnum } from '~/schemaTypes';

export enum AppointmentSortingFields {
    durationMinutes = 'durationMinutes',
    startDate = 'startDate',
    description = 'description',
    note = 'note',
}

export interface IAppointmentOrderChange {
    field: AppointmentSortingFields;
    direction: OrderByDirectionEnum;
    isInit: boolean;
}
