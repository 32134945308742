import { Worksheet } from 'exceljs';

const COLOR_VARIANTS = {
    primary: 'FF94C3F4',
    secondary: 'FF5DA3EE',
    tertiary: 'FF3E9CD6',
    alert: 'FFF0000',
};

const FONT_SIZE = {
    title: 14,
    body: 11,
};

export const COLUMN_WIDTH = {
    short: 7,
    default: 11,
    mediumLong: 30,
    long: 70,
};

const FONT_FAMILY = 'Arial';

const formatCellBorderThin = (ws: Worksheet, row: number, col: number) => {
    ws.getCell(row, col).border = {
        top: { color: { argb: '#F000' }, style: 'thin' },
        bottom: { color: { argb: '#F000' }, style: 'thin' },
        left: { color: { argb: '#F000' }, style: 'thin' },
        right: { color: { argb: '#F000' }, style: 'thin' },
    };
};
export const formatContentRange = (
    ws: Worksheet,
    startRow: number,
    endRow: number,
    startCol: number,
    endCol: number,
    boldFirstCol?: boolean,
    boldFirstRow?: boolean,
): void => {
    for (let col = startCol; col <= endCol; col++) {
        for (let row = startRow; row <= endRow; row++) {
            ws.getCell(row, col).font = {
                size: FONT_SIZE.body,
                name: FONT_FAMILY,
                bold: (boldFirstCol && col === 1) || (boldFirstRow && row === 1),
            };
            ws.getCell(row, col).alignment = { horizontal: 'left', wrapText: true };
        }
    }
};

export const formatSheet = (ws: Worksheet): void => {
    ws.columns.forEach(column => {
        column.width = COLUMN_WIDTH.default;
    });
};

export const formatShortColumns = (ws: Worksheet, col: number): void => {
    ws.getColumn(col).width = COLUMN_WIDTH.short;
};

export const formatMediumLongColumns = (ws: Worksheet, col: number): void => {
    ws.getColumn(col).width = COLUMN_WIDTH.mediumLong;
};

export const formatLongColumns = (ws: Worksheet, col: number): void => {
    ws.getColumn(col).width = COLUMN_WIDTH.long;
};

export const formatCustomWidthColumns = (ws: Worksheet, col: number, width: number): void => {
    ws.getColumn(col).width = width;
};

export const formatTitle = (ws: Worksheet, row: number): void => {
    ws.getRow(row).font = { size: FONT_SIZE.title, bold: true };
};

export const formatFlag = (ws: Worksheet, startRow: number, col: number, height: number): void => {
    for (let i = 0; i <= height; i++) {
        const row = startRow + i;
        const currentFontStyle = { ...ws.getCell(row, col).font };
        ws.getCell(row, col).font = {
            ...currentFontStyle,
            color: { argb: COLOR_VARIANTS.alert },
            bold: true,
            size: FONT_SIZE.title,
        };
        ws.getCell(row, col).alignment = { horizontal: 'center', vertical: 'middle' };
    }
};

export const formatBackupLogo = (ws: Worksheet, row: number, col: number): void => {
    ws.getCell(row, col).font = {
        bold: true,
        color: { argb: COLOR_VARIANTS.tertiary },
        size: FONT_SIZE.title,
        name: FONT_FAMILY,
    };
};

export const formatTableTitle = (
    ws: Worksheet,
    row: number,
    startCol: number,
    endCol: number,
    color: keyof typeof COLOR_VARIANTS = 'primary',
): void => {
    ws.mergeCells(row, startCol, row, endCol);
    ws.getCell(row, startCol).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: COLOR_VARIANTS[color] },
    };
    ws.getCell(row, startCol).font = {
        size: FONT_SIZE.body,
        name: FONT_FAMILY,
        bold: true,
    };
    formatCellBorderThin(ws, row, startCol);
};

export const formatTableBorders = (
    ws: Worksheet,
    startRow: number,
    endRow: number,
    startCol: number,
    endCol: number,
): void => {
    for (let col = startCol; col <= endCol; col++) {
        for (let row = startRow; row <= endRow; row++) {
            formatCellBorderThin(ws, row, col);
        }
    }
};
