import React from 'react';
import { DialogContent, Typography } from '@mui/material';
import MaterialTable from '@material-table/core';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { useEligibilityMatchQuery, PotentialMatch } from '~/schemaTypes';

type ActionDialogProps = {
    id: string;
    callback: () => void;
};

const PotentialMatches: React.FC<ActionDialogProps> = props => {
    const { id, callback } = props;
    const { data, loading } = useEligibilityMatchQuery({
        fetchPolicy: 'network-only',
        variables: {
            input: {
                id,
            },
        },
    });
    return (
        <div>
            <DialogTitleWithClose id="form-dialog-title" onClose={callback}>
                Potential Matches
            </DialogTitleWithClose>
            <DialogContent>
                <MaterialTable<PotentialMatch>
                    columns={[
                        {
                            title: 'First Name',
                            render: ({ firstName }) => (
                                <Typography data-test={firstName}>{firstName}</Typography>
                            ),
                        },
                        {
                            title: 'Last Name',
                            render: ({ lastName }) => (
                                <Typography data-test={lastName}>{lastName}</Typography>
                            ),
                        },
                    ]}
                    data={
                        data?.eligibilityMatch?.potentialMatches?.map((o: any) => ({
                            ...o,
                        })) || []
                    }
                    isLoading={loading}
                    options={{
                        search: false,
                        paging: false,
                        toolbar: false,
                    }}
                />
            </DialogContent>
        </div>
    );
};

export default PotentialMatches;
