import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { ExpressionUseType, SurveyDefTextInput } from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import ConfigExpressionPicker from '~/views/ConfigDashboard/ConfigExpressions/components/ConfigExpressionPicker';

interface PieceTextInputParms extends SurveyDefTextInput {
    divisionId: string;
    sectionId: string;
    pieceId?: string;
    expressionList?: (string | null)[] | null | undefined;
    submitHandler: (data: any) => void;
    closeHandler: () => void;
    deleteHandler: (divId: string, secId: string, pieceId: string) => void;
}
const PieceTextInputValidation = Yup.object().shape({
    title: Yup.object().shape({
        en: Yup.string().required('Required'),
        es: Yup.string(),
    }),
    body: Yup.object().shape({
        en: Yup.string().required('Required'),
        es: Yup.string(),
    }),
});

const PieceTextModal: React.FC<PieceTextInputParms> = props => {
    const {
        register,
        handleSubmit,

        formState: { errors },
    } = useForm<SurveyDefTextInput>({
        resolver: yupResolver(PieceTextInputValidation as any),
    });

    const {
        divisionId,
        sectionId,
        pieceId,
        title,
        body,
        expressionList,
        submitHandler,
        closeHandler,
        deleteHandler,
    } = props;

    const isEditMode = Boolean(title.en);
    const [selectedExpressions, setSelectedExpressions] = useState<(string | null)[]>(
        expressionList ?? [],
    );

    const onSubmit = (nameVariables: any) => {
        const data = { ...nameVariables, divisionId, sectionId, pieceId, selectedExpressions };
        submitHandler(data);
    };

    const onDelete = () => {
        TriggerGlobalConfirm({
            message: 'Do you really want to delete this Text?',
            callback: () => {
                deleteHandler(divisionId, sectionId, pieceId ?? '');
            },
        });
    };
    const expressionAdded = (id: string) => {
        setSelectedExpressions(selectedExpressions?.concat(id));
    };
    const expressionDropped = (id: string) => {
        setSelectedExpressions(selectedExpressions?.filter(exp => exp !== id));
    };

    return (
        <Dialog open>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogTitleWithClose id="modalTitle" onClose={closeHandler}>
                    {isEditMode ? 'Edit' : 'Add'} Text Content
                </DialogTitleWithClose>
                <DialogContent>
                    <OutlinedSection title="Title">
                        <TextField
                            variant="outlined"
                            label="English"
                            type="text"
                            margin="dense"
                            defaultValue={title?.en}
                            fullWidth
                            {...register('title.en')}
                            error={!!errors.title}
                            helperText={errors.title?.en?.message}
                        />
                        <TextField
                            variant="outlined"
                            label="Spanish"
                            type="text"
                            margin="dense"
                            defaultValue={title?.es}
                            fullWidth
                            {...register('title.es')}
                            error={!!errors.title}
                            helperText={errors.title?.es?.message}
                        />
                    </OutlinedSection>
                    <OutlinedSection title="Body">
                        <TextField
                            variant="outlined"
                            label="English"
                            type="text"
                            multiline
                            rows={8}
                            margin="dense"
                            defaultValue={body?.en}
                            fullWidth
                            {...register('body.en')}
                            error={!!errors.body}
                            helperText={errors.body?.en?.message}
                        />
                        <TextField
                            variant="outlined"
                            label="Spanish"
                            type="text"
                            multiline
                            rows={8}
                            margin="dense"
                            defaultValue={body?.es}
                            fullWidth
                            {...register('body.es')}
                            error={!!errors.body}
                            helperText={errors.body?.es?.message}
                        />
                    </OutlinedSection>
                    <ConfigExpressionPicker
                        inputList={selectedExpressions}
                        itemAddedHandler={expressionAdded}
                        itemDroppedHandler={expressionDropped}
                        useType={ExpressionUseType.Surveys}
                        canChange
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeHandler} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button type="submit" color="secondary" variant="contained">
                        {pieceId ? 'Update ' : 'Add '}
                        Text
                    </Button>{' '}
                    {pieceId && (
                        <Button color="primary" variant="contained" onClick={onDelete}>
                            Remove Text
                        </Button>
                    )}{' '}
                </DialogActions>
            </form>
        </Dialog>
    );
};

export {};
export default PieceTextModal;
