import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    pageTitleWrap: {
        borderBottom: '1px solid black',
        marginBottom: 10,
    },
    pageTitle: {
        marginRight: 10,
    },
    sidebarWrapper: {
        paddingLeft: 20,
        borderLeft: '1px solid black',
        marginBottom: 10,
    },
    infoSection: {
        marginBottom: 20,
    },
});
