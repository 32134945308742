import React, { useMemo } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { AffiliateHistory } from '~/schemaTypes';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { displayDate, displayHistoryEntry } from './helpers';
import { useStyles } from './styles';

type AffiliateHistoryRecord = Pick<AffiliateHistory, 'oldData' | 'newData' | 'createdAt' | 'id'>;

interface IVCAffiliateHistoryProps {
    history?: (AffiliateHistoryRecord | null)[] | null;
}

type HistoryEntry = {
    text: string;
    timestamp: string;
    id: string;
};

export const VCAffiliatesHistory: React.FC<IVCAffiliateHistoryProps> = ({ history }) => {
    const { classes } = useStyles();

    const historyEntries = useMemo(
        () =>
            (history
                ?.map(record => {
                    if (!record) {
                        return null;
                    }
                    const recordText = displayHistoryEntry(record);
                    if (!recordText) {
                        return null;
                    }
                    return {
                        text: recordText,
                        timestamp: displayDate(record.createdAt),
                        id: record.id,
                    };
                })
                .filter(entry => entry !== null) ?? []) as HistoryEntry[],
        [history],
    );

    return (
        <OutlinedSection title="History" className={classes.historySection}>
            <Grid xs={12} container item className={classes.itemsContainer}>
                {historyEntries.length ? (
                    historyEntries?.map(({ text, timestamp, id }) => (
                        <Grid xs={12} item key={id}>
                            <Typography variant="caption">{timestamp}</Typography>
                            <Typography variant="body1">{text}</Typography>
                            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                        </Grid>
                    ))
                ) : (
                    <Grid xs={12} item alignSelf="center">
                        <Typography textAlign="center">No history recorded</Typography>
                    </Grid>
                )}
            </Grid>
        </OutlinedSection>
    );
};
