import React, { Dispatch, SetStateAction } from 'react';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import { Grid, TextField, DialogContent, Typography, IconButton, Tooltip } from '@mui/material';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import {
    AlertSeverity,
    useOrganizationVcEligibilityEligibilityResultsByIdQuery,
} from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import { TriggerGlobalAlert } from '~/state';
import { InfoOutlined } from '@mui/icons-material';
import { JsonViewer } from '@textea/json-viewer';

type ViewSelectedResultProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    resultId?: string;
};

const ViewSelectedResult: React.FC<ViewSelectedResultProps> = props => {
    const { resultId, setOpen } = props;
    const { data, loading } = useOrganizationVcEligibilityEligibilityResultsByIdQuery({
        variables: {
            input: {
                filter: {
                    fields: {
                        _id: resultId,
                    },
                },
            },
        },
    });
    const formData = data?.eligibilityResults?.results[0];
    const showContent = !loading && formData;
    return (
        <div>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setOpen(false)}>
                Virtual Care Eligibility Request
            </DialogTitleWithClose>
            <DialogContent dividers>
                {!showContent && <Loading />}
                {showContent && (
                    <Grid container direction="column" justifyContent="flex-start">
                        <Grid item width={400}>
                            <TextField
                                variant="outlined"
                                label="_id"
                                fullWidth
                                type="text"
                                margin="dense"
                                value={formData.id}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item width={400}>
                            <TextField
                                variant="outlined"
                                label="Passed"
                                fullWidth
                                type="text"
                                margin="dense"
                                value={formData.passed ? 'True' : 'False'}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item width={400}>
                            <TextField
                                variant="outlined"
                                label="Date Time"
                                fullWidth
                                type="text"
                                margin="dense"
                                value={moment(formData?.createdAt).format()}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <Grid item width={400}>
                                <TextField
                                    variant="outlined"
                                    label="First Name"
                                    fullWidth
                                    type="text"
                                    margin="dense"
                                    value={formData?.firstName}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item width={400}>
                                <TextField
                                    variant="outlined"
                                    label="Last Name"
                                    fullWidth
                                    type="text"
                                    margin="dense"
                                    value={formData?.lastName}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item width={400}>
                                <TextField
                                    variant="outlined"
                                    label="Email"
                                    fullWidth
                                    type="text"
                                    margin="dense"
                                    value={formData?.email}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item width={400}>
                                <TextField
                                    variant="outlined"
                                    label="Phone"
                                    fullWidth
                                    type="text"
                                    margin="dense"
                                    value={formData?.phoneNumber}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item minWidth={1000}>
                                <Grid container alignItems="center">
                                    <Grid item xs={11}>
                                        <TextField
                                            variant="outlined"
                                            label="Data Submitted"
                                            fullWidth
                                            type="text"
                                            margin="dense"
                                            value={formData?.data}
                                            multiline
                                            minRows={2}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={formData?.data}>
                                            <IconButton
                                                onClick={() => {
                                                    copy(`${formData?.data}`);
                                                    TriggerGlobalAlert({
                                                        message: 'Data  copied to clipboard',
                                                        severity: AlertSeverity.Success,
                                                    });
                                                }}
                                                size="large"
                                            >
                                                <InfoOutlined />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item minWidth={1000}>
                                <Grid
                                    container
                                    flexDirection="column"
                                    spacing={2}
                                    paddingLeft="10px"
                                >
                                    <Grid item>
                                        <Typography>Response</Typography>
                                    </Grid>
                                    <Grid item>
                                        <JsonViewer
                                            value={
                                                formData.response
                                                    ? JSON.parse(formData.response)
                                                    : {}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </div>
    );
};

export default ViewSelectedResult;
