/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    createHttpLink,
    from,
    split,
} from '@apollo/client';
import React from 'react';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import {
    AccessToken,
    ApolloCache,
    AuthTokens,
    IdToken,
    PortalVersionMismatchLastCheck,
    TriggerGlobalAlert,
    TriggerGlobalConfirm,
} from '~/state';
import { jwtDecode } from 'jwt-decode';
import LocalSchema from './state/localSchema';
import { AlertSeverity } from './schemaTypes';
import { logout } from './state/auth/auth.svc';
import { cognitoRefreshTokens } from './state/auth/cognito.svc';
import { log } from './utils/log.svc';

// let logoutWorker: SingOutEventEmitter | null = null;
type ApolloClientProps = {
    children: React.ReactNode;
};

const wsLink = new WebSocketLink({
    uri: window.location.host.startsWith('localhost')
        ? 'ws://localhost:4444/api/portal/subscriptions'
        : `${
              (window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host
          }/api/portal/subscriptions`,
    options: {
        reconnect: true,
        connectionParams: () =>
            localStorage.getItem(AuthTokens.WfhAuthToken)
                ? { 'wfh-auth-token': localStorage.getItem(AuthTokens.WfhAuthToken) }
                : {
                      authorization: `Bearer ${localStorage.getItem('AccessToken')}`,
                      id: `Bearer ${localStorage.getItem('IdToken')}`,
                  },
    },
});

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL || '/api/portal/graphql',
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
);

const errorHandlerLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        const accessToken = AccessToken();
        log.error('errorHandlerLink graphQLErrors', graphQLErrors);
        const { pathname } = window.location;
        graphQLErrors.forEach(({ message, path }) => {
            // /patient-invite pages and /virtual-care are public and dont need user info
            if (
                path?.includes('roleOfCurrentUser') &&
                !accessToken &&
                (pathname === '/patient-invite' ||
                    pathname.startsWith('/care-connect/doula') ||
                    pathname.startsWith('/virtual-care') ||
                    pathname.startsWith('/affiliate-care-portal/affiliate-creation') ||
                    (pathname.startsWith('/affiliate-care') &&
                        !pathname.startsWith('/affiliate-care-portal')))
            ) {
                return;
            }
            if (
                (path?.includes('roleOfCurrentUser') && !accessToken) ||
                (path?.includes('currentUser') && !accessToken)
            ) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Warning,
                    message: 'Your session expired, please login again.',
                });
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message,
                });
            }
        });
    }
    if (networkError) {
        log.error({ err: networkError }, 'errorHandlerLink networkError');
        TriggerGlobalAlert({
            severity: AlertSeverity.Error,
            message: networkError.message,
        });
    }
});

const responseMiddlewareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        log.info(
            { operation: { ...operation, query: operation.query?.loc }, response },
            'responseMiddlewareLink',
        );
        try {
            // Check Portal-UI Version
            if (
                !PortalVersionMismatchLastCheck() ||
                (PortalVersionMismatchLastCheck()?.valueOf() &&
                    Date.now() - (PortalVersionMismatchLastCheck()?.valueOf() as number) >
                        5 * 60 * 1000)
            ) {
                log.info(`Checking Portal-UI Version`);
                PortalVersionMismatchLastCheck(new Date());
                const context = operation.getContext();
                const portalVersionHeader = context.response.headers.get('X-Portal-UI-Version');
                if (!process.env.REACT_APP_VERSION) {
                    log.warn(`No Local Version Detected`);
                }
                log.info(
                    { REACT_APP_VERSION: process.env.REACT_APP_VERSION },
                    `Current Local Version:`,
                );
                if (!portalVersionHeader) {
                    log.warn(`No Remote Version Detected`);
                }
                log.info({ portalVersionHeader }, `Current Remote Version`);
                if (portalVersionHeader && process.env.REACT_APP_VERSION) {
                    if (portalVersionHeader !== process.env.REACT_APP_VERSION) {
                        log.info(`Portal Version mismatch detected, prompting Page Refresh`);
                        TriggerGlobalConfirm({
                            message: `New version of Portal available, please refresh your page by clicking confirm.`,
                            description:
                                'If you are in the middle of something feel free to click cancel and refresh the page manually after you finish your work.',
                            callback: () => {
                                window.location.reload();
                            },
                        });
                    }
                }
            }

            // Check for mutation errors
            const data = response?.data;
            if (data) {
                const keys = Object.keys(data);
                keys.forEach(key => {
                    const res = data[key];
                    if (res) {
                        const { success, message } = res;
                        if (success) {
                            TriggerGlobalAlert({
                                severity: AlertSeverity.Success,
                                message:
                                    message ??
                                    `${operation.operationName
                                        .replace(/([A-Z])/g, ' $1')
                                        .trim()}: Succeeded`,
                            });
                        }
                        if (success === false) {
                            TriggerGlobalAlert({
                                severity: AlertSeverity.Error,
                                message:
                                    message ??
                                    `${operation.operationName
                                        .replace(/([A-Z])/g, ' $1')
                                        .trim()}: Failed`,
                            });
                        }
                    }
                });
            }
        } catch (_error) {
            return response;
        }
        return response;
    });
});

const authLink = setContext(async (op, { headers }) => {
    const idToken = IdToken();
    const accessToken = AccessToken();
    const wfhAuthToken = localStorage.getItem(AuthTokens.WfhAuthToken);
    log.debug(
        { idToken: idToken?.payload, accessToken: accessToken?.payload, wfhAuthToken },
        'setContext',
    );
    const { operationName } = op;
    // FetchChatRoomsForMessageCenter is not considered user activity, is called in background for unread messages.
    const isActiveOperation = operationName !== 'FetchChatRoomsForMessageCenter';

    const baseHeaders = Object.fromEntries(
        Object.entries(headers)
            .filter(([key]) => !['id', 'authorization', 'wfh-auth-token'].includes(key))
            .concat([
                ['apollographql-client-name', 'Portal-UI'],
                ['apollographql-client-version', '1.0.0'],
            ]),
    );

    try {
        if (idToken && accessToken && !wfhAuthToken) {
            if (accessToken.isExpired()) {
                throw new Error('cognito access token expired');
            }
            if (accessToken.ageMinutes()! > 5 && isActiveOperation) {
                cognitoRefreshTokens().catch(err => log.error({ err }, 'cognitoRefreshTokens'));
            }
        } else if (wfhAuthToken) {
            const decoded = jwtDecode(wfhAuthToken);
            const exp = decoded?.exp;
            if (exp && Date.now() >= exp * 1000) {
                throw new Error('WfhAuthToken expired');
            }
        }
    } catch (error) {
        TriggerGlobalAlert({
            message: 'Your session expired, please login again.',
            severity: AlertSeverity.Warning,
        });
        const err = error as Error;
        log.error(
            { err, stack: err?.stack, msg: err?.message },
            'setContext: Token validation error, signing out',
        );
        await logout();
        // inactive login modal windows for queries not considered as activity
        if (isActiveOperation) {
            // eslint-disable-next-line no-self-assign
            window.location = window.location;
        }
        return {
            headers: baseHeaders,
        };
    }
    return {
        headers: {
            ...baseHeaders,
            ...(wfhAuthToken && { 'wfh-auth-token': wfhAuthToken }),
            ...(accessToken && !wfhAuthToken && { authorization: `Bearer ${accessToken.token}` }),
            ...(idToken && !wfhAuthToken && { id: `Bearer ${idToken.token}` }),
        },
    };
});

let sessionId = crypto.randomUUID();
const sessionLink = new ApolloLink((operation, forward) => {
    if (sessionId != null) {
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                'wfh-session-id': sessionId,
            },
        }));
    }
    return forward(operation).map(response => {
        const responseSessionId = operation.getContext().response?.headers.get('wfh-session-id');
        if (responseSessionId != null) {
            sessionId = responseSessionId;
        }
        return response;
    });
});

export const client = new ApolloClient({
    link: from([responseMiddlewareLink, sessionLink, authLink, errorHandlerLink, splitLink]),
    cache: ApolloCache,
    typeDefs: LocalSchema,
});

export const ApolloClientWrapper: React.FC<ApolloClientProps> = ({ children }) => {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
