import ObjectID from 'bson-objectid';
import {
    AdvocateTaskChecklistItemInput,
    AdvocateTaskTemplateInput,
    AdvocateTaskTemplateWhenType,
    TimeUnit,
} from '~/schemaTypes';
import { ClientSelectionType, OffsetType } from '~/enums/enums';
import { toMinutes } from '~/helpers/minuteConverters';
import { toBase64 } from '~/helpers/base64Helper';
import {
    AdvocateTaskTemplateChecklistItem,
    AdvocateTaskTemplateFormInput,
    WhenTimeline,
} from '../types/AdvocateTaskTemplateFormInput';

const toIncludeOrExclude = (taskTemplate: AdvocateTaskTemplateFormInput) => {
    const { clientSelectionType, includeApps, excludeApps } = taskTemplate?.whenTimeline ?? {};
    switch (clientSelectionType) {
        case ClientSelectionType.IncludeCertainApps:
            return {
                includeApps: includeApps ?? [],
                excludeApps: [],
            };
        case ClientSelectionType.ExcludeCertainApps:
            return {
                includeApps: [],
                excludeApps: excludeApps ?? [],
            };
        case ClientSelectionType.IncludeAllApps:
        default:
            return {
                includeApps: [],
                excludeApps: [],
            };
    }
};

const toChecklistInput = (
    checklist: AdvocateTaskTemplateChecklistItem[],
): AdvocateTaskChecklistItemInput[] =>
    checklist.map(checklistItem => ({
        id: checklistItem.id || new ObjectID().toHexString(),
        label: toBase64(checklistItem.label.en) || '',
        description: toBase64(checklistItem.description || '') || '',
        actionTypeIds: checklistItem.actionTypeIds,
        endSlaTimerOnComplete: checklistItem.endSlaTimerOnComplete || null,
    }));

export const toDurationMinutes = (durationValue: number, durationUnit: TimeUnit): number =>
    durationValue && durationUnit ? toMinutes(durationValue, durationUnit) : 0;

const toOffsetValue = (offsetValue: number, offsetType: OffsetType) =>
    offsetType === OffsetType.After ? Number(offsetValue) : Number(offsetValue) * -1;

const toOffsetAndDuration = ({
    offsetDays,
    offsetMonths,
    offsetYears,
    offsetType,
    durationValue,
    durationUnit,
}: WhenTimeline) => ({
    offsetDays: offsetDays && offsetType ? toOffsetValue(offsetDays, offsetType) : 0,
    offsetMonths: offsetMonths && offsetType ? toOffsetValue(offsetMonths, offsetType) : 0,
    offsetYears: offsetYears && offsetType ? toOffsetValue(offsetYears, offsetType) : 0,
    durationMinutes: toDurationMinutes(durationValue, durationUnit),
});

export const toRequestInput = (
    form: AdvocateTaskTemplateFormInput,
    checklist: AdvocateTaskTemplateChecklistItem[],
): AdvocateTaskTemplateInput => {
    const {
        label,
        description,
        priority,
        whenType,
        typeId,
        whenTimeline,
        durationUnit,
        durationValue,
        shouldNotify,
        slaId,
    } = form;

    return {
        label,
        description,
        priority,
        checklist: toChecklistInput(checklist),
        whenType,
        typeId,
        whenTimeline:
            whenType === AdvocateTaskTemplateWhenType.Timeline && whenTimeline
                ? {
                      timelineId: whenTimeline.timelineId,
                      tags: whenTimeline.tags,
                      ...toIncludeOrExclude(form),
                      ...toOffsetAndDuration(whenTimeline),
                  }
                : null,
        interventionDurationMinutes:
            durationValue && durationUnit ? toDurationMinutes(durationValue, durationUnit) : null,
        shouldNotify,
        slaId: slaId || null,
    };
};
