import { useContext, createContext, Dispatch, SetStateAction } from 'react';
import { Steps, FormInput } from './types';

export const DEFAULT_STEP_CB = () => async () => true;

type Context = {
    currentStep: Steps;
    setIsValidStep: (value: boolean) => void;
    setLoading: (value: boolean) => void;
    affiliate: FormInput | null;
    setAffiliateData: Dispatch<SetStateAction<FormInput>>;
    setStepCb: Dispatch<SetStateAction<() => Promise<boolean>>>;
};

const defaultData = {
    currentStep: Steps.Consultants,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setIsValidStep: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setLoading: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setAffiliateData: () => {},
    affiliate: null,
    setStepCb: DEFAULT_STEP_CB,
};

export const OnboardingContext = createContext<Context>(defaultData);

export const useOnboarding = () => useContext(OnboardingContext);
