import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { useRef, useState } from 'react';
import tableIcons from '~/helpers/tableIcons';
import useUserPermissions from '~/hooks/useUserPermissions';
import { TodosCompletedTablePropsInterface } from './interfaces';

export const CompletedTodosTable: React.FC<TodosCompletedTablePropsInterface> = ({
    data,
    onEdit,
    isLoading,
}) => {
    const { pagePermissions } = useUserPermissions();

    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    return (
        <div ref={tableRef}>
            <MaterialTable
                title="To Do's"
                icons={tableIcons as MaterialTableProps<any>['icons']}
                actions={[
                    {
                        onClick: (_, todoItem) => {
                            if (!Array.isArray(todoItem)) {
                                onEdit(todoItem.id);
                            }
                        },
                        disabled: !pagePermissions?.PatientTodos.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit',
                    },
                ]}
                columns={[
                    {
                        title: 'To Do',
                        field: 'title.en',
                        align: 'center',
                    },
                    {
                        title: 'Careplan',
                        field: 'carePlan.label.en',
                        align: 'center',
                    },
                    {
                        title: 'Category',
                        field: 'carePlan.group.label.en',
                        align: 'center',
                    },
                    {
                        title: 'Date Compeleted',
                        field: 'dateCompleted',
                        type: 'date',
                        dateSetting: {
                            format: 'mm/dd/yyyy',
                        },
                        align: 'center',
                    },
                ]}
                isLoading={isLoading}
                data={data}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [30, 50, 100],
                    exportAllData: pagePermissions?.PatientTodos.Export,
                }}
            />
        </div>
    );
};
