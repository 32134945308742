import { OrderByDirectionEnum } from '~/schemaTypes';
import { PatientSortingFields } from '../../Patients/types';

export enum CallListPreviewSortingFields {
    firstName = 'patient.firstName',
    lastName = 'patient.lastName',
    createdAt = 'patient.createdAt',
    birthDate = 'patient.birthDate',
    onboardingCompleteDate = 'patient.onboardingCompleteDate',
    isPregnant = 'patient.isPregnant',
    dueDate = 'patient.dueDate',
    deliveryDate = 'patient.deliveryDate',
    assignedHealthAdvocate = 'patient.assignedHealthAdvocate',
}

export const CallListSortingFieldsToPatientSorting: Record<
    CallListPreviewSortingFields,
    PatientSortingFields
> = {
    [CallListPreviewSortingFields.firstName]: PatientSortingFields.firstName,
    [CallListPreviewSortingFields.lastName]: PatientSortingFields.lastName,
    [CallListPreviewSortingFields.createdAt]: PatientSortingFields.createdAt,
    [CallListPreviewSortingFields.birthDate]: PatientSortingFields.birthDate,
    [CallListPreviewSortingFields.onboardingCompleteDate]:
        PatientSortingFields.onboardingCompleteDate,
    [CallListPreviewSortingFields.isPregnant]: PatientSortingFields.isPregnant,
    [CallListPreviewSortingFields.dueDate]: PatientSortingFields.dueDate,
    [CallListPreviewSortingFields.deliveryDate]: PatientSortingFields.deliveryDate,
    [CallListPreviewSortingFields.assignedHealthAdvocate]:
        PatientSortingFields.assignedHealthAdvocate,
};

export interface ICallListPreviewOrderChange {
    field: PatientSortingFields;
    direction: OrderByDirectionEnum;
    fieldIsProfileDefId?: boolean;
    isInit: boolean;
}
