import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import React, { useState } from 'react';
import { displayDateWithAbbrTimeZone } from '~/helpers';
import parse from 'html-react-parser';
import {
    ActionSource,
    FetchAdvocateTaskForAdvocateTaskPageQuery,
    useCreatePatientActionForAdvocateTaskPageMutation,
    useDeletePatientActionForAdvocateTaskPageMutation,
    useUpdatePatientActionForAdvocateTaskPageMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { PatientActionModal } from '../../components';
import { PatientActionModalSubmit } from '../../components/PatientActionModal/types';
import { HistoryActionType } from '../History/enums';
import { actionTypeLabelById, getPatientActionDiff } from '../History/helpers';
import { useStyles } from './styles';

type PatientActionType = NonNullable<
    FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']
>['actionTypes'][0];

type PatientAction = NonNullable<
    FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']
>['patientActions'][0];

interface PatientActionProps {
    advocateTaskId: string;
    patientId: string;
    actorId: string;
    patientActionTypes: PatientActionType[];
    patientActions: PatientAction[];
    onPatientActionsUpdated: (change: string) => void;
}

const PatientActions: React.FC<PatientActionProps> = ({
    advocateTaskId,
    patientId,
    actorId,
    patientActionTypes,
    patientActions,
    onPatientActionsUpdated,
}) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedPatientAction, setSelectedPatientAction] = useState<PatientAction | null>(null);
    const { classes } = useStyles();

    const onCloseModal = () => {
        setIsModalOpen(false);
        setSelectedPatientAction(null);
    };

    const [createPatientAction] = useCreatePatientActionForAdvocateTaskPageMutation({
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    onCloseModal();
                },
                message: `There was a problem saving the advocate task action: ${error.message}`,
            });
        },
        onCompleted: onCloseModal,
    });

    const [updatePatientAction] = useUpdatePatientActionForAdvocateTaskPageMutation({
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    onCloseModal();
                },
                message: `There was a problem saving the advocate task action: ${error.message}`,
            });
        },
        onCompleted: onCloseModal,
    });

    const [deletePatientAction] = useDeletePatientActionForAdvocateTaskPageMutation({
        onCompleted: () => {
            onCloseModal();
        },
    });

    const handlePatientActionDelete = ({ id, typeId }: PatientAction) => {
        const actionType = patientActionTypes.find(
            patientActionType => patientActionType.id === typeId,
        );
        const actionTypeLabel = actionType?.label ?? '';

        if (id) {
            deletePatientAction({ variables: { input: { id } } });
            onPatientActionsUpdated(`${HistoryActionType.DELETED} '${actionTypeLabel}' Action`);
        }
    };

    const onModalSubmit: PatientActionModalSubmit = (patientAction, id) => {
        const actionTypeLabel = actionTypeLabelById(patientAction.typeId, patientActionTypes);

        if (id) {
            updatePatientAction({
                variables: {
                    input: {
                        id,
                        data: {
                            ...patientAction,
                            patientId,
                            taskId: advocateTaskId,
                            actorId,
                            source: selectedPatientAction?.source,
                        },
                    },
                },
            });
            const [prevPatientAction] = patientActions.filter(action => action.id === id);
            const patientActionsDiff = getPatientActionDiff(
                patientAction,
                prevPatientAction,
                patientActionTypes,
            );
            onPatientActionsUpdated(
                `${HistoryActionType.UPDATED} '${actionTypeLabel}' Action: ${patientActionsDiff}`,
            );
        } else {
            createPatientAction({
                variables: {
                    input: { ...patientAction, patientId, taskId: advocateTaskId, actorId },
                },
            });
            onPatientActionsUpdated(`${HistoryActionType.CREATED} '${actionTypeLabel}' Action`);
        }
    };

    return (
        <>
            <Grid container>
                <Grid item container alignItems="center">
                    <Grid item>
                        <Typography variant="subtitle1" className={classes.summary}>
                            Patient Actions ({patientActions.length})
                        </Typography>
                    </Grid>
                    <Grid item className={classes.addButtonWrapper}>
                        <Tooltip title="Add Patient Action">
                            <IconButton
                                onClick={() => setIsModalOpen(true)}
                                className={classes.addButton}
                                size="large"
                            >
                                <AddOutlinedIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                {patientActions.length !== 0 && patientActionTypes?.length !== 0 && (
                    <Grid
                        item
                        style={{
                            width: '100%',
                        }}
                    >
                        {patientActions.map(patientAction => (
                            <Grid
                                container
                                item
                                className={classes.patientAction}
                                xs={11}
                                key={patientAction.id}
                            >
                                <Grid container item alignItems="center" wrap="nowrap">
                                    <Grid item>
                                        <Typography variant="body2">
                                            <span className={classes.patientActionType}>
                                                {patientActionTypes?.find(
                                                    actionType =>
                                                        actionType.id === patientAction.typeId,
                                                )?.label || ''}
                                            </span>{' '}
                                            on{' '}
                                            <span className={classes.date}>
                                                {displayDateWithAbbrTimeZone({
                                                    isoDateStr: patientAction.completedAt,
                                                    format: 'MM/DD/yyyy, hh:mm:ss A',
                                                    timeZone:
                                                        Intl.DateTimeFormat().resolvedOptions()
                                                            .timeZone,
                                                })}
                                            </span>
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.edit}>
                                        <Tooltip title="Edit">
                                            <IconButton
                                                onClick={() => {
                                                    setSelectedPatientAction(patientAction);
                                                    setIsModalOpen(true);
                                                }}
                                                size="large"
                                            >
                                                <FontAwesomeIcon icon={faPenToSquare} size="xs" />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1">
                                        {parse(patientAction.description || '')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Grid>
            <Dialog
                scroll="paper"
                open={isModalOpen}
                fullWidth
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
            >
                <PatientActionModal
                    data={selectedPatientAction}
                    patientActionTypes={patientActionTypes}
                    closeModal={() => {
                        setIsModalOpen(false);
                        setSelectedPatientAction(null);
                    }}
                    onSubmit={onModalSubmit}
                    onDelete={handlePatientActionDelete}
                    isEditMode={Boolean(selectedPatientAction)}
                    actionSource={ActionSource.HaTask}
                />
            </Dialog>
        </>
    );
};

export default PatientActions;
