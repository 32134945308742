import React, { useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Grid, Typography, Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    useSupportSettingsFetchAffiliateLazyQuery,
    useUpdateSupportInfoForAffiliateMutation,
    SupportSettingsFetchAffiliateQuery,
} from '~/schemaTypes';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import { useAffiliateCareView } from '~/hooks';
import Loading from '~/components/Loading/Loading';
import ContactInformation from '~/views/AffiliateCare/Onboarding/steps/Routing/components/ContactInformation/ContactInformation';
import { FormInput } from './types';
import { SUPPORT_FORM_SCHEMA } from './yupSchema';
import Layout from '../components/Layout';

const formDataMapper = (data: SupportSettingsFetchAffiliateQuery) => ({
    website: data?.virtualCareAffiliateByAffiliateId?.data?.website,
    supportEmail: data?.virtualCareAffiliateByAffiliateId?.data?.supportEmail,
    supportPhone: data?.virtualCareAffiliateByAffiliateId?.data?.supportPhone,
});

const Support = () => {
    const { userAffiliateId, id: affiliateId } = useAffiliateCareView();
    const [fetchSupportSettings, { loading: fetchSupportDataLazyLoading }] =
        useSupportSettingsFetchAffiliateLazyQuery({
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
        });
    const formData = useForm<FormInput>({
        resolver: yupResolver(SUPPORT_FORM_SCHEMA as any),
        defaultValues: async () => {
            const response = await fetchSupportSettings({
                variables: {
                    input: {
                        affiliateId: userAffiliateId,
                    },
                },
            });
            if (!response.data) {
                return {};
            }
            return formDataMapper(response.data);
        },
    });
    const {
        formState: { isDirty },
        handleSubmit,
        reset,
    } = formData;
    const [updateData, { loading: updateLoading }] = useUpdateSupportInfoForAffiliateMutation({
        onCompleted: async () => {
            const response = await fetchSupportSettings({
                variables: {
                    input: {
                        affiliateId: userAffiliateId,
                    },
                },
            });
            if (response.data) {
                reset(formDataMapper(response.data));
            }
        },
    });
    const onSubmit = useCallback(
        (data: FormInput) => {
            updateData({
                variables: {
                    input: {
                        id: affiliateId,
                        data,
                    },
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [affiliateId],
    );
    const loading = updateLoading || fetchSupportDataLazyLoading;
    return (
        <Layout title="Support Contact">
            <Grid item xs={12} md={8} container>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Support Contact Information</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        Who should the patient reach out to schedule appointments?
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
                {fetchSupportDataLazyLoading ? (
                    <Loading />
                ) : (
                    <FormProvider {...formData}>
                        <ContactInformation />
                    </FormProvider>
                )}
            </Grid>
            <Grid item xs={12} container>
                <Grid item>
                    <AsyncActionButton loading={loading}>
                        <Button
                            disabled={loading || !isDirty}
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Update Support
                        </Button>
                    </AsyncActionButton>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default Support;
