import { toBase64 } from '~/helpers/base64Helper';
import {
    AdvocateTaskChecklistItemInput,
    FetchAdvocateTaskForAdvocateTaskPageQuery,
} from '~/schemaTypes';

type Checklist = NonNullable<
    FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']
>['checklist'][0];

type ToAdvocateTaskChecklistInput = (checklist: Checklist[]) => AdvocateTaskChecklistItemInput[];

const toAdvocateTaskChecklistInput: ToAdvocateTaskChecklistInput = checklist =>
    checklist.map(
        ({
            id,
            label,
            description,
            completedAt,
            completedBy,
            actionTypeIds,
            endSlaTimerOnComplete,
        }) => ({
            id,
            label: toBase64(label || '') || '',
            description: toBase64(description || '') || '',
            completedAt,
            completedBy,
            actionTypeIds,
            endSlaTimerOnComplete,
        }),
    );

export const toAdvocateTaskChecklistInputForSteps: ToAdvocateTaskChecklistInput = checklist =>
    checklist.map(
        ({
            id,
            label,
            description,
            completedAt,
            completedBy,
            actionTypeIds,
            endSlaTimerOnComplete,
        }) => ({
            id,
            label,
            description,
            completedAt,
            completedBy,
            actionTypeIds,
            endSlaTimerOnComplete,
        }),
    );

export default toAdvocateTaskChecklistInput;
