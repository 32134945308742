import { Button, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import Loading from '~/components/Loading/Loading';
import { PAGESIZE } from '~/constants';
import { displayDateWithAbbrTimeZone } from '~/helpers';
import { ChoiceConfiguration, mapProfileVariableValue } from '~/helpers/getStringifiedValue';
import tableIcons from '~/helpers/tableIcons';
import {
    PatientProfileVariable,
    ProfileValueHistory,
    ProfileValueType,
    SourceTypeEnum,
    UserProfileValueType,
    useFetchUsersForAdvocateTaskListPageQueryQuery,
    useUserProfileDefForHistoriesQuery,
} from '~/schemaTypes';
import WeightValue from '~/components/WeightValue/WeightValue';
import useStyles from '../PatientProfileVariableEpisodesModal/styles';
import { PROFILE_VALUES_TO_CONVERT_WEIGHT } from '../PatientProfileVariables';

type PatientProfileVariableHistoryModalProps = {
    variableHistories: ProfileValueHistory[];
    patientProfileVariable: PatientProfileVariable;
    onClose: () => void;
};

export const PatientProfileVariableHistoryModal = ({
    variableHistories,
    patientProfileVariable,
    onClose,
}: PatientProfileVariableHistoryModalProps) => {
    const { classes } = useStyles();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const { data: userProfileDef, loading: userProfileDefLoading } =
        useUserProfileDefForHistoriesQuery({
            variables: {
                input: {
                    id: patientProfileVariable.profileVariableDefId,
                },
            },
        });

    const { data: users, loading: loadingUsers } = useFetchUsersForAdvocateTaskListPageQueryQuery();

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    if (userProfileDefLoading || loadingUsers) return <Loading />;

    return (
        <Grid className={classes.root} ref={tableRef}>
            <MaterialTable
                icons={tableIcons}
                data={_.cloneDeep(variableHistories)}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                columns={[
                    {
                        title: 'Date Changed',
                        field: 'createdAt',
                        type: 'datetime',
                        sorting: true,
                        render: rowData => {
                            const { dateTimeZone } = rowData.val;
                            return displayDateWithAbbrTimeZone({
                                isoDateStr: rowData.createdAt,
                                format: 'MM/DD/yyyy, hh:mm:ss A',
                                timeZone:
                                    dateTimeZone ??
                                    Intl.DateTimeFormat().resolvedOptions().timeZone,
                            });
                        },
                    },
                    {
                        title: 'Value',
                        field: 'value',
                        render: rowData => {
                            const data = variableHistories.find(
                                i => i.id === rowData.id,
                            ) as ProfileValueHistory;
                            if (data.type === ProfileValueType.Date) {
                                const date = data.val.date as string;
                                const displayDate = `${date.substring(5, 7)}/${date.substring(
                                    8,
                                    10,
                                )}/${date.substring(0, 4)}`;
                                return <Typography>{displayDate}</Typography>;
                            }
                            const isWeightData = PROFILE_VALUES_TO_CONVERT_WEIGHT.has(
                                patientProfileVariable.name,
                            );
                            if (isWeightData && data.val.num) {
                                return <WeightValue value={data.val.num} showUnit />;
                            }
                            return mapProfileVariableValue(
                                data.val,
                                data.type as unknown as UserProfileValueType,
                                {
                                    choices: userProfileDef?.userProfileDef?.choices,
                                    choiceValueType:
                                        userProfileDef?.userProfileDef?.choiceValueType,
                                } as ChoiceConfiguration,
                            );
                        },
                    },
                    {
                        title: 'Source',
                        field: 'source.type',
                        render: rowData => {
                            if (rowData.source?.type === SourceTypeEnum.PortalUser) {
                                return (
                                    users?.usersV2.results.find(
                                        u => u.id === rowData.source.portalUserId,
                                    )?.name ?? SourceTypeEnum.PortalUser
                                );
                            }
                            return rowData.source?.type ?? SourceTypeEnum.System;
                        },
                        searchable: false,
                    },
                ]}
                options={{
                    search: false,
                    pageSize: rowsPerPage,
                    toolbar: false,
                }}
            />
            <Grid className={classes.actions} item justifyContent="flex-end">
                <Button color="primary" variant="contained" onClick={onClose}>
                    Close
                </Button>
            </Grid>
        </Grid>
    );
};
