import { Typography } from '@mui/material';
import React from 'react';
import { GetPatientNotesForPatientTranscriptPageQuery } from '~/schemaTypes';

type PatientNote = NonNullable<
    GetPatientNotesForPatientTranscriptPageQuery['getPatientNotesForPatientTranscript']
>[0];

export const PatientNoteCard = ({ patientNote }: { patientNote: PatientNote }) => {
    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <Typography variant="h6">Note</Typography>
            <p className="m-0">Title: {patientNote?.title}</p>
            <p className="m-0">Created By: {patientNote?.staffName}</p>
            <p className="m-0">Note:</p>
            <p
                className="m-0"
                style={{ display: 'inline-block' }}
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{ __html: patientNote?.text || '' }}
            />
        </div>
    );
};
