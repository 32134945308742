import { makeStyles } from 'tss-react/mui';

export default makeStyles()({
    container: {
        border: '2px solid black',
        minHeight: 80,
    },
    iconContainer: {
        width: 80,
        height: 80,
        padding: 5,
    },
    icon: {
        fontWeight: 'bolder',
        fontSize: 60,
    },
    infoContainer: {
        paddingLeft: 10,
    },
});
