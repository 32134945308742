import { TimeUnit } from '~/schemaTypes';

export enum ClientSelectionType {
    'IncludeAllApps' = 'Include All Apps',
    'IncludeCertainApps' = 'Include Certain Apps',
    'ExcludeCertainApps' = 'Exclude Certain Apps',
}

export enum OffsetType {
    'Before' = 'Before',
    'After' = 'After',
}
export const ClientSelectionTypes = Object.values(ClientSelectionType);
export const Priorities = [1, 2, 3, 4, 5];
export const TimeUnits = Object.values(TimeUnit);
export const OffsetTypes = Object.values(OffsetType);
