import { BioScreenBuilderFormInput } from '~/views/ConfigDashboard/BioScreen/BioScreenBuilder/types/BioScreenBuilderFormInput';
import { BioScreenShortcutType, BioScreenTargetType } from '~/schemaTypes';
import _ from 'lodash';

export const toUpdateBioScreen = (formData: BioScreenBuilderFormInput) => {
    const { label, yourHealthInfo, surveyGroups, shortcuts } = formData;

    return {
        label,
        target: BioScreenTargetType.Portal,
        yourHealthInfo: {
            ...yourHealthInfo,
            type: BioScreenShortcutType.YourHealthInfo,
        },
        surveyGroups:
            surveyGroups?.map(item => ({
                ...item,
                surveys: item.surveys.map(survey => ({
                    ..._.omit(survey, 'label.__typename'),
                })),
            })) || [],
        shortcuts:
            shortcuts?.map(shortcut => ({
                ...shortcut,
                surveyId: shortcut.surveyId || undefined,
                surveyGroupId: shortcut.surveyGroupId || undefined,
            })) || [],
    };
};
