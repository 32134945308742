import React, { Dispatch, SetStateAction } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    ResponseCalculationEnum,
    SlaInput,
    SlaTimerTypeEnum,
    SlaTypeEnum,
    SlasV2ForSlAsPageDocument,
    SlasV2ForSlAsPageQuery,
    useCalendarsV2ForSlaModalQuery,
    useCreateSlaForSlaModalMutation,
    useUpdateSlaForSlaModalMutation,
} from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import {
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { SLA_VALIDATION_SCHEMA, SlaFormInput } from './SLAModal.formValidation';

type SLAModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    id?: string;
    defaultSLAValues?: SlaFormInput | null;
};

const SLAModal = ({ setOpen, id, defaultSLAValues }: SLAModalProps) => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<SlaFormInput>({
        resolver: yupResolver(SLA_VALIDATION_SCHEMA as any),
        defaultValues: {
            ...defaultSLAValues,
        },
    });

    // const {
    //     fields: notifications,
    //     append,
    //     remove,
    // } = useFieldArray({
    //     control,
    //     name: 'notifyBefore',
    // });

    const { data: calendars, loading: loadingCalendars } = useCalendarsV2ForSlaModalQuery();

    const [createSla, { loading: loadingCreateSla }] = useCreateSlaForSlaModalMutation({
        update: (cache, response) => {
            const newSla = response.data?.createSla?.resourceCreated;
            if (response.data?.createSla?.success && newSla?.id) {
                const getSlasData = cache.readQuery<SlasV2ForSlAsPageQuery>({
                    query: SlasV2ForSlAsPageDocument,
                });
                const currentSlas = getSlasData?.slasV2.results;
                if (currentSlas) {
                    const newSlas = [...currentSlas, newSla];
                    cache.writeQuery<SlasV2ForSlAsPageQuery>({
                        query: SlasV2ForSlAsPageDocument,
                        data: {
                            slasV2: {
                                __typename: getSlasData.slasV2.__typename,
                                results: {
                                    ...newSlas,
                                },
                            },
                        },
                    });
                }
            }
        },
    });

    const [updateSla, { loading: loadingUpdateSla }] = useUpdateSlaForSlaModalMutation({
        update: (cache, response) => {
            const updatedSla = response.data?.updateSla?.resourceUpdated;
            if (response.data?.updateSla?.success && updatedSla?.id) {
                const getSlasData = cache.readQuery<SlasV2ForSlAsPageQuery>({
                    query: SlasV2ForSlAsPageDocument,
                });
                const currentSlas = getSlasData?.slasV2.results;
                if (currentSlas) {
                    currentSlas.map(sla => (sla.id === updatedSla.id ? updatedSla : sla));
                    cache.writeQuery<SlasV2ForSlAsPageQuery>({
                        query: SlasV2ForSlAsPageDocument,
                        data: {
                            slasV2: {
                                __typename: getSlasData.slasV2.__typename,
                                results: {
                                    ...currentSlas,
                                },
                            },
                        },
                    });
                }
            }
        },
    });

    const onSubmit = (values: SlaFormInput) => {
        const input: SlaInput = {
            ...values,
            // notifyBefore: values.notifyBefore?.map(notification => notification.whenToNotify),
        };
        if (id) {
            updateSla({
                variables: {
                    input: {
                        id,
                        data: {
                            ...input,
                        },
                    },
                },
            });
        } else {
            createSla({
                variables: {
                    input: {
                        ...input,
                    },
                },
            });
        }
        setOpen(false);
    };

    if (loadingCreateSla || loadingUpdateSla || loadingCalendars) {
        return <Loading />;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitleWithClose id="sla-modal-title" onClose={() => setOpen(false)}>
                {id ? 'Edit' : 'Add'} SLA Modal
            </DialogTitleWithClose>
            <DialogContent>
                <Grid container>
                    <TextField
                        variant="outlined"
                        label="Name"
                        type="text"
                        margin="dense"
                        fullWidth
                        {...register('name')}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                    />
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Type</InputLabel>
                            <Controller
                                name="type"
                                control={control}
                                defaultValue={defaultSLAValues?.type}
                                render={({ field: { value } }) => (
                                    <Select
                                        variant="outlined"
                                        labelId="sla-type-label"
                                        fullWidth
                                        value={value}
                                        {...register('type')}
                                        error={!!errors.type}
                                    >
                                        {Object.values(SlaTypeEnum).map(type => (
                                            <MenuItem key={type} value={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Response Calculation</InputLabel>
                            <Controller
                                name="responseCalculation"
                                control={control}
                                defaultValue={defaultSLAValues?.responseCalculation}
                                render={({ field: { value } }) => (
                                    <Select
                                        variant="outlined"
                                        labelId="sla-response-calculation-label"
                                        fullWidth
                                        value={value}
                                        {...register('responseCalculation')}
                                        error={!!errors.responseCalculation}
                                    >
                                        {Object.values(ResponseCalculationEnum).map(type => (
                                            <MenuItem key={type} value={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Timer Type</InputLabel>
                            <Controller
                                name="timerType"
                                control={control}
                                defaultValue={defaultSLAValues?.timerType}
                                render={({ field: { value } }) => (
                                    <Select
                                        variant="outlined"
                                        labelId="sla-timer-type-label"
                                        fullWidth
                                        value={value}
                                        {...register('timerType')}
                                        error={!!errors.timerType}
                                    >
                                        {Object.values(SlaTimerTypeEnum).map(type => (
                                            <MenuItem key={type} value={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            label="Time to complete (measured based on timer type)"
                            type="number"
                            margin="dense"
                            fullWidth
                            {...register('timeToComplete')}
                            error={!!errors.timeToComplete}
                            helperText={errors.timeToComplete?.message}
                        />
                    </Grid>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Calendar</InputLabel>
                        <Controller
                            name="calendarId"
                            control={control}
                            defaultValue={defaultSLAValues?.calendarId}
                            render={({ field: { value } }) => (
                                <Select
                                    variant="outlined"
                                    labelId="sla-calendar-id-label"
                                    fullWidth
                                    value={value}
                                    {...register('calendarId')}
                                    error={!!errors.calendarId}
                                >
                                    {calendars?.calendarsV2.results.map(calendar => (
                                        <MenuItem key={calendar.id} value={calendar.id}>
                                            {calendar.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>
                    {/* <>
                        {notifications.map((field, i) => (
                            <>
                                <TextField
                                    variant="outlined"
                                    label="Notify before timer is complete (minutes)"
                                    type="number"
                                    margin="dense"
                                    fullWidth
                                    {...register(`notifyBefore.${i}.whenToNotify`)}
                                    error={!!errors.notifyBefore}
                                    helperText={errors.notifyBefore?.message}
                                />
                                <IconButton type="button" onClick={() => remove(i)}>
                                    <Delete />
                                </IconButton>
                            </>
                        ))}
                        <Button
                            variant="contained"
                            type="button"
                            onClick={() => append({ whenToNotify: 15 })}
                        >
                            add notification
                        </Button>
                    </> */}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    startIcon={<SaveIcon />}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={loadingCreateSla || loadingUpdateSla}
                >
                    Save
                </Button>
            </DialogActions>
        </form>
    );
};

export default SLAModal;
