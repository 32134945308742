/* eslint-disable radix */
import MaterialTable, { MaterialTableProps, OrderByCollection } from '@material-table/core';
import React, { useEffect, useRef, useState } from 'react';

import { makeStyles } from 'tss-react/mui';
import { PAGESIZE } from '~/constants';
import {
    OrderByDirectionEnum,
    useFetchPatientCallListsForDashboardQuery,
    FetchPatientCallListsForDashboardQueryVariables,
    PatientCallList,
    useFetchPatientCallListsExportToCsvLazyQuery,
    AlertSeverity,
    ExportToCsvCallListsFilterInput,
    useDeletePatientCallListForDashboardMutation,
} from '~/schemaTypes';
import tableIcons from '~/helpers/tableIcons';
import { Dialog, TablePagination, Typography, Link as MuiLink } from '@mui/material';
import { displayDateWithAbbrTimeZone } from '~/helpers';
import { flipDirection } from '~/helpers/flipDirection';
import { TriggerGlobalAlert } from '~/state';
import { Add, Download, Loop } from '@mui/icons-material';
import { keyframes } from 'tss-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useUser } from '~/hooks';
import useUserPermissions from '~/hooks/useUserPermissions';
import { CallListsSortingFields, ICallListsOrderChange } from './types';
import PatientCallListModal from './PatientCallListModal/PatientCallListModal';

const useStyles = makeStyles()(_theme => ({
    root: {
        position: 'relative',
        height: '100%',
        '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
            opacity: '0',
        },
    },
    searchChips: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
    },
    rotating: {
        animation: `${keyframes`
        from {
            transform: rotate(360deg);
        }
        to {
            transform: rotate(0deg);
        }
        `} 1s linear infinite`,
    },
}));

// corresponds to columns={[]} passed to MaterialUI
const columnIdToSortingFieldMap: Record<number, CallListsSortingFields> = {
    0: CallListsSortingFields.name,
    1: CallListsSortingFields.userName,
    2: CallListsSortingFields.createdAt,
};
const getDirection = (order: string): OrderByDirectionEnum =>
    order.toUpperCase() === OrderByDirectionEnum.Asc
        ? OrderByDirectionEnum.Asc
        : OrderByDirectionEnum.Desc;

const PatientCallListGrid: React.FC = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const { classes } = useStyles();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [order, setOrder] = useState<ICallListsOrderChange>({
        field: CallListsSortingFields.createdAt,
        direction: OrderByDirectionEnum.Desc,
        isInit: true,
    });
    const [filter] = useState<ExportToCsvCallListsFilterInput | null>(null);
    const [search, setSearch] = useState<string>('');
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [selectedCallList, setSelectedCallList] = useState<PatientCallList | null>(null);

    const { id: paramId } = useParams();
    const { data } = useUser();
    const navigate = useNavigate();
    const userId = data?.currentUser?.id;
    const { pagePermissions } = useUserPermissions();

    const changeDirection = (previousDirection: OrderByDirectionEnum): void => {
        setOrder(state => ({
            ...state,
            direction: flipDirection(previousDirection),
            isInit: false,
        }));
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const fetchPatientCallistsVariables: FetchPatientCallListsForDashboardQueryVariables = {
        input: {
            pagination: {
                skip: page * rowsPerPage,
                limit: rowsPerPage,
            },
            orderBy: {
                field: order.field,
                order: order.direction,
            },
            filter: {
                ...(userId && {
                    fields: {
                        createdBy: userId,
                        ...filter?.fields,
                    },
                }),
                ...filter,
            },
        },
    };
    const handleOrderChange = (orderByCollection: OrderByCollection[]) => {
        const orderBy = orderByCollection[0];
        const field = columnIdToSortingFieldMap[orderBy.orderBy];
        if (field) {
            if (field !== order.field) {
                setOrder(state => ({
                    ...state,
                    isInit: true,
                }));
            }
            const direction = order.isInit ? getDirection(orderBy.orderDirection) : order.direction;
            changeDirection(direction);
            setOrder(state => ({
                ...state,
                field,
            }));
        }
    };
    const {
        data: CallListsData,
        refetch,
        loading: CallListsLoading,
    } = useFetchPatientCallListsForDashboardQuery({
        variables: fetchPatientCallistsVariables,
    });

    const [deletePatientCallList, { loading: deletePatientCallListLoading }] =
        useDeletePatientCallListForDashboardMutation({
            onCompleted: data => {
                const success = data?.deletePatientCallList?.success;
                const status = data?.deletePatientCallList?.status;
                const message = data?.deletePatientCallList?.message;
                const id = data?.deletePatientCallList?.id;
                if (!success || status !== 200) {
                    TriggerGlobalAlert({
                        message: `Error deleting Patient Call List (${JSON.stringify(
                            id,
                        )}) : ${JSON.stringify(message)}`,
                        severity: AlertSeverity.Error,
                    });
                }
            },
        });

    const [fetchCSVExport, { loading: csvExportLoading }] =
        useFetchPatientCallListsExportToCsvLazyQuery({
            fetchPolicy: 'no-cache',
            onCompleted: data => {
                const csvContent = data?.PatientCallListsExportToCSV?.data;
                const fileName = data?.PatientCallListsExportToCSV?.fileName;
                if (csvContent) {
                    const parsedContent = csvContent.filter(c => c !== null) as string[];
                    const forBLob: ArrayLike<number> = new Uint8Array(
                        parsedContent.map(c => parseInt(c)),
                    );
                    const { buffer } = new Uint8Array(forBLob);
                    const blob = new Blob([buffer], { type: 'text/csv' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName ?? 'calllists.csv';
                    a.click();
                    a.remove();
                } else {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Warning,
                        message: 'Recieved empty response for csv documetn from the server.',
                    });
                }
            },
            onError: () => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'Failed to export Patient Call Lists.',
                });
            },
        });

    const handleDownloadPatientsCsv = () => {
        if (csvExportLoading) return;
        fetchCSVExport({
            variables: {
                input: { filter, orderBy: { field: order.field, order: order.direction } },
            },
        });
    };
    const handleAddCallList = () => {
        setSelectedCallList(null);
        setShowEditModal(true);
    };
    const handleEditCallList = (event: unknown, data: PatientCallList) => {
        setSelectedCallList(data);
        setShowEditModal(true);
    };
    const handleDeleteCallList = async (event: unknown, data: PatientCallList) => {
        const { id } = data;
        await deletePatientCallList({
            variables: {
                input: {
                    id,
                },
            },
        });
        await refetch(fetchPatientCallistsVariables);
    };

    const handleCloseModal = () => {
        setShowEditModal(false);
        setSelectedCallList(null);
    };

    const handleUpdateCallLists = () => {
        refetch(fetchPatientCallistsVariables);
    };

    useEffect(() => {
        if (!paramId && userId) {
            navigate(`/portal/call-list-dashboard/${userId}`, { replace: true });
        }
    }, [navigate, paramId, userId]);

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable<PatientCallList>
                title="Call Lists for Patients"
                isLoading={CallListsLoading || deletePatientCallListLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                actions={[
                    {
                        tooltip: 'Export CSV',
                        onClick: handleDownloadPatientsCsv,
                        icon: () =>
                            csvExportLoading ? <Loop className={classes.rotating} /> : <Download />,
                        isFreeAction: true,
                        hidden: !pagePermissions?.PatientCallLists.Export,
                    },
                    {
                        onClick: handleAddCallList,
                        icon: () => <Add />,
                        tooltip: 'Add Call List',
                        isFreeAction: true,
                        hidden: !pagePermissions?.PatientCallLists.Edit,
                    },
                    {
                        onClick: handleEditCallList,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Call List',
                        hidden: !pagePermissions?.PatientCallLists.Edit,
                    },
                    {
                        onClick: handleDeleteCallList,
                        icon: () => <FontAwesomeIcon icon={faTrash} />,
                        tooltip: 'Delete Call List',
                        hidden: !pagePermissions?.PatientCallLists.Delete,
                    },
                ]}
                columns={[
                    {
                        title: 'Title',
                        field: 'name',
                        align: 'left',
                        searchable: true,
                        render: ({ id, name }) => (
                            <MuiLink
                                to={`/portal/call-list-preview/${id}`}
                                component={Link}
                                underline="hover"
                            >
                                <Typography>{name}</Typography>
                            </MuiLink>
                        ),
                    },
                    {
                        title: 'Created by',
                        field: 'user.name',
                        align: 'left',
                    },
                    {
                        title: 'Created',
                        field: 'createdAt',
                        render: ({ createdAt }) => (
                            <Typography>
                                {displayDateWithAbbrTimeZone({
                                    isoDateStr: createdAt,
                                    format: 'MM/DD/yyyy, hh:mm:ss A',
                                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                })}
                            </Typography>
                        ),
                        customSort: (a, b) =>
                            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
                    },
                    {
                        title: 'Tags',
                        field: 'tags',
                        render: ({ tags }) => (
                            <Typography>{tags?.map(tag => tag.name).join(', ') ?? ''}</Typography>
                        ),
                        customSort: (a, b) => {
                            if (a.tags && b.tags) {
                                const aTags = a.tags.map(tag => tag.name).join(', ');
                                const bTags = b.tags.map(tag => tag.name).join(', ');
                                return aTags.localeCompare(bTags);
                            }
                            return 0;
                        },
                    },
                ]}
                data={(CallListsData?.patientCallListsV2.results as PatientCallList[]) || []}
                onOrderCollectionChange={handleOrderChange}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={CallListsData?.patientCallListsV2.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onSearchChange={searchText => {
                    setSearch(searchText);
                }}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    exportAllData: pagePermissions?.PatientCallLists.Export,
                    searchText: search ?? '',
                    paginationPosition: 'both',
                }}
            />
            <Dialog
                scroll="paper"
                open={showEditModal}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <PatientCallListModal
                    onClose={handleCloseModal}
                    onUpdate={handleUpdateCallLists}
                    selected={selectedCallList}
                />
            </Dialog>
        </div>
    );
};

export default PatientCallListGrid;
