import React from 'react';
import { Editor } from '@tiptap/react';
import {
    ContentType,
    useSimpleContentsForRichTextEditorMenuBarImageDialogQuery,
} from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import { Button, Dialog, DialogTitle } from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import ImageContentPicker from '~/components/ImageContentPicker/ImageContentPicker';
import { CLOUDFRONT_IMAGE_SOURCE } from '~/constants';

type ImageSelectFormInput = {
    simpleContent: {
        id: string;
        name: string;
        content: string;
    };
};

type RichTextEditorMenuBarImageDialogProps = {
    editor: Editor;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ImageSelectSchema = Yup.object()
    .shape({
        simpleContent: Yup.object()
            .shape({
                id: Yup.string().required(),
                name: Yup.string().required(),
                content: Yup.string().required(),
            })
            .required(),
    })
    .required();

const RichTextEditorMenuBarImageDialog = ({
    editor,
    open,
    setOpen,
}: RichTextEditorMenuBarImageDialogProps) => {
    const { setValue, handleSubmit } = useForm<ImageSelectFormInput>({
        resolver: yupResolver(ImageSelectSchema as any),
    });

    const { data: images, loading } = useSimpleContentsForRichTextEditorMenuBarImageDialogQuery({
        variables: {
            input: {
                filter: {
                    fields: {
                        contentType: ContentType.Image,
                    },
                },
            },
        },
    });

    const onSubmit = (data: ImageSelectFormInput) => {
        editor
            .chain()
            .focus()
            .insertContent(
                `<img src="${`${CLOUDFRONT_IMAGE_SOURCE}${data.simpleContent.content}`}" data-id="${
                    data.simpleContent.id
                }" />`,
            )
            .run();
        setOpen(false);
    };

    const handleSelectImageContent = (imageContentId: string) => {
        const imageContent = images?.simpleContentsV2.results.find(
            image => image.id === imageContentId,
        );
        if (!imageContent || !imageContent.content) return;
        setValue('simpleContent', {
            id: imageContent.id,
            name: imageContent.name,
            content: imageContent.content,
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (loading) {
        return <Loading subtitle="Loading images..." />;
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Select an image</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ImageContentPicker setSelectedImageContentId={handleSelectImageContent} required />
            </form>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '0 10px 10px 0',
                    gap: '10px',
                }}
            >
                <Button size="medium" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="contained" size="medium" onClick={handleSubmit(onSubmit)}>
                    Select
                </Button>
            </div>
        </Dialog>
    );
};

export default RichTextEditorMenuBarImageDialog;
