export enum AppValueEnum {
    APP_VALUES = 'app-values',
    APP_VALUES_MODAL = 'app-values-modal',
    MODAL_OVERRIDE_VALUE = 'modal-override-value',
    APP_VALUES_MODAL_OVERRIDE_EXPRESSION = 'app-values-modal-override-expression',
    APP_VALUES_MODAL_SAVE = 'app-values-modal-save',
}

export const AppValueSelectors = {
    APP_VALUES_MODAL: `div[data-test="${AppValueEnum.APP_VALUES_MODAL}"]`,
    EDIT_BUTTON: `div[data-test="${AppValueEnum.APP_VALUES}"] button[title="Edit App Value"]`,
    APP_VALUES_MODAL_VALUE: `div[data-test="${AppValueEnum.APP_VALUES_MODAL}"] input[name="value"]`,
    APP_VALUES_MODAL_OVERRIDE_VALUE: `div[data-test="${AppValueEnum.MODAL_OVERRIDE_VALUE}"] input`,
    APP_VALUES_MODAL_OVERRIDE_EXPRESSION: `div[data-test="${AppValueEnum.APP_VALUES_MODAL_OVERRIDE_EXPRESSION}"] div[role="button"]`,
    OVERRIDE_EXPRESSION_DROPDOWN:
        'div[role="presentation"] ul[aria-labelledby="SelectOverrideForValue-label"]',
    OVERRIDE_EXPRESSION_DROPDOWN_FIRST_ITEM:
        'ul[aria-labelledby="SelectOverrideForValue-label"] li[data-test]:first-child',
    OVERRIDE_EXPRESSION_DROPDOWN_SECOND_ITEM:
        'ul[aria-labelledby="SelectOverrideForValue-label"] li[data-test]:nth-child(2)',
    APP_VALUES_MODAL_SAVE_BUTTON: `button[data-test="${AppValueEnum.APP_VALUES_MODAL_SAVE}"]`,
};
