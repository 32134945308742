import * as Yup from 'yup';
import { UserProfileValueType } from '~/schemaTypes';

export const PROFILE_DEFINITION_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    reportingNotes: Yup.string(),
    inboundNotes: Yup.string(),
    category: Yup.string().required('Required'),
    valueType: Yup.mixed<UserProfileValueType>()
        .oneOf(Object.values(UserProfileValueType))
        .required('Required'),
    label: Yup.object().shape({
        en: Yup.string().required('Required'),
        es: Yup.string(),
    }),
    questionLabel: Yup.object().shape({
        en: Yup.string(),
        es: Yup.string(),
    }),
    choices: Yup.array(),
    choiceValueType: Yup.string(),
    isClientSpecific: Yup.boolean(),
    purposeAppPersonalization: Yup.boolean(),
    purposeHumanServices: Yup.boolean(),
    linkToJIRA: Yup.string(),
    clientSpecificDisplayName: Yup.string().when(
        'isClientSpecific',
        ([isClientSpecific], schema) => {
            return isClientSpecific
                ? schema.required('Required field if "Is Client Specific" is checked')
                : schema;
        },
    ),
});
