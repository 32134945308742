import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Loading from '~/components/Loading/Loading';
import {
    FetchDevicesForDevicesPageQueryDocument,
    FetchDevicesForDevicesPageQueryQuery,
    useCreateDeviceFromDevicesPageMutation,
    useFetchDeviceByIdForDeviceModalLazyQuery,
    useUpdateDeviceFromDevicesPageMutation,
} from '~/schemaTypes';

const DEVICE_VALIDATION_SCHEMA = Yup.object().shape({
    photo: Yup.string(),
    externalId: Yup.string(),
    name: Yup.object().shape({
        en: Yup.string().required('Required'),
        es: Yup.string(),
    }),
    docLink: Yup.object().shape({
        en: Yup.string(),
        es: Yup.string(),
    }),
});

const useStyles = makeStyles()({
    root: {},
});

interface DeviceFormInput {
    externalId: string;
    name: {
        en: string;
        es: string;
    };
    docLink: {
        en: string;
        es: string;
    };
    photo: string;
}

type DeviceModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setEditDeviceId: Dispatch<SetStateAction<string>>;
    id?: string;
};

const DevicesModal: React.FC<DeviceModalProps> = ({ setOpen, setEditDeviceId, id }) => {
    const { classes } = useStyles();
    const {
        reset,
        register,
        handleSubmit,

        formState: { errors },
    } = useForm<DeviceFormInput>({
        resolver: yupResolver(DEVICE_VALIDATION_SCHEMA as any),
    });

    const [getDevice, { data: deviceQuery, loading }] = useFetchDeviceByIdForDeviceModalLazyQuery({
        onCompleted: data => {
            if (data.device) {
                const { externalId, name, photo, docLink } = data.device;
                reset({
                    externalId: externalId ?? '',
                    name: {
                        en: name.en,
                        es: name.es ?? '',
                    },
                    photo: photo ?? '',
                    docLink: {
                        en: docLink?.en ?? '',
                        es: docLink?.es ?? '',
                    },
                });
            }
        },
    });

    const [updateDevice, { loading: updateDeviceLoading }] = useUpdateDeviceFromDevicesPageMutation(
        {
            onCompleted: data => {
                if (data.updateDevice?.success) {
                    setOpen(false);
                    setEditDeviceId('');
                }
            },
        },
    );

    const [createDevice, { loading: createDeviceLoading }] = useCreateDeviceFromDevicesPageMutation(
        {
            onCompleted: data => {
                if (data.createDevice?.success) {
                    setOpen(false);
                }
            },
            update: (cache, response) => {
                if (response.data?.createDevice?.success) {
                    const newDevice = response.data?.createDevice?.resourceCreated;
                    if (newDevice) {
                        const currentData = cache.readQuery<FetchDevicesForDevicesPageQueryQuery>({
                            query: FetchDevicesForDevicesPageQueryDocument,
                        });
                        if (currentData?.devices) {
                            cache.writeQuery<FetchDevicesForDevicesPageQueryQuery>({
                                query: FetchDevicesForDevicesPageQueryDocument,
                                data: {
                                    devices: [newDevice, ...currentData.devices],
                                },
                            });
                        }
                    }
                }
            },
        },
    );

    const onSubmit = ({ externalId, name, photo, docLink }: DeviceFormInput) => {
        if (id) {
            updateDevice({
                variables: {
                    input: {
                        id,
                        data: {
                            externalId,
                            name,
                            photo,
                            docLink,
                        },
                    },
                },
            });
        } else {
            createDevice({
                variables: {
                    input: {
                        externalId,
                        name,
                        photo,
                        ...(docLink.en && { docLink }),
                    },
                },
            });
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setEditDeviceId('');
    };

    useEffect(() => {
        if (id) {
            getDevice({
                variables: {
                    input: {
                        id,
                    },
                },
            });
        }
    }, [id, getDevice]);

    if (loading) {
        return <Loading />;
    }

    if (createDeviceLoading) {
        return <Loading subtitle="Creating device..." />;
    }

    if (updateDeviceLoading) {
        return <Loading subtitle="Updating device..." />;
    }

    return (
        <form className={classes.root} noValidate onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle id="form-dialog-title">
                {deviceQuery?.device?.id === undefined ? 'New Device' : 'Edit Device'}
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            error={!!errors.externalId}
                            label="External Id"
                            id="externalId"
                            type="text"
                            margin="dense"
                            fullWidth
                            {...register('externalId')}
                            helperText={errors.externalId?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            error={!!errors.photo}
                            label="Photo Link"
                            id="photo"
                            type="text"
                            margin="dense"
                            fullWidth
                            {...register('photo')}
                            helperText={errors.photo?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            error={!!errors.name?.en}
                            label="English Name"
                            id="nameEN"
                            type="text"
                            margin="dense"
                            fullWidth
                            {...register('name.en')}
                            helperText={errors.name?.en?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            error={!!errors.name?.es}
                            label="Spanish Name"
                            id="nameES"
                            type="text"
                            margin="dense"
                            fullWidth
                            {...register('name.es')}
                            helperText={errors.name?.es?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            error={!!errors.docLink?.en}
                            label="English Documentation Link"
                            id="docLinkEN"
                            type="text"
                            margin="dense"
                            fullWidth
                            {...register('docLink.en')}
                            helperText={errors.docLink?.en?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            error={!!errors.docLink?.es}
                            label="Spanish Documentation Link"
                            id="docLinkES"
                            type="text"
                            margin="dense"
                            fullWidth
                            {...register('docLink.es')}
                            helperText={errors.docLink?.es?.message}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </form>
    );
};

export default DevicesModal;
