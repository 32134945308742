import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';

const MatchComplete = () => (
    <Grid container margin="0 auto" rowSpacing={8} justifyContent="center">
        <Grid item xs={12}>
            <Typography variant="h3" textAlign="center">
                All Matches Completed
            </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
            <Grid item>
                <Link to="/app-config/eligibility-match">Back to Eligibility Match Config</Link>
            </Grid>
        </Grid>
    </Grid>
);

export default MatchComplete;
