import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { TriggerGlobalAlert } from '~/state';
import { AlertSeverity, useAddNoteFromConsultantMutation } from '~/schemaTypes';
import DateInput from '~/components/DateInput/DateInput';
import NoteYupSchema from './yupSchema';
import useStyles from './styles';
import { AddNoteInput } from './types';
import { dateToISOString } from '../../helpers';

const AddNote = () => {
    const { classes } = useStyles();
    const isMobileView = useMediaQuery('(max-width:900px)');
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
    } = useForm<AddNoteInput>({
        resolver: yupResolver(NoteYupSchema as any),
    });
    const [createNote, { loading }] = useAddNoteFromConsultantMutation({
        onCompleted: data => {
            if (data.addNoteFromConsultant?.status === 404) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: data.addNoteFromConsultant.message,
                });
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'Note was added',
                });
                reset();
            }
        },
        onError: () => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: "Note wasn't added",
            });
        },
    });
    const onSubmit = React.useCallback((data: AddNoteInput) => {
        createNote({
            variables: {
                input: {
                    patientFirstName: data.firstName,
                    patientLastName: data.lastName,
                    patientDOB: dateToISOString(data.birthDate),
                    insuranceId: data.insuranceId,
                    note: data.note,
                    providerName: data.providerName,
                    providerNumber: data.providerNumber,
                    sessionDate: dateToISOString(data.sessionDate),
                },
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Grid container className={classes.container} rowSpacing={2} columns={1}>
            <Grid className={classes.header} item xs={12}>
                <Typography variant="h4" component="h1">
                    Patient Lactation Services Note
                </Typography>
            </Grid>
            <Grid className={classes.content} item xs={12}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container rowSpacing={2} className={classes.contentContainer}>
                        <Grid item md={12}>
                            <Grid container>
                                <Grid item>
                                    <Typography variant="h6" component="h3">
                                        Patient Information
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12}>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Patient First Name"
                                        variant={isMobileView ? 'filled' : 'outlined'}
                                        fullWidth
                                        {...register('firstName')}
                                        error={!!errors.firstName}
                                        helperText={errors.firstName?.message}
                                        {...(isMobileView && { size: 'small' })}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Patient Last Name"
                                        fullWidth
                                        variant={isMobileView ? 'filled' : 'outlined'}
                                        {...register('lastName')}
                                        error={!!errors.lastName}
                                        helperText={errors.lastName?.message}
                                        {...(isMobileView && { size: 'small' })}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name="birthDate"
                                        render={({ field }) => (
                                            <DateInput
                                                label="Patient Date of Birth"
                                                field={field}
                                                error={errors.birthDate}
                                                inputProps={{
                                                    fullWidth: true,
                                                    ...(isMobileView && { size: 'small' }),
                                                    variant: isMobileView ? 'filled' : 'outlined',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Insurance ID"
                                        fullWidth
                                        variant={isMobileView ? 'filled' : 'outlined'}
                                        {...register('insuranceId')}
                                        error={!!errors.insuranceId}
                                        helperText={errors.insuranceId?.message}
                                        {...(isMobileView && { size: 'small' })}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12}>
                            <Typography variant="h6" component="h3">
                                Session Information
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name="sessionDate"
                                        render={({ field }) => (
                                            <DateInput
                                                label="Date of Session"
                                                field={field}
                                                error={errors.sessionDate}
                                                inputProps={{
                                                    fullWidth: true,
                                                    ...(isMobileView && { size: 'small' }),
                                                    variant: isMobileView ? 'filled' : 'outlined',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        label="Note"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        {...register('note')}
                                        error={!!errors.note?.message}
                                        helperText={errors.note?.message}
                                        {...(isMobileView && { size: 'small' })}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12}>
                            <Grid container>
                                <Typography variant="h6" component="h3">
                                    Provider Information
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={12}>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Provider Name"
                                        variant={isMobileView ? 'filled' : 'outlined'}
                                        fullWidth
                                        {...register('providerName')}
                                        error={!!errors.providerName}
                                        helperText={errors.providerName?.message}
                                        {...(isMobileView && { size: 'small' })}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Provider Number (NPI)"
                                        variant={isMobileView ? 'filled' : 'outlined'}
                                        fullWidth
                                        {...register('providerNumber')}
                                        error={!!errors.providerNumber}
                                        helperText={errors.providerNumber?.message}
                                        {...(isMobileView && { size: 'small' })}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            <Grid className={classes.footer} item xs={12}>
                <LoadingButton
                    variant="contained"
                    size={isMobileView ? 'medium' : 'large'}
                    onClick={handleSubmit(onSubmit)}
                    loading={loading}
                >
                    Save
                </LoadingButton>
            </Grid>
        </Grid>
    );
};

export default AddNote;
