import { Grid, Typography } from '@mui/material';
import { CloudDownloadOutlined } from '@mui/icons-material';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React from 'react';
import tableIcons from '~/helpers/tableIcons';
import { OrgTreeNode } from '~/hooks/useOrgTree';
import {
    FetchReportingJobsForReportingJobsPageQuery,
    OrderByDirectionEnum,
    useFetchOrganizationReportByKeyForReportingJobsPageLazyQuery,
    useFetchReportingJobsForReportingJobsPageQuery,
} from '~/schemaTypes';

type OrganizationReportingJobsProps = {
    orgNode: Omit<OrgTreeNode, 'level'>;
};

const getFileNameByResponseName = (resName: string): string => {
    if (resName.includes('/')) {
        return resName.split('/')[1];
    }
    return resName;
};

const OrganizationReportingJobs: React.FC<OrganizationReportingJobsProps> = ({ orgNode }) => {
    const { data, loading } = useFetchReportingJobsForReportingJobsPageQuery({
        variables: {
            input: {
                filter: {
                    fields: {
                        organizationId: orgNode.org.id,
                    },
                },
                orderBy: {
                    field: 'createdAt',
                    order: OrderByDirectionEnum.Desc,
                },
            },
        },
        fetchPolicy: 'network-only',
    });

    const [fetchPatientReportS3ObjectByKey] =
        useFetchOrganizationReportByKeyForReportingJobsPageLazyQuery({
            fetchPolicy: 'network-only',
            onCompleted: res => {
                if (res.getOrganizationReportByKey?.data) {
                    const blob = new Blob([
                        new Uint8Array(res.getOrganizationReportByKey.data as unknown as Buffer)
                            .buffer,
                    ]);
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = res?.getOrganizationReportByKey?.filename
                        ? getFileNameByResponseName(res?.getOrganizationReportByKey?.filename)
                        : 'organization-report.csv';
                    link.click();
                    link.remove();
                }
            },
        });

    const handleDownload = (s3key: string) => {
        fetchPatientReportS3ObjectByKey({
            variables: {
                input: {
                    key: s3key,
                },
            },
        });
    };

    return (
        <div style={{ marginRight: '1rem' }}>
            <Grid spacing={3}>
                <MaterialTable<
                    FetchReportingJobsForReportingJobsPageQuery['reportingJobsV2']['results'][0]
                >
                    icons={tableIcons as MaterialTableProps<any>['icons']}
                    columns={[
                        {
                            title: 'Name',
                            field: 'reportName',
                            render: ({ reportName }) => (
                                <Typography data-test={reportName}>{reportName}</Typography>
                            ),
                        },
                        {
                            title: 'Author',
                            field: 'startedBy',
                            render: ({ startedByName }) => (
                                <Typography data-test={startedByName}>{startedByName}</Typography>
                            ),
                        },
                        {
                            title: 'Date',
                            field: 'createdAt',
                            render: ({ createdAt }) => (
                                <Typography data-test={createdAt}>{createdAt}</Typography>
                            ),
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            render: ({ status }) => (
                                <Typography data-test={status}>{status}</Typography>
                            ),
                        },
                    ]}
                    totalCount={data?.reportingJobsV2.results.length ?? 0}
                    title="Reporting jobs"
                    data={
                        data?.reportingJobsV2.results.map((o: any) => ({
                            ...o,
                        })) || []
                    }
                    actions={[
                        rowData => ({
                            onClick: (_e, item: any) => handleDownload(item.s3key),
                            icon: () => <CloudDownloadOutlined />,
                            tooltip: rowData.s3key ? 'Download' : 'No report',
                            disabled: !rowData.s3key,
                        }),
                    ]}
                    options={{ search: false, paginationPosition: 'bottom' }}
                    isLoading={loading}
                />
            </Grid>
        </div>
    );
};

export default OrganizationReportingJobs;
