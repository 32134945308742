import React from 'react';
import { Editor } from '@tiptap/react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBold,
    faHeading,
    faItalic,
    faLink,
    faLinkSlash,
    faListOl,
    faListUl,
    faParagraph,
    faRotateLeft,
    faRotateRight,
    faStrikethrough,
    faUnderline,
} from '@fortawesome/free-solid-svg-icons';
import { AddPhotoAlternate, HorizontalRule, TextFields } from '@mui/icons-material';
import { primaryColor } from '~/theme/WildTheme';
import { GithubPicker } from 'react-color';
import RichTextEditorMenuBarImageDialog from './RichTextEditorMenuBarImageDialog/RichTextEditorMenuBarImageDialog';
import RichTextEditorMenuBarLinkDialog from './RichTextEditorMenuBarLinkDialog/RichTextEditorMenuBarLinkDialog';

export const FIXED_COLORS = [
    '#D0021B',
    '#F5A623',
    '#F8E71C',
    '#8B572A',
    '#7ED321',
    '#417505',
    '#BD10E0',
    '#9013FE',
    '#4A90E2',
    '#50E3C2',
    '#B8E986',
    '#000000',
    '#4A4A4A',
    '#9B9B9B',
    // colors specific to Anthem branding:
    '#1355E9',
    '#1A3673',
    '#231E33',
    '#FFFFFF',
    '#000000',
    '#3A5D93',
    '#5E7CB2',
    '#087EB3',
    '#21AEE4',
    '#3C4146',
];

const FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 17, 18, 20, 22, 24, 26, 28, 30, 36, 48, 72];

type RichTextEditorMenuBarProps = {
    editor: Editor;
    allowImages: boolean;
    allowLinks: boolean;
};
const RichTextEditorMenuBar = ({ editor, allowImages, allowLinks }: RichTextEditorMenuBarProps) => {
    const [openImageDialog, setOpenImageDialog] = React.useState<boolean>(false);
    const [openLinkDialog, setOpenLinkDialog] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openHeading = Boolean(anchorEl);
    const [anchorElColor, setAnchorElColor] = React.useState<null | HTMLElement>(null);
    const openColor = Boolean(anchorElColor);
    const [anchorElSize, setAnchorElSize] = React.useState<null | HTMLElement>(null);
    const openSize = Boolean(anchorElSize);
    const [highlighted, setHighlighted] = React.useState<string>('');

    const addImage = () => {
        setOpenImageDialog(true);
    };

    const addLink = () => {
        const { state } = editor;
        const { from, to } = state.selection;
        setHighlighted(state.doc.textBetween(from, to));
        setOpenLinkDialog(true);
    };

    const removeLink = () => {
        editor.chain().focus().unsetLink().run();
    };

    const handleOpenHeadingOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseHeadingOptions = () => {
        setAnchorEl(null);
    };

    const handleOpenColorOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElColor(event.currentTarget);
    };

    const handleCloseColorOptions = () => {
        setAnchorElColor(null);
    };

    const handleOpenSizingOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElSize(event.currentTarget);
    };

    const handleCloseSizingOptions = () => {
        setAnchorElSize(null);
    };

    return (
        <>
            <Tooltip title="Bold">
                <IconButton
                    type="button"
                    size="small"
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    disabled={!editor.can().chain().focus().toggleBold().run()}
                    style={editor.isActive('bold') ? { color: primaryColor } : { color: 'grey' }}
                >
                    <FontAwesomeIcon icon={faBold} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Italic">
                <IconButton
                    type="button"
                    size="small"
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    disabled={!editor.can().chain().focus().toggleItalic().run()}
                    style={editor.isActive('italic') ? { color: primaryColor } : { color: 'grey' }}
                >
                    <FontAwesomeIcon icon={faItalic} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Underline">
                <IconButton
                    type="button"
                    size="small"
                    onClick={() => editor.chain().focus().toggleUnderline().run()}
                    disabled={!editor.can().chain().focus().toggleUnderline().run()}
                    style={
                        editor.isActive('underline') ? { color: primaryColor } : { color: 'grey' }
                    }
                >
                    <FontAwesomeIcon icon={faUnderline} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Strike">
                <IconButton
                    type="button"
                    size="small"
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    disabled={!editor.can().chain().focus().toggleStrike().run()}
                    style={editor.isActive('strike') ? { color: primaryColor } : { color: 'grey' }}
                >
                    <FontAwesomeIcon icon={faStrikethrough} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Paragraph">
                <IconButton
                    type="button"
                    size="small"
                    onClick={() => editor.chain().focus().setParagraph().run()}
                    style={
                        editor.isActive('paragraph') ? { color: primaryColor } : { color: 'grey' }
                    }
                >
                    <FontAwesomeIcon icon={faParagraph} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Heading">
                <IconButton
                    type="button"
                    size="small"
                    onClick={handleOpenHeadingOptions}
                    style={editor.isActive('heading') ? { color: primaryColor } : { color: 'grey' }}
                >
                    <FontAwesomeIcon icon={faHeading} />
                </IconButton>
            </Tooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openHeading}
                onClose={handleCloseHeadingOptions}
            >
                <MenuItem
                    style={
                        editor.isActive('heading', { level: 1 })
                            ? { color: primaryColor }
                            : { color: 'grey' }
                    }
                    onClick={() => {
                        editor.chain().focus().toggleHeading({ level: 1 }).run();
                        handleCloseHeadingOptions();
                    }}
                >
                    H1
                </MenuItem>
                <MenuItem
                    style={
                        editor.isActive('heading', { level: 2 })
                            ? { color: primaryColor }
                            : { color: 'grey' }
                    }
                    onClick={() => {
                        editor.chain().focus().toggleHeading({ level: 2 }).run();
                        handleCloseHeadingOptions();
                    }}
                >
                    H2
                </MenuItem>
                <MenuItem
                    style={
                        editor.isActive('heading', { level: 3 })
                            ? { color: primaryColor }
                            : { color: 'grey' }
                    }
                    onClick={() => {
                        editor.chain().focus().toggleHeading({ level: 3 }).run();
                        handleCloseHeadingOptions();
                    }}
                >
                    H3
                </MenuItem>
                <MenuItem
                    style={
                        editor.isActive('heading', { level: 4 })
                            ? { color: primaryColor }
                            : { color: 'grey' }
                    }
                    onClick={() => {
                        editor.chain().focus().toggleHeading({ level: 4 }).run();
                        handleCloseHeadingOptions();
                    }}
                >
                    H4
                </MenuItem>
            </Menu>
            <Tooltip title="Font Size">
                <IconButton type="button" size="small" onClick={handleOpenSizingOptions}>
                    <TextFields />
                </IconButton>
            </Tooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorElSize}
                open={openSize}
                onClose={handleCloseSizingOptions}
                style={{ height: '250px' }}
            >
                {FONT_SIZES.map(size => (
                    <MenuItem
                        key={size}
                        onClick={() => {
                            editor.chain().focus().setFontSize(`${size}px`).run();
                            handleCloseSizingOptions();
                        }}
                    >
                        {size}
                    </MenuItem>
                ))}
            </Menu>
            <Tooltip title="Horizontal Rule">
                <IconButton
                    type="button"
                    size="small"
                    onClick={() => editor.chain().focus().setHorizontalRule().run()}
                    style={
                        editor.isActive('horizontalRule')
                            ? { color: primaryColor }
                            : { color: 'grey' }
                    }
                >
                    <HorizontalRule />
                </IconButton>
            </Tooltip>
            <Tooltip title="Bullet List">
                <IconButton
                    type="button"
                    size="small"
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    style={
                        editor.isActive('bulletList') ? { color: primaryColor } : { color: 'grey' }
                    }
                >
                    <FontAwesomeIcon icon={faListUl} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Ordered List">
                <IconButton
                    type="button"
                    size="small"
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    style={
                        editor.isActive('orderedList') ? { color: primaryColor } : { color: 'grey' }
                    }
                >
                    <FontAwesomeIcon icon={faListOl} />
                </IconButton>
            </Tooltip>
            {allowImages && (
                <Tooltip title="Add image">
                    <IconButton type="button" size="small" onClick={addImage}>
                        <AddPhotoAlternate />
                    </IconButton>
                </Tooltip>
            )}
            {allowLinks && (
                <Tooltip title="Add link">
                    <IconButton type="button" size="small" onClick={addLink}>
                        <FontAwesomeIcon icon={faLink} />
                    </IconButton>
                </Tooltip>
            )}
            {allowLinks && (
                <Tooltip title="Close link">
                    <IconButton type="button" size="small" onClick={removeLink}>
                        <FontAwesomeIcon icon={faLinkSlash} />
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title="Text color">
                <IconButton type="button" size="small" onClick={handleOpenColorOptions}>
                    <span style={{ color: editor.getAttributes('textStyle').color }}>A</span>
                </IconButton>
            </Tooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorElColor}
                open={openColor}
                onClose={handleCloseColorOptions}
            >
                <GithubPicker
                    onChange={color => {
                        editor.chain().focus().setColor(color.hex).run();
                        handleCloseColorOptions();
                    }}
                    colors={FIXED_COLORS}
                />
            </Menu>
            <Tooltip title="Undo">
                <span>
                    <IconButton
                        type="button"
                        size="small"
                        onClick={() => editor.chain().focus().undo().run()}
                        disabled={!editor.can().chain().focus().undo().run()}
                    >
                        <FontAwesomeIcon icon={faRotateLeft} />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Redo">
                <span>
                    <IconButton
                        type="button"
                        size="small"
                        onClick={() => editor.chain().focus().redo().run()}
                        disabled={!editor.can().chain().focus().redo().run()}
                    >
                        <FontAwesomeIcon icon={faRotateRight} />
                    </IconButton>
                </span>
            </Tooltip>
            {openImageDialog && (
                <RichTextEditorMenuBarImageDialog
                    editor={editor}
                    open={openImageDialog}
                    setOpen={setOpenImageDialog}
                />
            )}
            {openLinkDialog && (
                <RichTextEditorMenuBarLinkDialog
                    editor={editor}
                    open={openLinkDialog}
                    setOpen={setOpenLinkDialog}
                    label={highlighted}
                />
                // eslint-disable-next-line react/jsx-no-comment-textnodes
            )}
        </>
    );
};

export default RichTextEditorMenuBar;
