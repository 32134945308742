export enum AdminUserEnum {
    NAV_ADMIN_USERS = 'admin-users',
    CONTAINER = 'admin-users-container',
    MODAL = 'admin-users-modal',
    NAME = 'modal-name',
    TITLE = 'modal-title',
    EMAIL = 'modal-email',
    SAVE_CREATE = 'modal-save-create',
}

export const AdminUserSelectors = {
    NAV_ADMIN_USERS: `a[data-test="${AdminUserEnum.NAV_ADMIN_USERS}"]`,
    ADD_ADMIN_USER_BUTTON: `div[data-test="${AdminUserEnum.CONTAINER}"] button[title="Add Admin User"]`,
    EDIT_ADMIN_USER_BUTTON: `div[data-test="${AdminUserEnum.CONTAINER}"] button[title="Edit Admin User"]`,
    DELETE_ADMIN_USER_BUTTON: `div[data-test="${AdminUserEnum.CONTAINER}"] button[title="Delete Admin User"]`,
    MODAL: `form[data-test="${AdminUserEnum.MODAL}"]`,
    NAME_INPUT: `div[data-test="${AdminUserEnum.NAME}"] input`,
    TITLE_INPUT: `div[data-test="${AdminUserEnum.TITLE}"] input`,
    EMAIL_INPUT: `div[data-test="${AdminUserEnum.EMAIL}"] input`,
    SAVE_CREATE_BUTTON: `button[data-test="${AdminUserEnum.SAVE_CREATE}"]`,
    SEARCH_INPUT: `div[data-test="${AdminUserEnum.CONTAINER}"] input[placeholder="Search"]`,
};
