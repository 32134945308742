import ObjectId from 'bson-objectid';
import { ClientSelectionType } from '~/enums/enums';
import { CarePlanFormInput } from '../types';

export const defaultFormValues = (): Partial<CarePlanFormInput> => ({
    clientSelectionType: ClientSelectionType.IncludeAllApps,
    includeApps: [],
    excludeApps: [],
    tags: [],
    groups: [
        {
            id: new ObjectId(),
            label: { en: '' },
            templates: [
                {
                    title: { en: 'Test' },
                    isRecurring: false,
                    isEssential: false,
                    articleId: '',
                    priority: 1,
                    notificationData: {
                        shouldSendNotification: false,
                        en: '',
                        es: '',
                    },
                    tags: [],
                },
            ],
            suppressedTemplates: [],
        },
    ],
});
