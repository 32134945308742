import React from 'react';
import { Grid, Divider } from '@mui/material';

type FooterDividerProps = {
    isMobileView: boolean;
    bottomPadding?: number;
};

const FooterDivider: React.FC<FooterDividerProps> = props => {
    const { isMobileView, bottomPadding = 2 } = props;
    return (
        <Grid container>
            <Grid
                item
                xs={12}
                paddingTop={isMobileView ? 0 : 3}
                paddingBottom={isMobileView ? 0 : bottomPadding}
            >
                <Divider variant="middle" />
            </Grid>
        </Grid>
    );
};

export default FooterDivider;
