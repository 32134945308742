/* eslint-disable camelcase */
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    Card,
    CardHeader,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowBack, Save } from '@mui/icons-material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    ArticleListDocument,
    ArticleListQuery,
    CallToActionInput,
    ContentVariantInput,
    CtaType,
    Language,
    ReadingLevel,
    TagListQuery,
    TagUsageType,
    useArticleByIdLazyQuery,
    usePreFetchAppsQuery,
    useTagListQuery,
    useUpsertArticleVariantMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { ListItem } from '~/views/ConfigDashboard/Articles/Editors/ArticleEditor';
import ImageContentPicker from '~/components/ImageContentPicker/ImageContentPicker';
import { useUser } from '~/hooks';
import RichTextEditor from '~/components/RichTextEditor/RichTextEditor';
import { toBase64 } from '~/helpers/base64Helper';
import CTAModal from '../components/CTAModal';

type Tag = NonNullable<TagListQuery['getTagListByUsageTypes'][0]>;

const useStyles = makeStyles()(theme => ({
    root: {},
    fab: {
        position: 'absolute',
        top: theme.spacing(12),
        right: theme.spacing(4),
    },
}));

const ctaTypeName = (type: CtaType): string => {
    switch (type) {
        case CtaType.AddToQList:
            return 'Add to QList';
        case CtaType.OpenExternalLink:
            return 'Open External Link';
        case CtaType.OpenInternalLink:
            return 'Open Internal Link';
        case CtaType.PopUpMessage:
            return 'Pop-up Message';
        case CtaType.TapToCall:
            return 'Tap to Call';
        case CtaType.TapToCallPopUp:
            return 'Tap to Call Pop-up';
        default:
            return 'Unknown';
    }
};
export interface CTA {
    ctaId: string;
    ctaName: string;
    tagId?: string | undefined;
    tagNames?: string[];
}
export interface CtaModalParms {
    tags: Tag[];
    closeHandler: () => void;
    submitHandler: (
        isEditMode: boolean,
        cta: CallToActionInput,
        tagList?: ListItem[],
        contentName?: string,
    ) => void;
    ctaData?: { cta: CallToActionInput; tagList?: ListItem[]; contentName?: string } | null;
}

const VariantEditor: React.FC = () => {
    const history = useNavigate();
    const { classes } = useStyles();
    const { data: userData } = useUser();
    const [selectedImageId, setSelectedImageId] = useState<string>();
    const [isModified, setIsModified] = useState(false);
    const [currentVariant, setCurrentVariant] = useState<ContentVariantInput | undefined>();
    const [callToActions, setCallToActions] = useState<
        {
            cta: CallToActionInput;
            tagList?: ListItem[];
            contentName?: string;
        }[]
    >([]);
    const [selectedCTA, setSelectedCTA] = useState<{
        cta: CallToActionInput;
        tagNames?: string[];
        contentName?: string;
    } | null>(null);

    const [close, setClose] = useState(false);
    const [titleMarkup, setTitleMarkup] = useState<string>();
    const [summaryMarkup, setSummaryMarkup] = useState<string>();
    const [bodyMarkup, setBodyMarkup] = useState<string>();
    const [ctaModalOpen, setCtaModalOpen] = useState(false);
    const [variantExists, setVariantExists] = useState(false);
    const [selectedLang, setSelectedLang] = useState<Language>(Language.En);
    const [selectedReadingLevel, setSelectedReadingLevel] = useState<ReadingLevel>(
        ReadingLevel.Eighth,
    );
    const [selectedApp, setSelectedApp] = useState<string | null>(null);
    const { articleId, lang, rl, app } = useParams<{
        articleId: string;
        lang: string;
        rl: string;
        app: string;
    }>();
    const isEditMode = lang !== 'new';
    const appBundleId = app === 'null' ? null : atob(app || '');
    const [articleLoaded, setArticleLoaded] = useState<boolean>(false);

    const { data: tagData, loading: tagsLoading } = useTagListQuery({
        variables: { tagListInput: [TagUsageType.Filtering, TagUsageType.PatientRiskScore] },
    });

    const [loadArticle, { data: articleData, loading: articleLoading }] = useArticleByIdLazyQuery({
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        onCompleted: ({ article }) => {
            if (article) {
                const variant = article.variants?.find(
                    v =>
                        v.language === lang &&
                        v.readingLevel === rl &&
                        v.appBundleId === appBundleId,
                );
                if (isEditMode && variant) {
                    setCurrentVariant(_.cloneDeep(variant));
                    setSelectedLang(variant.language);
                    setSelectedReadingLevel(variant.readingLevel);
                    setSelectedApp(variant.appBundleId ?? null);
                    setSelectedImageId(variant.imageContentId);
                    if (variant.callToActions) {
                        setCallToActions(
                            variant.callToActions.map(c => ({
                                cta: {
                                    id: c.id,
                                    type: c.type,
                                    tagList: c.tagList,
                                    simpleContentId: c.simpleContentId,
                                    parameter1: { en: c.parameter1?.en, es: c.parameter1?.es },
                                    parameter2: { en: c.parameter2?.en, es: c.parameter2?.es },
                                    buttonText: { en: c.buttonText?.en, es: c.buttonText?.es },
                                },
                                tagList: c.tagList?.map(tag => ({
                                    id: tag,
                                    name:
                                        tagData?.getTagListByUsageTypes.find(t => t.id === tag)
                                            ?.name || 'Tag not found',
                                })),
                                contentName: c.simpleContent?.name || '',
                            })),
                        );
                    }
                    setTitleMarkup(variant.titleMarkup || '');
                    setSummaryMarkup(variant.summaryMarkup || '');
                    setBodyMarkup(variant.bodyMarkup || '');
                }
            }
            setArticleLoaded(true);
        },
    });
    useEffect(() => {
        if (tagData?.getTagListByUsageTypes) {
            loadArticle({ variables: { input: { id: articleId } } });
        }
    }, [loadArticle, articleId, tagData?.getTagListByUsageTypes]);

    const { data: appList, loading: appListLoading } = usePreFetchAppsQuery();

    const handleCtaModalSubmit = (
        isEditMode: boolean,
        cta: CallToActionInput,
        tagList?: ListItem[],
        contentName?: string,
    ) => {
        if (isEditMode) {
            const editedCallToActions = callToActions.map(action => {
                if (action.cta.id === cta.id) {
                    return {
                        cta,
                        tagList,
                        contentName,
                    };
                }
                return action;
            });
            setCallToActions(editedCallToActions);
        } else {
            callToActions.push({ cta, tagList, contentName });
            setCallToActions(callToActions);
        }
        setCtaModalOpen(false);
        setSelectedCTA(null);
        setIsModified(true);
    };
    const handleCtaModalClose = () => {
        setCtaModalOpen(false);
        setSelectedCTA(null);
    };
    const handleTitleChange = (markup: string) => {
        setTitleMarkup(markup);
        setIsModified(true);
    };
    const handleSummaryChange = (markup: string) => {
        setSummaryMarkup(markup);
        setIsModified(true);
    };
    const handleBodyChange = (markup: string) => {
        setBodyMarkup(markup);
        setIsModified(true);
    };
    const [upsertVariant, { loading: updateLoading }] = useUpsertArticleVariantMutation({
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    setIsModified(true);
                    setClose(false);
                },
                message: `There was a problem saving the article: ${error.message}`,
            });
        },
        onCompleted: () => {
            setIsModified(false);
            if (close) {
                history(`/app-config/articles/${articleId}`);
            } else {
                history(
                    `/app-config/variants/${articleId}/${selectedLang}/${selectedReadingLevel}/${
                        selectedApp ? btoa(selectedApp) : null
                    }`,
                );
            }
        },
        update: (cache, response) => {
            const updatedArticle = response.data?.upsertArticleVariant?.updatedArticle;
            if (response.data?.upsertArticleVariant?.success && updatedArticle) {
                const currentArticles = cache.readQuery<ArticleListQuery>({
                    query: ArticleListDocument,
                });
                if (currentArticles?.articlesV2) {
                    cache.writeQuery<ArticleListQuery>({
                        query: ArticleListDocument,
                        data: {
                            articlesV2: {
                                ...currentArticles.articlesV2,
                                results: [
                                    ...currentArticles.articlesV2.results.map(article => {
                                        if (article.id === updatedArticle.id) {
                                            return updatedArticle;
                                        }
                                        return article;
                                    }),
                                ],
                            },
                        },
                    });
                }
            }
        },
    });

    const onSubmit = () => {
        if (articleData?.article) {
            setVariantExists(false);
            if (!isEditMode) {
                if (
                    articleData.article &&
                    articleData.article.variants &&
                    articleData.article.variants.length > 0
                ) {
                    const check = articleData.article.variants.find(
                        v =>
                            v.readingLevel === selectedReadingLevel &&
                            v.language === selectedLang &&
                            v.appBundleId === selectedApp,
                    );
                    if (check) {
                        setVariantExists(true);
                        return;
                    }
                }
            }
            if (articleId) {
                const newVariant: ContentVariantInput = {
                    language: selectedLang,
                    readingLevel: selectedReadingLevel,
                    appBundleId: selectedApp === '' ? null : selectedApp,
                    imageContentId: selectedImageId || undefined,
                    callToActions: callToActions.map(c => c.cta),
                    datePublished: currentVariant?.datePublished,
                    dateEdited: new Date(),
                    editedBy: userData?.currentUser?.name,
                    titleMarkup: toBase64(titleMarkup),
                    summaryMarkup: toBase64(summaryMarkup),
                    bodyMarkup: toBase64(bodyMarkup),
                };
                upsertVariant({
                    variables: { articleId, variant: newVariant },
                });
            }
        }
    };

    const onEdit = () => {
        setIsModified(true);
        setVariantExists(false);
    };
    const onNavigateAway = () => {
        if (isModified)
            TriggerGlobalConfirm({
                message: `You have unsaved changes. Are you sure you want to return to the article page?`,
                callback: () => {
                    history(`/app-config/articles/${articleId}`);
                },
            });
        else history(`/app-config/articles/${articleId}`);
    };

    const handleCtaEdit = (id: string) => {
        const [editedCTA] = callToActions.filter(callToAction => callToAction.cta.id === id);
        setSelectedCTA({ ...editedCTA });
        setCtaModalOpen(true);
    };

    const handleCtaDelete = (id: string) => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete this Call to Action?`,
            callback: () => {
                const newCallToActions = callToActions.filter(a => a.cta.id !== id);
                setCallToActions(newCallToActions);
                setIsModified(true);
                setSelectedCTA(null);
            },
        });
    };
    if (articleLoading || updateLoading || tagsLoading || appListLoading || !articleLoaded) {
        return <Loading />;
    }

    return (
        <>
            {tagData && ctaModalOpen && (
                <CTAModal
                    submitHandler={handleCtaModalSubmit}
                    closeHandler={handleCtaModalClose}
                    tags={tagData.getTagListByUsageTypes}
                    ctaData={selectedCTA}
                />
            )}
            {isModified && (
                <div
                    style={{
                        color: 'orange',
                        fontSize: '2em',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        width: '100%',
                        backgroundColor: 'grey',
                    }}
                >
                    You have unsaved changes
                </div>
            )}
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12}>
                    <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                        Back to Article
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={`Variant for ${articleData?.article?.name}`} />
                        <OutlinedSection title="Variant Type *">
                            <div>
                                <span>
                                    Language:{' '}
                                    <select
                                        onChange={e => {
                                            onEdit();
                                            setSelectedLang(e.target.value as Language);
                                        }}
                                        disabled={currentVariant !== undefined}
                                        value={selectedLang}
                                    >
                                        <option key={Language.En} value={Language.En}>
                                            English
                                        </option>
                                        <option key={Language.Es} value={Language.Es}>
                                            Spanish
                                        </option>
                                    </select>
                                </span>
                                <span style={{ marginLeft: '10px' }}>
                                    Reading Level:{' '}
                                    <select
                                        onChange={e => {
                                            onEdit();
                                            setSelectedReadingLevel(e.target.value as ReadingLevel);
                                        }}
                                        disabled={currentVariant !== undefined}
                                        value={selectedReadingLevel}
                                    >
                                        <option key="5" value={ReadingLevel.Fifth}>
                                            Fifth Grade
                                        </option>
                                        <option key="6" value={ReadingLevel.Eighth}>
                                            Eighth Grade
                                        </option>
                                    </select>
                                </span>
                                <span style={{ marginLeft: '10px' }}>
                                    App:{' '}
                                    <select
                                        onChange={e => {
                                            onEdit();
                                            setSelectedApp(e.target.value);
                                        }}
                                        disabled={currentVariant !== undefined}
                                        value={selectedApp ?? ''}
                                    >
                                        <option key="1" value="">
                                            All Apps
                                        </option>
                                        {appList?.applicationsV2.results.map(a => (
                                            <option key={a.appBundleId} value={a.appBundleId}>
                                                {a.appBundleId}
                                            </option>
                                        ))}
                                    </select>
                                </span>
                            </div>
                            {variantExists && (
                                <div style={{ fontWeight: 'bold', color: 'red' }}>
                                    Variant for selected Language, Reading Level and App already
                                    exists.
                                </div>
                            )}
                        </OutlinedSection>
                        <ImageContentPicker
                            selectedImageContentId={selectedImageId}
                            setSelectedImageContentId={setSelectedImageId}
                            onEdit={onEdit}
                        />

                        <OutlinedSection title="Calls To Action">
                            <Button
                                onClick={() => {
                                    setCtaModalOpen(true);
                                }}
                            >
                                Add Call To Action
                            </Button>
                            {callToActions.length > 0 && (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell>Type</TableCell>
                                            <TableCell>Button text</TableCell>
                                            <TableCell />
                                            <TableCell>Tags</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {callToActions.map(a => (
                                            <TableRow>
                                                <TableCell>
                                                    <Button
                                                        title="Edit"
                                                        onClick={() => {
                                                            handleCtaEdit(a.cta.id);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faPenToSquare} />
                                                    </Button>
                                                    <Button
                                                        title="Delete"
                                                        onClick={() => {
                                                            handleCtaDelete(a.cta.id);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </TableCell>
                                                <TableCell>{ctaTypeName(a.cta.type)}</TableCell>
                                                <TableCell>{a.cta.buttonText?.en}</TableCell>
                                                <TableCell>{a.contentName}</TableCell>
                                                <TableCell>
                                                    {a.tagList?.map(t => t.name).join(', ')}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </OutlinedSection>
                    </Card>
                    <RichTextEditor
                        initialValue={titleMarkup}
                        onChange={value => handleTitleChange(value)}
                        label="Title Markup"
                        placeholder="Enter Title Markup"
                    />
                    <RichTextEditor
                        initialValue={summaryMarkup}
                        onChange={value => handleSummaryChange(value)}
                        label="Summary Markup"
                        placeholder="Enter Summary Markup"
                    />
                    <RichTextEditor
                        initialValue={bodyMarkup}
                        onChange={value => handleBodyChange(value)}
                        label="Body Markup"
                        placeholder="Enter Body Markup"
                    />
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        <Button
                            aria-label="save"
                            type="submit"
                            startIcon={<Save />}
                            color="secondary"
                            variant="contained"
                            onClick={onSubmit}
                        >
                            Save
                        </Button>
                        <Button
                            aria-label="saveandclose"
                            type="submit"
                            startIcon={<Save />}
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setClose(true);
                                onSubmit();
                            }}
                        >
                            Save &amp; Close
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default VariantEditor;
