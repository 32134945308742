import { CoverageAreaZipCodesType as ZipCodesType } from '~/schemaTypes';

export enum FormMode {
    View,
    AddManually,
    AddByCityState,
    AddByAddress,
}

export { ZipCodesType };
