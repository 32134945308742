import React from 'react';
import { Grid, Typography } from '@mui/material';

type ConfirmationProps = {
    classes: Record<string, string>;
};

const Confirmation: React.FC<ConfirmationProps> = ({ classes }) => {
    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            flexDirection="row"
            className={classes.confirmationContainer}
            alignContent="flex-start"
            flex={1}
        >
            <Grid item xs={10}>
                <Typography variant="h5" color="primary" style={{ textAlign: 'center' }}>
                    Thank you!
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <Typography variant="body1" style={{ textAlign: 'center' }}>
                    Based on the information you provided, SimpliFed will reach out to you to
                    schedule an appointment. In the meantime if you have any questions, please reach
                    out to (email here).
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Confirmation;
