import { PatientTodoTaskQueryQuery } from '~/schemaTypes';
import { HA_CREATED, TODO_TYPE } from '../enums';

type PatientTodoTask = NonNullable<PatientTodoTaskQueryQuery['patientProxiedTodoTask']>;

export const determineTodoType = (todo: PatientTodoTask): TODO_TYPE => {
    // ha created or careplan generated
    const isHACreated = todo.createdBy === HA_CREATED;
    const isCompleted = todo.isComplete;
    // the most restricted type by default
    const defType = TODO_TYPE.CPCreatedCompleted;

    if (isHACreated) {
        if (isCompleted) {
            return TODO_TYPE.HACreatedCompleted;
        }
        return TODO_TYPE.HACreatedUpcoming;
    }
    if (!isHACreated) {
        if (isCompleted) {
            return TODO_TYPE.CPCreatedCompleted;
        }
        return TODO_TYPE.CPCreatedUpcoming;
    }

    return defType;
};
