import { ClaimSubmittedStatus } from '~/schemaTypes';

export const OUMA_ORG_ID = '6446f71a7dfb8695804af26c';
export const SIMPLIFED_ORG_ID = '64ed23874b384a37ac0d2506';

export const INCOMPLETE_FILTER = [
    ClaimSubmittedStatus.InProgress,
    ClaimSubmittedStatus.Ready,
    ClaimSubmittedStatus.Error,
] as ClaimSubmittedStatus[];
