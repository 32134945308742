import React, { memo } from 'react';
import {
    Grid,
    Stepper,
    Step,
    StepLabel,
    Box,
    StepIconProps,
    Typography,
    StepProps,
    useMediaQuery,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useOnboarding } from '~/views/AffiliateCare/Onboarding/useOboarding';
import { Steps } from '../types';

const ICON_PATH = '/affiliate-onboarding/';

const useStyles = makeStyles()(() => ({
    image: {
        objectFit: 'contain',
        width: '100%',
        height: '100%',
    },
    stepper: {
        '.MuiStepConnector-root': {
            top: 50,
            left: 'calc(-50% + 50px)',
            right: 'calc(50% + 50px)',
        },
        '.MuiStep-root': {
            ':first-of-type': {
                marginLeft: 'calc(-12% + 50px)',
            },
            ':last-of-type': {
                marginRight: 'calc(-12% + 50px)',
            },
            '.Mui-active *': {
                fontWeight: 'bold',
            },
        },
    },
    mobileStepper: {
        '.MuiStepLabel-root': {
            display: 'flex',
            flexDirection: 'column',
            lineHeight: '24px',
            '.MuiStepLabel-labelContainer': {
                marginTop: 10,
                '.Mui-active *': {
                    fontWeight: 'bold',
                },
            },
        },
    },
}));

const Icon = memo((props: { step: Steps; isActive: boolean }) => {
    const { step, isActive } = props;
    const { classes } = useStyles();
    const path = `${ICON_PATH}${step + 1}${!isActive ? '-mono' : ''}.png`;
    return (
        <Box height={100} width={100}>
            <img className={classes.image} src={path} alt={`Step ${step + 1}`} />
        </Box>
    );
});

const ConsultantIcon = ({ active }: StepIconProps) => (
    <Icon step={Steps.Consultants} isActive={!!active} />
);
const PaymentIcon = ({ active }: StepIconProps) => (
    <Icon step={Steps.Payment} isActive={!!active} />
);
const LocationsIcon = ({ active }: StepIconProps) => (
    <Icon step={Steps.ServiceAndLocations} isActive={!!active} />
);
const RoutingIcon = ({ active }: StepIconProps) => (
    <Icon step={Steps.Routing} isActive={!!active} />
);

const CustomStep = ({
    title,
    icon,
    ...props
}: {
    title: string;
    icon: React.ElementType<StepIconProps>;
} & StepProps) => {
    return (
        <Step {...props}>
            <StepLabel StepIconComponent={icon}>
                <Typography variant="subtitle1" textAlign="center">
                    {title}
                </Typography>
            </StepLabel>
        </Step>
    );
};

const StepTitles = {
    [Steps.Consultants]: 'Add Lactation Consultants',
    [Steps.Routing]: 'Create Routing Customization',
    [Steps.Payment]: 'Add Payment Information',
    [Steps.ServiceAndLocations]: 'Add Service Types & Locations',
};

const StepIcons = {
    [Steps.Consultants]: ConsultantIcon,
    [Steps.Routing]: RoutingIcon,
    [Steps.Payment]: PaymentIcon,
    [Steps.ServiceAndLocations]: LocationsIcon,
};

const Progress = () => {
    const { currentStep } = useOnboarding();
    const { classes } = useStyles();
    const isMobileView = useMediaQuery('(max-width:900px)');
    const stepLabel = StepTitles[currentStep];
    const stepIcon = StepIcons[currentStep];
    return isMobileView ? (
        <Grid item xs={12} container justifyContent="center">
            <Grid className={classes.mobileStepper} item>
                <CustomStep title={stepLabel} icon={stepIcon} active />
            </Grid>
        </Grid>
    ) : (
        <Grid item xs={12}>
            <Stepper activeStep={currentStep} alternativeLabel className={classes.stepper}>
                <CustomStep
                    title={StepTitles[Steps.Consultants]}
                    key={Steps.Consultants}
                    icon={StepIcons[Steps.Consultants]}
                />
                <CustomStep
                    title={StepTitles[Steps.Payment]}
                    key={Steps.Payment}
                    icon={StepIcons[Steps.Payment]}
                />
                <CustomStep
                    title={StepTitles[Steps.ServiceAndLocations]}
                    key={Steps.ServiceAndLocations}
                    icon={StepIcons[Steps.ServiceAndLocations]}
                />
                <CustomStep
                    title={StepTitles[Steps.Routing]}
                    key={Steps.Routing}
                    icon={StepIcons[Steps.Routing]}
                />
            </Stepper>
        </Grid>
    );
};

export default Progress;
