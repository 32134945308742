import { FetchAdvocateTaskForAdvocateTaskPageQuery } from '~/schemaTypes';
import { CommentAuthor } from '../Comments/types';

type ICommentAuthor = NonNullable<
    FetchAdvocateTaskForAdvocateTaskPageQuery['advocateTask']
>['commentAuthors'][0];

type ToCommentAuthorsForm = (commentAuthors: ICommentAuthor[]) => CommentAuthor[];

const toCommentAuthorsForm: ToCommentAuthorsForm = commentAuthors =>
    commentAuthors.map(({ id, name }) => ({
        id,
        name,
    }));

export default toCommentAuthorsForm;
