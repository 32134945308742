import React from 'react';
import { Editor, FloatingMenu } from '@tiptap/react';
import { Props } from 'tippy.js';
import { IconButton, Menu, MenuItem, Paper, Tooltip } from '@mui/material';
import { faHeading } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { primaryColor } from '~/theme/WildTheme';

const RichTextEditorFloatingMenu = ({
    editor,
    tippyOptions,
}: {
    editor: Editor;
    tippyOptions?: Partial<Props> | undefined;
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleOpenHeadingOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseHeadingOptions = () => {
        setAnchorEl(null);
    };

    return (
        <FloatingMenu editor={editor} tippyOptions={tippyOptions}>
            <Paper elevation={2} sx={{ padding: '4px', marginLeft: '8px' }}>
                <Tooltip title="Heading">
                    <IconButton
                        type="button"
                        size="small"
                        onClick={handleOpenHeadingOptions}
                        style={
                            editor.isActive('heading') ? { color: primaryColor } : { color: 'grey' }
                        }
                    >
                        <FontAwesomeIcon icon={faHeading} />
                    </IconButton>
                </Tooltip>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseHeadingOptions}
                >
                    <MenuItem
                        style={
                            editor.isActive('heading', { level: 1 })
                                ? { color: primaryColor }
                                : { color: 'grey' }
                        }
                        onClick={() => {
                            editor.chain().focus().toggleHeading({ level: 1 }).run();
                            handleCloseHeadingOptions();
                        }}
                    >
                        H1
                    </MenuItem>
                    <MenuItem
                        style={
                            editor.isActive('heading', { level: 2 })
                                ? { color: primaryColor }
                                : { color: 'grey' }
                        }
                        onClick={() => {
                            editor.chain().focus().toggleHeading({ level: 2 }).run();
                            handleCloseHeadingOptions();
                        }}
                    >
                        H2
                    </MenuItem>
                    <MenuItem
                        style={
                            editor.isActive('heading', { level: 3 })
                                ? { color: primaryColor }
                                : { color: 'grey' }
                        }
                        onClick={() => {
                            editor.chain().focus().toggleHeading({ level: 3 }).run();
                            handleCloseHeadingOptions();
                        }}
                    >
                        H3
                    </MenuItem>
                    <MenuItem
                        style={
                            editor.isActive('heading', { level: 4 })
                                ? { color: primaryColor }
                                : { color: 'grey' }
                        }
                        onClick={() => {
                            editor.chain().focus().toggleHeading({ level: 4 }).run();
                            handleCloseHeadingOptions();
                        }}
                    >
                        H4
                    </MenuItem>
                </Menu>
            </Paper>
        </FloatingMenu>
    );
};

export default RichTextEditorFloatingMenu;
