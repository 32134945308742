import MaterialTable from '@material-table/core';
import { Add } from '@mui/icons-material';
import { Dialog, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import { ChatSlasV2ForChatSlasPageQuery, useChatSlasV2ForChatSlasPageQuery } from '~/schemaTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import ChatSlaModal from './ChatSlaModal/ChatSlaModal';
import { ChatSlaFormInput } from './ChatSlaModal/ChatSlaModal.formValidation';

type ChatSla = NonNullable<ChatSlasV2ForChatSlasPageQuery['chatSlasV2']>['results'][0];

export const ChatSlas = () => {
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [selectedId, setSelectedId] = React.useState<string>('');
    const [selectedChatSla, setSelectedChatSla] = React.useState<ChatSlaFormInput | null>(null);
    const [showSlaModal, setShowSlaModal] = useState<boolean>(false);

    const { data: chatSlaData, loading } = useChatSlasV2ForChatSlasPageQuery();

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div ref={tableRef}>
            <MaterialTable<ChatSla>
                title="Chat Sla"
                icons={tableIcons}
                isLoading={loading}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                data={_.cloneDeep(chatSlaData?.chatSlasV2.results) ?? []}
                actions={[
                    {
                        onClick: () => {
                            setSelectedId('');
                            setSelectedChatSla(null);
                            setShowSlaModal(true);
                        },
                        hidden: !pagePermissions?.Sla.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Chat Sla',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, chatSla: ChatSla) => {
                            const {
                                name,
                                timerType,
                                responseCalculation,
                                timeToComplete,
                                calendarId,
                                appBundleId,
                                careTeamMemberTypeId,
                                assignedToId,
                            } = chatSla;
                            setSelectedId(chatSla.id);
                            setSelectedChatSla({
                                name,
                                timerType,
                                responseCalculation,
                                timeToComplete,
                                calendarId,
                                appBundleId,
                                careTeamMemberTypeId,
                                assignedToId,
                                // notifyBefore: sla.notifyBefore?.map(time => {
                                //     return {
                                //         whenToNotify: time,
                                //     };
                                // }),
                            });
                            setShowSlaModal(true);
                        },
                        hidden: !pagePermissions?.Sla.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit SLA',
                    },
                ]}
                columns={[
                    {
                        title: 'Name',
                        field: 'name',
                        defaultSort: 'asc',
                        render: ({ name }) => <Typography>{name}</Typography>,
                    },
                    {
                        title: 'App Bundle Id',
                        field: 'appBundleId',
                    },
                    {
                        title: 'Time to complete',
                        field: 'timeToComplete',
                        render: ({ timeToComplete, timerType }) => (
                            <Typography>
                                {timeToComplete} {timerType}
                            </Typography>
                        ),
                    },
                    {
                        title: 'Timer Type',
                        field: 'timerType',
                    },
                    {
                        title: 'Type',
                        field: 'type',
                    },
                    {
                        title: 'Response Calculation',
                        field: 'responseCalculation',
                    },
                ]}
                options={{ pageSize: rowsPerPage }}
                localization={{ header: { actions: '' } }}
            />
            <Dialog scroll="body" open={showSlaModal} fullWidth maxWidth="lg">
                <ChatSlaModal
                    setOpen={setShowSlaModal}
                    id={selectedId}
                    defaultSLAValues={selectedChatSla}
                />
            </Dialog>
        </div>
    );
};
