import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    ReferencePages,
    useCareTeamsForCareTeamsPageQuery,
    useFetchReferenceLinksForCareTeamsPageQuery,
} from '~/schemaTypes';

type CareTeamsProps = {
    setLinks?: any;
};

const CareTeams: React.FC<CareTeamsProps> = ({ setLinks }) => {
    const history = useNavigate();
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);

    const { data: careTeamsData, loading } = useCareTeamsForCareTeamsPageQuery();

    const { data: refereneLinksData, loading: refereneLinksLoading } =
        useFetchReferenceLinksForCareTeamsPageQuery({
            variables: {
                input: {
                    page: ReferencePages.CareTeams,
                },
            },
        });

    useEffect(() => {
        if (refereneLinksData && setLinks) {
            setLinks(refereneLinksData.getReferenceLinksForPage);
        }
    }, [refereneLinksData, setLinks]);

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div ref={tableRef}>
            <MaterialTable
                title="Care Teams"
                icons={tableIcons}
                isLoading={loading || refereneLinksLoading}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                data={_.cloneDeep(careTeamsData?.careTeamsV2?.results) ?? []}
                actions={[
                    {
                        onClick: () => history('/app-config/care-teams/new'),
                        hidden: !pagePermissions?.CareTeams.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Care Team',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => history(`/app-config/care-teams/${id}`),
                        hidden: !pagePermissions?.CareTeams.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Care Team',
                    },
                ]}
                columns={[
                    { title: 'Name', field: 'name' },
                    { title: 'App Bundle ID', field: 'appBundleId' },
                ]}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100] }}
                localization={{ header: { actions: '' } }}
            />
        </div>
    );
};

export default CareTeams;
