import { gql } from '@apollo/client';

export default gql`
    extend type Query {
        GlobalAlert: GlobalAlert!
        GlobalConfirm: GlobalConfirm!
        GlobalLoading: GlobalLoading!
        GlobalImageUpload: GlobalImageUpload!
        GlobalImagePreview: GlobalImagePreview!
        GlobalBanner: GlobalBanner!
    }
    enum AlertSeverity {
        success
        info
        warning
        error
    }
    type GlobalLoading {
        message: String!
        open: Boolean!
    }
    type GlobalAlert {
        message: String!
        open: Boolean!
        severity: AlertSeverity!
    }
    type GlobalConfirm {
        open: Boolean!
        message: String!
        description: String
    }
    type GlobalImageUpload {
        open: Boolean!
        isImage: Boolean!
    }
    type GlobalImagePreview {
        open: Boolean!
        imageSrc: String!
    }
    type GlobalBanner {
        open: Boolean!
        message: String!
        actionText: String!
        severity: AlertSeverity!
    }
    query FetchBannerState {
        GlobalBanner @client {
            open
            message
            actionText
            severity
        }
    }

    query FetchAlertState {
        GlobalAlert @client {
            message
            open
            severity
        }
        GlobalConfirm @client {
            open
            message
            description
        }
        GlobalImageUpload @client {
            open
            isImage
        }
        GlobalImagePreview @client {
            open
            imageSrc
        }
    }

    query FetchSettings {
        settings {
            cognitoPoolId
            cognitoClientId
        }
    }
`;
