import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    sideBarContainer: {
        marginTop: 10,
    },
    sideBarItem: {
        marginBottom: 10,
        '& div': {
            fontSize: 14,
        },
        '& .MuiFormControl-root': {
            padding: '5px 0px',
            width: '100%',
        },
    },
    selectMenuItem: {
        fontSize: 14,
    },
    createdBy: {
        fontWeight: 'lighter',
    },
    taskCreator: {
        fontWeight: 'bolder',
    },
});
