import { makeStyles } from 'tss-react/mui';
import React from 'react';

const useStyles = makeStyles()(theme => ({
    root: {
        width: 'calc(100% - 20px)',
        border: '1px solid black',
        position: 'relative',
        padding: '15px 12px 12px',
        borderRadius: '4px',
        margin: '10px',
    },
    outlinePrimary: {
        borderColor: theme.colors.Primary(),
    },
    outlineSecondary: {
        borderColor: theme.palette.action.disabled,
    },
    title: {
        position: 'absolute',
        backgroundColor: 'white',
        top: '-10px',
        left: '12px',
        padding: '1px',
    },
}));

type OutlinedSectionProps = {
    title: string;
    className?: string;
    children: React.ReactNode;
    outlineColor?: 'primary' | 'secondary';
    dataTest?: string;
};

const OutlinedSection: React.FC<OutlinedSectionProps> = ({
    title,
    className,
    children,
    outlineColor = 'primary',
    dataTest,
}) => {
    const { classes } = useStyles();

    return (
        <div
            className={`${classes.root} ${
                outlineColor === 'secondary' ? classes.outlineSecondary : classes.outlinePrimary
            } ${className}`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(dataTest && {
                'data-test': dataTest,
            })}
        >
            <span className={classes.title}>{title}</span>
            {children}
        </div>
    );
};

export default OutlinedSection;
