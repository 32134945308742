import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    OrderByDirectionEnum,
    useGetAdvocateTaskCheckListChangesForPatientTranscriptPageQuery,
    useGetChatMessagesForPatientTranscriptQuery,
    useGetPatientNotesForPatientTranscriptPageQuery,
    useGetProfileValueHistoriesByPatientIdForPatientTranscriptQuery,
    useGetResolvedTagsForPatientTranscriptQuery,
    useGetSentMessageByPatientIdForPatientTranscriptQuery,
    useGetSurveyResponsesForPatientTranscriptQuery,
    useGetTrackerHistoryForPatientTranscriptQuery,
    useGetTriggerInterventionStartEpisodesForPatientTranscriptQuery,
    useGetTriggerInterventionEndEpisodesForPatientTranscriptQuery,
    useGetTriggerInterventionSetProfileValueForPatientTranscriptQuery,
    useGetTriggerInterventionSendMessageCenterForPatientTranscriptQuery,
    useGetTriggerInterventionCreateAdvocateTaskForPatientTranscriptQuery,
    useGetExpressionEvaluatedForPatientTranscriptQuery,
    useGetTriggerFiredEventsForPatientTranscriptQuery,
    useGetTriggerInterventionClearProfileValueForPatientTranscriptQuery,
    useGetTriggerInterventionClearProfileChoiceForPatientTranscriptQuery,
} from '~/schemaTypes';
import { displayDateWithLocalTimeZoneAbbr } from '~/helpers';
import { TrackerCard } from './TranscriptCards/TrackerCard/TrackerCard';
import { ProfileValueCard } from './TranscriptCards/ProfileValueCard/ProfileValueCard';
import { PatientNoteCard } from './TranscriptCards/PatientNoteCard/PatientNoteCard';
import { AdvocateTaskCard } from './TranscriptCards/AdvocateTaskCard/AdvocateTaskCard';
import { SurveyResponseCard } from './TranscriptCards/SurveyResponseCard/SurveyResponseCard';
import { ChatMessageCard } from './TranscriptCards/ChatMessageCard/ChatMessageCard';
import { SentMessageCard } from './TranscriptCards/SentMessageCard/SentMessageCard';
import { ResolvedTagEventCard } from './TranscriptCards/ResolvedTagEventCard/ResolvedTagEventCard';
import { TriggerInterventionStartEpisodeCard } from './TranscriptCards/TriggerInterventionCards/TriggerInterventionStartEpisodeCard';
import { TriggerInterventionEndEpisodeCard } from './TranscriptCards/TriggerInterventionCards/TriggerInterventionEndEpisodeCard';
import { TriggerInterventionSetProfileValueCard } from './TranscriptCards/TriggerInterventionCards/TriggerInterventionSetProfileValueCard';
import { TriggerInterventionSendMessageCenterCard } from './TranscriptCards/TriggerInterventionCards/TriggerInterventionSendMessageCenterCard';
import { TriggerInterventionCreateAdvocateTaskCard } from './TranscriptCards/TriggerInterventionCards/TriggerInterventionCreateAdvocateTaskCard';
import { ExpressionEvaluatedCard } from './TranscriptCards/TriggerInterventionCards/ExpressionEvaluatedCard';
import { TriggerFiredEventCard } from './TranscriptCards/TriggerInterventionCards/TriggerFiredEventCard';
import { TriggerInterventionClearProfileValueCard } from './TranscriptCards/TriggerInterventionCards/TriggerInterventionClearProfileValueCard';
import { TriggerInterventionClearProfileChoiceCard } from './TranscriptCards/TriggerInterventionCards/TriggerInterventionClearProfileChoiceCard';

const CARD_TYPES = [
    'Tracker',
    'ProfileValue',
    'PatientNotes',
    'AdvocateTasks',
    'SurveyResponse',
    'ChatMessages',
    'SentComms',
    'ResolvedTagEvent',
    'TriggerFiredEvent',
    'ExpressionEvaluated',
    'TriggerInterventionStartEpisode',
    'TriggerInterventionEndEpisode',
    'TriggerInterventionSetProfileValue',
    'TriggerInterventionSendMessageCenter',
    'TriggerInterventionCreateAdvocateTask',
    'TriggerInterventionClearProfileValue',
    'TriggerInterventionClearProfileChoice',
] as const;

type CardTypes = { [P in (typeof CARD_TYPES)[number]]: boolean };

type RouteParams = {
    id: string;
};

export const PatientTranscript = () => {
    const { id: patientId } = useParams<RouteParams>();
    const [cardTypes, setCardTypes] = useState<CardTypes>(() => {
        const savedCardTypes = localStorage.getItem('patientTranscriptCardTypes');
        return savedCardTypes
            ? JSON.parse(savedCardTypes)
            : {
                  Tracker: true,
                  ProfileValue: true,
                  PatientNotes: true,
              };
    });
    const [dateRange, setDateRange] = useState<string>(() => {
        return localStorage.getItem('patientTranscriptDateRange') || 'lastDay';
    });

    const dateRangeFilter = useMemo(() => {
        const range: { gte: Date | null; lte: Date | null } = {
            gte: null,
            lte: null,
        };

        if (dateRange) {
            const now = new Date();
            switch (dateRange) {
                case 'lastDay':
                    range.gte = new Date(new Date().setDate(now.getDate() - 1));
                    range.lte = now;
                    break;
                case 'last3Days':
                    range.gte = new Date(new Date().setDate(now.getDate() - 3));
                    range.lte = now;
                    break;
                case 'last7Days':
                    range.gte = new Date(new Date().setDate(now.getDate() - 7));
                    range.lte = now;
                    break;
                case 'last30Days':
                    range.gte = new Date(new Date().setDate(now.getDate() - 30));
                    range.lte = now;
                    break;
                // case 'custom':
                //     range.gte = parsedFilters.customStartDate
                //         ? new Date(parsedFilters.customStartDate)
                //         : null;
                //     range.lte = parsedFilters.customEndDate
                //         ? new Date(parsedFilters.customEndDate)
                //         : null;
                //     break;
                default:
                    break;
            }
        }

        return {
            ...(range.gte ? { greaterThanDate: range.gte } : {}),
            ...(range.lte ? { lessThanDate: range.lte } : {}),
        };
    }, [dateRange]);

    const { data: trackerHistory, loading: loadingTrackerHistory } =
        useGetTrackerHistoryForPatientTranscriptQuery({
            variables: {
                input: {
                    orderBy: {
                        field: 'createdAt',
                        order: OrderByDirectionEnum.Desc,
                    },
                    patientId,
                    types: ['BloodPressureTrackerRecord', 'GlucoseTrackerRecord'],
                    ...dateRangeFilter,
                },
            },
        });

    const { data: profileValueHistory, loading: loadingProfileValueHistory } =
        useGetProfileValueHistoriesByPatientIdForPatientTranscriptQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const { data: patientNotes, loading: loadingPatientNotes } =
        useGetPatientNotesForPatientTranscriptPageQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const { data: advocateTasks, loading: loadingAdvocateTasks } =
        useGetAdvocateTaskCheckListChangesForPatientTranscriptPageQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
            fetchPolicy: 'no-cache', // Cannot use cache because steps from same task templates have same stepId which causes steps to override each other.
        });

    const { data: surveyResponses, loading: loadingSurveyResponses } =
        useGetSurveyResponsesForPatientTranscriptQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const { data: chatMessages, loading: loadingChatMessages } =
        useGetChatMessagesForPatientTranscriptQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const { data: sentMessages, loading: loadingSentMessages } =
        useGetSentMessageByPatientIdForPatientTranscriptQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const { data: resolvedTagEvents, loading: loadingResolvedTagEvents } =
        useGetResolvedTagsForPatientTranscriptQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const {
        data: triggerInterventionStartEpisodes,
        loading: loadingTriggerInterventionStartEpisodes,
    } = useGetTriggerInterventionStartEpisodesForPatientTranscriptQuery({
        variables: {
            input: {
                patientId,
                ...dateRangeFilter,
            },
        },
    });

    const { data: triggerInterventionEndEpisodes, loading: loadingTriggerInterventionEndEpisodes } =
        useGetTriggerInterventionEndEpisodesForPatientTranscriptQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const {
        data: triggerInterventionSetProfileValue,
        loading: loadingTriggerInterventionSetProfileValue,
    } = useGetTriggerInterventionSetProfileValueForPatientTranscriptQuery({
        variables: {
            input: {
                patientId,
                ...dateRangeFilter,
            },
        },
    });

    const {
        data: triggerInterventionSendMessageCenter,
        loading: loadingTriggerInterventionSendMessageCenter,
    } = useGetTriggerInterventionSendMessageCenterForPatientTranscriptQuery({
        variables: {
            input: {
                patientId,
                ...dateRangeFilter,
            },
        },
    });

    const {
        data: triggerInterventionCreateAdvocateTask,
        loading: loadingTriggerInterventionCreateAdvocateTask,
    } = useGetTriggerInterventionCreateAdvocateTaskForPatientTranscriptQuery({
        variables: {
            input: {
                patientId,
                ...dateRangeFilter,
            },
        },
    });

    const { data: expressionEvaluated, loading: loadingExpressionEvaluated } =
        useGetExpressionEvaluatedForPatientTranscriptQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const { data: triggerFiredEvents, loading: loadingTriggerFiredEvents } =
        useGetTriggerFiredEventsForPatientTranscriptQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const {
        data: triggerInterventionClearProfileValue,
        loading: loadingTriggerInterventionClearProfileValue,
    } = useGetTriggerInterventionClearProfileValueForPatientTranscriptQuery({
        variables: {
            input: {
                patientId,
                ...dateRangeFilter,
            },
        },
    });

    const {
        data: triggerInterventionClearProfileChoice,
        loading: loadingTriggerInterventionClearProfileChoice,
    } = useGetTriggerInterventionClearProfileChoiceForPatientTranscriptQuery({
        variables: {
            input: {
                patientId,
                ...dateRangeFilter,
            },
        },
    });

    const Cards = useMemo(() => {
        return [
            ...(cardTypes.Tracker
                ? trackerHistory?.getTrackerHistoryForPatientTranscript || []
                : []),
            ...(cardTypes.ProfileValue
                ? profileValueHistory?.getProfileValueHistoriesByPatientId || []
                : []),
            ...(cardTypes.PatientNotes
                ? patientNotes?.getPatientNotesForPatientTranscript || []
                : []),
            ...(cardTypes.AdvocateTasks
                ? advocateTasks?.getAdvocateTaskCheckListChangesForPatientTranscript || []
                : []),
            ...(cardTypes.SurveyResponse
                ? surveyResponses?.getSurveyResponsesForPatientTranscript || []
                : []),
            ...(cardTypes.ChatMessages
                ? chatMessages?.getChatMessagesForPatientTranscript || []
                : []),
            ...(cardTypes.SentComms ? sentMessages?.getSentMessageByPatientId || [] : []),
            ...(cardTypes.ResolvedTagEvent
                ? resolvedTagEvents?.getResolvedTagsForPatientTranscript || []
                : []),
            ...(cardTypes.TriggerInterventionStartEpisode
                ? triggerInterventionStartEpisodes?.getTriggerInterventionStartEpisodesForPatientTranscript ||
                  []
                : []),
            ...(cardTypes.TriggerInterventionEndEpisode
                ? triggerInterventionEndEpisodes?.getTriggerInterventionEndEpisodesForPatientTranscript ||
                  []
                : []),
            ...(cardTypes.TriggerInterventionSetProfileValue
                ? triggerInterventionSetProfileValue?.getTriggerInterventionSetProfileValueForPatientTranscript ||
                  []
                : []),
            ...(cardTypes.TriggerInterventionSendMessageCenter
                ? triggerInterventionSendMessageCenter?.getTriggerInterventionSendMessageCenterForPatientTranscript ||
                  []
                : []),
            ...(cardTypes.TriggerInterventionCreateAdvocateTask
                ? triggerInterventionCreateAdvocateTask?.getTriggerInterventionCreateAdvocateTaskForPatientTranscript ||
                  []
                : []),
            ...(cardTypes.ExpressionEvaluated
                ? expressionEvaluated?.getExpressionEvaluatedForPatientTranscript || []
                : []),
            ...(cardTypes.TriggerFiredEvent
                ? triggerFiredEvents?.getTriggerFiredEventsForPatientTranscript || []
                : []),
            ...(cardTypes.TriggerInterventionClearProfileValue
                ? triggerInterventionClearProfileValue?.getTriggerInterventionClearProfileValueForPatientTranscript ||
                  []
                : []),
            ...(cardTypes.TriggerInterventionClearProfileChoice
                ? triggerInterventionClearProfileChoice?.getTriggerInterventionClearProfileChoiceForPatientTranscript ||
                  []
                : []),
        ].sort((a, b) =>
            new Date(a?.createdAt).getTime() >= new Date(b?.createdAt).getTime() ? -1 : 1,
        );
    }, [
        trackerHistory?.getTrackerHistoryForPatientTranscript,
        profileValueHistory?.getProfileValueHistoriesByPatientId,
        patientNotes?.getPatientNotesForPatientTranscript,
        advocateTasks?.getAdvocateTaskCheckListChangesForPatientTranscript,
        surveyResponses?.getSurveyResponsesForPatientTranscript,
        chatMessages?.getChatMessagesForPatientTranscript,
        sentMessages?.getSentMessageByPatientId,
        resolvedTagEvents?.getResolvedTagsForPatientTranscript,
        triggerInterventionStartEpisodes?.getTriggerInterventionStartEpisodesForPatientTranscript,
        triggerInterventionEndEpisodes?.getTriggerInterventionEndEpisodesForPatientTranscript,
        triggerInterventionSetProfileValue?.getTriggerInterventionSetProfileValueForPatientTranscript,
        triggerInterventionSendMessageCenter?.getTriggerInterventionSendMessageCenterForPatientTranscript,
        triggerInterventionCreateAdvocateTask?.getTriggerInterventionCreateAdvocateTaskForPatientTranscript,
        cardTypes.Tracker,
        cardTypes.ProfileValue,
        cardTypes.PatientNotes,
        cardTypes.AdvocateTasks,
        cardTypes.SurveyResponse,
        cardTypes.ChatMessages,
        cardTypes.SentComms,
        cardTypes.ResolvedTagEvent,
        cardTypes.TriggerInterventionStartEpisode,
        cardTypes.TriggerInterventionEndEpisode,
        cardTypes.TriggerInterventionSetProfileValue,
        cardTypes.TriggerInterventionSendMessageCenter,
        cardTypes.TriggerInterventionCreateAdvocateTask,
        expressionEvaluated?.getExpressionEvaluatedForPatientTranscript,
        triggerFiredEvents?.getTriggerFiredEventsForPatientTranscript,
        triggerInterventionClearProfileValue?.getTriggerInterventionClearProfileValueForPatientTranscript,
        triggerInterventionClearProfileChoice?.getTriggerInterventionClearProfileChoiceForPatientTranscript,
        cardTypes.ExpressionEvaluated,
        cardTypes.TriggerFiredEvent,
        cardTypes.TriggerInterventionClearProfileValue,
        cardTypes.TriggerInterventionClearProfileChoice,
    ]);

    return (
        <div className="gap-2 flex flex-col">
            <div className="flex flex-row items-center justify-between">
                <Typography variant="h5">Patient Transcript</Typography>
                <div className="flex flex-row gap-2">
                    <FormControl fullWidth variant="standard">
                        <Select
                            labelId="card-types-label"
                            id="card-types-select"
                            variant="outlined"
                            multiple
                            value={CARD_TYPES.filter(type => cardTypes[type as keyof CardTypes])}
                            onChange={e => {
                                const selectedTypes = e.target.value as string[];
                                const newCardTypes = CARD_TYPES.reduce((acc, type) => {
                                    acc[type as keyof CardTypes] = selectedTypes.includes(type);
                                    return acc;
                                }, {} as CardTypes);
                                setCardTypes(newCardTypes);
                                localStorage.setItem(
                                    'patientTranscriptCardTypes',
                                    JSON.stringify(newCardTypes),
                                );
                            }}
                            renderValue={values => {
                                if (values.length === 0) {
                                    return <em>Select card types</em>;
                                }
                                if (values.length === 1) {
                                    return values[0];
                                }
                                return `${values[0]}...`;
                            }}
                            displayEmpty
                        >
                            {CARD_TYPES.map(type => (
                                <MenuItem key={type} value={type}>
                                    <Checkbox checked={cardTypes[type as keyof CardTypes]} />
                                    <ListItemText primary={type} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="date-range-label">Date Range</InputLabel>
                        <Select
                            className="w-[150px]"
                            labelId="date-range-label"
                            value={dateRange}
                            label="Date Range"
                            onChange={e => {
                                localStorage.setItem('patientTranscriptDateRange', e.target.value);
                                setDateRange(e.target.value);
                            }}
                        >
                            <MenuItem value="lastDay">Last Day</MenuItem>
                            <MenuItem value="last3Days">Last 3 Days</MenuItem>
                            <MenuItem value="last7Days">Last 7 Days</MenuItem>
                            <MenuItem value="last30Days">Last 30 Days</MenuItem>
                            {/* <MenuItem value="custom">Custom Range</MenuItem> */}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] h-full w-full p-8 rounded-md">
                {loadingTrackerHistory && cardTypes.Tracker && (
                    <Typography variant="body1">Loading tracker data...</Typography>
                )}
                {loadingProfileValueHistory && cardTypes.ProfileValue && (
                    <Typography variant="body1">Loading profile value data...</Typography>
                )}
                {loadingPatientNotes && cardTypes.PatientNotes && (
                    <Typography variant="body1">Loading patient notes data...</Typography>
                )}
                {loadingAdvocateTasks && cardTypes.AdvocateTasks && (
                    <Typography variant="body1">Loading advocate task data...</Typography>
                )}
                {loadingSurveyResponses && cardTypes.SurveyResponse && (
                    <Typography variant="body1">Loading survey response data...</Typography>
                )}
                {loadingChatMessages && cardTypes.ChatMessages && (
                    <Typography variant="body1">Loading chat message data...</Typography>
                )}
                {loadingSentMessages && cardTypes.SentComms && (
                    <Typography variant="body1">Loading sent comms data...</Typography>
                )}
                {loadingResolvedTagEvents && cardTypes.ResolvedTagEvent && (
                    <Typography variant="body1">Loading resolved tag events...</Typography>
                )}
                {loadingTriggerInterventionStartEpisodes &&
                    cardTypes.TriggerInterventionStartEpisode && (
                        <Typography variant="body1">
                            Loading trigger intervention start episodes...
                        </Typography>
                    )}
                {loadingTriggerInterventionEndEpisodes &&
                    cardTypes.TriggerInterventionEndEpisode && (
                        <Typography variant="body1">
                            Loading trigger intervention end episodes...
                        </Typography>
                    )}
                {loadingTriggerInterventionSetProfileValue &&
                    cardTypes.TriggerInterventionSetProfileValue && (
                        <Typography variant="body1">
                            Loading trigger intervention set profile value...
                        </Typography>
                    )}
                {loadingTriggerInterventionSendMessageCenter &&
                    cardTypes.TriggerInterventionSendMessageCenter && (
                        <Typography variant="body1">
                            Loading trigger intervention send message center...
                        </Typography>
                    )}
                {loadingTriggerInterventionCreateAdvocateTask &&
                    cardTypes.TriggerInterventionCreateAdvocateTask && (
                        <Typography variant="body1">
                            Loading trigger intervention create advocate task...
                        </Typography>
                    )}
                {loadingExpressionEvaluated && cardTypes.ExpressionEvaluated && (
                    <Typography variant="body1">Loading expression evaluated events...</Typography>
                )}
                {loadingTriggerFiredEvents && cardTypes.TriggerFiredEvent && (
                    <Typography variant="body1">Loading trigger fired events...</Typography>
                )}
                {loadingTriggerInterventionClearProfileValue &&
                    cardTypes.TriggerInterventionClearProfileValue && (
                        <Typography variant="body1">
                            Loading trigger intervention clear profile value...
                        </Typography>
                    )}
                {loadingTriggerInterventionClearProfileChoice &&
                    cardTypes.TriggerInterventionClearProfileChoice && (
                        <Typography variant="body1">
                            Loading trigger intervention clear profile choice...
                        </Typography>
                    )}
                {Cards.map(card => (
                    <div className="w-[600px]" key={card?.id}>
                        {card?.__typename === 'TrackerHistory' && <TrackerCard tracker={card} />}
                        {card?.__typename === 'ProfileValueHistory' && (
                            <ProfileValueCard profileValue={card} />
                        )}
                        {card?.__typename === 'PatientNotes' && (
                            <PatientNoteCard patientNote={card} />
                        )}
                        {card?.__typename === 'AdvocateTask' && (
                            <AdvocateTaskCard advocateTask={card} />
                        )}
                        {card?.__typename === 'SurveyResponse' && (
                            <SurveyResponseCard surveyResponse={card} />
                        )}
                        {card?.__typename === 'ChatMessage' && (
                            <ChatMessageCard chatMessage={card} />
                        )}
                        {card?.__typename === 'SentMessage' && (
                            <SentMessageCard sentMessage={card} />
                        )}
                        {card?.__typename === 'ResolvedTagEvent' && (
                            <ResolvedTagEventCard resolvedTagEvent={card} />
                        )}
                        {card?.__typename === 'TriggerInterventionStartEpisodeEvent' && (
                            <TriggerInterventionStartEpisodeCard intervention={card} />
                        )}
                        {card?.__typename === 'TriggerInterventionEndEpisodeEvent' && (
                            <TriggerInterventionEndEpisodeCard intervention={card} />
                        )}
                        {card?.__typename === 'TriggerInterventionSetProfileValueEvent' && (
                            <TriggerInterventionSetProfileValueCard intervention={card} />
                        )}
                        {card?.__typename === 'TriggerInterventionSendMessageCenterEvent' && (
                            <TriggerInterventionSendMessageCenterCard intervention={card} />
                        )}
                        {card?.__typename === 'TriggerInterventionCreateAdvocateTaskEvent' && (
                            <TriggerInterventionCreateAdvocateTaskCard intervention={card} />
                        )}
                        {card?.__typename === 'ExpressionEvaluatedEvent' && (
                            <ExpressionEvaluatedCard event={card} />
                        )}
                        {card?.__typename === 'TriggerFiredEvent' && (
                            <TriggerFiredEventCard event={card} />
                        )}
                        {card?.__typename === 'TriggerInterventionClearProfileValueEvent' && (
                            <TriggerInterventionClearProfileValueCard intervention={card} />
                        )}
                        {card?.__typename === 'TriggerInterventionClearProfileChoiceEvent' && (
                            <TriggerInterventionClearProfileChoiceCard intervention={card} />
                        )}
                        <p className="flex justify-end italic">
                            {displayDateWithLocalTimeZoneAbbr({
                                isoDateStr: new Date(card?.createdAt).toISOString(),
                            })}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};
