import React, { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Grid } from '@mui/material';
import ToggleButtonSwitch from '~/components/ToggleButtonSwitch/ToggleButtonSwitch';
import {
    ClaimSubmittedConsultType,
    ClaimSubmittedVisitType,
    ClaimSubmittedBirthType,
    ClaimSubmittedVisitReason,
} from '~/schemaTypes';
import DateInput from '~/components/DateInput/DateInput';
import { DoulaClaim, VisitType, InPersonLocationType, LocationType } from '../types';
import TimeRange from '../TimeRange';

const DoulaForm = () => {
    const {
        control,
        setValue,
        watch,
        formState: { errors },
        resetField,
    } = useFormContext<DoulaClaim>();
    const visitType = watch('visitType');
    const location = watch('location');
    const inPersonLocation = watch('inPersonLocation');
    const birthType = watch('birthType');
    useEffect(() => {
        if (inPersonLocation) {
            const serviceType =
                inPersonLocation === InPersonLocationType.Home
                    ? ClaimSubmittedVisitType.Home
                    : ClaimSubmittedVisitType.Office;
            setValue('serviceType', serviceType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inPersonLocation]);
    useEffect(() => {
        switch (birthType) {
            case ClaimSubmittedBirthType.Home:
                setValue('serviceType', ClaimSubmittedVisitType.Home);
                break;
            case ClaimSubmittedBirthType.Hospital:
                setValue('serviceType', ClaimSubmittedVisitType.Hospital);
                break;
            case ClaimSubmittedBirthType.Center:
                setValue('serviceType', ClaimSubmittedVisitType.Center);
                break;
            default:
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [birthType]);
    useEffect(() => {
        resetField('location');
        resetField('consultType');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visitType]);
    useEffect(() => {
        if (location === LocationType.Virtual) {
            setValue('serviceType', ClaimSubmittedVisitType.Virtual);
        } else {
            setValue('serviceType', '');
        }
        resetField('inPersonLocation');
        resetField('birthType');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    useEffect(() => {
        if (birthType !== ClaimSubmittedBirthType.Hospital) {
            resetField('admissionFrom');
            resetField('admissionTo');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [birthType]);
    useEffect(() => {
        if (visitType !== VisitType.Consult) {
            resetField('endTime');
            resetField('startTime');
            resetField('duration');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visitType]);
    return (
        <>
            <Grid item xs={7}>
                <Controller
                    render={({ field: { value, onChange } }) => (
                        <ToggleButtonSwitch
                            label="Visit Type"
                            onChange={(_, value) => {
                                if (value !== null) {
                                    onChange(value);
                                }
                                switch (value) {
                                    case VisitType.Birth:
                                        setValue('visitReason', ClaimSubmittedVisitReason.Birth);
                                        break;
                                    case VisitType.Consult:
                                        setValue('visitReason', ClaimSubmittedVisitReason.Consult);
                                        break;
                                    default:
                                        setValue('visitReason', '');
                                }
                            }}
                            buttons={[
                                { label: 'Consult', value: VisitType.Consult },
                                { label: 'Birth', value: VisitType.Birth },
                            ]}
                            value={value}
                            error={errors.visitType?.message}
                        />
                    )}
                    name="visitType"
                    control={control}
                />
            </Grid>
            {visitType === VisitType.Consult && (
                <Grid item xs={7}>
                    <Controller
                        render={({ field: { value, onChange } }) => (
                            <ToggleButtonSwitch
                                label="Consult Type"
                                onChange={(_, value) => {
                                    if (value !== null) {
                                        onChange(value);
                                    }
                                }}
                                buttons={[
                                    {
                                        label: 'Prenatal',
                                        value: ClaimSubmittedConsultType.Prenatal,
                                    },
                                    {
                                        label: 'Postpartum',
                                        value: ClaimSubmittedConsultType.Postpartum,
                                    },
                                ]}
                                value={value}
                                error={errors.consultType?.message}
                            />
                        )}
                        name="consultType"
                        control={control}
                    />
                </Grid>
            )}
            <Grid item xs={7}>
                <Controller
                    render={({ field: { value, onChange } }) => (
                        <ToggleButtonSwitch
                            label="Location"
                            onChange={(_, value) => {
                                if (value !== null) {
                                    onChange(value);
                                }
                            }}
                            buttons={[
                                { label: 'Virtual', value: LocationType.Virtual },
                                { label: 'In Person', value: LocationType.InPerson },
                            ]}
                            value={value}
                            error={errors.location?.message}
                        />
                    )}
                    name="location"
                    control={control}
                />
            </Grid>
            {visitType === VisitType.Consult && location === LocationType.InPerson && (
                <Grid item xs={7}>
                    <Controller
                        render={({ field: { value, onChange } }) => (
                            <ToggleButtonSwitch
                                label="In-Person Location"
                                onChange={(_, value) => {
                                    if (value !== null) {
                                        onChange(value);
                                    }
                                }}
                                buttons={[
                                    { label: 'Home', value: InPersonLocationType.Home },
                                    { label: 'Office', value: InPersonLocationType.Office },
                                ]}
                                value={value}
                                error={errors.inPersonLocation?.message}
                            />
                        )}
                        name="inPersonLocation"
                        control={control}
                    />
                </Grid>
            )}
            {visitType === VisitType.Birth && location === LocationType.InPerson && (
                <Grid item xs={11}>
                    <Controller
                        render={({ field: { value, onChange } }) => (
                            <ToggleButtonSwitch
                                label="In-Person Location"
                                onChange={(_, value) => {
                                    if (value !== null) {
                                        onChange(value);
                                    }
                                }}
                                buttons={[
                                    { label: 'Hospital', value: ClaimSubmittedBirthType.Hospital },
                                    { label: 'Home', value: ClaimSubmittedBirthType.Home },
                                    {
                                        label: 'Birthing Center',
                                        value: ClaimSubmittedBirthType.Center,
                                    },
                                ]}
                                value={value}
                                error={errors.birthType?.message}
                            />
                        )}
                        name="birthType"
                        control={control}
                    />
                </Grid>
            )}
            {visitType === VisitType.Consult && (
                <Grid item xs={7}>
                    <TimeRange />
                </Grid>
            )}
            {visitType === VisitType.Birth && birthType === ClaimSubmittedBirthType.Hospital && (
                <>
                    <Grid item xs={7}>
                        <Controller
                            name="admissionFrom"
                            control={control}
                            render={({ field }) => (
                                <DateInput
                                    label="Admission Date"
                                    field={field}
                                    error={errors.admissionFrom}
                                    inputProps={{
                                        fullWidth: true,
                                        InputLabelProps: {
                                            shrink: true,
                                        },
                                        FormHelperTextProps: {
                                            sx: {
                                                whiteSpace: 'nowrap',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={7}>
                        <Controller
                            name="admissionTo"
                            control={control}
                            render={({ field }) => (
                                <DateInput
                                    label="Discharge Date"
                                    field={field}
                                    error={errors.admissionTo}
                                    inputProps={{
                                        fullWidth: true,
                                        InputLabelProps: {
                                            shrink: true,
                                        },
                                        FormHelperTextProps: {
                                            sx: {
                                                whiteSpace: 'nowrap',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </>
            )}
        </>
    );
};

export default DoulaForm;
