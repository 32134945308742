import * as Yup from 'yup';
import { ResponseCalculationEnum, SlaTimerTypeEnum, SlaTypeEnum } from '~/schemaTypes';

export const SLA_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    type: Yup.mixed<SlaTypeEnum>()
        .oneOf(Object.values(SlaTypeEnum))
        .required('SLA type is required.'),
    timerType: Yup.mixed<SlaTimerTypeEnum>()
        .oneOf(Object.values(SlaTimerTypeEnum))
        .required('SLA timer type is required.'),
    responseCalculation: Yup.mixed<ResponseCalculationEnum>()
        .oneOf(Object.values(ResponseCalculationEnum))
        .required('SLA response calculation type is required.'),
    timeToComplete: Yup.number().min(0).required('time to complete is required'),
    calendarId: Yup.string().required('calendar is required'),
    // notifyBefore: Yup.array()
    //     .of(
    //         Yup.object().shape({
    //             whenToNotify: Yup.number().min(0),
    //         }),
    //     )
    //     .notRequired(),
});

export type SlaFormInput = {
    name: string;
    type: SlaTypeEnum;
    timerType: SlaTimerTypeEnum;
    responseCalculation: ResponseCalculationEnum;
    timeToComplete: number;
    calendarId: string;
    // notifyBefore?: {
    //     whenToNotify: number;
    // }[];
};
