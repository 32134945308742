import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, IconButton, Tooltip } from '@mui/material';
import { Add, InfoOutlined } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import copy from 'copy-to-clipboard';
import { useUserPermissions } from '~/hooks';
import {
    AlertSeverity,
    ReportingCategoryListDocument,
    ReportingCategoryListQuery,
    useDeleteReportingCategoryMutation,
    useReportingCategoryListQuery,
    useReportingCategoryWhereUsedLazyQuery,
} from '~/schemaTypes';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import { ReportingCategoryModal } from './components/ReportingCategoryModal';
import { useStyles } from './styles';

type ReportingCategory = NonNullable<ReportingCategoryListQuery['reportingCategorys'][0]>;

const ReportingCategories: React.FC = () => {
    const { classes } = useStyles();
    const { data, loading } = useReportingCategoryListQuery();
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedReportingCategory, setSelectedReportingCategory] =
        useState<ReportingCategory | null>(null);
    const [showUsageModal, setShowUsageModal] = useState(false);
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        useReportingCategoryWhereUsedLazyQuery();
    const [deleteItem, { loading: deleteItemLoading }] = useDeleteReportingCategoryMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: ReportingCategoryListDocument,
                variables: {},
            },
        ],
    });
    const handleDelete = () => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${selectedReportingCategory?.name}'?`,
            callback: () => {
                deleteItem({ variables: { id: selectedReportingCategory?.id } });
                setShowUsageModal(false);
            },
        });
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleShowUsage = (item: ReportingCategory) => {
        whereUsed({
            variables: {
                id: item?.id,
            },
        });
        setShowUsageModal(true);
    };

    return (
        <>
            <div className={classes.root} ref={tableRef}>
                <MaterialTable<ReportingCategory>
                    title="Reporting Categories"
                    icons={tableIcons}
                    isLoading={loading || whereUsedLoading || deleteItemLoading}
                    data={_.cloneDeep(data?.reportingCategorys) ?? []}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    actions={[
                        {
                            onClick: () => setModalOpen(true),
                            icon: () => <Add />,
                            tooltip: 'Add Reporting Category',
                            isFreeAction: true,
                            hidden: !pagePermissions?.ReportingCategory.Edit,
                        },
                        {
                            onClick: (_, item) => {
                                const isSingleItemSelected = !Array.isArray(item);
                                if (isSingleItemSelected) {
                                    setModalOpen(true);
                                    setSelectedReportingCategory(item);
                                }
                            },
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit Reporting Category',
                            hidden: !pagePermissions?.ReportingCategory.Edit,
                        },
                        {
                            onClick: (_e, item) => {
                                setSelectedReportingCategory(item as ReportingCategory);
                                handleShowUsage(item as ReportingCategory);
                            },
                            icon: () => <FontAwesomeIcon icon={faEye} />,
                            tooltip: 'View References',
                        },
                    ]}
                    columns={[
                        {
                            title: 'ID',
                            field: 'id',
                            render: ({ id }) => (
                                <Tooltip title={id}>
                                    <IconButton
                                        onClick={() => {
                                            copy(`${id}`);
                                            TriggerGlobalAlert({
                                                message: 'Id copied to clipboard',
                                                severity: AlertSeverity.Success,
                                            });
                                        }}
                                        size="large"
                                    >
                                        <InfoOutlined />
                                    </IconButton>
                                </Tooltip>
                            ),
                        },
                        {
                            title: 'Name',
                            field: 'name',
                            defaultSort: 'asc',
                        },
                    ]}
                    options={{ pageSize: rowsPerPage }}
                    localization={{ header: { actions: '' } }}
                />
            </div>
            <ReportingCategoryModal
                isOpen={isModalOpen}
                item={selectedReportingCategory || null}
                onClose={() => {
                    setModalOpen(false);
                    setSelectedReportingCategory(null);
                }}
            />
            <Dialog scroll="paper" open={!whereUsedLoading && showUsageModal}>
                <WhereUsedModal
                    onClose={() => setShowUsageModal(false)}
                    onDelete={handleDelete}
                    title={`Usage for ${selectedReportingCategory?.name}`}
                    canDelete={pagePermissions?.ReportingCategory.Delete || false}
                    usageData={whereUsedData?.reportingCategoryWhereUsed?.dependencies || []}
                    permissions={pagePermissions}
                />
            </Dialog>
        </>
    );
};

export default ReportingCategories;
