import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, Checkbox, FormControlLabel } from '@mui/material';

const VirtualType = () => {
    const { control } = useFormContext();
    return (
        <Grid item xs={12}>
            <Controller
                name="isVirtualType"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={value}
                                onChange={e => {
                                    onChange(e.target.checked);
                                }}
                            />
                        }
                        label="Virtual"
                        labelPlacement="end"
                    />
                )}
            />
        </Grid>
    );
};

export default VirtualType;
