import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    subtitle: {
        fontWeight: 'bold',
    },
    chat: {
        cursor: 'pointer',
    },
    editLabelInput: {
        paddingLeft: 0,
    },
    patientSection: {
        marginBottom: 15,
    },
    fullName: {
        '&:hover': {
            filter: 'brightness(75%)',
        },
    },
});
