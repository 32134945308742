import { Worksheet } from 'exceljs';

export const formatSheet = (ws: Worksheet): void => {
    ws.columns.forEach(column => {
        column.width = 30;
    });
};

export const formatContentRange = (
    ws: Worksheet,
    startRow: number,
    endRow: number,
    startCol: number,
    endCol: number,
    boldFirstCol?: boolean,
    boldFirstRow?: boolean,
): void => {
    for (let col = startCol; col <= endCol; col++) {
        for (let row = startRow; row <= endRow; row++) {
            ws.getCell(row, col).font = {
                size: 14,
                name: 'Arial',
                bold: (boldFirstCol && col === 1) || (boldFirstRow && row === 1),
            };
            ws.getCell(row, col).alignment = { horizontal: 'left', wrapText: true };
        }
    }
};
