import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
} from '@mui/material';
import React, { useState } from 'react';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';

type FilterModalProps = {
    open: boolean;
    onClose: () => void;
};

export const FilterOrdersModal: React.FC<FilterModalProps> = ({ open, onClose }) => {
    const filterOrdersBPCuffsLoc = localStorage.getItem(`filterOrdersBPCuffs`);
    const [filterOrdersBPCuffs, setFilterOrdersBPCuffs] = useState<boolean>(
        filterOrdersBPCuffsLoc ? JSON.parse(filterOrdersBPCuffsLoc) : false,
    );
    const filterOrdersGlucometersLoc = localStorage.getItem(`filterOrdersGlucometers`);
    const [filterOrdersGlucometers, setFilterOrdersGlucometers] = useState<boolean>(
        filterOrdersGlucometersLoc ? JSON.parse(filterOrdersGlucometersLoc) : false,
    );
    const filterOrdersAdvocacyReferralsLoc = localStorage.getItem(`filterOrdersAdvocacyReferrals`);
    const [filterOrdersAdvocacyReferrals, setFilterOrdersAdvocacyReferrals] = useState<boolean>(
        filterOrdersAdvocacyReferralsLoc ? JSON.parse(filterOrdersAdvocacyReferralsLoc) : false,
    );
    const filterOrdersDeviceOrderLoc = localStorage.getItem(`filterOrdersDeviceOrder`);
    const [filterOrdersDeviceOrder, setFilterOrdersDeviceOrder] = useState<boolean>(
        filterOrdersDeviceOrderLoc ? JSON.parse(filterOrdersDeviceOrderLoc) : false,
    );
    const filterOrdersRPMLoc = localStorage.getItem(`filterOrdersRPMEligible`);
    const [filterOrdersRPMEligible, setFilterOrdersRPMEligible] = useState<boolean>(
        filterOrdersRPMLoc ? JSON.parse(filterOrdersRPMLoc) : false,
    );
    const filterOrdersVBCLoc = localStorage.getItem(`filterOrdersVBCEligible`);
    const [filterOrdersVBCEligible, setFilterOrdersVBCEligible] = useState<boolean>(
        filterOrdersVBCLoc ? JSON.parse(filterOrdersVBCLoc) : false,
    );

    const handleSave = () => {
        localStorage.setItem(`filterOrdersDeviceOrder`, `${filterOrdersDeviceOrder}`);
        localStorage.setItem(`filterOrdersAdvocacyReferrals`, `${filterOrdersAdvocacyReferrals}`);
        localStorage.setItem(`filterOrdersBPCuffs`, `${filterOrdersBPCuffs}`);
        localStorage.setItem(`filterOrdersGlucometers`, `${filterOrdersGlucometers}`);
        localStorage.setItem(`filterOrdersRPMEligible`, `${filterOrdersRPMEligible}`);
        localStorage.setItem(`filterOrdersVBCEligible`, `${filterOrdersVBCEligible}`);

        const filtersAppliedCount =
            (filterOrdersDeviceOrder ? 1 : 0) +
            (filterOrdersAdvocacyReferrals ? 1 : 0) +
            (filterOrdersBPCuffs ? 1 : 0) +
            (filterOrdersGlucometers ? 1 : 0) +
            (filterOrdersRPMEligible ? 1 : 0) +
            (filterOrdersVBCEligible ? 1 : 0);

        localStorage.setItem(`filtersAppliedCountOrders`, String(filtersAppliedCount));
        onClose();
    };

    const handleClearFilters = () => {
        setFilterOrdersDeviceOrder(false);
        setFilterOrdersAdvocacyReferrals(false);
        setFilterOrdersBPCuffs(false);
        setFilterOrdersGlucometers(false);
        setFilterOrdersRPMEligible(false);
        setFilterOrdersVBCEligible(false);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Filter Orders </DialogTitle>
            <DialogContent>
                <OutlinedSection title="Order Type">
                    <Grid container direction="row">
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filterOrdersDeviceOrder}
                                        onChange={() => {
                                            setFilterOrdersDeviceOrder(prev => !prev);
                                        }}
                                    />
                                }
                                label="Device Orders"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filterOrdersAdvocacyReferrals}
                                        onChange={() => {
                                            setFilterOrdersAdvocacyReferrals(prev => !prev);
                                        }}
                                    />
                                }
                                label="Advocacy Referrals"
                            />
                        </Grid>
                    </Grid>
                </OutlinedSection>
                <OutlinedSection title="Device Type">
                    <Grid container direction="row">
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filterOrdersBPCuffs}
                                        onChange={() => {
                                            setFilterOrdersBPCuffs(prev => !prev);
                                        }}
                                    />
                                }
                                label="BP cuffs"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filterOrdersGlucometers}
                                        onChange={() => {
                                            setFilterOrdersGlucometers(prev => !prev);
                                        }}
                                    />
                                }
                                label="Glucometers"
                            />
                        </Grid>
                    </Grid>
                </OutlinedSection>
                {/* todo: restrict for AHS */}
                <OutlinedSection title="Patient Eligibility Type">
                    <Grid container direction="row">
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filterOrdersRPMEligible}
                                        onChange={() => {
                                            setFilterOrdersRPMEligible(prev => !prev);
                                        }}
                                    />
                                }
                                label="RPM Eligible"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filterOrdersVBCEligible}
                                        onChange={() => {
                                            setFilterOrdersVBCEligible(prev => !prev);
                                        }}
                                    />
                                }
                                label="VBC Eligible"
                            />
                        </Grid>
                    </Grid>
                </OutlinedSection>
                <Button onClick={handleClearFilters}> Clear Filters </Button>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClose();
                    }}
                    color="primary"
                >
                    Close
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
