let activeWorker: null | Worker = null;
export class SingOutEventEmitter extends EventTarget {
    signout() {
        this.dispatchEvent(new Event('signout'));
    }
}
const singOutEvent = new SingOutEventEmitter();
window.logoutWorker = singOutEvent;

export function reInitSignoutWorker(timestamp: number) {
    if (activeWorker !== null) {
        activeWorker.terminate();
    }
    /**
     * Webpack 5+ usage of workers
     * https://webpack.js.org/guides/web-workers/
     */
    activeWorker = new Worker(new URL('./expirationCheck.worker.js', import.meta.url));
    activeWorker.postMessage(timestamp);
    activeWorker.addEventListener('message', event => {
        const { data } = event;
        if (activeWorker !== null && data === 'ok') {
            activeWorker.terminate();
            singOutEvent.signout();
        }
    });
}
