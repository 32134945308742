import _ from 'lodash';
import { ROOT_ORG_ID } from '~/constants';
import { OrgListQuery } from '../schemaTypes';

type Organization = NonNullable<NonNullable<OrgListQuery>['organizations'][0]>;

export type OrgTreeNode = {
    org: Organization;
    orgChildren: OrgTreeNode[];
    level: number;
};

const useOrgTree = ({ orgs }: { orgs: Organization[] }): OrgTreeNode => {
    const rootOrg = orgs.find(({ id }) => id === ROOT_ORG_ID);
    const nodeConstructor = (currentOrg: Organization, level: number): OrgTreeNode => {
        const children = _(orgs)
            .filter(o => o.parentId === currentOrg.id)
            .sortBy(['brandingName'])
            .value();
        return {
            org: currentOrg,
            orgChildren: children.map(child => nodeConstructor(child, level + 1)),
            level,
        };
    };
    const orgTree = nodeConstructor(rootOrg as Organization, 0);
    return orgTree;
};

export default useOrgTree;
