import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import {
    Button,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    MenuItem,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import omitDeep from 'omit-deep-lodash';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import FormBuilder, { FormBuilderRef } from '~/components/FormBuilder/FormBuilder';
import Loading from '~/components/Loading/Loading';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import {
    ConfigValueDictionary,
    ConfigValueType,
    FetchConfigValueDefaultsByIdForValueDefsModalQuery,
    FetchConfigValueDefsForConfigValuesPageDocument,
    FetchConfigValueDefsForConfigValuesPageQuery,
    useCreateConfigValueDefaultsFromValueDefPageMutation,
    useFetchConfigValueDefaultsByIdForValueDefsModalLazyQuery,
    useUpdateConfigValueDefaultsFromValueDefPageMutation,
} from '~/schemaTypes';
import { AppDefaultEnum } from '~/selectors';

type DefaultValue = NonNullable<
    FetchConfigValueDefaultsByIdForValueDefsModalQuery['configValueDef']
>['defaultValue'];
type PotentialValue = NonNullable<
    FetchConfigValueDefaultsByIdForValueDefsModalQuery['configValueDef']
>['potentialValues'][0];
const VALUE_DEF_VALIDATION_SCHEMA = Yup.object()
    .shape({
        name: Yup.string().required(),
        description: Yup.string().notRequired(),
        valueType: Yup.string().required().oneOf(Object.values(ConfigValueType)),
        category: Yup.string().required(),
        potentialValues: Yup.array<PotentialValue>()
            .of(
                Yup.object<PotentialValue>({
                    bool: Yup.bool().notRequired(),
                    str: Yup.string().notRequired(),
                    num: Yup.number().notRequired(),
                    localizedStr: Yup.object()
                        .shape({
                            en: Yup.string(),
                        })
                        .notRequired(),
                })
                    .noUnknown(false)
                    .defined(),
            )
            .notRequired(),
        defaultValue: Yup.object<DefaultValue>({
            bool: Yup.bool().nullable(),
            boolArr: Yup.array()
                .of(Yup.bool().nullable())
                .ensure()
                .nullable() as unknown as Yup.MixedSchema<(boolean | null)[]>,
            str: Yup.string().nullable(),
            strArr: Yup.array()
                .of(Yup.string().nullable())
                .ensure()
                .nullable() as unknown as Yup.MixedSchema<(string | null)[]>,
            num: Yup.number().nullable(),
            numArr: Yup.array()
                .of(Yup.number().nullable())
                .ensure()
                .nullable() as unknown as Yup.MixedSchema<(number | null)[]>,
            localizedStr: Yup.object()
                .shape({
                    en: Yup.string(),
                })
                .notRequired()
                .nullable(),
        })
            .noUnknown(false)
            .defined(),
        validationPattern: Yup.string().notRequired(),
    })
    .noUnknown(false)
    .defined();

const useStyles = makeStyles()({
    root: {},
});

interface ValueDefFormInput {
    name: string;
    description?: string;
    valueType: ConfigValueType;
    category: string;
    potentialValues?: PotentialValue[];
    defaultValue: DefaultValue;
    validationPattern?: string;
}

type ValueDefsModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setEditValueDefId: Dispatch<SetStateAction<string>>;
    id?: string;
};

const ValueDefsModal: React.FC<ValueDefsModalProps> = ({ setOpen, setEditValueDefId, id }) => {
    const { classes } = useStyles();
    const {
        reset,
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors },
        setValue,
    } = useForm<ValueDefFormInput>({
        resolver: yupResolver(VALUE_DEF_VALIDATION_SCHEMA as any),
        mode: 'onChange',
    });

    const [watchType, defaultValue] = watch(['valueType', 'defaultValue']);
    const formRef = useRef<FormBuilderRef>(null);

    const [fetchValDef, { data, loading }] =
        useFetchConfigValueDefaultsByIdForValueDefsModalLazyQuery({
            onCompleted: data => {
                if (data.configValueDef) {
                    reset({
                        name: data.configValueDef.name,
                        description: data.configValueDef.description ?? '',
                        valueType: data.configValueDef.valueType,
                        category: data.configValueDef.category,
                        potentialValues: data.configValueDef.potentialValues,
                        validationPattern: data.configValueDef.validationPattern ?? '',
                        defaultValue: data.configValueDef.defaultValue,
                    });
                }
            },
        });

    const [updateValueDef, { loading: updateValDefLoading }] =
        useUpdateConfigValueDefaultsFromValueDefPageMutation({
            onCompleted: data => {
                if (data.updateConfigValueDef?.success) {
                    setOpen(false);
                    setEditValueDefId('');
                }
            },
        });

    const [createValueDef, { loading: createValDefLoading }] =
        useCreateConfigValueDefaultsFromValueDefPageMutation({
            onCompleted: data => {
                if (data?.createConfigValueDef?.success) {
                    setOpen(false);
                }
            },
            update: (cache, response) => {
                if (response.data?.createConfigValueDef?.success) {
                    const newValueDef = response.data?.createConfigValueDef.resourceCreated;
                    if (newValueDef) {
                        const currentData =
                            cache.readQuery<FetchConfigValueDefsForConfigValuesPageQuery>({
                                query: FetchConfigValueDefsForConfigValuesPageDocument,
                            });
                        if (currentData?.configValueDefs) {
                            cache.writeQuery<FetchConfigValueDefsForConfigValuesPageQuery>({
                                query: FetchConfigValueDefsForConfigValuesPageDocument,
                                data: {
                                    configValueDefs: [newValueDef, ...currentData.configValueDefs],
                                },
                            });
                        }
                    }
                }
            },
        });
    const formBuilderData = useMemo(() => {
        return {
            ...defaultValue,
            strArr: defaultValue?.strArr?.map(str => ({
                value: str,
            })),
        };
    }, [defaultValue]);
    const handleArrField = (name: keyof ConfigValueDictionary, values: Record<string, any>) => {
        const arrValues = values[name]?.filter((val: any) => typeof val === 'string' && val);
        const formValue = defaultValue ? defaultValue[name] : null;
        if (!_.isEqual(arrValues, formValue)) {
            setValue(`defaultValue.${name}`, arrValues);
        }
    };

    const onSubmit = ({
        name,
        description,
        valueType,
        category,
        potentialValues,
        validationPattern,
        defaultValue,
    }: ValueDefFormInput) => {
        const value = omitDeep(defaultValue, '__typename');
        if (id) {
            updateValueDef({
                variables: {
                    input: {
                        id,
                        data: {
                            name,
                            description,
                            valueType,
                            category,
                            potentialValues: potentialValues ?? [],
                            defaultValue: _.pick(value, _.camelCase(valueType)),
                            validationPattern,
                        },
                    },
                },
            });
        } else {
            createValueDef({
                variables: {
                    input: {
                        name,
                        description,
                        valueType,
                        defaultValue: _.pick(value, _.camelCase(valueType)),
                        category,
                        potentialValues: potentialValues ?? [],
                        validationPattern,
                    },
                },
            });
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setEditValueDefId('');
    };
    useEffect(() => {
        if (id) {
            fetchValDef({
                variables: {
                    input: {
                        id,
                    },
                },
            });
        }
    }, [id, fetchValDef]);

    if (loading) {
        return <Loading subtitle="Loading details..." />;
    }

    if (createValDefLoading) {
        return <Loading subtitle="Creating app default..." />;
    }

    if (updateValDefLoading) {
        return <Loading subtitle="Updating app default..." />;
    }

    const title = data?.configValueDef?.id === undefined ? 'New App Default' : 'Edit App Default';
    return (
        <>
            <DialogTitleWithClose onClose={handleCancel} id="modalTitle">
                {title}
            </DialogTitleWithClose>
            <DialogContent dividers>
                <form
                    className={classes.root}
                    noValidate
                    onSubmit={() => {
                        formRef?.current?.triggerSubmit();
                        handleSubmit(onSubmit)();
                    }}
                >
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                label="Name"
                                id="name"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                data-test={AppDefaultEnum.MODAL_NAME}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                label="Category"
                                id="category"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('category')}
                                error={!!errors.category}
                                helperText={errors.category?.message}
                                data-test={AppDefaultEnum.MODAL_CATEGORY}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Description"
                                id="description"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('description')}
                                error={!!errors.description}
                                helperText={errors.description?.message}
                                data-test={AppDefaultEnum.MODAL_DESCRIPTION}
                            />
                        </Grid>
                        <ReactHookFormSelect
                            control={control}
                            defaultValue={data?.configValueDef?.valueType ?? ''}
                            name="valueType"
                            variant="outlined"
                            label="Value Type"
                            fullWidth
                            margin="dense"
                            data-test={AppDefaultEnum.MODAL_VALUE_TYPE}
                        >
                            {_.map(ConfigValueType, valueType => (
                                <MenuItem
                                    key={valueType}
                                    value={ConfigValueType[valueType]}
                                    data-test={valueType}
                                >
                                    {valueType}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                        <Grid item xs={12}>
                            <Typography variant="h6">Default Value</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {watchType === ConfigValueType.Bool && (
                                <Controller
                                    name="defaultValue.bool"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={value ?? false}
                                                    onChange={e => onChange(e.target.checked)}
                                                    data-test={AppDefaultEnum.DEFAULT_BOOLEAN_VALUE}
                                                />
                                            }
                                            label="Default Boolean Value"
                                        />
                                    )}
                                />
                            )}
                            {watchType === ConfigValueType.Str && (
                                <TextField
                                    variant="outlined"
                                    label="Default String Value"
                                    id="defaultValue.str"
                                    type="text"
                                    margin="dense"
                                    fullWidth
                                    {...register('defaultValue.str')}
                                    error={!!errors.defaultValue?.str}
                                    helperText={errors.defaultValue?.str?.message}
                                />
                            )}
                            {watchType === ConfigValueType.LocalizedStr && (
                                <>
                                    <TextField
                                        variant="outlined"
                                        label="Default Localized String Value (EN)"
                                        id="defaultValue.localizedStr.en"
                                        type="text"
                                        margin="dense"
                                        fullWidth
                                        {...register('defaultValue.localizedStr.en')}
                                        error={!!errors.defaultValue?.localizedStr}
                                        helperText={errors.defaultValue?.localizedStr?.message}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="Default Localized String Value (ES)"
                                        id="defaultValue.localizedStr.es"
                                        type="text"
                                        margin="dense"
                                        fullWidth
                                        {...register('defaultValue.localizedStr.es')}
                                        error={!!errors.defaultValue?.localizedStr}
                                        helperText={errors.defaultValue?.localizedStr?.message}
                                    />
                                </>
                            )}
                            {watchType === ConfigValueType.Num && (
                                <TextField
                                    variant="outlined"
                                    label="Default Number Value"
                                    id="defaultValue.num"
                                    type="number"
                                    margin="dense"
                                    defaultValue={undefined}
                                    fullWidth
                                    {...register('defaultValue.num')}
                                    error={!!errors.defaultValue?.num}
                                    helperText={errors.defaultValue?.num?.message}
                                />
                            )}
                            {watchType === ConfigValueType.StrArr && id && (
                                <FormBuilder
                                    data={formBuilderData}
                                    ref={formRef}
                                    handleSubmit={values => {
                                        handleArrField('strArr', values);
                                    }}
                                    fields={[
                                        {
                                            label: 'Default StrArr Values',
                                            name: 'strArr',
                                            type: 'string',
                                            array: true,
                                        },
                                    ]}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="Validation Pattern (Regex)"
                                id="validationPattern"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('validationPattern')}
                                error={!!errors.validationPattern}
                                helperText={errors.validationPattern?.message}
                                data-test={AppDefaultEnum.MODAL_VALIDATION_PATTERN}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    startIcon={<SaveIcon />}
                    onClick={() => {
                        formRef?.current?.triggerSubmit();
                        handleSubmit(onSubmit)();
                    }}
                    variant="contained"
                    color="secondary"
                    data-test={AppDefaultEnum.MODAL_SAVE_BUTTON}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
};

export default ValueDefsModal;
