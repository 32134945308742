import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    root: {
        '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
            opacity: '0',
        },
    },
}));

export default useStyles;
