import { Autocomplete, Button, Card, CardMedia, Grid, TextField, Typography } from '@mui/material';
import ObjectId from 'bson-objectid';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import toImagePickerForm from '~/components/ImageContentPicker/helpers/toFormValues';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { CLOUDFRONT_IMAGE_SOURCE } from '~/constants';
import {
    ContentType,
    useImageContentByIdForImageContentPickerLazyQuery,
    useImageContentByTextFilterLazyQuery,
} from '~/schemaTypes';
import { CarePlanEnum } from '~/selectors';
import { defaultFormValues } from './helpers/defaultFormValues';
import { useStyles } from './styles';
import { ImageContentFormInput, ImageContentItem, ImageContentPickerParams } from './types';

const ImageContentPicker: React.FC<ImageContentPickerParams> = ({
    selectedImageContentId,
    setSelectedImageContentId,
    setSelectedImageContentName,
    onEdit,
    disabled = false,
    compact = false,
    required = false,
    title = 'Select Image',
}) => {
    const { classes } = useStyles();
    const isEditMode = Boolean(selectedImageContentId);
    const [filterDisabled, setFilterDisabled] = useState<boolean>(true);
    const [imagesNotFound, setImagesNotFound] = useState<boolean>(false);
    const [isFilterInUse, setIsFilterInUse] = useState<boolean>(false);
    const [isImageSelectedByUser, setIsImageSelectedByUser] = useState<boolean>(false);
    const [imageContent, setImageContent] = useState<ImageContentItem[]>([]);
    const [defaultImage, setDefaultImage] = useState<ImageContentItem | null>(null);
    const [contentId, setContentId] = useState<string>('');
    const [textFilter, setTextFilter] = useState<string>('');

    const isFilterById = Boolean(contentId);
    const isFilterByCriteria = Boolean(textFilter);
    const isFilterEnabled = isFilterById || isFilterByCriteria;

    const showInfoMessage = useMemo(() => {
        return (!isEditMode && !isFilterInUse) || !defaultImage?.id;
    }, [isEditMode, isFilterInUse, defaultImage?.id]);
    const {
        control,
        register,
        setError,
        clearErrors,
        handleSubmit,

        formState: { errors },
    } = useForm({
        defaultValues: defaultFormValues,
    });

    const setDefaultImageWrapper = useCallback(
        (imageItem: ImageContentItem | null) => {
            setDefaultImage(state => {
                const isImageItemModified =
                    (onEdit && isFilterInUse && state?.id !== imageItem?.id) ||
                    (onEdit && !imageItem);
                if (isImageItemModified) {
                    onEdit();
                }
                return imageItem;
            });
        },
        [isFilterInUse, setDefaultImage, onEdit],
    );

    const [imageContentById, { loading: imageContentByIdLoading }] =
        useImageContentByIdForImageContentPickerLazyQuery({
            onCompleted: ({ simpleContent }) => {
                if (simpleContent && simpleContent.contentType === ContentType.Image) {
                    const [image] = toImagePickerForm([simpleContent]);
                    setImageContent(() => [image]);
                    setDefaultImageWrapper(image);
                } else {
                    setError('contentId', {
                        type: 'custom',
                        message: 'Found content is not an image...',
                    });
                }
            },
            onError: () => {
                setImagesNotFound(true);
            },
        });

    const [imageContentByTextFilter, { loading: imageContentByTextFilterLoading }] =
        useImageContentByTextFilterLazyQuery({
            onCompleted: ({ contentPickerFetch: { data, total } }) => {
                if (data) {
                    const sortedImages = _.sortBy(toImagePickerForm(data), 'name');
                    setImagesNotFound(!total);
                    setImageContent(() => sortedImages);
                    setDefaultImageWrapper(sortedImages[0]);
                }
            },
            fetchPolicy: 'network-only',
        });

    useEffect(() => {
        if (isFilterEnabled) {
            setFilterDisabled(false);
        } else {
            setFilterDisabled(true);
        }
    }, [isFilterEnabled]);

    useEffect(() => {
        if (imageContent.length) {
            setSelectedImageContentId(imageContent[0]?.id);
            if (setSelectedImageContentName) setSelectedImageContentName(imageContent[0].name);
        }
    }, [imageContent, setSelectedImageContentId, setSelectedImageContentName]);

    useEffect(() => {
        if (isEditMode && !isImageSelectedByUser) {
            imageContentById({ variables: { input: { id: selectedImageContentId } } });
        }
    }, [isEditMode, imageContentById, selectedImageContentId, isImageSelectedByUser]);

    const handleFilter: SubmitHandler<ImageContentFormInput> = ({ contentId, textFilter }) => {
        setImagesNotFound(false);
        setIsImageSelectedByUser(true);
        setIsFilterInUse(true);
        if (contentId && !ObjectId.isValid(contentId)) {
            setError('contentId', {
                type: 'custom',
                message: 'Provided id is not a valid ObjectId',
            });
            return;
        }

        if (!contentId) {
            imageContentByTextFilter({
                variables: {
                    input: {
                        ...(textFilter && { searchTerm: textFilter, types: [ContentType.Image] }),
                    },
                },
            });
            return;
        }

        imageContentById({ variables: { input: { id: contentId } } });
    };

    const handleImageChange = (val: { id: string } | null) => {
        const imageItem = imageContent.find(item => item?.id === val?.id);

        setIsImageSelectedByUser(true);
        setDefaultImageWrapper(imageItem || null);
        setSelectedImageContentId(val?.id ?? '');
        if (setSelectedImageContentName && imageItem) setSelectedImageContentName(imageItem?.name);
    };

    if (imageContentByIdLoading) {
        return <Loading height={250} subtitle="Fetching Image by id..." />;
    }
    if (imageContentByTextFilterLoading) {
        return <Loading height={250} subtitle="Fetching Image by text filter..." />;
    }

    return (
        <OutlinedSection title={title}>
            <OutlinedSection title="Filter">
                <form onSubmit={handleSubmit(handleFilter)}>
                    <Grid container>
                        <Grid item container direction="column" xs={12} md={compact ? 12 : 10}>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    label="Image Id"
                                    fullWidth
                                    margin="dense"
                                    onFocus={() => clearErrors('contentId')}
                                    className={classes.resetPadding}
                                    {...register('contentId')}
                                    disabled={disabled || isFilterByCriteria}
                                    error={Boolean(errors.contentId)}
                                    helperText={errors.contentId?.message}
                                    value={contentId}
                                    onChange={({ target: { value } }) => setContentId(value)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    label="Text Filter"
                                    fullWidth
                                    margin="dense"
                                    className={classes.resetPadding}
                                    disabled={disabled || isFilterById}
                                    {...register('textFilter')}
                                    value={textFilter}
                                    onChange={({ target: { value } }) => setTextFilter(value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            md={compact ? 12 : 2}
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <Grid item className={classes.filterButtonWrapper}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    disabled={disabled || filterDisabled}
                                    data-test={CarePlanEnum.FILTER}
                                    onClick={handleSubmit(handleFilter)}
                                >
                                    Filter
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </OutlinedSection>
            {imageContent.length > 0 && (
                <Grid
                    container
                    alignItems="center"
                    spacing={2}
                    className={classes.imageSelectWrapper}
                >
                    <Grid
                        item
                        xs={12}
                        lg={compact ? 5 : 3}
                        md={compact ? 5 : 4}
                        sm={compact ? 5 : 5}
                    >
                        {defaultImage?.content && (
                            <Card variant="outlined" className={classes.imageCard}>
                                <CardMedia
                                    component="img"
                                    image={`${CLOUDFRONT_IMAGE_SOURCE}${defaultImage.content}`}
                                    alt="Simple content image"
                                />
                            </Card>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={compact ? 7 : 9}
                        md={compact ? 7 : 8}
                        sm={compact ? 7 : 7}
                    >
                        <Controller
                            control={control}
                            name="image"
                            render={({ field: { onChange } }) => (
                                <Autocomplete
                                    disabled={disabled}
                                    className={classes.imageSelect}
                                    size="small"
                                    value={defaultImage}
                                    onChange={(_, val) => {
                                        onChange(val?.id || null);
                                        handleImageChange(val);
                                    }}
                                    getOptionLabel={selected => selected.name ?? ''}
                                    options={imageContent.map(o => ({
                                        name: o?.name,
                                        id: o?.id,
                                    }))}
                                    isOptionEqualToValue={(option, val) => option?.id === val?.id}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                            className={classes.imageTextField}
                                            placeholder="Select Image"
                                            label="Image"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            )}
            {imagesNotFound && (
                <Grid container justifyContent="center" alignItems="center">
                    <Typography className={classes.emptyState}>
                        Image Content not found. Please refine your search criteria...
                    </Typography>
                </Grid>
            )}
            {showInfoMessage && (
                <Grid container justifyContent="center" alignItems="center">
                    <Typography className={classes.emptyState}>
                        Use filter to search Images...
                    </Typography>
                </Grid>
            )}
            {required && !selectedImageContentId && (
                <Grid container justifyContent="center" alignItems="center">
                    <Typography className={classes.error}>
                        Please search and select an Image
                    </Typography>
                </Grid>
            )}
        </OutlinedSection>
    );
};
export default ImageContentPicker;
