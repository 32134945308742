import { faPenToSquare, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ObjectId from 'bson-objectid';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import Draggable from '~/components/Draggable/Draggable';
import Droppable from '~/components/Droppable/Droppable';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import Mappers from '~/helpers/mappers';
import { OrgTreeNode } from '~/hooks/useOrgTree';
import {
    FetchPatientHeaderDocument,
    HeaderProfileDef,
    PatientHeader,
    useCreatePatientHeaderMutation,
    useFetchPatientHeaderLazyQuery,
    useFetchPatientHeaderQuery,
    useFetchProfileDefsForProfileHeaderPageQuery,
    useUpdatePatientHeaderMutation,
} from '~/schemaTypes';
import _ from 'lodash';
import { TriggerGlobalConfirm } from '~/state';

type OrganizationConfigProps = {
    orgNode: OrgTreeNode;
};

export type SortableListOnChangeProps = {
    startIndex: number;
    endIndex: number;
};

export type ListItemType = {
    label: {
        en: string;
        es?: string | null;
    };
    id: string | ObjectId;
    disabled?: boolean;
    isParentField?: boolean | null;
};

interface ProfileDefFormInput {
    profileDefId: string;
    label: string;
}

export const useStyles = makeStyles()({
    enheritedItem: {
        backgroundColor: '#ddd',
        opacity: '0.8',
    },
    disabled: {
        backgroundColor: '#666',
        opacity: '0.8',
    },
});

const yupSchema = Yup.object().shape({
    profileDefId: Yup.string().required('Required'),
    label: Yup.string().required('Required'),
});

const OrganizationPatientHeader: React.FC<OrganizationConfigProps> = ({ orgNode }) => {
    const { classes } = useStyles();
    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<ProfileDefFormInput>({
        resolver: yupResolver(yupSchema as any),
    });
    const [orgHeader, setOrgHeader] = useState<PatientHeader | null>(null);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<HeaderProfileDef | null>(null);
    const [profileDefId, setProfileDefId] = useState<string | null>(null);
    const [currentProfileDefId, setCurrentProfileDefId] = useState<string | null>(null);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [profileDefsList, setProfileDefsList] = useState<any[]>([]);

    const { data: patientHeaderData, loading: fetchPatientHeaderLoading } =
        useFetchPatientHeaderQuery({
            variables: { input: { fields: { organizationId: orgNode.org.id } } },
            onCompleted: data => {
                setOrgHeader(data?.patientHeader ?? null);
            },
        });

    const { loading: fetchProfileDefs } = useFetchProfileDefsForProfileHeaderPageQuery({
        onCompleted: data => {
            if (data?.userProfileDefs) {
                const newProfileDefsList = data?.userProfileDefs.map(item => ({
                    id: item.id,
                    name: item.name,
                }));

                setProfileDefsList(newProfileDefsList);
            }
        },
    });

    const [createPatientHeader, { loading: creatingPatientHeader }] =
        useCreatePatientHeaderMutation({
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: FetchPatientHeaderDocument,
                    variables: { input: { fields: { organizationId: orgNode.org.id } } },
                },
            ],
        });

    const [
        fetchParentPatientHeader,
        { data: parentHeaderData, loading: fetchParentPatientHeaderLoading },
    ] = useFetchPatientHeaderLazyQuery();

    const [updatePatientHeader, { loading: updatingPatientHeader }] =
        useUpdatePatientHeaderMutation({
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: FetchPatientHeaderDocument,
                    variables: { input: { fields: { organizationId: orgNode.org.id } } },
                },
            ],
        });

    const handleItemClick = (item: ListItemType) => {
        setSelectedItem({ profileDefId: item.id, label: item.label.en });
        setIsEditMode(true);
        setEditModalOpen(true);
        setProfileDefId(item.id.toString());
        setCurrentProfileDefId(item.id.toString());
    };

    const handleItemDelete = (item: ListItemType) => {
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${item.label.en}'?`,
            callback: () => {
                const profileDefsArray = orgHeader?.profileDefs
                    ? orgHeader?.profileDefs.map(obj => Mappers.excludeTypeName(obj))
                    : [];

                const filteredArray = profileDefsArray.filter(
                    deletedItem => deletedItem.profileDefId !== item.id,
                );
                updatePatientHeader({
                    variables: {
                        input: {
                            id: orgHeader?.id,
                            data: {
                                profileDefs: filteredArray,
                            },
                        },
                    },
                });
            },
        });
    };

    const handleDisableItem = (item: ListItemType) => {
        const profileDefsArray = orgHeader?.profileDefs
            ? orgHeader?.profileDefs.map(obj => Mappers.excludeTypeName(obj))
            : [];

        const filteredArray = profileDefsArray.map(selectedItem => {
            if (selectedItem.profileDefId === item.id) {
                return {
                    ...selectedItem,
                    disabled: !selectedItem.disabled,
                };
            }
            return selectedItem;
        });

        updatePatientHeader({
            variables: {
                input: {
                    id: orgHeader?.id,
                    data: {
                        profileDefs: filteredArray,
                    },
                },
            },
        });
    };

    const handleAddProfileDef = () => {
        setEditModalOpen(true);
    };

    const handleCreatePatientHeader = async () => {
        let parentHeader;
        let profileDefs = [];

        if (orgNode.level > 0 && orgNode.org.parentId) {
            await fetchParentPatientHeader({
                variables: { input: { fields: { organizationId: orgNode.org.parentId } } },
            });

            parentHeader = parentHeaderData?.patientHeader;
        }

        if (parentHeader && parentHeader?.profileDefs && parentHeader?.profileDefs.length > 0) {
            const parentProfileDefs = parentHeader.profileDefs.map(obj =>
                Mappers.excludeTypeName(obj),
            );

            profileDefs = parentProfileDefs.map(item => ({
                ...item,
                isParentField: true,
            }));
        }
        createPatientHeader({
            variables: {
                input: {
                    organizationId: orgNode.org.id,
                    profileDefs: profileDefs.length > 0 ? profileDefs : [],
                },
            },
        });
    };

    const onDragEnd = useCallback(
        (result: { source: any; destination: any }) => {
            const { source, destination } = result;
            const hasSource = source && source.index != null;
            const hasDestination = destination && destination.index != null;
            const hasData = hasSource && hasDestination;
            const shouldMoveItem = hasData && source.index !== destination.index;
            if (!shouldMoveItem) {
                return;
            }

            const handleOrderChange = ({ startIndex, endIndex }: SortableListOnChangeProps) => {
                if (orgHeader && orgHeader.profileDefs && orgHeader.profileDefs?.length > 0) {
                    const listClone = [...orgHeader.profileDefs];
                    const element = listClone[startIndex];
                    listClone.splice(startIndex, 1);
                    listClone.splice(endIndex, 0, element);
                    const newOrgHeader = {
                        ...orgHeader,
                        profileDefs: listClone,
                    };
                    setOrgHeader(newOrgHeader);
                }
            };

            handleOrderChange({
                startIndex: result.source.index,
                endIndex: result.destination.index,
            });
        },
        [orgHeader],
    );

    const getClassName = (item: ListItemType) => {
        if (item.isParentField) {
            return item.disabled ? classes.disabled : classes.enheritedItem;
        }
        return '';
    };

    const getProfileDefsList = () => {
        const entireProfileDefsList = _.sortBy(profileDefsList, 'name').map(def => def.id);
        let usedProfileDefsIds = new Set();

        if (!isEditMode) {
            if (orgHeader && orgHeader?.profileDefs && orgHeader?.profileDefs.length > 0) {
                usedProfileDefsIds = new Set(
                    orgHeader?.profileDefs.map(item => item?.profileDefId),
                );
            }

            if (usedProfileDefsIds.size > 0) {
                return entireProfileDefsList.filter(item => !usedProfileDefsIds.has(item));
            }

            return entireProfileDefsList;
        }

        return entireProfileDefsList;
    };

    const onReset = () => {
        setIsEditMode(false);
        setEditModalOpen(false);
        setSelectedItem(null);
        setProfileDefId(null);
        setCurrentProfileDefId(null);
        reset();
    };

    const onSubmit = (profileDefItem: any) => {
        const profileDefSelected = {
            profileDefId: profileDefItem.profileDefId ?? profileDefId,
            label: profileDefItem.label,
            sortField: '',
            disabled: false,
        };

        const profileDefsArray = orgHeader?.profileDefs
            ? orgHeader?.profileDefs.map(obj => Mappers.excludeTypeName(obj))
            : [];

        if (isEditMode) {
            const itemIndex = profileDefsArray.findIndex(
                obj => obj.profileDefId === currentProfileDefId,
            );

            if (itemIndex >= 0) {
                const currentItem = profileDefsArray[itemIndex];

                profileDefsArray[itemIndex].label = profileDefItem.label ?? currentItem.label;
                profileDefsArray[itemIndex].profileDefId =
                    profileDefItem.profileDefId ?? profileDefId;
            }
        } else {
            profileDefsArray.push(profileDefSelected);
        }

        updatePatientHeader({
            variables: {
                input: {
                    id: orgHeader?.id,
                    data: {
                        profileDefs: profileDefsArray,
                    },
                },
            },
        });
        onReset();
    };

    const renderEditForm = () => (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Controller
                control={control}
                name="profileDefId"
                render={({ field: { onChange } }) => (
                    <Autocomplete
                        autoComplete
                        options={getProfileDefsList() ?? []}
                        getOptionLabel={def => profileDefsList.find(d => d.id === def)?.name ?? ''}
                        filterSelectedOptions
                        value={profileDefId}
                        onChange={(e, value) => {
                            onChange(value);
                            setProfileDefId(value);
                        }}
                        renderInput={params => (
                            <TextField
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                required
                                label="Profile Def"
                                variant="outlined"
                                error={!!errors.profileDefId}
                                helperText={errors.profileDefId?.message}
                            />
                        )}
                    />
                )}
            />
            <TextField
                label="Label"
                {...register('label')}
                type="label"
                fullWidth
                required
                defaultValue={selectedItem?.label ?? ''}
                error={!!errors.label}
                helperText={errors.label?.message}
                InputLabelProps={{ shrink: true }}
            />
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button
                    onClick={() => onReset()}
                    color="secondary"
                    variant="outlined"
                    style={{ marginRight: 10 }}
                >
                    Cancel
                </Button>
                <AsyncActionButton loading={updatingPatientHeader}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!profileDefId}
                    >
                        Save
                    </Button>
                </AsyncActionButton>
            </DialogActions>
        </form>
    );

    if (
        fetchProfileDefs ||
        fetchPatientHeaderLoading ||
        creatingPatientHeader ||
        updatingPatientHeader ||
        fetchParentPatientHeaderLoading
    ) {
        return <Loading height={500} />;
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={10}>
                    {patientHeaderData && (
                        <div style={{ margin: '30px 15px' }}>
                            <OutlinedSection title="Profile Defs Order">
                                <Grid item xs={12}>
                                    {orgHeader &&
                                    orgHeader?.profileDefs &&
                                    orgHeader?.profileDefs.length > 0 ? (
                                        <List>
                                            <Droppable
                                                onDragEnd={onDragEnd}
                                                droppableId="ProfileDefsOrder"
                                            >
                                                {[
                                                    ...orgHeader.profileDefs.map(
                                                        def =>
                                                            ({
                                                                label: { en: def?.label },
                                                                id: def?.profileDefId,
                                                                disabled: def?.disabled || null,
                                                                isParentField:
                                                                    def?.isParentField || null,
                                                            } as ListItemType),
                                                    ),
                                                ]?.map((item, index) => {
                                                    if (!item?.id) return null;
                                                    return (
                                                        <Draggable
                                                            id={item.id.toString()}
                                                            index={index}
                                                            key={item.id.toString()}
                                                        >
                                                            <ListItem
                                                                className={getClassName(item)}
                                                                style={{
                                                                    borderBottom:
                                                                        '1px solid lightgray',
                                                                    margin: '5px 0',
                                                                    borderRadius: '5px',
                                                                }}
                                                            >
                                                                {!item.isParentField && (
                                                                    <>
                                                                        <ListItemIcon
                                                                            style={{
                                                                                minWidth: 'auto',
                                                                                cursor: 'pointer',
                                                                                padding: '10px 8px',
                                                                                marginRight: '10px',
                                                                            }}
                                                                            onClick={() =>
                                                                                handleItemDelete(
                                                                                    item,
                                                                                )
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon={faTrash}
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemIcon
                                                                            style={{
                                                                                minWidth: 'auto',
                                                                                cursor: 'pointer',
                                                                                padding: '10px 8px',
                                                                                marginRight: '10px',
                                                                            }}
                                                                            onClick={() =>
                                                                                handleItemClick(
                                                                                    item,
                                                                                )
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon={faPenToSquare}
                                                                            />
                                                                        </ListItemIcon>
                                                                    </>
                                                                )}
                                                                <ListItemText
                                                                    primary={item.label?.en}
                                                                />
                                                                {!item.isParentField && (
                                                                    <ListItemSecondaryAction>
                                                                        <ListItemIcon className="drag-handle">
                                                                            <DragHandleIcon />
                                                                        </ListItemIcon>
                                                                    </ListItemSecondaryAction>
                                                                )}
                                                                {item.isParentField && (
                                                                    <ListItemSecondaryAction>
                                                                        <ListItemIcon
                                                                            onClick={() =>
                                                                                handleDisableItem(
                                                                                    item,
                                                                                )
                                                                            }
                                                                            style={{
                                                                                minWidth: 'auto',
                                                                                cursor: 'pointer',
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon={faTimesCircle}
                                                                            />
                                                                        </ListItemIcon>
                                                                    </ListItemSecondaryAction>
                                                                )}
                                                            </ListItem>
                                                        </Draggable>
                                                    );
                                                })}
                                            </Droppable>
                                        </List>
                                    ) : (
                                        <p>No Profile Defs</p>
                                    )}
                                </Grid>
                            </OutlinedSection>
                            <Grid item style={{ textAlign: 'left' }}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={handleAddProfileDef}
                                >
                                    Add Profile Variable
                                </Button>
                            </Grid>
                        </div>
                    )}
                    {!patientHeaderData && (
                        <Grid style={{ margin: '30px' }}>
                            <Grid item style={{ marginBottom: '26px' }}>
                                <Typography gutterBottom variant="subtitle1">
                                    There is no Patient Header for this organization
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={handleCreatePatientHeader}
                                >
                                    Create Patient Header
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {editModalOpen && (
                <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
                    <DialogTitle id="form-dialog-title">View Header Item Details</DialogTitle>
                    {renderEditForm()}
                </Dialog>
            )}
        </>
    );
};

export default OrganizationPatientHeader;
