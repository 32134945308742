import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardContent, CardHeader, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowBack, Save as SaveIcon } from '@mui/icons-material';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    ConfigExpressionUpdateInput,
    ConfigValueType,
    ExpressionUseType,
    FetchConfigExpressionListDocument,
    FetchConfigExpressionListQuery,
    useCreateConfigExpressionMutation,
    useFetchConfigExpressionLazyQuery,
    useFetchConfigExpressionListQuery,
    useFetchConfigValueDefsForExpressionPageQuery,
    useFetchProfileDefsForExpressionPageQuery,
    UserProfileValueType,
    useUpdateConfigValueExpressionMutation,
} from '~/schemaTypes';
import { ExpressionEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';
import { errorRed } from '~/theme/WildTheme';
import DisplayRule from '../components/DisplayRule';
import RuleModal from '../components/RuleModal';

export enum operator {
    eq = '==',
    gt = '>',
    gte = '>=',
    lt = '<',
    lte = '<=',
    missing = 'missing',
    and = 'and',
    or = 'or',
    not = '!',
}
export enum CompareType {
    ConfigToValue = 'ConfigToValue',
    Group = 'Group',
    SumOfValues = 'SumOfValues',

    // ProfileToValue
    ProfileToConfig = 'ProfileToConfig',
    ProfileToProfile = 'ProfileToProfile',
    ProfileToValue = 'ProfileToValue',

    // ProfileDateToValue
    DaysBeforeProfileToConfig = 'DaysBeforeProfileToConfig',
    DaysAfterProfileToConfig = 'DaysAfterProfileToConfig',
    DaysBeforeProfileToValue = 'DaysBeforeProfileToValue',
    DaysAfterProfileToValue = 'DaysAfterProfileToValue',
    DaysBeforeProfileToProfile = 'DaysBeforeProfileToProfile',
    DaysAfterProfileToProfile = 'DaysAfterProfileToProfile',

    // Trackers
    TrackerToProfile = 'TrackerToProfile',
    TrackerToConfig = 'TrackerToConfig',
    TrackerToValue = 'TrackerToValue',
    SymptomToValue = 'SymptomToValue',
}

export enum ExpressionMeasurementType {
    BloodPressureTrackerRecord_diastolic = 'BloodPressureTrackerRecord_diastolic',
    BloodPressureTrackerRecord_systolic = 'BloodPressureTrackerRecord_systolic',
    BloodPressureTrackerRecord_pulse = 'BloodPressureTrackerRecord_pulse',
    GlucoseTrackerRecord = 'GlucoseTrackerRecord',
    GlucoseTrackerRecord_fasting = 'GlucoseTrackerRecord_fasting',
    GlucoseTrackerRecord_postBreakfast = 'GlucoseTrackerRecord_postBreakfast',
    GlucoseTrackerRecord_postLunch = 'GlucoseTrackerRecord_postLunch',
    GlucoseTrackerRecord_postDinner = 'GlucoseTrackerRecord_postDinner',
}

export enum Symptom {
    BP_SYMPTOM_1 = 'BP_SYMPTOM_1',
    BP_SYMPTOM_2 = 'BP_SYMPTOM_2',
    BP_SYMPTOM_3 = 'BP_SYMPTOM_3',
    BP_SYMPTOM_4 = 'BP_SYMPTOM_4',
    BP_SYMPTOM_5 = 'BP_SYMPTOM_5',
    BP_SYMPTOM_6 = 'BP_SYMPTOM_6',
}

export const SYMPTOMS = new Map<Symptom, string>([
    [Symptom.BP_SYMPTOM_1, 'Constant Headaches'],
    [Symptom.BP_SYMPTOM_2, 'Upper Abdominal Pain'],
    [Symptom.BP_SYMPTOM_3, 'Changes in Vision'],
    [Symptom.BP_SYMPTOM_4, 'Severe Nausea or Vomiting'],
    [Symptom.BP_SYMPTOM_5, 'Swelling of Hands or Face'],
    [Symptom.BP_SYMPTOM_6, 'Trouble Breathing'],
]);

export interface Rule {
    id: number;
    op: operator;
    name: string;
    userProfileId?: string;
    userProfileName?: string;
    userProfileIdTo?: string;
    userProfileNameTo?: string;
    appConfigId?: string;
    appConfigName?: string;
    textValue?: string;
    compareType: CompareType;
    measurementType?: ExpressionMeasurementType;
    symptom?: Symptom;
    num?: number;
    bool?: boolean;
    choiceId?: string;
    choiceName?: string;
    subrules?: Rule[];
}
export const Operators = [
    { title: 'And group', value: operator.and },
    { title: 'Or group', value: operator.or },
    { title: 'Not group', value: operator.not },
    { title: 'Equal rule', value: operator.eq },
    { title: 'Missing rule', value: operator.missing },
    { title: 'Greater than rule', value: operator.gt },
    { title: 'Greater than or equal rule', value: operator.gte },
    { title: 'Less than rule', value: operator.lt },
    { title: 'Less than or equal rule', value: operator.lte },
];
export const isGroup = (op: operator): boolean =>
    op === operator.and || op === operator.or || op === operator.not;

const useStyles = makeStyles()(_theme => ({
    root: {},
    card: {
        height: 'calc(100vh - 175px)',
        display: 'grid',
        gridTemplateColumns: 'auto',
        gridTemplateRows: '50px 1fr',
        padding: 0,
    },
    cardHeader: {
        borderBottom: '1px solid lightgray',
    },
    cardBody: {
        overflowY: 'auto',
    },
    previewCard: {
        height: 'calc(100vh - 175px)',
    },
}));
interface ExpressionFormInput {
    name: string;
    description: string;
}

const formValuesDefault = (): ExpressionFormInput => {
    return {
        name: '',
        description: '',
    };
};
const allowedProfileTypes = (op: operator) => {
    switch (op) {
        case operator.eq:
        case operator.missing:
            return [
                UserProfileValueType.Bool,
                UserProfileValueType.Str,
                UserProfileValueType.Choice,
                UserProfileValueType.Choices,
                UserProfileValueType.Num,
                UserProfileValueType.Date,
            ];
        default:
            return [UserProfileValueType.Num, UserProfileValueType.Date];
    }
};
const allowedAppConfigTypes = (op: operator) => {
    switch (op) {
        case operator.eq:
        case operator.missing:
            return [ConfigValueType.Bool, ConfigValueType.Num, ConfigValueType.Str];
        default:
            return [ConfigValueType.Num];
    }
};

const ExpressionValidation = Yup.object().shape({
    name: Yup.string().required('Required'),
});
const Expression: React.FC = () => {
    const [rules, setRules] = useState<Rule | undefined>(undefined);
    const formValuesFromData = (instance: ConfigExpressionUpdateInput): ExpressionFormInput => {
        return {
            name: instance.name || '',
            description: instance.description || '',
        };
    };

    const { classes } = useStyles();
    const history = useNavigate();
    const { id: expressionId } = useParams<{ id: string }>();
    const isEditMode = expressionId !== 'new';
    const [nextNumber, setNextNumber] = useState<number>(0);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalParms, setmodalParms] = useState<{
        addMode: boolean;
        parentId?: number;
        rule: Rule;
        op: operator;
        appConfigs: any;
        profiles: any;
    }>();
    const [close, setClose] = useState(false);
    const { data: appConfigData, loading: configValueLoading } =
        useFetchConfigValueDefsForExpressionPageQuery();

    const { data: profileData, loading: profileDefsLoading } =
        useFetchProfileDefsForExpressionPageQuery();

    const getNextNumber = () => {
        const next = nextNumber + 1;
        setNextNumber(next);
        return next;
    };
    const flattenRules = useCallback((rule: Rule, ruleList: Rule[]) => {
        ruleList.push(rule);
        if (rule.subrules) rule.subrules.forEach(sub => flattenRules(sub, ruleList));
    }, []);

    const removeSubRule = (id: number, rule: Rule): Rule | undefined => {
        const ruleCloned = { ...rule };
        if (!ruleCloned.subrules) return undefined;

        const subRule = ruleCloned.subrules.find(r => r.id === id);
        if (subRule) {
            ruleCloned.subrules = ruleCloned.subrules.filter(r => r.id !== id);
        } else {
            const processedRules: Rule[] = [];
            for (const sub of ruleCloned.subrules) {
                const processed = removeSubRule(id, sub);
                if (processed !== undefined) {
                    processedRules.push(processed);
                } else {
                    processedRules.push(sub);
                }
            }
            ruleCloned.subrules = processedRules;
        }
        return ruleCloned;
    };
    const removeRule = (id: number) => {
        if (!rules) return;
        if (rules.id === id) {
            setRules(undefined);
        } else {
            setRules(removeSubRule(id, rules));
        }
    };
    const findRule = (id: number, ruleElement: Rule): Rule | null => {
        if (ruleElement.id === id) return ruleElement;
        if (!ruleElement.subrules) return null;
        let i = 0;
        let result = null;
        for (i = 0; result === null && i < ruleElement.subrules.length; i++)
            result = findRule(id, ruleElement.subrules[i]);
        return result;
    };
    const deleteRuleHandler = (id: number) => {
        TriggerGlobalConfirm({
            callback: () => {
                removeRule(id);
            },
            message: 'Are you sure you want to remove this item?',
        });
    };
    const editRuleHandler = (id: number) => {
        if (rules) {
            const rule = findRule(id, rules);
            if (rule) {
                setmodalParms({
                    addMode: false,
                    op: rule.op,
                    rule,
                    profiles: profileData?.userProfileDefs.filter(
                        p =>
                            !p.systemGenerated &&
                            allowedProfileTypes(rule.op).includes(p.valueType),
                    ),
                    appConfigs: appConfigData?.configValueDefs.filter(a =>
                        allowedAppConfigTypes(rule.op).includes(a.valueType),
                    ),
                });
                setModalOpen(true);
            }
        }
    };
    const addRuleHandler = (op: operator, id: number | undefined) => {
        const name = Operators.find(o => o.value === op)?.title || 'Unknown';
        if (isGroup(op)) {
            if (!rules) setRules({ id: getNextNumber(), name, op, compareType: CompareType.Group });
            else if (id) {
                const rule = findRule(id, rules);
                if (rule) {
                    if (rule.subrules)
                        rule.subrules = [
                            ...rule.subrules,
                            { id: getNextNumber(), name, op, compareType: CompareType.Group },
                        ];
                    else
                        rule.subrules = [
                            { id: getNextNumber(), name, op, compareType: CompareType.Group },
                        ];
                }
            }
        } else {
            setmodalParms({
                addMode: true,
                parentId: id,
                op,
                rule: { id: getNextNumber(), op, name, compareType: CompareType.ConfigToValue },
                profiles: profileData?.userProfileDefs.filter(
                    p => !p.systemGenerated && allowedProfileTypes(op).includes(p.valueType),
                ),
                appConfigs: appConfigData?.configValueDefs.filter(a =>
                    allowedAppConfigTypes(op).includes(a.valueType),
                ),
            });
            setModalOpen(true);
        }
    };
    const closeModalHandler = () => {
        setModalOpen(false);
    };
    const submitModalHandler = (ruleUpdate: Rule, parentId?: number) => {
        if (!rules) setRules(ruleUpdate);
        else {
            const rule = findRule(ruleUpdate.id, rules);
            if (!rule) {
                if (!parentId) setRules(ruleUpdate);
                else {
                    const rule = findRule(parentId, rules);
                    if (rule)
                        if (rule.subrules) rule.subrules = rule.subrules.concat(ruleUpdate);
                        else rule.subrules = [ruleUpdate];
                }
            } else {
                // updating existing rule
                rule.appConfigId = ruleUpdate.appConfigId;
                rule.compareType = ruleUpdate.compareType;
                rule.bool = ruleUpdate.bool;
                rule.choiceId = ruleUpdate.choiceId;
                rule.choiceName = ruleUpdate.choiceName;
                rule.name = ruleUpdate.name;
                rule.num = ruleUpdate.num;
                rule.textValue = ruleUpdate.textValue;
                rule.userProfileId = ruleUpdate.userProfileId;
                rule.userProfileIdTo = ruleUpdate.userProfileIdTo;
                rule.userProfileName = ruleUpdate.userProfileName;
                rule.userProfileNameTo = ruleUpdate.userProfileNameTo;
                rule.measurementType = ruleUpdate.measurementType;
                rule.symptom = ruleUpdate.symptom;
            }
        }
        setModalOpen(false);
    };
    const {
        register,
        reset,
        handleSubmit,
        setError,

        formState: { errors },
    } = useForm<ConfigExpressionUpdateInput>({
        resolver: yupResolver(ExpressionValidation as any),
        defaultValues: formValuesDefault(),
    });
    const [fetchExpressionById, { data: expressionData, loading: expressionDataLoading }] =
        useFetchConfigExpressionLazyQuery();

    const { data: expressionList, loading: expressionListLoading } =
        useFetchConfigExpressionListQuery();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [createExpression, { loading: createExpressionLoading }] =
        useCreateConfigExpressionMutation({
            update: (cache, response) => {
                if (response.data?.createConfigExpression?.success) {
                    const newExpression = response.data?.createConfigExpression.resourceCreated;
                    if (newExpression) {
                        const currentData = cache.readQuery<FetchConfigExpressionListQuery>({
                            query: FetchConfigExpressionListDocument,
                        });
                        if (currentData?.configExpressions) {
                            cache.writeQuery<FetchConfigExpressionListQuery>({
                                query: FetchConfigExpressionListDocument,
                                data: {
                                    configExpressions: [
                                        newExpression,
                                        ...currentData.configExpressions,
                                    ],
                                },
                            });
                        }
                    }
                }
            },
        });
    const [useType, setUseType] = useState<ExpressionUseType>(ExpressionUseType.Tags);

    const setProfileDefNames = useCallback(
        (rules: Rule) => {
            if (profileData && profileData.userProfileDefs) {
                const formattedRules: Rule = { ...rules };
                const formattedSubRules: Rule[] = [];

                if (rules.hasOwnProperty('userProfileId')) {
                    const profileDef = profileData.userProfileDefs.find(
                        userProfileDef => userProfileDef.id === rules.userProfileId,
                    );

                    formattedRules.userProfileName = profileDef?.name ?? '';
                    formattedRules.name = profileDef?.name ?? formattedRules.name;
                }

                if (
                    rules.hasOwnProperty('userProfileIdTo') &&
                    (rules.compareType === CompareType.ProfileToValue ||
                        rules.compareType === CompareType.ProfileToConfig ||
                        rules.compareType === CompareType.ProfileToProfile)
                ) {
                    const profileDef = profileData.userProfileDefs.find(
                        userProfileDef => userProfileDef.id === rules.userProfileIdTo,
                    );

                    formattedRules.userProfileNameTo = profileDef?.name ?? '';
                }

                if (formattedRules.subrules && formattedRules.subrules.length > 0) {
                    formattedRules.subrules.forEach(subrule => {
                        const formattedSubrule = setProfileDefNames(subrule);

                        formattedSubRules.push(formattedSubrule);
                    });

                    formattedRules.subrules = formattedSubRules;
                }

                return formattedRules;
            }

            return rules;
        },
        [profileData],
    );

    useEffect(() => {
        if (profileData && profileData.userProfileDefs && isEditMode) {
            fetchExpressionById({ variables: { input: { id: expressionId } } });
        }
    }, [profileData, expressionId, fetchExpressionById, isEditMode]);

    useEffect(() => {
        if (isEditMode) {
            if (expressionData && expressionData.configExpression) {
                reset(formValuesFromData(expressionData.configExpression));
                setUseType(expressionData.configExpression.useType);
                const dbRule: Rule = setProfileDefNames({
                    ...expressionData.configExpression.rules,
                });
                const subRules = dbRule.subrules ? _.cloneDeep(dbRule.subrules) : undefined;
                setRules({ ...dbRule, subrules: subRules });
                const ruleList: Rule[] = [];
                flattenRules(expressionData.configExpression.rules, ruleList);
                let maxId = 0;
                ruleList.forEach(r => {
                    if (r.id > maxId) maxId = r.id;
                });
                setNextNumber(maxId);
            }
        } else {
            reset(formValuesDefault());
        }
    }, [isEditMode, expressionData, reset, setNextNumber, flattenRules, setProfileDefNames]);
    const useTypeChangedHandler = (event: any) => {
        if (rules !== undefined) {
            TriggerGlobalConfirm({
                callback: () => {
                    setUseType(event.target.value);
                    setRules(undefined);
                },
                message: 'Changing Used On will remove all the rules. Are you sure?',
            });
        } else setUseType(event.target.value);
    };

    const [updateExpression, { loading: updateExpressionLoading }] =
        useUpdateConfigValueExpressionMutation();

    const handleSave = (form: ConfigExpressionUpdateInput) => {
        const profileIds = new Set<string>();
        const appConfigIds = new Set<string>();
        const measurementTypes = new Set<ExpressionMeasurementType>();
        const symptoms = new Set<Symptom>();
        let isValid = true;
        if (
            (isEditMode &&
                expressionList?.configExpressions.filter(
                    exp =>
                        exp.id !== expressionId &&
                        exp.name.toLowerCase() === form.name?.toLowerCase(),
                ).length !== 0) ||
            (!isEditMode &&
                expressionList?.configExpressions.filter(
                    exp => exp.name.toLowerCase() === form.name?.toLowerCase(),
                ).length !== 0)
        ) {
            setError('name', {
                type: 'manual',
                message: 'Name has already been used, please change.',
            });
            isValid = false;
        }
        if (rules === undefined) {
            isValid = false;
            setError('rules', {
                type: 'manual',
                message: 'At least one rule must be added.',
            });
            isValid = false;
        } else if (isValid) {
            const ruleList: Rule[] = [];
            flattenRules(rules, ruleList);
            if (
                ruleList.filter(r => isGroup(r.op) && (!r.subrules || r.subrules.length === 0))
                    .length > 0
            ) {
                setError('rules', {
                    type: 'manual',
                    message: 'All groups must have least one rule must be added.',
                });
                isValid = false;
            } else {
                ruleList.forEach(r => {
                    if (r.appConfigId) appConfigIds.add(r.appConfigId);
                    if (r.userProfileId) profileIds.add(r.userProfileId);
                    if (r.userProfileIdTo) profileIds.add(r.userProfileIdTo);
                    if (r.measurementType) measurementTypes.add(r.measurementType);
                    if (r.symptom) symptoms.add(r.symptom);
                });
            }
        }
        if (!isValid) return;
        if (isEditMode && expressionId)
            updateExpression({
                variables: {
                    input: {
                        id: expressionId,
                        data: {
                            name: form.name,
                            description: form.description,
                            useType,
                            rules,
                            profileDefIds: Array.from(profileIds),
                            appConfigIds: Array.from(appConfigIds),
                            measurementTypes: Array.from(measurementTypes),
                            symptoms: Array.from(symptoms),
                        },
                    },
                },
            });
        else
            createExpression({
                variables: {
                    input: {
                        name: form.name || '',
                        description: form.description,
                        useType,
                        rules,
                        profileDefIds: Array.from(profileIds),
                        appConfigIds: Array.from(appConfigIds),
                        measurementTypes: Array.from(measurementTypes),
                        symptoms: Array.from(symptoms),
                    },
                },
            });
        if (close) history('/app-config/expressions/');
    };
    if (
        expressionDataLoading ||
        configValueLoading ||
        profileDefsLoading ||
        updateExpressionLoading ||
        createExpressionLoading ||
        expressionListLoading
    ) {
        return <Loading />;
    }
    return (
        <>
            {modalOpen && modalParms && (
                <RuleModal
                    useType={useType}
                    addMode={modalParms.addMode}
                    parentId={modalParms.parentId}
                    rule={modalParms.rule}
                    appConfigs={modalParms.appConfigs}
                    profiles={modalParms.profiles}
                    closeHandler={closeModalHandler}
                    submitHandler={submitModalHandler}
                />
            )}
            <Button component={RouterLink} to="/app-config/expressions" startIcon={<ArrowBack />}>
                Back to Expression List
            </Button>
            <form noValidate onSubmit={handleSubmit(handleSave)}>
                <Card className={classes.card}>
                    <CardHeader
                        className={classes.cardHeader}
                        title={
                            isEditMode
                                ? `Edit ${expressionData?.configExpression?.name}`
                                : 'Create New Expression'
                        }
                    />
                    <CardContent className={classes.cardBody}>
                        <OutlinedSection title="Info:">
                            <TextField
                                variant="outlined"
                                id="name"
                                label="Name *"
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                fullWidth
                                data-test={ExpressionEnum.NAME_INPUT}
                            />
                            <TextField
                                variant="outlined"
                                id="description"
                                label="Description"
                                {...register('description')}
                                fullWidth
                                multiline
                                data-test={ExpressionEnum.DESCRIPTION_TEXTAREA}
                            />
                            <OutlinedSection title="Expression can be used on:">
                                <select
                                    value={useType}
                                    id="useType"
                                    onChange={useTypeChangedHandler}
                                    disabled={isEditMode}
                                >
                                    <option value={ExpressionUseType.Surveys}>Surveys</option>
                                    <option value={ExpressionUseType.Tags}>Tags</option>
                                </select>
                            </OutlinedSection>
                        </OutlinedSection>
                        <OutlinedSection title="Rules:">
                            {errors && errors.rules && (
                                <div style={{ color: errorRed }}>
                                    {String(errors.rules.message)}
                                </div>
                            )}
                            <DisplayRule
                                rule={rules}
                                addHandler={addRuleHandler}
                                deleteHandler={deleteRuleHandler}
                                editHandler={editRuleHandler}
                            />
                        </OutlinedSection>
                        <div style={{ width: '100%', textAlign: 'right' }}>
                            <Button
                                onClick={handleSubmit(handleSave)}
                                startIcon={<SaveIcon />}
                                type="submit"
                                color="secondary"
                                variant="contained"
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => {
                                    setClose(true);
                                    handleSubmit(handleSave);
                                }}
                                startIcon={<SaveIcon />}
                                type="submit"
                                color="secondary"
                                variant="contained"
                                data-test={ExpressionEnum.SAVE_AND_CLOSE}
                            >
                                Save &amp; Close
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </form>
        </>
    );
};

export default Expression;
