import { Button, Card, Grid, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowBack } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import {
    useFetchApplicationLazyQuery,
    useFetchConfigAppForConfigAppSummaryPageQuery,
} from '~/schemaTypes';
import { OnboardingScreenEnum } from '~/selectors';

import ConfigAppSummaryOnboarding from './ConfigAppSummaryOnboarding/ConfigAppSummaryOnboarding';
import ConfigAppSummaryValues from './ConfigAppSummaryValues/ConfigAppSummaryValues';

const useStyles = makeStyles()(_theme => ({
    root: {},
    tabs: {},
}));

const ConfigAppSummary: React.FC = () => {
    const classes = useStyles();
    const history = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [appBundleId, setAppBundleId] = useState('');
    const [fetchAppById, { data: app, loading: appLoading }] = useFetchApplicationLazyQuery({
        onCompleted: data => {
            if (data) {
                setAppBundleId(data.application?.appBundleId || '');
            }
        },
    });
    useEffect(() => {
        fetchAppById({ variables: { input: { id } } });
    }, [id, fetchAppById]);

    const [currentTab, setCurrentTab] = useState<number>(0);
    const { data: appData, loading: appDataLoading } =
        useFetchConfigAppForConfigAppSummaryPageQuery({
            variables: { appBundleId },
        });
    const onNavigateAway = () => {
        history(`/app-config/apps/`);
    };

    if (appLoading || appDataLoading) {
        return <Loading />;
    }

    return (
        <div className={classes.classes.root}>
            <Grid container spacing={3}>
                <Button
                    onClick={onNavigateAway}
                    startIcon={<ArrowBack />}
                    style={{ marginTop: '10px' }}
                >
                    Back to Application List
                </Button>

                <Grid item xs={12}>
                    <Grid container direction="column">
                        <Grid item xs={12}>
                            <Card elevation={2}>
                                <Typography variant="h5">{app?.application?.name}</Typography>
                                <Typography variant="subtitle2">{appBundleId}</Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            {appBundleId && appData && (
                                <div>
                                    <Tabs
                                        value={currentTab}
                                        onChange={(e, newValue) => setCurrentTab(newValue)}
                                        aria-label="App Value Select"
                                    >
                                        <Tab label="Config Values" />
                                        <Tab
                                            label="Onboarding Screens"
                                            data-test={OnboardingScreenEnum.APP_ONBOARDING_SCREENS}
                                        />
                                    </Tabs>
                                    {currentTab === 0 && (
                                        <ConfigAppSummaryValues
                                            values={appData.appConfigValues}
                                            valueDefs={appData.configValueDefs}
                                            appBundleId={appBundleId}
                                        />
                                    )}
                                    {currentTab === 1 && (
                                        <ConfigAppSummaryOnboarding
                                            appBundleId={appBundleId}
                                            valueDefs={appData.configValueDefs}
                                        />
                                    )}
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ConfigAppSummary;
