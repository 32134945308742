import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormHelperText, Grid, MenuItem, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import SortableList, { SortableListOnChangeProps } from '~/components/SortableList/SortableList';
import {
    AlertSeverity,
    BioScreenShortcutIcon,
    BioScreenShortcutType,
    useFetchSurveyDefsForBioScreenBuilderQuery,
} from '~/schemaTypes';
import { ProfileScreenEnum } from '~/selectors';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import ObjectID from 'bson-objectid';
import Shortcut from './Shortcut';
import SurveyGroup from './SurveyGroup';
import { BioScreenBuilderFormInput } from './types/BioScreenBuilderFormInput';
import { BioScreenBuilderFormProps } from './types/BioScreenBuilderFormProps';
import validationSchema from './ValidationSchema';

const BioScreenBuilderForm: React.FC<BioScreenBuilderFormProps> = ({
    defaultValues,
    classes,
    portalTags,
    onSubmit,
    handleClose,
}) => {
    const useFormMethods = useForm<BioScreenBuilderFormInput>({
        resolver: yupResolver(validationSchema as any),
        defaultValues,
    });
    const {
        control,
        register,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useFormMethods;

    const { fields, append, move, remove } = useFieldArray({
        control,
        name: 'surveyGroups',
    });

    const {
        fields: shortcutsFields,
        remove: shortcutsRemove,
        append: shortcutsAppend,
        move: shortcutsMove,
    } = useFieldArray({
        control,
        name: 'shortcuts',
    });

    useEffect(() => {
        const error: any = errors.surveyGroups || errors.shortcuts;
        if (error) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: Array.isArray(error) ? error[0]?.surveys?.message : error.message,
            });
        }
    }, [errors]);

    const { data: surveyDefs, loading: surveyDefsLoading } =
        useFetchSurveyDefsForBioScreenBuilderQuery();

    const deleteItem = (fn: () => void) => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete this item?`,
            callback: () => {
                fn();
            },
        });
    };

    const handleDeleteSurveyGroupClick = (index: number) => {
        deleteItem(() => remove(index));
    };

    const handleDeleteShortcutClick = (index: number) => {
        deleteItem(() => shortcutsRemove(index));
    };

    const handleAddSurveyGroupClick = () => {
        const id = new ObjectID().toHexString();
        append({ label: { en: '', es: '' }, surveys: [], id });
    };

    const handleAddShortcutClick = () => {
        shortcutsAppend({
            label: { en: '', es: '' },
            surveyId: '',
            type: BioScreenShortcutType.Survey,
            icon: BioScreenShortcutIcon.Flower,
            instructions: { en: '', es: '' },
        });
    };

    const handleSurveyGroupChange = ({ startIndex, endIndex }: SortableListOnChangeProps) => {
        move(startIndex, endIndex);
    };

    const handleShortcutChange = ({ startIndex, endIndex }: SortableListOnChangeProps) => {
        shortcutsMove(startIndex, endIndex);
    };

    return (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
            <Grid container item xs={12}>
                <Grid container item xs={12}>
                    <OutlinedSection title="Label">
                        <TextField
                            variant="outlined"
                            label="English *"
                            fullWidth
                            margin="dense"
                            {...register('label.en')}
                            error={Boolean(errors.label?.en)}
                            helperText={errors.label?.en?.message}
                            data-test={ProfileScreenEnum.LABEL}
                        />
                        <TextField
                            variant="outlined"
                            label="Spanish"
                            fullWidth
                            margin="dense"
                            {...register('label.es')}
                            error={Boolean(errors.label?.es)}
                            helperText={errors.label?.es?.message}
                        />
                    </OutlinedSection>
                </Grid>
                <Grid container item xs={12}>
                    <OutlinedSection title="Your Health Info">
                        <Grid container item xs={12}>
                            <OutlinedSection title="Label">
                                <TextField
                                    variant="outlined"
                                    label="English *"
                                    fullWidth
                                    margin="dense"
                                    {...register('yourHealthInfo.label.en')}
                                    error={Boolean(errors.yourHealthInfo?.label?.en)}
                                    helperText={errors.yourHealthInfo?.label?.en?.message}
                                    data-test={ProfileScreenEnum.HEALTH_INFO_LABEL}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Spanish"
                                    fullWidth
                                    margin="dense"
                                    {...register('yourHealthInfo.label.es')}
                                    error={Boolean(errors.yourHealthInfo?.label?.es)}
                                    helperText={errors.yourHealthInfo?.label?.es?.message}
                                />
                            </OutlinedSection>
                        </Grid>
                        <Grid item xs={12}>
                            <ReactHookFormSelect
                                control={control}
                                name="yourHealthInfo.icon"
                                variant="outlined"
                                defaultValue=""
                                label="Icon *"
                                fullWidth
                                margin="dense"
                                error={Boolean(errors.yourHealthInfo?.icon)}
                                data-test={ProfileScreenEnum.HEALTH_INFO_ICON}
                            >
                                {Object.values(BioScreenShortcutIcon).map(value => (
                                    <MenuItem key={value} value={value} data-test={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                            {errors?.yourHealthInfo?.icon && (
                                <FormHelperText error className={classes.helperMessage}>
                                    {errors?.yourHealthInfo?.icon?.message}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid container item xs={12}>
                            <OutlinedSection title="Instructions">
                                <TextField
                                    variant="outlined"
                                    label="English *"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    margin="dense"
                                    {...register('yourHealthInfo.instructions.en')}
                                    error={Boolean(errors.yourHealthInfo?.instructions?.en)}
                                    helperText={errors.yourHealthInfo?.instructions?.en?.message}
                                    data-test={ProfileScreenEnum.HEALTH_INFO_INSTRUCTIONS}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Spanish"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    margin="dense"
                                    {...register('yourHealthInfo.instructions.es')}
                                    error={Boolean(errors.yourHealthInfo?.instructions?.es)}
                                    helperText={errors.yourHealthInfo?.instructions?.es?.message}
                                />
                            </OutlinedSection>
                        </Grid>
                    </OutlinedSection>
                </Grid>
                {!surveyDefsLoading && (
                    <>
                        <Grid container item xs={12}>
                            {fields.map((_, index) => {
                                const item = getValues(`surveyGroups.${index}`);
                                return (
                                    <SurveyGroup
                                        key={item.id}
                                        id={item.id}
                                        label={item.label}
                                        index={index}
                                        classes={classes}
                                        onDelete={handleDeleteSurveyGroupClick}
                                        useFormMethods={useFormMethods}
                                        portalTags={portalTags}
                                        surveyDefs={
                                            surveyDefs?.surveyDefs.slice().sort((a, b) => {
                                                if (a && b) {
                                                    if ((a.name ?? '') < (b.name ?? '')) return -1;
                                                    if ((a.name ?? '') > (b.name ?? '')) return 1;
                                                }
                                                return 0;
                                            }) || []
                                        }
                                    />
                                );
                            })}
                            <Grid className={classes.addSurveyGroupBtn} item xs={12}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    fullWidth
                                    onClick={handleAddSurveyGroupClick}
                                    startIcon={<AddIcon />}
                                    data-test={ProfileScreenEnum.ADD_SURVEY_GROUP_BUTTON}
                                >
                                    Add Survey Group
                                </Button>
                            </Grid>
                        </Grid>
                        {fields && fields.length > 0 && (
                            <OutlinedSection
                                className={classes.sortableList}
                                title="Survey Group Order"
                            >
                                <Grid item xs={12}>
                                    <SortableList
                                        list={fields}
                                        droppableId="surveyGroupOrder"
                                        onChange={handleSurveyGroupChange}
                                        onDelete={handleDeleteSurveyGroupClick}
                                    />
                                </Grid>
                            </OutlinedSection>
                        )}
                        <Grid container item xs={12}>
                            {shortcutsFields.map((item, index) => {
                                const surveyGroups = getValues('surveyGroups');
                                return (
                                    <Shortcut
                                        key={item.id}
                                        shortcut={item}
                                        index={index}
                                        classes={classes}
                                        onDelete={handleDeleteShortcutClick}
                                        useFormMethods={useFormMethods}
                                        portalTags={portalTags}
                                        surveyDefs={
                                            surveyDefs?.surveyDefs.slice().sort((a, b) => {
                                                if (a && b) {
                                                    if ((a.name ?? '') < (b.name ?? '')) return -1;
                                                    if ((a.name ?? '') > (b.name ?? '')) return 1;
                                                }
                                                return 0;
                                            }) || []
                                        }
                                        surveyGroups={surveyGroups}
                                    />
                                );
                            })}
                            <Grid className={classes.addSurveyGroupBtn} item xs={12}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    fullWidth
                                    onClick={handleAddShortcutClick}
                                    startIcon={<AddIcon />}
                                    data-test={ProfileScreenEnum.ADD_SHORTCUT_BUTTON}
                                >
                                    Add Shortcut
                                </Button>
                            </Grid>
                        </Grid>
                        {shortcutsFields && shortcutsFields.length > 0 && (
                            <OutlinedSection
                                className={classes.sortableList}
                                title="Shortcut Order"
                            >
                                <Grid item xs={12}>
                                    <SortableList
                                        list={shortcutsFields}
                                        droppableId="shortcutOrder"
                                        onChange={handleShortcutChange}
                                        onDelete={handleDeleteShortcutClick}
                                    />
                                </Grid>
                            </OutlinedSection>
                        )}
                    </>
                )}
                <Grid item xs={12}>
                    <div className={classes.actionBtns}>
                        <Button
                            onClick={handleSubmit(onSubmit)}
                            startIcon={<SaveIcon />}
                            type="submit"
                            color="secondary"
                            variant="contained"
                        >
                            Save
                        </Button>
                        <Button
                            onClick={() => {
                                handleClose(true);
                                handleSubmit(onSubmit);
                            }}
                            startIcon={<SaveIcon />}
                            type="submit"
                            color="secondary"
                            variant="contained"
                            data-test={ProfileScreenEnum.SAVE_AND_CLOSE}
                        >
                            Save &amp; Close
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
};

export default BioScreenBuilderForm;
