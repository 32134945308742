import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { VirtualCareSurveyInput } from '../types';

type useFormProps = {
    formProps: UseFormReturn<VirtualCareSurveyInput>;
    selfSelected: boolean;
};

const useForm = (props: useFormProps) => {
    const {
        selfSelected,
        formProps: { setValue, watch, resetField },
    } = props;
    const [
        firstName,
        lastName,
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode,
        sex,
        birthDate,
        insuredAddressSameAsPatient,
    ] = watch([
        'firstName',
        'lastName',
        'addressLine1',
        'addressLine2',
        'city',
        'state',
        'zipCode',
        'sex',
        'birthDate',
        'insuredAddressSameAsPatient',
    ]);
    React.useEffect(() => {
        if (insuredAddressSameAsPatient) {
            // copy values to insured fields
            setValue('insuredAddressLine1', addressLine1, { shouldValidate: true });
            setValue('insuredAddressLine2', addressLine2, { shouldValidate: true });
            setValue('insuredCity', city, { shouldValidate: true });
            setValue('insuredState', state, { shouldValidate: true });
            setValue('insuredZipCode', zipCode, { shouldValidate: true });
        } else {
            resetField('insuredAddressLine1', { keepDirty: true });
            resetField('insuredAddressLine2', { keepDirty: true });
            resetField('insuredCity', { keepDirty: true });
            setValue('insuredState', '');
            resetField('insuredZipCode', { keepDirty: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insuredAddressSameAsPatient]);

    React.useEffect(() => {
        if (insuredAddressSameAsPatient) {
            setValue('insuredAddressLine1', addressLine1);
            setValue('insuredAddressLine2', addressLine2);
            setValue('insuredCity', city);
            setValue('insuredState', state);
            setValue('insuredZipCode', zipCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressLine1, addressLine2, city, state, zipCode]);

    React.useEffect(() => {
        if (selfSelected) {
            setValue('insuredFirstName', firstName);
            setValue('insuredLastName', lastName);
            setValue('subscriberSex', sex);
            setValue('subscriberBirthDate', birthDate);
        } else {
            resetField('insuredFirstName', { keepDirty: true });
            resetField('insuredLastName', { keepDirty: true });
            resetField('subscriberSex', { keepDirty: true, defaultValue: '' });
            resetField('subscriberBirthDate', { keepDirty: true, defaultValue: '' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selfSelected]);

    React.useEffect(() => {
        if (selfSelected) {
            setValue('insuredFirstName', firstName);
            setValue('insuredLastName', lastName);
            setValue('subscriberSex', sex);
            setValue('subscriberBirthDate', birthDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstName, lastName, sex, birthDate]);
};

export default useForm;
