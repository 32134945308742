import React, { useMemo } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { format, toDate } from 'date-fns-tz';
import { DialogContent, Grid, Button, DialogActions, Typography, Link } from '@mui/material';
import { Groups2Outlined } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import DateInput from '~/components/DateInput/DateInput';
import { NavLink } from 'react-router-dom';
import { TriggerGlobalConfirm } from '~/state';
import { SelectedPatient, FormType, Claim } from './types';
import { ADD_PATIENT_TO_CLAIM_SCHEMA } from './yupSchema';
import AffiliateForm from './ClaimForms/AffiliateForm';
import DoulaForm from './ClaimForms/DoulaForm';

type AddPatientToClaimModalProps = {
    onClose: () => void;
    patient: SelectedPatient;
    addClaimHandler: (claim: Claim) => void;
    portalView: boolean;
    currentAffiliateId: string;
    isPatientWhitelisted: boolean;
    allowNoInsuranceClaims?: boolean;
    VCView: boolean;
};

const DURATION_WARNING_MSG = 'Duration shown is > 2 hours.  Are you sure this is correct?';
const DURATION_WARNING_TIME = 2 * 60;

const AddPatientToClaimModal: React.FC<AddPatientToClaimModalProps> = props => {
    const {
        onClose,
        patient,
        addClaimHandler,
        portalView,
        currentAffiliateId,
        VCView,
        isPatientWhitelisted,
        allowNoInsuranceClaims,
    } = props;
    const checkEligibilityUrl = useMemo(() => {
        if (VCView) {
            return `/doula/portal/patients/${patient.id}`;
        }
        if (portalView) {
            return `/app-config/vcaffiliates/patients/${currentAffiliateId}/${patient.id}`;
        }
        return `/affiliate-care-portal/patients/${patient.id}`;
    }, [VCView, portalView, patient.id, currentAffiliateId]);
    const methods = useForm<Claim>({
        resolver: yupResolver(
            ADD_PATIENT_TO_CLAIM_SCHEMA(
                patient.insurancePlans,
                isPatientWhitelisted,
                allowNoInsuranceClaims,
            ) as any,
        ),
        context: { type: VCView ? FormType.Doula : FormType.Affiliate },
        defaultValues: {
            lastName: patient.lastName,
            firstName: patient.firstName,
            birthDate: format(toDate(patient.birthDate.split('T')[0]), 'MM/dd/yyyy'),
            id: Date.now(),
            patientId: patient.id,
            startTime: '',
            endTime: '',
            duration: 0,
            serviceType: '',
            birthType: '',
        },
    });
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = methods;
    const onSubmit = (data: Claim) => {
        if (VCView && data.duration > DURATION_WARNING_TIME) {
            TriggerGlobalConfirm({
                callback: () => addClaimHandler(data),
                message: DURATION_WARNING_MSG,
            });
        } else {
            addClaimHandler(data);
        }
    };
    return (
        <>
            <DialogTitleWithClose id="add-patient-to-calims" onClose={onClose}>
                Add Patient Visit
            </DialogTitleWithClose>
            <DialogContent dividers>
                <FormProvider {...methods}>
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                            rowSpacing={2}
                        >
                            <Grid item xs={6} padding="10px">
                                <Typography variant="body1" color="primary">
                                    Name:
                                </Typography>
                                <Typography variant="body1">
                                    {patient.firstName} {patient.lastName}
                                </Typography>
                            </Grid>
                            {patient.multiples && (
                                <Grid item xs={6} padding="10px" container columnSpacing={1}>
                                    <Grid item>
                                        <Groups2Outlined fontSize="large" />
                                    </Grid>
                                    <Grid item alignContent="center">
                                        <Typography variant="body2" fontWeight="bold">
                                            Having Twins/Multiples
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={7} padding="10px">
                                <Typography variant="body1" color="primary">
                                    DOB:
                                </Typography>
                                <Typography variant="body1">
                                    {format(toDate(patient.birthDate.split('T')[0]), 'MM/dd/yyyy')}
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Controller
                                    name="dateOfService"
                                    control={control}
                                    render={({ field }) => (
                                        <DateInput
                                            label="Date Of Service"
                                            field={field}
                                            error={errors.dateOfService}
                                            inputProps={{
                                                fullWidth: true,
                                                InputLabelProps: {
                                                    shrink: true,
                                                },
                                                FormHelperTextProps: {
                                                    sx: {
                                                        whiteSpace: 'nowrap',
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                                {errors.dateOfService?.type === 'is-eligible' && (
                                    <Link
                                        underline="always"
                                        padding={3}
                                        variant="body2"
                                        to={checkEligibilityUrl}
                                        target="_blank"
                                        component={NavLink}
                                    >
                                        Refresh Eligibility Data
                                    </Link>
                                )}
                            </Grid>
                            {!VCView && <AffiliateForm />}
                            {VCView && <DoulaForm />}
                        </Grid>
                    </form>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit(onSubmit)()}
                >
                    Add Visit
                </Button>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
};

export default AddPatientToClaimModal;
