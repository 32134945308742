import React, { useEffect, useState, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, Grid, TextField, FormControl } from '@mui/material';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import DateInput from '~/components/DateInput/DateInput';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import {
    useUploadAffiliateInsuranceMutation,
    useGetAffiliateInsuranceByKeyLazyQuery,
} from '~/schemaTypes';
import FileUpload, {
    generateFileFromString,
} from '~/views/AffiliateCare/components/FileUpload/FileUpload';
import { FormInput } from './types';
import { CONSULTANT_FORM_SCHEMA } from './yupSchema';
import { useStyles } from './styles';
import { getDateFormatted } from './helpers';
import { FormMode } from './constants';

type ConsultantFormProps = {
    isEdit: boolean;
    onComplete: (close: boolean, consultant?: FormInput) => void;
    data: FormInput | null;
    mode?: FormMode.Portal | FormMode.Onboarding;
};

const ConsultantForm: React.FC<ConsultantFormProps> = props => {
    const { isEdit, onComplete, data, mode = FormMode.Portal } = props;
    const { classes } = useStyles();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        control,
        getValues,
    } = useForm<FormInput>({
        resolver: yupResolver(CONSULTANT_FORM_SCHEMA() as any),
        defaultValues: {
            name: data?.name || '',
            IBCLCNumber: data?.IBCLCNumber?.substring(2) || '',
            IBCLCExpiration: getDateFormatted(data?.IBCLCExpiration),
            IBCLCCertification: data?.IBCLCCertification
                ? getDateFormatted(data?.IBCLCCertification)
                : undefined,
            CAQHNumber: data?.CAQHNumber || '',
            NPINumber: data?.NPINumber || '',
            fileName: data?.fileName || '',
            liabilityInsuranceDate: getDateFormatted(data?.liabilityInsuranceDate),
        },
    });
    const [fileData, setFileData] = useState<string | null>(null);
    const [showFileUploader, setShowFileUploader] = useState(!data?.fileName);
    const [downloadFile, { data: s3File }] = useGetAffiliateInsuranceByKeyLazyQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    useEffect(() => {
        if (data?.s3Link) {
            downloadFile({
                variables: {
                    input: {
                        key: data.s3Link,
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.s3Link]);
    const downloadedFile = useMemo(
        () =>
            generateFileFromString(
                s3File?.getAffiliateInsuranceByKey?.data as unknown as Buffer,
                data?.fileName,
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [s3File?.getAffiliateInsuranceByKey?.filename, data?.fileName],
    );
    useEffect(() => {
        if (downloadedFile) {
            setShowFileUploader(true);
        }
    }, [downloadedFile]);
    const [fileUpload, { loading }] = useUploadAffiliateInsuranceMutation({
        onCompleted: async data => {
            if (data.uploadAffiliateInsurance?.success) {
                const consultant = getValues();
                onComplete(true, {
                    ...consultant,
                    s3Link: data.uploadAffiliateInsurance.s3Link,
                    IBCLCNumber: `L-${consultant.IBCLCNumber}`,
                    IBCLCCertification: consultant.IBCLCCertification
                        ? consultant.IBCLCCertification
                        : null,
                });
            }
        },
    });
    const onSubmit = async (formInput: FormInput) => {
        if (fileData) {
            await fileUpload({
                variables: {
                    input: {
                        file: fileData,
                        fileName: formInput.fileName,
                    },
                },
            });
        } else {
            const consultant = getValues();
            onComplete(true, {
                ...consultant,
                s3Link: data?.s3Link,
                IBCLCNumber: `L-${consultant.IBCLCNumber}`,
                IBCLCCertification: consultant.IBCLCCertification
                    ? consultant.IBCLCCertification
                    : null,
            });
        }
    };
    return (
        <div>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => onComplete(true)}>
                {isEdit ? 'Lactation Consultant (IBCLC)' : 'Add Lactation Consultant (IBCLC)'}
            </DialogTitleWithClose>
            <DialogContent dividers>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justifyContent="flex-start" alignItems="center" rowSpacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label="Full Name"
                                fullWidth
                                margin="dense"
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label="IBCLC Number"
                                fullWidth
                                margin="dense"
                                {...register('IBCLCNumber')}
                                error={!!errors.IBCLCNumber}
                                helperText={errors.IBCLCNumber?.message}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Controller
                                control={control}
                                name="IBCLCExpiration"
                                render={({ field }) => (
                                    <DateInput
                                        label="IBCLC Date of Expiration"
                                        field={field}
                                        error={errors.IBCLCExpiration}
                                        inputProps={{
                                            fullWidth: true,
                                            InputLabelProps: {
                                                shrink: true,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Controller
                                control={control}
                                name="IBCLCCertification"
                                render={({ field }) => (
                                    <DateInput
                                        label="IBCLC Date of Certification (optional)"
                                        field={field}
                                        error={errors.IBCLCCertification}
                                        inputProps={{
                                            fullWidth: true,
                                            InputLabelProps: {
                                                shrink: true,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label="CAQH Number (optional)"
                                fullWidth
                                margin="dense"
                                {...register('CAQHNumber')}
                                error={!!errors.CAQHNumber}
                                helperText={errors.CAQHNumber?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label="NPI Number"
                                fullWidth
                                margin="dense"
                                {...register('NPINumber')}
                                error={!!errors.NPINumber}
                                helperText={errors.NPINumber?.message}
                            />
                        </Grid>
                        <Grid item xs={12} container alignItems="center" rowSpacing={2}>
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth>
                                    <FileUpload
                                        fileData={downloadedFile}
                                        setValue={(name, shouldValidate: boolean) =>
                                            setValue('fileName', name, { shouldValidate })
                                        }
                                        errorMessage={errors.fileName?.message}
                                        onComplete={data => setFileData(data)}
                                        showPreview={mode === FormMode.Portal}
                                        label="Upload Liability Insurance"
                                        showFileUploader={showFileUploader}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Controller
                                    control={control}
                                    name="liabilityInsuranceDate"
                                    render={({ field }) => (
                                        <DateInput
                                            label="Liability Insurance Date of Expiration"
                                            field={field}
                                            error={errors.liabilityInsuranceDate}
                                            inputProps={{
                                                fullWidth: true,
                                                InputLabelProps: {
                                                    shrink: true,
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions className={classes.buttonContainer}>
                <Grid item>
                    <AsyncActionButton loading={loading}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={() => handleSubmit(onSubmit)()}
                            disabled={loading || !showFileUploader}
                        >
                            {isEdit
                                ? 'Save Lactation Consultant (IBCLC)'
                                : 'Add Lactation Consultant (IBCLC)'}
                        </Button>
                    </AsyncActionButton>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => onComplete(true)}
                        color="primary"
                        variant="outlined"
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                </Grid>
            </DialogActions>
        </div>
    );
};

export default ConsultantForm;

export { FormMode };
export type { FormInput };
