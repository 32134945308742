import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, Grid, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import { FormProvider, useForm as useFormReact } from 'react-hook-form';
import Loading from '~/components/Loading/Loading';
import {
    AlertSeverity,
    useCheckEligibilityVirtualCareFormMutation,
    useCreateOrUpdateVirtualCareFormMutation,
    usePatientDataForEligibilityRecheckLazyQuery,
} from '~/schemaTypes';
import { SuppressNextGlobalAlert, TriggerGlobalAlert, TriggerGlobalBanner } from '~/state';
import moment from 'moment';
import { isEqual, omit } from 'lodash';
import { Close } from '@mui/icons-material';
import { useVCFContext } from '~/views/VirtualCareSurvey/hooks/useVCF';
import { VirtualCarePatientInfoInput, VirtualCareSurveyInput } from './types';
import VirtualCareSurveySchemas from './yupSchema';
import PatientInfo from './steps/PatientInfo';
import { preparePayload } from './helpers';
import useStyles from './styles';
import VirtualCareSurveyWrapper from './components/VirtualCareSurveyWrapper';
import { DOULA_ELIGIBILITY_DENIED_MSG, GENERAL_ELIGIBILITY_DENIED_MSG } from './constants';

SuppressNextGlobalAlert(true);

type EligibilityRecheckModalProps = {
    isOpen: boolean;
    onClose: (recheckSuccess: boolean) => void;
    patientId: string;
};

const ELIGIBILITY_RECHECK_DESCRIPTION =
    'Verify the information below, then submit to refresh eligibility information.';

export const EligibilityRecheckModal: React.FC<EligibilityRecheckModalProps> = ({
    isOpen,
    onClose,
    patientId,
}) => {
    const { classes } = useStyles();
    const [showLoading, setShowLoading] = React.useState<boolean>(true);

    const formProps = useFormReact<VirtualCareSurveyInput>({
        resolver: yupResolver(VirtualCareSurveySchemas(false, false)[0] as any),
    });
    const { handleSubmit, setValue, watch } = formProps;
    const [selfSelected, setSelfSelected] = React.useState<boolean>(true);
    const vcfProps = useVCFContext();
    const [requestPatientData, { data: patientData, loading: patientDataLoading }] =
        usePatientDataForEligibilityRecheckLazyQuery({
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
        });

    useEffect(() => {
        if (isOpen) {
            requestPatientData({
                variables: { input: { id: patientId } },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const [firstName, lastName, sex, birthDate] = watch([
        'firstName',
        'lastName',
        'sex',
        'birthDate',
    ]);

    useEffect(() => {
        if (selfSelected) {
            setValue('insuredFirstName', firstName);
            setValue('insuredLastName', lastName);
            setValue('subscriberSex', sex);
            setValue('subscriberBirthDate', birthDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstName, lastName, sex, birthDate, selfSelected]);

    const [checkEligibility, { loading: checkEligibilityLoading }] =
        useCheckEligibilityVirtualCareFormMutation({
            onCompleted: data => {
                if (
                    !data.checkVCFEligibility?.isEligible &&
                    data.checkVCFEligibility?.message === 'Eligibility failed'
                ) {
                    TriggerGlobalBanner({
                        open: true,
                        severity: AlertSeverity.Error,
                        actionText: '',
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        callback: () => {},
                        message: vcfProps.isDoulaView
                            ? DOULA_ELIGIBILITY_DENIED_MSG
                            : GENERAL_ELIGIBILITY_DENIED_MSG,
                    });
                    return;
                }
                if (!data.checkVCFEligibility?.isEligible && data.checkVCFEligibility?.message) {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Error,
                        message: data.checkVCFEligibility?.message,
                    });
                }
            },
        });

    const [createOrUpdate, { loading: createVCFLoading }] =
        useCreateOrUpdateVirtualCareFormMutation({
            onCompleted: data => {
                if (data.createOrUpdateVirtualCareForm?.data) {
                    const { firstName, lastName, email, birthDate, patientId } =
                        data.createOrUpdateVirtualCareForm.data;
                    if (!firstName || !lastName || !email || !patientId || !birthDate) {
                        TriggerGlobalAlert({
                            severity: AlertSeverity.Error,
                            message: 'Something went wrong. Please try again.',
                        });
                        return;
                    }
                    onClose(true);
                }
            },
            onError: error => {
                setShowLoading(false);
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: error.message,
                });
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                'AffiliateCarePatient',
                'VirtualCareAffiliatePatients',
                'FetchPatientsForPatientsPageQuery',
            ],
        });

    const onSubmit = async (data: VirtualCarePatientInfoInput) => {
        const prefillData = patientData?.patient;
        if (!prefillData) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'Patient data failed to load',
            });
            return;
        }

        const insuredAddressSameAsPatient = isEqual(
            prefillData.insuredAddress,
            prefillData.mailingAddress,
        );
        const completeData: VirtualCareSurveyInput = {
            ...data,
            addressLine1: prefillData.mailingAddress?.street1 ?? '',
            addressLine2: prefillData.mailingAddress?.street1 ?? '',
            state: prefillData.mailingAddress?.state ?? '',
            city: prefillData.mailingAddress?.city ?? '',
            zipCode: prefillData.mailingAddress?.code ?? '',
            insuredAddressLine1: prefillData.insuredAddress?.street1 ?? '',
            insuredAddressLine2: prefillData.insuredAddress?.street2 ?? '',
            insuredState: prefillData.insuredAddress?.state ?? '',
            insuredCity: prefillData.insuredAddress?.city ?? '',
            insuredZipCode: prefillData.insuredAddress?.code ?? '',
            insuredAddressSameAsPatient,
            ...(prefillData.virtualCareBabyData?.dueDate && {
                babyDueDate: prefillData.virtualCareBabyData.dueDate,
            }),
            ...(prefillData.virtualCareBabyData?.birthDate && {
                babyBirthDate: prefillData.virtualCareBabyData.birthDate,
            }),
            ...(prefillData.virtualCareBabyData?.firstName && {
                babyFirstName: prefillData.virtualCareBabyData.firstName,
            }),
            ...(prefillData.virtualCareBabyData?.lastName && {
                babyLastName: prefillData.virtualCareBabyData.lastName,
            }),
            ...(prefillData.virtualCareBabyData?.multiples && {
                babyMultiples: prefillData.virtualCareBabyData.multiples,
            }),
            ...(prefillData.virtualCareBabyData?.sex && {
                babyMultiples: prefillData.virtualCareBabyData.sex,
            }),
            ...(prefillData.virtualCareBabyData?.arrived && {
                babyArrived: prefillData.virtualCareBabyData.arrived,
            }),
        };
        const payload = preparePayload({
            formData: completeData,
            insuredAddressSameAsPatient: true,
            isAffiliateView: false,
            isDoulaView: vcfProps.isDoulaView,
            affiliateData: { selfCheck: false },
        });
        SuppressNextGlobalAlert(true);
        const result = await checkEligibility({
            variables: {
                input: payload,
            },
        });

        const insurancePlan = result.data?.checkVCFEligibility?.insurancePlan;
        if (!insurancePlan) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'Failed to retrieve insurance plan data',
            });
            return;
        }
        SuppressNextGlobalAlert(true);
        createOrUpdate({
            variables: {
                input: { ...payload, insurancePlan: omit(insurancePlan, '__typename') },
            },
        });
    };

    useEffect(() => {
        const prefillData = patientData?.patient;

        type SetValueParams = Parameters<typeof setValue>;
        const setValueIfExists = (name: SetValueParams[0], value?: SetValueParams[1] | null) =>
            value && setValue(name, value);

        if (prefillData) {
            setValueIfExists('firstName', prefillData.firstName);
            setValueIfExists('lastName', prefillData.lastName);
            setValue('birthDate', moment(new Date(prefillData.birthDate)).format('MM/DD/YYYY'));
            setValueIfExists('email', prefillData.email);
            setValueIfExists('sex', prefillData.gender);
            setValueIfExists('insuranceId', prefillData.insuranceId);
            setValueIfExists('insuranceGroup', prefillData.insuranceName);
            setValueIfExists('phoneNumber', prefillData.virtualCarePhoneNumber);

            const isSelfSelected = prefillData.patientRelationshipToInsured === 'self';
            setValueIfExists(
                'patientRelationshipToInsured',
                prefillData.patientRelationshipToInsured,
            );

            if (!isSelfSelected) {
                setValueIfExists('insuredFirstName', prefillData.insuredFirstName);
                setValueIfExists('insuredLastName', prefillData.insuredLastName);
                setValueIfExists(
                    'subscriberBirthDate',
                    moment(new Date(prefillData.subscriberBirthDate)).format('MM/DD/YYYY'),
                );
                setValueIfExists('subscriberSex', prefillData.subscriberSex);
            }

            setSelfSelected(isSelfSelected);
            setShowLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientData]);

    return (
        <Dialog
            scroll="paper"
            onClose={(_, reason) => reason !== 'backdropClick' && onClose(false)}
            open={isOpen}
            fullWidth
            maxWidth="lg"
        >
            <IconButton className={classes.closeButton} onClick={() => onClose(false)}>
                <Close />
            </IconButton>
            {showLoading || patientDataLoading ? (
                <Loading />
            ) : (
                <VirtualCareSurveyWrapper
                    formDescriptionText={ELIGIBILITY_RECHECK_DESCRIPTION}
                    showFormDescription
                >
                    <Grid item xs={12}>
                        <form className={classes.fullHeight}>
                            <Grid
                                className={classes.fullHeight}
                                alignItems="flex-start"
                                container
                                rowSpacing={{ md: 1, xs: 0 }}
                            >
                                <FormProvider {...formProps}>
                                    <PatientInfo
                                        stepHandler={handleSubmit(onSubmit)}
                                        setSelfSelected={setSelfSelected}
                                        stepLoading={checkEligibilityLoading || createVCFLoading}
                                        selfSelected={selfSelected}
                                        selfCheck={false}
                                        isEligibilityRecheck
                                    />
                                </FormProvider>
                            </Grid>
                        </form>
                    </Grid>
                </VirtualCareSurveyWrapper>
            )}
        </Dialog>
    );
};
