import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
} from '@mui/material';
import omitDeep from 'omit-deep-lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import _ from 'lodash';
import {
    CarePlansForCarePlansPageDocument,
    CarePlansForCarePlansPageQuery,
    CarePlanType,
    useCarePlanForCarePlanPageLazyQuery,
    useCarePlansForAddPageQuery,
    useCreateCarePlanForNewCarePlanPageMutation,
} from '~/schemaTypes';
import { CarePlanEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';
import { errorRed } from '~/theme/WildTheme';
import { toFormValuesFromCarePlan } from '../CarePlan/helpers';
import { formValuesToUpdateInput } from '../CarePlan/helpers/toRequestInput';
import { CarePlan, CarePlanInput } from '../CarePlan/types';

enum NewCarePlanType {
    BaseCarePlan = 'Base Care Plan',
    DerivedCarePlan = 'Derived Care Plan',
}

export const AddCarePlanModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
}> = props => {
    const history = useNavigate();
    const { isOpen, onClose } = props;
    const [selectedCarePlan, setSelectedCarePlan] = useState<CarePlan | null>(null);
    const [labelUsed, setLabelUsed] = useState(false);
    const [labelEmpty, setLabelEmpty] = useState(false);
    const [carePlanType, setCarePlanType] = useState(NewCarePlanType.BaseCarePlan);
    const [baseCarePlanId, setBaseCarePlanId] = useState('');
    const [label, setLabel] = useState('');

    const { data: carePlanList, loading: carePlanListLoading } = useCarePlansForAddPageQuery({
        variables: {
            input: { filter: { fields: { type: CarePlanType.Base } } },
        },
    });

    const [getCarePlan, { data: carePlanData, loading: carePlanLoading }] =
        useCarePlanForCarePlanPageLazyQuery();

    const { control, handleSubmit } = useForm({});

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if (baseCarePlanId) {
            getCarePlan({ variables: { carePlanInput: { id: baseCarePlanId } } });
        }
    }, [getCarePlan, baseCarePlanId]);

    useEffect(() => {
        if (carePlanData && carePlanData.carePlan) {
            setSelectedCarePlan({ ...carePlanData.carePlan });
            setLabel(carePlanData.carePlan?.portalLabel?.en || '');
        }
    }, [carePlanData]);

    const [createCarePlan, { loading: createCarePlanLoading }] =
        useCreateCarePlanForNewCarePlanPageMutation({
            onCompleted: ({ createCarePlan }) => {
                history(`/app-config/care-plans/${createCarePlan?.resourceCreated?.id}`);
                handleClose();
            },
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        handleClose();
                    },
                    message: `There was a problem saving the care plan: ${error.message}`,
                });
            },
            update: (cache, response) => {
                const newCarePlan = response.data?.createCarePlan?.resourceCreated;
                if (response.data?.createCarePlan?.success && newCarePlan) {
                    const currentCarePlans = cache.readQuery<CarePlansForCarePlansPageQuery>({
                        query: CarePlansForCarePlansPageDocument,
                    });
                    if (currentCarePlans?.carePlansV2?.results) {
                        cache.writeQuery<CarePlansForCarePlansPageQuery>({
                            query: CarePlansForCarePlansPageDocument,
                            data: {
                                carePlansV2: {
                                    __typename: currentCarePlans.carePlansV2.__typename,
                                    results: [...currentCarePlans.carePlansV2.results, newCarePlan],
                                },
                            },
                        });
                    }
                }
            },
        });

    const onSubmit = () => {
        if (carePlanType === NewCarePlanType.BaseCarePlan) {
            history('/app-config/care-plans/new');
        } else {
            setLabelUsed(false);
            setLabelEmpty(false);

            if (label === '') {
                setLabelEmpty(true);
            } else if (
                carePlanList &&
                carePlanList.carePlansV2.results.filter(
                    t => t?.portalLabel?.en.toLowerCase() === label.toLowerCase(),
                ).length > 0
            ) {
                setLabelUsed(true);
            } else {
                if (selectedCarePlan === null) {
                    return;
                }

                const { groups: todoGroupFields } = selectedCarePlan;
                const carePlanFormValues = toFormValuesFromCarePlan(selectedCarePlan);
                const resultingCarePlan = formValuesToUpdateInput(
                    carePlanFormValues,
                    todoGroupFields.map((group, index) => ({
                        ...group,
                        ...carePlanFormValues.groups[index],
                    })),
                );
                const cleared = omitDeep(resultingCarePlan, '__typename') as CarePlanInput;

                createCarePlan({
                    variables: {
                        input: {
                            ...cleared,
                            portalLabel: {
                                en: label,
                            },
                            type: CarePlanType.Derived,
                            baseCarePlanId,
                        },
                    },
                });
            }
        }
    };
    return (
        <Dialog open={isOpen} data-test={CarePlanEnum.ADD_CARE_PLAN_MODAL}>
            <DialogTitleWithClose id="modalTitle" onClose={handleClose}>
                Add New Care Plan
            </DialogTitleWithClose>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    {carePlanListLoading || carePlanLoading || createCarePlanLoading ? (
                        <Loading height={140} />
                    ) : (
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="type"
                                    defaultValue=""
                                    render={({ field: { onChange } }) => (
                                        <Autocomplete
                                            size="small"
                                            onChange={(_, val) => {
                                                onChange(val);
                                                setCarePlanType(
                                                    val || NewCarePlanType.BaseCarePlan,
                                                );
                                            }}
                                            // open={!''}
                                            getOptionLabel={selected => selected}
                                            options={Object.values(NewCarePlanType)}
                                            isOptionEqualToValue={(option, val) => option === val}
                                            value={carePlanType}
                                            renderInput={params => (
                                                <TextField
                                                    variant="outlined"
                                                    margin="dense"
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    {...params}
                                                    placeholder="Select Type"
                                                    label="Type"
                                                    InputLabelProps={{ shrink: true }}
                                                    data-test={CarePlanEnum.MODAL_TYPE_DROPDOWN}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                            {carePlanType === NewCarePlanType.DerivedCarePlan && (
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="type"
                                        defaultValue=""
                                        render={({ field: { onChange } }) => (
                                            <Autocomplete
                                                size="small"
                                                onChange={(_, val) => {
                                                    onChange(val);
                                                    setBaseCarePlanId(val || '');
                                                }}
                                                options={
                                                    _.sortBy(
                                                        carePlanList?.carePlansV2?.results,
                                                        'portalLabel.en',
                                                    )?.map((item: any) => item.id) ?? []
                                                }
                                                getOptionLabel={id =>
                                                    carePlanList?.carePlansV2?.results?.find(
                                                        (item: any) => item.id === id,
                                                    )?.portalLabel?.en ?? ''
                                                }
                                                isOptionEqualToValue={(option, val) =>
                                                    option === val
                                                }
                                                value={baseCarePlanId}
                                                renderInput={params => (
                                                    <TextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                                        {...params}
                                                        placeholder="Select Base Care Plan"
                                                        label="Base Care Plan"
                                                        InputLabelProps={{ shrink: true }}
                                                        data-test={
                                                            CarePlanEnum.BASE_CARE_PLAN_DROPDOWN
                                                        }
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                            {baseCarePlanId && carePlanType === NewCarePlanType.DerivedCarePlan && (
                                <Grid item xs={12}>
                                    <Controller
                                        control={control}
                                        name="profileValue"
                                        defaultValue=""
                                        render={({ field: { onChange } }) => (
                                            <TextField
                                                variant="outlined"
                                                name="label"
                                                label="Label for New Care Plan"
                                                type="text"
                                                margin="dense"
                                                defaultValue={selectedCarePlan?.portalLabel?.en}
                                                value={label}
                                                onChange={e => {
                                                    onChange(e.currentTarget.value);
                                                    setLabel(e.currentTarget.value);
                                                }}
                                                fullWidth
                                                data-test={CarePlanEnum.DERIVED_LABEL}
                                            />
                                        )}
                                    />
                                    {labelUsed && (
                                        <div style={{ color: errorRed }}>
                                            Name already used. Please change.
                                        </div>
                                    )}
                                    {labelEmpty && (
                                        <div style={{ color: errorRed }}>
                                            Label is empty. Please change.
                                        </div>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={() => handleSubmit(onSubmit)}
                        data-test={CarePlanEnum.MODAL_ADD_BUTTON}
                    >
                        Add Care Plan
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
