import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import AffiliateCareClaimsBuilder from '~/views/AffiliateCare/ClaimsBuilder/ClaimsBuilder';
import Loading from '~/components/Loading/Loading';
import Navigation, { TabsConfig } from './Navigation';
import { useAffiliate } from './hooks';

const ClaimBuilder = () => {
    const { affiliateId = '' } = useParams();
    const { affiliate } = useAffiliate(affiliateId);
    if (!affiliate) {
        return <Loading />;
    }
    return (
        <Grid container>
            <Navigation
                currentTab={TabsConfig.builder}
                affiliateId={affiliate.affiliateId}
                id={affiliate.id}
                name={affiliate.internalName}
            />
            <Grid item container xs={12}>
                <AffiliateCareClaimsBuilder affiliateId={affiliateId} portalView />
            </Grid>
        </Grid>
    );
};

export default ClaimBuilder;
