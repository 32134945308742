import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ObjectId from 'bson-objectid';
import React, { ReactElement, useCallback } from 'react';
import Draggable from '~/components/Draggable/Draggable';
import Droppable from '~/components/Droppable/Droppable';
import { OnboardingScreenEnum } from '~/selectors';
import parse from 'html-react-parser';

export type ListItemType = {
    label?: {
        en: string;
        es?: string | null;
    };
    id?: string | ObjectId;
};

export type SortableListOnChangeProps = {
    startIndex: number;
    endIndex: number;
};

type SortableListProps<T extends ListItemType> = {
    list: T[];
    onChange: (props: SortableListOnChangeProps) => void;
    onDelete: (index: number) => void;
    onClick?: (item: T, index: number) => void;
    droppableId: string;
    children?(index: number, item: T): ReactElement;
};

const EMPTY_LIST_ITEM_TEXT = 'No title';

const SortableList: React.FC<SortableListProps<ListItemType>> = ({
    list,
    onChange,
    onDelete,
    onClick,
    droppableId,
    children,
}): ReactElement | null => {
    const onDragEnd = useCallback(
        (result: { source: any; destination: any }) => {
            const { source, destination } = result;
            const hasSource = source && source.index != null;
            const hasDestination = destination && destination.index != null;
            const hasData = hasSource && hasDestination;
            const shouldMoveItem = hasData && source.index !== destination.index;
            if (!shouldMoveItem) {
                return;
            }

            if (onChange) {
                onChange({ startIndex: result.source.index, endIndex: result.destination.index });
            }
        },
        [onChange],
    );

    function handleSurveyGroupDelete(index: number) {
        return () => onDelete(index);
    }

    if (list.length < 1) return null;
    return (
        <List>
            <Droppable onDragEnd={onDragEnd} droppableId={droppableId}>
                {list?.map((item, index) => {
                    if (!item?.id) return null;
                    return (
                        <Draggable id={item.id.toString()} index={index} key={item.id.toString()}>
                            <ListItem data-test={item?.label?.en}>
                                {children && children(index, item)}
                                <ListItemIcon
                                    style={{
                                        minWidth: 'auto',
                                        cursor: 'pointer',
                                        marginRight: '20px',
                                    }}
                                    onClick={handleSurveyGroupDelete(index)}
                                    data-test={OnboardingScreenEnum.SCREEN_ORDER_DELETE}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </ListItemIcon>
                                <ListItemIcon
                                    style={{
                                        minWidth: 'auto',
                                        cursor: 'pointer',
                                        marginRight: '20px',
                                    }}
                                    onClick={() => onClick && onClick(item, index)}
                                    data-test={OnboardingScreenEnum.SCREEN_ORDER_TEXT}
                                >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        item.label?.en
                                            ? parse(item.label?.en)
                                            : `${EMPTY_LIST_ITEM_TEXT} #${index + 1}`
                                    }
                                    // data-test={OnboardingScreenEnum.SCREEN_ORDER_TEXT}
                                />
                                <ListItemSecondaryAction>
                                    <ListItemIcon className="drag-handle">
                                        <DragHandleIcon />
                                    </ListItemIcon>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Draggable>
                    );
                })}
            </Droppable>
        </List>
    );
};

export default SortableList;
