import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ImageContentPicker from '~/components/ImageContentPicker/ImageContentPicker';
import _ from 'lodash';
import { TaggedImageParams } from '../types';

const useStyles = makeStyles()(() => ({
    paper: { minWidth: '800px' },
    select: { width: '100%' },
}));

export const TaggedImageModal: React.FC<TaggedImageParams> = ({
    closeHandler,
    submitHandler,
    tags,
}) => {
    const { classes } = useStyles();

    const [selectedImageId, setSelectedImageId] = useState<string>('');
    const [selectedImageName, setSelectedImageName] = useState<string>('');
    const [selectedTagId, setSelectedTagId] = useState<string>(_.sortBy(tags, 'name')[0].id);
    const [selectedTagName, setSelectedTagName] = useState<string>(_.sortBy(tags, 'name')[0].name);
    const onSubmit = () => {
        if (selectedImageId !== '')
            submitHandler({
                tagId: selectedTagId,
                tagName: selectedTagName,
                imageContentId: selectedImageId,
                imageName: selectedImageName,
            });
    };

    return (
        <Dialog open classes={{ paper: classes.paper }}>
            <DialogTitleWithClose id="modalTitle" onClose={closeHandler}>
                Add Tagged Image
            </DialogTitleWithClose>
            <DialogContent>
                <OutlinedSection title="Select Tag">
                    <select
                        onChange={e => {
                            setSelectedTagId(e.target.value);
                            setSelectedTagName(e.target.options[e.target.selectedIndex].text);
                        }}
                    >
                        {_.sortBy(tags, 'name').map(t => (
                            <option key={t.id} value={t.id}>
                                {t.name}
                            </option>
                        ))}
                    </select>
                </OutlinedSection>
                <ImageContentPicker
                    selectedImageContentId={selectedImageId}
                    setSelectedImageContentId={setSelectedImageId}
                    setSelectedImageContentName={setSelectedImageName}
                    required
                />
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={closeHandler} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={onSubmit} color="secondary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
