import { FetchCurrentUserForUseUserHookQuery } from '~/schemaTypes';

enum HistoryChangeTypeEnum {
    UPDATED = 'Updated',
    CREATED = 'Created',
}

export const createHistory = (
    updateData: any,
    userData: FetchCurrentUserForUseUserHookQuery | undefined,
    initialProfileDefinitionState?: any,
) =>
    Object.entries(updateData)
        .filter(([key, value]) => {
            if (!initialProfileDefinitionState) {
                return !!value;
            }
            if (!initialProfileDefinitionState[key] && !value) return false;
            if (typeof value === 'object')
                return JSON.stringify(value) !== JSON.stringify(initialProfileDefinitionState[key]);
            return initialProfileDefinitionState[key] !== value;
        })
        .map(([key, value]) => {
            let changeType = HistoryChangeTypeEnum.UPDATED;
            const valueWasEmpty =
                !initialProfileDefinitionState ||
                !initialProfileDefinitionState[key] ||
                (typeof initialProfileDefinitionState[key] === 'object' &&
                    Object.keys(initialProfileDefinitionState[key]).length === 0);
            if (valueWasEmpty) {
                changeType = HistoryChangeTypeEnum.CREATED;
            }
            return {
                afterState: JSON.stringify(value),
                beforeState: initialProfileDefinitionState
                    ? JSON.stringify(initialProfileDefinitionState[key])
                    : '',
                datetime: new Date(),
                name: key,
                type: changeType,
                userId: userData?.currentUser?.id,
                username: userData?.currentUser?.name,
            };
        });
