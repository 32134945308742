import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    TextField,
} from '@mui/material';
import { Add as AddIcon, Save as SaveIcon } from '@mui/icons-material';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import { IndexedMemberType, MemberType, MemberTypeModalProps } from '../types';
import { useStyles } from './styles';
import CARE_TEAM_TYPE_SCHEMA from './validationSchema';

const initialModalData = {
    label: {
        en: '',
        es: undefined,
    },
    id: '',
    hiddenToPhone: false,
    readOnly: false,
    isRequired: false,
};

const MemberTypeModal: React.FC<MemberTypeModalProps> = ({
    isEditMode,
    data,
    closeModal,
    onSubmit,
    careTeamMemberTypes,
    selectedMemberTypeIds,
    setSelectedMemberTypeIds,
}) => {
    const { classes } = useStyles();
    const [memberType = null, index = null] = data || [];
    const [previousSelectedTypeId, setPreviousSelectedTypeId] = useState<string>('');

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<MemberType>({
        resolver: yupResolver(CARE_TEAM_TYPE_SCHEMA as any),
        defaultValues: memberType || initialModalData,
    });

    const toSubmitInput = (form: MemberType, index: number | null): IndexedMemberType => [
        form,
        index,
    ];

    const onModalSubmit: SubmitHandler<MemberType> = form => onSubmit(toSubmitInput(form, index));

    const handleCareTeamMemberTypeClick = (id: string) => {
        setSelectedMemberTypeIds(state => {
            const nextState = state.filter(id =>
                previousSelectedTypeId ? id !== previousSelectedTypeId : id !== memberType?.id,
            );
            nextState.push(id);
            return nextState;
        });
        setPreviousSelectedTypeId(id);
    };

    const handleCancel = () => closeModal();

    return (
        <form className={classes.root} onSubmit={handleSubmit(onModalSubmit)}>
            <DialogTitleWithClose id="form-dialog-title" onClose={handleCancel}>
                {isEditMode ? 'Edit' : 'Add'} Member Type
            </DialogTitleWithClose>
            <DialogContent className="formCont">
                <Grid container>
                    <Grid item xs={12}>
                        <ReactHookFormSelect
                            control={control}
                            name="id"
                            variant="outlined"
                            defaultValue={
                                isEditMode
                                    ? careTeamMemberTypes.find(type => type.id === memberType?.id)
                                          ?.id
                                    : ''
                            }
                            label="Select Care Team Member Type *"
                            fullWidth
                            margin="dense"
                            error={Boolean(errors.id)}
                        >
                            {careTeamMemberTypes.map(({ name, id }) => (
                                <MenuItem
                                    key={id}
                                    value={id}
                                    disabled={selectedMemberTypeIds.includes(id)}
                                    onClick={() => handleCareTeamMemberTypeClick(id)}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                        {errors?.id && (
                            <FormHelperText error className={classes.helperMessage}>
                                {errors?.id?.message}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedSection title="Label" className={classes.labelWrapper}>
                            <TextField
                                variant="outlined"
                                label="English *"
                                fullWidth
                                margin="dense"
                                {...register('label.en', { required: true })}
                                defaultValue={isEditMode ? memberType?.label?.en : ''}
                                error={Boolean(errors.label)}
                                helperText={errors.label?.en?.message}
                            />
                            <TextField
                                variant="outlined"
                                label="Spanish"
                                fullWidth
                                margin="dense"
                                {...register('label.es')}
                                defaultValue={isEditMode ? memberType?.label?.es : ''}
                            />
                        </OutlinedSection>
                    </Grid>
                    <Grid item>
                        <Controller
                            name="hiddenToPhone"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <FormControlLabel
                                    label="Hidden To Phone"
                                    control={
                                        <Checkbox
                                            checked={value}
                                            onChange={e => onChange(e.target.checked)}
                                        />
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <Controller
                            name="readOnly"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <FormControlLabel
                                    label="Read-Only"
                                    control={
                                        <Checkbox
                                            checked={value}
                                            onChange={e => onChange(e.target.checked)}
                                        />
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <Controller
                            name="isRequired"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <FormControlLabel
                                    label="Is Required"
                                    control={
                                        <Checkbox
                                            checked={value}
                                            onChange={e => onChange(e.target.checked)}
                                        />
                                    }
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    startIcon={isEditMode ? <SaveIcon /> : <AddIcon />}
                    type="submit"
                    color="secondary"
                    variant="contained"
                >
                    {isEditMode ? 'Save' : 'Create'}
                </Button>
            </DialogActions>
        </form>
    );
};

export default MemberTypeModal;
