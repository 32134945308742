/* eslint-disable camelcase */
import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    TextField,
    Typography,
    Box,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import {
    ConfigValueType,
    ExpressionUseType,
    FetchConfigValueDefsForExpressionPageQuery,
    FetchProfileDefsForExpressionPageQuery,
    UserProfileValueType,
} from '~/schemaTypes';
import { ExpressionEnum } from '~/selectors';
import {
    CompareType,
    operator,
    Rule,
    ExpressionMeasurementType,
    Symptom,
    SYMPTOMS,
} from '../ConfigExpression/ConfigExpression';

type ConfigValueDefs = NonNullable<
    FetchConfigValueDefsForExpressionPageQuery['configValueDefs'][0]
>;
type UserProfileDefs = NonNullable<FetchProfileDefsForExpressionPageQuery['userProfileDefs'][0]>;
type UserProfileDefChoices = NonNullable<
    NonNullable<FetchProfileDefsForExpressionPageQuery['userProfileDefs'][0]>['choices']
>[0];

const formValuesFromData = (instance: Rule) => {
    return {
        textValue: instance.textValue,
        bool: instance.bool,
        num: instance.num,
    };
};
const configTypeMatch = (config: ConfigValueDefs, valueType: string): boolean => {
    return (
        (config.valueType === ConfigValueType.Bool && valueType === 'bool') ||
        (config.valueType === ConfigValueType.Num && valueType === 'number') ||
        (config.valueType === ConfigValueType.Str && valueType === 'string')
    );
};
const profileTypeMatch = (
    profile: UserProfileDefs,
    compareType: CompareType,
    op: operator,
): boolean => {
    if (
        (compareType === CompareType.DaysBeforeProfileToValue ||
            compareType === CompareType.DaysAfterProfileToValue ||
            compareType === CompareType.DaysAfterProfileToConfig ||
            compareType === CompareType.DaysBeforeProfileToConfig ||
            compareType === CompareType.DaysBeforeProfileToProfile ||
            compareType === CompareType.DaysAfterProfileToProfile) &&
        profile.valueType !== UserProfileValueType.Date
    ) {
        return false;
    }
    if (
        compareType !== CompareType.ProfileToProfile &&
        !(
            compareType === CompareType.DaysBeforeProfileToValue ||
            compareType === CompareType.DaysAfterProfileToValue ||
            compareType === CompareType.DaysAfterProfileToConfig ||
            compareType === CompareType.DaysBeforeProfileToConfig ||
            compareType === CompareType.DaysBeforeProfileToProfile ||
            compareType === CompareType.DaysAfterProfileToProfile
        ) &&
        op !== operator.eq &&
        op !== operator.missing &&
        profile.valueType === UserProfileValueType.Date
    ) {
        return false;
    }
    if (compareType === CompareType.ProfileToValue) {
        return true;
    }
    if (
        profile.valueType === UserProfileValueType.Choice ||
        profile.valueType === UserProfileValueType.Choices
    ) {
        return false;
    }

    return true;
};
const profile2TypeMatch = (
    compareToId: string | undefined,
    profile: UserProfileDefs,
    valueType: string,
): boolean => {
    return (
        profile.id !== compareToId &&
        profile.valueType !== UserProfileValueType.Choice &&
        ((profile.valueType === UserProfileValueType.Bool && valueType === 'bool') ||
            (profile.valueType === UserProfileValueType.Num && valueType === 'number') ||
            (profile.valueType === UserProfileValueType.Str && valueType === 'string') ||
            (profile.valueType === UserProfileValueType.Date && valueType === 'date'))
    );
};
const valueTypeFromRule = (rule: Rule) => {
    if (rule.bool !== undefined) return 'bool';
    if (rule.num !== undefined) return 'number';
    if (rule.textValue) return 'string';
    if (rule.choiceId) return 'choice';
    return '';
};
const valueTypeFromProfileType = (upType: UserProfileValueType) => {
    if (upType === UserProfileValueType.Bool) return 'bool';
    if (upType === UserProfileValueType.Num) return 'number';
    if (upType === UserProfileValueType.Str) return 'string';
    if (upType === UserProfileValueType.Choice || upType === UserProfileValueType.Choices) {
        return 'choice';
    }
    if (upType === UserProfileValueType.Date) {
        return 'date';
    }
    return '';
};
const RuleModal: React.FC<{
    useType: ExpressionUseType;
    addMode: boolean;
    rule: Rule;
    parentId?: number;
    appConfigs?: ConfigValueDefs[];
    profiles?: UserProfileDefs[];
    closeHandler: () => void;
    submitHandler: (rule: Rule, parentId?: number) => void;
}> = props => {
    const { useType, addMode, parentId, rule, appConfigs, profiles, closeHandler, submitHandler } =
        props;
    const [selectedType, setSelectedType] = useState<CompareType>(rule.compareType);
    const [selectedAppConfigId, setSelectedAppConfigId] = useState(rule.appConfigId);
    const [selectedProfileId, setSelectedProfileId] = useState(rule.userProfileId);
    const [selectedProfileId2, setSelectedProfileId2] = useState(rule.userProfileIdTo);
    const [choices, setChoices] = useState<(UserProfileDefChoices | null)[]>();
    const [selectedChoiceId, setSelectedChoiceId] = useState(rule.choiceId);
    const [valueType, setValueType] = useState(valueTypeFromRule(rule));
    const [measurementType, setMeasurementType] = useState<ExpressionMeasurementType | null>(null);
    const [symptom, setSymptom] = useState<Symptom | null>(null);

    useEffect(() => {
        if (rule.userProfileId) {
            const profile = profiles?.find(p => p.id === rule.userProfileId);
            if (profile) {
                setValueType(valueTypeFromProfileType(profile?.valueType));
                if (profile.choices) setChoices(profile.choices);
            }
        }
    }, [rule, profiles]);

    const handleAppConfigChange = (id: string) => {
        if (id === '') return;
        setSelectedAppConfigId(id);
        setValueType('');
        if (rule.op !== operator.missing) {
            const appConfig = appConfigs?.find(ac => ac.id === id);
            if (appConfig) {
                if (appConfig.valueType === ConfigValueType.Bool) setValueType('bool');
                if (appConfig.valueType === ConfigValueType.Num) setValueType('number');
                if (appConfig.valueType === ConfigValueType.Str) setValueType('string');
            }
        }
    };

    const handleProfileChange = (id: string) => {
        if (id === '') return;
        setSelectedProfileId(id);
        if (rule.op !== operator.missing) {
            const profile = profiles?.find(p => p.id === id);
            if (profile) {
                if (profile.valueType === UserProfileValueType.Bool) setValueType('bool');
                if (profile.valueType === UserProfileValueType.Num) setValueType('number');
                if (profile.valueType === UserProfileValueType.Str) setValueType('string');
                if (profile.valueType === UserProfileValueType.Date) setValueType('date');
                if (
                    profile.valueType === UserProfileValueType.Choice ||
                    profile.valueType === UserProfileValueType.Choices
                )
                    setValueType('choice');
                if (profile.choices) setChoices(profile.choices);
            } else {
                setValueType('');
            }
        }
    };
    const handleProfile2Change = (id: string) => {
        if (id === '') return;
        setSelectedProfileId2(id);
    };
    const handleTypeChange = (event: any) => {
        setSelectedType(event.target.value);
        if (event.target.value === CompareType.SumOfValues) {
            setValueType('number');
        } else {
            setValueType('');
        }

        if (
            event.target.value !== CompareType.TrackerToConfig &&
            event.target.value !== CompareType.TrackerToProfile &&
            event.target.value !== CompareType.TrackerToValue
        ) {
            setMeasurementType(null);
        }

        if (event.target.value !== CompareType.SymptomToValue) {
            setSymptom(null);
        }

        setSelectedAppConfigId(undefined);
        setSelectedProfileId(undefined);
        setSelectedProfileId2(undefined);
        setSelectedChoiceId(undefined);
    };
    const handleChoiceChange = (event: any) => {
        setSelectedChoiceId(event.target.value);
    };
    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<Rule>({
        defaultValues: formValuesFromData(rule),
    });
    const onSubmit = (nameVariables: any) => {
        const data = { ...nameVariables };
        if (
            selectedType === CompareType.ConfigToValue ||
            selectedType === CompareType.ProfileToValue ||
            selectedType === CompareType.SumOfValues
        ) {
            if (valueType === 'string' && data.textValue.length === 0) {
                setError('textValue', { message: 'Value is required' });
                return;
            }
        }
        let name;
        let choiceName;
        let profileName;
        const dataBool = data.bool ? data.bool : false;
        const appConfigName = appConfigs?.find(a => a.id === selectedAppConfigId)?.name;
        const userProfileNameTo = profiles?.find(a => a.id === selectedProfileId2)?.name;
        const profile = profiles?.find(a => a.id === selectedProfileId);

        if (profile) {
            profileName = profile.name;
            if (selectedChoiceId)
                choiceName = profile.choices?.find(c => c?.id === selectedChoiceId)?.label?.en;
        }
        switch (selectedType) {
            case CompareType.SumOfValues:
                name = 'Sum of number value choices';
                break;
            case CompareType.ConfigToValue:
                name = appConfigName;
                break;
            case CompareType.ProfileToValue:
            case CompareType.ProfileToConfig:
            case CompareType.ProfileToProfile:
            case CompareType.DaysAfterProfileToProfile:
            case CompareType.DaysBeforeProfileToProfile:
            case CompareType.DaysAfterProfileToConfig:
            case CompareType.DaysBeforeProfileToConfig:
            case CompareType.DaysAfterProfileToValue:
            case CompareType.DaysBeforeProfileToValue:
            case CompareType.TrackerToConfig:
            case CompareType.TrackerToProfile:
            case CompareType.SymptomToValue:
            case CompareType.TrackerToValue: {
                name = profileName;
                break;
            }
            default:
                break;
        }
        const newRule: Rule = {
            ...rule,
            name: name || '',
            appConfigId: selectedAppConfigId,
            appConfigName,
            userProfileId: selectedProfileId,
            userProfileIdTo: selectedProfileId2,
            userProfileName: profileName,
            userProfileNameTo,
            choiceId: selectedChoiceId,
            choiceName,
            textValue: valueType === 'string' && data.textValue ? data.textValue : undefined,
            num: data.num ? +data.num : undefined,
            bool: valueType === 'bool' ? dataBool : undefined,
            compareType: selectedType,
            measurementType: measurementType || rule.measurementType || undefined,
            symptom: symptom || rule.symptom || undefined,
        };
        submitHandler(newRule, parentId);
    };

    return (
        <Dialog open>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogTitleWithClose
                    id="modalTitle"
                    onClose={closeHandler}
                    data-test={ExpressionEnum.RULE_MODAL}
                >
                    {addMode ? 'Add' : 'Update'} Rule
                </DialogTitleWithClose>
                <DialogContent>
                    <div>
                        <div>
                            <div>
                                <select
                                    onChange={handleTypeChange}
                                    style={{ marginRight: '10px', marginBottom: '20px' }}
                                    defaultValue={selectedType}
                                    data-test={ExpressionEnum.DATASET_DROPDOWN}
                                >
                                    <option value={CompareType.ConfigToValue}>
                                        App Setting{rule.op !== operator.missing && ' to Value'}
                                    </option>
                                    <option value={CompareType.ProfileToValue}>
                                        User Info{rule.op !== operator.missing && ' to Value'}
                                    </option>
                                    <option value={CompareType.TrackerToValue}>
                                        Tracker{rule.op !== operator.missing && ' to Value'}
                                    </option>
                                    {rule.op !== operator.missing && (
                                        <>
                                            <option value={CompareType.ProfileToConfig}>
                                                Compare User Info to App Setting
                                            </option>
                                            <option value={CompareType.ProfileToProfile}>
                                                Compare User Info to User Info
                                            </option>
                                            <option value={CompareType.DaysBeforeProfileToValue}>
                                                Days Before Profile to Value
                                            </option>
                                            <option value={CompareType.DaysAfterProfileToValue}>
                                                Days After Profile to Value
                                            </option>
                                            <option value={CompareType.DaysBeforeProfileToConfig}>
                                                Days Before Profile to Config
                                            </option>
                                            <option value={CompareType.DaysAfterProfileToConfig}>
                                                Days After Profile to Config
                                            </option>
                                            <option value={CompareType.DaysBeforeProfileToProfile}>
                                                Days Before Profile to Profile
                                            </option>
                                            <option value={CompareType.DaysAfterProfileToProfile}>
                                                Days After Profile to Profile
                                            </option>
                                            <option value={CompareType.TrackerToConfig}>
                                                Tracker to Config
                                            </option>
                                            <option value={CompareType.TrackerToProfile}>
                                                Tracker to Profile
                                            </option>
                                        </>
                                    )}
                                    {rule.op !== operator.missing &&
                                        useType === ExpressionUseType.Surveys && (
                                            <option value={CompareType.SumOfValues}>
                                                Sum of Number Value Choices
                                            </option>
                                        )}
                                    {rule.op === operator.missing && (
                                        <option value={CompareType.SymptomToValue}>Symptom</option>
                                    )}
                                </select>
                            </div>
                            <div>
                                {(selectedType === CompareType.ProfileToValue ||
                                    selectedType === CompareType.ProfileToProfile ||
                                    selectedType === CompareType.ProfileToConfig ||
                                    selectedType === CompareType.DaysBeforeProfileToValue ||
                                    selectedType === CompareType.DaysAfterProfileToValue ||
                                    selectedType === CompareType.DaysAfterProfileToConfig ||
                                    selectedType === CompareType.DaysBeforeProfileToConfig ||
                                    selectedType === CompareType.DaysBeforeProfileToProfile ||
                                    selectedType === CompareType.DaysAfterProfileToProfile) && (
                                    <Controller
                                        name="userProfileId"
                                        control={control}
                                        render={() => (
                                            <Autocomplete
                                                size="medium"
                                                onChange={(_, profile) =>
                                                    handleProfileChange(profile?.id)
                                                }
                                                value={
                                                    profiles?.find(
                                                        p => p.id === selectedProfileId,
                                                    ) ?? undefined
                                                }
                                                defaultValue={
                                                    profiles?.find(
                                                        p => p.id === selectedProfileId,
                                                    ) ?? undefined
                                                }
                                                getOptionLabel={selected => selected.name}
                                                options={
                                                    profiles
                                                        ?.filter(p =>
                                                            profileTypeMatch(
                                                                p,
                                                                selectedType,
                                                                rule.op,
                                                            ),
                                                        )
                                                        ?.sort((a, b) => {
                                                            const x = a.name.toLowerCase();
                                                            const y = b.name.toLowerCase();
                                                            if (x < y) return -1;
                                                            if (x > y) return 1;
                                                            return 0;
                                                        }) ?? []
                                                }
                                                isOptionEqualToValue={(option, val) =>
                                                    option.id === val.id
                                                }
                                                renderOption={(props, option) => (
                                                    <Box
                                                        component="li"
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            flexWrap: 'wrap',
                                                            alignItems: 'flex-start',
                                                        }}
                                                        {...props}
                                                    >
                                                        <option
                                                            value={option.id}
                                                            key={option.id}
                                                            data-test={option.name}
                                                        >
                                                            {option.name}({option.valueType})
                                                        </option>
                                                    </Box>
                                                )}
                                                renderInput={params => (
                                                    <TextField
                                                        variant="outlined"
                                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                                        {...params}
                                                        placeholder="Select User Info"
                                                        label="User Info"
                                                        InputLabelProps={{ shrink: true }}
                                                        data-test={
                                                            ExpressionEnum.OPERATORS_U_DROPDOWN
                                                        }
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                )}
                            </div>
                            <div>
                                {selectedType === CompareType.ProfileToProfile && (
                                    <Controller
                                        name="userProfileId"
                                        control={control}
                                        render={() => (
                                            <Autocomplete
                                                size="medium"
                                                onChange={(_, profile) =>
                                                    handleProfile2Change(profile?.id)
                                                }
                                                value={
                                                    profiles?.find(
                                                        p => p.id === selectedProfileId2,
                                                    ) ?? undefined
                                                }
                                                defaultValue={
                                                    profiles?.find(
                                                        p => p.id === selectedProfileId2,
                                                    ) ?? undefined
                                                }
                                                getOptionLabel={selected => selected.name}
                                                options={
                                                    profiles
                                                        ?.filter(p =>
                                                            profile2TypeMatch(
                                                                selectedProfileId,
                                                                p,
                                                                valueType,
                                                            ),
                                                        )
                                                        ?.sort((a, b) => {
                                                            const x = a.name.toLowerCase();
                                                            const y = b.name.toLowerCase();
                                                            if (x < y) return -1;
                                                            if (x > y) return 1;
                                                            return 0;
                                                        }) ?? []
                                                }
                                                isOptionEqualToValue={(option, val) =>
                                                    option.id === val.id
                                                }
                                                renderOption={(props, option) => (
                                                    <Box
                                                        component="li"
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            flexWrap: 'wrap',
                                                            alignItems: 'flex-start',
                                                        }}
                                                        {...props}
                                                    >
                                                        <option
                                                            value={option.id}
                                                            key={option.id}
                                                            data-test={option.name}
                                                        >
                                                            {option.name}({option.valueType})
                                                        </option>
                                                    </Box>
                                                )}
                                                renderInput={params => (
                                                    <TextField
                                                        variant="outlined"
                                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                                        {...params}
                                                        placeholder="Select User Info"
                                                        label="User Info"
                                                        InputLabelProps={{ shrink: true }}
                                                        data-test={
                                                            ExpressionEnum.OPERATORS_U_DROPDOWN
                                                        }
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                )}
                            </div>
                            <div>
                                {(selectedType === CompareType.ConfigToValue ||
                                    selectedType === CompareType.ProfileToConfig) && (
                                    <Controller
                                        name="userProfileId"
                                        control={control}
                                        render={() => (
                                            <Autocomplete
                                                size="medium"
                                                onChange={(_, config) =>
                                                    handleAppConfigChange(config?.id)
                                                }
                                                value={
                                                    appConfigs?.find(
                                                        p => p.id === selectedAppConfigId,
                                                    ) ?? undefined
                                                }
                                                defaultValue={
                                                    appConfigs?.find(
                                                        p => p.id === selectedAppConfigId,
                                                    ) ?? undefined
                                                }
                                                getOptionLabel={selected => selected.name}
                                                options={
                                                    appConfigs
                                                        ?.filter(
                                                            c =>
                                                                selectedType ===
                                                                    CompareType.ConfigToValue ||
                                                                configTypeMatch(c, valueType),
                                                        )
                                                        ?.sort((a, b) => {
                                                            const x = a.name.toLowerCase();
                                                            const y = b.name.toLowerCase();
                                                            if (x < y) return -1;
                                                            if (x > y) return 1;
                                                            return 0;
                                                        }) ?? []
                                                }
                                                isOptionEqualToValue={(option, val) =>
                                                    option.id === val.id
                                                }
                                                renderOption={(props, option) => (
                                                    <Box
                                                        component="li"
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            flexWrap: 'wrap',
                                                            alignItems: 'flex-start',
                                                        }}
                                                        {...props}
                                                    >
                                                        <option
                                                            value={option.id}
                                                            key={option.id}
                                                            data-test={option.name}
                                                        >
                                                            {option.name}({option.valueType})
                                                        </option>
                                                    </Box>
                                                )}
                                                renderInput={params => (
                                                    <TextField
                                                        variant="outlined"
                                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                                        {...params}
                                                        placeholder="Select App Config"
                                                        label="Compare to:"
                                                        InputLabelProps={{ shrink: true }}
                                                        data-test={
                                                            ExpressionEnum.OPERATORS_U_DROPDOWN
                                                        }
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                )}
                            </div>
                            <div>
                                {(selectedType === CompareType.TrackerToConfig ||
                                    selectedType === CompareType.TrackerToProfile ||
                                    selectedType === CompareType.TrackerToValue) && (
                                    <Controller
                                        control={control}
                                        name="measurementType"
                                        render={({ field: { onChange } }) => (
                                            <Autocomplete
                                                size="small"
                                                onChange={(_, val) => {
                                                    onChange(val);
                                                    setMeasurementType(val);
                                                }}
                                                getOptionLabel={selected => selected}
                                                fullWidth
                                                options={Object.values(ExpressionMeasurementType)}
                                                defaultValue={rule.measurementType}
                                                isOptionEqualToValue={(option, val) =>
                                                    option === val
                                                }
                                                renderInput={params => (
                                                    <TextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                                        {...params}
                                                        placeholder="Select Measurement Type"
                                                        label="Measurement Type"
                                                        InputLabelProps={{ shrink: true }}
                                                        data-test={
                                                            ExpressionEnum.OPERATORS_U_DROPDOWN
                                                        }
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                )}
                            </div>
                            <div>
                                {selectedType === CompareType.SymptomToValue &&
                                    rule.op === operator.missing && (
                                        <Controller
                                            control={control}
                                            name="symptom"
                                            render={({ field: { onChange } }) => (
                                                <Autocomplete
                                                    size="small"
                                                    onChange={(_, val) => {
                                                        onChange(val);
                                                        setSymptom(val);
                                                    }}
                                                    getOptionLabel={selected =>
                                                        SYMPTOMS.get(selected) ?? ''
                                                    }
                                                    fullWidth
                                                    options={Object.values(Symptom)}
                                                    defaultValue={rule.symptom}
                                                    isOptionEqualToValue={(option, val) =>
                                                        option === val
                                                    }
                                                    renderInput={params => (
                                                        <TextField
                                                            variant="outlined"
                                                            margin="dense"
                                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                                            {...params}
                                                            placeholder="Select Symptom"
                                                            label="Symptom"
                                                            InputLabelProps={{ shrink: true }}
                                                            data-test={
                                                                ExpressionEnum.OPERATORS_U_DROPDOWN
                                                            }
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    )}
                            </div>
                        </div>
                        <div>
                            {(selectedType === CompareType.ConfigToValue ||
                                selectedType === CompareType.ProfileToValue) &&
                                valueType === 'bool' && (
                                    <Controller
                                        name="bool"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                label="Value"
                                                labelPlacement="top"
                                                control={
                                                    <Checkbox
                                                        defaultChecked={rule.bool}
                                                        // inputRef={refBool}
                                                        {...field}
                                                        onChange={e =>
                                                            field.onChange(e.target.checked)
                                                        }
                                                    />
                                                }
                                            />
                                        )}
                                    />
                                )}
                            {(selectedType === CompareType.ConfigToValue ||
                                selectedType === CompareType.ProfileToValue) &&
                                valueType === 'string' && (
                                    <Controller
                                        name="textValue"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="Value *"
                                                type="text"
                                                error={Boolean(errors?.textValue)}
                                                helperText={errors?.textValue?.message}
                                                margin="dense"
                                                fullWidth
                                                {...field}
                                            />
                                        )}
                                    />
                                )}
                            {(selectedType === CompareType.ConfigToValue ||
                                selectedType === CompareType.ProfileToValue ||
                                selectedType === CompareType.SumOfValues) &&
                                valueType === 'number' && (
                                    <Controller
                                        name="num"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="Value"
                                                type="number"
                                                margin="dense"
                                                error={Boolean(errors?.num)}
                                                helperText={errors?.num?.message}
                                                defaultValue={0}
                                                {...field}
                                            />
                                        )}
                                    />
                                )}
                            {selectedType === CompareType.ProfileToValue &&
                                valueType === 'choice' && (
                                    <select
                                        onChange={handleChoiceChange}
                                        style={{ width: '300px' }}
                                        value={selectedChoiceId}
                                        data-test={ExpressionEnum.OPERATORS_U_CHOICES_DROPDOWN}
                                    >
                                        <option value="" key="0">
                                            Select:
                                        </option>
                                        {choices &&
                                            choices.map(c => (
                                                <option value={c?.id} key={c?.id}>
                                                    {c?.label?.en}
                                                </option>
                                            ))}
                                    </select>
                                )}
                        </div>
                        <div>
                            {(selectedType === CompareType.DaysBeforeProfileToValue ||
                                selectedType === CompareType.DaysAfterProfileToValue ||
                                (selectedType === CompareType.TrackerToValue &&
                                    rule.op !== operator.missing)) && (
                                <Controller
                                    name="num"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            label="Value"
                                            type="number"
                                            margin="dense"
                                            error={Boolean(errors?.num)}
                                            helperText={errors?.num?.message}
                                            defaultValue={0}
                                            {...field}
                                        />
                                    )}
                                />
                            )}
                            {(selectedType === CompareType.DaysBeforeProfileToConfig ||
                                selectedType === CompareType.DaysAfterProfileToConfig ||
                                selectedType === CompareType.TrackerToConfig) && (
                                <Controller
                                    name="userProfileId"
                                    control={control}
                                    render={() => (
                                        <Autocomplete
                                            size="medium"
                                            onChange={(_, config) =>
                                                handleAppConfigChange(config?.id)
                                            }
                                            value={
                                                appConfigs?.find(
                                                    p => p.id === selectedAppConfigId,
                                                ) ?? undefined
                                            }
                                            defaultValue={
                                                appConfigs?.find(
                                                    p => p.id === selectedAppConfigId,
                                                ) ?? undefined
                                            }
                                            getOptionLabel={selected => selected.name}
                                            options={
                                                appConfigs
                                                    ?.filter(c => configTypeMatch(c, 'number'))
                                                    ?.sort((a, b) => {
                                                        const x = a.name.toLowerCase();
                                                        const y = b.name.toLowerCase();
                                                        if (x < y) return -1;
                                                        if (x > y) return 1;
                                                        return 0;
                                                    }) ?? []
                                            }
                                            isOptionEqualToValue={(option, val) =>
                                                option.id === val.id
                                            }
                                            renderOption={(props, option) => (
                                                <Box
                                                    component="li"
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        flexWrap: 'wrap',
                                                        alignItems: 'flex-start',
                                                    }}
                                                    {...props}
                                                >
                                                    <option
                                                        value={option.id}
                                                        key={option.id}
                                                        data-test={option.name}
                                                    >
                                                        {option.name}({option.valueType})
                                                    </option>
                                                </Box>
                                            )}
                                            renderInput={params => (
                                                <TextField
                                                    variant="outlined"
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    {...params}
                                                    placeholder="Select App Config"
                                                    label="Compare to:"
                                                    InputLabelProps={{ shrink: true }}
                                                    data-test={ExpressionEnum.OPERATORS_U_DROPDOWN}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            )}
                            {(selectedType === CompareType.DaysAfterProfileToProfile ||
                                selectedType === CompareType.DaysBeforeProfileToProfile ||
                                selectedType === CompareType.TrackerToProfile) && (
                                <Controller
                                    name="userProfileId"
                                    control={control}
                                    render={() => (
                                        <Autocomplete
                                            size="medium"
                                            onChange={(_, profile) =>
                                                handleProfile2Change(profile?.id)
                                            }
                                            value={
                                                profiles?.find(p => p.id === selectedProfileId2) ??
                                                undefined
                                            }
                                            defaultValue={
                                                profiles?.find(p => p.id === selectedProfileId2) ??
                                                undefined
                                            }
                                            getOptionLabel={selected => selected.name}
                                            options={
                                                profiles
                                                    ?.filter(p =>
                                                        profile2TypeMatch(
                                                            selectedProfileId2,
                                                            p,
                                                            'number',
                                                        ),
                                                    )
                                                    ?.sort((a, b) => {
                                                        const x = a.name.toLowerCase();
                                                        const y = b.name.toLowerCase();
                                                        if (x < y) return -1;
                                                        if (x > y) return 1;
                                                        return 0;
                                                    }) ?? []
                                            }
                                            isOptionEqualToValue={(option, val) =>
                                                option.id === val.id
                                            }
                                            renderOption={(props, option) => (
                                                <Box
                                                    component="li"
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        flexWrap: 'wrap',
                                                        alignItems: 'flex-start',
                                                    }}
                                                    {...props}
                                                >
                                                    <option
                                                        value={option.id}
                                                        key={option.id}
                                                        data-test={option.name}
                                                    >
                                                        {option.name}({option.valueType})
                                                    </option>
                                                </Box>
                                            )}
                                            renderInput={params => (
                                                <TextField
                                                    variant="outlined"
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    {...params}
                                                    placeholder="Select User Info"
                                                    label="Compare to:"
                                                    InputLabelProps={{ shrink: true }}
                                                    data-test={ExpressionEnum.OPERATORS_U_DROPDOWN}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            )}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions
                    style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: 'white',
                        zIndex: 1000,
                    }}
                >
                    <Button onClick={closeHandler} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        data-test={ExpressionEnum.RULE_MODAL_SUBMIT_BUTTON}
                    >
                        <Typography>{addMode ? 'Add' : 'Update'} Rule</Typography>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default RuleModal;
