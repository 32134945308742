export enum CareTeamMemberEnum {
    NAV_CARE_TEAM_MEMBERS = 'care-team-members',
    CONTAINER = 'care-team-members-container',
    MODAL = 'care-team-member-modal',
    LABEL_EN = 'modal-label-en',
    LABEL_ES = 'modal-label-es',
    CLIENT = 'modal-client',
    CONTACT = 'modal-contact',
    TYPE = 'modal-type',
    SAVE = 'modal-save',
}

export const CareTeamMemberSelectors = {
    NAV_CARE_TEAM_MEMBERS: `a[data-test="${CareTeamMemberEnum.NAV_CARE_TEAM_MEMBERS}"]`,
    ADD_CARE_TEAM_MEMBER_BUTTON: `div[data-test="${CareTeamMemberEnum.CONTAINER}"] button[title="Add Care Team Member"]`,
    EDIT_CARE_TEAM_MEMBER_BUTTON: `div[data-test="${CareTeamMemberEnum.CONTAINER}"] button[title="Edit Care Team Member"]`,
    DELETE_CARE_TEAM_MEMBER_BUTTON: `div[data-test="${CareTeamMemberEnum.CONTAINER}"] button[title="Delete Care Team Member"]`,
    MODAL: `form[data-test="${CareTeamMemberEnum.MODAL}"]`,
    LABEL_EN_INPUT: `div[data-test="${CareTeamMemberEnum.LABEL_EN}"] input`,
    LABEL_ES_INPUT: `div[data-test="${CareTeamMemberEnum.LABEL_ES}"] input`,
    CLIENT_DROPDOWN: `div[data-test="${CareTeamMemberEnum.CLIENT}"] div[role="button"]`,
    CLIENT_DROPDOWN_OPTIONS: 'div[role="presentation"] ul[aria-labelledby="appBundleId-label"]',
    CLIENT_DROPDOWN_OPTIONS_DISABLED: '[aria-labelledby="appBundleId-label"][aria-disabled="true"]',
    CLIENT_BAPTIST_OPTION: 'li[data-test="com.wildflowerhealth.UGROW.BaptistHealth"]',
    CLIENT_PROVIDENCE_OPTION: 'li[data-test="com.caregiver.providence"]',
    MEMBER_TYPE_DROPDOWN: `div[data-test="${CareTeamMemberEnum.TYPE}"] div[role="button"]`,
    MEMBER_TYPE_DROPDOWN_OPTIONS: 'ul[aria-labelledby="careTeamMemberTypeId-label"]',
    MEMBER_TYPE_DROPDOWN_OPTION: 'ul[aria-labelledby="careTeamMemberTypeId-label"] li:first-child',
    MEMBER_CONTACT_DROPDOWN: `div[data-test="${CareTeamMemberEnum.CONTACT}"] div[role="button"]`,
    MEMBER_CONTACT_DROPDOWN_OPTIONS: 'ul[aria-labelledby="careTeamMemberContactId-label"]',
    MEMBER_CONTACT_DROPDOWN_OPTION:
        'ul[aria-labelledby="careTeamMemberContactId-label"] li:first-child',
    SAVE_BUTTON: `button[data-test="${CareTeamMemberEnum.SAVE}"]`,
    SEARCH_INPUT: `div[data-test="${CareTeamMemberEnum.CONTAINER}"] input[placeholder="Search"]`,
};
