import * as Yup from 'yup';

export enum NewOrderSections {
    PatientInfo = 'PatientInfo',
    NewDiagnose = 'NewDiagnose',
    PatientAdditionalInfo = 'PatientAdditionalInfo',
}

const PATIENT_INFO_SCHEMA = {
    firstName: Yup.string().when(['lastName, patientId'], ([lastName, patientId], schema) => {
        if (lastName === '' && patientId === '') {
            return schema.required();
        }
        return schema;
    }),
    lastName: Yup.string().when(['firstName, patientId'], ([firstName, patientId], schema) => {
        if (firstName === '' && patientId === '') {
            return schema.required();
        }
        return schema;
    }),
    patientId: Yup.string().when(['firstName', 'lastName'], ([firstName, lastName], schema) => {
        if (firstName === '' && lastName === '') {
            return schema.required();
        }
        return schema;
    }),
};

const NEW_DIAGNOSE_INFO_SCHEMA = {
    code: Yup.string().required(),
    codeSet: Yup.string().default('ICD-10').equals(['ICD-10']).required(),
    type: Yup.string(),
    name: Yup.string(),
    dateTime: Yup.string().required(),
};

const PATIENT_ADDITIONAL_INFO_SCHEMA = {
    providerNPI: Yup.string(),
    comments: Yup.string(),
    providerFirstName: Yup.string(),
    providerLastName: Yup.string(),
    prescribeDate: Yup.string().required(),
};

export const generateFormSchema = (orgDetailsSection?: string) => {
    switch (orgDetailsSection) {
        case NewOrderSections.PatientInfo:
            return Yup.object().shape(PATIENT_INFO_SCHEMA);
        case NewOrderSections.NewDiagnose:
            return Yup.object().shape(NEW_DIAGNOSE_INFO_SCHEMA);
        case NewOrderSections.PatientAdditionalInfo:
            return Yup.object().shape(PATIENT_ADDITIONAL_INFO_SCHEMA);
        default:
            return Yup.object().shape({
                ...PATIENT_INFO_SCHEMA,
                ...NEW_DIAGNOSE_INFO_SCHEMA,
                ...PATIENT_ADDITIONAL_INFO_SCHEMA,
            });
    }
};
