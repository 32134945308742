import { OrgTreeNode } from '~/hooks/useOrgTree';
import { OrgListQuery } from '~/schemaTypes';

export type OrgId = OrgTreeNode['org']['id'];
export type FlattedOrgNode = { id: OrgId; name: string; node: OrgTreeNode };

export type State = {
    orgTree: OrgTreeNode | null;
    orgState: Record<OrgId, boolean>;
    selectedOrgId: OrgId | null;
    calculated: {
        flattedOrgTreeInfo: FlattedOrgNode[];
        flattedOrgTreeInfoMap: Record<OrgId, FlattedOrgNode>;
    };
};

export type Act = {
    loadOrgs: (data: OrgListQuery) => void;
    selectOrgId: (id: OrgId) => void;
};

export enum ActionEnum {
    LOADED_ORGS = 'LoadedOrgs',
    SET_SELECTED_ORG_ID = 'setSelectedOrgId',
}

export type Action<T extends ActionEnum, P> = {
    type: T;
    payload: P;
};
