import React from 'react';
import { Grid, Typography } from '@mui/material';

type HeaderProps = {
    name: string;
};

const Header: React.FC<HeaderProps> = props => {
    const { name } = props;
    return (
        <Grid item xs={12} paddingBottom={3}>
            <Typography variant="h6">VC Affiliate - {name}</Typography>
        </Grid>
    );
};

export default Header;
