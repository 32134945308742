export enum RepeatIntervalUnit {
    'Daily' = 'Daily',
    'Weekly' = 'Weekly',
    'Monthly' = 'Monthly',
}

export enum ViewLimitUnit {
    'Weeks' = 'Week(s)',
    'Months' = 'Month(s)',
    'Years' = 'Year(s)',
}
